import {ArrayColumnArrayResult, ArrayColumnObjectResult} from '~/src/app/core/helper/array-support.d';

export class ArraySupport {

    /**
     * Visszatér az adott tömb egy oszlopával, melyet a 'name'-el határozunk meg.
     * Opcionálisan meg lehet adni egy kulcsot, amit a 'key' érték határoz meg, hogy mi alapján index-elje az értékeket.
     *
     * @param {T[]} array
     * @param {keyof T} name
     * @param {keyof T} key
     * @returns {ArrayColumnArrayResult | ArrayColumnObjectResult}
     */
    public static arrayColumn<T = null>(array: T[], name: (keyof T), key?: (keyof T)): ArrayColumnArrayResult<T> | ArrayColumnObjectResult<T> {

        if (key) {
            const result: ArrayColumnObjectResult<T> = {};

            array.forEach(item => {
                result[`${item[key]}`] = item[name];
            });

            return result;
        }

        return array.map((item) => item[name]);
    }

    /**
     * Vissza adja két tömb közös elemeit
     *
     * @param {T[]} array1
     * @param {T[]} array2
     * @returns {T[]}
     */
    public static segment<T = null>(array1: T[], array2: T[]): T[] {
        const result: T[] = [];

        array1.forEach(item1 => {
            array2.forEach(item2 => {
                if (item1 === item2) {
                    result.push(item2);
                }
            });
        });

        return result;
    }

    /**
     * Összefésül két tömböt.
     * Opcionálisan be meg lehet adni, hogy egy adott elem szerepelhet-e többször az eredményben.
     *
     * @param {T[]} array1
     * @param {T[]} array2
     * @param {boolean} unique
     * @returns {T[]}
     */
    public static arrayMerge<T = null>(array1: T[], array2: T[], unique = false): T[] {
        const result = array1;

        array2.forEach(item => {
            if (unique) {
                if (!result.includes(item)) {
                    result.push(item);
                }
            } else {
                result.push(item);
            }
        });

        return result;
    }

    /**
     * A tömbből eltávolítjuk az adott elemet
     *
     * @param {T[]} array
     * @param {T[] | T} values
     * @param firstKey
     * @param lastKey
     * @returns {T[]}
     */
    public static removeItem<T = null, K = null>(
        array: T[],
        values: T | T[] | K | K[],
        firstKey: keyof T | string = null,
        lastKey: keyof T | keyof K | string = null
    ): T[] {
        const removeItem = (element) => {
            const index = array.findIndex((item) => {
                if (firstKey) {

                    if (lastKey) {
                        return item[<string>(firstKey)] === element[lastKey];
                    }

                    return item[<string>(firstKey)] === element[firstKey];
                } else {
                    return item === element;
                }
            });

            if (index > -1) {
                array.splice(index, 1);
            }
        };

        values = this.itemConvertToArray(values);

        (<T[]>values).forEach(element => removeItem(element));

        return array;
    }

    /**
     * Egyedülálló elemet tömbbé konvertál
     *
     * @param item
     * @returns {any[]}
     */
    public static itemConvertToArray(item: any): any[] {
        if (!(item instanceof Array)) {
            item = [item];
        }

        return item;
    }
}
