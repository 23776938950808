import {Component, ElementRef, EventEmitter, Host, HostListener, Input, OnInit, Output} from '@angular/core';
import {SocialSiteInterface} from '~/src/app/components/social-site-select/social-site-select.component';
import {SocialMediaPlatform} from '~/src/app/modules/social-media-post/social-media-post.interface';
import {SocialMediaPlatformConfig} from '~/src/app/modules/social-media-post/social-media-platforms-config';
import {LanguageService} from '~/src/app/services/language.service';
import {OrganizationsComponent} from '~/src/app/modules/users/organizations/organizations.component';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Component({
    selector: 'smd-social-site-card',
    templateUrl: './social-site-card.component.html',
    styleUrls: ['./social-site-card.component.scss']
})
export class SocialSiteCardComponent implements OnInit {
    @Input() site: SocialSiteInterface;
    @Output() modifyClick: EventEmitter<SocialSiteInterface> = new EventEmitter<SocialSiteInterface>();
    @Output() refreshClick: EventEmitter<SocialSiteInterface> = new EventEmitter<SocialSiteInterface>();
    @Output() deleteClick: EventEmitter<SocialSiteInterface> = new EventEmitter<SocialSiteInterface>();
    platformConfig: SocialMediaPlatform;
    isEmphasized = false;

    constructor(
        public languageService: LanguageService,
        private hostElement: ElementRef,
        private scrollTo: ScrollToService,
        @Host() private parent: OrganizationsComponent
    ) {}

    ngOnInit() {
        if (this.site) {
            this.platformConfig = SocialMediaPlatformConfig.getConfig(this.site.socialType);

            if (this.parent && (this.parent instanceof OrganizationsComponent) && (String(this.site.siteID) === this.parent.initialSocialSiteID)) {
                this.scrollTo.scrollTo({
                    target: this.hostElement.nativeElement
                }).subscribe(() => {
                    this.isEmphasized = true;
                });

                this.parent.initialSocialSiteID = null;
            }
        }
    }

    @HostListener('click')
    removeEmphasizedState(): void {
        this.isEmphasized = false;
    }

    emitModify() {
        this.modifyClick.emit(this.site);
    }

    emitRefresh() {
        this.refreshClick.emit(this.site);
    }

    emitDelete() {
        this.deleteClick.emit(this.site);
    }
}
