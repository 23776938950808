import { debounceTime } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { Debounce, Helpers } from '~/src/app/services/helpers';
import { DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import { GeoLocationInterface } from '~/src/app/modules/posts/post-actions.interfaces';
import { NotifyService } from '~/src/app/services/notify.service';
//import { PostActionsService } from '~/src/app/modules/posts/post-actions.service';
import { PostActionsService } from '~/src/app/modules/instagram-location/location.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LanguageService } from '~/src/app/services/language.service';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// news feed stuff 
import { MatInput } from '@angular/material/input';

// saját
@Component({
    selector: 'smd-instagram-location-collection-manager',
    templateUrl: './instagram-location-collection-manager.component.html',
    styleUrls: ['./instagram-location-collection-manager.component.scss'],
})

export class InstagramLocationCollectionManagerComponent implements OnInit {
    // Data arrays
    @Input() isModal = true;
    @Output() valueChange = new EventEmitter();

    // location
    @ViewChild('searchLocationsInput', { static: true }) searchLocationsInput: MatInput;
    locationSearchInProgress = false;
    geoLocations: GeoLocationInterface[] = [];
    selectedLocation: GeoLocationInterface;
    value: '';

    constructor(
        public languageService: LanguageService,
        private service: PostActionsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (this.data && this.data.item) {
            // this.selectedLocation = new GeoLocationInterface{ key: this.data.locationID, name: this.data.name };
            this.selectedLocation = <GeoLocationInterface>{ id: this.data.item.locationID, name: this.data.item.name };
        }
    }

    ngOnInit() {
    }

    /**
     * Emit filter change
     */
    @Debounce(350)
    searchLocation(event: KeyboardEvent) {
        const value = event.target['value'];

        if ([UP_ARROW, DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW].indexOf(event.keyCode) > -1) {
            return;
        }

        if (!value) {
            return;
        }

        this.locationSearchInProgress = true;
        this.getGeoLocation(value);
    }

    /**
     * Get geo locations
     * @param {string} searchValue
     */
    private getGeoLocation(searchValue: string) {
        this.getTargetingLocations(searchValue).subscribe((geoLocations: GeoLocationInterface[]) => {
            this.geoLocations = geoLocations;
            this.locationSearchInProgress = false;
        }, error => {
            NotifyService.error('Get geo locations', 'Failed get locations!');
            this.locationSearchInProgress = false;
        });
    }

    /**
     * Get targeting locations by searchValue
     *
     * @param {string} searchValue
     * @return {Observable<GeoLocationInterface[]>}
     */
    private getTargetingLocations(searchValue: string): Observable<GeoLocationInterface[]> {
        return new Observable(observer => {
            this.service.getLocations(searchValue)
                .then(response => {
                    observer.next(response.targeting.data);
                    observer.complete();
                })
                .catch(error => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    /**
     * Select location
     * @param {MatAutocompleteSelectedEvent} event
     */
    selectLocation(event: MatAutocompleteSelectedEvent) {
        const value = event.option.value;

        const location = this.geoLocations.find(item => {
            return item.name === value;
        });

        this.selectedLocation = <GeoLocationInterface>location;

        this.changeEmit();
    }

    /**
     * Reset location 
     */
    onRemoveLocation() {

        // this.instagramLocationInput.nativeElement. = '';
        this.searchLocationsInput.value = '';
        // this.selectedLocation = undefined;
        this.selectedLocation = <GeoLocationInterface>{};

        this.changeEmit();
    }

    /**
     * Emit value change
     */
    changeEmit() {
        // this.valueChange.emit(this.getFormData());
        this.valueChange.emit(this.selectedLocation);
    }

}
