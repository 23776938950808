import {Injectable} from '@angular/core';
import {IRSSFeedListFilters} from '~/src/app/modules/rss/types/rss-feed.interface';
import {LocalStorage} from '~/src/app/core/local-storage';
import {RSS_LIST_FILTERS_STORE_KEY} from '~/src/app/modules/rss/rss.constants';
import {LoggedUser} from '~/src/app/services/logged-user';

@Injectable({providedIn: 'root'})
export class RssStateService {

    /**
     * Save RSS feed page filter params
     * @param {IRSSFeedListFilters} params
     */
    storeFilters(params: IRSSFeedListFilters): void {
        LocalStorage.set(RSS_LIST_FILTERS_STORE_KEY, {
            ...(LocalStorage.get(RSS_LIST_FILTERS_STORE_KEY) || {}),
            [LoggedUser.getUser().userID]: params
        });
    }

    /**
     * Get RSS feed page filter params
     * @return {IRSSFeedListFilters}
     */
    getFilters(): IRSSFeedListFilters {
        const data: {[key: string]: IRSSFeedListFilters} = LocalStorage.get(RSS_LIST_FILTERS_STORE_KEY);
        let filters: IRSSFeedListFilters = null;

        if (!data || !data[LoggedUser.getUser().userID]) {
            filters = {
                organizationIDs: [],
                keyword: null,
                sort: null,
                rssFeeds: [],
                rssGroupID: null
            };
        } else {
            filters = data[LoggedUser.getUser().userID];
        }

        return filters;
    }
}
