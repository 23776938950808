import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Component, Inject, OnInit} from '@angular/core';
import {FacebookService} from 'ngx-facebook';
import {Facebook} from './Facebook.class';
import {SocialManagerComponent} from './social-manager/social-manager.component';
import {SocialManagerService} from './social-manager/social-manager.service';
import {DialogWarnComponent} from '../../components/dialog-warn/dialog-warn.component';
import {DialogLoaderComponent} from '../../components/dialog-loader/dialog-loader.component';
import {FacebookUser, Helpers} from '../../services/helpers';
import {DialogSuccessComponent} from '../../components/dialog-success/dialog-success.component';
import {LinkedInService} from '~/src/app/services/linked-in.service';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {HttpClient} from '~/node_modules/@angular/common/http';
import {Token} from '~/src/app/services/token';
import {DEFAULT_TIMEZONE} from '~/src/app/configs/configs';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {SocialSiteUtils} from '~/src/app/modules/users/social-site.utils';
import {LanguageService} from '~/src/app/services/language.service';
import { BackendService } from '../../core/backend.service';
import {forEach, orderBy} from 'lodash';

interface FacebookProfileInterface {
    access_token: string;
    id: string;
    name: string;
    organizationID: string;
}

interface FacebookPageInterface {
    access_token: string;
    category: string;
    category_list: any[];
    id: string;
    name: string;
    tasks: string[];
    feedTarget: any[];
    organizationID: string;
    facebook_page_id?: any;
}

interface FacebookGroupInterface {
    access_token?: string;
    id: string;
    name: string;
    organizationID: string;
}

interface DialogData {
    organizationID: string;
    page: string;
    addAnotherPageMethod?: () => any;
}

enum FacebookDialogModes {
    AddProfile = 'addProfile',
    AddPages = 'addPages',
    AddGroups = 'addGroups',
    AddLinkedInPages = 'AddLinkedInPages',
    AddLinkedInProfiles = 'AddLinkedInProfiles',
    AddInstagram = 'AddInstagram',
}

@Component({
    selector: 'smd-facebook-dialog',
    templateUrl: './Facebook-dialog.component.html',
    styleUrls: ['./Facebook-dialog.component.scss']
})

export class FacebookDialogComponent implements OnInit {
    fb: Facebook;
    socialManager: SocialManagerComponent;
    profiles: FacebookProfileInterface = {
        name: '',
        id: '',
        access_token: '',
        organizationID: ''
    };
    pages: FacebookPageInterface[] = [];
    groups: FacebookGroupInterface[] = [];
    linkedInPages;
    linkedInProfiles;
    showProfile = false;
    showPages = false;
    instagramPages = false;
    showGroups = false;
    showLoader = true;
    selected = false;
    pageFeedTarget = [];
    selectedPages: FacebookPageInterface[] = [];
    selectedProfile: FacebookProfileInterface[] = [];
    selectedGroups: FacebookGroupInterface[] = [];
    selectedLinkedInPages = [];
    selectedLinkedInProfiles = [];
    mode: FacebookDialogModes = FacebookDialogModes.AddProfile;
    filterCollection: String = '';

    constructor(
        public _dialog: MatDialog,
        public _dialogRef: MatDialogRef<FacebookDialogComponent>,
        public facebookservice: FacebookService,
        socialManagerService: SocialManagerService,
        @Inject(MAT_DIALOG_DATA) public _data: DialogData,
        private linkedInService: LinkedInService,
        private backend: BackendService,
        private http: HttpClient,
        private openModal: OpenModalService
    ) {
        this.fb = new Facebook(facebookservice, _dialog);
        this.socialManager = new SocialManagerComponent(socialManagerService);

        if (this._data.page === 'user') {
            this.facebookProfile();
            this.mode = FacebookDialogModes.AddProfile;
        }

        if (this._data.page === 'page') {
            this.facebookPages();
            this.mode = FacebookDialogModes.AddPages;
        }

        if (this._data.page === 'instagram') {
            this.getInstagramPagesWithFacebookLogin();
            this.mode = FacebookDialogModes.AddPages;
        }

        if (this._data.page === 'group') {
            this.facebookGroups();
            this.mode = FacebookDialogModes.AddGroups;
        }

        if (this._data.page === 'linkedInPage') {
            this.mode = FacebookDialogModes.AddLinkedInPages;
            this.handleLinkedInSites();
        }
        if (this._data.page === 'linkedInProfile') {
            this.mode = FacebookDialogModes.AddLinkedInProfiles;
            this.handleLinkedInProfiles();
        }
    }

    ngOnInit() {
        this._dialogRef.updateSize('75vw', 'auto');
    }

    get languageService() {
        return LanguageService;
    }

    facebookProfile() {
        this.showProfile = false;
        this.showLoader = true;
        this.fb.getProfile()
            .then(res => {
                this.profiles = res;
                this.showProfile = true;
                this.showLoader = false;
            })
            .catch(e => {
                console.log(e);
            });
    }

    facebookPages() {
        this.showPages = false;
        this.showLoader = true;
        this.fb.getPages()
            .then(res => {
                this.pages = orderBy(res.data, 'name');
                this.getFeedTargeting();
                this.showPages = true;
                this.showLoader = false;
            })
            .catch(e => {
                console.log(e);
            });

    }

    getInstagramPagesWithFacebookLogin() {
        this.showPages = false;
        this.showLoader = true;
        this.instagramPages = false;
        this.fb.getPages()
            .then( async res => {
                let fbPages = res.data;
                const businessPages = await this.getInstaBusinesses(fbPages);
                const instaPages = businessPages.filter(page => page !== null);
                this.pages = orderBy(instaPages, 'name');
                this.showPages = false;
                this.instagramPages = true;
                this.showLoader = false;
            })
            .catch(e => {
                console.log(e);
            });

    }

    getFeedTargeting() {
        for (const page of this.pages) {
            this.fb.getPageTargetFeed(page.id)
                .then(res => {
                    page.feedTarget = res.data;
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    facebookGroups() {
        this.showGroups = false;
        this.showLoader = true;
        this.fb.getGroups()
            .then(res => {
                this.groups = orderBy(res.data, 'name');
                this.showGroups = true;
                this.showLoader = false;
            })
            .catch(e => {
                console.log(e);
            });
    }

    facebookLogout() {
        this.fb.logout();
    }

    facebookBack() {
        this._dialogRef.close();
    }

    saveProfile() {
        if (this.selectedProfile.length === 0) {

            this._dialog.open(DialogWarnComponent, {
                data: {
                    message: 'Choose a profile!'
                }
            });

            return false;
        }

        const _loader = this.openModal.loader(DialogLoaderComponent);

        const profiles = [];

        for (const profile of this.selectedProfile) {
            const data = {
                userID: profile.id,
                accessToken: profile.access_token,
                userName: profile.name,
                organizationID: this._data.organizationID,
            };

            profiles.push(data);
        }

        this.socialManager.addProfiles(profiles)
            .then(response => {
                _loader.close();
                this._dialog.open(DialogSuccessComponent, {
                    data: {
                        message: 'Profile successfully created!'
                    }
                }).afterClosed().subscribe(() => {
                    this._dialogRef.close(true);
                });
            })
            .catch(error => {
                _loader.close();
                this._dialog.open(DialogWarnComponent, {
                    data: {
                        message: 'Profile create failed!'
                    }
                });
            });
    }

    async saveInstagramPages() {
        if (this.selectedPages.length === 0) {

            this._dialog.open(DialogWarnComponent, {
                data: {
                    message: 'Choose a social page!'
                }
            });

            return false;
        }

        const _loader = this.openModal.loader(DialogLoaderComponent);

        const pages = [];

        for (const page of this.selectedPages) {

            await this.fb.getPageAccessToken(page.facebook_page_id, this.backend)
            .then((response) => {
                pages.push({
                    pageID: page.id,
                    accessToken: response.accessToken,
                    userAccessToken: response.userAccessToken,
                    pageName: page.name,
                    socialType: 'instagram',
                    organizationID: this._data.organizationID,
                    pageAccessTokenExpire: SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                    connectedFacebookPageID: page.facebook_page_id ? page.facebook_page_id : null,
                });
            });           
        }

        this.socialManager.addPages(pages)
            .then(response => {
                _loader.close();
                this._dialog.open(DialogSuccessComponent, {
                    data: {
                        message: 'Page successfully created!'
                    }
                }).afterClosed().subscribe(() => {
                    this._dialogRef.close(true);
                });
            })
            .catch(error => {
                _loader.close();
                this._dialog.open(DialogWarnComponent, {
                    data: {
                        message: 'Page create failed'
                    }
                });
            });
    }

    savePages() {
        if (this.selectedPages.length === 0) {

            this._dialog.open(DialogWarnComponent, {
                data: {
                    message: 'Choose a social page!'
                }
            });

            return false;
        }

        const _loader = this.openModal.loader(DialogLoaderComponent);

        const pages = [];

        for (const page of this.selectedPages) {
            pages.push({
                pageID: page.id,
                accessToken: page.access_token,
                pageName: page.name,
                organizationID: this._data.organizationID,
                pageAccessTokenExpire: SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                connectedFacebookPageID: page.facebook_page_id ? page.facebook_page_id : null,
            });
        }

        this.socialManager.addPages(pages)
            .then(response => {
                _loader.close();
                this._dialog.open(DialogSuccessComponent, {
                    data: {
                        message: 'Page successfully created!'
                    }
                }).afterClosed().subscribe(() => {
                    this._dialogRef.close(true);
                });
            })
            .catch(error => {
                _loader.close();
                this._dialog.open(DialogWarnComponent, {
                    data: {
                        message: 'Page create failed'
                    }
                });
            });
    }

    saveGroup() {
        if (this.selectedGroups.length === 0) {

            this._dialog.open(DialogWarnComponent, {
                data: {
                    message: 'Choose a group page!'
                }
            });

            return false;
        }

        const _loader = this.openModal.loader(DialogLoaderComponent);

        const groups = [];

        for (const group of this.selectedGroups) {
            groups.push({
                groupID: group.id,
                accessToken: group.access_token,
                groupName: group.name,
                organizationID: this._data.organizationID
            });
        }

        this.socialManager.addGroups(groups)
            .then(response => {
                _loader.close();
                this._dialog.open(DialogSuccessComponent, {
                    data: {
                        message: 'Group successfully created!'
                    }
                }).afterClosed().subscribe(() => {
                    this._dialogRef.close(true);
                });
            })
            .catch(error => {
                _loader.close();
                this._dialog.open(DialogWarnComponent, {
                    data: {
                        message: 'Group create failed!'
                    }
                });
            });
    }

    changeFilter(event) {
        this.filterCollection = event.target.value;
    }

    addNewLinkedInPages() {
        let dialogResult = {isNeedAnotherAcc: true};

        if (!!this._data.addAnotherPageMethod) {
            this._data.addAnotherPageMethod();
        } else {
            dialogResult = null;
        }

        this._dialogRef.close(dialogResult);
    }

    addNewLinkedInProfiles() {
        let dialogResult = {isNeedAnotherPage: true};

        if (!!this._data.addAnotherPageMethod) {
            this._data.addAnotherPageMethod();
        } else {
            dialogResult = null;
        }

        this._dialogRef.close(dialogResult);
    }
    saveLinkedInPages() {
        if (this.selectedLinkedInPages.length === 0) {

            this._dialog.open(DialogWarnComponent, {
                data: {
                    message: 'Choose a social page!'
                }
            });

            return false;
        }

        const _loader = this.openModal.loader(DialogLoaderComponent);

        const pages = [];

        for (const page of this.selectedLinkedInPages) {
            pages.push({
                pageID: page.id,
                accessToken: this.linkedInService.token,
                pageName: page.name,
                organizationID: this._data.organizationID,
                socialType: 'linkedIn',
                pageAccessTokenExpire: this.linkedInService.expiresIn
            });
        }

        this.socialManager.addPages(pages)
            .then(response => {
                _loader.close();
                this._dialog.open(DialogSuccessComponent, {
                    data: {
                        message: 'Page successfully created!'
                    }
                }).afterClosed().subscribe(() => {
                    this._dialogRef.close(true);
                });
            })
            .catch(error => {
                _loader.close();
            });
    }

    saveLinkedInProfiles() {
        if (this.selectedLinkedInProfiles.length === 0) {

            this._dialog.open(DialogWarnComponent, {
                data: {
                    message: 'Choose a profile!'
                }
            });

            return false;
        }

        const _loader = this._dialog.open(DialogLoaderComponent, {
            disableClose: true,
            panelClass: 'dialog-loader-modal',
            minWidth: '200vw',
            minHeight: '200vh',
            hasBackdrop: false
        });


        this.linkedInService.getMe().then((linkedInProfile) => {
            const sites = {
                sites: JSON.stringify(
                    [
                        {
                            pageID: `urn:li:person:${linkedInProfile.id}`,
                            socialType: 'linkedIn',
                            name: linkedInProfile.firstName.localized.en_US + ' ' + linkedInProfile.lastName.localized.en_US,
                            timezone: DEFAULT_TIMEZONE,
                            pageType: 'user',
                            pageAccessToken: this.linkedInService.token,
                            organizationID: this._data.organizationID,
                            pageAccessTokenExpire: this.linkedInService.expiresIn
                        }
                    ]
                )
            };
            const sitesFormData = Helpers.objectToFormData(sites);

            return this.http.post(`api/social-sites`,
                sitesFormData,
                Helpers.getBaseHttpHeaders(Token.getToken()))
                .toPromise().then(profiles => {
                    _loader.close();
                    this._dialog.open(DialogSuccessComponent, {
                        data: {
                            message: 'Profile successfully added.'
                        }
                    }).afterClosed().subscribe(() => {
                        this._dialogRef.close(true);
                    });
                }).catch(err => {

                    _loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: FormValidationService.readError(err).message
                        });
                    });

                    _loader.close();
                });
        }).catch(() => {
            _loader.close();
        });
    }

    selectProfile(profile) {
        const index = this.selectedProfile.indexOf(profile);

        profile.access_token = FacebookUser.getData().access_token;
        profile.userID = FacebookUser.getData().userID;

        if (index > -1) {
            this.selectedProfile.splice(index, 1);
        } else {
            this.selectedProfile.push(profile);
        }
    }

    selectPage(page) {
        const index = this.selectedPages.indexOf(page);


        if (index > -1) {
            this.selectedPages.splice(index, 1);
        } else {
            this.selectedPages.push(page);
        }
    }

    selectGroup(group) {
        const index = this.selectedGroups.indexOf(group);

        group.access_token = FacebookUser.getData().access_token;

        if (index > -1) {
            this.selectedGroups.splice(index, 1);
        } else {
            this.selectedGroups.push(group);
        }
    }

    selectLinkedInPage(page) {
        const index = this.selectedLinkedInPages.indexOf(page);

        if (index > -1) {
            this.selectedLinkedInPages.splice(index, 1);
        } else {
            this.selectedLinkedInPages.push(page);
        }
    }

    selectLinkedInProfile(page) {
        const index = this.selectedLinkedInProfiles.indexOf(page);

        if (index > -1) {
            this.selectedLinkedInProfiles.splice(index, 1);
        } else {
            this.selectedLinkedInProfiles.push(page);
        }
    }

    handleLinkedInSites() {
        this.linkedInService.getPages().then(linkedInPages => {
            const pages = linkedInPages.elements.map(page => {
                return {
                    id: page['organizationalTarget'],
                    name: page['organizationalTarget~'].localizedName
                };
            });
            this.linkedInPages = orderBy(pages, 'name');
            this.showLoader = false;
        }).catch(error => {
            this.linkedInPages = null;
            this.showLoader = false;
        });
    }
    handleLinkedInProfiles() {
        this.linkedInService.getMe().then(linkedInProfile => {
            this.linkedInProfiles = [
                {
                    id: linkedInProfile.id,
                    name: linkedInProfile.firstName.localized.en_US + ' ' + linkedInProfile.lastName.localized.en_US,
                }
            ];
            this.showLoader = false;
        }).catch(error => {
            this.linkedInPages = null;
            this.showLoader = false;
        });
    }

    get FacebookDialogModes() {
        return FacebookDialogModes;
    }

    async getInstaBusinesses(fbPages)
    {
        const promises = [];
        for(const page of fbPages) {
            promises.push(this.getInstaPages(page)); 
        }

        const responses = await Promise.all(promises);
        const instaPages = responses.filter(response => response !== null)
        const businessPromises = [];
        for(const response of instaPages) {
            if(response) {
                businessPromises.push(this.getBusinessInstaPages(response)); 
            }
        }
        return await Promise.all(businessPromises);
    }

    async getInstaPages(page)
    {
        try {
            const response = await this.fb.getInstagramPages(page.id, page.access_token)
            if(response.data.length) {
                return {facebook_page_id: page.id,access_token: page.access_token,name: response.data[0]['username']}
            } else {
                return null;
            }
        } catch(error) {
            return null;
        }
    }

    async getBusinessInstaPages(page) {
        try {
            const response = await this.fb.getInstagramBusinessID(page.facebook_page_id, page.access_token)
            if(response.instagram_business_account) {
                return {...page,id: response.instagram_business_account.id}
            } else {
                return null;
            }
        } catch(error) {
            return null;
        }
    }
}
