import {Component, EventEmitter, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '~/node_modules/@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {LanguageService} from '~/src/app/services/language.service';
import {Dashboard} from '~/src/app/modules/analytics/dashboard.interfaces';

interface DialogData {
    dashboard: Dashboard;
}

@Component({
    selector: 'smd-update-dashboard-modal',
    templateUrl: './update-dashboard-modal.component.html',
    styleUrls: ['./update-dashboard-modal.component.scss']
})
export class UpdateDashboardModalComponent {
    submitEvent = new EventEmitter();
    dashboardForm: FormGroup;
    message: object;
    dashboard: Dashboard;

    constructor(
        public dialogRef: MatDialogRef<UpdateDashboardModalComponent>,
        public languageService: LanguageService,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) {
        this.dashboardForm = new FormGroup({
            name: new FormControl(this.dialogData.dashboard.name || '',
                Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(55)])),
            description: new FormControl('', Validators.compose([Validators.maxLength(55)]))
        });
        this.dashboard = this.dialogData.dashboard;
    }

    /**
     * Close modal instance
     */
    closeDialog() {
        this.dialogRef.close(false);
    }

    validateInput(): void {
        if (!this.dashboardForm.valid) {
            this.message = FormValidationService.getMessages(this.dashboardForm.controls);
        }
    }

    submitDashboard(event) {
        if (this.dashboardForm.valid) {
            this.dashboard.name = this.dashboardForm.get('name').value;

            this.submitEvent.emit(
                this.dashboard
            );
        }
    }
}
