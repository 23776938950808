import {
    AfterViewInit,
    Component,
    ComponentRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import {TemplateActionsController} from '~/src/app/modules/posts/template-actions';

@Component({
    selector: 'smd-cube-parent',
    templateUrl: './cube-parent.component.html',
    styleUrls: ['./cube-parent.component.scss'],
    providers: [
        TemplateActionsController
    ]
})
export class CubeParentComponent implements OnInit, OnDestroy, AfterViewInit {

    show = '';

    @Input('filters') filters: any = {};

    @Input() elementRef: ComponentRef<any>;

    // ez majd jön attól függően hogy hol hívódik meg

    @Input('elemFilter') elemFilter: string;

    @Input('inputElementRef') inputElementRef: any;


    templates: any = [];
    page = 0;
    limit = 15;
    isLoading = true;

    constructor(
        private templateService: TemplateActionsController,
        @Inject(ViewContainerRef) public viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.open();
        this.getItems();
    }

    getItems() {

        this.templateService.filters = {
            ...this.templateService.filters,
            ...this.filters
        };

        this.templateService.filters['offset'] = this.limit * this.page;
        this.templateService.filters['limit'] = this.limit;

        this.templateService.getItems(
            response => {
                this.templates = this.templates.concat(response.templates);
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            }
        );
    }

    get resultSetIsEmpty() {
        if (this.isLoading) {
            return false;
        }
        return !this.templates.filter((item) => {
            return item[this.elemFilter];
        }).length;
    }

    // When scroll down the screen
    onScroll() {
        this.page = this.page + 1;
        this.getItems();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
    }

    // open modal
    open(): void {
        this.show = 'show';
    }

    // close modal
    close(): void {
        this.show = '';
    }

    selectItem(data) {
        this.inputElementRef.setValue(data);
        this.close();
    }


}
