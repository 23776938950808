import {Component, ElementRef, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import {LanguageService} from '~/src/app/services/language.service';
import {Subscription} from 'rxjs';
import {MenuItem} from '~/src/app/components/main-menu/menu-item.model';

declare const $: any;

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnDestroy {
    private subs: Subscription[] = [];

    menuItems: MenuItem[] = [
        new MenuItem({
            resource: 'post.post.posts',
            label: LanguageService.getLine('menu.items.post.schedule'),
            link: '/post-schedule',
            icon: 'mdi mdi-av-timer'
        }),
        new MenuItem({
            resource: 'post.post.posts',
            label: LanguageService.getLine('menu.items.post.list'),
            icon: 'mdi mdi-file-document-box',
            subItems: [
                {
                    link: '/post-list/all',
                    label: LanguageService.getLine('menu.items.post.all')
                },
                {
                    link: '/post-list/drafts',
                    label: LanguageService.getLine('menu.items.post.drafts')
                },
                {
                    link: '/post-list/awaiting-approval',
                    label: LanguageService.getLine('menu.items.post.waitingforapproval')
                },
                {
                    link: '/post-list/approval-declined',
                    label: LanguageService.getLine('menu.items.post.notapproved')
                },
                {
                    link: '/post-list/approved',
                    label: LanguageService.getLine('menu.items.post.approved')
                },
                {
                    link: '/post-list/published',
                    label: LanguageService.getLine('menu.items.post.posted')
                },
                {
                    link: '/post-list/partially-failed',
                    label: LanguageService.getLine('menu.items.post.partiallyfailed')
                },
                {
                    link: '/post-list/failed-to-post',
                    label: LanguageService.getLine('menu.items.post.failedtopost')
                }
            ]
        }),
        new MenuItem({
            icon: 'mdi mdi-file-multiple',
            label: LanguageService.getLine('menu.items.resources'),
            resource: [
                'post.template.templates',
                'organization.media.mediaList',
                'rss.feed.list'
            ],
            subItems: [
                {
                    resource: 'post.template.templates',
                    label: LanguageService.getLine('menu.items.post.manager.template'),
                    subItems: [
                        {
                            link: '/template-manager',
                            label: 'All templates'
                        },
                        {
                            link: '/template-manager/my-templates',
                            label: 'My templates'
                        },
                        {
                            link: '/template-manager/branded-templates',
                            label: LanguageService.getLine('menu.items.post.manager.template.branded')
                        },
                        {
                            partnerPermission: PartnerPermissions.GenericTemplate,
                            link: '/template-manager/generic-templates',
                            label: LanguageService.getLine('menu.items.post.manager.template.generic')
                        }
                    ]
                },
                {
                    resource: 'organization.media.mediaList',
                    link: '/documents',
                    label: LanguageService.getLine('menu.items.documents')
                },
                {
                    partnerPermission: PartnerPermissions.RssModule,
                    resource: ['rss.feed.list', 'rss.group.list'],
                    link: '/rss-feeds',
                    label: LanguageService.getLine('menu.items.post.manager.rss')
                }
            ]
        }),
        new MenuItem({
            resource: ['post.autoFeed.settings.list', 'post.autoFeed.postTimes'],
            connectionType: 'and', // both resources must be available
            link: '/autofeed',
            partnerPermission: PartnerPermissions.AutoFeedModule,
            icon: 'mdi mdi-checkbox-multiple-blank-circle',
            label: LanguageService.getLine('menu.items.autofeed')
        }),
        new MenuItem({
            resource: [
                'auth.user.users',
                'organization.role.list',
                'organization.organization.organizationTree',
                'post.category.create',
                'hashtag.tag.bulkCreate',
                'rss.feed.bulkCreate'
            ],
            icon: 'mdi mdi-settings',
            label: LanguageService.getLine('menu.items.settings'),
            subItems: [
                {
                    resource: ['auth.user.users', 'organization.role.list'],
                    link: '/access-management',
                    label: LanguageService.getLine('menu.items.settings.accessManagement')
                },
                {
                    resource: 'organization.organization.organizationTree',
                    link: '/organisations',
                    label: LanguageService.getLine('menu.items.settings.organizations')
                },
                {
                    resource: 'post.category.create',
                    link: '/categories',
                    label: LanguageService.getLine('menu.items.settings.categories')
                },
                {
                    resource: 'hashtag.tag.bulkCreate',
                    link: '/hashtag-manager',
                    label: LanguageService.getLine('menu.items.settings.hashtagManager')
                },
                {
                    partnerPermission: PartnerPermissions.RssModule,
                    resource: 'rss.feed.bulkCreate',
                    link: '/rss-manager',
                    label: LanguageService.getLine('menu.items.settings.rssManager')
                }
            ]
        }),
        new MenuItem({
            partnerPermission: PartnerPermissions.AnalyticsModule,
            resource: 'analytics.dashboard.dashboards',
            link: '/analytics',
            icon: 'mdi mdi-elevation-decline',
            label: LanguageService.getLine('menu.items.analytics')
        }),
        new MenuItem({
            resource: 'background.administration.access',
            icon: 'mdi mdi-settings',
            label: LanguageService.getLine('menu.items.administration'),
            subItems: [
                {link: '/partners', label: LanguageService.getLine('menu.items.administration.partners')},
                {link: '/packages', label: LanguageService.getLine('menu.items.administration.packages')},
                {link: '/default-roles', label: LanguageService.getLine('menu.items.administration.defaultRoles')},
                {link: '/admin-template-list', label: LanguageService.getLine('menu.items.administration.adminTemplates')},
                {link: '/admin-media-list', label: LanguageService.getLine('menu.items.administration.adminMediaList')},
                {link: '/logging', label: LanguageService.getLine('menu.items.administration.logging')},
            ]
        })
    ];

    constructor(
        private router: Router,
        private el: ElementRef<MainMenuComponent>
    ) {
        this.subs.push(
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.resetMenuItemsState(this.menuItems);
                    this.initActiveMenuItem(event.url);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    /**
     * Menu item click event
     * @param {MouseEvent} event
     */
    menuItemClick(event: MouseEvent): void {
        const $element = $(event.target);

        if ($element) {
            this.closeAllCollapse($element.parents('.collapse').toArray() as HTMLElement[]);
        }
    }

    /**
     * Close all collapse element
     * @param {HTMLElement[]} exceptions
     */
    private closeAllCollapse(exceptions: HTMLElement[] = []): void {
        $(this.el.nativeElement).find('.collapse').each((index, el) => {
            if (!exceptions.find(_el => _el.isEqualNode(el))) {
                $(el).collapse('hide');
            }
        });
    }

    /**
     * Set active menu tree state
     * @param {MenuItem} menuItem
     */
    private setActiveMenuTree(menuItem: MenuItem) {
        menuItem.isActive = true;

        if (menuItem.parent) {
            this.setActiveMenuTree(menuItem.parent);
        }
    }

    /**
     * Reset all menu item state
     * @param {MenuItem[]} menuItems
     */
    private resetMenuItemsState(menuItems: MenuItem[]): void {
        for (const menuItem of menuItems) {
            menuItem.isActive = false;

            if (menuItem.subItems.length) {
                this.resetMenuItemsState(menuItem.subItems);
            }
        }
    }

    /**
     * Set active menu item by current URL
     * @param {string} currentUrl
     */
    private initActiveMenuItem(currentUrl: string): void {
        if (currentUrl || typeof currentUrl === 'string') {
            const menuItem = this.findMenuItemByUrl(this.menuItems, currentUrl);

            if (menuItem) {
                this.setActiveMenuTree(menuItem);
            }
        }
    }

    /**
     * Find menu item by URL
     * @param {MenuItem[]} menuItems
     * @param {string} url
     * @return {MenuItem}
     */
    private findMenuItemByUrl(menuItems: MenuItem[], url: string): MenuItem {
        let _menuItem = null;

        for (const menuItem of menuItems) {
            if (menuItem.link && url.includes(menuItem.link)) {
                _menuItem = menuItem;
            } else if (menuItem.subItems.length && !_menuItem) {
                _menuItem = this.findMenuItemByUrl(menuItem.subItems, url);
            }
        }

        return _menuItem;
    }
}
