import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import Utils from '~/src/app/core/utils';
import {HashtagModel} from '~/src/app/modules/hashtag/hashtag.model';
import {HashtagService} from '~/src/app/modules/hashtag/hashtag.service';
import {FormControl} from '@angular/forms';
import {HashtagUtils} from '~/src/app/modules/hashtag/hashtag.utils';

@Component({
    selector: 'smd-hashtag-select',
    templateUrl: './hashtag-select.component.html',
    styleUrls: ['./hashtag-select.component.scss']
})
export class HashtagSelectComponent implements OnInit {
    @Input() selectControl: FormControl;
    @Input() errorMessage: string;
    @Input() createHashtag = true;
    @Input() multiple = false;
    @Input() bindValue = 'tag';
    isAdmin = false;
    hashtags: HashtagModel[] = [];
    hashtagGetPending = false;

    constructor(
        private hashtagService: HashtagService,
        public language: LanguageService
    ) {}

    ngOnInit(): void {
        this.getHashtags();
    }

    getHashtags() {
        this.hashtagGetPending = true;
        this.hashtagService.getList().then(response => {
            this.hashtags = Utils.lodash.orderBy<HashtagModel>(
                response.tags,
                [hashtag => hashtag[this.bindValue].toLowerCase()]
            );
            this.hashtagGetPending = false;
        }).catch(() => {
            this.hashtagGetPending = false;
        });
    }

    /**
     * @param {string} tag
     * @return {string}
     */
    addTagFn(tag: string) {

        if (HashtagUtils.hasHashtagSign(tag)) {
            return tag;
        }

        return HashtagUtils.addHashtagSign(tag).replace(/ /g, '');
    }

}
