import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmModalOptions} from '~/src/app/modules/social-media-post/open-modal.interfaces';

interface DialogData {
    title?: string;
    message: string;
}

@Component({
    selector: 'smd-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

    yesButtonText = '';

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmModalOptions,
    ) {
        if (this.data['yesButtonText']) {
            this.yesButtonText = this.data['yesButtonText'];
        }
    }

    ngOnInit() {
    }

}
