import {Component, ViewChild} from '@angular/core';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {LanguageService} from '~/src/app/services/language.service';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {DefaultRoleService} from '~/src/app/modules/administration/default-roles/default-role.service';
import {Helpers} from '~/src/app/services/helpers';
import Utils from '~/src/app/core/utils';
import {
    SingleActionClick,
    TableBodyConfig,
    TableHeadConfig
} from '~/src/app/core/components/collection-list/collection-list.interfaces';
import {DefaultRole} from '~/src/app/modules/administration/default-roles/default-roles.interfaces';
import {ApiActionConfig, ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {DialogConfirmComponent} from '~/src/app/components/dialog-confirm/dialog-confirm.component';
import {ConfirmModalOptions} from '~/src/app/modules/social-media-post/open-modal.interfaces';
import {CollectionListComponent} from '~/src/app/core/components/collection-list/collection-list.component';
import {DefaultRoleManagerComponent} from '~/src/app/modules/administration/default-roles/default-role-manager/default-role-manager.component';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'smd-default-roles',
    templateUrl: './default-roles.component.html',
    styleUrls: ['./default-roles.component.scss'],
    providers: [
        FormHelpersService,
        DefaultRoleService,
        ComponentHelpers
    ]
})
export class DefaultRolesComponent {
    @ViewChild(CollectionListComponent, {static: true}) collectionList: CollectionListComponent;
    readonly defaultOrder = 'name-asc';
    filterNames = {
        Keyword: 'keyword',
        OrderBy: 'order',
        IsAdmin: 'isAdmin'
    };
    filtersFormGroup = new FormGroup({
        [this.filterNames.Keyword]: new FormControl(null),
        [this.filterNames.OrderBy]: new FormControl(null),
        [this.filterNames.IsAdmin]: new FormControl(false),
    });
    paginationController: PaginationController = new PaginationController();
    defaultRoles: DefaultRole[] = [];
    selectedRoles = [];
    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: 'defaultRole.name',
        },
        {
            nameKey: 'defaultRole.description',
            onlyMobile: true,
            onlyDesktop: true
        },
        {
            nameKey: 'core.crud.status',
            onlyMobile: true,
            onlyDesktop: true
        },
        {
            nameKey: 'defaultRole.isAdmin',
            onlyMobile: true,
            onlyDesktop: true
        },
        {
            nameKey: 'core.crud.actions',
            onlyDesktop: true
        }
    ];
    tableBodyConfig: TableBodyConfig[] = [
        {
            bindValue: 'name'
        },
        {
            bindValue: 'view.description',
            bindTooltipValue: 'description',
            onlyDesktop: true,
        },
        {
            bindValue: 'view.statusMessage',
            onlyDesktop: true,
        },
        {
            bindValue: 'admin',
            onlyDesktop: true,
        },
        {
            staticView: 'itemActions',
            onlyDesktop: true
        }
    ];

    constructor(
        public formHelper: FormHelpersService,
        public languageService: LanguageService,
        public defaultRoleService: DefaultRoleService,
        private openModal: OpenModalService,
        private componentHelpers: ComponentHelpers,
    ) {
        this.initialize();
    }

    /**
     * Default role action
     * @param {SingleActionClick<DefaultRole>} event
     */
    singleAction(event: SingleActionClick<DefaultRole>) {
        if (event.type === 'edit') {
            this.openModal.editDefaultRole(DefaultRoleManagerComponent, event.item, {
                data: {
                    afterSuccessEdit: () => {
                        this.getDefaultRoles();
                    }
                }
            });
        }

        if (event.type === 'delete') {
            this.deleteDefaultRoles(event.item);
        }
    }

    /**
     * Default roles multi action
     * @param {MouseEvent} event
     * @param {"delete"} type
     */
    multiAction(event: MouseEvent, type: 'delete') {
        event.preventDefault();
        event.stopPropagation();

        if (this.collectionList.dataTableHelpers.getSelectedItems().length > 0) {
            if (type === 'delete') {
                this.deleteDefaultRoles(this.collectionList.dataTableHelpers.getSelectedItems());
            }
        }
    }

    /**
     * Open create default role modal
     * @param {MouseEvent} event
     */
    createDefaultRole(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.openModal.createDefaultRole(DefaultRoleManagerComponent, {
            data: {
                afterSuccessCreate: () => {
                    this.getDefaultRoles();
                }
            }
        });
    }

    /**
     * Reset filters
     * @param {MouseEvent} event
     */
    resetFilters(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.formHelper.resetFormGroup(this.filtersFormGroup);
    }

    /**
     * Get default roles
     * @return {Promise<DefaultRolesResponse>}
     */
    private getDefaultRoles() {
        const filters = {
            ...this.paginationController.requestParams,
            [this.filterNames.OrderBy]: Helpers.getOrderFormat(this.defaultOrder)
        };

        Utils.lodash.forEach(this.filtersFormGroup.getRawValue(), (value, filterName) => {
            if (!!value) {

                if (filterName === this.filterNames.OrderBy) {
                    value = Helpers.getOrderFormat(value);
                }

                if (filterName === this.filterNames.IsAdmin) {
                    value = value ? 'true' : null;
                }

                filters[filterName] = value;
            }
        });

        const loader = this.openModal.loader(DialogLoaderComponent);
        return this.defaultRoleService.getDefaultRoles(filters).then((response: any) => {

            if (Utils.lodash.has(response, 'count')) {
                this.paginationController.paginationOptions.length = response.count;
            }

            if (this.collectionList) {
                this.collectionList?.clearSelection();
            }

            this.selectedRoles = [];
            loader.close();
            return response;
        }).catch((error) => {
            loader.close();
            return error;
        });
    }

    /**
     * Delete default role(s)
     * @param {DefaultRole | DefaultRole[]} items
     */
    private deleteDefaultRoles(items: DefaultRole | DefaultRole[]) {
        const successApiActionConfig: ApiActionConfig = {messageKey: 'defaultRole.multiAction.delete.success'};
        const errorApiActionConfig: ApiActionConfig = {messageKey: 'defaultRole.multiAction.delete.error'};
        const confirmMessages: ConfirmModalOptions = {
            message: this.languageService.getLine('defaultRole.multiAction.delete.confirm'),
            yesButtonText: this.languageService.getLine('defaultRole.multiAction.delete.confirm.button')
        };

        if (!Array.isArray(items)) {
            items = [items] as DefaultRole[];

            successApiActionConfig.messageKey = 'defaultRole.singleAction.delete.success';
            errorApiActionConfig.messageKey = 'defaultRole.singleAction.delete.error';
            confirmMessages.message = this.languageService.getLine('defaultRole.singleAction.delete.confirm');
            confirmMessages.yesButtonText = this.languageService.getLine('defaultRole.singleAction.delete.confirm.button');
        }

        this.openModal.confirmModal(DialogConfirmComponent, confirmMessages).afterClosed().subscribe((doIt) => {
            if (!!doIt) {
                const roleIDs: string[] = (items as DefaultRole[]).map(item => item.defaultRoleID);
                const loader = this.openModal.loader(DialogLoaderComponent);

                this.defaultRoleService.deleteDefaultRoles(roleIDs).then(response => {
                    loader.afterClosed().subscribe(() => {
                        this.componentHelpers.apiAction('success', response, successApiActionConfig).then(() => this.getDefaultRoles());
                    });
                    loader.close();
                    this.collectionList.clearSelection();
                }).catch(error => {
                    loader.afterClosed().subscribe(() => {
                        this.componentHelpers.apiAction('failed', error, errorApiActionConfig);
                    });
                    loader.close();
                });
            }
        });
    }

    /**
     * Initialize component
     */
    private initialize() {
        this.defaultRoleService.defaultRoles.subscribe(defaultRoles => {
            this.defaultRoles = defaultRoles;
        });

        for (const controlName in this.filtersFormGroup.controls) {
            const control: AbstractControl = this.filtersFormGroup.get(controlName);
            const _debounceTime = controlName === this.filterNames.Keyword ? 1000 : 350;

            control.valueChanges.pipe(
                debounceTime(_debounceTime)
            ).subscribe(() => {
                this.paginationController.paginationOptions.pageIndex = 0;
                this.getDefaultRoles();
            });

        }

        this.paginationController.onPaginationChange.subscribe(() => this.getDefaultRoles());

        this.getDefaultRoles();
    }
}
