import AbstractModel from '~/src/app/core/model.abstract';
import {IRSSGroup} from '~/src/app/modules/rss/types/rss-group.interface';
import {IRSSFeed} from '~/src/app/modules/rss/types/rss-feed.interface';
import {RssFeedModel} from '~/src/app/modules/rss/models/rss-feed.model';
import {OrganizationModel} from '~/src/app/modules/users/organizations/organization.model';

export class RssGroupModel extends AbstractModel<IRSSGroup> implements IRSSGroup {
    createDate: string;
    groupID: number;
    name: string;
    feeds: RssFeedModel[] = [];
    organizationID: number[];
    organizations: OrganizationModel[];
    userID?: number;
    orgOfUser?: number;
    userName?: string;
    nameOfUserOrg?: string;

    constructor(json: IRSSGroup, feeds: IRSSFeed[] = [], organizations: OrganizationModel[] = []) {
        super(json);
        this.parse(json);
        this.feeds = feeds.map(feed => new RssFeedModel(feed, this));

        if (this.organizationID) {
            this.organizationID = (Array.isArray(this.organizationID) ? this.organizationID : [this.organizationID]).map(id => Number(id));
        } else {
            this.organizationID = [];
        }

        this.organizations = organizations || [];
    }
}
