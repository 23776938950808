import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {SmdFileInterface} from '~/src/app/services/file.class';

const openSubject = new Subject<any>();
const fileSubject = new Subject<any>();
const openBrowserSubject = new Subject<any>();

@Injectable({ providedIn: 'root' })
export default class EditorControllerService {
    public static open(data) {
        openSubject.next(data);
    }

    public static openEvent(): Observable<any> {
        return openSubject.asObservable();
    }

    public static openBrowser(toggle: boolean) {
        openBrowserSubject.next(toggle);
    }

    public static openBrowserEvent(): Observable<any> {
        return openBrowserSubject.asObservable();
    }

    public static selectFile(file: SmdFileInterface) {
        fileSubject.next(file);
    }

    public static selectFileEvent(): Observable<any> {
        return fileSubject.asObservable();
    }
}
