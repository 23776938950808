import {Injectable} from '~/node_modules/@angular/core';
import {BackendService} from '~/src/app/core/backend.service';
import {Resource, ResourcesResponse, ServerTimeResponse} from '~/src/app/core/export-resource';
import {BehaviorSubject} from '~/node_modules/rxjs';
import Utils from '~/src/app/core/utils';
import {ResourceAbstract} from '~/src/app/core/resource.abstract';

@Injectable({providedIn: 'root'})
export class ExportResourceService extends ResourceAbstract {

    // Main resource api URl
    protected mainApiUrl = '/export';

    // Sub resource URLs
    private serverTimeUrl = this.getFullApiUrl('/server-time');
    private resourcesUrl = this.getFullApiUrl('/resources.json');

    // Resource collection
    resourceCollection: BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>([]);

    // Resource pending variables
    resourcePending = false;

    constructor(
        protected backendService: BackendService
    ) {
        super();
    }

    /**
     * Resource hydrator
     * @param {Resource[]} resources
     * @param {boolean} needOrder
     * @returns {Resource[]}
     */
    static resourceHydrator(resources: Resource[], needOrder = true): Resource[] {
        // Hydrate resources
        const hydratedResources = resources.map(resource => {
            // Convert moduleID to number from string
            resource.moduleID = parseInt(resource.moduleID as string) || resource.moduleID;

            return resource;
        });

        // Return with hydrated resources when no need order
        if (!needOrder) {
            return hydratedResources;
        }

        // Order resources
        return Utils.orderBy<Resource>(
            hydratedResources,
            ['name', 'description']
        );
    }

    /**
     * Get resources observable item
     * @returns {Observable<Resource[]>}
     */
    get resources() {
        return this.resourceCollection.asObservable();
    }

    /**
     * Get server time from backend
     * @returns {Promise<ServerTimeResponse>}
     */
    async getServerTime(): Promise<ServerTimeResponse> {
        // Get server time from backend
        const result = await this.backendService.get<ServerTimeResponse>(
            this.serverTimeUrl
        );

        // Return with response
        return result;
    }

    /**
     * Get auth resources
     * @returns {Promise<Resource[]>}
     */
    async getResources(): Promise<ResourcesResponse> {
        // Resources pending set true
        this.resourcePending = true;

        // Get resources from backend
        const result = await this.backendService.get<ResourcesResponse>(
            this.resourcesUrl
        );

        // Hydrate resources
        const resources = ExportResourceService.resourceHydrator(result.resources);

        // Update resources collection
        this.resourceCollection.next(resources);

        // Add hydrated resources to response
        result.resources = resources;

        // Resources pending set false
        this.resourcePending = false;

        // Return with resources response
        return result;
    }
}
