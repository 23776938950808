import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ISocialSiteChooserDialogData} from '~/src/app/shared/components/social-site-chooser-modal/social-site-chooser-modal';
import {
    SocialSiteInterface,
    SocialSiteSelectComponent
} from '~/src/app/components/social-site-select/social-site-select.component';
import {MatSelectChange} from '@angular/material/select';
import {ClickDecorator} from '~/src/app/core/decorators';

@Component({
    selector: 'smd-social-site-chooser-modal',
    templateUrl: './social-site-chooser-modal.component.html',
    styleUrls: ['./social-site-chooser-modal.component.scss']
})
export class SocialSiteChooserModalComponent implements OnInit {
    @ViewChild(SocialSiteSelectComponent, {read: SocialSiteSelectComponent}) socialSiteSelect: SocialSiteSelectComponent;
    selectedSocialSites: SocialSiteInterface[] | SocialSiteInterface = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: ISocialSiteChooserDialogData,
        private dialogRef: MatDialogRef<SocialSiteChooserModalComponent>
    ) {}

    ngOnInit(): void {}

    @ClickDecorator()
    chooseSites(event: MouseEvent): void {
        this.dialogRef.afterClosed().subscribe(() => this.dialogData?.afterChoose(this.selectedSocialSites));
        this.dialogRef.close();
    }

    selectionChange(event: MatSelectChange) {
        const value = Array.isArray(event?.value) ? event?.value : [event?.value];
        this.selectedSocialSites = this.socialSiteSelect.socialSites.filter(site => value.includes(site.siteID));

        if (!this.dialogData.multiple) {
            this.selectedSocialSites = this.selectedSocialSites.length ? this.selectedSocialSites[0] : null;
        }
    }
}
