import { ResourceAbstract } from "~/src/app/core/resource.abstract";
import { Injectable } from "~/node_modules/@angular/core";
import {
    Role,
    RolesFilters,
    RolesResponse,
} from "~/src/app/modules/users/roles-resource";
import { BackendService } from "~/src/app/core/backend.service";
import { BehaviorSubject } from "~/node_modules/rxjs";
import { Organization } from "~/src/app/modules/users/users-resource";
import Utils from "~/src/app/core/utils";
import { Helpers } from "~/src/app/services/helpers";

@Injectable({ providedIn: "root" })
export class RolesResourceService extends ResourceAbstract {
    // main API URL
    protected mainApiUrl = "/roles";

    // role collection
    private roleCollection: BehaviorSubject<Role[]> = new BehaviorSubject<
        Role[]
    >([]);

    constructor(private backendService: BackendService) {
        super();
    }

    /**
     * Hydrate roles
     * @param {Role[]} roles
     * @returns {Role[]}
     */
    static roleHydrator(roles: Role[]): Role[] {
        // get organizations from logged user
        const organizations: Organization[] = JSON.parse(
            localStorage.getItem("loggedUser")
        ).organizations;

        roles = roles.map((role) => {
            // get organization
            role.organization = organizations.find(
                (organization) =>
                    Number(organization.organizationID) ===
                    Number(role.organizationID)
            );

            // convert string boolean to real boolean
            role.admin = Helpers.stringToBoolean(role.admin as string);
            if (role.isDefault) {
                const splitRoleID = role.roleID.split(".");
                role.defaultRoleID = splitRoleID[1];
            }

            return role;
        });

        // order roles
        return Utils.lodash.orderBy(roles, ["organization.name"]);
    }

    /**
     * Get roles
     * @returns {Observable<Role[]>}
     */
    get roles() {
        return this.roleCollection.asObservable();
    }

    /**
     * Get roles from server
     * @param {RolesFilters} additionalFilters
     * @returns {Promise<{roles: Role[]}>}
     */
    async getRoles(
        additionalFilters?: RolesFilters
    ): Promise<{ roles: Role[] }> {
        // get roles from server
        const response = await this.backendService.get<RolesResponse>(
            this.mainApiUrl,
            additionalFilters
        );

        // all role
        const allRole: Role[] = [];

        // get all role from object
        for (const roleArray of Object.values(response.roles)) {
            allRole.push(...roleArray);
        }

        // hydrate and order roles
        const roles = RolesResourceService.roleHydrator(allRole);

        // refresh role collection with hydrated roles
        this.roleCollection.next(roles);

        // refresh response with hydrated roles
        response.roles = roles as Role[];

        // return with modified response
        return response as { roles: Role[] };
    }
}
