import {Component, EventEmitter} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {LanguageService} from '~/src/app/services/language.service';

@Component({
    selector: 'smd-create-dashboard-modal',
    templateUrl: './create-dashboard-modal.component.html',
    styleUrls: ['./create-dashboard-modal.component.scss']
})
export class CreateDashboardModalComponent {
    submitEvent = new EventEmitter();
    newDashboardForm: FormGroup;
    message: object;

    constructor(
        public dialogRef: MatDialogRef<CreateDashboardModalComponent>,
        public languageService: LanguageService
    ) {
        this.newDashboardForm = new FormGroup({
            name: new FormControl('', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(55)])),
            description: new FormControl('', Validators.compose([Validators.maxLength(55)]))
        });
    }

    /**
     * Close modal instance
     */
    closeDialog() {
        this.dialogRef.close();
    }

    validateInput(): void {
        if (!this.newDashboardForm.valid) {
            this.message = FormValidationService.getMessages(this.newDashboardForm.controls);
        }
    }

    submitNewDashboard(event) {
        if (this.newDashboardForm.valid) {
            this.submitEvent.emit({
                name: this.newDashboardForm.get('name').value,
                data: '{"widgets": []}'
            });
        }
    }
}
