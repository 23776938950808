import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidationService} from '../../../services/form.validation.service';
import {ValidatorEqual} from '../../../directives/validator-equal.directive';
import {AuthService} from '../auth.service';
import {NotifyService} from '../../../services/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PreloaderComponent} from '../../../components/preloader/preloader.component';
import {LanguageService} from '../../../services/language.service';

@Component({
    selector: 'smd-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

    @ViewChild(PreloaderComponent, {static: true}) preloader: PreloaderComponent;

    passwordResetForm = new FormGroup({
        newPassword: new FormControl('', [
            Validators.required
        ]),
        newPasswordConfirm: new FormControl('', [
            Validators.required,
            ValidatorEqual('newPasswordConfirm', 'newPassword')
        ])
    });

    passwordResetMessage: object = {
        newPassword: '',
        newPasswordConfirm: ''
    };

    sub;

    isActivateUser = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        /**
         * Hide preloader
         */
        // this.preloader.hide(AvailableLoaders.PASSWORD_RESET);
        this.sub = this.activatedRoute.data.subscribe((response) => {
            if (response["activateUser"]) {
                this.isActivateUser = true;
            }
        });
    }

    getHashCode() {
        const hashArray = this.router.url.split('/');
        return hashArray[hashArray.length - 1].trim();
    }

    onPasswordReset(event) {
        event.preventDefault();

        const hashCode = this.getHashCode();

        this.passwordResetMessage = {};

        if (this.passwordResetForm.valid) {
            this.auth.passwordReset(hashCode, this.passwordResetForm.controls.newPassword.value, this.isActivateUser).subscribe(
                response => {
                    NotifyService.success(this.isActivateUser ? LanguageService.getLine('activate.title') : LanguageService.getLine('reset.password'), response['data']['message']);

                    setTimeout(() => {
                        this.router.navigateByUrl('/login');
                    }, 6000);
                },
                response => {
                    NotifyService.error(this.isActivateUser ? LanguageService.getLine('activate.title') : LanguageService.getLine('reset.password'), FormValidationService.readError(response)["message"]);
                }
            );
        } else {
            this.passwordResetMessage = FormValidationService.getMessages(this.passwordResetForm.controls);
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    get languageService() {
        return LanguageService;
    }
}
