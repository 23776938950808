import {Component, Inject} from '@angular/core';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {Partner} from '~/src/app/shared/types/partners.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PartnerChooserDialogData} from '~/src/app/modules/administration/partners/partner-chooser/partner-chooser.interfaces';
import Utils from '~/src/app/core/utils';
import {LanguageService} from '~/src/app/services/language.service';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import {SmdFileInterface} from '~/src/app/services/file.class';
import {CoreConfig} from '~/src/app/core/core.config';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {ItemSeparationChange} from '~/src/app/core/components/item-separation/item-separation.interfaces';

@Component({
    selector: 'smd-partner-chooser',
    templateUrl: './partner-chooser.component.html',
    styleUrls: ['./partner-chooser.component.scss'],
    providers: [
        ComponentHelpers
    ]
})
export class PartnerChooserComponent {
    enabledPartners: Partner[] = [];
    disabledPartners: Partner[] = [];
    forMedia = !!this.dialogData.medias;
    forTemplate = !!this.dialogData.templates;
    isBranded = false;
    isGeneric = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PartnerChooserDialogData,
        public dialogRef: MatDialogRef<PartnerChooserComponent>,
        public partnerService: PartnersService,
        public language: LanguageService,
        private componentHelpers: ComponentHelpers,
        private openModal: OpenModalService
    ) {
        const collection: PostTemplateInterface[] | SmdFileInterface[] = this.dialogData.templates || this.dialogData.medias;
        const systemType = collection[0].systemType;
        const allowedPartnerIDs: number[] = [];
        const disAllowedPartnerIDs: number[] = [];

        for (const item of collection) {
            allowedPartnerIDs.push(...item.partnerIDs);
            disAllowedPartnerIDs.push(...item.disallowedPartnerIDs);
        }

        this.partnerService.getPartners().then((response: any) => {
            const partners: Partner[] = Utils.lodash.orderBy(response.partners, ['name']) || [];

            if (systemType === CoreConfig.getSystemTypes().Generic) {

                this.isGeneric = true;
                this.enabledPartners = partners.filter(partner => disAllowedPartnerIDs.indexOf(partner.partnerID) === -1);
                this.disabledPartners = partners.filter(partner => disAllowedPartnerIDs.indexOf(partner.partnerID) > -1);

            } else if (systemType === CoreConfig.getSystemTypes().Branded) {

                this.isBranded = true;
                this.enabledPartners = partners.filter(partner => allowedPartnerIDs.indexOf(partner.partnerID) > -1);
                this.disabledPartners = partners.filter(partner => allowedPartnerIDs.indexOf(partner.partnerID) === -1);

            }

        });
    }

    /**
     * Save modifies to templates/medias
     * @param {MouseEvent} event
     */
    save(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const loader = this.openModal.loader(DialogLoaderComponent);
        const successAction = (response, messageKey: string) => {
            loader.afterClosed().subscribe(() => {
                this.componentHelpers.apiAction('success', response, {messageKey: messageKey}).then(() => {

                    this.dialogRef.afterClosed().subscribe(() => {
                        if (!!this.dialogData.afterSuccessSave) {
                            this.dialogData.afterSuccessSave(response);
                        }
                    });

                    this.dialogRef.close();
                });
            });

            loader.close();
        };
        const failedAction = (error, messageKey: string) => {
            loader.afterClosed().subscribe(() => {
                this.componentHelpers.apiAction('failed', error, {messageKey: messageKey});
            });

            loader.close();
        };

        const partnerIDs: number[] = this.isGeneric
            ? this.disabledPartners.map(partner => partner.partnerID)
            : this.enabledPartners.map(partner => partner.partnerID);

        const itemIDs: number[] = this.forTemplate
            ? this.dialogData.templates.map(template => template.templateID)
            : this.dialogData.medias.map(media => media.mediaID);

        if (this.forTemplate) {
            this.partnerService.setPartnersToTemplates(itemIDs, partnerIDs)
            .then(response => successAction(response, 'templateList.setPartners.success'))
            .catch(error => failedAction(error, 'templateList.setPartners.error'));
        }

        if (this.forMedia) {
            this.partnerService.setPartnersToMedias(itemIDs, partnerIDs)
            .then(response => successAction(response, 'mediaList.setPartners.success'))
            .catch(error => failedAction(error, 'mediaList.setPartners.error'));
        }
    }

    /**
     * Partner separation change
     * @param event
     */
    separationChange(event: ItemSeparationChange) {
        this.enabledPartners = event.enabledItems;
        this.disabledPartners = event.disabledItems;
    }
}
