import {UsersController} from '../users/users/users.component';
import {ActivityItemInterface, ActivityResponse, ActivityResponseRaw} from './activity.interfaces';
import {ActivityItemEntity} from './activityItem.entity';
import {Helpers} from '../../services/helpers';
import {LoggedUser} from '../../services/logged-user';
import {PostTemplateController} from '../posts/post-template/post-template.controller';
import {ACTIVITY_OPEN_POST_TYPES, ACTIVITY_OPEN_TEMPLATE_TYPES} from './activity.options';
import {SmdFile} from '../../services/file.class';
import {PostController} from '../posts/post.controller';
import Utils from '~/src/app/core/utils';
import {CarouselController, CarouselItem} from '~/src/app/modules/posts/carousel/carousel.component';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';

export interface Transformer<P, R> {
    initialize(response: P): Promise<R>;
}

export class ActivityTransformer implements Transformer<ActivityResponseRaw, ActivityResponse> {
    constructor(
        public usersController: UsersController,
        public postTemplateController: PostTemplateController,
        public postController: PostController,
        public carouselController: CarouselController,
    ) {
    }

    initialize(response: ActivityResponseRaw): Promise<ActivityResponse> {
        return new Promise<ActivityResponse>((resolve, reject) => {
            const result: ActivityItemEntity[] = [];

            const renderActivityItem = (activityRaw, user) => {
                const activity: ActivityItemInterface = Object.assign(activityRaw, {
                    username: user.username,
                    fullName: user.fullName,
                    userProfile: user.profileImageUrl
                });

                result.push(new ActivityItemEntity(activity));
            };

            let userIDs = response.activities.map(activity => Number(activity.userID));
            userIDs = Helpers.arrayUnique(userIDs);

            const myUser = LoggedUser.getUser(),
                myIDIndex = userIDs.indexOf(myUser.userID);

            if (myIDIndex > -1) {
                userIDs.splice(myIDIndex, 1);
            }

            if (userIDs.length > 0) {
                this.usersController.getUsersByIDs(userIDs).subscribe(({users}: { users: any[] }) => {
                    response.activities.forEach(activityRaw => {
                        users.forEach(user => {
                            if (activityRaw.userID === user.userID) {
                                renderActivityItem(activityRaw, user);
                            }
                        });

                        if (activityRaw.userID === myUser.userID) {
                            renderActivityItem(activityRaw, myUser);
                        }
                    });

                    this.resolveActivities(resolve, result);
                }, error => {
                    reject(error);
                });
            } else if (myIDIndex > -1) {
                response.activities.forEach(activityRaw => {
                    renderActivityItem(activityRaw, myUser);
                });

                this.resolveActivities(resolve, result);
            } else {
                this.resolveActivities(resolve, result);
            }
        });
    }

    resolveActivities(resolve, result: ActivityItemEntity[]) {
        let postIDs: number[] = [],
            postTemplateIDs: number[] = [],
            getPostsDone = false,
            getPostTemplatesDone = false;

        const getEntityDone = (type: 'post' | 'postTemplate', response) => {
            let templates = [],
                posts = [];

            if (type === 'post') {
                getPostsDone = true;

                posts = response.posts.map(post => {
                    post.medias = post.medias.map(media => new SmdFile(media));

                    return post;
                });

                posts = this.postController.postsProcess(posts);
            }

            if (type === 'postTemplate') {
                getPostTemplatesDone = true;

                templates = response.templates.map(template => {
                    template.medias = template.medias.map(media => new SmdFile(media));

                    template = Utils.initLineBreaksOnPostEntity(template);

                    return template;
                });
            }

            result.map(activity => {
                if (ACTIVITY_OPEN_TEMPLATE_TYPES.indexOf(activity.objectType) > -1) {
                    for (const template of templates) {
                        if (Number(activity.objectID) === template.templateID) {
                            activity.entity = template;
                        }
                    }
                }

                if (ACTIVITY_OPEN_POST_TYPES.indexOf(activity.objectType) > -1) {
                    for (const post of posts) {
                        if (Number(activity.objectID) === post.postID) {
                            activity.entity = post;
                        }
                    }
                }

                return activity;
            });

            if (getPostsDone && getPostTemplatesDone) {
                resolve({activities: result});
            }
        };

        for (const activity of result) {
            if (ACTIVITY_OPEN_POST_TYPES.indexOf(activity.objectType) > -1) {
                postIDs.push(activity.objectID);
            }

            if (ACTIVITY_OPEN_TEMPLATE_TYPES.indexOf(activity.objectType) > -1) {
                postTemplateIDs.push(activity.objectID);
            }
        }

        postIDs = Helpers.arrayUnique(postIDs);
        postTemplateIDs = Helpers.arrayUnique(postTemplateIDs);

        if (postTemplateIDs.length > 0) {
            this.getEntity('postTemplate', postTemplateIDs)
            .then(response => getEntityDone('postTemplate', response));
        } else {
            getPostTemplatesDone = true;
        }

        if (postIDs.length > 0) {
            this.getEntity('post', postIDs)
            .then(response => getEntityDone('post', response));
        } else {
            getPostsDone = true;
        }

        if (getPostsDone && getPostTemplatesDone) {
            resolve({activities: result});
        }
    }

    getEntity(type: 'post' | 'postTemplate', IDs: number[]) {
        if (type === 'postTemplate') {
            return this.postTemplateController.getItemsByIDs<{ templates: PostTemplateInterface[] }>('templateID', IDs).then(response => {
                const carouselMediaIDs: number[] = response.templates
                .filter(template => !!template.medias && (Utils.lodash.get(template, 'medias[0].type', '') === FILE_TYPES.CAROUSEL))
                .map(template => Number(template.medias[0].mediaID));

                if (!!carouselMediaIDs.length) {
                    return this.carouselController.getCarouselItemsByIDs(carouselMediaIDs).then(({carouselItems}) => {
                        response.templates = response.templates.map(template => {
                            template.medias = template.medias.map(media => {
                                const mediaCarouselItems = [];

                                carouselItems.map(carouselItem => {
                                    if (Number(media.mediaID) === Number(carouselItem.carouselID)) {
                                        mediaCarouselItems.push(new CarouselItem(carouselItem));
                                    }
                                });

                                media.carouselItems = mediaCarouselItems;

                                return media;
                            });

                            return template;
                        });

                        return response;
                    });
                }

                return Promise.resolve(response);
            });
        }

        if (type === 'post') {
            return this.postController.getItemsByIDs('postIDs', IDs);
        }
    }
}
