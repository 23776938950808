import AbstractModel from '~/src/app/core/model.abstract';
import {IMention} from '~/src/app/modules/mention/mention.interface';

export class MentionModel extends AbstractModel<IMention> implements IMention {
    id: string;
    link: string;
    name: string;
    image: string;

    constructor(props: IMention) {
        super(props);
        this.parse(props);
    }
}
