import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IRSSFeedForm, IRSSFeedFormData, IRSSFeedModalDialogData} from '~/src/app/modules/rss/types/rss-feed.interface';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '~/src/app/services/language.service';
import {ClickDecorator} from '~/src/app/core/decorators';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {RssFeedService} from '~/src/app/modules/rss/services/rss-feed.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {RssFeedModel} from '~/src/app/modules/rss/models/rss-feed.model';
import {url} from '@ng-validators/ng-validators';

@Component({
    selector: 'smd-rss-feed-modal-form',
    templateUrl: './rss-feed-modal-form.component.html',
    styleUrls: ['./rss-feed-modal-form.component.scss'],
    providers: [
        ComponentHelpers
    ]
})
export class RssFeedModalFormComponent implements OnInit {
    @ViewChild('newFormBtn', {read: ElementRef}) newFormBtn: ElementRef<HTMLButtonElement>;

    // is edit mode
    isEditMode = false;

    controlNames: {[key: string]: keyof IRSSFeedFormData} = {
        Name: 'name',
        FeedUrl: 'feedUrl',
        Organization: 'organizationID'
    };

    feedForms: IRSSFeedForm[] = [this.createFormGroup(this.data && this.data.feed ? this.data.feed : null)];

    constructor(
        public language: LanguageService,
        @Inject(MAT_DIALOG_DATA) public data: IRSSFeedModalDialogData,
        private dialogRef: MatDialogRef<RssFeedModalFormComponent>,
        private scrollTo: ScrollToService,
        private rssFeedService: RssFeedService,
        private componentHelpers: ComponentHelpers
    ) {
        if (this.data && this.data.feed) {
            this.isEditMode = true;
        }
    }

    ngOnInit() {
    }

    @ClickDecorator()
    createFeed(event: MouseEvent) {
        if (this.validateForms()) {
            this.componentHelpers.startApiAction(
                () => this.rssFeedService.create(this.feedForms.map(form => form.formGroup.value)),
                {
                    successMessageKey: 'rss.feed.create.success',
                    failedMessageKey: 'rss.feed.create.failed',
                    afterSuccessAction: (response) => {
                        if (this.data && this.data.afterSuccessAction) {
                            this.data.afterSuccessAction(response);
                        }

                        this.dialogRef.close();
                    }
                }
            );
        }
    }

    @ClickDecorator()
    modifyFeed(event: MouseEvent) {
        if (this.validateForms()) {
            this.componentHelpers.startApiAction(
                () => this.rssFeedService.modify(this.data.feed.feedID, this.feedForms[0].formGroup.value as IRSSFeedFormData),
                {
                    successMessageKey: 'rss.feed.modify.success',
                    failedMessageKey: 'rss.feed.modify.failed',
                    afterSuccessAction: (response) => {
                        if (this.data && this.data.afterSuccessAction) {
                            this.data.afterSuccessAction(response);
                        }

                        this.dialogRef.close();
                    }
                }
            );
        }
    }

    /**
     * Add new feed form
     * @param {MouseEvent} event
     */
    @ClickDecorator()
    addNewForm(event: MouseEvent): void {
        this.feedForms.push(this.createFormGroup());

        if (this.newFormBtn) {
            setTimeout(() => {
                this.scrollTo.scrollTo({
                    target: this.newFormBtn
                });
            }, 0);
        }
    }

    @ClickDecorator()
    removeFormAt(event: MouseEvent, index: number) {
        this.feedForms.splice(index, 1);
    }

    /**
     * Create new form group
     * @param {RssFeedModel} feed
     * @return {IRSSFeedForm}
     */
    private createFormGroup(feed?: RssFeedModel): IRSSFeedForm {
        const formGroup = new FormGroup({
            [this.controlNames.Name]: new FormControl(feed ? feed.name : null, [Validators.required, Validators.minLength(3)]),
            [this.controlNames.FeedUrl]: new FormControl(feed ? feed.feedUrl : null, [Validators.required, url]),
            [this.controlNames.Organization]: new FormControl(feed ? feed.organizationID : [], [Validators.required])
        });
        const formHelpers = new FormHelpersService();

        formHelpers.formInit(formGroup);

        return {
            formGroup,
            formHelpers
        };
    }

    /**
     * Validate all feed form
     * @return {boolean}
     */
    private validateForms(): boolean {
        const validFormCount = this.feedForms.filter(form => {
            const isValid = form.formGroup.valid;

            if (!isValid) {
                form.formHelpers.validateForm(form.formGroup);
            }

            return isValid;
        }).length;

        return validFormCount === this.feedForms.length;
    }
}
