import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogSuccessComponent} from '~/src/app/components/dialog-success/dialog-success.component';

interface DialogData {
    message: string;
}

@Component({
    selector: 'smd-post-template-success-create-modal',
    templateUrl: './post-template-success-create-modal.component.html',
    styleUrls: ['./post-template-success-create-modal.component.scss']
})
export class PostTemplateSuccessCreateModalComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogSuccessComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
    }
}
