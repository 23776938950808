import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidationService} from '../../../services/form.validation.service';
import {Debounce, MyErrorStateMatcher} from '../../../services/helpers';
import {AnalyticsService} from '~/src/app/modules/analytics/analytics.service';
import {SocialSiteInterface} from '~/src/app/components/social-site-select/social-site.interfaces';
import {WidgetTypes} from '~/src/app/modules/analytics/widget/widget.configs';
import {WidgetTypeMap} from '~/src/app/modules/analytics/widget/widget.interfaces';

@Component({
    selector: 'smd-dialog-create-analytics',
    templateUrl: './dialog-create-analytics.component.html',
    styleUrls: ['./dialog-create-analytics.component.scss'],
    providers: [
        AnalyticsService
    ]
})
export class DialogCreateAnalyticsComponent implements OnInit {

    types: WidgetTypeMap[] = WidgetTypes;

    socialSites: SocialSiteInterface[] = [];
    createAnalyticsFormErrors: object = {};
    matcher = new MyErrorStateMatcher();

    createAnalyticsForm = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(48)
        ]),
        type: new FormControl('', [
            Validators.required
        ])
    });

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogCreateAnalyticsComponent>,
        private analyticsService: AnalyticsService
    ) {
    }

    ngOnInit() {
    }

    submitPanel(event) {
        event.preventDefault();

        this.createAnalyticsFormErrors = {};
        this.matcher = new MyErrorStateMatcher();

        if (this.createAnalyticsForm.valid) {
            const widget = {
                name: this.createAnalyticsForm.get('name').value,
                type: this.createAnalyticsForm.get('type').value,
                id: this.analyticsService.generateUUID()
            };

            this.dialogRef.close(widget);
        } else {
            this.createWidgetFormValidation();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    @Debounce()
    createWidgetFormValidation() {
        this.createAnalyticsFormErrors = FormValidationService.getMessages(this.createAnalyticsForm.controls);

        for (const name in this.createAnalyticsFormErrors) {
            this.createAnalyticsForm.get(name).markAsTouched();
        }
    }

    get panelName(): any {
        return this.createAnalyticsForm.get('name').value;
    }

    get panelPage(): any {
        return this.createAnalyticsForm.get('page').value;
    }

    get panelMetric(): any {
        return this.createAnalyticsForm.get('metric').value;
    }
}
