import { NotifyService } from '~/src/app/services/notify.service';
import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
    EmailNotificationManagerComponent
} from '~/src/app/modules/users/profile/email-notification-manager/email-notification-manager.component';
import { OpenModalService } from '~/src/app/modules/social-media-post/open-modal.service';
import { HostListener } from '~/node_modules/@angular/core';
import { CustomEvents, Helpers, LoggedUserInterface } from '~/src/app/services/helpers';
import { LoggedUser } from '~/src/app/services/logged-user';
import { SmdFile } from '~/src/app/services/file.class';
import { PostActionsService } from '~/src/app/modules/posts/post-actions.service';
import { NotificationService } from '~/src/app/modules/notification-menu/notification.service';
import { NotificationModel, PublishingNotification, SystemNotification, TeamCommentNotification } from '~/src/app/modules/notification-menu/notification.model';
import { TemplateActionsService } from '~/src/app/modules/posts/template-actions.service';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs/index';
import { LanguageService } from '~/src/app/services/language.service';
import { PostPreviewComponent } from '~/src/app/modules/posts/post-previews/post-preview/post-preview.component';
import { Token } from '../../services/token';
import Utils from '../../core/utils';
import * as moment from "moment-timezone";
import CommonPostHelpers from '../posts/common-post-helpers';
import { FILE_TYPES } from '../../components/file/types/fileTypes';
import { CarouselController, CarouselItem } from '../posts/carousel/carousel.component';
import { CarouselItemResponseInterface } from '../posts/carousel/carousel.interface';
import { PLACEHOLDER_IMAGE } from '../../configs/configs';
import { CacheService } from '../../services/cache.service';

@Component({
    selector: 'smd-notification-menu',
    templateUrl: './notification-menu.component.html',
    styleUrls: ['./notification-menu.component.scss'],
})
export class NotificationMenuComponent implements OnInit, OnDestroy {

    counter = 0;
    teamCommentsCounter: number = 0;
    publishingCounter: number = 0;
    systemCounter: number = 0;

    user: LoggedUserInterface | {} = {};
    notifications: NotificationModel;
    lastNotificationDate: string = '';
    pollingSubscription: Subscription;
    userToken: string;

    placeholderImg = PLACEHOLDER_IMAGE;

    constructor(
        private openModal: OpenModalService,
        private cacheService: CacheService,
        //private previewService: ShowPreviewService,
        private postService: PostActionsService,
        private templateService: TemplateActionsService,
        private notificationService: NotificationService,
        private titleService: Title,
        public languageService: LanguageService,
        private router: Router,
        public http: HttpClient,
        public carouselController: CarouselController,
    ) {
        this.setUser();
    }

    ngOnInit(): void {
        this.getNotifications();
        this.getLastNotificationDate();
        this.userToken = Token.getToken();
    }

    ngOnDestroy(): void {
        this.pollingSubscription.unsubscribe();
    }

    expandDropdown(): void {
        NotifyService.closeAll(); // close all notification toasts when user opens the main notif menu
    }

    markAllAsRead(): void {
        let unseenComments = this.notifications?.teamComments?.filter(notification => !notification.seenAt).map(notification => notification.notificationID);
        let unseenPublishing = this.notifications?.publishing?.filter(notification => !notification.seenAt).map(notification => notification.notificationID);
        let unseenSystem = this.notifications?.system?.filter(notification => !notification.seenAt).map(notification => notification.notificationID);
        let unseenNotificationIDs = unseenComments.concat(unseenPublishing, unseenSystem);

        this.notificationService.seenNotification(unseenNotificationIDs)
            .then((response) => {
                //this.getNotifications();
            });

        // set all notifications as seen if they are not seen yet instantly after clicking the button
        this.notifications?.teamComments?.forEach(notification => {
            if (!notification.seenAt) {
                notification.seenAt = new Date();
            }
        });

        this.notifications?.publishing?.forEach(notification => {
            if (!notification.seenAt) {
                notification.seenAt = new Date();
            }
        });

        this.notifications?.system?.forEach(notification => {
            if (!notification.seenAt) {
                notification.seenAt = new Date();
            }
        });

        this.updateCounters();
    }

    openEmailNotificationManager(event?: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.openModal.manageEmailNotification(EmailNotificationManagerComponent, this.user);
    }

    getNotifications(): void {
        this.notificationService.getNotifications().then(response => {
            this.notifications = response.notifications;
            this.notifications?.teamComments?.forEach(notification => {
                notification.dateAgo = moment.tz(notification.createDate, 'UTC').fromNow();
                if (notification.objectType === "post" && notification.objectSocialSites?.length > 5) {
                    notification.tooltipSocialSitesCount = notification.objectSocialSites.length - 5;
                    let sites = notification.objectSocialSites.slice(5, notification.objectSocialSites.length);
                    let siteNames = [];
                    for (let site of sites) {
                        siteNames.push(site.siteName);
                    }
                    notification.tooltipSocialSites = siteNames.join(', ');
                }
                if (Number.isInteger(parseInt(notification.objectThumbnail))) {
                    this.carouselController.getCarouselItemsByIDs([parseInt(notification.objectThumbnail)])
                        .then(({ carouselItems }: { carouselItems: CarouselItemResponseInterface[] }) => {
                            const mediaCarouselItems = [];

                            carouselItems.map(carouselItem => {
                                mediaCarouselItems.push(new CarouselItem(carouselItem));
                            });

                            notification.objectThumbnail = mediaCarouselItems[0]?._imageUrl;
                        });
                }

                // if notification was created in the last minute, call onNewTeamComment
                if (notification.createDate && moment.tz(notification.createDate, 'UTC').isAfter(moment.tz(new Date(), 'UTC').subtract(1, 'minute'))) {
                    this.onNewTeamComment(notification.objectID);
                }
            });
            this.notifications?.publishing?.forEach(notification => {
                notification.dateAgo = moment.tz(notification.createDate, 'UTC').fromNow();
                if (notification.objectType === "post" && notification.objectSocialSites?.length > 5) {
                    notification.tooltipSocialSitesCount = notification.objectSocialSites.length - 5;
                    let sites = notification.objectSocialSites.slice(5, notification.objectSocialSites.length);
                    let siteNames = [];
                    for (let site of sites) {
                        siteNames.push(site.siteName);
                    }
                    notification.tooltipSocialSites = siteNames.join(', ');
                }
            });
            this.notifications?.system?.forEach(notification => {
                notification.dateAgo = moment.tz(notification.createDate, 'UTC').fromNow();
                if ((notification.type === "userCreate" || notification.type === "userModify") && notification.userOtherOrganizations?.length > 5) {
                    notification.tooltipOrganizationsCount = notification.userOtherOrganizations?.length - 5;
                    let orgs = notification.userOtherOrganizations.slice(5, notification.userOtherOrganizations.length);
                    let organizationNames = [];
                    for (let org of orgs) {
                        organizationNames.push(org.name + " - " + org.roleName);
                    }
                    notification.tooltipOrganizations = organizationNames.join(', ');
                }
            });

            this.updateCounters();
            this.popToastForNewNotifications();
        });
    }

    onCommentNotificationClick(notification: TeamCommentNotification): void {
        this.markAsRead(notification.notificationID);

        if (notification.objectType === 'post') {
            this.postService.getAll(Helpers.objectToQueryOptions({ 'postIDs[]': notification.objectID }))
                .then((response) => {/*post: PostInterface*/
                    if (response.posts.length > 0) {
                        const post = Utils.initLineBreaksOnPostEntity(CommonPostHelpers.mergeDataWithDraftData(response.posts[0])); // so draft works, and linebreaks at editing too

                        if (post.medias && post.medias[0] && post.medias[0].mediaType === FILE_TYPES.CAROUSEL) { // so carousels work
                            const mediaID = post.medias[0].mediaID

                            if (mediaID) {
                                return this.carouselController
                                    .getCarouselItemsByIDs([mediaID])
                                    .then(({ carouselItems }: { carouselItems: CarouselItemResponseInterface[] }) => {
                                        post.medias[0].carouselItems = carouselItems
                                            .filter(
                                                (carouselItem) =>
                                                    carouselItem.carouselID.toString() ===
                                                    post.medias[0].mediaID.toString()
                                            ).map((item) => new CarouselItem(item));
                                        post.medias = SmdFile.initFileArray(post.medias);
                                        return this.openModal.commentToPost(PostPreviewComponent, post);
                                    });
                            }
                        } else {
                            post.medias = SmdFile.initFileArray(post.medias);
                            return this.openModal.commentToPost(PostPreviewComponent, post);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (notification.objectType === 'postTemplate') {
            this.templateService.getAll(Helpers.objectToQueryOptions({ 'templateID[]': notification.objectID }))
                .then((response) => { //template: PostTemplateInterface
                    if (response.templates.length > 0) {
                        const template = Utils.initLineBreaksOnPostEntity(response.templates[0]); // so linebreaks work at editing

                        if (template.medias && template.medias[0] && template.medias[0].type === FILE_TYPES.CAROUSEL) { // so carousels work
                            const mediaID = template.medias[0].mediaID

                            if (mediaID) {
                                return this.carouselController
                                    .getCarouselItemsByIDs([mediaID])
                                    .then(({ carouselItems }: { carouselItems: CarouselItemResponseInterface[] }) => {
                                        template.medias = template.medias.map(media => {
                                            const mediaCarouselItems = [];

                                            carouselItems.map(carouselItem => {
                                                if (Number(media.mediaID) === Number(carouselItem.carouselID)) {
                                                    mediaCarouselItems.push(new CarouselItem(carouselItem));
                                                }
                                            });

                                            media.carouselItems = mediaCarouselItems;

                                            return new SmdFile(media);
                                        });

                                        template.coverMediaSrc = CommonPostHelpers.getCoverMediaSrc(template);
                                        return this.openModal.commentTemplate(PostPreviewComponent, template);
                                    });
                            }
                        } else {
                            template.medias = SmdFile.initFileArray(template.medias);
                            return this.openModal.commentTemplate(PostPreviewComponent, template);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    onImportNotificationClick(notification: PublishingNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        if (notification.type && notification.type === 'postImport') {
            if (notification.status === "waitingForApproval") {
                //status = waitingForApproval, postIDs = notification.objectIDs?.join(', ')
                let url = '/post-list/awaiting-approval?searchValue=' + notification.objectIDs?.join(', ');
                this.router.navigateByUrl(url);
            } else if (notification.status === "approved") {
                //status = approved, postIDs = notification.objectIDs?.join(', ')
                let url = '/post-list/approved?searchValue=' + notification.objectIDs?.join(', ');
                this.router.navigateByUrl(url);
            }
        } else if (notification.type && notification.type === 'templateImport') {
            if (notification.status === "waitingForApproval") {
                //status = waitingForApproval, templateIDs = notification.objectIDs?.join(', ')
                let url = '/template-manager?status=waitingForApproval&searchValue=' + notification.objectIDs?.join(', ');
                this.router.navigateByUrl(url);
            } else if (notification.status === "approved") {
                //status = active, templateIDs = notification.objectIDs?.join(', ')
                let url = '/template-manager?status=active&searchValue=' + notification.objectIDs?.join(', ');
                this.router.navigateByUrl(url);
            }
        }
    }

    onApprovalNotificationClick(notification: PublishingNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        // redirect to post or template manager with the right filters
        if (notification.type && notification.type === "postApproval") {
            let urlStatus = notification.status === "approved" ? "approved" : "approval-declined";
            this.router.navigateByUrl('/post-list/' + urlStatus + '?postID=' + notification.objectID);
        } else if (notification.type && notification.type === "postApprovalRequest") {
            this.router.navigateByUrl('/post-list/awaiting-approval?postID=' + notification.objectID);
        } else if (notification.type && notification.type === "templateApproval") {
            const status = notification.status === "approved" ? "active" : "notApproved";
            this.router.navigateByUrl('/template-manager?status=' + status + '&templateID=' + notification.objectID);
        } else if (notification.type && notification.type === "templateApprovalRequest") {
            this.router.navigateByUrl('/template-manager?status=waitingForApproval&templateID=' + notification.objectID);
        }
    }

    onFailedToPublishClick(notification: PublishingNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        if (notification.partialFail) {
            this.router.navigateByUrl('/post-list/partially-failed?searchValue=' + notification.objectID);
        } else {
            this.router.navigateByUrl('/post-list/failed-to-post?searchValue=' + notification.objectID);
        }
    }
    onPublishClick(notification: PublishingNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        if (notification.partialSuccess) {

            this.router.navigateByUrl('/post-list/published?searchValue=' + notification.objectID);
        } else {
            this.router.navigateByUrl('/post-list/published?searchValue=' + notification.objectID);
        }
    }

    onReviewWillExpireClick(notification: PublishingNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }
        this.router.navigateByUrl('/post-list/awaiting-approval?postID=' + notification.objectID);
    }

    onUserNotificationClick(notification: SystemNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        // if !delete redirect to access management filter by username? (more like userID)
        if (notification.type && notification.type !== "userDelete") {
            let username = notification.username ? notification.username : "";
            this.router.navigateByUrl('/access-management?name=' + username);
        }
    }

    onOrganizationNotificationClick(notification: SystemNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        // if !delete redirect to organizations edit page of the organization
        if (notification.type && notification.type !== "organizationDelete") {
            this.router.navigateByUrl('/organisations/' + notification.organizationID);
        }
    }

    onSocialSiteNotificationClick(notification: SystemNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        // if !delete redirect to organizations / social networks / highlighted social site card view
        if (notification.type && notification.type !== "socialSiteDelete") {
            this.router.navigateByUrl('/organisations/' + notification.organizationID + '/social-networks/' + notification.siteSocialType + '/' + notification.siteID);
        }
    }

    onAnalyticsNotificationClick(notification: SystemNotification): void {
        if (!notification.seenAt) {
            this.markAsRead(notification.notificationID);
        }

        this.router.navigateByUrl('/analytics'); // TODO: when Analytics module is reworked we should make this url more accurate somehow (point to predefined dashboards?!)
    }

    markAsRead(notificationID: number): void {
        for (const notificationType in this.notifications) {
            if (this.notifications[notificationType] instanceof Array) {
                const notification = this.notifications[notificationType].find(notification => notification.notificationID === notificationID);
                if (notification) {
                    notification.seenAt = new Date();
                    this.updateCounters();
                    break;
                }
            }
        }

        this.notificationService.seenNotification([notificationID]).then(response => {
            //this.getNotifications();
        });
    }

    getLastNotificationDate(): void {
        // poll lastNotificationDate every minute (60000 ms)
        this.pollingSubscription = interval(60000).subscribe(() => {
            this.notificationService.getLastNotificationDate().then(response => {
                if (response.lastNotificationDate !== this.lastNotificationDate) {
                    this.lastNotificationDate = response.lastNotificationDate;
                    this.getNotifications();
                } else { // if there are no new notifications, just refresh the dateAgo property of the existing ones for rendering
                    for (const notificationType in this.notifications) {
                        if (this.notifications[notificationType] instanceof Array) {
                            this.notifications[notificationType].forEach(notification => {
                                notification.dateAgo = moment.tz(notification.createDate, 'UTC').fromNow();
                            });
                        }
                    }
                    this.updatePageTitle(); // also, update page title to make sure its correct
                }
            });
        });
    }

    @HostListener(`document:${CustomEvents.USER_UPDATE}`, ['$event'])
    setUser(): void {
        this.user = LoggedUser.getUser();
    }

    popToastForNewNotifications(): void {
        NotifyService.closeAll();

        if (document.getElementById('notificationDropdown').classList.contains('show')) {
            return; // don't pop toast if notifications dropdown is open
        }

        if (this.notifications.toastData) {
            const toastData = this.notifications.toastData;

            for (const notificationType in toastData) {
                const notificationCount = toastData[notificationType];
                if (notificationCount > 0) {
                    const toastHeadline = LanguageService.getLine('notifications.toast.new')
                    const toastText = LanguageService.getLine(`notifications.toast.${notificationType}`, { notificationCount: notificationCount, s: notificationCount > 1 ? 's' : '' });
                    NotifyService.notification(toastHeadline, toastText);
                }
            }
        }
    }

    updatePageTitle(): void {
        if (this.counter > 0) {
            const lastNumber = this.titleService.getTitle().match(/\(\d+\)$/g);
            if (lastNumber) {
                this.titleService.setTitle(this.titleService.getTitle().replace(lastNumber[0], `(${this.counter})`));
            } else {
                this.titleService.setTitle(this.titleService.getTitle() + ` (${this.counter})`);
            }
        } else {
            this.titleService.setTitle(this.titleService.getTitle().replace(/\(\d+\)$/g, ''));
        }
    }

    updateCounters(): void {
        this.teamCommentsCounter = this.notifications?.teamComments?.filter(notification => !notification.seenAt).length;
        this.publishingCounter = this.notifications?.publishing?.filter(notification => !notification.seenAt).length;
        this.systemCounter = this.notifications?.system?.filter(notification => !notification.seenAt).length;
        this.counter = this.teamCommentsCounter + this.publishingCounter + this.systemCounter;

        this.updatePageTitle();
    }

    onNewTeamComment(objectID: number): void {
        this.cacheService.invalidateWithPart(`objectID=${objectID}`); // invalidate cache for comments when a new notification is received
        this.notificationService.triggerCommentNotificationEvent(objectID); // trigger event so opened comments refresh
    }
}
