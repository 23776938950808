import AbstractModel from '~/src/app/core/model.abstract';
import {IHashtag} from '~/src/app/modules/hashtag/hashtag.interface';
import Utils from '~/src/app/core/utils';
import {VIEW_MOMENT_BASE_DATETIME_FORMAT} from '~/src/app/configs/configs';
import {HashtagUtils} from '~/src/app/modules/hashtag/hashtag.utils';

export class HashtagModel extends AbstractModel<IHashtag> implements IHashtag {
    tag: string;
    tagID: number;
    createDate: string;
    organizationID: number[];
    organizationNames?: string[];
    userID?: number;
    userName?: string;
    nameOfUserOrg?: string;

    constructor(json: IHashtag) {
        super(json);

        this.parse(json);

        if (!HashtagUtils.hasHashtagSign(this.tag)) {
            this.tag = HashtagUtils.addHashtagSign(this.tag);
        }

        if (this.createDate) {
            this.createDate = Utils.moment(this.createDate).format(VIEW_MOMENT_BASE_DATETIME_FORMAT);
        }

        if (this.organizationID && this.organizationID instanceof Array) {
            this.organizationID = this.organizationID.map(id => Number(id));
        }
    }
}
