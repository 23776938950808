
export type MessageBoxTypes = 'info' | 'warning' | 'danger';

export class MessageBoxConfig {

    /**
     * Message box types
     *
     * @type {{Warning: string; Danger: string; Info: string}}
     */
    static readonly TYPES: {[p: string]: MessageBoxTypes} = {
        Info: 'info',
        Warning: 'warning',
        Danger: 'danger',
    };

    /**
     * Message box type - class names map
     *
     * @type {{[p: string]: string}}
     */
    static readonly TYPE_CLASS_MAP = {
        [MessageBoxConfig.TYPES.Info]: 'alert-info',
        [MessageBoxConfig.TYPES.Warning]: 'alert-warning',
        [MessageBoxConfig.TYPES.Danger]: 'alert-danger'
    };

    /**
     * Message box type - text class map
     *
     * @type {{[p: string]: string}}
     */
    static readonly TYPE_TEXT_CLASS_MAP = {
        [MessageBoxConfig.TYPES.Info]: 'text-info',
        [MessageBoxConfig.TYPES.Warning]: 'text-warning',
        [MessageBoxConfig.TYPES.Danger]: 'text-danger'
    };

    /**
     * Message box type - icon class map
     *
     * @type {{[p: string]: string}}
     */
    static readonly TYPE_ICON_CLASS_MAP = {
        [MessageBoxConfig.TYPES.Info]: 'fa fa-exclamation-circle',
        [MessageBoxConfig.TYPES.Warning]: 'fa fa-exclamation-triangle',
        [MessageBoxConfig.TYPES.Danger]: 'fa fa-exclamation-triangle'
    };

    /**
     * Get box class name
     *
     * @param {MessageBoxTypes} type
     * @returns {string}
     */
    static getClassName(type: MessageBoxTypes): string {
        return `bg-transparent w-100 alert ${this.TYPE_CLASS_MAP[type]}`;
    }

    /**
     * Get icon class name
     *
     * @param {MessageBoxTypes} type
     * @returns {string}
     */
    static getIconClassName(type: MessageBoxTypes): string {
        return `m-r-5 ${this.TYPE_ICON_CLASS_MAP[type]}`;
    }

    /**
     * Get text class name
     *
     * @param {MessageBoxTypes} type
     * @returns {string}
     */
    static getTextClassName(type: MessageBoxTypes): string {
        return `pointer ${this.TYPE_TEXT_CLASS_MAP[type]} m-0`;
    }

}
