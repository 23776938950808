import {ActivityItemInterface} from './activity.interfaces';
import {Token} from '../../services/token';
import {BASE_DATETIME_FORMAT, NO_AVATAR_IMAGE_PATH} from '../../configs/configs';
import * as moment from 'moment';
import {EventEmitter} from '@angular/core';

export class ActivityItemEntity implements ActivityItemInterface {
    private _activityDate: string;
    private _activityID: number;
    private _objectID: number;
    private _objectType: string;
    private _objectTypeMessage: string;
    private _summary: string;
    private _userID: number;
    private _fullName: string;
    private _userProfile: string;
    private _username: string;
    private _entity: any;

    onOpenPreview: EventEmitter<any> = new EventEmitter<any>();

    constructor(activity: ActivityItemInterface) {
        this.activityDate = activity.activityDate;
        this._activityID = activity.activityID;
        this._objectID = activity.objectID;
        this._objectType = activity.objectType;
        this._objectTypeMessage = activity.objectTypeMessage;
        this._summary = activity.summary;
        this._userID = activity.userID;
        this._fullName = activity.fullName;
        this.userProfile = activity.userProfile;
        this._username = activity.username;
    }

    onClick(): void {
        const previewData = {
            ...this.entity,
            id: this.entity.postID || this.entity.templateID,
            activeFrom: this.entity.activeFrom || 'active from date',
            headline: this.entity.headline,
            subHeadline: this.entity.subHeadline,
            mainCopy: this.entity.mainCopy,
            signature: this.entity.signature,
            medias: this.entity.medias || [],
            socialSiteName: (this.entity.socialSites && this.entity.socialSites.length > 0) ? this.entity.socialSites[0].socialSiteName : 'social-site-name',
            linkshare: this.entity.linkshare || '',
            status: this.entity.status || ''
        };

        this.onOpenPreview.emit(previewData);
    }

    get activityDate(): string {
        return this._activityDate;
    }

    set activityDate(value: string) {
        this._activityDate = moment(value).format(BASE_DATETIME_FORMAT);
    }

    get activityID(): number {
        return this._activityID;
    }

    set activityID(value: number) {
        this._activityID = value;
    }

    get objectID(): number {
        return this._objectID;
    }

    set objectID(value: number) {
        this._objectID = Number(value);
    }

    get objectType(): string {
        return this._objectType;
    }

    set objectType(value: string) {
        this._objectType = value;
    }

    get objectTypeMessage(): string {
        return this._objectTypeMessage;
    }

    set objectTypeMessage(value: string) {
        this._objectTypeMessage = value;
    }

    get summary(): string {
        return this._summary;
    }

    set summary(value: string) {
        this._summary = value;
    }

    get userID(): number {
        return this._userID;
    }

    set userID(value: number) {
        this._userID = value;
    }

    get fullName(): string {
        return this._fullName;
    }

    set fullName(value: string) {
        this._fullName = value;
    }

    get userProfile(): string {
        return this._userProfile;
    }

    set userProfile(value: string) {
        this._userProfile = (value && value !== '') ? `${value}&token=${Token.getToken()}` : NO_AVATAR_IMAGE_PATH;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get entity(): any {
        return this._entity;
    }

    set entity(value: any) {
        this._entity = value;
    }
}
