import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {RssManagerPageComponent} from '~/src/app/modules/rss/components/rss-manager-page/rss-manager-page.component';
import {RssFeedManagerComponent} from '~/src/app/modules/rss/components/rss-feed-manager/rss-feed-manager.component';
import {RssFeedModalFormComponent} from '~/src/app/modules/rss/components/rss-feed-modal-form/rss-feed-modal-form.component';
import {RssGroupManagerComponent} from '~/src/app/modules/rss/components/rss-group-manager/rss-group-manager.component';
import {RssGroupModalFormComponent} from '~/src/app/modules/rss/components/rss-group-modal-form/rss-group-modal-form.component';
import {RssFeedSelectComponent} from '~/src/app/modules/rss/components/rss-feed-select/rss-feed-select.component';
import {RssFeedPageComponent} from '~/src/app/modules/rss/components/rss-feed-page/rss-feed-page.component';
import {RssManagerModalComponent} from '~/src/app/modules/rss/components/rss-manager-modal/rss-manager-modal.component';
import {RssGroupSelectComponent} from '~/src/app/modules/rss/components/rss-group-select/rss-group-select.component';
import {RssFeedListComponent} from '~/src/app/modules/rss/components/rss-feed-list/rss-feed-list.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    declarations: [
        RssManagerPageComponent,
        RssFeedManagerComponent,
        RssFeedModalFormComponent,
        RssGroupManagerComponent,
        RssGroupModalFormComponent,
        RssFeedSelectComponent,
        RssFeedPageComponent,
        RssManagerModalComponent,
        RssGroupSelectComponent,
        RssFeedListComponent
    ],
    providers: [],
    entryComponents: [RssFeedModalFormComponent, RssGroupModalFormComponent, RssManagerModalComponent]
})
export class RssModule {
}
