import { Injectable } from "@angular/core";
import { LoggedUser } from "~/src/app/services/logged-user";
import { Organization } from "~/src/app/modules/users/users-resource";
import { LoggedUserInterface } from "../../services/helpers";

interface CheckResourceData {
    resources: { [key: number]: string[] };
    organizations: Organization[];
}

@Injectable({ providedIn: "root" })
export class ResourceService {
    user: LoggedUserInterface;

    constructor() {
        this.user = LoggedUser.getUser();
    }

    checkResource(
        resources: string[] | string,
        forceEnable = false,
        data: CheckResourceData = null,
        connectionType: "and" | "or" = "and",
        onlyInOrganization: number | string = null
    ) {
        if (!data) {
            data = {
                resources: this.user.resources,
                organizations: this.user.organizations,
            };
        }

        const myResources: { [key: number]: string[] } = data.resources || [];
        const myOrganizationIDs: any[] = onlyInOrganization
            ? [onlyInOrganization]
            : (data.organizations || []).map((org) => org.organizationID);
        const resource = Array.isArray(resources) ? resources[0] : resources;
        let enable = false;

        switch (connectionType) {
            case "and":
                if (!forceEnable) {
                    for (const key of myOrganizationIDs) {
                        if (Array.isArray(resources) && resources.length > 1) {
                            if (
                                !resources.find(
                                    (res) =>
                                        myResources[key]?.indexOf(res) === -1
                                )
                            ) {
                                enable = true;
                            }
                        } else if (resource) {
                            if (myResources[key]?.indexOf(resource) > -1) {
                                enable = true;
                            }
                        }
                    }
                } else {
                    enable = true;
                }

                break;

            case "or":
                for (const organizationID of myOrganizationIDs) {
                    for (const _resource of resources) {
                        if (myResources[organizationID].includes(_resource)) {
                            enable = true;
                        }
                    }
                }

                break;
        }

        return enable;
    }

    /**
     * Check administration access.
     * @returns {boolean}
     */
    checkAdministrationAccess(): boolean {
        return this.checkResource("background.administration.access");
    }
}
