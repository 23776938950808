/**
 * @param {boolean} breakEvent
 * @return {MethodDecorator}
 * @constructor
 */
export function ClickDecorator(breakEvent = true): MethodDecorator {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function () {
            const args = arguments ? Array.from(arguments) : [];

            if (args.length) {
                const event: MouseEvent = args.find(arg => arg instanceof MouseEvent);

                if (event && breakEvent) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }

            originalMethod.bind(this)(...args);
        };
    };
}
