import {ComponentAbstract} from '../../../services/component.abstract';
import {AutofeedService} from './autofeed.service';
import {MatDialog} from '@angular/material/dialog';

export interface FeedStatusSettingApiObject {
    status?: 'active' | 'paused';
    daysOff?: string;
}

export class AutofeedApiComponent extends ComponentAbstract {

    constructor(
        public service: AutofeedService,
        public dialog: MatDialog
    ) {
        super();
    }

    /**
     * Order posts by IDS
     *
     * @param {number} sourceID
     * @param {number} targetID
     * @return {Promise<any>}
     */
    orderPosts(sourceID: number, targetID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.service.orderPosts(sourceID, targetID)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    this.service.showErrorAlert(error);

                    reject(error);
                });
        });
    }

    /**
     * Edit auto feed item
     *
     * @param {number} id
     * @param {object} data
     * @return {Promise<any>}
     */
    editFeedItem(id: number, data: object): Promise<any> {
        return this.service.editFeedItem(id, data);
    }

    /**
     * Delete auto feed time items
     *
     * @param {number} postTimeID
     * @param {string} days
     * @return {Promise<any>}
     */
    deleteFeedItem(postTimeID: number, days?: string): Promise<any> {
        return this.service.deleteFeedItem(postTimeID, days);
    }

    /**
     * Set feed status on a social site
     *
     * @param {number} socialSiteID
     * @param {FeedStatusSettingApiObject} data
     * @return {Promise<any>}
     */
    setFeedStatus(socialSiteID: number, data: FeedStatusSettingApiObject): Promise<any> {
        return this.service.setFeedStatus(socialSiteID, data);
    }

    /**
     * Return feed status
     *
     * @param {number} socialSiteID
     * @return {Promise<any>}
     */
    getFeedStatus(socialSiteID: number): Promise<any> {
        return this.service.getFeedStatus(socialSiteID);
    }

}
