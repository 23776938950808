/**
 * Activity types
 * @type {string}
 */
export const ACTIVITY_PREFIX = "activity.";

export const ACTIVITY_CREATE_USER = "createUser";
export const ACTIVITY_MODIFY_USER = "modifyUser";
export const ACTIVITY_DELETE_USER = "deleteUser";
export const ACTIVITY_MODIFY_PROFILE = "modifyProfile";
export const ACTIVITY_SCHEDULE_POST = "schedulePost";
export const ACTIVITY_INSTANT_POST = "instantPost";
export const ACTIVITY_SCHEDULE_POST_AUTOFEED = "schedulePostAutofeed";
export const ACTIVITY_MODIFY_POST_AUTOFEED = "modifyPostAutofeed";
export const ACTIVITY_DELETE_POST_AUTOFEED = "deletePostAutofeed";
export const ACTIVITY_AUTOFEED_PAUSE = "autoFeedQueuePause";
export const ACTIVITY_AUTOFEED_START = "autoFeedQueueStart";
export const ACTIVITY_MODIFY_POST = "modifyPost";
export const ACTIVITY_DELETE_POST = "deletePost";
export const ACTIVITY_STATUS_CHANGE_POST = "changeStatusPost";
export const ACTIVITY_CREATE_TEMPLATE = "createTemplate";
export const ACTIVITY_MODIFY_TEMPLATE = "modifyTemplate";
export const ACTIVITY_DELETE_TEMPLATE = "deleteTemplate";
export const ACTIVITY_STATUS_CHANGE_TEMPLATE = "changeStatusTemplate";
export const ACTIVITY_UPLOAD_FILE = "uploadFile";
export const ACTIVITY_MODIFY_FILE = "modifyFile";
export const ACTIVITY_DELETE_FILE = "deleteFile";
export const ACTIVITY_CREATE_CAROUSEL = "createCarousel";
export const ACTIVITY_MODIFY_CAROUSEL = "modifyCarousel";
export const ACTIVITY_DELETE_CAROUSEL = "deleteCarousel";
export const ACTIVITY_CREATE_CATEGORY = "createCategory";
export const ACTIVITY_MODIFY_CATEGORY = "modifyCategory";
export const ACTIVITY_DELETE_CATEGORY = "deleteCategory";
export const ACTIVITY_CREATE_ORGANIZATION = "createOrganization";
export const ACTIVITY_MODIFY_ORGANIZATION = "modifyOrganization";
export const ACTIVITY_DELETE_ORGANIZATION = "deleteOrganization";
export const ACTIVITY_COMMENT = "comment";
export const ACTIVITY_LOGIN = "login";
export const ACTIVITY_LOGOUT = "logout";
export const ACTIVITY_CREATE_SOCIAL_SITE = "createSocialSite";
export const ACTIVITY_MODIFY_SOCIAL_SITE = "modifySocialSite";
export const ACTIVITY_DELETE_SOCIAL_SITE = "deleteSocialSite";
export const ACTIVITY_CREATE_PARTNER = "createPartner";
export const ACTIVITY_MODIFY_PARTNER = "modifyPartner";
export const ACTIVITY_DELETE_PARTNER = "deletePartner";
export const ACTIVITY_STATUS_PARTNER = "statusPartner";
export const ACTIVITY_DELETE_REQUEST_PARTNER = "deleteRequestPartner";
export const ACTIVITY_ADD_SUBSCRIPTION_PARTNER = "addSubscriptionPartner";
export const ACTIVITY_EDIT_SUBSCRIPTION = "editSubscription";
export const ACTIVITY_MODIFY_PLAN = "modifyPlan";
export const ACTIVITY_MODIFY_ADDON = "modifyAddon";
export const ACTIVITY_IMPORT_TEMPLATE = "importTemplate";
export const ACTIVITY_STATUS_TEMPLATE = "statusTemplate";
export const ACTIVITY_STATUS_MEDIA = "statusMedia";
export const ACTIVITY_VISIBILITY_MEDIA = "visibilityMedia";

export const ACTIVITY_TYPES = [
    ACTIVITY_CREATE_USER,
    ACTIVITY_MODIFY_USER,
    ACTIVITY_DELETE_USER,
    ACTIVITY_MODIFY_PROFILE,
    ACTIVITY_SCHEDULE_POST,
    ACTIVITY_INSTANT_POST,
    ACTIVITY_SCHEDULE_POST_AUTOFEED,
    ACTIVITY_MODIFY_POST_AUTOFEED,
    ACTIVITY_DELETE_POST_AUTOFEED,
    ACTIVITY_AUTOFEED_PAUSE,
    ACTIVITY_AUTOFEED_START,
    ACTIVITY_MODIFY_POST,
    ACTIVITY_DELETE_POST,
    ACTIVITY_STATUS_CHANGE_POST,
    ACTIVITY_CREATE_TEMPLATE,
    ACTIVITY_MODIFY_TEMPLATE,
    ACTIVITY_DELETE_TEMPLATE,
    ACTIVITY_STATUS_CHANGE_TEMPLATE,
    ACTIVITY_UPLOAD_FILE,
    ACTIVITY_MODIFY_FILE,
    ACTIVITY_DELETE_FILE,
    ACTIVITY_CREATE_CAROUSEL,
    ACTIVITY_MODIFY_CAROUSEL,
    ACTIVITY_DELETE_CAROUSEL,
    ACTIVITY_CREATE_CATEGORY,
    ACTIVITY_MODIFY_CATEGORY,
    ACTIVITY_DELETE_CATEGORY,
    ACTIVITY_CREATE_ORGANIZATION,
    ACTIVITY_MODIFY_ORGANIZATION,
    ACTIVITY_DELETE_ORGANIZATION,
    ACTIVITY_COMMENT,
    ACTIVITY_LOGIN,
    ACTIVITY_LOGOUT,
    ACTIVITY_CREATE_SOCIAL_SITE,
    ACTIVITY_MODIFY_SOCIAL_SITE,
    ACTIVITY_DELETE_SOCIAL_SITE,
    ACTIVITY_CREATE_PARTNER,
    ACTIVITY_MODIFY_PARTNER,
    ACTIVITY_DELETE_PARTNER,
    ACTIVITY_STATUS_PARTNER,
    ACTIVITY_DELETE_REQUEST_PARTNER,
    ACTIVITY_ADD_SUBSCRIPTION_PARTNER,
    ACTIVITY_EDIT_SUBSCRIPTION,
    ACTIVITY_MODIFY_PLAN,
    ACTIVITY_MODIFY_ADDON,
    ACTIVITY_IMPORT_TEMPLATE,
    ACTIVITY_STATUS_TEMPLATE,
    ACTIVITY_STATUS_MEDIA,
    ACTIVITY_VISIBILITY_MEDIA,
];
