import Utils from '~/src/app/core/utils';
import {SmdFileInterface} from '~/src/app/services/file.class';
import {PostFormControlNames} from '~/src/app/modules/social-media-post/social-media-post.constant';
import {MentionHelpers} from '~/src/app/modules/posts/mention-helpers';
import {StringSupport} from '~/src/app/core/helper/string-support';
import {SocialMediaPlatform} from '~/src/app/modules/social-media-post/social-media-post.interface';

export const SocialMediaPostUtils = {
    copyCommonPreparer: (formData: any = {}, platform, config: SocialMediaPlatform) => {
        let data = Utils.lodash.cloneDeep(formData);
        const medias: SmdFileInterface[] = data[PostFormControlNames.Medias];

        data = MentionHelpers.prepareMentions(data, platform, config);

        if (data && data[PostFormControlNames.FeedTargetingOptions]) {
            delete data[PostFormControlNames.FeedTargetingOptions];
        }

        if (medias && medias.length) {
            data[PostFormControlNames.Medias] = medias
                .filter(media => {
                    if (!media.socialChannels || !media.socialChannels.length) {
                        return true;
                    } else if (media.socialChannels.includes(platform)) {
                        return true;
                    }

                    return false;
                });
        }

        return data;
    },

    /**
     * @param data
     * @return {string}
     */
    mergePostFieldsToMainCopy: (data: any): string => {
        const headline = data[PostFormControlNames.Headline] || '';
        const subHeadline = data[PostFormControlNames.SubHeadline] || '';
        const mainCopy = data[PostFormControlNames.Message] || '';
        const signature = data[PostFormControlNames.Signature] || '';
        const lineBreak = '<br />';
        let content = '';

        [headline, subHeadline, mainCopy, signature].forEach(value => {
            if (value) {
                content += value + lineBreak;
            }
        });

        return StringSupport.trimSpecifyString(content, lineBreak, 'right');
    }
};
