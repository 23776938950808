import {Component, Inject, OnDestroy} from '@angular/core';
import {ItemSeparationChange} from '~/src/app/core/components/item-separation/item-separation.interfaces';
import {SmdFile} from '~/src/app/services/file.class';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LanguageService} from '~/src/app/services/language.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {MediasService} from '~/src/app/services/file-browser.service';
import {ResourceSeparatorDialogData} from '~/src/app/modules/administration/media-separator/resource-separator.interfaces';
import {CoreConfig} from '~/src/app/core/core.config';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PostTemplateSystemTypesForSelect} from '~/src/app/modules/social-media-post/social-media-post.options';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {TemplateActionsController} from '~/src/app/modules/posts/template-actions';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import Utils from 'src/app/core/utils';

@Component({
    selector: 'smd-resource-separator',
    templateUrl: './resource-separator.component.html',
    styleUrls: ['./resource-separator.component.scss'],
    providers: [
        TemplateActionsController,
        ComponentHelpers,
        FormHelpersService
    ]
})
export class ResourceSeparatorComponent {
    allowedItems: any[] = [];
    disallowedItems: any[] = [];
    setResourcesFormGroup = new FormGroup({
        systemTypeFormControl: new FormControl({value: null, disabled: false}, [Validators.required])
    });
    mode: 'media' | 'template' = this.dialogData.mode;
    successSaveCount = 0;
    failedSaveCount = 0;
    readonly modes = {
        Media: 'media',
        Template: 'template'
    };

    systemType: string = '';

    postTemplateSystemTypes = Utils.lodash.cloneDeep(PostTemplateSystemTypesForSelect).filter(type => type.id !== CoreConfig.getSystemTypes().Custom);

    private _resourceGetPending = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: ResourceSeparatorDialogData,
        public dialogRef: MatDialogRef<ResourceSeparatorComponent>,
        public mediaService: MediasService,
        public templateService: TemplateActionsController,
        public language: LanguageService,
        public formHelpers: FormHelpersService,
        private componentHelpers: ComponentHelpers,
        private partnerService: PartnersService,
    ) {
        this.formHelpers.formInit(this.setResourcesFormGroup);

        this.setResourcesFormGroup.get('systemTypeFormControl')
            .valueChanges.subscribe(value => {
            switch (this.mode) {
                case this.modes.Media:
                    this.getMedias(value);

                    break;

                case this.modes.Template:
                    this.getTemplates(value);

                    break;
            }

            this.systemType = value;
        });
    }

    /**
     * Save the separated resources to partner(s)
     * @param {MouseEvent} event
     */
    save(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const actions: { [key: string]: () => Promise<any> } = {
            [this.modes.Media]: () => this.setMediasToPartners(),
            [this.modes.Template]: () => this.setTemplatesToPartners()
        };
        const successMessages = {
            [this.modes.Media]: 'mediaSeparator.setMediasToPartners.success',
            [this.modes.Template]: 'templateSeparator.setTemplatesToPartners.success',
        };
        const failedMessages = {
            [this.modes.Media]: 'mediaSeparator.setMediasToPartners.error',
            [this.modes.Template]: 'templateSeparator.setTemplatesToPartners.error',
        };

        if (this.setResourcesFormGroup.valid) {
            this.componentHelpers.startApiAction(
                () => {
                    return actions[this.mode]();
                },
                {
                    successMessageKey: successMessages[this.mode],
                    failedMessageKey: failedMessages[this.mode],
                    afterSuccessAction: (response) => this.afterSuccessSave(response),
                    afterFailedAction: (error) => this.afterFailedSave(error)
                }
            );
        } else {
            this.formHelpers.validateForm(this.setResourcesFormGroup);
        }
    }

    /**
     * Separation change event
     * @param {ItemSeparationChange} event
     */
    separationChange(event: ItemSeparationChange) {
        this.allowedItems = event.enabledItems;
        this.disallowedItems = event.disabledItems;
    }

    /**
     * Check the mode equal with param
     * @param {string} mode
     * @return {boolean}
     */
    thisModeIs(mode: string) {
        return this.mode === mode;
    }

    /**
     * Set medias to partner(s)
     * @return {Promise<Object>}
     */
    private setMediasToPartners() {
        const itemIDs = this.getItemsIDs();

        return this.partnerService.setPartnersToMedias(
            itemIDs,
            this.dialogData.partners.map(partner => partner.partnerID),
            this.systemType,
            true
        );
    }

    /**
     * Set templates to partner(s)
     * @return {Promise<Object>}
     */
    private setTemplatesToPartners() {
        const itemIDs = this.getItemsIDs();

        return this.partnerService.setPartnersToTemplates(
            itemIDs,
            this.dialogData.partners.map(partner => partner.partnerID),
            this.systemType,
            true
        );
    }

    /**
     * After success save callback
     * @param response
     */
    private afterSuccessSave(response: any) {
        this.successSaveCount += 1;

        if (this.successSaveCount === 1) {
            this.dialogRef.afterClosed().subscribe(() => {
                if (!!this.dialogData.afterSuccessSave) {
                    this.dialogData.afterSuccessSave(response);
                }
            });
        }
    }

    /**
     * After failed save callback
     * @param error
     */
    private afterFailedSave(error: any) {
        this.failedSaveCount += 1;

        if (this.failedSaveCount === 1) {
            this.dialogRef.afterClosed().subscribe(() => {
                if (!!this.dialogData.afterFailedSave) {
                    this.dialogData.afterFailedSave(error);
                }
            });
        }
    }

    /**
     * Get medias by system type
     * @param {string} systemType
     */
    private getMedias(systemType: string) {
        if (!!systemType) {
            this.resourceGetPending = true;

            this.mediaService.getItems({
                systemType: JSON.stringify([systemType]),
                isAdmin: 'yes'
            }).then(({medias}: { medias: SmdFile[] }) => {
                this.separateResources(medias, systemType);
                this.resourceGetPending = false;
            }).catch(() => {
                this.resourceGetPending = false;
            });
        } else {
            this.allowedItems = [];
            this.disallowedItems = [];
        }
    }

    /**
     * Get templates
     * @param {string} systemType
     */
    private getTemplates(systemType: string) {
        if (!!systemType) {
            this.resourceGetPending = true;

            this.templateService.filters = {
                systemType: [systemType],
                isAdmin: 'yes'
            };
            this.templateService.getItems(({templates}: { templates: PostTemplateInterface[] }) => {
                this.separateResources(templates, systemType);
                this.resourceGetPending = false;
            }, () => {
                this.resourceGetPending = false;
            });
        } else {
            this.allowedItems = [];
            this.disallowedItems = [];
        }
    }

    /**
     * Separate resources
     * @param {SmdFile[] | PostTemplateInterface[]} resources
     * @param {string} systemType
     */
    private separateResources(resources: SmdFile[] | PostTemplateInterface[], systemType: string) {
        const allowedResources: any[] = [];
        const disallowedResources: any[] = [];

        for (const resource of resources) {

            if (systemType === CoreConfig.getSystemTypes().Branded) {

                if (this.isSetInSelectedPartners(resource)) {
                    allowedResources.push(resource);
                } else {
                    disallowedResources.push(resource);
                }

            } else if (systemType === CoreConfig.getSystemTypes().Generic) {

                if (this.isSetInSelectedPartners(resource)) {
                    disallowedResources.push(resource);
                } else {
                    allowedResources.push(resource);
                }

            }
        }

        this.allowedItems = allowedResources;
        this.disallowedItems = disallowedResources;
    }

    /**
     * Check the resource is set in the selected partners
     * @param {SmdFile | PostTemplateInterface} resource
     * @return {boolean}
     */
    private isSetInSelectedPartners(resource: SmdFile | PostTemplateInterface): boolean {
        const key = (resource.systemType === CoreConfig.getSystemTypes().Generic)
            ? 'disallowedPartnerIDs'
            : (resource.systemType === CoreConfig.getSystemTypes().Branded)
                ? 'partnerIDs'
                : null;
        let result = false;

        if (!key) {
            return false;
        }

        this.dialogData.partners.forEach(partner => {
            if (resource[key].indexOf(partner.partnerID) > -1) {
                result = true;
            }
        });

        return result;
    }

    /**
     * Get itemIDs by selected system type
     * @return {number[]}
     */
    private getItemsIDs() {
        const systemType = this.setResourcesFormGroup.get('systemTypeFormControl').value;
        const keys = {
            [this.modes.Media]: 'mediaID',
            [this.modes.Template]: 'templateID'
        };
        const key = keys[this.mode];

        return (systemType === CoreConfig.getSystemTypes().Generic)
            ? this.disallowedItems.map(item => item[key])
            : this.allowedItems.map(item => item[key]);
    }

    get resourceGetPending(): boolean {
        return this._resourceGetPending;
    }

    set resourceGetPending(value: boolean) {
        this._resourceGetPending = value;

        if (value) {
            this.setResourcesFormGroup.get('systemTypeFormControl').disable({emitEvent: false});
        } else {
            this.setResourcesFormGroup.get('systemTypeFormControl').enable({emitEvent: false});
        }
    }
}
