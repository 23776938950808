import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {SetTemplatesToPartnersInterfaces} from '~/src/app/modules/administration/set-templates-to-partners/set-templates-to-partners';
import {FormControl, FormGroup} from '~/node_modules/@angular/forms';
import {PostTemplateSystemTypesForAdminSelect} from '~/src/app/modules/social-media-post/social-media-post.options';
import {LanguageService} from '~/src/app/services/language.service';
import {BASE_DATE_FORMAT, Configs} from '~/src/app/configs/configs';
import {TemplateActionsController} from '~/src/app/modules/posts/template-actions';
import {Debounce, Helpers} from '~/src/app/services/helpers';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {Partner} from '~/src/app/shared/types/partners.model';
import {CoreConfig} from '~/src/app/core/core.config';
import Utils from '~/src/app/core/utils';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {ShowPreviewService} from '~/src/app/directives/show-preview/show-preview.service';
import {SetTemplatesToPartnersModel} from '~/src/app/modules/administration/set-templates-to-partners/set-templates-to-partners.model';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';

@Component({
    selector: 'smd-template-separator',
    templateUrl: './set-templates-to-partners.component.html',
    styleUrls: ['./set-templates-to-partners.component.scss'],
    providers: [
        ComponentHelpers,
        TemplateActionsController,
        FormHelpersService
    ]
})
export class SetTemplatesToPartnersComponent implements OnInit {

    /**
     * Filters form control names
     *
     * @type {{Categories: string; StartCreateDate: string; EndCreateDate: string; SearchValue: string; SocialType: string; Tags: string; Allowed: string}}
     */
    filtersFormControlNames = {
        StartCreateDate: 'startCreateDate',
        EndCreateDate: 'endCreateDate',
        Tags: 'tags',
        Categories: 'categories',
        SearchValue: 'searchValue',
        SocialType: 'socialType',
        SystemType: 'systemType',
        Allowed: 'allowed'
    };

    /**
     * Filters form group
     */
    filtersFormGroup: FormGroup;

    /**
     * Admin system type select options
     */
    systemTypeOptions = PostTemplateSystemTypesForAdminSelect;

    /**
     * Social type options
     *
     * @type {({name: string; id: string} | {name: string; id: string})[]}
     */
    socialTypeOptions = Configs.socials;

    /**
     * Post template items
     *
     * @type {SetTemplatesToPartnersInterfaces.PostTemplate[]}
     */
    postTemplates: SetTemplatesToPartnersInterfaces.PostTemplate[] = [];

    /**
     * Post template results count
     */
    postTemplateCount: number;

    /**
     * Selected partners
     *
     * @type {any[]}
     */
    partners: Partner[] = [];

    /**
     * Pagination controller
     *
     * @type {PaginationController}
     */
    paginatorController: PaginationController = new PaginationController();

    /**
     * Post template isAllowed properties is saved
     *
     * @type {boolean}
     */
    isSaved = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: SetTemplatesToPartnersInterfaces.DialogData,
        public language: LanguageService,
        private _dialogRef: MatDialogRef<SetTemplatesToPartnersComponent>,
        private _templateService: TemplateActionsController,
        private _formHelpers: FormHelpersService,
        private _openModal: OpenModalService,
        private _previewService: ShowPreviewService,
        private _partnerService: PartnersService,
        private _componentHelpers: ComponentHelpers
    ) {

        // get partners from dialog data
        this.partners = this.dialogData.partners || [];

        // get templates on pagination change
        this.paginatorController.onPaginationChange.subscribe(() => this._getPostTemplates());

        // init filters form group
        this._initFiltersFormGroup();

        // get post template items
        this._getPostTemplates();
    }

    ngOnInit() {}

    /**
     * Save button click event
     *
     * @param {MouseEvent} event
     */
    saveBtnClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this._saveAction();
    }

    /**
     * Filter button click
     *
     * @param {MouseEvent} event
     */
    @Debounce()
    filterBtnClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        // go to first page on filter change
        this.paginatorController.paginationOptions.pageIndex = 0;

        // get post template items
        this._getPostTemplates();
    }

    /**
     * Reset button click
     *
     * @param {MouseEvent} event
     */
    @Debounce()
    resetBtnClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this._formHelpers.resetFormGroup(this.filtersFormGroup);

        this._getPostTemplates();
    }

    /**
     * Open template preview button click event
     *
     * @param {MouseEvent} event
     * @param {SetTemplatesToPartnersInterfaces.PostTemplate} template
     */
    @Debounce()
    openPreviewBtnClick(event: MouseEvent, template: SetTemplatesToPartnersInterfaces.PostTemplate): void {
        event.preventDefault();
        event.stopPropagation();

        this._openTemplatePreview(template);
    }

    /**
     * Group actions click
     *
     * @param {MouseEvent} event
     * @param {SetTemplatesToPartnersInterfaces.GroupActions} action
     */
    @Debounce()
    groupActionClick(event: MouseEvent, action: SetTemplatesToPartnersInterfaces.GroupActions) {
        event.preventDefault();

        const isAllow = [
            SetTemplatesToPartnersModel.GROUP_ACTIONS.AllowAllOnPage,
            SetTemplatesToPartnersModel.GROUP_ACTIONS.AllowAllOnResult
        ].includes(action);
        const onPage = [
            SetTemplatesToPartnersModel.GROUP_ACTIONS.AllowAllOnPage,
            SetTemplatesToPartnersModel.GROUP_ACTIONS.DisallowAllOnPage
        ].includes(action);

        if (onPage) {
            this._changeTemplatesAllowedOnPage(isAllow);

            this.isSaved = false;
        } else {
            this._changeAllTemplates(isAllow);
        }
    }

    /**
     * Template allowed switch change event
     *
     * @param {MatSlideToggleChange} event
     * @param {SetTemplatesToPartnersInterfaces.PostTemplate} template
     */
    @Debounce()
    templateAllowedSwitchChange(event: MatSlideToggleChange, template: SetTemplatesToPartnersInterfaces.PostTemplate) {
        template.isAllowed = event.checked;

        this.isSaved = false;
    }

    /**
     * Set templates to partner(s)
     *
     * @private
     */
    _saveAction() {
        const allowed: SetTemplatesToPartnersInterfaces.Allowed = {};
        const templateID: number[] = [];

        this.postTemplates.forEach(template => {
            templateID.push(template.templateID);

            allowed[template.templateID] = template.isAllowed ? 'yes' : 'no';
        });

        const requestData: SetTemplatesToPartnersInterfaces.SaveRequestData = {
            partnerID: this.partners.map(partner => Number(partner.partnerID)),
            templateID,
            allowed
        };

        this._componentHelpers.startApiAction(
            () => {
                return this._partnerService.setTemplatesToPartners(requestData)
                    .then(response => this._getPostTemplates(false).then(() => Promise.resolve(response)));
            }, {
                successMessageKey: 'templateSeparator.setTemplatesToPartners.success',
                failedMessageKey: 'templateSeparator.setTemplatesToPartners.error'
            }
        );
    }

    /**
     * Init filters form group
     *
     * @private
     */
    _initFiltersFormGroup(): void {
        this.filtersFormGroup = new FormGroup({
            [this.filtersFormControlNames.Allowed]: new FormControl(null),
            [this.filtersFormControlNames.Categories]: new FormControl(null),
            [this.filtersFormControlNames.SearchValue]: new FormControl(null),
            [this.filtersFormControlNames.SocialType]: new FormControl(null),
            [this.filtersFormControlNames.SystemType]: new FormControl(null),
            [this.filtersFormControlNames.Tags]: new FormControl(null),
            [this.filtersFormControlNames.StartCreateDate]: new FormControl(null),
            [this.filtersFormControlNames.EndCreateDate]: new FormControl(null),
        });
    }

    /**
     * Get post template items
     *
     * @param {boolean} showLoader
     * @returns {Promise<any>}
     * @private
     */
    _getPostTemplates(showLoader = true): Promise<any> {

        // reset template service filters
        this._templateService.filters = this._templateService.getDefaultFilterOptions();

        // open loader
        const loader = showLoader ? this._openModal.loader(DialogLoaderComponent) : null;

        const filters = this._getFilters();
        const categoriesKey = this.filtersFormControlNames.Categories;
        const tagsKey = this.filtersFormControlNames.Tags;

        // stringify categories in filters
        if (categoriesKey in filters) {
            filters[categoriesKey] = JSON.stringify(filters[categoriesKey]);
        }

        // stringify tags in filters
        if (tagsKey in filters) {
            filters[tagsKey] = JSON.stringify(filters[tagsKey]);
        }

        // set filters
        this._templateService.filters = {
            ...this._templateService.filters,
            ...filters,
            isAdmin: 'yes',
            order: Helpers.getOrderFormat('createDate-desc'),
            ...this.paginatorController.requestParams
        };

        // get post template items
        return new Promise((resolve, reject) => {
            this._templateService.getItems(
                ({templates, count}) => {
                    this.postTemplates = templates.map((template: SetTemplatesToPartnersInterfaces.PostTemplate) => {

                        template.isAllowed = this._checkTemplateIsAllowed(template);
                        template.tagsString = template.tags && template.tags.length
                            ? Utils.lodash.truncate(template.tags.join(', '), {length: 50})
                            : null;

                        return template;
                    }) as SetTemplatesToPartnersInterfaces.PostTemplate[];

                    this.postTemplateCount = count;

                    this.paginatorController.paginationOptions.length = count;

                    this.isSaved = true;

                    if (loader) {
                        loader.afterClosed().subscribe(() => {
                            resolve("");
                        });

                        loader.close();
                    } else {
                        resolve("");
                    }
                },
                (error) => {
                    if (loader) {
                        loader.afterClosed().subscribe(() => {
                            reject();
                        });

                        loader.close();
                    } else {
                        reject();
                    }
                }
            );
        });
    }

    /**
     * Get filters
     *
     * @returns {Object}
     * @private
     */
    private _getFilters(): Object {
        // get filters
        const filters = {};

        // remove null filters
        for (const name in this.filtersFormGroup.value) {
            let value = this.filtersFormGroup.value[name];

            if (value !== null && value !== undefined) {

                if ([this.filtersFormControlNames.StartCreateDate, this.filtersFormControlNames.EndCreateDate].includes(name)) {
                    if (this.filtersFormControlNames.EndCreateDate == name) {
                        value = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1); // + 1 day since its 00:00, so it will filter correctly
                    }
                    value = Utils.moment(value).format(BASE_DATE_FORMAT);
                }

                filters[name] = value;
            }
        }

        // add partnerID filter when allow is filled
        if (this.filtersFormControlNames.Allowed in filters) {
            filters['partnerID'] = this.partners.map(partner => partner.partnerID);
        }

        return filters;
    }

    /**
     * Check template is allowed at this partner(s)
     *
     * @param {SetTemplatesToPartnersInterfaces.PostTemplate} template
     * @returns {boolean}
     * @private
     */
    private _checkTemplateIsAllowed(template: SetTemplatesToPartnersInterfaces.PostTemplate): boolean {
        const systemType = template.systemType;
        let allowed = false;

        if (systemType) {
            if (systemType === CoreConfig.getSystemTypes().Branded) {

                if (this._isSetInSelectedPartners(template)) {
                    allowed = true;
                }

            } else if (systemType === CoreConfig.getSystemTypes().Generic) {

                if (!this._isSetInSelectedPartners(template)) {
                    allowed = true;
                }

            }
        }

        return allowed;
    }

    /**
     * Check the resource is set in the selected partners
     *
     * @param {SetTemplatesToPartnersInterfaces.PostTemplate} template
     * @return {boolean}
     */
    
    /*private _isSetInSelectedPartners(template: SetTemplatesToPartnersInterfaces.PostTemplate): boolean {
        const key = (template.systemType === CoreConfig.getSystemTypes().Generic)
            ? 'disallowedPartnerIDs'
            : (template.systemType === CoreConfig.getSystemTypes().Branded)
                ? 'partnerIDs'
                : null;
        let result = false;

        if (!key) {
            return false;
        }

        this.partners.forEach(partner => {
            if (template[key].indexOf(partner.partnerID) > -1) {
                result = true;
            }
        });

        return result;
    }*/

    private _isSetInSelectedPartners(template: SetTemplatesToPartnersInterfaces.PostTemplate): boolean {
        if (template.systemType === CoreConfig.getSystemTypes().Generic) {
            return !this.partners.some(partner => (template['disallowedPartnerIDs'].indexOf(partner.partnerID) > -1));
        }

        if (template.systemType === CoreConfig.getSystemTypes().Branded) {
            return this.partners.some(partner => (template['partnerIDs'].indexOf(partner.partnerID) > -1));
        }

        return false;
    }

    /**
     * Open post template preview modal
     *
     * @param {SetTemplatesToPartnersInterfaces.PostTemplate} template
     * @private
     */
    private _openTemplatePreview(template: SetTemplatesToPartnersInterfaces.PostTemplate) {
        this._previewService.showPreview(template);
    }

    /**
     * Change all template isAllowed property on page
     *
     * @param {boolean} value
     * @private
     */
    private _changeTemplatesAllowedOnPage(value: boolean) {

        this.postTemplates = this.postTemplates.map(template => {

            template.isAllowed = value;

            return template;
        });

    }

    /**
     * Change all templates isAllowed property
     *
     * @param {boolean} isAllowed
     * @private
     */
    private _changeAllTemplates(isAllowed: boolean) {
        const requestData: SetTemplatesToPartnersInterfaces.SaveRequestData = {
            allowed: isAllowed ? 'yes' : 'no',
            partnerID: this.partners.map(partner => partner.partnerID),
            filters: this._getFilters()
        };

        this._componentHelpers.startApiAction(
            () => {
                return this._partnerService.setTemplatesToPartners(requestData)
                    .then(response => this._getPostTemplates(false).then(() => Promise.resolve(response)))
            },
            {
                successMessageKey: 'templateSeparator.setTemplatesToPartners.success',
                failedMessageKey: 'templateSeparator.setTemplatesToPartners.error'
            }
        );
    }

}
