import { Injectable } from "~/node_modules/@angular/core";
import { ExportResourceService } from "~/src/app/core/export-resource.service";
import { BehaviorSubject } from "~/node_modules/rxjs";
import Utils from "~/src/app/core/utils";
import { environment } from "~/src/environments/environment";
import { LoggedUser } from "../services/logged-user";
import { DateSupport } from "./helper/date-support";

@Injectable({ providedIn: "root" })
export class ServerTimeService {
    // Server time in timestamp
    private serverTime: number;

    // Formatted server time in observable object
    private _preparedServerTime = new BehaviorSubject<string>("");
    private _preparedUserTime = new BehaviorSubject<string>("");

    private _timeout;

    constructor(private exportResourceService: ExportResourceService) {
        // Start server time calculating
        this.calculateServerTime();
    }

    /**
     * Get formatted server time
     */
    get preparedServerTime() {
        return this._preparedServerTime.asObservable();
    }

    get loggedUserTime() {
        return this._preparedUserTime.asObservable();
    }

    /**
     * Calculate server time
     */
    async calculateServerTime() {
        if (this._timeout) {
            clearTimeout(this._timeout);
        }

        // Exit function when server is sharp
        /* if (!!environment.isSharpMode) {
            return;
        } */

        const serverTimeResponse =
            await this.exportResourceService.getServerTime();

        // Get initial server time
        const serverTime = serverTimeResponse.serverTime;

        // Increment time every one second
        const incrementServerTime = (time: number, utcOffset: string) => {
            // Increment time
            this.serverTime = time + 1;

            // Set prepared server time
            this.setPreparedServerTime(
                this.getFormattedTimeStamp(this.serverTime, utcOffset)
            );
            // Set prepared server time
            this.setPreparedUserTime(
                Utils.moment
                    .tz(
                        Utils.moment().utc(),
                        LoggedUser.getUser().settings.defaultTimeZone ||
                            DateSupport.getClientTimeZoneName()
                    )
                    .format("DD MMMM YYYY, hh:mm A ([GMT] Z)")
            );

            // Call server time incrementer after 1 second
            this._timeout = setTimeout(() => {
                incrementServerTime(this.serverTime, utcOffset);
            }, 1000);
        };

        // Start time incrementer
        incrementServerTime(serverTime, serverTimeResponse.utcOffset);
    }

    /**
     * Set prepared server time
     * @param value
     */
    private setPreparedServerTime(value: string) {
        this._preparedServerTime.next(value);
    }

    private setPreparedUserTime(value: string) {
        this._preparedUserTime.next(value);
    }

    /**
     * Get formatted date from timestamp
     * @param timestamp
     * @param utcOffset
     * @param format
     */
    private getFormattedTimeStamp(
        timestamp: number,
        utcOffset: string,
        format = "DD MMMM YYYY, hh:mm A (GMT Z)"
    ) {
        return Utils.moment(new Date(timestamp * 1000))
            .utcOffset(utcOffset)
            .format(format);
    }
}
