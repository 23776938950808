import {Injectable} from '~/node_modules/@angular/core';
import {ResourceAbstract} from '~/src/app/core/resource.abstract';
import {BackendService} from '~/src/app/core/backend.service';
import {User, UsersFilters, UsersResponse} from '~/src/app/modules/users/users-resource';
import {LoggedUser} from '~/src/app/services/logged-user';
import {BehaviorSubject, Observable} from '~/node_modules/rxjs';
import {Core} from '~/src/app/core/core';

@Injectable({providedIn: 'root'})
export class UsersResourceService extends ResourceAbstract {

    // main API URL
    protected mainApiUrl = '/users';

    // sub API URLs
    protected getMeUrl = this.getFullApiUrl('/me');
    protected updateTokenUrl = this.getFullApiUrl('/update-token');
    protected passwordRecoveryUrl = this.getFullApiUrl('/password-recovery');
    protected passwordResetUrl = this.getFullApiUrl('/password-reset');
    protected authenticateUrl = this.getFullApiUrl('/authenticate');
    protected logoutUrl = this.getFullApiUrl('/logout');
    protected passwordChangeUrl = this.getFullApiUrl('/change-password');
    protected userEditUrl = this.getFullApiUrl('/edit');
    protected userUpdateUrl = this.getFullApiUrl('/update');
    protected twoFactorUrl = this.getFullApiUrl('/twofactor');
    protected twoFactorVerifyUrl = this.getFullApiUrl('/twofactor/verify');
    protected activationEmailReSendUrl = this.getFullApiUrl('/resend-activation-email');

    // entity collections
    private userCollection: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

    constructor(
        private backendService: BackendService
    ) {
        super();
    }

    /**
     * User hydrator
     * @param {User[]} users
     * @returns {User[]}
     */
    static userHydrator(users: User[]): User[] {
        const loggedUser = LoggedUser.getUser();

        return users.map(user => {

            // Save raw datas
            user.rawDatas = {
                partnerLogoImage: user.partnerLogoImage,
                profileImageUrl: user.profileImageUrl,
            };

            // Add domain and token to image URLs
            user.partnerLogoImage = Core.addDomainAndTokenToUrl(user.partnerLogoImage);
            user.profileImageUrl = Core.addDomainAndTokenToUrl(user.profileImageUrl);

            if (!!user.organizations && !!user.organizations.length) {

                // Check user is admin
                user.isAdmin = user.organizations.filter(organization => organization.admin === 'yes').length > 0;

                // Set user main organization
                user.mainOrganization = user.organizations.find(organization => organization.main).name;
            }

            if (loggedUser) {
                user.isCurrentUser = user.userID == loggedUser.userID;
            }

            return user;
        });
    }

    /**
     * Get users
     * @returns {Observable<User[]>}
     */
    get users(): Observable<User[]> {
        return this.userCollection.asObservable();
    }

    /**
     * Get users from server
     * @param {UsersFilters} additionalFilters
     * @returns {Promise<UsersResponse>}
     */
    async getUsers(additionalFilters?: UsersFilters): Promise<UsersResponse> {

        // TODO temporary solution
        if (!!additionalFilters && !!additionalFilters.userIDs) {
            additionalFilters = {
                ...additionalFilters,
                userIDs: JSON.stringify(additionalFilters.userIDs)
            };
        }

        // Get users from backend with additional filters
        const response = await this.backendService.get<UsersResponse>(
            this.mainApiUrl,
            additionalFilters
        );

        // Hydrate users
        const users = UsersResourceService.userHydrator(response.users);

        // Add users to user collection
        this.userCollection.next(users);

        // Refresh users in response
        response.users = users;

        // Return with response
        return response;
    }
}
