import { SettingsImagesForRender, SettingsImagesForUpload } from "./../reports.interface";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileInputComponent } from "ngx-material-file-input";
import { FALLBACK_IMAGE } from "~/src/app/configs/configs";
import { TimezoneService } from "../../../users/social-site-edit-dialog/timezone.service";
import { SettingsModalData } from "../reports.interface";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "smd-report-settings",
  templateUrl: "./report-settings.component.html",
  styleUrls: ["./report-settings.component.scss"],
})
export class ReportSettingsComponent implements OnInit {
  @ViewChild("reportImageInput", { static: false }) reportImageInput: FileInputComponent;
  @ViewChild("companyLogoInput", { static: false }) companyLogoInput: FileInputComponent;

  reportSettings: SettingsModalData;
  settingsFormGroup = new FormGroup({
    name: new FormControl(""),
    timezone: new FormControl("", this.autocompleteStringValidator()),
    image: new FormControl(""),
    dataAggregation: new FormControl(false),
    kpiGoals: new FormControl(false),
    kpiGoalsOwn: new FormControl({ value: false, disabled: true }),
    kpiGoalsShared: new FormControl({ value: false, disabled: true }),
    cover: new FormControl(false),
    companyLogo: new FormControl(""),
  });

  timezones: string[] = [];
  filteredTimezones: Observable<string[]> = new Observable();

  uploadedImages: SettingsImagesForUpload = {};
  renderedImages: SettingsImagesForRender = {
    fallback: FALLBACK_IMAGE,
  };

  constructor(
    public dialogRef: MatDialogRef<ReportSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SettingsModalData,
    public timezoneService: TimezoneService,
    private sanitizer: DomSanitizer
  ) {
    this.reportSettings = data;
  }

  // Filter for timezone autocomplete
  private _tzFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.timezones.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
    // Get timezone list
    this.timezoneService.getAll().then((response) => {
      this.timezones = response;

      this.filteredTimezones = this.settingsFormGroup.get("timezone").valueChanges.pipe(
        startWith(""),
        map((value) => this._tzFilter(value))
      );
    });

    // When kpiGoals is checked, enable kpiGoalsOwn and kpiGoalsShared
    this.settingsFormGroup.get("kpiGoals").valueChanges.subscribe((value) => {
      if (value) {
        this.settingsFormGroup.get("kpiGoalsOwn").enable();
        this.settingsFormGroup.get("kpiGoalsShared").enable();
      } else {
        this.settingsFormGroup.get("kpiGoalsOwn").disable();
        this.settingsFormGroup.get("kpiGoalsOwn").setValue(false);
        this.settingsFormGroup.get("kpiGoalsShared").disable();
        this.settingsFormGroup.get("kpiGoalsShared").setValue(false);
      }
    });

    // Set values if we have them already
    this.settingsFormGroup.patchValue({
      name: this.reportSettings.name,
      timezone: this.reportSettings.timezone,
      image: this.reportSettings.image,
      dataAggregation: this.reportSettings.aggregationEnabled,
      kpiGoals: this.reportSettings.kpiEnabled,
      kpiGoalsOwn: this.reportSettings.kpiOwnEnabled,
      kpiGoalsShared: this.reportSettings.kpiSharedEnabled,
      cover: this.reportSettings.coverEnabled,
      companyLogo: this.reportSettings.companyLogo,
    });

    // Set rendered images
    if (this.reportSettings.image !== null) {
      this.renderedImages.report = this.reportSettings.image;
    }

    if (this.reportSettings.companyLogo !== null) {
      this.renderedImages.companyLogo = this.reportSettings.companyLogo;
    }
  }

  autocompleteStringValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.timezones?.indexOf(control.value) !== -1) {
        return null; /* valid option selected */
      }
      return { invalidAutocompleteString: { value: control.value } };
    };
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openImageChooser(event: MouseEvent, type: string): void {
    if (type === "report") {
      this.reportImageInput.open();
    } else if (type === "company_logo") {
      this.companyLogoInput.open();
    }
  }

  fileChosen(type: string): void {
    if (type === "report") {
      if (this.reportImageInput.value.files.length > 0 && this.reportImageInput.value.files[0] !== null) {
        this.uploadedImages.report = this.reportImageInput.value.files[0];

        const objectURL = URL.createObjectURL(this.reportImageInput.value.files[0]);
        this.renderedImages.report = this.sanitizer.bypassSecurityTrustUrl(objectURL) as string;
      }
    } else if (type === "company_logo") {
      if (this.companyLogoInput.value.files.length > 0 && this.companyLogoInput.value.files[0] !== null) {
        this.uploadedImages.companyLogo = this.companyLogoInput.value.files[0];

        const objectURL = URL.createObjectURL(this.companyLogoInput.value.files[0]);
        this.renderedImages.companyLogo = this.sanitizer.bypassSecurityTrustUrl(objectURL) as string;
      }
    }
  }

  saveReportSettings(): void {
    this.dialogRef.close({
      reportSettings: this.settingsFormGroup.value,
      uploadedImages: this.uploadedImages,
    });
  }
}
