import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import {ChartData} from 'chart.js';
import Utils from '~/src/app/core/utils';
import {LanguageService} from '~/src/app/services/language.service';

export const WIDGET_METRIC_OPTION_LINKEDIN_CLICKS_OVERVIEW: WidgetMetricOptionType = {
    id: 'linkedin-clicks-overview',
    name: 'Clicks Overview',
    resource: 'analytics.analytics.pageClicks'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_CLICKS_BY_PAGE_TYPE: WidgetMetricOptionType = {
    id: 'linkedin-clicks-by-page-type',
    name: 'Clicks by Page Type',
    resource: 'analytics.analytics.pageClicks'
};

// METRIC CONFIGS

export const MetricLinkedInClicksOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_CLICKS_OVERVIEW.id,
    apiURL: '/page-clicks',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Clicks',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'overview'
    }
};

export const MetricLinkedInClicksByPageTypeConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_CLICKS_BY_PAGE_TYPE.id,
    apiURL: '/page-clicks',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Clicks by Page Type',
    getDatasets: (analytics: any) => {
        const langKeyPrefix = 'analytics.metrics.linkedin.pageClicksByPageType.';
        const chartData: ChartData = {
            datasets: [],
        };
        const datas: {[key: string]: any[]} = {};
        const sums: {[key: string]: number} = {};
        const percents: {[key: string]: number} = {};

        // Get sums
        Utils.getObjectKeys(analytics.sum, null, (key: string, value: any) => {
            if (!(value instanceof Object)) {
                sums[key] = value;
            }
        });

        // Get percents
        Utils.getObjectKeys(analytics.percent, null, (key: string, value: any) => {
            if (!(value instanceof Object)) {
                percents[key] = value;
            }
        });

        for (const item of analytics.values) {
            Utils.getObjectKeys(item, null, (key: string, value: any) => {
                if (!(value instanceof Object)) {
                    datas[key] = datas[key] || [];
                    datas[key].push(value);
                }
            });
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                // TODO set value from language service
                label: LanguageService.getLine(langKeyPrefix + propertyName) +
                ` - Sum: ${sums[propertyName]} | Percent: ${percents[propertyName]}%`,
                data: values
            });
        }

        return chartData;
    },
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'pageType'
    }
};
