import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PostTemplateFolderShareDialogData, TemplateFolderInterface } from "../../posts/template-folder.interface";
import { TemplateFolderActionsService } from "../../posts/template-folder-actions.service";
import { OpenModalService } from "../open-modal.service";
import { OrganizationItem } from "~/src/app/components/organization-select/organization.interfaces";
import { OrganizationService } from "../../users/organizations/organization.service";
import { now } from "lodash";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";

@Component({
  selector: "smd-template-folder-share",
  templateUrl: "./template-folder-share.component.html",
  styleUrls: ["./template-folder-share.component.scss"],
})
export class TemplateFolderShareComponent implements OnInit
{
  folder: TemplateFolderInterface = null;
  isAdminMode: boolean = false;

  selectedOrganizations: OrganizationItem[] = [];
  unselectedOrganizations: OrganizationItem[] = [];
  indeterminateOrganizations: OrganizationItem[] = [];
  lockedOrganizations: OrganizationItem[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PostTemplateFolderShareDialogData,
    public dialogRef: MatDialogRef<TemplateFolderShareComponent>,
    public organizationService: OrganizationService,
    private folderService: TemplateFolderActionsService,
    private openModal: OpenModalService,
  ) {
    this.folder = data.folder;
    this.isAdminMode = data.isAdminMode;
  }

  async ngOnInit() {
    const orgList = (await this.organizationService.getList()).organizations.map(organization => {
      return {
          name: organization.name,
          organizationID: organization.organizationID,
      } as OrganizationItem;
    });

    if (orgList) {
      const sharedWithOrgs = this.folder.sharedWithOrgs?.map(org => Number(org.organizationID)) || [];
      this.lockedOrganizations = orgList.filter(organization => this.folder.organizationID === organization.organizationID);
      this.selectedOrganizations = orgList.filter(organization => sharedWithOrgs.includes(organization.organizationID) || this.folder.organizationID === organization.organizationID);
      this.unselectedOrganizations = orgList.filter(organization => !sharedWithOrgs.includes(organization.organizationID));
      this.indeterminateOrganizations = orgList.filter(organization => sharedWithOrgs.includes(organization.organizationID));
    }
  }

  saveSharing() {
    const nowSharedWith: number[] = this.folder.sharedWithOrgs?.map(org => Number(org.organizationID)) || [];
    const ignoredOrganizationIDs: number[] = this.indeterminateOrganizations.map(organization => organization.organizationID);
    const addOrganizationIDs: number[] = this.selectedOrganizations.map(organization => organization.organizationID).filter(organizationID => organizationID !== this.folder.organizationID && !ignoredOrganizationIDs.includes(organizationID));
    const removeOrganizationIDs: number[] = this.unselectedOrganizations.map(organization => organization.organizationID).filter(organizationID => nowSharedWith.includes(organizationID));
    
    const loader = this.openModal.loader(DialogLoaderComponent);

    if (addOrganizationIDs.length === 0 && removeOrganizationIDs.length === 0) {
      this.openModal.successModal(DialogSuccessComponent, {
        message: 'No changes to save',
      });
      loader.close();
      this.closeDialog();
      return;
    }

    this.folderService.shareFolder(this.folder.folderID, {
      organizationsToAdd: addOrganizationIDs,
      organizationsToRemove: removeOrganizationIDs,
    }).then((response: any) => {
      if (response.success) {
        this.data.afterSuccessSave();
        let successMessage = 'Success';
        if (response.success.addedCount > 0 && response.success.removedCount === 0) {
          successMessage = `Shared with ${response.success.addedCount} organizations`;
        } else if (response.success.addedCount === 0 && response.success.removedCount > 0) {
          successMessage = `Removed sharing from ${response.success.removedCount} organizations`;
        } else if (response.success.addedCount > 0 && response.success.removedCount > 0) {
          successMessage = `Shared with ${response.success.addedCount} organizations, removed from ${response.success.removedCount} organizations`;
        }
        this.openModal.successModal(DialogSuccessComponent, {
          message: successMessage,
        });
      }
    }).catch((error: any) => {
      this.openModal.errorModal(DialogErrorComponent, {
        message: error.error.message,
      });
    }).finally(() => {
      loader.close();
    });

    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}