
export class ObjectSupport {

    /**
     * Check object contain key
     *
     * @param {T} object
     * @param {keyof T} key
     * @returns {boolean}
     */
    static contain<T = Object>(object: T, key: keyof T) {
        return key in object;
    }

    /**
     * Recursive property get from a object
     *
     * @param {T} object
     * @param {string} propertyName
     * @param {boolean} onlyValue
     * @returns {{[p: string]: any}}
     */
    public static get<T = null>(object: T, propertyName: string, onlyValue = false): {[p: string]: any} {

        if (object[propertyName]) {
            if (onlyValue) {
                return object[propertyName];
            } else {
                return {
                    [propertyName]: object[propertyName]
                };
            }
        } else {
            let result = onlyValue ? null : {};

            for (const key in object) {
                if (object[key] instanceof Object) {
                    result = ObjectSupport.get(object[key], propertyName);
                }
            }

            return result;
        }

    }

    /**
     * Safe parse string
     *
     * @param data
     * @returns {null | any}
     */
    static parse(data) {
        try {
            data = JSON.parse(data);
        } catch (e) {}

        if (!data || data === '') {
            return null;
        }

        return data;
    }

    public static multipleGet<T = null>(object: T, propertyNames: string[], onlyValue = false): {[p: string]: any} | any[] {
        const resultArray = [];
        let resultObject = {};

        for (const name of propertyNames) {
            if (onlyValue) {
                resultArray.push(
                    ObjectSupport.get(object, name, onlyValue)
                );
            } else {
                resultObject = {
                    ...resultObject,
                    ...ObjectSupport.get(object, name, onlyValue)
                };
            }
        }

        if (onlyValue) {
            return resultArray;
        } else {
            return resultObject;
        }
    }

}
