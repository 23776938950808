import {ModelAbstract} from './model.abstract';
import {Helpers} from './helpers';
import {HttpEventType} from '@angular/common/http';
import {OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Configs, VIEW_BASE_DATETIME_FORMAT} from '../configs/configs';
import {PaginationController, PaginationOptions} from './pagination.controller';
import {Plugins} from './plugins';

export interface OrderOptions {
    by: string;
    dir: string;
}

export interface FilterOptions {
    limit: number;
    offset: number;
    order: string;
}

export abstract class ComponentAbstract implements OnInit {

    @ViewChild(MatPaginator) pagination: MatPaginator;

    /**
     * Model service
     *
     * Set in child
     */
    service: ModelAbstract;

    /**
     * TinyMCE apiKey
     *
     * @type {string}
     */
    tinyMceKey: string = Configs.keys.tinyMce;

    formErrorMessages: object = {};

    private _itemLimit: number = Plugins.pagination.pageSizeOptions[1];

    /**
     * Order options
     *
     * @type {{by: string; dir: string}}
     */
    order: OrderOptions = {
        by: 'none',
        dir: 'ASC'
    };

    /**
     * Default filters options
     *
     * @type {{limit: number; offset: number; order: string}}
     */
    defaultFilters: FilterOptions = {
        limit: this.itemLimit,
        offset: 0,
        order: `{"${this.order.by}":"${this.order.dir}"}`
    };

    public errorMessages: object = {};

    /**
     * Filters options
     *
     * @type {{limit: number; offset: number; order: string}}
     */
    public filters: object | FilterOptions = this.getDefaultFilterOptions();

    paginationController: PaginationController = new PaginationController();

    protected constructor() {
        this.paginationController.onPaginationChange.subscribe(event => {
            this.setPagination(event);
            this.getItems();
        });
    }

    ngOnInit(): void {
        this.paginationController.setPaginator(this.pagination);
    }

    getDefaultFilterOptions() {
        return {
            limit: this.itemLimit,
            offset: 0,
            order: `{"${this.order.by}":"${this.order.dir}"}`
        };
    }

    /**
     * Get items
     *
     * @param {(response: any) => void} callback
     * @param {object} filters
     */
    getItems(callback: (response: any) => void = () => {}, errorCallback: (error: any) => void = () => {}): void {
        this.beforeGetItems();
        this.beforeDataLoad();

        let options = this.filters;

        options = Helpers.objectToQueryOptions(options);

        this.service.getAll(options)
            .then(response => {
                callback(response);
                this.afterGetItems();
                this.afterDataLoad();
                this.successGetItems(response);
            })
            .catch(error => {
                errorCallback(error);
                this.afterGetItems();
                this.afterDataLoad();
                this.failedGetItems(error);
            });
    }

    getItemByID(options: object = {}): Promise<any> {
        return this.service.getAll(Helpers.objectToQueryOptions(options));
    }

    getItemsByIDs<T>(name: string, IDs: any[], filters = null): Promise<T> {
        this.beforeGetItems();
        return this.service.getByIDs(name, IDs, filters).then(response => {
            this.afterGetItems();
            return response;
        });
    }

    /**
     * Create item
     *
     * @param {object} item
     * @param {(response: any) => void} success
     * @param {(error: any) => void} error
     */
    createItem(item: object | FormData, success: (response: any) => void = response => {}, errorCallback: (error: any) => void = error => {}): void {
        this.beforeCreate();
        this.beforeDataLoad();

        this.service.create(item)
            .then(response => {
                this.afterCreate();
                this.afterDataLoad();
                success(response);
                this.successCreate(response);
            })
            .catch(error => {
                this.afterCreate();
                this.afterDataLoad();
                errorCallback(error);
                this.failedCreate(error);
            });
    }

    /**
     * Create item with progress
     *
     * @param {object} item
     */
    createItemWithProgress(item: object): void {
        this.service.createWithProgress(item)
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.createInProgress(event);
                } else if (event.type === HttpEventType.Response) {
                    this.successCreateWithProgress(event, item);
                }
            }, error => {
                this.failedCreateWithProgress(error, item);
                this.service.showErrorAlert(error);
            });
    }

    /**
     * Edit item
     *
     * @param {number} id
     * @param {object} item
     */
    editItem(id: number, item: object): void {
        this.beforeEdit();
        this.beforeDataLoad();

        this.service.edit(id, item)
            .then(response => {
                this.afterEdit(response);
                this.afterDataLoad();
                this.successEdit(response);
            })
            .catch(error => {
                this.afterEdit(error);
                this.afterDataLoad();
                this.failedEdit(error);
            });
    }

    /**
     * Edit item with post request
     *
     * @param {number} id
     * @param {object} item
     */
    editItemWithPost(id: number, item: object, success: (response) => void = () => {}, failed: (error) => void = () => {}): void {
        this.beforeEditWithPost();
        this.beforeDataLoad();

        this.service.editWithPost(id, item)
            .then(response => {
                success(response);
                this.afterEditWithPost();
                this.afterDataLoad();
                this.successEditWithPost(response);
            })
            .catch(error => {
                failed(error);
                this.afterEditWithPost();
                this.afterDataLoad();
                this.failedEditWithPost(error);
            });
    }

    /**
     * Delete item by ID
     *
     * @param {number} id
     */
    deleteItem(id: number, success: (response) => void = () => {}, failed: (error) => void = () => {}): void {
        this.beforeDelete();
        this.beforeDataLoad();

        this.service.deleteItem(id)
            .then(response => {
                success(response);
                this.afterDelete();
                this.afterDataLoad();
                this.successDelete(response);
            })
            .catch(error => {
                failed(error);
                this.afterDelete();
                this.afterDataLoad();
                this.failedDelete(error);
            });
    }

    /**
     * Set filters default options
     */
    clearFilters(): void {
        this.filters = this.getDefaultFilterOptions();
    }

    /**
     * Pagination change event
     *
     * @param {object} event
     */
    onPaginateChange(event) {
        this.paginationController.paginateChange(event);
    }

    setPagination(pagination) {
        this.filters['offset'] = this.paginationOptions.pageIndex * this.paginationOptions.pageSize;
        this.filters['limit'] = this.paginationOptions.pageSize;
    }

    /**
     * Filter change event
     *
     * @param filters
     */
    filtersChange(filters) {
        this.paginationController.paginationOptions.pageIndex = 0;
        /* tslint:disable */
        for (const filterName in filters) {
            let filter = filters[filterName];

            if (filterName === 'order') {
                filter = this.getOrderFormat(filter);
            }

            if (filter !== null) {
                this.filters[filterName] = filter;
            } else {
                delete this.filters[filterName];
            }
        }
        /* tslint:enable */

        if (this.pagination) {
            this.setPagination({
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize
            });
        }

        this.getItems();
    }

    /**
     * Get orderBy format
     *
     * @param {string} value
     * @return {string}
     */
    getOrderFormat(value: string): string {
        value = value || 'none-none';

        const order = value.split('-');

        return `{"${order[0]}":"${order[1]}"}`;
    }

    /**
     * Go to first pagination page, if is not first
     *
     * @return {boolean}
     */
    goToFirstPage(): boolean {
        if (this.pagination.hasPreviousPage()) {
            this.pagination.firstPage();

            return true;
        }

        return false;
    }

    /**
     * Set query filters
     *
     * @param {{filterName: string; filterValue: string | number | boolean}[]} filters
     */
    setFilters(filters: {filterName: string, filterValue: string | number | boolean}[], getItems = true) {
        for (const filter of filters) {
            this.filters[filter.filterName] = filter.filterValue;
        }

        if (getItems) {
            this.getItems();
        }
    }

    /**
     * Before data load event
     */
    beforeDataLoad(): void {}

    /**
     * After data load event
     */
    afterDataLoad(): void {}

    /**
     * Before delete event
     */
    beforeDelete(): void {}

    /**
     * After delete event
     */
    afterDelete(): void {}

    /**
     * Success delete callback
     *
     * @param response
     */
    successDelete(response: any): void {}

    /**
     * Failed delete callback
     *
     * @param error
     */
    failedDelete(error: any): void {}

    /**
     * Before edit event
     */
    beforeEdit(): void {}

    /**
     * After edit event
     */
    afterEdit(response: any): void {}

    /**
     * Success edit callback
     *
     * @param response
     */
    successEdit(response: any): void {}

    /**
     * Failed edit callback
     *
     * @param error
     */
    failedEdit(error: any): void {}

    /**
     * Before edit with post event
     */
    beforeEditWithPost(): void {}

    /**
     * After edit with post event
     */
    afterEditWithPost(): void {}

    /**
     * Success edit with post callback
     *
     * @param response
     */
    successEditWithPost(response: any): void {}

    /**
     * Failed edit with post callback
     *
     * @param error
     */
    failedEditWithPost(error: any): void {}

    /**
     * Before create event
     */
    beforeCreate(): void {}

    /**
     * After create event
     */
    afterCreate(): void {}

    /**
     * Success create callback
     *
     * @param response
     */
    successCreate(response: any): void {}

    /**
     * In progress event in create with progress method
     *
     * @param event
     * @return {any}
     */
    createInProgress(event: any): void {}

    /**
     * Success create with progress callback
     *
     * @param response
     * @param request
     */
    successCreateWithProgress(response: any, request: any): void {}

    /**
     * Failed create with progress callback
     *
     * @param error
     * @param request
     */
    failedCreateWithProgress(error: any, request: any): void {}

    /**
     * Failed create callback
     *
     * @param error
     * @param enable
     */
    failedCreate(error: any, enable = true): void {
        if (enable) {
            this.service.showErrorAlert(error);
        }
    }

    /**
     * Before getItems event
     */
    beforeGetItems(): void {}

    /**
     * After getItems event
     */
    afterGetItems(): void {}

    /**
     * Success getItems callback
     *
     * @param response
     */
    successGetItems(response: any): void {}

    /**
     * Failed getItems callback
     *
     * @param error
     */
    failedGetItems(error: any): void {}

    /**
     * Get items limit
     *
     * @return {number}
     */
    get itemLimit(): number {
        return this._itemLimit;
    }

    /**
     * Set items limit
     *
     * @param {number} value
     */
    set itemLimit(value: number) {
        this.paginationOptions.pageSize = value;
        this.filters['limit'] = value;
        this._itemLimit = value;
    }

    get viewBaseDateTimeFormat() {
        return VIEW_BASE_DATETIME_FORMAT;
    }

    get paginationOptions(): PaginationOptions {
        return this.paginationController.paginationOptions;
    }
}
