import {Injectable} from '@angular/core';
import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {HASHTAG_API_RESOURCE_HASHTAG} from '~/src/app/modules/hashtag/hashtag.constant';
import {IHashtag, IHashtagFormData, IHashtagList} from '~/src/app/modules/hashtag/hashtag.interface';
import {HashtagModel} from '~/src/app/modules/hashtag/hashtag.model';

@Injectable({
    providedIn: 'root'
})
export class HashtagService {

    constructor(
        private backend: BackendService
    ) {}

    /**
     * List hashtag items
     *
     * @param {RequestParams} params
     * @return {Promise<IHashtagList<HashtagModel>>}
     */
    getList(params: RequestParams = {}): Promise<IHashtagList<HashtagModel>> {
        return this.backend.get(`${HASHTAG_API_RESOURCE_HASHTAG}`, params)
            .then((response: IHashtagList<IHashtag>) => {
                const result: IHashtagList<HashtagModel> = {
                    ...response,
                    tags: response.tags.map(tag => new HashtagModel(tag))
                };
                return Promise.resolve(result);
            });
    }

    /**
     * Create new hashtag
     *
     * @param {IHashtagFormData} data
     * @return {Promise<null>}
     */
    create(data: IHashtagFormData) {
        if (data.tag instanceof Array) {
            return this._bulkCreate(data);
        }

        return this.backend.post(`${HASHTAG_API_RESOURCE_HASHTAG}`, data);
    }

    /**
     * Update hashtag
     *
     * @param {number} tagID
     * @param {IHashtagFormData} data
     * @return {Promise<null>}
     */
    update(tagID: number, data: IHashtagFormData) {
        return this.backend.put(`${HASHTAG_API_RESOURCE_HASHTAG}/${tagID}`, data);
    }

    /**
     * Remove hashtag
     *
     * @param {number | number[]} tagID
     * @return {Promise<any>}
     */
    remove(tagID: number | number[]) {

        if (tagID instanceof Array) {
            return this._bulkRemove(tagID);
        }

        return this.backend.delete(`${HASHTAG_API_RESOURCE_HASHTAG}/${tagID}`);
    }

    /**
     * Bulk hashtag create
     *
     * @param {IHashtagFormData} data
     * @return {Promise<null>}
     * @private
     */
    _bulkCreate(data: IHashtagFormData) {
        return this.backend.post(`${HASHTAG_API_RESOURCE_HASHTAG}/bulk-create`, data);
    }

    /**
     * Bulk hashtag remove
     *
     * @param {number[]} tagIDs
     * @return {Promise<null>}
     */
    _bulkRemove(tagIDs: number[]) {
        return this.backend.post(`${HASHTAG_API_RESOURCE_HASHTAG}/bulk-delete`, {tagID: tagIDs});
    }
}
