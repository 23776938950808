import {ComponentAbstract} from '../../../services/component.abstract';
import {SocialManagerService} from './social-manager.service';
import {Configs, DEFAULT_TIMEZONE} from '../../../configs/configs';

interface SocialSiteCommonInterface {
    accessToken: string;
    userAccessToken?: string;
    organizationID: string;
    socialType?: string;
    pageAccessTokenExpire?: number;
    connectedFacebookPageID?: string;
}

interface PageInterface extends SocialSiteCommonInterface {
    pageID: string;
    pageName: string;
}

interface ProfileInterface extends SocialSiteCommonInterface {
    userID: string;
    userName: string;
    accessTokenSecret?: string;
}

interface GroupInterface extends SocialSiteCommonInterface {
    groupID: string;
    groupName: string;
}

interface SiteModifyInterface {
    name?: string;
    timezone?: string;
    pageAccessToken?: string;
    userAccessToken?: string;
    pageAccessTokenExpire?: number;
    accessTokenSecret?: string;
    connectedFacebookPageID?: string;
}

export class SocialManagerComponent extends ComponentAbstract {
    defaultSocialType: string = Configs.socials[0].id;
    timezone = DEFAULT_TIMEZONE;

    constructor(
        public service: SocialManagerService
    ) {
        super();
    }

    /**
     * @param {PageInterface[]} pages
     * @return {Promise<any>}
     */
    addPages(pages: PageInterface[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const requestPages = [];

            for (const page of pages) {
                const _socialType = page.socialType ? page.socialType : this.defaultSocialType;
                const data = {
                    socialType: _socialType,
                    pageID: page.pageID,
                    pageAccessToken: page.accessToken,
                    name: page.pageName,
                    timezone: this.timezone,
                    pageType: 'page',
                    organizationID: page.organizationID
                };

                if (page.pageAccessTokenExpire) {
                    data['pageAccessTokenExpire'] = page.pageAccessTokenExpire;
                }

                if (page.userAccessToken) {
                    data['userAccessToken'] = page.userAccessToken;
                }

                if (page.connectedFacebookPageID) {
                    data['connectedFacebookPageID'] = page.connectedFacebookPageID;
                }

                requestPages.push(data);
            }


            const datas = {
                sites: JSON.stringify(requestPages),
            };

            this.createItem(datas, response => resolve(response), error1 => reject(error1));

        });
    }

    /**
     * @param {ProfileInterface[]} profiles
     * @return {Promise<any>}
     */
    addProfiles(profiles: ProfileInterface[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            const requestProfiles = [];

            for (const profile of profiles) {
                const data = {
                    socialType: profile.socialType || this.defaultSocialType,
                    pageID: profile.userID,
                    pageAccessToken: profile.accessToken,
                    name: profile.userName,
                    timezone: this.timezone,
                    pageType: 'user',
                    organizationID: profile.organizationID
                };

                if (profile.accessTokenSecret) {
                    data['pageAccessTokenSecret'] = profile.accessTokenSecret;
                }

                if (profile.pageAccessTokenExpire) {
                    data['pageAccessTokenExpire'] = profile.pageAccessTokenExpire;
                }

                requestProfiles.push(data);
            }

            const datas = {
                sites: JSON.stringify(requestProfiles)
            };

            this.createItem(datas, response => resolve(response), error1 => reject(error1));

        });
    }

    /**
     * @param {GroupInterface[]} groups
     * @return {Promise<any>}
     */
    addGroups(groups: GroupInterface[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            const requestGroups = [];

            for (const group of groups) {
                const data = {
                    socialType: this.defaultSocialType,
                    pageID: group.groupID,
                    pageAccessToken: group.accessToken,
                    name: group.groupName,
                    timezone: this.timezone,
                    pageType: 'group',
                    organizationID: group.organizationID
                };

                requestGroups.push(data);
            }

            const datas = {
                sites: JSON.stringify(requestGroups)
            };

            this.createItem(datas, response => resolve(response), error1 => reject(error1));

        });
    }

    /**
     * @param {number} siteID
     * @return {Promise<any>}
     */
    deletePage(siteID: string | number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.deleteItem(siteID as number, response => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    }

    /**
     * @param {number} siteID
     * @param {SiteModifyInterface} item
     * @return {Promise<any>}
     */
    editPage(siteID: number, item: SiteModifyInterface): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.editItemWithPost(siteID, item, response => {
                resolve(response);
            }, failed => {
                reject(failed);
            });
        });
    }
}
