import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'smd-partner-info-modal',
  templateUrl: './partner-info-modal.component.html',
  styleUrls: ['./partner-info-modal.component.scss']
})
export class PartnerInfoModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
