import { Injectable } from "@angular/core";
import { FormMessagesService } from "./form.messages.service";
import Utils from "~/src/app/core/utils";

@Injectable()
export class FormValidationService {
    constructor() {}

    private static validate(controls: object): object[] {
        const messages: object[] = [];

        for (const inputName in controls) {
            if ("controls" in controls[inputName]) {
                const recursiveMessages = this.validate(
                    controls[inputName].controls
                );
                messages.push(...recursiveMessages);
            }

            let controlHasError = false;
            for (const errorName in controls[inputName].errors) {
                if (controlHasError) {
                    continue;
                }
                if (errorName === "minlength") {
                    messages.push({
                        inputName: inputName,
                        message: FormMessagesService.getError(errorName, {
                            min: controls[inputName].errors[errorName]
                                .requiredLength,
                        }),
                    });
                    controlHasError = true;
                } else if (errorName === "maxlength") {
                    messages.push({
                        inputName: inputName,
                        message: FormMessagesService.getError(errorName, {
                            max: controls[inputName].errors[errorName]
                                .requiredLength,
                        }),
                    });
                    controlHasError = true;
                } else if (errorName === "max") {
                    messages.push({
                        inputName: inputName,
                        message: FormMessagesService.getError(errorName, {
                            max: controls[inputName].errors.requiredValue,
                        }),
                    });
                    controlHasError = true;
                } else if (errorName === "min") {
                    messages.push({
                        inputName: inputName,
                        message: FormMessagesService.getError(errorName, {
                            min: controls[inputName].errors.requiredValue,
                        }),
                    });
                    controlHasError = true;
                } else {
                    messages.push({
                        inputName: inputName,
                        message: FormMessagesService.getError(errorName),
                    });
                    controlHasError = true;
                }
            }
        }

        return messages;
    }

    public static getMessages(controls: object): object {
        const messages = this.validate(controls),
            inputMessages = {};

        for (const index in messages) {
            const message = messages[index];
            if (message["message"]) {
                inputMessages[message["inputName"]] = message["message"];
            }
        }

        return inputMessages;
    }

    /**
     * Show messages on form version
     */

    public static setFormControlsIncorrect(
        error,
        formGroup,
        formErrorMessages
    ) {
        const errors = FormValidationService.readError(error);

        formErrorMessages = {
            ...formErrorMessages,
            ...errors["formMessages"],
        };

        if (errors["formMessages"]) {
            for (const inputName in errors["formMessages"]) {
                if (formGroup && formGroup.controls[inputName]) {
                    formGroup.controls[inputName].setErrors({
                        incorrect: false,
                    });
                }
            }
        }

        return formErrorMessages;
    }

    /**
     * Read error messages from response
     *
     * @param errorResponse
     * @return {{base: any}}
     */
    public static readError(errorResponse: any) {
        const info = Utils.lodash.get(errorResponse, "error.error.info", null);

        let infoMessages = info ? Utils.lodash.get(info, "messages", {}) : {};
        // deletable
        const keys = Object.keys(infoMessages);

        if (infoMessages && !Object.keys(infoMessages).length) {
            const fieldErrors = Utils.get(
                errorResponse,
                "error.error.errors",
                null
            );
            infoMessages = {};

            if (fieldErrors && fieldErrors instanceof Object) {
                for (const fieldName in fieldErrors) {
                    let fieldMessage = fieldErrors[fieldName];

                    if (
                        fieldMessage &&
                        fieldMessage instanceof Array &&
                        fieldMessage.length
                    ) {
                        fieldMessage = fieldMessage[0];
                    }

                    infoMessages[fieldName] = fieldMessage;
                }
            }
        }

        return {
            // deletable
            base: Utils.lodash.get(errorResponse, "error.error.message", ""),
            // deletable
            firstSpecify: infoMessages[keys[0]] || "",
            message: Utils.lodash.get(errorResponse, "error.error.message", ""),
            formMessages: infoMessages,
            info,
        };
    }
}
