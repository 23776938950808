import { MetricPostsByOriginConfig, MetricPostsByApprovalStatusConfig, MetricPostsByPublishingStatusConfig } from './facebook/activities';
import { ChartConfiguration, ChartData } from "~/node_modules/@types/chart.js";
import {
    MetricLikesGrowthConfig,
    MetricLikesGrowthPercentConfig,
    MetricLikesOverallConfig,
    MetricLikesOverviewConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/likes";
import {
    MetricReachOrganicConfig,
    MetricReachOverviewConfig,
    MetricReachPaidConfig,
    MetricReachTotalConfig,
    MetricReachViralConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/reach-overview";
import {
    MetricActivitiesByOrganizationsConfig,
    MetricActivitiesByTeamMembersConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/activities";
import { MetricClicksConfig } from "~/src/app/modules/analytics/widget/metric-configs/facebook/clicks";
import {
    MetricEngagementConfig,
    MetricEngagementRateConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/engagement";
import {
    MetricImpressionOrganicConfig,
    MetricImpressionOverviewConfig,
    MetricImpressionPaidConfig,
    MetricImpressionTotalConfig,
    MetricImpressionViralConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/impression";
import { MetricOrganizationPerformanceConfig } from "~/src/app/modules/analytics/widget/metric-configs/facebook/organizations-performance";
import {
    MetricPostsEngagementConfig,
    MetricPostsImpressionsConfig,
    MetricTopPostsByEngagementConfig,
    MetricTopPostsByImpressionsConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/posts";
import {
    MetricViewPagesConfig,
    MetricViewVideosOrganicConfig,
    MetricViewVideosPaidConfig,
    MetricViewVideosTotalConfig,
    //MetricViewVideosUniqueConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/views";
import {
    MetricLinkedInClicksByPageTypeConfig,
    MetricLinkedInClicksOverviewConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/clicks";
import {
    MetricLinkedInFollowersByAssociationConfig,
    MetricLinkedInFollowersByFunctionConfig,
    MetricLinkedInFollowersBySeniorityConfig,
    MetricLinkedInFollowersByStaffCountRangeConfig,
    MetricLinkedInFollowersGrowthConfig,
    MetricLinkedInFollowersGrowthOrganicConfig,
    MetricLinkedInFollowersGrowthPaidConfig,
    MetricLinkedInFollowersOverviewConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/followers";
import {
    MetricLinkedInImpressionsOverviewConfig,
    MetricLinkedInImpressionsTotalConfig,
    MetricLinkedInImpressionsUniqueConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/impressions";
import {
    MetricLinkedInInteractionsClickCountConfig,
    MetricLinkedInInteractionsCommentCountConfig,
    MetricLinkedInInteractionsEngagementConfig,
    MetricLinkedInInteractionsLikeCountConfig,
    MetricLinkedInInteractionsMentionsCommentConfig,
    MetricLinkedInInteractionsMentionsConfig,
    MetricLinkedInInteractionsMentionsShareConfig,
    MetricLinkedInInteractionsMentionsTotalConfig,
    MetricLinkedInInteractionsOverviewConfig,
    MetricLinkedInInteractionsShareCountConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/interactions";
import {
    MetricLinkedInPageViewsByCategoriesConfig,
    MetricLinkedInPageViewsByCountryConfig,
    MetricLinkedInPageViewsByFunctionConfig,
    MetricLinkedInPageViewsByIndustryConfig,
    MetricLinkedInPageViewsByPlatformConfig,
    MetricLinkedInPageViewsByRegionConfig,
    MetricLinkedInPageViewsBySeniorityConfig,
    MetricLinkedInPageViewsByStaffCountRangeConfig,
    MetricLinkedInPageViewsOverviewConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/page-views";
import {
    MetricLinkedInPostsCommentsConfig,
    MetricLinkedInPostsEngagementAndPostFrequencyConfig,
    MetricLinkedInPostsEngagementSummaryConfig,
    MetricLinkedInPostsPersonalProfilePerformanceSummaryConfig,
    MetricLinkedInPostsPostFrequencyConfig,
    MetricLinkedInPostsReactionsConfig,
    MetricLinkedInPostsTopPostsByEngagement,
    MetricLinkedInPostsTotalEngagementConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/posts";

import {
    MetricLinkedInViewVideosOverviewConfig,
    MetricLinkedInViewVideosTotalConfig,
    MetricLinkedInViewVideosUniqueConfig,
} from "~/src/app/modules/analytics/widget/metric-configs/linkedin/views";
import { MetricLinkedInOverviewConfig } from "~/src/app/modules/analytics/widget/metric-configs/linkedin/overview";
import { MetricInstagramClicksOverview, MetricInstagramClicksEmailContact, MetricInstagramClicksDirections, MetricInstagramClicksPhoneCall, MetricInstagramClicksTextMessage, MetricInstagramClicksWebsite, MetricInstagramViewsProfile, MetricInstagramTopPostsByEngagementConfig, MetricInstagramTopPostsByImpressionConfig, MetricInstagramClicksTotal, MetricInstagramEngagementLikeConfig, MetricInstagramEngagementCommentConfig, MetricInstagramEngagementOverviewConfig, MetricInstagramEngagementReplyConfig, MetricInstagramEngagementSaveConfig, MetricInstagramEngagementTotalConfig, MetricInstagramFollowersGrowthConfig, MetricInstagramFollowersOverallConfig, MetricInstagramPostsPostFrequencyConfig, MetricInstagramPostsPostFrequencyCarouselsConfig, MetricInstagramPostsPostFrequencyImagesConfig, MetricInstagramPostsPostFrequencyTotalConfig, MetricInstagramPostsPostFrequencyVideosConfig, MetricInstagramStoriesPerformanceConfig, MetricInstagramStoriesFrequencyConfig, MetricInstagramStoryExitsConfig, MetricInstagramStoryImpressionsConfig, MetricInstagramStoryReachConfig, MetricInstagramStoryRepliesConfig, MetricInstagramStoryTapsBackConfig, MetricInstagramStoryTapsForwardConfig, MetricInstagramAudienceByAgeConfig, MetricInstagramAudienceByAgeMenConfig, MetricInstagramAudienceByAgeUnspecifiedConfig, MetricInstagramAudienceByAgeWomenConfig, MetricInstagramAudienceByCitiesConfig, MetricInstagramAudienceByCountriesConfig, MetricInstagramAudienceByGenderConfig } from "./instagram/metrics";
//  Facebook select groups
export const WIDGET_METRIC_GROUP_LIKES = "Likes";
export const WIDGET_METRIC_GROUP_REACH = "Reach";
export const WIDGET_METRIC_GROUP_IMPRESSION = "Impression";
export const WIDGET_METRIC_GROUP_ENGAGEMENT = "Engagement";
export const WIDGET_METRIC_GROUP_VIEWS = "Views";
export const WIDGET_METRIC_GROUP_CLICKS = "Clicks";
export const WIDGET_METRIC_GROUP_POSTS = "Posts";

//  LinkedIn select groups
export const WIDGET_LINKEDIN_METRIC_GROUP_OVERVIEWS = "Overviews";
export const WIDGET_LINKEDIN_METRIC_GROUP_INTERACTIONS = "Interactions";
export const WIDGET_LINKEDIN_METRIC_GROUP_FOLLOWERS = "Followers";
export const WIDGET_LINKEDIN_METRIC_GROUP_IMPRESSIONS = "Impressions";
export const WIDGET_LINKEDIN_METRIC_GROUP_PAGE_VIEWS = "Page Views";
export const WIDGET_LINKEDIN_METRIC_GROUP_CLICKS = "Clicks";
export const WIDGET_LINKEDIN_METRIC_GROUP_POSTS = "Posts";

export type SocialChannels =
    | "facebook"
    | "linkedIn"
    | "twitter"
    | "instagram"
    | "gmb"
    | "organization"; // custom org widgets

export type MetricContentType = "list" | "html" | "chart" | "summary" | "table";

export type MetricExtensionType =
    | "user"
    | "organization"
    | "seniority"
    | "function"
    | "region"
    | "industry"
    | "country";

export interface MetricConfigs {
    metricID: string;
    socialType: SocialChannels;
    contentType: MetricContentType;
    apiURL?: string;
    extensionType?: MetricExtensionType;
    chartConfig?: ChartConfiguration;
    defaultFilters?: { [key: string]: any };
    partMetricIDs?: string[];
    unavailableSocialTypes?: SocialChannels[];
    replaceMetricIDMap?: { [key in SocialChannels]?: string };
    // TODO set config values from language service
    dataName?: string;
    nullable?: boolean;
    htmlTemplate?: (dataName: string, response: any) => string;
    multiSocialSite?: boolean;
    getDatasets?: (analytics: any, extensionData?: any[]) => ChartData;
    hydrateResponse?: (response: any) => any;
    firstPossibleDate?: Date;
}

export const AllMetricConfig: MetricConfigs[] = [
    // facebook
    MetricActivitiesByTeamMembersConfig,
    MetricActivitiesByOrganizationsConfig,
    MetricPostsByOriginConfig,
    MetricPostsByApprovalStatusConfig,
    MetricPostsByPublishingStatusConfig,

    MetricClicksConfig,

    MetricEngagementConfig,
    MetricEngagementRateConfig,

    MetricImpressionTotalConfig,
    MetricImpressionOrganicConfig,
    MetricImpressionPaidConfig,
    MetricImpressionViralConfig,
    MetricImpressionOverviewConfig,

    MetricLikesOverviewConfig,
    MetricLikesGrowthConfig,
    MetricLikesGrowthPercentConfig,
    MetricLikesOverallConfig,

    MetricOrganizationPerformanceConfig,

    MetricReachOverviewConfig,
    MetricReachTotalConfig,
    MetricReachPaidConfig,
    MetricReachOrganicConfig,
    MetricReachViralConfig,

    MetricPostsEngagementConfig,
    MetricPostsImpressionsConfig,

    MetricTopPostsByEngagementConfig,
    MetricTopPostsByImpressionsConfig,

    MetricViewPagesConfig,
    MetricViewVideosOrganicConfig,
    MetricViewVideosPaidConfig,
    MetricViewVideosTotalConfig,

    // linkedIn
    MetricLinkedInClicksOverviewConfig,
    MetricLinkedInClicksByPageTypeConfig,

    MetricLinkedInFollowersByAssociationConfig,
    MetricLinkedInFollowersByFunctionConfig,
    MetricLinkedInFollowersBySeniorityConfig,
    MetricLinkedInFollowersByStaffCountRangeConfig,
    MetricLinkedInFollowersGrowthConfig,
    MetricLinkedInFollowersGrowthOrganicConfig,
    MetricLinkedInFollowersGrowthPaidConfig,
    MetricLinkedInFollowersOverviewConfig,

    MetricLinkedInImpressionsOverviewConfig,
    MetricLinkedInImpressionsTotalConfig,
    MetricLinkedInImpressionsUniqueConfig,

    MetricLinkedInInteractionsOverviewConfig,
    MetricLinkedInInteractionsClickCountConfig,
    MetricLinkedInInteractionsEngagementConfig,
    MetricLinkedInInteractionsLikeCountConfig,
    MetricLinkedInInteractionsCommentCountConfig,
    MetricLinkedInInteractionsShareCountConfig,
    MetricLinkedInInteractionsMentionsConfig,
    MetricLinkedInInteractionsMentionsTotalConfig,
    MetricLinkedInInteractionsMentionsCommentConfig,
    MetricLinkedInInteractionsMentionsShareConfig,

    MetricLinkedInOverviewConfig,

    MetricLinkedInPageViewsOverviewConfig,
    MetricLinkedInPageViewsByCategoriesConfig,
    MetricLinkedInPageViewsByCountryConfig,
    MetricLinkedInPageViewsByFunctionConfig,
    MetricLinkedInPageViewsByIndustryConfig,
    MetricLinkedInPageViewsByRegionConfig,
    MetricLinkedInPageViewsBySeniorityConfig,
    MetricLinkedInPageViewsByStaffCountRangeConfig,
    MetricLinkedInPageViewsByPlatformConfig,

    MetricLinkedInPostsTopPostsByEngagement,
    MetricLinkedInPostsEngagementAndPostFrequencyConfig,
    MetricLinkedInPostsEngagementSummaryConfig,
    MetricLinkedInPostsPersonalProfilePerformanceSummaryConfig,
    MetricLinkedInPostsPostFrequencyConfig,
    MetricLinkedInPostsTotalEngagementConfig,
    MetricLinkedInPostsCommentsConfig,
    MetricLinkedInPostsReactionsConfig,

    MetricLinkedInViewVideosOverviewConfig,
    MetricLinkedInViewVideosTotalConfig,
    MetricLinkedInViewVideosUniqueConfig,

    // Insta
    MetricInstagramClicksTotal,
    MetricInstagramClicksOverview,
    MetricInstagramClicksEmailContact,
    MetricInstagramClicksDirections,
    MetricInstagramClicksPhoneCall,
    MetricInstagramClicksTextMessage,
    MetricInstagramClicksWebsite,

    MetricInstagramViewsProfile,

    MetricInstagramTopPostsByEngagementConfig,
    MetricInstagramTopPostsByImpressionConfig,
    MetricInstagramPostsPostFrequencyCarouselsConfig,
    MetricInstagramPostsPostFrequencyImagesConfig,
    MetricInstagramPostsPostFrequencyConfig,
    MetricInstagramPostsPostFrequencyTotalConfig,
    MetricInstagramPostsPostFrequencyVideosConfig,

    MetricInstagramEngagementLikeConfig,
    MetricInstagramEngagementCommentConfig,
    MetricInstagramEngagementOverviewConfig,
    MetricInstagramEngagementReplyConfig,
    MetricInstagramEngagementSaveConfig,
    MetricInstagramEngagementTotalConfig,

    MetricInstagramFollowersGrowthConfig,
    MetricInstagramFollowersOverallConfig,

    MetricInstagramStoriesPerformanceConfig,
    MetricInstagramStoriesFrequencyConfig,
    MetricInstagramStoryExitsConfig,
    MetricInstagramStoryImpressionsConfig,
    MetricInstagramStoryReachConfig,
    MetricInstagramStoryRepliesConfig,
    MetricInstagramStoryTapsBackConfig,
    MetricInstagramStoryTapsForwardConfig,

    MetricInstagramAudienceByAgeConfig,
    MetricInstagramAudienceByAgeMenConfig,
    MetricInstagramAudienceByAgeUnspecifiedConfig,
    MetricInstagramAudienceByAgeWomenConfig,
    MetricInstagramAudienceByCitiesConfig,
    MetricInstagramAudienceByCountriesConfig,
    MetricInstagramAudienceByGenderConfig,
];