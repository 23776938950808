import {CommentForViewInterface} from './interfaces';
import {Token} from '../../../../services/token';
import {NO_AVATAR_IMAGE_PATH} from '../../../../configs/configs';
import {Helpers} from '../../../../services/helpers';

export class PostCommentForView implements CommentForViewInterface {
    private _commentID: string;
    private _createDate: string;
    private _objectID: string;
    private _objectType: string;
    private _profileImage: string;
    private _text: string;
    private _userID: string;
    private _userName: string;
    private _renderHelper: {
        timeAgo?: string;
        timeForUser?: string;
    } = {
        timeAgo: 'a few seconds ago',
        timeForUser: '2022-01-01 00:00:00',
    };

    constructor(comment: CommentForViewInterface) {
        this._commentID = comment.commentID;
        this._createDate = comment.createDate;
        this._objectID = comment.objectID;
        this._objectType = comment.objectType;
        this._profileImage = NO_AVATAR_IMAGE_PATH;
        this._text = comment.text;
        this._userID = comment.userID;
        this._userName = comment.userName;
        this._renderHelper = comment.renderHelper;

        const profilePath = comment.profileImage + `&token=${Token.getToken()}`;

        if (comment.profileImage) {
            Helpers.imageTester(profilePath, () => { this.profileImage = profilePath });
        } else {
            this.profileImage = NO_AVATAR_IMAGE_PATH;
        }
    }

    get commentID(): string {
        return this._commentID;
    }

    set commentID(value: string) {
        this._commentID = value;
    }

    get createDate(): string {
        return this._createDate;
    }

    set createDate(value: string) {
        this._createDate = value;
    }

    get objectID(): string {
        return this._objectID;
    }

    set objectID(value: string) {
        this._objectID = value;
    }

    get objectType(): string {
        return this._objectType;
    }

    set objectType(value: string) {
        this._objectType = value;
    }

    get profileImage(): string {
        return this._profileImage;
    }

    set profileImage(value: string) {
        this._profileImage = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get userID(): string {
        return this._userID;
    }

    set userID(value: string) {
        this._userID = value;
    }

    get userName(): string {
        return this._userName;
    }

    set userName(value: string) {
        this._userName = value;
    }

    get renderHelper(): {
        timeAgo?: string;
        timeForUser?: string;
    } {
        return this._renderHelper;
    }

    set renderHelper(value: {
        timeAgo?: string;
        timeForUser?: string;
    }) {
        this._renderHelper = value;
    }
}
