import AbstractModel from '~/src/app/core/model.abstract';
import {IRSSFeed} from '~/src/app/modules/rss/types/rss-feed.interface';
import {RssGroupModel} from '~/src/app/modules/rss/models/rss-group.model';
import {OrganizationModel} from '~/src/app/modules/users/organizations/organization.model';

export class RssFeedModel extends AbstractModel<IRSSFeed> implements IRSSFeed {
    createDate: string;
    feedID: number;
    feedUrl: string;
    name: string;
    organizationID: number[];
    group: RssGroupModel = null;
    organizations: OrganizationModel[];
    userID?: number;
    orgOfUser?: number;
    userName?: string;
    nameOfUserOrg?: string;

    constructor(json: IRSSFeed, group: RssGroupModel = null, organizations: OrganizationModel[] = []) {
        super(json);
        this.parse(json);
        this.group = group;

        if (this.feedID) {
            this.feedID = Number(this.feedID);
        }

        if (this.organizationID) {
            this.organizationID = (Array.isArray(this.organizationID) ? this.organizationID : [this.organizationID]).map(organizationID => Number(organizationID));
        } else {
            this.organizationID = [];
        }

        this.organizations = organizations || [];
    }
}
