import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostPreviewComponent} from '~/src/app/modules/posts/post-previews/post-preview/post-preview.component';
import {CoreConfig} from '~/src/app/core/core.config';

@Directive({selector: '[smdShowPreview]'})
export class ShowPreviewDirective {
    @Input('smdShowPreview') smdShowPreview;
    @Input() isAdminMode = false;
    @Output() statusChange: EventEmitter<any>;

    constructor(
        private openModal: OpenModalService
    ) {
        this.statusChange = new EventEmitter<any>();
    }

    @HostListener('click', ['$event'])
    onClick() {
        const entity = {
            ...this.smdShowPreview,
            linkshare: this.smdShowPreview.linkshare || {},
            activeFrom: this.smdShowPreview.createDate || this.smdShowPreview.activeFrom,
        };
        const isGeneric = entity.systemType && entity.systemType === CoreConfig.getSystemTypes().Generic;
        const isBranded = entity.systemType && entity.systemType === CoreConfig.getSystemTypes().Branded;
        this.openModal.entityPreview(PostPreviewComponent, entity, {
            data: {
                noApproveButtons: (isGeneric || isBranded) && !this.isAdminMode,
                successApprove: (response) => {
                    this.statusChange.emit(response);
                }
            }
        });
    }
}
