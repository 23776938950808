import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Debounce} from '~/src/app/services/helpers';
import {PostTemplateController} from '~/src/app/modules/posts/post-template/post-template.controller';
import {includes} from 'lodash';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import {PostInterface} from '~/src/app/modules/posts/post-actions';

@Component({
    selector: 'smd-acceptance',
    templateUrl: './acceptance.component.html',
    styleUrls: ['./acceptance.component.scss']
})
export class AcceptanceComponent implements OnInit {
    @Input() objectType;
    @Input() objectID;
    @Input() status;
    @Input() entity: PostTemplateInterface & PostInterface;
    @Input() isAdminMode: boolean = false;
    @Output() successStatusChange = new EventEmitter();
    @Output() failedStatusChange = new EventEmitter();
    isWaitingForApproval = false;
    isApproved = false;
    approvedStatuses = ['approved', 'active'];
    hasStatus = false;

    enableApprovement = true;
    allowApproveBtn: boolean = true;
    allowDeclineBtn: boolean = true;
    allowApprove: boolean = true;
    allowDecline: boolean = true;

    constructor(
        private postTemplateController: PostTemplateController,
        private partnerConfigService: PartnerConfigService,
    ) {}

    ngOnInit() {
        this.hasStatus = !!this.status && !!this.objectID;
        this.isWaitingForApproval = !!this.status ? this.status === 'waitingForApproval' : false;
        this.isApproved = !!this.status ? includes(this.approvedStatuses, this.status) : false;
        this.enableApprovement = !!this.status ? this.status !== 'underPost' : true;
        this.allowApprove = this.entity?.approveButtonVisible;
        this.allowDecline = this.entity?.declineButtonVisible;
        this.init();
    }

    @Debounce()
    setApprove(status: 'approved' | 'notApproved' | 'active') {
        const id = this.objectID;

        if (!!id && !!status) {
            this.postTemplateController.postActions.setPostStatus(id, status, this.objectType)
            .then((response) => {
                if (includes(this.approvedStatuses, status)) {
                    this.isApproved = true;
                    this.allowApprove = false;
                    this.allowDecline = true;
                } else if (status === 'notApproved') {
                    this.isApproved = false;
                    this.allowApprove = true;
                    this.allowDecline = false;
                }
                this.isWaitingForApproval = false;
                this.status = status;
                this.init();
                this.successStatusChange.emit(response);
            }).catch(error => this.failedStatusChange.emit(error));
        }
    }

    init() {
        this.allowApproveBtn = CommonPostHelpers.allowApprove({
            hasPartnerConfig: this.partnerConfigService.hasConfig(PartnerPermissions.ApprovementWorkflow),
            isAdminMode: this.isAdminMode,
            status: this.status,
            type: this.objectType,
            templateOrganizationID: this.isTemplateMode ? this.entity?.organizationID : null,
            approveButtonVisible: this.allowApprove,
            systemType: this.entity?.systemType
        });
        this.allowDeclineBtn = CommonPostHelpers.allowDecline({
            hasPartnerConfig: this.partnerConfigService.hasConfig(PartnerPermissions.ApprovementWorkflow),
            isAdminMode: this.isAdminMode,
            status: this.status,
            type: this.objectType,
            templateOrganizationID: this.isTemplateMode ? this.entity?.organizationID : null,
            declineButtonVisible: this.allowDecline,
            systemType: this.entity?.systemType
        });
    }

    get isTemplateMode() {
        return !!this.objectType && this.objectType === 'postTemplate';
    }
}
