import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {FileBrowserComponent} from '../../../components/file-browser/file-browser.component';
import {SmdFileInterface} from '../../../services/file.class';

interface DialogData {
    selectedImages: SmdFileInterface[];
}

@Component({
    selector: 'smd-dialog-file-browser',
    templateUrl: './dialog-file-browser.component.html',
    styleUrls: ['./dialog-file-browser.component.scss']
})
export class DialogFileBrowserComponent implements OnInit {

    @ViewChild(FileBrowserComponent, {static: true}) fileBrowser: FileBrowserComponent;

    constructor(
        public dialogRef: MatDialogRef<DialogFileBrowserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
        if (this.data && this.data.selectedImages.length > 0) {
            this.fileBrowser.setSelectedFiles(this.data.selectedImages);
        }
    }

    addItems() {
        this.dialogRef.close(this.fileBrowser.selectedFiles);
    }

    get fileTypes() {
        return FILE_TYPES;
    }
}
