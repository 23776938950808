import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';

@Directive({
    selector: '[language]'
})
export class LanguageDirective implements OnInit {

    @Input('language') lineKey: string;
    @Input('languagePlural') languagePlural: boolean;

    constructor(
        private el: ElementRef,
        private language: LanguageService
    ) {

    }

    ngOnInit() {
        this.setText();
    }

    setText() {
        const params = {}

        if (this.languagePlural) {
            params['s'] = this.languagePlural ? 's' : '';
        }

        this.el.nativeElement.innerHTML = this.language.getLine(this.lineKey, params);
    }

}
