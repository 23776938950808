import {Component, Inject, OnInit} from '@angular/core';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {ClickDecorator} from '~/src/app/core/decorators';
import {LanguageService} from '~/src/app/services/language.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RssGroupService} from '~/src/app/modules/rss/services/rss-group.service';
import {IRSSGroupModalDialogData} from '~/src/app/modules/rss/types/rss-group.interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {FormValidationService} from '~/src/app/services/form.validation.service';

@Component({
    selector: 'smd-rss-group-modal-form',
    templateUrl: './rss-group-modal-form.component.html',
    styleUrls: ['./rss-group-modal-form.component.scss'],
    providers: [
        ComponentHelpers,
        FormHelpersService
    ]
})
export class RssGroupModalFormComponent implements OnInit {

    isEditMode = false;
    rssGroupControlNames = {
        name: 'name',
        feedID: 'feedID',
        organizationIDs: 'organizationID'
    };
    rssGroupFormGroup = new FormGroup({
        [this.rssGroupControlNames.name]: new FormControl(this.data && this.data.group ? this.data.group.name : null, [Validators.required, Validators.minLength(3)]),
        [this.rssGroupControlNames.feedID]: new FormControl(
            this.data && this.data.group
                ? this.data.group.feeds.map(feed => feed.feedID)
                : [],
            [Validators.required]
        ),
        [this.rssGroupControlNames.organizationIDs]: new FormControl(
            this.data && this.data.group
                ? this.data.group.organizationID
                : [],
            [Validators.required]
        )
    });

    constructor(
        public language: LanguageService,
        @Inject(MAT_DIALOG_DATA) public data: IRSSGroupModalDialogData,
        public formHelpersService: FormHelpersService,
        private dialogRef: MatDialogRef<RssGroupModalFormComponent>,
        private rssGroupService: RssGroupService,
        private componentHelpers: ComponentHelpers
    ) {
        if (this.data && this.data.group) {
            this.isEditMode = true;
        }

        this.formHelpersService.formInit(this.rssGroupFormGroup);
    }

    ngOnInit() {
    }

    @ClickDecorator()
    createGroup(event: MouseEvent) {
        if (this.rssGroupFormGroup.valid) {
            this.componentHelpers.startApiAction(
                () => this.rssGroupService.create(this.rssGroupFormGroup.value),
                {
                    successMessageKey: 'rss.group.create.success',
                    failedMessageKey: 'rss.group.create.failed',
                    afterSuccessAction: (response) => {
                        if (this.data && this.data.afterSuccessAction) {
                            this.data.afterSuccessAction(response);
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: error => {
                        this.formHelpersService.validateForm(this.rssGroupFormGroup, FormValidationService.readError(error).formMessages);
                    }
                }
            );
        } else {
            this.formHelpersService.validateForm(this.rssGroupFormGroup);
        }
    }

    @ClickDecorator()
    modifyGroup(event: MouseEvent) {
        if (this.rssGroupFormGroup.valid) {
            this.componentHelpers.startApiAction(
                () => this.rssGroupService.modify(this.data.group.groupID, this.rssGroupFormGroup.value),
                {
                    successMessageKey: 'rss.group.modify.success',
                    failedMessageKey: 'rss.group.modify.failed',
                    afterSuccessAction: (response) => {
                        if (this.data && this.data.afterSuccessAction) {
                            this.data.afterSuccessAction(response);
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: error => {
                        this.formHelpersService.validateForm(this.rssGroupFormGroup, FormValidationService.readError(error).formMessages);
                    }
                }
            );
        } else {
            this.formHelpersService.validateForm(this.rssGroupFormGroup);
        }
    }

}
