import {Injectable} from '@angular/core';

declare const $;

@Injectable({providedIn: 'root'})
export class NotifyService {
    private static defaultConfigs = {
        hideAfter: 3500,
        position: 'top-right',
    };
    private static configs = {
        error: {
            icon: 'error',
        },
        success: {
            icon: 'success',
            stack: 6
        },
        info: {
            icon: 'info',
            stack: 6
        },
        warning: {
            icon: 'warning',
            stack: 1
        },
        notification: {
            icon: 'info',
            stack: 6,
            position: {
                top: '80px',
                right: '10px'
            },
            bgColor: '#28315e',
            loader: false,
            hideAfter: 5000,
            textColor: 'white',
        }
    };

    // https://kamranahmed.info/toast for docs

    constructor () {}

    private static callNotify (title: string, message: string, name: string): void {
        const options = Object.assign(this.defaultConfigs, this.configs[name]);

        options['heading'] = title;
        options['text'] = message;

        $.toast(options);
    }

    public static error (title: string, message: string): void {
        this.callNotify(title, message, 'error');
    }
    public static success (title: string, message: string): void {
        this.callNotify(title, message, 'success');
    }
    public static info (title: string, message: string): void {
        this.callNotify(title, message, 'info');
    }
    public static warning (title: string, message: string): void {
        this.callNotify(title, message, 'warning');
    }
    public static notification (title: string, message: string): void {
        this.callNotify(title, message, 'notification');
    }

    public static closeAll (): void {
        $.toast().reset('all');
    }
}
