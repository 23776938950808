import {environment} from '~/src/environments/environment';
import Utils from '~/src/app/core/utils';
import {Token} from '~/src/app/services/token';

// Types
export type SystemType = 'custom' | 'generic' | 'branded';

export class Core {

    /**
     * Add domain to URL
     * @param url
     */
    public static addDomainToUrl(url: string): string {

        if (typeof url !== 'string') {
            return url;
        }

        // Get api URL by environment
        const domain = environment.apiUrl;

        // Check url exist domain
        if (!Utils.lodash.includes(url, domain)) {
            url = domain + url;
        }

        return url;
    }

    /**
     * Add token to URL
     * @param url
     */
    public static addTokenToUrl(url: string): string {

        if (typeof url !== 'string') {
            return url;
        }

        const query = Utils.queryString.parseUrl(url).query;
        if (!Utils.lodash.get(query, 'token', null)) {
            const separator = !!Object.keys(query).length ? '&' : '?';

            url = `${url + separator}token=${Token.getToken()}`;
        }

        return url;
    }

    /**
     * Add domain and token to url
     * @param {string} url
     * @returns {string}
     */
    public static addDomainAndTokenToUrl(url: string): string {
        url = this.addDomainToUrl(url);
        url = this.addTokenToUrl(url);

        return url;
    }

}
