import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
} from "@angular/core";
import { LanguageService } from "~/src/app/services/language.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subject } from "rxjs";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import {
    Debounce,
    FacebookUser,
    Helpers,
    LoggedUserInterface,
    MyErrorStateMatcher,
} from "~/src/app/services/helpers";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { cloneDeep, forEach, forEachRight, has, includes, isBoolean, keys } from "lodash";
import { OrganizationController } from "~/src/app/components/organization-select/organization.service";
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { ScrollToService } from "@nicky-lenaers/ngx-scroll-to";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { PostManagerDialogData } from "~/src/app/modules/social-media-post/post-manager/post-manager.interfaces";
import { PostInterface } from "~/src/app/modules/posts/post-actions";
import { PostSkeletonComponent } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.component";
import { PostSkeletonGetDataForRequestOptions } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.interfaces";
import { SmdFile } from "~/src/app/services/file.class";
import Utils from "~/src/app/core/utils";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { PostTemplateSuccessCreateModalComponent } from "~/src/app/modules/social-media-post/post-template-success-create-modal/post-template-success-create-modal.component";

import {
    BASE_DATETIME_FORMAT,
    SOCIAL_PLATFORMS_DEFAULT_CONFIG,
} from "~/src/app/configs/configs";
import { ArraySupport } from "~/src/app/core/helper/array-support";
import { ObjectSupport } from "~/src/app/core/helper/object-support";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import {
    SocialMediaPlatformConfig,
    SocialMediaPlatforms,
    socialSiteAppearance,
} from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { MentionHelpers } from "~/src/app/modules/posts/mention-helpers";
import { MessageBoxComponent } from "~/src/app/core/components/message-box/message-box.component";
import {
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
} from "~/src/app/core/constants";
import { GmbPostSkeletonComponent } from "../post-skeleton/gmb-post-skeleton.component";
import { MatSelect } from "@angular/material/select";
import { LoggedUser } from "~/src/app/services/logged-user";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { SocialSiteUtils } from "../../users/social-site.utils";
import { Facebook } from "../../users/Facebook.class";
import { SocialManagerComponent } from "../../users/social-manager/social-manager.component";
import { SocialManagerService } from "../../users/social-manager/social-manager.service";
import { FacebookService } from "ngx-facebook";
import { BackendService } from "~/src/app/core/backend.service";
import { LinkedInService } from "~/src/app/services/linked-in.service";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { TwitterAPI } from "../../users/twitter.service";
import { GmbApiLocationService } from "../gmb/gmb-api-location.service";
import { PostCreateModalComponent } from "~/src/app/modules/social-media-post/post-create-modal/post-create-modal.component";

type TYPE_PROFILE = "profile";
type TYPE_PAGE = "page";
type TYPE_GROUP = "group";

const SITE_TYPE = {
    Profile: "profile",
    Page: "page",
    Group: "group",
};

@Component({
    selector: "smd-post-manager",
    templateUrl: "./post-manager.component.html",
    styleUrls: ["./post-manager.component.scss"],
    providers: [ComponentHelpers, TwitterAPI],
})
export class PostManagerComponent implements OnInit, AfterViewInit, OnChanges {
    /**
     * Cube view
     */
    @ViewChild("CubeForPosts", { read: ViewContainerRef, static: true })
    cubeForPosts: ViewContainerRef;
    @ViewChildren("postSkeleton")
    postSkeletons: QueryList<PostSkeletonComponent>;
    @ViewChild(MessageBoxComponent, { read: ElementRef, static: true })
    messageBox: ElementRef<MessageBoxComponent>;
    @ViewChild(PostSkeletonComponent) postSkeleton: PostSkeletonComponent;
    @ViewChild(GmbPostSkeletonComponent) gmbPostSkeleton: GmbPostSkeletonComponent;
    @ViewChild("noJumpSelector") selector: MatSelect;
    tabOptions: any[] = [
        {
            id: "postTemplateChooser",
            tabNameKey: "schedule.select.post.template",
            iconClass: "mdi mdi-checkbox-multiple-blank",
            isDisabled: false,
            isAllwaysActive: true,
        },
        {
            id: "customizePost",
            tabNameKey: "schedule.customize.post",
            iconClass: "fa fa-edit",
            isBaseTab: true,
            isPostEditor: true,
            isAllwaysActive: true,
        },
        ...cloneDeep(
            SocialMediaPlatforms.filter(
                (config) =>
                    socialSiteAppearance?.[config.platform]?.schedule !== false
            )
        ),
        {
            id: "preview",
            tabNameKey: "schedule.modal.post.preview",
            iconClass: "fa fa-eye",
            isDisabled: false,
            isAllwaysActive: true,
        },
    ];
    getPostDataFromComponents = new Subject();
    socialSites = [];
    selectedSocialSites = [];
    organizations = [];
    commonControlNames = {
        SocialSite: PostFormControlNames.SocialSite,
        Organization: PostFormControlNames.Organization,
        Categories: PostFormControlNames.Categories,
    };
    commonFormGroup = new FormGroup({
        [this.commonControlNames.SocialSite]: new FormControl(null, [
            Validators.required,
        ]),
        [this.commonControlNames.Organization]: new FormControl({
            value: null,
            disabled: this.socialSiteController.getSocialSitesInProgress,
        }),
        [this.commonControlNames.Categories]: new FormControl(null),
    });
    commonFormErrors = {};
    errorMatcher = new MyErrorStateMatcher();
    baseFormData = {};
    copyBaseFormData = new Subject();
    readSelectedTemplate = new Subject();
    wasValidation = false;
    entities = [];
    initialScheduleDate;
    isPostEditMode = false;
    isInstantPostHidden = false;
    expiryTabHidden = false;
    isPostCopyMode = false;
    isPostCommentMode = false;
    isCreateFromTemplateMode = false;
    isDraftPostEditMode = false;
    wasSubmitClick = false;
    refreshPostList = false;
    isInstantPost = false;
    isApproved = false;
    initialMedias;
    selectedMedias = [];
    postTemplateFilters;
    draftID = null;
    showCopyCheckbox = false;
    hasApprovementWorkflow = false;
    selectedTemplate: PostInterface;
    public isPreviewShow = false;
    clearDraftAutoSaveTimer = new Subject();
    errorTitle: string = null;
    errorMessages: string = null;
    infoMessages: string = null;
    createdFromTemplateManager = false;

    originPostContentHash: string;
    currentPostContentHash: string;

    private _isAutoFeed = false;
    private _defaultTabIndex = 0;
    private _currentTabIndex = this.defaultTabIndex;
    public socialPlatforms = SOCIAL_PLATFORMS_DEFAULT_CONFIG.filter(
        (item) => socialSiteAppearance?.[item.id]?.postmanager !== false
    );

    socialSitesFetching: boolean = false;

    // Render helpers
    organizationControl: AbstractControl;
    socialSelectFormControl: AbstractControl;
    currentTabIsTemplateChooser: boolean;
    currentPost: PostInterface;
    postsForPreview: PostInterface[] = [];
    dataForDraft: any;
    saveButtonDisabled: boolean = true;
    draftEntityData: any;

    user: LoggedUserInterface;

    fb: Facebook;
    socialManagerComponent: SocialManagerComponent;
    linkedInLoginWindow;
    isLoggedInWithLinkedIn: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PostManagerDialogData,
        public dialogRef: MatDialogRef<PostManagerComponent>,
        public language: LanguageService,
        public socialSiteController: SocialSiteController,
        public organizationController: OrganizationController,
        private postService: SocialMediaPostService,
        private openModal: OpenModalService,
        private scrollTo: ScrollToService,
        private partnerConfig: PartnerConfigService,
        public socialManagerService: SocialManagerService,
        public dialog: MatDialog,
        public fbService: FacebookService,
        private backend: BackendService,
        private linkedinService: LinkedInService,
        public twitterAPI: TwitterAPI,
        private componentHelpers: ComponentHelpers,
        public gmbApiService: GmbApiLocationService,
    ) {
        this.user = LoggedUser.getUser();
        this.fb = new Facebook(fbService, dialog);
        this.socialManagerComponent = new SocialManagerComponent(
            socialManagerService
        );
        this.isLoggedInWithLinkedIn = this.linkedinService.isLoggedIn();
        this.initialize();
    }

    ngOnInit() {
        this.initializeOnInit();

        // Render helpers
        this.organizationControl = this.commonFormGroup.get(this.commonControlNames.Organization);
        this.socialSelectFormControl = this.commonFormGroup.get(this.commonControlNames.SocialSite);
        this.currentTabIsTemplateChooser = this.getCurrentTabIsTemplateChooser();
        this.currentPost = this.getCurrentPost();
        this.saveButtonDisabled = this.getSaveButtonDisabled()
        this.draftEntityData = this.getDataForDraft();
    }

    ngAfterViewInit(): void {
        setTimeout(() => { // runs after the change detection has run
            this.loadLazyElementsOfActiveTab();
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.currentPost = this.getCurrentPost();
        } else if (changes.currentPostContentHash) {
            this.saveButtonDisabled = this.getSaveButtonDisabled();
        }
    }

    /**
     * Click on "Create Post" button
     */
    create(isInstantPost = false, isApproved = false) {
        this.isInstantPost = isInstantPost;
        this.isApproved = isApproved;
        this.wasSubmitClick = true;
        this.clearDraftAutoSaveTimer.next();
        const settableTabs = this.tabOptions.filter(
            (tab) =>
                tab.isPostEditor &&
                !tab.isBaseTab &&
                !tab.isCopied &&
                !tab.isDisabled &&
                tab.id !== "preview"
        );
        this.entities = [];
        if (this.validateCommonForm()) {
            if (settableTabs.length) {
                this.setPostFormData(true);
            } else {
                this.getPostDataFromComponents.next({
                    isInstantPost: isInstantPost,
                });
            }
        }
    }

    /**
     * Save post modifies
     *
     * @param {boolean} isApproved
     */
    save(isApproved = false) {
        this.entities = [];
        if (this.validateCommonForm()) {
            this.getPostDataFromComponents.next();

            const { posts, allValid } = this.getPostsDataAndValidity();

            forEach(posts, (post, index) => {
                console.log('check expire date ', post);
                if (this.expiryTabHidden) {
                    console.log('check expire date hidden or not', this.expiryTabHidden);
                    post.expiryDate = null;
                    post.expiryPeriod = null;
                }

                posts[index] = {
                    ...post,
                    [this.commonControlNames.SocialSite]:
                        this.commonFormGroup.get(
                            this.commonControlNames.SocialSite
                        ).value,
                    status: isApproved ? "approved" : null,
                };
            });

            if (allValid) {
                const loader = this.openModal.loader(DialogLoaderComponent);

                loader.afterClosed().subscribe((result: any) => {
                    if (result.isSuccess) {
                        this.openModal
                            .successModal(PostTemplateSuccessCreateModalComponent, {
                                message: this.language.getLine(
                                    "post.template.success.templateCreated"
                                ),
                            })
                            .afterClosed()
                            .subscribe((createNew) => {
                                if (createNew && result.response && result.response.posts && result.response.posts.length > 0) {
                                    result.response.posts[0].createNewpost = createNew;
                                }
                                this.dialogRef.close(
                                    Utils.lodash.get(
                                        result,
                                        "result.response.post",
                                        true
                                    )
                                );
                            });
                    } else {
                        this.validateCommonForm(
                            this.getCommonFormServerErrors(result.error)
                        );

                        this.openModal.errorModal(DialogErrorComponent, {
                            message: this.readError(result.error),
                        });
                    }
                });

                this.postService
                    .savePost(this.getCurrentPost().postID, posts)
                    .then((response) =>
                        loader.close({ isSuccess: true, response: response })
                    )
                    .catch((error) =>
                        loader.close({ isSuccess: false, error: error })
                    );
            }
        }
    }

    @Debounce()
    postReaded(): void {
        if (this.wasSubmitClick) {
            this.validateAndCreatePost();
        }
    }

    /**
     * Get all post from forms
     * @param {string} platform
     * @param post
     */
    @Debounce()
    setPosts(platform: string, isValid: boolean, post) {
        if (this.wasSubmitClick) {
            this.validateAndCreatePost();
        }
    }

    /**
     * Load lazy elements of active tab
     */
    loadLazyElementsOfActiveTab() {
        // load media
        let loaded = false;
        this.postSkeletons.forEach((postSkeleton) => {
            if (this.tabOptions.findIndex((tab) => tab.id === postSkeleton.socialMediaType) === this.currentTabIndex) {
                postSkeleton.loadMedia();
                loaded = true;
                return;
            }
        });

        // fallback to default tab if no platform selected
        if (!loaded) {
            this.postSkeletons.forEach((postSkeleton) => {
                if (postSkeleton.socialMediaType === 'default') {
                    postSkeleton.loadMedia();
                    return;
                }
            });
        }
    }

    /**
     * Change active tab event
     * @param {MatTabChangeEvent} event
     */
    onTabChange(event: MatTabChangeEvent) {
        const activeTabIndex = event.index;

        this.currentTabIndex = activeTabIndex;
        this.setPostFormData();
        this.changeModalWidth();

        if (
            this.currentTabIndex ===
            this.tabOptions.findIndex((tab) => tab.id === "preview")
        ) {
            this.postsForPreview = this.getPostsForPreview();
            this.isPreviewShow = true;
            this.getPostDataFromComponents.next();
        } else {
            this.isPreviewShow = false;
            this.loadLazyElementsOfActiveTab();
        }

        this.currentTabIsTemplateChooser = this.getCurrentTabIsTemplateChooser(); // to help rendering
    }

    /**
     * Get current post
     * @return {null}
     */
    getCurrentPost() {
        return this.data && (this.data.post || this.data.rssTemplate)
            ? this.data.post || this.data.rssTemplate
            : null;
    }

    selectAllSocialSite(): void {
        this.commonFormGroup
            .get(this.commonControlNames.SocialSite)
            .setValue(
                CommonPostHelpers.selectAllSocialSite(
                    this.socialSites,
                    this.selectedSocialSites
                )
            );
    }

    /**
     * Get common form control errors
     */
    setControlErrors(serverErrors?: any) {
        this.wasValidation = true;
        this.commonFormErrors = serverErrors
            ? FormValidationService.setFormControlsIncorrect(
                serverErrors,
                this.commonFormGroup,
                this.commonFormErrors
            )
            : FormValidationService.getMessages(this.commonFormGroup.controls);
    }

    /**
     * Set base form data on base form change
     * @param data
     */
    setBaseFormData(data: any) {
        this.baseFormData = data;
    }

    /**
     * Change selected post template
     * @param {PostInterface} template
     */
    changeSelectedTemplate(template: PostInterface) {
        this.commonFormGroup
            .get(this.commonControlNames.Organization)
            .setValue([]);
        this.commonFormGroup
            .get(this.commonControlNames.Categories)
            .setValue(null);

        this.selectedTemplate = template;

        this.data = {
            ...this.data,
            post: template,
        };

        this.currentPost = this.getCurrentPost();

        let activateTabIndex;
        this.tabOptions.map((tab, index) => {
            if (tab.isBaseTab) {
                tab.isDisabled = false;
                activateTabIndex = index;
            }
            return tab;
        });

        setTimeout(() => {
            const categories =
                !!template && !!template[PostFormControlNames.Categories]
                    ? template[PostFormControlNames.Categories].map(
                        (item) => item.name
                    )
                    : [];
            const category = !!categories.length ? categories[0] : null;

            this.setActiveTab(activateTabIndex);
            this.readSelectedTemplate.next({
                tabIndexes: [this.currentTabIndex + 1],
                emit: false,
            });

            // Set organizations
            if (!!template && !!template[PostFormControlNames.Organization]) {
                this.commonFormGroup
                    .get(this.commonControlNames.Organization)
                    .setValue(
                        template[PostFormControlNames.Organization].map(
                            (organizationID) => Number(organizationID)
                        ),
                        { emitEvent: true }
                    );
            }

            // Set social sites
            this.initSocialSites(
                this.commonFormGroup.get(this.commonControlNames.Organization).value
            );

            // Set category
            if (!!category) {
                this.commonFormGroup
                    .get(this.commonControlNames.Categories)
                    .setValue(category, { emitEvent: true });
            }

            if (this.data.post.gmbOptions) {
                this.gmbPostSkeleton.gmbForm.setGmbFormByGmbOptions(
                    this.data.post.gmbOptions,
                    this.gmbPostSkeleton.postFormGroup.get('gmbOptions')
                );
            }
        });
    }

    /**
     * Get data for draft
     * @return {{posts: {}[]}}
     */
    getDataForDraft() {
        let result = {};

        // Get data from post forms
        if (!!this.postSkeletons) {
            for (const component of this.postSkeletons.toArray()) {
                const skeletonData: any = component.getDataForRequest({
                    removeMedias: false,
                });

                const tab = this.tabOptions[component.tabPosition];
                const skipTabNumber = this.isCreateFromTemplateMode ? 1 : 0;
                if (component.tabPosition > skipTabNumber && !tab.isCopied) {
                    continue;
                }

                result["socialChannels"] = result["socialChannels"] || {};

                if (has(skeletonData, "medias") && !!skeletonData.medias) {
                    skeletonData.medias = skeletonData.medias.map((media) => {
                        return SmdFile.getRawMedia(media);
                    });
                }

                if (!!component.socialMediaType) {
                    result["socialChannels"][component.socialMediaType] =
                        skeletonData;
                } else {
                    result["socialChannels"]["default"] = skeletonData;
                }
            }
        }

        const categories =
            this.commonFormGroup.getRawValue()[PostFormControlNames.Categories];

        // Get data from common form
        result = {
            ...result,
            ...this.commonFormGroup.getRawValue(),
            [PostFormControlNames.Categories]: !!categories
                ? [categories]
                : null,
        };

        return {
            posts: [result],
        };
    }

    getDraftEntityDataOnClick() {
        this.draftEntityData = this.getDataForDraft();
    }

    /**
     * Success draft save
     * @param event
     */
    successDraftSave(event) {
        this.draftID = event.draftID;
        this.refreshPostList = true;
    }

    getPostsForPreview() {
        let data = this.getPostsDataAndValidity({
            removeMedias: false,
            replaceLineBreakSign: false,
            forPreview: true,
        }).posts;
        const findDefaultPostIndex = () => {
            return data.findIndex((item) => item.socialType === "default");
        };
        const defaultPost = data[findDefaultPostIndex()];
        const renderPostsForAllPlatform =
            !this.tabOptions.filter(
                (tab) =>
                    tab.isPostEditor &&
                    !tab.isDisabled &&
                    !tab.isBaseTab &&
                    tab.isCopied
            ).length &&
            !this.isPostEditMode &&
            !this.isPostCommentMode;

        const needToRenderFromDefault = this.tabOptions.filter((tab) => {
            if (
                tab.isPostEditor &&
                !tab.isDisabled &&
                !tab.isBaseTab &&
                !tab.isCopied
            ) {
                return true;
            }
        });
        const dataLength = data.length;
        if (
            needToRenderFromDefault.length === 0 &&
            dataLength === 1 &&
            defaultPost
        ) {
            data = [];
        }

        SocialMediaPlatforms.filter(
            (config) =>
                socialSiteAppearance?.[config.platform]?.schedule !== false
        ).forEach((item) => {
            if (
                (needToRenderFromDefault.some(
                    (tab) => tab.platform === item.platform
                ) ||
                    (needToRenderFromDefault.length === 0 &&
                        dataLength === 1)) &&
                defaultPost
            ) {
                const extendedPost = MentionHelpers.prepareMentions(
                    Utils.lodash.cloneDeep(defaultPost),
                    item.platform,
                    item
                );

                const siteIndex = data.findIndex(
                    (element) => element.socialType === item.platform
                );
                if (siteIndex !== -1) {
                    data[siteIndex] = {
                        ...extendedPost,
                        socialType: item.platform,
                    };
                } else {
                    data.push({
                        ...extendedPost,
                        socialType: item.platform,
                    });
                }
            }
        });

        if (findDefaultPostIndex() > -1) {
            data.splice(findDefaultPostIndex(), 1);
        }

        data.map((post) => {
            post["socialSites"] = this.socialSites
                .filter((site) =>
                    includes(
                        this.commonFormGroup.get(
                            this.commonControlNames.SocialSite
                        ).value,
                        site.siteID
                    )
                )
                .filter((site) => site.socialType === post.socialType);
            return post;
        });

        // set default selected platform tab on preview by selected template
        if (
            this.selectedTemplate &&
            this.selectedTemplate.socialType &&
            renderPostsForAllPlatform
        ) {
            data.forEach((post, index) => {
                if (post.socialType === this.selectedTemplate.socialType) {
                    data.splice(index, 1);
                    data.unshift(post);
                }
            });
        }

        return data;
    }

    /**
     * Validate common form
     * @return {boolean}
     */
    validateCommonForm(serverErrors?: any): boolean {
        this.commonFormErrors = {};

        if (serverErrors) {
            this.commonFormErrors = serverErrors;
            this._setFormErrorsByServer(serverErrors);

            return;
        } else {
            this.errorTitle = null;
            this.errorMessages = null;
        }

        if (this.commonFormGroup.invalid) {
            this.setControlErrors();

            for (const controlName in this.commonFormErrors) {
                this.commonFormGroup.get(controlName).markAsTouched();
            }

            this.scrollTo.scrollTo({
                target: "commonFormGroup",
            });

            return false;
        }

        return true;
    }

    /**
     * Validity change event
     * @param {string} tabID
     * @param {boolean} isValid
     */
    validityChange(tabID: string, isValid: boolean) {
        this.tabOptions.map((tab) => {
            if (tab.id === tabID) {
                tab.isValid = isValid;
            }

            return tab;
        });
    }

    /**
     * Check current tab is template chooser
     * @return {boolean}
     */
    getCurrentTabIsTemplateChooser() {
        return (
            this.currentTabIndex ===
            this.tabOptions.findIndex((tab) => tab.id === "postTemplateChooser")
        );
    }

    /**
     * Return with save button disable state.
     *
     * @returns {boolean}
     */
    getSaveButtonDisabled() {
        if (!this.isPostEditMode || !this.postSkeletons) {
            return false;
        }

        return (
            !this.postSkeletons.first.formChanged() &&
            this.originPostContentHash == this.currentPostContentHash
        );
    }

    /**
     * Get active tab index
     * @return {number}
     */
    get currentTabIndex(): number {
        return this._currentTabIndex;
    }

    /**
     * Set active tab index
     * @param {number} value
     */
    set currentTabIndex(value: number) {
        this._currentTabIndex = value;
    }

    /**
     * Get isAutoFeed status
     * @return {boolean}
     */
    get isAutoFeed(): boolean {
        return this._isAutoFeed;
    }

    /**
     * Set isAutoFeed status
     * @param {boolean} value
     */
    set isAutoFeed(value: boolean) {
        this.commonFormGroup
            .get(this.commonControlNames.Categories)
            .clearValidators();

        if (value) {
            this.commonFormGroup
                .get(this.commonControlNames.Categories)
                .setValidators(Validators.required);
        }

        setTimeout(() => {
            this.commonFormGroup
                .get(this.commonControlNames.Categories)
                .updateValueAndValidity();
            this.commonFormGroup
                .get(this.commonControlNames.Categories)
                .markAsUntouched();
        });

        this._isAutoFeed = value;
    }

    /**
     * Get default tab index
     * @return {number}
     */
    get defaultTabIndex(): number {
        return this._defaultTabIndex;
    }

    /**
     * Set default tab index
     * @param {number} value
     */
    set defaultTabIndex(value: number) {
        this._defaultTabIndex = value;
        this.currentTabIndex = value;
    }

    /**
     * Initialize on constructor
     */
    private initialize() {
        this.isPostCopyMode = this.data ? !!this.data.isCopy : false;
        this.isPostCommentMode = this.data ? !!this.data.isCommentMode : false;
        this.isCreateFromTemplateMode = this.data
            ? !!this.data.createFromTemplate
            : false;
        this.initialScheduleDate =
            this.data && this.data.scheduleDate
                ? this.data.scheduleDate
                : Utils.moment().format(BASE_DATETIME_FORMAT);
        this.initialMedias =
            this.data &&
                !!this.data.initialMedias &&
                this.data.initialMedias.length
                ? this.data.initialMedias
                : null;
        this.isDraftPostEditMode =
            !!this.getCurrentPost() &&
            this.getCurrentPost().type === "draft" &&
            !this.isPostCopyMode;
        this.isPostEditMode =
            !!this.getCurrentPost() &&
            !!this.getCurrentPost().postID &&
            !this.isPostCommentMode &&
            !this.isPostCopyMode &&
            !this.isDraftPostEditMode;
        this.isInstantPostHidden =
            !!this.getCurrentPost() &&
                this.getCurrentPost().repeats &&
                this.getCurrentPost().repeats.length > 0
                ? true
                : false;
        this.expiryTabHidden =
            !!this.getCurrentPost() &&
                this.getCurrentPost().isRepeat &&
                !this.getCurrentPost().repeats
                ? true
                : false;
        this.hasApprovementWorkflow = this.partnerConfig.hasConfig(
            PartnerPermissions.ApprovementWorkflow
        );
        this.createdFromTemplateManager = this.data ? !!this.data.createdFromTemplateManager : false;

        if (!this.isCreateFromTemplateMode) {
            this.tabOptions.splice(0, 1);
        } else {
            this.initCreateFromTemplateMode();
        }

        if (
            (this.getCurrentPost() &&
                !this.isPostCopyMode &&
                !this.isDraftPostEditMode) ||
            this.isPostCommentMode
        ) {
            const getActiveTabIndex = () => {
                return this.tabOptions.findIndex(
                    (tab) => tab.id === this.getCurrentPost().socialType
                );
            };

            if (!!getActiveTabIndex() && getActiveTabIndex() > -1) {
                this.tabOptions[getActiveTabIndex()].isDisabled = false;

                const removeAbleIDs = [];
                forEach(this.tabOptions, (tab, index) => {
                    if (index !== getActiveTabIndex() && tab.isPostEditor) {
                        // this.tabOptions[index].isDisabled = true;
                        removeAbleIDs.push(tab.id);
                    }
                });

                removeAbleIDs.map((id) => {
                    const index = this.tabOptions.findIndex(
                        (tab) => tab.id === id
                    );
                    this.tabOptions.splice(index, 1);
                });

                this.defaultTabIndex = getActiveTabIndex();
                this.setCommonFormValues();
            }
        }

        if (
            this.isPostCopyMode ||
            this.isPostCommentMode ||
            this.isDraftPostEditMode
        ) {
            this.setCommonFormValues();
        }

        if (this.isDraftPostEditMode) {
            const archive = has(this.getCurrentPost(), "archive")
                ? this.getCurrentPost().archive
                : null;
            const draftSettings =
                !!archive && has(archive, "draftSettings")
                    ? archive.draftSettings
                    : null;
            this.draftID = this.getCurrentPost().postID;

            if (!!draftSettings) {
                const platforms = keys(draftSettings);
                this.tabOptions.map((tab) => {
                    if (includes(platforms, tab.platform)) {
                        tab.isCopied = true;
                    }

                    return tab;
                });
            }
        }

        if (this.isPostCommentMode) {
            forEach(this.commonFormGroup.controls, (control, controlName) => {
                this.commonFormGroup.get(controlName).disable();
            });
        }

        this.originPostContentHash = Utils.md5(
            JSON.stringify(this.commonFormGroup.getRawValue())
        );

        this.commonFormGroup.valueChanges.subscribe(() => {
            this.currentPostContentHash = Utils.md5(
                JSON.stringify(this.commonFormGroup.getRawValue())
            );
        });

        this.commonFormGroupInit();
        this.initOrganizations();
    }

    /**
     * Initialize on ngOnInit
     */
    private initializeOnInit() { }

    /**
     * Init create from template mode
     */
    private initCreateFromTemplateMode() {
        this.tabOptions.map((tab) => {
            if (tab.isBaseTab) {
                tab.isDisabled = true;
            }

            return tab;
        });
    }

    private readError(errorResponse) {
        let message = FormValidationService.readError(errorResponse).message;
        const error = errorResponse.error.error;

        if (error && error.info && error.info.messages) {
            forEach(error.info.messages, (messageObj) => {
                if (Object.keys(messageObj).length) {
                    forEach(Object.keys(messageObj), (key) => {
                        message += " " + messageObj[key];
                    });
                }
            });
        }

        if (message) {
            this.errorTitle = "Validation error(s)";
            this.setErrorMessages(message);
        }

        return message;
    }

    /**
     * Validate forms and create new post
     * @param {boolean} isInstantPost
     */
    private validateAndCreatePost() {
        const { posts, allValid } = this.getPostsDataAndValidity();
        this.wasSubmitClick = false;

        if (allValid) {
            const loader = this.openModal.loader(DialogLoaderComponent);
            const fromRss = !!this.data && !!this.data.rssTemplate;
            const fromMedia = !!this.data && !!this.data.initialMedias;
            let templateID = !!this.selectedTemplate
                ? this.selectedTemplate.templateID
                : null;
            if (!templateID && this.createdFromTemplateManager) {
                templateID = this.data?.post?.templateID ? this.data.post.templateID : null;
            }

            loader.afterClosed().subscribe((result: any) => {
                if (!result.isSuccess) {
                    this.wasSubmitClick = false;

                    this.validateCommonForm(
                        this.getCommonFormServerErrors(result.error)
                    );

                    // If there are sites successfully instant posted, remove them from the editor
                    if (has(result, "error.error.error.developer.info.successSites")
                        && result.error.error.error.developer.info.successSites !== undefined
                        && result.error.error.error.developer.info.successSites.length
                    ) {
                        const currentSites = this.commonFormGroup.get(this.commonControlNames.SocialSite).value;
                        const successSites = result.error.error.error.developer.info.successSites.map((siteID) => siteID.toString());

                        const newSocialSites = currentSites.filter((siteID) => !includes(successSites, siteID));

                        this.commonFormGroup.get(this.commonControlNames.SocialSite).setValue(newSocialSites);
                    }

                    // If there are sites that are not instant posted, jump to the first tab that contains them
                    if (has(result, "error.error.error.developer.info.failedTypes")
                        && result.error.error.error.developer.info.failedTypes !== undefined
                        && result.error.error.error.developer.info.failedTypes.length
                    ) {
                        const failedTypes = result.error.error.error.developer.info.failedTypes;

                        const tabOrder = this.tabOptions.map((tab) => tab.id);
                        // find the first tab with error
                        const firstErrorTabIndex = tabOrder.findIndex(
                            (tabID) => failedTypes.indexOf(tabID) !== -1
                        );

                        if (firstErrorTabIndex !== -1) {
                            this.setActiveTab(firstErrorTabIndex);
                        }
                    }

                    // If there are sites scheduled, remove them from the editor
                    if (has(result, "error.error.error.info.savedSites")
                        && result.error.error.error.info.savedSites !== undefined
                        && result.error.error.error.info.savedSites.length
                    ) {
                        const currentSites = this.commonFormGroup.get(this.commonControlNames.SocialSite).value;
                        const savedSites = result.error.error.error.info.savedSites.map((siteID) => siteID.toString());

                        const newSocialSites = currentSites.filter((siteID) => !includes(savedSites, siteID));

                        this.commonFormGroup.get(this.commonControlNames.SocialSite).setValue(newSocialSites);
                    }

                    // Invalidate tabs that contain validation errors
                    if (has(result, "error.error.error.info.messages")
                        && result.error.error.error.info.messages !== undefined
                        && Object.keys(result.error.error.error.info.messages).length
                    ) {
                        forEach(Object.keys(result.error.error.error.info.messages), (key) => {
                            const tab = this.tabOptions.find((tab) => tab.id === key);
                            if (tab) {
                                tab.isValid = false;
                            }
                        });
                    }

                    this.openModal.errorModal(DialogErrorComponent, {
                        message: this.readError(result.error),
                    });
                } else {
                    this.openModal
                        .successModal(PostTemplateSuccessCreateModalComponent, {
                            message: this.language.getLine(
                                this.isInstantPost
                                    ? "schedule.create.instant.post.success"
                                    : "schedule.schedule.post.success"
                            ),
                        })
                        .afterClosed()
                        .subscribe((createNew) => {
                            if (createNew) {
                                this.openModal
                                    .successModal(PostCreateModalComponent, {
                                        message: this.language.getLine(
                                            this.isInstantPost
                                                ? "schedule.create.instant.post.success"
                                                : "schedule.schedule.post.success"
                                        ),
                                    })
                                    .afterClosed()
                                    .subscribe((createNew) => {
                                        console.log('createNew', createNew);
                                        if (createNew && result.response && result.response.posts && result.response.posts.length > 0) {
                                            result.response.posts[0].createNewpost = createNew;
                                        }
                                        this.dialogRef.close(
                                            Utils.lodash.get(result, "response.posts",)
                                        );
                                    });

                            } else {
                                this.dialogRef.close(
                                    Utils.lodash.get(result, "response.posts",)
                                );
                            }
                        });

                }
            });

            posts.map((post) => {
                post.status = this.isApproved ? "approved" : null;
                post.draftID = /* this.isInstantPost ? null : */ this.draftID;
                return post;
            });

            if (this.isInstantPost) {
                posts.map((post) => {
                    post.instantPost =
                        this.getCurrentPost() &&
                            !this.isDraftPostEditMode &&
                            !this.isCreateFromTemplateMode &&
                            !this.isPostCopyMode &&
                            !(this.getCurrentPost().postID === undefined) // happens in rss feed instant posts
                            ? { scheduledPostID: this.getCurrentPost().postID }
                            : 1;
                    return post;
                });

                this.postService
                    .instantPost(posts, fromRss, fromMedia, templateID)
                    .then((response) => {
                        loader.close({ isSuccess: true, response: response })
                    }
                    )
                    .catch((error) => {
                        this.refreshPostList = true;
                        loader.close({ isSuccess: false, error: error });
                    });
            } else {
                this.postService
                    .postsSchedule(posts, fromRss, fromMedia, templateID)
                    .then((response) => {
                        loader.close({ isSuccess: true, response: response })
                    }
                    )
                    .catch((error) =>
                        loader.close({ isSuccess: false, error: error })
                    );
            }
        }
    }

    /**
     * Get posts data and validity
     * @return {{posts: {socialType: any; [p: string]: any}[]; allValid: boolean}}
     */
    private getPostsDataAndValidity(
        options: PostSkeletonGetDataForRequestOptions = {}
    ) {
        options = {
            forPreview: false,
            ...options,
        };

        const errors = {};
        let allValid = true;
        this.entities = this.postSkeletons
            .toArray()
            .filter((component) =>
                options.forPreview
                    ? true
                    : component.socialMediaType !== "default"
            )
            .map((component) => {
                return {
                    platform: component.socialMediaType,
                    isValid: this.isInstantPost
                        ? component.validateInstantPostForm()
                        : component.postFormGroup.valid,
                    post: Utils.lodash.cloneDeep(
                        component.getDataForRequest(options)
                    ),
                    formErrors: Utils.lodash.cloneDeep(
                        component.postFormErrorMessages
                    ),
                };
            });
        const posts = this.entities
            .filter((entity) => {
                const tabOption = this.tabOptions.find(
                    (tab) => tab.platform === entity.platform
                );

                if (
                    entity.formErrors &&
                    Object.keys(entity.formErrors).length &&
                    !tabOption.isDisabled
                ) {
                    errors[entity.platform] = entity.formErrors;
                }

                if (tabOption) {
                    return !tabOption.isDisabled;
                }

                return true;
            })
            .map((entity) => {
                if (!entity.isValid) {
                    allValid = false;
                }

                if (
                    entity.platform === SOCIAL_MEDIA_TYPE_GMB &&
                    !entity.post.headline &&
                    !entity.post.subHeadline &&
                    !entity.post.mainCopy &&
                    !entity.post.signature &&
                    !entity.post.fileIDs
                ) {
                    errors[entity.platform] = {
                        mainCopy:
                            "The message/description box and photo/video cannot both be empty.",
                        medias: "The message/description box and photo/video cannot both be empty.",
                    };
                    allValid = false;
                }

                if (
                    entity.platform === SOCIAL_MEDIA_TYPE_GMB &&
                    entity.post.fileIDs &&
                    entity.post.fileIDs.length > 1
                ) {
                    errors[entity.platform] = {
                        medias: "Only one photo is allowed.",
                    };
                    allValid = false;
                }

                if (
                    (entity.platform === SOCIAL_MEDIA_TYPE_LINKEDIN ||
                        entity.platform === SOCIAL_MEDIA_TYPE_TWITTER) &&
                    !entity.post.mainCopy
                ) {
                    errors[entity.platform] = {
                        mainCopy: "The message/description box cannot be empty.",
                    };
                    allValid = false;
                }
                if (entity.platform === SOCIAL_MEDIA_TYPE_FACEBOOK &&
                    !entity.post.headline &&
                    !entity.post.subHeadline &&
                    !entity.post.mainCopy &&
                    !entity.post.signature &&
                    !entity.post.fileIDs) {
                    errors[entity.platform] = {
                        mainCopy: "The message/description box cannot be empty.",
                    };
                    allValid = false;
                }


                return {
                    socialType: entity.platform,
                    ...entity.post,
                    ...this.commonFormGroup.getRawValue(),
                    [this.commonControlNames.SocialSite]:
                        this.getSelectedSocialSiteIDsByPlatform(
                            entity.platform
                        ),
                    [this.commonControlNames.Categories]: [
                        this.commonFormGroup.get(
                            this.commonControlNames.Categories
                        ).value,
                    ],
                };
            });

        allValid = allValid && !!posts.length && this.commonFormGroup.valid;

        if (!options.forPreview) {
            this.errorTitle = "Validation errors";
            this.setErrorMessages(errors);

            if (!allValid && !!errors && Object.keys(errors).length) {
                this.tabOptions.forEach((tab) => {
                    if (errors[tab.id]) {
                        tab.isValid = false;
                    }
                });

                const tabOrder = this.tabOptions.map((tab) => tab.id);
                // find the first tab with error
                const firstErrorTabIndex = tabOrder.findIndex(
                    (tabID) => Object.keys(errors).indexOf(tabID) !== -1
                );

                // order errors in the same order as tabs
                const orderedErrors = {};
                tabOrder.forEach((tabID) => {
                    if (errors[tabID]) {
                        orderedErrors[tabID] = errors[tabID];
                    }
                });

                this.setErrorMessages(orderedErrors);

                this.setActiveTab(firstErrorTabIndex);
            }
        }

        return {
            posts: posts,
            allValid: allValid,
        };
    }

    /**
     * Get social site IDs by social media platform
     * @param {string} platform
     * @return {any[]}
     */
    private getSelectedSocialSiteIDsByPlatform(platform: string) {
        const selectedSocialSiteIDs = this.commonFormGroup.get(
            this.commonControlNames.SocialSite
        ).value;
        const socialSiteIDs = this.socialSites
            .filter(
                (site) =>
                    includes(selectedSocialSiteIDs, site.siteID) &&
                    site.socialType === platform
            )
            .map((site) => site.siteID);

        return socialSiteIDs;
    }

    /**
     * Change selected social sites event
     */
    private changeSelectedSocialSites(socialSitesIDs) {
        const tabIndex = this.tabOptions.findIndex((tab) => tab.isBaseTab);
        const selectedSocialSites = !this.socialSites.length
            ? !!this.getCurrentPost()
                ? this.getCurrentPost().socialSites || []
                : []
            : this.socialSites.filter((site) =>
                includes(socialSitesIDs, site.siteID)
            );

        const socialTypes = Helpers.arrayUnique(
            selectedSocialSites.map((site) => site.socialType)
        );
        this.showCopyCheckbox = socialSitesIDs.length > 1;

        this.selectedSocialSites = selectedSocialSites.map((site) => {
            site.siteID = site.socialSiteID || site.siteID;
            site.name = site.socialSiteName || site.name;
            return site;
        });

        //Insta page selected
        if (
            typeof socialTypes.find((element) => element === "instagram") !==
            "undefined"
        ) {
            if (
                this.baseFormData.hasOwnProperty("medias") &&
                this.baseFormData["medias"]
            ) {
                const medias = this.baseFormData["medias"];
                //Length more than 1
                if (medias.length > 1) {
                    //error
                }

                if (medias && medias.length > 0 && medias[0].type === "image") {
                    const ratioSplit = medias[0].ratio.split(":");
                    const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);

                    if (ratio < 0.8 || ratio > 1.91) {
                        const error = {
                            instagram: {
                                medias: LanguageService.getLine(
                                    "post.template.error.image.ratio"
                                ),
                            },
                        };
                        this.setErrorTitle("Validation error(s)");
                        this.setErrorMessages(error);
                    }
                }
            }
        } else {
            if (
                this.errorMessages &&
                this.errorMessages.includes("instagram")
            ) {
                this.setErrorTitle(null);
                this.setErrorMessages(null);
            }
        }
        if (
            typeof socialTypes.find((element) => element === "facebook") !==
            "undefined"
        ) {
            if (
                this.baseFormData.hasOwnProperty("medias") &&
                this.baseFormData["medias"]
            ) {
                const medias = this.baseFormData["medias"];
                //Length more than 1
                if (medias.length > 1) {
                    //error
                }

                if (medias && medias.length > 0 && medias[0].type === "image") {
                    const ratioSplit = medias[0].ratio.split(":");
                    const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);

                    if (ratio < 0.8 || ratio > 1.91) {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine(
                                    "post.facebook.error.image.ratio"
                                ),
                            },
                        };
                        this.setErrorTitle("Validation error(s)");
                        this.setErrorMessages(error);
                    }
                }
            }
        } else {
            if (
                this.errorMessages &&
                this.errorMessages.includes("facebook")
            ) {
                this.setErrorTitle(null);
                this.setErrorMessages(null);
            }
        }

        this.validateSelectedSocialSitesNumber();

        if (!this.isPostEditMode && !this.createdFromTemplateManager) {
            if (!socialTypes.length) {
                this.tabOptions.map((tab) => {
                    tab.isDisabled = !tab.isBaseTab && !!tab.isPostEditor;
                    tab.isCopied = false;
                    tab.isValid = false;
                    return tab;
                });

                this.setActiveTab(tabIndex);
            } else {
                let needTabChange = false;
                this.tabOptions.map((tab, index) => {
                    const notSelectedThisPlatform = !includes(
                        socialTypes,
                        tab.id
                    );

                    if (
                        notSelectedThisPlatform &&
                        this.currentTabIndex === index &&
                        !tab.isBaseTab
                    ) {
                        needTabChange = true;
                    }

                    tab.isDisabled =
                        tab.isBaseTab || tab.isAllwaysActive
                            ? false
                            : notSelectedThisPlatform;

                    if (!includes(socialTypes, tab.id)) {
                        tab.isCopied = false;
                        tab.isValid = false;
                    }

                    return tab;
                });

                if (needTabChange) {
                    this.setActiveTab(tabIndex);
                }
            }
        }

        // set TinyMCE instagram plugin active on default and instagram pageSW
        if (typeof this.postSkeleton !== "undefined") {
            this.postSkeleton.allowInstaLocationPlugin =
                typeof socialTypes.find(
                    (element) => element === SOCIAL_MEDIA_TYPE_INSTAGRAM
                ) !== "undefined";
            this.postSkeleton.activateInstagramLocationPlugin(
                this.postSkeleton.allowInstaLocationPlugin
            );
        }
    }

    private checkMedias() {
        if (this.selectedSocialSites.length === 0 || this.selectedMedias.length === 0) {
            this.setInfoMessages(null);
            return;
        }

        const containsGmb = this.selectedSocialSites.some(
            (site) => site.socialType === SOCIAL_MEDIA_TYPE_GMB
        );

        const containsVideo = this.selectedMedias.some(
            (media) => media.type === "video"
        );

        const containsMultipleMedia = this.selectedMedias.length > 1; // Check for multiple images/videos

        if ((containsVideo || containsMultipleMedia) && containsGmb) {
            let message = "";
            if (containsVideo) {
                message = "Currently GMB API doesn't support video attachments. Only single images allowed. The selected video is removed from the GMB tab. If you want to attach media element please add a single image instead on the GMB tab.";
            }
            if (containsMultipleMedia) {
                message += "Currently GMB API doesn't support video attachments. Only single images allowed. The selected video is removed from the GMB tab. If you want to attach media element please add a single image instead on the GMB tab.";
            }
            if (!has(this.infoMessages, "gmb.message")) {
                this.setInfoMessages({
                    gmb: {
                        message: message
                    }
                });
            }
        } else {
            this.setInfoMessages(null);
        }
    }

    /**
     * Set social sites disabled state by social platform selectable social sites limit
     */
    private validateSelectedSocialSitesNumber() {
        /*this.socialSites = CommonPostHelpers.filterSocialSiteList(
            this.socialSites,
            this.selectedSocialSites
        );*/
    }

    /**
     * Copy base post form data to post forms of not copied tabs
     */
    private setPostFormData(isSubmit = false) {
        if (isSubmit) {
            const notCopiedIndexes = this.tabOptions
                .filter(
                    (tab) =>
                        isBoolean(tab.isCopied) &&
                        !tab.isCopied &&
                        !tab.isDisabled
                )
                .map((tab) =>
                    this.tabOptions.findIndex((item) => item.id === tab.id)
                );

            this.tabOptions.map((tab, index) => {
                if (includes(notCopiedIndexes, index)) {
                    tab.isCopied = true;
                }

                return tab;
            });

            this.copyBaseFormData.next({
                tabIndexes: notCopiedIndexes,
                emit: isSubmit,
            });
        } else {
            const activatedTab = this.tabOptions[this.currentTabIndex];
            if (
                isBoolean(activatedTab.isCopied) &&
                !activatedTab.isCopied &&
                !activatedTab.isDisabled
            ) {
                activatedTab.isCopied = true;
                this.copyBaseFormData.next({
                    tabIndexes: [this.currentTabIndex],
                    emit: isSubmit,
                });
            }
        }
    }

    /**
     * Get common form errors from server error
     * @param serverError
     * @return {{}}
     */
    private getCommonFormServerErrors(serverError) {
        const formMessages =
            FormValidationService.readError(serverError).formMessages;
        this.errorTitle = FormValidationService.readError(serverError).message;
        this.setErrorMessages(formMessages);
        return FormValidationService.readError(serverError).formMessages;
    }

    /**
     * @param {{[p: string]: {[p: string]: string}}} errors
     */
    private setErrorMessages(
        errors: { [key: string]: { [key: string]: string } } | string
    ) {
        if (errors instanceof Object) {
            this.errorMessages = Object.keys(errors)
                .map((platform) => {
                    const config =
                        SocialMediaPlatformConfig.getConfig(platform);
                    const messages = errors[platform];
                    return (
                        `<strong class="font-bold"><i class="${config.iconClass
                        }"></i> ${Utils.lodash.capitalize(
                            platform
                        )}</strong><br>` +
                        Object.keys(messages)
                            .map((fieldName) => {
                                return `<strong class="font-weight-bold">${LanguageService.getLine(
                                    `post.fieldName.${fieldName}`
                                )}:</strong> ${messages[fieldName]}`;
                            })
                            .join("<br>")
                    );
                })
                .join("<br><br>");
        } else {
            this.errorMessages = errors;
        }

        setTimeout(() => {
            this.scrollTo.scrollTo({
                target: this.messageBox,
            });
        }, 0);
    }

    private setInfoMessages(
        messages: { [key: string]: { [key: string]: string } } | string
    ) {
        if (messages instanceof Object) {
            if (has(messages, "linkshare.message")) {
                this.infoMessages = messages.linkshare.message;
            }
            if (has(messages, "gmb.message")) {
                this.infoMessages = messages.gmb.message;
            }
        } else {
            this.infoMessages = messages;
        }
    }

    private setSelectedMedias(medias: SmdFile[]) {
        this.selectedMedias = medias;
    }

    private setErrorTitle(title: string | null) {
        this.errorTitle = title;
    }

    /**
     * Change selected organizations event
     * @param organizationIDs
     */
    @Debounce(100)
    private changeSelectedOrganizations(organizationIDs) {
        this.initSocialSites(organizationIDs);
    }

    /**
     * Set active tab
     * @param {number} tabIndex
     */
    private setActiveTab(tabIndex: number): void {
        // Szebben nem tudtam megoldani az editor-ok inicializálása miatt.
        // Ha lesz rá idő, belemászok jobban
        document
            .querySelectorAll(".post-management-tab-group .mat-tab-label")
        [tabIndex].dispatchEvent(new Event("click"));
    }

    /**
     * Init common form group
     */
    private commonFormGroupInit() {
        this.commonFormGroup
            .get(this.commonControlNames.SocialSite)
            .valueChanges.subscribe((value) => {
                this.changeSelectedSocialSites(value);
                this.checkMedias();
            });

        /*this.commonFormGroup
            .get(this.commonControlNames.Organization)
            .valueChanges.subscribe((value) =>
                this.changeSelectedOrganizations(value)
            );*/

        this.commonFormGroup.valueChanges.subscribe(() => { });
    }

    selectionChange(form) {
        this.changeSelectedOrganizations(form.value);
    }

    /**
     * Set common form values
     */
    private setCommonFormValues() {
        const post = { ...this.getCurrentPost() };
        const socialSiteIDs =
            post.socialSites && post.socialSites.length
                ? post.socialSites.map((site) => site.socialSiteID)
                : [];

        // this.commonFormGroup
        //     .get(this.commonControlNames.SocialSite)
        //     .setValue(socialSiteIDs);

        this.socialSiteController
            .getItemsByIDs("organizationID", post.organizationIDs, {})
            .then((response: any) => {
                console.log('organizationID123', response);
                const socialSitesall = response.socialSites;

                forEach(post.socialSites, (siter, index) => {
                    const selected_social_site = socialSitesall.find((panel) => panel["siteID"] == siter.socialSiteID);
                    if (selected_social_site.pageType == "group") {
                        const index = socialSiteIDs.indexOf(siter.socialSiteID);
                        socialSiteIDs.splice(index, 1);
                    }
                    console.log('check1', socialSiteIDs);
                    if (selected_social_site.pageType != "group") {
                        this.commonFormGroup
                            .get(this.commonControlNames.SocialSite)
                            .setValue(socialSiteIDs);
                    }
                });
            }
            );
        if (
            this.isPostCopyMode ||
            this.isDraftPostEditMode ||
            this.isPostEditMode
        ) {
            this.changeSelectedSocialSites(socialSiteIDs);
        }

        if (post.categories && post.categories.length) {
            this.commonFormGroup
                .get(this.commonControlNames.Categories)
                .setValue(post.categories.map((category) => category.name)[0]);
        }

        if (post.organizationIDs && post.organizationIDs.length) {
            const organizationIDs = post.organizationIDs.map((ID) =>
                Number(ID)
            );
            this.commonFormGroup
                .get(this.commonControlNames.Organization)
                .setValue(organizationIDs);
            this.changeSelectedOrganizations(organizationIDs);
        }
    }

    private _setFormErrorsByServer(serverErrors: any): void {
        let wrongControlNames = [];
        let socialType;

        for (const socialTypeKey in serverErrors) {
            if (socialTypeKey in serverErrors) {
                const errors = serverErrors[socialTypeKey];
                const controlNames = Object.keys(errors);
                const commonControlNames = Object.values(
                    this.commonControlNames
                );

                if (!socialType) {
                    socialType = socialTypeKey;
                }

                wrongControlNames = ArraySupport.arrayMerge(
                    wrongControlNames,
                    ArraySupport.segment(controlNames, commonControlNames),
                    true
                );
            }
        }

        if (wrongControlNames.length) {
            this.commonFormErrors[socialType || "default"] =
                ObjectSupport.multipleGet(serverErrors, wrongControlNames);
        }

        if (socialType) {
            this.setActiveTab(
                this.tabOptions.findIndex((item) => item.id === socialType)
            );
        }

        for (const controlName of wrongControlNames) {
            if (![PostFormControlNames.SocialSite].includes(controlName)) {
                this.commonFormGroup
                    .get(controlName)
                    .setErrors({ invalid: true });
            }
        }
    }

    /**
     * Get social sites
     */
    private initSocialSites(organizationIDs: number[]) {
        if (!!organizationIDs && !!organizationIDs.length) {
            const filters =
                this.getCurrentPost() &&
                    !!this.getCurrentPost().socialType &&
                    (this.isPostEditMode || this.createdFromTemplateManager)
                    ? {
                        socialType: this.getCurrentPost().socialType,
                    }
                    : {};

            this.socialSitesFetching = true;
            this.socialSiteController
                .getItemsByIDs("organizationID", organizationIDs, filters)
                .then((response: any) => {
                    // TODO: remove mock
                    response.socialSites = response.socialSites.filter(
                        (config) =>
                            socialSiteAppearance?.[config.socialType]
                                ?.schedule !== false
                    );
                    response.socialSites.map((site) => {
                        if (site.socialType === SOCIAL_MEDIA_TYPE_GMB) {
                            site.iconClass = "fa fa-gmb color-gmb";
                        } else {
                            site.iconClass = `fa fa-${site.socialType.toLowerCase()}-square color-${site.socialType.toLowerCase()}`;
                        }
                        if (site.pageType === 'group') {
                            site.isGroup = true;

                        } else {
                            site.isGroup = false;
                        }
                        if (site.pageAccessTokenExpire) {
                            const expire = Date.parse(site.pageAccessTokenExpire);
                            if (expire < Date.now()) {
                                site.accessTokenIsExpired = true;
                            } else {
                                site.accessTokenIsExpired = false;
                            }
                        } else {
                            site.accessTokenIsExpired = false;
                        }

                        const userSiteResources = this.user.resources[site.organizationID];
                        if (userSiteResources) {
                            site.isRefreshableByCurrentUser = userSiteResources.includes("social.site.update");
                            site.tokenExpiredTooltipText = userSiteResources.includes("social.site.update") ? this.language.getLine('social.site.token.expired.refresh') : this.language.getLine('social.site.token.expired');
                        } else {
                            site.isRefreshableByCurrentUser = false;
                            site.tokenExpiredTooltipText = this.language.getLine('social.site.token.expired');
                        }

                        return site;
                    });

                    this.socialSites = Helpers.orderBy(
                        (response.socialSites || []).map((item) => {
                            return { ...item, disabled: false };
                        }),
                        "name"
                    );
                    this.validateSelectedSocialSitesNumber();
                }).finally(() => {
                    this.socialSitesFetching = false;
                });
        } else {
            this.socialSites = [];

            if (!this.isPostEditMode) {
                this.commonFormGroup
                    .get(this.commonControlNames.SocialSite)
                    .setValue([]);
            }
        }
    }

    /**
     * Get organizations
     */
    private initOrganizations() {
        this.organizationController.getItems((response) => {
            this.organizations = response.organizations;
        });
    }

    /**
     * Set modal width
     */
    private changeModalWidth() {
        const width =
            this.currentTabIndex ===
                this.tabOptions.findIndex((platform) => platform.id === "preview")
                ? "auto"
                : "80vw";
        this.dialogRef.updateSize(width);
    }

    refreshSiteToken(site: SocialSiteInterface) {
        const socialType = site.socialType;
        let type = site.pageType;

        if (type == "user" && socialType == "linkedIn") {
            type = "profile";
        }

        if (!socialType || !type) {
            console.error("No socialType or pageType at 'refreshSiteToken' method.");
            return;
        }

        switch (socialType) {
            case SOCIAL_MEDIA_TYPE_FACEBOOK:
                this._refreshFacebookSite(
                    site,
                    type as TYPE_PAGE | TYPE_GROUP
                );
                break;
            case SOCIAL_MEDIA_TYPE_LINKEDIN:
                this._refreshLinkedInSite(
                    site,
                    type as TYPE_PAGE | TYPE_PROFILE
                );
                break;
            case SOCIAL_MEDIA_TYPE_TWITTER:
                this._refreshTwitterSite(site, type as TYPE_PROFILE);
                break;
            case SOCIAL_MEDIA_TYPE_INSTAGRAM:
                this._refreshInstagramSite(site, type as TYPE_PAGE);
                break;
            case SOCIAL_MEDIA_TYPE_GMB:
                this._refreshGmbSite(site, type as TYPE_PAGE);
                break;
            default:
                console.warn(`This social type (${socialType}) is not defined!`);
                break;
        }
    }

    private _refreshFacebookSite(socialSite: SocialSiteInterface, type: TYPE_PAGE | TYPE_GROUP) {
        this.facebookLogin(type).then((res) => {
            if (!!res) {
                const loader = this.openModal.loader(DialogLoaderComponent);
                const methods = {
                    page: "getPages",
                    group: "getGroups",
                };

                this.fb[methods[type]]()
                    .then((response) => {
                        const page = response.data.find(
                            (item) => item.id === socialSite.pageID
                        );

                        if (!!page) {
                            this.socialManagerComponent
                                .editPage(socialSite.siteID, {
                                    name: socialSite.name,
                                    timezone: socialSite.timezone,
                                    pageAccessToken:
                                        type === "group"
                                            ? FacebookUser.getData()
                                                .access_token
                                            : page.access_token,
                                    pageAccessTokenExpire:
                                        SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                                })
                                .then((editResponse) => {
                                    loader.afterClosed().subscribe(() => {
                                        this.openModal
                                            .successModal(
                                                DialogSuccessComponent,
                                                {
                                                    message:
                                                        LanguageService.getLine(
                                                            "social.sites.refresh.success"
                                                        ),
                                                }
                                            )
                                            .afterClosed()
                                            .subscribe(() => {
                                                this.initSocialSites(this.commonFormGroup.get(this.commonControlNames.Organization).value);
                                            });
                                    });

                                    loader.close();
                                })
                                .catch((editError) => {
                                    loader.afterClosed().subscribe(() => {
                                        this.openModal.errorModal(
                                            DialogErrorComponent,
                                            {
                                                message:
                                                    FormValidationService.readError(
                                                        editError
                                                    ).message,
                                            }
                                        );
                                    });

                                    loader.close();
                                });
                        } else {
                            loader.afterClosed().subscribe(() => {
                                this.openModal.errorModal(
                                    DialogErrorComponent,
                                    {
                                        message: LanguageService.getLine(
                                            "organization.socialSite.error.refresh"
                                        ),
                                    }
                                );
                            });

                            loader.close();
                        }
                    })
                    .catch((error) => {
                        loader.close();
                    });
            }
        });
    }

    facebookLogin(type: "page" | "group" | "simple" = "simple") {
        return this.fb.loginWithOptions(type).then((res) => {
            if (res.status === "connected") {
                return res;
            }

            return false;
        });
    }

    private _refreshLinkedInSite(socialSite: SocialSiteInterface, type: TYPE_PAGE | TYPE_PROFILE) {
        const editPage = (loader, condition: any) => {
            if (!!condition) {
                const data = {
                    name: socialSite.name,
                    timezone: socialSite.timezone,
                    pageAccessToken: this.linkedinService.token,
                };

                if (this.linkedinService.expiresIn) {
                    data["pageAccessTokenExpire"] =
                        this.linkedinService.expiresIn;
                }

                this.socialManagerComponent
                    .editPage(socialSite.siteID, data)
                    .then(() => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal
                                .successModal(DialogSuccessComponent, {
                                    message: LanguageService.getLine(
                                        "social.sites.refresh.success"
                                    ),
                                })
                                .afterClosed()
                                .subscribe(() => {
                                    this.initSocialSites(this.commonFormGroup.get(this.commonControlNames.Organization).value);
                                });
                        });

                        loader.close();
                    })
                    .catch((editError) => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.errorModal(DialogErrorComponent, {
                                message:
                                    FormValidationService.readError(editError)
                                        .message,
                            });
                        });

                        loader.close();
                    });
            } else {
                loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message: LanguageService.getLine(
                            "organization.socialSite.error.refresh"
                        ),
                    });
                });

                loader.close();
            }
        };

        this.loginWithLinkedIn(false, () => {
            const loader = this.openModal.loader(DialogLoaderComponent);

            switch (type) {
                case SITE_TYPE.Page:
                    this.linkedinService
                        .getPages()
                        .then((response) => {
                            const condition = response.elements.find(
                                (item: any) =>
                                    item.organizationalTarget ===
                                    socialSite.pageID
                            );

                            editPage(loader, condition);
                        })
                        .catch(() => loader.close());

                    break;

                case SITE_TYPE.Profile:
                    this.linkedinService
                        .getMe()
                        .then((response: any) => {
                            const condition =
                                `urn:li:person:${response.id}` ===
                                socialSite.pageID;

                            editPage(loader, condition);
                        })
                        .catch(() => loader.close());

                    break;

                default:
                    console.warn(`This site type (${type}) is not defined.`);
                    loader.close();

                    break;
            }
        });
    }

    private loginWithLinkedIn(
        isForced = false,
        successCallback: () => void = null
    ) {
        const success = (event: CustomEvent) => {
            if (isForced) {
                setTimeout(() => {
                    this.linkedInLoginWindow.close();
                }, 5000);
            }

            const loader = this.openModal.loader(DialogLoaderComponent);
            this.linkedinService
                .handleLinkedInTokenByCode(event.detail.code)
                .then(() => {
                    this.isLoggedInWithLinkedIn =
                        this.linkedinService.isLoggedIn();

                    loader.afterClosed().subscribe(() => {
                        if (!!successCallback) {
                            successCallback();
                        } else {
                            this.initSocialSites(this.commonFormGroup.get(this.commonControlNames.Organization).value);
                        }
                    });
                    loader.close();
                })
                .catch((err) => {
                    if (err && (err.massage || err.error_description)) {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.errorModal(DialogErrorComponent, {
                                message: err.message || err.error_description,
                            });
                        });
                    }

                    loader.close();
                });
        };
        const failed = (event: CustomEvent) => {
            this.openModal.errorModal(DialogErrorComponent, {
                message: event.detail.message,
            });
        };

        window["smdData"] = {
            forceLogin: isForced,
            success: (event: CustomEvent) => {
                success(event);
            },
            failed: (event: CustomEvent) => {
                failed(event);
            },
        };

        const windowFeatures =
            "location=yes,height=570,width=520,scrollbars=no,status=no";
        this.linkedInLoginWindow = window.open(
            this.linkedinService.url,
            null,
            windowFeatures
        );
    }

    private _refreshTwitterSite(socialSite: SocialSiteInterface, type: TYPE_PROFILE) {
        this.twitterAPI.authenticate((data) => {
            if (data.successAuth) {
                this.componentHelpers.startApiAction(
                    () => {
                        if (
                            socialSite.pageID.toString() ===
                            data.twitterToken.user_id.toString()
                        ) {
                            return this.socialManagerComponent.editPage(
                                socialSite.siteID,
                                {
                                    name: socialSite.name,
                                    timezone: socialSite.timezone,
                                    pageAccessToken:
                                        data.twitterToken.oauth_token,
                                    accessTokenSecret:
                                        data.twitterToken.oauth_token_secret,
                                }
                            );
                        } else {
                            return Promise.reject({
                                error: {
                                    error: {
                                        message: LanguageService.getLine(
                                            "organization.socialSite.error.refresh"
                                        ),
                                    },
                                },
                            });
                        }
                    },
                    {
                        successMessageKey: "social.sites.refresh.success",
                        failedMessageKey:
                            "organization.refresh.twitter.site.failed",
                        afterSuccessAction: () => {
                            this.initSocialSites(this.commonFormGroup.get(this.commonControlNames.Organization).value);
                        },
                    }
                );
            } else {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        data.message ||
                        Utils.get(data, "error.message", null) ||
                        LanguageService.getLine(
                            "organization.refresh.twitter.site.failed"
                        ),
                });
            }
        });
    }

    private _refreshInstagramSite(socialSite: SocialSiteInterface, type: TYPE_PAGE | TYPE_GROUP) {
        this.facebookLogin(type).then((res) => {
            if (!!res) {
                const loader = this.openModal.loader(DialogLoaderComponent);
                const methods = {
                    page: "getPages",
                };

                this.fb[methods[type]]()
                    .then(async (response) => {
                        try {
                            let fbPages = response.data;
                            const businessReturn =
                                await this.getInstaBusinesses(fbPages);
                            const businessPages = await businessReturn.filter(
                                (businessPage) => businessPage !== null
                            );

                            let page = await businessPages.find(
                                (item) => item.id == socialSite.pageID
                            );
                            if (!!page) {
                                this.fb
                                    .getPageAccessToken(
                                        page.facebook_page_id,
                                        this.backend
                                    )
                                    .then((access) => {
                                        this.socialManagerComponent
                                            .editPage(socialSite.siteID, {
                                                name: socialSite.name,
                                                timezone: socialSite.timezone,
                                                pageAccessToken:
                                                    type === "group"
                                                        ? FacebookUser.getData().access_token
                                                        : access.accessToken,
                                                userAccessToken:
                                                    type === "group"
                                                        ? FacebookUser.getData().access_token
                                                        : access.userAccessToken,
                                                pageAccessTokenExpire:
                                                    SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                                                connectedFacebookPageID: page.facebook_page_id,
                                            })
                                            .then((editResponse) => {
                                                loader
                                                    .afterClosed()
                                                    .subscribe(() => {
                                                        this.openModal
                                                            .successModal(
                                                                DialogSuccessComponent,
                                                                {
                                                                    message:
                                                                        LanguageService.getLine(
                                                                            "social.sites.refresh.success"
                                                                        ),
                                                                }
                                                            )
                                                            .afterClosed()
                                                            .subscribe(() => {
                                                                this.initSocialSites(this.commonFormGroup.get(this.commonControlNames.Organization).value);
                                                            });
                                                    });

                                                loader.close();
                                            })
                                            .catch((editError) => {
                                                loader
                                                    .afterClosed()
                                                    .subscribe(() => {
                                                        this.openModal.errorModal(
                                                            DialogErrorComponent,
                                                            {
                                                                message:
                                                                    FormValidationService.readError(
                                                                        editError
                                                                    ).message,
                                                            }
                                                        );
                                                    });

                                                loader.close();
                                            });
                                    });
                            } else {
                                loader.afterClosed().subscribe(() => {
                                    this.openModal.errorModal(
                                        DialogErrorComponent,
                                        {
                                            message: LanguageService.getLine(
                                                "organization.socialSite.error.refresh"
                                            ),
                                        }
                                    );
                                });

                                loader.close();
                            }
                        } catch (e) {
                            loader.afterClosed().subscribe(() => {
                                this.openModal.errorModal(
                                    DialogErrorComponent,
                                    {
                                        message: LanguageService.getLine(
                                            "organization.socialSite.error.refresh"
                                        ),
                                    }
                                );
                            });
                            loader.close();
                        }
                    })
                    .catch((error) => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.errorModal(DialogErrorComponent, {
                                message: LanguageService.getLine(
                                    "organization.socialSite.error.refresh"
                                ),
                            });
                        });
                        loader.close();
                    });
            }
        });
    }

    async getInstaBusinesses(fbPages) {
        const promises = [];
        for (const page of fbPages) {
            promises.push(this.getInstaPages(page));
        }

        const responses = await Promise.all(promises);
        const instaPages = responses.filter((response) => response !== null);
        const businessPromises = [];
        for (const response of instaPages) {
            if (response) {
                businessPromises.push(this.getBusinessInstaPages(response));
            }
        }
        return await Promise.all(businessPromises);
    }

    async getInstaPages(page) {
        try {
            const response = await this.fb.getInstagramPages(
                page.id,
                page.access_token
            );
            if (response.data.length) {
                //console.log("Found instagram page connected to: " + page.name);
                return {
                    facebook_page_id: page.id,
                    access_token: page.access_token,
                    name: response.data[0]["username"],
                };
            } else {
                return null;
            }
        } catch (e) {
            //console.log("getInstaPage error:");
            //console.log(e);
            return null;
        }
    }

    async getBusinessInstaPages(page) {
        try {
            const response = await this.fb.getInstagramBusinessID(
                page.facebook_page_id,
                page.access_token
            );
            if (response.instagram_business_account) {
                return { ...page, id: response.instagram_business_account.id };
            } else {
                return null;
            }
        } catch (e) {
            //console.log("getBusinessInstaPages error:");
            //console.log(e);
            return null;
        }
    }

    private _refreshGmbSite(socialSite: SocialSiteInterface, type: TYPE_PAGE) {
        this.gmbApiService.authorization().then(() => {
            const loader = this.openModal.loader(DialogLoaderComponent);
            this.gmbApiService.get().then((response: any) => {
                if (
                    response.length > 0 && response.map(item => (item.location)).filter(Boolean).length > 0
                ) {
                    const splitID = socialSite.pageID.split("/");
                    const pageID = splitID.length === 4 ? splitID[3] : null;
                    const location = response.find(
                        (item) => item.location.name.split("/")[1] === pageID
                    );

                    if (location) {
                        this.gmbApiService
                            .refresh(socialSite.siteID)
                            .then(() => {
                                loader.afterClosed().subscribe(() => {
                                    this.openModal
                                        .successModal(DialogSuccessComponent, {
                                            message: LanguageService.getLine(
                                                "social.sites.refresh.success"
                                            ),
                                        })
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.initSocialSites(this.commonFormGroup.get(this.commonControlNames.Organization).value);
                                        });
                                });
                                loader.close();
                            })
                            .catch((response) => {
                                loader.afterClosed().subscribe(() => {
                                    this.openModal.errorModal(
                                        DialogErrorComponent,
                                        {
                                            message: LanguageService.getLine(
                                                "organization.socialSite.error.refresh"
                                            ),
                                        }
                                    );
                                });
                                loader.close();
                            });
                    } else {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: LanguageService.getLine(
                                "organization.socialSite.error.refresh"
                            ),
                        });
                        loader.close();
                    }
                }
            });
        });
    }
}
