import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ModelAbstract} from '../../services/model.abstract';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class ActivityService extends ModelAbstract {


    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/activity';
    }
}
