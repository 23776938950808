import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import {WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_OVERVIEW} from '~/src/app/modules/analytics/widget/metric-configs/linkedin/interactions';
import {WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_OVERVIEW} from '~/src/app/modules/analytics/widget/metric-configs/linkedin/impressions';
import {WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_OVERVIEW} from '~/src/app/modules/analytics/widget/metric-configs/linkedin/followers';

export const WIDGET_METRIC_OPTION_LINKEDIN_OVERVIEW: WidgetMetricOptionType = {
    id: 'linkedin-overview',
    name: 'Overview (Interactions, Impressions, Followers)',
    resource: [
        'analytics.analytics.interactions',
        'analytics.analytics.pageImpressions',
        'analytics.analytics.pageOverallLikes'
    ],
    socialChannel: 'linkedIn'
};

export const MetricLinkedInOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_OVERVIEW.id,
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Overview (Interactions, Impressions, Followers)',
    chartConfig: {
        ...BaseChartConfig
    },
    partMetricIDs: [
        WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_OVERVIEW.id,
        WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_OVERVIEW.id,
        WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_OVERVIEW.id,
    ]
};
