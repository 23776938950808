import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConsoleLoggerService } from "~/src/app/shared/services/log/console-logger.service";
import { SocialPlatformSwitcherComponent } from "~/src/app/shared/components/social-platform-switcher/social-platform-switcher.component";
import { StorageServiceModule } from "~/node_modules/angular-webstorage-service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PreloaderModule } from "~/src/app/components/preloader/preloader.module";
import { ShowPreviewModule } from "~/src/app/directives/show-preview/show-preview.module";
import { FileUploadModule } from "ng2-file-upload";
import { ResourceCheckerModule } from "~/src/app/directives/resource-checker/resource-checker.module";
import { MaterialModule } from "~/src/app/modules/material.module";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { PostPreviewComponent } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.component";
import { ImagePreloadModule } from "~/src/app/shared/image-preload.module";
import { NgxGalleryModule } from "@kolkov/ngx-gallery";
import { LanguagesModule } from "~/src/app/modules/languages/languages.module";
import { AcceptanceComponent } from "~/src/app/modules/social-media-post/acceptance/acceptance.component";
import { SlickCarouselModule } from "~/node_modules/ngx-slick-carousel";
import { ChartsModule } from "ng2-charts";
import { InnerHtmlModule } from "~/src/app/directives/inner-html/inner-html.module";
import { SocialPlatformService } from "~/src/app/shared/components/social-platform-switcher/social-platform-switcher.service";
import { SelectActionDirective } from "~/src/app/shared/directives/select-action.directive";
import { SmdDatePipe } from "~/src/app/core/pipes/smd-date.pipe";
import { SmdCalendarDatePipe } from "~/src/app/core/pipes/calendar-title.pipe";
import { PartnerHasDirective } from "~/src/app/shared/directives/partner-has.directive";
import { CollectionListComponent } from "~/src/app/core/components/collection-list/collection-list.component";
import { ItemSeparationModule } from "~/src/app/core/components/item-separation/item-separation.module";
import { CategorySelectComponent } from "~/src/app/core/components/category-select/category-select.component";
import { MediaTagSelectComponent } from "~/src/app/core/components/media-tag-select/media-tag-select.component";
import { NgxMatDrpModule } from "~/node_modules/ngx-mat-daterange-picker";
import { TagSelectComponent } from "~/src/app/core/components/tag-select/tag-select.component";
import { ActivationButtonComponent } from "~/src/app/core/components/activation-button/activation-button.component";
import { PartnerConfigPipe } from "~/src/app/modules/administration/partners/partner-config.pipe";
import { ButtonGroupDirective } from "~/src/app/core/components/collection-list/button-group.directive";
import { OtherButtonsComponent } from "~/src/app/core/components/other-buttons/other-buttons.component";
import { ButtonDirective } from "~/src/app/core/components/other-buttons/button.directive";
import { CustomViewDirective } from "~/src/app/core/components/collection-list/custom-view.directive";
import { ColorPickerModule } from "~/node_modules/ngx-color-picker";
import { NgSelectComponent } from "~/src/app/core/components/ng-select/ng-select.component";
import { MessageBoxComponent } from "~/src/app/core/components/message-box/message-box.component";
import { StartCasePipe } from "~/src/app/core/pipes/start-case.pipe";
import { PostMediaViewComponent } from "~/src/app/modules/social-media-post/post-media-view/post-media-view.component";
import { SmdGalleryComponent } from "~/src/app/components/smd-gallery/smd-gallery.component";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { OrganizationSelectComponent } from "~/src/app/core/components/organization-select/organization-select.component";
import { SelectButtonsComponent } from "~/src/app/core/components/select-buttons/select-buttons.component";
import { SocialSiteCardComponent } from "~/src/app/modules/users/organizations/social-site-card/social-site-card.component";
import { FeedCardComponent } from "~/src/app/modules/rss/components/feed-card/feed-card.component";
import { PartnerInfoComponent } from "~/src/app/shared/components/partner-info/partner-info.component";
import { PartnerInfoModalComponent } from "~/src/app/shared/components/partner-info-modal/partner-info-modal.component";
import { SocialSiteChooserModalComponent } from "~/src/app/shared/components/social-site-chooser-modal/social-site-chooser-modal.component";
import { SocialSiteSelectComponent } from "~/src/app/components/social-site-select/social-site-select.component";
import { LightboxModule } from "ng-gallery/lightbox";
import { GalleryModule } from "ng-gallery";
import { PositioningDirective } from "./directives/positioning.directive";
import { GmbPostPreviewComponent } from "../modules/posts/post-previews/gmb-post-preview/gmb-post-preview.component";
import { GmbMediaComponent } from "../modules/posts/post-previews/gmb-post-preview/gmb-media.component";
import { OrganizationCheckboxTreeComponent } from "../core/components/organization-checkbox-tree/organization-checkbox-tree.component";
import { SocialSiteTreeComponent } from "../core/components/social-site-tree/social-site-tree.component";
import { CommentsComponent } from '~/src/app/modules/social-media-post/comments/comments.component';
import { InfiniteScrollModule } from '~/node_modules/ngx-infinite-scroll';
import { MentionModule as AngularMentionModule } from '~/node_modules/angular-mentions';
import { LanguagePipe } from "../core/pipes/language.pipe";
import { FileSizePipe } from "../core/pipes/filesize.pipe";
import { TemplateFolderViewComponent } from "../modules/posts/template-folder-view/template-folder-view.component";
import { FolderCardComponent } from "../modules/posts/folder-card/folder-card.component";
import { TemplateFolderFiltersComponent } from "../modules/posts/template-folder-filters/template-folder-filters.component";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule.withConfig({
            warnOnNgModelWithFormControl: "never",
        }),
        ResourceCheckerModule,
        ShowPreviewModule,
        LanguagesModule,
        PreloaderModule,
        FileUploadModule,
        BrowserAnimationsModule,
        StorageServiceModule,
        ScrollToModule.forRoot(),
        ImagePreloadModule,
        LightboxModule,
        GalleryModule,
        NgxGalleryModule,
        SlickCarouselModule,
        ChartsModule,
        NgxMatDrpModule,
        MaterialModule,
        InnerHtmlModule,
        ItemSeparationModule,
        ColorPickerModule,
        PickerModule,
        InfiniteScrollModule,
        AngularMentionModule,
    ],
    declarations: [
        SocialPlatformSwitcherComponent,
        PostPreviewComponent,
        GmbPostPreviewComponent,
        GmbMediaComponent,
        AcceptanceComponent,
        SelectActionDirective,
        SmdDatePipe,
        SmdCalendarDatePipe,
        LanguagePipe,
        FileSizePipe,
        PartnerHasDirective,
        CollectionListComponent,
        ButtonGroupDirective,
        CategorySelectComponent,
        MediaTagSelectComponent,
        TagSelectComponent,
        ActivationButtonComponent,
        PartnerConfigPipe,
        OtherButtonsComponent,
        ButtonDirective,
        CustomViewDirective,
        NgSelectComponent,
        MessageBoxComponent,
        StartCasePipe,
        SmdGalleryComponent,
        PostMediaViewComponent,
        OrganizationSelectComponent,
        OrganizationCheckboxTreeComponent,
        SelectButtonsComponent,
        SocialSiteCardComponent,
        FeedCardComponent,
        PartnerInfoComponent,
        PartnerInfoModalComponent,
        SocialSiteSelectComponent,
        SocialSiteChooserModalComponent,
        PositioningDirective,
        SocialSiteTreeComponent,
        CommentsComponent,
        TemplateFolderViewComponent,
        TemplateFolderFiltersComponent,
        FolderCardComponent,
    ],
    exports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ResourceCheckerModule,
        ShowPreviewModule,
        PreloaderModule,
        FileUploadModule,
        MaterialModule,
        BrowserAnimationsModule,
        SocialPlatformSwitcherComponent,
        StorageServiceModule,
        PostPreviewComponent,
        GmbPostPreviewComponent,
        GmbMediaComponent,
        LightboxModule,
        GalleryModule,
        NgxGalleryModule,
        ImagePreloadModule,
        ScrollToModule,
        AcceptanceComponent,
        SlickCarouselModule,
        ChartsModule,
        InnerHtmlModule,
        SelectActionDirective,
        SmdDatePipe,
        NgxMatDrpModule,
        SmdCalendarDatePipe,
        LanguagePipe,
        FileSizePipe,
        PartnerHasDirective,
        CollectionListComponent,
        ButtonGroupDirective,
        ItemSeparationModule,
        CategorySelectComponent,
        MediaTagSelectComponent,
        TagSelectComponent,
        ActivationButtonComponent,
        PartnerConfigPipe,
        OtherButtonsComponent,
        ButtonDirective,
        CustomViewDirective,
        ColorPickerModule,
        NgSelectComponent,
        MessageBoxComponent,
        StartCasePipe,
        SmdGalleryComponent,
        PostMediaViewComponent,
        PickerModule,
        OrganizationSelectComponent,
        OrganizationCheckboxTreeComponent,
        SelectButtonsComponent,
        SocialSiteCardComponent,
        FeedCardComponent,
        PartnerInfoComponent,
        PartnerInfoModalComponent,
        SocialSiteSelectComponent,
        SocialSiteChooserModalComponent,
        PositioningDirective,
        SocialSiteTreeComponent,
        CommentsComponent,
        TemplateFolderViewComponent,
        TemplateFolderFiltersComponent,
        FolderCardComponent,
    ],
    entryComponents: [
        PostPreviewComponent,
        PartnerInfoModalComponent,
        SocialSiteChooserModalComponent,
    ],
    providers: [
        ConsoleLoggerService,
        SocialPlatformService,
        SocialPlatformSwitcherComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
