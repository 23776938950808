
export class ColorSupport {

    /**
     * Return random color in hex
     *
     * @returns {string}
     */
    static getRandomColorInHex(): string {
        const letters = 'BCDEF'.split('');
        let color = '#';

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }

        return color;
    }

    /**
     * Get random color in rgba
     *
     * @param {number} opacity - number between 0 and 1. Default: 1
     * @returns {string}
     */
    static getRandomColorInRgba(opacity?: number): string {
        return this.convertHexToRgba(
            this.getRandomColorInHex(),
            opacity
        );
    }

    /**
     * Convert hex color to rgba, return with rgba color
     *
     * @param {string} hex
     * @param {number} opacity - number between 0 and 1. Default: 1
     * @returns {string}
     */
    static convertHexToRgba(hex: string, opacity: number = 1): string {
        let c;

        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');

            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }

            c = '0x' + c.join('');

            // tslint:disable-next-line:no-bitwise
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
        }

        return null;
    }
}
