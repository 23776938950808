import {Component, HostListener} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {FALLBACK_IMAGE, LOADING_IMAGE} from './configs/configs';
import {MatDialog} from '@angular/material/dialog';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    keyPressValue = '';

    constructor(
        public dialog: MatDialog,
        private meta: Meta,
        public openModal: OpenModalService
    ) {
        this.meta.addTags([
            {rel: 'preload', href: LOADING_IMAGE, as: 'image'},
            {rel: 'preload', href: FALLBACK_IMAGE, as: 'image'}
        ]);
    }

    // @HostListener('document:keypress', ['$event'])
    // onDocumentKeyPress(event) {
    //     this.keyPressValue += event.key;
    //
    //     this.checkKeyPress();
    // }
    //
    // @Debounce()
    // checkKeyPress() {
    //     if (this.keyPressValue === 'cp') {
    //         this.openModal.createPost(PostManagerComponent);
    //     }
    //
    //     this.keyPressValue = '';
    // }
}
