import {NgModule} from '@angular/core';
import {DialogEditWidgetComponent} from './dialog-edit-widget.component';
import {CommonModule} from '@angular/common';
import {LanguagesModule} from '../../languages/languages.module';
import {EditorModule} from '@tinymce/tinymce-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '~/src/app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LanguagesModule,
        EditorModule,
        SharedModule
    ],
    declarations: [DialogEditWidgetComponent],
    exports: [DialogEditWidgetComponent],
    entryComponents: [DialogEditWidgetComponent]
})
export class EditWidgetModule {}
