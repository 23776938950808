import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LINKEDIN_FAILED_LOGIN_EVENT_NAME, LINKEDIN_SUCCESS_LOGIN_EVENT_NAME} from '~/src/app/configs/configs';
import Utils from '~/src/app/core/utils';
import {LinkedInService} from '~/src/app/services/linked-in.service';

@Component({
    selector: 'smd-linkedin-login',
    templateUrl: './linkedin-login.component.html',
    styleUrls: ['./linkedin-login.component.scss']
})
export class LinkedinLoginComponent {

    constructor(
        private activatedRoute: ActivatedRoute,
        private linkedInService: LinkedInService
    ) {
        this.handleLinkedInLogin();
    }

    private handleLinkedInLogin() {
        if (this.activatedRoute.snapshot.queryParams.code) {
            // window.opener.dispatchEvent(new CustomEvent(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, {
            //     detail: {
            //         code: this.activatedRoute.snapshot.queryParams.code
            //     }
            // }));

            window.opener['smdData'].success(new CustomEvent(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, {
                detail: {
                    code: this.activatedRoute.snapshot.queryParams.code,
                }
            }));

            if (!!Utils.lodash.get(window.opener, 'smdData.forceLogin')) {
                window.opener.localStorage.removeItem(this.linkedInService.linkedInAuthLocalstorageKey);
                location.href = 'https://www.linkedin.com/m/logout';
            } else {
                window.close();
            }

        } else if (
            this.activatedRoute.snapshot.queryParams.error &&
            this.activatedRoute.snapshot.queryParams.error === 'user_cancelled_login'
        ) {
            // window.opener.dispatchEvent(new CustomEvent(LINKEDIN_FAILED_LOGIN_EVENT_NAME, {
            //     detail: {
            //         message: 'Login cancelled by user'
            //     }
            // }));

            window.opener['smdData'].failed(new CustomEvent(LINKEDIN_FAILED_LOGIN_EVENT_NAME, {
                detail: {
                    message: 'Login cancelled by user'
                }
            }));

            window.close();
        }
    }
}
