import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import Utils from './core/utils';
import { CacheService } from './services/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    cacheEnabled: boolean = environment.cache.enabled;
    cacheIgnoreUrls: string[] = [
        '/api/export/server-time',
        '/api/notifications',
        '/api/notifications/last-notification-date',
        '/api/notifications/seen',
        '/api/users/update-token',
        '/api/dashboard/data-available',
        '/api/users/me', // only here because of notification settings not updating after editing orgs(?). TODO: if there are too many requests for this, check if a correlation is better
        '/api/log',
        '/api/template-folder/minimal',
    ];

    cacheCorrelatedUrls: Array<Array<string>>  = [
        ["/api/social-sites", "/api/organization", "/api/social/gmb/refresh"], 
        ["/api/media", "/api/carousel"], 
        ["/api/post/template", "/api/template-folder"],
        ["/api/organization", "/api/template-folder"],
    ];
    //_ongoingRequests = new Map<string, {req: Observable<HttpEvent<any>>, startedAt: number}>();

    constructor(
        private _cacheService: CacheService,
        private http: HttpClient
    ) { }

    _handle(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        //if (this._ongoingRequests.has(request.urlWithParams)) {
            //if (this._ongoingRequests.get(request.urlWithParams).startedAt > Date.now() - 15 * 1000) {
                // TODO...
                //console.log("Request is already ongoing, cancelling the new one");
                //return new Observable(observer => {
                //    observer.error(new Error("Request is already ongoing"));
                //});
            //} else {
                //this._ongoingRequests.delete(request.urlWithParams);
            //}
        //}

        let obs = next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.status === 200) {
                        this._cacheService.put(request.urlWithParams, Utils.lodash.cloneDeep(event.clone()));
                    }
                }
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    this._cacheService.invalidate(request.urlWithParams);
                }

                return new Observable<HttpEvent<any>>(observer => {
                    console.error('Error while intercepting in interceptor...');
                    observer.error(err);
                });
            })
        );

        //this._ongoingRequests.set(request.urlWithParams, {req: obs, startedAt: Date.now()});
        return obs;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.cacheEnabled) {
            return next.handle(request);
        }

        if (request.method !== 'GET') {
            if (request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE' || request.method === 'PATCH') {
                // We should invalidate the cache for all the endpoints that are related to the current request.
                // This way, for example: api/media/file-upload will invalidate api/media.
                let prefixArray = request.url.match(/.*api\/\w*\/{0,1}/);
                if (prefixArray) {
                    const prefix = prefixArray[0].replace(/\/$/, ''); // remove trailing slash if present
                    this._cacheService.invalidateWithPrefix(prefix);
                }

                // If there are correlating URLs, we should invalidate them as well.
                // For example, when refreshing a social site, we should invalidate the organizations endpoint.
                if (this.cacheCorrelatedUrls.length > 0) {
                    for (const correlatedUrl of this.cacheCorrelatedUrls) {
                        if (correlatedUrl.some(url => request.url.includes(url))) {
                            for (const url of correlatedUrl) {
                                this._cacheService.invalidateWithPart(url);
                            }
                        }
                    }
                }
            }
            return next.handle(request);
        }

        if (this.cacheIgnoreUrls.some(url => request.url.indexOf(url) !== -1)) {
            return next.handle(request);
        }

        const cachedResponse = this._cacheService.get(request.urlWithParams);
        if (cachedResponse) {
            return new Observable(observer => {
                observer.next(cachedResponse);
                observer.complete();
            });
        }

        return this._handle(next, request);
    }
}
