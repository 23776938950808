import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoggingRequestFilters, LoggingResponse } from "./logging.interface";
import { Helpers } from "../../services/helpers";
import { Token } from "../../services/token";

@Injectable({
    providedIn: "root",
})
export class LoggingService{
    apiLink: string;

    constructor(public http: HttpClient) {
        this.apiLink = "/api/log";
    }

    getLogs(filters: LoggingRequestFilters = {n: 10, filter: '', date: '2022-12-22'}): Promise<LoggingResponse> {
        // some hacky date manipulation, sorry, had to be fast - for some reason we did not get the correct format here, so lets fix it
        filters.date = new Date(filters.date).toISOString().split('T')[0];

        const query = "?filter=" + filters.filter + "&n=" + filters.n + "&date=" + filters.date;
        return this.http.get(this.apiLink + "/general" + query, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise() as Promise<LoggingResponse>;
    }
}
