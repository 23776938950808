export class Icons {
    public static IMAGE = 'mdi mdi-file-image';
    public static VIDEO = 'mdi mdi-file-video';
    public static CAROUSEL = 'mdi mdi-view-carousel';
    public static OG = 'mdi mdi-web';
    public static TEXT = 'mdi mdi-note-text';
    public static VIDEO_360 = 'fa fa-refresh';

    public static DASHBOARD_VIEW = 'mdi mdi-eye';
    public static DASHBOARD_UPDATE = 'mdi mdi-lead-pencil';
    public static DASHBOARD_DELETE = 'mdi mdi-delete';

    public static getIcon(name: string): string {
        const key = name.toUpperCase();
        return this[key] || 'mdi mdi-help';
    }
}
