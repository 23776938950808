import {Injectable} from '@angular/core';
import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {
    IOrganizationList,
    IOrganizationRequestData,
    IOrganizationTree
} from '~/src/app/modules/users/organizations/organization.interface';
import {OrganizationModel} from '~/src/app/modules/users/organizations/organization.model';
import {
    ORGANIZATION_API_RESOURCE,
    ORGANIZATION_TREE_API_RESOURCE
} from '~/src/app/modules/users/organizations/organization.constant';

@Injectable({providedIn: 'root'})
export class OrganizationService {
    constructor(
        private backendService: BackendService
    ) {
    }

    /**
     * Get organization items
     * @param {RequestParams} params
     * @return {Promise<IOrganizationList<OrganizationModel>>}
     */
    getList(params: RequestParams = {}): Promise<IOrganizationList<OrganizationModel>> {
        return this.backendService.get(ORGANIZATION_API_RESOURCE, params)
            .then((response: IOrganizationList) => {
                response.organizations = (response.organizations || []).map(item => new OrganizationModel(item));
                return Promise.resolve(response as IOrganizationList<OrganizationModel>);
            });
    }

    /**
     * Get organization tree
     * @param {RequestParams} params
     * @return {Promise<IOrganizationTree>}
     */
    getTree(params: RequestParams = {}): Promise<IOrganizationTree> {
        return this.backendService.get(ORGANIZATION_TREE_API_RESOURCE);
    }

    /**
     * Create organization
     * @param {IOrganizationRequestData} data
     * @return {Promise<any>}
     */
    create(data: IOrganizationRequestData): Promise<any> {
        return this.backendService.post(ORGANIZATION_API_RESOURCE, data);
    }

    remove(organizationID: number): Promise<any> {
        return this.backendService.delete(`${ORGANIZATION_API_RESOURCE}/${organizationID}`);
    }
}
