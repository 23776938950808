import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LanguageService} from '~/src/app/services/language.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {TemplateActions} from '~/src/app/modules/posts/template-actions';
import {PostActions} from '~/src/app/modules/posts/post-actions';
import {TagsService} from '~/src/app/modules/posts/tags.service';
import {PostActionsService} from '~/src/app/modules/posts/post-actions.service';
import {CarouselController} from '~/src/app/modules/posts/carousel/carousel.component';
import {CategoriesService} from '~/src/app/modules/posts/categories.service';
import {UsersController} from '~/src/app/modules/users/users/users.component';
import {TemplateActionsService} from '~/src/app/modules/posts/template-actions.service';
import {Helpers} from '~/src/app/services/helpers';
import {CommentController} from '~/src/app/modules/posts/comments/post-comment/comment-controller.service';
import {NotifyService} from '~/src/app/services/notify.service';
import {truncate} from 'lodash';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostManagerComponent} from '~/src/app/modules/social-media-post/post-manager/post-manager.component';
import {PostTemplateManagerComponent} from '~/src/app/modules/social-media-post/post-template-manager/post-template-manager.component';
import { PostPreviewComponent } from '../../posts/post-previews/post-preview/post-preview.component';
import { SmdFile } from '~/src/app/services/file.class';
import CommonPostHelpers from '../../posts/common-post-helpers';
import { IRelatedUser } from '../../users/users/users.interface';

@Component({
    selector: 'smd-recent-comments',
    templateUrl: './recent-comments.component.html',
    styleUrls: ['./recent-comments.component.scss']
})
export class RecentCommentsComponent implements OnInit {
    commentsLoading = false;
    postActions;
    templateActions;
    postComments = [];

    constructor(
        public dialog: MatDialog,
        public comments: CommentController,
        public postActionsService: PostActionsService,
        public usersController: UsersController,
        public carouselController: CarouselController,
        public templateActionsService: TemplateActionsService,
        public tagsService: TagsService,
        public categoriesService: CategoriesService,
        private openModal: OpenModalService
    ) {
        this.postActions = new PostActions(postActionsService, dialog, usersController, carouselController, openModal);
        this.templateActions = new TemplateActions(templateActionsService,
            tagsService,
            categoriesService,
            postActionsService,
            dialog,
            usersController,
            carouselController,
            openModal
        );

        this.initLatestComments();
    }

    ngOnInit() {}

    commentPostClick(commentObject) {
        if (!commentObject || !commentObject['objectID'] ||
            ['post', 'posttemplate'].indexOf(commentObject.objectType.toLowerCase()) === -1) {
            return;
        }

        let loader;

        const promiseOfGettingObject = new Promise((resolve, reject) => {

            loader = this.dialog.open(DialogLoaderComponent, {
                disableClose: true,
                panelClass: 'dialog-loader-modal',
                minWidth: '200vw',
                minHeight: '200vh',
                hasBackdrop: false
            });

            if (commentObject.objectType.toLowerCase() === 'post') {
                this.postActions.getItemByID({'postIDs': [commentObject['objectID']]}).then((post) => {
                    if (post) {
                        resolve(post);
                    } else {
                        reject();
                    }
                });
            }

            if (commentObject.objectType.toLowerCase() === 'posttemplate') {
                this.templateActions.getItemByID({'templateID': [commentObject['objectID']]}).then((template) => {
                    if (template) {
                        resolve(template);
                    } else {
                        reject();
                    }
                });
            }
        });

        promiseOfGettingObject.then((postOrTemplate) => {
            if (!postOrTemplate['count']) {
                NotifyService.info(LanguageService.getLine('post.list.post.doesNotExist'), '');
                loader.close();
                return;
            }
            loader.afterClosed().subscribe(() => {
                const isPost = !!(commentObject.objectType === 'post');
                const isPostTemplate = !!(commentObject.objectType === 'postTemplate');
                const entities = isPost ? postOrTemplate['posts'] : isPostTemplate ? postOrTemplate['templates'] : null;
                let entity = !!entities && !!entities[0] ? entities[0] : null;

                
                if (!entity) {
                    return;
                }
                
                if (isPost && entity.type === 'draft') {
                    entity = CommonPostHelpers.mergeDataWithDraftData(entity);
                }

                if (entity.medias) {
                    entity.medias = entity.medias.map(
                        (media) => new SmdFile(media)
                    );
                }

                if (isPost) {
                    this.openModal.commentToPost(PostPreviewComponent, entity).afterClosed().subscribe(() => {
                        this.initLatestComments();
                    });
                } else if (isPostTemplate) {
                    this.openModal.commentTemplate(PostPreviewComponent, entity).afterClosed().subscribe(() => {
                        this.initLatestComments();
                    });
                }
            });

            loader.close();

        }).catch(() => {
            this.dialog.open(DialogErrorComponent, {
                data: {
                    message: LanguageService.getLine('comments_cannot_be_retrieved')
                }
            });
        });

    }

    private initLatestComments() {
        this.commentsLoading = true;

        const promise = new Promise((resolve, reject) => {
            this.comments.getRecentComments((comments) => {
                resolve(comments);
            });
        });

        const usersPromise = this.usersController.service.getRelatedUsers();

        Promise.all([promise, usersPromise]).then((responses) => {
            let comments = responses[0] as any;
            let users = responses[1].users as IRelatedUser[];
            this.postComments = comments.map(comment => {
                // swap userIDs to names in mentions
                comment.text = comment.text.replace(/##(\d+)##/g, (match, userID) => {
                    const user = users.find(user => user.userID == userID);
                    if (user) {
                        return "@" + user.name;
                    }
                    return match;
                });

                // truncate to max 70 chars
                comment.text = truncate(comment.text, {length: 70});

                return comment;
            });
            this.commentsLoading = false;
        }).catch(reason => {
            this.dialog.open(DialogErrorComponent, {
                data: {
                    message: LanguageService.getLine('comments.cannot.be.retrieved')
                }
            });
            this.commentsLoading = false;
        });
    }
}
