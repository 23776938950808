import { Injectable } from "@angular/core";
import { BackendService } from "~/src/app/core/backend.service";
import { Helpers } from "~/src/app/services/helpers";
import { AuthWindow } from "./auth-window.class";
import { DEFAULT_TIMEZONE } from "~/src/app/configs/configs";

@Injectable({ providedIn: "root" })
export class GmbApiLocationService {
    private authWindow: AuthWindow;

    constructor(private backendService: BackendService) {
        this.authWindow = new AuthWindow();
    }

    protected authorizationUrl() {
        return this.backendService.get("/social/gmb-authorization/authorization-url");
    }

    public authorization() {
        return new Promise((resolve, reject) => {
            this.authorizationUrl()
                .then((response: { authorizationUrl: string }) => {
                    this.authWindow
                        .watch(response.authorizationUrl)
                        .then((params: { code: string }) => {
                            this.backendService
                                .post("/social/gmb-authorization/authorization", params)
                                .then(resolve)
                                .catch(reject);
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    public get() {
        return this.backendService.get("/social/gmb/accounts-locations");
    }

    public save(list: any[], organizationID: string) {
        const items = [];

        for (const item of list) {
            items.push({
                socialType: "gmb",
                pageID: item.id,
                name: item.name,
                timezone: DEFAULT_TIMEZONE,
                pageType: "page",
                organizationID: organizationID,
            });
        }

        return this.backendService.post(
            "/social-sites",
            Helpers.objectToFormData({ sites: JSON.stringify(items) })
        );
    }

    public refresh(siteID) {
        return this.backendService.post("/social/gmb/refresh", { siteID });
    }
}
