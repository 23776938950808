import {
    MetricConfigs,
    MetricExtensionType,
} from "~/src/app/modules/analytics/widget/metric-configs/metric.config";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import Utils from "~/src/app/core/utils";
import {
    ChartConfiguration,
    ChartDataSets,
} from "~/node_modules/@types/chart.js";
import { BASE_DATE_FORMAT } from "~/src/app/configs/configs";
import { UsersResourceService } from "~/src/app/modules/users/users-resource.service";
import { OrganizationController } from "~/src/app/components/organization-select/organization.service";
import { NewsFeedTargetingLinkedinService } from "~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.service";
import {
    LinkedInTargetingTypes,
    RefreshTargetingsOptions,
} from "~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.interfaces";
import { MetricConfigGetter } from "~/src/app/modules/analytics/widget/metric-configs/metric-config.getter";

export class MetricModel {
    // Extension datas getter by type map
    readonly extensionTypesMap: {
        [key in MetricExtensionType]: (
            extensionKeys: any[],
            response: any
        ) => Promise<any>;
    } = {
        user: (extensionKeys: any[], response: any) => {
            return this.getUsers(extensionKeys, response);
        },
        organization: (extensionKeys: any[], response: any) => {
            return this.getOrganizations(extensionKeys, response);
        },
        seniority: (extensionKeys: any[], response: any) => {
            return this.getLinkedInTargeting(
                extensionKeys,
                response,
                "seniorities"
            );
        },
        function: (extensionKeys: any[], response: any) => {
            return this.getLinkedInTargeting(
                extensionKeys,
                response,
                "functions"
            );
        },
        region: (extensionKeys: any[], response: any) => {
            return this.getLinkedInTargeting(
                extensionKeys,
                response,
                "regions"
            );
        },
        industry: (extensionKeys: any[], response: any) => {
            return this.getLinkedInTargeting(
                extensionKeys,
                response,
                "industries"
            );
        },
        country: (extensionKeys: any[], response: any) => {
            return this.getLinkedInTargeting(
                extensionKeys,
                response,
                "countries"
            );
        },
    };

    modelID: string;
    metricConfig: MetricConfigs;
    partMetricConfigs: MetricConfigs[];
    responses: { [key: string]: any } = {};
    extensionDatas: any[];
    socialSite: SocialSiteInterface = null;
    organizations: number[] = null; // for org graphs
    dateRange: { from: string; to: string };
    errorMessage: string;
    infoMessage: string;
    warnMessage: string;
    posts: any[];
    summaryData: any[];
    tableData: {[key: string]: any[]};
    htmlContents: string[];
    multiSocialSites = true;

    constructor(
        metricID: string,
        entities: SocialSiteInterface | number[], // socialSite OR orgID
        dateRange: { from: string; to: string },
        private usersResource: UsersResourceService,
        private organizationController: OrganizationController,
        private linkedinTargetingService: NewsFeedTargetingLinkedinService
    ) {
        this.metricConfig = MetricConfigGetter.get(metricID);

        // if entity is a number, it's an organization ID
        if (typeof entities !== "number") {
            this.socialSite = entities as SocialSiteInterface;
        } else {
            this.organizations = entities as number[];
        }

        this.dateRange = dateRange;

        this.initialize();

        return this;
    }

    getChartConfig(): ChartConfiguration {
        if (!!this.errorMessage) {
            return null;
        }

        const configuration = this.metricConfig.chartConfig;

        configuration.data = {
            datasets: [],
            labels: [],
        };

        for (const responseKey in this.responses) {
            const response = this.responses[responseKey];

            if (this.metricConfig.contentType === "html") {
                this.htmlContents = this.htmlContents || [];

                this.htmlContents.push(
                    this.metricConfig.htmlTemplate(
                        this.metricConfig.dataName,
                        response
                    )
                );

                continue;
            }

            // Has 'analytics' key in response
            if (Utils.lodash.has(response, "analytics")) {
                let analytics = response.analytics;

                // Set dates on X axe
                if (
                    Utils.lodash.has(analytics, "from") &&
                    Utils.lodash.has(analytics, "to")
                ) {
                    if (
                        !!Utils.lodash.get(
                            configuration,
                            "options.scales.xAxes[0].time"
                        )
                    ) {
                        configuration.options.scales.xAxes[0].time["min"] =
                            Utils.moment(this.dateRange.from).format(
                                BASE_DATE_FORMAT
                            );
                        configuration.options.scales.xAxes[0].time["max"] =
                            Utils.moment(this.dateRange.to).format(
                                BASE_DATE_FORMAT
                            );
                    }
                }

                // Set chart title
                configuration.options = {
                    ...configuration.options,
                    title: {
                        display: true,
                        text: [this.metricConfig.dataName as string],
                    },
                };

                if (!!this.metricConfig.hydrateResponse) {
                    analytics =
                        this.metricConfig.hydrateResponse(response).analytics;
                }

                // Set datasets with custom datasets
                if (!!this.metricConfig.getDatasets) {
                    configuration.data = this.metricConfig.getDatasets(
                        analytics,
                        this.extensionDatas
                    );
                    continue;
                }

                // Has 'values' key in response
                if (Utils.lodash.has(analytics, "values")) {
                    if (
                        Utils.lodash.get(
                            analytics,
                            "values[0]",
                            null
                        ) instanceof Object
                    ) {
                        if (this.metricConfig.contentType === "list") {
                            this.posts = this.postsHydrator(analytics.values);
                            return null;
                        } else if (
                            this.metricConfig.contentType === "summary"
                        ) {
                            this.summaryData = this.summaryHydrator(
                                analytics.values
                            );

                            return null;
                        } else if (this.metricConfig.contentType === "table") {
                            this.tableData = {'headers': analytics.headers, 'values': analytics.values, 'meta': analytics.meta};
                            return null;
                        }

                        const propertyNames = Object.keys(analytics.values[0]);

                        for (const propertyName of propertyNames) {
                            const values = (analytics.values as any[]).map(
                                (item) => item[propertyName]
                            );
                            const sum = Utils.get(
                                analytics,
                                `sum[${propertyName}]`,
                                null
                            );
                            const percent = Utils.get(
                                analytics,
                                `percent[${propertyName}]`,
                                null
                            );

                            // TODO set property name from language service
                            let label = `${Utils.lodash.startCase(
                                propertyName
                            )}`;

                            if (sum !== null) {
                                label =
                                    label +
                                    " - " +
                                    this.getSumPercentFormat(sum, percent);
                            };

                            configuration.data.datasets.push({
                                data: values,
                                label: label,
                            });
                        }

                        // This caused a bug that didn't show all the data. Why is this here?
                        // It only got called at: LinkedIn - page views overview and page views by platform
                        //configuration.data.labels = propertyNames;
                    } else {
                        if (Utils.get(analytics, "avg", null) !== null) {
                            configuration.data.datasets.push({
                                data: analytics.values,
                                label:
                                    this.getAvgPercentFormat(
                                        Utils.get(analytics, "avg", null),
                                        Utils.get(analytics, "percent", null)
                                    ) || "",
                            } as ChartDataSets);
                        } else {
                            configuration.data.datasets.push({
                                data: analytics.values,
                                label:
                                    this.getSumPercentFormat(
                                        Utils.get(analytics, "sum", null),
                                        Utils.get(analytics, "percent", null)
                                    ) || "",
                            } as ChartDataSets);
                        }
                    }
                } else {
                    // Example: posts, medias, templates
                    const propertyNames = Object.keys(analytics);

                    // Separate datas by extension key
                    const separatedDatas: { [key: string]: any[] } = {};
                    const allExtensionKey = [];

                    // Get all extension key
                    for (const propertyName of propertyNames) {
                        const valueMap = analytics[propertyName];
                        const extensionKeys = Object.keys(valueMap);
                        const values = [];

                        // Set default value if empty
                        separatedDatas[propertyName] =
                            separatedDatas[propertyName] || [];

                        for (const extensionKey of extensionKeys) {
                            if (allExtensionKey.indexOf(extensionKey) === -1) {
                                allExtensionKey.push(extensionKey);
                            }

                            values.push(Number(valueMap[extensionKey + ""]));
                        }

                        configuration.data.datasets.push({
                            data: values,
                            // TODO set value from language service
                            label: Utils.lodash.startCase(propertyName),
                        });
                    }

                    configuration.data.labels = allExtensionKey;
                }
            }
        }

        if (!!this.htmlContents) {
            return null;
        }

        return configuration;
    }

    /**
     * Get metric models
     * @returns {MetricModel[]}
     */
    getMetricModels(): MetricModel[] {
        let metricModels: MetricModel[] = [];

        if (this.metricConfig.apiURL) {
            return [this];
        }

        if (!!this.partMetricConfigs.length) {
            metricModels = this.partMetricConfigs.map((config) => {
                return new MetricModel(
                    config.metricID,
                    this.socialSite || this.organizations,
                    this.dateRange,
                    this.usersResource,
                    this.organizationController,
                    this.linkedinTargetingService
                );
            });
        }

        return metricModels;
    }

    /**
     * Set response datas
     * @param {string} apiURL
     * @param response
     */
    setDatas(apiURL: string, response: any): Promise<any> {
        if (!!this.metricConfig.hydrateResponse) {
            response = this.metricConfig.hydrateResponse(response);
        }

        if (
            Utils.lodash.has(response, "analytics") &&
            !Utils.lodash.has(response, "analytics.values")
        ) {
            return this.getExtensionDatas(response)
                .then((res) => {
                    this.responses[apiURL] = response;

                    return Promise.resolve(res);
                })
                .catch((error) => {
                    this.setErrorMessage([
                        Utils.lodash.get(
                            error,
                            "message",
                            "Error in extension datas!"
                        ),
                    ]);

                    return Promise.reject(error);
                });
        }

        this.responses[apiURL] = response;

        return Promise.resolve(response);
    }

    /**
     * Set error messages
     * @param {string[]} message
     */
    setErrorMessage(message: string[]) {
        this.errorMessage = message[0];
    }

    /**
     * Set info message
     * @param {string} message
     */
    setInfoMessage(message: string) {
        this.infoMessage = message;
    }

    /**
     * Set warn message
     * @param {string} message
     */
    setWarnMessage(message: string) {
        this.warnMessage = message;
    }

    /**
     * Class initialize
     */
    private initialize() {
        this.modelID = Utils.uuid.UUID();

        if (Utils.lodash.has(this.metricConfig, "multiSocialSite")) {
            this.multiSocialSites = this.metricConfig.multiSocialSite;
        }

        this.getPartMetricConfigs();
    }

    /**
     * Get part metric configs
     * @returns {MetricConfigs[]}
     */
    private getPartMetricConfigs(): MetricConfigs[] {
        let configs: MetricConfigs[] = [];

        if (this.metricConfig.partMetricIDs) {
            configs = this.metricConfig.partMetricIDs.map((metricID) =>
                MetricConfigGetter.get(metricID)
            );
        }

        this.partMetricConfigs = configs;

        return configs;
    }

    /**
     * Get sum and percent string format
     * @param {number} sum
     * @param {number} percent
     * @returns {string}
     */
    private getSumPercentFormat(sum: number | string, percent: number): string {
        if (sum !== null && percent !== null) {
            sum = (sum as number).toFixed(2);

            return `Sum: ${sum} | Percent: ${percent}%`;
        }

        return "";
    }

    /**
     * Get avg and percent string format
     * @param {number} avg
     * @param {number} percent
     * @returns {string}
     */
    private getAvgPercentFormat(avg: number | string, percent: number): string {
        if (avg !== null && percent !== null) {
            avg = (avg as number).toFixed(2);

            return `Avg: ${avg}% | Percent: ${percent}%`;
        }

        return "";
    }

    /**
     * Get extension datas
     * @param response
     * @returns {Promise<any>}
     */
    private getExtensionDatas(response: any): Promise<any> {
        if (!Utils.lodash.has(this.metricConfig, "extensionType")) {
            return Promise.resolve(response);
        }

        const extensionType: MetricExtensionType =
            this.metricConfig.extensionType;
        const allExtensionKey = [];

        for (const propertyName in response.analytics) {
            const valueMap = response.analytics[propertyName];

            if (valueMap instanceof Object) {
                const extensionKeys = Object.keys(valueMap);

                for (const extensionKey of extensionKeys) {
                    if (allExtensionKey.indexOf(extensionKey) === -1) {
                        allExtensionKey.push(extensionKey);
                    }
                }
            }
        }

        return this.extensionTypesMap[extensionType](allExtensionKey, response);
    }

    /**
     * Post hydrator
     * @param {any[]} posts
     * @returns {any[]}
     */
    private postsHydrator(posts: any[]) {
        return posts.map((post) => {
            if (!!this.socialSite) {
                post.grouped = true;
                // this.widget.name = this.originName + ' (Groupped)';
                post.socialSiteNameFull = this.socialSite.name;
                post.socialSiteName = Utils.lodash.truncate(
                    this.socialSite.name,
                    {
                        length: 15,
                    }
                );
            } else {
                // this.widget.name = this.originName;
            }

            post.message = Utils.lodash.truncate(post.message, { length: 70 });

            return post;
        });
    }

    /**
     * Summary hydrator
     * @param {any[]} data
     * @returns {any[]}
     */
    private summaryHydrator(data: any[]) {
        return data.map((data) => {
            if (!!this.socialSite) {
                data.socialSiteNameFull = this.socialSite.name;
            }

            return data;
        });
    }

    /**
     * Get users by userIDs
     * @param {any[]} userIDs
     * @param response
     * @returns {Promise<any>}
     */
    private getUsers(userIDs: any[], response: any): Promise<any> {
        return this.usersResource
            .getUsers({
                userIDs: userIDs,
            })
            .then((userResponse) => {
                this.extensionDatas = userResponse.users;

                for (const propertyName in response.analytics) {
                    const valueMap = response.analytics[propertyName];

                    for (const userID in valueMap) {
                        const values = valueMap[userID];
                        const user = this.extensionDatas.find(
                            (item) => String(item.userID) === String(userID)
                        );

                        delete response.analytics[propertyName][userID];

                        if (user) {
                            response.analytics[propertyName][user.fullName] =
                                values;
                        }
                    }
                }

                return Promise.resolve(response);
            });
    }

    /**
     * Get organizations by organizationIDs
     * @param {any[]} organizationsIDs
     * @returns {Promise<any>}
     */
    private getOrganizations(
        organizationsIDs: any[],
        response: any
    ): Promise<any> {
        return this.organizationController
            .getItemsByIDs("organizationIDs", organizationsIDs)
            .then((organizationResponse: any) => {
                this.extensionDatas = organizationResponse.organizations;

                for (const propertyName in response.analytics) {
                    const valueMap = response.analytics[propertyName];

                    for (let key in valueMap) {
                        // if organizationID starts with _, it means it is ordered. We can remove it here.
                        let organizationID = key;
                        if (organizationID.startsWith("_")) {
                            organizationID = organizationID.substring(1);
                        }

                        const values = valueMap[key];
                        const organization = this.extensionDatas.find(
                            (item) =>
                                String(item.organizationID) ===
                                String(organizationID)
                        );

                        delete response.analytics[propertyName][key];

                        response.analytics[propertyName][organization.name] =
                            values;
                    }
                }

                return Promise.resolve(response);
            });
    }

    private getLinkedInTargeting(
        extensionKeys: any[],
        response: any,
        targetType: LinkedInTargetingTypes
    ): Promise<any> {
        const filters: RefreshTargetingsOptions = {
            socialType: "linkedIn",
            socialSiteID: this.socialSite.siteID,
        };

        switch (targetType) {
            case "industries":
                return this.linkedinTargetingService
                    .refreshIndustries(filters)
                    .then((result) => {
                        this.extensionDatas = result;

                        return Promise.resolve(result);
                    });

            case "functions":
                return this.linkedinTargetingService
                    .refreshJobFunctions(filters)
                    .then((result) => {
                        this.extensionDatas = result;

                        return Promise.resolve(result);
                    });

            case "seniorities":
                return this.linkedinTargetingService
                    .refreshSeniorities(filters)
                    .then((result) => {
                        this.extensionDatas = result;

                        return Promise.resolve(result);
                    });

            case "countries":
                return this.linkedinTargetingService
                    .refreshCountries(filters)
                    .then((result) => {
                        this.extensionDatas = result;

                        return Promise.resolve(result);
                    });

            case "regions":
                return this.linkedinTargetingService
                    .refreshRegions(filters)
                    .then((result) => {
                        this.extensionDatas = result;

                        return Promise.resolve(result);
                    });
        }

        return Promise.resolve();
    }
}
