import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Debounce, Helpers } from "~/src/app/services/helpers";
import { IRelatedUser } from "../../users/users/users.interface";
import { Observable } from "rxjs";
import { TagsComponent } from "../tags.component";
import { FolderTagsService } from "../folder-tags.service";

export interface TemplateFolderFiltersInterface {
  searchValue: string;
  orderBy: string;
  createdBy: number[];
  organizations: number[];
  tags: string;
}

@Component({
  selector: "smd-template-folder-filters",
  templateUrl: "./template-folder-filters.component.html",
  styleUrls: ["./template-folder-filters.component.scss"],
})
export class TemplateFolderFiltersComponent implements OnInit, OnDestroy {
  subs = [];

  form: FormGroup = new FormGroup({
    searchValueFormControl: new FormControl({ value: "", disabled: false }),
    orderByFormControl: new FormControl({ value: "createdAt-desc", disabled: false }),
    createdByFormControl: new FormControl({ value: null, disabled: false }),
    organizationsFormControl: new FormControl({ value: null, disabled: false }),
    tagsFormControl: new FormControl({ value: null, disabled: false }),
  });

  @Input() isAdminMode: boolean = false;
  @Input() filterClickEvent: Observable<any>;
  @Input() relatedUsers: Array<IRelatedUser> = [];

  @Output("filterChange") filterChange: EventEmitter<TemplateFolderFiltersInterface> = new EventEmitter<TemplateFolderFiltersInterface>();

  filteredUsers: Array<IRelatedUser> = [];
  tags: Array<string> = [];
  _tagsComponent: TagsComponent;

  constructor(
    private tagsService: FolderTagsService,
  ) {
  }

  ngOnInit() { 
    this.tagsInit();

    const sub1 = this.filterClickEvent.subscribe(() => {
      this.updateFilteredUsers();
    });

    const sub2 = this.form.get("organizationsFormControl").valueChanges.subscribe(() => {
      this.updateFilteredUsers();
    });

    this.subs.push(sub1, sub2);
  }

  ngOnDestroy() { 
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  @Debounce(500)
  emitFilterChange() {
    this.filterChange.emit(this.filterValues);
  }

  @Debounce(1000)
  keywordChange() {
    this.filterChange.emit(this.filterValues);
  }

  get filterValues(): TemplateFolderFiltersInterface {
    // innentől kezdve nincs null és üres string filter érték.
    const typeConversion = function (input, toNumber?: boolean) {
      if (input === null) {
        return null;
      }

      if (typeof input === "string") {
        if (toNumber) {
          return Number(input.trim());
        } else {
          return input.trim();
        }
      }

      /* if (typeof input === "object" && input instanceof Object) {
        if (toNumber) {
          return Object.keys(input).length ? JSON.stringify(input.map((item) => Number(item))) : null;
        }
        return Object.keys(input).length ? JSON.stringify(input) : null;
      } */

      if (typeof input === "object" && input instanceof Array) {
        if (toNumber) {
          return input.length ? JSON.stringify(input.map((item) => Number(item))) : null;
        }
        return input.length ? JSON.stringify(input) : null;
      }

      return input;
    };

    const result = {
      searchValue: typeConversion(
        this.form.get("searchValueFormControl").value
      ),
      orderBy: typeConversion(this.form.get("orderByFormControl").value),
      tags: typeConversion(this.form.get("tagsFormControl").value),
      createdBy: typeConversion(
        this.form.get("createdByFormControl").value, true
      ),
      organizations: typeConversion(
        this.form.get("organizationsFormControl").value
      ),
    };

    return result;
  }

  updateFilteredUsers() {
    const organizationIDs = this.form.get("organizationsFormControl").value;
    if (organizationIDs && organizationIDs.length) {
      this.filteredUsers = this.relatedUsers.filter((user) => {
        let userOrgIDs = (user.organizationIDs.split(',') || []).map(Number);
        return organizationIDs.some((orgID) => userOrgIDs.includes(orgID));
      });
    } else {
      this.filteredUsers = this.relatedUsers.filter((user) => {
        return true;
      });
    }

    // order by name
    this.filteredUsers = Helpers.orderBy(this.filteredUsers, "name");
  }

  tagsInit() {
    if (!this._tagsComponent) {
      this._tagsComponent = new TagsComponent(this.tagsService);
    }

    if (this.isAdminMode) {
      this._tagsComponent.filters["type"] = "generic";
    } else {
      delete this._tagsComponent.filters["type"];
    }

    this._tagsComponent.getItems(({ tags }: { tags: any[] }) => {
        this.tags = Helpers.orderBy(tags, "name");
    });
  }
}