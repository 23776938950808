import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import Utils from '~/src/app/core/utils';
import {ChartData} from 'chart.js';
import {
    JobFunction,
    Seniority
} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.interfaces';
import {StaffCountRanges} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.constants';

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_OVERVIEW: WidgetMetricOptionType = {
    id: 'linkedin-followers-overview',
    name: 'Followers overview',
    resource: 'analytics.analytics.pageOverallLikes',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_STAFF_COUNT_RANGE: WidgetMetricOptionType = {
    id: 'linkedin-followers-by-staff-count-range',
    name: 'Followers by Staff Count Range',
    resource: 'analytics.analytics.pageOverallLikes',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_FUNCTION: WidgetMetricOptionType = {
    id: 'linkedin-followers-by-function',
    name: 'Followers by Function',
    resource: 'analytics.analytics.pageOverallLikes',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_ASSOCIATION: WidgetMetricOptionType = {
    id: 'linkedin-followers-by-association',
    name: 'Followers by Association',
    resource: 'analytics.analytics.pageOverallLikes',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_SENIORITY: WidgetMetricOptionType = {
    id: 'linkedin-followers-by-seniority',
    name: 'Followers by Seniority',
    resource: 'analytics.analytics.pageOverallLikes',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_GROWTH: WidgetMetricOptionType = {
    id: 'linkedin-followers-growth',
    name: 'Followers growth',
    resource: 'analytics.analytics.pageLikeOverview',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_GROWTH_ORGANIC: WidgetMetricOptionType = {
    id: 'linkedin-organic-followers-growth',
    name: 'Organic followers growth',
    resource: 'analytics.analytics.pageLikeOverview',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_GROWTH_PAID: WidgetMetricOptionType = {
    id: 'linkedin-paid-followers-growth',
    name: 'Paid followers growth',
    resource: 'analytics.analytics.pageLikeOverview',
    socialChannel: 'linkedIn'
};

// METRIC CONFIGS

export const MetricLinkedInFollowersOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_OVERVIEW.id,
    apiURL: '/page-like-overview',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers overview',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInFollowersByStaffCountRangeConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_STAFF_COUNT_RANGE.id,
    apiURL: '/page-overall-likes',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers by Staff Count Range',
    getDatasets: (analytics: any) => {
        const staffCountRanges = Utils.lodash.cloneDeep(StaffCountRanges);
        const chartData: ChartData = {
            datasets: [],
            labels: Object.keys(analytics['staffCountRange']).map(urn => staffCountRanges.find(item => item.value === urn).name)
        };
        let sum = 0;

        for (const urn in analytics['staffCountRange']) {
            const value = analytics['staffCountRange'][urn];

            chartData.datasets.push({
                data: value
            });

            sum += value;
        }

        chartData.datasets = chartData.datasets.map(dataset => {
            dataset.label = `Sum: ${sum}`; // TODO: Language file?
            return dataset;
        });

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: {
                            min: null,
                            max: null
                        },
                        stacked: false,
                    }],
                    yAxes: [{
                        stacked: false,
                        type: 'linear',
                    }],
                },
            }
        }
    ),
    defaultFilters: {
        type: 'staffCountRange'
    }
};

export const MetricLinkedInFollowersByFunctionConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_FUNCTION.id,
    apiURL: '/page-overall-likes',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers by Function (Top 25)',
    extensionType: 'function',
    getDatasets: (analytics: any, extensionData: JobFunction[]) => {
        const chartData: ChartData = {
            datasets: [],
            labels: Object.keys(analytics['function']).map(urn => extensionData.find(item => item.id === urn).name)
        };
        let sum = 0;

        for (const urn in analytics['function']) {
            const value = analytics['function'][urn];

            chartData.datasets.push({
                data: value
            });

            sum += value;
        }

        chartData.datasets = chartData.datasets.map(dataset => {
            dataset.label = `Sum: ${sum}`;
            return dataset;
        });

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: {
                            min: null,
                            max: null
                        },
                        stacked: false,
                    }],
                    yAxes: [{
                        stacked: false,
                        type: 'linear',
                    }],
                },
            }
        }
    ),
    defaultFilters: {
        type: 'function'
    }
};

export const MetricLinkedInFollowersByAssociationConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_ASSOCIATION.id,
    apiURL: '/page-overall-likes',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers by Association',
    getDatasets: (analytics) => {
        const chartData: ChartData = {
            datasets: [],
        };
        let sum = 0;

        for (const propertyName in analytics.associationType) {
            const value = analytics.associationType[propertyName];

            // TODO set propertyName from language service
            chartData.datasets.push({
                data: value,
            });

            sum += value;
        }

        chartData.labels = Object.keys(analytics.associationType);
        chartData.datasets = chartData.datasets.map(dataset => {
            // TODO set value from language service
            dataset.label = `Sum: ${sum}`;
            return dataset;
        });

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: {
                            min: null,
                            max: null
                        },
                        stacked: false,
                    }],
                    yAxes: [{
                        stacked: false,
                        type: 'linear',
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                },
            }
        }
    ),
    defaultFilters: {
        type: 'associationType'
    }
};

export const MetricLinkedInFollowersBySeniorityConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_BY_SENIORITY.id,
    apiURL: '/page-overall-likes',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers by Seniority',
    extensionType: 'seniority',
    getDatasets: (analytics: any, extensionData: Seniority[]) => {
        const chartData: ChartData = {
            datasets: [],
            labels: Object.keys(analytics['seniority']).map(urn => extensionData.find(item => item.id === urn).name)
        };
        let sum = 0;

        for (const urn in analytics['seniority']) {
            const value = analytics['seniority'][urn];

            chartData.datasets.push({
                data: value
            });

            sum += value;
        }

        chartData.datasets = chartData.datasets.map(dataset => {
            dataset.label = `Sum: ${sum}`;
            return dataset;
        });

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: {
                            min: null,
                            max: null
                        },
                        stacked: false,
                    }],
                    yAxes: [{
                        stacked: false,
                        type: 'linear',
                    }],
                },
            }
        }
    ),
    defaultFilters: {
        type: 'seniority'
    }
};

export const MetricLinkedInFollowersGrowthConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_GROWTH.id,
    apiURL: '/page-like-overview',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers Growth',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInFollowersGrowthOrganicConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_GROWTH_ORGANIC.id,
    apiURL: '/page-like-overview',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers Growth (organic)',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'organic'
    }
};

export const MetricLinkedInFollowersGrowthPaidConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_FOLLOWERS_GROWTH_PAID.id,
    apiURL: '/page-like-overview',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Followers Growth (paid)',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'paid'
    }
};
