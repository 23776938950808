import {ModelAbstract} from '../../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Partner, PartnerSubscription} from '~/src/app/shared/types/partners.model';
import {LoggedUser} from '~/src/app/services/logged-user';
import Utils from '~/src/app/core/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import {
    PARTNER_STATUS_ACTIVE,
    PARTNER_STATUS_INACTIVE,
    PARTNER_UNLIMITED_KEY
} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.config';
import {
    PARTNER_BOOLEAN_PERMISSIONS,
    PARTNER_MAX_LIMIT_GENERIC_TEMPLATE,
    PARTNER_MAX_LIMIT_POST_FROM_RSS,
    PARTNER_MAX_LIMIT_RSS_URL,
    PARTNER_MAX_LIMIT_SOCIAL_SITES,
    PARTNER_MAX_LIMIT_USERS,
    PARTNER_PERIOD_POST_TEMPLATE,
    PARTNER_PERIOD_RSS_FEED,
    PARTNER_MAX_LIMIT_ORGANIZATION
} from '~/src/app/modules/administration/partners/partner.constant';
import { environment } from '~/src/environments/environment';
import {
    PartnerAnalytics,
    PartnerAnalyticsActivity,
    PartnerAnalyticsCore,
    PartnerAnalyticsData,
    PartnerAnalyticsExportResponse,
    PartnerAnalyticsResolution,
    PartnerAnalyticsResponse,
    PartnerAnalyticsStatistic
} from '~/src/app/modules/administration/partners/partner-analytics/partner-analytics.model';
import {Range} from '~/node_modules/ngx-mat-daterange-picker';
import {BASE_DATE_FORMAT} from '~/src/app/configs/configs';
import {SetTemplatesToPartnersInterfaces} from '~/src/app/modules/administration/set-templates-to-partners/set-templates-to-partners';
import {BackendService} from '~/src/app/core/backend.service';
import {IPartnerInfo, IPartnerInfoResponse} from '~/src/app/modules/administration/partners/partner.interface';
import {LocalStorage} from '~/src/app/core/local-storage';
import {LINK_SHORTEN_TYPE_BITLY} from '~/src/app/modules/social-media-post/link-shortening.constant';

interface MyPartner {
    partnerID: number;
    partnerLogo: string;
    partnerName: string;
    partnerDeleteRequested: boolean;
    subscriptionName: string;
    bitlyToken?: string;
    partnerLimitUsage: any;
    linkShorten: string;
}

export const CONNECTED_PLATFORMS_KEY = 'numberOfConnectedPlatforms';

/**
 * Partner config types
 */
type PartnerConfigType = 'plan' | 'addon' | 'manual' | 'total';

@Injectable({providedIn: 'root'})
export class PartnersService extends ModelAbstract {
    private partnerCollection: BehaviorSubject<Partner[]> = new BehaviorSubject<Partner[]>([]);
    getPending = false;

    private user: LoggedUser;

    constructor(
        public http: HttpClient,
        public dialog: MatDialog,
        private openModal: OpenModalService,
        private backendService: BackendService
    ) {
        super(http, dialog);
        this.apiLink = '/api/partner';

        this.user = LoggedUser.getUser();
    }

    static getBooleanConfigNames() {
        return PARTNER_BOOLEAN_PERMISSIONS;
    }

    static getAllConfigName(): string[] {
        return [
            PARTNER_MAX_LIMIT_USERS,
            PARTNER_MAX_LIMIT_GENERIC_TEMPLATE,
            PARTNER_MAX_LIMIT_RSS_URL,
            // PARTNER_MAX_LIMIT_GENERIC_MEDIA,
            PARTNER_MAX_LIMIT_POST_FROM_RSS,
            PARTNER_MAX_LIMIT_ORGANIZATION,
            PARTNER_MAX_LIMIT_SOCIAL_SITES,
            PARTNER_PERIOD_POST_TEMPLATE,
            // PARTNER_PERIOD_MEDIA,
            PARTNER_PERIOD_RSS_FEED,

            ...this.getBooleanConfigNames()
        ];
    }

    get partners(): Observable<Partner[]> {
        return this.partnerCollection.asObservable();
    }

    /**
     * Get partners
     * @param filters
     * @return {Promise<Array<Partner>>}
     */
    getPartners(filters?, orderable = true): Promise<Array<Partner>> {
        this.showLoader(this.openModal);

        this.getPending = true;
        return new Promise((resolve, reject) => {
            this.getAll(filters).then(httpResponse => {
                this.partnerCollection.next(this.itemsHydrator(httpResponse.partners, orderable));
                this.getPending = false;
                resolve(httpResponse);

                return httpResponse;
            }).catch(resp => {
                this.partnerCollection.next([]);
                this.getPending = false;
                reject(resp);
                return resp;
            });
        });
    }

    /**
     * Create partner
     * @param {Partner} partner
     * @return {Promise<any>}
     */
    createPartner(partner: Partner): Promise<any> {
        this.showLoader(this.openModal);
        const data = this.itemTransformer(partner);

        return this.create(Utils.obj2fd(data));
    }

    /**
     * Edit partner
     * @param {Partner} partner
     * @return {Promise<any>}
     */
    editPartner(partner: Partner): Promise<any> {
        this.showLoader(this.openModal);

        const data = this.itemTransformer(partner);

        if (!('partnerName' in data) || !data['partnerName'] || data['partnerName'] === '') {
            data['partnerName'] = partner.name;
        }

        delete data.name;
        delete data.partnerID;

        return this.editWithPost(partner.partnerID, Utils.obj2fd(data));
    }

    /**
     * Resend activation e-mail to first user of partner
     * @param {number} partnerID
     * @return {Promise<null>}
     */
    resendActivationEmail(partnerID: number) {
        return this.backendService.post('/partner/resend-activation-email', {partnerID});
    }

    /**
     * Activate partners
     * @param {number[]} partnerIDs
     * @return {Promise<any>}
     */
    activatePartners(partnerIDs: number[]): Promise<any> {
        const data = {
            partnerIDs: partnerIDs,
            status: PARTNER_STATUS_ACTIVE
        };

        this.showLoader(this.openModal);
        return this.http.post(this.apiLink + '/status', Utils.obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Delete partners
     * @param {string} partnerIDs
     * @return {Promise<any>}
     */
    deletePartners(partnerIDs: number[]): Promise<any> {
        const data = {partnerIDs: partnerIDs};

        this.showLoader(this.openModal);
        return this.http.post(this.apiLink + '/delete', Utils.obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Get partner information
     * @return {Promise<any>}
     */
    getPartnerInfo(withRefresh = true): Promise<IPartnerInfo> | IPartnerInfo {
        const storageKey = Utils.md5('administrationPartnerInformation');

        if (withRefresh) {
            return this.backendService.get('/partner/read-info')
                .then((response: IPartnerInfoResponse) => {
                    response.partnerInfo.addonData = (response.partnerInfo?.addonData || []).map(addon => {
                        addon.uid = Utils.uuid.UUID();
                        return addon;
                    });
                    LocalStorage.set(storageKey, response.partnerInfo);
                    return Promise.resolve(response.partnerInfo);
                });
        } else {
            return LocalStorage.get(storageKey);
        }
    }

    /**
     * Set partners to templates
     * @param templateIDs
     * @param partnerIDs
     * @param systemType
     * @param itemsToPartners
     */
    setPartnersToTemplates(templateIDs: number[], partnerIDs: number[], systemType: string = '', itemsToPartners: boolean = false) {
        const data = {
            templateID: templateIDs,
            partnerID: partnerIDs
        };

        if (itemsToPartners) {
            data['itemsToPartners'] = 'yes';
        }

        if (systemType) {
            data['systemType'] = systemType;
        }

        return this.http.post(
            environment.apiUrl + '/api/post/template/template-visibility',
            Utils.obj2fd(data),
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    /**
     * Set templates to partner(s)
     *
     * @param {SetTemplatesToPartnersInterfaces.SaveRequestData} data
     * @returns {Promise<null>}
     */
    setTemplatesToPartners(data: SetTemplatesToPartnersInterfaces.SaveRequestData) {
        return this.backendService.post('/post/template/template-visibility', data);
    }

    /**
     * Set partners to medias
     * @param mediaIDs
     * @param partnerIDs
     * @param systemType
     * @param itemsToPartners
     */
    setPartnersToMedias(mediaIDs: number[], partnerIDs: number[], systemType: string = '', itemsToPartners: boolean = false) {
        const data = {
            mediaIDs: mediaIDs,
            partnerIDs: partnerIDs
        };

        if (itemsToPartners) {
            data['itemsToPartners'] = 'yes';
        }

        if (systemType) {
            data['systemType'] = systemType;
        }

        return this.http.post(
            environment.apiUrl + '/api/media/media-visibility',
            Utils.obj2fd(data),
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    /**
     * Suspend partners
     * @param {number[]} partnerIDs
     * @return {Promise<any>}
     */
    suspendPartners(partnerIDs: number[]): Promise<any> {
        const data = {
            status: PARTNER_STATUS_INACTIVE,
            partnerIDs: partnerIDs
        };

        this.showLoader(this.openModal);
        return this.http.post(this.apiLink + '/status', Utils.obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Delete request
     * @param {"delete" | "revert"} type
     * @return {Promise<any>}
     */
    deleteRequest(type: 'delete' | 'revert') {
        const data = {
            type: type,
            partnerID: this.getMyPartnerData().partnerID
        };

        this.showLoader(this.openModal);
        return this.http.post(this.apiLink + '/deleteRequest', Utils.obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Get partner analytics
     * @param partnerIDs
     * @param resolution
     * @param range
     */
    getAnalytics(partnerIDs: number[], resolution: PartnerAnalyticsResolution, range: Range): Promise<PartnerAnalytics[]> {
        const params: any = {
            'partnerID[]': partnerIDs,
            resolution: resolution,
            from: Utils.moment(range.fromDate).format('YYYY-MM-DD'),
            to: Utils.moment(range.toDate).format('YYYY-MM-DD')
        };

        return this.http.get<PartnerAnalyticsResponse>(
            this.apiLink + '/analytics',
            {
                ...Helpers.getBaseHttpHeaders(Token.getToken()),
                params: params,
            }
        ).toPromise().then((response) => {
            // TODO: ideiglenesen
            return response.partnerAnalytics.map(analytics => this.analyticsHydrate(analytics));
        });
    }

    /**
     * Export partner analytics to csv
     * @param partner
     * @param resolution
     * @param range
     */
    exportAnalytics(partner: Partner, resolution: PartnerAnalyticsResolution, range: Range) {
        const params: any = {
            partnerID: partner.partnerID,
            resolution: resolution,
            from: Utils.moment(range.fromDate).format('YYYY-MM-DD'),
            to: Utils.moment(range.toDate).format('YYYY-MM-DD')
        };

        return this.http.get<PartnerAnalyticsExportResponse>(
            this.apiLink + '/analytics-export',
            {
                ...Helpers.getBaseHttpHeaders(Token.getToken()),
                params: params
            }
        ).toPromise().then(response => {

            if (
                !response.file ||
                !response.file.content ||
                !response.file.mime ||
                !response.file.name
            ) {
                throw new Error('Response has no file data!');
            }

            Utils.generateFile(response.file.content, response.file.mime, response.file.name);
            return response;
        });
    }

    /**
     * Add subscription
     * @param data
     * @param partnerID
     */
    addSubscription(data: any, partnerID: number) {
        data = {
            ...data,
            partnerID
        };

        return this.http.post(
            this.apiLink + '/add-subscription',
            Utils.obj2fd(data),
            Helpers.getBaseHttpHeaders(Token.getToken()),
        ).toPromise();
    }

    /**
     * Edit subscription
     * @param data
     * @param {number} subscriptionID
     * @param {number} partnerID
     * @returns {Promise<Object>}
     */
    editSubscription(data: any, subscriptionID: number, partnerID: number) {

        data = {
            ...data,
            partnerID
        };

        return this.http.post(
            this.apiLink + '/edit-subscription/' + subscriptionID,
            Utils.obj2fd(data),
            Helpers.getBaseHttpHeaders(Token.getToken()),
        ).toPromise();
    }

    /**
     * Get my partner data
     * @return {{partnerID: number}}
     */
    getMyPartnerData(): MyPartner {
        const partnerInfo = this.getPartnerInfo(false) as IPartnerInfo;
        const partnerLogo = Utils.lodash.get(this.user, 'partnerLogoImage', null) || null;
        return {
            partnerID: Utils.lodash.get(partnerInfo, 'partnerID', null),
            partnerLogo: !!partnerLogo ? environment.apiUrl + partnerLogo : null,
            partnerName: Utils.lodash.get(this.user, 'partnerName', ''),
            partnerDeleteRequested: Utils.lodash.get(partnerInfo, 'partnerDeleteRequested', null),
            subscriptionName: Utils.lodash.get(partnerInfo, 'subscriptionName', ''),
            bitlyToken: Utils.lodash.get(partnerInfo, 'bitlyToken', '') || '',
            partnerLimitUsage: Utils.lodash.get(partnerInfo, 'partnerLimitUsage', {}) || {},
            linkShorten: Utils.lodash.get(this.user, 'partnerData.linkShorten', LINK_SHORTEN_TYPE_BITLY) || LINK_SHORTEN_TYPE_BITLY,
        };
    }

    /**
     * Get my partner config
     *
     * @description A partner config 3 helyről jöhet: 'plan', 'addon' és 'manual'.
     * A config-nak 3 fajta értéke lehet: 'yes' vagy 'no', number és 'time period' string-ként.
     * Ha a config értéke 'yes' vagy 'no', akkor az alábbiak szerint íródik felül az adott config értéke:
     * Elsődleges: manual, Másodlagos: addon, Harmadlagos: plan.
     * Ha a config értéke number, akkor összeadódik a 3 helyről. (ahol definiálva van)
     * Ha a config értéke time period, ugyanaz a helyzet mint a 'yes'-'no'-nál
     *
     * @param {string | string[]} configNames
     * @param {boolean} withNameKey
     * @param {PartnerConfigType} configType
     * @param configs
     * @return {any | any[]}
     */
    getPartnerConfig(
        configNames: string | string[],
        withNameKey: boolean = false,
        configType: PartnerConfigType = null,
        configs: { [key in PartnerConfigType]?: any } = {}
    ): any | any[] {
        const getAll = (configNames === 'all');

        if (!configNames && !configNames.length) {
            console.warn('The config name(s) param is required!');
            return null;
        }

        if (typeof configNames === 'string') {
            configNames = [configNames];
        }

        // const partnerConfig = Utils.lodash.get(LoggedUser.getUser(), `partnerConfig`, null);
        const partnerInfo = this.getPartnerInfo(false) as IPartnerInfo;
        const partnerConfig = {
            manual: partnerInfo?.manual || {},
            plan: partnerInfo?.planData?.config || {},
            total: partnerInfo?.partnerConfig?.total || {},
            ...configs
        };
        const getConfig = (name: string) => {

            if (configType) {
                return Utils.lodash.get(partnerConfig, `${configType}.${name}`, null);
            }

            return Utils.lodash.get(
                partnerConfig,
                `manual.${name}`,
                Utils.lodash.get(
                    partnerConfig,
                    `addon.${name}`,
                    Utils.lodash.get(partnerConfig, `plan.${name}`, null)
                )
            );
        };

        if (!partnerConfig) {
            console.warn('Missing partner config!');
            return null;
        } else {
            const result = {};
            const langKeyPrefix = 'partner.info.';
            const periodUnitKeyPrefix = 'partner.period.unit.';

            if (getAll) {
                configNames = PartnersService.getAllConfigName();
            }

            for (const configName of configNames) {
                let value: any = parseInt(getConfig(configName), null);

                if (!value && value !== 0) {
                    value = getConfig(configName);
                }

                value = (value === 'yes') ? true : (value === 'no') ? false : value;

                if (Number.isInteger(value)) {
                    const planNumber = parseInt(Utils.lodash.get(partnerConfig, `plan.${configName}`, 0), null);
                    const addonNumber = parseInt(Utils.lodash.get(partnerConfig, `addon.${configName}`, 0), null);
                    const manualPlanNumber = parseInt(Utils.lodash.get(partnerConfig, `manual.${configName}`, 0), null);

                    if (configType) {
                        value = (value === -1)
                            ? PARTNER_UNLIMITED_KEY
                            : parseInt(Utils.lodash.get(partnerConfig, `${configType}.${configName}`, 0));
                    } else {
                        value = (value === -1) ? PARTNER_UNLIMITED_KEY : planNumber + addonNumber + manualPlanNumber;
                    }
                } else if (typeof value !== 'boolean' && !!value) {
                    if (value !== PARTNER_UNLIMITED_KEY) {
                        value = periodUnitKeyPrefix + value;
                    }
                }

                if (typeof value !== 'boolean' && !value) {
                    if (Utils.lodash.includes(PartnersService.getBooleanConfigNames(), configName)) {
                        value = true;
                    }
                }

                if (typeof value === 'boolean' || !!value || value == 0) {
                    result[configName] = !withNameKey ? value : {
                        value: value,
                        configName: configName,
                        nameKey: `${langKeyPrefix}${configName}`
                    };
                }
            }

            const resultValues = Object.values(result);

            if (!resultValues.length) {
                return null;
            }

            return result;
        }
    }

    /**
     * Hydrate partner analytics response
     * @param analytics
     */
    private analyticsHydrate(analytics: PartnerAnalyticsCore): PartnerAnalytics {
        const partnerDataKeys = [
            'accountID',
            'customerID',
            'businessName',
            'lastPublishDate',
            'partnerLastLogin',
            'partnerLocation',
            'subscription'
        ];
        const partnerStatisticKeys = [
            CONNECTED_PLATFORMS_KEY,
            'numberOfConnectedRssFeeds',
            'numberOfOrganizations',
            'numberOfTemplates',
            'numberOfUsers',
            'usedBrowsers',
            'usedDevices',
            'usedSpace',
        ];
        const partnerActivityKeys = [
            'activityData'
        ];
        const partnerData = {};
        const partnerStatistic = {};
        const partnerActivity = {};

        // Group partner analytics data
        for (const key of Object.keys(analytics)) {
            let value = analytics[key];

            if (typeof value !== 'boolean' && !value) {
                value = '--';
            }

            if (partnerDataKeys.indexOf(key) > -1) {
                partnerData[key] = value;
            }

            if (partnerStatisticKeys.indexOf(key) > -1) {
                if (key === CONNECTED_PLATFORMS_KEY && value && value instanceof Object) {
                    if ('total' in value) {
                        value['zzztotal'] = value.total;
                        delete value.total;
                    }
                }

                partnerStatistic[key] = value;
            }

            if (partnerActivityKeys.indexOf(key) > -1) {

                for (const resolution of Object.keys(value)) {
                    if (resolution === 'weekly') {
                        for (const dateKey of Object.keys(value[resolution])) {
                            const values = value[resolution][dateKey];
                            const [year, week] = dateKey.split('-');
                            const newDateKey = Utils.moment().year(parseInt(year, null)).week(parseInt(week, null)).format('YYYY-MM-DD');

                            value[resolution][newDateKey] = values;
                            delete value[resolution][dateKey];
                        }
                    }
                }

                partnerActivity[key] = value;
            }
        }

        return {
            core: analytics as PartnerAnalyticsCore,
            view: {
                partnerData: partnerData as PartnerAnalyticsData,
                partnerStatistic: partnerStatistic as PartnerAnalyticsStatistic,
                partnerActivity: partnerActivity as PartnerAnalyticsActivity
            }
        };
    }

    private itemsHydrator(partners: Partner[], orderable = true) {
        partners = partners.map(partner => {
            const partnerLogo = Utils.lodash.get(partner, 'partnerLogo', null) || null;

            let partnerSubscription;
            try {
                partner.subscription = JSON.parse(partner.subscription as string) as PartnerSubscription;

                if (partner.subscription.hasOwnProperty('addonIDs')) {
                    partner.subscription.addonIDs = (partner.subscription.addonIDs as string[]).map(id => parseInt(id)) as number[];
                }

                partner.subscription.partnerID = parseInt((partner.subscription.partnerID as string));
                partner.subscription.planID = parseInt((partner.subscription.planID as string));

                partner.subscription.expiryDate = Utils.moment(partner.subscription.expiryDate).format(BASE_DATE_FORMAT);

                partnerSubscription = (partner.subscription as PartnerSubscription).subscriptionName;
            } catch (e) {
                partnerSubscription = partner.subscription;
            }

            return {
                ...partner,
                partnerLogo: !!partnerLogo ? environment.apiUrl + partnerLogo : null,
                partnerData: Utils.lodash.get(partner, 'partnerData', '{}') || '{}',
                view: {
                    shortenedSubscription: Utils.lodash.truncate(partnerSubscription, {
                        'length': 35,
                    }),
                    subscription: partnerSubscription,
                }
            };
        });

        if (orderable) {
            return Utils.lodash.orderBy(
                partners,
                [partner => partner.name.toLowerCase()]
            );
        } else {
            return partners;
        }
    }

    private itemTransformer(partner: Partner) {
        const data: Partner = Utils.lodash.cloneDeep(partner);

        delete data.statusMessage;
        delete data.subscription;
        delete data.view;
        // delete data.partnerName;

        return data;
    }
}
