import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {DocumentsModule} from '~/src/app/modules/documents/documents.module';
import {ImagePreloadModule} from '~/src/app/shared/image-preload.module';
import {PostCardComponent} from '~/src/app/modules/posts/post-card/post-card.component';
import {GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';
import { TemplateOrganizationChooserComponent } from './template-organization-chooser/template-organization-chooser.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule,
        ImagePreloadModule,
        DocumentsModule,
        GalleryModule,
        LightboxModule,
    ],
    declarations: [
        PostCardComponent,
        TemplateOrganizationChooserComponent,
    ],
    exports: [
        PostCardComponent,
    ],
    entryComponents: [
        TemplateOrganizationChooserComponent,
    ]
})
export class PostTemplateModule {}
