import {ModelAbstract} from '../../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Helpers} from '../../../services/helpers';
import {Token} from '../../../services/token';
import {MatDialog} from '@angular/material/dialog';

import { environment } from '~/src/environments/environment';

@Injectable({providedIn: 'root'})
export class UsersService extends ModelAbstract {

    constructor(public http: HttpClient, public dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/users';
    }

    /**
     * Get users by IDs
     *
     * @param {number[]} userIDs
     * @return {Promise<any>}
     */
    getUsersByIDs(userIDs: number[]): Promise<any> {
        return this.http.get(this.apiLink + '?userIDs=' + JSON.stringify(userIDs), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Get related users
     *
     * @param {any} params
     * @return {Promise<any>}
     */
    getRelatedUsers(params?: any): Promise<any> {
        const options = Helpers.getBaseHttpHeaders(Token.getToken());

        if (params) {
            options['params'] = params;
        }

        return this.http.get(environment.apiUrl + '/api/users/related-users', options).toPromise();
    }

    /**
     * Get object related users
     * 
     * @param {('post' | 'postTemplate')} objectType
     * @param {Array<number>} objectIDs
     * @param {any} params
     * @return {Promise<any>}
     */

    getObjectRelatedUsers(objectType: 'post' | 'postTemplate', objectIDs: Array<number>): Promise<any> {
        if (!objectType || !objectIDs) {
            return Promise.reject('Object type and IDs are required');
        }

        const formData = new FormData();
        formData.append('objectType', objectType);
        formData.append('objectIDs', JSON.stringify(objectIDs));

        return this.http.post(environment.apiUrl + '/api/users/object-related-users', formData, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
