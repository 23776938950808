import * as Chart from "~/node_modules/@types/chart.js";
import { ChartConfiguration, ChartSize } from "~/node_modules/@types/chart.js";
import * as ChartAnnotation from "~/node_modules/chartjs-plugin-annotation";
import * as moment from "~/node_modules/moment";
import {
    BASE_DATE_FORMAT,
    VIEW_BASE_DATE_FORMAT,
} from "~/src/app/configs/configs";
import Utils from "~/src/app/core/utils";

const setCanvasHeight = (chart: Chart) => {
    const widgetCardEl = chart.canvas.parentElement.parentElement.parentElement;
    const legendEl = widgetCardEl.querySelector(".chart-legend-label-list");
    let height = widgetCardEl.clientHeight - 110;

    if (legendEl) {
        height -= legendEl.clientHeight;
    }

    chart.canvas.style.height = height + "px";
};

const BaseConfig: ChartConfiguration = {
    options: {
        onResize: function (newSize: ChartSize) {
            const chart: Chart = arguments[0];

            setCanvasHeight(chart);
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
        },
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            xAxes: [
                {
                    type: "time",
                    time: {
                        tooltipFormat: VIEW_BASE_DATE_FORMAT,
                        unit: "day",
                        min: moment().format("DD"),
                        max: moment().format("DD"),
                    },
                },
            ],
        },
        legend: {
            labels: {
                boxWidth: 20,
                fontSize: 12,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var label =
                        data.datasets[tooltipItem.datasetIndex].label || "";
                    return label.split(new RegExp(/ - (?:Sum|Avg):/gm))[0] + ": " + tooltipItem.value; // Hide sum and percent on tooltip
                },
            },
        },
    },
    type: "line",
    plugins: [ChartAnnotation,
        {
            beforeInit: function (chart) {
                if (chart.config.type === "line") {
                    const time = chart.config.options.scales.xAxes[0].time, // 'time' object reference
                        // difference (in days) between min and max date
                        timeDiff = moment(time.max).diff(moment(time.min), "d");
                    // populate 'labels' array
                    // (create a date string for each date between min and max, inclusive)
                    for (let i = 0; i <= timeDiff; i++) {
                        const _label = moment(time.min)
                            .add(i, "d")
                            .format(BASE_DATE_FORMAT);
                        chart.data.labels.push(_label);
                    }
                }
            },
            afterInit(chartInstance: Chart, options?: any): void {
                setCanvasHeight(chartInstance);
            },
        },
    ],
} as ChartConfiguration;

export const BaseChartConfig: ChartConfiguration =
    Utils.lodash.cloneDeep(BaseConfig);
