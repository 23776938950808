import {Component, Inject, OnInit} from '@angular/core';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {FormControl, FormGroup, Validators} from '~/node_modules/@angular/forms';
import {MyErrorStateMatcher} from '~/src/app/services/helpers';
import {LanguageService} from '~/src/app/services/language.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {CategoriesService} from '~/src/app/modules/categories/categories.service';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CategoryManager as CategoryManagerDialogData} from '~/src/app/modules/categories/category-manager/category-manager';

@Component({
    selector: 'smd-category-manager',
    templateUrl: './category-manager.component.html',
    styleUrls: ['./category-manager.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class CategoryManagerComponent implements OnInit {

    // error matcher
    errorMatcher = new MyErrorStateMatcher();

    // is edit mode
    isEditMode = false;

    // category type
    categoryType: string;

    // category control names
    categoryControlNames = {
        Name: 'name',
        Color: 'color'
    };

    // category form group
    categoryFormGroup = new FormGroup({
        [this.categoryControlNames.Name]: new FormControl(null, [Validators.required]),
        [this.categoryControlNames.Color]: new FormControl(null, [Validators.required])
    });

    private _color: string = null;

    constructor(
        public language: LanguageService,
        public formHelpers: FormHelpersService,
        private componentHelpers: ComponentHelpers,
        private categoriesService: CategoriesService,
        private dialogRef: MatDialogRef<CategoryManagerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CategoryManagerDialogData
    ) {
    }

    ngOnInit() {
        this.isEditMode = this.data.isEditMode;
        this.categoryType = this.data.categoryType;

        if (this.isEditMode) {
            this.categoryFormGroup.patchValue(this.data.category);
            this._color = this.data.category.color;
        }
    }

    /**
     * Add category
     * @param event
     */
    public addCategory(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.categoryFormGroup.valid) {
            const data = {
                ...this.categoryFormGroup.value,
                [this.categoryControlNames.Color]: this.color,
                type: this.categoryType
                //global: 1
            };

            this.componentHelpers.startApiAction(
                () => {
                    return this.categoriesService.addCategory(data);
                }, {
                    successMessageKey: 'post.category.success.modal.create',
                    failedMessageKey: 'post.category.error.modal.failedToCreate',
                    afterSuccessAction: (response: any) => {
                        if (!!this.data.successCreate) {
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.data.successCreate(response);
                            });
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: (response: any) => {
                        this.formHelpers.validateForm(this.categoryFormGroup, FormValidationService.readError(response).formMessages);
                    }
                },
            );
        } else {
            this.formHelpers.validateForm(this.categoryFormGroup);
        }
    }

    /**
     * Edit category
     * @param event
     */
    public editCategory(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.categoryFormGroup.valid) {
            const data = {
                ...this.categoryFormGroup.value,
                [this.categoryControlNames.Color]: this.color,
                type: this.categoryType
            };

            this.componentHelpers.startApiAction(
                () => {
                    return this.categoriesService.editCategory(data, this.data.category.categoryID);
                }, {
                    successMessageKey: 'post.category.success.modal.edit',
                    failedMessageKey: 'post.category.error.modal.failedToEdit',
                    afterSuccessAction: (response: any) => {
                        if (!!this.data.successEdit) {
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.data.successEdit(response);
                            });
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: (response: any) => {
                        this.formHelpers.validateForm(this.categoryFormGroup, FormValidationService.readError(response).formMessages);
                    }
                },
            );
        } else {
            this.formHelpers.validateForm(this.categoryFormGroup);
        }
    }

    /**
     * Color getter
     * @returns {string}
     */
    get color(): string {
        return this._color;
    }

    /**
     * Set color
     * @param {string} value
     */
    set color(value: string) {
        this._color = value;
        this.categoryFormGroup.get(this.categoryControlNames.Color).setValue(value);
    }
}
