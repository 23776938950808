import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PartnerConfigViewsDialogData} from '~/src/app/modules/administration/partners/partner-config-views/partner-config-views.interfaces';
import {Partner, PartnerConfig} from '~/src/app/shared/types/partners.model';
import {LanguageService} from '~/src/app/services/language.service';
import Utils from '~/src/app/core/utils';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';

@Component({
    selector: 'smd-partner-config-views',
    templateUrl: './partner-config-views.component.html',
    styleUrls: ['./partner-config-views.component.scss']
})
export class PartnerConfigViewsComponent {
    partner: Partner = this.dialogData.partner;
    partnerConfigLangKeyPrefix = 'partner.info.';
    hasPartnerConfig: boolean = !!this.partner.partnerConfig;
    configTypes: (keyof PartnerConfig)[] = ['plan', 'addon', 'manual', 'total'];
    columnNames = [
        'type',
        ...Object.keys(Utils.lodash.get(this.partner, 'partnerConfig.total', []) || [])
    ];
    dataSource = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PartnerConfigViewsDialogData,
        public dialogRef: MatDialogRef<PartnerConfigViewsComponent>,
        public language: LanguageService
    ) {
        if (this.hasPartnerConfig) {
            this.dataPrepare();
        }
    }

    getView(value: any) {

        /**
         * Value is unlimited
         */
        if ([-1, '-1'].indexOf(value) > -1) {
            return this.language.getLine('partner.info.unlimited');
        }

        /**
         * Check icon
         */
        if (value === 'yes') {
            return '<i class="mdi mdi-check mdi-18px text-success"></i>';
        }

        /**
         * Close icon
         */
        if (value === 'no') {
            return '<i class="mdi mdi-close mdi-18px text-danger"></i>';
        }

        if (value === '-') {
            return '<i>' + this.language.getLine('partner.info.notDefined') + '</i>';
        }

        if (!isNaN(parseInt(value))) {
            return value;
        }

        return this.language.getLine('partner.period.unit.' + value);
    }

    private dataPrepare() {
        const data = [];

        for (const type of this.configTypes) {
            if (!!this.partner && !!this.partner.partnerConfig && !!this.partner.partnerConfig[type]) {
                const value = this.partner.partnerConfig[type];
                data.push({
                    type: type,
                    ...value
                });
            } else {
                const fake = {};
                const totalPartnerConfig = Utils.get(this.partner, 'partnerConfig.total', null);
                const configNames = totalPartnerConfig ? Object.keys(totalPartnerConfig) : PartnersService.getAllConfigName();

                for (const dataName of configNames) {
                    fake[dataName] = '-';
                }
                data.push({
                    type: type,
                    ...fake
                });
            }
        }

        this.dataSource = data;
    }
}
