import {Directive, ElementRef, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ResourceService} from '~/src/app/directives/resource-checker/resource.service';
import {ConsoleLoggerService} from '~/src/app/shared/services/log/console-logger.service';

@Directive({
    selector: '[resource]'
})
export class ResourceDirective implements OnInit {

    @Input() resource: string | Array<string>;
    @Input('resourceForceEnable') set setEnable(value: boolean) {
        this.haveForceEnable = true;
        this.enable = value;
    }
    @Input() connectionType: 'and' | 'or' = 'and';
    enable = false;
    haveForceEnable = false;

    constructor(
        public el: ElementRef,
        public container: ViewContainerRef,
        public service: ResourceService,
        private logService: ConsoleLoggerService
    ) {}

    ngOnInit(): void {
        let param;

        if (!this.resource) {
            return;
        }

        if (!this.resource.length) {
            this.logService.error('has no resource defined.');
        }

        param = Array.isArray(this.resource) ? this.resource : [this.resource];

        if (!this.service.checkResource(param, this.haveForceEnable, null, this.connectionType)) {
            this.el.nativeElement.remove();
        }
    }

}
