import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {InnerHtmlModule} from '~/src/app/directives/inner-html/inner-html.module';
import {ImagePreloadModule} from '~/src/app/shared/image-preload.module';
import {DocumentsComponent} from '~/src/app/modules/documents/documents.component';
import {MediaComponent} from '~/src/app/components/media/media.component';
import {FileBrowserComponent} from '~/src/app/components/file-browser/file-browser.component';
import {DialogFileBrowserComponent} from '~/src/app/modules/posts/dialog-file-browser/dialog-file-browser.component';
import {FileComponent} from '~/src/app/components/file/file.component';
import {FileUploadComponent} from '~/src/app/components/file-upload/file-upload.component';
import {DialogFileUploaderComponent} from '~/src/app/components/file-upload/dialog-file-uploader/dialog-file-uploader.component';
import {SharedModule} from '~/src/app/shared/shared.module';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import {GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule,
        InnerHtmlModule,
        NgxGalleryModule,
        GalleryModule,
        LightboxModule,
        ImagePreloadModule
    ],
    declarations: [
        DocumentsComponent,
        MediaComponent,
        FileBrowserComponent,
        DialogFileBrowserComponent,
        FileComponent,
        FileUploadComponent,
        DialogFileUploaderComponent
    ],
    exports: [
        DocumentsComponent,
        MediaComponent,
        FileBrowserComponent,
        DialogFileBrowserComponent,
        FileComponent,
        FileUploadComponent,
        DialogFileUploaderComponent
    ],
    entryComponents: [
        DialogFileBrowserComponent,
        DialogFileUploaderComponent
    ]
})
export class DocumentsModule {}
