import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import {Helpers} from '~/src/app/services/helpers';
import {truncate} from 'lodash';
import {RssBlogPostModel} from '~/src/app/modules/rss/models/rss-blog-post.model';
import {RssSavedPostService} from '~/src/app/modules/rss/services/rss-saved-post.service';
import {ClickDecorator} from '~/src/app/core/decorators';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostManagerComponent} from '~/src/app/modules/social-media-post/post-manager/post-manager.component';
import Utils from '~/src/app/core/utils';
import {BASE_DATETIME_FORMAT, PLACEHOLDER_IMAGE} from '~/src/app/configs/configs';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';
import {
    POST_SCHEDULE_DATE_DELAY_MINUTES,
    PostFormControlNames
} from '~/src/app/modules/social-media-post/social-media-post.constant';
import {IRSSSavePostResponse} from '~/src/app/modules/rss/types/rss-saved-post.interface';
import {RssSavedPostModel} from '~/src/app/modules/rss/models/rss-saved-post.model';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'smd-feed-card',
    templateUrl: './feed-card.component.html',
    styleUrls: ['./feed-card.component.scss'],
    providers: [
        ComponentHelpers
    ]
})
export class FeedCardComponent {
    @Input() blogPost: RssBlogPostModel;
    @Output() removed: EventEmitter<RssBlogPostModel> = new EventEmitter<RssBlogPostModel>();
    @Output() saved: EventEmitter<RssBlogPostModel> = new EventEmitter<RssBlogPostModel>();
    fallbackImg = PLACEHOLDER_IMAGE;

    constructor(
        public languageService: LanguageService,
        private rssSavedPostService: RssSavedPostService,
        private componentHelpers: ComponentHelpers,
        private openModal: OpenModalService
    ) { }

    @ClickDecorator()
    savePost(event: MouseEvent, feedMenuTrigger: MatMenuTrigger): void {
        this.componentHelpers.startApiAction(
            () => this.rssSavedPostService.savePost(this.blogPost.channel.feedID, {
                pubDate: this.blogPost.pubDate,
                title: this.blogPost.title,
                description: this.blogPost.description,
                image: this.blogPost.image,
                author: this.blogPost.creator,
                link: this.blogPost.link
            }),
            {
                successMessageKey: 'rss.savedPost.save.success.message',
                failedMessageKey: 'rss.savedPost.save.failed.message',
                afterSuccessAction: (response: IRSSSavePostResponse<RssSavedPostModel>) => {
                    const blogPost: RssBlogPostModel = Utils.lodash.cloneDeep(this.blogPost);
                    blogPost.savedPost = response.savedPost;
                    this.saved.emit(blogPost);
                    feedMenuTrigger.closeMenu();
                }
            }
        );
    }

    @ClickDecorator()
    deletePost(event: MouseEvent): void {
        this.componentHelpers.startApiAction(
            () => this.rssSavedPostService.deleteSavedPost(this.blogPost.savedPost.postID),
            {
                successMessageKey: 'rss.savedPost.remove.success.message',
                failedMessageKey: 'rss.savedPost.remove.failed.message',
                confirmMessageKey: 'rss.savedPost.remove.confirm.message',
                confirmYesButtonTextKey: 'rss.savedPost.remove.confirm.button',
                afterSuccessAction: () => this.removed.emit(this.blogPost)
            },
            true
        );
    }

    @ClickDecorator()
    createPostTemplate(event: MouseEvent) {
        const post = {
            [PostFormControlNames.Headline]: Utils.get<RssBlogPostModel>(this.blogPost, 'title', null),
            [PostFormControlNames.Message]: Utils.get<RssBlogPostModel>(this.blogPost, 'description'),
            linkshare: {
                title: Utils.get<RssBlogPostModel>(this.blogPost, 'title', ''),
                description: Utils.get<RssBlogPostModel>(this.blogPost, 'description', ''),
                image: Utils.get<RssBlogPostModel>(this.blogPost, 'image', ''),
                url: Utils.get<RssBlogPostModel>(this.blogPost, 'link', ''),
                fromRss: true
            }
        };

        if (this.blogPost.link) {
            if (post[PostFormControlNames.Message] !== '') {
                post[PostFormControlNames.Message] += '<br>';
            }

            post[PostFormControlNames.Message] += this.blogPost.link.trim();
        }

        this.openModal.createPost(PostManagerComponent, {
            data: {
                rssTemplate: post,
                scheduleDate: CommonPostHelpers.roundMinuteToFive(Utils.moment().add(POST_SCHEDULE_DATE_DELAY_MINUTES, 'minute').format(BASE_DATETIME_FORMAT)).dateTime
            }
        });
    }

    getTitle() {
        return truncate(this.blogPost.title, {length: 45});
    }

    getMainDomain(url: string) {
        return Helpers.extractRootDomain(url);
    }

}
