import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {MessageBoxConfig, MessageBoxTypes} from '~/src/app/core/components/message-box/message-box.config';

declare const $: any;

@Component({
    selector: 'smd-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent implements OnInit {

    // input properties
    @Input() title = 'Information';
    @Input() message: string;
    @Input() set type(value: MessageBoxTypes) {
        this._type = value;

        this._setClassNames();
    }
    @Input() opened = false;
    @Output() initialized = new EventEmitter<ElementRef<MessageBoxComponent>>();

    // view children
    @ViewChild('messageBox', {static: true}) messageBox: ElementRef<HTMLDivElement>;
    @ContentChild('messageBoxContent') messageBoxContent: TemplateRef<any>;

    // class names
    classNames = '';
    textClassNames = '';
    iconClassNames = '';

    isCollapsed = false;
    get type(): MessageBoxTypes {
        return this._type;
    }

    private _type: MessageBoxTypes = MessageBoxConfig.TYPES.Info;

    constructor(
        private el: ElementRef
    ) {
    }

    ngOnInit() {
        this.isCollapsed = this.opened;
        this._setClassNames();
        this.initialized.emit(this.el);
    }

    /**
     * Collapse message box element click event
     *
     * @param event
     */
    collapseElement(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.isCollapsed = !this.isCollapsed;

        $(this.messageBox.nativeElement).collapse('toggle');
    }

    /**
     * Set class names
     *
     * @private
     */
    private _setClassNames(): void {
        if (this.type) {

            // set host class names by type
            this.classNames = MessageBoxConfig.getClassName(this.type);

            // set icon class names by type
            this.iconClassNames = MessageBoxConfig.getIconClassName(this.type);

            // set text class names by type
            this.textClassNames = MessageBoxConfig.getTextClassName(this.type);
        }
    }
}
