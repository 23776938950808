import { SocialMediaPlatform } from "~/src/app/modules/social-media-post/social-media-post.interface";
import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
} from "~/src/app/core/constants";
import {
    activeFromDatMaxDate,
    activeFromDatMinDate,
    PostFormControlNames,
    PostFormExpansionPanels,
    TWITTER_MAX_SELECTED_IMAGE,
    INSTAGRAM_MAX_SELECTED_IMAGE,
    INSTAGRAM_MAX_SELECTED_VIDEO,
    INSTAGRAM_MAX_SELECTED_MIXED,
} from "~/src/app/modules/social-media-post/social-media-post.constant";
import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { maxDate, minDate } from "@ng-validators/ng-validators";
import { SocialMediaPostUtils } from "~/src/app/modules/social-media-post/social-media-post.utils";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { MentionHelpers } from "~/src/app/modules/posts/mention-helpers";
import {
    FALLBACK_IMAGE,
    TINYMCE_DELIMITER_MENTION,
} from "~/src/app/configs/configs";
import { StringSupport } from "../../core/helper/string-support";
import { POST_FORM_CONTROL_NAMES as GMB_POST_FORM_CONTROL_NAMES } from "~/src/app/modules/social-media-post/gmb/gmb.constants";
import { truncate } from "fs";

declare const twttr: any;

export const socialSiteAppearance = {
    gmb: {
        schedule: true,
        postmanager: true,
        medialibrary: true,
        analytics: false,
        templatemanager: true,
    },
    instagram: {
        analytics: true,
    },
    twitter: {
        analytics: false, //its also filtered in the analytics module
    },
};

export const MENTION_FORMATTERS = {
    [SOCIAL_MEDIA_TYPE_FACEBOOK]: (mention) => {
        return `@[${mention.id}]`;
    },
    [SOCIAL_MEDIA_TYPE_TWITTER]: (mention) => {
        return `${mention.name}`;
    },
    [SOCIAL_MEDIA_TYPE_LINKEDIN]: (mention) => {
        return `${mention.name}`;
    },
};

export const MENTION_FORMATTER_IN = {
    [SOCIAL_MEDIA_TYPE_FACEBOOK]: (
        content: string,
        mention: any,
        replacement: string
    ) => {
        return content.replace(
            new RegExp(`@\\[${mention.id}\\]`, "g"),
            replacement
        );
    },

    [SOCIAL_MEDIA_TYPE_TWITTER]: (
        content: string,
        mention: any,
        replacement: string
    ) => {
        return content.replace(
            new RegExp(`@${mention.name}`, "g"),
            replacement
        );
    },

    [SOCIAL_MEDIA_TYPE_LINKEDIN]: (
        content: string,
        mention: any,
        replacement: string
    ) => {
        return content.replace(
            new RegExp(`@${mention.name}`, "g"),
            replacement
        );
    }
};

export const SocialMediaPlatforms: SocialMediaPlatform[] = [
    {
        id: "facebook",
        platform: SOCIAL_MEDIA_TYPE_FACEBOOK,
        iconClass: "fa fa-facebook-square",
        tabNameKey: "social.media.platform.facebook",
        isPostEditor: true,
        isDisabled: true,
        isValid: false,
        isCopied: false,
        maxLength: 5000,
        validators: {
            [PostFormControlNames.Headline]: [
                postContentMaxLength(255, SOCIAL_MEDIA_TYPE_FACEBOOK),
            ],
            [PostFormControlNames.SubHeadline]: [
                postContentMaxLength(255, SOCIAL_MEDIA_TYPE_FACEBOOK),
            ],
            [PostFormControlNames.Message]: [
                Validators.required,
                Validators.minLength(1),
            ],
            [PostFormControlNames.ActiveFromDate]: [
                Validators.required,
                minDate(activeFromDatMinDate),
                maxDate(activeFromDatMaxDate),
            ],
            [PostFormControlNames.ActiveFromHour]: [
                Validators.required,
                Validators.min(0),
                Validators.max(23),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.ActiveFromMinute]: [
                Validators.required,
                Validators.min(0),
                Validators.max(59),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.Comment]: [Validators.maxLength(20000)],
        },
        enableMention: true,
        mentionFormat: MENTION_FORMATTERS[SOCIAL_MEDIA_TYPE_FACEBOOK],
        mentionPreviewFormatter(mentionLabel: string): string {
            return mentionLabel.replace(
                new RegExp(TINYMCE_DELIMITER_MENTION, "g"),
                ""
            );
        },
        hashtagSearchPageUrl(hashtag: string): string {
            return `https://www.facebook.com/hashtag/${hashtag}`;
        },
        copyFormDataPreparer(formData: {} = {}): any {
            return SocialMediaPostUtils.copyCommonPreparer(
                formData,
                SOCIAL_MEDIA_TYPE_FACEBOOK,
                this
            );
        },
    },
    {
        id: "linkedIn",
        platform: SOCIAL_MEDIA_TYPE_LINKEDIN,
        iconClass: "fa fa-linkedin-square",
        tabNameKey: "social.media.platform.linkedIn",
        isPostEditor: true,
        isDisabled: true,
        isValid: false,
        isCopied: false,
        maxLength: 3000,
        /**
         * maxLengths FIX this:
         * com.linkedin.publishing.util.common.ResponseException:
         * ShareCommentary text length (2095 characters) exceeded the maximum allowed (3000 characters)
         */
        validators: {
            [PostFormControlNames.Headline]: [
                postContentMaxLength(215, SOCIAL_MEDIA_TYPE_LINKEDIN),
            ],
            [PostFormControlNames.SubHeadline]: [
                postContentMaxLength(215, SOCIAL_MEDIA_TYPE_LINKEDIN),
            ],
            [PostFormControlNames.Message]: [
                Validators.required,
                Validators.minLength(1),
            ],
            [PostFormControlNames.ActiveFromDate]: [
                Validators.required,
                minDate(activeFromDatMinDate),
                maxDate(activeFromDatMaxDate),
            ],
            [PostFormControlNames.ActiveFromHour]: [
                Validators.required,
                Validators.min(0),
                Validators.max(23),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.ActiveFromMinute]: [
                Validators.required,
                Validators.min(0),
                Validators.max(59),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.Comment]: [Validators.maxLength(20000)],
        },
        enableMention: true,
        mentionPreviewFormatter(mentionLabel: string): string {
            return mentionLabel.replace(
                new RegExp(TINYMCE_DELIMITER_MENTION, "g"),
                ""
            );
        },
        hashtagSearchPageUrl(hashtag: string): string {
            return `https://www.linkedin.com/feed/hashtag/?keywords=${hashtag}`;
        },
        copyFormDataPreparer(formData: {} = {}): any {
            return SocialMediaPostUtils.copyCommonPreparer(
                formData,
                SOCIAL_MEDIA_TYPE_LINKEDIN,
                this
            );
        },
    },
    {
        id: SOCIAL_MEDIA_TYPE_TWITTER,
        platform: SOCIAL_MEDIA_TYPE_TWITTER,
        iconClass: "mdi mdi-twitter-box",
        tabNameKey: "social.media.platform.twitter",
        isPostEditor: true,
        isDisabled: true,
        isValid: false,
        isCopied: false,
        maxLength: 280,
        selectableSocialSiteLimit: 1,
        excludedControlNames: [
            PostFormControlNames.Headline,
            PostFormControlNames.SubHeadline,
            PostFormControlNames.Signature,
        ],
        excludedExpansionPanels: [
            PostFormExpansionPanels.Headline,
            PostFormExpansionPanels.SubHeadline,
            PostFormExpansionPanels.Signature,
            PostFormExpansionPanels.NewsFeedTargeting,
        ],
        disabledControlNames: [
            PostFormControlNames.LinkShareTitle,
            PostFormControlNames.LinkShareDescription,
        ],
        validators: {
            [PostFormControlNames.Message]: [
                Validators.required,
                Validators.minLength(1),
                postContentMaxLength(280, SOCIAL_MEDIA_TYPE_TWITTER),
            ],
            [PostFormControlNames.ActiveFromDate]: [
                Validators.required,
                minDate(activeFromDatMinDate),
                maxDate(activeFromDatMaxDate),
            ],
            [PostFormControlNames.ActiveFromHour]: [
                Validators.required,
                Validators.min(0),
                Validators.max(23),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.ActiveFromMinute]: [
                Validators.required,
                Validators.min(0),
                Validators.max(59),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.Comment]: [Validators.maxLength(20000)],
        },
        fileBrowserRule: {
            conditions: {
                type: FILE_TYPES.CAROUSEL,
            },
            rules: {
                notAllow: true,
            },
            limits: {
                selectedImage: TWITTER_MAX_SELECTED_IMAGE,
            },
        },
        enableMention: true,
        mentionFormat: MENTION_FORMATTERS[SOCIAL_MEDIA_TYPE_TWITTER],
        mentionPreviewFormatter(mentionLabel: string): string {
            return mentionLabel;
        },
        hashtagSearchPageUrl(hashtag: string): string {
            return `https://twitter.com/hashtag/${hashtag}`;
        },
        copyFormDataPreparer(formData = {}) {
            const data = SocialMediaPostUtils.copyCommonPreparer(
                formData,
                SOCIAL_MEDIA_TYPE_TWITTER,
                this
            );

            if (Object.keys(data).length) {
                data[PostFormControlNames.Message] =
                    SocialMediaPostUtils.mergePostFieldsToMainCopy(data);
                data[PostFormControlNames.Headline] = null;
                data[PostFormControlNames.SubHeadline] = null;
                data[PostFormControlNames.Signature] = null;
                data[PostFormControlNames.Medias] = (
                    data[PostFormControlNames.Medias] || []
                ).filter((item) => item.type !== FILE_TYPES.CAROUSEL);

                if (data.backupLinkshare && data.backupLinkshare.twitter) {
                    const hasOGImage =
                        data[PostFormControlNames.Medias].filter(
                            (media) => media.type === FILE_TYPES.OG
                        ).length > 0;

                    data.backupLinkshare = data.backupLinkshare.twitter;
                    data.linkshare = {
                        ...(data.linkshare || {}),
                        ...data.backupLinkshare,
                    };
                    data[PostFormControlNames.LinkShareTitle] =
                        data.linkshare.title;
                    data[PostFormControlNames.LinkShareDescription] =
                        data.linkshare.description;
                    data[PostFormControlNames.LinkShareURL] =
                        data.linkshare.url;
                    data[PostFormControlNames.LinkShareImageURL] =
                        data.linkshare.image;

                    if (
                        data[PostFormControlNames.Medias].length &&
                        !hasOGImage
                    ) {
                        data[PostFormControlNames.Medias] = [];
                    }
                }

                if (
                    data[PostFormControlNames.SelectedMentions] &&
                    Object.keys(data[PostFormControlNames.SelectedMentions])
                ) {
                    const messageMentions =
                        data[PostFormControlNames.SelectedMentions][
                            PostFormControlNames.Message
                        ] || [];
                    data[PostFormControlNames.SelectedMentions][
                        PostFormControlNames.Message
                    ] = [
                        ...(data[PostFormControlNames.SelectedMentions][
                            PostFormControlNames.Headline
                        ] || []),
                        ...(data[PostFormControlNames.SelectedMentions][
                            PostFormControlNames.SubHeadline
                        ] || []),
                        ...messageMentions,
                        ...(data[PostFormControlNames.SelectedMentions][
                            PostFormControlNames.Signature
                        ] || []),
                    ];
                    delete data[PostFormControlNames.SelectedMentions][
                        PostFormControlNames.Headline
                    ];
                    delete data[PostFormControlNames.SelectedMentions][
                        PostFormControlNames.SubHeadline
                    ];
                    delete data[PostFormControlNames.SelectedMentions][
                        PostFormControlNames.Signature
                    ];
                }
            }

            return data;
        },
    },
    {
        id: "instagram",
        platform: SOCIAL_MEDIA_TYPE_INSTAGRAM,
        iconClass: "fa fa-instagram",
        tabNameKey: "social.media.platform.instagram",
        isPostEditor: true,
        isDisabled: true,
        isValid: false,
        isCopied: false,
        maxLength: 2200,
        excludedExpansionPanels: [
            PostFormExpansionPanels.NewsFeedTargeting,
            PostFormExpansionPanels.LinkShare,
        ],
        validators: {
            [PostFormControlNames.Headline]: [
                postContentMaxLength(255, SOCIAL_MEDIA_TYPE_INSTAGRAM),
            ],
            [PostFormControlNames.SubHeadline]: [
                postContentMaxLength(255, SOCIAL_MEDIA_TYPE_INSTAGRAM),
            ],
            [PostFormControlNames.Message]: [
                postContentMaxLength(2200, SOCIAL_MEDIA_TYPE_INSTAGRAM),
            ],
            [PostFormControlNames.ActiveFromDate]: [
                Validators.required,
                minDate(activeFromDatMinDate),
                maxDate(activeFromDatMaxDate),
            ],
            [PostFormControlNames.ActiveFromHour]: [
                Validators.required,
                Validators.min(0),
                Validators.max(23),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.ActiveFromMinute]: [
                Validators.required,
                Validators.min(0),
                Validators.max(59),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.Comment]: [Validators.maxLength(20000)],
            [PostFormControlNames.Medias]: [Validators.required],
        },
        fileBrowserRule: {
            conditions: {
                type: FILE_TYPES.CAROUSEL,
            },
            rules: {
                notAllow: true,
            },
            limits: {
                selectedImage: INSTAGRAM_MAX_SELECTED_IMAGE,
                selectedVideo: INSTAGRAM_MAX_SELECTED_VIDEO,
                selectedMixed: INSTAGRAM_MAX_SELECTED_MIXED,
            },
        },
        enableMention: false,
        mentionFormat: MENTION_FORMATTERS[SOCIAL_MEDIA_TYPE_INSTAGRAM],
        mentionPreviewFormatter(mentionLabel: string): string {
            return mentionLabel.replace(
                new RegExp(TINYMCE_DELIMITER_MENTION, "g"),
                ""
            );
        },
        hashtagSearchPageUrl(hashtag: string): string {
            return `https://www.facebook.com/hashtag/${hashtag}`;
        },
        copyFormDataPreparer(formData: {} = {}): any {
            const data = SocialMediaPostUtils.copyCommonPreparer(
                formData,
                SOCIAL_MEDIA_TYPE_INSTAGRAM,
                this
            );

            if (Object.keys(data).length) {
                const subHeadline =
                    data[PostFormControlNames.SubHeadline] || "";
                const mainCopy = data[PostFormControlNames.Message] || "";
                const lineBreak = "<br />";
                let content = "";

                [subHeadline, mainCopy].forEach((value) => {
                    if (value) {
                        content += value + lineBreak + lineBreak;
                    }
                });

                data[PostFormControlNames.Message] =
                    StringSupport.trimSpecifyString(
                        content,
                        lineBreak,
                        "right"
                    );
                data[PostFormControlNames.Medias] = (
                    data[PostFormControlNames.Medias] || []
                ).filter((item) => item.type !== FILE_TYPES.CAROUSEL);
                data[PostFormControlNames.SubHeadline] = null;
            }

            return data;
        },
    },
    {
        id: SOCIAL_MEDIA_TYPE_GMB,
        platform: SOCIAL_MEDIA_TYPE_GMB,
        iconClass: "fa fa-gmb",
        tabNameKey: "social.media.platform.gmb",
        isPostEditor: true,
        isDisabled: true,
        isValid: false,
        isCopied: false,
        maxLength: 1500,
        validators: {
            [PostFormControlNames.Headline]: [
                postContentMaxLength(250, SOCIAL_MEDIA_TYPE_GMB),
            ],
            [PostFormControlNames.ActiveFromDate]: [
                Validators.required,
                minDate(activeFromDatMinDate),
                maxDate(activeFromDatMaxDate),
            ],
            [PostFormControlNames.ActiveFromHour]: [
                Validators.required,
                Validators.min(0),
                Validators.max(23),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.ActiveFromMinute]: [
                Validators.required,
                Validators.min(0),
                Validators.max(59),
                Validators.maxLength(2),
            ],
            [PostFormControlNames.Comment]: [Validators.maxLength(20000)],
            /* [PostFormControlNames.Message]: [
                eitherRequired(PostFormControlNames.Medias),
            ],
            [PostFormControlNames.Medias]: [
                eitherRequired(PostFormControlNames.Message),
            ], */
        },
        enableMention: false,
        excludedExpansionPanels: [
            PostFormExpansionPanels.NewsFeedTargeting,
            PostFormExpansionPanels.LinkShare,
        ],
        includedExpansionPanels: [PostFormExpansionPanels.GmbOptions],
        disabledControlNames: [
            PostFormControlNames.SubHeadline,
            PostFormControlNames.Signature,
        ],
        formControlsInExpansionPanel: {
            [PostFormExpansionPanels.GmbOptions]: Object.values(
                GMB_POST_FORM_CONTROL_NAMES
            ),
        },
        fileBrowserRule: {
            conditions: {
                type: FILE_TYPES.CAROUSEL,
            },
            rules: {
                notAllow: true,
            },
            limits: {
                selectedImage: INSTAGRAM_MAX_SELECTED_IMAGE,
            },
        },
        copyFormDataPreparer(formData: {} = {}): any {
            const data = SocialMediaPostUtils.copyCommonPreparer(
                formData,
                SOCIAL_MEDIA_TYPE_GMB,
                this
            );

            if (Object.keys(data).length) {
                const subHeadline =
                    data[PostFormControlNames.SubHeadline] || "";
                const signature = data[PostFormControlNames.Signature] || "";
                const mainCopy = data[PostFormControlNames.Message] || "";
                const lineBreak = "<br />";
                let content = "";

                [subHeadline, mainCopy, signature].forEach((value) => {
                    if (value) {
                        content += value + lineBreak;
                    }
                });

                data[PostFormControlNames.Message] =
                    StringSupport.trimSpecifyString(
                        content,
                        lineBreak,
                        "right"
                    );
                data[PostFormControlNames.Medias] = (
                    data[PostFormControlNames.Medias] || []
                ).filter((item) => item.type !== FILE_TYPES.CAROUSEL);

                data[PostFormControlNames.SubHeadline] = null;
                data[PostFormControlNames.Signature] = null;
            }

            return data;
        },
    },
];

export class SocialMediaPlatformConfig {
    static getConfig(platform: string): SocialMediaPlatform {
        return SocialMediaPlatforms.find(
            (_platform) => _platform.platform === platform
        );
    }
}

/**
 * @param {number} maxLength
 * @param {string} platform
 * @return {ValidatorFn}
 */
export function postContentMaxLength(
    maxLength: number,
    platform: string
): ValidatorFn {
    return (control: AbstractControl) => {
        let value = htmlSpecialDecode(
            (control.value || "").replace(/<br(.*?)>/g, "\n")
        );
        value = MentionHelpers.formatMention(
            value,
            platform,
            MENTION_FORMATTERS[platform] || null
        );

        const valueLength: number =
            platform === SOCIAL_MEDIA_TYPE_TWITTER
                ? twttr.txt.getTweetLength(value)
                : value.length;

        if (valueLength > maxLength) {
            return {
                maxlength: {
                    requiredLength: maxLength,
                },
            };
        }

        return null;
    };
}

export function htmlSpecialDecode(value: string): string {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = value;
    return tempElement.innerText;
}

/**
 * @param {number} maxLength
 * @param {string} platform
 * @return {ValidatorFn}
 */
/* export function eitherRequired(otherField): ValidatorFn {
    return (control: AbstractControl) => {
        if (
            (!control.parent.get(otherField).value ||
                !control.parent.get(otherField).value.length) &&
            (!control.value || !control.value.length)
        ) {
            return {
                required: true,
            };
        }
        return null;
    };
} */
