import {Component, Input, OnInit} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'smd-select-buttons',
    templateUrl: './select-buttons.component.html',
    styleUrls: ['./select-buttons.component.scss']
})
export class SelectButtonsComponent implements OnInit {
    @Input() ngSelectElement: NgSelectComponent;
    @Input() matSelectElement: MatSelect;

    constructor() {}

    ngOnInit() {}
}
