import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {PostTemplateFiltersComponent} from '~/src/app/modules/posts/post-template-filters/post-template-filters.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    exports: [
        PostTemplateFiltersComponent
    ],
    declarations: [
        PostTemplateFiltersComponent
    ]
})
export class PostTemplateFiltersModule {
}
