import {debounceTime} from 'rxjs/operators';
import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {DataTableHelpers} from '~/src/app/core/services/data-table-helpers.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import Utils from '~/src/app/core/utils';
import {LanguageService} from '~/src/app/services/language.service';
import {ItemViewTemplateDirective} from '~/src/app/core/components/item-separation/item-separation-templates.directive';
import {ItemSeparationChange} from '~/src/app/core/components/item-separation/item-separation.interfaces';
@Component({
    selector: 'smd-item-separation',
    templateUrl: './item-separation.component.html',
    styleUrls: ['./item-separation.component.scss']
})
export class ItemSeparationComponent {
    @Input() bindViewValue;
    @Input() bindSearchValue;
    @Input() bindSelectionValue;
    @Input() bindOrderValue;
    @Input() getPending = false;
    @Input() enabledTitleKey = 'item.chooser.enabled.title';
    @Input() disabledTitleKey = 'item.chooser.disabled.title';
    @Input() searchLabelKey = 'item.chooser.search.label';
    @Output() separationChange = new EventEmitter<ItemSeparationChange>();
    @ContentChild(ItemViewTemplateDirective, {read: TemplateRef}) itemViewTmp: TemplateRef<any>;
    enabledItemSelection = new DataTableHelpers();
    disabledItemSelection = new DataTableHelpers();
    enabledSearchControl = new FormControl(null);
    disabledSearchControl = new FormControl(null);
    private _enabledItems: any[] = [];
    private _disabledItems: any[] = [];
    private _filteredEnabledItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    private _filteredDisabledItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        public language: LanguageService
    ) {
        this.enabledSearchControl.valueChanges.pipe(debounceTime(350)).subscribe(() => this.setFilteredEnabledItems());
        this.disabledSearchControl.valueChanges.pipe(debounceTime(350)).subscribe(() => this.setFilteredDisabledItems());
    }

    /**
     * Enable selected items
     * @param {MouseEvent} event
     */
    enableSelected(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.enabledItems = [
            ...this.enabledItems,
            ...this.disabledItemSelection.getSelectedItems()
        ];

        this.disabledItems = Utils.lodash.differenceBy(
            this.disabledItems, this.disabledItemSelection.getSelectedItems(), this.bindSelectionValue
        );
        this.disabledItemSelection.clearSelection();

        this.emitItemSeparationChange();
    }

    /**
     * Disable selected items
     * @param {MouseEvent} event
     */
    disableSelected(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.disabledItems = [
            ...this.disabledItems,
            ...this.enabledItemSelection.getSelectedItems()
        ];

        this.enabledItems = Utils.lodash.differenceBy(
            this.enabledItems, this.enabledItemSelection.getSelectedItems(), this.bindSelectionValue
        );
        this.enabledItemSelection.clearSelection();

        this.emitItemSeparationChange();
    }

    /**
     * Enable all item
     * @param {MouseEvent} event
     */
    enableAll(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.enabledItems = [
            ...this.enabledItems,
            ...Utils.lodash.cloneDeep(this._filteredDisabledItems.getValue())
        ];
        this.disabledItems = Utils.lodash.differenceBy(this.disabledItems, this._filteredDisabledItems.getValue(), this.bindSelectionValue);
        this._filteredDisabledItems.next([]);

        this.emitItemSeparationChange();
    }

    /**
     * Disable all item
     * @param {MouseEvent} event
     */
    disableAll(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.disabledItems = [
            ...this.disabledItems,
            ...Utils.lodash.cloneDeep(this._filteredEnabledItems.getValue())
        ];
        this.enabledItems = Utils.lodash.differenceBy(this.enabledItems, this._filteredEnabledItems.getValue(), this.bindSelectionValue);
        this._filteredEnabledItems.next([]);

        this.emitItemSeparationChange();
    }

    /**
     * Select item
     * @param {MouseEvent} event
     * @param item
     * @param {"enabled" | "disabled"} type
     */
    selectItem(event: MouseEvent, item, type: 'enabled' | 'disabled') {
        if (type === 'enabled') {
            this.enabledItemSelection.selectItem(item, this.bindSelectionValue);
        } else if (type === 'disabled') {
            this.disabledItemSelection.selectItem(item, this.bindSelectionValue);
        }
    }

    /**
     * Check the item is selected
     * @param item
     * @param {"enabled" | "disabled"} type
     * @return {boolean}
     */
    isSelected(item, type: 'enabled' | 'disabled') {
        if (type === 'enabled') {
            return this.enabledItemSelection.isSelected(item, this.bindSelectionValue);
        } else if (type === 'disabled') {
            return this.disabledItemSelection.isSelected(item, this.bindSelectionValue);
        }
    }

    /**
     * Set filtered enabled items
     */
    setFilteredEnabledItems() {
        const searchValue = this.enabledSearchControl.value;

        if (!!searchValue) {
            this._filteredEnabledItems.next(
                this.enabledItems.filter(item => this.search(searchValue, item))
            );
        } else {
            this._filteredEnabledItems.next(this.enabledItems);
        }
    }

    /**
     * Set filtered disabled items
     */
    setFilteredDisabledItems() {
        const searchValue = this.disabledSearchControl.value;

        if (!!searchValue) {
            this._filteredDisabledItems.next(
                this.disabledItems.filter(item => this.search(searchValue, item))
            );
        } else {
            this._filteredDisabledItems.next(this.disabledItems);
        }
    }

    /**
     * Emit item separation change
     */
    emitItemSeparationChange() {
        this.separationChange.emit({
            enabledItems: this.enabledItems,
            disabledItems: this.disabledItems
        });
    }

    get filteredEnabledItems(): Observable<any[]> {
        return this._filteredEnabledItems.asObservable();
    }

    get filteredDisabledItems(): Observable<any[]> {
        return this._filteredDisabledItems.asObservable();
    }

    get enabledItems(): any[] {
        return this._enabledItems;
    }

    @Input()
    set enabledItems(value: any[]) {
        this._enabledItems = Utils.lodash.orderBy(value, [this.bindOrderValue]);
        this.setFilteredEnabledItems();
    }

    get disabledItems(): any[] {
        return this._disabledItems;
    }

    @Input()
    set disabledItems(value: any[]) {
        this._disabledItems = Utils.lodash.orderBy(value, [this.bindOrderValue]);
        this.setFilteredDisabledItems();
    }

    /**
     * Search function
     * @param {string} searchValue
     * @param item
     * @return {boolean}
     */
    private search(searchValue: string, item) {
        const value = (item[this.bindSearchValue] as string).trim().toLowerCase();
        searchValue = searchValue.trim().toLowerCase();

        return value.indexOf(searchValue) > -1;
    }
}
