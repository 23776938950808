import {HttpClient} from '@angular/common/http';
import {HostListener, Injectable} from '@angular/core';
import {CustomEvents, Helpers} from './helpers';
import {TOKEN_KEY} from '../configs/configs';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '~/src/environments/environment';

const API_URL = environment.apiUrl + '/api/users/update-token';

@Injectable({providedIn: 'root'})
export class Token {

    public time;
    public enableTimer = true;
    private _enableResetToken = true;

    constructor(
        private http: HttpClient,
        private dialog: MatDialog
    ) {
        this.setTime();
    }

    /**
     * Save token in localStorage
     *
     * @param {string} token
     * @param {number} expires
     */
    public static saveToken(token: string, expires: number): void {
        localStorage.setItem(TOKEN_KEY, JSON.stringify({
            token: token,
            expires: expires
        }));
    }

    public saveToken(token: string, expires: number): void {
        Token.saveToken(token, expires);
    }

    /**
     * Get token from localStorage
     *
     * @returns {string}
     */
    public static getToken(): string {
        const value = localStorage.getItem(TOKEN_KEY);
        const isNeedLogout = Token.isExpired() && location.href.indexOf('/login') === -1;

        if (isNeedLogout) {
            // todo refreshToken CustomEvents.updateToken();
            Token.deleteToken();
            location.href = document.location.origin + '/login';

            return;
        }

        if (value) {
            return JSON.parse(value)['token'];
        } else {
            return null;
        }
    }

    public static deleteToken(): void {
        localStorage.removeItem(TOKEN_KEY);
    }

    public static isExpired(): boolean {
        const expires = Token.getExpires();
        const time = Math.floor(new Date().getTime() / 1000);

        return (time >= expires);
    }

    public getToken(): string {
        return Token.getToken();
    }

    /**
     * Get expires from localStorage
     *
     * @returns {number}
     */
    public getExpires(): number {
        return Token.getExpires();
    }

    public static getExpires(): number {
        const value = localStorage.getItem(TOKEN_KEY);

        if (value) {
            return JSON.parse(value)['expires'];
        } else {
            return null;
        }
    }

    public addTime(): void {
        const self = this;

        if (this.isResetPoint()) {
            this.destroyTimer();
            this.resetToken();
        }

        if (this.enableTimer) {
            this.setTime();

            setTimeout(function () {
                self.addTime();
            }, 3000);
        }
    }

    public setTime(): void {
        this.time = Math.floor(new Date().getTime() / 1000);
    }

    public startTimer(): void {
        this.doEnableTimer();

        if (this.getToken()) {
            this.addTime();
        }
    }

    public destroyTimer(): void {
        this.enableTimer = false;
    }

    public doEnableTimer(): void {
        this.enableTimer = true;
    }

    public isResetPoint(): boolean {
        return (this.time >= (this.getExpires() - 300));
    }

    public isDeadPoint(): boolean {
        return (this.time >= this.getExpires());
    }

    public disableResetToken(): void {
        this._enableResetToken = false;
    }

    public enableResetToken(): void {
        this._enableResetToken = true;
    }

    public resetToken(): void {
        if (!this._enableResetToken) {
            return;
        }

        this.updateToken();
    }

    @HostListener(`document:${CustomEvents.UPDATE_TOKEN}`)
    updateToken() {
        this.http.get(environment.apiUrl + '/api/users/update-token', Helpers.getBaseHttpHeaders(Token.getToken())).subscribe(res => {
            CustomEvents.successUpdateToken(res);

            this.saveToken(res['data'].token, res['data'].expires);
            this.startTimer();
        }, error => {
            CustomEvents.failedUpdateToken(error);

            this.dialog.closeAll();
            CustomEvents.logout();
        });
    }
}
