import AbstractModel from '~/src/app/core/model.abstract';
import {
    IScheduleRule,
    IScheduleRulePresetResponse
} from '~/src/app/modules/autofeed/interfaces/schedule-rule.interface';

export class ScheduleRulePresetModel extends AbstractModel<IScheduleRulePresetResponse> implements IScheduleRulePresetResponse, IScheduleRule {
    presetID: number;
    name: string;
    settings: IScheduleRule;
    categoryID: number;
    categoryName: string;
    hour: number;
    minute: number;
    period: string;
    createDate: string;
    organizationID: number[];

    constructor(json: IScheduleRulePresetResponse) {
        super(json);
        this.parse(json);

        if (typeof this.settings === 'string') {
            this.settings = JSON.parse(this.settings);
        }

        this.categoryID = this.settings?.categoryID;
        this.categoryName = this.settings?.categoryName;
        this.hour = this.settings?.hour;
        this.minute = this.settings?.minute;
        this.period = this.settings?.period;
        this.organizationID = (this.organizationID || []).map(orgID => Number(orgID));
    }

    getTime(): string {
        return `${this.hour.toString().padStart(2, '0')}:${this.minute.toString().padStart(2, '0')}`;
    }
}
