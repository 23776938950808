import { debounceTime } from "rxjs/operators";
import {
    AfterViewInit,
    Component,
    ComponentRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    Input,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FileBrowserService } from "../../services/file-browser.service";
import { SmdFile, SmdFileInterface } from "../../services/file.class";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { DialogConfirmComponent } from "../../components/dialog-confirm/dialog-confirm.component";
import { FileBrowserAbstract } from "../../services/file-browser.abstract";
import { DialogLoaderComponent } from "../../components/dialog-loader/dialog-loader.component";
import { Debounce, Helpers } from "../../services/helpers";
import { OrganizationService } from "../../components/organization-select/organization.service";
import { OrganizationComponent } from "../../components/organization-select/organization.component";
import { Icons } from "../../helpers/icons";
import { UploadMediaComponent } from "../../components/upload-media/upload-media.component";
import { CategoriesComponent } from "../posts/categories.component";
import { CategoriesService } from "../posts/categories.service";
import { RightSideBarComponent } from "../../components/right-side-bar/right-side-bar.component";
import { MainComponent } from "../main/main.component";
import { ComponentInjector } from "../../services/component.injector";
import {
    CarouselComponent,
    CarouselController,
} from "../posts/carousel/carousel.component";
import { CarouselService } from "../posts/carousel/carousel.service";
import { DialogSuccessComponent } from "../../components/dialog-success/dialog-success.component";
import { LanguageService } from "../../services/language.service";
import { StateSaver } from "../../services/state-saver";
import { PaginationController } from "../../services/pagination.controller";
import { Configs, FALLBACK_IMAGE } from "../../configs/configs";
import { NgForm } from "~/node_modules/@angular/forms";
import { BASE_DATETIME_FORMAT } from "~/src/app/configs/configs";
import * as moment from "moment";
import { MediaTagsController } from "~/src/app/modules/documents/media-tags.controller";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PostManagerComponent } from "~/src/app/modules/social-media-post/post-manager/post-manager.component";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import Utils from "~/src/app/core/utils";
import { ActivatedRoute } from "@angular/router";
import { PostTemplateSystemTypesForSelect } from "~/src/app/modules/social-media-post/social-media-post.options";
import { PartnerChooserComponent } from "~/src/app/modules/administration/partners/partner-chooser/partner-chooser.component";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { CoreConfig } from "~/src/app/core/core.config";
import EditorControllerService from "~/src/app/components/image-editor/editor-controller.service";
import { HttpEventType } from "@angular/common/http";
import { DialogProgressComponent } from "~/src/app/components/dialog-progress/dialog-progress.component";
import { Gallery, GalleryRef, VideoItem } from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { socialSiteAppearance } from "../social-media-post/social-media-platforms-config";
import { CollectionListConfig, CollectionListSelectionChange, TableBodyConfig, TableHeadConfig } from "../../core/components/collection-list/collection-list.interfaces";
import { DataTableHelpersService } from "../../core/services/data-table-helpers.service";
import { KeyValue } from "@angular/common";

declare var $;

@Component({
    selector: "app-documents",
    templateUrl: "./documents.component.html",
    styleUrls: ["./documents.component.scss"],
    providers: [DataTableHelpersService, ComponentHelpers],
})
export class DocumentsComponent
    extends FileBrowserAbstract
    implements OnInit, AfterViewInit, OnDestroy
{
    readonly defaultOrder = "createDate-desc";

    // components
    _organizationComponent: OrganizationComponent;
    _categoriesComponent: CategoriesComponent;
    _carouselComponent: CarouselComponent;

    @ViewChild("actionSelect", { static: true }) actionSelect: MatSelect;
    @ViewChild("documentFilterResetElement", { static: true })
    documentFilterResetElement: NgForm;
    @Input("loadedFrom") loadedFrom = null;

    /**
     * View chooser settings
     *
     * @type {{list: boolean; card: boolean}}
     */
    viewChooserSettings: object = {
        list: true,
        card: false,
    };
    /**
     * All media entity
     *
     * @type {any[]}
     */
    medias: SmdFileInterface[] = [];
    /**
     * Selected medias for action
     *
     * @type {any[]}
     */
    selectedMedias: SmdFileInterface[] = [];
    /**
     * Gallery ref.
     */
    imageGalleryRef: GalleryRef;
    videoGalleryRef: GalleryRef;
    carouselGalleryRef: GalleryRef;
    /**
     * Gallery ID
     *
     * @type {string}
     */
    imageGalleryID = "imageGallery";
    videoGalleryID = "videoGallery";
    carouselGalleryID = "videoGallery";
    /**
     * Image index in gallery
     *
     * @type {{}}
     */
    imageIndex: object = {};
    videoIndex: object = {};
    carouselIndex: object = {};
    images = [];
    checkedImages = 0;
    _dialogRef: MatDialogRef<any>;
    _loader: MatDialogRef<DialogLoaderComponent>;
    organizations = [];
    categories = [];
    _openedComponent: ComponentRef<any>;
    _stateManager = null;
    documentsPaginatorController: PaginationController =
        new PaginationController();
    enableGetPostsByFilterChange = true;
    socialTypes = Configs.socials;

    filterForm = new FormGroup({
        searchNameCtrl: new FormControl(""),
        organizationCtrl: new FormControl(""),
        typeCtrl: new FormControl(""),
        categoryCtrl: new FormControl(""),
        tagsCtrl: new FormControl(""),
        orderbyCtrl: new FormControl(this.defaultOrder),
        socialTypes: new FormControl(""),
        systemTypes: new FormControl(""),
    });
    hasMediaTag = false;
    hasMediaCategory = false;
    postTemplateSystemTypes = [];
    isAdminMode = !!Utils.lodash.get(
        this.route,
        "snapshot.data.isAdmin",
        false
    );
    private subs = [];

    collectionConfig: CollectionListConfig<SmdFileInterface> = {
        allowSelection: (item) => {
            return (
                (item.allowedActions?.includes('DELETE') && item.systemType !== CoreConfig.getSystemTypes().Generic) ||
                this.isAdminMode
            );
        },
    };

    // Column data for select
    columnVisibility: any = {
        mediaID: {
            name: "documents.id",
            visible: true,
        },
        medias: {
            name: "documents.preview",
            visible: true,
        },
        name: {
            name: "documents.name",
            visible: true,
        },
        tags: {
            name: "documents.tags",
            visible: true,
        },
        uploader: {
            name: "documents.uploader",
            visible: true,
        },
        sharedWithOrg: {
            name: "documents.shared.with.org",
            visible: true,
        },
        createdDate: {
            name: "documents.upload.time",
            visible: true,
        },
        lastModified: {
            name: "documents.last.modified",
            visible: true,
        },
        type: {
            name: "documents.type",
            visible: true,
        },
        socialType: {
            name: "documents.social.type",
            visible: true,
        },
    };
    defaultColumns: any[] = Object.keys(this.columnVisibility);
    
    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
    }

    tableHeadConfig: TableHeadConfig[] = [
        { 
            nameKey: "documents.id", 
            onlyDesktop: true, 
            onlyMobile: true,
            enable: () => this.columnVisibility["mediaID"].visible, 
        },
        { 
            nameKey: "documents.preview", 
            onlyDesktop: true, 
            onlyMobile: true,
            enable: () => this.columnVisibility["medias"].visible, 
        },
        { 
            nameKey: "documents.name",
            enable: () => this.columnVisibility["name"].visible,
        },
        {
            nameKey: "documents.tags",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["tags"].visible,
        },
        {
            nameKey: "documents.uploader",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["uploader"].visible,
        },
        {
            nameKey: "documents.shared.with.org",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["sharedWithOrg"].visible,
        },
        {
            nameKey: "documents.upload.time",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["createdDate"].visible,
        },
        {
            nameKey: "documents.last.modified",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["lastModified"].visible, 
        },
        { 
            nameKey: "documents.status", 
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => {
                return this.isAdminMode;
            },
        },
        {
            nameKey: "documents.type",
            onlyDesktop: true,
            onlyMobile: true,
            showMediaHelp: true,
            enable: () => this.columnVisibility["type"].visible, 
        },
        {
            nameKey: "documents.social.type",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["socialType"].visible, 
        },
        {
            nameKey: "documents.systemType",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => {
                return this.isAdminMode;
            },
        },
        { 
            nameKey: "documents.actions", 
            onlyDesktop: true
        },
    ];
    tableBodyConfig: TableBodyConfig[] = [
        {
            bindValue: "mediaID",
            onlyDesktop: true,
            enable: () => this.columnVisibility["mediaID"].visible,
        },
        {
            selector: "imageView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["medias"].visible,
        },
        {
            bindValue: "name",
            enable: () => this.columnVisibility["name"].visible,
        },
        {
            selector: "tagsView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["tags"].visible,
        },
        {
            selector: "uploader",
            onlyDesktop: true,
            enable: () => this.columnVisibility["uploader"].visible,
        },
        {
            selector: "sharedOrganizationView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["sharedWithOrg"].visible,
        },
        {
            bindValue: "createDate",
            dateFormat: true,
            onlyDesktop: true,
            enable: () => this.columnVisibility["createdDate"].visible,
        },
        {
            bindValue: "modifyDate",
            dateFormat: true,
            onlyDesktop: true,
            enable: () => this.columnVisibility["lastModified"].visible,
        },
        { 
            bindValue: "statusMessage",
            selector: "statusView",
            onlyDesktop: true,
            enable: () => {
                return this.isAdminMode;
            },
        },
        {
            selector: "type",
            onlyDesktop: true,
            enable: () => this.columnVisibility["type"].visible,
        },
        {
            selector: "socialTypeView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["socialType"].visible,
        },
        {
            bindValue: "systemTypeMessage",
            onlyDesktop: true,
            enable: () => {
                return this.isAdminMode;
            },
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
        },
    ];

    constructor(
        public service: FileBrowserService,
        public gallery: Gallery,
        public dialog: MatDialog,
        private _organizationService: OrganizationService,
        private _categoriesService: CategoriesService,
        @Inject(MainComponent) private mainComponentRef: MainComponent,
        private componentInjector: ComponentInjector,
        private carouselService: CarouselService,
        carouselController: CarouselController,
        public lightBox: Lightbox,
        private mediaTagsController: MediaTagsController,
        private openModal: OpenModalService,
        private partnerConfig: PartnerConfigService,
        private route: ActivatedRoute,
        private componentHelpers: ComponentHelpers,
        public dataTableHelper: DataTableHelpersService,
    ) {
        super(service, carouselController);

        const exceptSystemType = this.isAdminMode
            ? CoreConfig.getSystemTypes().Custom
            : CoreConfig.getSystemTypes().Generic;

        this.postTemplateSystemTypes = Utils.lodash
            .cloneDeep(PostTemplateSystemTypesForSelect)
            .filter((type) => type.id !== exceptSystemType);

        if (this.isAdminMode) {
            this.filters["isAdmin"] = "yes";
        }

        this.filterForm
            .get("tagsCtrl")
            .valueChanges.pipe(debounceTime(350))
            .subscribe((value) => {
                this.refreshDocumentsByTagIds(value);
            });

        this.filterForm
            .get("categoryCtrl")
            .valueChanges.pipe(debounceTime(350))
            .subscribe((value) => {
                this.refreshDocumentsByCategoryIds(value);
            });

        this.hasMediaTag = this.partnerConfig.hasConfig(
            PartnerPermissions.MediaTag
        );
        this.hasMediaCategory = this.partnerConfig.hasConfig(
            PartnerPermissions.MediaCategory
        );
        this._carouselComponent = new CarouselComponent(this.carouselService);
    }

    ngAfterViewInit(): void {
        $(".collapse-activator")
            .on("collapse", function () {
                $(this)
                    .parent()
                    .find(".fa-angle-down")
                    .removeClass("fa-angle-down")
                    .addClass("fa-angle-up");
            })
            .on("document-mobile-collapse collapse.show", function () {
                $(this)
                    .parent()
                    .find(".fa-angle-up")
                    .removeClass("fa-angle-up")
                    .addClass("fa-angle-down");
            });
    }

    ngOnInit() {
        this.socialTypes = this.socialTypes.filter(
            (item) =>
                socialSiteAppearance?.[item.id]?.medialibrary !== false
        );

        /**
         * It fixes the following error:
         * Expression has changed after it was checked. Previous value: 'id: undefined'. Current value: 'id: mat-dialog-0'.
         */
        setTimeout(() => {
            const sub1 =
                this.documentsPaginatorController.onPaginationChange.subscribe(
                    () => {
                        this.onPaginateChange();
                    }
                );
            const sub2 =
                this.documentsPaginatorController.onSetPagination.subscribe(
                    (options) => {
                        this.filters["offset"] = options.offset;
                        this.filters["limit"] = options.limit;

                        if (this.enableGetPostsByFilterChange) {
                            this.getItems();
                        }
                    }
                );

            if (!this.isAdminMode) {
                this.organizationInit();
            }

            if (this.hasMediaCategory && !this.isAdminMode) {
                this.categoryInit();
            }

            /**
             * Define gallery
             *
             * @type {GalleryRef}
             */
            this.imageGalleryRef = this.gallery.ref(this.imageGalleryID);
            this.imageGalleryRef.setConfig({
                loadingStrategy: "lazy",
            });

            this.videoGalleryRef = this.gallery.ref(this.videoGalleryID);

            this.carouselGalleryRef = this.gallery.ref(this.carouselGalleryID);

            this._stateManager = new StateSaver();
            this._stateManager.getState("viewChooser");

            const getSaved =
                this._stateManager.getPagionationState("documentPagination");

            if (getSaved && !this.isAdminMode) {
                this.documentsPaginatorController.setPagination(getSaved);
            } else {
                this.getItems();
            }

            this.subs.push(sub1, sub2);
        });

        this.getOrganizations();
    }

    ngOnDestroy() {
        $(".collapse-activator").off();
        this.subs.forEach((sub) => sub.unsubscribe());
    }

    socialTypesChange($event) {
        this.filters["socialChannels"] = $event;
        this.getItems();
    }

    async getOrganizations() {
        this.organizations = (await this._organizationService.getAll()).organizations;
    }

    getOrganizationNameFromID(organizationID) {
        return this.organizations?.find(
            (organization) => organization.organizationID == organizationID
        )?.name || organizationID;
    };

    getNonMainOrganizations(media) {
        return media.organizationIDs.filter(orgId => orgId != media.organizationID && this.organizations.some(org => org.organizationID == orgId));
    }

    getTooltipForOrganizationSharing(media) {
        if (media) {
            const organizationIDs = this.getNonMainOrganizations(media);
            const organizationNames = [];

            for (const organizationID of organizationIDs.slice(9, organizationIDs.length)) {
                organizationNames.push(
                    this.getOrganizationNameFromID(organizationID)
                );
            }

            return organizationNames.join(", ");
        }

        return "";
    }

    /**
     * @description for matSelects
     * @param select
     * @param dataArray
     */
    selectAllOrganization(select: FormControl, dataArray, modelValueProperty) {
        const selectedValues = dataArray.map(
            (data) => data[modelValueProperty]
        );

        select.setValue(selectedValues);
        this.refreshDocumentByOrganizationIDs(selectedValues);
    }

    deselectAllOrganization(socialSiteSelect) {
        socialSiteSelect.setValue([]);
        this.refreshDocumentByOrganizationIDs([]);
    }

    openSideBar(ev, media: SmdFileInterface) {
        ev.preventDefault();

        if (this._openedComponent) {
            this._openedComponent.destroy();
        }

        this._openedComponent = this.componentInjector.insertComponent(
            this.mainComponentRef.rightSideBar,
            RightSideBarComponent
        );

        (<RightSideBarComponent>this._openedComponent.instance).media = media;
        (<RightSideBarComponent>this._openedComponent.instance).elementRef =
            this._openedComponent;
    }

    beforeGetItems(): void {
        super.beforeGetItems();

        if (!this._loader) {
            this._loader = this.openModal.loader(DialogLoaderComponent);

            this._loader.afterClosed().subscribe(() => {
                this._loader = null;
            });
        }
    }

    openVideoByMedia(media) {
        const videoItem = new VideoItem({
            src: [
                {
                    url: media.url,
                    type: "video/mp4",
                },
            ] as any,
            thumb: media.thumb,
        });
        this.videoGalleryRef = this.gallery.ref(this.videoGalleryID);
        this.videoGalleryRef.load([videoItem]);

        this.lightBox.open(0, this.videoGalleryID, {
            panelClass: "fullscreen",
        });
    }

    /**
     * Success getItems event
     *
     * @param response
     */
    successGetItems(response: any): void {
        this.initFiles(response, this.isAdminMode).then(
            ({ medias }: { medias: SmdFileInterface[] }) => {
                this.medias = medias;
                this.documentsPaginatorController.paginationOptions.length =
                    response.count;
                this.checkedImages = 0;
                this.enableGetPostsByFilterChange = true;
                this.selectedMedias = [];

                let imageCounter = 0,
                    videoCounter = 0,
                    carouselCounter = 0;

                const addMediaToGallery = (media, index) => {
                    switch (media.type) {
                        case FILE_TYPES.IMAGE:
                            this.imageIndex[index] = imageCounter;

                            imageCounter++;

                            this.images.push(media);

                            break;

                        case FILE_TYPES.VIDEO:
                            this.videoIndex[index] = videoCounter;

                            videoCounter++;

                            this.videoGalleryRef.addVideo({
                                src: media.url,
                                title: media.name,
                                thumb: media.thumb,
                            });

                            break;

                        case FILE_TYPES.CAROUSEL:
                            this.carouselIndex[index] = carouselCounter;

                            carouselCounter++;

                            this.carouselGalleryRef.addImage({
                                src: media.url,
                                title: media.name,
                                thumb: media.galleryThumb,
                            });

                            break;

                        default:
                            this.imageIndex[index] = null;
                            this.videoIndex[index] = null;
                            break;
                    }
                };

                this.imageGalleryRef.reset();
                this.videoGalleryRef.reset();

                for (const index in this.medias) {
                    const media = this.medias[index];

                    addMediaToGallery(media, index);
                }

                if (this._loader) {
                    this._loader.close();
                }
            }
        );
    }

    failedGetItems(error: any): void {
        super.failedGetItems(error);

        this._loader.close();
    }

    organizationInit() {
        if (!this._organizationComponent) {
            this._organizationComponent = new OrganizationComponent(
                this._organizationService
            );
        }

        this._organizationComponent.getItems((response) => {
            this.organizations = response.organizations;
        });
    }

    categoryInit() {
        if (!this._categoriesComponent) {
            this._categoriesComponent = new CategoriesComponent(
                this._categoriesService
            );
        }

        this._categoriesComponent.getItems((response) => {
            this.categories = Helpers.orderBy(response.categories, "name");
        });
    }

    isMixedSelectionBySystemType(): boolean {
        const types: string[] = [];

        if (this.selectedMedias.length === 0) {
            return false;
        }

        for (const media of this.selectedMedias) {
            if (types.findIndex((type) => type === media.systemType) === -1) {
                types.push(media.systemType);
            }
        }

        return types.length > 1;
    }

    isActiveMedia(media: SmdFileInterface) {
        return media.status === "active";
    }

    /**
     * Select action
     *
     * @param event
     */
    selectAction(
        event: MouseEvent,
        value: "delete" | "setPartners" | "activate" | "suspend",
        media: SmdFileInterface = null
    ): void {
        if (!!event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (
            (value !== undefined && this.dataTableHelper.getSelectedItems().length > 0) ||
            !!media
        ) {
            switch (value) {
                case "suspend":
                    this.setStatus("inactive", media);

                    break;

                case "activate":
                    this.setStatus("active", media);

                    break;

                case "setPartners":
                    this.setPartners(media);

                    break;

                case "delete":
                    this.deleteMedias();

                    break;
            }
        }
    }

    private editImage(media: SmdFileInterface) {
        (media.editorMetaFile
            ? this.service.getEditorMeta(media.editorMetaFile)
            : Promise.resolve(media.editorMeta)
        ).then((response) => {
            media.editorMeta = response;
            EditorControllerService.open({
                media: media,
                callback: this.saveEditedImage.bind(this),
            });
        });
    }

    private saveEditedImage(request) {
        const progress: MatDialogRef<DialogProgressComponent> =
            this.dialog.open(DialogProgressComponent, {
                data: {
                    title: LanguageService.getLine("documents.media.upload"),
                },
                disableClose: true,
            });

        this.service
            .replaceFile({
                file: request.file,
                editorMeta: request.editorMeta,
                mediaID: request.mediaID,
            })
            .subscribe(
                (response) => {
                    if (response["type"] === HttpEventType.UploadProgress) {
                        progress.componentInstance.progressValue++;
                    }

                    if (response["type"] === HttpEventType.Response) {
                        progress.close();
                        this.getItems();
                    }
                },
                (error) => {
                    progress.close();
                    this.service.showErrorAlert(error);
                }
            );
    }

    /**
     * Set media(s) status
     * @param status
     */
    private setStatus(status: "active" | "inactive", media?: SmdFileInterface) {
        const successMessageKeys = {
            active: "mediaList.multiAction.activate.success",
            inactive: "mediaList.multiAction.suspend.success",
        };
        const errorMessageKeys = {
            active: "mediaList.multiAction.activate.error",
            inactive: "mediaList.multiAction.suspend.error",
        };
        const loader = this.openModal.loader(DialogLoaderComponent);
        const mediaIDs = !!media
            ? [media.mediaID]
            : this.dataTableHelper.getSelectedItems().map((item) => item.mediaID);

        this.service
            .setStatus(mediaIDs, status)
            .then((response) => {
                loader.afterClosed().subscribe(() => {
                    this.componentHelpers
                        .apiAction("success", response, {
                            messageKey: successMessageKeys[status],
                        })
                        .then(() => {
                            this.getItems();
                        });
                });
                loader.close();
            })
            .catch((error) => {
                loader.afterClosed().subscribe(() => {
                    this.componentHelpers.apiAction("failed", error, {
                        messageKey: errorMessageKeys[status],
                    });
                });
                loader.close();
            });
    }

    /**
     * Set partners
     */
    private setPartners(media?: SmdFileInterface) {
        this.openModal.setPartners(PartnerChooserComponent, {
            data: {
                medias: !!media ? [media] : this.dataTableHelper.getSelectedItems(),
                afterSuccessSave: () => this.getItems(),
            },
        });
    }

    /**
     * Delete selected medias
     */
    private deleteMedias() {
        const confirmRef = this.dialog.open(DialogConfirmComponent, {
            data: {
                message: LanguageService.getLine(
                    "media.delete.confirm.message",
                    { itemCount: this.dataTableHelper.selectedItems.length }
                ),
                yesButtonText: LanguageService.getLine("media.delete.confirm"),
            },
        });

        confirmRef.afterClosed().subscribe((result) => {
            if (result) {
                const loader = this.openModal.loader(DialogLoaderComponent);

                this.deleteFiles(
                    this.dataTableHelper.selectedItems,
                    (response) => {
                        loader.afterClosed().subscribe(() => {
                            this.dialog
                                .open(DialogSuccessComponent, {
                                    data: {
                                        message: LanguageService.getLine(
                                            "media.delete.success.message"
                                        ),
                                    },
                                })
                                .afterClosed()
                                .subscribe(() => {
                                    this.getItems();
                                    //this.dataTableHelper.clearSelection();
                                });
                        });
                        
                        loader.close();
                        this.dataTableHelper.clearSelection();
                    },
                    (error) => {
                        loader.afterClosed().subscribe(() => {
                            this.service.showErrorAlert(error);
                        });

                        loader.close();
                    }
                );
            }
        });
    }

    /**
     * Select medias for action
     *
     * @param {MouseEvent} $event
     * @param {SmdFileInterface} media
     */
    selectItemForAction($event: MouseEvent, media: SmdFileInterface): void {
        const indexInSelectedMedias = this.selectedMedias.findIndex((value) => {
            return SmdFile.isFilesEqual(media, value);
        });

        if (indexInSelectedMedias === -1) {
            this.selectedMedias.push(media);
        } else {
            this.selectedMedias.splice(indexInSelectedMedias, 1);
        }
    }

    /**
     * Select all media for action
     *
     * @param {MouseEvent} event
     */
    selectAllItemForAction(event: MouseEvent): void {
        if (this.medias.length > this.selectedMedias.length) {
            for (const index in this.medias) {
                const media = this.medias[index],
                    indexInSelectedMedias = this.selectedMedias.findIndex(
                        (value) => {
                            return SmdFile.isFilesEqual(media, value);
                        }
                    );

                if (indexInSelectedMedias === -1) {
                    this.selectedMedias.push(media);
                }
            }
        } else {
            this.selectedMedias = [];
        }
    }

    /**
     * Pagination change event
     * @param event
     */
    @Debounce()
    onPaginateChange(): void {
        if (!this.isAdminMode) {
            this._stateManager.setPaginationState(
                "documentPagination",
                this.documentsPaginatorController.paginationOptions
            );
        }
    }

    /**
     * Choose view event by click
     *
     * @param {MouseEvent} event
     * @param {string} choosedItem
     */
    viewChooser(event: MouseEvent, choosedItem: string): void {
        for (const key in this.viewChooserSettings) {
            this.viewChooserSettings[key] = false;
        }

        this.viewChooserSettings[choosedItem] = true;
    }

    editFileClick(media: SmdFileInterface) {
        if (media.type === FILE_TYPES.CAROUSEL) {
            this.openUploadMediaComponent(media, true);

            return;
        }

        this.openUploadMediaComponent(media, false);
    }

    editFileCanvaClick(media: SmdFileInterface) {
        this.openUploadMediaComponent(media, false, true);
    }

    deleteFile(media: SmdFileInterface) {
        if (media.type === FILE_TYPES.CAROUSEL) {
            this.dialog
                .open(DialogConfirmComponent, {
                    data: {
                        message: LanguageService.getLine(
                            "carousel.confirm.delete.message"
                        ),
                        yesButtonText: LanguageService.getLine(
                            "carousel.confirm.delete"
                        ),
                    },
                })
                .afterClosed()
                .subscribe((doIt) => {
                    if (doIt) {
                        const _loader = this.openModal.loader(
                            DialogLoaderComponent
                        );

                        _loader.afterClosed().subscribe((isSuccess) => {
                            if (isSuccess) {
                                this.getItems();
                            }
                        });

                        this._carouselComponent
                            .deleteCarouselByID(media.mediaID)
                            .then(
                                (response) => {
                                    _loader.afterClosed().subscribe(() => {
                                        this.dialog.open(
                                            DialogSuccessComponent,
                                            {
                                                data: {
                                                    message:
                                                        LanguageService.getLine(
                                                            "media.delete.success.message"
                                                        ),
                                                },
                                            }
                                        );
                                    });
                                    _loader.close(true);
                                },
                                (error) => {
                                    _loader.afterClosed().subscribe(() => {
                                        this.service.showErrorAlert(error);
                                    });
                                    _loader.close(false);
                                }
                            );
                    }
                });

            return;
        }

        this.dialog
            .open(DialogConfirmComponent, {
                data: {
                    message: LanguageService.getLine(
                        "media.confirm.delete.message"
                    ),
                    yesButtonText: LanguageService.getLine(
                        "media.confirm.delete"
                    ),
                },
            })
            .afterClosed()
            .subscribe((doIt) => {
                if (doIt) {
                    const _loader = this.openModal.loader(
                        DialogLoaderComponent
                    );

                    this.deleteFiles(
                        [media],
                        (response) => {
                            _loader.afterClosed().subscribe(() => {
                                this.dialog
                                    .open(DialogSuccessComponent, {
                                        data: {
                                            message: LanguageService.getLine(
                                                "media.delete.success.message"
                                            ),
                                        },
                                    })
                                    .afterClosed()
                                    .subscribe(() => {
                                        this.getItems();
                                        this.dataTableHelper.clearSelection();
                                    });
                            });

                            _loader.close();
                        },
                        (error) => {
                            _loader.afterClosed().subscribe(() => {
                                this.service.showErrorAlert(error);
                            });

                            _loader.close();
                        }
                    );
                }
            });
    }

    @Debounce(900)
    setSearchValue(event) {
        this.setPagination(0);

        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "searchValue",
                filterValue: event.target.value,
            },
        ]);
    }

    @Debounce(250)
    setOrganization(event) {
        const organizationIDs = event.value;

        this.refreshDocumentByOrganizationIDs(organizationIDs);
    }

    @Debounce(250)
    setType(event, reference) {
        this._stateManager.insertFormStorage(reference, event.value);

        this.setPagination(0);

        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "type",
                filterValue: event.value,
            },
        ]);
    }

    @Debounce(250)
    setSystemType(event: MatSelectChange) {
        this.setPagination(0);

        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "systemType",
                filterValue: JSON.stringify(event.value),
            },
        ]);
    }

    setOrder(event) {
        this.setPagination(0);

        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "order",
                filterValue: this.getOrderFormat(event.value) || "",
            },
        ]);
    }

    mediaUpload() {
        this.openUploadMediaComponent();
    }

    canvaClick() {
        this.openUploadMediaComponent(null, null, true);
    }

    openUploadMediaComponent(
        media?: SmdFileInterface,
        isCarousel?: boolean,
        isCanva?: boolean
    ) {
        const dataKeyName: string = isCarousel ? "carousel" : "media";

        this.dialog
            .open(UploadMediaComponent, {
                data: {
                    [dataKeyName]: media || null,
                    availableMediaLength: this.medias.length,
                    isAdminMode: this.isAdminMode,
                    isCanva: isCanva || null,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((wasCreate) => {
                if (wasCreate) {
                    this.getItems();
                }
            });
    }

    reset(event) {
        event.preventDefault();
        this.filterForm.reset(
            {
                orderbyCtrl: this.defaultOrder,
            },
            { emitEvent: false }
        );
        this.filters = {
            order: Helpers.getOrderFormat(this.defaultOrder),
        };

        if (this.isAdminMode) {
            this.filters["isAdmin"] = "yes";
        }

        this.filtersChange({});
    }

    createPostFromMedia(media) {
        const fullDate = moment().format(BASE_DATETIME_FORMAT);

        this.openModal.createPost(PostManagerComponent, {
            data: {
                scheduleDate: fullDate,
                initialMedias: [media],
            },
        });
    }

    private refreshDocumentByOrganizationIDs(organizationIDs: Array<number>) {
        const reference = "docFilterOrganization";

        this._stateManager.insertFormStorage(reference, organizationIDs);
        this.setPagination(0);
        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "organizationID",
                filterValue: JSON.stringify(organizationIDs),
            },
        ]);
    }

    private refreshDocumentsByCategoryIds(categoryIDs) {
        this.setPagination(0);

        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "categories",
                filterValue: JSON.stringify(categoryIDs),
            },
        ]);
    }

    private refreshDocumentsByTagIds(tagIDs) {
        this.setPagination(0);

        this.documentsPaginatorController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByFilterChange = false;
        this.documentsPaginatorController.setPagination(
            this.documentsPaginatorController.paginationOptions
        );

        this.setFilters([
            {
                filterName: "tags",
                filterValue: JSON.stringify(tagIDs),
            },
        ]);
    }

    selectionChanges(event: CollectionListSelectionChange) {
        this.dataTableHelper.setSelectedItems(event.selectedItems, event.itemCollection, 'mediaID');
    }

    /**
     * Toggle table columns
     * @param event
     * */
    toggleColumns(event): void {
        Object.keys(this.columnVisibility).forEach((column) => {
            this.columnVisibility[column].visible =
                event.value.includes(column);
        });
    }

    /**
     * Insert tag to Keyword search on click
     * 
     * @param tag 
     */
    tagClick(tag: string) {
        let currentSearchValue = this.filterForm.get("searchNameCtrl").value.trim();

        let newSearchValue = "";
        if (currentSearchValue && currentSearchValue.includes(tag + ",")) {
            newSearchValue = currentSearchValue.replace(tag + ",", "");
        } else if (currentSearchValue && currentSearchValue.includes(tag)) {
            newSearchValue = currentSearchValue.replace(tag, "");
        } else if (currentSearchValue && currentSearchValue.charAt(currentSearchValue.length - 1) == ",") {
            newSearchValue = currentSearchValue + " " + tag + ", ";
        } else if (currentSearchValue) {
            newSearchValue = currentSearchValue + ", " + tag + ", ";
        } else {
            newSearchValue = tag + ", ";
        }

        this.filterForm.patchValue({
            searchNameCtrl: newSearchValue,
        });

        this.setFilters([
            {
                filterName: "searchValue",
                filterValue: newSearchValue,
            },
        ]);
    }

    getTooltipForMediaTags(media) {
        if (media) {
            const tags = media.tags;
            const tooltipTags = [];

            for (const tag of tags.slice(10, tags.length)) {
                tooltipTags.push(tag);
            }

            return tooltipTags.join(", ");
        }

        return "";
    }

    isActionAllowedForMedia(media: SmdFileInterface, action: string): boolean {
        return this.isAdminMode || media.allowedActions?.includes(action);
    }

    /**
     * Get all file types
     *
     * @return {FILE_TYPES}
     */
    get fileTypes() {
        return FILE_TYPES;
    }

    get icons() {
        return Icons;
    }

    get languageService() {
        return LanguageService;
    }

    get allImagesChecked(): boolean {
        return this.medias.length === this.checkedImages;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }
}
