import {ModelAbstract} from '../../../../services/model.abstract';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../../../services/helpers';
import {Token} from '../../../../services/token';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class CommentModel extends ModelAbstract {

    objectType: 'post' | 'postTemplate' = 'post';

    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/comments';
    }

    /**
     * Create post comment
     *
     * @param {number} postID
     * @param {string} comment
     * @return {Promise<any>}
     */
    createComment(postID: number, comment: string): Promise<any> {
        const data = {
            objectID: postID,
            objectType: this.objectType,
            comment: comment
        };

        return this.http.post(`${this.apiLink}`, Helpers.objectToFormData(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Get comments for post by postID
     *
     * @param {number} postID
     * @return {Promise<any>}
     */
    getComments(postID: number | number[], filterParams: object = {}): Promise<any> {
        let filters = {
            objectType: this.objectType,
            offset: 0,
            limit: 10
        };

        if (postID) {
            filters = Object.assign(filters, {
                objectID: postID
            });
        }

        const query = Helpers.getHttpQuery(Object.assign(filters, filterParams));

        return this.http.get(`${this.apiLink + query}`, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    getRecentComments(): Promise<any> {
        return this.http.get(`${this.apiLink}/most-recent-comments`, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
