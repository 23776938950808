import {Injectable} from '@angular/core';
import {ModelAbstract} from '../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {ComponentAbstract} from '../../services/component.abstract';
import {MatDialog} from '@angular/material/dialog';
import {Helpers} from '../../services/helpers';

export interface Organization {
    organizationID: number;
    name: string;
    parentID: number;
    partnerID: number;
}

@Injectable({providedIn: 'root'})
export class OrganizationService extends ModelAbstract {

    constructor(
        http: HttpClient,
        dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/organization';
    }

    getAll(filters?: object): Promise<any> {
        return super.getAll(filters).then(response => {
            response.organizations.map(organization => organization.main = Helpers.stringToBoolean(organization.main));

            return response;
        });
    }
}

@Injectable({providedIn: 'root'})
export class OrganizationController extends ComponentAbstract {
    isGetting = false;

    constructor(
        public service: OrganizationService,
        public dialog: MatDialog
    ) {
        super();
    }

    beforeGetItems(): void {
        super.beforeGetItems();

        this.isGetting = true;
    }

    afterGetItems(): void {
        super.afterGetItems();

        this.isGetting = false;
    }
}
