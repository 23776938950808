import {ModelAbstract} from '../../../services/model.abstract';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../../services/helpers';
import {Token} from '../../../services/token';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class PostTemplateModel extends ModelAbstract {

    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/post/template';
    }

    /**
     * Delete items by IDs
     *
     * @param {number[]} IDs
     * @return {Promise<any>}
     */
    deleteItems(IDs: object): Promise<any> {
        return this.http.post(this.apiLink + '/delete', Helpers.objectToFormData(IDs), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

}

/**
 * Bulk template upload example
 */
export interface BulkTemplateUploadSampleResponse {
    file: {
        name: string;       // file name
        size: number;       // file size in bytes
        mime: string;       // file mime type
        content: string;    // file in base64
    };
}
