import {NgModule} from '@angular/core';
import { ReportsHomeComponent } from './reports-home/reports-home.component';
import { MyReportsComponent } from './my-reports/my-reports.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportCardComponent } from './reports-home/report-card/report-card.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { LanguagesModule } from '../languages/languages.module';
import { ReportSettingsComponent } from './reports/report-settings/report-settings.component';
import { RouterModule } from '@angular/router';
import { ShareObjectComponent } from '../analytics/share-reports/share-object.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule,
        RouterModule
    ],
    declarations: [
        ReportsHomeComponent,
        MyReportsComponent,
        ReportsComponent,
        ReportCardComponent,
        ReportSettingsComponent,
        ShareObjectComponent
    ],
    entryComponents: [
        ReportSettingsComponent,
        ShareObjectComponent
    ]
})
export class DashboardsModule {}
