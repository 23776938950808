import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {NotifyService} from '../../services/notify.service';
import {FormValidationService} from '../../services/form.validation.service';
import {AvailableLoaders, PreloaderComponent} from '../../components/preloader/preloader.component';
import {LanguageService} from '../../services/language.service';
import {TwoFactorLogin} from '../../services/helpers';

declare const $;

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements AfterViewInit, OnInit {

    @ViewChild(PreloaderComponent, {static: true}) preLoader: PreloaderComponent;

    loginForm = new FormGroup({
        username: new FormControl('', [
            Validators.compose([
                Validators.required,
                Validators.minLength(3)
            ])
        ]),
        password: new FormControl('', [
            Validators.compose([
                Validators.required,
                Validators.minLength(3)
            ])
        ]),
        rememberme: new FormControl('' )
    });

    recoverForm = new FormGroup({
        username: new FormControl('', [
            Validators.compose([
                Validators.required,
                Validators.minLength(3)
            ])
        ])
    });

    verifyForm: FormGroup = new FormGroup({
        code: new FormControl('', [
            Validators.required
        ])
    });

    loginMessages: object = {
        username: '',
        password: ''
    };

    recoverMessages: object = {
        username: ''
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private auth: AuthService
    ) {
    }

    ngOnInit(): void {
        if (TwoFactorLogin.has()) {
            this.switchBox('verify');
        } else {
            this.switchBox('login');
        }
    }

    ngAfterViewInit() {
        this.preLoader.hide(AvailableLoaders.LOGIN_LOADER);
    }

    /**
     * Switch visible form
     * @param {"login" | "passwordRecover" | "verify" | "usernameRecover"} activeBox
     */
    switchBox(activeBox: 'login' | 'passwordRecover' | 'verify' | 'usernameRecover') {
        const selector = {
            login: '#loginform',
            passwordRecover: '#recoverform',
            verify: '#verifyForm',
            usernameRecover: '#usernameRecoverForm',
        }[activeBox];

        $('#loginform, #recoverform, #verifyForm, #usernameRecoverForm').each(function(index, box) {
            const $box = $(box);
            if ($box.is(':visible')) {
                $box.fadeOut(400, () => $(selector).fadeIn());
            }
        });
    }

    onLogin(event) {
        event.preventDefault();
        this.preLoader.show(AvailableLoaders.LOGIN_LOADER);

        const username = this.loginForm.value.username,
            password = this.loginForm.value.password,
            rememberme = this.loginForm.value.rememberme;

        const data = {};

        if (rememberme && rememberme !== '') {
            data['rememberMe'] = 'yes';
        }

        if ( this.loginForm.valid ) {
            this.auth.login(username, password, data).subscribe(
                (response) => {
                    this.preLoader.hide(AvailableLoaders.LOGIN_LOADER);

                    if (response['twoFactor']) {
                        TwoFactorLogin.save();
                        this.switchBox('verify');
                    }
                },
                error => {
                    const apiError = error.error.error;

                    this.preLoader.hide(AvailableLoaders.LOGIN_LOADER);

                    this.loginMessages = {};

                    NotifyService.error('', apiError.message);
                }
            );
        } else {
            const controls = this.loginForm.controls;

            this.preLoader.hide(AvailableLoaders.LOGIN_LOADER);

            this.loginMessages = FormValidationService.getMessages(controls);
        }
    }

    onPassRecovery(event) {
        event.preventDefault();

        const username = this.recoverForm.value.username;

        if ( this.recoverForm.valid ) {
            this.recoverMessages = {};

            this.auth.passwordRecovery(username).subscribe(response => {
                this.recoverForm.controls.username.setValue('');
                NotifyService.success(LanguageService.getLine('auth.password.recovery.title'), response['data']['message']);
            }, error => {
                NotifyService.error(LanguageService.getLine('auth.password.recovery.title'), error['error']['error']['message']);
            });
        } else {
            const controls = this.recoverForm.controls;

            this.recoverMessages = FormValidationService.getMessages(controls);
        }
    }

    onVerify(event) {
        event.preventDefault();

        if (this.verifyForm.valid) {
            this.auth.twoFactorVerify({'verificationCode': this.verifyForm.get('code').value}).subscribe((value) => {
                if (value.result === 'OK') {
                    TwoFactorLogin.clear();
                    this.router.navigateByUrl('/');
                }
            }, (error) => {
                NotifyService.error(LanguageService.getLine('auth.twofactorauth.title'), error['error']['error']['message']);
            });
        } else {
            const controls = this.verifyForm.controls;
            this.recoverMessages = FormValidationService.getMessages(controls);
        }
    }

    get languageService() {
        return LanguageService;
    }
}
