import {Injectable} from '@angular/core';
import obj2fd from 'obj2fd';
import {forEach, has} from 'lodash';
import {HttpClient} from '@angular/common/http';
import {environment} from '~/src/environments/environment';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import {PostActions, PostInterface} from '~/src/app/modules/posts/post-actions';
import {PostActionsService} from '~/src/app/modules/posts/post-actions.service';
import {MatDialog} from '@angular/material/dialog';
import {UsersController} from '~/src/app/modules/users/users/users.component';
import {CarouselController} from '~/src/app/modules/posts/carousel/carousel.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import Utils from '~/src/app/core/utils';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {MediaPostRequestKeys} from '~/src/app/modules/social-media-post/post-skeleton/post-skeleton.options';
import {PostSkeletonComponent} from '~/src/app/modules/social-media-post/post-skeleton/post-skeleton.component';
import {BASE_DATE_FORMAT} from '~/src/app/configs/configs';
import {Subject} from 'rxjs';
import {PostFormControlNames} from '~/src/app/modules/social-media-post/social-media-post.constant';
import {BackendService} from '~/src/app/core/backend.service';

/**
 * @todo Implement APIService
 */
@Injectable({providedIn: 'root'})
export class SocialMediaPostService {
    private apiLink = environment.apiUrl + '/api/post';
    private postActions: PostActions;

    postListRefresh$ = new Subject();

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private postActionsService: PostActionsService,
        private usersController: UsersController,
        private carouselController: CarouselController,
        private backendService: BackendService,
        private openModal: OpenModalService
    ) {
        this.postActions = this.createPostActionInstance();
    }

    getPosts(params: any = {}): Promise<{count: number, posts: PostInterface[]}> {
        const postActions = this.createPostActionInstance();
        postActions.filters = Object.assign(postActions.filters, params);
        return new Promise((resolve, reject) => postActions.getItems(resolve, reject));
    }

    /**
     * Posts Schedule
     * @param {any[]} posts
     * @param fromRss
     * @param fromMedia
     * @param templateID
     * @return {Promise<Object>}
     */
    postsSchedule(posts: any[], fromRss: boolean = false, fromMedia = false, templateID = null) {
        const data = {
            posts: posts
        };

        //  A postFromRSS számláló miatt szükséges ez a plusz kulcs, ha rss feed-ből posztolok
        if (fromRss) {
            data['postFromRss'] = 'yes';
        }

        if (fromMedia) {
            data['postFromMedia'] = 'yes';
        }

        if (!!templateID) {
            data['templateID'] = templateID;
        }

        return this.http.post(this.apiLink, obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }

    /**
     * Instant post
     * @param {any[]} posts
     * @param fromRss
     * @param fromMedia
     * @param templateID
     * @return {Promise<Object>}
     */
    instantPost(posts: any[], fromRss = false, fromMedia = false, templateID = null) {
        forEach(posts, (post, index) => {

            if (!post['instantPost']) {
                post['instantPost'] = 1;
            }

            posts[index] = {
                ...post,
                expiryPeriod: null,
                expiryDate: null
            };
        });

        return this.postsSchedule(posts, fromRss, fromMedia, templateID);
    }

    /**
     * Save post
     * @param {number} postID
     * @param {any[]} posts
     * @return {Promise<Object>}
     */
    savePost(postID: number, posts: any[]) {
        const data = {
            posts: posts
        };
        return this.http.post(this.apiLink + `/${postID}`, obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken())).toPromise()
            .then((response: any) => {
                if (has(response, 'post')) {
                    response.post = this.postActions.postsProcess([response.post]);
                }

                return response;
            });
    }

    /**
     * Set post status
     * @param {number[]} postIDs
     * @param {string} status
     * @return {Promise<any>}
     */
    setPostStatus(postIDs: number[], status: string): Promise<any> {
        const data = {
            postIDs: JSON.stringify(postIDs),
            status
        };
        return this.backendService.post('/post/status', data);
    }

    /**
     * Set post template status
     * @param {number[]} templateIDs
     * @param {string} status
     * @return {Promise<any>}
     */
    setPostTemplateStatus(templateIDs: number[], status: string): Promise<any> {
        const data = {
            postTemplateIDs: JSON.stringify(templateIDs),
            status
        };
        return this.backendService.post('/post/template/status', data);
    }

    /**
     * Share templates to organizations
     * @param templateIDs
     * @param organizationIDs
     */
     setPostTemplateOrganizations(templateIDs: number[], organizationIDs: number[], ignoredOrganizationIDs: number[]) {
        const data = {
            postTemplateIDs: JSON.stringify(templateIDs),
            organizationIDs: JSON.stringify(organizationIDs),
            ignoredOrganizationIDs: JSON.stringify(ignoredOrganizationIDs)
        };
        return this.backendService.post('/post/template/share', data);
    }

    /**
     * Prepare post object before send to backend
     * @param post
     */
    preparePostForRequest(post: PostTemplateInterface) {
        let result = Utils.lodash.cloneDeep(post);

        // Remove unavailable properties
        delete result['archive'];
        delete result['activeFromInput'];
        delete result['activeTo'];
        delete result['calendarDate'];
        delete result['isCopyable'];
        delete result['isDeleteable'];
        delete result['isEditable'];
        delete result['isRepeat'];
        delete result['mediaType'];
        delete result['organizationID'];
        delete result['parentID'];
        delete result['postID'];
        delete result['repeatIndex'];
        delete result['repeats'];
        delete result['socialSiteName'];
        delete result['socialSiteStatuses'];
        delete result['status'];
        delete result['statusMessage'];
        delete result['statuses'];
        delete result['templateID'];
        delete result['typeMessage'];
        delete result['userID'];

        // Set link share datas when post contains
        if (!!result['linkshare']) {
            result = {
                ...result,
                [PostFormControlNames.LinkShareTitle]: result['linkshare']['title'] || null,
                [PostFormControlNames.LinkShareDescription]: result['linkshare']['description'] || null,
                [PostFormControlNames.LinkShareImageURL]: result['linkshare']['image'] || null,
                [PostFormControlNames.LinkShareURL]: result['linkshare']['url'] || null,
            };
        }

        // Remove link share property from result
        delete result['linkshare'];

        // Get carousel OR file IDs
        const medias = (result['medias'] || []);
        const mediaIDs = {};
        const fileIDs = medias
            .filter(media => media.type !== FILE_TYPES.CAROUSEL)
            .map(media => media.mediaID);
        const carouselIDs = medias
            .filter(media => media.type === FILE_TYPES.CAROUSEL)
            .map(media => media.mediaID);

        // Add file IDs to result
        if (!!fileIDs.length) {
            mediaIDs[MediaPostRequestKeys.fileIDs] = fileIDs;
        }

        // Add carousel IDs to result
        if (!!carouselIDs.length) {
            mediaIDs[MediaPostRequestKeys.carouselIDs] = carouselIDs;
        }

        // Remove medias property from result
        delete result['medias'];

        result = {
            ...result,
            [PostFormControlNames.Categories]: (result['categories'] || []).map(category => category.name),
            ...mediaIDs,
            [PostFormControlNames.Organization]: (result['organizationIDs'] || []).map(organizationID => Number(organizationID)),
            [PostFormControlNames.SocialSite]: (result['socialSites'] || []).map(socialSite => Number(socialSite.socialSiteID)),
            [PostFormControlNames.Headline]: PostSkeletonComponent.postContentPrepareBeforeSend(result['headline'] || null),
            [PostFormControlNames.SubHeadline]: PostSkeletonComponent.postContentPrepareBeforeSend(result['subHeadline'] || null),
            [PostFormControlNames.Message]: PostSkeletonComponent.postContentPrepareBeforeSend(result['mainCopy'] || null),
            [PostFormControlNames.Signature]: PostSkeletonComponent.postContentPrepareBeforeSend(result['signature'] || null),
            [PostFormControlNames.ExpiryEndDate]: !!result['expiryDate']
                ? Utils.moment(result['expiryDate']).format(BASE_DATE_FORMAT)
                : null,
        };

        // Remove social site property from result
        delete result['socialSites'];

        return result;
    }

    private createPostActionInstance() {
        return new PostActions(this.postActionsService, this.dialog, this.usersController, this.carouselController, this.openModal);
    }
}
