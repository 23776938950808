import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {PixieComponent} from '~/src/app/components/pixie/pixie.component';
import {ImageBrowserComponent} from '~/src/app/components/image-browser/image-browser.component';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import EditorControllerService from './editor-controller.service';
import {get, merge} from 'lodash';
import Utils from '~/src/app/core/utils';

@Component({
    selector: 'smd-image-editor',
    templateUrl: './image-editor.component.html'
})
export class ImageEditorComponent implements OnInit, OnDestroy {
    private editorSubscription: Subscription;
    private browserSubscription: Subscription;

    constructor(private dialog: MatDialog) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = "100%";
        dialogConfig.height = "100%";

        this.editorSubscription = EditorControllerService.openEvent().subscribe(media => {
            const url = media.media.url || get(media.media, '_url');

            // change url to base64, if file not edited yet
            ( media.media.editorMetaFile === null ? Utils.openFileFromUrl(url).then(Utils.convertBlobToBase64) : Promise.resolve(url) ).then((response) => {
                dialogConfig.data = {...{media: merge(media, { media: { url: response } })}};

                const dialogRef = this.dialog.open(PixieComponent, dialogConfig);

                this.browserSubscription = EditorControllerService.openBrowserEvent().subscribe(toggle => {
                    this.dialog.open(ImageBrowserComponent);
                });

                dialogRef.afterClosed().subscribe(() => {
                    this.browserSubscription.unsubscribe();
                });
            });
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.editorSubscription.unsubscribe();
    }
}
