import AbstractPreviewAdapter from "~/src/app/modules/posts/post-previews/abstract-preview-adapter";
import { FacebookAdapter } from "~/src/app/modules/posts/post-previews/facebook-preview-adapter";

// !!!
// I don't think this is used. Check "getInstagramContent" in post-preview.component.ts
// !!!
class Adapter extends FacebookAdapter {
    adapt(value, isLinkShare = null): string {
        value = value || "";

        // !!!!!!!!!!!!!!!! ^

        return super.adapt(value);
    }
}

export const InstagramPreviewAdapter = new Adapter();
