import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {EMPTY_NEXT_SHARING, PostActions, PostInterface} from '../posts/post-actions';
import {PostActionsService} from '../posts/post-actions.service';
import {CategoriesComponent} from '../posts/categories.component';
import {CategoriesService} from '../posts/categories.service';
import {Category} from '../posts/template.interface';
import {SocialSiteInterface} from '../../components/social-site-select/social-site-select.component';
import {AutofeedApiComponent, FeedStatusSettingApiObject} from '../posts/autofeed/autofeed.component';
import {AutofeedService} from '../posts/autofeed/autofeed.service';
import {AutofeedService as NewAutoFeedService} from '~/src/app/modules/autofeed/autofeed.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {DialogLoaderComponent} from '../../components/dialog-loader/dialog-loader.component';
import {SocialSiteService} from '../../components/social-site-select/social-site.service';
import {SocialSiteComponent} from '../../components/social-site-select/social-site.component';
import {CurrentSocialSite, Debounce, Helpers, MyErrorStateMatcher} from '../../services/helpers';
import {DialogErrorComponent} from '../../components/dialog-error/dialog-error.component';
import {DialogFeedItemDeleteConfirmComponent} from './components/dialog-feed-item-delete-confirm/dialog-feed-item-delete-confirm.component';
import {FormValidationService} from '../../services/form.validation.service';
import {UsersController} from '../users/users/users.component';
import {Swappable} from '@shopify/draggable';
import {LanguageService} from '../../services/language.service';
import {StateSaver} from '../../services/state-saver';
import {CarouselController} from '../posts/carousel/carousel.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostManagerComponent} from '~/src/app/modules/social-media-post/post-manager/post-manager.component';
import Utils from '~/src/app/core/utils';
import {AutofeedPageService} from './autofeed-service.service';
import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
} from '~/src/app/core/constants';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';
import {ScheduleRulePresetChooserModalComponent} from '~/src/app/modules/autofeed/components/schedule-rule-preset-chooser-modal/schedule-rule-preset-chooser-modal.component';
import {ClickDecorator} from '~/src/app/core/decorators';
import {ScheduleRulePresetModel} from '~/src/app/modules/autofeed/models/schedule-rule-preset.model';
import {CategorySelectComponent} from '~/src/app/core/components/category-select/category-select.component';
import {AUTOFEED_RULE_PERIODS} from '~/src/app/modules/autofeed/autofeed.constants';
import {ScheduleRulesPresetListComponent} from '~/src/app/modules/autofeed/components/schedule-rules-preset-list/schedule-rules-preset-list.component';
import {NotifyService} from '~/src/app/services/notify.service';
import {DialogInfoComponent} from '~/src/app/components/dialog-info/dialog-info.component';
import { PostPreviewComponent } from '../posts/post-previews/post-preview/post-preview.component';
import { PostTemplateManagerComponent } from '../social-media-post/post-template-manager/post-template-manager.component';

declare var moment: any;

declare const $;

export interface AutoFeedItem {
    categoryID: number;
    days: string[];
    period?: string;
    postTimeID: number;
    settingID: number;
    time: string;
    multiple?: number;
    color?: string;
}

export const DAYS: object = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7
};

enum FeedStatus {
    ACTIVE = 'active',
    PAUSED = 'paused'
}

const NewPostTimeFormControlsName = {
    Period: 'period',
    Hour: 'hour',
    Minute: 'minute',
    Category: 'category',
    IsMultiple: 'isMultiple',
    MaxPostNumber: 'maxPostNumber',
    PresetName: 'presetName'
};

@Component({
    selector: 'smd-autofeed',
    templateUrl: './autofeed.component.html',
    styleUrls: ['./autofeed.component.scss']
})
export class AutofeedComponent extends PostActions implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('newPostTimeFormElement', {static: true}) newPostTimeFormElement: NgForm;
    @ViewChild('autoFeedFilterResetElement', {static: true}) autoFeedFilterResetElement: NgForm;

    @ViewChild('periodSelect', {static: true}) periodSelect: MatSelect;
    @ViewChild('hourInput', {read: MatSelect, static: true}) hourInput: MatSelect;
    @ViewChild('minuteInput', {read: MatSelect, static: true}) minuteInput: MatSelect;
    @ViewChild('allTime') allTime: ElementRef<HTMLInputElement>;
    @ViewChild('multipleCheckbox', {static: true}) multipleCheckboxInput: ElementRef<HTMLInputElement>;
    @ViewChild('multipleNumber', {static: true}) multipleNumberInput: ElementRef<HTMLInputElement>;
    @ViewChild(CategorySelectComponent, {read: CategorySelectComponent}) categorySelect: CategorySelectComponent;
    @ViewChild(ScheduleRulesPresetListComponent, {read: ScheduleRulesPresetListComponent}) rulePresetList: ScheduleRulesPresetListComponent;
    @ViewChild('saveAsPresetCheckbox', {read: ElementRef}) saveAsPresetCheckbox: ElementRef<HTMLInputElement>;

    currentPostTimeID: number = null;
    categoriesComponent: CategoriesComponent;
    categories: Category[] = [];
    categoryColors = [];
    queueFilterForm = new FormGroup({});
    autoFeedApi: AutofeedApiComponent;
    minutes: number[] = [];
    hours: number[] = [];
    autoFeeds: { index: number, name: string, items: any[] }[] = [];
    autoFeedCount = 0;
    socialSiteComponent: SocialSiteComponent;
    currentSocialSite: SocialSiteInterface = CurrentSocialSite.getSocialSites();
    isCurrentSiteEmpty = false;
    swappable: Swappable;
    currentTimezone = '--';
    periodOptions: string[] = AUTOFEED_RULE_PERIODS;
    needRefresh = false;

    _daysOff: string[] = [];
    newPostTimeForm: FormGroup = new FormGroup({
        period: new FormControl('', [
            Validators.required
        ]),
        hour: new FormControl('', [
            Validators.required,
            Validators.min(0),
            Validators.max(23)
        ]),
        minute: new FormControl('', [
            Validators.required,
            Validators.min(0),
            Validators.max(59)
        ]),
        category: new FormControl('', [
            Validators.required
        ]),
        isMultiple: new FormControl(''),
        maxPostNumber: new FormControl('', [
            Validators.required,
            Validators.min(1)
        ])
    });
    autoFeedFilterReset: FormGroup = new FormGroup({
        categoryFilter: new FormControl('')
    });
    newPostTimeFormErrors: object = {};
    matcher = new MyErrorStateMatcher();
    swapSource: HTMLElement;
    swapTarget: HTMLElement;
    postIDPrefix = 'group-';
    _stateManager = null;
    schedulePostCounter = 10;
    hasMoreSchedulePost = false;
    subs = [];
    hoursOptions = CommonPostHelpers.getHourSelectOptions();
    minutesOptions = CommonPostHelpers.getMinuteSelectOptions();
    _showPostTimeForm = false;
    _postTimeFormEditMode = false;

    /**
     * @description Akkor inicializáljuk a autofeed-preset-list komponenst, amikor először a tabjára kattintunk.
     * Ennek oka: így az oldal betöltésnél nem lesz dupla full screen loader
     * @type {boolean}
     */
    allowRulePresetList = false;

    socialPlatforms = [
        {
            id: SOCIAL_MEDIA_TYPE_FACEBOOK,
            label: 'Facebook',
            socialSites: [],
            icon: 'mdi-facebook-box'
        },
        {
            id: SOCIAL_MEDIA_TYPE_LINKEDIN,
            label: 'LinkedIn',
            socialSites: [],
            icon: 'mdi-linkedin-box'
        },
        {
            id: SOCIAL_MEDIA_TYPE_TWITTER,
            label: 'Twitter',
            socialSites: [],
            icon: 'mdi-twitter-box'
        },
        {
            id: SOCIAL_MEDIA_TYPE_INSTAGRAM,
            label: 'Instagram',
            socialSites: [],
            icon: 'mdi-instagram',
            disabled: false
        },
        {
            id: SOCIAL_MEDIA_TYPE_GMB,
            label: 'GMB',
            socialSites: [],
            icon: 'mdi-gmb',
            disabled: false
        }
    ];
    private _saveAsPreset = false;


    get saveAsPreset(): boolean {
        return this._saveAsPreset;
    }

    set saveAsPreset(value: boolean) {
        this._saveAsPreset = value;
        const containControl = this.newPostTimeForm.contains(NewPostTimeFormControlsName.PresetName);

        if (value && !containControl) {
            this.newPostTimeForm.addControl(NewPostTimeFormControlsName.PresetName, new FormControl(null, [Validators.required]))
        } else if (containControl && !value) {
            this.newPostTimeForm.removeControl(NewPostTimeFormControlsName.PresetName);
        }

        this.saveAsPresetCheckbox.nativeElement.checked = !!value;
    }

    constructor(
        service: PostActionsService,
        categoriesService: CategoriesService,
        private autofeedService: AutofeedService,
        private newAutofeedService: NewAutoFeedService,
        private autofeedPageService: AutofeedPageService,
        socialSiteService: SocialSiteService,
        public dialog: MatDialog,
        users: UsersController,
        carouselController: CarouselController,
        public openModal: OpenModalService,
        public languageService: LanguageService
    ) {
        super(service, dialog, users, carouselController, openModal);

        delete this.filters['limit'];
        delete this.filters['offset'];
        this.filters['type'] = 'autoFeed';

        this.autoFeedApi = new AutofeedApiComponent(autofeedService, dialog);
        delete this.autoFeedApi.filters['limit'];
        delete this.autoFeedApi.filters['offset'];

        this.socialSiteComponent = new SocialSiteComponent(socialSiteService);
        this.categoriesComponent = new CategoriesComponent(categoriesService);

        this.initialize();

        const sub1 = this.autofeedPageService.reloadAutofeed.subscribe(() => {
            this.getPostItems(true);
        });
        this.subs.push(sub1);
    }

    private getCategories() {
        return new Promise((resolve, reject) => {
            this.categoriesComponent.getItems(response => {
                for (const category of response.categories) {
                    this.categories[category.categoryID] = category.name;
                    this.categoryColors[category.categoryID] = category.color;
                }
                resolve(response);
            }, error => reject(error));
        });
    }

    private initialize() {
        const loader = this.openModal.loader(DialogLoaderComponent);

        this.socialSiteComponent.getItems(
            response => {
                this.socialSites = response.socialSites;
                const socialSiteIndex = !!this.currentSocialSite
                    ? this.socialSites.findIndex(site => site.siteID === this.currentSocialSite.siteID)
                    : -1;

                this.socialPlatforms = this.socialPlatforms.map(platform => {
                    if (!platform.disabled) {
                        platform.socialSites = Helpers.orderBy(this.socialSites.filter(site => site.socialType === platform.id), 'name');
                    }

                    return platform;
                });

                if (!!this.socialSites && !!this.socialSites.length) {
                    if (!this.currentSocialSite || !this.currentSocialSite.siteID || (socialSiteIndex === -1)) {
                        CurrentSocialSite.setSocialSite(this.socialSites[0]);
                        this.currentSocialSite = CurrentSocialSite.getSocialSites();
                    }
                }

                if (!this.currentSocialSite || !this.currentSocialSite.siteID) {
                    this.isCurrentSiteEmpty = true;
                }

                if (!!this.currentSocialSite && !!this.currentSocialSite.siteID) {


                    this.getData().then(responses => loader.close()).catch(error => loader.close());
                } else {
                    loader.close();
                }

                this.setCurrentTimezone();
            },
            error => {
                loader.close();
            }
        );

        // Set minute items
        for (let i = 0; i <= 60; i++) {
            this.minutes.push(i);
        }

        // Set hour items
        for (let i = 0; i <= 24; i++) {
            this.hours.push(i);
        }
    }

    /**
     * Get datas
     * @return {Promise<[any]>}
     */
    private getData(hasLoader = false) {
        const requests = [];
        let loader;

        if (hasLoader) {
            loader = this.openModal.loader(DialogLoaderComponent);
        }

        const closeLoader = () => {
            if (hasLoader) {
                loader.close();
            }
        };

        requests.push(
            ...[
                this.getPostItems(),
                this.getAutoFeedItems(false),
                this.getSettings()
            ]
        );
        return Promise.all(requests).then(response => {
            closeLoader();
            return response;
        }).catch(error => {
            closeLoader();
            return error;
        });
    }

    ngOnInit() {
        this.newPostTimeFormInit();
        this.checkMoreSchedulePost();
    }

    ngOnDestroy(): void {
       this.subs.forEach(sub => sub.unsubscribe());
    }

    ngAfterViewInit(): void {
        this._stateManager = new StateSaver;
        this._stateManager.getState('autofeedActionsNav');
    }

    @ClickDecorator()
    choosePreset(event: MouseEvent) {
        this.openModal.scheduleRulePresetChooser(ScheduleRulePresetChooserModalComponent, {
            multiple: true,
            afterChoose: (presets: ScheduleRulePresetModel[]) => {
                if (presets && presets.length) {
                    const loader = this.openModal.loader(DialogLoaderComponent);
                    this.newAutofeedService.createScheduleRules(
                        presets.map(preset => ({
                            ...preset.settings,
                            presetID: preset.presetID,
                            presetName: preset.name
                        })),
                        [this.currentSocialSite.siteID]
                    ).then(result => {
                        loader.afterClosed().subscribe(() => {
                            this.openModal.infoModal(DialogInfoComponent, {
                                message: result.message
                            }).afterClosed().subscribe(() => {
                                if (result.success) {
                                    this.getAutoFeedItems(true)
                                }
                            });
                        });

                        loader.close();
                    });
                }
            }
        })
    }

    saveAsPresetChange(event: Event) {
        this.saveAsPreset = !!(event?.target as HTMLInputElement)?.checked;
    }

    tabChange(index: number): void {
        if (index === 1 && this.rulePresetList) {
            this.rulePresetList.getRulePresets();
        }

        if (!this.allowRulePresetList) {
            this.allowRulePresetList = true;
        }

        if (this.needRefresh) {
            this.needRefresh = false;
            this.getAutoFeedItems(true);
        }
    }

    /**
     * Get autoFeed settings
     * @return {Promise<any>}
     */
    getSettings() {
        if (this.currentSocialSite.siteID) {
            return this.autoFeedApi.getFeedStatus(this.currentSocialSite.siteID)
                .then(value => {
                    this._daysOff = [];
                    if (value.autoFeedSettings.length) {
                        const status = value.autoFeedSettings[0].status,
                            daysOff = value.autoFeedSettings[0].daysOff;

                        this._daysOff = (daysOff) ? daysOff.split(',') : [];
                        this.hasMoreSchedulePost = (status === FeedStatus.ACTIVE);
                    }
                    return value;
                });
        }
    }

    /**
     * Get post items
     * @return {Promise<any>}
     */
    getPostItems(hasLoader = false) {
        this.nextSharing = EMPTY_NEXT_SHARING;
        let loader;

        if (hasLoader) {
            loader = this.openModal.loader(DialogLoaderComponent);
        }

        const closeLoader = () => {
            if (hasLoader) {
                loader.close();
            }
        };

        this.filters['socialSiteID'] = this.currentSocialSite.siteID;
        return this.getItems().then(() => closeLoader()).catch(() => closeLoader());
    }

    successGetItems(response: any): void {

        this.responsePostCount = 0;

        // this.posts = response.posts;

        this.queueItems = [];
        this.postedItems = [];
        this.sortedItems = [];

        let orderKey = 'createDate';
        let orderBy = 'ASC';

        let posts: PostInterface[] = response.posts,
            queueGroupedItems: object = {},
            postedGroupedItems: object = {},
            sortedGroupedItems: object = {},
            currentDay = moment().format('Y-MM-DD'),
            nextDay = moment().add(1, 'day').format('Y-MM-DD'),
            prevDay = moment().subtract(1, 'day').format('Y-MM-DD');

        if (this.filters['order']) {
            try {
                const order = JSON.parse(this.filters['order']);
                if (Object.keys(order)[0] !== 'none') {
                    orderKey = Object.keys(order)[0];
                    orderBy = order[Object.keys(order)[0]].toUpperCase();
                }
            } catch (e) {
                console.error(e);
            }
        }

        posts = this.postsProcess(posts);

        for (const post of posts) {
            const dateKey = moment(post.activeFrom).format('Y-MM-DD');
            let groupName = dateKey;

            switch (dateKey) {
                case currentDay:
                    groupName = 'Today';
                    break;

                case prevDay:
                    groupName = 'Yesterday';
                    break;

                case nextDay:
                    groupName = 'Tomorrow';
                    break;
            }

            if (Object.keys(queueGroupedItems).indexOf(dateKey) === -1 && post.status !== 'posted') {
                queueGroupedItems[dateKey] = {
                    createDate: dateKey,
                    groupName: groupName,
                    items: []
                };
            }

            if (Object.keys(postedGroupedItems).indexOf(dateKey) === -1 && post.status === 'posted') {
                postedGroupedItems[dateKey] = {
                    createDate: dateKey,
                    groupName: groupName,
                    items: []
                };
            }

            if (Object.keys(sortedGroupedItems).indexOf(dateKey) === -1) {
                sortedGroupedItems[dateKey] = {
                    createDate: dateKey,
                    groupName: groupName,
                    items: []
                };
            }

            if (post.status !== 'posted') {
                queueGroupedItems[dateKey]['items'].push(post);

                if (!moment(post.activeFrom).isBefore(moment())) {
                    this.setNextSharingTime(post);
                }
            } else {
                postedGroupedItems[dateKey]['items'].push(post);
            }

            sortedGroupedItems[dateKey]['items'].push(post);
        }

        for (const key in queueGroupedItems) {
            const value = queueGroupedItems[key];

            value.items = Helpers.orderBy(value.items, 'activeFrom', orderBy);

            this.responsePostCount += value.items.length;

            if (value.items.length > 0) {
                this.queueItems.push(value);
            }
        }

        for (const key in postedGroupedItems) {
            const value = postedGroupedItems[key];

            value.items = Helpers.orderBy(value.items, 'activeFrom', orderBy);

            if (value.items.length > 0) {
                this.postedItems.push(value);
            }
        }

        for (const key in sortedGroupedItems) {
            const value = sortedGroupedItems[key];

            value.items = Helpers.orderBy(value.items, 'activeFrom', orderBy);

            if (value.items.length > 0) {
                this.sortedItems.push(value);
            }
        }

        this.queueItems = Helpers.orderBy(this.queueItems, orderKey, orderBy);

        this.postedItems = Helpers.orderBy(this.postedItems, orderKey, orderBy);

        this.sortedItems = Helpers.orderBy(this.sortedItems, orderKey, orderBy);

        // callback(posts);

        setTimeout(() => {
            if (this.filters['categories']) {
                this.swapInit();
            } else {
                this.swapDestroy();
            }
        });

    }

    swapInit() {
        this.swappable = new Swappable(document.querySelectorAll('.queue-items'), {
            draggable: '.queue-post-item'
        });

        this.swappable.on('swappable:swapped', (e) => {
            if(this.swapSource === e.data.dragEvent.data.source && this.swapTarget === e.data.swappedElement) {
                this.swapSource = null;
                this.swapTarget = null;
            } else {
                this.swapSource = e.data.dragEvent.data.source;
                this.swapTarget = e.data.swappedElement;
            }
        });

        this.swappable.on('swappable:stop', (data) => {
            const id1 = $(this.swapSource).data('id'),
                id2 = $(this.swapTarget).data('id');

            if (id1 && id2) {
                const loader = this.openModal.loader(DialogLoaderComponent);

                delete this.swapSource;
                delete this.swapTarget;

                this.autoFeedApi.orderPosts(id1, id2)
                    .then(response => {
                        loader.afterClosed().subscribe(() => {
                            this.getPostItems(true);
                        });
                        loader.close();
                    })
                    .catch(() => {
                        loader.close();
                    });
            }
        });
    }

    swapDestroy() {
        if (this.swappable) {
            this.swappable.destroy();
        }
    }

    /**
     * Get autoFeed times
     */
    async getAutoFeedItems(hasLoader: boolean = true) {
        this.autoFeeds = [];

        let _loader;

        if (hasLoader) {
            _loader = this.openModal.loader(DialogLoaderComponent);
        }

        await this.getCategories();

        this.autoFeedApi.filters['socialSiteID'] = this.currentSocialSite.siteID;

        return new Promise((resolve, reject) => {
            this.autoFeedApi.getItems(response => {

                    if (hasLoader) {
                        _loader.close();
                    }

                    const autoFeeds: AutoFeedItem[] = response.autoFeeds,
                        orderedAutoFeeds: object = {};

                    for (const day in DAYS) {
                        orderedAutoFeeds[day] = {
                            name: day,
                            items: []
                        };
                    }

                    this.autoFeedCount = autoFeeds.length;

                    for (const feed of autoFeeds) {

                        //feed.color = Helpers.getRandomColor();

                        for (const day of feed.days) {
                            const time = feed.time.split(':'),
                                hour = time[0],
                                minute = time[1],
                                second = time[2];

                            orderedAutoFeeds[day].items.push({
                                hour: hour,
                                minute: minute,
                                second: second,
                                categoryID: String(feed.categoryID),
                                days: feed.days,
                                period: feed.period,
                                multiple: feed.multiple,
                                postTimeID: feed.postTimeID,
                                color: this.categoryColors[feed.categoryID] || '#000'
                            });
                        }
                    }

                    for (const key in orderedAutoFeeds) {
                        const value = orderedAutoFeeds[key];

                        if (value.items && value.items.length > 0) {
                            value.items = value.items.sort((a, b) => {
                                if (a.hour < b.hour) {
                                    return -1;
                                }
                                if (a.hour > b.hour) {
                                    return 1;
                                }

                                if (a.minute < b.minute) {
                                    return -1;
                                }
                                if (a.minute > b.minute) {
                                    return 1;
                                }
                                return 0;
                            });
                        }

                        this.autoFeeds.push(value);
                    }

                    resolve(response);
                },
                error => {
                    if (hasLoader) {
                        _loader.close();
                    }
                    reject(error);
                }
            );
        });
    }

    /**
     * Save selected social site
     *
     * @param {string} type
     * @param {SocialSiteInterface} site
     */
    saveToCurrentSocialSite(type: string, site: SocialSiteInterface): void {
        CurrentSocialSite.setSocialSite(site);

        this.currentSocialSite = CurrentSocialSite.getSocialSites();

        this.isCurrentSiteEmpty = false;

        const startPosition = site.timezone.indexOf('/') + 1;
        this.currentTimezone = site.timezone.substr(startPosition);

        this.closeEditTime();
        this.getData(true);
    }

    /**
     * Add new auto feed time
     */
    addNewTime() {
        if (this.newPostTimeFormValidation()) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            loader.afterClosed().subscribe(({isSuccess, response}) => {

                if (isSuccess) {
                    this.getPostItems();
                    this.getAutoFeedItems();

                    this.newPostTimeFormElement.resetForm();
                    this.saveAsPreset = false;

                    this.newPostTimeFormValidation();

                    this._showPostTimeForm = false;
                } else {
                    this.newPostTimeFormErrors = FormValidationService.readError(response).formMessages;
                }
            });

            this.savePreset().then(() => {
                this.autoFeedApi.createItem(this.postTimeFormData, response => {
                    loader.close({
                        isSuccess: true,
                        response: response
                    });
                }, (error) => {
                    loader.close({
                        isSuccess: false,
                        response: error
                    });
                });
            }).catch(error => {
                loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message: FormValidationService.readError(error).message
                    });
                });
                loader.close({
                    isSuccess: false,
                    response: error
                });
            });
        }
    }

    /**
     * Edit time
     */
    editTime() {
        if (this.newPostTimeFormValidation()) {
            const _loader = this.openModal.loader(DialogLoaderComponent);
            const onSuccess = () => {
                _loader.close();

                this._postTimeFormEditMode = false;
                this._showPostTimeForm = false;

                this.newPostTimeForm.controls[NewPostTimeFormControlsName.Period].enable();

                this.newPostTimeFormElement.resetForm();
                this.saveAsPreset = false;

                this.getPostItems();
                this.getAutoFeedItems();
            };

            this.savePreset()
                .then(() => {
                    this.autoFeedApi.editFeedItem(this.currentPostTimeID, this.postTimeFormDataForEdit)
                        .then(value => onSuccess())
                        .catch(reason => {
                            _loader.close();

                            // TODO nyelvesítés
                            this.openModal.errorModal(DialogErrorComponent, {
                                message: 'Failed auto feed time editing!'
                            });
                        });
                })
                .catch(error => {
                    _loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: FormValidationService.readError(error).message
                        });
                    });
                    _loader.close();
                });
        }
    }

    /**
     * Close from time edit
     */
    closeEditTime() {
        this._postTimeFormEditMode = false;
        this._showPostTimeForm = false;

        this.newPostTimeForm.controls[NewPostTimeFormControlsName.Period].enable();

        this.newPostTimeFormElement.resetForm();
    }

    /**
     * Delete time
     *
     * @param {string} day
     * @param {object} time
     */
    deleteTime(day: string, time: object) {
        this.dialog.open(DialogFeedItemDeleteConfirmComponent, {
            data: {
                day: day,
                time: `${time['hour']}:${time['minute']}`,
                days: time['days']
            }
        }).afterClosed().subscribe(everyDay => {
            /**
             * No delete
             */
            if (everyDay === null || everyDay === undefined) {
                return false;
            }

            const _loader = this.openModal.loader(DialogLoaderComponent);

            if (everyDay) { // every day
                day = null;
            }

            this.autoFeedApi.deleteFeedItem(time['postTimeID'], day)
                .then(value => {
                    _loader.close();

                    this.getAutoFeedItems();
                    this.getPostItems();
                })
                .catch(reason => {
                    _loader.close();

                    // TODO nyelvesítés
                    this.openModal.errorModal(DialogErrorComponent, {
                        message: 'Failed auto feed time delete!'
                    });
                });
        });
    }

    /**
     * Save settings as preset
     * @return {Promise<any>}
     */
    savePreset(): Promise<any> {
        if (this.newPostTimeForm.contains(NewPostTimeFormControlsName.PresetName)) {
            const data = this.newPostTimeForm.getRawValue();
            const category: Category = this.categorySelect.categories.find(_category => _category.categoryID === data[NewPostTimeFormControlsName.Category])
            return this.newAutofeedService.createPreset({
                categoryID: category.categoryID,
                categoryName: category.name,
                period: data[NewPostTimeFormControlsName.Period],
                hour: data[NewPostTimeFormControlsName.Hour],
                minute: data[NewPostTimeFormControlsName.Minute],
                name: data[NewPostTimeFormControlsName.PresetName],
            }).then((response) => {
                this.saveAsPreset = false;
                NotifyService.success(
                    LanguageService.getLine('post.autoFeedPreset.title.create'),
                    LanguageService.getLine('post.autoFeedPreset.create.success')
                );
                return Promise.resolve(response);
            });
        }

        return Promise.resolve();
    }

    /**
     *
     *
     * @param {string} day
     * @param {object} time
     */
    loadDataPostTimeForm(day: string, time: object) {
        this._postTimeFormEditMode = true;

        this.newPostTimeForm.controls[NewPostTimeFormControlsName.Period].disable();

        this.newPostTimeForm.reset();

        this.currentPostTimeID = time['postTimeID'];

        time['period'] = day;

        this.postTimeFormData = time;

        setTimeout(() => {
            this.periodSelect.focus();
        });
    }

    /**
     * @description it is opening the create comment to post modal
     * @param post
     */
    commentPostClick(post: PostInterface) {
        this.openModal.commentToPost(PostPreviewComponent, post);
    }

    /**
     * Toggle social site list visibility
     * @param {MouseEvent} event
     * @param {string} elementID
     */
    socialSiteSelectListToggle(event: MouseEvent, elementID: string): void {
        const siteListEl: HTMLDivElement = document.getElementById(elementID) as HTMLDivElement;
        const documentClickEvent = (e: MouseEvent) => {
            if (!siteListEl.contains(e.target as HTMLElement)) {
                $(siteListEl).collapse('hide');
                removeClickListener();
            }
        };
        const removeClickListener = () => document.removeEventListener('click', documentClickEvent);

        $(siteListEl).on('shown.bs.collapse', function () {
            document.addEventListener('click', documentClickEvent);
        });
    }

    /**
     * Get time form data
     *
     * @return {object}
     */
    get postTimeFormData(): object {
        const data = {
            time: `${this.hourInputValue}:${this.minuteInputValue}`,
            categoryID: this.newPostTimeForm.controls[NewPostTimeFormControlsName.Category].value,
            days: this.newPostTimeForm.controls[NewPostTimeFormControlsName.Period].value,
            socialSiteID: this.currentSocialSite.siteID
        };

        if (this.newPostTimeForm.controls[NewPostTimeFormControlsName.IsMultiple].value) {
            data['multiple'] = this.newPostTimeForm.controls[NewPostTimeFormControlsName.MaxPostNumber].value || 1;
        }

        return data;
    }

    get hourInputValue() {
        let hour = this.newPostTimeForm.get(this.newPostTimeFormControlsName.Hour).value;

        hour = (Number(hour) < 10) ? `0${Number(hour)}` : hour;

        return hour;
    }

    get minuteInputValue() {
        let minute = this.newPostTimeForm.get(this.newPostTimeFormControlsName.Minute).value;

        minute = (Number(minute) < 10) ? `0${Number(minute)}` : minute;

        return minute;
    }

    /**
     * Set time form data
     *
     * @param {object} data
     */
    set postTimeFormData(data: object) {
        this.newPostTimeForm.controls[NewPostTimeFormControlsName.Period].setValue(data['period'] || '');
        this.newPostTimeForm.controls[NewPostTimeFormControlsName.Category].setValue(Number(data['categoryID']) || null);

        this.newPostTimeForm.controls[NewPostTimeFormControlsName.Hour].setValue(Number(data['hour'] || ''));
        this.newPostTimeForm.controls[NewPostTimeFormControlsName.Minute].setValue(Number(data['minute'] || ''));

        if (data['multiple']) {
            this.newPostTimeForm.controls[NewPostTimeFormControlsName.IsMultiple].setValue(true);
            this.newPostTimeForm.controls[NewPostTimeFormControlsName.MaxPostNumber].setValue(data['multiple']);
        } else {
            this.newPostTimeForm.controls[NewPostTimeFormControlsName.IsMultiple].setValue(false);
            this.newPostTimeForm.controls[NewPostTimeFormControlsName.MaxPostNumber].reset();
        }

    }

    /**
     * Get post time form data for edit
     *
     * @return {object}
     */
    get postTimeFormDataForEdit(): object {
        const data = this.postTimeFormData;

        if (this.allTime.nativeElement.checked) {
            data['editAll'] = 1;
        }

        return data;
    }

    /**
     * Play/pause post queue
     */
    setQueueStatus(day?: string) {
        const data: FeedStatusSettingApiObject = {
            status: (this.hasMoreSchedulePost) ? FeedStatus.PAUSED : FeedStatus.ACTIVE,
            daysOff: this._daysOff.join(',')
        };

        if (day) {
            delete data['status'];

            if (data.daysOff.indexOf(day) > -1) {
                if (data.daysOff.indexOf(`${day},`) > -1) {
                    data.daysOff = data.daysOff.replace(`${day},`, '');
                } else {
                    if (data.daysOff.indexOf(`,${day}`) > -1) {
                        data.daysOff = data.daysOff.replace(`,${day}`, '');
                    } else {
                        data.daysOff = data.daysOff.replace(day, '');
                    }
                }
            } else {
                data.daysOff += (data.daysOff.length > 2) ? `,${day}` : day;
            }
        }

        const _loader = this.openModal.loader(DialogLoaderComponent);

        this.autoFeedApi.setFeedStatus(this.currentSocialSite.siteID, data)
            .then(value => {
                const status = value['autoFeedSetting']['status'];

                this.hasMoreSchedulePost = (status === FeedStatus.ACTIVE);

                Promise.all([
                    this.getSettings(),
                    this.getPostItems()
                ]).finally(() => _loader.close());
            })
            .catch(errorResponse => {
                const errorMsg = FormValidationService.readError(errorResponse).message;

                _loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message: errorMsg ? errorMsg : this.languageService.getLine('autofeed.error.queuestatus')
                    });
                });

                _loader.close();
            });
    }

    newPostTimeFormInit() {
        this.newPostTimeForm.controls[NewPostTimeFormControlsName.MaxPostNumber].disable();

        const sub1 = this.newPostTimeForm.valueChanges.subscribe(() => {
            this.newPostTimeFormValidation();
        });

        const sub2 = this.newPostTimeForm.controls[NewPostTimeFormControlsName.IsMultiple].valueChanges.subscribe(value => {
            if (value) {
                this.newPostTimeForm.controls[NewPostTimeFormControlsName.MaxPostNumber].enable();
            } else {
                this.newPostTimeForm.controls[NewPostTimeFormControlsName.MaxPostNumber].disable();
            }
        });

        this.subs.push(sub1);
        this.subs.push(sub2);
    }

    /**
     * New post time form validation
     *
     * @return {boolean} isValid
     */
    newPostTimeFormValidation(): boolean {
        this.newPostTimeFormErrors = {};
        this.matcher = new MyErrorStateMatcher();

        if (this.newPostTimeForm.valid) {
            return true;
        } else {
            this.newPostTimeFormErrors = FormValidationService.getMessages(this.newPostTimeForm.controls);

            return false;
        }
    }

    get newPostTimeFormControlsName() {
        return NewPostTimeFormControlsName;
    }

    /**
     * Are Scheduled Posts?
     */
    private checkMoreSchedulePost(): void {

        if (this.schedulePostCounter > 0) {
            this.hasMoreSchedulePost = true;
            return;
        }
        this.hasMoreSchedulePost = false;
    }

    getInvertColor(hex: string) {
        return Utils.invertColor(hex);
    }

    setCurrentTimezone() {
        let timezone = '--';

        if (!!this.currentSocialSite) {
            const startPosition = this.currentSocialSite.timezone.indexOf('/') + 1;
            timezone = this.currentSocialSite.timezone.substr(startPosition);
        }

        this.currentTimezone = timezone;
    }

    editPostClick(post: PostInterface) {
        this.openModal.editPost(PostManagerComponent, post).afterClosed().subscribe(hasChange => {
            if (!!hasChange) {
                this.getPostItems(true);
            }
        });
    }

    copyPostClick(post: PostInterface) {
        this.openModal.copyPost(PostManagerComponent, post, {
            data: {
                scheduleDate: post.activeFrom
            }
        }).afterClosed().subscribe((newPosts) => {
            if (!!newPosts) {
                this.getPostItems(true);
            }
        });
    }

    deletePostClick(post: PostInterface): Promise<any> {
        return super.deletePostClick(post).then(() => {
            this.getPostItems(true);
        });
    }

    @Debounce()
    categoryChange(event: MatSelectChange) {
        const value = event.value;

        if (value) {
            this.filters['categories'] = JSON.stringify([value]);
        } else {
            delete this.filters['categories'];
        }

        this.getPostItems(true);
    }

    resetFilter(event) {
        event.preventDefault();
        this.autoFeedFilterReset.reset();
        delete this.filters['categories'];

        this.getPostItems(true);
    }

    get helpers() {
        return Helpers;
    }

    createTemplateFromPost(post: PostInterface) {
        post.activeFromInput = null;
        post.type = "customFeed";
        this.openModal.createTemplateRaw(PostTemplateManagerComponent, {
            data: {
                template: post,
                isTemplateFromPost: true,
            },
        });
    }
}
