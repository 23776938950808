import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {BASE_DATE_FORMAT, BASE_DATETIME_FORMAT} from '../../../../configs/configs';
import * as moment from 'moment';

@Component({
    selector: 'smd-bottom-sheet-choose-schedule-source',
    templateUrl: './bottom-sheet-choose-schedule-source.component.html',
    styleUrls: ['./bottom-sheet-choose-schedule-source.component.scss']
})
export class BottomSheetChooseScheduleSourceComponent implements OnInit {

    date = '';

    constructor(
        public _bottomScheetRef: MatBottomSheetRef<BottomSheetChooseScheduleSourceComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public _data
    ) {
    }

    ngOnInit() {
        if (this._data) {
            if (this._data.date) {
                this.date = moment(this._data.date).format(BASE_DATE_FORMAT);
            }

            if (this._data.fullDate) {
                this.date = moment(this._data.fullDate).format(BASE_DATETIME_FORMAT);
            }
        }
    }

    /**
     * Choose post schedule source and close bottom sheet
     *
     * @param {"template" | "scratch"} source
     */
    chooseSource(source: 'template' | 'scratch'): void {
        this._bottomScheetRef.dismiss(source);
    }
}
