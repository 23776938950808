import {ComponentFactoryResolver, Inject, Injectable, ViewContainerRef} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ComponentInjector {

    private factoryResolver: ComponentFactoryResolver;
    private rootViewContainer: ViewContainerRef;

    constructor(
        @Inject(ComponentFactoryResolver) factoryResolver
    ) {
        this.factoryResolver = factoryResolver;
    }

    insertComponent(viewContainerRef: ViewContainerRef, sourceComponent) {
        this.rootViewContainer = viewContainerRef;

        const factory = this.factoryResolver.resolveComponentFactory(sourceComponent);

        const component = factory.create(this.rootViewContainer.parentInjector);

        this.rootViewContainer.insert(component.hostView);

        return component;
    }

}
