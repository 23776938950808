import { StringSupport } from "~/src/app/core/helper/string-support";

export default abstract class AbstractPreviewAdapter {
    /**
     * @type {RegExp}
     */
    lineBreakRegex: RegExp = new RegExp(`(<br ?\/?>|<br class="smd">)`, "g");

    /**
     * @type {string[]}
     */
    contentLines: string[] = [];

    /**
     * Convert post content to social platform specify format
     *
     * @param {string} value
     */
    adapt(value: string, isLinkShare = null): string {
        value = StringSupport.trimWhiteSpaces(value);

        // remove <mark> and </mark> tags (used for highlighting)
        value = value.replace(/<mark>/g, "").replace(/<\/mark>/g, "");
        // replace shift+enter(/n) with a single line break character
        value = value.replace(/ ?<\/p>\n<p> ?/g, "<br />");
        // set content lines
        this.contentLines = value
        .split(this.lineBreakRegex)
        .filter((str) => str !== "")
        .map((str) => {
            if (!str.includes("<br ")) {
                return `<p>${str}</p>`;
            }
            
            return str;
        });
        
        return value;
    }
}
