import {Injectable} from '@angular/core';
import {environment} from '~/src/environments/environment';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import {HttpClient, HttpHeaders} from '~/node_modules/@angular/common/http';

interface LinkedInAuthRO {
    accessToken: {
        access_token: string;
        /**
         * @description The number of seconds remaining, from the time it was requested, before the token will expire.
         * Access tokens are issued with a 30 minute lifespan.  You can request a new token once your previous token expires.
         */
        expires_in: number;
    };
}

interface LinkedInPage {
    id: number;
    name: string;
}

interface LinkedInPagesRO {
    _total: number;
    elements: Array<LinkedInPage>;
}

@Injectable({ providedIn: 'root' })
export class LinkedInService {
    public client_id = environment.linkedIn.clientId;
    public redirect_uri: string = encodeURI(location.origin + '/linkedin');
    public scopes = 'r_emailaddress w_member_social rw_ads w_organization_social ' +
    'r_liteprofile r_ads_reporting r_organization_social rw_organization_admin';
    public linkedinAuthUrl = 'https://www.linkedin.com/oauth/v2/authorization';
    url = `${this.linkedinAuthUrl}?response_type=code&client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&scope=${this.scopes}`;

    public token;
    expiresIn;
    linkedInAuthLocalstorageKey = 'linkedInAuth';
    private fromExpires;

    constructor(
        private http: HttpClient
    ) {
        if (localStorage.getItem(this.linkedInAuthLocalstorageKey)) {
            const linkedInAuth: LinkedInAuthRO = JSON.parse(localStorage.getItem(this.linkedInAuthLocalstorageKey));

            this.token = linkedInAuth.accessToken.access_token;
            this.expiresIn = linkedInAuth.accessToken.expires_in;
        }
    }

    handleLinkedInTokenByCode(code: string): Promise<LinkedInAuthRO> {
        const apiUrlPart = '/api/social-sites/exchange-access-token';
        const params = {
            socialType: 'linkedIn',
            code: code,
            redirectUri: this.redirect_uri//environment.linkedIn.linkedInRedirectUri,
        };

        return this.http.post<LinkedInAuthRO>(
            environment.apiUrl + apiUrlPart,
            Helpers.objectToFormData(params),
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise().then((resp: any) => {
            const timestamp = this.getTimeStamp();

            if (!!resp.accessToken.error) {
                throw resp.accessToken;
            }

            this.token = resp.accessToken.access_token;
            this.expiresIn = resp.accessToken.expires_in;
            this.fromExpires = timestamp;

            localStorage.setItem(this.linkedInAuthLocalstorageKey, JSON.stringify(
                {
                    ...resp,
                    fromExpires: timestamp
                }
            ));

            return resp;
        });
    }

    request(requestType: string, urlPart: string, params = {}): Promise<any> {
        const headers = this.getLinkedInRequestHeadersByToken(this.token);

        return this.http[requestType](
            `https://api.linkedin.com/${urlPart}`,
            Helpers.objectToFormData(params),
            headers
        ).toPromise();
    }

    // requestNew(requestType: string, urlPart: string, params = {}): Promise<any> {
    //     const apiUrl = `https://api.linkedin.com/${urlPart}`;
    //     const headers = this.getLinkedInRequestHeadersByToken(this.token);
    
    //     // Use POST request with query tunneling
    //     return this.http.post(
    //       apiUrl,
    //       `q=roleAssignee&projection=(elements*(organizationalTarget~))&${Helpers.objectToFormData(params)}`,
    //       {
    //         headers: {
    //           'X-HTTP-Method-Override': 'GET',
    //           'Content-Type': 'application/x-www-form-urlencoded',
    //           ...headers.headers
    //         }
    //       }
    //     ).toPromise();
    //   }

    getPages(): Promise<LinkedInPagesRO> {
        return this.http.get<LinkedInPagesRO>(
            environment.apiUrl + `/api/social-sites/additional-information?target=/organizationalEntityAcls&socialType=linkedIn&accessToken=${this.token}`,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    // getPagesNew(): Promise<LinkedInPagesRO> {
    //     // Use POST request with query tunneling
    //     const apiUrlPart = '/v2/organizationalEntityAcls';
    //     const params = 'q=roleAssignee&projection=(elements*(organizationalTarget~))';
    //     const headers = this.getLinkedInRequestHeadersByToken(this.token);
    
    //     return this.http.post<LinkedInPagesRO>(
    //       environment.apiUrl + apiUrlPart,
    //       params,
    //       {
    //         headers: {
    //           'X-HTTP-Method-Override': 'GET',
    //           'Content-Type': 'application/x-www-form-urlencoded',
    //           ...headers.headers
    //         }
    //       }
    //     ).toPromise();
    //   }

    /**
     Ha nincs social site, akkor kell a socialType=linkedIn&accessToken={token}

     Amennyiben a socialSite már a rendszerben van, akkor egy socialSiteID
     megadásával tudsz kérést indítani. Itt nem kell accessToken és socialType.
     */
    getMe() {
        if (!this.isLoggedIn()) {
            return new Promise((resolve, reject) => {
                reject();
            });
        }
        return this.http.get<any>(
            environment.apiUrl + `/api/social-sites/additional-information?target=/me&socialType=linkedIn&accessToken=${this.token}`,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    isLoggedIn() {
        const token = localStorage.getItem(this.linkedInAuthLocalstorageKey) ?
            JSON.parse(localStorage.getItem(this.linkedInAuthLocalstorageKey)).accessToken.access_token : undefined;
        const isLoggedInn = (token && !this.isExpiredToken());

        return isLoggedInn;
    }

    private getLinkedInRequestHeadersByToken(token) {
        const header = {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
            })
        };

        return header;
    }

    private isExpiredToken() {
        const expires = this.expiresIn + this.fromExpires;
        const time = this.getTimeStamp();

        return (time >= expires);
    }

    private getTimeStamp() {
        return Math.floor(new Date().getTime() / 1000);
    }
}
