import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    declarations: []
})
export class MentionModule {
}
