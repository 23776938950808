import {Injectable} from '~/node_modules/@angular/core';
import {ResourceAbstract} from '~/src/app/core/resource.abstract';
import {MetricModel} from '~/src/app/modules/analytics/widget/metric.model';
import {SocialSiteInterface} from '~/src/app/components/social-site-select/social-site-select.component';
import {BackendService} from '~/src/app/core/backend.service';
import {Debug} from '~/src/app/core/debug';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import Utils from '~/src/app/core/utils';
import {BASE_DATE_FORMAT} from '~/src/app/configs/configs';
import {UsersResourceService} from '~/src/app/modules/users/users-resource.service';
import {OrganizationController} from '~/src/app/components/organization-select/organization.service';
import {MetricConfigs, SocialChannels} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {NewsFeedTargetingLinkedinService} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.service';
import {LanguageService} from '~/src/app/services/language.service';

export interface MetricDataFilters {
    metricID: string;
    socialSites: SocialSiteInterface[];
    dateRange: {
        from: string,
        to: string,
    };
    organizations?: number[];
    metricModifiers?: string[];
}

@Injectable()
export class MetricDataService extends ResourceAbstract {

    // main API URL
    protected mainApiUrl = '/analytics';

    // social channels date range duration limits in day
    readonly metricRangeLimitMap: {[key in SocialChannels]: number} = {
        facebook: 730, // original: 91
        linkedIn: 730,  // original: 426
        instagram: 730,  // original: 28
        twitter: null,
        gmb: null,
        organization: 999,
    };

    constructor(
        private backendService: BackendService,
        private usersResource: UsersResourceService,
        private organizationController: OrganizationController,
        private linkedinTargetingService: NewsFeedTargetingLinkedinService
    ) {
        super();
    }

    async getDatas(filters: MetricDataFilters): Promise<MetricModel[]> {
        const requests = [];
        const fromDate = Utils.moment(filters.dateRange.from).format(BASE_DATE_FORMAT);
        const toDate = Utils.moment(filters.dateRange.to).format(BASE_DATE_FORMAT);
        const metricModels: MetricModel[] = [];

        let entities = [];
        let isSite = false;

        if (filters.socialSites && filters.socialSites.length) {
            entities = filters.socialSites;
            isSite = true;
        } else if (filters.organizations && filters.organizations.length) {
            entities = filters.organizations;
        }

        // handling site entities (most of the widgets)
        if (isSite) {
            entities.forEach(entity => {
                let metricModel: MetricModel = new MetricModel(
                    filters.metricID,
                    entity,
                    {
                        from: fromDate,
                        to: toDate
                    },
                    this.usersResource,
                    this.organizationController,
                    this.linkedinTargetingService
                );

                const replacementWidgetID = Utils.lodash.get(metricModel.metricConfig, `replaceMetricIDMap.${entity.socialType}`, null);
                if (!!replacementWidgetID) {
                    metricModel = new MetricModel(
                        replacementWidgetID,
                        entity,
                        {
                            from: fromDate,
                            to: toDate
                        },
                        this.usersResource,
                        this.organizationController,
                        this.linkedinTargetingService
                    );
                }

                if (metricModel?.metricConfig?.firstPossibleDate) {
                    const firstPossibleDate = Utils.moment(metricModel.metricConfig.firstPossibleDate).format(BASE_DATE_FORMAT);
                    if (Utils.moment(fromDate).isBefore(firstPossibleDate)) {
                        metricModel.setErrorMessage([LanguageService.getLine('analytics.error.notAllowed.firstPossibleDate', {date: firstPossibleDate})]);
                    }
                }

                if (!metricModel.metricConfig.unavailableSocialTypes ||
                    (!isSite || metricModel.metricConfig.unavailableSocialTypes.indexOf(entity.socialType) === -1)) {

                    metricModel.getMetricModels().forEach(model => {
                        // date range duration in day
                        const dateRangeDuration = Utils.moment(toDate).diff(fromDate, 'd');

                        if (!model.socialSite || dateRangeDuration <= this.metricRangeLimitMap[model.socialSite.socialType]) {
                            requests.push(
                                this.backendService.get(
                                    this.getFullApiUrl(model.metricConfig.apiURL),
                                    {
                                        ...Utils.get<MetricConfigs>(model.metricConfig, 'defaultFilters', {}),
                                        socialSiteID: entity.siteID,
                                        from: fromDate,
                                        to: toDate,
                                    },
                                    {
                                        showErrorModal: false
                                    }
                                ).then((response: any) => {
                                    const modelIndex = this.getMetricModelIndex(metricModels, model);

                                    if ('message' in Utils.lodash.get(response, 'analytics', null)) {
                                        metricModels[modelIndex].setWarnMessage(response.analytics.message);

                                        return;
                                    }

                                    if ('values' in Utils.lodash.get(response, 'analytics', null)) {
                                        if (!response.analytics.values.length) {
                                            if (Utils.lodash.has(metricModels[modelIndex].metricConfig, 'nullable') && !metricModels[modelIndex].metricConfig.nullable) {
                                                metricModels[modelIndex].setWarnMessage(LanguageService.getLine('analytics.analytics.message.noDataAvailable'));

                                                return;
                                            }
                                        }
                                    }

                                    return metricModels[modelIndex].setDatas(
                                        model.metricConfig.apiURL + JSON.stringify(filters),
                                        response
                                    ).then(() => Promise.resolve(response)).catch((error) => {

                                        metricModels[modelIndex].setErrorMessage([
                                            // TODO language service
                                            FormValidationService.readError(error).message || 'Unknown error!'
                                        ]);

                                        return Promise.reject(error);
                                    });

                                }).catch(error => {
                                    const modelIndex = this.getMetricModelIndex(metricModels, model);

                                    metricModels[modelIndex].setErrorMessage([
                                        // TODO language service
                                        FormValidationService.readError(error).message || 'Unknown error!'
                                    ]);

                                    return Promise.reject(error);
                                })
                            );
                        } else {
                            model.setErrorMessage([
                                LanguageService.getLine('analytics.widget.metric.dateRange.error', {
                                    day: this.metricRangeLimitMap[model.socialSite.socialType]
                                })
                            ]);
                        }

                        metricModels.push(model);
                    });
                } else {
                    if (filters.socialSites.length === 1) {
                        metricModel.setErrorMessage([LanguageService.getLine('analytics.error.notAllowed.socialType')]);
                        metricModels.push(metricModel);
                    }
                }
            });
        // handling organization entities (organization widgets)
        } else {
            let metricModel: MetricModel = new MetricModel(
                filters.metricID,
                entities,
                {
                    from: fromDate,
                    to: toDate
                },
                this.usersResource,
                this.organizationController,
                this.linkedinTargetingService
            );

            metricModel.getMetricModels().forEach(model => {
                    requests.push(
                        this.backendService.get(
                            this.getFullApiUrl(model.metricConfig.apiURL),
                            {
                                ...Utils.get<MetricConfigs>(model.metricConfig, 'defaultFilters', {}),
                                organizations: entities,
                                metricModifiers: filters.metricModifiers,
                                from: fromDate,
                                to: toDate,
                            },
                            {
                                showErrorModal: false
                            }
                        ).then((response: any) => {
                            const modelIndex = this.getMetricModelIndex(metricModels, model);

                            if ('message' in Utils.lodash.get(response, 'analytics', null)) {
                                metricModels[modelIndex].setWarnMessage(response.analytics.message);
                                return;
                            }

                            if ('values' in Utils.lodash.get(response, 'analytics', null)) {
                                if (!response.analytics.values.length) {
                                    if (Utils.lodash.has(metricModels[modelIndex].metricConfig, 'nullable') && !metricModels[modelIndex].metricConfig.nullable) {
                                        metricModels[modelIndex].setWarnMessage(LanguageService.getLine('analytics.analytics.message.noDataAvailable'));
                                        return;
                                    }
                                }
                            }

                            if (!Utils.lodash.has(response, 'analytics')) {
                                if (response.analytics == []) {
                                    metricModels[modelIndex].setWarnMessage(LanguageService.getLine('analytics.analytics.message.noDataAvailable'));
                                } else {
                                    metricModels[modelIndex].setWarnMessage(LanguageService.getLine('analytics.analytics.message.wrongResponseRoot'));
                                }
                                return;
                            }
                            
                            return metricModels[modelIndex].setDatas(
                                model.metricConfig.apiURL + JSON.stringify(filters),
                                response
                            ).then(() => Promise.resolve(response)).catch((error) => {

                                metricModels[modelIndex].setErrorMessage([
                                    // TODO language service
                                    FormValidationService.readError(error).message || 'Unknown error!'
                                ]);

                                return Promise.reject(error);
                            });

                        }).catch(error => {
                            const modelIndex = this.getMetricModelIndex(metricModels, model);

                            metricModels[modelIndex].setErrorMessage([
                                // TODO language service
                                FormValidationService.readError(error).message || 'Unknown error!'
                            ]);

                            return Promise.reject(error);
                        })
                    );

                metricModels.push(model);
            });
        }

        const responses = await Promise.all(requests).catch(error => {
            if ('error' in error && 'error' in error.error && 'message' in error.error.error) {
                return Promise.reject(error);
            } else if ('message' in error) {
                error = {
                    error: {
                        error: {
                            message: error.message
                        }
                    }
                };
            }

            return Promise.reject(error);
        });

        return Promise.resolve(metricModels);
    }

    /**
     * Get metric model index
     * @param {MetricModel[]} metricModels
     * @param {MetricModel} metricModel
     * @returns {number}
     */
    private getMetricModelIndex(metricModels: MetricModel[], metricModel: MetricModel) {
        return metricModels.findIndex(model => model.modelID === metricModel.modelID);
    }

    /**
     * Remove metric model from collections
     * @param {MetricModel[]} metricModels
     * @param {MetricModel} metricModel
     * @returns {MetricModel[]}
     */
    private removeMetricModelFromCollection(metricModels: MetricModel[], metricModel: MetricModel, replaceItem?: MetricModel) {
        metricModels.splice(this.getMetricModelIndex(metricModels, metricModel), 1, replaceItem);
        return metricModels;
    }
}
