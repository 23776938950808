import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import Utils from '~/src/app/core/utils';

export const WIDGET_METRIC_OPTION_ORGANIZATIONS_PERFORMANCE: WidgetMetricOptionType = {
    id: 'organizations-performance',
    name: 'Organizations Performance',
    resource: 'analytics.analytics.organizationGraphData',
    socialChannel: 'facebook'
};

export const MetricOrganizationPerformanceConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_ORGANIZATIONS_PERFORMANCE.id,
    apiURL: '/organization-graph',
    socialType: 'facebook',
    contentType: 'chart',
    dataName: 'Organizations Performance',
    extensionType: 'organization',
    multiSocialSite: false,
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                }
            }
        }
    )
};
