import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Utils from '~/src/app/core/utils';
import {
    PartnerControlNames,
    PartnerRules,
    PeriodUnits
} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.config';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '~/src/app/services/language.service';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {EditOrgErrorStateMatcher} from '~/src/app/services/helpers';
import {FileInput} from 'ngx-material-file-input';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {DialogSuccessComponent} from '~/src/app/components/dialog-success/dialog-success.component';
import {PartnerManagerDialogData} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.interfaces';
import {Partner, PartnerRule, PeriodUnit} from '~/src/app/shared/types/partners.model';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {
    LINK_SHORTEN_TYPE_BITLY,
    LINK_SHORTEN_TYPE_OPTIONS
} from '~/src/app/modules/social-media-post/link-shortening.constant';
import {AuthService} from '~/src/app/modules/auth/auth.service';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';

@Component({
    selector: 'smd-partner-manager',
    templateUrl: './partner-manager.component.html',
    styleUrls: ['./partner-manager.component.scss'],
})
export class PartnerManagerComponent {
    errorMatcher = new EditOrgErrorStateMatcher();
    isEditMode = false;
    partnerRules: PartnerRule[] = Utils.lodash.cloneDeep(PartnerRules);
    periodUnits: PeriodUnit[] = Utils.lodash.cloneDeep(PeriodUnits);
    partnerControlNames = PartnerControlNames;
    partnerFormGroup = new FormGroup({
        [this.partnerControlNames.Name]: new FormControl(
            Utils.lodash.get(this.dialogData, 'partner.name', null),
            [
                Validators.required
            ]
        ),
        [this.partnerControlNames.Logo]: new FormControl(null),
        [this.partnerControlNames.LinkShortener]: new FormControl(null),
        [this.partnerControlNames.CustomerID]: new FormControl(Utils.lodash.get(this.dialogData, 'partner.zohoCustomerID', null)),

        [this.partnerControlNames.FirstName]: new FormControl(null, [Validators.required]),
        [this.partnerControlNames.LastName]: new FormControl(null, [Validators.required]),
        [this.partnerControlNames.Email]: new FormControl(null, [Validators.required]),

        [this.partnerControlNames.Rules]: new FormControl([]),
        [this.partnerControlNames.PostFromRssNumber]: new FormControl(null),
        // [this.partnerControlNames.MediaNumber]: new FormControl(null, [Validators.required]),
        [this.partnerControlNames.PostTemplateNumber]: new FormControl(null),
        [this.partnerControlNames.RssUrlNumber]: new FormControl(null),
        [this.partnerControlNames.OrganizationNumber]: new FormControl(Validators.required, Validators.max(99999)),
        [this.partnerControlNames.SocialSiteNumber]: new FormControl(null),
        [this.partnerControlNames.UserNumber]: new FormControl(null),

        // [this.partnerControlNames.MediaPeriod]: new FormControl(null, [Validators.required]),
        [this.partnerControlNames.PostTemplatePeriod]: new FormControl(null),
        [this.partnerControlNames.RssFeedPeriod]: new FormControl(null),
    });
    partnerFormErrors = {};
    logoSrc = Utils.lodash.get(this.dialogData, 'partner.partnerLogo', '');
    partnerConfig = Utils.lodash.get(this.dialogData, 'partner.partnerConfig.manual', {});
    totalPartnerConfig = Utils.get(this.dialogData, 'partner.partnerConfig.total', {});
    linkShortenerOptions = LINK_SHORTEN_TYPE_OPTIONS;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PartnerManagerDialogData,
        public language: LanguageService,
        private dialogRef: MatDialogRef<PartnerManagerComponent>,
        private openModal: OpenModalService,
        private partnerService: PartnersService,
        private partnerConfigService: PartnerConfigService,
        private authService: AuthService
    ) {
        this.isEditMode = !!this.dialogData && !!this.dialogData.partner;
        this.partnerFormInit();

        if (this.isEditMode) {
            this.initEditMode();
        }
    }

    /**
     * Create partner
     * @param {MouseEvent} event
     */
    createPartner(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.partnerFormGroup.valid) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            this.partnerService.createPartner(this.getPartner())
                .then(response => {
                    loader.afterClosed().subscribe(() => {
                        this.successAction(response, this.language.getLine('partner.manager.action.create.success'));
                    });
                    loader.close();
                })
                .catch(error => {
                    loader.afterClosed().subscribe(() => {
                        this.failedAction(error);
                    });
                    loader.close();
                });
        } else {
            this.partnerFormValidation();
        }
    }

    /**
     * Edit partner
     * @param {MouseEvent} event
     */
    async editPartner(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.partnerFormGroup.valid) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            try {
                const response = await this.partnerService.editPartner(this.getPartner());

                // this is my partner
                if (this.getPartner().partnerID === this.partnerService.getMyPartnerData().partnerID) {
                    await this.authService.refreshLoggedUserData().catch();
                    this.partnerConfigService.setConfig();
                }

                loader.afterClosed().subscribe(() => {
                    this.successAction(response, this.language.getLine('partner.manager.action.edit.success'));
                });
            } catch (e) {
                loader.afterClosed().subscribe(() => {
                    this.failedAction(e);
                });
            } finally {
                loader.close();
            }

        } else {
            this.partnerFormValidation();
        }
    }

    /**
     * Get action type
     * @return {"edit" | "create"}
     */
    get actionType(): 'edit' | 'create' {
        return this.isEditMode ? 'edit' : 'create';
    }

    /**
     * Init partner edit mode
     */
    private initEditMode() {
        [
            this.partnerControlNames.FirstName,
            this.partnerControlNames.LastName,
            this.partnerControlNames.Email
        ].forEach(name => {
            this.partnerFormGroup.removeControl(name);
        });

        const linkShorten = Utils.lodash.get(this.dialogData.partner, 'partnerData.linkShorten', null);
        const values = {
            [this.partnerControlNames.LinkShortener]: linkShorten
        };

        this.partnerFormGroup.patchValue(values);
        this.manageLinkShortenControl();
    }

    /**
     * Get partner data
     * @return {{}}
     */
    private getPartner(): Partner {
        let data: any = this.isEditMode ? this.dialogData.partner : {};

        data = {
            ...data,
            partnerConfig: this.partnerConfig,
            partnerLogo: null
        };

        const partnerData = {};
        Utils.lodash.forEach(this.partnerFormGroup.getRawValue(), (value, controlName) => {
            [
                this.partnerControlNames.BitlyToken,
                this.partnerControlNames.Logo,
                this.partnerControlNames.LinkShortener
            ].forEach(name => {
                if (controlName === name) {
                    if (!!value) {
                        partnerData[name] = (name === this.partnerControlNames.Logo)
                            ? Utils.lodash.get(value, '_files[0]', null)
                            : value;
                    }
                }
            });

            if (!Utils.lodash.includes([
                ...PartnersService.getAllConfigName(),
                this.partnerControlNames.Rules,
                this.partnerControlNames.BitlyToken,
                this.partnerControlNames.Logo,
                this.partnerControlNames.LinkShortener
            ], controlName)) {
                data = {
                    ...data,
                    [controlName]: value
                };
            }
        });

        return {
            ...data,
            partnerData: partnerData
        } as Partner;
    }

    /**
     * Create bitly token form control
     * @return {FormControl}
     */
    private createBitlyTokenFormControl(): FormControl {
        return new FormControl(
            Utils.lodash.get(this.dialogData.partner, 'partnerData.bitlyToken', null),
            [Validators.required]
        );
    }

    /**
     * Partner form init
     */
    private partnerFormInit() {
        this.partnerFormGroup.valueChanges.subscribe((values) => {
            this.manageLinkShortenControl();
            this.setPartnerFormErrorMessages();
        });

        this.partnerFormGroup.get(this.partnerControlNames.Logo).valueChanges.subscribe((value: FileInput) => {
            if (!!Utils.lodash.get(value, 'files.length', null)) {
                this.setLogoSrc(value.files[0]);
            }
        });
    }

    /**
     * Add/remove link shorten form control by link shorten value
     */
    private manageLinkShortenControl(): void {
        const containBitlyTokenControl = this.partnerFormGroup.contains(this.partnerControlNames.BitlyToken);
        const isBitlyShortener = this.partnerFormGroup.get(this.partnerControlNames.LinkShortener).value === LINK_SHORTEN_TYPE_BITLY;

        if (isBitlyShortener) {
            if (!containBitlyTokenControl) {
                this.partnerFormGroup.addControl(this.partnerControlNames.BitlyToken, this.createBitlyTokenFormControl());
            }
        } else if (containBitlyTokenControl) {
            this.partnerFormGroup.removeControl(this.partnerControlNames.BitlyToken);
        }
    }

    /**
     * Set partner logo src from file object
     * @param {File} file
     */
    private setLogoSrc(file: File) {
        const showErrorModal = () => {
            this.openModal.errorModal(DialogErrorComponent, {
                message: this.language.getLine('partner.modify.logo.error')
            });
        };

        Utils.readImageUrl(file).then(src => {
            if (!!src) {
                this.logoSrc = src;
            } else {
                showErrorModal();
            }
        }).catch(() => showErrorModal());
    }

    /**
     * Partner form validation
     * @param {any} fromBackend
     */
    private partnerFormValidation(fromBackend = null) {

        if (!!fromBackend) {
            Object.keys(fromBackend).forEach(controlName => {
                if (Utils.lodash.has(this.partnerFormGroup.controls, controlName)) {
                    this.partnerFormGroup.get(controlName).setErrors({invalid: true});
                }
            });
        } else {
            for (const controlName of Object.keys(this.partnerFormGroup.controls)) {
                this.partnerFormGroup.get(controlName).markAsTouched();
            }
        }

        this.setPartnerFormErrorMessages(fromBackend);
    }

    /**
     * Set partner form error messages
     * @param fromBackend
     */
    private setPartnerFormErrorMessages(fromBackend: any = null) {
        this.partnerFormErrors = !!fromBackend
            ? fromBackend
            : FormValidationService.getMessages(this.partnerFormGroup.controls);
    }

    /**
     * Success action method
     * @param response
     * @param {string} message
     */
    private successAction(response: any, message: string) {
        this.openModal.successModal(DialogSuccessComponent, {
            message: message
        }).afterClosed().subscribe(() => {

            this.dialogRef.afterClosed().subscribe(() => {
                if (this.actionType === 'create') {
                    if (!!this.dialogData.successCreate) {
                        this.dialogData.successCreate(response);
                    }
                }

                if (this.actionType === 'edit') {
                    if (!!this.dialogData.successEdit) {
                        this.dialogData.successEdit(response);
                    }
                }
            });

            this.dialogRef.close();
        });
    }

    /**
     * Failed action method
     * @param errorResponse
     */
    private failedAction(errorResponse: any) {
        const errors = FormValidationService.readError(errorResponse);

        this.partnerFormValidation(Utils.lodash.get(errors, 'formMessages', {}) || {});

        this.openModal.errorModal(DialogErrorComponent, {
            message: errors.message || this.language.getLine('partner.manager.action.create.error')
        });
    }
}
