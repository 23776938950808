import {BulkImportItemModel} from '~/src/app/modules/posts/bulk-import-item.model';
import {MediaPostTemplateRequestKeys} from '~/src/app/modules/social-media-post/post-skeleton/post-skeleton.options';
import { POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL } from '../social-media-post/social-media-post.constant';

export class BulkImportPostTemplateItemModel extends BulkImportItemModel {

    templateID: number;
    _tags: string[];

    tagNames: string;

    get tags(): string[] {
        return this._tags;
    }

    set tags(value: string[]) {
        this._tags = value;

        this.tagNames = (this.tags || []).join(', ');
    }

    constructor(props) {
        super(props);

        this.templateID = props.templateID || null;
        this.tags = (props.tags || props.postTags || []).map(tag => tag.name || tag);

        this.templateID = Number(this.templateID) || this.templateID;

        this.isDatabaseEntity = !!this.templateID;

        return this;
    }

    /**
     * Get request data of model
     *
     * @returns {Object}
     */
    getRequestData() {
        return {
            ...super.getRequestData(),
            templateID: this.templateID,
            draftID: this.templateID,
            socialType: this.socialType,
            postTags: this.tags,
            id: this.id,
            status: POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL,
        };
    }

    getDraftRequestData(): any  {
        const requestData = super.getDraftRequestData(true);

        const data = {
            ...requestData,
            status: this.status,
            socialType: this.socialType,
            organizationIDs: [this.organizationID],
            categories: [this.category],
            tags: this.tags,
            [MediaPostTemplateRequestKeys.fileIDs]: requestData.fileIDs || []
        };

        delete data.category;
        delete data.fileIDs;

        for (const key in data) {
            const value = data[key];

            if (Array.isArray(value)) {
                data[key] = JSON.stringify(value);
            }
        }

        return data;
    }
}
