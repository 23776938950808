import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {SmdFileInterface} from '~/src/app/services/file.class';
import {ViewFileObject} from '~/src/app/components/upload-media/upload-media.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PixieDomService} from "./pixie-dom.service";
import {PixieControllerService} from "./pixie-controller.service";
import EditorControllerService from "~/src/app/components/image-editor/editor-controller.service";

@Component({
  selector: 'smd-pixie',
  templateUrl: './pixie.component.html'
})
export class PixieComponent implements OnInit, OnDestroy {
    private readonly media: ViewFileObject | SmdFileInterface;
    private subscription: Subscription;

    constructor(
        private dialogRef: MatDialogRef<PixieComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private pixieControllerService: PixieControllerService
    ) {
        dialogRef.disableClose = true;
        this.media = data.media;
    }

    ngOnInit(): void {
        this.pixieControllerService.open(this.media).finally(() => this.dialogRef.close());

        PixieDomService.show(document.querySelector('#pixie-editor'));

        this.subscription = EditorControllerService.selectFileEvent().subscribe((file: SmdFileInterface) => {
            this.pixieControllerService.addFile(file.url);
        });
    }

    ngOnDestroy() {
        PixieDomService.hide();
        this.subscription.unsubscribe();
    }
}
