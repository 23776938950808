import {ModelAbstract} from '../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Helpers} from '../../services/helpers';
import {Token} from '../../services/token';
import {GeoLocationResponseInterface} from './post-actions.interfaces';
import {DraftType, StatusType} from './post-actions';
import {Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import obj2fd from 'obj2fd';

export type PostStatus = 'waitingForApproval' | 'posted' | 'notApproved' | 'inactive' | 'approved';

export type PostTemplateStatus = 'active' | 'inactive' | 'waitingForApproval' | 'notApproved';

export interface SetPostStatusRequest {
    postIDs?: string;
    postTemplateIDs?: string;
    status: PostStatus | PostTemplateStatus;
}

@Injectable({providedIn: 'root'})
export class PostActionsService extends ModelAbstract {
    private refreshSubject = new Subject<any>();

    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/post';
    }

    /**
     * Post/PostTemplate draft
     *
     * @param type
     * @param {object} data
     * @param ID
     * @return {Promise<any>}
     */
    draft(type: DraftType, data: object, ID: number = null): Promise<any> {
        let urls = {
            post: `${this.apiLink}`,
            postTemplate: `${this.apiLink}/template`
        };

        if (ID) {
            urls = {
                post: urls.post + `/${ID}`,
                postTemplate: urls.postTemplate + `/${ID}`
            };
        }

        return this.http.post(urls[type], obj2fd(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Set post status
     *
     * @param {SetPostStatusRequest} data
     * @return {Promise<any>}
     */
    setPostStatus(data: SetPostStatusRequest, type: StatusType): Promise<any> {
        const urls = {
            post: `${this.apiLink}/status`,
            postTemplate: `${this.apiLink}/template/status`
        };

        return this.http.post(urls[type], Helpers.objectToFormData(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Get targeting locations
     *
     * @param {string} searchValue
     * @return {Promise<GeoLocationResponseInterface>}
     */
    getLocations(searchValue: string): Promise<GeoLocationResponseInterface> {
        return this.http.get<GeoLocationResponseInterface>(`${this.apiLink}/targeting?type=adgeolocation&searchValue=${searchValue}&socialType=facebook`, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    /**
     * Delete posts by IDs
     *
     * @param {number[]} postIDs
     * @return {Promise<any>}
     */
    deletePosts(postIDs: number[]): Promise<any> {
        const data = {
            postIDs: JSON.stringify(postIDs)
        };

        return this.http.post(this.apiLink + '/delete', Helpers.objectToFormData(data), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise();
    }

    getRefreshSignal(): Observable<any> {
        return this.refreshSubject.asObservable();
    }

    requestRefresh() {
        this.refreshSubject.next();
    }

}
