import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import Utils from '~/src/app/core/utils';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {LoggedUser} from '~/src/app/services/logged-user';
import {NO_AVATAR_IMAGE_PATH} from '~/src/app/configs/configs';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PartnerInfoModalComponent} from '~/src/app/shared/components/partner-info-modal/partner-info-modal.component';
import {IAddonData, IPartnerInfo, IPlanData} from '~/src/app/modules/administration/partners/partner.interface';

@Component({
    selector: 'smd-partner-info',
    templateUrl: './partner-info.component.html',
    styleUrls: ['./partner-info.component.scss']
})
export class PartnerInfoComponent implements OnInit {
    @Input() viewTotalSubscription = false;
    partnerConfig = {};
    partnerData = {
        logo: this.partnerService.getMyPartnerData().partnerLogo || NO_AVATAR_IMAGE_PATH,
        name: this.partnerService.getMyPartnerData().partnerName,
        packageName: this.partnerService.getMyPartnerData().subscriptionName,
        bitlyToken: this.partnerService.getMyPartnerData().bitlyToken,
        partnerLimitUsage: this.partnerService.getMyPartnerData().partnerLimitUsage,
    };
    partnerInfo: IPartnerInfo = this.partnerService.getPartnerInfo(false) as IPartnerInfo;
    addOns: IAddonData[] = [];
    noAvatar = NO_AVATAR_IMAGE_PATH;
    planData: Partial<IPlanData> = {};
    trialDays: number = Utils.lodash.get(LoggedUser.getUser(), 'trialDays', -1);

    constructor(
        public language: LanguageService,
        private partnerService: PartnersService,
        private openModal: OpenModalService
    ) {
    }

    ngOnInit() {
        this.partnerConfig = this.partnerService.getPartnerConfig('all', true, this.viewTotalSubscription ? 'total' : 'plan');
        this.addOns = ((this.partnerService.getPartnerInfo(false) as IPartnerInfo).addonData || []).map(addon => {

            for (const name in (addon.config || {})) {
                const value = (addon.config || {})[name];

                if (value && value !== '-1' && !isNaN(Number(value))) {
                    addon.config[name] = Number(value) * addon.quantity;
                }
            }

            addon.config = this.partnerService.getPartnerConfig(Object.keys(addon.config), true, 'addon', {addon: addon.config});
            return addon;
        });
        this.planData = this.partnerInfo?.planData || {};
    }

    openPartnerInfoModal(event: MouseEvent) {
        this.openModal.partnerInfo(PartnerInfoModalComponent);
    }

    isBoolean(value: any): boolean {
        return Utils.lodash.isBoolean(value);
    }

    isNumber(value: any): boolean {
        return Utils.lodash.isNumber(value);
    }
}
