import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_OVERVIEW: WidgetMetricOptionType = {
    id: 'linkedin-interactions-overview',
    name: 'Interactions overview',
    resource: 'analytics.analytics.interactions',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_CLICK_COUNT: WidgetMetricOptionType = {
    id: 'linkedin-interactions-click-count',
    name: 'Interactions: Click count',
    resource: 'analytics.analytics.pageClicks',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_ENGAGEMENT: WidgetMetricOptionType = {
    id: 'linkedin-interactions-engagement',
    name: 'Interactions: Engagement',
    resource: 'analytics.analytics.pageEngagement',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_LIKE_COUNT: WidgetMetricOptionType = {
    id: 'linkedin-interactions-like-count',
    name: 'Interactions: Like count',
    resource: 'analytics.analytics.pagePostLikes',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_COMMENT_COUNT: WidgetMetricOptionType = {
    id: 'linkedin-interactions-comment-count',
    name: 'Interactions: Comment count',
    resource: 'analytics.analytics.pagePostComments',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_SHARE_COUNT: WidgetMetricOptionType = {
    id: 'linkedin-interactions-share-count',
    name: 'Interactions: Share count',
    resource: 'analytics.analytics.pagePostShares',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS: WidgetMetricOptionType = {
    id: 'linkedin-interactions-mentions',
    name: 'Interactions: Mentions',
    resource: 'analytics.analytics.mentions',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_TOTAL: WidgetMetricOptionType = {
    id: 'linkedin-interactions-mentions-total',
    name: 'Interactions: Mentions Total',
    resource: 'analytics.analytics.mentions',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_COMMENT: WidgetMetricOptionType = {
    id: 'linkedin-interactions-mentions-comment',
    name: 'Interactions: Mentions Comment',
    resource: 'analytics.analytics.mentions',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_SHARE: WidgetMetricOptionType = {
    id: 'linkedin-interactions-mentions-share',
    name: 'Interactions: Mentions Share',
    resource: 'analytics.analytics.mentions',
    socialChannel: 'linkedIn'
};

// METRIC CONFIGS

export const MetricLinkedInInteractionsOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_OVERVIEW.id,
    apiURL: '/interactions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions overview',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsClickCountConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_CLICK_COUNT.id,
    apiURL: '/page-clicks',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (click count)',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsEngagementConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_ENGAGEMENT.id,
    apiURL: '/page-engagement',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (engagement)',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsLikeCountConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_LIKE_COUNT.id,
    apiURL: '/page-post-likes',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (like count)',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsCommentCountConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_COMMENT_COUNT.id,
    apiURL: '/page-post-comments',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (comment count)',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsShareCountConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_SHARE_COUNT.id,
    apiURL: '/page-post-shares',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (share count)',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsMentionsConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS.id,
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (mentions)',
    chartConfig: {
        ...BaseChartConfig
    },
    partMetricIDs: [
        WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_COMMENT.id,
        WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_SHARE.id,
    ]
};

export const MetricLinkedInInteractionsMentionsTotalConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_TOTAL.id,
    apiURL: '/mentions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (mentions - total)',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInInteractionsMentionsCommentConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_COMMENT.id,
    apiURL: '/mentions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (mentions - comment)',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'commentMentionsCount'
    }
};

export const MetricLinkedInInteractionsMentionsShareConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_MENTIONS_SHARE.id,
    apiURL: '/mentions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Interactions (mentions - share)',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'shareMentionsCount'
    }
};
