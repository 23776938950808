import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentAbstract } from '../../services/component.abstract';
import { TemplateFolderActionsService } from './template-folder-actions.service';
import { TemplateFolderInterface } from './template-folder.interface';
import Utils from '../../core/utils';

@Injectable({providedIn: 'root'})
export class TemplateFolderActions extends ComponentAbstract
{
  constructor(
    public service: TemplateFolderActionsService,
  ) {
      super();
  }

  getItems(
    callback: (response: { folders: TemplateFolderInterface[], count: number }) => void = () => {},
    failed: (error: any) => void = () => {},
    filters: object = {}
  ) {
    this.beforeGetItems();
    this.beforeDataLoad();

    let options: any = Object.assign(this.filters, filters);
    
    /* options = Utils.queryString.stringify(options, {arrayFormat: 'bracket'});
    console.log(options); */

    this.service.getAll(options)
      .then((response: { folders: TemplateFolderInterface[], count: number }) => {
        callback(response);
        this.afterGetItems();
        this.afterDataLoad();
        this.successGetItems(response);
      })
      .catch((error: any) => {
        failed(error);
        this.afterGetItems();
        this.afterDataLoad();
        this.failedGetItems(error);
      });
  }
}