import {timeout} from 'rxjs/operators';
import {Injectable} from '~/node_modules/@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '~/node_modules/@angular/common/http';
import {environment} from '~/src/environments/environment';
import Utils from '~/src/app/core/utils';
import {Token} from '~/src/app/services/token';
import {Observable, TimeoutError} from '~/node_modules/rxjs';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {LanguageService} from '~/src/app/services/language.service';

/**
 * Available request methods
 */
type RequestMethods = 'get' | 'post' | 'put' | 'delete';

/**
 * Request datas
 */
type RequestDatas = { [key: string]: any } | FormData | string;

/**
 * Request params
 */
export interface RequestParams {
    [key: string]: string | string[] | number | number[] | boolean;
}

/**
 * Available request options
 */
interface RequestOptions {
    // HTTPClient request options
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body' | 'events' | 'response';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    withCredentials?: boolean;

    // custom options
    authorizationRequired?: boolean;
    formDataRequired?: boolean;
    timeout?: number;
    disableTimeOut?: boolean;
    showErrorModal?: boolean;
    asObservable?: boolean;
}

@Injectable()
export class BackendService {

    // every time start api URL with '/' (example: /post)
    private _apiUrl;
    private _token;
    private _disableTimeout = false;
    private readonly _customRequestOptions: RequestOptions = {
        authorizationRequired: true,
        formDataRequired: true,
        timeout: environment.requestTimeout,
        showErrorModal: true,
        disableTimeOut: false
    };
    private readonly _defaultRequestOptions: RequestOptions = {};

    constructor(
        private http: HttpClient,
        private openModal: OpenModalService
    ) {
    }

    /**
     * Disable request timeout
     */
    disableTimeout() {
        this._disableTimeout = true;
    }

    /**
     * Enable request timeout
     */
    enableTimeout() {
        this._disableTimeout = false;
    }

    /**
     * Download from smd/webroot folder by filename
     *
     * @param {string} fileName
     * @returns {Promise<null>}
     */
    downloadSample(fileName: string) {
        return this.get('/post/template/download/sample', {fileName})
            .then((response: any) => {
                if (
                    !response.file ||
                    !response.file.content ||
                    !response.file.mime ||
                    !response.file.name
                ) {
                    throw new Error('Response has no file data!');
                }

                Utils.generateFile(response.file.content, response.file.mime, response.file.name);

                return response;
            });
    }

    /**
     * Send get request
     * @param {string} url
     * @param {RequestParams} params
     * @param {RequestOptions} options
     * @returns {Promise<T>}
     */
    get<T = null>(url: string, params?: RequestParams, options?: RequestOptions): Promise<T> {
        // send request
        return this.request<T>('get', url, null, params, options) as Promise<T>;
    }

    /**
     * Send post request
     * @param {string} url
     * @param {RequestDatas} data
     * @param {RequestParams} params
     * @param {RequestOptions} options
     * @returns {Promise<T>}
     */
    post<T = null>(url: string, data: RequestDatas, params?: RequestParams, options?: RequestOptions): Promise<T> {
        // send request
        return this.request<T>('post', url, data, params, options) as Promise<T>;
    }

    /**
     * Send put request
     * @param {string} url
     * @param {RequestDatas} data
     * @param {RequestParams} params
     * @param {RequestOptions} options
     * @returns {Promise<T>}
     */
    put<T = null>(url: string, data: RequestDatas, params?: RequestParams, options?: RequestOptions): Promise<T> {
        options = options || {};
        options.formDataRequired = false;

        options.headers = options.headers || {};
        options.headers['Accept'] = 'application/json';
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';

        data = Utils.queryString.stringify(data as Object, {arrayFormat: 'bracket'});

        // send request
        return this.request<T>('put', url, data, params, options) as Promise<T>;
    }

    /**
     * Send delete request
     * @param {string} url
     * @param {RequestParams} params
     * @param {RequestOptions} options
     * @returns {Promise<any>}
     */
    delete<T = null>(url: string, params?: RequestParams, options?: RequestOptions): Promise<T> {
        // send request
        return this.request('delete', url, null, params, options) as Promise<T>;
    }

    /**
     * Get backend access token
     * @returns {string}
     */
    getToken(): string {
        return this._token || Token.getToken();
    }

    /**
     * Upload files
     * @param {string} url
     * @param {RequestDatas} data
     * @param {RequestParams} params
     * @param {RequestOptions} options
     * @return {Observable<T>}
     */
    uploadFiles<T = any>(url: string, data: RequestDatas, params?: RequestParams, options?: RequestOptions ): Observable<T> {
        options = options || {};
        options.asObservable = true;
        options.reportProgress = true;
        options.observe = 'events';
        return this.request('post', url, data, params, options) as Observable<T>;
    }

    /**
     * Base request method
     * @param {RequestMethods} method
     * @param {string} url
     * @param {RequestDatas} data
     * @param {RequestParams} params
     * @param {RequestOptions} options
     * @returns {Promise<T>}
     */
    private request<T = any>(
        method: RequestMethods,
        url: string,
        data?: RequestDatas,
        params?: RequestParams,
        options?: RequestOptions
    ): Promise<T> | Observable<any> {

        this.token = Token.getToken();

        // merge request options with defaults
        options = this.mergeRequestOptions(options);

        if (params && params instanceof Object) {
            let sort: string | Object;
            if ('sort' in params && (sort = params.sort as string)) {
                const field = (sort as string).split('-')[0];
                let order: string | number = (sort as string).split('-')[1];
                order = (order === 'asc') ? 1 : -1;
                sort = {
                    [field]: order
                };
                (params as any)['sort'] = JSON.stringify(sort);
            }
        }

        // get merged api url
        url = this.mergeQueryParamsWithUrl(url, params);

        // create form data when required
        if (!!data && !(data instanceof FormData) && options.formDataRequired) {
            data = Utils.obj2fd(data);
        }

        // remove authorization from request headers when not required
        if (!options.authorizationRequired) {
            options = this.removeAuthorization(options);
        }

        // set api URL
        this.apiUrl = url;

        // add form data to request options when existing
        if (!!data) {
            options = {
                ...options || {},
                body: data
            };
        }

        // get timeout
        const timeoutSecs = options.timeout;
        const showErrorModal = options.showErrorModal;
        const disableTimeOut = options.disableTimeOut;

        // delete custom options from request options
        options = this.deleteCustomRequestOptions(options);

        // send request
        let request = this.http.request(method, this.apiUrl, options || {});

        if (!this._disableTimeout && !disableTimeOut) {
            request = request.pipe(timeout(timeoutSecs)) as Observable<T>;
        }

        if (options.asObservable) {
            return request;
        }

        // return request as Promise
        return request.toPromise()
            .catch(error => {
                if (error instanceof TimeoutError) {
                    if (showErrorModal) {
                        //this.openModal.errorModal(DialogErrorComponent, {
                        //    message: LanguageService.getLine('core.message.timeOut')
                        //});
                        console.error("There was a timeout error.", error);
                    } else {
                        return Promise.reject({
                            error: {
                                error: {
                                    message: LanguageService.getLine('core.message.timeOut')
                                }
                            }
                        });
                    }
                }
                return Promise.reject(error);
            }) as Promise<T>;
    }

    /**
     * Get base request headers
     * @param {{[p: string]: any}} headers
     * @returns {{[p: string]: any; Authorization: string; 'Cache-Control': string; Pragma: string; Expires: string}}
     */
    private getBaseHeaders(headers?: { [key: string]: any }) {
        return {
            'Authorization': `Bearer ${this.getToken()}`,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
            ...headers || {}
        };
    }

    /**
     * Remove authorization from request headers
     * @param {RequestOptions} options
     * @returns {RequestOptions}
     */
    private removeAuthorization(options: RequestOptions) {
        try {
            delete options.headers['Authorization'];
        } catch (e) {
            return options;
        }

        return options;
    }

    /**
     * Merge request options with defaults
     * @param {RequestOptions} options
     * @returns {RequestOptions}
     */
    private mergeRequestOptions(options: RequestOptions): RequestOptions {
        return {
            ...this._defaultRequestOptions,
            ...this._customRequestOptions,
            ...options || {},
            headers: {
                ...this.getBaseHeaders(),
                ...Utils.lodash.get(options, 'headers', {}) || {}
            },
        };
    }

    /**
     * Delete custom options from request options
     * @param {RequestOptions} options
     * @returns {RequestOptions}
     */
    private deleteCustomRequestOptions(options: RequestOptions): RequestOptions {
        if (!!options) {
            for (const configName of Object.keys(this._customRequestOptions)) {
                if (Utils.lodash.has(options, configName)) {
                    delete options[configName];
                }
            }

            return options;
        }

        return options;
    }

    /**
     * Merge query params with api url
     * @param {string} url
     * @param {RequestParams} params
     * @returns {string}
     */
    private mergeQueryParamsWithUrl(url: string, params?: RequestParams): string {

        // add query params to url, when params object exist
        if (!!params) {

            // overwrite param when param is null or empty string for query not contain this param
            for (const key in params) {
                const value = params[key];

                if ([null, ''].indexOf(value as string) > -1) {
                    params[key] = undefined;
                }
            }

            url += '?' + Utils.queryString.stringify(params, {
                // array key contains '[]'
                arrayFormat: 'bracket'
            });
        }

        return url;
    }

    /**
     * Set token
     * @param {string} value
     */
    private set token(value: string) {
        this._token = value;
    }

    /**
     * Get api URL in this class
     * @returns {any}
     */
    private get apiUrl() {
        return this._apiUrl;
    }

    /**
     * Set api URL
     * @param value
     */
    private set apiUrl(value) {
        this._apiUrl = `${environment.apiUrl}/api${value}`;
    }
}
