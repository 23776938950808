import { Component, Input } from '@angular/core';
import { NotifyService } from '~/src/app/services/notify.service';
import { FormValidationService } from '~/src/app/services/form.validation.service';
import { OrganizationController } from '~/src/app/components/organization-select/organization.component';
import { OrganizationItem } from '~/src/app/components/organization-select/organization.interfaces';
import { LanguageService } from '~/src/app/services/language.service';
import { OrganisationService } from "~/src/app/modules/users/organizations/organisation.service";
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface OrganizationNode {
    name: string;
    id: string,
    children?: OrganizationNode[];
}

@Component({
    selector: 'smd-organization-checkbox-tree',
    templateUrl: './organization-checkbox-tree.component.html',
    styleUrls: ['./organization-checkbox-tree.component.scss']
})
export class OrganizationCheckboxTreeComponent {
    organizations: OrganizationItem[] = [];
    isLoading = false;

    selectAllText = "filters.select.all";
    selectAllIcon = "layers";

    treeControl = new NestedTreeControl<OrganizationNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<OrganizationNode>();

    @Input('selectedElements') selectedElements: OrganizationItem[] = [];
    @Input('unselectedElements') unselectedElements: OrganizationItem[] = [];
    @Input('lockedElements') lockedElements: OrganizationItem[] = [];
    @Input('indeterminateElements') indeterminateElements: OrganizationItem[] = [];

    constructor(
        public languageService: LanguageService,
        private organizationController: OrganizationController,
        private organizationService: OrganisationService,
    ) {
        this.getOrganizations();
        this.getOrganizationTree();
    }

    hasChild = (_: number, node: OrganizationNode) => !!node.children && node.children.length > 0;

    isIndeterminate(id) {
        return this.indeterminateElements.find(element => element.organizationID == id);
    }

    isDisabled(id) {
        return this.lockedElements.find(element => element.organizationID == id);
    }

    isChecked(id) {
        return this.selectedElements.find(element => element.organizationID == id);
    }

    ngOnChanges(changes) {
        if (changes.selectedElements) {
            this.selectAllText = this.getSelectAllText();
            this.selectAllIcon = this.getSelectAllIcon();
        }
    }

    // Look out for the reference, since we need to change the original arrays to reflect changes in the parent element
    checkboxChange(event, id) {
        if (event.checked) {
            if (!this.selectedElements.find(element => element.organizationID == id)) {
                this.selectedElements.push(this.organizations.find(element => element.organizationID == id));
            }

            if (this.unselectedElements.findIndex(element => element.organizationID == id) > -1) {
                this.unselectedElements.splice(this.unselectedElements.findIndex(element => element.organizationID == id), 1);
            }
        } else {
            if (this.selectedElements.findIndex(element => element.organizationID == id) > -1) {
                this.selectedElements.splice(this.selectedElements.findIndex(element => element.organizationID == id), 1);
            }

            if (!this.unselectedElements.find(element => element.organizationID == id)) {
                this.unselectedElements.push(this.organizations.find(element => element.organizationID == id));
            }
        }

        if (this.indeterminateElements.findIndex(element => element.organizationID == id) > -1) {
            this.indeterminateElements.splice(this.indeterminateElements.findIndex(element => element.organizationID == id), 1);
        }
    }

    getSelectAllText() {
        // deselect all if everything is selected, and select all otherwise
        if (this.organizations.every((item) => this.selectedElements?.map(org => org.organizationID).includes(item.organizationID))) {
            return LanguageService.getLine("filters.deselect.all");
        } else {
            return LanguageService.getLine("filters.select.all");
        }
    }

    getSelectAllIcon() {
        // deselect all if everything is selected, and select all otherwise
        if (this.organizations.every((item) => this.selectedElements?.map(org => org.organizationID).includes(item.organizationID))) {
            return "layers_clear";
        } else {
            return "layers";
        }
    }

    selectAll() {
        const checked = !this.organizations.every((item) => this.selectedElements?.map(org => org.organizationID).includes(item.organizationID));
        this.organizations.forEach(organization => {
            if (!this.lockedElements.find(element => element.organizationID == organization.organizationID)) {
                this.checkboxChange({ checked: checked }, organization.organizationID);
            }
        });
    }

    private getOrganizations() {
        this.isLoading = true;
        this.organizationController.getItems(
            response => {
                this.organizations = response.organizations;
                this.isLoading = false;
            },
            error => {
                NotifyService.error(FormValidationService.readError(error).message, '');
                this.isLoading = false;
            }
        );
    }

    private getOrganizationTree() {
        this.organizationService.getOrganization().then((response) => {
            this.dataSource.data = this.parseTreeObjectRecursively(response["organizationTree"]);
            this.treeControl.dataNodes = this.dataSource.data;
            this.treeControl.expandAll();
        });
    }

    // This function will parse the response from the organization-tree endpoint into the format we can use with mat-tree
    private parseTreeObjectRecursively(object) {
        let returnArray = [];
        Object.keys(object).forEach((key) => {
            let value = object[key];
            if (value.children) {
                returnArray.push({ id: parseInt(key), name: value.name, children: this.parseTreeObjectRecursively(value.children) })
            } else {
                returnArray.push({ id: parseInt(key), name: value.name });
            }

        });
        return returnArray
    }

    private getDescendantIds(node) {
        let ids = [];
        if (node.children) {
            node.children.forEach(child => {
                ids.push(child.id);
                ids = ids.concat(this.getDescendantIds(child));
            });
        }
        return ids;
    }

    isAllSelectedBelow(node) {
        let nodeAndDescendants = this.getDescendantIds(node).concat(node.id).filter(id => !this.lockedElements.find(element => element.organizationID == id));
        let selectedOrgs = this.selectedElements.map(org => org.organizationID);

        return nodeAndDescendants?.every(id => selectedOrgs?.includes(id));
    }

    selectAllDescendants(node) {
        const selectedOrgs = this.getDescendantIds(node).concat(node.id);
        const checked = !this.isAllSelectedBelow(node);
        selectedOrgs.forEach(id => {
            if (!this.lockedElements.find(element => element.organizationID == id)) {
                this.checkboxChange({ checked: checked }, id);
            }
        });
    }

    getFilterTooltip(node) {
        return LanguageService.getLine("filters." + (this.isAllSelectedBelow(node) ? "de" : "") + "select.all.child.organizations");
    }
}
