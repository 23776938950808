import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectionListComponent} from '~/src/app/core/components/collection-list/collection-list.component';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {FormControl, FormGroup} from '@angular/forms';
import {
    CollectionListSelectionChange,
    TableBodyConfig,
    TableHeadConfig
} from '~/src/app/core/components/collection-list/collection-list.interfaces';
import {LanguageService} from '~/src/app/services/language.service';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {DataTableHelpersService} from '~/src/app/core/services/data-table-helpers.service';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {ClickDecorator} from '~/src/app/core/decorators';
import {Helpers} from '~/src/app/services/helpers';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {RssGroupModel} from '~/src/app/modules/rss/models/rss-group.model';
import {RssGroupService} from '~/src/app/modules/rss/services/rss-group.service';
import {RssGroupModalFormComponent} from '~/src/app/modules/rss/components/rss-group-modal-form/rss-group-modal-form.component';
import {debounceTime} from 'rxjs/operators';
import { KeyValue } from "@angular/common";

@Component({
    selector: 'smd-rss-group-manager',
    templateUrl: './rss-group-manager.component.html',
    styleUrls: ['./rss-group-manager.component.scss'],
    providers: [
        FormHelpersService,
        DataTableHelpersService,
        ComponentHelpers
    ]
})
export class RssGroupManagerComponent implements OnInit {
    @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(CollectionListComponent) collectionList: CollectionListComponent;

    paginatorController: PaginationController = new PaginationController();
    filterNames = {
        OrderBy: 'sort',
        Keyword: 'name',
        Organization: 'organizationID'
    };
    filtersFormGroup: FormGroup = new FormGroup({
        [this.filterNames.Keyword]: new FormControl(null),
        [this.filterNames.OrderBy]: new FormControl('createDate-desc'),
        [this.filterNames.Organization]: new FormControl(null),
    });

    groupItems: RssGroupModel[] = [];

    // Column data for select
    columnVisibility: any = {
        name: {
            name: "category.name",
            visible: true,
        },
        feedItems: {
            name: "rss.feed.label.items",
            visible: true,
        },
        createdBy: {
            name: "rss.creared.by",
            visible: true,
        },
        createDate: {
            name: "core.crud.createDate",
            visible: true,
        },
        organizations: {
            name: "dashboard.organizations",
            visible: true,
        },
    };
    defaultColumns: any[] = Object.keys(this.columnVisibility);

    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
    }

    // table head
    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: 'category.name',
            enable: () => this.columnVisibility["name"].visible,
        },
        {
            nameKey: 'rss.feed.label.items',
            enable: () => this.columnVisibility["feedItems"].visible,
        },
        {
            nameKey: 'rss.creared.by',
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            nameKey: 'core.crud.createDate',
            enable: () => this.columnVisibility["createDate"].visible,
        },
        {
            nameKey: 'dashboard.organizations',
            enable: () => this.columnVisibility["organizations"].visible,
        },
        {
            nameKey: 'category.actions',
            onlyDesktop: true,
            alignment: 'right'
        }
    ];

    // table body
    tableBodyConfig: TableBodyConfig<RssGroupModel>[] = [
        {
            bindValue: 'name',
            enable: () => this.columnVisibility["name"].visible,
        },
        {
            selector: 'feedsField',
            enable: () => this.columnVisibility["feedItems"].visible,
        },
        {
            selector: 'createdBy',
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            bindValue: 'createDate',
            dateFormat: true,
            enable: () => this.columnVisibility["createDate"].visible,
        },
        {
            selector: 'organizations',
            enable: () => this.columnVisibility["organizations"].visible,
        },
        {
            staticView: 'itemActions',
            onlyDesktop: true,
            alignment: 'flex-end'
        },
    ];

    readonly defaultOrder = 'name-asc';

    constructor(
        public languageService: LanguageService,
        public formHelpers: FormHelpersService,
        public dataTableHelperService: DataTableHelpersService,
        private rssGroupService: RssGroupService,
        private openModal: OpenModalService,
        private componentHelpers: ComponentHelpers
    ) {
        this.formHelpers.formInit(this.filtersFormGroup);

        this.getRssGroups();

        // on filter change value
        this.filtersFormGroup.valueChanges
            .pipe(
                debounceTime(350)
            )
            .subscribe(() => {
                this.paginatorController.paginationOptions.pageIndex = 0;
                this.getRssGroups();
            });

        // on pagination
        this.paginatorController.onPaginationChange.subscribe(() => this.getRssGroups());
    }

    ngOnInit() {
    }

    @ClickDecorator()
    openGroupManager(event: MouseEvent, group?: RssGroupModel) {
        this.openModal.rssGroupModalForm(RssGroupModalFormComponent, group, {
            data: {
                afterSuccessAction: (response: any): void => {
                    this.getRssGroups();
                    this.emitRefresh();
                }
            }
        });
    }

    @ClickDecorator()
    removeGroup(event: MouseEvent, group: RssGroupModel) {
        this.componentHelpers.startApiAction(
            () => this.rssGroupService.remove([group.groupID]),
            {
                confirmMessageKey: 'rss.group.remove.confirm.message',
                confirmYesButtonTextKey: 'rss.group.remove.confirm.button',
                successMessageKey: 'rss.group.remove.success',
                failedMessageKey: 'rss.group.remove.failed',
                afterSuccessAction: () => {
                    this.getRssGroups(this.groupItems.length === 1);
                    this.emitRefresh();
                }
            },
            true
        );
    }

    @ClickDecorator()
    removeGroups(event: MouseEvent) {
        const groups = this.dataTableHelperService.getSelectedItems<RssGroupModel>();

        if (groups.length) {
            this.componentHelpers.startApiAction(
                () => this.rssGroupService.remove(groups.map(group => group.groupID)),
                {
                    confirmMessageKey: 'rss.group.bulkRemove.confirm.message',
                    confirmYesButtonTextKey: 'rss.group.bulkRemove.confirm.button',
                    successMessageKey: 'rss.group.bulkRemove.success',
                    failedMessageKey: 'rss.group.bulkRemove.failed',
                    afterSuccessAction: () => {
                        this.getRssGroups(groups.length === this.groupItems.length);
                        this.emitRefresh();
                    }
                },
                true
            );
        }
    }

    /**
     * @param {CollectionListSelectionChange<RssGroupModel>} event
     */
    selectionChange(event: CollectionListSelectionChange<RssGroupModel>): void {
        this.dataTableHelperService.selectedItems = event.selectedItems;
    }

    /**
     * @param {MouseEvent} event
     */
    @ClickDecorator()
    resetFilters(event: MouseEvent) {
        this.formHelpers.resetFormGroup(this.filtersFormGroup);
    }

    private getRssGroups(goToPreviousPage = false) {
        if (goToPreviousPage && this.paginatorController.paginationOptions.pageIndex !== 0) {
            this.paginatorController.paginationOptions.pageIndex -= 1;
        }

        const filters = {
            ...this.paginatorController.requestParams,
            //[this.filterNames.OrderBy]: Helpers.getOrderFormat(this.defaultOrder),
            ...this.filtersFormGroup.getRawValue()
        };

        const loader = this.openModal.loader(DialogLoaderComponent);
        this.rssGroupService.listGroups(filters)
            .then(response => {
                this.paginatorController.paginationOptions.length = response.count;

                if (!!this.collectionList) {
                    this.collectionList.clearSelection();
                }

                this.groupItems = response.groups;
            })
            .finally(() => loader.close());
    }

    /**
     * Emit refresh event
     */
    private emitRefresh(): void {
        this.refresh.emit();
    }

    /**
     * Toggle table columns
     * @param event
     * */
    toggleColumns(event): void {
        Object.keys(this.columnVisibility).forEach((column) => {
            this.columnVisibility[column].visible =
                event.value.includes(column);
        });
    }

    noItemsSelected(): boolean {
        return this.dataTableHelperService.getSelectedItems().length === 0;
    }

    getDeleteHoverMessage(): string {
        if (this.dataTableHelperService.getSelectedItems().length === 0) {
            return LanguageService.getLine("rss.group.selectAtLeastOne");
        }

        return LanguageService.getLine("rss.group.label.bulkDelete", { number: this.dataTableHelperService.selectedItems.length });
    }
}
