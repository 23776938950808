import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {TOKEN_KEY} from '../../configs/configs';
import {Token} from '../../services/token';
import {TwoFactorLogin} from '../../services/helpers';
import {LanguageService} from '~/src/app/services/language.service';
import {AuthService} from '~/src/app/modules/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {

    constructor(
        private router: Router,
        public token: Token,
        private language: LanguageService,
        private auth: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const getLanguage = () => {
            return this.language.setLanguages();
        };

        if (TwoFactorLogin.has()) {
            return getLanguage().then(() => {
                return true;
            }).catch(() => {
                return true;
            });
        }

        if (!localStorage.getItem(TOKEN_KEY)) {
            // logged in so return true
            return getLanguage().then(() => {
                return true;
            }).catch(() => {
                return true;
            });
        }

        if (this.token.isDeadPoint()) {
            return getLanguage().then(() => {
                return true;
            }).catch(() => {
                return true;
            });
        }

        if (!this.auth.isLoggedIn()) {
            // not logged in so redirect to login page with the return url
            debugger;
            this.router.navigate(['/']);
        } else if (route.url[0] && route.url[0].path === "activate-user") {
            debugger;
            this.auth.logout(true);
        }
        return getLanguage().then(() => {
            return false;
        }).catch(() => {
            return false;
        });
    }
}
