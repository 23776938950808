import {Injectable} from '@angular/core';
import {ModelAbstract} from '../../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../../services/helpers';
import {Token} from '../../../services/token';
import {FeedStatusSettingApiObject} from './autofeed.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class AutofeedService extends ModelAbstract {

    constructor(public http: HttpClient, public dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/autofeed';
    }

    /**
     * Order posts by IDs
     *
     * @param {number} sourceID
     * @param targetID
     * @return {Promise<any>}
     */
    orderPosts(sourceID: number, targetID): Promise<any> {
        const data = {
            source: sourceID,
            target: targetID
        };

        return this.http.post(this.apiLink + '/order', Helpers.objectToFormData(data), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Edit auto feed item
     *
     * @param {number} id
     * @param {object} data
     * @return {Promise<any>}
     */
    editFeedItem(id: number, data: object): Promise<any> {
        return this.http.post(this.apiLink + `/${id}`, Helpers.objectToFormData(data), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Set feed status on a social site
     *
     * @param {number} socialSiteID
     * @param {FeedStatusSettingApiObject} data
     * @return {Promise<any>}
     */
    setFeedStatus(socialSiteID: number, data: FeedStatusSettingApiObject): Promise<any> {
        return this.http.post(this.apiLink + `/settings/${socialSiteID}`, Helpers.objectToFormData(data), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Get feed status
     *
     * @param {number} socialSiteID
     * @return {Promise<any>}
     */
    getFeedStatus(socialSiteID: number): Promise<any> {
        return this.http.get(this.apiLink + `/settings?socialSiteID=${socialSiteID}`, Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Delete auto feed time items
     *
     * @param {number} postTimeID
     * @param {string} days
     * @return {Promise<Object>}
     */
    deleteFeedItem(postTimeID: number, days?: string) {
        const query = (days) ? `?days=${days}` : `?deleteAll`;
        return this.http.delete(this.apiLink + `/${postTimeID}${query}`, Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }
}
