import Utils from "~/src/app/core/utils";
import { ChartData } from "~/node_modules/@types/chart.js";
import { LanguageService } from "~/src/app/services/language.service";

export class MetricConfigHelper {
    /**
     * Generate chart data for linkedIn *by* charts
     * @param analytics
     * @param {any[]} extensionData
     * @param {string} responseKey
     * @param {string} langKeyPrefix
     * @returns {Chart.ChartData}
     */
    static generateChartDataForByCharts(
        analytics: any,
        extensionData: any[],
        responseKey: string,
        langKeyPrefix: string
    ): ChartData {
        const labels = Object.keys(analytics[responseKey]).map(
            (urn) => extensionData.find((item) => item.id === urn)?.name || 'Unknown'
        );
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        const sums: { [key: string]: number } = {};

        for (const urn in analytics[responseKey]) {
            const valueMap = analytics[responseKey][urn];

            Utils.getObjectKeys(valueMap, null, (key, value) => {
                if (!(value instanceof Object)) {
                    datas[key] = datas[key] || [];
                    datas[key].push(value);
                    sums[key] =
                        sums[key] !== undefined ? sums[key] + value : value;
                }
            });
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label:
                    LanguageService.getLine(langKeyPrefix + propertyName) +
                    ` - Sum: ${sums[propertyName]}`,
            });
        }

        return chartData;
    }
}
