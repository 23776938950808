import {Validators} from '@angular/forms';

export const LocationRadiusLimits: { km: { min: number, max: number }, mile: { min: number, max: number } } = {
    km: {
        min: 17,
        max: 80
    },
    mile: {
        min: 10,
        max: 50
    }
};

export const LocationRadiusValidators: { km: any[], mile: any[] } = {
    km: [
        Validators.min(LocationRadiusLimits.km.min),
        Validators.max(LocationRadiusLimits.km.max)
    ],
    mile: [
        Validators.min(LocationRadiusLimits.mile.min),
        Validators.max(LocationRadiusLimits.mile.max)
    ]
};
