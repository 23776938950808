import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {ComponentAbstract} from '../../services/component.abstract';
import {FileUploadService} from './file-upload.service';
import {MatDialog} from '@angular/material/dialog';
import {UploadMediaComponent} from '../upload-media/upload-media.component';
@Component({
    selector: 'smd-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: [
        '../file/file.component.scss',
        './file-upload.component.scss'
    ]
})
export class FileUploadComponent extends ComponentAbstract {
    @Output() beforeUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeUploadStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadDone: EventEmitter<any> = new EventEmitter<any>();
    @Input('availableMedia') availableMedia: any;
    @Input() isAdminMode = false;
    @Input() isCanva = false;

    constructor(
        public service: FileUploadService,
        public dialog: MatDialog
    ) {
        super();
    }

    @HostListener('click')
    openMediaUploader() {
        const dataConfig = {
            disableClose: true,
            isAdminMode: this.isAdminMode,
            isCanva: this.isCanva
        };

        if (this.availableMedia) {
            dataConfig['availableMediaLength'] = this.availableMedia.length;
        }

        this.dialog.open(UploadMediaComponent, {'data': dataConfig}).afterClosed().subscribe((wasCreate) => {

            if (wasCreate["response"] || wasCreate["carousel"]) {
                this.fileUploadDone.emit(wasCreate);
            }
        });
    }
}
