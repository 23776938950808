import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'smd-dialog-loader',
    templateUrl: './dialog-loader.component.html',
    styleUrls: ['./dialog-loader.component.scss']
})
export class DialogLoaderComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogLoaderComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: {message: string}
    ) {}
}
