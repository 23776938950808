import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { TextWidgetEntity } from "../widget/text-widget.entity";
import { ChartWidgetEntity } from "../widget/chart-widget.entity";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Configs } from "../../../configs/configs";
import { LanguageService } from "../../../services/language.service";
import { SocialSiteController } from "../../../components/social-site-select/social-site.component";
import { SocialSiteInterface } from "../../../components/social-site-select/social-site-select.component";
import { WidgetMetricSelectType } from "../widget/widget.interfaces";
import { FormValidationService } from "../../../services/form.validation.service";
import { AnalyticsService } from "~/src/app/modules/analytics/analytics.service";
import { ResourceService } from "~/src/app/directives/resource-checker/resource.service";
import { forEach } from "lodash";
import { Debounce, Helpers } from "~/src/app/services/helpers";
import { Subject } from "rxjs";
import {
    WIDGET_METRIC_SELECT_OPTIONS_AGGREGATABLE,
    WIDGET_METRIC_SELECT_OPTIONS_FACEBOOK,
    WIDGET_METRIC_SELECT_OPTIONS_INSTAGRAM,
    WIDGET_METRIC_SELECT_OPTIONS_LINKEDIN,
    WIDGET_METRIC_SELECT_OPTIONS_LINKEDIN_PERSONAL,
    WIDGET_METRIC_SELECT_OPTIONS_ORGANIZATION,
    WIDGET_TYPE_CHART,
    WIDGET_TYPE_TEXT,
} from "~/src/app/modules/analytics/widget/widget.configs";
import { SOCIAL_MEDIA_TYPE_FACEBOOK, SOCIAL_MEDIA_TYPE_INSTAGRAM, SOCIAL_MEDIA_TYPE_LINKEDIN, SOCIAL_MEDIA_TYPE_TWITTER } from "~/src/app/core/constants";
import { socialSiteAppearance } from "../../social-media-post/social-media-platforms-config";

interface DialogData {
    widget: TextWidgetEntity | ChartWidgetEntity;
    setWidgetsByWidget: Function;
}

@Component({
    templateUrl: "./dialog-edit-widget.component.html",
    styleUrls: ["./dialog-edit-widget.component.scss"],
    providers: [AnalyticsService],
})
export class DialogEditWidgetComponent
    implements OnDestroy, OnInit, AfterViewInit
{
    onInit: EventEmitter<any> = new EventEmitter<any>();
    editWidgetControlNames = {
        Name: "name",
        Content: "content",
        SocialSite: "socialSite",
        Metrics: "metrics",
        SocialChannel: "socialChannel",
        Organizations: "organizations",
        Aggregation: "aggregation", // A metric modifier, currently the only one used for organization widgets
    };
    usedControls = [this.editWidgetControlNames.Name];
    widget: TextWidgetEntity | ChartWidgetEntity;
    socialSites: SocialSiteInterface[] = [];
    socialSitesOriginal: SocialSiteInterface[] = [];

    editWidgetFormGroup: FormGroup = new FormGroup({
        [this.editWidgetControlNames.Name]: new FormControl(null, [
            Validators.required,
        ]),
    });
    editWidgetFormErrors: object = {};
    metricOptions: WidgetMetricSelectType = {};
    editorApiKey: string = Configs.keys.tinyMce;
    editorOptions = {
        skin_url: "/themes/smd/src/assets/tinymce/skins/lightgray",
        external_plugins: {
            link: "/themes/smd/src/assets/plugins/tinymce/plugins/link/plugin.min.js",
            image: "/themes/smd/src/assets/plugins/tinymce/plugins/image/plugin.min.js",
            imagetools:
                "/themes/smd/src/assets/plugins/tinymce/plugins/imagetools/plugin.min.js",
        },
        content_css: "/themes/smd/src/assets/css/tiny-mce-content.css",
        plugins: ["link image", "imagetools"],
        height: "450",
        toolbar:
            "insertfile undo redo | styleselect | bold italic | " +
            "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
        relative_urls: false,
    };
    socialTypes = Configs.socials;
    isSocialSitesShows = true;
    socialSitesLoaded = new Subject();
    socialSitesPending = false;
    socialTypeRequirementInfo = "";

    firstChangeDone = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<DialogEditWidgetComponent>,
        public dialog: MatDialog,
        public languageService: LanguageService,
        public socialSiteController: SocialSiteController,
        private analyticsService: AnalyticsService,
        public resourceService: ResourceService
    ) {
        this.initialize();
    }

    ngOnInit(): void {
        this.onInit.emit({});

        this.socialTypes = this.socialTypes.filter(
            (item) => socialSiteAppearance?.[item.id]?.analytics !== false
        );

        // add "organization" to socialTypes
        this.socialTypes.push({
            id: "organization",
            name: "Organization",
            iconClasses: "mdi mdi-account-multiple color-primary",
        });

        document
            .querySelector("html")
            .classList.add("dialog-edit-wiget-opened");
    }

    ngOnDestroy(): void {
        document
            .querySelector("html")
            .classList.remove("dialog-edit-wiget-opened");
    }

    ngAfterViewInit(): void {}

    saveWidget() {
        if (this.editWidgetFormValidation()) {
            const editWidgetValue = {
                ...this.editWidgetFormGroup.getRawValue(),
                [this.editWidgetControlNames.SocialSite]:
                    this.isUsedControl(this.editWidgetControlNames.SocialSite) ?
                    this.socialSitesOriginal.find((item) => {
                        return (
                            String(item.siteID) ===
                            this.editWidgetFormGroup
                                .get(this.editWidgetControlNames.SocialSite)
                                .value.toString()
                        );
                    }) :
                    null,
                [this.editWidgetControlNames.Organizations]:
                    this.isUsedControl(this.editWidgetControlNames.Organizations) ?
                    this.editWidgetFormGroup
                        .get(this.editWidgetControlNames.Organizations)
                        .value :
                    null,
            };

            if (
                this.isUsedControl(this.editWidgetControlNames.Aggregation) &&
                this.editWidgetFormGroup
                    .get(this.editWidgetControlNames.Aggregation)
                    .value
            ) {
                editWidgetValue.metricModifiers = ["aggregation"];
                delete editWidgetValue[this.editWidgetControlNames.Aggregation];
            }

            this.dialogRef.close(editWidgetValue);
        }
    }

    initialize() {
        if (this.data) {
            if (this.data.widget) {
                this.widget = this.data.widget;
            }
        }

        this.initEditWidgetForm();

        switch (this.widget.type) {
            case WIDGET_TYPE_TEXT:
                this.initEditTextWidgetForm();
                break;

            case WIDGET_TYPE_CHART:
                this.initEditChartWidgetForm();
                break;
        }
    }

    initEditWidgetForm() {
        this.editWidgetFormGroup
            .get(this.editWidgetControlNames.Name)
            .setValue(this.widget.name);
    }

    initEditTextWidgetForm() {
        this.addControlToEditWidgetFormGroup(
            this.editWidgetControlNames.Content,
            new FormControl(null, [Validators.required])
        );

        this.editWidgetFormGroup
            .get(this.editWidgetControlNames.Content)
            .setValue(this.widget.content);
    }

    initEditChartWidgetForm() {
        this.initSocialSites();

        this.socialSitesLoaded.subscribe(() => {
            if (this.widget.metrics) {
                this.socialTypesChange(
                    this.widget.socialChannel || "facebook",
                    false
                );
            }

            const selectedSocialType = this.editWidgetFormGroup.get(
                this.editWidgetControlNames.SocialChannel
            ).value;
            if (!!selectedSocialType) {
                this.socialTypesChange(
                    selectedSocialType,
                    !this.widget.metrics
                );
            }

            this.editWidgetFormGroup
                .get(this.editWidgetControlNames.SocialSite)
                .setValue(
                    !!this.widget.socialSiteID
                        ? this.widget.socialSiteID.toString()
                        : null
                );
        });

        //  Add social site form control to form group
        this.addControlToEditWidgetFormGroup(
            this.editWidgetControlNames.SocialSite,
            new FormControl(null, [Validators.required])
        );

        let socialType;
        if (this.widget.metrics) {
            let optionLinkedIn;

            forEach(WIDGET_METRIC_SELECT_OPTIONS_LINKEDIN, (options) => {
                const findedOption = options.find(
                    (option) => option.id === this.widget.metrics
                );

                if (!!findedOption) {
                    optionLinkedIn = findedOption;
                }
            });

            forEach(WIDGET_METRIC_SELECT_OPTIONS_FACEBOOK, (options) => {
                forEach(options, (option) => {
                    if (option && option.id) {
                        socialType = option.socialChannel;
                    }
                });
            });

            if (!!optionLinkedIn) {
                socialType = optionLinkedIn.socialChannel;
            }

            this.metricsChange();
        }

        //  Add social site form control to form group
        this.addControlToEditWidgetFormGroup(
            this.editWidgetControlNames.SocialChannel,
            //new FormControl(socialType || this.widget.socialChannel || null, [
            new FormControl(this.widget.socialChannel || null, [
                Validators.required,
            ])
        );

        //  Add metrics form controls to form group
        this.addControlToEditWidgetFormGroup(
            this.editWidgetControlNames.Metrics,
            new FormControl(this.widget.metrics || null, [Validators.required])
        );

        // this.editWidgetFormGroup.get(this.editWidgetControlNames.Metrics).setValue(this.widget.metrics);
    }

    editWidgetFormValidation(): boolean {
        if (this.editWidgetFormGroup.valid) {
            return true;
        }

        this.editWidgetFormErrors = FormValidationService.getMessages(
            this.editWidgetFormGroup.controls
        );

        forEach(this.editWidgetFormErrors, (value, controlName) => {
            this.editWidgetFormGroup.get(controlName).markAsTouched();
        });

        return false;
    }

    initSocialSites() {
        this.socialSitesPending = true;
        this.socialSiteController.getItems(
            (response) => {
                var socialSites = (
                    response.socialSites as SocialSiteInterface[]
                )

                // Remove personal profiles for now, since we don't have access to that API.
                socialSites = socialSites.filter(item => !item.pageID.includes('urn:li:person'));

                this.socialSites = Helpers.orderBy(socialSites, "name");
                this.socialSitesOriginal = Helpers.orderBy(socialSites, "name");
                this.socialSitesLoaded.next();
                this.socialSitesPending = false;
            },
            (error) => {
                this.socialSiteController.service.showErrorAlert(error);
                this.socialSitesPending = false;
            }
        );
    }

    addControlToEditWidgetFormGroup(
        controlName: string,
        control: AbstractControl
    ) {
        this.editWidgetFormGroup.addControl(controlName, control);
        this.usedControls.push(controlName);
    }

    isUsedControl(controlName: string) {
        return this.usedControls.indexOf(controlName) > -1;
    }

    removeControlFromEditWidgetFormGroup(controlName: string) {
        this.editWidgetFormGroup.removeControl(controlName);
        this.usedControls = this.usedControls.filter(
            (control) => control !== controlName
        );
    }

    getSocialSite({ id, name }: { id?: any; name?: any }) {
        if (name) {
            return this.socialSites.find((site) => site.name === name);
        }

        if (id) {
            return this.socialSites.find((site) => site.siteID === id);
        }

        return null;
    }

    @Debounce()
    socialTypesChange($event, clearSocialSite = true) {
        // change controls
        if (this.isUsedControl(this.editWidgetControlNames.SocialSite) && $event == "organization") {
            this.removeControlFromEditWidgetFormGroup(
                this.editWidgetControlNames.SocialSite
            );
            this.addControlToEditWidgetFormGroup(
                this.editWidgetControlNames.Organizations,
                new FormControl(null, [Validators.required])
            )

            if (!!this.widget.organizations && !!this.widget.availableOrganizations) {
                this.widget.organizations = this.widget.organizations.filter(value => this.widget.availableOrganizations.includes(value));
            }

            if (!this.firstChangeDone) {
                this.editWidgetFormGroup
                .get(this.editWidgetControlNames.Organizations)
                .setValue(
                    !!this.widget.organizations
                        ? this.widget.organizations
                        : null
                );

                this.firstChangeDone = true;
            } else {
                this.editWidgetFormGroup
                .get(this.editWidgetControlNames.Metrics)
                .setValue(null);
            }
        } else if (!this.isUsedControl(this.editWidgetControlNames.SocialSite) && $event != "organization") {
            this.addControlToEditWidgetFormGroup(
                this.editWidgetControlNames.SocialSite,
                new FormControl(null, [Validators.required])
            );
            this.removeControlFromEditWidgetFormGroup(
                this.editWidgetControlNames.Organizations
            );
        }

        if (clearSocialSite && this.isUsedControl(this.editWidgetControlNames.SocialSite)) {
            this.editWidgetFormGroup
                .get(this.editWidgetControlNames.SocialSite)
                .setValue(null);
        }

        this.socialTypeRequirementInfo = "";
        switch ($event) {
            case "facebook":
                this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_FACEBOOK;
                this.socialSites = this.socialSitesOriginal.filter(
                    (site) => site.socialType === "facebook"
                );
                break;
            case "linkedIn":
                this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_LINKEDIN;
                this.socialSites = this.socialSitesOriginal.filter(
                    (site) => site.socialType === "linkedIn"
                );
                break;
            case "instagram":
                this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_INSTAGRAM;
                this.socialSites = this.socialSitesOriginal.filter(
                    (site) => site.socialType === "instagram"
                );
                this.socialTypeRequirementInfo = this.languageService.getLine('analytics.instagram.atLeast100Followers.message');
                break;
            case "organization":
                this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_ORGANIZATION;
                this.socialSites = [];
                break;
            default:
                this.metricOptions = {};
                this.socialSites = [];
                break;
        }
    }

    @Debounce()
    socialSiteChange() {
        let chosenSite = this.socialSitesOriginal.filter(
            (site) =>
                site.siteID ==
                this.editWidgetFormGroup.get(
                    this.editWidgetControlNames.SocialSite
                ).value
        )?.[0];
        if (chosenSite) {
            if (chosenSite.socialType == SOCIAL_MEDIA_TYPE_LINKEDIN) {
                if (chosenSite.pageID.includes("urn:li:person")) {
                    this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_LINKEDIN_PERSONAL;
                } else {
                    this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_LINKEDIN;
                }
            } else if (chosenSite.socialType == SOCIAL_MEDIA_TYPE_FACEBOOK) {
                this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_FACEBOOK;
            } else if (chosenSite.socialType == SOCIAL_MEDIA_TYPE_INSTAGRAM) {
                this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_INSTAGRAM;
            }
        } else {
            this.metricOptions = WIDGET_METRIC_SELECT_OPTIONS_ORGANIZATION;
        }
    }

    @Debounce()
    metricsChange($event = null) {
        let chosenMetric = this.editWidgetFormGroup.get(this.editWidgetControlNames.Metrics).value

        if (WIDGET_METRIC_SELECT_OPTIONS_AGGREGATABLE.includes(chosenMetric)) {
            if (!this.isUsedControl(this.editWidgetControlNames.Aggregation)) {
                this.addControlToEditWidgetFormGroup(
                    this.editWidgetControlNames.Aggregation,
                    new FormControl(null)
                );

                if (this.widget.metricModifiers) {
                    this.editWidgetFormGroup
                        .get(this.editWidgetControlNames.Aggregation)
                        .setValue(
                            !!this.widget.metricModifiers.includes(this.editWidgetControlNames.Aggregation)
                        );
                }
            }
        } else {
            if (this.isUsedControl(this.editWidgetControlNames.Aggregation)) {
                this.removeControlFromEditWidgetFormGroup(
                    this.editWidgetControlNames.Aggregation
                );
            }
        }
    }
}
