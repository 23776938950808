import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
    selector: '[smdPositioning]'
})
export class PositioningDirective {
    @Input() target: HTMLElement;

    @HostBinding('style.top') get top() {
        const top = this.getRect()?.top + (this.getRect()?.height || 0);
        return top ? `${top}px` : top;
    }

    @HostBinding('style.left') get left() {
        const left = this.getRect()?.left;
        return left ? `${left}px` : left;
    }

    constructor() {}

    private getRect(): DOMRect {
        return this.target?.getBoundingClientRect() || {} as any;
    }
}
