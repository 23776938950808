import { Injectable, TemplateRef } from "@angular/core";
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from "@angular/material/dialog";
import {
    ChooserModalOptions,
    ConfirmModalOptions,
    ErrorModalOptions,
    SuccessModalOptions,
} from "~/src/app/modules/social-media-post/open-modal.interfaces";
import { ComponentType } from "@angular/cdk/portal";
import { PostManagerDialogData } from "~/src/app/modules/social-media-post/post-manager/post-manager.interfaces";
import { PostTemplateManagerDialogData } from "~/src/app/modules/social-media-post/post-template-manager/post-template-manager.interfaces";
import { Observable } from "rxjs";
import { SocialMediaPlatformChooserComponent } from "~/src/app/modules/social-media-post/social-media-platform-chooser/social-media-platform-chooser.component";
import { PostPreviewDialogData } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.interfaces";
import { PartnerManagerDialogData } from "~/src/app/modules/administration/partners/partner-manager/partner-manager.interfaces";
import { PackageEditorDialogData } from "~/src/app/modules/administration/packages/package-editor/package-editor.interfaces";
import { Addon } from "~/src/app/modules/administration/addons.interfaces";
import { Plan } from "~/src/app/modules/administration/plans.interfaces";
import { PartnerChooserDialogData } from "~/src/app/modules/administration/partners/partner-chooser/partner-chooser.interfaces";
import { DefaultRole } from "~/src/app/modules/administration/default-roles/default-roles.interfaces";
import { DefaultRoleManagerDialogData } from "~/src/app/modules/administration/default-roles/default-role-manager/default-role-manager.interface";
import { PostTemplateImportDialogData } from "~/src/app/modules/administration/post-template-import/post-template-import.interfaces";
import { ResourceSeparatorDialogData } from "~/src/app/modules/administration/media-separator/resource-separator.interfaces";
import { PartnerAnalyticsDialogData } from "~/src/app/modules/administration/partners/partner-analytics/partner-analytics.model";
import { Partner } from "~/src/app/shared/types/partners.model";
import { PartnerConfigViewsDialogData } from "~/src/app/modules/administration/partners/partner-config-views/partner-config-views.interfaces";
import { PartnerSubscriptionDialogData } from "~/src/app/modules/administration/partners/partner-subscription/partner-subscription.interfaces";
import { CategoryManager as CategoryManagerDialogData } from "~/src/app/modules/categories/category-manager/category-manager";
import { Category } from "~/src/app/modules/categories/categories";
import { DashboardShareDialogData } from "~/src/app/modules/analytics/share-dashboard/share-dashboard";
import { SetTemplatesToPartnersInterfaces } from "~/src/app/modules/administration/set-templates-to-partners/set-templates-to-partners";
import { BulkUploadManager } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager";
import { BulkItemEditor } from "~/src/app/modules/posts/bulk-upload/item-editor/item-editor";
import { BulkImportItemModel } from "~/src/app/modules/posts/bulk-import-item.model";
import { HashtagModel } from "~/src/app/modules/hashtag/hashtag.model";
import { InstagramLocationModel } from "~/src/app/modules/instagram-location/instagram-location.model";
import { IHashtagModalFormDialogData } from "~/src/app/modules/hashtag/components/hashtag-modal-form/hashtag-modal-form.interface";
import { HashtagCollectionModel } from "~/src/app/modules/hashtag/hashtag-collection.model";
import { RssFeedModel } from "~/src/app/modules/rss/models/rss-feed.model";
import {
    IRSSFeedModalDialogData,
    IRSSManagerModalData,
} from "~/src/app/modules/rss/types/rss-feed.interface";
import { RssGroupModel } from "~/src/app/modules/rss/models/rss-group.model";
import { IRSSGroupModalDialogData } from "~/src/app/modules/rss/types/rss-group.interface";
import {
    IScheduleRulePresetChooserDialogData,
    IScheduleRulePresetModalFormData,
} from "~/src/app/modules/autofeed/interfaces/schedule-rule.interface";
import { ScheduleRulePresetModel } from "~/src/app/modules/autofeed/models/schedule-rule-preset.model";
import { ISocialSiteChooserDialogData } from "~/src/app/shared/components/social-site-chooser-modal/social-site-chooser-modal";
import { IDialogInfoData } from "~/src/app/components/dialog-info/dialog-info.interface";
import { TemplateOrganizationChooserDialogData } from "../posts/post-template/template-organization-chooser/template-organization-chooser.interfaces";
import { PostTemplateFolderManagerDialogData, PostTemplateFolderSelectDialogData, PostTemplateFolderShareDialogData } from "../posts/template-folder.interface";

type ModalType<T = any> = ComponentType<T> | TemplateRef<T>;

@Injectable({ providedIn: "root" })
export class OpenModalService {
    constructor(private dialog: MatDialog) {}

    /**
     * Open loader modal
     * @param {ModalType} modalComponent
     * @param {{}} config
     * @return {MatDialogRef<any, any>}
     */
    loader(modalComponent: ModalType, config = {}) {
        return this.dialog.open(modalComponent, {
            disableClose: true,
            panelClass: "dialog-loader-modal",
            minWidth: "200vw",
            minHeight: "200vh",
            hasBackdrop: false,
            ...config,
        });
    }

    /**
     * Open create post modal
     * @param {ModalType} modalComponent
     * @param {{}} config
     * @return {MatDialogRef<any, any>}
     */
    createPost(
        modalComponent: ModalType,
        config: MatDialogConfig<PostManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            width: "80vw",
            disableClose: true,
            autoFocus: false,
            ...config,
        });
    }

    /**
     * Open create post from template modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<PostManagerDialogData>} config
     * @return {MatDialogRef<any, any>}
     */
    createPostFromTemplate(
        modalComponent: ModalType,
        config: MatDialogConfig<PostManagerDialogData> = {}
    ) {
        return this.createPost(modalComponent, {
            ...config,
            data: {
                createFromTemplate: true,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open create post modal for edit post
     * @param {ModalType} modalComponent
     * @param post
     * @param {{}} config
     * @return {MatDialogRef<any, any>}
     */
    editPost(
        modalComponent: ModalType,
        post: any,
        config: MatDialogConfig<PostManagerDialogData> = {}
    ) {
        return this.createPost(modalComponent, {
            ...config,
            data: {
                post: post,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open create post modal for post copy
     * @param {ModalType} modalComponent
     * @param post
     * @param {{}} config
     * @return {MatDialogRef<any, any>}
     */
    copyPost(
        modalComponent: ModalType,
        post: any,
        config: MatDialogConfig<PostManagerDialogData> = {}
    ) {
        return this.createPost(modalComponent, {
            ...config,
            data: {
                post: post,
                isCopy: true,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open create post modal for comment to post
     * @param {ModalType} modalComponent
     * @param post
     * @param {{}} config
     * @return {MatDialogRef<any, any>}
     */
    commentToPost(
        modalComponent: ModalType,
        post: any,
        config: MatDialogConfig<PostManagerDialogData> = {}
    ) {
        return this.createPost(modalComponent, {
            width: "auto",
            maxHeight: "80vh",
            maxWidth: "min(65vw, 1200px)",
            ...config,
            data: {
                post: post,
                isCommentMode: true,
                ...(config.data || {}),
            },
            panelClass: "dialog-comment-modal",
        });
    }

    /**
     * Open schedule rule preset modal form
     * @param {ModalType} modalComponent
     * @param {ScheduleRulePresetModel} rulePreset
     * @param {MatDialogConfig<IScheduleRulePresetModalFormData>} config
     * @return {MatDialogRef<T, any>}
     */
    scheduleRulePreset(
        modalComponent: ModalType,
        rulePreset: ScheduleRulePresetModel = null,
        config: MatDialogConfig<IScheduleRulePresetModalFormData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            width: "650px",
            maxWidth: "100vw",
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                rulePreset,
                ...(config?.data || {}),
            },
        });
    }

    /**
     * Open social site chooser modal
     * @param {ModalType} modalComponent
     * @param {ISocialSiteChooserDialogData} data
     * @param {MatDialogConfig<ISocialSiteChooserDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    socialSiteChooser(
        modalComponent: ModalType,
        data: ISocialSiteChooserDialogData,
        config: MatDialogConfig<ISocialSiteChooserDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            width: "460px",
            maxWidth: "100vw",
            autoFocus: true,
            disableClose: false,
            ...config,
            data: {
                multiple: false,
                ...data,
            },
        });
    }

    /**
     * Open schedule rule preset chooser
     * @param {ModalType} modalComponent
     * @param {IScheduleRulePresetChooserDialogData} data
     * @param {MatDialogConfig<IScheduleRulePresetChooserDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    scheduleRulePresetChooser(
        modalComponent: ModalType,
        data: IScheduleRulePresetChooserDialogData,
        config: MatDialogConfig<IScheduleRulePresetChooserDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            width: "650px",
            maxWidth: "100vw",
            autoFocus: false,
            disableClose: false,
            ...config,
            data: {
                multiple: false,
                ...data,
            },
        });
    }

    /**
     * Open confirm modal
     * @param {ModalType} modalComponent
     * @param {ConfirmModalOptions} options
     * @return {MatDialogRef<any, any>}
     */
    confirmModal(modalComponent: ModalType, options: ConfirmModalOptions) {
        return this.dialog.open(modalComponent, {
            data: {
                message: options.message,
                yesButtonText: options.yesButtonText,
                noButtonText: options.noButtonText,
            },
            ...(options.options || {}),
        });
    }

    /**
     * Open chooser modal
     * @param {ModalType} modalComponent
     * @param {ChooserModalOptions} options
     * @return {MatDialogRef<any, any>}
     */
    chooserModal(modalComponent: ModalType, options: ChooserModalOptions) {
        return this.dialog.open(modalComponent, {
            data: {
                title: options.title || null,
                message: options.message,
                options: options.options,
                cancelButtonText: options.cancelButtonText || null,
                confirmButtonText: options.confirmButtonText || null,
                displayChooser: options.displayChooser,
            },
        });
    }

    /**
     * Open success modal
     * @param {ModalType} modalComponent
     * @param {SuccessModalOptions} options
     * @return {MatDialogRef<any, any>}
     */
    successModal(modalComponent: ModalType, options: SuccessModalOptions) {
        return this.dialog.open(modalComponent, {
            ...(options.options || {}),
            data: {
                message: options.message,
                title: options.title || null,
            },
        });
    }

    /**
     * Open information modal
     * @param {ModalType} modalComponent
     * @param {IDialogInfoData} data
     * @param {MatDialogConfig<IDialogInfoData>} options
     * @return {MatDialogRef<T, any>}
     */
    infoModal(
        modalComponent: ModalType,
        data: IDialogInfoData,
        options: MatDialogConfig<IDialogInfoData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            ...options,
            data,
        });
    }

    /**
     * Open error modal
     * @param {ModalType} modalComponent
     * @param {ErrorModalOptions} options
     * @return {MatDialogRef<any, any>}
     */
    errorModal(modalComponent: ModalType, options: ErrorModalOptions) {
        return this.dialog.open(modalComponent, {
            ...(options.options || {}),
            data: {
                title: options.title || null,
                message: options.message,
            },
        });
    }

    /**
     * Open choosing canva platform
     * @return string

    createCanvaDesign() {
        return new Promise<MatDialogRef<ModalType>>((resolve, reject) => {
            this.socialMediaPlatformChooser(SocialMediaPlatformChooserComponent).afterClosed().subscribe(platform => {
                if (!!platform) {
                    resolve(platform);
                }
            });
        });
    }*/

    /**
     * Open create post template modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<PostTemplateManagerDialogData>} config
     * @return {Observable<MatDialogRef<ModalType>>}
     */
    createTemplate(
        modalComponent: ModalType,
        config: MatDialogConfig<PostTemplateManagerDialogData> = {}
    ) {
        return new Promise<MatDialogRef<ModalType>>((resolve, reject) => {
            this.socialMediaPlatformChooser(SocialMediaPlatformChooserComponent)
                .afterClosed()
                .subscribe((platform) => {
                    if (!!platform) {
                        const createTemplateModal = this.createTemplateRaw(
                            modalComponent,
                            {
                                ...config,
                                data: {
                                    initialPlatform: platform,
                                    ...(config.data || {}),
                                },
                            }
                        );

                        resolve(createTemplateModal);
                    }
                });
        });
    }

    /**
     * Open create post template modal for template edit
     * @param {ModalType} modalComponent
     * @param template
     * @param {MatDialogConfig<PostTemplateManagerDialogData>} config
     * @return {MatDialogRef<any, any>}
     */
    editTemplate(
        modalComponent: ModalType,
        template: any,
        config: MatDialogConfig<PostTemplateManagerDialogData> = {}
    ) {
        return this.createTemplateRaw(modalComponent, {
            ...config,
            data: {
                template: template,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open create template modal for copy template
     * @param {ModalType} modalComponent
     * @param template
     * @param {MatDialogConfig<PostTemplateManagerDialogData>} config
     * @return {Observable<MatDialogRef<ModalType>>}
     */
    copyTemplate(
        modalComponent: ModalType,
        template: any,
        config: MatDialogConfig<PostTemplateManagerDialogData> = {}
    ) {
        return new Promise<MatDialogRef<ModalType>>((resolve, reject) => {
            this.socialMediaPlatformChooser(SocialMediaPlatformChooserComponent)
                .afterClosed()
                .subscribe((platform) => {
                    if (!!platform) {
                        template.socialType = platform.id; // set the socialType so preview works
                        template.socialTypeMessage = platform.name;

                        const modal = this.editTemplate(
                            modalComponent,
                            template,
                            {
                                ...config,
                                data: {
                                    initialPlatform: platform,
                                    isCopy: true,
                                    ...(config.data || {}),
                                },
                            }
                        );

                        resolve(modal);
                    }
                });
        });
    }

    /**
     * Open create template modal for comment to post template
     * @param {ModalType} modalComponent
     * @param template
     * @param {MatDialogConfig<PostTemplateManagerDialogData>} config
     * @return {MatDialogRef<any, any>}
     */
    commentTemplate(
        modalComponent: ModalType,
        template: any,
        config: MatDialogConfig<PostTemplateManagerDialogData> = {}
    ) {
        return this.createTemplateRaw(modalComponent, {
            width: "auto",
            maxHeight: "80vh",
            maxWidth: "min(65vw, 1200px)",
            ...config,
            data: {
                template: template,
                isCommentMode: true,
                ...(config.data || {}),
            },
            panelClass: "dialog-comment-modal",
        });
    }

    /**
     * Open entity preview modal
     * @param {ModalType} modalComponent
     * @param entity
     * @param {MatDialogConfig<PostPreviewDialogData>} config
     * @returns {MatDialogRef<T, any>}
     */
    entityPreview(
        modalComponent: ModalType,
        entity: any,
        config: MatDialogConfig<PostPreviewDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            width: "auto",
            maxHeight: "80vh",
            maxWidth: "min(65vw, 1200px)",
            autoFocus: false,
            ...config,
            data: {
                post: entity,
                ...(config.data || {}),
            },
            panelClass: "dialog-comment-modal",
        });
    }

    /**
     * Open create partner modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    createPartner(
        modalComponent: ModalType,
        config: MatDialogConfig<PartnerManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "650px",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open edit partner modal
     * @param {ModalType} modalComponent
     * @param partner
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    editPartner(
        modalComponent: ModalType,
        partner: any,
        config: MatDialogConfig<PartnerManagerDialogData> = {}
    ) {
        return this.createPartner(modalComponent, {
            ...config,
            data: {
                partner: partner,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open category manager modal
     * @param modalComponent
     * @param category
     * @param config
     */
    manageCategory(
        modalComponent: ModalType,
        category?: Category,
        config: MatDialogConfig<CategoryManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "650px",
            ...config,
            data: {
                category: category,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open RSS feed modal form
     * @param {ModalType} modalComponent
     * @param {RssFeedModel} feed
     * @param {MatDialogConfig<IRSSFeedModalDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    rssFeedModalForm(
        modalComponent: ModalType,
        feed?: RssFeedModel,
        config: MatDialogConfig<IRSSFeedModalDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "425px",
            maxWidth: "650px",
            ...config,
            data: {
                feed: feed,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open RSS group modal form
     * @param {ModalType} modalComponent
     * @param {RssGroupModel} group
     * @param {MatDialogConfig<IRSSGroupModalDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    rssGroupModalForm(
        modalComponent: ModalType,
        group?: RssGroupModel,
        config: MatDialogConfig<IRSSGroupModalDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "425px",
            maxWidth: "650px",
            ...config,
            data: {
                group: group,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open instagram location modal form
     * @param {ModalType} modalComponent
     * @param {InstagramLocationModel} hashtag
     * @param {MatDialogConfig<IHashtagModalFormDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    instagramLocationModalForm(
        modalComponent: ModalType,
        instagramLocation?: any,
        config: MatDialogConfig<IHashtagModalFormDialogData> = {}
    ) {
        console.log(instagramLocation);
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "425px",
            maxWidth: "650px",
            ...config,
            data: {
                item: instagramLocation,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open hashtag modal form
     * @param {ModalType} modalComponent
     * @param {HashtagModel} hashtag
     * @param {MatDialogConfig<IHashtagModalFormDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    hashtagModalForm(
        modalComponent: ModalType,
        hashtag?: HashtagModel,
        config: MatDialogConfig<IHashtagModalFormDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "425px",
            maxWidth: "650px",
            ...config,
            data: {
                item: hashtag,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open hashtag collection modal form
     * @param {ModalType} modalComponent
     * @param {HashtagModel} hashtag
     * @param {MatDialogConfig<IHashtagModalFormDialogData>} config
     * @return {MatDialogRef<T, any>}
     */
    hashtagCollectionModalForm(
        modalComponent: ModalType,
        hashtag?: HashtagCollectionModel,
        config: MatDialogConfig<IHashtagModalFormDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "425px",
            maxWidth: "650px",
            ...config,
            data: {
                item: hashtag,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open hashtag collection manager
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<any>} config
     * @return {MatDialogRef<T, any>}
     */
    hashtagCollectionManager(
        modalComponent: ModalType,
        config: MatDialogConfig<any> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open RSS manager modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<any>} config
     * @return {MatDialogRef<T, any>}
     */
    rssManager(
        modalComponent: ModalType,
        config: MatDialogConfig<IRSSManagerModalData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open partner analytics modal
     * @param modalComponent
     * @param partner
     * @param config
     */
    partnerAnalytics(
        modalComponent: ModalType,
        partners: Partner[],
        config: MatDialogConfig<PartnerAnalyticsDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "80vw",
            ...config,
            data: {
                partners: partners,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open partner info modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<any>} config
     * @return {MatDialogRef<T, any>}
     */
    partnerInfo(modalComponent: ModalType, config: MatDialogConfig<any> = {}) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "650px",
            maxWidth: "80vw",
            ...config,
        });
    }

    /**
     * Manage subscription
     * @param modalComponent
     * @param partner
     * @param config
     */
    manageSubscription(
        modalComponent: ModalType,
        partner: Partner,
        config: MatDialogConfig<PartnerSubscriptionDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "80vw",
            ...config,
            data: {
                partner: partner,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open edit package modal
     * @param {ModalType} modalComponent
     * @param {Plan | Addon} packet
     * @param {MatDialogConfig<PackageEditorDialogData>} config
     * @return {MatDialogRef<any, any>}
     */
    editPackage(
        modalComponent: ModalType,
        packet: Plan | Addon,
        config: MatDialogConfig<PackageEditorDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "650px",
            ...config,
            data: {
                packet: packet,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open import post templates modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    importTemplates(
        modalComponent: ModalType,
        config: MatDialogConfig<PostTemplateImportDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "650px",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open set partners to templates modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    setPartners(
        modalComponent: ModalType,
        config: MatDialogConfig<PartnerChooserDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open set organizations to templates modal
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    setOrganizationsForTemplates(
        modalComponent: ModalType,
        config: MatDialogConfig<TemplateOrganizationChooserDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open set medias to partners modal
     * @param modalComponent
     * @param config
     */
    setResourcesToPartners(
        modalComponent: ModalType,
        config: MatDialogConfig<ResourceSeparatorDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open set templates to partner(s)
     *
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<SetTemplatesToPartnersInterfaces.DialogData>} config
     * @returns {MatDialogRef<T, any>}
     */
    setTemplatesToPartners(
        modalComponent: ModalType,
        config: MatDialogConfig<SetTemplatesToPartnersInterfaces.DialogData>
    ) {
        return this.dialog.open(modalComponent, {
            width: "60vw",
            autoFocus: false,
            disableClose: true,
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open default role create form
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    createDefaultRole(
        modalComponent: ModalType,
        config: MatDialogConfig<DefaultRoleManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "700px",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open default role edit form
     * @param {ModalType} modalComponent
     * @param {DefaultRole} defaultRole
     * @param {MatDialogConfig<DefaultRoleManagerDialogData>} config
     * @return {MatDialogRef<any, any>}
     */
    editDefaultRole(
        modalComponent: ModalType,
        defaultRole: DefaultRole,
        config: MatDialogConfig<DefaultRoleManagerDialogData> = {}
    ) {
        return this.createDefaultRole(modalComponent, {
            ...config,
            data: {
                defaultRole: defaultRole,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open partner config view modal
     * @param modalComponent
     * @param partner
     * @param defaultRole
     * @param config
     */
    partnerCongfigView(
        modalComponent: ModalType,
        partner: Partner,
        config: MatDialogConfig<PartnerConfigViewsDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            ...config,
            data: {
                partner: partner,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open share dashboard(s) modal
     * @param {ModalType} modalComponent
     * @param {{}} config
     * @return {MatDialogRef<any, any>}
     */
    shareDashboards(
        modalComponent: ModalType,
        config: MatDialogConfig<DashboardShareDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "80vw",
            minWidth: "960px",
            ...config,
        });
    }

    manageEmailNotification(
        modalComponent: ModalType,
        user,
        config: MatDialogConfig<CategoryManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            width: "80vw",
            ...config,
            data: {
                user: user,
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open bulk upload manager
     *
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<BulkUploadManager.DialogData>} config
     * @returns {MatDialogRef<T, any>}
     */
    bulkUploadManager(
        modalComponent: ModalType,
        config: MatDialogConfig<BulkUploadManager.DialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "80vw",
            minWidth: "960px",
            ...config,
        });
    }

    /**
     * Open bulk upload item editor
     *
     * @param {ModalType} modalComponent
     * @param {BulkUploadManager.ViewImportItem} entity
     * @param {MatDialogConfig<BulkUploadManager.DialogData>} config
     * @returns {MatDialogRef<T, any>}
     */
    bulkItemEditor(
        modalComponent: ModalType,
        entity: BulkImportItemModel,
        config: MatDialogConfig<BulkItemEditor.DialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            maxWidth: "80vw",
            minWidth: "960px",
            ...config,
            data: {
                entity: entity,
                ...config.data,
            },
        });
    }

    /**
     * Open create post template modal without social media platform chooser
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig<PostTemplateManagerDialogData>} config
     * @return {MatDialogRef<any, any>}
     */
    createTemplateRaw(
        modalComponent: ModalType,
        config: MatDialogConfig<PostTemplateManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            disableClose: true,
            autoFocus: false,
            width: "80vw",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open social media platform chooser
     * @param {ModalType} modalComponent
     * @param {MatDialogConfig} config
     * @return {MatDialogRef<any, any>}
     */
    private socialMediaPlatformChooser(
        modalComponent: ModalType,
        config: MatDialogConfig = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            //width: "333px",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    /**
     * Open create template folder modal
     * @param {ModalType} modalComponent 
     * @param {MatDialogConfig<PostTemplateManagerDialogData>} config 
     * @return {MatDialogRef<any, any>}
     */
    createTemplateFolder(
        modalComponent: ModalType,
        config: MatDialogConfig<PostTemplateFolderManagerDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "40vw",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    addTemplateToFolder(
        modalComponent: ModalType,
        config: MatDialogConfig<PostTemplateFolderSelectDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "50vw",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }

    shareFolderWithOrganizations(
        modalComponent: ModalType,
        config: MatDialogConfig<PostTemplateFolderShareDialogData> = {}
    ) {
        return this.dialog.open(modalComponent, {
            autoFocus: false,
            disableClose: true,
            width: "50vw",
            ...config,
            data: {
                ...(config.data || {}),
            },
        });
    }
}
