import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {LanguageService} from '~/src/app/services/language.service';
import {PartnerManagerComponent} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {
    PARTNER_ANALYTICS_DATA_LANGUAGE_PREFIX,
    PARTNER_ANALYTICS_RESOLUTION_DATE_FORMATS,
    PartnerAnalyticsActivity,
    PartnerAnalyticsData,
    PartnerAnalyticsDialogData,
    PartnerAnalyticsResolution,
    PartnerAnalyticsStatistic
} from '~/src/app/modules/administration/partners/partner-analytics/partner-analytics.model';
import {Partner} from '~/src/app/shared/types/partners.model';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {FormControl, FormGroup} from '~/node_modules/@angular/forms';
import {NgxDrpOptions, PresetItem, Range} from '~/node_modules/ngx-mat-daterange-picker';
import Utils from '~/src/app/core/utils';
import {Debounce} from '~/src/app/services/helpers';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';

@Component({
    selector: 'smd-partner-analytics',
    templateUrl: './partner-analytics.component.html',
    styleUrls: ['./partner-analytics.component.scss'],
    providers: [
        ComponentHelpers
    ]
})
export class PartnerAnalyticsComponent {
    partners: Partner[] = this.dialogData.partners;
    partnerDatas: { [key: number]: PartnerAnalyticsData } = {};
    partnerStatistics: { [key: number]: PartnerAnalyticsStatistic } = {};
    partnerActivities: { [key: number]: PartnerAnalyticsActivity } = {};
    displayedColumns: { [key: number]: any } = {};
    activitySource: { [key: number]: any } = {};
    filterControlNames = {
        Range: 'range',
        Resolution: 'resolution',
    };
    filterFormGroup = new FormGroup({
        [this.filterControlNames.Range]: new FormControl(null),
        [this.filterControlNames.Resolution]: new FormControl('daily')
    });
    dateRangeOptions: NgxDrpOptions;
    presets: Array<PresetItem> = [];
    dateRange: Range = {
        fromDate: new Date(Utils.moment().format('YYYY-MM-') + '01'),
        toDate: new Date(Utils.moment().format('YYYY-MM-') + '28')
    };
    tabs = ['daily', 'weekly', 'monthly'];
    dateFormats = PARTNER_ANALYTICS_RESOLUTION_DATE_FORMATS;
    getPending = false;
    currentPartner: Partner = Utils.lodash.get(this.dialogData, 'partners[0]', null);

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PartnerAnalyticsDialogData,
        public language: LanguageService,
        private dialogRef: MatDialogRef<PartnerManagerComponent>,
        private openModal: OpenModalService,
        private partnerService: PartnersService,
        private componentHelpers: ComponentHelpers
    ) {
        this.setDateRangeOptions();
        this.getAnalytics(this.dateRange);
    }

    /**
     * Export active partner analytics
     * @param event
     */
    exportAnalytics(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.componentHelpers.startApiAction(
            () => {
                return this.partnerService.exportAnalytics(
                    this.currentPartner,
                    this.filterFormGroup.get(this.filterControlNames.Resolution).value,
                    this.dateRange
                );
            },
            {
                successMessageKey: 'partner.analytics.export.success',
                failedMessageKey: 'partner.analytics.export.error'
            }
        );
    }

    /**
     * Change date range
     * @param event
     */
    changeDateRange(event: Range) {
        this.dateRange = event;
        this.getAnalytics(event);
    }

    /**
     * Active partner change
     * @param index
     */
    activePartnerChange(index: number) {
        this.currentPartner = this.partners[index];
    }

    /**
     * Analytics data tab change
     * @param event
     */
    tabChange(event: MatTabChangeEvent) {
        this.filterFormGroup.get(this.filterControlNames.Resolution).setValue(
            this.tabs[event.index]
        );
    }

    /**
     * Get analytics for partner(s)
     * @param range
     */
    @Debounce()
    getAnalytics(range: Range) {
        this.getPending = true;
        this.displayedColumns = {};
        this.activitySource = {};

        this.partnerService.getAnalytics(
            this.partners.map(partner => partner.partnerID),
            'daily',
            range
        ).then(responses => {

            for (const response of responses) {
                this.partnerDatas[response.core.accountID] = response.view.partnerData;
                this.partnerStatistics[response.core.accountID] = response.view.partnerStatistic;
                this.partnerActivities[response.core.accountID] = response.view.partnerActivity;
                this.displayedColumns[response.core.accountID] = [
                    'date',
                    ...Object.keys(Utils.lodash.get(response.view, 'partnerActivity.activityData.total', []))
                ];
                for (const resolution of Object.keys(this.getActivityDate(response.core.accountID))) {

                    if (resolution !== 'total') {
                        for (const date of Object.keys(this.getActivityDate(response.core.accountID, resolution as PartnerAnalyticsResolution))) {
                            const values = this.getActivityDate(response.core.accountID, resolution as PartnerAnalyticsResolution)[date];

                            this.activitySource[response.core.accountID] = this.activitySource[response.core.accountID] || {};

                            this.activitySource[response.core.accountID][resolution] = [
                                ...this.activitySource[response.core.accountID][resolution] || [],
                                {
                                    date: date,
                                    ...values
                                }
                            ];
                        }
                    }
                }
            }

            this.getPending = false;
        }).catch((error) => {
            this.getPending = false;

            // if the error is backend error, show error message in a modal
            const errorMessage = FormValidationService.readError(error).message;
            if (!!errorMessage) {
                this.openModal.errorModal(DialogErrorComponent, {
                    message: errorMessage
                });
            }
        });
    }

    /**
     * Get partner activities data
     */
    getActivityDate(partnerID: number, resolution?: PartnerAnalyticsResolution) {
        return !!this.partnerActivities
            ? !!resolution ? this.partnerActivities[partnerID].activityData[resolution]
                : this.partnerActivities[partnerID].activityData : {};
    }

    /**
     * Check value is JSON
     * @param value
     */
    isJson(value: any) {
        return value instanceof Object;
    }

    /**
     * Check this property is date type by propertyName
     * @param {string} key
     * @return {boolean}
     */
    isDateValueKey(key: string) {
        return [
            'lastPublishDate',
            'partnerLastLogin',
            'expiryDate'
        ].indexOf(key) > -1;
    }

    /**
     * Get analytics data language prefix
     */
    get dataLanguagePrefix() {
        return PARTNER_ANALYTICS_DATA_LANGUAGE_PREFIX;
    }

    private setDateRangeOptions() {
        const year = Utils.moment().format('YYYY');
        const month = Utils.moment().format('MM');
        const lastDayOfMonth = Utils.moment(new Date(Number(year), Number(month), 0)).format('DD');
        const today = new Date();
        const defaultFrom = new Date(`${year}-${month}-01`);
        const defaultTo = new Date(`${year}-${month}-${lastDayOfMonth}`);
        const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

        this.setupPresets();

        this.dateRangeOptions = {
            presets: this.presets,
            format: 'dd MMMM yyyy',
            range: this.dateRange,
            applyLabel: 'Submit',
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: false,
                hasBackdrop: true // do not change to false, because it is buggy
            }
            // cancelLabel: 'Cancel',
            // excludeWeekends:true,
            // fromMinMax: {fromDate:fromMin, toDate:fromMax},
            // toMinMax: {fromDate:toMin, toDate:toMax}
        };
    }

    private setupPresets() {
        const backDate = (numOfDays) => {
            const _today = new Date();

            return new Date(_today.setDate(_today.getDate() - numOfDays));
        };

        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.presets = [
            {presetLabel: 'Yesterday', range: {fromDate: yesterday, toDate: today}},
            {presetLabel: 'Last 7 Days', range: {fromDate: minus7, toDate: today}},
            {presetLabel: 'Last 30 Days', range: {fromDate: minus30, toDate: today}},
            {presetLabel: 'This Month', range: {fromDate: currMonthStart, toDate: currMonthEnd}},
            {presetLabel: 'Last Month', range: {fromDate: lastMonthStart, toDate: lastMonthEnd}}
        ];
    }
}
