import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { UserService } from "~/src/app/services/user.service";
import { OrganizationComponent } from "~/src/app/components/organization-select/organization.component";
import {
    Organization,
    OrganizationService,
} from "~/src/app/components/organization-select/organization.service";
import { RoleService } from "~/src/app/components/organization-select/role.service";
import { LanguageService } from "~/src/app/services/language.service";
import { forEach, has } from "lodash";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { ResourceService } from "~/src/app/directives/resource-checker/resource.service";
import { LoggedUser } from "~/src/app/services/logged-user";
import { ConsoleLoggerService } from "~/src/app/shared/services/log/console-logger.service";

interface OrganizationInputGroupInterface {
    organizationName: string;
    organizationRoleName: string;
    selectedID?: number|string;
}

enum ModifyUserDialogModes {
    Create = "createUser",
    Edit = "editUser",
}

@Component({
    selector: "smd-modify-user-dialog",
    templateUrl: "./modify-user-dialog.component.html",
    styleUrls: ["./modify-user-dialog.component.scss"],
})
export class ModifyUserDialogComponent implements OnInit, AfterViewInit {
    @ViewChild("userForm", { static: true })
    userForm: ElementRef<HTMLFormElement>;

    newUserForm = new FormGroup({
        userID: new FormControl(""),
        username: new FormControl({ value: "", disabled: false }, [
            Validators.required,
        ]),
        firstName: new FormControl("", [Validators.required]),
        lastName: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required]),
        mainOrganizationID: new FormControl({ value: "", disabled: false }, [
            Validators.required,
        ]),
        mainOrganizationRole: new FormControl({ value: "", disabled: true }, [
            Validators.required,
        ]),
    });

    organizationInputs: OrganizationInputGroupInterface[] = [];

    organizationInputCounter = 0;

    user: object = {};

    message: object = {};

    _organizationsComponent: OrganizationComponent;

    organizations: Array<Organization> = [];
    blackListOrganizations: Array<any> = [];

    roles = {};
    roleCollection = {};

    mode: ModifyUserDialogModes = ModifyUserDialogModes.Create;

    getOrganizationsInProgress = true;
    getOrganizationRolesInProgress = true;

    onOrganizationsLoaded: EventEmitter<any> = new EventEmitter<any>();

    mainOrganizationIdPreviousValue: undefined;
    wasValidation = false;
    requestPending = false;
    rolesLoaded = false;
    onRolesLoad: EventEmitter<any> = new EventEmitter<any>();

    addOrganizationDisabled = false;

    constructor(
        private userService: UserService,
        private roleService: RoleService,
        public dialogRef: MatDialogRef<ModifyUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _organizationsService: OrganizationService,
        private _openModal: OpenModalService,
        public resourceService: ResourceService
    ) {
        this.getRoles();
        this.organizationInit();
    }

    ngOnInit() {
        if (this.data["edit"]) {
            this.mode = ModifyUserDialogModes.Edit;
            this.newUserForm.controls["username"].disable();
            this.newUserForm.controls["userID"].setValue(
                this.data["user"]["userID"]
            );
            this.newUserForm.controls["firstName"].setValue(
                this.data["user"]["firstName"]
            );
            this.newUserForm.controls["lastName"].setValue(
                this.data["user"]["lastName"]
            );
            this.newUserForm.controls["email"].setValue(
                this.data["user"]["email"]
            );

            const formInit = () => {
                if (this.data["edit"]) {
                    const user = this.data["user"];
                    if (user.organizations) {
                        for (const organization of user.organizations) {
                            if (organization.main) {
                                this.newUserForm.controls[
                                    "mainOrganizationID"
                                ].setValue(Number(organization.organizationID));

                                let orgId = {value: organization.organizationID}
                                this.organizationChange(
                                    orgId,
                                    "mainOrganizationRole",
                                    {selectedID: organization.organizationID}
                                );

                                this.newUserForm.controls[
                                    "mainOrganizationRole"
                                ].setValue(organization.role);

                                if (
                                    !this.resourceService.checkResource(
                                        "auth.user.create",
                                        false,
                                        null,
                                        "and",
                                        organization.organizationID
                                    )
                                ) {
                                    this.newUserForm.controls[
                                        "mainOrganizationID"
                                    ].disable();
                                    this.newUserForm.controls[
                                        "mainOrganizationRole"
                                    ].disable();
                                }
                            } else {
                                this.addOrganization(
                                    organization.organizationID,
                                    organization.role
                                );
                            }
                        }
                    }
                } else {
                    this.newUserForm.controls["username"].enable();
                }
            };

            const organizationsInit = () => {
                if (this.organizations.length > 0) {
                    formInit();
                } else {
                    this.onOrganizationsLoaded.subscribe(() => {
                        formInit();
                    });
                }
            };

            if (this.rolesLoaded) {
                organizationsInit();
            } else {
                this.onRolesLoad.subscribe(() => {
                    organizationsInit();
                });
            }
        }

        this.addOrganizationDisabled = this.newUserForm.controls["mainOrganizationID"].value === "";
    }

    ngAfterViewInit() {}

    onFormSubmitNewUser(event) {
        event.preventDefault();
        this.wasValidation = true;

        const newUserChangeForm = this.newUserForm;

        const endPoint =
            this.mode === ModifyUserDialogModes.Create
                ? "createUser"
                : "setUserData";

        if (newUserChangeForm.valid) {
            this.setMessage({});

            this.requestPending = true;
            this.userService[endPoint](
                newUserChangeForm.getRawValue()
            ).subscribe(
                (response) => {
                    if (this.mode === ModifyUserDialogModes.Create) {
                        this._openModal.successModal(DialogSuccessComponent, {
                            message: LanguageService.getLine(
                                "access.management.user.new"
                            ),
                        });
                    } else {
                        this._openModal.successModal(DialogSuccessComponent, {
                            message: LanguageService.getLine(
                                "access.management.user.modify"
                            ),
                        });
                    }
                    this.requestPending = false;
                    this.dialogRef.close(true);
                },
                (error) => {
                    const errorMessage =
                        this.mode === ModifyUserDialogModes.Create
                            ? this.languageService.getLine(
                                  "access.management.user.new.error"
                              )
                            : this.languageService.getLine(
                                  "access.management.user.modify.error"
                              );

                    this._openModal.errorModal(DialogErrorComponent, {
                        title: errorMessage,
                        message: FormValidationService.readError(error).message,
                    });

                    this.setMessage(
                        FormValidationService.setFormControlsIncorrect(
                            error,
                            this.newUserForm,
                            this.message
                        )
                    );
                    this.requestPending = false;
                }
            );
        } else {
            if (!this.hasIncorrect()) {
                this.setMessage(
                    FormValidationService.getMessages(
                        newUserChangeForm.controls
                    )
                );
            }
        }
    }

    addOrganization(
        organizationId: number | string = "",
        organizationRole: string = ""
    ) {
        this.organizationInputCounter++;

        if (organizationId === "" && organizationRole === "") {
            this.addOrganizationDisabled = true;
        }

        const organizationItemGroup: OrganizationInputGroupInterface = {
            organizationName:
                "organizationIDs[" + this.organizationInputCounter + "]",
            organizationRoleName:
                "organizationRoleIDs[" + this.organizationInputCounter + "]",
        };

        organizationItemGroup["selectedID"] = undefined;

        this.newUserForm.addControl(
            organizationItemGroup.organizationName,
            new FormControl(
                {
                    value: organizationId,
                    disabled: !this.resourceService.checkResource(
                        "auth.user.create",
                        false,
                        null,
                        "and",
                        organizationId
                    ),
                },
                [Validators.required]
            )
        );

        this.newUserForm.addControl(
            organizationItemGroup.organizationRoleName,
            new FormControl(
                {
                    value: organizationRole,
                    disabled: !(organizationRole !== ""),
                },
                [Validators.required]
            )
        );

        let orgId = {value: organizationId};
        if (organizationId !== "") {
            const haveRole = this.organizationChange(
                orgId,
                organizationItemGroup.organizationRoleName,
                organizationItemGroup
            );

            if (haveRole) {
                this.organizationInputs.push(organizationItemGroup);
            } else {
                this.newUserForm.removeControl(
                    organizationItemGroup.organizationName
                );
                this.newUserForm.removeControl(
                    organizationItemGroup.organizationRoleName
                );
            }

            organizationItemGroup["selectedID"] = organizationId;
        } else {
            this.organizationInputs.push(organizationItemGroup);
        }

        if (
            !this.resourceService.checkResource(
                "auth.user.create",
                false,
                null,
                "and",
                organizationId
            )
        ) {
            this.newUserForm.controls[
                organizationItemGroup.organizationRoleName
            ]?.disable();
        }
    }

    hasResourceToModify(group) {
        return !this.resourceService.checkResource(
            "auth.user.create",
            false,
            null,
            "and",
            group.selectedID
        );
    }

    removeOrganization(group) {
        this.newUserForm.removeControl(group.organizationName);
        this.newUserForm.removeControl(group.organizationRoleName);

        const inputDelete = this.organizationInputs.findIndex(function (value) {
            return (
                value.organizationName === group.organizationName &&
                value.organizationRoleName === group.organizationRoleName
            );
        });
        if (inputDelete >= 0) {
            this.organizationInputs.splice(inputDelete, 1);
        }

        this.removeBlackListedOrganizations(group);

        let allOrgsFilled = true;

        for (let i = 0; i < this.organizationInputs.length; i++) {
            if (
                this.organizationInputs[i].selectedID === undefined
            ) {
                allOrgsFilled = false; 
            }
        }

        if (allOrgsFilled) this.addOrganizationDisabled = false;
    }

    organizationInit() {
        if (!this._organizationsComponent) {
            this._organizationsComponent = new OrganizationComponent(
                this._organizationsService
            );
        }

        this.getOrganizationsInProgress = true;

        setTimeout(() => {
            this._organizationsComponent.getItems(
                ({ organizations }: { organizations: any[] }) => {
                    this.organizations = organizations;
                    this.getOrganizationsInProgress = false;

                    this.onOrganizationsLoaded.emit();
                },
                (error) => {
                    this.getOrganizationsInProgress = false;

                    this._openModal.errorModal(DialogErrorComponent, {
                        message: "Organization cannot be retrieved.",
                    });
                }
            );
        }, 50) // we need the timeout so the mainrole loads (idk what the problem is, this is just a workaround since the issue appeared after we added caching)
    }

    organizationChange(event, roleFormControl, group) {
        if (typeof event === "undefined") {
            return;
        }

        const organizationID = event.value;

        this.removeBlackListedOrganizations(group);

        this.blackListOrganizations.push(event.value);

        if (organizationID) this.addOrganizationDisabled = false;

        if (this.newUserForm.controls[roleFormControl] instanceof FormControl) {
            this.roles[roleFormControl] = [];
            this.newUserForm.controls[roleFormControl].disable();
        }

        const role = this.roleCollection[organizationID];

        if (!role) {
            return false;
        } else {
            group.selectedID = organizationID;

            if (roleFormControl === "mainOrganizationRole") {
                this.mainOrganizationIdPreviousValue = event.value;
            }

            if (
                this.newUserForm.controls[roleFormControl] instanceof
                FormControl
            ) {
                this.newUserForm.controls[roleFormControl].enable();

                // add isModifiable to the role
                const loggedOrgs = LoggedUser.getOrganizations();
                role.forEach((roleItem) => {
                    roleItem.isModifiable = true;
                    // a non superadmin user should not be able to give superadmin roles
                    // temporary faster workaround instead of a correct one as requested in the daily
                    if (roleItem.roleID.includes(".superAdmin.") && !loggedOrgs.find((org) => org.organizationID == roleItem.organizationID).role.includes('.superAdmin.')) {
                        roleItem.isModifiable = false;
                    }
                });

                this.roles[roleFormControl] = role;
            }

            return true;
        }
    }

    removeBlackListedOrganizations(group) {
        if (this.blackListOrganizations.indexOf(group.selectedID) > -1) {
            this.blackListOrganizations.splice(
                this.blackListOrganizations.indexOf(group.selectedID),
                1
            );
        }
    }

    organizationCopy(selectedID) {
        const filteredOrganizations = this.organizations.filter((item) => {
            return (
                this.blackListOrganizations.indexOf(item.organizationID) ===
                    -1 || item.organizationID === selectedID
            );
        });

        return filteredOrganizations;
    }

    get ModifyUserDialogModes() {
        return ModifyUserDialogModes;
    }

    get languageService() {
        return LanguageService;
    }

    validateInput(): void {
        if (!this.newUserForm.valid && this.wasValidation) {
            this.setMessage(
                FormValidationService.getMessages(this.newUserForm.controls)
            );
        }
    }

    private getRoles() {
        this.getOrganizationRolesInProgress = true;
        setTimeout(() => {
            this.roleService
                .getAll()
                .then((response: any) => {
                    this.roleCollection = response.roles;
                    this.getOrganizationRolesInProgress = false;
                    this.onRolesLoad.emit(response.roles);
                    this.rolesLoaded = true;
                })
                .catch((error: any) => {
                    this._openModal.errorModal(DialogErrorComponent, {
                        title: this.languageService.getLine(
                            "access.management.organization.get.error"
                        ),
                        message: FormValidationService.readError(error).message,
                    });

                    this.getOrganizationRolesInProgress = false;
                });
        }, 1000); // we need the timeout so the mainrole loads (idk what the problem is, this is just a workaround since the issue appeared after we added caching)
    }

    private hasIncorrect() {
        let result = false;

        forEach(Object.keys(this.newUserForm.controls), (controlName) => {
            const control = this.newUserForm.get(controlName);

            if (has(control.errors, "incorrect")) {
                result = true;
            }
        });

        return result;
    }

    private setMessage(value) {
        this.message = value;

        for (const controlName of Object.keys(this.newUserForm.controls)) {
            if (has(this.message, controlName)) {
                this.newUserForm.get(controlName).markAsTouched();
            } else {
                this.newUserForm.get(controlName).markAsUntouched();
            }
        }
    }
}
