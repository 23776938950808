import {Component, Injectable, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import {ActivityController} from '~/src/app/modules/activity/activity.controller';
import {OrganizationController} from '~/src/app/components/organization-select/organization.component';
import {BASE_DATE_FORMAT, Configs, NO_AVATAR_IMAGE_PATH} from '~/src/app/configs/configs';
import {ActivityItemEntity} from '~/src/app/modules/activity/activityItem.entity';
import {Debounce, Helpers} from '~/src/app/services/helpers';
import {LanguageService} from '~/src/app/services/language.service';
import {ACTIVITY_PREFIX, ACTIVITY_TYPES} from '~/src/app/modules/activity/activity.constants';
import {OrganizationItem} from '~/src/app/components/organization-select/organization.interfaces';
import Utils from '~/src/app/core/utils';

export const YYYY_MM_DD_Format = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'D MMMM YYYY',
        monthYearLabel: 'D MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM-YYYY',
    },
};

const ACTIVITY_ITEM_PER_PAGE = 10;

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}

@Component({
    selector: 'smd-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
    providers: [
        ActivityController,
        { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: YYYY_MM_DD_Format },
    ]
})
export class ActivityComponent implements OnInit {
    // current date store
    private _currentDate = '';
    readonly activityDateViewFormat: string = 'hh:mm a';
    // settings formgroup
    activitySettingsForm = new FormGroup({});
    // store daily activities
    dailyActivities: ActivityItemEntity[] = [];
    myActivities: ActivityItemEntity[] = [];
    filterFormControlNames = {
        LogType: 'type',
        Username: 'name',
        SocialChannel: 'socialChannel',
        Organization: 'organization'
    };
    filterFormGroup: FormGroup;
    date = new FormControl(moment());
    //organizations: OrganizationItem[] = [];
    logTypes: {value: string, label: string}[] = Utils.orderBy(
        ACTIVITY_TYPES.map(_type => ({value: _type, label: LanguageService.getLine(ACTIVITY_PREFIX + _type)})),
        ['label']
    );

    constructor(
        public dialog: MatDialog,
        public controller: ActivityController,
        public organizationController: OrganizationController
    ) {
        this.initialize();
     }

    ngOnInit() {}

    initialize() {
        this.initFilterForm();

        this.currentDate = moment();

        this.controller.itemLimit = ACTIVITY_ITEM_PER_PAGE;

        this.controller.onRefreshActivities.subscribe(() => {
            this.getActivities();
        });

        //this.getOrganizations();
    }

    @Debounce(500)
    getActivities() {
        this.dailyActivities = [];
        this.myActivities = [];

        this.getDailyActivities();

        this.getMyActivities();
    }

    getDailyActivities() {
        this.controller.filters['offset'] = this.dailyActivities.length;

        this.controller.getDailyActivities(response => {
            this.dailyActivities = Helpers.arrayUnique(this.dailyActivities.concat(response.activities), 'activityID');
        }, error => {});
    }

    getMyActivities() {
        this.controller.filters['offset'] = this.myActivities.length;

        this.controller.getMyActivities(response => {
            this.myActivities = Helpers.arrayUnique(this.myActivities.concat(response.activities), 'activityID');
        }, error => {});
    }

    initFilterForm() {
        const controls = {};

        controls[this.filterFormControlNames.LogType] = new FormControl(null);
        controls[this.filterFormControlNames.Username] = new FormControl(null);
        controls[this.filterFormControlNames.SocialChannel] = new FormControl(null);
        controls[this.filterFormControlNames.Organization] = new FormControl(null);

        this.filterFormGroup = new FormGroup(controls);

        this.filterFormGroup.valueChanges.subscribe((values) => {
            for (const key in values) {
                const value = values[key];

                delete this.controller.filters[key];

                if (value) {
                    this.controller.filters[key] = value;
                }
            }
 
            this.getActivities();
        });
    }

    selectAll(select: AbstractControl, dataArray, modelValueProperty) {
        const selectedValues = dataArray.map(data => {
            if (modelValueProperty) {
                return data[modelValueProperty];
            }

            return data;
        });

        select.setValue(selectedValues);
    }

    deselectAll(select: AbstractControl) {
        select.setValue([]);
    }

    @Debounce()
    scrolled(type) {
        switch (type) {
            case 'daily':
                this.getDailyActivities();
                break;

            case 'my':
                this.getMyActivities();
                break;
        }
    }

    setDateFilter(operation: 'add' | 'sub', value: number, unit: 'day' = 'day') {
        switch (operation) {
            case 'add':
                this.currentDate = moment(this._currentDate).add(value, unit);

                break;

            case 'sub':
                this.currentDate = moment(this._currentDate).subtract(value, unit);

                break;
        }
    }

    changeDay(type, event) {
        const targetDate = event.value;

        this.currentDate = moment(targetDate);
    }

    /* getOrganizations() {
        this.organizationController.getItems((response) => {
            this.organizations = response.organizations;
        });
    } */


    reset() {
        this.filterFormGroup.reset();
        this.filterFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    }

    get currentDate(): any {
        return this._currentDate;
    }

    set currentDate(value: any) {
        this._currentDate = value;

        this.controller.filters['activityDate'] = Utils.moment(this.currentDate).format(BASE_DATE_FORMAT);

        this.getActivities();
    }

    get getActivitiesInProgress() {
        return this.controller.getDailyActivitiesInProgress || this.controller.getMyActivitiesInProgress;
    }

    get socialChannels() {
        return Configs.socials;
    }

    get languageService() {
        return LanguageService;
    }

    get fallbackImage() {
        return NO_AVATAR_IMAGE_PATH;
    }
}
