import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';

@Directive({
    selector: '[myInnerHtml]'
})
export class InnerHtmlDirective {

    @Output() parseHtml = new EventEmitter<HTMLElement>();
    @Input() formating: boolean = false;
    @Input('myInnerHtml') set setInnerHtml(value: string) {

        if (typeof value !== 'number') {
            value = value || '';
        }

        if (this.formating) {
            value = value.replace(/\n/g, '<br>');
        }

        this.el.nativeElement.innerHTML = value;
        this.parseHtml.emit(this.el.nativeElement);
    }

    constructor(
        public el: ElementRef<HTMLElement>
    ) {}

}
