import {AbstractControl, FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import Utils from '~/src/app/core/utils';

export interface FormHelpers {
    formErrors: {[key: string]: any};
    formInit(formGroup: FormGroup): void;
    setFormErrors(formGroup: FormGroup, fromBackend?: {[key: string]: any}): void;
    validateForm(formGroup: FormGroup, fromBackend?: {[key: string]: any}): void;
}

@Injectable()
export class FormHelpersService implements FormHelpers {
    formErrors: { [p: string]: any } = {};

    /**
     * Form group init
     * Add value change listener
     * @param {FormGroup} formGroup
     */
    formInit(formGroup: FormGroup): void {
        this.setFormErrors(formGroup);

        formGroup.valueChanges.subscribe((values) => {
            this.setFormErrors(formGroup);
        });
    }

    /**
     * Set form error messages
     * @param {FormGroup} formGroup
     * @param fromBackend
     */
    setFormErrors(formGroup: FormGroup, fromBackend?: { [p: string]: any }): void {
        this.formErrors = !!fromBackend
            ? fromBackend
            : FormValidationService.getMessages(formGroup.controls);
    }

    /**
     * Validate form group
     * @param {FormGroup} formGroup
     * @param fromBackend
     */
    validateForm(formGroup: FormGroup, fromBackend?: { [p: string]: any }): void {
        this.setFormErrors(formGroup, fromBackend);

        for (const controlName in formGroup.controls) {
            const formControl = formGroup.controls[controlName];

            if (!!fromBackend) {

                if (Object.keys(fromBackend).includes(controlName)) {
                    formControl.setErrors({invalid: true});
                    formControl.markAsTouched();
                }

            } else {
                formControl.markAsTouched();
            }

            if (formControl instanceof FormGroup) {
                this.validateForm(formControl, fromBackend);
            }
        }
    }

    /**
     * Reset form group
     * @param {FormGroup} formGroup
     * @param defaultValues
     * @param loopCallback
     */
    resetFormGroup(formGroup: FormGroup, defaultValues: {[key: string]: any} = {}, loopCallback: (control: AbstractControl) => void = null) {
        const values = {};

        Utils.lodash.forEach(formGroup.controls, (control, controlName) => {
            values[controlName] = Utils.lodash.has(defaultValues, controlName) ? defaultValues[controlName] : null;

            if (loopCallback) {
                loopCallback(formGroup.get(controlName));
            }
        });

        formGroup.setValue(values, {emitEvent: true});
    }

    /**
     * Call action when form is valid
     * @param {FormGroup} formGroup
     * @param {() => void} actionFn
     */
    submit(formGroup: FormGroup, actionFn: () => void): void {
        if (formGroup.valid) {
            actionFn();
        } else {
            this.validateForm(formGroup);
        }
    }
}
