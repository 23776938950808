import {Injectable} from '@angular/core';
import {BackendService} from '~/src/app/core/backend.service';
import {Categories as CategoriesResponse, Category, CategoryFilters} from '~/src/app/modules/categories/categories';
import {BehaviorSubject} from '~/node_modules/rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    // api url
    private apiUrl = '/category';

    // categories collection
    private categoriesCollection: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);

    constructor(
        private backendService: BackendService
    ) {
    }

    /**
     * Add category
     * @param data
     */
    public addCategory(data: any): Promise<any> {
        return this.backendService.post(
            this.apiUrl,
            data
        );
    }

    /**
     * Edit category
     * @param data
     * @param categoryID
     */
    public editCategory(data: any, categoryID: number): Promise<any> {
        data = {
            ...data,
            categoryID
        };

        return this.backendService.post(
            this.apiUrl + '/' + categoryID,
            data
        );
    }

    /**
     * Delete category
     * @param categoryID
     */
    public deleteCategory(categoryID: number): Promise<any> {
        return this.backendService.delete(
            this.apiUrl + '/' + categoryID,
            {}
        );
    }

    /**
     * Delete categories
     * @param categoryID
     */
    public deleteCategories(categoryID: number[]): Promise<any> {
        return this.backendService.post(
            this.apiUrl + '/group-delete',
            {categoryID}
        );
    }

    /**
     * Return categories
     */
    async getCategories(filters?: CategoryFilters): Promise<CategoriesResponse> {
        const result = await this.backendService.get<CategoriesResponse>(
            this.apiUrl,
            filters
        );

        this.categoriesCollection.next(result.categories);

        return result;
    }

    /**
     * Clear categories
     */
    public clearCategories(): void {
        this.categoriesCollection.next([]);
    }

    /**
     * Categories
     */
    public get categories() {
        return this.categoriesCollection.asObservable();
    }
}
