import { MetricConfigs } from "~/src/app/modules/analytics/widget/metric-configs/metric.config";
import { BaseChartConfig } from "~/src/app/modules/analytics/widget/metric-configs/_base-chart-config";
import { WidgetMetricOptionType } from "~/src/app/modules/analytics/widget/widget.interfaces";
import Utils from "~/src/app/core/utils";
import { MetricConfigHelper } from "~/src/app/modules/analytics/widget/metric-configs/metric-config.helper";

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_ENGAGEMENT_AND_POST_FREQUENCY: WidgetMetricOptionType =
    {
        id: "linkedin-posts-engagement-and-post-frequency",
        name: "Engagement and Post frequency",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_POST_FREQUENCY: WidgetMetricOptionType =
    {
        id: "linkedin-posts-post-frequency",
        name: "Post frequency - Daily",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

// TODO: Rename this to Post Performance Summary, since it's not limited to personal profiles only anymore.
export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_PERSONAL_PROFILE_PERFORMANCE_SUMMARY: WidgetMetricOptionType =
    {
        id: "linkedin-posts-personal-profile-performance-summary",
        name: "Post Performance Summary",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_ENGAGEMENT_SUMMARY: WidgetMetricOptionType =
    {
        id: "linkedin-posts-engagement-summary",
        name: "Engagement Summary",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_TOTAL_ENGAGEMENT: WidgetMetricOptionType =
    {
        id: "linkedin-posts-total-engagement",
        name: "Total Engagement",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_REACTIONS: WidgetMetricOptionType =
    {
        id: "linkedin-posts-reactions",
        name: "Reactions",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_COMMENTS: WidgetMetricOptionType =
    {
        id: "linkedin-posts-comments",
        name: "Comments",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_POSTS_TOP_POSTS_BY_ENGAGEMENT: WidgetMetricOptionType =
    {
        id: "linkedin-posts-top-posts-by-engagement",
        name: "Top posts by Engagement",
        resource: "analytics.analytics.socialSiteDataAccess",
    };

// METRIC CONFIGS

export const MetricLinkedInPostsTotalEngagementConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_POSTS_TOTAL_ENGAGEMENT.id,
    apiURL: "/post-social-data",
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Total engagement",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "total_engagement",
    },
};

export const MetricLinkedInPostsReactionsConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_POSTS_REACTIONS.id,
    apiURL: "/post-social-data",
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Reactions",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "reactions",
    },
};

export const MetricLinkedInPostsCommentsConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_POSTS_COMMENTS.id,
    apiURL: "/post-social-data",
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Comments",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "comments",
    },
};

export const MetricLinkedInPostsEngagementAndPostFrequencyConfig: MetricConfigs =
    {
        metricID:
            WIDGET_METRIC_OPTION_LINKEDIN_POSTS_ENGAGEMENT_AND_POST_FREQUENCY.id,
        socialType: "linkedIn",
        contentType: "chart",
        dataName: "Engagement and Post Frequency",
        chartConfig: {
            ...BaseChartConfig,
        },
        partMetricIDs: [
            WIDGET_METRIC_OPTION_LINKEDIN_POSTS_TOTAL_ENGAGEMENT.id,
            WIDGET_METRIC_OPTION_LINKEDIN_POSTS_POST_FREQUENCY.id,
            WIDGET_METRIC_OPTION_LINKEDIN_POSTS_REACTIONS.id,
            WIDGET_METRIC_OPTION_LINKEDIN_POSTS_COMMENTS.id,
        ],
    };

export const MetricLinkedInPostsPostFrequencyConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_POSTS_POST_FREQUENCY.id,
    apiURL: "/post-frequency",
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Posts published",
    chartConfig: {
        ...BaseChartConfig,
    },
};

export const MetricLinkedInPostsPersonalProfilePerformanceSummaryConfig: MetricConfigs =
    {
        metricID:
            WIDGET_METRIC_OPTION_LINKEDIN_POSTS_PERSONAL_PROFILE_PERFORMANCE_SUMMARY.id,
        apiURL: "/personal-profile-performance-summary",
        socialType: "linkedIn",
        contentType: "summary",
        dataName: "Post Performance Summary",
        chartConfig: {
            ...BaseChartConfig,
        },
    };

export const MetricLinkedInPostsEngagementSummaryConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_POSTS_ENGAGEMENT_SUMMARY.id,
    apiURL: "/engagement-summary",
    socialType: "linkedIn",
    contentType: "summary",
    dataName: "Engagement Summary",
    chartConfig: {
        ...BaseChartConfig,
    },
};

export const MetricLinkedInPostsTopPostsByEngagement: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_POSTS_TOP_POSTS_BY_ENGAGEMENT.id,
    apiURL: "/top-posts-engagement",
    socialType: "linkedIn",
    contentType: "table",
    dataName: "Top posts by Engagement",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        limit: 15,
    },
};
