import Utils from "~/src/app/core/utils";
import { FacebookAdapter } from "~/src/app/modules/posts/post-previews/facebook-preview-adapter";

class Adapter extends FacebookAdapter {
    adapt(value, isLinkShare = null): string {
        value = value.trim() || "";
        value = value.replaceAll('<br />', ' <br/>');
        
        // if there are multiple <br/> tags in a row, only keep the first one
        value = value.replace(/(\\r <br\/>?)+/g, '<br/>');
        
        // match urls not in href
        //const urls = value.match(/(?<!href=")https?:\/\/\S+/gi);
        // match urls not in href without lookbehind (causes issue in safari...)
        const urls = value.match(/https?:\/\/\S+/gi);
        
        // remove mentions from urls (because lookbehind regex does not work in safari...)
        if (urls?.length > 0) {
            urls.forEach((url, index) => {
                const indexStart = value.indexOf(url) - 6;
                const indexEnd = value.indexOf(url) - 1;
                if (indexStart < 0) {
                    return;
                }
                
                if (value.substring(indexStart, indexEnd) === "href=") {
                    urls.splice(index, 1);
                }
            });
        }

        if (urls?.length > 0 && isLinkShare !== false) { // legyen nullra is jo
            const lastUrl = urls.slice(-1)[0] ;
            if (value.replace(/<[^>]*>?/gm, '').endsWith(lastUrl)) {
                value = value.split(' ').reverse().join(' ').replace(new RegExp(lastUrl), '').split(' ').reverse().join(' ');
            }
        }

        value = value.replace(/https?:\/\/(www\.)?/gi, '');

        if (urls) {
            const uniqueUrls : string[] = Utils.lodash.uniq(urls);
            uniqueUrls.forEach(url => {
                url = url.replace(/https?:\/\/(www\.)?/gi, '')
                if (url.endsWith('/')) {
                    const url_without_slash = url.slice(0, -1);
                    value = value.replace(new RegExp(url, 'g'), url_without_slash);
                    url = url_without_slash;
                }
                if (url.length > 25) {
                    value = value.replace(new RegExp(url, 'g'), '<a href="https://'+url+'" target="_blank">' + url.slice(0, 22) + "...</a>");
                } else {
                    value = value.replace(new RegExp(url, 'g'), '<a href="https://'+url+'" target="_blank">' + url + "</a>");
                }
            });
        }

        return super.adapt(value);
    }
}

export const TwitterPreviewAdapter = new Adapter();
