import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '~/src/app/modules/auth/auth.service';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {LanguageService} from '~/src/app/services/language.service';
import Utils from '~/src/app/core/utils';
import {AvailableLoaders, PreloaderComponent} from '~/src/app/components/preloader/preloader.component';
import {NotifyService} from '~/src/app/services/notify.service';

@Component({
    selector: 'smd-username-reset',
    templateUrl: './username-reset.component.html',
    styleUrls: ['./username-reset.component.scss']
})
export class UsernameResetComponent implements OnInit {
    @Input() preLoader: PreloaderComponent;
    @Output() successReset: EventEmitter<any> = new EventEmitter<any>();
    @Output() switchToLogin: EventEmitter<any> = new EventEmitter<any>();
    resetControlNames = {
        Email: 'email'
    };
    resetFormGroup = new FormGroup({
        [this.resetControlNames.Email]: new FormControl(null, [
            Validators.required,
            Validators.email
        ])
    });
    resetFormErrors = {};

    constructor(
        public authService: AuthService,
        public languageService: LanguageService
    ) {
        this.resetFormGroup.valueChanges
            .subscribe(() => {
                this.clearResetFormErrors();
            });
    }

    ngOnInit() {}

    /**
     * Reset username click event
     * @param {MouseEvent} e
     */
    resetClickEvent(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (this.resetFormGroup.valid) {
            this.preLoader.show(AvailableLoaders.LOGIN_LOADER);
            this.authService.usernameRecovery(this.resetFormGroup.get(this.resetControlNames.Email).value)
                .then(response => {
                    this.clearResetFormErrors();
                    this.emitSuccessReset();
                    this.resetFormGroup.get(this.resetControlNames.Email).setValue(null);
                    this.preLoader.hide(AvailableLoaders.LOGIN_LOADER);
                    NotifyService.success(
                        LanguageService.getLine('recover.username'),
                        LanguageService.getLine('recover.username.success.message')
                    );
                })
                .catch(error => {
                    const errors = Utils.get(error, 'error.error.errors', {});
                    const message = FormValidationService.readError(error).message;

                    if (message) {
                        NotifyService.error(LanguageService.getLine('recover.username'), message);
                    }

                    for (const fieldName in errors) {
                        let fieldMessage = errors[fieldName];

                        if (fieldMessage instanceof Array && fieldMessage.length) {
                            fieldMessage = fieldMessage[0];
                        }

                        this.resetFormErrors[fieldName] = fieldMessage;
                    }
                    this.preLoader.hide(AvailableLoaders.LOGIN_LOADER);
                });
        } else {
            this.resetFormErrors = FormValidationService.getMessages(this.resetFormGroup.controls);
        }
    }

    emitSuccessReset() {
        this.successReset.emit();
    }

    emitSwitchToLogin() {
        this.switchToLogin.emit();
    }

    clearResetFormErrors() {
        this.resetFormErrors = {};
    }
}
