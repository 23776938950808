import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {ScheduleRulesPresetListComponent} from '~/src/app/modules/autofeed/components/schedule-rules-preset-list/schedule-rules-preset-list.component';
import {DialogFeedItemDeleteConfirmComponent} from '~/src/app/modules/autofeed/components/dialog-feed-item-delete-confirm/dialog-feed-item-delete-confirm.component';
import {ScheduleRulePresetModalFormComponent} from '~/src/app/modules/autofeed/components/schedule-rule-preset-modal-form/schedule-rule-preset-modal-form.component';
import {ScheduleRulePresetChooserModalComponent} from './components/schedule-rule-preset-chooser-modal/schedule-rule-preset-chooser-modal.component';

@NgModule({
    declarations: [
        DialogFeedItemDeleteConfirmComponent,
        ScheduleRulesPresetListComponent,
        ScheduleRulePresetModalFormComponent,
        ScheduleRulePresetChooserModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    exports: [
        DialogFeedItemDeleteConfirmComponent,
        ScheduleRulesPresetListComponent
    ],
    entryComponents: [
        DialogFeedItemDeleteConfirmComponent,
        ScheduleRulePresetModalFormComponent,
        ScheduleRulePresetChooserModalComponent
    ]
})
export class AutofeedModule {
}
