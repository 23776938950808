import { Component } from "@angular/core";
import { PlansService } from "~/src/app/modules/administration/plans.service";
import { AddonsService } from "~/src/app/modules/administration/addons.service";
import { Plan } from "~/src/app/modules/administration/plans.interfaces";
import { Addon } from "~/src/app/modules/administration/addons.interfaces";
import { LanguageService } from "~/src/app/services/language.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PackageEditorComponent } from "~/src/app/modules/administration/packages/package-editor/package-editor.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { Configs } from "../../../configs/configs";
import { environment } from "~/src/environments/environment";

@Component({
    selector: "smd-packages",
    templateUrl: "./packages.component.html",
    styleUrls: ["./packages.component.scss"],
})
export class PackagesComponent {
    protected plans: Plan[] = [];
    protected addons: Addon[] = [];

    constructor(
        public planService: PlansService,
        public addonService: AddonsService,
        public language: LanguageService,
        private openModal: OpenModalService
    ) {
        this.planService.plans.subscribe((plans) => (this.plans = plans));
        this.addonService.addons.subscribe((addons) => (this.addons = addons));

        this.getPackages();
    }

    getPackages() {
        const loader = this.openModal.loader(DialogLoaderComponent);

        Promise.all([this.getPlans(), this.getAddons()])
            .then(() => loader.close())
            .catch(() => loader.close());
    }

    openEditor(event: MouseEvent, packet: Plan | Addon) {
        event.preventDefault();
        event.stopPropagation();

        this.openModal.editPackage(PackageEditorComponent, packet, {
            data: {
                successEdit: () => {
                    this.getPackages();
                },
            },
        });
    }

    /**
     * Sync package from Zoho click
     * @param {MouseEvent} event
     * @param {"plan" | "addon"} type
     */
    async syncClick(
        event: MouseEvent,
        type: "plan" | "addon",
        secondIteration = false
    ) {
        event.preventDefault();
        event.stopPropagation();

        const successMessages = {
            plan: this.language.getLine("plan.sync.success"),
            addon: this.language.getLine("addon.sync.success"),
        };
        const errorMessages = {
            plan: this.language.getLine("plan.sync.error"),
            addon: this.language.getLine("addon.sync.error"),
        };

        const loader = this.openModal.loader(DialogLoaderComponent);
        const success = () => {
            loader.afterClosed().subscribe(() => {
                this.openModal.successModal(DialogSuccessComponent, {
                    message: successMessages[type],
                });
            });

            loader.close();
        };
        const failed = (response) => {
            if (!secondIteration) return;
            loader.afterClosed().subscribe(() => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        FormValidationService.readError(response).message ||
                        errorMessages[type],
                });
            });

            loader.close();
        };

        let needNewRefreshToken = false;
        if (type === "plan") {
            await this.planService
                .syncPlans()
                .then(() => this.getPlans())
                .then(() => success())
                .catch((error) => {
                    failed(error);
                    needNewRefreshToken = true;
                });
        } else if (type === "addon") {
            await this.addonService
                .syncAddons()
                .then(() => this.getAddons())
                .then(() => success())
                .catch((error) => {
                    failed(error);
                    needNewRefreshToken = true;
                });
        }

        if (!needNewRefreshToken) {
            return;
        }

        let needRedirect: boolean;
        await this.planService
            .refreshAuthtoken()
            .then(() => {
                needRedirect = false;
            })
            .catch(() => {
                needRedirect = true;
            });

        if (!needRedirect && !secondIteration) {
            loader.close();
            this.syncClick(event, type, true);
            return;
        }

        let doRedirect = false;
        if (needRedirect) {
            loader.close();
            await this.openModal
                .confirmModal(DialogConfirmComponent, {
                    message: LanguageService.getLine(
                        "core.zoho.oauth.redirect"
                    ),
                    yesButtonText: LanguageService.getLine("core.button.ok"),
                    noButtonText: LanguageService.getLine("core.button.cancel"),
                })
                .afterClosed()
                .toPromise()
                .then((doIt) => {
                    if (!!doIt) {
                        doRedirect = true;
                    }
                });
        }

        if (doRedirect) {
            window.location.href = this.generateZohoOauthUrl();
        }
    }

    generateZohoOauthUrl(): string {
        const clientId = Configs.keys.zoho_client_id;
        const redirectUri =
            environment.production && environment.isSharpMode
                ? "https://app.esemdee.com/api/plan/zoho-oauth" // prod
                : "https://test.esemdev.com/api/plan/zoho-oauth"; // dev (!!!) & teszt
        return `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoSubscriptions.plans.READ,ZohoSubscriptions.addons.READ&client_id=${clientId}&state=testing&response_type=code&redirect_uri=${redirectUri}&access_type=offline&prompt=consent`;
    }

    toggleDescription(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const path =
            (event as any).path ||
            ((event as any).composedPath && (event as any).composedPath());

        const parent: HTMLElement = path.find((element) => {
            return !!element.classList.contains("package");
        });

        parent.classList.toggle("show-description");
    }

    hasConfig(config: object) {
        return !!Object.keys(config).length;
    }

    private getPlans() {
        return this.planService.getPlans();
    }
    private getAddons() {
        return this.addonService.getAddons();
    }
}
