import {Injectable} from '@angular/core';
import {configDefaults, initConfigDefaults} from './pixie.defaults';
import {PixieModel} from './pixie.model';
import {merge} from 'lodash';
import {SmdFileInterface} from '~/src/app/services/file.class';
import {ViewFileObject} from '~/src/app/components/upload-media/upload-media.interface';
import {PixieDomService} from "./pixie-dom.service";

declare const Pixie: any;

@Injectable({
    providedIn: 'root'
})
export class PixieControllerService {
    private pixie: any = null;

    constructor() {
        PixieDomService.create();
        this.pixie = new Pixie(initConfigDefaults);
    }

    public open(data) {
        const media: ViewFileObject | SmdFileInterface = data.media;
        const callback: Function = data.callback;

        return new Promise<any>((resolve, reject) => {
            const config: PixieModel = merge(configDefaults, {
                onSave: (data) => fetch(data).then(response => response.blob()).then((blob: Blob) => {
                    media.url = data;
                    media.editorMeta = this.pixie.getState();
                    media.file = new File([blob], media.name, { type: 'image/jpeg' });

                    callback(media);
                    resolve(media);
                }),
                onOpen: () => media.editorMeta ? this.pixie.loadState(media.editorMeta) : '',
                onClose: () => reject(),
                image: !media.editorMeta ? media.url : undefined
            });

            this.pixie.resetAndOpenEditor(config);
        });
    }

    public addFile(file) {
        this.pixie.openFile(file);
    }
}
