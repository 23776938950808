import {Injectable} from '@angular/core';
import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {HASHTAG_API_RESOURCE_HASHTAG_COLLECTION} from '~/src/app/modules/hashtag/hashtag.constant';
import {
    IHashtagCollection,
    IHashtagCollectionFormData,
    IHashtagCollectionList
} from '~/src/app/modules/hashtag/hashtag-collection.interface';
import {HashtagCollectionModel} from '~/src/app/modules/hashtag/hashtag-collection.model';
import {HashtagUtils} from '~/src/app/modules/hashtag/hashtag.utils';

@Injectable({
    providedIn: 'root'
})
export class HashtagCollectionService {

    constructor(
        private backend: BackendService
    ) {}

    /**
     * List hashtag collections
     *
     * @param {RequestParams} params
     * @return {Promise<IHashtagCollectionList<HashtagCollectionModel>>}
     */
    getList(params: RequestParams = {}): Promise<IHashtagCollectionList<HashtagCollectionModel>> {
        return this.backend.get(`${HASHTAG_API_RESOURCE_HASHTAG_COLLECTION}`, params)
            .then((response: IHashtagCollectionList<IHashtagCollection>) => {
                const result: IHashtagCollectionList<HashtagCollectionModel> = {
                    ...response,
                    collections: response.collections.map(collection => new HashtagCollectionModel(collection))
                };
                return Promise.resolve(result);
            });
    }

    /**
     * Create hashtag collection
     *
     * @param {IHashtagCollectionFormData} data
     * @return {Promise<null>}
     */
    create(data: IHashtagCollectionFormData) {
        return this.backend.post(`${HASHTAG_API_RESOURCE_HASHTAG_COLLECTION}`, this._prepareFormData(data));
    }

    /**
     * Update hashtag collection
     *
     * @param {number} collectionID
     * @param {IHashtagCollectionFormData} data
     * @return {Promise<null>}
     */
    update(collectionID: number, data: IHashtagCollectionFormData) {
        return this.backend.put(`${HASHTAG_API_RESOURCE_HASHTAG_COLLECTION}/${collectionID}`, this._prepareFormData(data));
    }

    /**
     * Delete hashtag collection
     *
     * @param {number | number[]} collectionID
     * @return {Promise<any>}
     */
    remove(collectionID: number | number[]) {

        if (collectionID instanceof Array) {
            return this._bulkRemove(collectionID);
        }

        return this.backend.delete(`${HASHTAG_API_RESOURCE_HASHTAG_COLLECTION}/${collectionID}`);
    }

    /**
     * Prepare hashtag collection form data before send
     *
     * @param {IHashtagCollectionFormData} data
     * @return {IHashtagCollectionFormData}
     * @private
     */
    _prepareFormData(data: IHashtagCollectionFormData): IHashtagCollectionFormData {
        // convert array to string
        if (data.tags) {
            if (data.tags instanceof Array) {
                data.tags = data.tags.map(tag => {
                    if (HashtagUtils.hasHashtagSign(tag)) {
                        return tag;
                    }

                    return HashtagUtils.addHashtagSign(tag);
                }).join(', ');
            }
        }

        return data;
    }

    /**
     * Bulk hashtag collection delete
     *
     * @param {number[]} collectionIDs
     * @return {Promise<null>}
     * @private
     */
    _bulkRemove(collectionIDs: number[]) {
        return this.backend.post(`${HASHTAG_API_RESOURCE_HASHTAG_COLLECTION}/bulk-delete`, {collectionID: collectionIDs});
    }
}
