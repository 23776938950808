import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "../../services/language.service";

@Pipe({
    name: "language",
    pure: true,
})
export class LanguagePipe implements PipeTransform {
    constructor () {};

    transform(key: any, params?: any): any {
        return LanguageService.getLine(key, params || {});
    }
}
