import {CoreConfig} from '~/src/app/core/core.config';
import Utils from '~/src/app/core/utils';

export const PostTemplateSystemTypesForSelect = [
    {id: CoreConfig.getSystemTypes().Generic, nameKey: 'post.template.systemType.generic'},
    {id: CoreConfig.getSystemTypes().Branded, nameKey: 'post.template.systemType.branded'},
    {id: CoreConfig.getSystemTypes().Custom, nameKey: 'post.template.systemType.custom'},
];

export const PostTemplateSystemTypesForAdminSelect: { id: string, nameKey: string }[] = Utils.lodash.cloneDeep(
    PostTemplateSystemTypesForSelect.filter(item => item.id !== CoreConfig.getSystemTypes().Custom)
);

export const PostTemplateSystemTypesForCreateTemplate = [
    {id: CoreConfig.getSystemTypes().Generic, nameKey: 'post.template.systemType.generic'},
    {id: CoreConfig.getSystemTypes().Branded, nameKey: 'post.template.systemType.branded'},
];
