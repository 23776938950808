import {ComponentAbstract} from '../../services/component.abstract';
import { FolderTagsService } from './folder-tags.service';
import {TagsService} from './tags.service';

export class TagsComponent extends ComponentAbstract {

    constructor(
        public service: TagsService | FolderTagsService
    ) {
        super();

        this.filters = {};
    }
}
