import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import Utils from '~/src/app/core/utils';

@Injectable({providedIn: 'root'})
export class PartnerGuard implements CanActivate {

    constructor(
        private router: Router,
        private partnerConfigService: PartnerConfigService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const permission = Utils.lodash.get(route, 'data.permission', null);
        const result = this.partnerConfigService.hasConfig(permission);

        if (!result) {
            this.router.navigateByUrl('/');
        }

        return result;
    }
}
