import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RssFeedService} from '~/src/app/modules/rss/services/rss-feed.service';
import {RssFeedModel} from '~/src/app/modules/rss/models/rss-feed.model';
import {FormControl} from '@angular/forms';
import {LanguageService} from '~/src/app/services/language.service';
import {MatSelectChange} from '@angular/material/select';
import {RssGroupModel} from '~/src/app/modules/rss/models/rss-group.model';
import Utils from '~/src/app/core/utils';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'smd-rss-feed-select',
    templateUrl: './rss-feed-select.component.html',
    styleUrls: ['./rss-feed-select.component.scss']
})
export class RssFeedSelectComponent implements OnInit {
    @Input() feedControl: FormControl;
    @Input() multiple = false;
    @Input() errorMessage = null;
    @Input() rssFeeds: RssFeedModel[] = [];
    @Input() bindValue: keyof RssFeedModel = 'feedID';
    @Input() rssGroupControl: FormControl;
    @Input() organizationControl: FormControl;
    @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
    feedsLoading = false;
    selectedRssFeeds: RssFeedModel[] = [];

    constructor(
        public languageService: LanguageService,
        private rssFeedService: RssFeedService
    ) {}

    ngOnInit(): void {
        let organizationID = null;

        if (this.rssGroupControl) {
            this.rssGroupControl
                .valueChanges
                .pipe(
                    debounceTime(350)
                )
                .subscribe((value: RssGroupModel | RssGroupModel[]) => {
                    const rssFeeds = [];
                    (Array.isArray(value) ? value : [value]).forEach(group => rssFeeds.push(...(group ? (group.feeds || []) : [])));
                    if (rssFeeds && rssFeeds) {
                        this.feedControl.setValue(rssFeeds.map(feed => feed[this.bindValue]), {emitEvent: true});
                    }
                });
        }

        if (this.organizationControl) {
            this.organizationControl
                .valueChanges
                .pipe(
                    debounceTime(350)
                )
                .subscribe(value => this.getRssFeeds(value));

            organizationID = this.organizationControl.value;
        }

        if (this.feedControl) {
            this.feedControl.valueChanges.subscribe((value: any[]) => this.setSelectedRssFeeds(value));
        }

        this.getRssFeeds(organizationID);
    }

    getRssFeeds(organizationID?: number[]): void {
        this.feedsLoading = true;
        this.rssFeedService.listFeeds({organizationID})
            .then(response => {
                this.rssFeeds = response.feeds;
                const feedControlValue = this.feedControl.value ? Array.isArray(this.feedControl.value) ? this.feedControl.value : [this.feedControl.value] : [];
                const feed = this.rssFeeds.find(_feed => feedControlValue.includes(_feed.feedID));

                if (!feed) {
                    this.feedControl.setValue(this.multiple ? [] : null, {emitEvent: true});
                }

                this.setSelectedRssFeeds(this.feedControl.value);
            })
            .finally(() => this.feedsLoading = false);
    }

    /**
     * Set selected RSS feeds
     * @param {any[]} value
     */
    setSelectedRssFeeds(value: any[]): void {
        if (value) {
            value = Array.isArray(value) ? value : [value];
            const feeds: RssFeedModel[] = this.rssFeeds.filter(item => value.includes(item[this.bindValue]));
            this.selectedRssFeeds = this.multiple ? feeds : Utils.get(feeds, '[0]', null);
        } else {
            this.selectedRssFeeds = this.multiple ? [] : null;
        }
    }

    emitSelectionChange(event: MatSelectChange) {
        this.selectionChange.emit(event);
    }
}
