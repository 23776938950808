import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {ItemViewTemplateDirective} from '~/src/app/core/components/item-separation/item-separation-templates.directive';
import {MaterialModule} from '~/src/app/modules/material.module';
import {ItemSeparationComponent} from '~/src/app/core/components/item-separation/item-separation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        LanguagesModule
    ],
    declarations: [
        ItemSeparationComponent,
        ItemViewTemplateDirective
    ],
    exports: [
        ItemSeparationComponent,
        ItemViewTemplateDirective
    ]
})
export class ItemSeparationModule {}
