import {Directive, Input} from '@angular/core';
import {FALLBACK_IMAGE} from '~/src/app/configs/configs';

@Directive({
    selector: 'img[fallback]',
    host: {
        '(error)': 'updateUrl()',
        '[src]': 'src'
    }
})
export class ImagePreloadDirective {
    @Input() src: string;
    @Input() fallback: string;

    updateUrl() {
        this.src = this.fallback || FALLBACK_IMAGE;
    }
}
