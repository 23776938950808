import {
    AfterViewInit,
    Component,
    HostListener,
    Inject,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { CalendarEvent } from "angular-calendar";
import { Subject, Subscription } from "rxjs/index";
import * as moment from "moment";
import { DialogLoaderComponent } from "../../../components/dialog-loader/dialog-loader.component";
import { PostActions, PostInterface } from "../post-actions";
import { PostActionsService, PostStatus } from "../post-actions.service";
import { PostTemplateFiltersComponent } from "../post-template-filters/post-template-filters.component";
import { ActivatedRoute } from "@angular/router";
import { SocialSiteService } from "../../../components/social-site-select/social-site.service";
import { SocialSiteInterface } from "../../../components/social-site-select/social-site-select.component";
import { BASE_DATE_FORMAT, PLACEHOLDER_IMAGE } from "../../../configs/configs";
import { DialogConfirmComponent } from "../../../components/dialog-confirm/dialog-confirm.component";
import { DialogSuccessComponent } from "../../../components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "../../../components/dialog-error/dialog-error.component";
import { UsersController } from "../../users/users/users.component";
import { LanguageService } from "../../../services/language.service";
import { SmdFile } from "../../../services/file.class";
import { PaginationController } from "../../../services/pagination.controller";
import { CarouselController, CarouselItem } from "../carousel/carousel.component";
import { ShowPreviewService } from "~/src/app/directives/show-preview/show-preview.service";
import { CustomEvents, Debounce, Helpers, LoggedUserInterface } from "~/src/app/services/helpers";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PostManagerComponent } from "~/src/app/modules/social-media-post/post-manager/post-manager.component";
import { FormControl } from "~/node_modules/@angular/forms";
import { BulkUploadManagerComponent } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.component";
import { BulkUploadManagerModel } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.model";
import { DataTableHelpersService } from "~/src/app/core/services/data-table-helpers.service";
import {
    CollectionListSelectionChange,
    TableBodyConfig,
    TableHeadConfig,
} from "~/src/app/core/components/collection-list/collection-list.interfaces";
import { CollectionListComponent } from "~/src/app/core/components/collection-list/collection-list.component";
import { DialogWarnComponent } from "~/src/app/components/dialog-warn/dialog-warn.component";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import Utils from "~/src/app/core/utils";
import { IRelatedUser } from "../../users/users/users.interface";
import { POST_STATUS_APPROVED, POST_STATUS_FAILED, POST_STATUS_NOT_APPROVED, POST_STATUS_PARTIALLY_FAILED, POST_STATUS_POSTED } from "../../social-media-post/social-media-post.constant";
import { PostPreviewComponent } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.component";
import { KeyValue } from "@angular/common";
import { PostTemplateManagerComponent } from "../../social-media-post/post-template-manager/post-template-manager.component";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { CarouselItemResponseInterface } from "../carousel/carousel.interface";
import { cloneDeep } from "lodash";
import { PostStatusConfigs } from "../post-card/post-card";
import { CommentsComponent } from "../../social-media-post/comments/comments.component";
import { LoggedUser } from "~/src/app/services/logged-user";
import { CommentForViewInterface } from "../comments/post-comment/interfaces";
import { CommentController } from "../comments/post-comment/comment-controller.service";
import { MentionConfig } from "~/node_modules/angular-mentions";

@Component({
    selector: "smd-post-list",
    templateUrl: "./post-list.component.html",
    styleUrls: ["./post-list.component.scss"],
    providers: [DataTableHelpersService],
})
export class PostListComponent
    extends PostActions
    implements AfterViewInit, OnDestroy
{
    @ViewChildren(PostTemplateFiltersComponent) filterForm;
    @ViewChildren('feedViewComment') feedViewComment: QueryList<CommentsComponent>;
    @ViewChild(CollectionListComponent) collectionList: CollectionListComponent;

    filterClickEventsSubject: Subject<any> = new Subject();

    user: LoggedUserInterface = LoggedUser.getUser();

    unformattedComment: string = '';
    htmlDoc: HTMLElement;
    mentionConfig: MentionConfig = {
        maxItems: 5,
        dropUp: true,
        labelKey: "name",
        allowSpace: true,
        mentionSelect: (e: any) => {
            return '##' + e.userID + '##';
        },
    };
    createCommentInProgress: boolean = false;
    objectType: string = "post";

    commentsForFeedView: CommentForViewInterface[];

    public resetForm: Subject<any> = new Subject<any>();
    public enableGetPosts = true;
    public status: string[];
    public posts: PostInterface[] = [];
    public _listChange = false;
    public _socialSites: SocialSiteInterface[] = [];
    public date = "";
    public pageTitle = "";
    public paginationController: PaginationController =
        new PaginationController();
    public enableGetPostsByUrlChange = true;
    public isLoaded: boolean;
    private sub: any;
    subscriptions: Subscription[] = [];
    public loader: MatDialogRef<DialogLoaderComponent>;
    public isAdminMode = !!Utils.lodash.get(
        this.route,
        "snapshot.data.isAdmin",
        false
    );

    // Column data for select
    columnVisibility: any = {
        postID: {
            name: "post.manager.id",
            visible: true,
        },
        medias: {
            name: "post.manager.media",
            visible: true,
        },
        mainCopy: {
            name: "post.manager.mainCopy",
            visible: true,
        },
        activeFrom: {
            name: "post.manager.activeFrom",
            visible: true,
        },
        socialType: {
            name: "post.manager.social.network",
            visible: true,
        },
        createdBy: {
            name: "post.manager.created.by",
            visible: true,
        },
        statusMessage: {
            name: "post.manager.status",
            visible: true,
        },
        publishedFrom: {
            name: "post.manager.published.from",
            visible: true,
        },
    };
    defaultColumns: any[] = Object.keys(this.columnVisibility);

    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
    }

    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: "post.manager.id",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["postID"].visible,
        },
        {
            nameKey: "post.manager.media",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["medias"].visible,
        },
        {
            nameKey: "post.manager.mainCopy",
            enable: () => this.columnVisibility["mainCopy"].visible,
        },
        {
            nameKey: "post.manager.activeFrom",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["activeFrom"].visible,
        },
        {
            nameKey: "post.manager.social.network",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["socialType"].visible,
        },
        {
            nameKey: "post.manager.created.by",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            nameKey: "post.manager.status",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["statusMessage"].visible,
        },
        {
            nameKey: "post.manager.published.from",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["publishedFrom"].visible,
        },
        {
            nameKey: "post.manager.actions",
            onlyDesktop: true,
            onlyMobile: true,
        },
    ];
    tableBodyConfig: TableBodyConfig[] = [
        {
            bindValue: "postID",
            onlyDesktop: true,
            enable: () => this.columnVisibility["postID"].visible,
        },
        {
            bindValue: "medias",
            selector: "mediasView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["medias"].visible,
        },
        {
            bindValue: "mainCopy",
            selector: "messageView",
            //maxWidth: true,
            enable: () => this.columnVisibility["mainCopy"].visible,
        },
        {
            bindValue: "activeFrom",
            selector: "activeFromView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["activeFrom"].visible,
        },
        {
            bindValue: "socialType",
            selector: "socialTypeView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["socialType"].visible,
        },
        {
            selector: "createdBy",
            onlyDesktop: true,
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            bindValue: "statusMessage",
            selector: "statusView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["statusMessage"].visible,
        },
        {
            selector: "publishedFrom",
            onlyDesktop: true,
            enable: () => this.columnVisibility["publishedFrom"].visible,
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
        },
    ];
    placeholderImg = PLACEHOLDER_IMAGE;

    relatedUsers: Array<IRelatedUser> = [];
    objectRelatedUsers: KeyValue<String, Array<IRelatedUser>>;
    filterToggled = false;

    listView = false;
    cardView = true;
    feedView = false;

    constructor(
        public postActionsService: PostActionsService,
        private route: ActivatedRoute,
        public _dialogRef: MatDialogRef<PostListComponent>,
        public _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            date: Date;
            events: CalendarEvent[];
            socialSites: SocialSiteInterface[];
        },
        public socialSiteService: SocialSiteService,
        public carouselController: CarouselController,
        users: UsersController,
        public previewService: ShowPreviewService,
        public openModal: OpenModalService,
        public language: LanguageService,
        public dataTableHelper: DataTableHelpersService,
        private postService: SocialMediaPostService,
        private commentController: CommentController,
    ) {
        super(
            postActionsService,
            _dialog,
            users,
            carouselController,
            openModal
        );

        this.setDefaultOrder();

        this.initSocialSites();
        
        this.date = moment(this.dialogData.date).format(BASE_DATE_FORMAT);
        this._socialSites = this.dialogData.socialSites;

        this.subscriptions.push(
            this.postService.postListRefresh$.subscribe(() => {
                this.getPosts();
            })
        );
    }

    ngAfterViewInit() {
        /* it fixes the following error:
        ERROR Error: ExpressionChangedAfterItHasBeenCheckedError:
        Expression has changed after it was checked. Previous value: 'hidden: true'.
        Current value: 'hidden: false'. */
        setTimeout(() => {
            this.isLoaded = true;
            setTimeout(() => {
                this.presetFilters();
            });
        });
    }

    ngOnDestroy() {
        //this.sub.unsubscribe();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ngOnInit() {
        this.paginationController.onSetPagination.subscribe(
            ({ offset, limit }: { offset: number; limit: number }) => {
                this.filters["offset"] = offset;
                this.filters["limit"] = limit;

                if (this.enableGetPostsByUrlChange) {
                    this.getPosts();
                }
            }
        );
        this.urlListener();
        this.getRelatedUsers();
    }

    /**
     * Open bulk upload manager in post mode
     *
     * @param {MouseEvent} event
     */
    importPostsBtnClick(event: MouseEvent) {
        event.preventDefault();

        this.openModal.bulkUploadManager(BulkUploadManagerComponent, {
            data: {
                entityType: BulkUploadManagerModel.ENTITY_TYPES.Post,
                afterSuccessSave: () => {
                    this.getPosts();
                },
            },
        });
    }

    /**
     * Init social site component and get social sites
     */
    initSocialSites() {
        if (!this.socialSiteService.socialSites) {
            this.socialSiteService.getAll().then(() => {
                this.socialSites = this.socialSiteService.socialSites;
            });
        } else {
            this.socialSites = this.socialSiteService.socialSites;
        }
    }

    urlListener() {
        const loadPosts = (params: any) => {
            this.status = null;
            delete this.filters["type"];
            delete this.filters["status"];

            this.paginationController.paginationOptions.pageIndex = 0;
            this.enableGetPostsByUrlChange = false;
            this.paginationController.setPagination(
                this.paginationController.paginationOptions
            );

            if (params["status"]) {
                this.status = [params["status"]];
                this.filters["status"] = params["status"];
            } else {
                delete this.filters["status"];
            }

            if (params["type"]) {
                this.filters["type"] = params["type"];
                if (params["type"] == "draft") {
                    this.filters["status"] = params["type"];
                } else {
                    delete this.filters["status"];
                }
            } else {
                delete this.filters["type"];
            }

            const defaultOrder = "activeFrom-desc";
            this.filters["order"] = defaultOrder;

            //this.filtersChange(this.filters);
        };
        this.route.data.subscribe((data) => loadPosts(data));

        this.route.queryParams.subscribe((params) => {
            this.paginationController.paginationOptions.pageIndex = 0;
            //this.enableGetPostsByUrlChange = false;
            this.paginationController.setPagination(
                this.paginationController.paginationOptions
            );

            const searchValue = params.searchValue || null;
            const postID = params.postID || null;

            if (searchValue) {
                delete this.filters["searchValue"];
                this.filters["searchValue"] = searchValue;
                this.filters["order"] = this.getOrderFormat("activeFrom-desc");
            } else {
                delete this.filters["searchValue"];
                this.filters["order"] = this.getOrderFormat("activeFrom-desc");
            }

            if (postID) {
                this.getItemByID({ "postIDs[]": postID })
                    .then(
                        ({
                            posts,
                            count,
                        }: {
                            posts: any[];
                            count: number;
                        }) => {
                            if (posts.length > 0) {
                                const post = Utils.initLineBreaksOnPostEntity(CommonPostHelpers.mergeDataWithDraftData(posts[0])); // so drafts and linebraks in texts work

                                if (post.medias && post.medias[0] && post.medias[0].mediaType === FILE_TYPES.CAROUSEL) { // so carousels work
                                    const mediaID = post.medias[0].mediaID
            
                                    if (mediaID) {
                                        return this.carouselController
                                            .getCarouselItemsByIDs([mediaID])
                                            .then(({ carouselItems }: { carouselItems: CarouselItemResponseInterface[] }) => {
                                                post.medias[0].carouselItems = carouselItems
                                                    .filter(
                                                        (carouselItem) =>
                                                            carouselItem.carouselID.toString() ===
                                                            post.medias[0].mediaID.toString()
                                                    ).map((item) => new CarouselItem(item));
                                                    post.medias = SmdFile.initFileArray(post.medias);
                                                    return this.openModal.commentToPost(PostPreviewComponent, post)
                                                        .afterClosed()
                                                        .subscribe(() => {
                                                            this.getPosts();
                                                        });
                                            });
                                    }
                                } else {
                                    post.medias = SmdFile.initFileArray(post.medias);
                                    return this.openModal.commentToPost(PostPreviewComponent, post)
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.getPosts();
                                        });
                                }
                            }
                        }
                    )
                    .catch((error) => {
                        this.service.showErrorAlert(error);
                    });
            }

            this.filtersChange(this.filters);
        });
    }

    /**
     * Set filters and get posts
     *
     * @param viewRenderEvent
     */
    getPosts(): void {
        this.enableGetPosts = false;

        const loader = this.openModal.loader(DialogLoaderComponent);
        this.getItems(
            () => 
            {
                loader.close();

                // after we get posts, set the search input to the correct value (in case we came here from a notification after another of the same kind)
                this.filterForm.first.form?.controls["searchValueFormControl"]?.setValue(this.filters["searchValue"] || null);
            },
            () => loader.close(),
        );
    }

    successGetItems(
        response: any,
        callback: (posts?: PostInterface[]) => void = () => {}
    ): void {
        super.successGetItems(response, (_posts) => {
            const { posts, count } = response;
            /**
             * It's need to date range filter
             */
            _posts.map((post) => {
                if (!!post.repeats && !!Object.keys(post.repeats).length) {
                    const activeFrom = Object.values(post.repeats)[0] || "";

                    if (!!activeFrom) {
                        post.activeFrom = activeFrom;
                    }
                }

                post.renderHelper = {
                    localizedStatus: this.getStatus(post) || "",
                    postSource: this.getPostSource(post) || "",
                    postCreatorName: this.getUserFromID(post.userID).name || "Unknown",
                    postCreatorOrganizationName: this.getUserFromID(post.userID).organizationName || "Unknown",
                    postCreatorRoleName: this.getUserFromID(post.userID).roleName || "Unknown role",
                    postMessage: this.getMessage(post) || "",
                    postActiveFrom: this.getActiveFrom(post) || "",
                    socialSitesTooltipFor10: this.getTooltipForSocialNetwork(post, 10),
                    socialSitesTooltipFor5: this.getTooltipForSocialNetwork(post, 5),
                }

                if (post.statusChangedOn) {
                    post.statusChangedOn = moment.utc(post.statusChangedOn).tz(this.user.settings.defaultTimeZone);
                }

                if (post.gmbOptions && post.gmbOptions.type) {
                    post.renderHelper.platformData = {
                        gmbIcon: this.getIconForGmbType(post.gmbOptions.type)
                    };
                }

                let status = post.type === 'draft' ? post.type : post.status;
                post.statusConfigs = PostStatusConfigs[status];

                let name = Utils.get(post, "categories[0].name", null);
                let color = Utils.get(
                    post,
                    "categories[0].color",
                    "#d4459e"
                );
                post.categoryConfigs = {
                    categoryName: name,
                    categoryColor: color,
                }

                return post;
            });

            this.enableGetPostsByUrlChange = true;

            if (
                _posts.length === 0 &&
                this.paginationController.paginationOptions.pageIndex !== 0
            ) {
                this.paginationController.paginationOptions.pageIndex--;
                this.filters["offset"] -=
                    this.paginationController.paginationOptions.pageSize;
                this.getPosts();
                return;
            }

            this.paginationController.paginationOptions.length = count;

            _posts = _posts.filter(
                (post) => !post.isRepeat || post.repeatIndex === "0"
            );

            this.posts = _posts;

            let postIDs = this.posts.map(function (post) {
                return post.postID;
            });
            
            let filterParams = {
                limit: 1000,
                offset: 0,
                order: Helpers.getOrderFormat("createDate-desc"),
                objectType: this.objectType,
            };
    
            this.commentController.getComments(
                postIDs,
                filterParams,
                (comments) => {
                    this.commentsForFeedView = comments;
                },
            );

            this.getRelatedUsers(postIDs);
        });
    }

    /**
     * PresetFilters
     */
    presetFilters() {

        if (
            this.filterForm.first &&
            this.filterForm.first.hasOwnProperty("form")
        ) {
            const status = this.filters["status"] || null;
            const searchValue = this.filters["searchValue"] || null;
    
            this.filterForm.first.form.controls["organizationID"].enable();
            this.filterForm.first.form.controls["orderByFormControl"].enable();
            this.filterForm.first.form.controls["orderByFormControl"].setValue("activeFrom-desc");
            this.filterForm.first.form.controls["searchValueFormControl"].enable();
            this.filterForm.first.form.controls["searchValueFormControl"].setValue(searchValue);
            this.filterForm.first.form.controls["tagsFormControl"].disable();
            this.filterForm.first.form.controls["categoriesFormControl"].enable();
            this.filterForm.first.form.controls["dateFromFormControl"].enable();
            this.filterForm.first.form.controls["dateToFormControl"].enable();
            this.filterForm.first.form.controls["definedDatesFormControl"].enable();
            this.filterForm.first.form.controls["statusFormControl"].enable();
            this.filterForm.first.form.controls["statusFormControl"].setValue(status ? [status] : status);
    
            this.filterChange();
        }
    }

    filterChange() {
        this.filterForm.first.emitFilterChange();
    }

    filtersChange(filters) {
        this.paginationController.paginationOptions.pageIndex = 0;
        this.enableGetPostsByUrlChange = false;
        this.paginationController.setPagination(
            this.paginationController.paginationOptions
        );

        for (const filterName in filters) {
            let filter = filters[filterName];

            if (filterName === "order") {
                // HACK - rename order value to activeFrom from createDate
                if (!!filter && filter.indexOf("createDate") > -1) {
                    filter = (filter as string).replace(
                        "createDate",
                        "activeFrom"
                    );
                }

                if (!!filter && filter.indexOf("{") !== 0) { // dont "reencode" it
                    filter = this.getOrderFormat(filter);
                }
            }

            if (filter !== null) {
                this.filters[filterName] = filter;
            } else {
                delete this.filters[filterName];
            }
        }

        this.getPosts();
    }

    selectionChange(event: CollectionListSelectionChange) {
        this.dataTableHelper.selectedItems = event.selectedItems;
    }

    /**
     * Get translated post status
     * @param {any} post
     * @return {string}
     * */
    getStatus(post: any) {
        let status = null;
        if (post.status === "finished" || post.status === "failedToPost") {
            status = post.statusMessage;
        } else if (post.status === "posted") {
            status = this.language.getLine("post.status.posted");
        } else if (post.status === "inactive") {
            status = this.language.getLine("post.status.inactive");
        } else if (post.status === "active") {
            status = this.language.getLine("post.status.active");
        } else if (post.status === "approved") {
            status = this.language.getLine("post.status.approved");
        } else if (post.status === "waitingForApproval") {
            if (post.type === "draft") {
                status = this.language.getLine("post.status.draft");
            } else {
                status = this.language.getLine(
                    "post.status.waiting.for.approval"
                );
            }
        } else if (post.status === "waiting") {
            if (post.type === "draft") {
                status = this.language.getLine("post.status.draft");
            } else {
                status = post.statusMessage;
            }
        } else if (post.status === "notApproved") {
            status = this.language.getLine("post.status.not.approved");
        } else if (post.status === "draft") {
            status = this.language.getLine("post.status.draft");
        } else if (post.status === "underPost") {
            status = this.language.getLine("post.status.underPost");
        } else if (post.status === POST_STATUS_FAILED) {
            status = this.language.getLine("post.status.failed");
        } else if (post.status === POST_STATUS_PARTIALLY_FAILED) {
            status = this.language.getLine("post.status.partially.failed");
        }

        return status;
    }

    /**
     * Get post body
     * @param {any} post
     * @return {string}
     * */
    getMessage(post: any) {
        // if (post.type === 'draft' && !!post.draftSettings) {
        //     return post.draftSettings.default.mainCopy;
        // }
        // return post.mainCopy;
        return CommonPostHelpers.getShortMainCopy(post, 235);
    }

    /**
     * Get social site name or type
     * @param {any} post
     * @return {string}
     * */
    getSocialSite(post: any) {
        if (post.socialSites.length === 1) {
            return post.socialSites[0].socialSiteName;
        }
        return post.socialType;
    }

    /**
     * Get active from date
     * @param {any} post
     * @return {string}
     * */
    getActiveFrom(post: any) {
        if (
            post.type === "draft" &&
            !!post.draftSettings &&
            post.draftSettings.default
        ) {
            return Utils.get(post, "draftSettings.default.activeFrom", "");
        }
        return post.activeFrom;
    }

    /**
     * Select action
     *
     * @param event
     * @param value
     */
    selectAction(event: MouseEvent, value: string): void {
        event.preventDefault();
        event.stopPropagation();

        if (
            value !== undefined &&
            value !== "undefined" &&
            this.dataTableHelper.selectedItems.length > 0
        ) {
            switch (value) {
                case "approve":
                    this.changePostStatus("approved");
                    break;
                case "decline":
                    this.changePostStatus("notApproved");
                    break;
                case "delete":
                    if (this.dataTableHelper.selectedItems.length > 0) {
                        const confirmRef = this._dialog.open(
                            DialogConfirmComponent,
                            {
                                data: {
                                    message: LanguageService.getLine(
                                        "post.list.delete.multiple.confirm.message",
                                        {
                                            itemCount:
                                                this.dataTableHelper
                                                    .selectedItems.length,
                                        }
                                    ),
                                    yesButtonText: LanguageService.getLine(
                                        "post.list.delete.multiple.confirm"
                                    ),
                                },
                            }
                        );

                        confirmRef.afterClosed().subscribe((result) => {
                            if (result) {
                                this.loader = this._dialog.open(
                                    DialogLoaderComponent,
                                    {
                                        disableClose: true,
                                        panelClass: "dialog-loader-modal",
                                        minWidth: "200vw",
                                        minHeight: "200vh",
                                        hasBackdrop: false,
                                    }
                                );
                                const ids = [];
                                for (const post of this.dataTableHelper
                                    .selectedItems) {
                                    ids.push(post["postID"]);
                                }
                                this.deletePost(
                                    ids,
                                    (response) => {
                                        this._listChange = true;

                                        const errors = response?.errors || {};
                                        if (Object.keys(errors).length) {
                                            for (const postID of ids) {
                                                const postIndex =
                                                    this.posts.findIndex(
                                                        (item) =>
                                                            item.postID ===
                                                            postID
                                                    );
                                                const post =
                                                    this.posts[postIndex];
                                                if (errors[postID]) {
                                                    if (post) {
                                                        post.errorMessage =
                                                            errors[postID];
                                                    }
                                                } else {
                                                    this.posts.splice(
                                                        postIndex,
                                                        1
                                                    );
                                                    this.collectionList.clearSelection();
                                                    // trigger list view refresh
                                                    this.posts = this.posts.map(
                                                        (_post) => _post
                                                    );
                                                }
                                            }
                                        } else {
                                            this.loader
                                                .afterClosed()
                                                .subscribe(() => {
                                                    // TODO nyelvesítás és más szöveg
                                                    this.openModal
                                                        .successModal(
                                                            DialogSuccessComponent,
                                                            {
                                                                message:
                                                                    "Success post delete!",
                                                            }
                                                        )
                                                        .afterClosed()
                                                        .subscribe(() => {
                                                            this.collectionList.clearSelection();
                                                            this.getPosts();
                                                        });
                                                });
                                        }

                                        this.loader.close();
                                    },
                                    (error) => {
                                        this.loader
                                            .afterClosed()
                                            .subscribe(() => {
                                                this.collectionList.clearSelection();
                                                // TODO nyelvesítés és más szöveg
                                                this.openModal.errorModal(
                                                    DialogErrorComponent,
                                                    {
                                                        message:
                                                            "Failed post delete!",
                                                    }
                                                );
                                            });

                                        this.loader.close();
                                    }
                                );
                                // this.deleteTemplates({'templateIDs': JSON.stringify(ids)});
                            }
                        });
                    } else {
                        this._dialog.open(DialogWarnComponent, {
                            data: {
                                message: LanguageService.getLine(
                                    "post.list.delete.error.multipleSelect"
                                ),
                            },
                        });
                    }

                    break;
            }
        }
    }

    /**
     * Toggle table columns
     * @param event
     * */
    toggleColumns(event): void {
        Object.keys(this.columnVisibility).forEach((column) => {
            this.columnVisibility[column].visible =
                event.value.includes(column);
        });
    }

    @Debounce(500)
    onStatusChange() {
        this.getPosts();
    }

    /**
     * Delete post click event
     * @param {PostInterface} post
     */
    deletePostClick(post: PostInterface): Promise<any> {
        return new Promise((resolve, reject) => {
            this._dialog
                .open(DialogConfirmComponent, {
                    data: {
                        message: LanguageService.getLine(
                            "post.list.confirmDelete.message"
                        ),
                        yesButtonText: LanguageService.getLine(
                            "post.list.confirmDelete"
                        ),
                    },
                })
                .afterClosed()
                .subscribe((doIt) => {
                    if (doIt) {
                        const _loader = this.openModal.loader(
                            DialogLoaderComponent
                        );

                        this.deletePost(
                            [post.postID],
                            (response) => {
                                this._listChange = true;

                                if (
                                    response?.errors &&
                                    response.errors[post.postID]
                                ) {
                                    _loader.afterClosed().subscribe(() => {
                                        // TODO nyelvesítés és más szöveg
                                        this.openModal
                                            .errorModal(DialogErrorComponent, {
                                                message:
                                                    response.errors[
                                                        post.postID
                                                    ],
                                            })
                                            .afterClosed()
                                            .subscribe(() => {
                                                reject(response.errors);
                                            });
                                    });
                                    post.errorMessage =
                                        response.errors[post.postID];
                                } else {
                                    _loader.afterClosed().subscribe(() => {
                                        // TODO nyelvesítás és más szöveg
                                        this.openModal
                                            .successModal(
                                                DialogSuccessComponent,
                                                {
                                                    message:
                                                        "Success post delete!",
                                                }
                                            )
                                            .afterClosed()
                                            .subscribe(() => {
                                                this.getItems();
                                                resolve(response);
                                            });
                                    });

                                    this.posts.splice(
                                        this.posts.indexOf(post),
                                        1
                                    );
                                }

                                _loader.close();
                            },
                            (error) => {
                                _loader.afterClosed().subscribe(() => {
                                    // TODO nyelvesítés és más szöveg
                                    this.openModal
                                        .errorModal(DialogErrorComponent, {
                                            message: "Failed post delete!",
                                        })
                                        .afterClosed()
                                        .subscribe(() => {
                                            reject(error);
                                        });
                                });

                                _loader.close();
                            }
                        );
                    }
                });
        });
    }

    /**
     * Edit post
     *
     * @param {PostInterface} post
     */
    editPostClick(post: PostInterface) {
        this.openModal
            .editPost(PostManagerComponent, post)
            .afterClosed()
            .subscribe((isChange) => {
                if (isChange) {
                    this.getPosts();
                }
            });
    }

    /**
     * Copy post
     *
     * @param {PostInterface} post
     */
    copyPost(post: PostInterface) {
        this.openModal
            .copyPost(PostManagerComponent, post)
            .afterClosed()
            .subscribe((isChange) => {
                if (!!isChange) {
                    this.getPosts();
                }
            });
    }

    commentPostClick(post: PostInterface) {
        this.openModal
            .commentToPost(PostPreviewComponent, post)
            .afterClosed()
            .subscribe((isChange) => {
                if (!!isChange) {
                    this.getPosts();
                }
            });
    }

    showPreview(post: PostInterface) {
        this.previewService.showPreview(post).then((data) => {
            if (data) {
                this.onStatusChange();
            }
        });
    }

    private changePostStatus(status: PostStatus) {
        const successMessages = {
            approved: this.language.getLine("post.status.message.approved"),
            notApproved: this.language.getLine(
                "post.status.message.notApproved"
            ),
        };
        const errorMessages = {
            approved: this.language.getLine(
                "post.status.message.approved.error"
            ),
            notApproved: this.language.getLine(
                "post.status.message.notApproved.error"
            ),
        };
        const loader = this.openModal.loader(DialogLoaderComponent);

        this.postActionsService
            .setPostStatus(
                {
                    postIDs: JSON.stringify(
                        this.dataTableHelper.selectedItems.map(
                            (item) => item.postID
                        )
                    ),
                    status: status,
                },
                "post"
            )
            .then((response) => {
                loader.afterClosed().subscribe(() => {
                    if (!response.errors) {
                        this.openModal
                            .successModal(DialogSuccessComponent, {
                                message: successMessages[status],
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.collectionList.clearSelection();
                                this.getPosts();
                            });
                    } else {
                        let errorMessage = "";
                        for (const id of Object.keys(response.errors)) {
                            const message = response.errors[id];

                            errorMessage += `${id} - ${message}\n`;
                        }

                        this.openModal
                            .successModal(DialogErrorComponent, {
                                message: errorMessage,
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.collectionList.clearSelection();
                                this.getPosts();
                            });
                    }
                });

                loader.close();
            })
            .catch((error) => {
                loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message:
                            FormValidationService.readError(error).message ||
                            errorMessages[status],
                    });
                });

                loader.close();
            });
    }

    /**
     * Set default post order
     */
    private setDefaultOrder() {
        this.filters["order"] = Helpers.getOrderFormat("activeFrom-desc");
    }

    getIconForGmbType(type) {
        switch (type) {
            case "event":
                return "event";
            case "offer":
                return "local_offer";
            default:
                return "new_releases";
        }
    }

    /**
     * Get related users (to the current user)
     */
    getRelatedUsers(postIDs?: number[]) {
        if (!postIDs) {
            this.users.service.getRelatedUsers()
            .then((response) => {
                this.relatedUsers = response.users;
            })
            .catch((error) => {
                this.service.showErrorAlert('Unexpected error while getting related users'); //TODO: language
            });
        } else {
            this.users.service.getObjectRelatedUsers("post", postIDs)
            .then((response) => {
                this.objectRelatedUsers = response.usersForObject;
            })
        }
    }

    /**
     * Get user from the related users based on userID
     *
     * @param userID
     */
    getUserFromID(userID) {
        let foundUser = this.relatedUsers.find(user => user.userID == userID);
        return foundUser ? foundUser : {name: "Inactivated User", organizationName: "", roleName: ""};
    }

    /**
     * Get where a post was posted from (Esemdee or Facebook or Instagram or etc.)
     *
     * @param post
     */
    getPostSource(post) {
        let source = post && post?.status === POST_STATUS_POSTED
            ? CommonPostHelpers.getSource(post)
            : "";
        return source;
    }

    /**
     * Get tooltip for post social sites list
     *
     * @param post
     */
    getTooltipForSocialNetwork(post, number: number) {
        if (post) {
            const socialSiteNames = [];

            for (const socialSite of post.socialSites.slice(number, post.socialSites.length)) {
                socialSiteNames.push(
                    socialSite.socialSiteName
                );
            }

            return socialSiteNames.join(", ");
        }

        return "";
    }

    filterToggleClicked() {
        if (!this.filterToggled) {
            this.filterClickEventsSubject.next();
        }
        this.filterToggled = true;
    }

    toggleViews(currentView: string) {
        if (currentView === "list") {
            this.listView = true;
            this.cardView = false;
            this.feedView = false;
        } else if (currentView === "card") {
            this.listView = false;
            this.cardView = true;
            this.feedView = false;
        } else if (currentView === "feed") {
            this.listView = false;
            this.cardView = false;
            this.feedView = true;
        }
    }

    @HostListener(`document:${CustomEvents.POST_OR_TEMPLATE_EDIT}`, ['$event'])
    getPostsOnPostEdit(): void {
        this.getPosts();
    }

    createTemplateFromPost(post: PostInterface) {
        post.activeFromInput = null;
        this.openModal.createTemplateRaw(PostTemplateManagerComponent, {
            data: {
                template: post,
                isTemplateFromPost: true,
            },
        });
    }

    approvement(item: PostInterface, approve: boolean): void {
        const loader = this.openModal.loader(DialogLoaderComponent);
        const status = approve
            ? POST_STATUS_APPROVED
            : POST_STATUS_NOT_APPROVED;
        const successMsgKey = approve
            ? "post.status.message.approved.success"
            : "post.status.message.notApproved.success";
        const errorMsgKey = approve
            ? "post.status.message.approved.error"
            : "post.status.message.notApproved.error";

        this.postService
            .setPostStatus(
                [item.postID],
                status
            )
            .then((response) => {
                this.openModal
                    .successModal(DialogSuccessComponent, {
                        message: this.language.getLine(successMsgKey),
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.getPosts();
                    });
            })
            .catch((error) => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        FormValidationService.readError(error).message ||
                        this.language.getLine(errorMsgKey),
                });
            })
            .finally(() => loader.close());
    }

    /**
    * Trigger create new comment in comments component
    */
    createComment(postID: number): void {
        let message = document.getElementById('commentText_' + postID).innerHTML;
        if (message && message !== "") {
            message = message.replace(/<span.*?id="(.*?)".*?>(.*?)<\/span>/g, '##$1##');

            this.createCommentInProgress = true;
            this.feedViewComment.find((commentComponent) => {return commentComponent.objectID == postID}).createComment(message);
        }
    }

    successCreateComment(response): void {
        this.createCommentInProgress = false;
        this.unformattedComment = "";
        this.htmlDoc = document.getElementById('commentText_' + response.comment.objectID);
        this.htmlDoc.innerHTML = "";
    }

    failedCreateComment(id): void {
        this.createCommentInProgress = false;
        this.unformattedComment = "";
        this.htmlDoc = document.getElementById('commentText_' + id);
        this.htmlDoc.innerHTML = "";
    }

    relatedUserSelected($event: any, postID: number) {
        setTimeout(() => {
            this.htmlDoc = document.getElementById('commentText_' + postID);
            this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(
                '##' + $event.userID + '##',
                '<span id="' + $event.userID + '" class="mention" ' +
                'style="color: #0072f0;" contenteditable=false>@' + $event.name + '</span>&nbsp;'
            );
            // set cursor to the end of the text
            this.htmlDoc.focus();
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(this.htmlDoc.childNodes[this.htmlDoc.childNodes.length - 1], 1);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);

        }, 10);
    }
}
