import {Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
    NgForm
} from '~/node_modules/@angular/forms';

@Component({
    selector: 'smd-ng-select',
    templateUrl: './ng-select.component.html',
    styleUrls: ['./ng-select.component.scss']
})
export class NgSelectComponent implements OnInit {

    @Input() bindLabel;
    @Input() bindValue;
    @Input() addTag = false;
    @Input() appendTo = '#main-wrapper';
    @Input() items: any[] = [];
    @Input() multiple = false;
    @Input() loading = false;
    @Input() virtualScroll = false;
    @Input() closeOnSelect = null;
    @Input() placeholder;
    @Input() formControl: FormControl;

    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
        if (this.closeOnSelect === null) {
            this.closeOnSelect = !this.multiple;
        }
    }

    /**
     * Selection change event
     *
     * @param event
     */
    selectionChange(event) {
        this.change.emit(event);
    }
}
