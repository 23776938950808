import {NgModule} from '@angular/core';
import {DialogCreateAnalyticsComponent} from './dialog-create-analytics.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {LanguagesModule} from '../../languages/languages.module';
import {SharedModule} from '~/src/app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        LanguagesModule
    ],
    declarations: [DialogCreateAnalyticsComponent],
    entryComponents: [DialogCreateAnalyticsComponent]
})
export class CreateWidgetModule {}
