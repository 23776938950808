import {Injectable} from '@angular/core';
import {
    IScheduleRule,
    IScheduleRulePresetFormData,
    IScheduleRulePresetListResponse,
    IScheduleRulePresetResponse,
    IScheduleRuleRequestData, IScheduleRulesResponse
} from '~/src/app/modules/autofeed/interfaces/schedule-rule.interface';
import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {ScheduleRulePresetModel} from '~/src/app/modules/autofeed/models/schedule-rule-preset.model';
import {
    AUTOFEED_PRESET_RESOURCE,
    AUTOFEED_RESOURCE,
    AUTOFEED_RULE_PERIODS
} from '~/src/app/modules/autofeed/autofeed.constants';
import {LanguageService} from '~/src/app/services/language.service';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import Utils from '~/src/app/core/utils';
import {CategoriesService} from '~/src/app/modules/categories/categories.service';
import {Debug} from '~/src/app/core/debug';

@Injectable({
    providedIn: 'root'
})
export class AutofeedService {
    rulePeriods = AUTOFEED_RULE_PERIODS;

    constructor(
        private backendService: BackendService,
        private categoryService: CategoriesService
    ) {}

    /**
     * Get schedule rule presets
     * @param {RequestParams} params
     * @return {Promise<IScheduleRulePresetListResponse<ScheduleRulePresetModel>>}
     */
    async getPresets(params: RequestParams = {}): Promise<IScheduleRulePresetListResponse<ScheduleRulePresetModel>> {
        try {
            const presetList: IScheduleRulePresetListResponse = await this.backendService.get(AUTOFEED_PRESET_RESOURCE, params);
            const presets: ScheduleRulePresetModel[] = (presetList?.presets || []).map(preset => new ScheduleRulePresetModel(preset));

            if (presets && presets.length) {
                const {categories} = await this.categoryService.getCategories();

                presets.forEach(preset => {
                    const category = categories.find(item => item.categoryID === preset.categoryID);

                    if (category) {
                        preset.categoryName = category.name;
                    } else {
                        Debug.warn('Not found category for this preset: ', preset);
                    }
                });
            }

            return {
                ...presetList,
                presets
            };
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * Create schedule rule preset
     * @param {IScheduleRulePresetFormData} data
     * @return {Promise<any>}
     */
    createPreset(data: IScheduleRulePresetFormData): Promise<any> {
        return this.backendService.post(AUTOFEED_PRESET_RESOURCE, this.rulePresetToFormData(data));
    }

    /**
     * Save schedule rule preset
     * @param {number} presetID
     * @param {IScheduleRulePresetFormData} data
     * @return {Promise<any>}
     */
    savePreset(presetID: number, data: IScheduleRulePresetFormData): Promise<any> {
        return this.backendService.put(`${AUTOFEED_PRESET_RESOURCE}/${presetID}`, this.rulePresetToFormData(data));
    }

    /**
     * Remove schedule rule presets
     * @param {number | number[]} presetID
     * @return {Promise<any>}
     */
    removePreset(presetID: number | number[]): Promise<any> {
        presetID = Array.isArray(presetID) ? presetID : [presetID];
        return this.backendService.post(`${AUTOFEED_PRESET_RESOURCE}/bulk-delete`, {presetID});
    }

    /**
     * Set schedule rule on social site
     * @param scheduleRules
     * @param {string[]} socialSiteID
     * @return {Promise<any>}
     */
    createScheduleRule(scheduleRules: IScheduleRule[], socialSiteID: number[]): Promise<IScheduleRulesResponse> {
        const data: IScheduleRuleRequestData = {
            socialSiteID,
            presetID: scheduleRules.map(rule => rule.presetID).filter(id => id),
            postTimeData: scheduleRules.map(rule => ({
                time: `${rule.hour.toString().padStart(2, '0')}:${rule.minute.toString().padStart(2, '0')}`,
                days: rule.period,
                categoryID: rule.categoryID,
            }))
        };

        return this.backendService.post(AUTOFEED_RESOURCE, data);
    }

    /**
     * Set schedule rules on social sites
     * @param {IScheduleRule[]} scheduleRules
     * @param {number[]} socialSiteID
     * @return {Promise<{success: boolean; message: string}>}
     */
    async createScheduleRules(scheduleRules: IScheduleRule[], socialSiteID: number[]): Promise<{success: boolean, message: string}> {
        let message = '';
        let success = false;

        try {
            await this.createScheduleRule(scheduleRules, socialSiteID)
                .then(response => {
                    success = true;

                    const presets: {presetID: number, presetName: string}[] = scheduleRules.map(rule => ({
                        presetID: rule.presetID,
                        presetName: rule.presetName
                    }));
                    const wrongPresetIDs = Object.keys(response?.autoFeeds?.errors || {}).map(id => Number(id));

                    presets.forEach(preset => {
                        if (wrongPresetIDs.includes(preset.presetID)) {
                            const info = response.autoFeeds.errors[preset.presetID.toString()].info;
                            message += this.getFailedApplyMessage({error: {error: {info}}}, preset.presetName);
                        } else {
                            message += this.getSuccessApplyMessage(response, preset.presetName);
                        }
                    });

                    return Promise.resolve(response);
                })
                .catch(error => {
                    message = FormValidationService.readError(error).message;
                    return Promise.resolve(error);
                })
        } catch (e) {
            return Promise.resolve({success, message});
        }

        return {success, message};
    }

    /**
     * Show success message on apply preset settings
     * @param res
     * @param {string} ruleName
     */
    getSuccessApplyMessage(res: any, ruleName?: string): string {
        let message = LanguageService.getLine('post.autoFeedPreset.applyOnSocialSites.success');

        if (ruleName) {
            message = `
                <h3 class="text-left font-20"><i class="fa fa-check-circle text-success"></i> ${ruleName}:</h3>
                <p class="text-left">${message}</p>
            `;
        }

        return message;
    }

    /**
     * Show error(s) on apply preset settings
     * @param error
     * @param {string} ruleName
     */
    getFailedApplyMessage(error: any, ruleName?: string): string {
        const formMessages = FormValidationService.readError(error).formMessages;
        let message = Object.keys(formMessages)
            .filter(field => field && (formMessages[field] || '').trim() !== '')
            .map(field => `<p class="text-left"><strong>${Utils.lodash.capitalize(field)}:</strong> ${formMessages[field]}</p>`)
            .join('').trim();

        if (!message) {
            message = `<p class="text-left">${FormValidationService.readError(error).message || LanguageService.getLine('post.autoFeedPreset.applyOnSocialSites.failed')}</p>`;
        }

        if (ruleName) {
            message = `
                <h3 class="text-left font-20"><i class="fa fa-times-circle text-danger"></i> ${ruleName}:</h3>
                ${message}
            `;
        }

        return message;
    }

    /**
     * Prepare schedule rule preset to request
     * @param {IScheduleRulePresetFormData} preset
     * @return {IScheduleRulePresetResponse}
     */
    private rulePresetToFormData(preset: IScheduleRulePresetFormData): IScheduleRulePresetResponse {
        return {
            name: preset.name,
            organizationID: preset.organizationID,
            settings: JSON.stringify({
                categoryID: preset.categoryID,
                categoryName: preset.categoryName,
                period: preset.period,
                hour: preset.hour,
                minute: preset.minute
            })
        };
    }
}
