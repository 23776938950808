
export interface PostStatusConfig {
    [key: string]: {
        iconClass: string;
        statusMessageKey?: string;
        color?: string;
    };
}

export const PostSiteStatusConfig: PostStatusConfig = {
    waiting: {
        iconClass: 'mdi ml-1 mdi-clock text-warning-origin',
    },

    failedToPost: {
        iconClass: 'mdi ml-1 mdi-close-circle-outline text-danger'
    },

    posted: {
        iconClass: 'mdi ml-1 mdi-check-circle-outline text-success'
    }
};

export const PostStatusConfigs: PostStatusConfig = {
    posted: {
        iconClass: 'fa fa-circle post-status-Posted',
        statusMessageKey: 'post.status.posted',
        color: '#3fcfff'
    },

    failed: {
        iconClass: 'fa fa-circle post-status-Failed',
        statusMessageKey: 'post.status.failed',
        color: 'red'
    },

    partiallyFailed: {
        iconClass: 'fa fa-circle post-status-partiallyFailed',
        statusMessageKey: 'post.status.partially.failed',
        color: '#f58d3d'
    },

    finished: {
        iconClass: 'fa fa-circle post-status-Posted',
        color: '#3fcfff'
    },

    inactive: {
        iconClass: 'fa fa-circle post-status-Inactive',
        statusMessageKey: 'post.status.inactive',
        color: '#ab58e7'
    },

    failedToPost: {
        iconClass: 'fa fa-circle post-status-Inactive',
        color: 'red'
    },

    active: {
        iconClass: 'fa fa-circle post-status-Approved',
        statusMessageKey: 'post.status.active',
        color: '#57d580'
    },

    approved: {
        iconClass: 'fa fa-circle post-status-Approved',
        statusMessageKey: 'post.status.approved',
        color: '#57d580'
    },

    waitingForApproval: {
        iconClass: 'fa fa-circle post-status-waitingForApproval',
        statusMessageKey: 'post.status.waiting.for.approval',
        color: '#fff133'
    },

    waitingForApprovalDraft: {
        iconClass: 'fa fa-circle post-status-Draft',
        statusMessageKey: 'post.status.draft',
        color: 'gray'
    },

    waiting: {
        iconClass: 'fa fa-circle post-status-waitingForApproval',
        color: '#fff133'
    },

    waitingDraft: {
        iconClass: 'fa fa-circle post-status-Draft',
        statusMessageKey: 'post.status.draft',
        color: 'gray'
    },

    notApproved: {
        iconClass: 'fa fa-circle post-status-notApproved',
        statusMessageKey: 'post.status.not.approved',
        color: '#f96a68'
    },

    draft: {
        iconClass: 'fa fa-circle post-status-Draft',
        statusMessageKey: 'post.status.draft',
        color: 'gray'
    },

    underPost: {
        iconClass: 'fa fa-circle post-status-UnderPost',
        statusMessageKey: 'post.status.message.underPost',
        color: '#1466d0'
    }
};
