import {Component} from '@angular/core';

@Component({
    selector: 'smd-rss-manager-modal',
    templateUrl: './rss-manager-modal.component.html',
    styleUrls: ['./rss-manager-modal.component.scss']
})
export class RssManagerModalComponent {
    constructor() {}
}
