import {PostInterface} from '~/src/app/modules/posts/post-actions';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import {StringSupport} from '~/src/app/core/helper/string-support';
import Utils from '~/src/app/core/utils';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {BASE_DATE_FORMAT, BASE_DATETIME_FORMAT, PostSources} from '~/src/app/configs/configs';
import {SocialSiteInterface} from '~/src/app/components/social-site-select/social-site-select.component';
import {FormGroup} from '@angular/forms';
import {SmdFile} from '~/src/app/services/file.class';
import {ObjectSupport} from '~/src/app/core/helper/object-support';
import {
    MAX_CHARACTER_LENGTH_UPPER_LIMIT, POST_STATUS_APPROVED,
    POST_STATUS_INACTIVE,
    POST_STATUS_NOT_APPROVED,
    POST_STATUS_POSTED,
    POST_STATUS_WAITING_FOR_APPROVAL, POST_TEMPLATE_STATUS_ACTIVE,
    POST_TEMPLATE_STATUS_INACTIVE,
    POST_TEMPLATE_STATUS_NOT_APPROVED,
    POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL,
    POST_TYPE_DRAFT,
    PostFormControlNames,
    POST_STATUS_FAILED,
    POST_STATUS_PARTIALLY_FAILED
} from '~/src/app/modules/social-media-post/social-media-post.constant';
import {
    MENTION_FORMATTERS,
    SocialMediaPlatforms
} from '~/src/app/modules/social-media-post/social-media-platforms-config';
import {MentionHelpers} from '~/src/app/modules/posts/mention-helpers';
import * as moment from 'moment';
import {CoreConfig} from '~/src/app/core/core.config';
import {SOCIAL_MEDIA_TYPE_TWITTER} from '~/src/app/core/constants';
import {LoggedUser} from '~/src/app/services/logged-user';

declare const twttr: any;

interface ISetPostCharacterNumberConfig {
    formGroup: FormGroup | { value: { [key: string]: any } };
    postContentMaxLength: number;
    platform: string;
    currentCharacterNumber?: number;
}

interface ISetPostCharacterNumberResults {
    characterNumber: number;
    characterNumberIsInvalid: boolean;
    doNotUpdate?: boolean;
}

interface ICheckApprovementData {
    isAdminMode: boolean;
    status: string;
    type: string;
    systemType: string;
    hasPartnerConfig: boolean;
    approveButtonVisible?: boolean;
    declineButtonVisible?: boolean;
    templateOrganizationID?: number;
}

interface ICheckCommentData {
    systemType: string;
    isAdminMode: boolean;
}

export default class CommonPostHelpers {

    /**
     * Get post source label
     *
     * @param {PostInterface} post
     * @returns {string | any}
     */
    static getSource(post: PostInterface) {
        const isThirdParty = post.isThirdParty && post.isThirdParty === 'yes';

        if (isThirdParty && post.socialSites && post.socialSites.length) {
            const sourceKey = Utils.lodash.upperFirst(post.socialSites[0].socialType);

            if (sourceKey in PostSources) {
                return PostSources[sourceKey].label;
            }
        }

        return PostSources.Esemdee.label;
    }

    /**
     * Get post/template short message
     *
     * @param {PostInterface | PostTemplateInterface} entity
     * @param {number} length
     * @returns {string}
     */
    static getShortMessage(entity: PostInterface | PostTemplateInterface, length = 150) {
        const headline = entity.headline ? entity.headline + ' ' : '';
        const subHeadline = entity.subHeadline ? entity.subHeadline + ' ' : '';
        const mainCopy = entity.mainCopy ? entity.mainCopy + ' ' : '';
        const signature = entity.signature ? entity.signature + ' ' : '';
        let shortMessage = headline + subHeadline + mainCopy + signature;

        // trim string
        shortMessage = shortMessage.trim();

        // remove HTML
        shortMessage = StringSupport.removeHtml(shortMessage);

        // get first x character
        shortMessage = Utils.lodash.truncate(shortMessage, {length});

        return shortMessage;
    }

    /**
     * Get post short mainCopy
     *
     * @param post
     * @param {number} length
     * @returns {string}
     */
    static getShortMainCopy(post, length = 150) {
        let mainCopy = '';
        if (post.type === 'draft' && !!post.draftSettings) {
            mainCopy = Utils.get(post, 'draftSettings.default.mainCopy', '');
        } else {
            mainCopy = Utils.get(post, 'mainCopy', '');
        }
        // trim string
        let shortMessage = mainCopy.trim();
        // remove HTML
        shortMessage = StringSupport.removeHtml(shortMessage);
        // get first x character
        shortMessage = Utils.lodash.truncate(shortMessage, {length});
        return shortMessage;
    }

    /**
     * Get cover media src
     *
     * @param {PostInterface | PostTemplateInterface} entity
     * @returns {string}
     */
    static getCoverMediaSrc(entity: PostInterface | PostTemplateInterface): string {
        const ogImageSrc = this.getOgImage(entity);
        const carouselCoverSrc = this.getCarouselCover(entity);
        let src = null;

        if (ogImageSrc) {
            return ogImageSrc;
        }

        if (carouselCoverSrc) {
            return carouselCoverSrc;
        }

        if (entity.medias && entity.medias.length) {
            src = entity.medias[0].thumb;
        }

        return src;
    }

    /**
     * Get OG image src
     *
     * @param {PostInterface | PostTemplateInterface} entity
     * @returns {string}
     */
    static getOgImage(entity: PostInterface | PostTemplateInterface): string {
        const medias = entity.medias;

        if (medias && medias.length && medias[0].type === FILE_TYPES.OG) {
            return medias[0].url;
        }

        const linkshare = entity.linkshare && Object.keys(entity.linkshare).length
            ? entity.linkshare
            : entity['linkShare'];
        return Utils.lodash.get(linkshare, 'image', Utils.get(linkshare, 'linkshareImageURL', null));
    }

    /**
     * Get carousel cover
     *
     * @param {PostInterface | PostTemplateInterface} entity
     * @returns {string}
     */
    static getCarouselCover(entity: PostInterface | PostTemplateInterface): string {
        const medias = entity.medias;
        const haveCarousel = !!medias
            && !!medias.length
            && medias[0].type === FILE_TYPES.CAROUSEL
            && !!medias[0].carouselItems
            && !!medias[0].carouselItems.length;

        return haveCarousel ? medias[0].carouselItems[0].imageUrl : null;
    }

    static getLastUrlFrom(urls) {
        const url = Array.from(urls);
        let lastUrl: string = url[url.length - 1] as string;

        lastUrl = (lastUrl + '').replace(/\/+$/, '');

        return lastUrl;
    }

    /**
     * @param {any[]} socialSites
     * @param {SocialSiteInterface[]} selectedSocialSites
     * @return {any[]}
     */
    static filterSocialSiteList(socialSites: any[] = [], selectedSocialSites: SocialSiteInterface[] = []) {
        const platformConfig = SocialMediaPlatforms;

        for (const config of platformConfig) {
            const platform = config.platform;
            const selectableSocialSiteLimit = config.selectableSocialSiteLimit || null;
            const selectedSitesByPlatform = selectedSocialSites.filter(site => site.socialType === platform);

            if (typeof selectableSocialSiteLimit === 'number') {
                if (selectedSitesByPlatform.length === selectableSocialSiteLimit) {
                    const selectedSiteIDs = selectedSitesByPlatform.map(site => site.siteID);
                    socialSites = socialSites.map(site => {
                        if (site.socialType === platform) {
                            site.disabled = !selectedSiteIDs.includes(site.siteID);
                        }
                        return site;
                    });
                } else {
                    socialSites = socialSites.map(site => {
                        if (site.socialType === platform) {
                            site.disabled = false;
                        }
                        return site;
                    });
                }
            }
        }

        return socialSites;
    }

    /**
     * @param {SocialSiteInterface[]} socialSitesParam
     * @param {SocialSiteInterface[]} selectedSocialSitesParam
     * @return {number[]}
     */
    static selectAllSocialSite(socialSitesParam: SocialSiteInterface[], selectedSocialSitesParam: SocialSiteInterface[]): number[] {
        const selectedSocialSites = [...selectedSocialSitesParam];
        const selectedSiteIDs = selectedSocialSites.map(site => site.siteID);
        let socialSites = [...socialSitesParam].filter(site => !selectedSiteIDs.includes(site.siteID));

        for (const platformConfig of SocialMediaPlatforms) {
            if (typeof platformConfig.selectableSocialSiteLimit === 'number') {
                const selectedPlatformSitesNumber = selectedSocialSites
                    .filter(site => site.socialType === platformConfig.platform).length;
                const selectablePlatformSiteNumber =
                    platformConfig.selectableSocialSiteLimit - selectedPlatformSitesNumber;
                let platformSiteIndex = 0;

                socialSites = socialSites.filter(site => {
                    if (site.socialType === platformConfig.platform) {
                        platformSiteIndex++;
                        if (platformSiteIndex > selectablePlatformSiteNumber) {
                            return false;
                        }
                    }
                    return true;
                });
            }
        }

        return [
            ...selectedSiteIDs,
            ...socialSites.map(site => site.siteID)
        ];
    }

    /**
     * Validate post content character length
     *
     * @param {ISetPostCharacterNumberConfig} config
     * @return {ISetPostCharacterNumberResults}
     */
    static setPostCharacterNumber(config: ISetPostCharacterNumberConfig): ISetPostCharacterNumberResults {
        const formData = config.formGroup.value;
        const textControlNames = [
            PostFormControlNames.Headline,
            PostFormControlNames.SubHeadline,
            PostFormControlNames.Message,
            PostFormControlNames.Signature
        ];
        let characterNumber = 0;

        let isJustMark = false;
        for (const controlName in formData) {
            let value = formData[controlName] || '';
            if (textControlNames.includes(controlName)) {
                //console.log(controlName + " - " + value);
                if (value.startsWith('<mark>') && value.endsWith('</mark>')) {
                    isJustMark = true;
                }

                // value = this.formatMention(value, config.platform);
                value = MentionHelpers.formatMention(value, config.platform, MENTION_FORMATTERS[config.platform] || null);
                // value = this.formatHashTag(value);
                value = Utils.htmlEntities.decode(value).replace(/<br(.*?)>/g, '');
                value = value.replace(/<mark(.*?)>/g, '').replace(/<\/mark>/g, '');
                characterNumber += (config.platform !== SOCIAL_MEDIA_TYPE_TWITTER)
                    ? value.length
                    : twttr.txt.getTweetLength(value);
            }
        }

        const characterNumberIsInvalid = characterNumber > (config.postContentMaxLength || MAX_CHARACTER_LENGTH_UPPER_LIMIT);

        // do not update character number if we only highlighted some text because for some reason tinymce is bugged
        const doNotUpdate = isJustMark;

        return {characterNumber, characterNumberIsInvalid, doNotUpdate};
    }

    /**
     * @param {string} value
     * @return {string}
     */
    static formatHashTag(value: string) {

        if (value) {
            const tempEl = document.createElement('div');
            tempEl.innerHTML = value;

            for (const tagNode of Array.from(tempEl.querySelectorAll('.autocomplete-item--hashtag'))) {
                value = value.replace(tagNode.outerHTML, tagNode.textContent);
            }

            tempEl.remove();
        }

        return value;
    }

    /**
     * @param entity
     * @return {any}
     */
    static mergeDataWithDraftData(entity: any) {
        if (entity.draftSettings) {
            const defaultData = Utils.get(entity, 'draftSettings.default', null);

            if (defaultData) {
                if ('type' in (defaultData || {})) {
                    delete defaultData.type;
                }

                if (!defaultData?.medias?.length) {
                    defaultData.medias = (defaultData.medias || []).map(media => new SmdFile(media));
                }

                entity = {
                    ...entity,
                    ...(defaultData || {})
                };
            }
        }

        let linkShare = {};

        if ('archive' in entity && entity.archive) {
            let data = ObjectSupport.parse(entity.archive);

            data = Utils.get(data, 'draftSettings.default', null) || data;

            if (data) {
                const linkshareData = data.linkShare || data;

                if (PostFormControlNames.LinkShareURL in linkshareData) {
                    linkShare = {
                        title: linkshareData[PostFormControlNames.LinkShareTitle],
                        description: linkshareData[PostFormControlNames.LinkShareDescription],
                        url: linkshareData[PostFormControlNames.LinkShareURL],
                        image: linkshareData[PostFormControlNames.LinkShareImageURL],
                    };

                    entity['linkshare'] = linkShare;
                    entity['linkShare'] = data.linkShare;
                }
            }
        }

        // fix: I get social type as array in draft post
        if (entity.socialType && (entity.socialType instanceof Array) && entity.socialType.length) {
            entity.socialType = entity.socialType[0];
        }

        return entity;
    }

    /**
     * Get hour options for schedule hour select
     * @return {number[]}
     */
    static getHourSelectOptions(): number[] {
        const hours = [];

        for (let hour = 0; hour <= 23; hour++) {
            hours.push(hour);
        }

        return hours;
    }

    /**
     * Get minute options for schedule minute select
     * @return {number[]}
     */
    static getMinuteSelectOptions(): number[] {
        const minutes = [];

        for (let minute = 0; minute < 60; minute += 5) {
            minutes.push(minute);
        }

        return minutes;
    }

    /**
     * @param {string} date
     * @return {{date: string; dateTime: string; hour: number; minute: number} | {date: null; dateTime: null; hour: null; minute: null}}
     */
    static roundMinuteToFive(date: string) {

        if (!date) {
            return {
                date: null,
                hour: null,
                minute: null,
                dateTime: null
            };
        }

        let hour = Number(moment(date).format('H'));
        let minute = Number(moment(date).format('m'));
        minute = Math.ceil(minute / 5) * 5;

        if (minute === 60) {
            minute = 0;
            date = moment(date).add(1, 'hour').format(BASE_DATETIME_FORMAT);
            hour = Number(moment(date).format('H'));
        }

        date = moment(date).minute(minute).hour(hour).format(BASE_DATETIME_FORMAT);

        return {
            date: moment(date).format(BASE_DATE_FORMAT),
            hour,
            minute,
            dateTime: date
        };
    }

    static allowCommentOnTemplate(data: ICheckCommentData): boolean {
        const invalidCommentSystemTypes = [
            CoreConfig.getSystemTypes().Generic,
            CoreConfig.getSystemTypes().Branded
        ];
        return !invalidCommentSystemTypes.includes(data.systemType) || data.isAdminMode;
    }

    static allowApprove(data: ICheckApprovementData): boolean {
        return this.hasApprovement(data) &&
            [
                POST_STATUS_INACTIVE, POST_STATUS_WAITING_FOR_APPROVAL, POST_STATUS_NOT_APPROVED,
                POST_TEMPLATE_STATUS_INACTIVE, POST_TEMPLATE_STATUS_NOT_APPROVED, POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL
            ].includes(data.status) && (data.approveButtonVisible || data.isAdminMode);
    }

    static allowDecline(data: ICheckApprovementData): boolean {
        return this.hasApprovement(data) &&
            [
                POST_STATUS_APPROVED, POST_STATUS_WAITING_FOR_APPROVAL,
                POST_TEMPLATE_STATUS_ACTIVE, POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL
            ].includes(data.status) && (data.declineButtonVisible || data.isAdminMode);
    }

    private static hasApprovement(data: ICheckApprovementData): boolean {

        if ((data?.templateOrganizationID && !this.checkOrganizationID(data.templateOrganizationID)) && !data.isAdminMode) {
            return false;
        }

        return data.hasPartnerConfig &&
            data.status !== POST_STATUS_POSTED &&
            data.status !== POST_STATUS_FAILED &&
            data.status !== POST_STATUS_PARTIALLY_FAILED &&
            data.type !== POST_TYPE_DRAFT &&
            ![CoreConfig.getSystemTypes().Generic, CoreConfig.getSystemTypes().Branded].includes(data?.systemType);
    }

    private static checkOrganizationID(organizationID: number): boolean {
        for (let organization of Object.values(LoggedUser.getOrganizations())) {
            if (organization.organizationID === organizationID) return true;
        }
        return false;
    }
}
