import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '~/src/app/services/language.service';
import {Debounce, Helpers, MyErrorStateMatcher} from '~/src/app/services/helpers';
import {DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, UP_ARROW} from '@angular/cdk/keycodes';
import {GeoLocationInterface} from '~/src/app/modules/posts/post-actions.interfaces';
import {NotifyService} from '~/src/app/services/notify.service';
import {Observable} from 'rxjs';
import {PostActionsService} from '~/src/app/modules/posts/post-actions.service';
import {GeoLocationView} from '~/src/app/modules/social-media-post/news-feed-targeting-facebook/news-feed-targeting-facebook.interfaces';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatInput} from '@angular/material/input';
import {LocationRadiusValidators} from '~/src/app/modules/social-media-post/news-feed-targeting-facebook/news-feed-targeting-facebook.options';
import {isEmpty, keys} from 'lodash';
import {FacebookNewsFeedTargetingControlNames} from '~/src/app/modules/social-media-post/social-media-post.constant';

@Component({
    selector: 'smd-news-feed-targeting-facebook',
    templateUrl: './news-feed-targeting-facebook.component.html',
    styleUrls: ['./news-feed-targeting-facebook.component.scss']
})
export class NewsFeedTargetingFacebookComponent implements OnInit {
    /**
     * Set feed targeting from base form
     * @param value
     */
    @Input() set targetingOptions(value) {
        if (!isEmpty(value) && keys(value).length && !this.targetingOptions) {
            this._targetingOptions = value;
            this.setFeedTargeting(value);
        }
    }

    /**
     * Set current entity (post or post template)
     * @param value
     */
    @Input() set initialValue(value: string) {
        if (!!value) {
            this.setFeedTargeting(value);
        }
    }
    @Output() valueChange = new EventEmitter();
    @ViewChild('searchLocationsInput', {static: true}) searchLocationsInput: MatInput;
    private _targetingOptions;
    targetingOptionsFormControlNames = FacebookNewsFeedTargetingControlNames;
    targetingOptionsFormGroup = new FormGroup({
        [this.targetingOptionsFormControlNames.AgeMin]: new FormControl(null, [
            Validators.min(13),
            Validators.max(65)
        ]),
        [this.targetingOptionsFormControlNames.AgeMax]: new FormControl(null, [
            Validators.min(13),
            Validators.max(65)
        ]),
        [this.targetingOptionsFormControlNames.Gender]: new FormControl(null)
    });
    formErrorMessages = {};
    matcher = new MyErrorStateMatcher();
    locationSearchInProgress = false;
    geoLocations: GeoLocationInterface[] = [];
    selectedLocations: GeoLocationView[] = [];
    locationFormGroup = new FormGroup({});
    citiesCounter = 0;

    constructor(
        public languageService: LanguageService,
        private service: PostActionsService
    ) {
    }

    ngOnInit() {
        this.targetingOptionsFormGroup.valueChanges.subscribe(() => {
            this.changeEmit();
        });
    }

    @Debounce(350)
    searchLocation(event: KeyboardEvent) {
        const value = event.target['value'];

        if ([UP_ARROW, DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW].indexOf(event.keyCode) > -1) {
            return;
        }

        if (!value) {
            return;
        }

        this.locationSearchInProgress = true;
        this.getGeoLocation(value);
    }

    /**
     * Select location
     * @param {MatAutocompleteSelectedEvent} event
     */
    selectLocation(event: MatAutocompleteSelectedEvent) {
        const value = event.option.value;

        const location = this.geoLocations.find(item => {
            return item.name === value;
        });

        const viewItem = this.renderGeoLocationView(location);

        const index = this.selectedLocations.findIndex(item => {
            return item.location.key === viewItem.location.key;
        });

        if (index === -1) {
            this.selectedLocations.push(viewItem);
            this.searchLocationsInput.value = '';
        }

        this.changeEmit();
    }

    /**
     * Remove location from selected locations
     * @param {GeoLocationView} location
     */
    removeLocation(location: GeoLocationView) {
        const index = this.selectedLocations.findIndex(item => {
            return item.location.key === location.location.key;
        });

        this.locationFormGroup.removeControl(location.formControls.name);

        this.selectedLocations.splice(index, 1);
    }

    /**
     * Emit value change
     */
    changeEmit() {
        this.valueChange.emit(this.getFormData());
    }

    get targetingOptions() {
        return this._targetingOptions;
    }

    /**
     * Get selected locations in JSON
     * @return {{geo_locations: {}}}
     */
    get locationsJson() {
        const locations = {
            geo_locations: {}
        };

        let countries = [];
        const regions = [];
        const zips = [];
        const cities = [];

        for (const location of this.selectedLocations) {
            switch (location.location.type) {
                case 'city':
                    const cityItem: { key: string, name?: string, radius?: number, distance_unit?: string } = {
                        key: location.location.key,
                        name: location.location.name
                    };

                    const radius = this.locationFormGroup.controls[location.formControls.name]
                        ? this.locationFormGroup.controls[location.formControls.name].value
                        : null;

                    if (radius) {
                        cityItem.radius = radius;
                        cityItem.distance_unit = location.formControls.unit;
                    }

                    cities.push(cityItem);

                    break;

                case 'region':
                    regions.push({key: location.location.key, name: location.location.name});

                    break;

                case 'country':
                    countries.push({countryCode: location.location['country_code'], name: location.location.name});

                    break;

                case 'country_group':
                    countries = countries.concat({
                        countryCodes: location.location['country_codes'],
                        name: location.location.name
                    });

                    break;

                case 'zip':
                    zips.push({key: location.location.key, name: location.location.name});

                    break;
            }
        }

        countries = Helpers.arrayUnique(countries);

        if (countries.length > 0) {
            locations.geo_locations['countries'] = countries;
        }

        if (regions.length > 0) {
            locations.geo_locations['regions'] = regions;
        }

        if (cities.length > 0) {
            locations.geo_locations['cities'] = cities;
        }

        if (zips.length > 0) {
            locations.geo_locations['zips'] = zips;
        }

        if (!Object.keys(locations.geo_locations).length) {
            delete locations.geo_locations;
        }

        return locations;
    }

    /**
     * Setting feed targeting options from entity
     */
    private setFeedTargeting(feedTargetingOptions) {
        const options: {
            age_max?: number,
            age_min?: number,
            genders?: string[],
            geo_locations?: any[]
        } = JSON.parse(feedTargetingOptions);

        if (options.age_min) {
            this.targetingOptionsFormGroup.get(this.targetingOptionsFormControlNames.AgeMin).setValue(options.age_min);
        }

        if (options.age_max) {
            this.targetingOptionsFormGroup.get(this.targetingOptionsFormControlNames.AgeMax).setValue(options.age_max);
        }

        if (options.genders && options.genders.length) {
            this.targetingOptionsFormGroup.get(this.targetingOptionsFormControlNames.Gender).setValue(options.genders);
        }

        if (options.geo_locations && keys(options.geo_locations).length) {
            this.setSelectedLocations(options.geo_locations);
        }
    }

    /**
     * Load selected locations
     * @param geoLocations
     */
    private setSelectedLocations(geoLocations) {
        for (const key in geoLocations) {
            const group = geoLocations[key];

            const addToSelectedList = (item) => {
                const index = this.selectedLocations.findIndex(locationItem => locationItem.location.name === item.location.name);

                if (index === -1) {
                    this.selectedLocations.push(item);
                }
            };

            const keyType = {
                cities: 'city',
                countries: 'country',
                regions: 'region',
                zips: 'zip'
            };

            group.map(groupItem => {
                let item;

                if (key === 'countries') {
                    item = this.renderGeoLocationView({
                        country_code: groupItem.countryCode,
                        name: groupItem.name,
                        type: keyType[key]
                    });
                } else {
                    item = this.renderGeoLocationView({
                        key: groupItem.key,
                        name: groupItem.name,
                        type: keyType[key]
                    });
                }

                addToSelectedList(item);
            });
        }
    }

    /**
     * Get form data
     * @return {string}
     */
    private getFormData() {
        let result = '';
        const data = {
            ...this.getPickedFormData(),
            ...this.locationsJson
        };

        if (keys(data).length) {
            result = JSON.stringify(data);
        }

        return result;
    }

    /**
     * Get picked form data
     * @return {{}}
     */
    private getPickedFormData() {
        const data = {};
        const formData = this.targetingOptionsFormGroup.getRawValue();

        for (const controlName in formData) {
            const value = formData[controlName];
            if (value && value !== '') {
                data[controlName] = value;
            }
        }

        return data;
    }

    /**
     * Get geo locations
     * @param {string} searchValue
     */
    private getGeoLocation(searchValue: string) {
        this.getTargetingLocations(searchValue).subscribe((geoLocations: GeoLocationInterface[]) => {
            this.geoLocations = geoLocations;
            this.locationSearchInProgress = false;
        }, error => {
            NotifyService.error('Get geo locations', 'Failed get locations!');
            this.locationSearchInProgress = false;
        });
    }

    /**
     * Get targeting locations by searchValue
     *
     * @param {string} searchValue
     * @return {Observable<GeoLocationInterface[]>}
     */
    private getTargetingLocations(searchValue: string): Observable<GeoLocationInterface[]> {
        return new Observable(observer => {
            this.service.getLocations(searchValue)
                .then(response => {
                    observer.next(response.targeting.data);
                    observer.complete();
                })
                .catch(error => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    /**
     * Render geo location view item
     * @param {GeoLocationInterface | object} location
     * @return {GeoLocationView}
     */
    private renderGeoLocationView(location: GeoLocationInterface | object) {
        const loc = <GeoLocationInterface>location;

        const item: GeoLocationView = {
            location: loc,
            formControls: {
                name: `locationCity-${this.citiesCounter + 1}`,
                radius: 0,
                unit: 'km'
            }
        };

        this.citiesCounter++;

        this.locationFormGroup.addControl(item.formControls.name, new FormControl(null, LocationRadiusValidators.km));

        return item;
    }
}
