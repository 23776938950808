import {Partner} from '~/src/app/shared/types/partners.model';

export type PartnerAnalyticsResolution = 'daily' | 'weekly' | 'monthly' | 'total';

export interface PartnerAnalyticsActivityData {
    createdCarousel: number;
    createdTemplate: number;
    login: number;
    postFromBrandedTemplate: number;
    postFromGenericTemplate: number;
    postFromMedia: number;
    postFromRss: number;
    postFromScratch: number;
    postFromTemplate: number;
    publishedAutoFeedPosts: number;
    publishedCustomFeedPosts: number;
    publishedPosts: number;
    publishedRepeatedPosts: number;
    uploadedImages: number;
    uploadedVideos: number;
}

/**
 * Partner data
 */
export interface PartnerAnalyticsData {
    accountID: number;
    customerID: number;
    businessName: string;
    lastPublishDate: string;
    partnerLastLogin: string;
    partnerLocation: string | null;
    subscription: {
        [property: string]: string;
    } | string;
}

/**
 * Partner statistics
 */
export interface PartnerAnalyticsStatistic {
    numberOfConnectedPlatforms: { [platform: string]: number; };
    numberOfConnectedRssFeeds: number;
    numberOfOrganizations: number;
    numberOfTemplates: number;
    numberOfUsers: number;
    usedBrowsers: { [browser: string]: number; };
    usedDevices: { [device: string]: number; };
    usedSpace: string;  // in megabyte (example: 406.95 MB)
}

/**
 * Partner activities
 */
export interface PartnerAnalyticsActivity {
    activityData: {
        daily: { [date: string]: PartnerAnalyticsActivityData; };
        monthly: { [month: string]: PartnerAnalyticsActivityData; };
        weekly: { [week: string]: PartnerAnalyticsActivityData; };
        total: PartnerAnalyticsActivityData;
    };
}

export interface PartnerAnalyticsCore
    extends PartnerAnalyticsData,
        PartnerAnalyticsStatistic, PartnerAnalyticsActivity {
}

/**
 * Partner analytics response
 */
export interface PartnerAnalyticsResponse {
    partnerAnalytics: PartnerAnalyticsCore[];
}

export interface PartnerAnalytics {
    core: PartnerAnalyticsCore;
    view: {
        partnerData: PartnerAnalyticsData;
        partnerStatistic: PartnerAnalyticsStatistic;
        partnerActivity: PartnerAnalyticsActivity;
    };
}

export interface PartnerAnalyticsExportResponse {
    file: {
        name: string;       // file name
        size: number;       // file size in bytes
        mime: string;       // file mime type
        content: string;    // file in base64
    };
}

export interface PartnerAnalyticsDialogData {
    partners: Partner[];
}

// Constants

export const PARTNER_ANALYTICS_DATA_LANGUAGE_PREFIX = 'partner.analytics.data.';
export const PARTNER_ANALYTICS_EXPORT_LANGUAGE_PREFIX = 'auth.partner.analytics.';

/**
 * Keys need to be PartnerAnalyticsResolution type
 */
export const PARTNER_ANALYTICS_RESOLUTION_DATE_FORMATS: {[key: string]: string} = {
    'daily': 'DD MMMM YYYY',
    'weekly': 'ww YYYY',
    'monthly': 'MM YYYY'
};
