import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeoLocationInterface } from '~/src/app/modules/posts/post-actions.interfaces';
import { LanguageService } from '~/src/app/services/language.service';

@Component({
  selector: 'smd-instagram-location-collection-manager-modal',
  templateUrl: './instagram-location-collection-manager-modal.component.html',
  styleUrls: ['./instagram-location-collection-manager-modal.component.scss']
})
export class InstagramLocationCollectionManagerModalComponent implements OnInit {

  selectedLocation: GeoLocationInterface;

  constructor(
    public language: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InstagramLocationCollectionManagerModalComponent>,
  ) {
    console.log(this.data);
  }

  ngOnInit() {
    // console.log(this.selectedLocation);
  }

  onLocationChange(location: GeoLocationInterface) {
    /* console.log('on location change');
    console.log('location id is : ' + location)
    console.info(location);
    console.info(location.key); */
    this.selectedLocation = location;
    // console.log((this.selectedLocation && this.selectedLocation.key))
  }

  onSaveDialog() {
    // console.log('close&save dialog');
    this.dialogRef.close({
      save: true,
      location: this.selectedLocation
    });
  }

}
