import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {SmdFile, SmdFileInterface} from '~/src/app/services/file.class';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import Utils from '~/src/app/core/utils';
import {FALLBACK_IMAGE} from '~/src/app/configs/configs';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';
import {Gallery, GalleryRef, ImageItem, ImageItemData, VideoItem, VideoItemData} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';

declare const $;

let templateIndex = 0;
let carouselIndex = 0;

@Component({
    selector: 'smd-post-media-view',
    templateUrl: './post-media-view.component.html',
    styleUrls: ['./post-media-view.component.scss']
})
export class PostMediaViewComponent implements OnInit, AfterViewInit {
    @Input() medias: SmdFileInterface[];
    @Input() entity: any;
    @Input() isImageOpenable = true;

    galleryID: string;
    galleryRef: GalleryRef;
    videoGalleryRef: GalleryRef;
    videoGalleryID: string;
    carouselIndex: number;

    preImages: SmdFileInterface[] = [];
    preVideos: any[] = [];
    preMedia: any[] = []; // todo...

    mediaCount: any = {video: 0, image: 0};

    fallbackImage = FALLBACK_IMAGE;
    isLoaded = false;

    carouselCoverSrc: string = null;
    carouselID: string;
    carouselCurrentIndex = 1;
    carouselItemTotal: number;
    $carouselElement: any;

    ogImageSrc: string = null;

    @HostListener('click', ['$event'])
    onClick(event) {
        event.preventDefault();
        event.stopPropagation();

        const carouselElement = event.target ? event.target.closest('[data-ride="carousel"]') : null;

        if (carouselElement) {
            const nextNavElement = event.target.closest('.carousel-control-next');
            const prevNavElement = event.target.closest('.carousel-control-prev');
            const carouselID = carouselElement.getAttribute('id');

            if (nextNavElement) {
                $(`#${carouselID}`).carousel('next');
            }

            if (prevNavElement) {
                $(`#${carouselID}`).carousel('prev');
            }
        }
    }

    constructor(
        public gallery: Gallery,
        public lightBox: Lightbox,
    ) {
        this.videoGalleryID = Utils.lodash.uniqueId();
    }

    ngOnInit() {

        // set carousel cover src
        this.carouselCoverSrc = CommonPostHelpers.getCarouselCover(this.entity);

        // set OG image src
        this.ogImageSrc = CommonPostHelpers.getOgImage(this.entity);

        if (this.entity.type === 'draft' && !!this.entity['archive']) {
            const archive: any = Utils.lodash.cloneDeep(this.entity['archive']);

            if (!!archive && !!archive.draftSettings) {
                const draft = archive.draftSettings.default;
                const linkShare = Utils.lodash.has(draft, 'linkshareTitle') ? {
                    title: draft.linkshareTitle || '',
                    image: draft.linkshareImageURL || '',
                    description: draft.linkshareDescription || '',
                    url: draft.linkshareURL || ''
                } : {};

                const key = !!this.entity.createDate ? 'createDate' : 'activeFrom';

                this.entity = Utils.initLineBreaksOnPostEntity({
                    ...Utils.lodash.cloneDeep(this.entity),
                    ...draft,
                    type: 'draft',
                    [key]: this.entity[key],
                    linkshare: linkShare
                });
            }
        }

        if (this.carouselCoverSrc) {
            this.carouselIndex = carouselIndex;
            carouselIndex++;

            // set carousel ID attr.
            this.carouselID = `carouselControls-${this.entity.templateID || this.entity['postID']}-${this.carouselIndex}`;
        }

        templateIndex++;

        this.galleryID = `templateGallery${templateIndex}`;
        this.galleryRef = this.gallery.ref(this.galleryID);

        this.medias = SmdFile.initFileArray(<any>this.medias).map((media, index) => {
            switch (media.type) {
                case FILE_TYPES.IMAGE:

                    if (this.preImages.length < 2) {
                        this.preImages.push(media);
                    }

                    this.mediaCount.image++;

                    this.galleryRef.addImage({ // dont think this call is even needed here since we dont use it in the template this way
                        src: media.url,
                        thumb: media.thumb,
                        title: media.name,
                    })

                    break;

                case FILE_TYPES.VIDEO:
                    this.preVideos.push(media);

                    this.mediaCount.video++;

                    this.galleryRef.addVideo({
                        src: media.url,
                        thumb: media.thumb,
                        title: media.name
                    });

                    break;
            }

            this.preMedia.push(media);

            return media;
        });

        this.isLoaded = true;
    }

    ngAfterViewInit(): void {

        // listen carousel slide event
        this.addCarouselSlideListener();

    }

    /**
     * Open video
     * @param video
     */
    openVideos(videos) {
        if (!this.isImageOpenable) {
            return;
        }

        const videoArray = [];
        videos.forEach(video => {
            const videoCopy = Utils.lodash.cloneDeep(video);
            const _videoSrc = videoCopy.url;
            const _video: VideoItemData = {
                src: [
                    {
                        url: _videoSrc,
                        type: 'video/mp4'
                    }
                ] as any,
                thumb: videoCopy.thumb || video.previewFileUrl,
            };
            const videoItem = new VideoItem(_video);
            videoArray.push(videoItem);
        });

        this.videoGalleryRef = this.gallery.ref(this.videoGalleryID);
        this.videoGalleryRef.load(videoArray);
        this.lightBox.open(0, this.videoGalleryID, {
            panelClass: 'fullscreen'
        });
    }

    /**
     * Open mixed media (video and images)
     * @param videos
     * @param images
     */
    openMixedMedia(videos, images) {
        if (!this.isImageOpenable) {
            return;
        }

        const videoArray = [];
        const imageArray = [];
        videos.forEach(video => {
            const videoCopy = Utils.lodash.cloneDeep(video);
            const _videoSrc = videoCopy.url.split('?token=')[1]
                ? videoCopy.url
                : video.url + '?token=' + videoCopy.previewFileUrl.split('?token=')[1];
            const _video: VideoItemData = {
                src: [
                    {
                        url: _videoSrc,
                        type: 'video/mp4'
                    }
                ] as any,
                thumb: videoCopy.thumb || video.previewFileUrl,
            };
            const videoItem = new VideoItem(_video);
            videoArray.push(videoItem);
        });

        images.forEach(image => {
            const imageCopy = Utils.lodash.cloneDeep(image);
            const _imageSrc = imageCopy.url.split('?token=')[1]
                ? imageCopy.url
                : imageCopy.url + '?token=' + imageCopy.previewFileUrl.split('?token=')[1];
            const imageItem = new ImageItem({
                src: _imageSrc,
                thumb: imageCopy.thumb || imageCopy.previewFileUrl,
            });
            imageArray.push(imageItem);
        });

        this.videoGalleryRef = this.gallery.ref(this.videoGalleryID);
        this.videoGalleryRef.load(videoArray.concat(imageArray));

        this.lightBox.open(0, this.videoGalleryID, {
            panelClass: 'fullscreen'
        });
    }


    /**
     * Open image
     * @param image
     
    openImage(image) {
        if (!this.isImageOpenable) {
            return;
        }

        const imageCopy = Utils.lodash.cloneDeep(image);

        this.galleryRef.load([imageCopy]);
        
        this.lightBox.open(0, this.galleryID, {
            panelClass: 'fullscreen'
        });
    }*/

    /**
     * Carousel navigation
     *
     * @param event
     * @param {"prev" | "next"} direction
     */
    carouselSlide(event: MouseEvent, direction: 'prev' | 'next') {
        event.preventDefault();
        event.stopPropagation();

        if (this.$carouselElement) {
            this.$carouselElement.carousel(direction);
        }
    }

    /**
     * Add carousel slide event listener
     */
    private addCarouselSlideListener() {

        if (this.carouselID) {

            // get carousel element
            this.$carouselElement = $(`#${this.carouselID}`);

            // set total carousel item count
            this.carouselItemTotal = this.$carouselElement.find('.carousel-item').length;

            // add slide event listener
            this.$carouselElement.on('slide.bs.carousel', ({relatedTarget, from, to}) => {
                this.carouselCurrentIndex = to + 1;
            });
        }

    }
}
