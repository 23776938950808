import {ComponentAbstract} from '../../../services/component.abstract';
import {Injectable} from '@angular/core';
import {PostTemplateModel} from './post-template.model';
import {PostTemplateInterface} from '../template.interface';
import {Helpers} from '../../../services/helpers';
import {SmdFile} from '../../../services/file.class';
import {Configs, SocialSite} from '../../../configs/configs';
import {PostActions} from '../post-actions';
import {PostActionsService} from '../post-actions.service';
import {MatDialog} from '@angular/material/dialog';
import {UsersController} from '../../users/users/users.component';
import {CarouselController} from '../carousel/carousel.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import Utils from '~/src/app/core/utils';

@Injectable({providedIn: 'root'})
export class PostTemplateController extends ComponentAbstract {

    /**
     * Social sites
     *
     * @type {{id: string; name: string}[]}
     */
    socials: SocialSite[] = Configs.socials;

    /**
     * Social type of current template
     */
    protected _socialType: string;

    /**
     * Post actions class
     */
    postActions: PostActions;

    constructor(
        public service: PostTemplateModel,
        postActionsService: PostActionsService,
        _dialog: MatDialog,
        users: UsersController,
        carsouelController: CarouselController,
        openModal: OpenModalService
    ) {
        super();

        /**
         * New post actions class
         *
         * @type {PostActions}
         */
        this.postActions = new PostActions(postActionsService, _dialog, users, carsouelController, openModal);
    }

    /**
     * Instant posting action
     */
    instantPosting(post?) {
        this.beforeDataLoad();

        this.postActions.instantPosting(post || this.getTemplate(), response => {
            this.afterDataLoad();
            this.successInstantPosting(response);
        }, error => {
            this.afterDataLoad();
            this.failedInstantPosting(error);
        });
    }

    postSchedule(post) {
        this.beforeDataLoad();

        this.postActions.postSchedule(post, response => {
            this.afterDataLoad();
            this.successPostSchedule(response);
        }, error => {
            this.afterDataLoad();
            this.failedPostSchedule(error);
        });
    }

    getItems(callback: (response: any) => void = () => {}, filters: object = {}): void {
        this.beforeGetItems();
        this.beforeDataLoad();

        let options = Object.assign(this.filters, filters);

        options = Helpers.objectToQueryOptions(options);

        this.service.getAll(options)
        .then(response => {
            const newTemplates = [];

            for (const index in response['templates']) {
                const template = response['templates'][index];

                template.medias = template.medias.map(file => new SmdFile(file));
                template['partnerIDs'] = Utils.lodash.get(template, 'partnerIDs', []).map(id => parseInt(id, null));
                template['disallowedPartnerIDs'] = Utils.lodash.get(template, 'disallowedPartnerIDs', []).map(id => parseInt(id, null));

                newTemplates.push(template);
            }

            response['templates'] = newTemplates;

            callback(response);
            this.afterGetItems();
            this.afterDataLoad();
            this.successGetItems(response);
        })
        .catch(error => {
            this.afterGetItems();
            this.afterDataLoad();
            this.failedGetItems(error);
        });
    }

    /**
     * Delete templates by IDs
     *
     * @param {object} IDs
     */
    deleteTemplates(IDs: object) {
        this.beforeDeleteTemplates();

        this.service.deleteItems(IDs)
        .then(response => {
            this.afterDeleteTemplates();
            this.successDeleteTemplates(response);
        })
        .catch(error => {
            this.afterDeleteTemplates();
            this.failedDeleteTemplates(error);
        });
    }

    successPostSchedule(response: any): void {}

    failedPostSchedule(error: any): void {}

    /**
     * Before delete templates event
     */
    beforeDeleteTemplates(): void {}

    /**
     * After delete templates event
     */
    afterDeleteTemplates(): void {}

    /**
     * Success delete items callback
     *
     * @param response
     */
    successDeleteTemplates(response: any): void {}

    /**
     * Failed delete items callback
     *
     * @param error
     */
    failedDeleteTemplates(error: any): void {}

    /**
     * Success instant posting callback
     *
     * @param response
     */
    successInstantPosting(response: any): void {}

    /**
     * Failed instant posting callback
     *
     * @param error
     */
    failedInstantPosting(error: any): void {}

    /**
     * Get current template data
     *
     * @return {any}
     */
    getTemplate(): any {}

    /**
     * Set current template
     *
     * @param {PostTemplateInterface} template
     */
    setTemplate(template: PostTemplateInterface): void {}

    /**
     * Set socialType event
     *
     * @param {string} value
     */
    setSocialTypeEvent(value: string): void {}

    /**
     * Get socialType
     *
     * @return {string}
     */
    public get socialType(): string {
        return this._socialType;
    }

    /**
     * Set socialType
     *
     * @param {string} value
     */
    public set socialType(value: string) {
        this._socialType = value;
        this.setSocialTypeEvent(value);
    }

}
