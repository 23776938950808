import { PixieModel } from './pixie.model';
import EditorControllerService from "~/src/app/components/image-editor/editor-controller.service";

export const configDefaults: PixieModel = {
    ui: {
        visible: true
    }
};

export const initConfigDefaults: PixieModel = {
    image: null,
    baseUrl: '/themes/smd/src/assets/plugins/pixie',
    tools: {
        export: {
            defaultFormat: 'jpeg',
            defaultQuality: 1.0
        },
        import: {
            openDroppedImageAsBackground: true
        }
    },
    ui: {
        visible: false,
        openImageDialog: {
            show: false
        },
        toolbar: {
            replaceDefaultLeftItems: true,
            hideCloseButton: false,
            leftItems: [
                {
                    type: 'button',
                    icon: 'photo-library',
                    text: 'Open',
                    action: () => {
                        EditorControllerService.openBrowser(true);
                    }
                },
                {
                    type: 'button',
                    icon: 'file-download',
                    text: 'Save',
                    action: 'exportImage',
                },
                {
                    type: 'button',
                    icon: 'close',
                    text: 'Close',
                    action: 'closeEditor'
                }
            ]
        }
    }
};
