import { Component, OnInit } from '@angular/core';
import { EventEmitter, Input, Output, ViewChild } from '~/node_modules/@angular/core';
import { FormControl } from '~/node_modules/@angular/forms';
import { LanguageService } from '~/src/app/services/language.service';
import { MatSelect, MatSelectChange } from '~/node_modules/@angular/material/select';
import { NestedTreeControl } from '~/node_modules/@angular/cdk/tree';
import { MatTreeNestedDataSource } from '~/node_modules/@angular/material/tree';
import { SocialSiteInterface } from '~/src/app/components/social-site-select/social-site-select.component';
import { Configs } from '~/src/app/configs/configs';
import { OrganisationService } from '~/src/app/modules/users/organizations/organisation.service';
import Utils from '~/src/app/core/utils';

interface SocialSiteNode {
    name: string;
    id: string,
    children?: SocialSiteNode[];
    type?: string;
    platform?: string;
    pageType?:string;
}  

enum ViewMode {
    PlatformView, OrganizationView
}

@Component({
    selector: 'smd-social-site-tree',
    templateUrl: './social-site-tree.component.html',
    styleUrls: ['./social-site-tree.component.scss']
})
export class SocialSiteTreeComponent implements OnInit {
    @Input('socialSelectFormControl') socialSelectFormControl = new FormControl({ value: null, disabled: false });
    @Input('socialSites') socialSites: SocialSiteInterface[] = [];
    @Input('multiple') multiple = true;
    @Input('toggleViewVisible') toggleViewVisible = true;
    @Input('oneSitePerPlatform') oneSitePerPlatform = false;
    @Input('errorMessage') errorMessage: string;
    @Input('loading') loading = false;
    @Output('selectionChange') selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
    @Output('refreshSocialSite') refreshSocialSite: EventEmitter<SocialSiteInterface> = new EventEmitter<SocialSiteInterface>();
    @ViewChild("noJumpSelector") selector: MatSelect;

    viewMode = ViewMode;
    actualViewMode = ViewMode.PlatformView;
    disabledSiteIDs: number[] = [];

    treeControls = {
        Platform: new NestedTreeControl<SocialSiteNode>(node => node.children),
        Organization: new NestedTreeControl<SocialSiteNode>(node => node.children)
    };
    dataSources = {
        Platform: new MatTreeNestedDataSource<SocialSiteNode>(),
        Organization: new MatTreeNestedDataSource<SocialSiteNode>()
    };

    platformConfigByID = {}; // this will contain the config of platforms itself, not the sites
    organizations = [];

    constructor(public languageService: LanguageService,
        private organizationService: OrganisationService) {
    }

    ngOnInit(): void {
        this.platformConfigByID = Configs.socials.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});

        this.getOrganizationTree();
    }

    private parseSocialSites(viewMode : ViewMode = ViewMode.PlatformView) {
        let list = [];

        if (!this.socialSites) {
            return list;
        }

        if (viewMode == ViewMode.OrganizationView) {
            this.organizations.forEach((org) => list.push({ name: org.name, id: org.id, children: [], type: 'organization', pageType:org.pageType}));
        } else if (viewMode == ViewMode.PlatformView) {
            Object.keys(this.platformConfigByID).forEach((platformId) => {
                list.push({ name: this.platformConfigByID[platformId].name, id: platformId, children: [], type: 'platform', pageType:this.platformConfigByID[platformId].pageType});
            });
        }

        if (!list.length) {
            return list;
        }

        this.socialSites.forEach((site) => {
            if (viewMode == ViewMode.PlatformView) {
                let platform = list.find((platform) => platform.id === site.socialType);
                platform.children.push({ name: site.name, id: site.siteID, children: [], type: 'site', pageType:site.pageType, platform: site.socialType, accessTokenExpired: site.accessTokenIsExpired, expiredTooltipText: site.tokenExpiredTooltipText, refreshable: site.isRefreshableByCurrentUser, isGroup:site.isGroup  });
            } else if (viewMode == ViewMode.OrganizationView) {
                let organization = list.find((organization) => organization.id == site.organizationID);
                organization.children.push({ name: site.name, id: site.siteID, children: [], type: 'site', pageType:site.pageType, platform: site.socialType, accessTokenExpired: site.accessTokenIsExpired, expiredTooltipText: site.tokenExpiredTooltipText, refreshable: site.isRefreshableByCurrentUser, isGroup:site.isGroup  });
            }
        });

        // if there is no children for an element of the list, remove it
        list = list.filter((item) => item.children.length > 0);

        // sort the list
        if (viewMode == ViewMode.OrganizationView) {
            list.forEach((item) => item.children = Utils.lodash.sortBy(item.children, ['platform', site => site.name.toLowerCase()]));
        } else if (viewMode == ViewMode.PlatformView) {
            list.forEach((item) => item.children = Utils.lodash.sortBy(item.children, site => site.name.toLowerCase()));
        }

        return list;
    }

    emitSelectionChange(change: MatSelectChange) {
        //this.selectionChange.emit(change);
    }

    validateSocialSites() {
        // if the selection contains at least one twitter site, disable all other twitter sites, otherwise enable all
        let selectedSites = Array.isArray(this.socialSelectFormControl.value) ? this.socialSelectFormControl.value : [parseInt(this.socialSelectFormControl.value)]; // this only contains the id-s, e.g. [1, 2, 3]
        let twitterSites = this.socialSites.filter((site) => site.socialType == 'twitter');
        let twitterSiteIds = twitterSites.map((site) => site.siteID);
        let twitterSiteIdsInSelection = selectedSites.filter((siteId) => twitterSiteIds.includes(siteId));
        let twitterSiteIdsNotInSelection = twitterSiteIds.filter((siteId) => !twitterSiteIdsInSelection.includes(siteId));

        if (twitterSiteIdsInSelection.length > 0) {
            this.disabledSiteIDs = twitterSiteIdsNotInSelection;
        } else {
            this.disabledSiteIDs = [];
        }

        if (this.oneSitePerPlatform) {
            let sitesByPlatform = this.socialSites.reduce((acc, cur) => {
                if (!acc[cur.socialType]) {
                    acc[cur.socialType] = [];
                }
                acc[cur.socialType].push(cur);
                return acc;
            }, {});

            Object.keys(sitesByPlatform).forEach((platformId) => {
                let sites = sitesByPlatform[platformId];
                let siteIds = sites.map((site) => site.siteID);
                let siteIdsInSelection = selectedSites.filter((siteId) => siteIds.includes(siteId));
                let siteIdsNotInSelection = siteIds.filter((siteId) => !siteIdsInSelection.includes(siteId));

                if (siteIdsInSelection.length > 0) {
                    this.disabledSiteIDs = this.disabledSiteIDs.concat(siteIdsNotInSelection);
                }
            });
        }
    }

    onSelectionChange(event) {
        this.validateSocialSites();
    }

    selectOpenStateChanged($event) {
        if (!$event) {
            this.emitSelectionChange({ value: this.socialSelectFormControl.value } as MatSelectChange);
        }
    }

    changeView() {
        let enumCounter = Object.keys(ViewMode).map((val) => Number(isNaN(Number(val)))).reduce((a, b) => a + b, 0);
        this.actualViewMode = (this.actualViewMode + 1) % enumCounter;
    }

    private getOrganizationTree() {
        this.organizationService.getOrganization().then((response) => {
            this.organizations = this.flattenOrganizationTree(response["organizationTree"]);
        }).finally(() => {
            this.ngOnChanges({socialSites: true}) // call parse after the tree is loaded
        });
    }

    private flattenOrganizationTree(object) {
        let returnArray = [];
        Object.keys(object).forEach((key) => {
            let value = object[key];
            returnArray.push({ name: value.name, id: parseInt(key) });
            if (value.children) {
                returnArray = [...returnArray, ...this.flattenOrganizationTree(value.children)];
            }
        });
        return returnArray;
    }

    getPlatformIcon(platformId) {
        return this.platformConfigByID[platformId].iconClasses;
    }

    ngOnChanges(changes) {
        if (changes.socialSites) {
            this.dataSources.Organization.data = this.parseSocialSites(ViewMode.OrganizationView);
            this.dataSources.Platform.data = this.parseSocialSites(ViewMode.PlatformView);

            this.treeControls.Organization.dataNodes = this.dataSources.Organization.data;
            this.treeControls.Platform.dataNodes = this.dataSources.Platform.data;

            this.treeControls.Platform.expandAll();
            this.treeControls.Organization.expandAll();
        }
    }

    ngAfterViewInit(): void {
        (<any>this.selector).baseonselect = (<any>this.selector)._onSelect;
        (<any>this.selector)._onSelect = (ev, _) => {
            (<any>this.selector).baseonselect(ev, false);
        };
    }

    hasChild = (_: number, node: SocialSiteNode) => !!node.children && node.children?.length > 0;

    getSelectAllText() {
        let allSites = this.socialSites.filter((site) => site.socialType != 'twitter').map((item) => item['siteID']);

        if (allSites.every((item) => this.socialSelectFormControl.value?.includes(item))) {
            return LanguageService.getLine("filters.deselect.all");
        } else {
            return LanguageService.getLine("filters.select.all");
        }
    }

    getSelectAllIcon() {
        let allSites = this.socialSites.filter((site) => site.socialType != 'twitter').map((item) => item['siteID']);

        if (allSites.every((item) => this.socialSelectFormControl.value?.includes(item))) {
            return "layers_clear";
        } else {
            return "layers";
        }
    }

    private getDescendantIds(node) {
        let ids = [];
        if (node.children) {
            node.children.forEach(child => {
                ids.push(child.id);
                ids = ids.concat(this.getDescendantIds(child)); // basically unused here, but useful for recursive calls if needed in the future
            });
        }
        return ids;
    }

    isAllSelectedBelow(node) {
        let descendants = this.getDescendantIds(node);
        let selectedSites = this.socialSelectFormControl.value;

        // filter out twitter sites from descendants
        descendants = descendants.filter((item) => (this.socialSites.find((site) => site.siteID == item).socialType != 'twitter'));

        return descendants?.every(id => selectedSites?.includes(id));
    }

    selectAllDescendants(node) {
        let selectedSites = this.getDescendantIds(node);

        // count twitter sites in selection
        let twitterSiteCount: number = selectedSites.filter((item) => this.socialSites.find((site) => site.siteID == item).socialType == 'twitter').length;

        // filter out twitter sites from descendants if there are more than one, otherwise select the only twitter site
        if (twitterSiteCount > 1) {
            selectedSites = selectedSites.filter((item) => (this.socialSites.find((site) => site.siteID == item).socialType != 'twitter'));
        }
        
        if (!this.isAllSelectedBelow(node)) {
            selectedSites = Array.from(new Set(selectedSites.concat(this.socialSelectFormControl.value)));
        } else {
            selectedSites = Array.from(new Set(this.socialSelectFormControl.value.filter(id => !selectedSites?.includes(id))));
        }

        this.socialSelectFormControl.setValue(selectedSites);

        this.validateSocialSites();
    }

    selectAll(select: FormControl, dataArray, modelValueProperty) {
        let twitterSiteCount: number = dataArray.filter((item) => item['socialType'] == 'twitter').length;

        // exclude twitter sites from selection if there are more than one, otherwise select the only twitter site
        if (twitterSiteCount > 1) {
            dataArray = dataArray.filter((item) => item['socialType'] != 'twitter');
        }

        if (dataArray?.every((item) => select.value?.includes(item[modelValueProperty]))) {
            select.setValue([]);
        } else {
            select.setValue(dataArray.map((item) => item[modelValueProperty]));
        }

        this.validateSocialSites();
    }

    getFilterTooltip(node) {
        return LanguageService.getLine("filters." + (this.isAllSelectedBelow(node) ? "de" : "") + "select.all");
    }

    isOptionLocked(siteId) {
        return this.disabledSiteIDs.includes(siteId);
    }

    isNodeSelected(node) { // TODO! Refactor so no function calls are made in the template
        if (!this.socialSelectFormControl.value) {
            return false;
        }

        if (Array.isArray(this.socialSelectFormControl.value)) {
            return this.socialSelectFormControl.value?.includes(node.id);
        }

        return parseInt(this.socialSelectFormControl.value) === parseInt(node.id);
    }

    refreshToken(id: number) {
        const site = this.socialSites.find(element => element.siteID == id);
        if (!site) {
            console.error("No social site at 'refreshToken' method.");
            return;
        }
        if (!site.isRefreshableByCurrentUser) { 
            return; 
        }

        this.refreshSocialSite.emit(site);
    }
}
