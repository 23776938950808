import {ComponentAbstract} from '../../../services/component.abstract';
import {UsersService} from './users.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export class UsersComponent extends ComponentAbstract {

    constructor(
        public service: UsersService
    ) {
        super();
    }

    /**
     * Get users by IDs
     *
     * @param {number[]} userIDs
     * @return {Observable<any>}
     */
    getUsersByIDs(userIDs: number[]): Observable<any> {
        return new Observable<any>(ob => {
            this.service.getUsersByIDs(userIDs)
            .then(response => {
                ob.next(response);
                ob.complete();
            })
            .catch(error => {
                ob.error(error);
                ob.complete();
            });
        });
    }
}

@Injectable({providedIn: 'root'})
export class UsersController extends UsersComponent {

    constructor(service: UsersService) {
        super(service);
    }
}
