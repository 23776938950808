declare const $;
declare const moment;

interface Select2 {
    init: (options?: object) => any;
    setValue: (value: any) => void;
    onSelect: (callback: (e) => void) => void;
    onChange: (callback: (e) => void) => void;
    destroy: () => void;
}

interface Wizard {
    init: (options: object) => any;
    destroy: () => void;
}

export class Plugins {
    constructor() {
    }

    /**
     * Material pagination options
     *
     * @type {{pageSizeOptions: number[]}}
     */
    public static pagination = {
        pageSizeOptions: [5, 10, 25, 50]
    };

    /**
     * Select2 plugin actions
     *
     * @param {string} selector
     * @returns {Select2}
     */
    public static select2(selector: string): Select2 {
        return {
            init: function (options?: object): any {
                return $(selector).select2(options || {});
            },
            setValue: function (value: any): void {
                $(selector).val(value).trigger('change');
            },
            onSelect: function (callback: (e) => void): void {
                $(selector).on('select2:select', function (e) {
                    callback.call(this, e);
                });
            },
            onChange: function (callback: (e) => void): void {
                $(selector).on('change.select2', function (e) {
                    callback.call(this, e);
                });
            },
            destroy: function (): void {
                $(selector).select2('destroy').off('select2:select');
            }
        };
    }

    /**
     * Steps plugin actions
     *
     * @param {string} selector
     * @return {Wizard}
     */
    public static wizard(selector: string): Wizard {
        return {
            init: function (options: object): any {
                const defaultOptions = {};

                options = Object.assign(defaultOptions, options);

                return $(selector).steps(options);
            },
            destroy: function (): void {
                setTimeout(function () {
                    $(selector).steps('destroy');
                }, 300);
            }
        };
    }
}
