import {debounceTime} from 'rxjs/operators';
import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LanguageService } from '~/src/app/services/language.service';
import { ReportsService } from '../../dashboards/reports.service';
import { UserMinimal } from '../../users/users-resource';
import { UsersService } from '../../users/users/users.service';
import { EditModes, ObjectShareDialogData, ReportOrganization, ReportShareData } from './share-object.interface';
import { FormHelpersService } from '~/src/app/core/services/form-helpers';

@Component({
    selector: 'smd-share-object',
    templateUrl: './share-object.component.html',
    styleUrls: ['./share-object.component.scss'],
    providers: [
        FormHelpersService
    ]
})

export class ShareObjectComponent implements OnInit {
    objectNamesString: string;
    editMode: EditModes = 'separate';

    shareSettings: Array<{
        userID: number;
        reportID: number;
        shared: boolean;
    }> = []; 

    organizations: ReportOrganization[] = []; //expansion: add more possible types if needed
    objects: ReportShareData[] = []; //expansion: add more possible types if needed
    objectSharesSame: boolean = true;

    constructor(
        public language: LanguageService,
        public reportService: ReportsService,
        public formHelpers: FormHelpersService,
        @Inject(MAT_DIALOG_DATA) public dialogData: ObjectShareDialogData,
    ) {

    }

    ngOnInit() {
        this.objectNamesString = this.dialogData.objectData.map(object => object.name).join(', ');
        this.initializeSettings();
    }

    /**
     * Initialize share settings based on object type
     */
    initializeSettings() {
        const ids = [];
        for (const object of this.dialogData.objectData) {
            ids.push(object.id);
        }

        if (this.dialogData.objectType === 'report') {
            this.reportService.getShareData(ids).then(response => {
                this.organizations = response.data.organizations;
                this.objects = response.data.reports;

                this.getObjectSharesSame();
                this.getShareSettings();
            });
        } //expansion: else if-s can be added if there will be more types
    }

    getObjectSharesSame() {
        let i = 0;
        while (i < this.objects.length - 1) {
            i++;
            this.objectSharesSame = this.objectSharesSame && (JSON.stringify(this.objects[i].sharedWith) === JSON.stringify(this.objects[i-1].sharedWith));
        }
    }

    getShareSettings() {
        this.objects.forEach(object => {
            object.sharedWith.forEach(share => {
                this.shareSettings.push({
                    userID: share.userID,
                    reportID: object.reportID,
                    shared: share.shared
                });
            });
        });
    }

    /**
     * Radio button selection change event, changes edit mode when sharing more objects
     */
    editModeRadioClick(event: MouseEvent, editMode: EditModes) {
        this.editMode = editMode;
    }

    saveAction(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        //this.reportService.shareReports(this.shareSettings)
    }

    shareCheckboxChange(event: Event) {
        const splitIdArray = (event.target as any).id.split('_');
        const reportID = splitIdArray[1];
        const userID = splitIdArray[2];
        const shared = (event.target as any).checked;

        const foundIndex = this.shareSettings.findIndex(element => {
            return element.userID == userID && element.reportID == reportID
        });
        Object.assign(this.shareSettings[foundIndex], {shared: shared});
    }
}