import {RemoveSpecifyTagReplaceValue, RemoveSpecifyTagsOptions} from '~/src/app/core/helper/string-support.d';

export class StringSupport {

    /**
     * Replace multi line breaks to single line break
     *
     * @param {string} content
     * @param trimWhiteSpaces
     * @returns {string}
     */
    static trimLineBreaks(content: string, trimWhiteSpaces = true): string {

        if (trimWhiteSpaces) {
            content = this.trimWhiteSpaces(content);
        }

        if (content.match(/(( ?)+<br ?\/?>( ?)+){2,}/g)) {
            content = content.replace(/(( ?)+<br ?\/?>( ?)+){3,}/g, '<br class="smd"><br class="smd">');
            // content = content.replace(/(( ?)+<br ?\/?>( ?)+){2,}/g, '<br class="smd">');
        }

        return content;
    }

    /**
     * @param {string} content
     * @returns {string}
     */
    static trimWhiteSpaces(content: string): string {

        content = content.replace(new RegExp(`(${String.fromCharCode(160)}){1,}`, 'g'), ' ');
        content = content.replace(new RegExp(`( ){2,}`, 'g'), ' ');

        return content;
    }

    /**
     * Remove html tags from string
     *
     * @param {string} string
     * @returns {string}
     */
    static removeHtml(string: string = ''): string {

        // create new html element
        const element: HTMLDivElement = document.createElement('div');

        // set inner html of element
        element.innerHTML = string;

        // get element inner text
        string = element.textContent || element.innerText || '';

        // remove element
        element.remove();

        // return result string
        return string;
    }

    /**
     * Remove specify html tags from string content
     *
     * @param {string} content
     * @param tags
     * @param options
     * @returns {string}
     */
    static removeSpecifyTags(content: string, tags: string[] = [], options: RemoveSpecifyTagsOptions = {}): string {

        if ('replaceValue' in options && typeof options.replaceValue === 'string') {
            options.replaceValue = {
                first: options.replaceValue,
                last: options.replaceValue
            };
        }

        const config: RemoveSpecifyTagsOptions = {
            replaceValue: {
                first: '',
                last: '',
            } as RemoveSpecifyTagReplaceValue,
            removeContent: false,
            ...options
        };

        config.replaceValue = (<RemoveSpecifyTagReplaceValue> config.replaceValue);

        tags.forEach(tag => {

            if (config.removeContent) {

                const regex = new RegExp(`(<${tag}>(.*?)<\/${tag}>|<${tag} (.*?)>(.*?)<\/${tag}>)`, 'g');

                content = content.replace(regex, (<RemoveSpecifyTagReplaceValue> config.replaceValue).first);

            } else {

                const firstRegex = new RegExp(`(<${tag}>|<${tag} (.*?)>)`, 'g');
                const lastRegex = new RegExp(`<\/${tag}>`, 'g');

                content = content.replace(firstRegex, (<RemoveSpecifyTagReplaceValue> config.replaceValue).first);
                content = content.replace(lastRegex, (<RemoveSpecifyTagReplaceValue> config.replaceValue).last);

            }

        });

        return content;
    }

    /**
     * @param {string} content
     * @param {string} string
     * @param {"left" | "right"} side
     * @return {string}
     */
    static trimSpecifyString(content: string, string: string, side?: 'left' | 'right') {
        const regexStrs = {
            left: `^(${string})+`,
            right: `(${string})+$`
        };

        if (side) {
            return content.replace(new RegExp(regexStrs[side], 'g'), '');
        }

        return content.replace(new RegExp(`${regexStrs.left}|${regexStrs.right}`, 'g'), '');
    }
}
