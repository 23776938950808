import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs/index';
import {catchError, map, tap} from 'rxjs/internal/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Token} from '../../services/token';
import {Helpers, LoggedUserInterface, RememberMe} from '../../services/helpers';
import {LoggedUser} from '../../services/logged-user';
import {environment} from '~/src/environments/environment';
import {BackendService} from '~/src/app/core/backend.service';

@Injectable({providedIn: 'root'})
export class AuthService {

    loginUrl = environment.apiUrl + '/api/users/authenticate';
    logoutUrl = environment.apiUrl + '/api/users/logout';

    constructor(
        private http: HttpClient,
        private router: Router,
        public token: Token,
        private backendService: BackendService
    ) {
    }

    /**
     * Username recovery
     * @param {string} email
     * @return {Promise<null>}
     */
    usernameRecovery(email: string) {
        return this.backendService.post('/forgot/username', {email}, null, {authorizationRequired: false});
    }

    getMe(): Observable<object> {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        return this.http.get<any>(environment.apiUrl + '/api/users/me', httpOptions)
        .pipe(map(data => {
            return data;
        }));
    }

    /**
     * Refresh logged user data in local storage
     * @return {Promise<object>}
     */
    refreshLoggedUserData() {
        return this.getMe().toPromise().then(response => {
            LoggedUser.saveUser(response['user']);
            return response;
        });
    }

    passwordRecovery(username: string): Observable<object> {
        const formData = new FormData();
        formData.append('username', username);

        return this.http.post(environment.apiUrl + '/api/users/password-recovery', formData)
        .pipe(map(data => {
            return data;
        }));
    }

    passwordReset(token: string, password: string, isActivate?: boolean): Observable<object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        let body: any = {
            token: token,
            password: password
        };

        if (isActivate) {
            body.isActivate = true;
        }

        return this.http.post(environment.apiUrl + '/api/users/password-reset', body, httpOptions)
        .pipe(tap(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    passwordChange(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        return this.http.post(environment.apiUrl + '/api/users/change-password', Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    login(username: string, password: string, data: object = {}): Observable<object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic ' + btoa(`${username}:${password}`)
            })
        };

        return this.http.post<any>(this.loginUrl, Helpers.objectToFormData(data), httpOptions)
            .pipe(map(response => {
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('token')) {

                    response = response.data;

                    const userData: LoggedUserInterface = response.user;
                    const userObject = response.user;
                    userObject['userData'] = response.userData;
                    LoggedUser.saveUser(userObject);

                    if (data && 'rememberMe' in data) {
                        RememberMe.save();
                    }

                    Token.saveToken(response.token, response.expires);

                    if (userData.settings.twoFactorAuthentication) {
                        return {twoFactor: true};
                    } else {
                        this.router.navigateByUrl('/');
                    }
                }

                return response;
            }));
    }

    isLoggedIn() {
        return Token.getToken() && !Token.isExpired();
    }

    logout(isWithoutNavigation = false) {
        /**
         * Todo backenden token törlésa vagyis invalidálása
         * @type {{headers: HttpHeaders}}
         */
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        const logoutUI = () => {
            const ZohoHCAsap = window['ZohoHCAsap'] || null;
            let zohoHCScript;

            if (zohoHCScript = document.getElementById('zohoHelpCenterScript')) {
                zohoHCScript.remove();
            }

            if (window['ZohoHCAsap'] && window['ZohoHCAsap'].UnInstall) {
                window['ZohoHCAsap'].UnInstall();
            }

            this.resetUserData();

            if (!isWithoutNavigation) {
                location.replace('/login');
            }
        };

        return this.http.get<any>(this.logoutUrl, httpOptions).pipe(map(data => {
            logoutUI();
        }), catchError((error, caught) => {
            logoutUI();
            return of(error);
        }));
    }

    resetUserData() {
        this.token.disableResetToken();
        RememberMe.clear();
        Token.deleteToken();
        LoggedUser.deleteUser();
        localStorage.clear();
    }

    twoFactorAuthSetup() {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        return this.http.get<any>(environment.apiUrl + '/api/users/twofactor', httpOptions)
        .pipe(map(data => {
            const tokenDatas = data.twoFactorAuthenticate;

            Token.saveToken(tokenDatas.token, tokenDatas.expires);

            return data;
        }));
    }

    twoFactorVerify(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        return this.http.post<any>('/api/users/twofactor/verify', Helpers.objectToFormData(data), httpOptions)
        .pipe(map(data => {
            const tokenDatas = data.verifyTwoFactor;

            Token.saveToken(tokenDatas.token, tokenDatas.expires);

            return {result: 'OK'};
        }));
    }
}
