export const PostFormControlNames = {
    Headline: "headline",
    SubHeadline: "subHeadline",
    Message: "mainCopy",
    Signature: "signature",
    LinkShareTitle: "linkshareTitle",
    LinkShareDescription: "linkshareDescription",
    LinkShareURL: "linkshareURL",
    LinkShareImageURL: "linkshareImageURL",
    LinkShareIncluded: "linkshareIncluded",
    AutoLinkshareEnabled: "autoLinkshareEnabled",
    FeedTargetingOptions: "feedTargetingOptions",
    ExpiryPeriod: "expiryPeriod",
    ExpiryEndDate: "expiryDate",
    ActiveFrom: "activeFrom",
    ActiveFromDate: "activeFromDate",
    ActiveFromHour: "activeFromHour",
    ActiveFromMinute: "activeFromMinute",
    AutoFeed: "isAutoFeed",
    SeparateRepeat: "separateRepeat",
    Comment: "comment",
    Medias: "medias",
    SocialSite: "socialSiteIDs",
    Organization: "organizationIDs",
    Categories: "categories",
    Tags: "tags",
    IsCopy: "isCopy",
    SocialType: "socialType",
    SystemType: "systemType",
    Partners: "partnerIDs",
    OriginPostContentHash: "originPostContentHash",
    CurrentPostContentHash: "currentPostContentHash",
    SelectedMentions: "mentions",
    FirstComment: "firstComment",
    /* InstagramReel: "instagramReel",
    InstagramShareToFeed: "instagramShareToFeed", */
    InstagramPostType: "instagramPostType",
    InstagramLocationID: "instagramLocationID",
    InstagramLocationName: "instagramLocationName",
    FacebookPostType: "FacebookPostType",
    Size: "Size",
    Duration: "Duration",
    /* Type: "type",
    Event: "event",
    Offer: "offer",
    CallToAction: "callToAction",
    StartDate: "startDate",
    EndDate: "endDate",
    Title: "title", */
};
export const PostFormExpansionPanels = {
    Headline: "headline",
    SubHeadline: "subHeadline",
    Message: "mainCopy",
    Signature: "signature",
    LinkShare: "linkShare",
    PostRepeat: "postRepeat",
    NewsFeedTargeting: "newsFeedTargeting",
    Schedule: "schedule",
    Comments: "comments",
    FirstComment: "firstComment",
    InstagramLocation: "instagramLocation",
    GmbOptions: "gmbOptions",
};
export const FacebookNewsFeedTargetingControlNames = {
    AgeMin: "age_min",
    AgeMax: "age_max",
    Gender: "genders",
};
export const MAX_CHARACTER_LENGTH_UPPER_LIMIT = 5000;
export const TWITTER_MAX_SELECTED_IMAGE = 4;
export const INSTAGRAM_MAX_SELECTED_IMAGE = 10;
export const INSTAGRAM_MAX_SELECTED_VIDEO = 1;
export const INSTAGRAM_MAX_SELECTED_MIXED = 0; // video + image
// Date picker min date
export const activeFromDatMinDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
);
// Date picker max date
export const activeFromDatMaxDate = new Date(
    new Date().getFullYear() + 50,
    new Date().getMonth(),
    new Date().getDate()
);
export const POST_SCHEDULE_DATE_DELAY_MINUTES = 10;

// Post statuses
export const POST_STATUS_WAITING_FOR_APPROVAL = "waitingForApproval";
export const POST_STATUS_POSTED = "posted";
export const POST_STATUS_APPROVED = "approved";
export const POST_STATUS_NOT_APPROVED = "notApproved";
export const POST_STATUS_INACTIVE = "inactive";
export const POST_STATUS_FAILED = "failed";
export const POST_STATUS_PARTIALLY_FAILED = "partiallyFailed";
export const POST_STATUS_UNDER_POST = "underPost";

// Post types
export const POST_TYPE_AUTOFEED = "autoFeed";
export const POST_TYPE_CUSTOMFEED = "customFeed";
export const POST_TYPE_DRAFT = "draft";

// Post template statuses
export const POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL = "waitingForApproval";
export const POST_TEMPLATE_STATUS_ACTIVE = "active";
export const POST_TEMPLATE_STATUS_INACTIVE = "inactive";
export const POST_TEMPLATE_STATUS_NOT_APPROVED = "notApproved";
