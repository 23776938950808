import Utils from '~/src/app/core/utils';

export const SocialSiteUtils = {

    /**
     * Get facebook page token expire
     * @return {number}
     */
    getFacebookPageAccessTokenExpire(): number {
        const start = Utils.moment(),
            end = Utils.moment().add(3, 'month');

        return Math.round(Utils.moment.duration(end.diff(start)).asSeconds());
    }
};
