import {WidgetAbstract} from './widget.abstract';
import {ChartWidget} from './widget.interfaces';

export class ChartWidgetEntity extends WidgetAbstract implements ChartWidget {
    constructor(
        widget: ChartWidget,
    ) {
        super(widget);

        this.isChartWidget = true;
    }
}
