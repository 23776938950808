import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '~/src/environments/environment';
import Utils from '../core/utils';

@Injectable({
	providedIn: 'root'
})
export class CacheService {
    _cache: Map<string, { response: HttpResponse<any>, cachedAt: number }> = new Map();
    public get(req: string): HttpResponse<any> | undefined | null {
        const cached = this._cache.get(req);

        if (!cached) {
            return undefined;
        }

        const isExpired = cached.cachedAt < Date.now() - (environment.cache.maxAge);

        if (isExpired) {
            this.invalidate(req);
            return null;
        }
        
        return Utils.lodash.cloneDeep(cached.response);
    }

    public has(req: string): boolean {
        return this._cache.has(req);
    }

    public put(req: string, resp: HttpResponse<any>): void {
        this._cache.set(req, {response: resp, cachedAt: Date.now()});
    }

    public invalidate(req: string): void {
        this._cache.delete(req);
    }

    public invalidateWithPrefix(prefix: string): void {
        if (!prefix) {
            return;
        }

        for (const key of Array.from(this._cache.keys())) {
            if (key.startsWith(prefix)) {
                this.invalidate(key);
            }
        }
    }

    public invalidateWithPart(part: string): void {
        if (!part) {
            return;
        }

        for (const key of Array.from(this._cache.keys())) {
            if (key.includes(part)) {
                this.invalidate(key);
            }
        }
    }
}