import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BulkUploadManagerComponent } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.component";
import {
    FormsModule,
    ReactiveFormsModule,
} from "~/node_modules/@angular/forms";
import { NgxMatDrpModule } from "~/node_modules/ngx-mat-daterange-picker";
import { SharedModule } from "~/src/app/shared/shared.module";
import { LanguagesModule } from "~/src/app/modules/languages/languages.module";
import { InnerHtmlModule } from "~/src/app/directives/inner-html/inner-html.module";
import { ItemEditorComponent } from "~/src/app/modules/posts/bulk-upload/item-editor/item-editor.component";
import { EditorModule } from "~/node_modules/@tinymce/tinymce-angular";
import { DocumentsModule } from "~/src/app/modules/documents/documents.module";
import { GmbModule } from "../../social-media-post/gmb/gmb.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatDrpModule,
        SharedModule,
        LanguagesModule,
        InnerHtmlModule,
        EditorModule,
        DocumentsModule,
        GmbModule,
    ],
    declarations: [BulkUploadManagerComponent, ItemEditorComponent],
    exports: [BulkUploadManagerComponent, ItemEditorComponent],
    entryComponents: [BulkUploadManagerComponent, ItemEditorComponent],
})
export class BulkUploadModule {}
