import { 
  Component, 
  OnInit,
  Input, 
} from '@angular/core';
import { CardConfigInterface } from '../reports-home.interfaces';

@Component({
  selector: 'smd-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss']
})
export class ReportCardComponent implements OnInit {

  @Input() card: CardConfigInterface;

  constructor() { }

  ngOnInit(): void {
  }

}
