import { Component, OnInit, Input } from "@angular/core";
import { AbstractFormGroupComponent } from "~/src/app/modules/social-media-post/gmb/form/abstract/abstract.component";
import { ACTION_TYPE_OPTIONS } from "~/src/app/modules/social-media-post/gmb/gmb.constants";
import { url } from "@ng-validators/ng-validators";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "smd-call-to-action",
    templateUrl: "./call-to-action-form.component.html",
    styleUrls: ["./call-to-action-form.component.scss"],
})
export class CallToActionFormComponent
    extends AbstractFormGroupComponent
    implements OnInit
{
    formGroup: FormGroup = this.fb.group({
        actionType: null,
        url: [{ value: null, disabled: true }, [url]],
    });
    actionTypes = ACTION_TYPE_OPTIONS;

    ngOnInit() {
        this.formGroup.get("url").disable();

        this.formGroup.get("actionType").valueChanges.subscribe((value) => {
            this.formGroup
                .get("url")
                [value && value !== "" ? "enable" : "disable"]();
        });
    }
}
