import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import {PartnerPermission} from '~/src/app/shared/services/partner-config/partner-config.interfaces';

@Directive({
    selector: '[smdPartnerHas]',
})
export class PartnerHasDirective implements OnInit {
    @Input() smdPartnerHas: PartnerPermission;

    constructor(
        public partnerConfig: PartnerConfigService,
        public el: ElementRef
    ) {}

    ngOnInit(): void {
        if (!this.smdPartnerHas) {
            return;
        }

        if (!this.partnerConfig.hasConfig(this.smdPartnerHas)) {
            this.el.nativeElement.remove();
        }
    }
}
