import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '~/src/app/shared/shared.module';
import { LanguagesModule } from '~/src/app/modules/languages/languages.module';
import { InstagramLocationCollectionManagerModalComponent } from './instagram-location-collection-manager-modal/instagram-location-collection-manager-modal.component';
import { InstagramLocationCollectionManagerComponent } from './instagram-location-collection-manager/instagram-location-collection-manager.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    declarations: [InstagramLocationCollectionManagerModalComponent, InstagramLocationCollectionManagerComponent],
    entryComponents: [InstagramLocationCollectionManagerModalComponent]
})
export class InstagramLocationModule {
}

