/**
 * Post template request media keys
 * @type {{fileIDs: string; carouselIDs: string}}
 */
export const MediaPostTemplateRequestKeys = {
    fileIDs: 'filesId',
    carouselIDs: 'carouselId'
};

/**
 * Post request media keys
 * @type {{fileIDs: string; carouselIDs: string}}
 */
export const MediaPostRequestKeys = {
    fileIDs: 'fileIDs',
    carouselIDs: 'carouselID'
};
