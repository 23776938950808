import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {FormGroup} from '~/node_modules/@angular/forms';
import {Icons} from '~/src/app/helpers/icons';
import {Observable} from '~/node_modules/rxjs';
import {ArraySupport} from '~/src/app/core/helper/array-support';
import {
    DashboardPermissionsList,
    DashboardShareSettingsPermissions,
    DashboardShareSettingsSaveRequestData,
    ShareSettingsControlNames
} from '~/src/app/modules/analytics/share-dashboard/share-dashboard';
import {Dashboard} from '~/src/app/modules/analytics/dashboard.interfaces';
import {LoggedUser} from '~/src/app/services/logged-user';
import Utils from '~/src/app/core/utils';
import {User} from '~/src/app/modules/users/users-resource';

interface SelectedUserIDs {
    dashboardRead: string[];
    dashboardUpdate: string[];
    dashboardDelete: string[];
}

interface UserViewInterface {
    id: number;
    name: string;
    allowedPermissions: string[];
}

@Component({
    selector: 'smd-share-dashboard-settings',
    templateUrl: './share-dashboard-settings.component.html',
    styleUrls: ['./share-dashboard-settings.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class ShareDashboardSettingsComponent implements OnInit {

    @Input() shareSettingsFormGroup: FormGroup;
    @Input() filteredOrganizations: any[];
    @Input() dashboardID: number;
    shareSettingsControlNames = ShareSettingsControlNames;
    uuid = Utils.uuid.UUID();

    icons = Icons;

    constructor(
        public language: LanguageService
    ) {
    }

    ngOnInit(): void {
    }

    /**
     * Dashboard resource state change
     *
     * @param {Event | boolean} event
     * @param {keyof SelectedUserIDs} resource
     * @param {UserViewInterface | UserViewInterface[]} users
     */
    dashboardResourceStateChange(event: Event | boolean, resource: ShareSettingsControlNames, users: UserViewInterface | UserViewInterface[]) {
        const isAdded = (typeof event !== 'boolean') ? (event.srcElement as any).checked : event;
        const needCheckRead = [
            this.shareSettingsControlNames.DashboardUpdate,
            this.shareSettingsControlNames.DashboardDelete
        ].includes(resource);
        const needCheckUpdate = [
            this.shareSettingsControlNames.DashboardDelete
        ].includes(resource)
        let formControlValue = this.shareSettingsFormGroup.get(resource).value;
        let viewFormControlValue = this.shareSettingsFormGroup.get(this.shareSettingsControlNames.DashboardRead).value;
        let updatedFormControlValue = this.shareSettingsFormGroup.get(this.shareSettingsControlNames.DashboardUpdate).value;

        users = ArraySupport.itemConvertToArray(users);

        for (const user of users) {

            if (!user.allowedPermissions.includes(resource)) {
                continue;
            }

            if (isAdded) {
                formControlValue = ArraySupport.arrayMerge(
                    formControlValue,
                    [user.id],
                    true
                );

                if (needCheckRead) {
                    viewFormControlValue = ArraySupport.arrayMerge(
                        viewFormControlValue,
                        [user.id],
                        true
                    );
                }

                if (needCheckUpdate) {
                    updatedFormControlValue = ArraySupport.arrayMerge(
                        updatedFormControlValue,
                        [user.id],
                        true
                    );
                }
            } else {
                formControlValue = ArraySupport.removeItem(
                    formControlValue,
                    [user.id]
                );
            }
        }

        this.shareSettingsFormGroup.get(resource).setValue(formControlValue);
        this.shareSettingsFormGroup.get(this.shareSettingsControlNames.DashboardRead).setValue(viewFormControlValue);
    }

    /**
     * Prepare form datas to request
     *
     * @returns {DashboardShareSettingsSaveRequestData}
     */
    getDatasForRequest(): DashboardShareSettingsSaveRequestData {
        const permissions = [
            this.shareSettingsControlNames.DashboardRead,
            this.shareSettingsControlNames.DashboardUpdate,
            this.shareSettingsControlNames.DashboardDelete
        ];
        const permissionAliases = {
            [this.shareSettingsControlNames.DashboardRead]: DashboardPermissionsList.View,
            [this.shareSettingsControlNames.DashboardUpdate]: DashboardPermissionsList.Update,
            [this.shareSettingsControlNames.DashboardDelete]: DashboardPermissionsList.Delete,
        };
        const permissionConfig = {};

        permissions.forEach(permission => {
            (this.shareSettingsFormGroup.get(permission).value || []).forEach(userID => {
                permissionConfig[userID] = permissionConfig[userID] || [];

                (permissionConfig[userID] as DashboardPermissionsList[]).push(
                    permissionAliases[permission]
                );
            });
        });

        return {
            dashboardID: this.dashboardID,
            permissions: permissionConfig
        };
    }

    /**
     * Clear userID selections
     *
     * @param {MouseEvent} event
     */
    clearSelections(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const resources = [
            this.shareSettingsControlNames.DashboardRead,
            this.shareSettingsControlNames.DashboardUpdate,
            this.shareSettingsControlNames.DashboardDelete
        ];
        const myUserID = LoggedUser.getUser().userID;

        // reset dashboard user permissions except my userID
        resources.forEach(resource => {
            this.shareSettingsFormGroup.get(resource).setValue(
                (this.shareSettingsFormGroup.get(resource).value || []).filter(userID => userID === myUserID)
            );
        });
    }

    /**
     * Check current resource is selected at that organization
     *
     * @param {keyof SelectedOrganizationIDs} resource
     * @param {string} organizationID
     * @returns {boolean}
     */
    isChecked(resource: keyof SelectedUserIDs, organizationID: string): boolean {

        if (!this.shareSettingsFormGroup) {
            return false;
        }

        return (this.shareSettingsFormGroup.get(resource).value || []).includes(organizationID);
    }

    /**
     * Return check that permission at all user are checked
     *
     * @param {keyof SelectedUserIDs} resource
     * @param {number[]} userIDs
     * @returns {boolean}
     */
    isAllChecked(resource: keyof SelectedUserIDs, userIDs: number[]): boolean {
        const usersCount = userIDs.length;
        const matchedUserID = this.matchSelectedUserID(resource, userIDs);

        return usersCount === matchedUserID;
    }

    /**
     * Count user IDs in selected user IDs
     *
     * @param {keyof SelectedUserIDs} resource
     * @param {number[]} userIDs
     * @returns {number}
     */
    matchSelectedUserID(resource: keyof SelectedUserIDs, userIDs: number[]): number {
        let count = 0;

        if (!this.shareSettingsFormGroup) {
            return count;
        }

        for (const id of this.shareSettingsFormGroup.get(resource).value) {
            if (userIDs.includes(id)) {
                count++;
            }
        }

        return count;
    }
}
