
export class BulkUploadManagerModel {

    /**
     * Entity types
     *
     * @type {{Post: 'post'; Template: 'template'}}
     */
    static ENTITY_TYPES = {
        Post: 'post',
        Template: 'template'
    };

}
