import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LanguageService } from "~/src/app/services/language.service";
import { RssGroupService } from "~/src/app/modules/rss/services/rss-group.service";
import { RssGroupModel } from "~/src/app/modules/rss/models/rss-group.model";
import { MatSelect } from "@angular/material/select";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "smd-rss-group-select",
    templateUrl: "./rss-group-select.component.html",
    styleUrls: ["./rss-group-select.component.scss"],
})
export class RssGroupSelectComponent implements OnInit {
    @Input() groupControl: FormControl;
    @Input() errorMessage: string;
    @Input() multiple = false;
    @Input() selectedGroupID: number;
    @Input() organizationControl: FormControl;
    @ViewChild(MatSelect, { read: MatSelect }) groupSelect: MatSelect;
    rssGroups: RssGroupModel[] = [];
    groupsLoading = false;

    constructor(
        public language: LanguageService,
        private rssGroupService: RssGroupService
    ) {}

    ngOnInit(): void {
        let organizationID = null;

        if (this.organizationControl) {
            this.organizationControl.valueChanges
                .pipe(debounceTime(350))
                .subscribe((value) => this.getGroups(value));

            organizationID = this.organizationControl.value;
        }

        this.getGroups(organizationID);
    }

    selectionChange(rssGroupIDs: number | number[]): void {
        if (this.groupControl) {
            let value = null;
            rssGroupIDs = (
                rssGroupIDs
                    ? Array.isArray(rssGroupIDs)
                        ? rssGroupIDs
                        : [rssGroupIDs]
                    : null
            ) as number[];

            if (rssGroupIDs) {
                value = this.rssGroups.filter((group) =>
                    (rssGroupIDs as number[]).includes(group.groupID)
                );
            }

            this.groupControl.setValue(value, { emitEvent: true });
        }
    }

    setValue(groupIDs: number | number[]): void {
        if (this.multiple && groupIDs) {
            groupIDs = Array.isArray(groupIDs) ? groupIDs : [groupIDs];
        }

        if (this.groupSelect) {
            this.selectedGroupID = null;
            this.groupSelect.writeValue(groupIDs);
            this.groupControl.setValue(groupIDs, { emitEvent: true });
            this.groupSelect.value = groupIDs;
        }
    }

    getGroups(organizationID?: number[]): void {
        this.groupsLoading = true;
        this.rssGroupService
            .listGroups({ organizationID })
            .then((response) => {
                this.rssGroups = response.groups;

                if (this.selectedGroupID) {
                    const group = this.rssGroups.find(
                        (_group) => _group.groupID === this.selectedGroupID
                    );

                    if (group) {
                        this.groupControl.setValue(group, {
                            emitEvent: false,
                            onlySelf: true,
                            emitModelToViewChange: false,
                            emitViewToModelChange: false,
                        });
                    }
                }
            })
            .finally(() => (this.groupsLoading = false));
    }
}
