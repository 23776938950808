import {IMenuItem} from '~/src/app/components/main-menu/menu-item.interface';
import {PartnerPermission} from '~/src/app/shared/services/partner-config/partner-config.interfaces';
import Utils from '~/src/app/core/utils';
import AbstractModel from '~/src/app/core/model.abstract';

export class MenuItem extends AbstractModel<IMenuItem> implements IMenuItem {
    icon: string = null;
    label: string = null;
    link: string = null;
    resource: string | string[] = null;
    connectionType: string = null;
    subItems: MenuItem[] = [];
    partnerPermission: PartnerPermission = null;
    uid: string;
    isActive: boolean;
    parent: MenuItem;

    constructor(json: IMenuItem, parent?: MenuItem) {
        super(json);
        this.parse(json);
        this.uid = Utils.uuid.UUID();
        this.isActive = false;
        this.parent = parent || null;

        if (json.subItems && json.subItems.length) {
            this.subItems = json.subItems.map(subItem => new MenuItem(subItem, this));
        } else {
            this.subItems = [];
        }
    }
}
