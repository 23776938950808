export default abstract class AbstractModel<T = any> {

    json: T;

    protected constructor(entity: T) {
        this.json = entity;
    }

    /**
     * Assign json properties to model class
     *
     * @param {T} entity
     */
    parse(entity: T) {
        Object.assign(this, entity);
    }

}
