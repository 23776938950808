import {debounceTime} from 'rxjs/operators';
import {Component, ViewChild} from '@angular/core';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {LanguageService} from '~/src/app/services/language.service';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PartnerManagerComponent} from './partner-manager/partner-manager.component';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {
    PARTNER_STATUS_ACTIVE,
    PARTNER_STATUS_INACTIVE
} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.config';
import {FormControl, FormGroup} from '@angular/forms';
import Utils from '~/src/app/core/utils';
import {Helpers} from '~/src/app/services/helpers';
import {Partner} from '~/src/app/shared/types/partners.model';
import {DialogConfirmComponent} from '~/src/app/components/dialog-confirm/dialog-confirm.component';
import {DialogSuccessComponent} from '~/src/app/components/dialog-success/dialog-success.component';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {DataTableHelpersService} from '~/src/app/core/services/data-table-helpers.service';
import {
    CollectionListSelectionChange,
    SingleActionClick,
    TableBodyConfig,
    TableHeadConfig
} from '~/src/app/core/components/collection-list/collection-list.interfaces';
import {CollectionListComponent} from '~/src/app/core/components/collection-list/collection-list.component';
import {ResourceSeparatorComponent} from '~/src/app/modules/administration/media-separator/resource-separator.component';
import {PartnerAnalyticsComponent} from '~/src/app/modules/administration/partners/partner-analytics/partner-analytics.component';
import {PartnerConfigViewsComponent} from '~/src/app/modules/administration/partners/partner-config-views/partner-config-views.component';
import {PartnerSubscriptionComponent} from '~/src/app/modules/administration/partners/partner-subscription/partner-subscription.component';
import {SetTemplatesToPartnersComponent} from '~/src/app/modules/administration/set-templates-to-partners/set-templates-to-partners.component';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';

@Component({
    selector: 'smd-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
    providers: [
        DataTableHelpersService,
        ComponentHelpers
    ]
})
export class PartnersComponent {
    @ViewChild(CollectionListComponent) collectionList: CollectionListComponent;
    // TODO just for testing
    readonly defaultOrder = 'name-asc';
    partnerStatuses = [
        {
            id: PARTNER_STATUS_ACTIVE,
            nameKey: 'partner.status.active'
        },
        {
            id: PARTNER_STATUS_INACTIVE,
            nameKey: 'partner.status.inactive'
        }
    ];
    filterNames = {
        Keyword: 'searchValue',
        OrderBy: 'order',
        Status: 'status'
    };
    filtersFormGroup = new FormGroup({
        [this.filterNames.Keyword]: new FormControl(null),
        [this.filterNames.OrderBy]: new FormControl(null),
        [this.filterNames.Status]: new FormControl(null)
    });
    paginatorController: PaginationController = new PaginationController();
    partners: Partner[] = [];
    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: 'partner.list.partnerName'
        },
        {
            nameKey: 'partner.list.partnerAccountStatus',
            onlyDesktop: true,
            onlyMobile: true
        },
        {
            nameKey: 'partner.list.subscription',
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: 'partner.list.partnerActions',
            onlyDesktop: true
        }
    ];
    tableBodyConfig: TableBodyConfig[] = [
        {
            bindValue: 'name'
        },
        {
            bindValue: 'statusMessage',
            onlyDesktop: true,
        },
        {
            bindValue: 'view.shortenedSubscription',
            bindTooltipValue: 'view.subscription',
            onlyDesktop: true,
        },
        {
            staticView: 'itemActions',
            onlyDesktop: true
        },
    ];

    constructor(
        public languageService: LanguageService,
        public partnersService: PartnersService,
        public dataTableHelper: DataTableHelpersService,
        public componentHelpers: ComponentHelpers,
        private openModal: OpenModalService
    ) {
        this.partnersService.partners.subscribe(partners => this.partners = partners);
        this.getPartners();

        this.filtersFormGroup.valueChanges.pipe(
            debounceTime(350))
            .subscribe(() => {
                this.paginatorController.paginationOptions.pageIndex = 0;
                this.getPartners();
            });

        this.paginatorController.onPaginationChange.subscribe(() => this.getPartners());
    }

    /**
     * Resend activation e-mail to first user of partner
     * @param {MouseEvent} e
     * @param {Partner} partner
     */
    resendActivationEmail(e: MouseEvent, partner: Partner) {
        e.preventDefault();
        e.stopPropagation();

        this.componentHelpers.startApiAction(
            () => this.partnersService.resendActivationEmail(partner.partnerID),
            {
                successMessageKey: 'user.create.success.activateEmailSend',
                failedMessageKey: 'user.create.error.activateEmailSend',
                confirmMessageKey: 'partner.resendActivationEmail.confirm.message',
                confirmYesButtonTextKey: 'partner.resendActivationEmail.confirm.button'
            },
            true
        );
    }

    /**
     * Single action click
     * @param {MouseEvent} event
     * @param {"edit" | "suspend" | "delete"} actionName
     * @param {Partner} partner
     */
    singleAction(event: SingleActionClick<Partner>): void {
        switch (event.type) {
            case 'edit':
                this.editPartner(event.item);

                break;

            case 'configView':
                this.openPartnerConfigView(event.item);

                break;

            case 'analytics':
                this.handleOpenPartnerAnalyticsModal([event.item]);

                break;

            case 'addSubscription':
                this.manageSubscription(event.item);

                break;

            case 'editSubscription':
                this.manageSubscription(event.item, true);

                break;

            case 'activate':
                this.activatePartners([event.item]);

                break;

            case 'suspend':
                this.suspendPartners([event.item]);

                break;

            case 'setMedias':
                this.setMediasToPartners([event.item]);

                break;

            case 'setTemplates':
                this.setTemplatesToPartners([event.item]);

                break;

            case 'delete':
                this.deletePartners([event.item]);

                break;
        }
    }

    /**
     * Group action click
     * @param {MouseEvent} event
     * @param {"suspend" | "delete"} actionName
     */
    groupAction(event: MouseEvent, actionName: 'suspend' | 'delete' | 'setMedias' | 'setTemplates' | 'activate' | 'analytics'): void {
        event.preventDefault();
        event.stopPropagation();

        if (!!this.dataTableHelper.selectedItems.length) {
            switch (actionName) {
                case 'activate':
                    this.activatePartners(this.dataTableHelper.selectedItems);

                    break;

                case 'analytics':
                    this.handleOpenPartnerAnalyticsModal(this.dataTableHelper.selectedItems);

                    break;

                case 'suspend':
                    this.suspendPartners(this.dataTableHelper.selectedItems);

                    break;

                case 'setMedias':
                    this.setMediasToPartners(this.dataTableHelper.selectedItems);

                    break;

                case 'setTemplates':
                    this.setTemplatesToPartners(this.dataTableHelper.selectedItems);

                    break;

                case 'delete':
                    this.deletePartners(this.dataTableHelper.selectedItems);

                    break;
            }
        }
    }

    selectionChange(event: CollectionListSelectionChange<Partner>) {
        this.dataTableHelper.selectedItems = event.selectedItems;
    }

    /**
     * Reset filters
     * @param {MouseEvent} event
     */
    resetFilters(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const values = {};
        Utils.lodash.forEach(this.filtersFormGroup.controls, (control, controlName) => {
            values[controlName] = null;
        });

        this.filtersFormGroup.setValue(values, {emitEvent: true});
    }

    /**
     * Get partners from server
     */
    getPartners() {
        const filters = {
            ...this.paginatorController.requestParams,
            [this.filterNames.OrderBy]: Helpers.getOrderFormat(this.defaultOrder)
        };

        Utils.lodash.forEach(this.filtersFormGroup.getRawValue(), (value, filterName) => {
            if (!!value) {

                if (filterName === this.filterNames.OrderBy) {
                    value = Helpers.getOrderFormat(value);
                }

                filters[filterName] = value;
            }
        });

        const loader = this.openModal.loader(DialogLoaderComponent);
        this.partnersService.getPartners(filters, false).then((response: any) => {
            this.paginatorController.paginationOptions.length = response.count;

            if (!!this.collectionList) {
                this.collectionList.clearSelection();
            }

            loader.close();
        }).catch((error) => {
            loader.afterClosed().subscribe(() => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message: FormValidationService.readError(error).message ||
                        this.languageService.getLine('partner.getPartners.error.message')
                });
            });

            loader.close();
        });
    }

    /**
     * Open create partner modal
     * @param {MouseEvent} event
     */
    openCreatePartnerManager(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.openModal.createPartner(PartnerManagerComponent, {
            data: {
                successCreate: () => {
                    this.getPartners();
                }
            }
        });
    }

    /**
     * Check partner is active
     * @param partner
     */
    isActivePartner(partner: Partner): boolean {
        return partner.status === PARTNER_STATUS_ACTIVE;
    }

    /**
     * Check partner is inactive
     * @param partner
     */
    isInactivePartner(partner: Partner): boolean {
        return partner.status === PARTNER_STATUS_INACTIVE;
    }

    /**
     * Get lodash in view
     */
    get lodash() {
        return Utils.lodash;
    }

    /**
     * Get selected partners number
     * @return {number}
     */
    get selectedPartnersNumber(): number {
        return this.dataTableHelper.selectedItems.length;
    }

    /**
     * Set medias to partners
     * @param partners
     */
    private setMediasToPartners(partners: Partner[]) {
        this.openModal.setResourcesToPartners(ResourceSeparatorComponent, {
            data: {
                partners: partners,
                mode: 'media',
                afterSuccessSave: () => {
                    this.getPartners();
                }
            }
        });
    }

    /**
     * Set templates to partners
     * @param {Partner[]} partners
     */
    private setTemplatesToPartners(partners: Partner[]) {
        this.openModal.setTemplatesToPartners(SetTemplatesToPartnersComponent, {
            data: {
                partners,
                afterSuccessSave: () => {
                    this.getPartners();
                }
            }
        });
    }

    /**
     * Edit partner
     * @param {Partner} partner
     */
    private editPartner(partner: Partner): void {
        this.openModal.editPartner(PartnerManagerComponent, partner, {
            data: {
                successEdit: () => {
                    this.getPartners();
                }
            }
        });
    }

    /**
     * Manage subscription
     * @param partner
     * @param isEditMode
     */
    private manageSubscription(partner: Partner, isEditMode: boolean = false): void {
        this.openModal.manageSubscription(PartnerSubscriptionComponent, partner, {
            data: {
                successSubscriptionAdd: () => {
                    this.getPartners();
                },
                isEditMode: isEditMode
            }
        });
    }

    /**
     * Open partner config view modal
     * @param partner
     */
    private openPartnerConfigView(partner: Partner) {
        this.openModal.partnerCongfigView(PartnerConfigViewsComponent, partner);
    }

    /**
     * Open partner analytics modal
     * @param partner
     */
    private handleOpenPartnerAnalyticsModal(partners: Partner[]): void {
        this.openModal.partnerAnalytics(PartnerAnalyticsComponent, partners);
    }

    /**
     * Activate partners
     * @param {Partner[]} partners
     */
    private activatePartners(partners: Partner[]): void {
        let successMessage = '';
        let failedFallbackMessage = '';

        if (partners.length === 1) {    // single
            successMessage = this.languageService.getLine('partner.list.action.activate.success');
            failedFallbackMessage = this.languageService.getLine('partner.list.action.activate.error');
        } else {                        // multiple
            successMessage = this.languageService.getLine('partner.list.action.activateAll.success', {number: this.selectedPartnersNumber});
            failedFallbackMessage = this.languageService.getLine('partner.list.action.activateAll.error');
        }

        const loader = this.openModal.loader(DialogLoaderComponent);
        this.partnersService.activatePartners(partners.map(item => item.partnerID)).then(() => {
            loader.afterClosed().subscribe(() => {
                this.openModal.successModal(DialogSuccessComponent, {
                    message: successMessage
                }).afterClosed().subscribe(() => {
                    this.getPartners();
                });
            });
            loader.close();
        }).catch(error => {
            loader.afterClosed().subscribe(() => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message: FormValidationService.readError(error).message || failedFallbackMessage
                });
            });
            loader.close();
        });
    }

    /**
     * Suspend partner(s)
     * @param {Partner[]} partners
     */
    private suspendPartners(partners: Partner[]): void {
        let message = '';
        let yesButtonText = '';
        let successMessage = '';
        let failedFallbackMessage = '';

        if (partners.length === 1) {    // single
            message = this.languageService.getLine('partner.info.suspend.confirmMessage');
            yesButtonText = this.languageService.getLine('partner.info.suspend.yesButtonText');
            successMessage = this.languageService.getLine('partner.list.action.suspend.success');
            failedFallbackMessage = this.languageService.getLine('partner.list.action.suspend.error');
        } else {                        // multiple
            message = this.languageService.getLine('partner.list.action.suspendAll.confirm.message', {number: this.selectedPartnersNumber});
            yesButtonText = this.languageService.getLine('partner.list.action.suspendAll.confirm.yesButtonText');
            successMessage = this.languageService.getLine('partner.list.action.suspendAll.success', {number: this.selectedPartnersNumber});
            failedFallbackMessage = this.languageService.getLine('partner.list.action.suspendAll.error');
        }

        this.openModal.confirmModal(DialogConfirmComponent, {
            message: message,
            yesButtonText: yesButtonText
        }).afterClosed().subscribe(doIt => {
            if (!!doIt) {
                const loader = this.openModal.loader(DialogLoaderComponent);
                this.partnersService.suspendPartners(partners.map(item => item.partnerID)).then(() => {
                    loader.afterClosed().subscribe(() => {
                        this.openModal.successModal(DialogSuccessComponent, {
                            message: successMessage
                        }).afterClosed().subscribe(() => {
                            this.getPartners();
                        });
                    });

                    loader.close();
                }).catch(error => {
                    loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: FormValidationService.readError(error).message || failedFallbackMessage
                        });
                    });

                    loader.close();
                });
            }
        });
    }

    /**
     * Delete partner(s)
     * @param {Partner[]} partners
     */
    private deletePartners(partners: Partner[]): void {
        let message = '';
        let yesButtonText = '';
        let successMessage = '';
        let failedFallbackMessage = '';

        if (partners.length === 1) {    // single
            message = this.languageService.getLine('partner.info.delete.confirmMessage');
            yesButtonText = this.languageService.getLine('partner.info.delete.yesButtonText');
            successMessage = this.languageService.getLine('partner.list.action.delete.success');
            failedFallbackMessage = this.languageService.getLine('partner.list.action.delete.error');
        } else {                        // multiple
            message = this.languageService.getLine('partner.list.action.deleteAll.confirm.message', {number: this.selectedPartnersNumber});
            yesButtonText = this.languageService.getLine('partner.list.action.deleteAll.confirm.yesButtonText');
            successMessage = this.languageService.getLine('partner.list.action.deleteAll.success', {number: this.selectedPartnersNumber});
            failedFallbackMessage = this.languageService.getLine('partner.list.action.deleteAll.error');
        }

        this.openModal.confirmModal(DialogConfirmComponent, {
            message: message,
            yesButtonText: yesButtonText
        }).afterClosed().subscribe(doIt => {
            if (!!doIt) {
                const loader = this.openModal.loader(DialogLoaderComponent);
                this.partnersService.deletePartners(partners.map(item => item.partnerID)).then(() => {
                    loader.afterClosed().subscribe(() => {
                        this.openModal.successModal(DialogSuccessComponent, {
                            message: successMessage
                        }).afterClosed().subscribe(() => {
                            this.paginatorController.paginationOptions.pageIndex = 0;
                            this.getPartners();
                        });
                    });
                    loader.close();
                }).catch(error => {
                    loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: FormValidationService.readError(error).message || failedFallbackMessage
                        });
                    });
                    loader.close();
                });
            }
        });
    }
}
