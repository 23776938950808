import {
    ACTIVITY_CREATE_TEMPLATE,
    ACTIVITY_INSTANT_POST,
    ACTIVITY_MODIFY_POST,
    ACTIVITY_MODIFY_POST_AUTOFEED,
    ACTIVITY_MODIFY_TEMPLATE,
    ACTIVITY_SCHEDULE_POST,
    ACTIVITY_SCHEDULE_POST_AUTOFEED,
    ACTIVITY_STATUS_CHANGE_POST,
    ACTIVITY_STATUS_CHANGE_TEMPLATE
} from './activity.constants';


/**
 * Activity open entities options
 * @type {string[]}
 */
export const ACTIVITY_OPEN_POST_TYPES: string[] = [
    ACTIVITY_SCHEDULE_POST,
    ACTIVITY_INSTANT_POST,
    ACTIVITY_SCHEDULE_POST_AUTOFEED,
    ACTIVITY_MODIFY_POST_AUTOFEED,
    ACTIVITY_MODIFY_POST,
    ACTIVITY_STATUS_CHANGE_POST
];

export const ACTIVITY_OPEN_TEMPLATE_TYPES: string[] = [
    ACTIVITY_CREATE_TEMPLATE,
    ACTIVITY_MODIFY_TEMPLATE,
    ACTIVITY_STATUS_CHANGE_TEMPLATE
];
