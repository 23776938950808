
export const AUTOFEED_RESOURCE = '/autofeed';

export const AUTOFEED_PRESET_RESOURCE = '/autofeed/preset';
export const AUTOFEED_PRESET_DEFAULT_ORDER = 'createDate-desc';

export const AUTOFEED_RULE_PERIODS = [
    'weekend',
    'weekdays',
    'everyday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
];

export const AUTOFEED_PRESET_FILTER_CONTROL_NAMES = {
    Organization: 'organizationID',
    Name: 'name',
    Sort: 'sort',
    Period: 'period',
    Category: 'categoryID'
};
