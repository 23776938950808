import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '~/src/app/services/language.service';
import { ComponentHelpers } from '~/src/app/core/services/component-helpers';
import { OpenModalService } from '~/src/app/modules/social-media-post/open-modal.service';
import { DialogLoaderComponent } from '~/src/app/components/dialog-loader/dialog-loader.component';
import { TemplateOrganizationChooserDialogData } from './template-organization-chooser.interfaces';
import { OrganizationService } from '../../../users/organizations/organization.service';
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import { OrganizationItem } from '~/src/app/components/organization-select/organization.interfaces';

@Component({
    selector: 'smd-template-organization-chooser',
    templateUrl: './template-organization-chooser.component.html',
    styleUrls: ['./template-organization-chooser.component.scss'],
    providers: [
        ComponentHelpers
    ]
})
export class TemplateOrganizationChooserComponent {
    enabledOrganizations: OrganizationItem[] = [];
    disabledOrganizations: OrganizationItem[] = [];
    indeterminateOrganizations: OrganizationItem[] = [];
    lockedOrganizations: OrganizationItem[] = [];

    isLoading: Boolean = true;
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: TemplateOrganizationChooserDialogData,
        public dialogRef: MatDialogRef<TemplateOrganizationChooserComponent>,
        public organizationService: OrganizationService,
        public language: LanguageService,
        private componentHelpers: ComponentHelpers,
        private openModal: OpenModalService,
        private socialMediaPostService: SocialMediaPostService,
    ) { }

    async ngOnInit() {
        const orgList = (await this.organizationService.getList()).organizations.map(organization => {
            return {
                name: organization.name,
                organizationID: organization.organizationID,
            } as OrganizationItem;
        });
        if (orgList) {
            let templateMainOrganizations: Set<number> = new Set();
            let allUsedOrganizations: OrganizationItem[] = [];

            // intersection = enabledOrganizations
            // union - intersection = indeterminateOrganizations
            if (this.dialogData.templates.length > 0) {
                this.enabledOrganizations = orgList.filter(organization => this.dialogData.templates[0].organizationIDs.includes(organization.organizationID.toString()));
                this.dialogData.templates.forEach(template => {
                    if (template.organizationIDs) {
                        this.enabledOrganizations = this.enabledOrganizations.filter(organization => template.organizationIDs.includes(organization.organizationID.toString()));
                        const orgListWithoutAllUsedOrganizations = orgList.filter(organization => !allUsedOrganizations.includes(organization));
                        allUsedOrganizations = allUsedOrganizations.concat(orgListWithoutAllUsedOrganizations.filter(organization => template.organizationIDs.includes(organization.organizationID.toString())));
                    }
                    if (template.organizationID) {
                        templateMainOrganizations.add(template.organizationID);
                    }
                });
            }
            this.indeterminateOrganizations = allUsedOrganizations.filter(organization => !this.enabledOrganizations.includes(organization));
            this.disabledOrganizations = orgList.filter(organization => !this.enabledOrganizations.includes(organization) && !this.indeterminateOrganizations.includes(organization));
            

            // remove main organizations from both lists so they are hidden (since they cannot (well, should not) be changed)
            // this is actually unnecessary, they will be "locked" (disabled).
            //this.enabledOrganizations = this.enabledOrganizations.filter(organization => !templateMainOrganizations.has(organization.organizationID));
            //this.disabledOrganizations = this.disabledOrganizations.filter(organization => !templateMainOrganizations.has(organization.organizationID));

            // only lock main org if we edit templates with the same main org
            if (templateMainOrganizations.size == 1) {
                this.lockedOrganizations = orgList.filter(organization => templateMainOrganizations.has(organization.organizationID));
            }
        }
        this.isLoading = false;
    }

    /**
     * Save modifies to templates/medias
     * @param {MouseEvent} event
     */
    save(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const loader = this.openModal.loader(DialogLoaderComponent);
        const successAction = (response, messageKey: string) => {
            loader.afterClosed().subscribe(() => {
                this.componentHelpers.apiAction('success', response, { messageKey: messageKey }).then(() => {

                    this.dialogRef.afterClosed().subscribe(() => {
                        if (!!this.dialogData.afterSuccessSave) {
                            this.dialogData.afterSuccessSave(response);
                        }
                    });

                    this.dialogRef.close();
                });
            });

            loader.close();
        };
        const failedAction = (error, messageKey: string) => {
            loader.afterClosed().subscribe(() => {
                this.componentHelpers.apiAction('failed', error, { messageKey: messageKey });
            });

            loader.close();
        };

        const organizationIDs: number[] = this.enabledOrganizations.map(organization => organization.organizationID)
        const ignoredOrganizationIDs: number[] = this.indeterminateOrganizations.map(organization => organization.organizationID)
        const templateIDs: number[] = this.dialogData.templates.map(template => template.templateID);

        this.socialMediaPostService
            .setPostTemplateOrganizations(templateIDs, organizationIDs, ignoredOrganizationIDs)
            .then((response) => {
                successAction(response, 'post.template.organization.chooser.success');
            })
            .catch((error) => {
                failedAction(error, 'post.template.organization.chooser.error');
            });
        return;
    }
}
