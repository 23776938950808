import {NgModule} from '@angular/core';
import {ImagePreloadDirective} from './directives/image-preload.directive';
import {CommonModule} from '@angular/common';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImagePreloadDirective
    ],
    exports: [
        ImagePreloadDirective
    ]
})
export class ImagePreloadModule {}
