import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrganizationGroupbyService {
  private filterDataSource = new BehaviorSubject<any>(null);
  currentFilterData = this.filterDataSource.asObservable();
  constructor() { }
  changeFilterData(data: any) {

    this.filterDataSource.next(data);
  }
}
