import {Directive, ElementRef, HostListener} from '@angular/core';
import {AuthService} from '../modules/auth/auth.service';
import {CustomEvents} from '../services/helpers';

@Directive({
    selector: '[doLogout]'
})
export class DoLogoutDirective {

    constructor(private el: ElementRef, private auth: AuthService) {
    }

    /**
     * Logout by click event on [doLogout] element
     */
    @HostListener('click') onClick() {
        this.auth.logout().subscribe();
    }

    /**
     * Logout by logout event on document
     *
     * @param event
     */
    @HostListener(`document:${CustomEvents.LOGOUT}`, ['$event'])
    public onLogout(event): void {
        this.auth.logout();
    }
}
