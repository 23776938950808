import {
    PARTNER_PERMISSION_ACCESS_TO_GENERIC_MEDIAS,
    PARTNER_PERMISSION_ACCESS_TO_GENERIC_TEMPLATES,
    PARTNER_PERMISSION_ANALYTICS_MODULE,
    PARTNER_PERMISSION_APPROVAL_WORKFLOW,
    PARTNER_PERMISSION_AUTOFEED_MODULE,
    PARTNER_PERMISSION_DASHBOARD_PERSONALIZATION,
    PARTNER_PERMISSION_LINK_SHORTENING,
    PARTNER_PERMISSION_MEDIA_LIBRARY_CATEGORIES,
    PARTNER_PERMISSION_MEDIA_LIBRARY_TAGS,
    PARTNER_PERMISSION_RESOURCES,
    PARTNER_PERMISSION_ROLE_CREATE,
    PARTNER_PERMISSION_ROLE_EDIT,
    PARTNER_PERMISSION_RSS_MODULE
} from '~/src/app/modules/administration/partners/partner.constant';

export const PartnerPermissions = {
    ResourcesTab: PARTNER_PERMISSION_RESOURCES,
    RssModule: PARTNER_PERMISSION_RSS_MODULE,
    AnalyticsModule: PARTNER_PERMISSION_ANALYTICS_MODULE,
    AutoFeedModule: PARTNER_PERMISSION_AUTOFEED_MODULE,
    MediaTag: PARTNER_PERMISSION_MEDIA_LIBRARY_TAGS,
    MediaCategory: PARTNER_PERMISSION_MEDIA_LIBRARY_CATEGORIES,
    DashboardPersonalization: PARTNER_PERMISSION_DASHBOARD_PERSONALIZATION,
    ApprovementWorkflow: PARTNER_PERMISSION_APPROVAL_WORKFLOW,
    LinkShortening: PARTNER_PERMISSION_LINK_SHORTENING,
    RoleCreate: PARTNER_PERMISSION_ROLE_CREATE,
    RoleEdit: PARTNER_PERMISSION_ROLE_EDIT,
    GenericTemplate: PARTNER_PERMISSION_ACCESS_TO_GENERIC_TEMPLATES,
    GenericMedia: PARTNER_PERMISSION_ACCESS_TO_GENERIC_MEDIAS,
};
