import AbstractModel from '~/src/app/core/model.abstract';
import {IRSSBlogPost, IRSSChannel} from '~/src/app/modules/rss/types/rss-feed.interface';
import Utils from '~/src/app/core/utils';
import {RssSavedPostModel} from '~/src/app/modules/rss/models/rss-saved-post.model';
import {StringSupport} from '~/src/app/core/helper/string-support';

export class RssBlogPostModel extends AbstractModel<IRSSBlogPost> implements IRSSBlogPost {
    'dc:creator': string;
    author: string;
    category: string[];
    comments: string;
    _description: string;
    enclosure: string;
    guid: string;
    link: string;
    _pubDate: string;
    source: string;
    timestamp: string;
    title: string;
    image: string;
    creator: string;
    channel: IRSSChannel<RssBlogPostModel>;
    savedPost: RssSavedPostModel;
    pubDateFromNow: string = null;
    rawDescription: string;
    createDate: Date;

    get pubDate(): string {
        return this._pubDate;
    }

    set pubDate(value: string) {
        if (value) {
            this.pubDateFromNow = Utils.moment(new Date(value)).fromNow();
        } else {
            this.pubDateFromNow = null;
        }

        this._pubDate = value;
        this.createDate = new Date(value);
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = StringSupport.removeHtml(value);

        if (this.description) {
            this.rawDescription = StringSupport.removeHtml(this.description);
        }
    }

    constructor(json: IRSSBlogPost, channel: IRSSChannel<RssBlogPostModel> = null, savedPost: RssSavedPostModel = null) {
        super(json);
        this.parse(json);
        this.creator = this['dc:creator'] || this.author;
        this.channel = channel;
        this.savedPost = savedPost;
    }

}
