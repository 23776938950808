import { Component, OnInit, Inject } from '@angular/core';
import { Configs } from '~/src/app/configs/configs';
import { socialSiteAppearance } from '../social-media-platforms-config';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'smd-social-media-platform-chooser',
    templateUrl: './social-media-platform-chooser.component.html',
    styleUrls: ['./social-media-platform-chooser.component.scss']
})
export class SocialMediaPlatformChooserComponent implements OnInit {
    platforms = Configs.socials;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; socialsite: string }) {
    }

    ngOnInit() {

        this.platforms = this.platforms.filter(
            (item) => socialSiteAppearance?.[item.id]?.templatemanager !== false
        );

    }
    get socialsite() {
        return this.data.socialsite;
    }
}
