import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DraftType} from '~/src/app/modules/posts/post-actions';
import {Debounce} from '~/src/app/services/helpers';
import * as md5 from 'md5';
import {PostController} from '~/src/app/modules/posts/post.controller';
import {Subject} from '~/node_modules/rxjs';

const AUTO_DRAFT_INTERVAL = 5000;

@Component({
    selector: 'smd-draft',
    templateUrl: './draft.component.html',
    styleUrls: ['./draft.component.scss']
})
export class DraftComponent implements OnInit, OnDestroy {
    @Input() entityType: 'post' | 'postTemplate';
    @Input() entityData;
    @Input() validateMethod;
    @Input() parentScope;
    @Input() disabled;
    @Input() draftID;
    @Input() clearAutoSave: Subject<any>;
    @Output() successDraft = new EventEmitter();
    @Output() failedDraft = new EventEmitter();
    draftInProgress = false;
    autoDraftIsRun = false;
    draftIsSuccess = false;
    draftIsFailed = false;
    postFormSave: string;
    autoSaveInterval: any;

    constructor(
        private postController: PostController
    ) {
    }

    ngOnInit() {
        if (!!this.draftID) {
            this.autoDraft();
        }

        if (!!this.clearAutoSave) {
            this.clearAutoSave.subscribe(() => {
                this.clearTimer();
            });
        }
    }

    ngOnDestroy(): void {
        this.clearTimer();
    }

    @Debounce()
    draftClick() {
        const type: DraftType = this.entityType;
        const data = !this.isTemplateMode ? this.getPostDraftData() : this.getPostTemplateDraftData();
        const statusKeyName = (type === 'post') ? 'type' : 'status';

        if (!!this.validateMethod && typeof this.validateMethod === 'function') {
            if (!this.validateMethod.call(this.parentScope)) {
                this.onDraftIsFailed();
                return;
            }
        }

        if (!!data.posts) {
            data['posts'][0][statusKeyName] = 'draft';
        } else {
            data[statusKeyName] = 'draft';
        }

        // remove null values from orgids because there is a bug which we don't understand, and this is the easiest & fastest fix
        // sorry if this causes another issue in the future
        if (data['organizationIDs']) {
            // convert the string to an actual array
            data['organizationIDs'] = data['organizationIDs'].replace(/[\[\]]+/g, '').split(',');
            // filter out null values
            data['organizationIDs'] = data['organizationIDs'].filter(id => id !== "null");
            // convert back to the ugly string
            data['organizationIDs'] = `[${data['organizationIDs'].join(',')}]`;
        }

        this.draftInProgress = true;

        this.postController.draft(type, data, this.draftID).then(response => {
            const entity = this.isTemplateMode() ? response.template : !!response.posts ? response.posts[0] : response.post;
            this.draftID = entity.templateID || entity.postID;
            this.emitSuccessDarft({
                draftID: this.draftID
            });

            if (!this.autoSaveInterval) {
                this.autoDraft();
            }

            this.onDraftIsSuccess();
        }).catch(error => {
            this.emitFailedDarft(error);

            this.onDraftIsFailed();
        });
    }

    /**
     * Automatic draft save
     */
    private autoDraft() {
        this.autoSaveInterval = setInterval(() => {
            this.save();
        }, AUTO_DRAFT_INTERVAL);
    }

    /**
     * Save draft entity
     */
    save() {
        this.autoDraftIsRun = true;

        if (!!this.disabled) {
            return;
        }

        const generatePostFormSave = () => {
            let values = this.entityData;
            values = JSON.stringify(values);
            values = md5(values);

            return values;
        };

        if (!this.postFormSave) {
            this.postFormSave = generatePostFormSave();
        } else {
            const currentState = generatePostFormSave();
            if (this.postFormSave !== currentState) {
                this.draftClick();

                this.postFormSave = currentState;
            }
        }
    }

    /**
     * Clear auto save timer
     */
    private clearTimer() {
        if (!!this.autoSaveInterval) {
            clearInterval(this.autoSaveInterval);
        }
    }

    /**
     * Success draft event
     */
    private onDraftIsSuccess() {
        this.draftInProgress = false;
        this.draftIsSuccess = true;

        setTimeout(() => {
            this.draftIsSuccess = false;
        }, AUTO_DRAFT_INTERVAL - 2000);
    }

    /**
     * Failed draft event
     */
    private onDraftIsFailed() {
        this.draftInProgress = false;
        this.draftIsFailed = true;

        setTimeout(() => {
            this.draftIsFailed = false;
        }, AUTO_DRAFT_INTERVAL - 2000);
    }

    /**
     * Get data for post draft
     * @return {any}
     */
    private getPostDraftData() {
        return this.entityData;
    }

    /**
     * Get data for post template draft
     * @return {any}
     */
    private getPostTemplateDraftData() {
        return this.entityData;
    }

    /**
     * Emit success draft event
     * @param {{}} data
     */
    private emitSuccessDarft(data = {}) {
        this.successDraft.emit(data);
    }

    /**
     * Emit failed draft event
     * @param {{}} data
     */
    private emitFailedDarft(data = {}) {
        this.failedDraft.emit(data);
    }

    /**
     * Check is post template
     * @return {boolean}
     */
    private isTemplateMode() {
        return !!this.entityType && this.entityType === 'postTemplate';
    }
}
