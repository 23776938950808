import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import Utils from '~/src/app/core/utils';
import {ResourceService} from '~/src/app/directives/resource-checker/resource.service';

@Injectable({providedIn: 'root'})
export class ResourceGuard implements CanActivate {

    constructor(
        private router: Router,
        private resourceService: ResourceService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const resources = Utils.lodash.get(route, 'data.resources', []);
        const result = (!!resources && !!resources.length) ? this.resourceService.checkResource(resources) : false;

        if (!result) {
            this.router.navigateByUrl('/');
        }

        return result;
    }
}
