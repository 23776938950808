import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogSuccessComponent } from '~/src/app/components/dialog-success/dialog-success.component';

interface DialogData {
    message: string;
}

@Component({
    selector: 'smd-post-create-modal',
    templateUrl: './post-create-modal.component.html',
    styleUrls: ['./post-create-modal.component.scss']
})
export class PostCreateModalComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogSuccessComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
    }
    close(action: string): void {
        this.dialogRef.close(action);
    }
    closeDialog() {
        this.dialogRef.close();
    }
}
