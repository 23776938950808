import * as _ from 'lodash';
import {Many} from 'lodash';
import obj2fd from 'obj2fd';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';
import {UUID} from 'angular2-uuid';
import * as queryString from 'query-string';
import * as striptags from 'striptags';
import * as md5 from 'md5';
import {AllHtmlEntities, Html5Entities} from 'html-entities';

/**
 * Useful functions collection
 */
export default class Utils {
    public static lodash = _;
    public static obj2fd = obj2fd;
    public static moment = moment;
    public static uuid = UUID;
    public static queryString = queryString;
    public static striptags = striptags;
    public static md5 = md5;
    public static htmlEntities: Html5Entities = class extends Html5Entities {
        static decode(string: string): string {
            const decodedString = AllHtmlEntities.decode(string);

            if (string === decodedString) {
                return decodedString;
            } else {
                return Utils.htmlEntities.decode(decodedString);
            }
        }
    };
    private static validHtmlTags: string[] = [
        '<a ', '<a', '/a',
        '<span ', '<span', '/span',
        '<p ', '<p', '/p',
        '<b ', '<b', '/b',
        '<u ', '<u', '/u',
        '<i ', '<i', '/i',
        '<br', '<br ',
    ];

    public static orderBy<Entity = null>(collection: Entity[], key: (keyof Entity | string)[], order: Many<boolean|"asc"|"desc"> = null) {
        return this.lodash.orderBy(collection, key, order);
    }

    public static get<T = any>(object: any, path: keyof T | (keyof T)[] | string | string[], defaultValue?: any) {
        return this.lodash.get(object, path, defaultValue);
    }

    /**
     * Get object keys recursively
     * @param object
     * @param {string} preKey
     * @param {(fullKey: string, value: any) => void} callback
     * @param {string} delimiter
     * @returns {any[]}
     */
    public static getObjectKeys(object: any, preKey?: string, callback?: (fullKey: string, value: any) => void, delimiter: string = '.') {
        const keys = [];

        for (const key in object) {
            const value = object[key];
            const newKey = !!preKey ? preKey + delimiter + key : key;

            keys.push(newKey);

            if (!!callback) {
                callback(newKey, value);
            }

            if (value instanceof Object) {
                keys.push(
                    ...this.getObjectKeys(object[key], key, callback, delimiter)
                );
            }
        }

        return keys;
    }

    public static replaceHTMLToSymbol(string: string, mode: 'input' | 'output' = 'output') {
        if (!!string && this.lodash.isString(string)) {
            string = string.replace(/<br \/>/g, '\n');
            string = string.replace(/<a/g, '<a target="_blank"');

            string = string.replace(/style="(.*?)"/g, '');
            string = string.replace(/class="(.*?)"/g, '');
            string = string.replace(/id="(.*?)"/g, '');

            string = string.replace(/<h1(.*?)>/g, '<p>');
            string = string.replace(/<\/h1>/g, '</p>');

            string = string.replace(/<h2(.*?)>/g, '<p>');
            string = string.replace(/<\/h2>/g, '</p>');

            string = string.replace(/<h3(.*?)>/g, '<p>');
            string = string.replace(/<\/h3>/g, '</p>');

            string = string.replace(/<h4(.*?)>/g, '<p>');
            string = string.replace(/<\/h4>/g, '</p>');

            string = string.replace(/<h5(.*?)>/g, '<p>');
            string = string.replace(/<\/h5>/g, '</p>');

            string = string.replace(/<div(.*?)>/g, '');
            string = string.replace(/<\/div(.*?)>/g, '');

            // const matches: string[] = string.match(/\<(.*?)\>/g);

            // if (!!matches && !!matches.length) {
            //     matches.forEach(stringPart => {
            //         this.validHtmlTags.forEach(element => {
            //             if (stringPart.indexOf(element) > -1) {
            //                 if (mode === 'output') {
            //                     string = string.replace(stringPart, '');
            //                 }
            //             } else {
            //                 if (mode === 'input') {
            //                     string = this.replaceTagSignsToSymbol(string);
            //                 }
            //             }
            //         });
            //     });
            // }
        }

        return string;
    }

    public static initLineBreaksOnPostEntity(entity: any, keyList = ['headline', 'subHeadline', 'mainCopy', 'signature']) {
        keyList.forEach(propName => {
            let value = entity[propName];

            if (!!value && this.lodash.isString(value)) {
                const matches: string[] = value.match(/\<(.*?)\>/g);

                if (!!matches && !!matches.length) {
                    matches.forEach(part => {
                        this.validHtmlTags.forEach(tag => {
                            if (part.indexOf(tag) === -1) {
                                value = value.replace(part, this.replaceHTMLToSymbol(part));
                            }
                        });
                    });
                }

                value = value.replace(/\n/g, '<br />');
                value = value.replace(/↵/g, '<br />');
            }

            entity[propName] = (value || '').replace(/<placeholder>/g, this.htmlEntities.encode('<placeholder>'));
        });

        return entity;
    }

    public static removeStyle(html: string) {
        let result = html;

        html.match(/style="(.*?)\"/g).forEach(match => {
            result = result.replace(match, '');
        });

        return result;
    }

    public static replaceTagSignsToSymbol(string: string) {
        if (!!string && this.lodash.isString(string)) {
            string = string.replace(/</g, '&lt;');
            string = string.replace(/>/g, '&gt;');
        }

        return string;
    }

    /**
     * Get date view format
     * @param {string} date
     * @param {string} format
     * @return {string}
     */
    public static getViewDateFormat(date: string | Date, format: string) {
        return this.moment(date).format(format);
    }

    /**
     * Get inverse of color
     * @param {string} hex
     * @return {string}
     */
    public static invertColor(hex: string) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            console.warn('Invalid HEX color.', {hex});
            return '#' + hex;
        }
        // invert color components
        const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
    }

    /**
     * Get file url from file object
     * @param {File} file
     * @return {Promise<any>}
     */
    public static readImageUrl(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                resolve(this.lodash.get(e, 'target.result', null));
            };

            reader.onerror = (e) => {
                reject(e);
            };

            reader.readAsDataURL(file);
        });
    }

    /**
     * Generate file and download
     * @param content
     * @param mime
     * @param fileName
     */
    public static generateFile(content: string, mime: string, fileName: string) {

        // create link element
        const linkElement = document.createElement('a');

        // set link href with file content in base64
        linkElement.href = `data:${mime};base64,${content}`;

        // set download property
        linkElement.download = fileName;

        // hide element
        linkElement.style.display = 'none';

        // link add to DOM
        document.body.appendChild(linkElement);

        // start file download
        linkElement.click();

        // remove link element
        document.body.removeChild(linkElement);
    }

    public static openFileFromUrl(url): Promise<Blob> {
        return fetch(url).then(response => response.blob());
    }

    public static convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader;
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
    }

    private static padZero(str, len = null) {
        len = len || 2;
        const zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    public static isAbsoluteHttpUrl(value: string|URL): boolean {
        if (value instanceof URL) {
            return true;
        }
        if (typeof value !== 'string') {
            return false;
        }
        if (value.length == 0) {
            return false;
        }
        let protocolPosition: number;
        if (protocolPosition = value.indexOf('://')) {
            let protocol = value.substring(0, protocolPosition);
            let rest = value.substring(protocolPosition + 3);
            return ['http', 'https'].includes(protocol) && rest.length > 0 && rest.includes('.');
        }
        
        return false;
    }
}
