import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {environment} from '~/src/environments/environment';

@Injectable({providedIn: 'root'})
export class LanguageService {
    private static _language: object = [];

    constructor(
        private http: HttpClient
    ) {}

    static get language(): object {
        return this._language;
    }

    static set language(value: object) {
        this._language = value;
    }

    public static getLine(key: string, params: object = {}): string {
        //console.log("Get line called: " + key + " with params: " + JSON.stringify(params));
        if (key in LanguageService.language) {
            let returnValue = LanguageService.language[key];

            for (const key in params) {
                returnValue = returnValue.replace(':' + key, params[key]);
            }

            return returnValue;
        } else {
            return `<span style="background:red;color:white;padding:5px;">${key}</span>`;
        }
    }

    setLanguages(): Promise<boolean> {
        return this.getLanguageJson().then(data => {
            LanguageService.language = data['values'];
            return true;
        });
    }

    /**
     * Get languages from api
     *
     * @returns {Observable<object>}
     */
    getLanguageJson(): Promise<object> {
        return this.http.get<object>(environment.apiUrl + '/api/export/language.json').toPromise();
    }

    /**
     * Get language line from json
     *
     * @param key
     * @param params
     */
    getLine(key: string, params: object = {}): string {
        return LanguageService.getLine(key, params);
    }
}
