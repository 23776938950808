import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    selector: '[smdCustomView]'
})
export class CustomViewDirective {
    @Input() selector;

    constructor(
        public templateRef: TemplateRef<HTMLElement>
    ) {}

}
