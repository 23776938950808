import { WidgetMetricOptionType } from "../../widget.interfaces";
import { MetricConfigs } from "../metric.config";
import { BaseChartConfig } from "../_base-chart-config";

export const WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_OVERVIEW: WidgetMetricOptionType =
    {
        id: "views-videos-linkedin-overview",
        name: "Videos Views",
        resource: "analytics.analytics.pageVideoViews",
        socialChannel: "linkedIn",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_TOTAL: WidgetMetricOptionType =
    {
        id: "views-videos-linkedin-total",
        name: "Videos Views (Total)",
        resource: "analytics.analytics.pageVideoViews",
        socialChannel: "linkedIn",
    };

export const WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_UNIQUE: WidgetMetricOptionType =
    {
        id: "views-videos-linkedin-unique",
        name: "Video Views (Unique)",
        resource: "analytics.analytics.pageVideoViews",
        socialChannel: "linkedIn",
    };

export const MetricLinkedInViewVideosOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_OVERVIEW.id,
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Video Views Overview",
    chartConfig: {
        ...BaseChartConfig,
    },
    partMetricIDs: [
        WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_TOTAL.id,
        WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_UNIQUE.id,
    ],
};

export const MetricLinkedInViewVideosTotalConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_TOTAL.id,
    apiURL: "/page-video-views",
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Video Views",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        unique: "yes",
    },
};

export const MetricLinkedInViewVideosUniqueConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_VIEWS_VIDEOS_UNIQUE.id,
    apiURL: "/page-video-views",
    socialType: "linkedIn",
    contentType: "chart",
    dataName: "Unique viewers",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        unique: "no",
    },
};
