import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';

export const WIDGET_METRIC_OPTION_CLICKS: WidgetMetricOptionType = {
    id: 'clicks',
    name: 'Clicks',
    resource: 'analytics.analytics.pageClicks',
    socialChannel: 'facebook'
};

export const MetricClicksConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_CLICKS.id,
    apiURL: '/page-clicks',
    contentType: 'chart',
    socialType: 'facebook',
    dataName: 'Click',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        unique: 'no',
    }
};
