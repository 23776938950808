import { Component, ViewChild } from "@angular/core";
import { LanguageService } from "~/src/app/services/language.service";
import { FormControl, FormGroup } from "@angular/forms";
import { RssFeedService } from "~/src/app/modules/rss/services/rss-feed.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { RssBlogPostModel } from "~/src/app/modules/rss/models/rss-blog-post.model";
import Utils from "~/src/app/core/utils";
import { RssStateService } from "~/src/app/modules/rss/services/rss-state.service";
import { ClickDecorator } from "~/src/app/core/decorators";
import { RSS_LIST_DEFAULT_SORT_VALUE } from "~/src/app/modules/rss/rss.constants";
import { RssManagerModalComponent } from "~/src/app/modules/rss/components/rss-manager-modal/rss-manager-modal.component";
import { RssGroupModel } from "~/src/app/modules/rss/models/rss-group.model";
import { RssSavedPostService } from "~/src/app/modules/rss/services/rss-saved-post.service";
import { RssFeedSelectComponent } from "~/src/app/modules/rss/components/rss-feed-select/rss-feed-select.component";
import { RssFeedModel } from "~/src/app/modules/rss/models/rss-feed.model";
import { Subject } from "rxjs";
import { RssGroupSelectComponent } from "~/src/app/modules/rss/components/rss-group-select/rss-group-select.component";
import { debounceTime } from "rxjs/operators";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";

@Component({
    selector: "smd-rss-feed-page",
    templateUrl: "./rss-feed-page.component.html",
    styleUrls: ["./rss-feed-page.component.scss"],
})
export class RssFeedPageComponent {
    @ViewChild(RssFeedSelectComponent, { read: RssFeedSelectComponent })
    rssFeedSelect: RssFeedSelectComponent;
    @ViewChild(RssGroupSelectComponent, { read: RssGroupSelectComponent })
    rssGroupSelect: RssGroupSelectComponent;
    filterControlNames = {
        Organization: "organizationID",
        RssFeed: "feeds",
        Keyword: "keyword",
        Sort: "sort",
        RssGroup: "rssGroup",
    };
    filterFormGroup = new FormGroup({
        [this.filterControlNames.Organization]: new FormControl(
            this.rssStateService.getFilters().organizationIDs
        ),
        [this.filterControlNames.RssFeed]: new FormControl(
            this.rssStateService
                .getFilters()
                .rssFeeds.map((feed) => feed.feedID)
        ),
        [this.filterControlNames.Keyword]: new FormControl(
            this.rssStateService.getFilters().keyword
        ),
        [this.filterControlNames.Sort]: new FormControl(
            this.rssStateService.getFilters().sort ||
                RSS_LIST_DEFAULT_SORT_VALUE
        ),
        [this.filterControlNames.RssGroup]: new FormControl(null),
    });
    savedPostsFilterFormGroup = new FormGroup({
        [this.filterControlNames.Keyword]: new FormControl(null),
        [this.filterControlNames.RssFeed]: new FormControl([]),
        [this.filterControlNames.Sort]: new FormControl(
            RSS_LIST_DEFAULT_SORT_VALUE
        ),
    });
    rssBlogPosts: RssBlogPostModel[] = [];
    savedPosts: RssBlogPostModel[] = [];
    selectedRssFeeds: RssFeedModel[] = [];
    rssBlogPostsPaginationReset = new Subject();
    savedPostsPaginationReset = new Subject();
    searchFn = (item: RssBlogPostModel) => {
        return this._searchFn(item, [
            {
                searchFields: ["title", "description"],
                searchValue: this.filterFormGroup.get(
                    this.filterControlNames.Keyword
                ).value,
            },
        ]);
    };
    savedPostsSearchFn = (item: RssBlogPostModel) => {
        return this._searchFn(item, [
            {
                searchFields: ["title", "description"],
                searchValue: this.savedPostsFilterFormGroup.get(
                    this.filterControlNames.Keyword
                ).value,
            },
            {
                searchFields: ["savedPost.feedID"],
                searchValue: this.savedPostsFilterFormGroup.get(
                    this.filterControlNames.RssFeed
                ).value,
            },
        ]);
    };

    constructor(
        public languageService: LanguageService,
        public rssStateService: RssStateService,
        private rssService: RssFeedService,
        private rssSavedPostService: RssSavedPostService,
        private openModal: OpenModalService
    ) {
        // rss feeds list filter params change
        this.filterFormGroup.valueChanges.subscribe((values) => {
            const rssGroup: RssGroupModel =
                values[this.filterControlNames.RssGroup];
            this.rssStateService.storeFilters({
                keyword: values[this.filterControlNames.Keyword],
                sort: values[this.filterControlNames.Sort],
                organizationIDs: values[this.filterControlNames.Organization],
                rssFeeds: this.rssFeedSelect.selectedRssFeeds.map(
                    (feed) => feed.json
                ),
                rssGroupID:
                    rssGroup && "groupID" in rssGroup ? rssGroup.groupID : null,
            });
        });

        // rss feeds list - rss feed selection change
        this.filterFormGroup
            .get(this.filterControlNames.RssFeed)
            .valueChanges.pipe(debounceTime(350))
            .subscribe((value) => {
                this.loadRssChannels(this.rssFeedSelect.selectedRssFeeds);
            });

        // rss feeds list - sort selection change
        this.filterFormGroup
            .get(this.filterControlNames.Sort)
            .valueChanges.pipe(debounceTime(350))
            .subscribe(
                (value) =>
                    (this.rssBlogPosts = this._getSortRssBlogPosts(
                        this.rssBlogPosts,
                        value
                    ))
            );

        // saved post list - sort selection change
        this.savedPostsFilterFormGroup
            .get(this.filterControlNames.Sort)
            .valueChanges.pipe(debounceTime(350))
            .subscribe(
                (value) =>
                    (this.savedPosts = this._getSortRssBlogPosts(
                        this.savedPosts,
                        value
                    ))
            );

        // get stored selected RSS feeds
        this.selectedRssFeeds = this.rssStateService
            .getFilters()
            .rssFeeds.map((json) => new RssFeedModel(json));

        // load rss channels
        this.loadRssChannels(this.selectedRssFeeds);

        // get saved posts
        this.getSavedPosts();
    }

    loadRssChannels(feeds: RssFeedModel[]) {
        if (feeds && feeds.length) {
            const loader = this.openModal.loader(DialogLoaderComponent);
            this.rssService
                .loadRssChannel(feeds)
                .then((response) => {
                    const blogPosts: RssBlogPostModel[] = [];

                    for (const channel of response.rss) {
                        blogPosts.push(...channel.item);
                    }

                    this.rssBlogPosts = this._getSortRssBlogPosts(
                        blogPosts,
                        this.filterFormGroup.get(this.filterControlNames.Sort)
                            .value
                    );
                })
                .catch((error) => {
                    const fieldMessages =
                        FormValidationService.readError(error).formMessages;
                    let message = LanguageService.getLine("core.request.error");

                    if (fieldMessages && fieldMessages instanceof Object) {
                        const fields = Object.keys(fieldMessages);
                        const firstKey = fields.length ? fields[0] : null;

                        if (firstKey) {
                            message = fieldMessages[firstKey] || message;
                        }
                    }

                    loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message,
                        });
                    });
                })
                .finally(() => loader.close());
        } else {
            this.rssBlogPosts = [];
        }

        this.rssBlogPostsPaginationReset.next();
    }

    getSavedPosts(): void {
        const loader = this.openModal.loader(DialogLoaderComponent);
        this.rssSavedPostService
            .listSavedPosts()
            .then((response) => {
                this.savedPosts = this._getSortRssBlogPosts(
                    response.savedPosts.map((item) => item.blogPost),
                    this.savedPostsFilterFormGroup.get(
                        this.filterControlNames.Sort
                    ).value
                );
            })
            .finally(() => loader.close());
    }

    @ClickDecorator()
    openRssManager(event: MouseEvent): void {
        this.openModal.rssManager(RssManagerModalComponent, {
            data: {
                onItemsChange: () => {
                    this.rssFeedSelect.getRssFeeds();
                    this.rssGroupSelect.getGroups();
                },
            },
        });
    }

    @ClickDecorator()
    resetFilters(event: MouseEvent): void {
        this.filterFormGroup.reset(
            {
                [this.filterControlNames.Sort]: RSS_LIST_DEFAULT_SORT_VALUE,
                [this.filterControlNames.RssGroup]: null,
            },
            { emitEvent: true }
        );
        this.rssGroupSelect.setValue([]);
    }

    @ClickDecorator()
    refreshFeedPosts(event: MouseEvent): void {
        this.loadRssChannels(this.rssFeedSelect.selectedRssFeeds);
    }

    /**
     * Add saved post to list
     * @param {RssBlogPostModel} blogPost
     */
    addSavedPost(blogPost: RssBlogPostModel): void {
        this.savedPosts = this._getSortRssBlogPosts(
            [...this.savedPosts, blogPost],
            this.savedPostsFilterFormGroup.get(this.filterControlNames.Sort)
                .value
        );
    }

    /**
     * Remove saved post from list
     * @param {RssBlogPostModel} blogPost
     */
    removeSavedPost(blogPost: RssBlogPostModel) {
        const index = this.savedPosts.findIndex(
            (item) => item.savedPost.postID === blogPost.savedPost.postID
        );

        if (index > -1) {
            this.savedPosts.splice(index, 1);
            this.savedPostsPaginationReset.next();
        }
    }

    /**
     * Order RSS blog posts
     * @param {RssBlogPostModel[]} blogPosts
     * @param {string} sortValue
     * @return {RssBlogPostModel[]}
     * @private
     */
    private _getSortRssBlogPosts(
        blogPosts: RssBlogPostModel[],
        sortValue: string
    ): RssBlogPostModel[] {
        const [fieldName, dir] = sortValue.split(":");
        return Utils.lodash.orderBy(
            blogPosts,
            [fieldName],
            dir as "asc" | "desc"
        );
    }

    /**
     * Search rss blog posts by filter params
     * @param {RssBlogPostModel} item
     * @param {{searchFields: (keyof RssBlogPostModel | string)[]; searchValue: any}[]} conditions
     * @return {boolean}
     * @private
     */
    private _searchFn(
        item: RssBlogPostModel,
        conditions: {
            searchFields: (keyof RssBlogPostModel | string)[];
            searchValue: any;
        }[]
    ): boolean {
        const results = [];

        for (const condition of conditions) {
            const { searchFields, searchValue } = condition;

            if (searchValue && searchValue.length) {
                let enable = false;
                for (const fieldName of searchFields) {
                    const value: any = Utils.get(item, fieldName);

                    switch (typeof value) {
                        case "string":
                            if (
                                value
                                    .toLowerCase()
                                    .includes(
                                        (searchValue as string).toLowerCase()
                                    )
                            ) {
                                enable = true;
                            }

                            break;

                        case "number":
                            const _searchValue: any[] = Array.isArray(
                                searchValue
                            )
                                ? searchValue
                                : [searchValue];
                            if (_searchValue.includes(value)) {
                                enable = true;
                            }

                            break;
                    }
                }
                results.push(enable);
            } else {
                results.push(true);
            }
        }

        return results.filter((result) => result).length === conditions.length;
    }
}
