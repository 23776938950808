import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ModelAbstract} from '~/src/app/services/model.abstract';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {
    DefaultRole,
    DefaultRolesResponse
} from '~/src/app/modules/administration/default-roles/default-roles.interfaces';
import Utils from '~/src/app/core/utils';
import {Token} from '~/src/app/services/token';
import {Helpers} from '~/src/app/services/helpers';
import {LanguageService} from '~/src/app/services/language.service';

@Injectable()
export class DefaultRoleService extends ModelAbstract {
    private defaultRoleCollection = new BehaviorSubject<DefaultRole[]>([]);
    getPending = false;

    constructor(
        public http: HttpClient,
        dialog: MatDialog
    ) {
        super(http, dialog);
        this.apiLink = '/api/default-role';
    }

    get defaultRoles() {
        return this.defaultRoleCollection.asObservable();
    }

    getDefaultRoles(filters?): Promise<DefaultRolesResponse> {
        this.getPending = true;

        return this.http.get<DefaultRolesResponse>(this.apiLink + '?' + Utils.queryString.stringify(filters), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise().then((response) => {
            const roles = this.hydrate(response.defaultRoles);

            this.defaultRoleCollection.next(roles);

            response.defaultRoles = roles;

            this.getPending = false;
            return response;
        }).catch((error) => {
            this.getPending = false;
            return error;
        });
    }

    /**
     * Create default role
     * @param {FormData} formData
     * @return {Promise<any>}
     */
    createDefaultRole(data: DefaultRole) {
        return this.create(Utils.obj2fd(this.transform(data)));
    }

    /**
     * Edit default role
     * @param {DefaultRole} defaultRole
     * @return {Promise<any>}
     */
    editDefaultRole(roleID: string, defaultRole: DefaultRole) {
        return this.http.post(this.apiLink + '/edit', Utils.obj2fd(this.transform({
            ...defaultRole,
            defaultRoleID: roleID
        })), Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }

    /**
     * Delete default role(s)
     * @param {string[]} defaultRoleIDs
     * @return {Promise<void>}
     */
    deleteDefaultRoles(defaultRoleIDs: string[]) {
        const data = {
            defaultRoleID: defaultRoleIDs
        };
        return this.http.post(
            this.apiLink + '/delete',
            Utils.obj2fd(data),
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    /**
     * Convert items to frontend
     * @param {T} items
     * @return {DefaultRole[]}
     */
    private hydrate(items: DefaultRole[]) {
        const defaultRoles: DefaultRole[] = items as DefaultRole[];

        return defaultRoles.map(role => {
            role.admin = (role.admin === 'yes') as boolean;

            role.view = {
                description: Utils.lodash.truncate(role.description, {length: 35}),
                statusMessage: LanguageService.getLine('core.status.' + role.status)
            };

            return role;
        });
    }

    /**
     * Transform default role to backend
     * @param {DefaultRole[] | DefaultRole} items
     * @return {any}
     */
    private transform(items: DefaultRole[] | DefaultRole) {
        const transform = (item: DefaultRole) => {

            if (Utils.lodash.has(item, 'view')) {
                delete item.view;
            }

            item.admin = (item.admin) ? 'yes' : 'no';

            return item;
        };

        if (!Array.isArray(items)) {
            return transform(items);
        }

        return items.map(item => transform(item));
    }
}
