import {Component, Input, OnInit} from '@angular/core';
import {UserRolesService} from '~/src/app/services/user-roles.service';
import {LanguageService} from '~/src/app/services/language.service';
import {FormGroup} from '~/node_modules/@angular/forms';
import {MediaResourceService} from '~/src/app/modules/media/media-resource.service';

@Component({
    selector: 'smd-media-tag-select',
    templateUrl: './media-tag-select.component.html',
    styleUrls: ['./media-tag-select.component.scss'],
})
export class MediaTagSelectComponent implements OnInit {
    @Input() isAdminMode = false;
    @Input() formGroup: FormGroup;
    @Input() selectControlName: string;
    @Input() errorMessage: string;
    @Input() hintMessage: string;
    @Input() addTag = true;
    @Input() multiple = true;
    @Input() isModal = true;
    isAdmin = false;
    tagGetPending = false;

    constructor(
        private userService: UserRolesService,
        public language: LanguageService,
        public mediaResource: MediaResourceService
    ) {
        userService.currentUserCan('admin').then((adminBool) => {
            this.isAdmin = adminBool;
        });
    }

    ngOnInit(): void {
        this.getTags();
    }

    /**
     * Get media tags
     */
    getTags() {
        this.tagGetPending = true;

        const filters = {};

        if (this.isAdminMode) {
            filters['type'] = 'generic';
        }

        this.mediaResource.getMediaTags(filters)
            .then(() => {
                this.tagGetPending = false;
            })
            .catch(() => {
                this.tagGetPending = false;
            });
    }
}
