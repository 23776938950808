import {EventEmitter, Injectable} from '@angular/core';
import {Debounce} from '~/src/app/services/helpers';
import {GridStackItemsValueChange, GridStackItemValueChange} from '~/src/app/core/services/grid-stack.service.d';

declare const $;

@Injectable()
export class GridStackService {
    gridStackSelector = '#grid-stack';
    gridStackItemsSelector = '#grid-stack .grid-items';
    valueChange = new EventEmitter<GridStackItemValueChange | GridStackItemsValueChange>();

    constructor() {}

    /**
     * Add event listeners to grid stack items
     * Call on AfterViewInit
     */
    initEventListeners(getAllWidgetsData = false): void {
        $(this.gridStackSelector).on('added', (event, items) => {
            for (const item of items) {
                $(`${this.gridStackItemsSelector}[data-id=${item.id}]`).on('resizestop dragstop',
                    ($event, ui) => {
                        if (getAllWidgetsData) {
                            this.getAllItemData();
                        } else {
                            this.gridStackItemResizeStop($event, ui);
                        }
                    });
            }
        });
    }

    /**
     * Remove event listeners from grid stack items
     * Call on Destroy
     */
    removeEventListeners() {
        $(this.gridStackSelector).off();
        $(this.gridStackItemsSelector).off();
    }

    /**
     * Get the all widget new properties by grid stack item resize stop and drag stop event
     */
    @Debounce()
    getAllItemData() {
        const result: GridStackItemsValueChange = {items: []};

        for (const element of Array.from(document.querySelectorAll(this.gridStackItemsSelector)) as HTMLElement[]) {
            const widgetID = element.dataset.id.replace('widget-', '');
            const width = Number(element.dataset.gsWidth);
            const height = Number(element.dataset.gsHeight);
            const x = Number(element.dataset.gsX);
            const y = Number(element.dataset.gsY);

            result.items.push({
                widgetID: widgetID,
                width: width,
                height: height,
                x: x,
                y: y
            });
        }

        this.emitValueChange(result);
    }

    /**
     * Get the current widget new properties by grid stack item resize stop and drag stop event
     * @param event
     * @param ui
     */
    @Debounce()
    private gridStackItemResizeStop(event, ui) {
        const eventElement = ui.element ? ui.element[0] : ui.helper[0];
        const widgetID = eventElement.dataset.id.replace('widget-', '');
        const element: HTMLElement = document.querySelector(`[data-id*='widget-${widgetID}']`);
        const width = Number(element.dataset.gsWidth);
        const height = Number(element.dataset.gsHeight);
        const x = Number(element.dataset.gsX);
        const y = Number(element.dataset.gsY);

        this.emitValueChange({
            widgetID: widgetID,
            width: width,
            height: height,
            x: x,
            y: y
        });
    }

    /**
     * Emit value change event
     * @param data
     */
    private emitValueChange(data: GridStackItemValueChange | GridStackItemsValueChange) {
        if (!!data) {
            this.valueChange.emit(data);
        }
    }
}
