import {Injectable} from '@angular/core';
import {
    AUTOCOMPLETE_LIST_LIMIT,
    AUTOCOMPLETE_TYPE_HASHTAG,
    AUTOCOMPLETE_TYPE_MENTION
} from '~/src/app/modules/posts/autocomplete.constants';
import {IAutocompletePlatform} from '~/src/app/modules/posts/autocomplete.interface';
import {LanguageService} from '~/src/app/services/language.service';
import {HashtagService} from '~/src/app/modules/hashtag/hashtag.service';
import {HashtagCollectionService} from '~/src/app/modules/hashtag/hashtag-collection.service';
import {IHashtagList} from '~/src/app/modules/hashtag/hashtag.interface';
import {HashtagModel} from '~/src/app/modules/hashtag/hashtag.model';
import {HashtagCollectionModel} from '~/src/app/modules/hashtag/hashtag-collection.model';
import {IHashtagCollectionList} from '~/src/app/modules/hashtag/hashtag-collection.interface';
import {MentionService} from '~/src/app/modules/mention/mention.service';
import {IMentionListParams} from '~/src/app/modules/mention/mention.interface';
import {SocialSiteInterface} from '~/src/app/components/social-site-select/social-site-select.component';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {SocialMediaPlatforms} from '~/src/app/modules/social-media-post/social-media-platforms-config';
import Utils from '~/src/app/core/utils';
import {FALLBACK_IMAGE} from '~/src/app/configs/configs';

@Injectable({providedIn: 'root'})
export class AutocompleteService {

    constructor(
        private hashtagService: HashtagService,
        private hashtagCollectionService: HashtagCollectionService,
        private mentionService: MentionService
    ) {
    }

    /**
     * @param {string} searchValue
     * @param {string | string[]} platforms
     * @param {SocialSiteInterface[]} socialSites
     * @return {Promise<IAutocompletePlatform[]>}
     */
    getMentions(searchValue: string, platforms: string | string[], socialSites?: SocialSiteInterface[]): Promise<IAutocompletePlatform[]> {
        const platformResults = {};
        const result: IAutocompletePlatform[] = [];
        let requests = [];

        if (!platforms || platforms === 'default') {
            platforms = Utils.lodash.cloneDeep(SocialMediaPlatforms).filter(_platform => _platform.enableMention).map(_platform => _platform.platform);
        } else {
            platforms = [platforms] as string[];
        }

        requests = (platforms as string[]).map(
            _platform => {
                let siteID = null;

                if (socialSites && socialSites.length) {
                    socialSites.forEach(site => {
                        if (site.socialType === _platform) {
                            siteID = site.siteID;
                        }
                    });
                }

                const params: IMentionListParams = {
                    socialType: _platform,
                    searchValue,
                    limit: AUTOCOMPLETE_LIST_LIMIT,
                    socialSiteID: siteID
                };

                return this.mentionService.getList(params)
                    .then(response => {
                        platformResults[_platform] = platformResults[_platform] || [];
                        platformResults[_platform].push(
                            ...(response.slice(0, AUTOCOMPLETE_LIST_LIMIT)).map(_mention => ({
                                id: _mention.id,
                                name: _mention.name,
                                platform: _platform,
                                link: _mention.link,
                                image: _mention.image || null
                            }))
                        );

                        if (!platformResults[_platform].length) {
                            platformResults[_platform].push({message: 'No mentions found. Please, modify your search params.', type: AUTOCOMPLETE_TYPE_MENTION});
                        }

                        return Promise.resolve(response);
                    })
                    .catch((error) => {
                        platformResults[_platform] = platformResults[_platform] || [];

                        const message = FormValidationService.readError(error).message || null;
                        if (message) {
                            platformResults[_platform].push({message, type: AUTOCOMPLETE_TYPE_MENTION});
                            return Promise.resolve(platformResults);
                        }

                        return Promise.reject(error);
                    });
            }
        );

        return Promise.all(requests).then(() => {
            for (const platform in platformResults) {
                const items = platformResults[platform];
                let index = -1;

                if (items.length) {
                    result.push({
                        platform,
                        iconClass: this._getPlatformConfig(platform).iconClass + ` color-${platform}`,
                        type: AUTOCOMPLETE_TYPE_MENTION,
                        name: LanguageService.getLine(this._getPlatformConfig(platform).tabNameKey),
                        items: items.map(item => {
                            const image = item.image
                                ? item.image
                                : FALLBACK_IMAGE;

                            if (item.message) {
                                return item;
                            }

                            index++;
                            return {
                                id: item.id,
                                name: item.name,
                                platform: platform,
                                link: item.link,
                                image,
                                index,
                                type: AUTOCOMPLETE_TYPE_MENTION,
                                uid: `${platform}:${item.id}`
                            };
                        })
                    });
                }
            }

            return Promise.resolve(Utils.orderBy<IAutocompletePlatform>(result,  ['platform']));
        });
    }

    /**
     * @param {string} tag
     * @param {string | string[]} platforms
     * @param {string[] | number[]} organizationIDs
     * @return {Promise<IAutocompletePlatform[]>}
     */
    getHashTags(tag: string, platforms: string | string[] = null, organizationIDs: string[] | number[] = []): Promise<IAutocompletePlatform[]> {
        const requests = [];

        requests.push(this.hashtagService.getList({
            tag,
            organizationID: organizationIDs,
            limit: AUTOCOMPLETE_LIST_LIMIT
        }));
        requests.push(this.hashtagCollectionService.getList({tags: tag, name: tag, limit: AUTOCOMPLETE_LIST_LIMIT}));

        return Promise.all(requests).then(responses => {
            const [hashtags, hashtagCollections] = responses;
            let tagIndex = -1;
            let collectionIndex = -1;

            const result: IAutocompletePlatform[] = [];

            if (hashtags.tags && hashtags.tags.length > 0) {
                result.push({
                    platform: 'hashtag',
                    name: 'Hashtags',
                    iconClass: 'fa fa-hashtag',
                    type: AUTOCOMPLETE_TYPE_HASHTAG,
                    items: Utils.lodash.sortBy((hashtags as IHashtagList<HashtagModel>).tags.map(_tag => {
                        return {
                            type: AUTOCOMPLETE_TYPE_HASHTAG,
                            id: _tag.tagID,
                            name: _tag.tag,
                            index: ++tagIndex,
                            platform: 'hashtag',
                            uid: `hashtag:${_tag.tagID}`
                        };
                    }), item => item.name.toLowerCase())
                });
            } else {
                result.push({
                    platform: 'hashtag',
                    name: 'Hashtags',
                    iconClass: 'fa fa-hashtag',
                    type: AUTOCOMPLETE_TYPE_HASHTAG,
                    items: [
                        {   
                            type: AUTOCOMPLETE_TYPE_HASHTAG,
                            id: 1,
                            name: "No hashtags found.",
                            index: ++tagIndex,
                            platform: 'hashtag',
                            message: "No hashtags found."
                        }
                    ]
                });
            }

            if (hashtagCollections.collections && hashtagCollections.collections.length > 0) {
                result.push({
                    platform: 'hashtagCollection',
                    name: 'Hashtag Collections',
                    iconClass: 'fa fa-hashtag',
                    type: AUTOCOMPLETE_TYPE_HASHTAG,
                    items: Utils.lodash.sortBy((hashtagCollections as IHashtagCollectionList<HashtagCollectionModel>).collections.map(collection => {
                        return {
                            type: AUTOCOMPLETE_TYPE_HASHTAG,
                            id: collection.collectionID,
                            name: collection.name,
                            index: ++collectionIndex,
                            platform: 'hashtagCollection',
                            uid: `hashtagCollection:${collection.collectionID}`,
                            subItems: collection.tags.map(_tag => {
                                return {
                                    type: AUTOCOMPLETE_TYPE_HASHTAG,
                                    id: collectionIndex,
                                    name: _tag,
                                    index: collectionIndex,
                                    platform: 'hashtagCollection',
                                    uid: `hashtagCollection:${collectionIndex}`
                                };
                            })
                        };
                    }), item => item.name.toLowerCase())
                });
            } else {
                result.push({
                    platform: 'hashtagCollection',
                    name: 'Hashtag Collections',
                    iconClass: 'fa fa-hashtag',
                    type: AUTOCOMPLETE_TYPE_HASHTAG,
                    items: [
                        {
                            type: AUTOCOMPLETE_TYPE_HASHTAG,
                            id: 1,
                            name: "No hashtag collections found.",
                            index: ++collectionIndex,
                            platform: 'hashtagCollection',
                            message: "No hashtag collections found."
                        }
                    ]
                });
            }

            return Promise.resolve(result);
        });
    }

    /**
     * @param {string} platform
     * @return {SocialMediaPlatform}
     * @private
     */
    _getPlatformConfig(platform: string) {
        return SocialMediaPlatforms.find(_platform => _platform.platform === platform) || null;
    }
}
