export const PARTNER_PERMISSION_RESOURCES = 'resourcesTabAllowed';
export const PARTNER_PERMISSION_AUTOFEED_MODULE = 'autoFeedModuleAllowed';
export const PARTNER_PERMISSION_LINK_SHORTENING = 'linkShorteningAllowed';
export const PARTNER_PERMISSION_ANALYTICS_MODULE = 'analyticsModuleAllowed';
export const PARTNER_PERMISSION_APPROVAL_WORKFLOW = 'approvalWorkFlowAllowed';
export const PARTNER_PERMISSION_MEDIA_LIBRARY_TAGS = 'mediaLibraryTaggingAllowed';
export const PARTNER_PERMISSION_MEDIA_LIBRARY_CATEGORIES = 'mediaLibraryCategoryAllowed';
export const PARTNER_PERMISSION_DASHBOARD_PERSONALIZATION = 'dashboardPersonalisationAllowed';
export const PARTNER_PERMISSION_RSS_MODULE = 'rssModuleAllowed';
export const PARTNER_PERMISSION_ROLE_CREATE = 'roleCreateAllowed';
export const PARTNER_PERMISSION_ROLE_EDIT = 'roleEditAllowed';
export const PARTNER_PERMISSION_ACCESS_TO_GENERIC_TEMPLATES = 'accessToGenericTemplate';
export const PARTNER_PERMISSION_ACCESS_TO_GENERIC_MEDIAS = 'accessToGenericMedia';

export const PARTNER_MAX_LIMIT_USERS = 'userNumber';
export const PARTNER_MAX_LIMIT_GENERIC_TEMPLATE = 'maxPostFromTemplate';
export const PARTNER_MAX_LIMIT_GENERIC_MEDIA = 'maxMediaPerUnit';
export const PARTNER_MAX_LIMIT_POST_FROM_RSS = 'maxPostFromRss';
export const PARTNER_MAX_LIMIT_RSS_URL = 'rssUrlNumber';
export const PARTNER_MAX_LIMIT_SOCIAL_SITES = 'socialMediaAccountNumber';
export const PARTNER_MAX_LIMIT_ORGANIZATION = 'organizationNumber';
export const PARTNER_PERIOD_POST_TEMPLATE = 'maxPostFromTemplateUnit';
export const PARTNER_PERIOD_MEDIA = 'maxMediaUnit';
export const PARTNER_PERIOD_RSS_FEED = 'maxPostFromRssUnit';

export const PARTNER_BOOLEAN_PERMISSIONS = [
    PARTNER_PERMISSION_RESOURCES,
    PARTNER_PERMISSION_AUTOFEED_MODULE,
    PARTNER_PERMISSION_LINK_SHORTENING,
    PARTNER_PERMISSION_ANALYTICS_MODULE,
    PARTNER_PERMISSION_APPROVAL_WORKFLOW,
    PARTNER_PERMISSION_MEDIA_LIBRARY_TAGS,
    PARTNER_PERMISSION_MEDIA_LIBRARY_CATEGORIES,
    PARTNER_PERMISSION_DASHBOARD_PERSONALIZATION,
    PARTNER_PERMISSION_RSS_MODULE,
    PARTNER_PERMISSION_ROLE_CREATE,
    PARTNER_PERMISSION_ROLE_EDIT,
    PARTNER_PERMISSION_ACCESS_TO_GENERIC_TEMPLATES,
    // PARTNER_PERMISSION_ACCESS_TO_GENERIC_MEDIAS,
];
