import {Directive, HostListener, Input, OnInit} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {MatSelect, MatSelectChange} from '@angular/material/select';

type Actions = 'selectAll' | 'unSelectAll';

@Directive({
    selector: '[smdSelectAction]'
})
export class SelectActionDirective implements OnInit {
    @Input() action: Actions;
    @Input() selectElement: NgSelectComponent;
    @Input() matSelectElement: MatSelect;
    readonly methods = {
        selectAll: 'select',
        unSelectAll: 'unselect'
    };

    constructor() {}

    ngOnInit(): void {}

    @HostListener('click', ['$event']) click(event: MouseEvent) {
        event.preventDefault();

        if (this.matSelectElement) {
            let allOption = [];

            if (this.action === 'selectAll') {
                allOption = this.matSelectElement.options
                    .filter(item => !item.disabled)
                    .map(item => item.value);
            }

            if (this.matSelectElement.ngControl) {
                this.matSelectElement.ngControl.control.setValue(allOption, {emitEvent: true});
            }

            this.matSelectElement.writeValue(allOption);
            this.matSelectElement.selectionChange.emit(new MatSelectChange(this.matSelectElement, allOption));
            this.matSelectElement.valueChange.emit(allOption);
        }

        if (this.selectElement) {
            this.selectElement.itemsList.items.forEach(item => {
                if (!item['disabled']) {
                    this.selectElement[this.methods[this.action]](item);
                }
            });
        }
    }
}
