import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DefaultRoleManagerDialogData} from '~/src/app/modules/administration/default-roles/default-role-manager/default-role-manager.interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DefaultRoleManagerConfig} from '~/src/app/modules/administration/default-roles/default-role-manager/default-role-manager.config';
import Utils from '~/src/app/core/utils';
import {LanguageService} from '~/src/app/services/language.service';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {DefaultRoleService} from '~/src/app/modules/administration/default-roles/default-role.service';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {ExportResourceService} from '~/src/app/core/export-resource.service';
import {Resource} from '~/src/app/core/export-resource';
import {ArrayColumnArrayResult} from '~/src/app/core/helper/array-support.d';
import {ArraySupport} from '~/src/app/core/helper/array-support';

@Component({
    selector: 'smd-default-role-manager',
    templateUrl: './default-role-manager.component.html',
    styleUrls: ['./default-role-manager.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers,
        DefaultRoleService,
        ExportResourceService
    ]
})
export class DefaultRoleManagerComponent {
    statusOnlyEdit = true;
    roleControlNames = DefaultRoleManagerConfig.getDefaultRoleControlNames();
    roleFormGroup = new FormGroup({
        [this.roleControlNames.DefaultRoleID]: new FormControl(
            Utils.lodash.get(this.dialogData.defaultRole, 'defaultRoleID', null), [Validators.required]
        ),
        [this.roleControlNames.Name]: new FormControl(
            Utils.lodash.get(this.dialogData.defaultRole, 'name', null), [Validators.required]
        ),
        [this.roleControlNames.Description]: new FormControl(
            Utils.lodash.get(this.dialogData.defaultRole, 'description', null), [Validators.required]
        ),
        [this.roleControlNames.IsAdmin]: new FormControl(
            Utils.lodash.get(this.dialogData.defaultRole, 'admin', false)
        ),
        [this.roleControlNames.Resources]: new FormControl([], [Validators.required]),
        [this.roleControlNames.Status]: new FormControl(
            Utils.lodash.get(this.dialogData.defaultRole, 'status', null),
            (this.statusOnlyEdit) ? [] : [Validators.required]
        ),
    });
    isEditMode: boolean = !!this.dialogData.defaultRole;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: DefaultRoleManagerDialogData,
        public dialogRef: MatDialogRef<DefaultRoleManagerComponent>,
        public language: LanguageService,
        public formHelpers: FormHelpersService,
        public componentHelpers: ComponentHelpers,
        public exportResource: ExportResourceService,
        private roleService: DefaultRoleService,
        private openModal: OpenModalService
    ) {

        // get resources
        this._getResources();

        // init default role edit mode
        if (this.isEditMode) {
            this._initEditMode();
        }

        // init form management
        this.formHelpers.formInit(this.roleFormGroup);
    }

    /**
     * Create default role
     *
     * @param {MouseEvent} event
     */
    createRole(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.roleFormGroup.valid) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            this.roleService.createDefaultRole(this._getDefaultRoleData()).then(response => {
                loader.afterClosed().subscribe(() => {
                    this.componentHelpers.apiAction('success', response, {
                        messageKey: 'defaultRole.manager.create.success'
                    }).then(() => {
                        this._addAfterSuccessCreate();
                        this.dialogRef.close();
                    });
                });

                loader.close();
            }).catch(error => {
                this.formHelpers.validateForm(this.roleFormGroup, FormValidationService.readError(error).formMessages);

                loader.afterClosed().subscribe(() => {
                    this.componentHelpers.apiAction('failed', error, {
                        messageKey: 'defaultRole.manager.create.error'
                    });
                });

                loader.close();
            });
        } else {
            this.formHelpers.validateForm(this.roleFormGroup);
        }
    }

    /**
     * Edit default role
     *
     * @param {MouseEvent} event
     */
    editRole(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.roleFormGroup.valid) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            this.roleService.editDefaultRole(this.dialogData.defaultRole.defaultRoleID, this._getDefaultRoleData()).then(response => {
                loader.afterClosed().subscribe(() => {
                    this.componentHelpers.apiAction('success', response, {
                        messageKey: 'defaultRole.manager.edit.success'
                    }).then(() => {
                        this._addAfterSuccessEdit();
                        this.dialogRef.close();
                    });
                });

                loader.close();
            }).catch(error => {
                this.formHelpers.validateForm(this.roleFormGroup, FormValidationService.readError(error).formMessages);

                loader.afterClosed().subscribe(() => {
                    this.componentHelpers.apiAction('failed', error, {
                        messageKey: 'defaultRole.manager.edit.error'
                    });
                });

                loader.close();
            });
        } else {
            this.formHelpers.validateForm(this.roleFormGroup);
        }
    }

    /**
     * Get resources
     *
     * @private
     */
    private _getResources(): void {
        this.exportResource.getResources().then(() => {
            if (this.isEditMode) {
                this._setResourcesControlValue();
            }
        });
    }

    /**
     * Set resources form control value
     *
     * @private
     */
    private _setResourcesControlValue(): void {
        const resourceIDs = ArraySupport.arrayColumn<Resource>(
            this.exportResource.resourceCollection.getValue(),
            'resourceID'
        ) as ArrayColumnArrayResult<Resource>;
        let defaultRoleResources: string[] = Utils.lodash.get(this.dialogData.defaultRole, 'resources', []);

        defaultRoleResources = defaultRoleResources.filter(resourceID => resourceIDs.includes(resourceID));

        this.roleFormGroup.get(this.roleControlNames.Resources).setValue(
            defaultRoleResources
        );
    }

    /**
     * Init default role edit mode
     *
     * @private
     */
    private _initEditMode(): void {
        this.roleFormGroup.get(this.roleControlNames.Status).setValidators([Validators.required]);
        this.roleFormGroup.get(this.roleControlNames.Status).enable();
        this.roleFormGroup.get(this.roleControlNames.Status).updateValueAndValidity();
    }

    /**
     * Get default role form data
     *
     * @returns {any}
     * @private
     */
    private _getDefaultRoleData() {
        return {
            ...this.roleFormGroup.getRawValue()
        };
    }

    /**
     * Call after success create callback
     *
     * @private
     */
    private _addAfterSuccessCreate() {
        if (!!this.dialogData.afterSuccessCreate) {
            this.dialogRef.afterClosed().subscribe(() => this.dialogData.afterSuccessCreate());
        }
    }

    /**
     * Call after success edit callback
     *
     * @private
     */
    private _addAfterSuccessEdit() {
        if (!!this.dialogData.afterSuccessEdit) {
            this.dialogRef.afterClosed().subscribe(() => this.dialogData.afterSuccessEdit());
        }
    }
}
