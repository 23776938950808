import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreloaderComponent} from '~/src/app/components/preloader/preloader.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PreloaderComponent
    ],
    exports: [
        PreloaderComponent
    ]
})
export class PreloaderModule {}
