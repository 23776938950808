import { Injectable } from "@angular/core";
import { ModelAbstract } from "../../services/model.abstract";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "../../services/helpers";
import { Token } from "../../services/token";
import { MatDialog } from "@angular/material/dialog";
import Utils from "~/src/app/core/utils";
import { BulkTemplateUploadSampleResponse } from "~/src/app/modules/posts/post-template/post-template.model";

@Injectable({ providedIn: "root" })
export class TemplateActionsService extends ModelAbstract {
    constructor(public http: HttpClient, public dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = "/api/post/template";
    }

    /**
     * Import templates from zip file
     * @param data
     */
    importTemplates(data: any) {
        return this.http
            .post(
                this.apiLink + "/import",
                Utils.obj2fd(data),
                Helpers.getBaseHttpHeaders(Token.getToken())
            )
            .toPromise();
    }

    /**
     * Download sample file for template
     *
     * @param {"general" | "twitter", | "instagram"} platform
     * @return {Promise<BulkTemplateUploadSampleResponse>}
     */
    downloadSample(
        platform: "instagram" | "general" | "twitter" | "gmb" = "general"
    ) {
        const fileNames = {
            general: "template_sample.zip",
            twitter: "template_twitter_sample.zip",
            instagram: "template_instagram_sample.zip",
            gmb: "template_gmb_sample.zip",
        };

        return this.http
            .get<BulkTemplateUploadSampleResponse>(
                this.apiLink + "/download/sample",
                {
                    params: {
                        fileName: fileNames[platform],
                    },
                    ...Helpers.getBaseHttpHeaders(Token.getToken()),
                }
            )
            .toPromise()
            .then((response) => {
                if (
                    !response.file ||
                    !response.file.content ||
                    !response.file.mime ||
                    !response.file.name
                ) {
                    throw new Error("Response has no file data!");
                }

                Utils.generateFile(
                    response.file.content,
                    response.file.mime,
                    response.file.name
                );

                return response;
            });
    }

    deleteTemplates(templateIDs: number[]) {
        return this.deleteItems({ templateIDs: JSON.stringify(templateIDs) });
    }

    /**
     * Delete items by IDs
     *
     * @param {number[]} IDs
     * @return {Promise<any>}
     */
    deleteItems(IDs: object): Promise<any> {
        return this.http
            .post(
                this.apiLink + "/delete",
                Helpers.objectToFormData(IDs),
                Helpers.getBaseHttpHeaders(Token.getToken())
            )
            .toPromise();
    }

    assignTemplatesToFolders(data: Array<{
        templateID: number, 
        foldersToAdd: Array<number>, 
        foldersToRemove: Array<number>
    }>) {
        const dataToSend = { data: JSON.stringify(data) };

        return this.http
            .post(
                this.apiLink + "/assign",
                Utils.obj2fd(dataToSend),
                Helpers.getBaseHttpHeaders(Token.getToken())
            )
            .toPromise();
    }
}
