import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LanguageService} from '~/src/app/services/language.service';
import html2canvas, {Options} from 'html2canvas';
import * as jsPDF from 'jspdf';
import {NotifyService} from '~/src/app/services/notify.service';
import {BASE_DATETIME_FORMAT, VIEW_BASE_DATE_FORMAT} from '~/src/app/configs/configs';
import Utils from '~/src/app/core/utils';

@Component({
    selector: 'smd-export-dashboard-modal',
    templateUrl: './export-dashboard-modal.component.html',
    styleUrls: ['./export-dashboard-modal.component.scss']
})
export class ExportDashboardModalComponent implements OnInit {

    @ViewChild('canvas', {static: true}) canvas: ElementRef;
    @ViewChild('downloadLink', {static: true}) downloadLink: ElementRef;
    mode = '';
    output = '';
    screen;
    requestPending = false;
    interval = null;

    constructor(
        public dialogRef: MatDialogRef<ExportDashboardModalComponent>,
        public languageService: LanguageService,
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {

    }

    ngOnInit() {
        this.screen = this.dialogData.screen;
        this.interval = this.dialogData.interval;
    }

    setOutput(output, event) {
        this.output = output;
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    download() {

        let name = 'dashboard_export_';
        this.requestPending = true;

        if (this.dialogData.dashboardname) {
            name += this.slugify(this.dialogData.dashboardname);
        }

        name += '_' + Date.now();

        const element: HTMLElement = this.screen.nativeElement;
        const elementID = element.getAttribute('id');
        const extension = this.output.split('/')[1];

        const html2canvasOptions: Partial<Options> = {
            imageTimeout: 15000,
            useCORS: true,

            // FIX gray background parts this config
            // scale: 2,

            // set to for debug
            logging: false,
            removeContainer: false,
            ignoreElements: (HTMLElement) => {
                return HTMLElement.classList.contains('highcharts-credits');
            },
            onclone: (clonedDocument) => {
                const partnerLogoContainer: HTMLElement = document.createElement('div');
                const partnerLogo = clonedDocument.querySelector('.sidebar-head-image__partner-logo');

                const clonedElement = clonedDocument.getElementById(elementID);
                const intervalTag = document.createElement('h3');
                const toDate = Utils.moment(this.interval.toDate).format(VIEW_BASE_DATE_FORMAT);
                const fromDate = Utils.moment(this.interval.fromDate).format(VIEW_BASE_DATE_FORMAT);

                intervalTag.innerHTML = '<b>' + fromDate + '</b> - <b>' + toDate + '</b>';
                intervalTag.classList.add('text-center', 'm-b-15');

                partnerLogo.classList.add('ml-auto', 'mr-auto');

                partnerLogoContainer.appendChild(partnerLogo);
                partnerLogoContainer.classList.add('w-100', 'text-center', 'm-b-15');

                /**
                 * Add iterval data for element before render canvas
                 */
                clonedElement.insertBefore(intervalTag, clonedElement.firstChild);

                /**
                 * Add partner logo for element before render canvas
                 */
                clonedElement.insertBefore(partnerLogoContainer, clonedElement.firstChild);
            }
        };

        const createimage = (filename) => {
            html2canvas(element, html2canvasOptions).then(canvas => {
                this.canvas.nativeElement.src = canvas.toDataURL();
                this.downloadLink.nativeElement.href = canvas.toDataURL(this.output);
                this.downloadLink.nativeElement.download = filename;
                this.downloadLink.nativeElement.click();
                this.requestPending = false;
            }).catch(error => {
                this.requestPending = false;
                NotifyService.error(this.languageService.getLine('analytics.export.error.title'), '');
            });
        };

        const createpdf = (filename) => {
            html2canvas(element, html2canvasOptions).then((canvas) => {
                const orientation = (canvas.width < canvas.height) ? 'portrait' : 'landscape';

                const doc = new jsPDF({
                    orientation: orientation,
                    unit: 'px',
                    format: [canvas.width, canvas.height],
                });

                const width = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();

                doc.addImage(canvas.toDataURL('image/jpeg', 1), 'JPEG', 0, 0, width, height);
                doc.save(filename);
                this.requestPending = false;
            }).catch(error => {
                this.requestPending = false;

                NotifyService.error(this.languageService.getLine('analytics.export.error.title'), '');
            });
        };

        try {
            if (this.output !== 'image/pdf') {
                createimage(name + '.' + extension);
            } else {
                createpdf(name + '.' + extension);
            }
        } catch (e) {
            console.log(e);
        }

    }

    slugify(input: string) {
        return input.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    get downloadIsEnabled() {
        return this.output === '';
    }

}
