import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LanguageService } from "~/src/app/services/language.service";
import { LoggedUser } from "~/src/app/services/logged-user";
import { RoleService } from "~/src/app/components/organization-select/role.service";
import { NotifyService } from "~/src/app/services/notify.service";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { cloneDeep, forEach, intersection, isArray, uniq } from "lodash";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";

enum ModifyRoleDialogModes {
    Create = "createRole",
    Edit = "editRole",
    Bulk = "bulkRole",
    Clone = "cloneRole",
    View = "viewRole",
}

@Component({
    selector: "smd-modify-role-dialog",
    templateUrl: "./modify-role-dialog.component.html",
    styleUrls: ["./modify-role-dialog.component.scss"],
})
export class ModifyRoleDialogComponent implements OnInit, AfterViewInit {
    @ViewChild("roleForm", { static: true })
    roleForm: ElementRef<HTMLFormElement>;

    resources = [];
    selectedRoleResources = [];
    selectedRoleIDs = [];
    currentUser: object = {};
    organizations = [];
    isReadOnlyMode = false;
    message: object = {};
    newRoleForm: FormGroup;
    mode: ModifyRoleDialogModes = ModifyRoleDialogModes.Create;

    constructor(
        private roleService: RoleService,
        private openModal: OpenModalService,
        public dialogRef: MatDialogRef<ModifyRoleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        const newRoleForm = {
            name: new FormControl("", [Validators.required]),
            description: new FormControl("", [Validators.required]),
            roleResources: new FormControl({ value: "", disabled: true }, [
                Validators.required,
            ]),
            organizationID: new FormControl({ value: "", disabled: true }, [
                Validators.required,
            ]),
            roleID: new FormControl("", [Validators.required]),
            selectedRoleIDs: new FormControl(""),
            isPartnerRole: new FormControl(false),
        };

        if (this.data.bulk) {
            delete newRoleForm.roleID;
            delete newRoleForm.name;
            delete newRoleForm.description;
            delete newRoleForm.organizationID;
            this.mode = ModifyRoleDialogModes.Bulk;
            newRoleForm.selectedRoleIDs.setValue(
                this.data.roles.map((role) => role.roleID)
            );
        } else if (this.data.edit) {
            this.mode = ModifyRoleDialogModes.Edit;
            this.isReadOnlyMode =
                this.data.role.isDefault && !this.data.role.isPartnerRole;
        } else if (this.data.clone) {
            delete newRoleForm.roleID;
            this.mode = ModifyRoleDialogModes.Clone;
        } else {
            delete newRoleForm.roleID;
            this.mode = ModifyRoleDialogModes.Create;
        }

        this.currentUser = LoggedUser.getUser();
        this.newRoleForm = new FormGroup(newRoleForm);
    }

    get ModifyRoleDialogModes() {
        return ModifyRoleDialogModes;
    }

    get languageService() {
        return LanguageService;
    }

    ngOnInit() {
        if (this.data.edit) {
            this.mode = ModifyRoleDialogModes.Edit;
        } else if (this.data.bulk) {
            this.mode = ModifyRoleDialogModes.Bulk;
        } else if (this.data.clone) {
            this.mode = ModifyRoleDialogModes.Clone;
        }
        this.getOrganizations();
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.initData();
        });
    }

    public onFormSubmitNewRole(event) {
        event.preventDefault();
        event.stopPropagation();

        let successMessage;
        let errorMessage;

        if (this.mode === this.ModifyRoleDialogModes.Bulk) {
            return this.onFormSubmitNewRoleBulk(event);
        }

        const newRoleForm = this.newRoleForm;
        this.message = {};

        if (newRoleForm.valid) {
            const endPoint =
                this.mode === ModifyRoleDialogModes.Create ||
                this.mode === ModifyRoleDialogModes.Clone
                    ? "createRole"
                    : "setRoleData";

            switch (this.mode) {
                case ModifyRoleDialogModes.Clone:
                case ModifyRoleDialogModes.Create:
                    successMessage = this.languageService.getLine(
                        "access.management.role.new"
                    );
                    errorMessage = this.languageService.getLine(
                        "access.management.role.new.error"
                    );
                    break;
                case ModifyRoleDialogModes.Edit:
                    successMessage = this.languageService.getLine(
                        "access.management.role.modify"
                    );
                    errorMessage = this.languageService.getLine(
                        "access.management.role.modify.error"
                    );
                    break;
                default:
                    successMessage = this.languageService.getLine(
                        "access.management.roles.modify"
                    );
                    errorMessage = this.languageService.getLine(
                        "access.management.roles.modify.error"
                    );
                    break;
            }

            const data = newRoleForm.getRawValue();
            for (let i = 0; i < data["roleResources"].length; i++) {
                data["resourceID[" + i + "]"] = data["roleResources"][i];
            }
            for (let i = 0; i < data["organizationID"].length; i++) {
                data["organizationID[" + i + "]"] = data["organizationID"][i];
            }

            delete data["roleResources"];
            if (this.mode !== ModifyRoleDialogModes.Edit) {
                delete data["organizationID"];
            }
            if (this.data.type === "partner") {
                data["isPartnerRole"] = true;
            }
            delete data["selectedRoleIDs"];

            const loader = this.openModal.loader(DialogLoaderComponent);
            this.roleService[endPoint](data).subscribe(
                (response) => {
                    this.dialogRef.afterClosed().subscribe(() => {
                        this.data.reloadGrid();
                    });

                    loader.afterClosed().subscribe(() => {
                        this.dialogRef.close();
                    });

                    NotifyService.success(successMessage, response["data"]);

                    loader.close();
                },
                (error) => {
                    NotifyService.error(
                        errorMessage,
                        error["error"]["error"]["message"]
                    );
                    FormValidationService.setFormControlsIncorrect(
                        error,
                        this.newRoleForm,
                        this.message
                    );

                    loader.close();
                }
            );
        } else {
            this.message = FormValidationService.getMessages(
                newRoleForm.controls
            );

            newRoleForm.markAllAsTouched();
        }

        return false;
    }
    public validateInput(): void {
        if (!this.newRoleForm.valid) {
            this.message = FormValidationService.getMessages(
                this.newRoleForm.controls
            );
        }
    }
    public getOrganizations() {
        if (typeof this.currentUser["organizations"] !== "undefined") {
            for (let i = 0; i < this.currentUser["organizations"].length; i++) {
                this.organizations = this.currentUser["organizations"];
            }
        }
    }
    public organizationChangeRoles(
        selectedOrganizationId: number | Array<number>
    ) {
        const orgIds = isArray(selectedOrganizationId)
            ? selectedOrganizationId
            : [selectedOrganizationId];

        if (typeof this.currentUser["resources"] !== "undefined") {
            this.newRoleForm.controls["roleResources"].enable();

            this.resources = this.getResourcesByOrganizationIds(orgIds);
        }
    }
    public deselectAllResource() {
        this.newRoleForm.get("roleResources").setValue([]);
    }
    public selectAllResource() {
        this.newRoleForm
            .get("roleResources")
            .setValue(this.resources.map((resource) => resource.resourceID));
    }

    private initData() {
        if (this.mode === ModifyRoleDialogModes.Edit) {
            let organizationID = parseInt(
                this.data["role"]["organizationID"],
                10
            );

            if (this.data.type === "partner") {
                organizationID =
                    LoggedUser.getMainOrganization().organizationID;
            }

            this.resources = this.getResourcesByOrganizationIds([
                organizationID,
            ]);
            this.newRoleForm.controls["roleResources"].enable();

            if (this.data.type && this.data.type !== "partner") {
                this.newRoleForm.controls["organizationID"].enable();
                this.newRoleForm.controls["organizationID"].setValue(
                    organizationID
                );
            }
            this.newRoleForm.controls["name"].setValue(
                this.data["role"]["name"]
            );
            this.newRoleForm.controls["description"].setValue(
                this.data["role"]["description"]
            );
            this.newRoleForm.controls["roleID"].setValue(
                this.data["role"]["roleID"]
            );
            this.selectedRoleResources = this.data.role.resources;
        } else if (this.mode === ModifyRoleDialogModes.Clone) {
            let organizationID = parseInt(
                this.data["role"]["organizationID"],
                10
            );

            if (this.data.type === "partner") {
                organizationID =
                    LoggedUser.getMainOrganization().organizationID;
            }

            this.resources = this.getResourcesByOrganizationIds([
                organizationID,
            ]);
            this.newRoleForm.controls["roleResources"].enable();
            if (this.data.type && this.data.type !== "partner") {
                this.newRoleForm.controls["organizationID"].enable();
            }
            this.newRoleForm.controls["name"].setValue(
                this.data["role"]["name"]
            );
            this.newRoleForm.controls["description"].setValue(
                this.data["role"]["description"]
            );
            this.selectedRoleResources = this.data.role.resources;
        } else if (this.mode === ModifyRoleDialogModes.Bulk) {
            let orgIds = uniq(
                this.data.roles.map((role) => parseInt(role.organizationID, 10))
            );

            if (this.data.type === "partner") {
                orgIds = [LoggedUser.getMainOrganization().organizationID];
            }

            const resources = this.getResourcesByOrganizationIds(orgIds);

            this.resources = resources;
            this.selectedRoleResources = resources.map(
                (resource) => resource.resourceID
            );
            this.newRoleForm.controls["roleResources"].enable();
        } else if (this.mode === ModifyRoleDialogModes.Create) {
            if (this.data.type && this.data.type !== "partner") {
                this.newRoleForm.controls["organizationID"].enable();
            } else {
                this.newRoleForm.controls["roleResources"].enable();
                this.resources = this.getResourcesByOrganizationIds([
                    LoggedUser.getMainOrganization().organizationID,
                ]);
            }
        }

        if (this.data.type === "default") {
            this.mode = ModifyRoleDialogModes.View;
        }
    }
    private onFormSubmitNewRoleBulk(event) {
        if (event) {
            event.preventDefault();
        }
        const newRoleForm = this.newRoleForm;
        this.message = {};

        if (newRoleForm.valid) {
            const successMessage = this.languageService.getLine(
                "access.management.roles.modify"
            );
            const errorMessage = this.languageService.getLine(
                "access.management.roles.modify.error"
            );
            const formData = cloneDeep(newRoleForm.getRawValue());

            /**
             * @description it is just for bulk edit
             */
            for (let i = 0; i < formData["roleResources"].length; i++) {
                formData["resourceID[" + i + "]"] =
                    formData["roleResources"][i];
            }

            for (let i = 0; i < this.data.roles.length; i++) {
                formData["roleID[" + i + "]"] = this.data.roles[i].roleID;
            }

            delete formData.roleResources;

            const loader = this.openModal.loader(DialogLoaderComponent);
            this.roleService.setRolesData(formData).subscribe(
                (response) => {
                    this.dialogRef.afterClosed().subscribe(() => {
                        this.data.reloadGrid();
                    });

                    loader.afterClosed().subscribe(() => {
                        this.dialogRef.close();
                    });

                    NotifyService.success(successMessage, response["data"]);

                    loader.close();
                },
                (error) => {
                    NotifyService.error(
                        errorMessage,
                        error["error"]["error"]["message"]
                    );
                    FormValidationService.setFormControlsIncorrect(
                        error,
                        this.newRoleForm,
                        this.message
                    );

                    loader.close();
                }
            );
        } else {
            this.message = FormValidationService.getMessages(
                newRoleForm.controls
            );
        }

        return false;
    }
    private getResourcesByOrganizationIds(orgIds) {
        const allResources = this.data.allResources;
        const resourcesOfOrganozations = [];
        let resourceIDs;
        const resourceObjects = [];

        forEach(orgIds, (orgId) => {
            resourcesOfOrganozations.push(this.currentUser["resources"][orgId]);
        });
        resourceIDs = intersection(...resourcesOfOrganozations);

        forEach(resourceIDs, (resourceID) => {
            const resourceObject = allResources.find(
                (_resourceObject) => _resourceObject.resourceID === resourceID
            );

            if (resourceObject) {
                resourceObjects.push(resourceObject);
            }
        });

        return resourceObjects;
    }
}
