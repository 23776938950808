import {AbstractControl, ValidatorFn} from '@angular/forms';

export function ValidatorEqual(currentControlName: string, requiredControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

        let valid = false;

        if ( control.parent ) {
            valid = control.value === control.parent.controls[requiredControlName].value;
        }

        return !valid ? {'notEqual': true} : null;
    };
}
