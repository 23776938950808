import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';

@Pipe({
    name: 'partnerConfig'
})
export class PartnerConfigPipe implements PipeTransform {
    private partnerConfigLangKeyPrefix = 'partner.info.';

    transform(value: any, type: 'key' | 'value'): any {

        if (type === 'key') {
            return LanguageService.getLine(this.partnerConfigLangKeyPrefix + value);
        }


        if (type === 'value') {
            return this.getValueView(value);
        }

        return value;
    }

    private getValueView(value: any) {
        /**
         * Value is unlimited
         */
        if ([-1, '-1'].indexOf(value) > -1) {
            return LanguageService.getLine('partner.info.unlimited');
        }

        /**
         * Check icon
         */
        if (value === 'yes') {
            return '<i class="mdi mdi-check mdi-18px text-success"></i>';
        }

        /**
         * Close icon
         */
        if (value === 'no') {
            return '<i class="mdi mdi-close mdi-18px text-danger"></i>';
        }

        if (value === '-') {
            return '<i>' + LanguageService.getLine('partner.info.notDefined') + '</i>';
        }

        if (!isNaN(parseInt(value))) {
            return value;
        }

        return LanguageService.getLine('partner.period.unit.' + value);
    }
}
