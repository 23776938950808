import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {PartnersComponent} from './partners/partners.component';
import {SharedModule} from '~/src/app/shared/shared.module';
import {PartnerManagerComponent} from './partners/partner-manager/partner-manager.component';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {MatInputModule} from '@angular/material/input';
import {PackagesComponent} from './packages/packages.component';
import {PackageEditorComponent} from './packages/package-editor/package-editor.component';
import {PartnerConfigFormComponent} from './partners/partner-config-form/partner-config-form.component';
import {DefaultRolesComponent} from './default-roles/default-roles.component';
import {PostTemplateImportComponent} from '~/src/app/modules/administration/post-template-import/post-template-import.component';
import {PartnerChooserComponent} from './partners/partner-chooser/partner-chooser.component';
import {DefaultRoleManagerComponent} from '~/src/app/modules/administration/default-roles/default-role-manager/default-role-manager.component';
import {ResourceSeparatorComponent} from '~/src/app/modules/administration/media-separator/resource-separator.component';
import {SetTemplatesToPartnersComponent} from '~/src/app/modules/administration/set-templates-to-partners/set-templates-to-partners.component';
import {PartnerAnalyticsComponent} from './partners/partner-analytics/partner-analytics.component';
import {PartnerConfigViewsComponent} from './partners/partner-config-views/partner-config-views.component';
import {PartnerSubscriptionComponent} from './partners/partner-subscription/partner-subscription.component';
import {PostTemplateModule} from '~/src/app/modules/posts/post-template/post-template.module';
import { LoggingComponent, LoggingJsonDialogComponent } from './logging/logging.component';

// todo lazy loading
const routes: Routes = [];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        LanguagesModule,
        MatInputModule,
        PostTemplateModule,
    ],
    declarations: [
        PartnersComponent,
        LoggingComponent,
        LoggingJsonDialogComponent,
        PartnerManagerComponent,
        PackagesComponent,
        PackageEditorComponent,
        PartnerConfigFormComponent,
        DefaultRolesComponent,
        DefaultRoleManagerComponent,
        PostTemplateImportComponent,
        PartnerChooserComponent,
        ResourceSeparatorComponent,
        SetTemplatesToPartnersComponent,
        PartnerAnalyticsComponent,
        PartnerConfigViewsComponent,
        PartnerSubscriptionComponent,
    ],
    entryComponents: [
        LoggingJsonDialogComponent,
        PartnerManagerComponent,
        PackageEditorComponent,
        PostTemplateImportComponent,
        PartnerChooserComponent,
        DefaultRoleManagerComponent,
        ResourceSeparatorComponent,
        SetTemplatesToPartnersComponent,
        PartnerAnalyticsComponent,
        PartnerConfigViewsComponent,
        PartnerSubscriptionComponent
    ]
})

export class AdministrationModule {}
