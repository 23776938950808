import {
    PostActionsService,
    PostStatus,
    PostTemplateStatus,
    SetPostStatusRequest,
} from "~/src/app/modules/posts/post-actions.service";
import {
    Category,
    PostTemplateForRequest,
    PostTemplateInterface,
} from "~/src/app/modules/posts/template.interface";
import { ComponentAbstract } from "~/src/app/services/component.abstract";
import { SmdFile, SmdFileInterface } from "~/src/app/services/file.class";
import {
    BASE_DATETIME_FORMAT,
    TemplateTinyMceBaseOptions,
    VIEW_MOMENT_BASE_DATETIME_FORMAT,
} from "../../configs/configs";
import { Helpers } from "~/src/app/services/helpers";
import * as moment from "moment";
import { Observable } from "rxjs";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { MatDialog } from "@angular/material/dialog";
import { PostPreviewComponent } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.component";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { GeoLocationInterface } from "~/src/app/modules/posts/post-actions.interfaces";
import { UsersController } from "~/src/app/modules/users/users/users.component";
import { CommentResponse } from "~/src/app/modules/posts/comments/post-comment/interfaces";
import { LanguageService } from "~/src/app/services/language.service";
import {
    CarouselController,
    CarouselItem,
} from "~/src/app/modules/posts/carousel/carousel.component";
import { CarouselItemResponseInterface } from "~/src/app/modules/posts/carousel/carousel.interface";
import { cloneDeep, forEach, has, includes, values } from "lodash";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { Icons } from "~/src/app/helpers/icons";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import Utils from "~/src/app/core/utils";
import { DateSupport } from "~/src/app/core/helper/date-support";
import { ISocialMediaPost } from "~/src/app/modules/social-media-post/social-media-post.interface";
import {
    MENTION_FORMATTER_IN,
    SocialMediaPlatformConfig,
} from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { AUTOCOMPLETE_TYPE_MENTION } from "~/src/app/modules/posts/autocomplete.constants";
import { IMentionViewItem } from "~/src/app/modules/social-media-post/post-skeleton/mention-list/mention-list";
import { Debug } from "~/src/app/core/debug";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { POST_STATUS_FAILED, POST_STATUS_INACTIVE, POST_STATUS_PARTIALLY_FAILED, POST_STATUS_POSTED, POST_STATUS_UNDER_POST } from "../social-media-post/social-media-post.constant";

export const EMPTY_NEXT_SHARING = "---";

export interface PostInterface {
    activeFrom: string;
    activeFromInput?: string;
    activeTo?: string;
    originActiveFrom?: string;
    headline?: string;
    categories?: Category[];
    mainCopy: string;
    medias?: SmdFileInterface[];
    organizationID?: number;
    organizationIDs?: any[];
    socialSites?: any[];
    socialSiteID?: number;
    socialSiteName?: string;
    socialType?: string;
    parentID?: number;
    postID?: number;
    signature?: string;
    status?: string;
    subHeadline?: string;
    templateID?: number;
    userID?: number;
    calendarDate?: string;
    isRepeat?: boolean;
    isDeleteable?: boolean;
    isEditable?: boolean;
    isCopyable?: boolean;
    isCommentable?: boolean;
    repeats?: string[];
    repeatIndex?: string;
    comments: CommentResponse[];
    feedTargetingOptions?: string;
    expiryDate?: string;
    expiryPeriod?: number;
    type?: string; // for example draft  todo make an eval for it
    isThirdParty?: "yes" | "no";
    linkshare?: any;
    errorMessage?: string;
    approveButtonVisible?: boolean;
    declineButtonVisible?: boolean;
    instagramLocationID?: number;
    instagramLocationName?: string;
    firstComment?: string;
    instantPosted?: boolean;
    timeZone?: string;
    instagramReel?: boolean;
    instagramShareToFeed?: boolean;

    statusChangedBy?: number;
    statusChangedByName?: string;
    statusChangedByRole?: string;
    statusChangedOn?: any;
    previousStatus?: string;

    renderHelper?: {
        localizedStatus: string;
        postSource: string;
        postCreatorName: string;
        postCreatorOrganizationName: string;
        postCreatorRoleName?: string,
        postMessage: string;
        postActiveFrom: string;
        platformData?: {
            gmbIcon?: string;
        }
        socialSitesTooltipFor10: string;
        socialSitesTooltipFor5: string;
    };

    gmbOptions?: any; // im sorry

    statusConfigs?: {
        iconClass?: string,
        statusMessageKey?: string,
        color?: string,  
    };

    categoryConfigs?: {
        categoryName?: string,
        categoryColor?: string,
    };
}

export type DraftType = "post" | "postTemplate" | "autoDraft";

export type StatusType = "post" | "postTemplate";

export class PostActions extends ComponentAbstract {
    /**
     * All the items that are in queue
     * @type {any[]}
     */
    queueItems: any[] = [];
    /**
     * All the items that are posted
     * @type {any[]}
     */
    postedItems: any[] = [];
    /**
     * All the items sorted and groupped by day
     * @type {any[]}
     */
    sortedItems: any[] = [];

    posts: any[] = [];

    nextSharing: string = EMPTY_NEXT_SHARING;
    viewNextSharing: string = EMPTY_NEXT_SHARING;

    responsePostCount = 0;

    _listChange = false;

    socialSites: SocialSiteInterface[] = [];

    constructor(
        public service: PostActionsService,
        public _dialog: MatDialog,
        public users: UsersController,
        public carouselController: CarouselController,
        public openModal: OpenModalService
    ) {
        super();
    }

    /**
     * Post/PostTemplate draft
     *
     * @param {"Post" | "PostTemplate"} type
     * @param {PostTemplateForRequest} data
     * @return {Promise<any>}
     */
    draft(
        type: DraftType = "post",
        data: PostTemplateForRequest,
        ID: number = null
    ): Promise<any> {
        return this.service.draft(type, data, ID).catch((error) => {
            this.service.showErrorAlert(error);

            return error;
        });
    }

    /**
     * Set post(s) status
     *
     * @param {number[]} IDs
     * @param {PostStatus} status
     * @return {Observable<any>}
     */
    setPostsStatus(
        IDs: number[],
        status: PostStatus | PostTemplateStatus,
        type: StatusType
    ): Observable<any> {
        return new Observable<any>((observer) => {
            const data: SetPostStatusRequest = {
                postIDs: JSON.stringify(IDs),
                status: status,
            };

            if (type !== "post") {
                delete data.postIDs;
                data.postTemplateIDs = JSON.stringify(IDs);
            }

            this.service
                .setPostStatus(data, type)
                .then((response) => {
                    observer.next(response);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    setPostStatus(
        id: number,
        status: PostStatus | PostTemplateStatus,
        type: StatusType
    ): Promise<any> {
        let successMsg, failedMsg;

        switch (status) {
            case "approved":
            case "active":
                successMsg = LanguageService.getLine(
                    "post.status.change.approved.success"
                );
                failedMsg = LanguageService.getLine(
                    "post.status.change.approved.error"
                );

                break;

            case "notApproved":
                successMsg = LanguageService.getLine(
                    "post.status.change.rejected.success"
                );
                failedMsg = LanguageService.getLine(
                    "post.status.change.rejected.error"
                );

                break;
        }

        return new Promise((resolve, reject) => {
            const loader = this._dialog.open(DialogLoaderComponent, {
                disableClose: true,
                panelClass: "dialog-loader-modal",
                minWidth: "200vw",
                minHeight: "200vh",
                hasBackdrop: false,
            });

            loader.afterClosed().subscribe(({ isSuccess, result }) => {
                if (isSuccess) {
                    if (has(result, "errors")) {
                        const errors = [];
                        let finalErrorMessage = "";

                        forEach(Object.keys(result.errors), (entityID) => {
                            const error = result.errors[entityID];

                            if (!includes(errors, error)) {
                                errors.push(error);
                                finalErrorMessage += error + "\n";
                            }
                        });

                        this._dialog
                            .open(DialogErrorComponent, {
                                data: {
                                    message: finalErrorMessage,
                                },
                            })
                            .afterClosed()
                            .subscribe(() => {
                                reject(result);
                            });
                    } else {
                        this._dialog
                            .open(DialogSuccessComponent, {
                                data: {
                                    message: successMsg,
                                },
                            })
                            .afterClosed()
                            .subscribe(() => {
                                resolve(result);
                            });
                    }
                } else {
                    this._dialog
                        .open(DialogErrorComponent, {
                            data: {
                                message: failedMsg,
                            },
                        })
                        .afterClosed()
                        .subscribe(() => {
                            reject(result);
                        });
                }
            });

            this.setPostsStatus([id], status, type).subscribe(
                (result) => {
                    loader.close({
                        isSuccess: true,
                        result: result,
                    });
                },
                (error) => {
                    loader.close({
                        isSuccess: false,
                        result: error,
                    });
                }
            );
        });
    }

    /**
     * Instant posting
     *
     * @param {PostTemplateInterface} template
     * @param {boolean} getData
     * @return {void | object}
     */
    instantPosting(
        template: PostTemplateForRequest,
        success: (response: any) => void = (response) => {},
        error: (error: any) => void = (error) => {}
    ): void {
        this.createItem(this.getDataForPost(template, true), success, error);
    }

    /**
     * Post Schedule
     *
     * @param {PostTemplateForRequest} post
     * @param {(response: any) => void} success
     * @param {(error: any) => void} error
     */
    postSchedule(
        post: PostTemplateForRequest,
        success: (response: any) => void = (response) => {},
        error: (error: any) => void = (error) => {}
    ) {
        this.createItem(this.getDataForPost(post), success, error);
    }

    /**
     *
     * Edit post by ID
     *
     * @param {number} id
     * @param {PostTemplateForRequest} item
     * @param {(response: any) => void} success
     * @param {(error: any) => void} error
     */
    editItemWithPost(
        id: number,
        item: PostTemplateForRequest,
        success: (response: any) => void = (response) => {},
        error: (error: any) => void = (error) => {}
    ): void {
        super.editItemWithPost(id, this.getDataForPost(item), success, error);
    }

    /**
     * Delete posts by post IDs
     *
     * @param {number[]} postIDs
     * @param {(response: any) => void} success
     * @param {(error: any) => void} error
     */
    deletePost(
        postIDs: number[],
        success: (response: any) => void = (response) => {},
        error: (error: any) => void = (error) => {}
    ) {
        this.service
            .deletePosts(postIDs)
            .then((response) => {
                success(response);
            })
            .catch((errorData) => {
                error(errorData);
            });
    }

    findSocialSiteName(socialSiteID: number) {
        try {
            if (this.socialSites.length > 0) {
                const socialSite = this.socialSites.find((site) => {
                    return Number(site.siteID) === Number(socialSiteID);
                });

                if (socialSite) {
                    return socialSite.name;
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } catch (e) {
            console.error(e);
        }
    }

    getItems(
        callback: (response: any) => void = () => {},
        errorCallback: (error: any) => void = () => {}
    ) {
        return this._getPosts()
            .then((response) => {
                const filteredPosts = response.posts
                    .map((post) =>
                        CommonPostHelpers.mergeDataWithDraftData(post)
                    )
                    .filter(
                        (post) =>
                            post.medias &&
                            post.medias[0] &&
                            post.medias[0].mediaType === FILE_TYPES.CAROUSEL
                    );

                const mediaIDs = Helpers.arrayUnique(
                    filteredPosts.map((post) => post.medias[0].mediaID)
                );

                if (mediaIDs.length) {
                    return this.carouselController
                        .getCarouselItemsByIDs(mediaIDs)
                        .then(
                            ({
                                carouselItems,
                            }: {
                                carouselItems: CarouselItemResponseInterface[];
                            }) => {
                                filteredPosts.forEach((post) => {
                                    post.medias[0].carouselItems = carouselItems
                                        .filter(
                                            (carouselItem) =>
                                                carouselItem.carouselID.toString() ===
                                                post.medias[0].mediaID.toString()
                                        )
                                        .map((item) => new CarouselItem(item));
                                    post.medias = SmdFile.initFileArray(
                                        post.medias
                                    );

                                    const index = response.posts.findIndex(
                                        (postItem) =>
                                            postItem.postID === post.postID
                                    );
                                    if (index > -1) {
                                        response.posts.splice(index, 1, post);
                                    }
                                });

                                return this.afterSuccessGetItems(
                                    response,
                                    callback
                                );
                            }
                        )
                        .catch((error) =>
                            this.afterFailedGetItems(error, errorCallback)
                        );
                } else {
                    response.posts.map((post) => {
                        post.medias = SmdFile.initFileArray(post.medias);
                        return post;
                    });
                    return this.afterSuccessGetItems(response, callback);
                }
            })
            .catch((error) => this.afterFailedGetItems(error, errorCallback));
    }

    private _getPosts() {
        this.beforeGetItems();
        this.beforeDataLoad();

        let options: any = {
            ...this.filters,
            clientTimezone: DateSupport.getClientTimeZoneName(),
        };

        options = Helpers.objectToQueryOptions(options);
        return this.service.getAll(options);
    }

    private afterSuccessGetItems(response, callback: (res) => void = () => {}) {
        callback(response);
        this.afterGetItems();
        this.afterDataLoad();
        this.successGetItems(response);
        return response;
    }

    private afterFailedGetItems(
        error,
        errorCallback: (err) => void = () => {}
    ) {
        errorCallback(error);
        this.afterGetItems();
        this.afterDataLoad();
        this.failedGetItems(error);
        return error;
    }

    postsProcess(posts: PostInterface[]) {
        const result = [];

        forEach(posts, (post: ISocialMediaPost) => {
            post = CommonPostHelpers.mergeDataWithDraftData(post);

            const activeFrom = post.activeFrom;

            if (!post.activeFrom) {
                post.activeFrom = moment().format(BASE_DATETIME_FORMAT);
            } else {
                post.activeFrom = moment(post.activeFrom).format(
                    BASE_DATETIME_FORMAT
                );
            }

            post["mediaType"] = this.getPostMediaType(post);
            post.coverMediaSrc = CommonPostHelpers.getCoverMediaSrc(
                post as PostInterface
            );

            post["medias"] = post["medias"].map((media) => new SmdFile(media));
            post.socialSiteName = this.findSocialSiteName(post.socialSiteID);

            post = Utils.initLineBreaksOnPostEntity(post);

            post.calendarDate = activeFrom;

            let mentions = null;
            if (post.mentions && Object.keys(post.mentions).length) {
                let _index = 0;
                mentions = {};
                for (const fieldName in post.mentions) {
                    const mentionItems = post.mentions[fieldName];
                    try {
                        mentions[fieldName] = mentionItems.map((mention) => {
                            const platformConfig =
                                SocialMediaPlatformConfig.getConfig(
                                    mention.platform
                                );
                            const renderedTag =
                                TemplateTinyMceBaseOptions.mentions.insert({
                                    type: AUTOCOMPLETE_TYPE_MENTION,
                                    name: mention.name,
                                    uid: `${mention.platform}:${mention.id}`,
                                    link: mention.link,
                                });
                            const extendedMention: IMentionViewItem = {
                                uid: renderedTag.uid,
                                label: mention.label,
                                items: [
                                    {
                                        type: AUTOCOMPLETE_TYPE_MENTION,
                                        id: mention.id as number,
                                        name: mention.name,
                                        index: _index++,
                                        platform: mention.platform,
                                        image: mention.image,
                                        uid: `${mention.platform}:${mention.id}`,
                                        tagUid: renderedTag.uid,
                                        platformName: LanguageService.getLine(
                                            platformConfig.tabNameKey
                                        ),
                                        iconClass:
                                            platformConfig.iconClass +
                                            ` color-${mention.platform}`,
                                    },
                                ],
                            };
                            if (post[fieldName]) {
                                const formatterIn =
                                    MENTION_FORMATTER_IN[mention.platform] ||
                                    null;
                                if (
                                    formatterIn &&
                                    formatterIn instanceof Function
                                ) {
                                    post[fieldName] = formatterIn(
                                        post[fieldName],
                                        mention,
                                        renderedTag.html
                                    );
                                } else {
                                    post[fieldName] = post[fieldName].replace(
                                        new RegExp(`@${mention.name} `, "g"),
                                        renderedTag.html
                                    );
                                }
                            }
                            return extendedMention;
                        });
                    } catch (e) {
                        Debug.warn(e);
                    }
                }
            }
            post.mentions = mentions;

            if (post.repeats && values(post.repeats).length) {
                const originDate = post.activeFrom;
                for (const repeatIndex in post.repeats) {
                    const date = post.repeats[repeatIndex];
                    const newPost = {
                        ...Utils.lodash.cloneDeep(post),
                        repeatIndex,
                        activeFromInput: originDate,
                        activeFrom: date,
                        calendarDate: date,
                        isRepeat: true,
                    };

                    result.push(newPost);
                }
            } else {
                post.activeFromInput = post.activeFrom;
                result.push(post);
            }
        });

        return result;
    }

    public static getPostMainStatus(post) {
        /* if (
            [POST_STATUS_UNDER_POST, POST_STATUS_INACTIVE, POST_STATUS_POSTED, POST_STATUS_FAILED, POST_STATUS_PARTIALLY_FAILED].indexOf(post.status) > -1 &&
            post.statuses
        ) {
            post.status = post.statuses[post.repeatIndex || 0].status;
            post.statusMessage =
                post.statuses[post.repeatIndex || 0].statusMessage;
        }

        return cloneDeep(post); */
        return post; // unnecessary after refactoring post statuses
    }

    successGetItems(
        response: any,
        callback: (posts?: PostInterface[]) => void = () => {}
    ): void {
        super.successGetItems(response);

        this.responsePostCount = 0;

        // this.posts = response.posts;

        this.queueItems = [];
        this.postedItems = [];
        this.sortedItems = [];

        let orderKey = "createDate";
        let orderBy = "ASC";

        let posts: PostInterface[] = response.posts,
            queueGroupedItems: object = {},
            postedGroupedItems: object = {},
            sortedGroupedItems: object = {},
            currentDay = moment().format("Y-MM-DD"),
            nextDay = moment().add(1, "day").format("Y-MM-DD"),
            prevDay = moment().subtract(1, "day").format("Y-MM-DD");

        if (this.filters["order"]) {
            try {
                const order = JSON.parse(this.filters["order"]);
                if (Object.keys(order)[0] !== "none") {
                    orderKey = Object.keys(order)[0];
                    orderBy = order[Object.keys(order)[0]].toUpperCase();
                }
            } catch (e) {
                console.error(e);
            }
        }

        posts = this.postsProcess(posts);

        for (const post of posts) {
            const dateKey = moment(post.activeFrom).format("Y-MM-DD");
            let groupName = dateKey;

            switch (dateKey) {
                case currentDay:
                    groupName = "Today";
                    break;

                case prevDay:
                    groupName = "Yesterday";
                    break;

                case nextDay:
                    groupName = "Tomorrow";
                    break;
            }

            if (
                Object.keys(queueGroupedItems).indexOf(dateKey) === -1 &&
                post.status !== "posted"
            ) {
                queueGroupedItems[dateKey] = {
                    createDate: dateKey,
                    groupName: groupName,
                    items: [],
                };
            }

            if (
                Object.keys(postedGroupedItems).indexOf(dateKey) === -1 &&
                post.status === "posted"
            ) {
                postedGroupedItems[dateKey] = {
                    createDate: dateKey,
                    groupName: groupName,
                    items: [],
                };
            }

            if (Object.keys(sortedGroupedItems).indexOf(dateKey) === -1) {
                sortedGroupedItems[dateKey] = {
                    createDate: dateKey,
                    groupName: groupName,
                    items: [],
                };
            }

            if (post.status !== "posted") {
                if (!moment(post.activeFrom).isBefore(moment())) {
                    this.setNextSharingTime(post);

                    queueGroupedItems[dateKey]["items"].push(post);
                }
            } else {
                postedGroupedItems[dateKey]["items"].push(post);
            }

            sortedGroupedItems[dateKey]["items"].push(post);
        }

        for (const key in queueGroupedItems) {
            const value = queueGroupedItems[key];

            value.items = Helpers.orderBy(value.items, "activeFrom", orderBy);

            this.responsePostCount += value.items.length;

            if (value.items.length > 0) {
                this.queueItems.push(value);
            }
        }

        for (const key in postedGroupedItems) {
            const value = postedGroupedItems[key];

            value.items = Helpers.orderBy(value.items, "activeFrom", orderBy);

            if (value.items.length > 0) {
                this.postedItems.push(value);
            }
        }

        for (const key in sortedGroupedItems) {
            const value = sortedGroupedItems[key];

            value.items = Helpers.orderBy(value.items, "activeFrom", orderBy);

            if (value.items.length > 0) {
                this.sortedItems.push(value);
            }
        }

        this.queueItems = Helpers.orderBy(this.queueItems, orderKey, orderBy);

        this.postedItems = Helpers.orderBy(this.postedItems, orderKey, orderBy);

        this.sortedItems = Helpers.orderBy(this.sortedItems, orderKey, orderBy);

        callback(posts);
    }

    /**
     * Set next sharing time
     *
     * @param {PostInterface} post
     */
    setNextSharingTime(post: PostInterface) {
        let forbiddenStatuses = [POST_STATUS_POSTED, POST_STATUS_FAILED, POST_STATUS_PARTIALLY_FAILED];
        if (!forbiddenStatuses.includes(post.status)) {
            let currentPostActiveFrom = moment(post.activeFrom).format(
                BASE_DATETIME_FORMAT
            );

            currentPostActiveFrom = moment(currentPostActiveFrom).isValid()
                ? currentPostActiveFrom
                : null;

            if (!currentPostActiveFrom) {
                return false;
            }

            if (this.nextSharing === EMPTY_NEXT_SHARING) {
                this.nextSharing = moment(currentPostActiveFrom).format(
                    BASE_DATETIME_FORMAT
                );
                this.viewNextSharing = moment(this.nextSharing).format(
                    VIEW_MOMENT_BASE_DATETIME_FORMAT
                );
            }

            if (
                moment(currentPostActiveFrom).isBefore(this.nextSharing) &&
                moment(currentPostActiveFrom).isAfter()
            ) {
                this.nextSharing = moment(post.activeFrom).format(
                    BASE_DATETIME_FORMAT
                );
                this.viewNextSharing = moment(this.nextSharing).format(
                    VIEW_MOMENT_BASE_DATETIME_FORMAT
                );
            }
        }
    }

    /**
     * Get targeting locations by searchValue
     *
     * @param {string} searchValue
     * @return {Observable<GeoLocationInterface[]>}
     */
    getTargetingLocations(
        searchValue: string
    ): Observable<GeoLocationInterface[]> {
        return new Observable((observer) => {
            this.service
                .getLocations(searchValue)
                .then((response) => {
                    observer.next(response.targeting.data);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    /**
     * Delete post click event
     * @param {PostInterface} post
     * @return {Promise<any>}
     */
    deletePostClick(post: PostInterface) {
        return new Promise((resolve, reject) => {
            this._dialog
                .open(DialogConfirmComponent, {
                    data: {
                        message: LanguageService.getLine(
                            "post.manager.deletepostconfirm"
                        ),
                        yesButtonText: LanguageService.getLine(
                            "post.manager.confirmDelete"
                        ),
                    },
                })
                .afterClosed()
                .subscribe((doIt) => {
                    if (doIt) {
                        const _loader = this._dialog.open(
                            DialogLoaderComponent,
                            {
                                disableClose: true,
                                panelClass: "dialog-loader-modal",
                                minWidth: "200vw",
                                minHeight: "200vh",
                                hasBackdrop: false,
                            }
                        );

                        this.deletePost(
                            [post.postID],
                            (response) => {
                                this.posts.splice(this.posts.indexOf(post), 1);

                                this._listChange = true;

                                _loader.afterClosed().subscribe(() => {
                                    this._dialog
                                        .open(DialogSuccessComponent, {
                                            data: {
                                                message:
                                                    LanguageService.getLine(
                                                        "post.manager.successDelete"
                                                    ),
                                            },
                                        })
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.afterDeletePost(
                                                true,
                                                response
                                            );
                                            resolve(response);
                                        });
                                });

                                _loader.close();
                            },
                            (error) => {
                                _loader.afterClosed().subscribe(() => {
                                    this._dialog
                                        .open(DialogErrorComponent, {
                                            data: {
                                                message:
                                                    LanguageService.getLine(
                                                        "post.manager.deleteFailed"
                                                    ),
                                            },
                                        })
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.afterDeletePost(false, error);
                                            reject(error);
                                        });
                                });

                                _loader.close();
                            }
                        );
                    }
                });
        });
    }

    openPostPreviewModal(
        template,
        afterClose: (result: any) => void = () => {}
    ) {
        this.openModal.entityPreview(PostPreviewComponent, template);
    }

    /**
     * Get object for posting
     *
     * @param {PostTemplateInterface} template
     * @param {boolean} isInstant
     * @return {object}
     */
    getDataForPost(
        template: PostTemplateForRequest,
        isInstant: boolean = false,
        type: DraftType = "post"
    ): object {
        const post = {
            templateID: "",
            headline: "",
            subHeadline: "",
            mainCopy: "",
            signature: "",
            type: "",
            organizationIDs: "",
            socialSiteIDs: "",
            socialType: "",
            categories: "",
            comment: "",
            status: "",
            feedTargetingOptions: "",
            expiryPeriod: "",
            expiryDate: "",
            isCopy: "",
            instantPost: null,
            isAutoFeed: "",
            tags: "",
            linkshareTitle: "",
            linkshareDescription: "",
            linkshareURL: "",
            linkshareImageURL: "",
            createPostBySocialSite: "",
            instagramLocationID: "",
            instagramLocationName: "",
        };

        if (!isInstant) {
            post["activeFrom"] = "";

            if ("activeTo" in template) {
                post["activeTo"] = "";
            }
        } else {
            delete post.expiryPeriod;
            delete post.expiryDate;
        }

        /**
         * Set text contents
         */
        for (const key in post) {
            if (template[key]) {
                post[key] = template[key] || "";
            }
        }

        if (isInstant && !post["instantPost"]) {
            post["instantPost"] = 1;
        }

        const filesIDKey = type === "post" ? "fileIDs" : "filesId",
            carouselIDKey = type === "post" ? "carouselID" : "carouselId";

        /**
         * Set file IDs
         */
        if (template.medias && template.medias.length > 0) {
            const IDs: number[] = [];

            for (const index in template.medias) {
                const file = template.medias[index];

                if (file.type === FILE_TYPES.CAROUSEL) {
                    post[carouselIDKey] = template.medias[0].mediaID;
                } else {
                    IDs.push(file.mediaID);
                }
            }

            if (!(carouselIDKey in post)) {
                post[filesIDKey] = JSON.stringify(IDs);
            }
        }

        return post;
    }

    createItem(
        item: object,
        success: (response: any) => void = (response) => {},
        errorCallback: (error: any) => void = (error) => {}
    ): void {
        super.createItem(item, success, (error) => {
            errorCallback(error);
        });
    }

    isPass(date: string): boolean {
        return moment(date).isBefore(moment());
    }

    afterDeletePost(isSuccess: boolean, response: any) {}

    private getPostMediaType(post: any) {
        const languageKeyPrefix = "post.mediaType.";
        const icons = {
            [FILE_TYPES.VIDEO]: Icons.VIDEO,
            [FILE_TYPES.IMAGE]: Icons.IMAGE,
            [FILE_TYPES.CAROUSEL]: Icons.CAROUSEL,
            [FILE_TYPES.OG]: Icons.OG,
            text: Icons.TEXT,
            video360: Icons.VIDEO_360,
        };
        const type = {
            icon: "",
            languageKey: "",
        };
        let mediaType = "";
        let is360 = false;

        forEach(post.medias, (media) => {
            mediaType = media.type;

            if (mediaType === FILE_TYPES.VIDEO) {
                is360 = media.is360 === "true" ? true : false;
            }
        });

        if (mediaType !== "") {
            if (mediaType === FILE_TYPES.VIDEO) {
                if (is360) {
                    mediaType = "video360";
                }
            }
        } else {
            mediaType = "text";
        }

        if (post.linkshare && post.linkshare.image) {
            mediaType = FILE_TYPES.OG;
        }

        type.languageKey = languageKeyPrefix + mediaType;
        type.icon = icons[mediaType];

        return type;
    }
}
