import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IDialogInfoData} from '~/src/app/components/dialog-info/dialog-info.interface';

@Component({
    selector: 'smd-dialog-info',
    templateUrl: './dialog-info.component.html',
    styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: IDialogInfoData
    ) {
    }

    ngOnInit(): void {
    }

}
