import {ComponentAbstract} from '../../services/component.abstract';
import {EventEmitter, Injectable} from '@angular/core';
import {ActivityService} from './activity.service';
import {UsersController} from '../users/users/users.component';
import {ActivityResponse, ActivityResponseRaw} from './activity.interfaces';
import {ActivityTransformer} from './activity.transformer';
import {LoggedUser} from '../../services/logged-user';
import {PostTemplateController} from '../posts/post-template/post-template.controller';
import {PostController} from '../posts/post.controller';
import {PostPreviewComponent} from '~/src/app/modules/posts/post-previews/post-preview/post-preview.component';
import {MatDialog} from '@angular/material/dialog';
import {ACTIVITY_OPEN_TEMPLATE_TYPES} from './activity.options';
import {StatusType} from '../posts/post-actions';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {CarouselController} from '~/src/app/modules/posts/carousel/carousel.component';

@Injectable()
export class ActivityController extends ComponentAbstract {
    getDailyActivitiesInProgress = false;
    getMyActivitiesInProgress = false;
    onRefreshActivities: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public service: ActivityService,
        public usersController: UsersController,
        public postTemplateController: PostTemplateController,
        public postController: PostController,
        public dialog: MatDialog,
        private openModal: OpenModalService,
        public carouselController: CarouselController,
    ) {
        super();

        this.filters = {};

        this.filters['order'] = this.getOrderFormat('activityDate-desc');
    }

    getItems(callback: (response: ActivityResponse) => void = () => {}, errorCallback: (error: any) => void = () => {}): void {
        super.getItems((response: ActivityResponseRaw) => {
            new ActivityTransformer(this.usersController, this.postTemplateController, this.postController, this.carouselController).initialize(response)
                .then(result => {
                    result.activities.forEach(activity => {
                        let type: StatusType = 'post';

                        if (ACTIVITY_OPEN_TEMPLATE_TYPES.indexOf(activity.objectType) > -1) { type = 'postTemplate'; }

                        activity.onOpenPreview.subscribe(data => {
                            this.openModal.entityPreview(PostPreviewComponent, data, {
                                data: {
                                    successApprove: () => {
                                        this.onRefreshActivities.emit();
                                    }
                                }
                            });
                        });
                    });

                    callback(result);
                })
                .catch(error => errorCallback(error));
        }, error => errorCallback(error));
    }

    getDailyActivities(callback: (response: ActivityResponse) => void = () => {}, errorCallback: (error: any) => void = () => {}) {
        if (this.filters['userID']) {
            delete this.filters['userID'];
        }

        this.getDailyActivitiesInProgress = true;

        return this.getItems(response => {
            this.getDailyActivitiesInProgress = false;
            callback(response);
        }, error => {
            this.getDailyActivitiesInProgress = false;
            errorCallback(error);
        });
    }

    getMyActivities(callback: (response: ActivityResponse) => void = () => {}, errorCallback: (error: any) => void = () => {}) {
        this.filters['userID'] = LoggedUser.getUser().userID;

        this.getMyActivitiesInProgress = true;

        return this.getItems(response => {
            this.getMyActivitiesInProgress = false;
            callback(response);
        }, error => {
            this.getMyActivitiesInProgress = false;
            errorCallback(error);
        });
    }
}

