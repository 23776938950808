import {Injectable} from '@angular/core';
import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {
    IListRSSGroupFeedResponse,
    IListRssGroupResponse,
    IRSSGroupFormData
} from '~/src/app/modules/rss/types/rss-group.interface';
import {RssGroupModel} from '~/src/app/modules/rss/models/rss-group.model';
import {RSS_SETTINGS_GROUP_API_RESOURCE} from '~/src/app/modules/rss/rss.constants';
import {IRSSFeed} from '~/src/app/modules/rss/types/rss-feed.interface';
import {OrganizationService} from '~/src/app/modules/users/organizations/organization.service';

@Injectable({providedIn: 'root'})
export class RssGroupService {

    constructor(
        private backend: BackendService,
        private organizationService: OrganizationService
    ) {}

    /**
     * List RSS groups
     * @param {RequestParams} params
     * @return {Promise<IListRssGroupResponse<RssGroupModel>>}
     */
    async listGroups(params: RequestParams = {}): Promise<IListRssGroupResponse<RssGroupModel>> {
        const organizationList = await this.organizationService.getList();
        return this.backend.get(RSS_SETTINGS_GROUP_API_RESOURCE, params)
            .then((response: IListRssGroupResponse) => {
                const groupIDs = response.groups.map(group => group.groupID);
                const prepareGroups = (_feedItems: {[key: string]: IRSSFeed[]} = {}): IListRssGroupResponse<RssGroupModel> => {
                    response.groups = (response.groups || []).map(item => {
                        const organizationIDs = (item.organizationID as string[] || []).map(id => Number(id));

                        return new RssGroupModel(
                            item,
                            _feedItems[item.groupID],
                            organizationList.organizations.filter(organization => organizationIDs.includes(organization.organizationID))
                        );
                    });

                    return response as IListRssGroupResponse<RssGroupModel>;
                };

                if (groupIDs.length) {
                    return this.listFeedsByGroupIDs(groupIDs).then(feedResponse => {
                        return Promise.resolve(prepareGroups(feedResponse.feeds));
                    });
                }

                return Promise.resolve(prepareGroups({})) as Promise<IListRssGroupResponse<RssGroupModel>>;
            });
    }

    /**
     * Create RSS group
     * @param {IRSSGroupFormData} data
     * @return {Promise<any>}
     */
    create(data: IRSSGroupFormData): Promise<any> {
        return this.backend.post(RSS_SETTINGS_GROUP_API_RESOURCE, data);
    }

    /**
     * Modify RSS group item
     * @param {number} groupID
     * @param {IRSSGroupFormData} data
     * @return {Promise<any>}
     */
    modify(groupID: number, data: IRSSGroupFormData): Promise<any> {
        return this.backend.put(`${RSS_SETTINGS_GROUP_API_RESOURCE}/${groupID}`, data);
    }

    /**
     * Remove RSS group items
     * @param {number[]} groupID
     * @return {Promise<any>}
     */
    remove(groupID: number[]): Promise<any> {
        return this.backend.post(`${RSS_SETTINGS_GROUP_API_RESOURCE}/bulk-delete`, {groupID});
    }

    /**
     * List RSS feed items by group IDs
     * @param {number[]} groupID
     * @return {Promise<IListRSSGroupFeedResponse>}
     */
    listFeedsByGroupIDs(groupID: number[]): Promise<IListRSSGroupFeedResponse> {
        return this.backend.get(`${RSS_SETTINGS_GROUP_API_RESOURCE}/feeds`, {groupID});
    }
}
