import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';

export const WIDGET_METRIC_OPTION_IMPRESSION_TOTAL: WidgetMetricOptionType = {
    id: 'impression-total',
    name: 'Total',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'facebook'
};
export const WIDGET_METRIC_OPTION_IMPRESSION_OVERVIEW: WidgetMetricOptionType = {
    id: 'impression-overview',
    name: 'Overview',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_IMPRESSION_ORGANIC: WidgetMetricOptionType = {
    id: 'impression-organic',
    name: 'Organic',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_IMPRESSION_PAID: WidgetMetricOptionType = {
    id: 'impression-paid',
    name: 'Paid',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_IMPRESSION_VIRAL: WidgetMetricOptionType = {
    id: 'impression-viral',
    name: 'Viral',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'facebook'
};

// METRIC CONFIGS

export const MetricImpressionTotalConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_IMPRESSION_TOTAL.id,
    apiURL: '/page-impressions',
    contentType: 'chart',
    dataName: 'Impressions (total)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'total',  // TODO megnézni, hogy kell-e
    }
};

export const MetricImpressionOrganicConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_IMPRESSION_ORGANIC.id,
    apiURL: '/page-impressions',
    contentType: 'chart',
    dataName: 'Impressions (organic)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'organic'
    }
};

export const MetricImpressionPaidConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_IMPRESSION_PAID.id,
    apiURL: '/page-impressions',
    contentType: 'chart',
    dataName: 'Impressions (paid)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'paid'
    }
};

export const MetricImpressionViralConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_IMPRESSION_VIRAL.id,
    apiURL: '/page-impressions',
    contentType: 'chart',
    dataName: 'Impressions (viral)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'viral'
    }
};

export const MetricImpressionOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_IMPRESSION_OVERVIEW.id,
    contentType: 'chart',
    dataName: 'Impressions overview',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    partMetricIDs: [
        WIDGET_METRIC_OPTION_IMPRESSION_TOTAL.id,
        WIDGET_METRIC_OPTION_IMPRESSION_ORGANIC.id,
        WIDGET_METRIC_OPTION_IMPRESSION_VIRAL.id,
        WIDGET_METRIC_OPTION_IMPRESSION_PAID.id,
    ]
};
