import {OnInit, ViewChild} from '@angular/core';

declare var $;

/**
 * Stored UI states (form, selector etc.)
 */
export class StateSaver {

    constructor() {
        this.viewChangeEvent();
    }

    /**
     *  For tabs and view selector buttons (eg. calendar/list view)
     */
     viewChangeEvent() {
        $('[data-state="tab"]').on('click', '[data-state-item]', function(e) {
            e.preventDefault();

            const $this = $(this);
            const id = $this.data('id');
            const parentID = $this.parents('[data-state="tab"]').attr('id');

            StateSaver.saveState(parentID, {
                'id': id
            });
        });
    }

    /**
     * Save item's values base on it's ID;
    */
    public static saveState(itemID, values) {
        if (values && itemID) {
            //this.storage.set(itemID, JSON.stringify(values));
            localStorage.setItem(itemID, JSON.stringify(values));
        } else {
            console.error('TypeError storage in state helper');
        }
    }

    /**
     * Get state from item's ID and set to state
     */
    public getState(itemID) {
        const itemState = localStorage.getItem(itemID);
        //let itemState = this.storage.get(itemID);

        const itemStateObj = JSON.parse(itemState);
        const $itemDOM = $('#' + itemID);

        for (const key in itemStateObj) {
            const $key = $itemDOM.find('[data-id=' + itemStateObj[key] + ']');

            // tabs with input
            if ($($key).find('input') && $($key).find('input').length) {
                $($key).find('input').trigger('click');
                return;
            }

            // link
            if ($($key).attr('href') && $($key).attr('href').length) {
                $($key).trigger('click');
                return;
            }

            // otherwise
            if ($($key).length) {
                $($key).trigger('click');
                return;
            }
        }
    }

    public setPaginationState(paginatorID, values) {
        //this.storage.set(paginatorID, JSON.stringify(values));
        localStorage.setItem(paginatorID, JSON.stringify(values));
    }

    public getPagionationState(paginator) {
        const paginatorSettings = JSON.parse(localStorage.getItem(paginator));
        //let paginatorSettings = JSON.parse(this.storage.get(paginator));

        if (paginatorSettings) {
            return paginatorSettings;
        }


        return false;
    }

    public insertFormStorage(formItem, value) {
        if (formItem && value) {
            localStorage.setItem(formItem, value);
        } else {
            console.error('TypeError storage in state helper');
        }
    }

    public getFormStorage(formArray) {
        return new Promise((resolve, reject) => {

            const filterResult = [];

            for (const formItem of formArray) {
                if (formItem) {
                    const storeVal = JSON.parse(localStorage.getItem(formItem.name));
                    const itemObj = {
                        value: storeVal
                    };
                    filterResult.push(itemObj);
                }
            }

            if (filterResult.length === 0) {
                reject();
            }

            resolve(filterResult);
        });
    }

    /**
     * Deleted State from storaged
    */
    public static deletedState(itemID) {
        localStorage.removeItem(itemID);
    }
}
