import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'smd-hashtag-collection-manager-modal',
  templateUrl: './hashtag-collection-manager-modal.component.html',
  styleUrls: ['./hashtag-collection-manager-modal.component.scss']
})
export class HashtagCollectionManagerModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
