import {ComponentAbstract} from '../../services/component.abstract';
import {OrganizationService} from './organization.service';
import {Injectable} from '@angular/core';

export class OrganizationComponent extends ComponentAbstract {
    /**
     * Constructor
     *
     * @param service
     */
    constructor(
        public service: OrganizationService
    ) {
        super();
    }
}

@Injectable({providedIn: 'root'})
export class OrganizationController extends OrganizationComponent {
    getItemsPending = false;

    constructor(service: OrganizationService) {
        super(service);
    }

    beforeGetItems(): void {
        super.beforeGetItems();
        this.getItemsPending = true;
    }

    afterGetItems(): void {
        super.afterGetItems();
        this.getItemsPending = false;
    }
}
