import AbstractModel from "~/src/app/core/model.abstract";
import Utils from "~/src/app/core/utils";
import { SmdFile, SmdFileInterface } from "~/src/app/services/file.class";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { BASE_DATETIME_FORMAT } from "~/src/app/configs/configs";
import { BulkUploadManager } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { SocialMediaPlatforms } from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { SOCIAL_MEDIA_TYPE_GMB } from "../../core/constants";

export interface IMediaRequestData {
    carouselID?: number;
    fileIDs?: number[];
    media?: {
        cacheID: string;
    };
}

export interface ILinkShareRequestData {
    linkshareTitle?: string;
    linkshareDescription?: string;
    linkshareURL?: string;
    linkshareImageURL?: string;
    linkshareIncluded?: boolean;
    autoLinkshareEnabled?: boolean;
}

export interface ICommonRequestData
    extends IMediaRequestData,
        ILinkShareRequestData {
    category: string;
    headline: string;
    subHeadline: string;
    mainCopy: string;
    signature: string;
}

export class BulkImportItemModel extends AbstractModel {
    static SHORT_CONTENT_MAX_LENGTH = 500;

    // properties from API
    headline = "";
    subHeadline = "";
    mainCopy = "";
    signature = "";
    mediaName: string;
    userID: number;
    organizationID;
    createDate: string;
    modifyDate: string;
    status: string;
    feedTargetingOptions: any;
    archive: any;
    systemType: string;
    category: string;
    organizationIDs: number[];
    linkShareTitle: string;
    linkShareDescription: string;
    linkShareURL: string;
    linkShareImageURL: string;
    ogMediaItem: SmdFile;
    gmbOptions: any;
    backupLinkshare: {
        [key in keyof BulkImportItemModel]?: string;
    };
    socialType: string;
    characterCount: number = null;
    characterLengthIsValid: boolean;
    _error: { [key: string]: string };
    _warning: { [key: string]: string };
    _media: BulkUploadManager.ImportItemMediaElement;

    hasError = false;
    hasWarning = false;

    get media() {
        return this._media;
    }

    set media(value) {
        this._media = {
            imageTags: [],
            ...value,
        };

        this._setNewMedia();
    }

    get error() {
        return this._error;
    }

    set error(value) {
        this._error = value;

        this.hasError = !!this._error && !!Object.keys(this._error).length;
    }

    get warning() {
        return this._warning;
    }

    set warning(value) {
        this._warning = value;

        this.hasWarning =
            !!this._warning && !!Object.keys(this._warning).length;
    }

    // generated properties
    shortProperties = {
        headline: "",
        subHeadline: "",
        mainCopy: "",
        signature: "",
    };
    id: string; // generated uniq ID by UUID
    medias: SmdFileInterface[] = [];
    fileIDs: number[];
    carouselID: number;
    pendingMedia: boolean; // get origin media in progress
    getMediaFailed: boolean;
    newMedia: SmdFileInterface;
    draftSaveInProgress: boolean;

    /**
     * Check entity is exist in database
     *
     * @type {boolean}
     */
    isDatabaseEntity = false;
    mediaIsUploaded = false;
    private _mediaSrc: string; // origin media element base64 content

    get mediaSrc(): string {
        return this._mediaSrc || "";
    }

    set mediaSrc(value: string) {
        this._mediaSrc = value;

        this._setNewMedia();
    }

    constructor(props) {
        super(props);

        this.id = props.id || Utils.uuid.UUID();

        this.headline = props.headline;
        this.subHeadline = props.subHeadline;
        this.mainCopy = props.mainCopy;
        this.signature = props.signature;
        this.mediaName = props.mediaName;
        this.userID = props.userID;
        this.organizationID =
            Number(props.organizationID) || props.organizationID;
        this.createDate = props.createDate;
        this.modifyDate = props.modifyDate;
        this.status = props.status;
        this.feedTargetingOptions = props.feedTargetingOptions;
        this.archive = props.archive;
        this.systemType = props.systemType;
        this.category = Utils.get(props, "category.name", props.category);
        this.error = props.error;
        this.media = props.media;
        this.organizationIDs = props.organizationIDs || [];

        if (this.media) {
            if (!this.media.mediaID) {
                this.media.mediaID = new Date().getTime();
            } else if (this.media.cacheID) {
                this.mediaIsUploaded = true;
            }
        }

        this.generateShortProperties();

        ["headline", "subHeadline", "mainCopy", "signature"].forEach((key) => {
            this[key] = Utils.htmlEntities.encode(this[key]);

            this[key] = this[key].replace(/\n/g, "<br />");
            this[key] = this[key].replace(/↵/g, "<br />");
        });

        return this;
    }

    /**
     * Get request data of model
     *
     * @returns {Object}
     */
    getRequestData() {
        let data: any = this.getCommonRequestData();

        if (this.media) {
            data = {
                ...data,
                mediaName: this.media.mediaName,
                imageTags: this.media.imageTags,
            };
        }

        if (this.socialType === SOCIAL_MEDIA_TYPE_GMB) {
            const archive = JSON.parse(this.archive);
            data = {
                ...data,
                gmbOptions: archive.gmbOptions,
            };
        }

        return data;
    }

    /**
     * @param {boolean} forDraft
     * @return {ICommonRequestData}
     */
    getDraftRequestData(forDraft = false): ICommonRequestData {
        return this.getCommonRequestData(forDraft);
    }

    /**
     * @param {boolean} forDraft
     * @return {ICommonRequestData}
     */
    getCommonRequestData(forDraft = false): ICommonRequestData {
        return {
            category: this.category,
            headline: this.headline,
            subHeadline: this.subHeadline,
            mainCopy: this.mainCopy,
            signature: this.signature,
            ...this.getMediaRequestProperties(forDraft),
            [PostFormControlNames.LinkShareIncluded]: false,
            [PostFormControlNames.AutoLinkshareEnabled]: true,
            ...this.getLinkShareData(),
        };
    }

    /**
     * @param {boolean} forDraft
     * @return {IMediaRequestData}
     */
    getMediaRequestProperties(forDraft = false): IMediaRequestData {
        const requestData = {};

        if (this.medias && this.medias.length) {
            const newFileIndex = this.medias.findIndex(
                (media) => media.entityID && media.entityID === this.id
            );

            // remove cacheID when removed origin media element
            if (newFileIndex > -1 && !forDraft) {
                requestData["media"] = {
                    cacheID: this.media.cacheID,
                };
            }

            // separate media elements
            const fileIDs = [];
            let carouselID = null;

            this.medias.forEach((media, index) => {
                if (media.type === FILE_TYPES.CAROUSEL) {
                    carouselID = media.mediaID;
                } else if (
                    (index !== newFileIndex || forDraft) &&
                    media.type !== FILE_TYPES.OG
                ) {
                    fileIDs.push(media.mediaID);
                }
            });

            if (carouselID) {
                requestData["carouselID"] = carouselID;
            } else if (fileIDs.length) {
                requestData["fileIDs"] = fileIDs;
            }
        }

        return requestData;
    }

    /**
     * @return {{}}
     */
    getLinkShareData(): ILinkShareRequestData {
        const data: ILinkShareRequestData = {};

        if (this.hasLinkShare()) {
            data[PostFormControlNames.LinkShareTitle] = this.linkShareTitle;
            data[PostFormControlNames.LinkShareDescription] =
                this.linkShareDescription;
            data[PostFormControlNames.LinkShareURL] = this.linkShareURL;

            if (this.medias && this.medias.length) {
                const media = this.medias[0];

                data[PostFormControlNames.LinkShareImageURL] =
                    !this.ogImageReplaced()
                        ? media.url
                        : SmdFile.addDomainToMediaUrl(media.url);
            }

            data[PostFormControlNames.LinkShareIncluded] = true;
        }

        return data;
    }

    /**
     * Set backup linkshare properties
     *
     * @param data
     */
    setBackupLinkshare(data: { [key in keyof this]?: any } = this): void {
        this.backupLinkshare = {
            linkShareURL: data.linkShareURL,
            linkShareImageURL: data.linkShareImageURL,
            linkShareTitle: data.linkShareTitle,
            linkShareDescription: data.linkShareDescription,
        };
    }

    /**
     * Set character number
     */
    setCharacterCount() {
        if (!this.socialType) {
            return;
        }

        const result = CommonPostHelpers.setPostCharacterNumber({
            formGroup: {
                value: {
                    [PostFormControlNames.Headline]: this.headline,
                    [PostFormControlNames.SubHeadline]: this.subHeadline,
                    [PostFormControlNames.Message]: this.mainCopy,
                    [PostFormControlNames.Signature]: this.signature,
                },
            },
            postContentMaxLength: SocialMediaPlatforms.find(
                (platform) => platform.platform === this.socialType
            ).maxLength,
            platform: this.socialType,
        });

        this.characterCount = result.characterNumber;
        this.characterLengthIsValid = !result.characterNumberIsInvalid;
    }

    /**
     * @return {boolean}
     */
    hasLinkShare(): boolean {
        return !!(this.linkShareTitle || this.linkShareURL);
    }

    /**
     * @return {boolean}
     */
    ogImageReplaced(): boolean {
        return (
            this.hasLinkShare() &&
            !(
                this.medias &&
                this.medias.length &&
                this.medias[0].type === FILE_TYPES.OG
            )
        );
    }

    /**
     * Get preview data
     *
     * @returns {{medias: SmdFileInterface[]; signature: string; subHeadline: string; mainCopy: string; socialType: string; headline: string}}
     */
    getPreviewData() {
        const archive = JSON.parse(this.archive);
        const data = {
            headline: this.headline,
            subHeadline: this.subHeadline,
            mainCopy: this.mainCopy,
            signature: this.signature,
            socialType: this.socialType,
            medias: this.medias,
            gmbOptions: archive ? archive.gmbOptions : null,
            [PostFormControlNames.LinkShareURL]: this.linkShareURL,
            [PostFormControlNames.LinkShareImageURL]: this.linkShareImageURL,
            [PostFormControlNames.LinkShareTitle]: this.linkShareTitle,
            [PostFormControlNames.LinkShareDescription]:
                this.linkShareDescription,
        };

        for (const key in data) {
            const value = data[key];

            if (typeof value === "string") {
                data[key] = value.replace(/\n/g, "<br />");
            }
        }

        return data;
    }

    /**
     * Generate short properties
     */
    generateShortProperties() {
        for (const key in this.shortProperties) {
            let value = this[key] || "";

            value = Utils.htmlEntities.decode(value);

            this[key] = value.replace(/<br \/>/g, "\n");

            this.shortProperties[key] = Utils.lodash.truncate(this[key], {
                length: BulkImportItemModel.SHORT_CONTENT_MAX_LENGTH,
            });
        }

        this.setCharacterCount();
    }

    /**
     * Add media element error message
     *
     * @param {keyof this} key
     * @param {string} message
     */
    addPropertyError(key: keyof this | string, message: string) {
        this.addPropertyMessage(key, message, "error");
    }

    /**
     * Remove media element error message
     *
     * @param {keyof this} key
     */
    removePropertyError(key: keyof this | string) {
        this.removePropertyMessage(key, "error");
    }

    /**
     * @param {keyof this | string} key
     * @param {string} message
     * @param {"error" | "warning"} level
     */
    addPropertyMessage(
        key: keyof this | string,
        message: string,
        level: "error" | "warning" = "error"
    ) {
        switch (level) {
            case "error":
                this.error = {
                    ...(this.error || {}),
                    [key]: message,
                };

                break;

            case "warning":
                this.warning = {
                    ...(this.warning || {}),
                    [key]: message,
                };

                break;
        }
    }

    /**
     * @param {keyof this | string} key
     * @param {"error" | "warning"} level
     */
    removePropertyMessage(
        key: keyof this | string,
        level: "error" | "warning" = "error"
    ) {
        switch (level) {
            case "error":
                if (this.error && key in this.error) {
                    delete this.error[key as string];

                    this.error = this.error;
                }

                break;

            case "warning":
                if (this.warning && key in this.warning) {
                    delete this.warning[key as string];

                    this.warning = this.warning;
                }

                break;
        }
    }

    /**
     * Check property in invalid
     *
     * @param {keyof this} key
     * @returns {boolean}
     */
    isValid(key: keyof this): boolean {
        return !(this.error && key in this.error);
    }

    /**
     * Set class variable
     *
     * @param {string} key
     * @param {*} value
     * @returns {this}
     */
    setValue(key: keyof this, value: any) {
        this[key] = value;

        // remove generic error message
        this.removePropertyError("generic");

        return this;
    }

    /**
     * Get cover media src
     *
     * @returns {any}
     */
    getCoverMediaSrc() {
        let src = null;

        if (this.medias.length) {
            src = this.medias[0].bigThumb;

            if (
                this.newMedia &&
                this.medias.filter((media) => media.entityID).length
            ) {
                src = this.newMedia.url;
            }
        }

        return src;
    }

    /**
     * Set new media element
     *
     * @private
     */
    _setNewMedia(): void {
        if (
            !this.media ||
            (this.media && !this.media.cacheID) ||
            !this.isValid("media")
        ) {
            this.newMedia = null;
        } else {
            this.newMedia = new SmdFile(
                {
                    name: this.media.mediaName,
                    mediaID: this.media.mediaID,
                    url: this.mediaSrc || "",
                    mime: "image/png",
                    entityID: this.id as string,
                    cacheID: this.media.cacheID,
                    type: FILE_TYPES.IMAGE,
                    createDate: Utils.moment().format(BASE_DATETIME_FORMAT),
                    selected: true,
                },
                true
            );
        }

        const newMediaIndex = this.medias.findIndex(
            (media) => media.entityID === this.id
        );

        if (newMediaIndex > -1) {
            this.medias.splice(newMediaIndex, 1);
        }

        if (this.newMedia) {
            this.medias = [...this.medias, this.newMedia];
        }
    }
}
