import {Component} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import {CATEGORY_TYPE_CUSTOM, CATEGORY_TYPE_GENERIC} from '~/src/app/modules/categories/categories.config';
import {CategoryTab} from '~/src/app/modules/categories/categories';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';

@Component({
    selector: 'smd-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent {

    // define tabs
    readonly tabs: CategoryTab[] = [
        {
            type: CATEGORY_TYPE_CUSTOM,
            label: this.languageService.getLine('post.category.label.tab.myCategories'),
            initialized: true
        },
        {
            type: CATEGORY_TYPE_GENERIC,
            label: this.languageService.getLine('post.category.label.tab.genericByEsemdee'),
            initialized: false,
            permission: PartnerPermissions.GenericTemplate
        }
    ];

    constructor(
        public languageService: LanguageService,
        public partnerConfigService: PartnerConfigService
    ) {}

    /**
     * On active tab change
     * @param {number} event
     */
    onTabChange(event: number) {
        this.tabs[event].initialized = true;
    }
}
