import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import { WIDGET_INSTAGRAM_METRIC_OPTION_FOLLOWERS_GROWTH } from '../instagram/metrics';

export const WIDGET_METRIC_OPTION_LIKES_GROWTH: WidgetMetricOptionType = {
    id: 'likes-growth',
    name: 'Growth',
    resource: 'analytics.analytics.pageLikeGrowth',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_LIKES_GROWTH_PERCENT: WidgetMetricOptionType = {
    id: 'likes-growth-percent',
    name: 'Growth percent',
    resource: 'analytics.analytics.pageLikeGrowth',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_LIKES_OVERALL: WidgetMetricOptionType = {
    id: 'likes-overall',
    name: 'Overall',
    resource: 'analytics.analytics.pageOverallLikes',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_LIKES_OVERVIEW: WidgetMetricOptionType = {
    id: 'likes-overview',
    name: 'Overview',
    resource: 'analytics.analytics.pageLikeOverview',
    socialChannel: 'facebook'
};

// METRIC CONFIGS

export const MetricLikesGrowthConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LIKES_GROWTH.id,
    apiURL: '/page-overall-likes',
    socialType: 'facebook',
    contentType: 'chart',
    dataName: 'Likes Growth',
    defaultFilters: {
        growth: 'yes'
    },
    chartConfig: {
        ...BaseChartConfig
    },
    replaceMetricIDMap: {
        linkedIn: "",
        facebook: "",
        twitter: "",
        instagram: WIDGET_INSTAGRAM_METRIC_OPTION_FOLLOWERS_GROWTH.id,
        gmb: "",
    },
};

export const MetricLikesGrowthPercentConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LIKES_GROWTH_PERCENT.id,
    apiURL: '/page-like-growth',
    socialType: 'facebook',
    contentType: 'chart',
    defaultFilters: {
        percent: 'yes'
    }
};

export const MetricLikesOverallConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LIKES_OVERALL.id,
    apiURL: '/page-overall-likes',
    socialType: 'facebook',
    contentType: 'html',
    dataName: 'Likes Overall',
    nullable: true,
    htmlTemplate: (dataName: string, response: any) => {
        return '<div><h2 style="text-align: center;">' +
            '<h2 style="text-align: center;"><strong>' + dataName + '</strong><strong><br></strong></h2>' +
            '<p>&nbsp;</p>' +
            '<h1 style="text-align: center;">' +
            '<i class="fa fa-thumbs-o-up m-r-10 text-primary"></i>' +
            (response.analytics.values[response.analytics.values.length - 1] || 0) +
            '</h1></div>';
    },
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLikesOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LIKES_OVERVIEW.id,
    apiURL: '/page-like-growth',
    socialType: 'facebook',
    contentType: 'chart',
    dataName: 'Likes Overview',
    chartConfig: {
        ...BaseChartConfig
    },
};
