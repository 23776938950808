import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PostTemplateFolderManagerDialogData, TemplateFolderInterface } from "../../posts/template-folder.interface";
import { FormHelpersService } from "~/src/app/core/services/form-helpers";
import { OpenModalService } from "../open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { TemplateFolderActionsService } from "../../posts/template-folder-actions.service";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { 
  TEMPLATE_FOLDER_STATUS_ACTIVE, 
  TEMPLATE_FOLDER_TYPES, 
  TEMPLATE_FOLDER_TYPE_ORGANIZATION, 
  TEMPLATE_FOLDER_TYPE_USER 
} from "../../posts/template-folder.constant";
import {LoggedUser} from '~/src/app/services/logged-user';

export class CustomValidators
{
  nameValidator(control: FormControl): { [key: string]: boolean } {
    const nameRegexp: RegExp = /[!@#$%^&*()_+=\[\]{};':"\\|,<>\/?]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { invalidname: true };
    }
  }
}

@Component({
  selector: "smd-template-folder-manager",
  templateUrl: "./template-folder-manager.component.html",
  styleUrls: ["./template-folder-manager.component.scss"],
  providers: [CustomValidators, FormHelpersService]
})
export class TemplateFolderManagerComponent implements OnInit {
  isEditMode: boolean = false;
  isAdminMode: boolean = false;
  folder: TemplateFolderInterface = null;
  folderTypes = TEMPLATE_FOLDER_TYPES;

  userMainOrganization: number = null;

  folderControlNames = {
    Name: "name",
    Type: "type",
    MainOrganization: "mainOrganization",
    Tags: "tags",
    Pinned: "pinned",
    Editable: "editable",
    AssignTemplates: "assignTemplates",
    ChildOrgSharing: "childOrgSharing"
  };
  folderFormGroup = new FormGroup({
    [this.folderControlNames.Name]: new FormControl(
      "", 
      [
        Validators.required,
        Validators.maxLength(255), 
        Validators.minLength(3),
        this.customValidators.nameValidator
      ]
    ),
    [this.folderControlNames.Type]: new FormControl("user", [Validators.required]),
    [this.folderControlNames.MainOrganization]: new FormControl(null, [Validators.required]),
    [this.folderControlNames.Tags]: new FormControl(null),
    [this.folderControlNames.Pinned]: new FormControl(false),
    [this.folderControlNames.Editable]: new FormControl({value: false, disabled: true}),
    [this.folderControlNames.AssignTemplates]: new FormControl({value: false, disabled: true}),
    [this.folderControlNames.ChildOrgSharing]: new FormControl({value: false, disabled: true}),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PostTemplateFolderManagerDialogData,
    public dialogRef: MatDialogRef<TemplateFolderManagerComponent>,
    private customValidators: CustomValidators,
    public formHelpersService: FormHelpersService,
    private openModal: OpenModalService,
    public folderService: TemplateFolderActionsService,
  ) {
    //console.log(data);
    this.userMainOrganization = LoggedUser.getMainOrganization().organizationID;
    this.formHelpersService.formInit(this.folderFormGroup);
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.isEditMode = !!this.data.folder;
    this.isAdminMode = !!this.data.isAdminMode;
    this.folder = this.data.folder || null;

    this.folderFormGroup.controls[this.folderControlNames.MainOrganization].setValue(this.userMainOrganization);
    this.folderFormGroup.controls[this.folderControlNames.MainOrganization].disable();

    // if it is edit mode set initial form values
    if (this.isEditMode && this.folder) {
      this.folderFormGroup.controls[this.folderControlNames.Name].setValue(this.folder.name);

      this.folderFormGroup.controls[this.folderControlNames.Type].setValue(this.folder.type);
      this.folderFormGroup.controls[this.folderControlNames.Type].disable();

      this.folderFormGroup.controls[this.folderControlNames.MainOrganization].setValue(this.folder.organizationID);
      this.folderFormGroup.controls[this.folderControlNames.MainOrganization].disable();

      if (this.folder.type === TEMPLATE_FOLDER_TYPE_ORGANIZATION) {
        this.folderFormGroup.controls[this.folderControlNames.Editable].enable();
        this.folderFormGroup.controls[this.folderControlNames.Editable].setValue(this.folder.editable);
        // this.folderFormGroup.controls[this.folderControlNames.AssignTemplates].enable();
        this.folderFormGroup.controls[this.folderControlNames.AssignTemplates].setValue(this.folder.assignTemplates);
        this.folderFormGroup.controls[this.folderControlNames.ChildOrgSharing].enable();
        this.folderFormGroup.controls[this.folderControlNames.ChildOrgSharing].setValue(this.folder.childOrgSharing);
      }

      this.folderFormGroup.controls[this.folderControlNames.Pinned].setValue(this.folder.pinned);

      if (this.folder.tags) {
        this.folderFormGroup.controls[this.folderControlNames.Tags].setValue(this.folder.tags);
      }
    }

    // subscribe to form Type changes
    this.folderFormGroup.controls[this.folderControlNames.Type].valueChanges.subscribe((type) => {
      if (type === TEMPLATE_FOLDER_TYPE_ORGANIZATION) {
        this.folderFormGroup.controls[this.folderControlNames.Editable].enable();
        // this.folderFormGroup.controls[this.folderControlNames.AssignTemplates].enable();
        this.folderFormGroup.controls[this.folderControlNames.ChildOrgSharing].enable();
        this.folderFormGroup.controls[this.folderControlNames.MainOrganization].enable();
      } else if (type === TEMPLATE_FOLDER_TYPE_USER) {
        this.folderFormGroup.controls[this.folderControlNames.Editable].setValue(false);
        this.folderFormGroup.controls[this.folderControlNames.Editable].disable();
        this.folderFormGroup.controls[this.folderControlNames.AssignTemplates].setValue(false);
        this.folderFormGroup.controls[this.folderControlNames.AssignTemplates].disable();
        this.folderFormGroup.controls[this.folderControlNames.ChildOrgSharing].setValue(false);
        this.folderFormGroup.controls[this.folderControlNames.ChildOrgSharing].disable();
        this.folderFormGroup.controls[this.folderControlNames.MainOrganization].setValue(this.userMainOrganization);
        this.folderFormGroup.controls[this.folderControlNames.MainOrganization].disable();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveFolder() {
    this.formHelpersService.validateForm(this.folderFormGroup);
    if (!this.folderFormGroup.invalid) {
      const loader = this.openModal.loader(DialogLoaderComponent);

      const data = this.folderFormGroup.getRawValue();

      if (data.tags && data.tags?.length) {
        data.tags = JSON.stringify(data.tags);
      } else {
        data.tags = null;
      }
      // status will be editable later
      data.status = TEMPLATE_FOLDER_STATUS_ACTIVE;

      this.folderService.editFolder(this.folder.folderID, data).then((response) => {
        this.openModal.successModal(DialogSuccessComponent, {
          message: 'Folder updated successfully'
        }).afterClosed().subscribe(() => {
          this.data.afterSuccessEdit();
          this.closeDialog();
        });
      }).catch((error) => {
        const errors = this.readErrorMessages(error);
        this.openModal.errorModal(DialogErrorComponent, {
          title: errors.title,
          message: errors.messages.length ? errors.messages.join(', ') : ' ',
        });
      }).finally(() => {
        loader.close();
      });
    }
  }

  createFolder() {
    this.formHelpersService.validateForm(this.folderFormGroup);
    if (!this.folderFormGroup.invalid) {
      const loader = this.openModal.loader(DialogLoaderComponent);

      const data = this.folderFormGroup.getRawValue();
      if (data.tags && data.tags?.length) {
        data.tags = JSON.stringify(data.tags);
      } else {
        data.tags = null;
      }
      data.status = TEMPLATE_FOLDER_STATUS_ACTIVE;

      this.folderService.create(data).then((response) => {
        this.openModal.successModal(DialogSuccessComponent, {
          message: 'Folder created successfully'
        }).afterClosed().subscribe(() => {
          this.data.afterSuccessCreate();
          this.closeDialog();
        });
      }).catch((error) => {
        const errors = this.readErrorMessages(error);
        this.openModal.errorModal(DialogErrorComponent, {
          title: errors.title,
          message: errors.messages.length ? errors.messages.join(', ') : ' ',
        });
      }).finally(() => {
        loader.close();
      });
    }
  }

  /**
   * Read error messages from error object
   * @param error 
   * @returns { title: string, messages: Array<string> }
   */
  private readErrorMessages(error): { title: string, messages: Array<string> } {
    let object = error.error.error;
    let errors = {
      title: !!object.message ? object.message : 'Unknown error',
      messages: []
    };

    if (object.info && object.info.messages) {
      Object.keys(object.info.messages).forEach((key) => {
        errors.messages.push(object.info.messages[key]);
      });
    }

    return errors;
  }
}