import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Log, LoggingResponse } from "../logging.interface";
import { LoggingService } from "../logging.service";

@Component({
    selector: "smd-logging",
    templateUrl: "./logging.component.html",
    styleUrls: ["./logging.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LoggingComponent implements OnInit {
    constructor(public logService: LoggingService, public dialog: MatDialog) {}

    loading = false;
    isDialogOpen = false;

    time: string;

    logs: Log[] = [
        {
            id: "errorLog",
            name: "Error Log",
            description: "Error Log. Should contain errors from APIs, and SMD itself.",
            content: "",
            fileSize: 0,
        },
        {
            id: "infoLog",
            name: "Info Log",
            description: "Info Log. Might contain some useful information, and API responses.",
            content: "",
            fileSize: 0,
        },
        {
            id: "cliLog",
            name: "CLI Log",
            description: "CLI Log. Should contain information about the CRONS / Tasks.",
            content: "",
            fileSize: 0,
        },
        {
            id: "postLog",
            name: "Post Log",
            description: "Post Log. Should contain information about posts if a date is selected.",
            content: "",
            fileSize: 0,
        },
    ];

    filterText: string = "";
    lineCount: number = 10;
    selectedDate: Date = new Date();

    lineCountOptions = [10, 50, 100, 1_000, 10_000];

    ngOnInit() {
        this.refreshClick();
    }

    hydrateContent(content: string): string {
        // Highlight with a color any word that contains a date in the format of yyyy-mm-ddThh:mm:ss+00:00
        content = content.replace(/\[(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2})\]/g, "<span class='highlight-date'>[$1]</span>");

        // Highlight [error] and [info] with a color
        content = content.replace(/\[(error)\]/g, "<span class='highlight-error'>[$1]</span>");
        content = content.replace(/\[(info)\]/g, "<span class='highlight-info'>[$1]</span>");

        // Check if there are json objects in the content, and if so, highlight them. Look for the first } match

        const jsonMatches = this.extractJsonsFromContent(content);

        if (jsonMatches) {
            jsonMatches.forEach((json) => {
                // replace the first match
                //content = content.replace(json, "<span class='highlight-json'>" + json + "</span>");

                // replace all matches with a span containing the json
                content = this.replaceAll(content, json, "<span class='highlight-json'>" + json + "</span>");
            });
        }

        // Highlight the filter text case insensitively. Do not highlight anything in HTML tags. (It would screw up previous highlights)
        if (this.filterText) {
            const filterRegex = new RegExp(`(?![^<]*>)(${this.filterText})`, "gi");
            content = content.replace(filterRegex, "<span class='highlight-filter'>$1</span>");
        }

        return content;
    }

    openDialog(event: Event): void {
        if (!(event.target instanceof HTMLElement) || this.isDialogOpen) {
            return;
        }

        const target = event.target as HTMLElement;
        const json = target.innerText;

        this.isDialogOpen = true;

        this.dialog
            .open(LoggingJsonDialogComponent, {
                minWidth: "40%",
                maxWidth: "80%",
                minHeight: "20%",
                maxHeight: "80%",
                data: { json: json },
            })
            .afterClosed()
            .subscribe(() => {
                this.isDialogOpen = false;
            });
    }

    refreshClick() {
        this.loading = true;

        // set selectedDate timezone to utc without changing the date
        this.selectedDate.setMinutes(this.selectedDate.getMinutes() - this.selectedDate.getTimezoneOffset());
        // get the date in the format of yyyy-mm-dd, in utc time
        const date = this.selectedDate.toISOString().substring(0, 10);

        this.logService
            .getLogs({ filter: this.filterText, n: this.lineCount, date: date })
            .then((response: LoggingResponse) => {
                this.logs.forEach((log) => {
                    log.content = this.hydrateContent(response.logContents[log.id] || "");
                    log.fileSize = response.fileSizes[log.id];
                });

                this.time = response.time;

                setTimeout(this.makeJSONsClickable.bind(this), 0); // wait for the DOM to update

                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }

    makeJSONsClickable() {
        const jsons = document.querySelectorAll(".highlight-json");

        jsons.forEach((json) => {
            json.addEventListener("click", this.openDialog.bind(this));
        });
    }

    // ****************************** //
    // JSON Helper functions.         //
    // ****************************** //
    extractJsonsFromContent(s: string): string[] {
        const res = [] as string[];

        let i = 0;
        let j = 0;
        let depth = 0;
        let inString = false;
        let inEscape = false;

        while (i < s.length) {
            const c = s[i];

            if (c === "{" && !inString) {
                if (depth === 0) {
                    j = i;
                }

                depth++;
            } else if (c === "}" && !inString) {
                depth--;

                if (depth === 0) {
                    res.push(s.substring(j, i + 1));
                }
            } else if (c === '"' && !inEscape) {
                inString = !inString;
            } else if (c === "\\") {
                inEscape = !inEscape;
            } else {
                inEscape = false;
            }

            i++;
        }

        return res;
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
    }
}

@Component({
    selector: "smd-logging-json-dialog",
    templateUrl: "./logging-json-dialog.component.html",
    styleUrls: ["./logging-json-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LoggingJsonDialogComponent {
    text: string;

    constructor(public dialogRef: MatDialogRef<LoggingJsonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { json: string }) {
        try {
            this.text = this.prettyPrint(JSON.parse(this.data.json));
        } catch (e) {
            this.text = "Invalid JSON.";
        }
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    replacer(match, pIndent, pKey, pVal, pEnd) {
        var key = "<span class=json-key>";
        var val = "<span class=json-value>";
        var str = "<span class=json-string>";
        var r = pIndent || "";
        if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
        if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
        return r + (pEnd || "");
    }

    prettyPrint(obj) {
        var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
        return JSON.stringify(obj, null, 3)
            .replace(/&/g, "&amp;")
            .replace(/\\"/g, "&quot;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(jsonLine, this.replacer);
    }
}
