import {debounceTime} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import {
    PartnerRules,
    PeriodUnits
} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.config';
import Utils from '~/src/app/core/utils';
import {FormGroup} from '@angular/forms';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {ArraySupport} from '~/src/app/core/helper/array-support';
import {PARTNER_BOOLEAN_PERMISSIONS} from '~/src/app/modules/administration/partners/partner.constant';

@Component({
    selector: 'smd-partner-config-form',
    templateUrl: './partner-config-form.component.html',
    styleUrls: ['./partner-config-form.component.scss']
})
export class PartnerConfigFormComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() formErrors = {};
    @Input() controlNames;
    @Input() config;
    @Input() totalPartnerConfig;
    @Input() enableUseDefaultConfigCheckbox = true;
    @Output() configChange = new EventEmitter();
    periodUnits = PeriodUnits;
    partnerRules = PartnerRules;
    useDefaultConfig = true;

    constructor(
        public language: LanguageService
    ) {}

    ngOnInit() {

        const hasConfig = !!this.config && !!Object.keys(this.config).length;
        const hasTotalPartnerConfig = this.totalPartnerConfig && (this.totalPartnerConfig instanceof Object);
        const hasRuleConfigInManual = hasConfig && ArraySupport.segment(
            Object.keys(this.config),
            PARTNER_BOOLEAN_PERMISSIONS
        ).length > 0;

        this.useDefaultConfig = this.useDefaultConfig &&
            this.enableUseDefaultConfigCheckbox &&
            (hasTotalPartnerConfig && !hasRuleConfigInManual);

        // Set rules form control default state
        this._setRulesFormControlState(this.useDefaultConfig);

        // Add change listener
        this._addFormGroupChangeListener();

        if (hasConfig || hasTotalPartnerConfig) {
            const config = this.config || {};
            const defaultRules = [];

            for (const name in (this.totalPartnerConfig || config)) {
                const value = (this.totalPartnerConfig || config)[name];

                if (value === 'yes') {
                    defaultRules.push(name);
                }
            }

            const values: any = {
                [this.controlNames.Rules]: defaultRules,
            };

            [
                this.controlNames.PostFromRssNumber,
                this.controlNames.RssUrlNumber,
                this.controlNames.UserNumber,
                this.controlNames.OrganizationNumber,
                this.controlNames.SocialSiteNumber,
                this.controlNames.PostTemplateNumber,
                // this.controlNames.MediaNumber,
                this.controlNames.RssFeedPeriod,
                // this.controlNames.MediaPeriod,
                this.controlNames.PostTemplatePeriod
            ].forEach(controlName => {
                let value = Utils.lodash.get(config, controlName, null);

                if (!!value || [0, '0'].indexOf(value) > -1) {
                    value = parseInt(value, null) || value;
                    values[controlName] = value;

                    if (value === -1 && Utils.lodash.has(this.formGroup.controls, controlName)) {
                        this.formGroup.get(controlName).disable();
                    }
                }
            });
            this.formGroup.patchValue(values, { emitEvent: true });
        }
    }

    /**
     * Set unlimited value
     * @param {MouseEvent} event
     * @param {string} controlName
     */
    toggleUnlimited(event: MouseEvent, controlName: string) {
        event.preventDefault();
        event.stopPropagation();

        if (Utils.lodash.has(this.formGroup.controls, controlName)) {
            if (this.formGroup.get(controlName).disabled) {
                this.formGroup.get(controlName).enable();
                this.formGroup.get(controlName).setValue(null);
            } else {
                this.formGroup.get(controlName).setValue(-1);
                this.formGroup.get(controlName).disable();
            }
        }
    }

    /**
     * Check the control is unlimited
     * @param {string} controlName
     * @return {boolean}
     */
    isUnlimited(controlName: string): boolean {
        return Utils.lodash.has(this.formGroup.controls, controlName) && (this.formGroup.get(controlName).value === -1);
    }

    /**
     * Check the control exist in the form group
     * @param {string} controlName
     * @return {boolean}
     */
    containThis(controlName: string): boolean {
        return this.formGroup.contains(controlName);
    }

    /**
     * Toggle use default config from plan/addon switch
     */
    toggleUseDefaultConfig() {
        this.useDefaultConfig = !this.useDefaultConfig;

        this._setRulesFormControlState(this.useDefaultConfig);
    }

    /**
     * Clear rules
     */
    clearRules(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (!this.useDefaultConfig) {
            this.formGroup.get(this.controlNames.Rules).setValue([]);
        }
    }

    /**
     * Add partner config form change listener
     * @private
     */
    private _addFormGroupChangeListener() {
        this.formGroup.valueChanges.pipe(
        debounceTime(350))
        .subscribe(() => {
            const modifiedConfig: any = {
                config: {}
            };

            Utils.lodash.forEach(this.formGroup.getRawValue(), (value, name) => {

                if ([
                    ...PartnersService.getAllConfigName(),
                    this.controlNames.Rules
                ].indexOf(name) > -1) {
                    switch (name) {
                        case this.controlNames.Rules:

                            if (this.useDefaultConfig) {
                                break;
                            }

                            if (!value || !(value instanceof Array)) {
                                value = [];
                            }

                            value.forEach(configName => {
                                modifiedConfig.config[configName] = 'yes';
                            });

                            if (this.enableUseDefaultConfigCheckbox) {
                                // get unsetted partner rules and set values 'no'
                                this.partnerRules
                                    .map(item => item.id)
                                    .filter(rule => !value.includes(rule))
                                    .forEach(rule => {
                                        modifiedConfig.config[rule] = 'no';
                                    });
                            }

                            break;

                        case this.controlNames.RssFeedPeriod:
                        // case this.controlNames.MediaPeriod:
                        case this.controlNames.PostTemplatePeriod:
                            if (!!value) {
                                modifiedConfig.config[name] = value;
                            }

                            break;

                        default:
                            if (!!value || value == 0) {
                                if (!!parseInt(value, null) || value == 0) {
                                    modifiedConfig.config[name] = value;
                                } else {
                                    modifiedConfig[name] = value;
                                }
                            }

                            break;
                    }
                }

            });

            this.configChange.emit(modifiedConfig);
        });
    }

    /**
     * Set rules form control state
     * @param {boolean} disabled
     * @private
     */
    private _setRulesFormControlState(disabled: boolean): void {
        if (disabled) {
            this.formGroup.get(this.controlNames.Rules).disable();
        } else {
            this.formGroup.get(this.controlNames.Rules).enable();
        }
    }
}
