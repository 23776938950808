import { Injectable } from '@angular/core';
import {Subject} from '~/node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutofeedPageService {
  public reloadAutofeed: Subject<any> = new Subject<any>();

  constructor() {
    this.reloadAutofeed.subscribe(() => {
      // do nothing
    });
  }
}
