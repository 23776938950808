import {Plugins} from './plugins';
import {MatPaginator} from '@angular/material/paginator';
import {EventEmitter} from '@angular/core';

export interface PaginationOptions {
    showFirstLastButtons: boolean;
    hidePageSize: boolean;
    length: number;
    pageIndex: number;
    pageSize: number;
    pageSizeOptions: number[];
}

export class PaginationController {

    onPaginationChange: EventEmitter<any> = new EventEmitter<any>();
    onSetPagination: EventEmitter<{
        offset: number,
        limit: number
    }> = new EventEmitter<{
        offset: number,
        limit: number
    }>();
    onSetItemLimit: EventEmitter<any> = new EventEmitter<any>();

    private _itemLimit: number = Plugins.pagination.pageSizeOptions[1];

    /**
     * Pagination default options
     *
     * @type {{showFirstLastButtons: boolean; hidePageSize: boolean; length: number; pageSize: number; pageIndex: number; pageSizeOptions: (number)[]}}
     */
    paginationOptions: PaginationOptions = {
        showFirstLastButtons: true,
        hidePageSize: false,
        length: 0,
        pageSize: this._itemLimit,
        pageIndex: 0,
        pageSizeOptions: Plugins.pagination.pageSizeOptions
    };

    pagination: MatPaginator;

    constructor() {}

    /**
     * Pagination change event
     *
     * @param {object} event
     */
    paginateChange(event) {
        this.setPagination(event);

        if (event.length) {
            this.paginationOptions.length = event.length;
        }

        this.onPaginationChange.emit(event);
    }

    setPagination(pagination) {
        this.paginationOptions.pageIndex = pagination.pageIndex;

        if (this.paginationOptions.pageSize !== pagination.pageSize) {
            this.paginationOptions.pageIndex = 0;
        }

        if (pagination.pageSize) {
            this.paginationOptions.pageSize = pagination.pageSize;
        }

        this.onSetPagination.emit({
            offset: this.paginationOptions.pageIndex * this.paginationOptions.pageSize,
            limit: this.paginationOptions.pageSize
        });
    }

    /**
     * Go to previous pagination page
     */
    goToPreviousPage(): void {
        const currentPageIndex = this.paginationOptions.pageIndex;

        if (currentPageIndex > 0) {
            this.paginationOptions.pageIndex = currentPageIndex - 1;
        }
    }

    /**
     * Go to first pagination page
     */
    goToFirstPage(): void {
        this.paginationOptions.pageIndex = 0;
    }

    /**
     * Get items limit
     *
     * @return {number}
     */
    get itemLimit(): number {
        return this._itemLimit;
    }

    /**
     * Set items limit
     *
     * @param {number} value
     */
    set itemLimit(value: number) {
        this.paginationOptions.pageSize = value;
        this._itemLimit = value;

        this.onSetItemLimit.emit(value);
    }

    setPaginator(pagination: MatPaginator) {
        this.pagination = pagination;
    }

    get requestParams() {
        return {
            limit: this.paginationOptions.pageSize,
            offset: this.paginationOptions.pageIndex * this.paginationOptions.pageSize,
        };
    }
}
