import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SocialPlatform} from '~/src/app/shared/types/social-platform-type';
import {SocialPlatformService} from '~/src/app/shared/components/social-platform-switcher/social-platform-switcher.service';
import { socialSiteAppearance } from '~/src/app/modules/social-media-post/social-media-platforms-config';

@Component({
    selector: 'smd-social-platform-switcher',
    templateUrl: './social-platform-switcher.component.html',
    styleUrls: ['./social-platform-switcher.component.scss']
})
export class SocialPlatformSwitcherComponent implements OnInit {
    public platforms: Array<SocialPlatform>;

    private storageKey;

    @Input() socialSites = [];
    @Output() socialPlatformsChange = new EventEmitter<Array<SocialPlatform>>();

    constructor(
        private service: SocialPlatformService
    ) {
        this.platforms = this.service.platforms;
    }

    ngOnInit(): void {
        this.saveData();
        this.platforms = this.platforms.filter(item => socialSiteAppearance?.[item.id]?.schedule !== false);
    }

    public setIsActiveByPlatform(platform): void {
        if (platform.isDisabled) {
            return;
        }
        platform.isActive = !platform.isActive;

        this.saveData();
        this.emitSave();
    }

    private saveData(): void {
        localStorage.setItem(this.storageKey, JSON.stringify(this.platforms));
    }

    private emitSave() {
        this.platforms.map(platform => {
            const hasSocialType: boolean = this.socialSites.findIndex(_platform => {
                return _platform.socialType === platform.id;
            }) > -1;

            platform.isDisabled = !hasSocialType;

            // make it to inactive when disabled
            if (platform.isDisabled) {
                platform.isActive = false;
            }

            return platform;
        });

        this.socialPlatformsChange.emit(this.platforms);
    }
}
