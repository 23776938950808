import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {RssBlogPostModel} from '~/src/app/modules/rss/models/rss-blog-post.model';
import {Subject} from 'rxjs';

@Component({
    selector: 'smd-rss-feed-list',
    templateUrl: './rss-feed-list.component.html',
    styleUrls: ['./rss-feed-list.component.scss']
})
export class RssFeedListComponent implements OnInit {
    @Input() source: RssBlogPostModel[] = [];
    @Input() fallbackMessage: string;
    @Input() resetPagination: Subject<any>;
    @Output() savedPost: EventEmitter<RssBlogPostModel> = new EventEmitter<RssBlogPostModel>();
    @Output() removedPost: EventEmitter<RssBlogPostModel> = new EventEmitter<RssBlogPostModel>();
    pagination = new PaginationController();

    constructor() {}

    ngOnInit() {
        if (this.resetPagination) {
            this.resetPagination.subscribe(() => this.pagination.paginationOptions.pageIndex = 0);
        }
    }

    emitSavedPost(rssBlogPost: RssBlogPostModel): void {
        this.savedPost.emit(rssBlogPost);
    }

    emitRemovedPost(blogPost: RssBlogPostModel): void {
        this.removedPost.emit(blogPost);
    }

}
