import {Component, ViewChild} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import {HashtagManagerComponent} from '~/src/app/modules/hashtag/components/hashtag-manager/hashtag-manager.component';
import {HashtagCollectionManagerComponent} from '~/src/app/modules/hashtag/components/hashtag-collection-manager/hashtag-collection-manager.component';

@Component({
  selector: 'smd-hashtag-manager-page',
  templateUrl: './hashtag-manager-page.component.html',
  styleUrls: ['./hashtag-manager-page.component.scss']
})
export class HashtagManagerPageComponent {
    @ViewChild(HashtagManagerComponent, {read: HashtagManagerComponent}) hashtagManager: HashtagManagerComponent;
    @ViewChild(HashtagCollectionManagerComponent, {read: HashtagCollectionManagerComponent}) hashtagCollectionManager: HashtagCollectionManagerComponent;

    constructor(
        public languageService: LanguageService,
    ) {}

    tabChange(index: number) {
        switch (index) {
            case 0:
                this.hashtagManager._getHashtagItems();

                break;

            case 1:
                this.hashtagCollectionManager._getHashtagCollections();

                break;
        }
    }

}
