import {NgModule} from '@angular/core';
import {DashboardFixComponent} from '~/src/app/modules/dashboard-fix/dashboard-fix.component';
import {CommonModule} from '@angular/common';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {InnerHtmlModule} from '~/src/app/directives/inner-html/inner-html.module';
import {ChartModule} from 'angular-highcharts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WidgetModule} from '~/src/app/modules/analytics/widget/widget.module';
import {RecentCommentsComponent} from './recent-comments/recent-comments.component';
import {TeamMembersOnlineOfflineComponent} from './team-members-online-offline/team-members-online-offline.component';
import {ImagePreloadModule} from '~/src/app/shared/image-preload.module';
import {DocumentsModule} from '~/src/app/modules/documents/documents.module';
import {SharedModule} from '~/src/app/shared/shared.module';
import {AnalyticsService} from '~/src/app/modules/analytics/analytics.service';
import {GridstackModule} from '@libria/gridstack';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule,
        InnerHtmlModule,
        ChartModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetModule,
        ImagePreloadModule,
        GridstackModule.forRoot(),
        DocumentsModule
    ],
    declarations: [
        DashboardFixComponent,
        RecentCommentsComponent,
        TeamMembersOnlineOfflineComponent,
    ],
    providers: [
        AnalyticsService
    ],
    exports: [
        DashboardFixComponent,
    ]
})
export class DashboardFixModule {}
