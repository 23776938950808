import { WidgetMetricOptionType } from "~/src/app/modules/analytics/widget/widget.interfaces";
import { MetricConfigs } from "~/src/app/modules/analytics/widget/metric-configs/metric.config";
import { BaseChartConfig } from "~/src/app/modules/analytics/widget/metric-configs/_base-chart-config";
import Utils from "~/src/app/core/utils";
import { MetricConfigHelper } from "../metric-config.helper";
import { ChartData } from "chart.js";
import * as moment from "moment";

// Clicks Options
export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_OVERVIEW: WidgetMetricOptionType = {
    id: "insta-clicks-overview",
    name: "Clicks overview",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_TOTAL: WidgetMetricOptionType = {
    id: "insta-clicks-total",
    name: "Total clicks",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_EMAIL_CONTACT: WidgetMetricOptionType = {
    id: "insta-clicks-email-contact",
    name: "Email contact clicks",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_DIRECTIONS: WidgetMetricOptionType = {
    id: "insta-clicks-directions",
    name: "Directions clicks",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_PHONE_CALL: WidgetMetricOptionType = {
    id: "insta-clicks-phone-call",
    name: "Phone call clicks",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_TEXT_MESSAGE: WidgetMetricOptionType = {
    id: "insta-clicks-text-message",
    name: "Text message clicks",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_WEBSITE: WidgetMetricOptionType = {
    id: "insta-clicks-website",
    name: "Website clicks",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

// Views Options
export const WIDGET_INSTAGRAM_METRIC_OPTION_VIEWS_PROFILE: WidgetMetricOptionType = {
    id: "insta-views-profiles",
    name: "Profile Views",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

// Posts Options
export const WIDGET_INSTAGRAM_METRIC_OPTION_TOP_POSTS_ENGAGEMENT: WidgetMetricOptionType = {
    id: "insta-posts-top-engagement",
    name: "Top posts by Engagement",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_TOP_POSTS_IMPRESSIONS: WidgetMetricOptionType = {
    id: "insta-posts-top-impression",
    name: "Top posts by Impression",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_TOTAL: WidgetMetricOptionType =
{
    id: "insta-posts-post-total",
    name: "Total posts published",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_VIDEOS: WidgetMetricOptionType =
{
    id: "insta-posts-post-videos",
    name: "Videos published",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_IMAGES: WidgetMetricOptionType =
{
    id: "insta-posts-post-images",
    name: "Images published",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_CAROUSELS: WidgetMetricOptionType =
{
    id: "insta-posts-post-carousels",
    name: "Carousels published",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY: WidgetMetricOptionType =
{
    id: "insta-posts-post-frequency",
    name: "Post frequency",
    resource: "analytics.analytics.socialSiteDataAccess",
    socialChannel: "instagram",
};

// Clicks Metrics
export const MetricInstagramClicksOverview: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_OVERVIEW.id,
    contentType: 'chart',
    dataName: 'Clicks overview',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    partMetricIDs: [
        WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_TOTAL.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_EMAIL_CONTACT.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_DIRECTIONS.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_PHONE_CALL.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_TEXT_MESSAGE.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_WEBSITE.id
    ]
};


export const MetricInstagramClicksEmailContact: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_EMAIL_CONTACT.id,
    apiURL: "/page-clicks",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Email Contact Clicks",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "email_contacts",
    },
};

export const MetricInstagramClicksDirections: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_DIRECTIONS.id,
    apiURL: "/page-clicks",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Directions Clicks",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "get_directions_clicks",
    },
};

export const MetricInstagramClicksPhoneCall: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_PHONE_CALL.id,
    apiURL: "/page-clicks",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Phone Call Clicks",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "phone_call_clicks",
    },
};

export const MetricInstagramClicksTextMessage: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_TEXT_MESSAGE.id,
    apiURL: "/page-clicks",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Text Message Clicks",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "text_message_clicks",
    },
};

export const MetricInstagramClicksWebsite: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_WEBSITE.id,
    apiURL: "/page-clicks",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Website Clicks",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "website_clicks",
    },
};

export const MetricInstagramClicksTotal: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_CLICKS_TOTAL.id,
    apiURL: "/page-clicks",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Total Clicks",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "total",
    },
};

// Views metrics

export const MetricInstagramViewsProfile: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_VIEWS_PROFILE.id,
    apiURL: "/page-views",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Profile Views",
    chartConfig: {
        ...BaseChartConfig,
    },
};

// Posts

export const MetricInstagramTopPostsByEngagementConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_TOP_POSTS_ENGAGEMENT.id,
    apiURL: "/top-posts-engagement",
    socialType: "instagram",
    contentType: "table",
    dataName: "Top Posts By Engagement",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        limit: 15,
    },
};

export const MetricInstagramTopPostsByImpressionConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_TOP_POSTS_IMPRESSIONS.id,
    apiURL: "/top-posts-impression",
    socialType: "instagram",
    contentType: "table",
    dataName: "Top Posts By Impression",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        limit: 15,
    },
};

export const MetricInstagramPostsPostFrequencyConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY.id,
    socialType: "instagram",
    contentType: "chart",
    dataName: "Post frequency",
    chartConfig: {
        ...BaseChartConfig,
    },
    partMetricIDs: [
        WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_TOTAL.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_IMAGES.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_VIDEOS.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_CAROUSELS.id,
    ]
};

export const MetricInstagramPostsPostFrequencyTotalConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_TOTAL.id,
    apiURL: "/post-frequency",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Posts published - total",
    chartConfig: {
        ...BaseChartConfig,
    },
};

export const MetricInstagramPostsPostFrequencyVideosConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_VIDEOS.id,
    apiURL: "/post-frequency",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Posts published - videos",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'VIDEO',
    },
};

export const MetricInstagramPostsPostFrequencyImagesConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_IMAGES.id,
    apiURL: "/post-frequency",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Posts published - images",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'IMAGE',
    },
};

export const MetricInstagramPostsPostFrequencyCarouselsConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_POSTS_POST_FREQUENCY_CAROUSELS.id,
    apiURL: "/post-frequency",
    socialType: "instagram",
    contentType: "chart",
    dataName: "Posts published - carousels",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'CAROUSEL_ALBUM',
    },
};

// Engagement
export const WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_LIKE_COUNT: WidgetMetricOptionType = {
    id: 'engagement-engagement-like-count',
    name: 'Engagement - Like Count',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_COMMENT_COUNT: WidgetMetricOptionType = {
    id: 'engagement-engagement-comment-count',
    name: 'Engagement - Comment Count',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_SAVE_COUNT: WidgetMetricOptionType = {
    id: 'engagement-engagement-save-count',
    name: 'Engagement - Save Count',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_REPLY_COUNT: WidgetMetricOptionType = {
    id: 'engagement-engagement-reply-count',
    name: 'Engagement - Reply Count',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_TOTAL_COUNT: WidgetMetricOptionType = {
    id: 'engagement-engagement-total-count',
    name: 'Engagement - Total Count',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_OVERVIEW: WidgetMetricOptionType = {
    id: 'engagement-engagement-overview-custom',
    name: 'Engagement Overview',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const MetricInstagramEngagementLikeConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_LIKE_COUNT.id,
    apiURL: '/page-engagement',
    contentType: 'chart',
    dataName: 'Like Count',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'likes',
    },
};

export const MetricInstagramEngagementReplyConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_REPLY_COUNT.id,
    apiURL: '/page-engagement',
    contentType: 'chart',
    dataName: 'Reply Count',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'replies',
    },
};

export const MetricInstagramEngagementCommentConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_COMMENT_COUNT.id,
    apiURL: '/page-engagement',
    contentType: 'chart',
    dataName: 'Comment Count',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'comments',
    },
};

export const MetricInstagramEngagementSaveConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_SAVE_COUNT.id,
    apiURL: '/page-engagement',
    contentType: 'chart',
    dataName: 'Save Count',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'saves',
    },
};

export const MetricInstagramEngagementTotalConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_TOTAL_COUNT.id,
    apiURL: '/page-engagement',
    contentType: 'chart',
    dataName: 'Total Count',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'engagement',
    },
};

export const MetricInstagramEngagementOverviewConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_OVERVIEW.id,
    contentType: 'chart',
    dataName: 'Save Count',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    partMetricIDs: [
        WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_TOTAL_COUNT.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_LIKE_COUNT.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_COMMENT_COUNT.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_SAVE_COUNT.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_ENGAGEMENT_REPLY_COUNT.id,
    ]
};

// Followers
export const WIDGET_INSTAGRAM_METRIC_OPTION_FOLLOWERS_OVERALL: WidgetMetricOptionType = {
    id: 'engagement-engagement-followers-overall',
    name: 'Followers Overall',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_FOLLOWERS_GROWTH: WidgetMetricOptionType = {
    id: 'engagement-engagement-followers-growth',
    name: 'Followers Overview', // this was Followers Growth
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const MetricInstagramFollowersGrowthConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_FOLLOWERS_GROWTH.id,
    apiURL: '/page-like-growth',
    socialType: 'instagram',
    contentType: 'chart',
    dataName: 'Followers Overview', // this was Followers Growth
    // set the firstPossibleDate to the current date minus 30 days
    //firstPossibleDate: moment().subtract(30, 'days').toDate(),    // comment this because it is not necessary with new analytics
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricInstagramFollowersOverallConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_FOLLOWERS_OVERALL.id,
    apiURL: '/page-overall-likes',
    socialType: 'instagram',
    contentType: 'html',
    dataName: 'Followers Overall',
    nullable: true,
    htmlTemplate: (dataName: string, response: any) => {
        return '<div><h2 style="text-align: center;">' +
            '<h2 style="text-align: center;"><strong>' + dataName + '</strong><strong><br></strong></h2>' +
            '<p>&nbsp;</p>' +
            '<h1 style="text-align: center;">' +
            '<i class="fa fa-user m-r-10 text-danger"></i>' +
            (response.analytics.values[response.analytics.values.length - 1] || 0) +
            '</h1></div>';
    },
    chartConfig: {
        ...BaseChartConfig
    },
};

// Stories
export const WIDGET_INSTAGRAM_METRIC_OPTION_STORIES_FREQUENCY: WidgetMetricOptionType = {
    id: 'insta-stories-frequency',
    name: 'Stories frequency',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORIES_PERFORMANCE: WidgetMetricOptionType = {
    id: 'insta-story-performance',
    name: 'Stories performance',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORY_EXITS: WidgetMetricOptionType = {
    id: 'insta-story-exits',
    name: 'Story exits',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORY_IMPRESSIONS: WidgetMetricOptionType = {
    id: 'insta-story-impressions',
    name: 'Story impressions',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORY_REACH: WidgetMetricOptionType = {
    id: 'insta-story-reach',
    name: 'Story reach',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORY_REPLIES: WidgetMetricOptionType = {
    id: 'insta-story-replies',
    name: 'Story replies',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORY_TAPS_FORWARD: WidgetMetricOptionType = {
    id: 'insta-story-taps-forward',
    name: 'Story taps forward',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const WIDGET_INSTAGRAM_METRIC_OPTION_STORY_TAPS_BACK: WidgetMetricOptionType = {
    id: 'insta-story-taps-back',
    name: 'Story taps back',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};


export const MetricInstagramStoriesFrequencyConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORIES_FREQUENCY.id,
    apiURL: "/post-frequency",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Stories frequency',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'STORY',
    },
};

export const MetricInstagramStoryExitsConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORY_EXITS.id,
    apiURL: "/story-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Story exits',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'exits',
    },
};

export const MetricInstagramStoryRepliesConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORY_REPLIES.id,
    apiURL: "/story-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Story replies',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'replies',
    },
};

export const MetricInstagramStoryTapsForwardConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORY_TAPS_FORWARD.id,
    apiURL: "/story-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Story taps forward',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'taps_forward',
    },
};

export const MetricInstagramStoryTapsBackConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORY_TAPS_BACK.id,
    apiURL: "/story-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Story taps back',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'taps_back',
    },
};

export const MetricInstagramStoryImpressionsConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORY_IMPRESSIONS.id,
    apiURL: "/story-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Story impressions',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'impressions',
    },
};

export const MetricInstagramStoryReachConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORY_REACH.id,
    apiURL: "/story-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Story reach',
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: 'reach',
    },
};

export const MetricInstagramStoriesPerformanceConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_STORIES_PERFORMANCE.id,
    contentType: 'chart',
    dataName: 'Stories performance',
    socialType: 'instagram',
    chartConfig: {
        ...BaseChartConfig
    },
    partMetricIDs: [
        WIDGET_INSTAGRAM_METRIC_OPTION_STORY_REPLIES.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_STORY_TAPS_BACK.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_STORY_TAPS_FORWARD.id,
        WIDGET_INSTAGRAM_METRIC_OPTION_STORY_EXITS.id,
    ]
};

// Audience
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE: WidgetMetricOptionType = {
    id: 'insta-audience-by-age',
    name: 'Audience by Age',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE_MEN: WidgetMetricOptionType = {
    id: 'insta-audience-by-age-men',
    name: 'Audience by Age (Men)',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE_WOMEN: WidgetMetricOptionType = {
    id: 'insta-audience-by-age-women',
    name: 'Audience by Age (Women)',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE_UNSPECIFIED: WidgetMetricOptionType = {
    id: 'insta-audience-by-age-unspecified',
    name: 'Audience by Age (Unspecified)',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_GENDER: WidgetMetricOptionType = {
    id: 'insta-audience-by-gender',
    name: 'Audience by Gender',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_COUNTRIES: WidgetMetricOptionType = {
    id: 'insta-audience-by-countries',
    name: 'Audience by Countries',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};
export const WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_CITIES: WidgetMetricOptionType = {
    id: 'insta-audience-by-cities',
    name: 'Audience by Cities',
    resource: 'analytics.analytics.socialSiteDataAccess',
    socialChannel: 'instagram'
};

export const MetricInstagramAudienceByAgeConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Age',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum,
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'age',
    },
};

export const MetricInstagramAudienceByAgeMenConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE_MEN.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Age (Men)',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum,
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'age_men',
    },
};

export const MetricInstagramAudienceByAgeWomenConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE_WOMEN.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Age (Women)',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum,
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'age_women',
    },
};

export const MetricInstagramAudienceByAgeUnspecifiedConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_AGE_UNSPECIFIED.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Age (Unspecified)',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum,
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'age_unspecified',
    },
};

export const MetricInstagramAudienceByGenderConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_GENDER.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Gender',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum,
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'gender',
    },
};

export const MetricInstagramAudienceByCountriesConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_COUNTRIES.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Countries',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum + " (Top 45 countries only)",
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'countries',
    },
};

export const MetricInstagramAudienceByCitiesConfig: MetricConfigs = {
    metricID: WIDGET_INSTAGRAM_METRIC_OPTION_AUDIENCE_BY_CITIES.id,
    apiURL: "/audience-metrics",
    socialType: "instagram",
    contentType: "chart",
    dataName: 'Audience by Cities',
    getDatasets: (analytics: any) => {
        const labels = Object.keys(analytics['values']);
        const chartData: ChartData = {
            datasets: [],
            labels: labels,
        };
        const datas = {};
        let sum: number = 0;

        for (const key in analytics['values']) {
            const value = analytics['values'][key];
            datas[key] = value;
            sum += value;
        }

        for (const propertyName in datas) {
            const values = datas[propertyName];

            chartData.datasets.push({
                data: values,
                label: "Sum: " + sum + " (Top 45 cities only)",
            });
        }

        return chartData;
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'cities',
    },
};
