import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxMatDrpModule} from 'ngx-mat-daterange-picker';
import {AnalyticsComponent} from './analytics.component';
import {GridstackModule} from '@libria/gridstack';
import {ChartModule} from 'angular-highcharts';
import {LanguagesModule} from '../languages/languages.module';
import {CreateWidgetModule} from './create-widget/create-widget.module';
import {EditWidgetModule} from './edit-widget/edit-widget.module';
import {InnerHtmlModule} from '../../directives/inner-html/inner-html.module';
import {CreateDashboardModalComponent} from './modals/create-dashboard-modal/create-dashboard-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UpdateDashboardModalComponent} from './modals/update-dashboard-modal/update-dashboard-modal.component';
import {WidgetModule} from '~/src/app/modules/analytics/widget/widget.module';
import {ExportDashboardModalComponent} from './modals/export-dashboard-modal/export-dashboard-modal.component';
import {SlugifyPipe} from '~/src/app/services/slugify.pipe';
import {SharedModule} from '~/src/app/shared/shared.module';
import {AnalyticsService} from '~/src/app/modules/analytics/analytics.service';
import {ShareDashboardComponent} from '~/src/app/modules/analytics/share-dashboard/share-dashboard.component';
import {ShareDashboardSettingsComponent} from '~/src/app/modules/analytics/share-dashboard/share-dashboard-settings/share-dashboard-settings.component';
import { DuplicateDashboardModalComponent } from './modals/duplicate-dashboard-modal/duplicate-dashboard-modal.component';

@NgModule({
    imports: [
        CommonModule,
        NgxMatDrpModule,
        SharedModule,
        LanguagesModule,
        InnerHtmlModule,
        ChartModule,
        GridstackModule.forRoot(),
        CreateWidgetModule,
        EditWidgetModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetModule
    ],
    declarations: [
        AnalyticsComponent,
        CreateDashboardModalComponent,
        UpdateDashboardModalComponent,
        DuplicateDashboardModalComponent,
        ExportDashboardModalComponent,
        SlugifyPipe,
        ShareDashboardComponent,
        ShareDashboardSettingsComponent,
    ],
    providers: [
        AnalyticsService
    ],
    exports: [
        AnalyticsComponent,
        ShareDashboardComponent,
        ShareDashboardSettingsComponent,
        SlugifyPipe
    ],
    entryComponents: [
        CreateDashboardModalComponent,
        UpdateDashboardModalComponent,
        DuplicateDashboardModalComponent,
        ExportDashboardModalComponent,
        ShareDashboardComponent,
    ]
})
export class AnalyticsModule {}
