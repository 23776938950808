import {IAutocompleteItem} from '~/src/app/modules/posts/autocomplete.interface';
import {PostFormControlNames} from '~/src/app/modules/social-media-post/social-media-post.constant';
import {IMentionViewItem} from '~/src/app/modules/social-media-post/post-skeleton/mention-list/mention-list';
import {SocialMediaPlatform} from '~/src/app/modules/social-media-post/social-media-post.interface';
import {MENTION_TAG_ELEMENT_CLASS} from '~/src/app/modules/mention/mention.contant';

export class MentionHelpers {
    /**
     * @param {string} value
     * @param {string} platform
     * @param {() => string} formatter
     * @return {string}
     */
    static formatMention(value: string, platform: string, formatter: (mention: any) => string) {
        value = value || '';

        const tempEl = document.createElement('div');
        let autocompleteString = null;
        let dummy = null;
        tempEl.innerHTML = value;

        if ((autocompleteString = tempEl.querySelector('#autocomplete'))) {
            autocompleteString = autocompleteString.outerHTML;
            dummy = tempEl.querySelector('.dummy') && tempEl.querySelector('.dummy').innerHTML ? tempEl.querySelector('.dummy').innerHTML :  '';
        }

        const mentions = tempEl.querySelectorAll(`.${MENTION_TAG_ELEMENT_CLASS}`);

        if (mentions && mentions.length) {
            for (const mentionNode of Array.from(mentions)) {
                const mentionData = mentionNode['dataset'];

                if (mentionData && (mentionData instanceof Object)) {
                    const mentionUids = (mentionData['itemUids'] || '').split(',');

                    if (mentionUids && (mentionUids instanceof Array)) {
                        const mentionUID = mentionUids.find(uid => uid.split(':')[0] === platform);
                        const replaceTagWithText = () => {
                            value = value.replace(mentionNode.outerHTML, (mentionNode as HTMLElement).innerText);
                        };

                        if (mentionUID) {
                            const _mention = mentionUID.split(':');

                            if (formatter && (formatter instanceof Function)) {
                                let id, _platform, name;

                                if (_mention instanceof Array) {
                                    _platform = _mention[0];
                                    id = _mention[1];
                                    name = (mentionNode as HTMLElement).innerText;
                                } else {
                                    _platform = (_mention as IAutocompleteItem).platform;
                                    id = (_mention as IAutocompleteItem).id;
                                    name = (_mention as IAutocompleteItem).name;
                                }

                                value = value.replace(mentionNode.outerHTML, formatter({
                                    platform: _platform,
                                    id,
                                    name
                                }));
                            } else {
                                replaceTagWithText();
                            }
                        } else {
                            if (platform === 'default') {
                                replaceTagWithText();
                            } else {
                                value = value.replace(mentionNode.outerHTML, '');
                            }
                        }
                    }
                }
            }
        }

        if (autocompleteString) {
            
            value = value.replace(autocompleteString, '#'+dummy);
        }

        tempEl.remove();

        return value;
    }

    /**
     * @param data
     * @param {string} platform
     * @param {SocialMediaPlatform} config
     * @return {any}
     */
    static prepareMentions(data: any, platform: string, config: SocialMediaPlatform): any {
        // mention is disabled on the current platform, so replace every mention with simple string
        if (config && !config.enableMention) {
            if (data && PostFormControlNames.SelectedMentions in data) {
                delete data[PostFormControlNames.SelectedMentions];
            }

            [
                PostFormControlNames.Headline,
                PostFormControlNames.SubHeadline,
                PostFormControlNames.Message,
                PostFormControlNames.Signature
            ].forEach(controlName => {
                if (data && data[controlName]) {
                    const tempEl = document.createElement('div');
                    tempEl.innerHTML = data[controlName];

                    for (const tagNode of Array.from(tempEl.querySelectorAll(`.${MENTION_TAG_ELEMENT_CLASS}`))) {
                        data[controlName] = data[controlName].replace(tagNode.outerHTML, tagNode.textContent);
                    }

                    tempEl.remove();
                }
            });

            return data;
        }

        // post content contain mention tags
        if (data && data[PostFormControlNames.SelectedMentions] && Object.keys(data[PostFormControlNames.SelectedMentions]).length) {
            const selectedMentions: { [key: string]: IMentionViewItem[] } = data[PostFormControlNames.SelectedMentions];
            [
                PostFormControlNames.Headline,
                PostFormControlNames.SubHeadline,
                PostFormControlNames.Message,
                PostFormControlNames.Signature
            ].forEach(controlName => {
                const fieldSelectedMentions = selectedMentions[controlName];

                // current field contain mention tag
                if (fieldSelectedMentions && fieldSelectedMentions.length) {
                    selectedMentions[controlName] = fieldSelectedMentions.map(fieldMentionTag => {
                        const filteredItems = fieldMentionTag.items.filter(_item => _item.platform === platform);

                        fieldMentionTag.items = filteredItems;

                        // current field contain current platform type mention
                        if (filteredItems.length) {
                            const isContainLabeledItem = !!filteredItems.filter(_item => _item.name === fieldMentionTag.label.substr(1)).length;
                            let newLabel = null;
                            let newLink = null;

                            if (!isContainLabeledItem) {
                                newLabel = fieldMentionTag.label.substr(0, 1) + filteredItems[0].name;
                                newLink = filteredItems[0].link;
                                fieldMentionTag.label = newLabel;
                            }

                            // remove mention UIDs from tag node dataset if not contain the items
                            if (data[controlName]) {
                                const tempEl = document.createElement('div');
                                tempEl.innerHTML = data[controlName];
                                const tagNode = tempEl.querySelector(`[data-uid="${fieldMentionTag.uid}"]`) as HTMLLinkElement;

                                if (tagNode) {
                                    const outerHtml = tagNode.outerHTML;

                                    if (newLabel) {
                                        tagNode.innerText = newLabel;
                                        tagNode.href = newLink || tagNode.href;
                                    }

                                    let tagNodeItemUIDs = tagNode.dataset['itemUids'].split(',');
                                    if (tagNodeItemUIDs && (tagNodeItemUIDs instanceof Array)) {
                                        tagNodeItemUIDs = tagNodeItemUIDs.filter(_uid => !!fieldMentionTag.items.filter(_item => _item.uid.toString() === _uid).length);
                                        tagNode.setAttribute('data-item-uids', tagNodeItemUIDs.join(','));
                                        data[controlName] = data[controlName].replace(outerHtml, tagNode.outerHTML);
                                    }
                                }

                                tempEl.remove();
                            }
                        } else {
                            // replace mention tag with simple string
                            if (data[controlName]) {
                                const tempEl = document.createElement('div');
                                tempEl.innerHTML = data[controlName];
                                const outerHtml = tempEl.querySelector(`[data-uid="${fieldMentionTag.uid}"]`).outerHTML;
                                data[controlName] = data[controlName].replace(outerHtml, fieldMentionTag.label);
                                tempEl.remove();
                            }
                        }

                        return fieldMentionTag;
                    }).filter(_tagItem => _tagItem.items.length);
                }

                if (selectedMentions[controlName] && !selectedMentions[controlName].length) {
                    delete selectedMentions[controlName];
                }
            });

            data[PostFormControlNames.SelectedMentions] = selectedMentions;
        }

        return data;
    }
}
