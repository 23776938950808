import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ChooserModalOptions } from '../../modules/social-media-post/open-modal.interfaces';
import { MatRadioChange } from '@angular/material/radio';


@Component({
  selector: 'smd-dialog-chooser',
  templateUrl: './dialog-chooser.component.html',
  styleUrls: ['./dialog-chooser.component.scss']
})

export class DialogChooserComponent implements OnInit
{
  title = '';
  submitDisabled: boolean = true;

  chooserControlNames = {
    Options: 'options',
  };

  chooserFormGroup = new FormGroup({
    [this.chooserControlNames.Options]: new FormControl(null, [Validators.required]),
  });

  displayChooser: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChooserModalOptions,
  ) {
    if (!!this.data['title']) {
      this.title = this.data['title'];
    } else {
      this.title = 'Choose an option';
    }

    this.displayChooser = this.data['displayChooser'] === null ? true : this.data['displayChooser'];
    if (this.displayChooser === false) {
      this.chooserFormGroup.controls[this.chooserControlNames.Options].setValue('noAction');
      this.submitDisabled = false;
    }
  }

  ngOnInit() {
    if (this.displayChooser === false) {
      this.submitDisabled = false;
    }
  }

  selectionChange(event: MatRadioChange) {
    if (event.value !== null) {
      this.submitDisabled = false;
    }
  }
}