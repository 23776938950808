export const TEMPLATE_FOLDER_TYPE_USER = 'user';
export const TEMPLATE_FOLDER_TYPE_ORGANIZATION = 'organization';

export const TEMPLATE_FOLDER_TYPES = [
    TEMPLATE_FOLDER_TYPE_USER,
    TEMPLATE_FOLDER_TYPE_ORGANIZATION,
];

export const TEMPLATE_FOLDER_STATUS_ACTIVE = 'active';
export const TEMPLATE_FOLDER_STATUS_ARCHIVED = 'archived';

export const TEMPLATE_FOLDER_STATUSES = [
    TEMPLATE_FOLDER_STATUS_ACTIVE,
    TEMPLATE_FOLDER_STATUS_ARCHIVED,
];
