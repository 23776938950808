import { Component, EventEmitter, Input, SimpleChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotifyService } from '~/src/app/services/notify.service';
import { FormValidationService } from '~/src/app/services/form.validation.service';
import { OrganizationController } from '~/src/app/components/organization-select/organization.component';
import { OrganizationItem } from '~/src/app/components/organization-select/organization.interfaces';
import { FormControl } from '@angular/forms';
import { LanguageService } from '~/src/app/services/language.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { OrganisationService } from "~/src/app/modules/users/organizations/organisation.service";
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { IOrganizationTreeItem } from '~/src/app/modules/users/organizations/organization.interface';
import { ConsoleLoggerService } from '~/src/app/shared/services/log/console-logger.service';

interface OrganizationNode {
    name: string,
    id: string,
    children?: OrganizationNode[],

    // extra info to optimize the tree rendering
    allChildrenSelected?: boolean,
    selected?: boolean,
    locked?: boolean,
}

@Component({
    selector: 'smd-organization-select',
    templateUrl: './organization-select.component.html',
    styleUrls: ['./organization-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrganizationSelectComponent {
    @Input('multiple') multiple = false;
    @Input('onlyChangeOnClose') onlyChangeOnClose = true;
    @Input('organizationControl') organizationControl: FormControl;
    @Input('errorMessage') errorMessage: string;
    @Input('required') required = false;
    
    @Input('placeholderText') placeholderText: string = LanguageService.getLine("choose.organization");

    // disabled is untested, but should work
    // we needed this workaround because simply disabling an option would hide the checkbox
    @Input('lockedAndEnabledOrganizationIDs') lockedAndEnabledOrganizationIDs: number[] = [];
    @Input('lockedAndDisabledOrganizationIDs') lockedAndDisabledOrganizationIDs: number[] = [];

    @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
    organizations: OrganizationItem[] = [];
    organizationGetting = false;

    @ViewChild("noJumpSelector") selector: MatSelect;
    treeControl = new NestedTreeControl<OrganizationNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<OrganizationNode>();
    hierarchyView = true;

    lastValue: [];

    selectAllText: string = LanguageService.getLine("filters.select.all");
    selectAllIcon: string = "layers";

    selectPanelClass: "normal" | "wide" = "normal";

    constructor(
        public languageService: LanguageService,
        private organizationController: OrganizationController,
        private organizationService: OrganisationService,
    ) {
        this.getOrganizations();
        this.getOrganizationTree();
    }

    hasChild = (_: number, node: OrganizationNode) => !!node.children && node.children.length > 0;

    // this will cause the dropdown *NOT* jump to the clicked option
    // because in the hierarchy view, it caused the dropdown to scroll to the wrong positions
    ngAfterViewInit(): void {
        (<any>this.selector).baseonselect = (<any>this.selector)._onSelect;
        (<any>this.selector)._onSelect = (ev, isUserInput) => {
            (<any>this.selector).baseonselect(ev, false);
        };
        (<any>this.selector)._getItemHeight = () => 0;
        (<any>this.selector)._scrollToOption = () => null;

        //this.updateRenderData();
    }

    ngOnInit() {
        if (Array.isArray(this.organizationControl.value)) {
            if (this.organizationControl?.value && this.organizationControl.value.length > 0) {
                this.organizationControl.setValue(this.organizationControl.value.filter(value => value !== null) || []);
            } else {
                this.organizationControl.setValue(this.lockedAndEnabledOrganizationIDs);
            }
        } else {
            if (this.organizationControl?.value == null && this.multiple) {
                this.organizationControl.setValue(this.lockedAndEnabledOrganizationIDs);
            } else if (this.organizationControl?.value == null) {
                this.organizationControl.setValue(null);
            }
        }
        
        this.organizationControl.valueChanges.subscribe(value => {
            if (value || value === []) {
                if (this.multiple) {
                    this.organizationControl.setValue(value.concat(this.lockedAndEnabledOrganizationIDs).filter((id) => id != null && !this.lockedAndDisabledOrganizationIDs?.includes(id)), { emitEvent: false });
                } else {
                    this.organizationControl.setValue(value, { emitEvent: false });
                }
                this.updateRenderData();
            }
        });
    }

    /**
     * Trigger selection change event
     * @param {MatSelectChange} change
     * @param {boolean} onClose
     */
    emitSelectionChange(change: MatSelectChange, onClose: boolean = false) {
        this.updateRenderData();
        if (this.onlyChangeOnClose && !onClose) {
            return;
        }

        const lastValue = (Array.isArray(this.lastValue)) ? this.lastValue : [this.lastValue];
        const changeValue = (Array.isArray(change.value)) ? change.value : [change.value];
        // if the value is the same as the last value, don't emit the event
        if (lastValue && lastValue.length === changeValue.length) {
            let same = true;
            for (let i = 0; i < lastValue.length; i++) {
                if (lastValue[i] !== changeValue[i]) {
                    same = false;
                    break;
                }
            }
            if (same) {
                return;
            }
        }

        this.lastValue = change.value;
        this.selectionChange.emit(change);
    }

    private getOrganizations() {
        this.organizationGetting = true;
        this.organizationController.getItems(
            response => {
                this.organizations = response.organizations;
                this.organizationGetting = false;
            },
            error => {
                NotifyService.error(FormValidationService.readError(error).message, '');
                this.organizationGetting = false;
            }
        );
    }

    selectAll(select: FormControl, dataArray, modelValueProperty) {
        // deselect all if everything is selected, and select all otherwise
        if (dataArray?.filter(item => !this.lockedAndEnabledOrganizationIDs?.includes(item[modelValueProperty]) && !this.lockedAndDisabledOrganizationIDs?.includes(item[modelValueProperty])).every((item) => select.value?.includes(item[modelValueProperty]))) {
            select.setValue(this.lockedAndEnabledOrganizationIDs);
        } else {
            select.setValue(dataArray.map((item) => item[modelValueProperty]).filter(id => !this.lockedAndDisabledOrganizationIDs?.includes(id)).concat(this.lockedAndEnabledOrganizationIDs));
        }
        this.emitSelectionChange({ value: select.value } as MatSelectChange);
    }

    updateSelectAllData() {
        if (!this.organizationControl) return;
        let allOrgs = this.organizations.map((item) => item['organizationID']);
        allOrgs = allOrgs?.filter(id => !this.lockedAndEnabledOrganizationIDs?.includes(id) && !this.lockedAndDisabledOrganizationIDs?.includes(id));

        if (allOrgs.length > 0 && this.organizationControl.value?.length > 0 && allOrgs.every((item) => this.organizationControl.value?.includes(item))) {
            this.selectAllText = LanguageService.getLine("filters.deselect.all");
            this.selectAllIcon = "layers_clear";
        } else {
            this.selectAllText = LanguageService.getLine("filters.select.all");
            this.selectAllIcon = "layers";
        }
    }

    private updateRenderData() { // update node data for rendering related tooltips etc
        if (!this.organizationControl) return;

        let selectedOrgs = (Array.isArray(this.organizationControl.value)) ? this.organizationControl.value : [this.organizationControl.value];
        const parseDataSourceNodes = (node) => {
            node.selected = selectedOrgs?.includes(node.id);
            node.locked = this.lockedAndEnabledOrganizationIDs.includes(parseInt(node.id)) || this.lockedAndDisabledOrganizationIDs.includes(parseInt(node.id));
            node.allChildrenSelected = this.isAllSelectedBelow(node);
            if (node.children) {
                node.children.forEach(child => parseDataSourceNodes(child));
            }
        };

        this.dataSource.data = this.dataSource.data.map(node => {
            parseDataSourceNodes(node);
            return node;
        });
        
        this.updateSelectAllData();
    }

    private getOrganizationTree() {
        this.organizationService.getOrganization().then((response) => {
            this.dataSource.data = this.parseTreeObjectRecursively(response["organizationTree"]);
            //this.organizationControl?.setValue(this.organizationControl.value?.filter(value => value !== null) || []); // to trigger the selection change event & preselect locked and enabled organizations
            this.treeControl.dataNodes = this.dataSource.data;
            this.treeControl.expandAll();
            this.updateRenderData();
        });
    }

    selectOpenStateChanged($event) {
        if (!$event) {
            this.emitSelectionChange({ value: this.organizationControl.value } as MatSelectChange, true);
        }
    }

    // This function will parse the response from the organization-tree endpoint into the format we can use with mat-tree
    private parseTreeObjectRecursively(object) {
        let returnArray = [];
        Object.keys(object).sort((a, b) => {
            return object[a].name.localeCompare(object[b].name);
        }).forEach((key) => {
            let value = object[key];
            if (value.children) {
                returnArray.push({ id: parseInt(key), name: value.name, children: this.parseTreeObjectRecursively(value.children) })
            } else {
                returnArray.push({ id: parseInt(key), name: value.name });
            }

        });
        return returnArray;
    }

    private getDescendantIds(node) {
        let ids = [];
        if (node.children) {
            node.children.forEach(child => {
                ids.push(child.id);
                ids = ids.concat(this.getDescendantIds(child));
            });
        }
        return ids;
    }

    isAllSelectedBelow(node) {
        let nodeAndDescendants = this.getDescendantIds(node).concat(node.id);
        let selectedOrgs = (Array.isArray(this.organizationControl.value)) ? this.organizationControl.value : [this.organizationControl.value];

        nodeAndDescendants = nodeAndDescendants?.filter(id => !this.lockedAndEnabledOrganizationIDs?.includes(id) && !this.lockedAndDisabledOrganizationIDs?.includes(id));

        return nodeAndDescendants?.every(id => selectedOrgs?.includes(id));
    }

    selectAllDescendants(node) {
        let selectedOrgs = this.getDescendantIds(node).concat(node.id);
        if (!this.isAllSelectedBelow(node)) {
            selectedOrgs = Array.from(new Set(selectedOrgs.concat(this.organizationControl.value)));
        } else {
            // deselect all descendants if all descendants are already selected
            selectedOrgs = Array.from(new Set(this.organizationControl.value.filter(id => !selectedOrgs?.includes(id))));
        }
        // remove locked orgs
        selectedOrgs = selectedOrgs?.filter(id => !this.lockedAndDisabledOrganizationIDs?.includes(id)).concat(this.lockedAndEnabledOrganizationIDs);
        this.organizationControl.setValue(selectedOrgs);
        this.emitSelectionChange({ value: selectedOrgs } as MatSelectChange);
    }

    getFilterTooltip(node) {
        return LanguageService.getLine("filters." + (this.isAllSelectedBelow(node) ? "de" : "") + "select.all.child.organizations");
    }

    isOptionLocked(organizationId) {
        return this.lockedAndEnabledOrganizationIDs?.includes(organizationId) || this.lockedAndDisabledOrganizationIDs?.includes(organizationId);
    }

    onWidenClick($event) {
        if (!$event) return;

        this.selector.close();
        
        setTimeout(() => {
            if (this.selectPanelClass === 'normal') {
                this.selectPanelClass = 'wide';
            } else {
                this.selectPanelClass = 'normal';
            }

            this.selector.open();
        }, 300);
    }

    selectNode(node) {
        // get the mat-option for the node
        let option = this.selector.options.find(option => option.value === node.id);

        // select/deselect the option
        if (option.selected) {
            option.deselect();
        }
        else {
            option.select();
        }

        // close the select
        this.selector.close();
    }
}
