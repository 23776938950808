import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import Utils from '~/src/app/core/utils';

export const WIDGET_METRIC_OPTION_ACTIVITIES_BY_TEAM_MEMBERS: WidgetMetricOptionType = {
    id: 'activites-by-team-members',
    name: 'Activities by Team Members',
    resource: 'analytics.analytics.activitiesByTeamMembers',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_ACTIVITIES_BY_ORGANIZATIONS: WidgetMetricOptionType = {
    id: 'activites-by-organizations',
    name: 'Activities by Organizations',
    resource: 'analytics.analytics.activitiesByOrganizations',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_POSTS_BY_ORIGIN: WidgetMetricOptionType = {
    id: 'posts-by-origin',
    name: 'Published posts by Organisations',
    resource: 'analytics.analytics.organizationDataAccess',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_POSTS_BY_PUBLISHING_STATUS: WidgetMetricOptionType = {
    id: 'posts-by-publishing-status',
    name: 'Posts by Publishing Status',
    resource: 'analytics.analytics.organizationDataAccess',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_POSTS_BY_APPROVAL_STATUS: WidgetMetricOptionType = {
    id: 'posts-by-approval-status',
    name: 'Posts by Approval Status',
    resource: 'analytics.analytics.organizationDataAccess',
    socialChannel: 'facebook'
};

// METRIC CONFIGS

export const MetricActivitiesByTeamMembersConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_ACTIVITIES_BY_TEAM_MEMBERS.id,
    apiURL: '/activities-by-team-members',
    contentType: 'chart',
    dataName: 'Activities',
    socialType: 'facebook',
    extensionType: 'user',
    multiSocialSite: false,
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                }
            }
        }
    )
};

export const MetricActivitiesByOrganizationsConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_ACTIVITIES_BY_ORGANIZATIONS.id,
    apiURL: '/activities-by-organizations',
    contentType: 'chart',
    dataName: 'Activities',
    socialType: 'facebook',
    extensionType: 'organization',
    multiSocialSite: false,
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                }
            }
        }
    )
};

export const MetricPostsByOriginConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_POSTS_BY_ORIGIN.id,
    apiURL: '/posts-by-origin',
    contentType: 'chart',
    dataName: 'Published posts by Organisations',
    socialType: 'facebook',
    extensionType: 'organization',
    multiSocialSite: false,
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                }
            }
        }
    )
};

export const MetricPostsByPublishingStatusConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_POSTS_BY_PUBLISHING_STATUS.id,
    apiURL: '/posts-by-publishing-status',
    contentType: 'chart',
    dataName: 'Posts by publishing status',
    socialType: 'facebook',
    extensionType: 'organization',
    multiSocialSite: false,
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear',
                        ticks: {
                            callback: function(value, index, values) {
                                return Math.abs(value);
                            }
                        }
                    }],
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ': ' + Math.abs(tooltipItem.value);
                        },
                    },
                },
            }
        }
    )
};

export const MetricPostsByApprovalStatusConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_POSTS_BY_APPROVAL_STATUS.id,
    apiURL: '/posts-by-approval-status',
    contentType: 'chart',
    dataName: 'Posts by approval status',
    socialType: 'facebook',
    extensionType: 'organization',
    multiSocialSite: false,
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
                /*annotation: {
                    annotations: [{
                        drawTime: 'afterDatasetsDraw',
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        value: 33,
                        borderColor: 'rgb(255, 20, 20)',
                        borderWidth: 2,
                    }]
                  }*/
            },
        }
    )
};