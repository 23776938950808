import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { PostSkeletonComponent } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.component";
import { GmbFormComponent } from "~/src/app/modules/social-media-post/gmb/form/gmb-form.component";
import { POST_OPTIONS_KEY } from "~/src/app/modules/social-media-post/gmb/gmb.constants";
import { POST_TYPE_DRAFT } from "../social-media-post.constant";
@Component({
    selector: "smd-gmb-post-skeleton",
    templateUrl: "./post-skeleton.component.html",
    styleUrls: ["./post-skeleton.component.scss"],
})
export class GmbPostSkeletonComponent
    extends PostSkeletonComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild(GmbFormComponent) gmbForm: GmbFormComponent;

    panelName: string = "gmbOptions";

    initializeOnInit() {
        super.initializeOnInit();
        this.messageTitle = "Write your post";
        this.expansionPanelHasFormControls[POST_OPTIONS_KEY] =
            this.socialMediaConfig?.formControlsInExpansionPanel[
                POST_OPTIONS_KEY
            ];
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        this.postFormGroup.addControl("gmbOptions", this.gmbForm.getForm());

        if (
            this.currentPost &&
            (this.currentPost.gmbOptions ||
                (this.currentPost.type === POST_TYPE_DRAFT &&
                    this.currentPost.draftSettings.gmb &&
                    this.currentPost.draftSettings.gmb.gmbOptions))
        ) {
            const gmbOptions = this.currentPost.gmbOptions
                ? this.currentPost.gmbOptions
                : this.currentPost.draftSettings.gmb.gmbOptions;

            this.gmbForm.setGmbFormByGmbOptions(
                gmbOptions,
                this.postFormGroup.get("gmbOptions")
            );
        }

        if (this.isEntityCommentMode) {
            this.gmbForm.getForm().disable();
        }
    }
}
