import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    AfterViewInit,
} from "@angular/core";
import { BulkItemEditor } from "~/src/app/modules/posts/bulk-upload/item-editor/item-editor";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from "~/node_modules/@angular/forms";
import { FormHelpersService } from "~/src/app/core/services/form-helpers";
import { LanguageService } from "~/src/app/services/language.service";
import Utils from "~/src/app/core/utils";
import {
    BASE_DATE_FORMAT,
    LINK_SHORTEN_PLUGIN_NAME,
    TemplateTinyMceBaseOptions,
} from "~/src/app/configs/configs";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import {
    FileBrowserComponent,
    FileBrowserSelectionChange,
    FileBrowserSelectionChangeTypes,
} from "~/src/app/components/file-browser/file-browser.component";
import { BulkImportPostItemModel } from "~/src/app/modules/posts/bulk-import-post-item.model";
import { BulkImportPostTemplateItemModel } from "~/src/app/modules/posts/bulk-import-post-template-item.model";
import { BulkImportItemModel } from "~/src/app/modules/posts/bulk-import-item.model";
import * as getUrls from "get-urls";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import {
    OPEN_GRAPH_TYPE_OG,
    OPEN_GRAPH_TYPE_TWITTER,
    UrlOpenGraphService,
} from "~/src/app/services/urlopengraph.service";
import { MatExpansionPanel } from "@angular/material/expansion";
import { SmdFile } from "~/src/app/services/file.class";
import { Debounce } from "~/src/app/services/helpers";
import { EventObj } from "@tinymce/tinymce-angular/editor/Events";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import {
    SOCIAL_MEDIA_TYPE_GMB,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_TWITTER,
} from "~/src/app/core/constants";
import {
    INSTAGRAM_MAX_SELECTED_IMAGE,
    INSTAGRAM_MAX_SELECTED_MIXED,
    INSTAGRAM_MAX_SELECTED_VIDEO,
    TWITTER_MAX_SELECTED_IMAGE,
} from "~/src/app/modules/social-media-post/social-media-post.constant";
import {
    SocialMediaPlatformConfig,
    SocialMediaPlatforms,
} from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { GmbFormComponent } from "../../../social-media-post/gmb/form/gmb-form.component";

@Component({
    selector: "smd-item-editor",
    templateUrl: "./item-editor.component.html",
    styleUrls: ["./item-editor.component.scss"],
    providers: [FormHelpersService],
})
export class ItemEditorComponent implements OnInit, AfterViewInit {
    @ViewChild(FileBrowserComponent, { static: true })
    fileBrowser: FileBrowserComponent;

    @ViewChild("linkSharePanel", { static: true })
    linkSharePanel: MatExpansionPanel;

    @ViewChild(GmbFormComponent) gmbForm: GmbFormComponent;

    /**
     * Import item form group
     */
    importItemFormGroup: FormGroup;

    /**
     * Import item form control names
     *
     * @type {Object}
     */
    importItemControlNames = {
        Headline: "headline",
        SubHeadline: "subHeadline",
        MainCopy: "mainCopy",
        Signature: "signature",
        Category: "category",
        Tags: "postTags",
        ActiveFrom: "activeFrom",
        ActiveFromDate: "activeFromDate",
        ActiveFromHour: "activeFromHour",
        ActiveFromMinutes: "activeFromMinutes",
        IsAutofeed: "isAutoFeed",
        MediaTags: "imageTags",

        LinkShareTitle: "linkshareTitle",
        LinkShareDescription: "linkshareDescription",
        LinkShareURL: "linkshareURL",
        LinkShareImageURL: "linkshareImageURL",
        LinkShareIncluded: "linkshareIncluded",
        AutoLinkShareEnabled: "autoLinkshareEnabled",
    };

    /**
     * @type {(string)[]}
     */
    linkShareControlNames = [
        this.importItemControlNames.LinkShareTitle,
        this.importItemControlNames.LinkShareDescription,
        this.importItemControlNames.LinkShareImageURL,
        this.importItemControlNames.LinkShareURL,
    ];

    /**
     * Import item
     */
    entity: BulkImportItemModel;

    /**
     * TinyMCE editor config
     */
    editorConfig: Object;

    /**
     * Check current entity is post type
     */
    isPostEntity: boolean;

    /**
     * Check current entity is template type
     */
    isTemplateEntity: boolean;

    /**
     * Check this post entity type is autofeed
     */
    isAutofeed: boolean;

    /**
     * @type {boolean}
     */
    linkShareDataLoading = false;

    /**
     * @type {boolean}
     */
    hasLinkShare = false;

    /**
     * @type {number}
     */
    characterNumber = 0;

    /**
     * @type {boolean}
     */
    characterNumberIsInvalid = false;

    /**
     * @type {number}
     */
    postContentMaxLength: number = null;

    /**
     * @type {any}
     */
    linkshareBackup = {};

    /**
     * @type {string}
     */
    messageTitle: string = "schedule.message";

    /**
     * @type {string}
     */
    headlineTitle: string = "schedule.headline";

    hoursOptions = CommonPostHelpers.getHourSelectOptions();
    minutesOptions = CommonPostHelpers.getMinuteSelectOptions();

    errorTitle: string = null;
    errorMessages: string = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: BulkItemEditor.DialogData,
        public formHelpers: FormHelpersService,
        public language: LanguageService,
        public partnerConfig: PartnerConfigService,
        private _dialogRef: MatDialogRef<ItemEditorComponent>,
        private _urlOpenGraphService: UrlOpenGraphService
    ) {
        this.entity = Utils.lodash.cloneDeep(this.dialogData.entity);
        this.entity = Utils.initLineBreaksOnPostEntity(
            this.entity
        ) as BulkImportPostItemModel;

        this.isPostEntity = !!this.dialogData.isPostEntity;
        this.isTemplateEntity = !!this.dialogData.isTemplateEntity;
        this.hasLinkShare = this.entity.hasLinkShare();

        if (this.isPostEntity) {
            this.isAutofeed = this.entity["type"] === "autoFeed";
        }

        this.editorConfig = this._getEditorConfig();

        this._initImportItemFormGroup();
    }

    ngOnInit() {
        this.fileBrowser.initialize.subscribe(() => {
            if (
                !this.entity.isDatabaseEntity &&
                this.entity.media &&
                this.entity.mediaSrc &&
                this.entity.newMedia
            ) {
                this.fileBrowser.addNewFile(this.entity.newMedia);
            }

            if (this.entity.ogMediaItem) {
                this.fileBrowser.addNewFile(
                    (<BulkImportPostItemModel>this.entity).ogMediaItem,
                    this.entity.hasLinkShare()
                );
            }

            this.fileBrowser.setSelectedFiles(
                this.entity.medias.filter(
                    (media) => media.type !== FILE_TYPES.OG
                )
            );

            if (this.hasLinkShare) {
                this.linkSharePanel.open();
            } else {
                this._parseLinkShare();
            }

            if (this.entity.socialType === SOCIAL_MEDIA_TYPE_TWITTER) {
                this.fileBrowser.setMediaRules({
                    conditions: {
                        type: FILE_TYPES.CAROUSEL,
                    },
                    rules: {
                        notAllow: true,
                    },
                    limits: {
                        selectedImage: TWITTER_MAX_SELECTED_IMAGE,
                    },
                });
            }

            if (this.entity.socialType === SOCIAL_MEDIA_TYPE_INSTAGRAM) {
                this.fileBrowser.setMediaRules({
                    conditions: {
                        type: FILE_TYPES.CAROUSEL,
                    },
                    rules: {
                        notAllow: true,
                    },
                    limits: {
                        selectedImage: INSTAGRAM_MAX_SELECTED_IMAGE,
                        selectedVideo: INSTAGRAM_MAX_SELECTED_VIDEO,
                        selectedMixed: INSTAGRAM_MAX_SELECTED_MIXED,
                    },
                });
            }

            if (this.entity.socialType === SOCIAL_MEDIA_TYPE_GMB) {
                this.fileBrowser.setMediaRules({
                    conditions: {
                        type: FILE_TYPES.CAROUSEL,
                    },
                    rules: {
                        notAllow: true,
                    },
                    limits: {
                        selectedImage: INSTAGRAM_MAX_SELECTED_IMAGE,
                    },
                });
            }
        });
    }

    ngAfterViewInit() {
        if (this.entity.socialType === SOCIAL_MEDIA_TYPE_GMB) {
            this.importItemFormGroup.addControl(
                "gmbOptions",
                this.gmbForm.getForm()
            );
            const archive = JSON.parse(this.entity.archive);
            if (archive.gmbOptions) {
                const gmbOptions = archive.gmbOptions;

                this.gmbForm.setGmbFormByGmbOptions(
                    gmbOptions,
                    this.gmbForm.getForm()
                );
            }
        }
    }

    /**
     * Save button click event
     *
     * @param {MouseEvent} event
     */
    saveBtnClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.errorMessages) {
            return false;
        }

        if (this.importItemFormGroup.valid) {
            const formData = this.importItemFormGroup.getRawValue();
            const controlNames = this.importItemControlNames;

            this.entity
                .setValue("category", formData[controlNames.Category])
                .setValue("medias", this.fileBrowser.getSelectedFiles())
                .setValue("headline", formData[controlNames.Headline])
                .setValue("subHeadline", formData[controlNames.SubHeadline])
                .setValue("mainCopy", formData[controlNames.MainCopy])
                .setValue("signature", formData[controlNames.Signature])
                .setValue(
                    "linkShareTitle",
                    formData[controlNames.LinkShareTitle]
                )
                .setValue(
                    "linkShareDescription",
                    formData[controlNames.LinkShareDescription]
                )
                .setValue("linkShareURL", formData[controlNames.LinkShareURL])
                .setValue(
                    "linkShareImageURL",
                    formData[controlNames.LinkShareImageURL]
                );

            if (this.entity.socialType === SOCIAL_MEDIA_TYPE_GMB) {
                const archive = JSON.parse(this.entity.archive);
                archive.gmbOptions = formData["gmbOptions"];
                this.entity.setValue("archive", JSON.stringify(archive));
                this.entity.setValue("gmbOptions", archive.gmbOptions);
            }

            this.entity.removePropertyError("headline");
            this.entity.removePropertyError("subHeadline");
            this.entity.removePropertyError("mainCopy");
            this.entity.removePropertyError("signature");

            if (this.entity.media) {
                this.entity.media.imageTags = formData[controlNames.MediaTags];
            }

            if (this.isTemplateEntity) {
                (<BulkImportPostTemplateItemModel>this.entity).tags =
                    formData[controlNames.Tags];
            }

            if (this.isPostEntity) {
                const postType = !!formData[controlNames.IsAutofeed]
                    ? "autoFeed"
                    : "customFeed";
                const activeFromGroup = this.importItemFormGroup.get(
                    controlNames.ActiveFrom
                ) as FormGroup;
                let activeFrom = (<BulkImportPostItemModel>this.entity)
                    .activeFrom;

                if (activeFromGroup.enabled) {
                    const hours = activeFromGroup.get(
                        controlNames.ActiveFromHour
                    ).value;
                    const minutes = activeFromGroup.get(
                        controlNames.ActiveFromMinutes
                    ).value;
                    let date = activeFromGroup.get(
                        controlNames.ActiveFromDate
                    ).value;

                    // convert only date format
                    if (date) {
                        date = Utils.moment(date);
                        date.add(hours, "hour");
                        date.add(minutes, "minute");
                    }

                    activeFrom = date.toDate();
                }

                (<BulkImportPostItemModel>this.entity)
                    .setValue("type", postType)
                    .setValue("activeFrom", activeFrom);
            }

            this.entity.generateShortProperties();

            this._dialogRef.afterClosed().subscribe(() => {
                if (this.dialogData.afterSaveAction) {
                    this.dialogData.afterSaveAction(this.entity);
                }
            });

            this._dialogRef.close();
        } else {
            this.formHelpers.validateForm(this.importItemFormGroup);
            this._validatePostCharacterLength();
        }
    }

    /**
     * @param {Event} event
     */
    @Debounce()
    pasteMainCopy(event: EventObj<ClipboardEvent>) {
        this._parseLinkShare();
    }

    /**
     * @param {MouseEvent} event
     */
    refreshLinkShareDataBtnClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this._parseLinkShare();
    }

    /**
     * @param {MouseEvent} event
     */
    removeLinkShareBtnClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this._removeLinkShareValues();
    }

    /**
     * @param {FileBrowserSelectionChange} event
     */
    mediaSelectionChange(event: FileBrowserSelectionChange) {
        if (
            event &&
            event.file &&
            event.file.type === FILE_TYPES.OG &&
            this.entity &&
            this.entity.socialType === SOCIAL_MEDIA_TYPE_TWITTER
        ) {
            if (
                event.eventType === FileBrowserSelectionChangeTypes.SelectFile
            ) {
                if (
                    this.linkshareBackup &&
                    this.linkshareBackup["url"] &&
                    !this.importItemFormGroup.get(
                        this.importItemControlNames.LinkShareURL
                    ).value
                ) {
                    this._setLinkShareValues(
                        this.linkshareBackup["url"],
                        { og: this.linkshareBackup },
                        false
                    );
                }
            }

            if (
                event.eventType === FileBrowserSelectionChangeTypes.UnselectFile
            ) {
                this._removeLinkShareValues(false);
            }
        }

        if (this.entity.socialType === SOCIAL_MEDIA_TYPE_INSTAGRAM) {
            const isFileSelect =
                event.eventType === FileBrowserSelectionChangeTypes.SelectFile;
            const isFileUnselect =
                event.eventType ===
                FileBrowserSelectionChangeTypes.UnselectFile;

            if (isFileSelect && event.file.type === "image") {
                const ratioSplit = event.file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);

                if (ratio < 0.8 || ratio > 1.91) {
                    const error = {
                        instagram: {
                            medias: LanguageService.getLine(
                                "post.template.error.image.ratio"
                            ),
                        },
                    };
                    this.setErrorTitle("Validation error(s)");
                    this.setErrorMessages(error);
                }
            }

            if (isFileUnselect) {
                this.setErrorTitle(null);
                this.setErrorMessages(null);
            }
        }
    }

    /**
     * Init import item form group
     *
     * @private
     */
    _initImportItemFormGroup() {
        const imageTagsFormControl = new FormControl(
            this.entity.media.imageTags || []
        );
        const isTwitter = this.entity.socialType === SOCIAL_MEDIA_TYPE_TWITTER;
        const isGmb = this.entity.socialType === SOCIAL_MEDIA_TYPE_GMB;

        if (!this.entity.mediaSrc) {
            imageTagsFormControl.disable();
        }

        this.importItemFormGroup = new FormGroup({
            [this.importItemControlNames.MainCopy]: new FormControl(
                this.entity.mainCopy,
                this._getControlValidator(this.importItemControlNames.MainCopy)
            ),
            [this.importItemControlNames.Category]: new FormControl(
                this.entity.category,
                [Validators.required]
            ),
            [this.importItemControlNames.MediaTags]: imageTagsFormControl,
        });

        this.importItemFormGroup.valueChanges.subscribe(() => {
            this._setPostCharacterNumber();
        });

        if (!isTwitter) {
            this.importItemFormGroup.addControl(
                this.importItemControlNames.Headline,
                new FormControl(
                    this.entity.headline,
                    this._getControlValidator(
                        this.importItemControlNames.Headline
                    )
                )
            );
            if (!isGmb) {
                this.importItemFormGroup.addControl(
                    this.importItemControlNames.SubHeadline,
                    new FormControl(
                        this.entity.subHeadline,
                        this._getControlValidator(
                            this.importItemControlNames.SubHeadline
                        )
                    )
                );
                this.importItemFormGroup.addControl(
                    this.importItemControlNames.Signature,
                    new FormControl(
                        this.entity.signature,
                        this._getControlValidator(
                            this.importItemControlNames.Signature
                        )
                    )
                );
            }
        }

        this.importItemFormGroup.addControl(
            this.importItemControlNames.LinkShareTitle,
            new FormControl({
                value: Utils.get<BulkImportItemModel>(
                    this.entity,
                    ["linkShareTitle"],
                    null
                ),
                disabled: isTwitter || isGmb,
            })
        );

        this.importItemFormGroup.addControl(
            this.importItemControlNames.LinkShareDescription,
            new FormControl({
                value: Utils.get<BulkImportItemModel>(
                    this.entity,
                    ["linkShareDescription"],
                    null
                ),
                disabled: isTwitter || isGmb,
            })
        );

        this.importItemFormGroup.addControl(
            this.importItemControlNames.LinkShareImageURL,
            new FormControl(
                Utils.get<BulkImportItemModel>(
                    this.entity,
                    ["linkShareImageURL"],
                    null
                )
            )
        );

        this.importItemFormGroup.addControl(
            this.importItemControlNames.LinkShareURL,
            new FormControl(
                Utils.get<BulkImportItemModel>(
                    this.entity,
                    ["linkShareURL"],
                    null
                )
            )
        );

        if (this.entity.linkShareURL || this.entity.backupLinkshare) {
            this.linkshareBackup = {
                url:
                    this.entity.linkShareURL ||
                    this.entity.backupLinkshare.linkShareURL,
                title:
                    this.entity.linkShareTitle ||
                    this.entity.backupLinkshare.linkShareTitle,
                description:
                    this.entity.linkShareDescription ||
                    this.entity.backupLinkshare.linkShareDescription,
                image:
                    this.entity.linkShareImageURL ||
                    this.entity.backupLinkshare.linkShareImageURL,
            };
        }

        // add tags form control
        if (this.isTemplateEntity) {
            this.importItemFormGroup.addControl(
                this.importItemControlNames.Tags,
                new FormControl(
                    (<BulkImportPostTemplateItemModel>this.entity).tags || []
                )
            );
        }

        // add active from form control
        if (this.isPostEntity) {
            let activeFrom =
                (this.entity["activeFrom"] &&
                    Utils.moment(this.entity["activeFrom"]).isValid()) ||
                Utils.moment(
                    this.entity["activeFrom"],
                    "DD-MM-YYYY HH:mm"
                ).isValid() ||
                Utils.moment(
                    this.entity["activeFrom"],
                    "YYYY-MM-DD HH:mm"
                ).isValid()
                    ? this.entity["activeFrom"]
                    : null;

            let dateFormat = "YYYY-MM-DD HH:mm";
            /* if (
                Utils.moment(
                    this.entity["activeFrom"],
                    "DD-MM-YYYY HH:mm"
                ).isValid()
            ) {
                dateFormat = "DD-MM-YYYY HH:mm";
            } else {
                dateFormat = "YYYY-MM-DD HH:mm";
            } */

            activeFrom = Utils.moment(activeFrom, dateFormat).format(
                "YYYY-MM-DD HH:mm"
            );
            const date = CommonPostHelpers.roundMinuteToFive(activeFrom);

            this.importItemFormGroup.addControl(
                this.importItemControlNames.ActiveFrom,
                new FormGroup({
                    [this.importItemControlNames.ActiveFromDate]:
                        new FormControl(
                            {
                                value: date.date,
                                disabled: this.isAutofeed,
                            },
                            [Validators.required]
                        ),
                    [this.importItemControlNames.ActiveFromHour]:
                        new FormControl(
                            {
                                value: date.hour,
                                disabled: this.isAutofeed,
                            },
                            [Validators.required]
                        ),
                    [this.importItemControlNames.ActiveFromMinutes]:
                        new FormControl(
                            {
                                value: date.minute,
                                disabled: this.isAutofeed,
                            },
                            [Validators.required]
                        ),
                })
            );
        }

        // add is autofeed form control
        if (this.isPostEntity) {
            const autoFeedFormControl = new FormControl(this.isAutofeed);

            autoFeedFormControl.valueChanges.subscribe((value) => {
                if (value) {
                    this.importItemFormGroup
                        .get(this.importItemControlNames.ActiveFrom)
                        .disable();
                } else {
                    this.importItemFormGroup
                        .get(this.importItemControlNames.ActiveFrom)
                        .enable();
                }
            });

            this.importItemFormGroup.addControl(
                this.importItemControlNames.IsAutofeed,
                autoFeedFormControl
            );
        }

        this.formHelpers.formInit(this.importItemFormGroup);
    }

    /**
     * @private
     */
    _parseLinkShare(content?: string) {
        if (!content) {
            content =
                this.importItemFormGroup.get(
                    this.importItemControlNames.MainCopy
                ).value || "";
        }

        content = content.replace(/(<a(.*?)>|<\/a>)/g, "");

        const urls = getUrls(content);

        if (urls.size && !this.entity.backupLinkshare) {
            const url = CommonPostHelpers.getLastUrlFrom(urls);
            const linkShareUrlControlValue =
                this.importItemFormGroup.get(
                    this.importItemControlNames.LinkShareURL
                ).value || "";
            const enableParse = linkShareUrlControlValue !== url;

            if (enableParse) {
                this.linkShareDataLoading = true;
                const ogType =
                    this.entity &&
                    this.entity.socialType === SOCIAL_MEDIA_TYPE_TWITTER
                        ? OPEN_GRAPH_TYPE_TWITTER
                        : OPEN_GRAPH_TYPE_OG;

                this._urlOpenGraphService
                    .getData(url, ogType)
                    .then((response: any) => {
                        this._setLinkShareValues(url, response);
                        this.linkshareBackup = {
                            ...((response || {}).og || {}),
                            url,
                        };
                        this.entity.setBackupLinkshare({
                            linkShareURL: this.importItemFormGroup.get(
                                this.importItemControlNames.LinkShareURL
                            ).value,
                            linkShareImageURL: this.importItemFormGroup.get(
                                this.importItemControlNames.LinkShareImageURL
                            ).value,
                            linkShareTitle: this.importItemFormGroup.get(
                                this.importItemControlNames.LinkShareTitle
                            ).value,
                            linkShareDescription: this.importItemFormGroup.get(
                                this.importItemControlNames.LinkShareDescription
                            ).value,
                        });

                        this.linkShareDataLoading = false;
                    })
                    .catch(() => {
                        this.linkShareDataLoading = false;
                    });
            }
        }
    }

    /**
     * @param {string} url
     * @param {{og: Object}} response
     * @param modifyMedia
     * @private
     */
    _setLinkShareValues(
        url: string,
        response: { og: Object },
        modifyMedia = true
    ): void {
        const ogImage = Utils.get(response, "og.image", null);

        // set link share URL form control value
        this.importItemFormGroup
            .get(this.importItemControlNames.LinkShareURL)
            .setValue(url);

        // set link share title form control value
        this.importItemFormGroup
            .get(this.importItemControlNames.LinkShareTitle)
            .setValue(Utils.get(response, "og.title", null));

        // set link share description form control value
        this.importItemFormGroup
            .get(this.importItemControlNames.LinkShareDescription)
            .setValue(Utils.get(response, "og.description", null));

        // set link share image form control value
        this.importItemFormGroup
            .get(this.importItemControlNames.LinkShareImageURL)
            .setValue(ogImage);

        if (ogImage && !this.entity.ogMediaItem) {
            (<BulkImportPostItemModel>this.entity).ogMediaItem =
                SmdFile.createMedia(ogImage);

            if (modifyMedia) {
                this.fileBrowser.clearSelectedFiles();
                this.fileBrowser.addNewFile(
                    (<BulkImportPostItemModel>this.entity).ogMediaItem,
                    true
                );
            }
        }

        this.hasLinkShare = true;

        // open link share accordion panel
        if (this.linkSharePanel) {
            this.linkSharePanel.open();
        }
    }

    /**
     * @param modifyMedias
     * @private
     */
    _removeLinkShareValues(modifyMedias = true): void {
        this.linkShareControlNames.forEach((controlName) => {
            this.importItemFormGroup.get(controlName).setValue(null);
        });

        if (this.entity.ogMediaItem) {
            if (modifyMedias) {
                // remove og media item from files collection
                this.fileBrowser.deleteFileByPropertyValue(
                    "mediaID",
                    (<BulkImportPostItemModel>this.entity).ogMediaItem.mediaID
                );

                this.fileBrowser.setSelectedFiles([]);
            }
        }

        this.hasLinkShare = false;

        if (this.linkSharePanel) {
            this.linkSharePanel.close();
        }
    }

    _setPostCharacterNumber() {
        const results = CommonPostHelpers.setPostCharacterNumber({
            formGroup: this.importItemFormGroup,
            postContentMaxLength: this.postContentMaxLength,
            platform: this.entity.socialType,
        });

        this.characterNumber = results.characterNumber;
        this.characterNumberIsInvalid = results.characterNumberIsInvalid;

        this._validatePostCharacterLength();
    }

    /**
     * @return {boolean}
     * @private
     */
    _validatePostCharacterLength(): boolean {
        const mainCopyControl = this.importItemFormGroup.get(
            this.importItemControlNames.MainCopy
        );

        if (this.characterNumberIsInvalid) {
            this.importItemFormGroup
                .get(this.importItemControlNames.MainCopy)
                .setErrors({
                    ...(this.importItemFormGroup.get(
                        this.importItemControlNames.MainCopy
                    ).errors || {}),
                    maxlength: {
                        requiredLength: this.postContentMaxLength,
                        invalid: true,
                    },
                });

            return false;
        } else if (
            "maxlength" in (mainCopyControl.errors || {}) &&
            mainCopyControl.errors["maxlength"].invalid
        ) {
            delete this.importItemFormGroup.get(
                this.importItemControlNames.MainCopy
            ).errors.maxlength;
        }

        return true;
    }

    /**
     * @param {string} controlName
     * @return {any[] | ValidatorFn[]}
     * @private
     */
    _getControlValidator(controlName: string) {
        if (!this.entity || !this.entity.socialType) {
            return [];
        }

        const platformConfig = SocialMediaPlatforms.find(
            (platform) =>
                platform.platform === this.entity.socialType &&
                !!platform.validators
        );

        if (platformConfig) {
            if (!this.postContentMaxLength) {
                this.postContentMaxLength = platformConfig.maxLength;
            }

            if (platformConfig.validators[controlName]) {
                return platformConfig.validators[controlName];
            }
        }

        return [];
    }

    /**
     * Get TinyMCE config
     * @return {{skin_url: string; entity_encoding: string; force_br_newlines: boolean; force_p_newlines: boolean; forced_root_block: string; external_plugins: {smileys: string; bitly: string}; auto_convert_smileys: boolean; toolbar: string; menubar: string; setup: (editor) => void; height: string; statusbar: boolean}}
     */
    _getEditorConfig() {
        const config = Utils.lodash.cloneDeep(TemplateTinyMceBaseOptions);

        if (!this.partnerConfig.allowLinkShorten()) {
            if (Utils.lodash.has(config, "toolbar")) {
                config["toolbar"] = config["toolbar"].replace(
                    LINK_SHORTEN_PLUGIN_NAME,
                    ""
                );
            }
        }

        return {
            ...config,
        };
    }

    private setErrorMessages(
        errors: { [key: string]: { [key: string]: string } } | string
    ) {
        if (errors instanceof Object) {
            this.errorMessages = Object.keys(errors)
                .map((platform) => {
                    const config =
                        SocialMediaPlatformConfig.getConfig(platform);
                    const messages = errors[platform];
                    return (
                        `<strong class="font-bold"><i class="${
                            config.iconClass
                        }"></i> ${Utils.lodash.capitalize(
                            platform
                        )}</strong><br>` +
                        Object.keys(messages)
                            .map((fieldName) => {
                                return `<strong class="font-weight-bold">${LanguageService.getLine(
                                    `post.fieldName.${fieldName}`
                                )}:</strong> ${messages[fieldName]}`;
                            })
                            .join("<br>")
                    );
                })
                .join("<br><br>");
        } else {
            this.errorMessages = errors;
        }
    }

    private setErrorTitle(title: string | null) {
        this.errorTitle = title;
    }

    changeMessageTitle(event) {
        this.messageTitle = "post.gmb.message.title." + event;
        this.headlineTitle = "post.gmb.headline.title." + event;
    }
}
