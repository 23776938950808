import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';

export const WIDGET_METRIC_OPTION_REACH_OVERVIEW: WidgetMetricOptionType = {
    id: 'reach-overview',
    name: 'Overview',
    resource: 'analytics.analytics.pageReach',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_REACH_ORGANIC: WidgetMetricOptionType = {
    id: 'reach-organic',
    name: 'Organic',
    resource: 'analytics.analytics.pageReach',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_REACH_PAID: WidgetMetricOptionType = {
    id: 'reach-paid',
    name: 'Paid',
    resource: 'analytics.analytics.pageReach',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_REACH_VIRAL: WidgetMetricOptionType = {
    id: 'reach-viral',
    name: 'Viral',
    resource: 'analytics.analytics.pageReach',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_REACH_TOTAL: WidgetMetricOptionType = {
    id: 'reach-total',
    name: 'Total',
    resource: 'analytics.analytics.pageReach',
    socialChannel: 'facebook'
};

// METRIC CONFIGS

export const MetricReachOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_REACH_OVERVIEW.id,
    socialType: 'facebook',
    contentType: 'chart',
    dataName: 'Reach overview',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        unique: 'yes',
    },
    partMetricIDs: [
        WIDGET_METRIC_OPTION_REACH_TOTAL.id,
        WIDGET_METRIC_OPTION_REACH_ORGANIC.id,
        WIDGET_METRIC_OPTION_REACH_VIRAL.id,
        WIDGET_METRIC_OPTION_REACH_PAID.id,
    ]
};

export const MetricReachTotalConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_REACH_TOTAL.id,
    apiURL: '/page-reach',
    contentType: 'chart',
    dataName: 'Reach (total)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'total'
    }
};

export const MetricReachViralConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_REACH_VIRAL.id,
    apiURL: '/page-reach',
    contentType: 'chart',
    dataName: 'Reach (viral)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'viral'
    }
};

export const MetricReachPaidConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_REACH_PAID.id,
    apiURL: '/page-reach',
    contentType: 'chart',
    dataName: 'Reach (paid)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'paid'
    }
};

export const MetricReachOrganicConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_REACH_ORGANIC.id,
    apiURL: '/page-reach',
    contentType: 'chart',
    dataName: 'Reach (organic)',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'organic'
    }
};
