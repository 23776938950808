/**
 * API resources abstract
*/
import {Debug} from '~/src/app/core/debug';

export abstract class ResourceAbstract {

    // Main resource api URL
    protected abstract mainApiUrl: string;

    /**
     * Get full api URl
     * URL param every time start with '/'
     * @param {string} url
     * @returns {string}
     */
    protected getFullApiUrl(url: string): string {

        // Check url start character
        if (!url.startsWith('/')) {
            Debug.warn('The URL param not start with \'/\'!');
            return url;
        }

        // Return with full api URl
        return this.mainApiUrl + url;
    }
}
