import {
    Component,
    OnInit,
} from "@angular/core";
import { LanguageService } from "~/src/app/services/language.service";
import { CardConfigInterface } from "./reports-home.interfaces";

@Component({
    selector: "smd-reports-home",
    templateUrl: "./reports-home.component.html",
    styleUrls: ["./reports-home.component.scss"],
})
export class ReportsHomeComponent implements OnInit {

    cardsConfig: [
        CardConfigInterface[],
        CardConfigInterface[],
    ];

    constructor() {
        this.cardsConfig = [
            [
                {
                    title: LanguageService.getLine('analytics.reports.customReport'),
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt velit rutrum est feugiat euismod.',
                    actionButtonText: LanguageService.getLine('analytics.reports.button.createReport'),
                    actionButtonLink: '/analytics/custom-reports',
                    icon: 'fa fa-pie-chart',
                },
                {
                    title: LanguageService.getLine('analytics.reports.socialPerformance'),
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt velit rutrum est feugiat euismod. Aenean vitae purus felis. Nulla facilisi.',
                    actionButtonText: LanguageService.getLine('analytics.reports.button.createReport'),
                    actionButtonLink: '/analytics/single-organization/social',
                    subTitle: LanguageService.getLine('analytics.reports.singleOrgReport'),
                    icon: 'fa fa-line-chart',
                },
                {
                    title: LanguageService.getLine('analytics.reports.internalPerformance'),
                    description: 'Short description of the report.',
                    actionButtonText: LanguageService.getLine('analytics.reports.button.createReport'),
                    actionButtonLink: '/analytics/single-organization/internal',
                    subTitle: LanguageService.getLine('analytics.reports.singleOrgReport'),
                    icon: 'fa fa-bar-chart',
                },
            ],
            [
                {
                    title: LanguageService.getLine('analytics.reports.socialPerformance'),
                    description: 'Short description of the report. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim tortor, ultricies et ultrices vitae, bibendum quis ligula.',
                    actionButtonText: LanguageService.getLine('analytics.reports.button.createReport'),
                    actionButtonLink: '/analytics/multi-organization/social',
                    subTitle: LanguageService.getLine('analytics.reports.multiOrgReport'),
                    icon: 'fa fa-line-chart',
                },
                {
                    title: LanguageService.getLine('analytics.reports.internalPerformance'),
                    description: 'Short description of the report.',
                    actionButtonText: LanguageService.getLine('analytics.reports.button.createReport'),
                    actionButtonLink: '/analytics/multi-organization/internal',
                    subTitle: LanguageService.getLine('analytics.reports.multiOrgReport'),
                    icon: 'fa fa-bar-chart',
                },
                {
                    title: LanguageService.getLine('analytics.reports.myReports'),
                    description: 'You can manage your saved reports by navigating to the My reports page.',
                    actionButtonText: LanguageService.getLine('analytics.reports.button.viewMyReports'),
                    actionButtonLink: '/analytics/my-reports',
                    icon: 'fa fa-bookmark',
                },
            ]
        ];
    }

    ngOnInit() {
    }
}
