import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '~/node_modules/@angular/forms';
import {Tag} from '~/src/app/modules/posts/template.interface';
import {UserRolesService} from '~/src/app/services/user-roles.service';
import {LanguageService} from '~/src/app/services/language.service';
import {TagsComponent} from '~/src/app/modules/posts/tags.component';
import {TagsService} from '~/src/app/modules/posts/tags.service';
import Utils from '~/src/app/core/utils';
import { FolderTagsService } from '~/src/app/modules/posts/folder-tags.service';

@Component({
    selector: 'smd-tag-select',
    templateUrl: './tag-select.component.html',
    styleUrls: ['./tag-select.component.scss'],
    providers: [
        TagsService
    ]
})
export class TagSelectComponent implements OnInit {
    @Input() isAdminMode = false;
    @Input() formGroup: FormGroup;
    @Input() selectControlName: string;
    @Input() errorMessage: string;
    @Input() addTag = true;
    @Input() tagsType: string = 'template';
    isAdmin = false;
    tags: Tag[] = [];
    tagGetPending = false;
    tagsComponent: TagsComponent;

    constructor(
        private userService: UserRolesService,
        public language: LanguageService,
        private tagsService: TagsService,
        private folderTagsService: FolderTagsService,
    ) {
        userService.currentUserCan('admin').then((adminBool) => {
            this.isAdmin = adminBool;
        });
    }

    ngOnInit(): void {
        this.initTags();
    }

    private initTags() {
        if (!this.tagsComponent) {
            if (this.tagsType === 'templateFolder') {
                this.tagsComponent = new TagsComponent(this.folderTagsService);
            } else {
                this.tagsComponent = new TagsComponent(this.tagsService);
            }
        }

        // fetch generic tags on administration
        if (this.isAdminMode) {
            this.tagsComponent.setFilters([
                {
                    filterName: 'type',
                    filterValue: 'generic'
                },
            ]);
        }

        this.tagGetPending = true;
        this.tagsComponent.getItems(({tags}: { tags: Tag[] }) => {
            this.tags = Utils.lodash.orderBy(tags, [tag => tag.name.toLowerCase()]);
            this.tagGetPending = false;
        }, () => {
            this.tagGetPending = false;
        });
    }
}
