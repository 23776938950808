import { Injectable, NgModule } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { HammerGestureConfig } from "@angular/platform-browser";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatTreeModule } from "@angular/material/tree";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { MatRadioModule } from "@angular/material/radio";
import { CommonModule } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { A11yModule } from "@angular/cdk/a11y";
import { BidiModule } from "@angular/cdk/bidi";
import { ObserversModule } from "@angular/cdk/observers";
import { OverlayModule } from "@angular/cdk/overlay";
import { PlatformModule } from "@angular/cdk/platform";
import { PortalModule } from "@angular/cdk/portal";
import { CdkTableModule } from "@angular/cdk/table";
import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDateFormats, NativeDateAdapter } from "@angular/material/core";
import { MatTimepickerModule } from "mat-timepicker";

export const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: "DD MM YYYY",
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "DD/MM/YYYY",
        dateA11yLabel: "DD/MM/YYYY",
        monthYearA11yLabel: "DD/MM/YYYY",
    },
};

const modules = [
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,
    DragDropModule,

    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatNativeDateModule,
    NgSelectModule,
    MaterialFileInputModule,
    MatTimepickerModule,
];

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    useUtcForDisplay = true;

    parse(value: any) {
        if (typeof value === "string" && value.indexOf("/") > -1) {
            const str = value.split("/");
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === "number" ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
        return ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}

@NgModule({
    imports: [CommonModule, ...modules],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    exports: [...modules],
})
export class MaterialModule {}
