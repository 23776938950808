import {Injectable} from '@angular/core';
import Utils from '~/src/app/core/utils';

export class DataTableHelpers {
    selectedItems: any[] = [];

    /**
     * Select item
     * @param {T} selectedItem
     * @param {string} bindKey
     */
    selectItem<ITEM = null>(selectedItem: ITEM, bindKey: string) {
        const index = this.selectedItems.findIndex(item => item[bindKey] === selectedItem[bindKey]);

        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(selectedItem);
        }
    }

    /**
     * Select all item
     * @param {ITEM[]} itemCollection
     */
    selectAllItem<ITEM = null>(itemCollection: ITEM[]) {
        this.selectedItems = Utils.lodash.cloneDeep(itemCollection);
    }

    /**
     * Clear selection
     */
    clearSelection() {
        this.selectedItems = []; // clear original array
    }

    /**
     * Check the item is selected
     * @param {ITEM} item
     * @param {string} bindKey
     * @return {boolean}
     */
    isSelected<ITEM = null>(item: ITEM, bindKey: string) {
        return (this.selectedItems.findIndex(match => match[bindKey] === item[bindKey]) > -1);
    }

    /**
     * Get selected items
     * @return {any}
     */
    getSelectedItems<ITEM = any>(): ITEM[] {
        return Utils.lodash.cloneDeep(this.selectedItems) as ITEM[];
    }

    /**
     * Select the given items out of a given collection through a bind key
     * Using this fixes the issue of the selection not being updated correctly when the collection itself is updated
     * (e.g. when an item was deleted, it still remained selected)
     * @param {ITEM[]} items
     */
    setSelectedItems<ITEM = any>(items: ITEM[], collection: ITEM[], bindKey: string) {
        this.selectedItems = items.filter(item => collection.findIndex(collectionItem => collectionItem[bindKey] === item[bindKey]) > -1);
    }
}

@Injectable()
export class DataTableHelpersService extends DataTableHelpers {
    constructor() {
        super();
    }
}
