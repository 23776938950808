import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {LoginGuard} from './guards/login-quard';
import {MainComponent} from '../modules/main/main.component';
import {DocumentsComponent} from '../modules/documents/documents.component';
import {AuthComponent} from '../modules/auth/auth.component';
import {PasswordResetComponent} from '../modules/auth/password-reset/password-reset.component';
import {TemplateManagerComponent} from '../modules/posts/template-manager.component';
import {ScheduleComponent} from '../modules/posts/schedule/schedule.component';
import {AccessManagementComponent} from '../modules/users/access-management/access-management.component';
import {OrganizationsComponent} from '../modules/users/organizations/organizations.component';
import {AutofeedComponent} from '../modules/autofeed/autofeed.component';
import {ProfileComponent} from '../modules/users/profile/profile.component';
import {PostListComponent} from '../modules/posts/post-list/post-list.component';
import {ActivityComponent} from '../modules/activity/activity.component';
import {DashboardFixComponent} from '~/src/app/modules/dashboard-fix/dashboard-fix.component';
import {AnalyticsComponent} from '~/src/app/modules/analytics/analytics.component';
import {LinkedinLoginComponent} from '~/src/app/modules/linkedin-login/linkedin-login.component';
import {PartnersComponent} from '~/src/app/modules/administration/partners/partners.component';
import {PartnerGuard} from '~/src/app/routing/partner.guard';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import {PackagesComponent} from '~/src/app/modules/administration/packages/packages.component';
import {ResourceGuard} from '~/src/app/routing/resource.guard';
import {DefaultRolesComponent} from '~/src/app/modules/administration/default-roles/default-roles.component';
import {CategoriesComponent} from '~/src/app/modules/categories/categories/categories.component';
import {OauthComponent} from '~/src/app/modules/oauth/oauth.component';
import {HashtagManagerPageComponent} from '~/src/app/modules/hashtag/pages/hashtag-manager-page/hashtag-manager-page.component';
import {CoreConfig} from '~/src/app/core/core.config';
import {RssManagerPageComponent} from '~/src/app/modules/rss/components/rss-manager-page/rss-manager-page.component';
import {RssFeedPageComponent} from '~/src/app/modules/rss/components/rss-feed-page/rss-feed-page.component';
import { LoggingComponent } from '../modules/administration/logging/logging.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'documents',
                component: DocumentsComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Media Library',
                    urls: [{title: 'Resources'}, {title: 'Media Library', url: '/documents'}],
                    resources: ['organization.media.mediaList']
                }
            },
            {
                path: 'template-manager',
                component: TemplateManagerComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Template Manager - All templates',
                    urls: [{title: 'Resources'}, {
                        title: 'Template Manager',
                        url: '/template-manager'
                    }, {title: 'All templates'}],
                    resources: ['post.template.templates']
                }
            },
            {
                path: 'template-manager/my-templates',
                component: TemplateManagerComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Template Manager - My templates',
                    urls: [{title: 'Resources'}, {
                        title: 'Template Manager',
                        url: '/template-manager/my-templates'
                    }, {title: 'My templates'}],
                    resources: ['post.template.templates'],
                    systemType: CoreConfig.getSystemTypes().Custom
                }
            },
            {
                path: 'template-manager/branded-templates',
                component: TemplateManagerComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Template Manager - Templates by Esemdee',
                    urls: [{title: 'Resources'}, {
                        title: 'Template Manager',
                        url: '/template-manager/branded-templates'
                    }, {title: 'Templates by Esemdee'}],
                    resources: ['post.template.templates'],
                    systemType: CoreConfig.getSystemTypes().Branded
                }
            },
            {
                path: 'template-manager/generic-templates',
                component: TemplateManagerComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Template Manager - Generic templates',
                    urls: [{title: 'Resources'}, {
                        title: 'Template Manager',
                        url: '/template-manager/generic-templates'
                    }, {title: 'Generic templates'}],
                    resources: ['post.template.templates'],
                    systemType: CoreConfig.getSystemTypes().Generic
                }
            },
            {
                path: 'rss-feeds',
                component: RssFeedPageComponent,
                canActivate: [ResourceGuard, PartnerGuard],
                data: {
                    title: 'Rss Feeds',
                    urls: [{title: 'Resources'}, {title: 'Rss Feeds'}],
                    permission: PartnerPermissions.RssModule,
                    resources: ['rss.feed.list']
                }
            },
            {
                path: 'post-schedule',
                component: ScheduleComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Schedule Manager',
                    urls: [{title: 'Schedule Manager', url: '/post-schedule'}],
                    resources: ['post.post.posts']
                }
            },
            {
                path: 'post-list',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager',
                    urls: [{title: 'Post Manager', url: '/post-list'}],
                    resources: ['post.post.posts']
                }
            },
            {
                path: 'post-list/drafts',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Drafts',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {title: 'Drafts', url: '/post-list/drafts'}],
                    resources: ['post.post.posts'],
                    type: 'draft'
                }
            },
            {
                path: 'post-list/awaiting-approval',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Awaiting Approval',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {
                        title: 'Awaiting Approval',
                        url: '/post-list/awaiting-approval'
                    }],
                    resources: ['post.post.posts'],
                    status: 'waitingForApproval'
                }
            },
            {
                path: 'post-list/approval-declined',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Approval Declined',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {
                        title: 'Approval Declined',
                        url: '/post-list/approval-declined'
                    }],
                    resources: ['post.post.posts'],
                    status: 'notApproved'
                }
            },
            {
                path: 'post-list/approved',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Approved',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {title: 'Approved', url: '/post-list/approved'}],
                    resources: ['post.post.posts'],
                    status: 'approved'
                }
            },
            {
                path: 'post-list/published',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Published',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {title: 'Published', url: '/post-list/published'}],
                    resources: ['post.post.posts'],
                    status: 'posted'
                }
            },
            {
                path: 'post-list/partially-failed',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Partially Failed',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {
                        title: 'Partially Failed',
                        url: '/post-list/failed-to-post'
                    }],
                    resources: ['post.post.posts'],
                    status: 'partiallyFailed'
                }
            },
            {
                path: 'post-list/failed-to-post',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager - Failed to Post',
                    urls: [{title: 'Post Manager', url: '/post-list'}, {
                        title: 'Failed to Post',
                        url: '/post-list/failed-to-post'
                    }],
                    resources: ['post.post.posts'],
                    status: 'failed'
                }
            },
            {
                path: 'post-list/all',
                component: PostListComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Post Manager',
                    urls: [{title: 'Post Manager', url: '/post-list/all'}],
                    resources: ['post.post.posts']
                }
            },
            {
                path: 'access-management',
                component: AccessManagementComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Access Management',
                    urls: [{title: 'Settings'}, {title: 'Access Management', url: '/access-management'}],
                    resources: ['auth.user.users', 'organization.role.list']
                }
            },
            {
                path: 'organisations',
                canActivate: [ResourceGuard],
                data: {
                    title: 'Organisations',
                    urls: [{title: 'Settings'}, {title: 'Organisations', url: '/organisations'}],
                    resources: ['organization.organization.organizationTree']
                },
                children: [
                    {path: '', component: OrganizationsComponent},
                    {path: ':organizationID', component: OrganizationsComponent},
                    {path: ':organizationID/:tabName', component: OrganizationsComponent},
                    {path: ':organizationID/:tabName/:socialType', component: OrganizationsComponent},
                    {path: ':organizationID/:tabName/:socialType/:socialSiteID', component: OrganizationsComponent},
                ]
            },
            {
                path: 'categories',
                component: CategoriesComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Categories',
                    urls: [{title: 'Settings'}, {title: 'Categories', url: '/categories'}],
                    resources: ['post.category.categories']
                }
            },
            {
                path: 'hashtag-manager',
                component: HashtagManagerPageComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Hashtag Manager',
                    urls: [{title: 'Settings'}, {title: 'Hashtag Manager'}],
                    resources: ['hashtag.tag.list', 'hashtag.collection.list']
                }
            },
            {
                path: 'rss-manager',
                component: RssManagerPageComponent,
                canActivate: [PartnerGuard, ResourceGuard],
                data: {
                    title: 'RSS Manager',
                    urls: [{title: 'Settings'}, {title: 'RSS Manager'}],
                    permission: PartnerPermissions.RssModule,
                    resources: [
                        'rss.feed.list',
                        'rss.group.list'
                    ]
                }
            },
            {
                path: 'autofeed',
                component: AutofeedComponent,
                canActivate: [PartnerGuard, ResourceGuard],
                data: {
                    title: 'Autofeed',
                    urls: [{title: 'Autofeed'}],
                    permission: PartnerPermissions.AutoFeedModule,
                    resources: ['post.autoFeed.settings.list', 'post.autoFeed.postTimes']
                }
            },
            {
                path: 'profile',
                component: ProfileComponent,
                data: {
                    title: 'Profile',
                    urls: [{title: 'Profile'}]
                }
            },
            {
                path: 'profile/:tab',
                component: ProfileComponent,
                data: {
                    title: 'Profile',
                    urls: [{title: 'Profile'}]
                }
            },
            {
                path: 'activity',
                component: ActivityComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Activity',
                    urls: [{title: 'Activity'}],
                    resources: ['auth.activity.list']
                }
            },
            {
                path: 'analytics',
                component: AnalyticsComponent,
                canActivate: [PartnerGuard, PartnerGuard],
                data: {
                    title: 'Analytics',
                    urls: [{title: 'Analytics'}],
                    permission: PartnerPermissions.AnalyticsModule,
                    resources: ['analytics.dashboard.dashboards']
                }
            },
            {
                path: 'partners',
                component: PartnersComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Partners',
                    urls: [{title: 'Administration'}, {title: 'Partners admin'}],
                    resources: ['background.administration.access']
                }
            },
            {
                path: 'packages',
                component: PackagesComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Plans and Addons',
                    urls: [{title: 'Administration'}, {title: 'Plans and Addons'}],
                    resources: ['background.administration.access']
                }
            },
            {
                path: 'default-roles',
                component: DefaultRolesComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Default roles',
                    urls: [{title: 'Administration'}, {title: 'Default roles'}],
                    resources: ['background.administration.access']
                }
            },
            {
                path: 'admin-template-list',
                component: TemplateManagerComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Template manager',
                    urls: [{title: 'Administration'}, {title: 'Template manager'}],
                    resources: ['background.administration.access'],
                    isAdmin: true
                }
            },
            {
                path: 'admin-media-list',
                component: DocumentsComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Media manager',
                    urls: [{title: 'Administration'}, {title: 'Media manager'}],
                    resources: ['background.administration.access'],
                    isAdmin: true
                }
            },
            {
                path: 'logging',
                component: LoggingComponent,
                canActivate: [ResourceGuard],
                data: {
                    title: 'Logs',
                    urls: [{title: 'Administration'}, {title: 'Logs'}],
                    resources: ['background.administration.access'],
                    isAdmin: true
                }
            },
            {
                path: '',
                component: DashboardFixComponent,
                data: {
                    title: 'Dashboard',
                    urls: [{title: 'Dashboard', url: '/'}]
                }
            }
        ]
    },
    {
        path: 'login',
        canActivate: [LoginGuard],
        component: AuthComponent
    },
    {
        path: 'password-reset/:hash',
        canActivate: [LoginGuard],
        component: PasswordResetComponent
    },
    {
        path: 'activate-user/:hash',
        canActivate: [LoginGuard],
        component: PasswordResetComponent,
        data: {activateUser: true}
    },
    {
        path: 'linkedin',
        component: LinkedinLoginComponent
    },
    {
        path: 'oauth/:platform',
        component: OauthComponent
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];

export const SmdRouting = RouterModule.forRoot(routes);
