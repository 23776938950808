import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ChangeDetectorRef,
  AfterContentChecked,
  Pipe,
} from "@angular/core";
import { KeyValue } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { DataTableHelpersService } from "~/src/app/core/services/data-table-helpers.service";
import { OpenModalService } from "../../social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { TemplateFolderActions } from "../template-folder-actions";
import { TemplateFolderActionsService } from "../template-folder-actions.service";
import { TemplateFolderInterface } from "../template-folder.interface";
import { PaginationController } from "~/src/app/services/pagination.controller";
import { CollectionListConfig, CollectionListSelectionChange, TableBodyConfig, TableHeadConfig } from "~/src/app/core/components/collection-list/collection-list.interfaces";
import { Debounce } from "~/src/app/services/helpers";
import { TemplateFolderManagerComponent } from "../../social-media-post/template-folder-manager/template-folder-manager.component";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { LanguageService } from "~/src/app/services/language.service";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { DialogChooserComponent } from "~/src/app/components/dialog-chooser/dialog-chooser.component";
import { map, startWith } from "rxjs/operators";
import { TemplateFolderMinimalInterface } from "../template-folder.interface";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { TemplateFolderShareComponent } from "../../social-media-post/template-folder-share/template-folder-share.component";
import { IRelatedUser } from "../../users/users/users.interface";
import { ResourceService } from "~/src/app/directives/resource-checker/resource.service";

@Component({
  selector: "smd-template-folder-view",
  templateUrl: "./template-folder-view.component.html",
  styleUrls: ["./template-folder-view.component.scss"],
  providers: [
    DataTableHelpersService,
  ],
})

export class TemplateFolderViewComponent 
extends TemplateFolderActions
implements OnInit, OnDestroy, AfterContentChecked
{
  @Input("isAdminMode") isAdminMode: boolean = false;
  @Input("templateManagerPage") templateManagerPage: string = 'all';
  @Input() folderCreated: Observable<any>;
  @Input() templateAssigned: Observable<any>;
  @Input() relatedUsers: Array<IRelatedUser> = [];

  @Output("createFolder") createFolderEvent: EventEmitter<any> = new EventEmitter();
  @Output("createTemplate") createTemplateEvent: EventEmitter<any> = new EventEmitter();
  @Output("importTemplates") importTemplatesEvent: EventEmitter<any> = new EventEmitter();
  @Output("importTemplatesAdmin") importTemplatesAdminEvent: EventEmitter<any> = new EventEmitter();
  @Output("foldersDeleted") foldersDeletedEvent: EventEmitter<any> = new EventEmitter();
  @Output("changeTab") changeTabEvent: EventEmitter<{tabIndex: number, folderID: number, folderName: string}> = new EventEmitter();

  subs = [];

  folders: TemplateFolderInterface[] = [];
  foldersOrdered: TemplateFolderMinimalInterface[] = [];
  //foldersFiltered: Observable<TemplateFolderMinimalInterface[]>;
  foldersFiltered: TemplateFolderMinimalInterface[] = [];
  foldersCount: number = 0;

  listView = false;
  cardView = true;

  bulkDeleteVisible = false;
  userHasDeleteResource = false;

  // Search form
  folderControlNames = {
    Type: "type",
    JumpToFolder: "jumpToFolder",
  };

  folderFormGroup = new FormGroup({
    [this.folderControlNames.Type]: new FormControl("all"),
    [this.folderControlNames.JumpToFolder]: new FormControl(""),
  });

  paginationController: PaginationController = new PaginationController();

  collectionConfig: CollectionListConfig<TemplateFolderInterface> = {
    allowSelection: (item) => {
        return (
          this.isAdminMode || 
          (item.allowedActions && item.allowedActions.delete)
        );
    },
  };

  // Table head config
  tableHeadConfig: TableHeadConfig[] = [
    { 
      nameKey: "templateFolder.id", 
      onlyDesktop: true, 
      onlyMobile: true,
      enable: () => this.columnVisibility["folderID"].visible, 
    },
    { 
      nameKey: "templateFolder.name", 
      onlyDesktop: true, 
      onlyMobile: true,
      enable: () => this.columnVisibility["name"].visible, 
    },
    { 
      nameKey: "templateFolder.type",
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["type"].visible,
    },
    {
      nameKey: "templateFolder.sharedWithOrg",
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["sharedWithOrg"].visible,
    },
    {
      nameKey: "templateFolder.tags",
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["folderTags"].visible,
    },
    {
      nameKey: "post.manager.created.date",
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["createdDate"].visible, 
    },
    { 
      nameKey: "post.manager.created.by", 
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["createdBy"].visible, 
    },
    { 
      nameKey: "templateFolder.lastModified", 
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["lastModified"].visible, 
    },
    {
      nameKey: "templateFolder.last.modified.by",
      onlyDesktop: true,
      onlyMobile: true,
      enable: () => this.columnVisibility["lastModifiedBy"].visible,
    },
    { 
      nameKey: "post.manager.actions", 
      onlyDesktop: true
    },
  ];

  // Table body config
  tableBodyConfig: TableBodyConfig[] = [
    {
      bindValue: "folderID",
      onlyDesktop: true,
      enable: () => this.columnVisibility["folderID"].visible,
    },
    {
      bindValue: "name",
      onlyDesktop: true,
      enable: () => this.columnVisibility["name"].visible,
    },
    {
      selector: "type",
      onlyDesktop: true,
      enable: () => this.columnVisibility["type"].visible,
    },
    {
      selector: "sharedOrganizationView",
      onlyDesktop: true,
      enable: () => this.columnVisibility["sharedWithOrg"].visible,
    },
    {
      selector: "folderTags",
      onlyDesktop: true,
      enable: () => this.columnVisibility["folderTags"].visible,
    },
    {
      bindValue: "createdAt",
      dateFormat: true,
      onlyDesktop: true,
      enable: () => this.columnVisibility["createdDate"].visible,
    },
    { 
      selector: "createdBy", 
      onlyDesktop: true,
      enable: () => this.columnVisibility["createdBy"].visible, 
    },
    {
      bindValue: "updatedAt",
      dateFormat: true,
      onlyDesktop: true,
      enable: () => this.columnVisibility["lastModified"].visible,
    },
    {
      selector: "lastModifiedBy",
      onlyDesktop: true,
      enable: () => this.columnVisibility["lastModifiedBy"].visible,
    },
    {
      staticView: "itemActions",
      onlyDesktop: true,
    },
  ];

  // Column data for select
  columnVisibility: any = {
    folderID: {
      name: "templateFolder.id",
      visible: true,
    },
    name: {
      name: "templateFolder.name",
      visible: true,
    },
    type: {
      name: "templateFolder.type",
      visible: true,
    },
    sharedWithOrg: {
      name: "templateFolder.sharedWithOrg",
      visible: true,
    },
    folderTags: {
      name: "templateFolder.tags",
      visible: true,
    },
    createdDate: {
      name: "post.manager.created.date",
      visible: true,
    },
    createdBy: {
      name: "post.manager.created.by",
      visible: true,
    },
    lastModified: {
      name: "templateFolder.lastModified",
      visible: true,
    },
    lastModifiedBy: {
      name: "templateFolder.last.modified.by",
      visible: true,
    },
  };
  
  // Default columns and order
  defaultColumns: any[] = Object.keys(this.columnVisibility);
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  };

  // Properties to avoid function calls in template
  renderHelper = { 
    noneSelected: true,
    tooltips: {
      delete: 'schedule.share.template.selectAtLeastOne',
    }
  }

  filterToggled = false;
  filterClickEventsSubject: Subject<any> = new Subject();
  
  constructor(
    public dataTableHelper: DataTableHelpersService,
    private openModal: OpenModalService,
    public folderService: TemplateFolderActionsService,
    private cd: ChangeDetectorRef,
    public resourceService: ResourceService,
  ) {
    super(folderService);
  }

  ngOnInit() {
    this.userHasDeleteResource = this.resourceService.checkResource('post.template.remove');
    this.initialize();

    this.getAllFoldersForSelect();

    const sub1 = this.folderCreated?.subscribe(() => {
      this.getFolders();
    });

    const sub3 = this.templateAssigned?.subscribe(() => {
      this.getFolders();
    });

    const sub2 = this.folderFormGroup.get(this.folderControlNames.Type).valueChanges.subscribe((value) => {
      this.filters["type"] = value;
      this.getFolders();
    });

    this.subs.push(sub1, sub2, sub3);

    this.cd.detach();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  initialize() {
    this.paginationController.onSetPagination.subscribe((result) => {
      this.filters["limit"] = result.limit;
      this.filters["offset"] = result.offset;

      this.getFolders();
    });

    this.getFolders();
  }

  /**
   * Get folders
   */
  getFolders() {
    const loader = this.openModal.loader(DialogLoaderComponent);
    const defaultFilters = {
      myFolders: this.templateManagerPage === 'custom' ? true : false,
    };

    if (!this.filters['orderBy']) {
      this.filters['orderBy'] = 'createdAt-desc';
    }

    if (this.filters['order']) {
      delete this.filters['order'];
    }

    this.getItems(
      () => {
        loader.close();
      },
      () => {
        loader.close();
      },
      defaultFilters
    );
  }

  successGetItems(response: any): void {
    super.successGetItems(response);

    this.folders = response.folders;
    this.foldersCount = response.count;
    this.paginationOptions.length = response.count;

    let anyDeletable = false;
    this.folders.forEach((folder) => {
      if (folder.sharedWithOrgs !== null && folder.sharedWithOrgs.length > 10) {
        folder.renderHelper = {
          organizationTooltipFor10: this.getOrganizationTooltipFor10(folder)
        }
      }
      if (folder.allowedActions.delete) {
        anyDeletable = true;
      }
    });

    this.bulkDeleteVisible = this.userHasDeleteResource || anyDeletable;
  }

  getAllFoldersForSelect() {
    const filters = {
      myFolders: this.templateManagerPage === 'custom' ? true : false,
    };

    this.folderService.getAllForSelect(filters)
      .then((response: {folders: TemplateFolderMinimalInterface[]}) => {
        this.foldersOrdered = response.folders;
        this.foldersFiltered = this.foldersOrdered.slice();
      }).catch((error) => {
        console.error(error);
      });
  }

  getOrganizationTooltipFor10(folder: TemplateFolderInterface): string {
    if (folder.sharedWithOrgs.length > 10) {
      return folder.sharedWithOrgs
        .slice(10, folder.sharedWithOrgs.length)
        .map((org) => org.name)
        .join(", ");
    }
  }

  selectionChange(event: CollectionListSelectionChange) {
    this.dataTableHelper.selectedItems = event.selectedItems;

    // rerender necessary components
    this.updateRenderData();
  }

  @Debounce(250)
  updateRenderData() {
    this.renderHelper.noneSelected = this.noItemsSelected();

    this.renderHelper.tooltips.delete = this.getDeleteHoverMessage();
  }

  noItemsSelected(): boolean {
    return this.dataTableHelper.getSelectedItems().length === 0;
  }

  getDeleteHoverMessage(): string {
    if (this.dataTableHelper.getSelectedItems().length === 0) {
        return "templateFolder.selectAtLeastOne";
    }

    return "templateFolder.selected.delete";
  }

  /**
   * Create a new folder emit event
   */
  createFolder() {
    this.createFolderEvent.emit();
  }

  /**
   * Create a new template emit event
   */
  createTemplate() {
    this.createTemplateEvent.emit();
  }

  /**
   * Import templates emit event
   * @param event MouseEvent
   */
  importTemplatesBtnClick(event: MouseEvent) {
    this.importTemplatesEvent.emit(event);
  }

  /**
   * Import templates admin version emit event
   * @param event MouseEvent
   */
  importTemplates(event: MouseEvent) {
    this.importTemplatesAdminEvent.emit(event);
  }

  /**
   * Toggle card and list view
   * @param currentView string
   */
  toggleViews(currentView: string) {
    if (currentView === "list") {
        this.listView = true;
        this.cardView = false;
    } else if (currentView === "card") {
        this.listView = false;
        this.cardView = true;
    }
  }

  /**
   * Refresh pagination
   * @param pageIndex number
   */
  refreshPagination(pageIndex) {
    this.paginationController.paginationOptions.pageIndex = pageIndex;
    this.filters["offset"] =
      this.paginationOptions.pageIndex * this.paginationOptions.pageSize;
  }

  /**
   * Toggle table columns
   * @param event
   */
  toggleColumns(event): void {
    Object.keys(this.columnVisibility).forEach((column) => {
      this.columnVisibility[column].visible =
          event.value.includes(column);
    });
  }

  editFolder(folder: TemplateFolderInterface) {
    this.openModal.createTemplateFolder(TemplateFolderManagerComponent, {
      data: {
        folder: folder,
        afterSuccessEdit: () => {
          this.getFolders();
        },
        isAdminMode: this.isAdminMode,
      }
    });
  }

  pinFolder(folder: TemplateFolderInterface) {
    const pinValue = !folder.pinned;

    this.folderService.editFolder(folder.folderID, { pinned: pinValue })
      .then((response) => {
        this.getFolders();
      }).catch((error) => {
        this.openModal.errorModal(DialogErrorComponent, {
          title: LanguageService.getLine('templateFolder.pin.folder.error.title'),
          message: !!error.error.message ? error.error.message : 'Unknown error',
        });
      });
  }

  deleteFolder(folder: TemplateFolderInterface) {
    this.deleteFolders([folder.folderID]);
  }

  shareFolder(folder: TemplateFolderInterface) {
    this.openModal.shareFolderWithOrganizations(TemplateFolderShareComponent, {
      data: {
        folder: folder,
        isAdminMode: this.isAdminMode,
        afterSuccessSave: () => {
          this.getFolders();
        },
      }
    });
  }

  selectAction(event: MouseEvent, value: string) {
    const folderIDs = this.dataTableHelper.getSelectedItems().map((folder) => folder.folderID);
    if (value === 'delete') {
      this.deleteFolders(folderIDs);
    }
  }
  
  openFolder(folderID: number) {
    this.changeTabEvent.emit({ tabIndex: 1, folderID: folderID, folderName: this.getFolderName(folderID) });
    this.folderFormGroup.get(this.folderControlNames.JumpToFolder).setValue("");
  }

  deleteFolders(folderIDs: number[]) {
    // find all folders, check if all are empty 
    let allFoldersEmpty = true;
    for (const folder of this.folders) {
      if (folderIDs.includes(folder.folderID) && folder.templateCount > 0) {
        allFoldersEmpty = false;
        break;
      }
    }

    this.openModal.chooserModal(DialogChooserComponent, {
      title: LanguageService.getLine('templateFolder.delete.folders.confirm.title', { itemCount: folderIDs.length }),
      message: allFoldersEmpty || !this.userHasDeleteResource ? LanguageService.getLine('templateFolder.delete.folders.confirm.message') : LanguageService.getLine('templateFolder.delete.folders.confirm.message.templates'),
      confirmButtonText: LanguageService.getLine('templateFolder.delete.folders.confirm.yes'),
      displayChooser: allFoldersEmpty || !this.userHasDeleteResource ? false : true,
      options: [
        {
          value: 'deleteTemplates',
          label: "Delete templates",
        },
        {
          value: 'doNotDeleteTemplates',
          label: "Keep templates",
        },
      ],
    }).afterClosed().subscribe((value) => {
      if (value !== null) {
        const deleteTemplates = value === 'deleteTemplates' ? true : false;
        const loader = this.openModal.loader(DialogLoaderComponent);

        this.folderService.deleteFolders(folderIDs, deleteTemplates)
          .then((response: any) => {
            if (response.success) {
              this.openModal.successModal(DialogSuccessComponent, {
                title: LanguageService.getLine('templateFolder.delete.folders.success.title'),
                message: response.success.message,
              }).afterClosed().subscribe(() => {
                this.selectionChange({ selectedItems: [], itemCollection: [] });
                this.getFolders();
                if (deleteTemplates) {
                  this.foldersDeletedEvent.emit();
                }
              });
            }
          }).catch((error) => {
            this.openModal.errorModal(DialogErrorComponent, {
              title: LanguageService.getLine('templateFolder.delete.folders.error.title'),
              message: !!error.error.message ? error.error.message : 'Unknown error',
            });
          }).finally(() => {
            loader.close();
          });
      }
    });
  }

  getTooltipForFolderTags(folder: TemplateFolderInterface): string {
    if (folder && folder.tags && folder.tags.length > 5) {
      const tags = folder.tags;
      const tooltipTags = [];

      for (const tag of tags.slice(5, tags.length)) {
          tooltipTags.push(tag);
      }

      return tooltipTags.join(", ");
    }

    return "";
  }

  getFolderName(folderID) {
    const folder = this.folders.find((folder) => folder.folderID == folderID);

    return folder ? folder.name : "";
  }

  filterFolders(value: string) {
    return this.foldersOrdered.filter(folder =>
      folder.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  filter(eventType?: string) {
    let filterValue = this.folderFormGroup.get(this.folderControlNames.JumpToFolder).value;
    if (eventType && eventType === 'focus') {
      filterValue = '';
    }
    this.foldersFiltered = this.filterFolders(filterValue);
  }

  filterToggleClicked() {
      if (!this.filterToggled) {
          this.filterClickEventsSubject.next();
      }
      this.filterToggled = true;
  }

  filtersChange(filters) {
    //console.log(filters);
    Object.keys(filters).forEach((key) => {
      this.filters[key] = filters[key];
    });

    this.getFolders();
  }
}