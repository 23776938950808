import { ReportEditRequest } from './../dashboards.interface';
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { ElementRef } from "~/node_modules/@angular/core";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { NgxDrpOptions } from "ngx-mat-daterange-picker";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site.interfaces";
import { BASE_DATE_FORMAT } from "~/src/app/configs/configs";
import Utils from "~/src/app/core/utils";
import { dashboardGranularities } from "../dashboards.constant";
import { Report } from "../dashboards.interface";
import { ReportSettingsComponent } from "./report-settings/report-settings.component";
import { SettingsImagesForUpload, SettingsModalData } from "./reports.interface";
import { ReportsService } from "../reports.service";
import { OpenModalService } from "../../social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { MatSelect, MatSelectChange } from "@angular/material/select";

@Component({
    selector: "smd-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, AfterViewInit {
    @ViewChild("reportSelect") reportSelect: ElementRef<MatSelect>;

    menuFormGroup = new FormGroup({
        selectedOrganizations: new FormControl([]),
        selectedSocialSites: new FormControl([]),
        selectedGranularity: new FormControl(null),
        selectedDateRange: new FormControl(null),
    });

    socialSites: SocialSiteInterface[] = [];
    dateRangeOptions: NgxDrpOptions;

    /* Variables mostly for the view */
    currentlyLoading = {
        socialSites: true,
        saveInProgress: false,
        currentReport: true,
    };
    noReportsAvailable: boolean = false;
    reportSavedInLast5Seconds: boolean = false;
    reportSavedInLast5SecondsTimer: number = null;
    reportSelectGroups = {
        myReports: { expanded: true },
        sharedReports: { expanded: true },
    };
    /* Variables mostly for the view */

    constants = {
        granularities: dashboardGranularities,
        datePresets: [],
    };

    currentReport: Report;
    reports: Report[];

    startingHashOfCurrentReport: string;

    constructor(
        public socialSiteController: SocialSiteController,
        public dialog: MatDialog,
        public activatedRoute: ActivatedRoute,
        public reportsService: ReportsService
    ) {}

    ngOnInit(): void {
        this.initSocialSites();
        this.initSubscriptions();
        this.initDatePicker();
        this.urlListener();

        this.loadReports();
    }

    loadReport(reportID: number): void {
        this.currentlyLoading.currentReport = true;

        this.reportsService
            .getReport(reportID)
            .then((reportResponse) => {
                this.currentReport = reportResponse.report;

                // TODO: Multiple orgs
                this.menuFormGroup.get("selectedOrganizations").setValue([this.currentReport.organizationID]);
                this.menuFormGroup.get("selectedSocialSites").setValue(this.currentReport.socialSites?.map((socialSite) => socialSite.siteID) || []);

                // TODO: Granularity
                // TODO: Daterange (need correct datepicker first)
            })
            .then(() => {
                this.currentlyLoading.currentReport = false;

                this.startingHashOfCurrentReport = this.getHashOfCurrentReport();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    loadReports(): void {
        this.reportsService
            .getMinimalReports()
            .then((reportsResponse) => {
                this.reports = reportsResponse.reports;
                if (this.reports.length > 0) {
                    this.loadReport(this.reports[0].reportID);
                } else {
                    this.noReportsAvailable = true;
                    this.currentlyLoading.currentReport = false;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    ngAfterViewInit(): void {
        /* This hack is necessary to override the style of mat-selects properly */
        /* For some reason, until focused mat-selects won't use the correct styles */
        const dropdownElements = document.getElementsByClassName("dashboard-menu-select") as HTMLCollectionOf<HTMLElement>;

        for (let i = 0; i < dropdownElements.length; i++) {
            const matSelectChilds = dropdownElements[i].getElementsByClassName("mat-select") as HTMLCollectionOf<HTMLElement>;

            for (let j = 0; j < matSelectChilds.length; j++) {
                matSelectChilds[j].focus();
                matSelectChilds[j].blur();
            }
        }
        /* End of the hack */
    }

    initSocialSites(): void {
        this.currentlyLoading.socialSites = true;

        const organizationIDs = this.menuFormGroup.get("selectedOrganizations").value;

        if (!organizationIDs || organizationIDs.length === 0) {
            this.currentlyLoading.socialSites = false;
            this.socialSites = [];
            return;
        }

        this.socialSiteController
            .getItemsByIDs("organizationID", organizationIDs, [])
            .then((response: any) => {
                this.socialSites = response.socialSites;
            })
            .finally(() => {
                this.currentlyLoading.socialSites = false;
            });
    }

    initSubscriptions(): void {
        this.menuFormGroup.get("selectedOrganizations").valueChanges.subscribe(() => {
            this.initSocialSites();
        });
    }

    // This inits the ngx-mat-drp datepicker, not the mat-datepicker one.
    // If the former will be unused, remove this.
    initDatePicker(): void {
        const backDate = (numOfDays) => {
            const _today = new Date();

            return new Date(_today.setDate(_today.getDate() - numOfDays));
        };

        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const currMonthEnd = new Date(Utils.moment().format(BASE_DATE_FORMAT));
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.constants.datePresets = [
            { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
            { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
            { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
            { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
            { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } },
        ];

        const year = Utils.moment().format("YYYY");
        const month = Utils.moment().format("MM");
        const defaultFrom = new Date(`${year}-${month}-01`);
        const defaultTo = new Date(Utils.moment().format(BASE_DATE_FORMAT));
        const defaultRange = { fromDate: defaultFrom, toDate: defaultTo };

        this.dateRangeOptions = {
            presets: this.constants.datePresets,
            format: "dd MM yyyy",
            range: defaultRange,
            applyLabel: "Set Date",
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: false,
                hasBackdrop: true,
            },
        };
    }

    // TODO: Add every field we can change
    getHashOfCurrentReport(): string {
        const formGroup = this.menuFormGroup;

        const hash: string = Utils.md5(
            JSON.stringify({
                selectedOrganizations: formGroup.get("selectedOrganizations").value,
                selectedSocialSites: formGroup.get("selectedSocialSites").value,
                selectedGranularity: formGroup.get("selectedGranularity").value,
                selectedDateRange: formGroup.get("selectedDateRange").value,
            })
        );

        return hash;
    }

    /**
     * This function is called when the user changes from one report to another.
     * It checks if the current report has been modified and asks the user if he wants to save the changes.
     * After that, it loads the new report and saves the current one if the user wants to.
     */
    onReportChange(event: MatSelectChange): void {
        if (this.currentReport && this.currentReport.reportID !== event.value) {
            // TODO: Or if no permissions to edit
            if (this.startingHashOfCurrentReport == this.getHashOfCurrentReport()) {
                this.loadReport(event.value);
            } else {
                // TODO: Language
                const dialogRef = this.dialog.open(DialogConfirmComponent, {
                    data: {
                        title: "Save current report?",
                        message: "There were changes to the report you currently have open. Do you want to save them before changing to another one?",
                        yesButtonText: "Save",
                        noButtonText: "Discard",
                    },
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.saveReport();
                    }

                    this.loadReport(event.value);
                });
            }
        }
    }

    openSettingsDialog(): void {
        const dialogData: SettingsModalData = this.currentReport;
        const dialogRef = this.dialog.open(ReportSettingsComponent, {
            width: "600px",
            data: dialogData,
        });

        dialogRef.afterClosed().subscribe((results: { reportSettings: SettingsModalData; uploadedImages: SettingsImagesForUpload }) => {
            // TODO: Save the report settings.
        });
    }

    openExportDialog(): void {
        console.log("Export dialog opened");
    }

    saveReport(): void {
        if (this.currentlyLoading.saveInProgress) {
            return;
        }

        this.currentlyLoading.saveInProgress = true;

        let dataToSave: ReportEditRequest = {};

        const formGroup = this.menuFormGroup;

        dataToSave.organizationID = formGroup.get("selectedOrganizations").value[0]; // TODO...
        dataToSave.socialSites = formGroup.get("selectedSocialSites").value;

        this.reportsService.editReport(this.currentReport.reportID, dataToSave).then((response) => {
            this.currentlyLoading.saveInProgress = false;
            this.reportSavedInLast5Seconds = true;

            console.log(response);

            if (this.reportSavedInLast5SecondsTimer) {
                clearTimeout(this.reportSavedInLast5SecondsTimer);
            }

            this.reportSavedInLast5SecondsTimer = window.setTimeout(() => {
                this.reportSavedInLast5Seconds = false;
            }, 5000);
        }).catch((error) => {
            this.currentlyLoading.saveInProgress = false;
            console.log(error);
        });
    }

    urlListener() {
        this.activatedRoute.queryParams.subscribe((params) => {
            console.log(params);
        });
    }
}
