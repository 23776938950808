import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {LanguageService} from '~/src/app/services/language.service';
import {DialogSuccessComponent} from '~/src/app/components/dialog-success/dialog-success.component';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {Injectable} from '@angular/core';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {DialogConfirmComponent} from '~/src/app/components/dialog-confirm/dialog-confirm.component';

export interface ApiActionConfig {
    messageKey: string;
}

export interface StartApiActionConfig {
    showSuccessModal?: boolean;
    showFailedModal?: boolean;
    successMessageKey?: string;
    failedMessageKey?: string;
    confirmMessageKey?: string;
    confirmYesButtonTextKey?: string;
    afterSuccessAction?: (response: any) => void;
    afterFailedAction?: (error: any) => void;
    finallyAction?: () => void;
}

@Injectable()
export class ComponentHelpers {
    constructor(
        public openModal: OpenModalService,
        public language: LanguageService
    ) {}

    /**
     * Start api action
     * @param action
     * @param config
     * @param withConfirm
     */
    startApiAction(action: () => Promise<any>, config: StartApiActionConfig, withConfirm: boolean = false) {

        return new Promise((resolve, reject) => {

            config = {
                showSuccessModal: true,
                showFailedModal: true,
                ...config
            };

            const apiAction = () => {

                // Open loader
                const loader = this.openModal.loader(DialogLoaderComponent);

                // After loader close action
                loader.afterClosed().subscribe(({isSuccess, response}) => {
                    if (isSuccess) {

                        const callCallbacks = () => {
                            // If has afterSuccessAction method, call that after close success modal
                            if (!!config && !!config.afterSuccessAction) {
                                config.afterSuccessAction(response);
                            }

                            // Call finally action
                            if (config && config.finallyAction) {
                                config.finallyAction();
                            }

                            resolve(response);
                        };

                        if (config.showSuccessModal) {
                            // If the request is success, open success modal
                            this.apiAction('success', response, {messageKey: config.successMessageKey}).then(() => {
                                callCallbacks();
                            });
                        } else {
                            callCallbacks();
                        }

                    } else {

                        const callCallbacks = () => {
                            // If has afterFailedAction method, call that after close fail modal
                            if (!!config && !!config.afterFailedAction) {
                                config.afterFailedAction(response);
                            }

                            // Call finally action
                            if (config && config.finallyAction) {
                                config.finallyAction();
                            }

                            reject(response);
                        };

                        if (config.showFailedModal) {
                            // If the request is failed, open failed modal
                            this.apiAction('failed', response, {messageKey: config.failedMessageKey}).then(() => {
                                callCallbacks();
                            });
                        } else {
                            callCallbacks();
                        }
                    }
                });

                // Call request and close loader modal with params
                action()
                    .then(response => loader.close({isSuccess: true, response: response}))
                    .catch(error => loader.close({isSuccess: false, response: error}));
            };

            if (withConfirm) {  // If need confirm modal

                // Check has every confirm modal config
                if (
                    !config ||
                    !config.confirmMessageKey ||
                    !config.confirmYesButtonTextKey
                ) {
                    console.warn('Missing config properties to confirm!');
                } else {

                    // Open confirm modal
                    this.openModal.confirmModal(DialogConfirmComponent, {
                        message: LanguageService.getLine(config.confirmMessageKey),
                        yesButtonText: LanguageService.getLine(config.confirmYesButtonTextKey)
                    }).afterClosed().subscribe(doIt => {
                        if (!!doIt) {

                            // After close confirm modal, and clicked on yes button, send request
                            apiAction();
                        }
                    });
                }
            } else {

                // If don't need confirm modal, send request
                apiAction();
            }

        });

    }

    /**
     * Api action container
     * @param {"success" | "failed"} type
     * @param response
     * @param {{messageKey: string}} config
     * @return {Promise<any>}
     */
    apiAction(type: 'success' | 'failed', response: any, config: ApiActionConfig) {
        if (type === 'success') {
            return this.successApiAction(response, config);
        }

        if (type === 'failed') {
            return this.failedApiAction(response, config);
        }
    }

    /**
     * Success api action
     * @param response
     * @param {{messageKey: string}} config
     * @return {Promise<any>}
     */
    successApiAction(response: any, config: ApiActionConfig) {
        return new Promise(resolve => {

            this.openModal.successModal(DialogSuccessComponent, {
                message: this.language.getLine(config.messageKey)
            }).afterClosed().subscribe(() => resolve(response));

        });
    }

    /**
     * Failed api action
     * @param response
     * @param {{messageKey: string}} config
     * @return {Promise<any>}
     */
    failedApiAction(response: any, config: ApiActionConfig) {
        return new Promise(resolve => {

            this.openModal.errorModal(DialogErrorComponent, {
                message: FormValidationService.readError(response).message || this.language.getLine(config.messageKey)
            }).afterClosed().subscribe(() => resolve(response));

        });
    }
}
