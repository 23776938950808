import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import {DialogSuccessComponent} from '~/src/app/components/dialog-success/dialog-success.component';
import {LanguageService} from '~/src/app/services/language.service';
import {OrganizationService} from '~/src/app/components/organization-select/organization.service';
import {OrganizationComponent} from '~/src/app/components/organization-select/organization.component';
import {FacebookService} from 'ngx-facebook';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {environment} from '~/src/environments/environment';
import {LoggedUser} from '~/src/app/services/logged-user';
import {AuthService} from '~/src/app/modules/auth/auth.service';
import {RssFeedService} from "~/src/app/modules/rss/services/rss-feed.service";
import {DialogInfoComponent} from '~/src/app/components/dialog-info/dialog-info.component';

declare const $;

interface DialogData {
    title: string;
}

@Component({
    selector: 'smd-create-org-modal',
    templateUrl: './create-org-modal.component.html',
    styleUrls: ['./create-org-modal.component.scss']
})
export class CreateOrgModalComponent implements OnInit {
    submitEvent = new EventEmitter();
    _organizationsComponent = null;
    _organizationComponent: OrganizationComponent;
    newOrganizationForm: FormGroup;
    //organizations = [];
    inputNumber = 0;
    inputfeeds = [
        {name: ''}
    ];
    inputMetas = [
        {
            id: 1
        }
    ];
    message: object = {};
    form = [{
        url: {
            valid: true,
            validMessage: 'Not valid URL!'
        }
    }];
    urlReg;

    constructor(
        private http: HttpClient,
        fbService: FacebookService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CreateOrgModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _organizationService: OrganizationService,
        private authService: AuthService,
        private rssService: RssFeedService
    ) {
        this.organizationInit();
    }

    ngOnInit() {
        this.urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w; .-]*/?';

        this.newOrganizationForm = new FormGroup({
            orgName: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(55)
            ])),
            orgDesc: new FormControl('', []),
            organizationParent: new FormControl('', Validators.required),
            orgCountry: new FormControl('', [
                Validators.maxLength(60)
            ]),
            orgState: new FormControl('', [
                Validators.maxLength(60)
            ]),
            orgZip: new FormControl('', [
                Validators.maxLength(60)
            ]),
            orgAddress: new FormControl('', [
                Validators.maxLength(60)
            ]),
            inputMetasArray: new FormArray([
                new FormControl('', Validators.pattern(this.urlReg)),
            ])
        });
    }

    organizationInit() {
        if (!this._organizationComponent) {
            this._organizationComponent = new OrganizationComponent(this._organizationService);
        }

        this._organizationComponent.filters = [];
        /* this._organizationComponent.getItems(response => {
            this.organizations = response.organizations;
        }); */
    }

    /**
     * Close modal instance
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     *
     * Create New organization handler
     *
     * @param event organization form event
     */
    async submitNewOrg(event) {
        event.preventDefault();

        this.message = {};
        let filteredUrls;

        if (this.organizitaionFeedUrls.value.length > 0) {
            filteredUrls = this.organizitaionFeedUrls.value.filter((item) => {
                return item !== null && item !== '';
            });
        } else {
            filteredUrls = [];
        }

        if (this.newOrganizationForm.valid) {
            const orgName = this.organizationName;
            const country = this.organizationAddressCountry;
            const state = this.organizationAddressState;
            const zip = this.organizationAddressZip;
            const address = (zip + ' ' + country + ' ' + state + ' ' + this.organizationAddress).trim();
            const parentID = this.organizationParent;
            const desc = this.organizationDesc;

            const sendFormDataObj = new FormData();
            sendFormDataObj.append('name', orgName);
            sendFormDataObj.append('address', address);
            sendFormDataObj.append('parentID', parentID);
            sendFormDataObj.append('description', desc);

            const _loader = this.dialog.open(DialogLoaderComponent, {
                disableClose: true,
                panelClass: 'dialog-loader-modal',
                minWidth: '200vw',
                minHeight: '200vh',
                hasBackdrop: false
            });
            let organizationCreated = false;

            // create organization
            const data: any = await this.http.post(environment.apiUrl + '/api/organization/', sendFormDataObj, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise()
                .then((response) => {
                    organizationCreated = true;
                    return Promise.resolve(response);
                })
                .catch(error => {
                    _loader.afterClosed().subscribe(() => {
                        this.dialog.open(DialogErrorComponent, {
                            data: {
                                message: FormValidationService.readError(error).message
                            }
                        });
                    });
                });

            if (organizationCreated) {
                const onSuccess = () => {
                    _loader.afterClosed().subscribe(() => {
                        const successDialog = this.dialog.open(DialogSuccessComponent, {
                            data: {
                                message: `Successfully created ${orgName} organization!`
                            }
                        });

                        successDialog.afterClosed().subscribe(() => {
                            this.closeDialog();
                            this.submitEvent.emit();
                        });
                    });
                };
                // create rss feeds
                if (filteredUrls?.length) {
                    await this.rssService.create(
                        filteredUrls.map(url => ({ name: url, feedUrl: url, organizationID: [data.organization.organizationID] }))
                    ).then(() => onSuccess()).catch(error => {
                        const infoDialog = this.dialog.open(DialogInfoComponent, {
                            data: {
                                message: `
                                    <h3 class="text-left font-20">
                                        <i class="fa fa-check-circle text-success"></i> Successfully created ${orgName} organization!
                                    </h3>
                                    <br>
                                    <h3 class="text-left font-20">
                                        <i class="fa fa-times-circle text-danger"></i> RSS feed creation
                                    </h3>
                                    <p class="text-left">${Object.values((FormValidationService.readError(error)?.formMessages || {})).join('<br>')}</p>
                                `
                            }
                        });
                        infoDialog.afterClosed().subscribe(() => {
                            this.closeDialog();
                            this.submitEvent.emit();
                        });
                    });
                } else {
                    onSuccess();
                }

                // update localStorage
                this.authService.getMe().toPromise().then(response => {
                    LoggedUser.saveUser(response['user']);
                });
            }

            _loader.close();
        } else {
            this.message = FormValidationService.getMessages(this.newOrganizationForm.controls);
        }
    }

    addNewInput() {
        let lastId = this.inputMetas[this.inputMetas.length - 1].id;
        const newMeta = {id: lastId++};
        this.inputMetas.push(newMeta);
        (this.newOrganizationForm.get('inputMetasArray') as FormArray).push(new FormControl('', [Validators.pattern(this.urlReg)]));
    }

    removeFeedItem(event, i) {
        const urlID = i + 1;

        if (urlID) {
            const index = this.inputMetas.findIndex(function (item) {
                return item.id === urlID;
            });
            this.inputMetas.splice(index, 1);
            (this.newOrganizationForm.get('inputMetasArray') as FormArray).removeAt(i);
        }
    }

    validateInput(): void {
        if (!this.newOrganizationForm.valid) {
            this.message = FormValidationService.getMessages(this.newOrganizationForm.controls);
        }
    }

    /**
     * Getters for create organization form
     * */
    get organizationName(): any {
        return this.newOrganizationForm.get('orgName').value;
    }

    get organizationAddressCountry(): any {
        return this.newOrganizationForm.get('orgCountry').value;
    }

    get organizationAddressState(): any {
        return this.newOrganizationForm.get('orgState').value;
    }

    get organizationAddressZip(): any {
        return this.newOrganizationForm.get('orgZip').value;
    }

    get organizationAddress(): any {
        return this.newOrganizationForm.get('orgAddress').value;
    }

    get organizationParent(): any {
        return this.newOrganizationForm.get('organizationParent').value;
    }

    get organizationDesc(): any {
        return this.newOrganizationForm.get('orgDesc').value;
    }

    get organizitaionFeedUrls(): any {
        return this.newOrganizationForm.get('inputMetasArray');
    }

    get languageService() {
        return LanguageService;
    }

}
