import { WidgetMetricOptionType } from "~/src/app/modules/analytics/widget/widget.interfaces";
import { MetricConfigs } from "~/src/app/modules/analytics/widget/metric-configs/metric.config";
import { BaseChartConfig } from "~/src/app/modules/analytics/widget/metric-configs/_base-chart-config";
import { SOCIAL_MEDIA_TYPE_LINKEDIN } from "~/src/app/core/constants";
import { WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_ENGAGEMENT } from "~/src/app/modules/analytics/widget/metric-configs/linkedin/interactions";

export const WIDGET_METRIC_OPTION_TOP_POSTS_IMPRESSIONS: WidgetMetricOptionType =
    {
        id: "top-post-by-impressions",
        name: "Top posts by Impressions",
        resource: "analytics.analytics.topPostsByImpression",
        socialChannel: "facebook",
    };

export const WIDGET_METRIC_OPTION_TOP_POSTS_REACH: WidgetMetricOptionType =
    {
        id: "top-post-by-impressions",
        name: "Top posts by Reach",
        resource: "analytics.analytics.topPostsByImpression",
        socialChannel: "facebook",
    };

export const WIDGET_METRIC_OPTION_TOP_POSTS_ENGAGEMENT: WidgetMetricOptionType =
    {
        id: "top-post-by-engagement",
        name: "Top posts by Engagement",
        resource: "analytics.analytics.topPostsByEngagement",
        socialChannel: "facebook",
    };

export const WIDGET_METRIC_OPTION_POSTS_IMPRESSIONS: WidgetMetricOptionType = {
    id: "post-impressions",
    name: "Post Impressions",
    resource: "analytics.analytics.topPostsByImpression",
    socialChannel: "facebook",
};

export const WIDGET_METRIC_OPTION_POSTS_ENGAGEMENT: WidgetMetricOptionType = {
    id: "post-engagement",
    name: "Post Engagement",
    resource: "analytics.analytics.pagePostEngagement",
    socialChannel: "facebook",
};

// METRIC CONFIGS

export const MetricTopPostsByImpressionsConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_TOP_POSTS_IMPRESSIONS.id,
    apiURL: "/top-posts-impression",
    socialType: "facebook",
    contentType: "table",
    dataName: "Top Posts By Impression",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        limit: 15,
    },
    unavailableSocialTypes: [SOCIAL_MEDIA_TYPE_LINKEDIN],
};

export const MetricTopPostsByReachConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_TOP_POSTS_REACH.id,
    apiURL: "/top-posts-reach",
    socialType: "facebook",
    contentType: "table",
    dataName: "Top Posts By Reach",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        limit: 15,
    },
    unavailableSocialTypes: [SOCIAL_MEDIA_TYPE_LINKEDIN],
};

export const MetricTopPostsByEngagementConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_TOP_POSTS_ENGAGEMENT.id,
    apiURL: "/top-posts-engagement",
    socialType: "facebook",
    contentType: "table",
    dataName: "Top Posts By Engagement",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        limit: 15,
    },
};

export const MetricPostsImpressionsConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_POSTS_IMPRESSIONS.id,
    apiURL: "/page-impressions",
    socialType: "facebook",
    contentType: "chart",
    dataName: "Post Reach",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        unique: "yes",
    },
};

export const MetricPostsEngagementConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_POSTS_ENGAGEMENT.id,
    apiURL: "/page-post-engagement",
    socialType: "facebook",
    contentType: "chart",
    dataName: "Post Engagement",
    replaceMetricIDMap: {
        linkedIn: WIDGET_METRIC_OPTION_LINKEDIN_INTERACTIONS_ENGAGEMENT.id,
        facebook: "",
        twitter: "",
        instagram: "",
        gmb: "",
    },
    chartConfig: {
        ...BaseChartConfig,
    },
};
