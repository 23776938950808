import {ModelAbstract} from '~/src/app/services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateDashboard, Dashboard} from '~/src/app/modules/analytics/dashboard.interfaces';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import {UUID} from 'angular2-uuid';
import {UsersController} from '~/src/app/modules/users/users/users.component';
import {OrganizationController} from '~/src/app/components/organization-select/organization.service';
import {environment} from '~/src/environments/environment';
import {NewsFeedTargetingLinkedinService} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.service';
import {BackendService} from '~/src/app/core/backend.service';

@Injectable()
export class AnalyticsService extends ModelAbstract {
    public apiPath = environment.apiUrl + '/api/dashboard';
    public lastCreatedWidgetId: string;
    public analyticsApiPath = environment.apiUrl + '/api/analytics';

    constructor(
        public http: HttpClient,
        public dialog: MatDialog,
        public usersController: UsersController,
        public organizationController: OrganizationController,
        private backendService: BackendService,
        private linkedinTargetingService: NewsFeedTargetingLinkedinService
    ) {
        super(http, dialog);

        this.apiLink = this.apiPath;
    }

    createDashboard(dashboard: CreateDashboard): Promise<any> {
        return this.http.post(
            this.apiPath,
            Helpers.objectToFormData(dashboard), Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    getDashboard(dashboard: Dashboard): Promise<any> {
        return this.http.get(
            `${this.apiPath}/${dashboard.dashboardID}`,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    updateDashboard(dashboard: Dashboard): Promise<any> {
        return this.http.post(
            `${this.apiPath}/${dashboard.dashboardID}`,
            Helpers.objectToFormDataRaw(dashboard), Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    deleteDashboard(dashboard: Dashboard) {
        return this.http.delete(
            `${this.apiPath}/${dashboard.dashboardID}`,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    setDashboardShare(shareSettings: any): Promise<any> {
        return this.backendService.post(
            `/dashboard/share`,
            {
                shareSettings: shareSettings
            }
        );
    }

    healthCheckAnalytics(): Promise<any> {
        return this.http.get(
            `${this.apiPath}/health-check`,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    getUnfinishedSocialSites(): Promise<any> {
        return this.http.get(
            `${this.apiPath}/data-available`,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    generateUUID(): string {
        this.lastCreatedWidgetId = UUID.UUID();

        return this.lastCreatedWidgetId;
    }
}
