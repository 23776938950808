import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PostTemplateFolderSelectDialogData, TemplateFolderMinimalInterface, TemplateFolderType } from "../../posts/template-folder.interface";
import { PostTemplate, PostTemplateInterface } from "../../posts/template.interface";
import { TemplateFolderActionsService } from "../../posts/template-folder-actions.service";
import { OpenModalService } from "../open-modal.service";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { LanguageService } from "~/src/app/services/language.service";
import { Debounce } from "~/src/app/services/helpers";
import { TemplateActionsService } from "../../posts/template-actions.service";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { TemplateFolderManagerComponent } from "../template-folder-manager/template-folder-manager.component";

@Component({
  selector: "smd-template-folder-select",
  templateUrl: "./template-folder-select.component.html",
  styleUrls: ["./template-folder-select.component.scss"],
})
export class TemplateFolderSelectComponent implements OnInit 
{
  foldersLoading: boolean = true;
  templates: Array<PostTemplateInterface> = [];
  folders: Array<TemplateFolderMinimalInterface> = [];
  filteredFolders: Array<TemplateFolderMinimalInterface> = [];
  multipleTemplates: boolean = false;

  initialFolderIDs: Array<number> = [];

  foldersToAdd: Array<number> = [];
  foldersToRemove: Array<number> = [];
  foldersNotChanged: Array<number> = [];

  foldersChangeMultiple: Array<{ 
    templateID: number, 
    foldersToAdd: Array<number>, 
    foldersToRemove: Array<number> 
  }> = [];
  indeterminateFolders: Array<number> = [];
  checkedFolders: Array<number> = [];

  searchControlNames = {
    Keyword: "keyword",
    Type: "type",
  };

  searchFormGroup = new FormGroup({
    [this.searchControlNames.Keyword]: new FormControl("", [Validators.maxLength(255)]),
    [this.searchControlNames.Type]: new FormControl("all"),
  });

  isAdminMode: boolean = false;
  canShare: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PostTemplateFolderSelectDialogData,
    public dialogRef: MatDialogRef<TemplateFolderSelectComponent>,
    public folderService: TemplateFolderActionsService,
    public templateService: TemplateActionsService,
    private openModal: OpenModalService,
  ) {
    this.templates = data.templates;
    this.isAdminMode = data.isAdminMode;
    this.multipleTemplates = data.templates.length > 1;
  }

  ngOnInit() {
    this.initialize();

    this.searchFormGroup.valueChanges.subscribe((values) => {
      this.searchValueChanges(values);
    });
  }

  initialize() {
    this.processCanShare();
    this.preselectFolderType();
    this.getFolders();

  }

  getFolders() {
    this.foldersLoading = true;

    if (this.templates.length === 0) {
      return;
    }
    let filter_folderType: "all" | "user" | "organization" = this.searchFormGroup.controls[this.searchControlNames.Type].value

    if (!this.multipleTemplates) {
      const template = this.templates[0];
      this.folderService.getAll({ minimalData: true, templateIDs: template.templateID.toString() })
        .then((response: any) => {
          this.folders = response.folders;
          
          this.folders.forEach(folder => {
            if (template.templateID == Number(folder.templateID)) {
              this.foldersNotChanged.push(folder.folderID);
              this.initialFolderIDs.push(folder.folderID);
            }
          });

          this.searchValueChanges({keyword:null, type: filter_folderType});
          this.foldersLoading = false;
        })
        .catch((error: any) => {
          console.error(error);
          this.foldersLoading = false;
        });
    } else {
      this.folderService.getAll({ minimalData: true, templateIDs: this.templates.map(template => template.templateID).join(",") })
        .then((response: any) => {
          this.folders = response.folders;

          const templateIDs = this.templates.map(template => template.templateID);
          templateIDs.forEach(templateID => {
            this.foldersChangeMultiple.push({
              templateID: templateID,
              foldersToAdd: [],
              foldersToRemove: [],
            });
          });

          this.folders.forEach(folder => {
            const responseTemplates = folder.templateID ? folder.templateID.split(",") : [];
            const folderTemplates = responseTemplates.map(templateID => Number(templateID));

            // if folder has all templates then it is checked
            if (templateIDs.every(templateID => folderTemplates.includes(templateID))) {
              this.checkedFolders.push(folder.folderID);
            }

            // if folder has some templates then it is indeterminate
            if (templateIDs.some(templateID => folderTemplates.includes(templateID))) {
              this.indeterminateFolders.push(folder.folderID);
            }
          });

          this.searchValueChanges({keyword:null, type: filter_folderType});
          this.foldersLoading = false;
        })
        .catch((error: any) => {
          console.error(error);
          this.foldersLoading = false;
        });
    }
  }

  processCanShare()
  {
    this.canShare = this.templates.length > 0 && !this.templates.map((template: PostTemplate) => {
      return !!template.allowedActions?.share
    }).includes(false);
  }

  preselectFolderType()
  {
    let type: "all" | "user" | "organization" = this.canShare ? "all" : "user";
    this.searchFormGroup.controls[this.searchControlNames.Type].patchValue(type)
  }

  isFolderChecked(folderID: number): boolean {
    if (!this.multipleTemplates) return this.foldersToAdd.includes(folderID) || this.foldersNotChanged.includes(folderID);
    return this.checkedFolders.includes(folderID);
  }

  isFolderIndeterminate(folderID: number): boolean {
    if (!this.multipleTemplates) return false;
    return this.indeterminateFolders.includes(folderID) && !this.checkedFolders.includes(folderID);
  }

  checkboxChange(event: any, folderID: number) {
    if (!this.multipleTemplates) {
      if (event.checked) {
        if (this.initialFolderIDs.includes(folderID) && !this.foldersNotChanged.includes(folderID)) {
          this.foldersNotChanged.push(folderID);
        } else if (!this.initialFolderIDs.includes(folderID)) {
          this.foldersToAdd.push(folderID);
        }
  
        if (this.foldersToRemove.includes(folderID)) {
          this.foldersToRemove = this.foldersToRemove.filter(folder => folder != folderID);
        }
      } else {
        if (this.initialFolderIDs.includes(folderID)) {
          this.foldersToRemove.push(folderID);
  
          if (this.foldersNotChanged.includes(folderID)) {
            this.foldersNotChanged = this.foldersNotChanged.filter(folder => folder != folderID);
          }
        }
  
        if (this.foldersToAdd.includes(folderID)) {
          this.foldersToAdd = this.foldersToAdd.filter(folder => folder != folderID);
        }
      }
    } else {
      const templateIDs = this.templates.map(template => template.templateID);
      const folderTemplates = this.folders.find(folder => folder.folderID === folderID).templateID?.split(",").map(templateID => Number(templateID)) ?? [];

      if (event.checked) {
        this.foldersChangeMultiple.forEach(item => {
          if (!folderTemplates.includes(item.templateID)) {
            item.foldersToAdd.push(folderID);
          }
          item.foldersToRemove = item.foldersToRemove.filter(folder => folder != folderID);
        })
      } else {
        this.foldersChangeMultiple.forEach(item => {
          if (folderTemplates.includes(item.templateID)) {
            item.foldersToRemove.push(folderID);
          }
          item.foldersToAdd = item.foldersToAdd.filter(folder => folder != folderID);
        });
      }
      console.log(this.foldersChangeMultiple);
    }
  }

  searchValueChanges(values: { keyword: string, type: "all" | "user" | "organization" }) {
    const allFolders = this.folders;

    if (values.keyword && values.keyword.length > 0) {
      if (values.type === "all") {
        this.filteredFolders = allFolders.filter(folder => folder.name.toLowerCase().includes(values.keyword.toLowerCase()));
      } else if (values.type === "user" || values.type === "organization") {
        this.filteredFolders = allFolders.filter(folder => folder.name.toLowerCase().includes(values.keyword.toLowerCase()) && folder.type === values.type);
      }
    } else {
      if (values.type === "all") {
        this.filteredFolders = allFolders;
      } else if (values.type === "user" || values.type === "organization") {
        this.filteredFolders = allFolders.filter(folder => folder.type === values.type);
      }
    }
  }

  save() {
    /* if (this.foldersToAdd.length === 0 && this.foldersToRemove.length === 0) {
      this.openModal.successModal(DialogSuccessComponent, {
        title: LanguageService.getLine('core.title.nothing.to.save'),
        message: LanguageService.getLine('core.message.nothing.to.save'),
      }).afterClosed().subscribe(() => {
        this.closeDialog();
      });
    } else { */
    const dataToSend = [];

    if (!this.multipleTemplates) {
      if (this.foldersToAdd.length > 0 || this.foldersToRemove.length > 0) {
        const template = this.templates[0];

        dataToSend.push({
          templateID: template.templateID,
          foldersToAdd: this.foldersToAdd,
          foldersToRemove: this.foldersToRemove,
        });
      }    
    } else {
      this.foldersChangeMultiple.forEach(item => {
        if (item.foldersToAdd.length > 0 || item.foldersToRemove.length > 0) {
          dataToSend.push(item);
        }
      });
    }

    console.log(dataToSend);

    if (dataToSend.length === 0) {
      this.openModal.successModal(DialogSuccessComponent, {
        title: LanguageService.getLine('core.title.nothing.to.save'),
        message: LanguageService.getLine('core.message.nothing.to.save'),
      }).afterClosed().subscribe(() => {
        this.closeDialog();
      });
    } else {
      this.templateService.assignTemplatesToFolders(dataToSend)
        .then((response: any) => {
          if (response.result) {
            let successMessage = LanguageService.getLine('core.message.success');
            console.log(response.result);
            /* if (response.success.addedCount && response.success.removedCount) {
              const added = response.success.addedCount;
              const removed = response.success.removedCount;

              if (added > 0 && removed > 0) {
                successMessage = LanguageService.getLine('template.folder.chooser.add.remove.success', { addedCount: added, removedCount: removed });
              }
              if (added > 0 && removed === 0) {
                successMessage = LanguageService.getLine('template.folder.chooser.add.success', { addedCount: added });
              }
              if (added === 0 && removed > 0) {
                successMessage = LanguageService.getLine('template.folder.chooser.remove.success', { removedCount: removed });
              }
            } */

            this.openModal.successModal(DialogSuccessComponent, {
              message: successMessage,
            }).afterClosed().subscribe(() => {
              this.closeDialog();
              this.getFolders();
              this.data.afterSuccessSave();
            });
          }
        })
        .catch((error: any) => {
          this.closeDialog();
          this.openModal.errorModal(DialogErrorComponent, {
            message: LanguageService.getLine('core.message.error'),
          }).afterClosed().subscribe(() => {
            this.closeDialog();
          });
        });
    }
  }

  createFolder() {
    this.openModal.createTemplateFolder(TemplateFolderManagerComponent, {
      data: {
        afterSuccessCreate: () => {
          this.getFolders();
        },
        isAdminMode: this.isAdminMode,
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}