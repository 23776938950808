
export class SetTemplatesToPartnersModel {

    /**
     * Group actions
     *
     * @type {{DisallowAllOnPage: string; AllowAllOnResult: string; DisallowAllOnResult: string; AllowAllOnPage: string}}
     */
    static GROUP_ACTIONS = {
        AllowAllOnPage: 'AllowAllOnPage',
        DisallowAllOnPage: 'DisallowAllOnPage',
        AllowAllOnResult: 'AllowAllOnResult',
        DisallowAllOnResult: 'DisallowAllOnResult',
    };

}
