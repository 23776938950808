import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Helpers} from './helpers';
import {Observable} from 'rxjs/index';
import {Token} from './token';
import {LoggedUser} from './logged-user';
import {environment} from '~/src/environments/environment';


@Injectable({ providedIn: 'root' })
export class UserRolesService {
    private userIsAdmin = false;
    private currentUser = null;
    private setTestUserWhoIsNotAdmin = false; // true: test user who is not admin

    constructor(
        private _http: HttpClient,
    ) { }

    private currentUsers(): Observable<any> {
        this.currentUser = LoggedUser.getUser();
        return this._http.get(environment.apiUrl + '/api/users', Helpers.getBaseHttpHeaders(Token.getToken()));
    }

    /**
     * Checked the role and run task
     * @param role except role name
     */
    public currentUserCan(role: string): Promise<any> {
        if (role === 'admin') {
            return this.checkAdmin();
        }
    }

    private checkAdmin(): Promise<any> {
        return new Promise((resolve, reject) => {
            const userObj = LoggedUser.getUser();

            if (this.setTestUserWhoIsNotAdmin) {
                userObj.organizations[0].admin = 'no';
            }

            const currentMainOrg = userObj.organizations.find((org) => {
                return org.main === true && org.admin === 'yes';
            });

            if (currentMainOrg) {
                this.userIsAdmin = true;
                resolve(this.userIsAdmin);

            } else {
                this.userIsAdmin = false;
                resolve(this.userIsAdmin);
            }
        });
    }

}
