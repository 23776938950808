import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "~/src/app/shared/shared.module";
import { LanguagesModule } from "~/src/app/modules/languages/languages.module";
import { GmbFormComponent } from "./form/gmb-form.component";
import { CallToActionFormComponent } from "./form/call-to-action/call-to-action-form.component";
import { EventFormComponent } from "./form/event/event-form.component";
import { OfferFormComponent } from "./form/offer/offer-form.component";
import { AbstractFormGroupComponent } from "./form/abstract/abstract.component";
import { FormHelpersService } from "~/src/app/core/services/form-helpers";

@NgModule({
    declarations: [
        GmbFormComponent,
        CallToActionFormComponent,
        EventFormComponent,
        OfferFormComponent,
        AbstractFormGroupComponent,
    ],
    exports: [GmbFormComponent],
    imports: [CommonModule, SharedModule, LanguagesModule],
    providers: [FormHelpersService],
})
export class GmbModule {}
