export class DefaultRoleManagerConfig {
    static getDefaultRoleControlNames() {
        return {
            DefaultRoleID: 'defaultRoleID',
            Name: 'name',
            Description: 'description',
            Resources: 'resourceID',
            IsAdmin: 'admin',
            Status: 'status'
        };
    }
}
