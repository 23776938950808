import {AllMetricConfig, MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import Utils from '~/src/app/core/utils';

export class MetricConfigGetter {
    /**
     * Get metric config by metricID
     * @param {string} metricID
     * @returns {MetricConfigs}
     */
    public static get(metricID: string): MetricConfigs {
        return Utils.lodash.cloneDeep(
            AllMetricConfig.find(item => item.metricID === metricID)
        );
    }
}
