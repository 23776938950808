import {environment} from '~/src/environments/environment';
import Utils from '~/src/app/core/utils';
import {ArraySupport} from '~/src/app/core/helper/array-support';

export class Debug {

    /**
     * Dump values
     *
     * @param {any | any[]} values
     * @param {boolean} isDeep
     */
    static dump(values: any | any[], isDeep: boolean = true): void {

        if (!this.isEnable()) {
            return;
        }

        // convert values to array
        values = ArraySupport.itemConvertToArray(values);

        if (isDeep) {
            values = values.map(value => {
                return Utils.lodash.cloneDeep(value);
            });
        }

        console.log(...values);
    }

    /**
     * Warn message in console
     * @param {string} message
     */
    static warn(...message: any) {
        if (!this.isEnable()) {
            return;
        }

        console.warn(...message);
    }

    /**
     * Check function run time
     * @param func
     * @param label
     */
    static runTime(func: () => any, label = 'Function run time'): void {

        // if production mode, close function
        if (!this.isEnable()) {
            return;
        }

        // check param is function
        if (typeof func !== 'function') {
            console.warn(
                '%cDebug warning: %cThe param is not function!', `font-weight: bold; font-size: ${this.getConfig().mediumFontSize};`
            );
        }

        // tslint:disable-next-line:no-console
        console.time(label);

        // call function
        func();

        // tslint:disable-next-line:no-console
        console.timeEnd(label);
    }

    /**
     * Get debug config
     */
    private static getConfig() {
        return {
            titleColor: 'green',
            paragraphColor: 'blue',
            largeFontSize: '22px',
            mediumFontSize: '18px',
            smallFontSize: '16px'
        };
    }

    /**
     * Check debug is enable
     */
    private static isEnable(): boolean {
        return environment.isDebugMode;
    }
}
