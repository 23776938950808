import { debounceTime } from "rxjs/operators";
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { UserService } from "~/src/app/services/user.service";
import { RoleService } from "~/src/app/components/organization-select/role.service";
import { HttpClient } from "@angular/common/http";
import { UsersComponent } from "~/src/app/modules/users/users/users.component";
import { UsersService } from "~/src/app/modules/users/users/users.service";
import { ModifyUserDialogComponent } from "./modify-user-dialog/modify-user-dialog.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { LanguageService } from "~/src/app/services/language.service";
import { NotifyService } from "~/src/app/services/notify.service";
import {
    Organization,
    OrganizationService,
} from "~/src/app/components/organization-select/organization.service";
import { OrganizationComponent } from "~/src/app/components/organization-select/organization.component";
import { ComponentAbstract } from "~/src/app/services/component.abstract";
import { ModifyRoleDialogComponent } from "~/src/app/modules/users/access-management/roles/modify-role-dialog/modify-role-dialog.component";
import { StateSaver } from "~/src/app/services/state-saver";
import { FILTERS_DEBOUNCE_MS } from "~/src/app/configs/configs";
import { PaginationController } from "~/src/app/services/pagination.controller";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { ActivationButtonClick } from "~/src/app/core/components/activation-button/activation-button.component";
import { ExportResourceService } from "~/src/app/core/export-resource.service";
import { Resource } from "~/src/app/core/export-resource";
import { UsersResourceService } from "~/src/app/modules/users/users-resource.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import {
    CollectionListConfig,
    CollectionListSelectionChange,
    TableBodyConfig,
    TableHeadConfig,
} from "~/src/app/core/components/collection-list/collection-list.interfaces";
import { DataTableHelpers } from "~/src/app/core/services/data-table-helpers.service";
import {
    User as UserModel,
    UsersResponse,
} from "~/src/app/modules/users/users-resource";
import { RolesResourceService } from "~/src/app/modules/users/roles-resource.service";
import { Role as RoleModel } from "~/src/app/modules/users/roles-resource";
import Utils from "~/src/app/core/utils";
import { CollectionListComponent } from "~/src/app/core/components/collection-list/collection-list.component";
import { LoggedUser } from "~/src/app/services/logged-user";
import { MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { Helpers } from "~/src/app/services/helpers";
import { Token } from "~/src/app/services/token";

declare const $;

export interface User {
    userID: number;
    name: string;
    status: string;
}

export interface Role {
    roleID: string;
    name: string;
    description: string;
    organizationID: string;
    admin: string;
    isDefault?: boolean;
    isPartnerRole?: boolean;
}

@Component({
    selector: "smd-access-management",
    templateUrl: "./access-management.component.html",
    styleUrls: ["./access-management.component.scss"],
    providers: [
        ComponentHelpers,
        ExportResourceService,
        UsersResourceService,
        RolesResourceService,
    ],
})
export class AccessManagementComponent
    extends ComponentAbstract
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild("paginatorUser", { read: MatPaginator })
    paginatorUser: MatPaginator;
    @ViewChild("paginatorDefaultRole", { read: MatPaginator })
    paginatorDefaultRole: MatPaginator;
    @ViewChild("paginatorPartnerRole", { read: MatPaginator })
    paginatorPartnerRole: MatPaginator;
    @ViewChild("paginatorOrganizationRole", { read: MatPaginator })
    paginatorOrganizationRole: MatPaginator;

    @ViewChild("userCollectionList", { read: CollectionListComponent })
    userCollectionList: CollectionListComponent;
    @ViewChild("roleCollectionList", { read: CollectionListComponent })
    roleCollectionList: CollectionListComponent;

    @ViewChild("roleTabGroup")
    roleTabGroup: MatTabGroup;

    apiLink = '/api/users';

    userFiltersForm = new FormGroup({
        name: new FormControl(null),
        status: new FormControl(null),
        organizationID: new FormControl(null),
        isAdmin: new FormControl(null),
    });

    partnerRoleFilterForm = new FormGroup({
        keyword: new FormControl(""),
        isAdmin: new FormControl(false),
    });
    organizationRoleFilterForm = new FormGroup({
        keyword: new FormControl(""),
        isAdmin: new FormControl(false),
        organizationID: new FormControl([]),
    });
    dataRoleSource: any = [];
    selection = new SelectionModel(true, []);
    selectionRoles = new SelectionModel(true, []);
    organizations: Array<Organization>;
    usersComponent: UsersComponent;
    _organizationsComponent: OrganizationComponent;
    users = [];
    resources: Resource[] = [];
    organizationIDs = [];
    dialogRef: any;
    selected = "All";
    _stateManager = null;
    userPaginatorController: PaginationController = new PaginationController();
    defaultRolePaginatorController: PaginationController =
        new PaginationController();
    partnerRolePaginatorController: PaginationController =
        new PaginationController();
    organizationRolePaginatorController: PaginationController =
        new PaginationController();

    userTableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: "access.management.id",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.image",
        },
        {
            nameKey: "access.management.name",
        },
        {
            nameKey: "access.management.username",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.organization",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.status",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.admin",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.actions",
            onlyDesktop: true,
        },
    ];
    userTableBodyConfig: TableBodyConfig<UserModel>[] = [
        {
            bindValue: "userID",
            onlyDesktop: true,
        },
        {
            bindValue: "profileImageUrl",
            selector: "userProfile",
        },
        {
            bindValue: "fullName",
            selector: "userFullName",
        },
        {
            bindValue: "username",
            onlyDesktop: true,
        },
        {
            bindValue: "mainOrganization",
            onlyDesktop: true,
        },
        {
            bindValue: "statusMessage",
            onlyDesktop: true,
        },
        {
            bindValue: "isAdmin",
            onlyDesktop: true,
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
        },
    ];
    roleCollectionConfig: CollectionListConfig<Role> = {
        allowSelection: true,
    };
    defaultRoleCollectionConfig: CollectionListConfig<Role> = {
        allowSelection: false,
    };
    defaultRoleTableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: "access.management.role.name",
        },
        {
            nameKey: "access.management.admin",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.actions",
            onlyDesktop: true,
            alignment: "right",
        },
    ];
    partnerRoleTableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: "access.management.role.name",
        },
        {
            nameKey: "access.management.actions",
            onlyDesktop: true,
            alignment: "right",
        },
    ];
    organizationRoleTableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: "access.management.role.name",
        },
        {
            nameKey: "organization.organization.name",
            onlyDesktop: true,
            onlyMobile: true,
        },
        {
            nameKey: "access.management.actions",
            onlyDesktop: true,
            alignment: "right",
        },
    ];
    defaultRoleTableBodyConfig: TableBodyConfig<Role>[] = [
        {
            bindValue: "name",
        },
        {
            bindValue: "admin",
            onlyDesktop: true,
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
            alignment: "flex-end",
        },
    ];
    partnerRoleTableBodyConfig: TableBodyConfig<Role>[] = [
        {
            bindValue: "name",
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
            alignment: "flex-end",
        },
    ];
    organizationRoleTableBodyConfig: TableBodyConfig<Role>[] = [
        {
            bindValue: "name",
        },
        {
            bindValue: "organization.name",
            onlyDesktop: true,
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
            alignment: "flex-end",
        },
    ];

    userCollectionListConfig: CollectionListConfig<UserModel> = {
        allowSelection: (item) => {
            return (
                !item.isCurrentUser
            );
        },
    };

    userDataTableHelpers: DataTableHelpers = new DataTableHelpers();
    organizationRoleDataTableHelpers: DataTableHelpers = new DataTableHelpers();
    partnerRoleDataTableHelpers: DataTableHelpers = new DataTableHelpers();

    roles: RoleModel[] = [];
    viewRoles: RoleModel[] = [];

    defaultRolesAll: RoleModel[] = [];
    partnerRolesAll: RoleModel[] = [];
    organizationRolesAll: RoleModel[] = [];

    defaultRoles: RoleModel[] = [];
    partnerRoles: RoleModel[] = [];
    organizationRoles: RoleModel[] = [];

    private subs = [];
    private dueTime = FILTERS_DEBOUNCE_MS; // for debounces

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
        public service: UsersService,
        public usersResource: UsersResourceService,
        public rolesResource: RolesResourceService,
        private openModal: OpenModalService,
        private exportResource: ExportResourceService,
        private userDataService: UserService,
        private organizationRoleService: RoleService,
        private _organizationsService: OrganizationService,
        private partnerConfigService: PartnerConfigService,
        private componentHelpers: ComponentHelpers,
        public activatedRoute: ActivatedRoute,
    ) {
        super();
        this.usersComponent = new UsersComponent(this.service);
        this._organizationsComponent = new OrganizationComponent(
            this._organizationsService
        );

        this.rolesResource.roles.subscribe((roles) => {
            const { limit, offset } =
                this.organizationRolePaginatorController.requestParams;

            this.defaultRolesAll = [];
            this.partnerRolesAll = [];
            this.organizationRolesAll = [];
            roles.forEach((role) => {
                if (role.isPartnerRole) {
                    if (
                        !this.partnerRolesAll.some(
                            (partnerRole) =>
                                partnerRole.defaultRoleID === role.defaultRoleID
                        )
                    ) {
                        if (
                            this.partnerRoleFilterForm.get("keyword").value ===
                                "" ||
                            role.name
                                .toLowerCase()
                                .match(
                                    this.partnerRoleFilterForm
                                        .get("keyword")
                                        .value.toLowerCase()
                                )
                        ) {
                            this.partnerRolesAll.push(role);
                        }
                    }
                } else if (role.isDefault) {
                    if (
                        !this.defaultRolesAll.some(
                            (defaultRole) =>
                                defaultRole.defaultRoleID === role.defaultRoleID
                        )
                    ) {
                        this.defaultRolesAll.push(role);
                    }
                } else {
                    if (
                        (this.organizationRoleFilterForm.get("keyword")
                            .value === "" ||
                            role.name
                                .toLowerCase()
                                .match(
                                    this.organizationRoleFilterForm
                                        .get("keyword")
                                        .value.toLowerCase()
                                )) &&
                        (this.organizationRoleFilterForm.get("organizationID")
                            .value.length === 0 ||
                            this.organizationRoleFilterForm
                                .get("organizationID")
                                .value.includes(Number(role.organizationID)))
                    ) {
                        this.organizationRolesAll.push(role);
                    }
                }
            });

            this.roles = roles;

            this.defaultRolePaginatorController.paginationOptions.length =
                this.defaultRolesAll.length;

            this.partnerRolePaginatorController.paginationOptions.length =
                this.partnerRolesAll.length;

            this.organizationRolePaginatorController.paginationOptions.length =
                this.organizationRolesAll.length;

            this.defaultRoles = Utils.lodash
                .cloneDeep(this.defaultRolesAll)
                .slice(offset, offset + limit);
            this.partnerRoles = Utils.lodash
                .cloneDeep(this.partnerRolesAll)
                .slice(offset, offset + limit);
            this.organizationRoles = Utils.lodash
                .cloneDeep(this.organizationRolesAll)
                .slice(offset, offset + limit);
        });

        this.organizationRolePaginatorController.onSetPagination.subscribe(
            () => {
                const { limit, offset } =
                    this.organizationRolePaginatorController.requestParams;

                this.organizationRoles = Utils.lodash
                    .cloneDeep(this.organizationRolesAll)
                    .slice(offset, offset + limit);
            }
        );

        this.defaultRolePaginatorController.onSetPagination.subscribe(() => {
            const { limit, offset } =
                this.defaultRolePaginatorController.requestParams;

            this.defaultRoles = Utils.lodash
                .cloneDeep(this.defaultRolesAll)
                .slice(offset, offset + limit);
        });

        this.partnerRolePaginatorController.onSetPagination.subscribe(() => {
            const { limit, offset } =
                this.partnerRolePaginatorController.requestParams;

            this.partnerRoles = Utils.lodash
                .cloneDeep(this.partnerRolesAll)
                .slice(offset, offset + limit);
        });

        const loader = this.openModal.loader(DialogLoaderComponent);
        const requests = [
            this.getUsers(loader),
            this.changeRolesDataSource(loader),
        ];

        if (
            this.partnerConfigService.hasConfig(PartnerPermissions.ResourcesTab)
        ) {
            requests.push(
                this.exportResource.getResources().then((response) => {
                    this.resources = response.resources;

                    return Promise.resolve(response);
                })
            );
        }

        Promise.all(requests)
            .then(() => loader.close())
            .catch(() => loader.close());
    }

    get languageService() {
        return LanguageService;
    }

    get partnerPermissions() {
        return PartnerPermissions;
    }

    ngOnInit() {
        this.urlListener();
        this.usersFilter();
        this.rolesFilter();

        this.usersPaginatorInit();
        this.defaultRolePaginatorController.setPaginator(
            this.paginatorDefaultRole
        );
        this.partnerRolePaginatorController.setPaginator(
            this.paginatorPartnerRole
        );
        this.organizationRolePaginatorController.setPaginator(
            this.paginatorOrganizationRole
        );
        this._stateManager = new StateSaver();
        this._stateManager.getState("userAccessNav");

        this.getOrganizations(() => {
            this.dataRoleSource.paginator = this.paginatorDefaultRole;
        });
    }

    ngOnDestroy(): void {
        this.destroyListeners();
    }

    ngAfterViewInit() {}

    /**
     * Users selection change
     * @param {CollectionListSelectionChange} event
     */
    usersSelectionChange(event: CollectionListSelectionChange) {
        this.userDataTableHelpers.selectedItems = event.selectedItems;
    }

    /**
     * Roles selection change
     * @param {CollectionListSelectionChange} event
     */
    rolesSelectionChange(event: CollectionListSelectionChange, type) {
        if (type === "partner") {
            this.partnerRoleDataTableHelpers.selectedItems =
                event.selectedItems;
        } else {
            this.organizationRoleDataTableHelpers.selectedItems =
                event.selectedItems;
        }
    }

    /**
     * Bulk user delete
     * @param {MouseEvent} event
     */
    bulkUserDelete(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const userIDs: number[] = this.userDataTableHelpers
            .getSelectedItems<UserModel>()
            .map((user) => user.userID);

        this.componentHelpers.startApiAction(
            () => {
                return this.userDataService.deleteUsers(userIDs);
            },
            {
                confirmMessageKey: "access.management.multiple.continue",
                confirmYesButtonTextKey:
                    "access.management.multiple.confirmDeleteUsers",
                successMessageKey: "access.management.multiple.success",
                failedMessageKey: "access.management.multiple.error",
                afterSuccessAction: () => {
                    this.getUsers();
                },
            },
            true
        );
    }

    /**
     * Export all/selected users to csv
     * @param {MouseEvent} event
     */
    exportUsers(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const userIDs: number[] = this.userDataTableHelpers
            .getSelectedItems<UserModel>()
            .map((user) => user.userID);

        const sendFormDataObj = new FormData();
        sendFormDataObj.append('userIDs', JSON.stringify(userIDs));
        return this.http.post(this.apiLink + '/export', sendFormDataObj, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response: any) => {
                Utils.generateFile(
                    btoa(unescape(encodeURIComponent(response.content))),
                    response.mime,
                    response.name
                );
            })
            .catch(error => {
                console.error(error);
                return error;
            }
        );
    }

    bulkRoleDelete(event: MouseEvent, type) {
        event.preventDefault();
        event.stopPropagation();
        let roleID = [];

        if (type === "partner") {
            roleID = this.partnerRoleDataTableHelpers
                .getSelectedItems<RoleModel>()
                .map((role) => role.roleID);
        } else {
            roleID = this.organizationRoleDataTableHelpers
                .getSelectedItems<RoleModel>()
                .map((role) => role.roleID);
        }

        const data = {
            roleID,
            isPartnerRole: type === "partner",
        };

        this.componentHelpers.startApiAction(
            () => {
                return this.organizationRoleService
                    .deleteRoles(data)
                    .toPromise();
            },
            {
                confirmMessageKey: "access.management.multiple.continue",
                confirmYesButtonTextKey:
                    "access.management.multiple.confirmDeleteRoles",
                successMessageKey: "access.management.multiple.success",
                failedMessageKey: "access.management.multiple.error",
                afterSuccessAction: () => {
                    this.changeRolesDataSource();
                },
            },
            true
        );
    }

    defaultRolesSelectionChange(event) {
        return;
    }

    bulkRoleEdit(event: MouseEvent, type: string) {
        event.preventDefault();
        event.stopPropagation();

        this.showModifyRoleModalBulk(
            type,
            this.organizationRoleDataTableHelpers.getSelectedItems()
        );
    }

    /**
     * @description for matSelects
     * @param select
     * @param dataArray
     */
    selectAllOrganization(select: FormControl, dataArray, modelValueProperty) {
        const selectedValues = dataArray.map(
            (data) => data[modelValueProperty]
        );
        select.setValue(selectedValues);
    }

    deselectAllOrganization(socialSiteSelect) {
        socialSiteSelect.setValue([]);
    }

    public usersFilter() {
        this.userFiltersForm.valueChanges
            .pipe(debounceTime(this.dueTime))
            .subscribe((values) => {
                this.userPaginatorController.paginationOptions.pageIndex = 0;

                this.getUsers();
            });
    }

    public rolesFilter() {
        const sub1 = this.partnerRoleFilterForm.valueChanges
            .pipe(debounceTime(this.dueTime))
            .subscribe((values) => {
                this.partnerRolePaginatorController.paginationOptions.pageIndex = 0;
                this.changeRolesDataSource();
            });

        this.subs.push(sub1);

        const sub2 = this.organizationRoleFilterForm.valueChanges
            .pipe(debounceTime(this.dueTime))
            .subscribe((values) => {
                this.organizationRolePaginatorController.paginationOptions.pageIndex = 0;
                this.changeRolesDataSource();
            });

        this.subs.push(sub2);
    }

    /**
     * Reset role filters
     */
    public resetRoleFilters(type) {
        if (type === "partner") {
            this.partnerRoleFilterForm.get("keyword").setValue("");
            this.partnerRoleFilterForm.get("isAdmin").setValue(false);
        } else {
            this.organizationRoleFilterForm.get("keyword").setValue("");
            this.organizationRoleFilterForm.get("isAdmin").setValue(false);
            this.organizationRoleFilterForm.get("organizationID").setValue([]);
        }
    }

    /**
     * Reset user filters
     */
    public resetUsersListFilter() {
        this.userFiltersForm.get("name").setValue("");
        this.userFiltersForm.get("status").setValue("");
        this.userFiltersForm.get("organizationID").setValue(null);
        this.userFiltersForm.get("isAdmin").setValue("");
        this.userFiltersForm.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true,
        });
    }

    /**
     * Get users from server
     * @returns {Promise<UsersResponse>}
     */
    public getUsers(
        loader?: MatDialogRef<DialogLoaderComponent>
    ): Promise<UsersResponse> {
        const loaderCloseRequired = !loader;

        if (!loader) {
            loader = this.openModal.loader(DialogLoaderComponent);
        }

        if (!!this.userCollectionList) {
            this.userCollectionList.clearSelection();
        }

        return this.usersResource
            .getUsers({
                ...this.userPaginatorController.requestParams,
                ...this.userFiltersForm.value,
            })
            .then((response) => {
                this.userPaginatorController.paginationOptions.length =
                    response.count;

                if (loaderCloseRequired) {
                    loader.close();
                }

                return Promise.resolve(response);
            })
            .catch((error) => {
                loader.afterClosed().subscribe(() => {
                    this.componentHelpers.failedApiAction(error, {
                        messageKey: "core.getItems.error",
                    });
                });

                if (loaderCloseRequired) {
                    loader.close();
                }

                return Promise.reject(error);
            });
    }

    public getOrganizations(callback?: Function) {
        return this._organizationsComponent.getItems(
            ({ organizations }: { organizations: any[] }) => {
                this.organizations = organizations;

                if (callback) {
                    callback();
                }
            },
            (error) => {
                NotifyService.error(
                    this.languageService.getLine(
                        "access.management.organization.get.error"
                    ),
                    ""
                );
            }
        );
    }

    public changeRolesDataSource(
        loader?: MatDialogRef<DialogLoaderComponent>,
        filters?: { [key: string]: any }
    ): Promise<any> {
        const loaderCloseRequired = !loader;

        if (!loader) {
            loader = this.openModal.loader(DialogLoaderComponent);
        }

        if (!!this.roleCollectionList) {
            this.roleCollectionList.clearSelection();
        }

        return this.rolesResource
            .getRoles({
                ...filters,
            })
            .then((response) => {
                if (loaderCloseRequired) {
                    loader.close();
                }
            })
            .catch((error) => {
                loader.afterClosed().subscribe(() => {
                    NotifyService.error(
                        FormValidationService.readError(error).message,
                        ""
                    );
                });

                if (loaderCloseRequired) {
                    loader.close();
                }
            });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelectedRoles() {
        const numSelected = this.selectionRoles.selected.length;
        const numRows = this.dataRoleSource.data.length;

        return numSelected === numRows;
    }

    /**
     * Re-send activation email to inactive user
     * @param event
     * @param userData
     */
    resendActivationEmail(event: MouseEvent, userData) {
        event.preventDefault();
        event.stopPropagation();

        this.componentHelpers.startApiAction(
            () => {
                return this.userDataService.resendActivationEmail(
                    userData.userID
                );
            },
            {
                successMessageKey: "user.create.success.activateEmailSend",
                failedMessageKey: "user.create.error.activateEmailSend",
            }
        );
    }

    /**
     * Set user status
     * @param event
     * @param userID
     */
    setUserStatus(event: ActivationButtonClick, userID: number) {
        const status = !!event.isActivation ? "active" : "inactive";

        this.componentHelpers.startApiAction(
            () => {
                return this.userDataService.setStatus(userID, status);
            },
            {
                successMessageKey: "user.statusChange.success",
                failedMessageKey: "user.statusChange.error",
                afterSuccessAction: () => {
                    this.getUsers();
                },
            }
        );
    }

    public showModifyUserModal(userData) {
        const config = {
            data: {
                user: userData || {},
                edit: false,
                allResources: this.resources,
            },
            disableClose: true,
            width: "630px",
        };

        if (userData) {
            config["data"].edit = true;
        }

        this.dialogRef = this.dialog.open(ModifyUserDialogComponent, config);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getUsers();
            }
        });
    }

    /**
     * @description create, modify, modify bulk modal form
     * @param roleData
     */
    public showModifyRoleModal(type, roleData) {
        const resources = this.resources;
        const config = {
            data: {
                role: roleData || {},
                edit: false,
                type,
                allResources: resources,
                reloadGrid: () => {
                    this.changeRolesDataSource();
                },
            },
        };

        if (roleData) {
            config["data"].edit = true;
        }

        this.dialogRef = this.dialog.open(ModifyRoleDialogComponent, config);
    }

    public showCloneRoleModal(type, roleData) {
        roleData = Utils.lodash.cloneDeep(roleData);

        const resources = this.resources;
        const config = {
            data: {
                role: roleData || {},
                edit: false,
                clone: true,
                allResources: resources,
                type,
                reloadGrid: () => {
                    this.changeRolesDataSource();
                },
            },
        };

        this.dialogRef = this.dialog.open(ModifyRoleDialogComponent, config);
    }

    public deleteUserAction(userData) {
        this.dialog
            .open(DialogConfirmComponent, {
                data: {
                    message: this.languageService.getLine(
                        "access.management.user.delete.allow"
                    ),
                    yesButtonText: this.languageService.getLine(
                        "access.management.user.delete.confirm"
                    ),
                    user: true,
                },
            })
            .afterClosed()
            .subscribe((doIt) => {
                if (doIt) {
                    this.deleteUser(userData);
                }
            });
    }

    private deleteUser(userData) {
        const loader = this.dialog.open(DialogLoaderComponent, {
            disableClose: true,
            panelClass: "dialog-loader-modal",
            minWidth: "200vw",
            minHeight: "200vh",
            hasBackdrop: false,
        });

        this.userDataService
            .deleteUser({
                userID: userData.userID,
            })
            .subscribe(
                (response) => {
                    loader.afterClosed().subscribe(() => {
                        this.dialog
                            .open(DialogSuccessComponent, {
                                data: {
                                    message: this.languageService.getLine(
                                        "access.management.user.delete.success"
                                    ),
                                },
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.getUsers();
                            });
                    });

                    loader.close();
                },
                (error) => {
                    loader.afterClosed().subscribe(() => {
                        this.dialog.open(DialogErrorComponent, {
                            data: {
                                message:
                                    this.languageService.getLine(
                                        "access.management.user.delete.error"
                                    ) + error,
                            },
                        });
                    });
                    loader.close();
                }
            );
    }

    public deleteRole(type, dataRole) {
        this.dialog
            .open(DialogConfirmComponent, {
                data: {
                    message: this.languageService.getLine(
                        "access.management.role.delete.allow"
                    ),
                    yesButtonText: this.languageService.getLine(
                        "access.management.role.delete.confirm"
                    ),
                },
            })
            .afterClosed()
            .subscribe((doIt) => {
                if (doIt) {
                    const loader = this.dialog.open(DialogLoaderComponent, {
                        disableClose: true,
                        panelClass: "dialog-loader-modal",
                        minWidth: "200vw",
                        minHeight: "200vh",
                        hasBackdrop: false,
                    });

                    this.organizationRoleService
                        .deleteRoles({
                            roleID: [dataRole.roleID],
                            isPartnerRole: type === "partner",
                        })
                        .subscribe(
                            (response) => {
                                loader.afterClosed().subscribe(() => {
                                    this.dialog
                                        .open(DialogSuccessComponent, {
                                            data: {
                                                message:
                                                    this.languageService.getLine(
                                                        "access.management.role.delete.success"
                                                    ),
                                            },
                                        })
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.changeRolesDataSource();
                                        });
                                });

                                loader.close();
                            },
                            (error) => {
                                loader.afterClosed().subscribe(() => {
                                    this.dialog.open(DialogErrorComponent, {
                                        data: {
                                            message:
                                                this.languageService.getLine(
                                                    "access.management.role.delete.error"
                                                ) +
                                                " " +
                                                error.error.error.message,
                                        },
                                    });
                                });
                                loader.close();
                            }
                        );
                }
            });
    }

    private showModifyRoleModalBulk(type, roleDatas) {
        const config = {
            data: {
                roles: roleDatas || [],
                edit: false,
                bulk: true,
                allResources: this.resources,
                type,
                reloadGrid: () => {
                    this.changeRolesDataSource();
                },
            },
        };

        this.dialogRef = this.dialog.open(ModifyRoleDialogComponent, config);
    }

    private destroyListeners() {
        this.subs.forEach((sub) => sub.unsubscribe());
    }

    private usersPaginatorInit() {
        this.userPaginatorController.setPaginator(this.paginatorUser);

        this.userPaginatorController.onSetPagination.subscribe(
            ({ offset, limit }) => {
                this.usersComponent.filters["offset"] = offset;
                this.usersComponent.filters["limit"] = limit;
                this.getUsers();
            }
        );
    }

    public mainRoleHas(resource: string) {
        const user = LoggedUser.getUser();
        const partnerMianOrg = user.organizations.find(
            (org) => org.partnerMain
        );
        if (
            partnerMianOrg &&
            user.resources[partnerMianOrg.organizationID].includes(resource)
        ) {
            return true;
        }
        return false;
    }

    public alignTabGroup() {
        this.roleTabGroup.realignInkBar();
    }

    urlListener() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const name = params.name || null;

            if (name) {
                this.userFiltersForm.get("name").setValue(name);
            }
        });
    }
}
