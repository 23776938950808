import {Injectable} from '@angular/core';
import {environment} from '~/src/environments/environment';
import {
    CountriesRes,
    Country,
    FieldsOfStudy,
    GetTargetingOptions,
    IndustriesRes,
    Industry,
    JobFunction,
    JobFunctionsRes,
    LinkedInTargetingTypes,
    RefreshTargetingsOptions,
    Region,
    RegionsRes,
    SenioritiesRes,
    Seniority
} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.interfaces';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import Utils from '~/src/app/core/utils';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {LanguageService} from '~/src/app/services/language.service';

@Injectable({
    providedIn: 'root'
})
export class NewsFeedTargetingLinkedinService {
    private apiUrl = environment.apiUrl + '/api/post/targeting';
    private seniorities: BehaviorSubject<Seniority[]> = new BehaviorSubject<Seniority[]>([]);
    private jobFunctions: BehaviorSubject<JobFunction[]> = new BehaviorSubject<JobFunction[]>([]);
    private industries: BehaviorSubject<Industry[]> = new BehaviorSubject<Industry[]>([]);
    private fieldsOfStudies: BehaviorSubject<FieldsOfStudy[]> = new BehaviorSubject<FieldsOfStudy[]>([]);
    private countries: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
    private regions: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>([]);

    constructor(
        private http: HttpClient,
        private openModal: OpenModalService
    ) {
    }

    initialize(socialSiteID: number) {
        const defaultOptions: GetTargetingOptions = {
            socialType: 'linkedIn'
        };

        try {
            //  Get industries
            this.refreshIndustries({
                ...defaultOptions,
                socialSiteID: socialSiteID
            } as RefreshTargetingsOptions);

            //  Get job functions
            this.refreshJobFunctions({
                ...defaultOptions,
                socialSiteID: socialSiteID
            } as RefreshTargetingsOptions);

            //  Get seniorities
            this.refreshSeniorities({
                ...defaultOptions,
                socialSiteID: socialSiteID
            } as RefreshTargetingsOptions);
        } catch (e) {
            this.showErrorModal(e.title, e.message);
        }
    }

    getCountries() {
        return this.countries.asObservable();
    }

    getRegions() {
        return this.regions.asObservable();
    }

    getFieldsOfStudies() {
        return this.fieldsOfStudies.asObservable();
    }

    getIndustries() {
        return this.industries.asObservable();
    }

    getSeniorities() {
        return this.seniorities.asObservable();
    }

    getJobFunctions() {
        return this.jobFunctions.asObservable();
    }

    refreshRegions(options: RefreshTargetingsOptions) {
        return this.getTargeting<RegionsRes>('regions', options).then(data => {
            const values = data.targeting.elements.map(element => {
                return {
                    id: element.$URN,
                    name: element.name.value
                };
            });

            this.setRegions(values);

            return values;
        }).catch(error => {
            throw {
                title: LanguageService.getLine('post.targeting.regions.getCollection.error.title'),
                message: FormValidationService.readError(error).message
            };
        });
    }

    refreshCountries(options: RefreshTargetingsOptions) {
        return this.getTargeting<CountriesRes>('countries', options).then(data => {
            const values = data.targeting.elements.map(element => {
                return {
                    id: element.$URN,
                    name: element.name.value
                };
            });

            this.setCountries(values);

            return values;
        }).catch(error => {
            throw {
                title: LanguageService.getLine('post.targeting.jobFunction.getCollection.error.title'),
                message: FormValidationService.readError(error).message
            };
        });
    }

    refreshIndustries(options: RefreshTargetingsOptions) {
        return this.getTargeting<IndustriesRes>('industries', options).then(data => {
            const values = data.targeting.elements.map(element => {
                const keys = Utils.lodash.keys(element.name.localized);
                return {
                    id: element.$URN,
                    name: element.name.localized[keys[0]]
                };
            });

            this.setIndustries(values);

            return values;
        }).catch(error => {
            throw {
                title: LanguageService.getLine('post.targeting.industries.getCollection.error.title'),
                message: FormValidationService.readError(error).message
            };
        });
    }

    refreshJobFunctions(options: RefreshTargetingsOptions): Promise<JobFunction[]> {
        return this.getTargeting<JobFunctionsRes>('functions', options).then(data => {
            const values = data.targeting.elements.map(element => {

                const keys = Utils.lodash.keys(element.name.localized);
                return {
                    id: element.$URN,
                    name: element.name.localized[keys[0]]
                };
            });

            this.setJobFunctions(values);

            return values;
        }).catch(error => {
            throw {
                title: LanguageService.getLine('post.targeting.jobFunction.getCollection.error.title'),
                message: FormValidationService.readError(error).message
            };
        });
    }

    refreshSeniorities(options: RefreshTargetingsOptions): Promise<Seniority[]> {
        return this.getTargeting<SenioritiesRes>('seniorities', options).then(data => {
            const values = data.targeting.elements.map(element => {
                const keys = Utils.lodash.keys(element.name.localized);
                return {
                    id: element.$URN,
                    name: element.name.localized[keys[0]]
                };
            });

            this.setSeniorities(values);

            return values;
        }).catch(error => {
            throw {
                title: LanguageService.getLine('post.targeting.seniorityLevel.getCollection.error.title'),
                message: FormValidationService.readError(error).message
            };
        });
    }

    getTargeting<T>(type: LinkedInTargetingTypes, options?: GetTargetingOptions): Promise<T> {
        let url = `${this.apiUrl}?type=${type}`;

        if (Utils.lodash.has(options, 'socialSiteID')) {
            url += `&socialSiteID=${options.socialSiteID}`;
        }

        if (Utils.lodash.has(options, 'socialType')) {
            url += `&socialType=${options.socialType}`;
        }

        return this.http.get<T>(
            url,
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    private setCountries(values: Country[]) {
        this.countries.next(Helpers.orderBy(values, 'name'));
    }

    private setRegions(values: Country[]) {
        this.regions.next(Helpers.orderBy(values, 'name'));
    }

    private setFieldsOfStudies(values: FieldsOfStudy[]) {
        this.fieldsOfStudies.next(Helpers.orderBy(values, 'name'));
    }

    private setIndustries(values: Industry[]) {
        this.industries.next(Helpers.orderBy(values, 'name'));
    }

    private setSeniorities(values: Seniority[]) {
        this.seniorities.next(Helpers.orderBy(values, 'name'));
    }

    private setJobFunctions(values: JobFunction[]) {
        this.jobFunctions.next(Helpers.orderBy(values, 'name'));
    }

    private showErrorModal(title: string, message: string) {
        this.openModal.errorModal(DialogErrorComponent, {
            title: title,
            message: message
        });
    }
}
