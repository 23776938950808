import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';

export interface ActivationButtonClick {
    isActivation: boolean;
}

@Component({
    selector: 'smd-activation-button',
    templateUrl: './activation-button.component.html',
    styleUrls: ['./activation-button.component.scss']
})
export class ActivationButtonComponent {
    @Input() status: string;
    @Output() buttonClick = new EventEmitter<ActivationButtonClick>();

    constructor(
        public language: LanguageService,
        public elementRef: ElementRef<ActivationButtonComponent>
    ) {}

    emitClick(event: MouseEvent, isActivation: boolean) {
        event.preventDefault();
        event.stopPropagation();

        this.buttonClick.emit({
            isActivation: isActivation
        });
    }

    get isActive() {
        return !!this.status && this.status === 'active';
    }

}
