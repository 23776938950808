import { HttpHeaders } from "@angular/common/http";
import { FilterOptions } from "./component.abstract";
import { SocialSiteInterface } from "../components/social-site-select/social-site-select.component";
import { ErrorStateMatcher } from "@angular/material/core";
import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { BASE_DATE_FORMAT } from "../configs/configs";
import * as moment from "moment";
import Utils from "~/src/app/core/utils";

export interface MimeTypeObject {
    type: string;
    extension: string;
}

export const FILE_SIZE_BYTES = "Bytes";
export const FILE_SIZE_KB = "KB";
export const FILE_SIZE_MB = "MB";
export const FILE_SIZE_GB = "GB";
export const FILE_SIZE_TB = "TB";
export const FILE_SIZE_PB = "PB";
export const FILE_SIZE_EB = "EB";
export const FILE_SIZE_ZB = "ZB";
export const FILE_SIZE_YB = "YB";

export const FILE_SIZE_UNITS: string[] = [
    FILE_SIZE_BYTES,
    FILE_SIZE_KB,
    FILE_SIZE_MB,
    FILE_SIZE_GB,
    FILE_SIZE_TB,
    FILE_SIZE_PB,
    FILE_SIZE_EB,
    FILE_SIZE_ZB,
    FILE_SIZE_YB,
];

export class Helpers {
    /**
     * @description select all value in mat selects
     */
    public static selectAll(select, dataArray): void {
        select.writeValue(dataArray);
    }

    /**
     * Convert lower case string to capitalize
     *
     * @param {string} text
     * @return {string}
     */
    public static toCapitalize(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    /**
     * Get html filtered object
     *
     * @param {object} object
     * @returns {object}
     */
    public static getHtmlFilteredObject(object: object): object {
        for (const key in object) {
            object[key] = Helpers.strip(object[key]);
        }

        return object;
    }

    /**
     * Convert object to form data RAW unfiltered
     *
     * @param {object | Array<object>} object
     * @returns {FormData}
     */
    public static objectToFormDataRaw(
        object: object | Array<object>
    ): FormData {
        const formData = new FormData();

        for (const i in object) {
            const value = object[i];
            formData.append(i, value);
        }

        return formData;
    }

    /**
     * Convert object to form data
     *
     * @param {object | Array<object>} object
     * @returns {FormData}
     */
    public static objectToFormData(
        object: object | Array<object> | FormData
    ): FormData {
        if (object.constructor.name === "FormData") {
            return object as FormData;
        }

        const formData = new FormData();

        for (const i in object) {
            const value = object[i];

            if (value && !!value) {
                if (typeof i === "string" && typeof value === "object") {
                    if (value.length) {
                        for (const item of value) {
                            // mi van ha a subelem null????
                            formData.append(i, item, item.name);
                        }
                    } else {
                        formData.append(i, value, value.name);
                    }
                } else {
                    formData.append(i, Helpers.strip(value));
                }
            }
        }

        return formData;
    }

    /**
     * Get base headers for token auth
     *
     * @param {string} token
     * @returns {{headers: HttpHeaders}}
     */
    public static getBaseHttpHeaders(token: string, headers = {}) {
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0",
                ...headers,
            }),
        };
    }

    /**
     * MimeType string to object
     *
     * @param {string} mimeType
     * @returns {MimeTypeObject}
     */
    public static mimeTypeToObject(mimeType: string): MimeTypeObject {
        const result = mimeType.split("/"),
            fileType = result[0],
            fileExt = result[1];

        return {
            type: fileType,
            extension: fileExt,
        };
    }

    /**
     * Remove html from string
     *
     * @param {string} html
     * @returns {string}
     */
    public static strip(html: string): string {
        const tmp = document.createElement("div");
        tmp.innerHTML = html;

        const result = tmp.textContent || tmp.innerText || "";

        tmp.remove();

        return result;
    }

    /**
     * Build options object to http queries
     *
     * @param args
     * @returns {object}
     */
    public static buildQueryOptions(...args): object {
        const SEPARATOR = "::";

        const paramLength = arguments.length,
            options: object = {};

        for (let index = 0; index < paramLength; index++) {
            const key = arguments[index].split(SEPARATOR)[0],
                value = arguments[index].split(SEPARATOR)[1];

            if (
                value !== null &&
                value !== undefined &&
                value.indexOf("undefined") === -1 &&
                value !== "" &&
                value.indexOf("none") === -1 &&
                value.indexOf("[]") === -1
            ) {
                options[key] = value;
            }
        }

        return options;
    }

    /**
     * Simple object convert to query options
     *
     * @param {object} options
     * @return {object}
     */
    public static objectToQueryOptions(
        options: object | FilterOptions
    ): object {
        const queryOptions: object = {};

        for (const key in options) {
            // ha null az érték akkor nincs hasOwnProperty még akkor sem ha a null object.
            // ha null vagy undefined vagy üres az érték akkor skippeljük mindenképp
            if (
                options[key] !== null &&
                options[key] !== undefined &&
                options[key] !== ""
            ) {
                const value = options[key].hasOwnProperty("toString")
                    ? options[key].toString()
                    : options[key];

                // ha az értéknek van indexOf tulajdonsága ami funkció, ami pl egy számnak nincs akkor megyünk tovább csak
                if (typeof value.indexOf === "function") {
                    if (
                        value.indexOf("undefined") === -1 &&
                        value.indexOf("none") === -1 &&
                        value.indexOf("[]") === -1
                    ) {
                        queryOptions[key] = value;
                    }
                } else {
                    queryOptions[key] = value;
                }
            }
        }

        return queryOptions;
    }

    /**
     * Get query string for http request
     *
     * @param {object} options
     * @returns {string}
     */
    public static getHttpQuery(options: object): string {
        let query = "?";

        for (const key in options) {
            const value = options[key];

            if (value instanceof Array) {
                for (const item of value) {
                    query += `${key}[]=${encodeURIComponent(item)}&`;
                }
            } else {
                query += `${key}=${encodeURIComponent(value)}&`;
            }
        }

        return query.slice(0, query.length - 1);
    }

    /**
     * Order array by key|value
     *
     * @param {any[]} array
     * @param {string | null} key
     * @param {"ASC" | "DESC"} dir
     * @return {any[]}
     */
    public static orderBy<T>(
        array: any[],
        key?: T | string,
        dir: string = "ASC"
    ): any[] {
        function ascOrder(a, b) {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }

            return 0;
        }

        function descOrder(a, b) {
            if (a > b) {
                return -1;
            }
            if (a < b) {
                return 1;
            }

            return 0;
        }

        function trim(value: string) {
            if (Utils.lodash.isString(value)) {
                return value.trim();
            }

            return value;
        }

        return array.sort((a, b) => {
            a = typeof a[key] !== "undefined" ? a[key] : a;
            b = typeof b[key] !== "undefined" ? b[key] : b;

            a = trim(a);
            b = trim(b);

            if (dir === "ASC") {
                return ascOrder(a, b);
            } else if (dir === "DESC") {
                return descOrder(a, b);
            }
        });
    }

    /**
     * Get random color
     *
     * @return {string}
     */
    public static getRandomColor() {
        const letters = "BCDEF".split("");
        let color = "#";

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }

        return color;
    }

    /**
     * Convert bytes to size
     *
     * @param bytes
     * @param decimals
     * @return {string}
     */
    public static formatBytes(bytes: number, decimals) {
        if (bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
            " " +
            FILE_SIZE_UNITS[i]
        );
    }

    public static getBase64FileSize(base64: string): number {
        return (
            base64.length * (3 / 4) - (base64.charAt(base64.length - 2) ? 2 : 1)
        );
    }

    public static convertStringFileSizeToBytes(fileSize: string) {
        const value = Number(fileSize.slice(0, -3).trim());
        const unit = fileSize.substr(-3).trim().toUpperCase();
        const bytesIndex = FILE_SIZE_UNITS.indexOf(FILE_SIZE_BYTES);
        const unitIndex = FILE_SIZE_UNITS.indexOf(unit);
        let result = value;

        if (unitIndex === -1) {
            return null;
        }

        for (let i = 0; i < unitIndex - bytesIndex; i++) {
            result *= 1024;
        }

        return result;
    }

    /**
     * Convert base64 to File object
     *
     * @param dataurl
     * @param filename
     * @return {File}
     */
    public static dataURLtoFile(dataurl, filename): File {
        const arr = dataurl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    /**
     * Remove multiple values from array
     *
     * @param {any[]} array
     * @return {any[]}
     */
    public static arrayUnique(array: any[], key?: string) {
        const result = [];

        if (key) {
            array.forEach((item) => {
                if (result.map((res) => res[key]).indexOf(item[key]) === -1) {
                    result.push(item);
                }
            });
        } else {
            for (const value of array) {
                if (result.indexOf(value) === -1) {
                    result.push(value);
                }
            }
        }

        return result;
    }

    public static imageTester(
        src: string,
        success: () => void = () => {},
        error: () => void = () => {}
    ) {
        const image = new Image();

        image.onerror = () => {
            error();
        };

        image.onabort = () => {
            error();
        };

        image.onload = () => {
            success();
        };

        image.src = src;
    }

    public static getOrderFormat(value: string): string {
        value = value || "none-none";

        const order = value.split("-");

        return `{"${order[0]}":"${order[1]}"}`;
    }

    public static stringToBoolean(string: string | null): boolean {
        if (typeof string === "boolean") {
            return string;
        }

        if (["true", "yes"].indexOf(string) > -1) {
            return true;
        }

        if (["false", "no"].indexOf(string) > -1) {
            return false;
        }

        return false;
    }

    public static extractHostname(url) {
        let hostname;

        url = url || "";

        if (url.indexOf("//") > -1) {
            hostname = url.split("/")[2];
        } else {
            hostname = url.split("/")[0];
        }

        hostname = hostname.split(":")[0];
        hostname = hostname.split("?")[0];

        return hostname;
    }

    public static extractRootDomain(url) {
        let domain = this.extractHostname(url);
        const splitArr = domain.split("."),
            arrLen = splitArr.length;

        if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];

            if (
                splitArr[arrLen - 2].length === 2 &&
                splitArr[arrLen - 1].length === 2
            ) {
                domain = splitArr[arrLen - 3] + "." + domain;
            }
        }
        return domain;
    }

    public static getPostEditable(post) {
        return post["isEditable"]
            ? moment(post.activeFrom).isAfter(moment().format())
            : post["isEditable"];
    }

    public static getPostRepeatEditable(post) {
        return moment(post.activeFrom).isAfter(moment().format());
    }

    public static toggleEnabledStates(string: string) {
        return { yes: "no", no: "yes" }[string];
    }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

export class EditOrgErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched)
        );
    }
}

export class CustomEvents {
    /**
     * Logout event name
     *
     * @type {string}
     */
    public static LOGOUT = "smd.logout";

    public static USER_UPDATE = "smd.userupdate";

    public static UPDATE_TOKEN = "smd.updateToken";

    public static SUCCESS_UPDATE_TOKEN = "smd.successUpdateToken";

    public static FAILED_UPDATE_TOKEN = "smd.failedUpdateToken";

    public static UPDATE_FILE_BROWSER = "smd.fileBrowserUpdate";

    public static POST_OR_TEMPLATE_EDIT = "smd.postOrTemplateEdit";

    /**
     * Trigger logout event in [doLogout] directive
     */
    public static logout(): void {
        document.dispatchEvent(new Event(CustomEvents.LOGOUT));
    }

    public static userupdate(): void {
        document.dispatchEvent(new Event(CustomEvents.USER_UPDATE));
    }

    public static updateToken() {
        document.dispatchEvent(new Event(CustomEvents.UPDATE_TOKEN));
    }

    public static successUpdateToken(data: object = {}) {
        document.dispatchEvent(
            new CustomEvent(this.SUCCESS_UPDATE_TOKEN, data)
        );
    }

    public static failedUpdateToken(data: object = {}) {
        document.dispatchEvent(new CustomEvent(this.FAILED_UPDATE_TOKEN, data));
    }

    public static fileBrowserUpdate(data: object = {}) {
        document.dispatchEvent(new CustomEvent(this.UPDATE_FILE_BROWSER, data));
    }

    public static postOrTemplateEdit(data: object = {}) {
        document.dispatchEvent(new CustomEvent(this.POST_OR_TEMPLATE_EDIT, data));
    }
}

/**
 * Angular debounce as decorator
 *
 * @param {number} delay
 * @return {MethodDecorator}
 * @constructor
 */
export function Debounce(delay: number = 300): MethodDecorator {
    return function (
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        let timeout = null;

        const original = descriptor.value;

        descriptor.value = function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => original.apply(this, args), delay);
        };

        return descriptor;
    };
}

/**
 * Current social site manager
 *
 * @example {
 *      facebook: {
 *          id: number,
 *          name: string
 *      },
 *      twitter: {
 *          id: number,
 *          name: string
 *      }
 * }
 */
export class CurrentSocialSite {
    private static readonly storageKey: string = "selectedSocialSites";

    static getSocialSites(): SocialSiteInterface | null {
        const raw = localStorage.getItem(this.storageKey);

        if (!raw && raw !== "undefined") {
            return null;
        }

        const socialSite = JSON.parse(raw);

        if (!!socialSite) {
            return socialSite;
        } else {
            return {
                siteID: null,
                name: "",
                timezone: "",
                pageType: "",
            };
        }
    }

    static setSocialSite(object: object): void {
        localStorage.setItem(this.storageKey, JSON.stringify(object));
    }
}

export interface UserOrganization {
    admin: "no" | "yes";
    organizationID: number;
    name: string;
    partner: string;
    role: string;
    roleName: string;
    main: boolean;
    partnerMain?: boolean;
}

export interface LoggedUserInterface {
    activateHash: string | null;
    bitlyToken?: string;
    createDate: string;
    email: string;
    firstName: string;
    fullName: string;
    groupID: number;
    imageFileID: number;
    lastLogin: string;
    lastName: string;
    modifyDate: string;
    organizations: UserOrganization[];
    partnerID: number;
    partnerLimitUsage: any;
    partnerDeleteRequested: boolean;
    partnerName: string;
    profileImageUrl: string;
    subscriptionName: string;
    resetHash: string;
    secret: string;
    resources: { [key: number]: string[] };
    settings: {
        twoFactorAuthentication: boolean;
        emailNotification: any;
        defaultTimeZone: string;
    };
    status: string;
    tempPassword: string;
    userID: number;
    username: string;
    partnerConfig?: any;
}

export interface FacebookUserInterface {
    access_token: string;
    userID: string;
    domainsData: any[];
}

export class FacebookUser {
    private static readonly _storageKey: string = "facebookUser";

    public static saveData(data: FacebookUserInterface) {
        localStorage.setItem(this._storageKey, JSON.stringify(data));
    }

    public static getData(): FacebookUserInterface {
        return JSON.parse(localStorage.getItem(this._storageKey));
    }
}

export interface DashboardCardWidthInterface {
    pageSummary: string;
    datePresets: string;
    likeGrowth: string;
    pageViews: string;
    videoViews: string;
    postEngagement: string;
    postReach: string;
    organFirst: string;
    organSecond: string;
    topEngagement: string;
    topReach: string;
    recentPosts: string;
    recentTemplate: string;
    recentMedia: string;
    activeMember: string;
    onlineMember: string;
    recentComments: string;
}

export class DashboardCardWidth {
    private static readonly _storageKey: string = "dashboardCardWidth";

    public static saveData(data: DashboardCardWidthInterface) {
        localStorage.setItem(this._storageKey, JSON.stringify(data));
    }

    public static getData(): DashboardCardWidthInterface {
        return JSON.parse(localStorage.getItem(this._storageKey));
    }
}

export class DateHelper {
    public static convertSingleDateToStartEndDate(
        date: string,
        isEnd: boolean
    ) {
        if (date !== null) {
            date = moment(date).format(BASE_DATE_FORMAT);
            return `${date} ${isEnd ? "23:59:59" : "00:00:00"}`;
        }
        return null;
    }
}

export class RememberMe {
    static readonly storageKey: string = "rememberMe";

    static save() {
        localStorage.setItem(this.storageKey, "yes");
    }

    static clear() {
        if (localStorage.getItem(this.storageKey)) {
            localStorage.removeItem(this.storageKey);
        }
    }

    static has(): boolean {
        const value = localStorage.getItem(this.storageKey);

        return !!value;
    }
}

export class TwoFactorLogin {
    private static readonly storageKey: string = "twoFactorLogin";

    static save() {
        localStorage.setItem(this.storageKey, "yes");
    }

    static clear() {
        if (this.has()) {
            localStorage.removeItem(this.storageKey);
        }
    }

    static has() {
        const value = localStorage.getItem(this.storageKey);

        return !!value;
    }
}
