import Utils from '~/src/app/core/utils';

export class LocalStorage {

    /**
     * Get item from local storage
     * @param key
     */
    public static get(key: string) {
        let value = null;

        if (this.has(key)) {
            value = localStorage.getItem(key);
        }

        try {
            value = JSON.parse(value);
        } catch (e) {}

        return value;
    }

    /**
     * Set item in local storage
     * @param key
     * @param value
     */
    public static set(key: string, value: any) {
        // available value type
        const availableTypes = ['string', 'number'];

        // value type in unavailable
        if (!Utils.lodash.includes(availableTypes, typeof value)) {
            value = JSON.stringify(
                Utils.lodash.cloneDeep(value)
            );
        }

        // store value in local storage
        localStorage.setItem(key, value);
    }

    /**
     * Check has item in local storage
     * @param key
     */
    public static has(key: string): boolean {
        return !!(localStorage.getItem(key));
    }

    /**
     * Remove item from local storage
     * @param key
     */
    public static remove(key: string) {
        if (this.has(key)) {
            localStorage.removeItem(key);
        }
    }
}
