import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModelAbstract } from '../../services/model.abstract';
import { Helpers } from '../../services/helpers';
import { Token } from '../../services/token';
import Utils from '../../core/utils';

@Injectable({providedIn: 'root'})
export class TemplateFolderActionsService extends ModelAbstract
{
  constructor(
    public http: HttpClient,
    public dialog: MatDialog
  ) {
      super(http, dialog);

      this.apiLink = '/api/template-folder';
  }

  /**
   * Delete folders by IDs
   * 
   * @param {Array<number>} folderIDs
   * @returns {Promise<any>}
   */
  public deleteFolders(folderIDs: Array<number>, deleteTemplates: boolean = false) {
    const data = {
      folderIDs: JSON.stringify(folderIDs),
      deleteTemplates: deleteTemplates,
    };

    return this.http.post(
      this.apiLink + '/delete', 
      Utils.obj2fd(data),
      Helpers.getBaseHttpHeaders(Token.getToken())
    ).toPromise();
  }

  /**
   * Edit folder
   * 
   * @param {number} folderID 
   * @param {any} data 
   * @returns {Promise<any>}
   */
  public editFolder(folderID: number, data: any) {
    return this.http.post(
      this.apiLink + '/' + folderID, 
      Utils.obj2fd(data),
      Helpers.getBaseHttpHeaders(Token.getToken())
    ).toPromise();
  }

  /**
   * Share folder with organizations
   * 
   * @param {number} folderID
   * @param {object} data 
   * @returns {Promise<any>}
   */
  public shareFolder(folderID: number, data: { organizationsToAdd: Array<number>, organizationsToRemove: Array<number> }) {
    return this.http.post(
      this.apiLink + '/share/' + folderID,
      Utils.obj2fd(data),
      Helpers.getBaseHttpHeaders(Token.getToken())
    ).toPromise();
  }

  /**
   * Get all folders for select
   * 
   * @param {object | string} filters
   * @param {boolean} queryConvert
   * @returns {Promise<any>}
   */
  public getAllForSelect(filters?: object | string, queryConvert = true) {
    const query = queryConvert ? (filters) ? Helpers.getHttpQuery(filters as object) : '' : '?' + filters;

    return this.http.get(
      this.apiLink + '/select' + query,
      Helpers.getBaseHttpHeaders(Token.getToken())
    ).toPromise();
  }
}