import {Component, Input, OnInit, ViewChild, HostBinding} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {SmdFile, SmdFileInterface} from '~/src/app/services/file.class';
import {LanguageService} from '~/src/app/services/language.service';
import {FALLBACK_IMAGE} from '~/src/app/configs/configs';
import Utils from '~/src/app/core/utils';

export interface CarouselItem {
    carouselName: string;
    imageFileID: number;
    imageUrl: string;
    itemID: number;
    position: number;
    status: 'active' | string;
    description?: string;
    name: string;
    url: string;
}

@Component({
    selector: 'smd-smd-gallery',
    templateUrl: './smd-gallery.component.html',
    styleUrls: ['./smd-gallery.component.scss'],
})
export class SmdGalleryComponent implements OnInit {
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    isOpened = false;
    subs = [];

    @ViewChild('gallery') gallery: any;
    @Input() images: SmdFile[] | any = [];
    @Input() type: 'button' | 'gallery' | 'img' | 'hidden' = 'gallery';
    @Input() index?: number;
    @Input() debug?: boolean;
    @Input() imageClasses?: string;
    @Input() isImageOpenable = true;
    @Input() enablePreview = true;

    @HostBinding('class.not-button') get notButton() {
        return this.type !== 'button';
    }

    constructor(
        public languageService: LanguageService
    ) {
        this.isOpened = this.type === 'gallery';
        this.imageClasses = this.imageClasses || 'default-image';
    }

    ngOnInit(): void {
        this.initGallery();
    }

    public openGallery() {
        const imageIndex = this.index || 0;

        if (!this.isImageOpenable || !this.enablePreview) {
            return;
        }

        this.isOpened = true;
        setTimeout(() => {
            this.gallery.openPreview(imageIndex);
            this.handleArrowsHide();

            const sub1 = this.gallery.previewClose.subscribe(() => {
                this.isOpened = false;
                sub1.unsubscribe();
            });
        });
    }

    public openGalleryWithImage(image: SmdFileInterface) {
        this.images = [image];
        this.refreshImages();
        this.openGallery();
    }

    private handleArrowsHide() {
        if (this.images.length < 2) {
            setTimeout(() => {
                const arrows = Array.from(document.querySelectorAll('.ngx-gallery-arrow-wrapper')) as HTMLElement[];

                for (const arrow of arrows) {
                    arrow.style.visibility = 'hidden';
                }
            });
        }
    }

    private refreshImages() {
        if (this.images.length && Utils.lodash.get(this.images, '[0].carouselName', false)) {
            this.galleryImages = this.images.map((image: any) => {
                return {
                    small: image.imageUrl,
                    medium: image.imageUrl,
                    big: image.imageUrl
                };
            });
        } else {
            this.galleryImages = this.images.map((image: SmdFile) => {
                return {
                    small: Utils.lodash.get(image, 'galleryThumb', ''),
                    medium: Utils.lodash.get(image, 'thumb', ''),
                    big: Utils.lodash.get(image, 'url', '')
                };
            });
        }
    }

    private initGallery() {
        const defaultGalleryOptions = [
            {
                width: '600px',
                height: '400px',
                thumbnailsColumns: 4,
                imageAnimation: NgxGalleryAnimation.Slide,
                previewCloseOnEsc: true
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                previewCloseOnEsc: true
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];

        switch (this.type) {
            case 'button':
                this.galleryOptions = [
                    {
                        'image': false,
                        'thumbnails': false,
                        'width': '0px',
                        'height': '0px',
                        'previewCloseOnEsc': true,
                        'previewCloseOnClick': true
                    },
                    {
                        'breakpoint': 500,
                        'width': '300px',
                        'height': '300px',
                        'thumbnailsColumns': 3,
                        'previewCloseOnEsc': true,
                        'previewCloseOnClick': true
                    },
                    {
                        'breakpoint': 300,
                        'width': '100%',
                        'height': '200px',
                        'thumbnailsColumns': 2,
                        'previewCloseOnEsc': true,
                        'previewCloseOnClick': true
                    }
                ];
                break;
            case 'hidden':
            case 'img':
                this.galleryOptions = [
                    {
                        'image': false,
                        'thumbnails': false,
                        'width': '0px',
                        'height': '0px',
                        'previewCloseOnEsc': true,
                        'previewCloseOnClick': true
                    },
                    {
                        'breakpoint': 500,
                        'width': '300px',
                        'height': '300px',
                        'thumbnailsColumns': 3,
                        'previewCloseOnEsc': true,
                        'previewCloseOnClick': true
                    },
                    {
                        'breakpoint': 300,
                        'width': '100%',
                        'height': '200px',
                        'thumbnailsColumns': 2,
                        'previewCloseOnEsc': true,
                        'previewCloseOnClick': true
                    }
                ];
                break;
            case 'gallery':
                this.galleryOptions = defaultGalleryOptions;
                break;
            default:
                this.galleryOptions = defaultGalleryOptions;
                break;
        }

        this.refreshImages();
        // show the gallery defaultly when type is set to gallery
        this.isOpened = this.type === 'gallery';
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }
}
