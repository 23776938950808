import {
    Component,
    Input,
    AfterViewInit,
    ViewChild,
    ElementRef,
    ViewChildren,
    QueryList,
} from "@angular/core";
import { FALLBACK_IMAGE } from "~/src/app/configs/configs";
import { each } from "lodash";
import { SmdFileInterface } from "~/src/app/services/file.class";

@Component({
    selector: "smd-gmb-media",
    templateUrl: "./gmb-media.component.html",
    styleUrls: ["./gmb-media.component.scss"],
})
export class GmbMediaComponent implements AfterViewInit {
    @Input() collection: SmdFileInterface[];
    @Input() meta: string;

    @ViewChild("list", { static: false }) holder: ElementRef;
    @ViewChildren("item") items: QueryList<ElementRef>;

    public galleryIndex: number = 0;
    private scrollContainer: HTMLElement = null;
    private scrollItems: HTMLElement[] = [];

    ngAfterViewInit() {
        this.scrollContainer = this.holder?.nativeElement;
        each(this.items.toArray(), (item) =>
            this.scrollItems.push(item.nativeElement)
        );
    }

    paging(change) {
        this.galleryIndex = this.galleryIndex + change;

        this.scrollContainer.scroll({
            top: 0,
            left: this.scrollItems[this.galleryIndex].offsetLeft,
            behavior: "smooth",
        });
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }
}
