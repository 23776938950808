import {Injectable} from '@angular/core';
import {ModelAbstract} from '../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Token} from '../../services/token';
import {Helpers} from '../../services/helpers';
import {take} from 'rxjs/internal/operators';
import {MatDialog} from '@angular/material/dialog';
import {Role} from '~/src/app/modules/users/access-management/access-management.component';
import {environment} from '~/src/environments/environment';
import Utils from '~/src/app/core/utils';

export const ROLE_API_URLS = {
    'general': environment.apiUrl + '/api/roles/edit',
    'bulkEdit': environment.apiUrl + '/api/roles/modify',
    'create': environment.apiUrl + '/api/roles/',
    'deleteRole': environment.apiUrl + '/api/roles/delete',
    'bulkDelete': environment.apiUrl + '/api/roles/delete',
    'getRoles': environment.apiUrl + '/api/roles'
};

@Injectable({providedIn: 'root'})
export class RoleService extends ModelAbstract {

    constructor(
        public http: HttpClient,
        public dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/roles';
    }

    /**
     * Get users by IDs
     *
     * @param {number[]} userIDs
     * @return {Promise<any>}
     */
    getRoleByIDs(organizationIDs: any[]): Promise<any> {
        return this.http.get(ROLE_API_URLS['getRoles'] + this.arrayToQuery('organizationID', organizationIDs), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    createRole(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(ROLE_API_URLS['create'], Helpers.objectToFormData(data), httpOptions).pipe(take(1));
    }

    setRoleData(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(ROLE_API_URLS['general'], Utils.obj2fd(data), httpOptions).pipe(take(1));
    }

    /**
     * @description it is for bulk edit mode
     */
    setRolesData(roles: Array<Role>) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(ROLE_API_URLS['bulkEdit'],  Utils.obj2fd(roles), httpOptions).pipe(take(1));
    }

    deleteRole(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        let formData = {};

        formData['roleID[]'] = data.roleID;
        formData =  Helpers.objectToFormData(formData);

        return this.http.post(ROLE_API_URLS['deleteRole'], formData, httpOptions).pipe(take(1));
    }

    deleteRoles(roles) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(ROLE_API_URLS['bulkDelete'],  Utils.obj2fd(roles), httpOptions).pipe(take(1));
    }
}
