import {Component, Inject, OnInit} from '@angular/core';
import {ClickDecorator} from '~/src/app/core/decorators';
import {AutofeedService} from '~/src/app/modules/autofeed/autofeed.service';
import {ScheduleRulePresetModel} from '~/src/app/modules/autofeed/models/schedule-rule-preset.model';
import {LanguageService} from '~/src/app/services/language.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IScheduleRulePresetChooserDialogData} from '~/src/app/modules/autofeed/interfaces/schedule-rule.interface';
import {AUTOFEED_PRESET_FILTER_CONTROL_NAMES} from '~/src/app/modules/autofeed/autofeed.constants';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'smd-schedule-rule-preset-chooser-modal',
    templateUrl: './schedule-rule-preset-chooser-modal.component.html',
    styleUrls: ['./schedule-rule-preset-chooser-modal.component.scss']
})
export class ScheduleRulePresetChooserModalComponent implements OnInit {
    multiple = this.dialogData?.multiple || false;
    bindLabel: keyof ScheduleRulePresetModel = this.dialogData?.bindLabel || 'name';
    bindValue: keyof ScheduleRulePresetModel = this.dialogData?.bindValue || 'presetID';
    rulePresets: ScheduleRulePresetModel[] = [];
    presetIsLoading = false;
    selectedPreset: ScheduleRulePresetModel | ScheduleRulePresetModel[];
    filterControlNames = AUTOFEED_PRESET_FILTER_CONTROL_NAMES;
    filterFormGroup = new FormGroup({
        [this.filterControlNames.Category]: new FormControl(null),
        [this.filterControlNames.Period]: new FormControl(null)
    });

    constructor(
        public language: LanguageService,
        @Inject(MAT_DIALOG_DATA) public dialogData: IScheduleRulePresetChooserDialogData,
        public autofeedService: AutofeedService,
        private dialogRef: MatDialogRef<ScheduleRulePresetChooserModalComponent>
    ) {
        this.filterFormGroup
            .valueChanges
            .pipe(
                debounceTime(350)
            )
            .subscribe(() => this.getPresets());

        this.getPresets();
    }

    ngOnInit(): void {}

    @ClickDecorator()
    choosePreset(event: MouseEvent): void {
        this.dialogRef.afterClosed().subscribe(() => this.dialogData?.afterChoose(this.selectedPreset));
        this.dialogRef.close();
    }

    selectionChange(event: any) {
        this.selectedPreset = event;
    }

    getPresets(): void {
        this.presetIsLoading = true;
        this.autofeedService.getPresets(this.filterFormGroup.value)
            .then(response => this.rulePresets = response.presets)
            .finally(() => this.presetIsLoading = false);
    }
}
