import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PixieDomService {
    private static pixieEditorWrapper: HTMLElement;
    private static pixieEditor: HTMLElement;

    public static create(): void {
        PixieDomService.pixieEditorWrapper = document.createElement('div');
        PixieDomService.pixieEditorWrapper.setAttribute('id', 'pixie-editor-wrapper');
        PixieDomService.pixieEditorWrapper.style.setProperty('display', 'none');

        PixieDomService.pixieEditor = document.createElement('pixie-editor');
        PixieDomService.pixieEditorWrapper.appendChild(PixieDomService.pixieEditor);

        document.body.appendChild(PixieDomService.pixieEditorWrapper);
    }

    public static show(element: HTMLElement): void {
        element.replaceWith(PixieDomService.pixieEditor);
    }

    public static hide(): void {
        PixieDomService.pixieEditorWrapper.appendChild(PixieDomService.pixieEditor);
    }
}
