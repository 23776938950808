import {
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {DataTableHelpersService} from '~/src/app/core/services/data-table-helpers.service';
import {LanguageService} from '~/src/app/services/language.service';
import Utils from '~/src/app/core/utils';
import {
    CollectionListConfig,
    CollectionListSelectionChange,
    SingleActionClick,
    SingleActionsConfig,
    TableBodyConfig,
    TableHeadConfig
} from '~/src/app/core/components/collection-list/collection-list.interfaces';
import {ButtonGroupDirective} from '~/src/app/core/components/collection-list/button-group.directive';
import {CustomViewDirective} from '~/src/app/core/components/collection-list/custom-view.directive';
import {groupBy} from 'lodash';
import { Icons } from '~/src/app/helpers/icons';

@Component({
    selector: 'smd-collection-list',
    templateUrl: './collection-list.component.html',
    styleUrls: ['./collection-list.component.scss'],
    providers: [
        DataTableHelpersService
    ]
})
export class CollectionListComponent implements OnInit, OnChanges {
    @Input() itemCollection: any[] = [];
    @Input() set config(value: CollectionListConfig) {
        this._config = Utils.lodash.merge(this.defaultConfig, value || {});

        if (typeof this.config.allowSelection === 'boolean') {
            this.allowMainSelection = this.config.allowSelection;
        }
    }
    @Input() enableSingleAction = true;
    @Input() enableGroupActions = true;
    @Input() enablePagination = true;
    @Input() tableHeadConfig: TableHeadConfig[] = [];
    @Input() tableBodyConfig: TableBodyConfig[] = [];
    @Input() singleActionsConfig: SingleActionsConfig[] = [];
    @Input() selectionBindKey: string;
    @Input() groupByField = null;
    @Input() commentable = undefined;
    @Input() previewable = false;
    @Input() customPreview = false;
    @Output() selectionChange = new EventEmitter<CollectionListSelectionChange>();
    @Output() singleActionClick = new EventEmitter<SingleActionClick>();
    @ViewChildren('mobileInfo') mobileInfo: QueryList<ElementRef<HTMLElement>>;
    @ContentChild(ButtonGroupDirective, {read: TemplateRef}) singleActionButtons: TemplateRef<any>;
    @ContentChildren(CustomViewDirective) customViews: QueryList<CustomViewDirective>;
    collapseID = Utils.uuid.UUID();
    groupedCollection: {[key: string]: any[]} = {};
    allowMainSelection = true;
    get config(): CollectionListConfig {
        return this._config;
    }
    readonly defaultConfig: CollectionListConfig = {
        allowSelection: true
    };
    _config: CollectionListConfig = this.defaultConfig;

    constructor(
        public dataTableHelpers: DataTableHelpersService,
        public languageService: LanguageService,
        public viewContainerRef: ViewContainerRef
    ) {
    }

    ngOnInit() {
        this.groupItems();
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.groupItems();
    }

    /**
     * Select all item
     */
    selectAllItem(event: MouseEvent) {
        if (this.selectedAll()) {
            this.dataTableHelpers.clearSelection();
        } else {
            this.dataTableHelpers.selectAllItem(this.getSelectableItems());
        }

        this.emitSelectionChange();
    }

    /**
     * Get selectable collection items
     * @return {any[]}
     */
    getSelectableItems() {
        return this.itemCollection.filter(item => this.checkAllowSelection(item));
    }

    /**
     * Select item
     * @param item
     */
    selectItem(item) {
        this.dataTableHelpers.selectItem(item, this.selectionBindKey);
        this.emitSelectionChange();
    }

    /**
     * Clear selection
     */
    clearSelection() {
        this.dataTableHelpers.clearSelection();
        this.emitSelectionChange();
    }

    /**
     * Is selected item
     * @param item
     * @return {boolean}
     */
    isSelected(item): boolean {
        return this.dataTableHelpers.isSelected(item, this.selectionBindKey);
    }

    /**
     * Collapse button added
     * @return {boolean}
     */
    collapseButtonAdded(): boolean {
        return this.tableBodyConfig.findIndex(item => item.staticView === 'showMobileData') > -1;
    }

    /**
     * Single action button added
     * @return {boolean}
     */
    singleActionButtonsAdded(): boolean {
        return this.tableBodyConfig.findIndex(item => item.staticView === 'itemActions') > -1;
    }

    /**
     * Single action click
     * @param {MouseEvent} event
     * @param {string} actionName
     * @param item
     */
    singleAction(event: MouseEvent, actionName: string, item: any) {
        event.preventDefault();
        event.stopPropagation();

        this.singleActionClick.emit({
            type: actionName,
            item: item
        });
    }

    /**
     * Return the collection grouped by key
     *
     * @return {any}
     * */
    groupItems() {
        if (this.groupByField) {
            this.groupedCollection = groupBy(this.itemCollection, this.groupByField);
        }
    }

    /**
     * Sort collection by descending order
     * @description keyvalue pipe fix https://github.com/angular/angular/issues/31420
     * */
    descOrder(a, b) {
        if (a.key < b.key) {
            return b.key;
        }
    }

    /**
     * Bind value
     * @param item
     * @param {string} bindValue
     * @return {any}
     */
    bindValue(item: any, bindValue: string) {
        return Utils.lodash.get(item, bindValue, '');
    }

    /**
     * Check the value is boolean and true
     * @param value
     * @return {boolean}
     */
    isTrueBoolean(value: any) {
        return (typeof value === 'boolean' && value);
    }

    /**
     * Check the value is boolean and false
     * @param value
     * @return {boolean}
     */
    isFalseBoolean(value: any) {
        return (typeof value === 'boolean' && !value);
    }

    /**
     * Check selection allowed
     * @param item
     * @return {boolean}
     */
    checkAllowSelection(item: any): boolean {
        return this.config.allowSelection instanceof Function ? this.config.allowSelection(item) : this.config.allowSelection;
    }

    /**
     * Check selected all selectable item
     * @return {boolean}
     */
    selectedAll(): boolean {
        const selectedItemsCount = this.dataTableHelpers.selectedItems.length;

        if (selectedItemsCount === 0) {
            return false;
        }

        return this.getSelectableItems().length === selectedItemsCount;
    }

    /**
     * Emit selection change event
     */
    private emitSelectionChange() {
        this.selectionChange.emit({
            selectedItems: Utils.lodash.cloneDeep(this.dataTableHelpers.selectedItems),
            itemCollection: Utils.lodash.cloneDeep(this.itemCollection)
        });
    }

    get icons() {
        return Icons;
    }
}
