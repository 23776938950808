export class AuthWindow {
    // TODO: make it generic
    private intervalId: any;
    private windowHandle: Window;
    private loopCount = 600;
    private intervalLength = 100;

    protected createOauthWindow(
        url: string,
        name = 'Authorization',
        width = 500,
        height = 600,
        left = 0,
        top = 0) {
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        return window.open(url, name, options);
    }

    public watch(authorizationUrl: string) {
        return new Promise((resolve, reject) => {
            let loopCount = this.loopCount;

            this.windowHandle = this.createOauthWindow(authorizationUrl, 'Google Authorization');

            this.intervalId = window.setInterval(() => {

                if (loopCount-- < 0) {
                    window.clearInterval(this.intervalId);
                    this.windowHandle.close();
                    this.loopCount = 600;
                    return reject('Timeout');
                }

                let urlParams: URLSearchParams;

                try {
                    urlParams = new URLSearchParams(this.windowHandle.location.search);
                } catch (e) {
                    /* CORS: console.log('Error:', e);*/
                }

                if (urlParams != null && urlParams.get('code')) {
                    window.clearInterval(this.intervalId);
                    this.windowHandle.close();

                    resolve({code: urlParams.get('code')});
                }

            }, this.intervalLength);
        });
    }

}
