import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Helpers} from './helpers';
import {Token} from './token';
import {environment} from '~/src/environments/environment';

export const OPEN_GRAPH_TYPE_OG = 'og';
export const OPEN_GRAPH_TYPE_TWITTER = 'twitter';

export type OPEN_GRAPH_TYPES = 'og' | 'twitter';

@Injectable({providedIn: 'root'})
export class UrlOpenGraphService {

    constructor(
        private http: HttpClient
    ) {}

    /**
     * Get Open Graph data from URL
     *
     * @param {string} url
     * @param {OPEN_GRAPH_TYPES} type
     * @return {Promise<object>}
     */
    getData(url: string, type: OPEN_GRAPH_TYPES = OPEN_GRAPH_TYPE_OG): Promise<object> {

        const requestData = {
            url,
            type
        };

        return this.http.post<object>(
            environment.apiUrl + '/api/export/getopengraphtags',
            requestData,
            Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
