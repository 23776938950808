import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PackageEditorDialogData } from '~/src/app/modules/administration/packages/package-editor/package-editor.interfaces';
import {
    PartnerControlNames,
    PartnerRules
} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.config';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PlansService } from '~/src/app/modules/administration/plans.service';
import { AddonsService } from '~/src/app/modules/administration/addons.service';
import { Addon } from '~/src/app/modules/administration/addons.interfaces';
import { Plan } from '~/src/app/modules/administration/plans.interfaces';
import Utils from '~/src/app/core/utils';
import { OpenModalService } from '~/src/app/modules/social-media-post/open-modal.service';
import { DialogSuccessComponent } from '~/src/app/components/dialog-success/dialog-success.component';
import { LanguageService } from '~/src/app/services/language.service';
import { DialogErrorComponent } from '~/src/app/components/dialog-error/dialog-error.component';
import { FormValidationService } from '~/src/app/services/form.validation.service';
import { FormHelpersService } from '~/src/app/core/services/form-helpers';
import { DialogLoaderComponent } from '~/src/app/components/dialog-loader/dialog-loader.component';

@Component({
    selector: 'smd-package-editor',
    templateUrl: './package-editor.component.html',
    styleUrls: ['./package-editor.component.scss'],
    providers: [FormHelpersService]
})
export class PackageEditorComponent {
    packageNames = PartnerControlNames;
    rules = PartnerRules;
    packageType: 'plan' | 'addon' = this.dialogData.packet.type;
    packageFormGroup = new FormGroup({
        [this.packageNames.Rules]: new FormControl([]),

        // Numbers
        // [this.packageNames.MediaNumber]: new FormControl(null, this.getValidators()),
        [this.packageNames.PostTemplateNumber]: new FormControl(null, this.getValidators()),
        [this.packageNames.SocialSiteNumber]: new FormControl(null, this.getValidators()),
        [this.packageNames.UserNumber]: new FormControl(null, this.getValidators()),
        [this.packageNames.RssUrlNumber]: new FormControl(null, this.getValidators()),
        [this.packageNames.PostFromRssNumber]: new FormControl(null, this.getValidators()),
        [this.packageNames.OrganizationNumber]: new FormControl(null, this.getValidators()),

        // Period units
        [this.packageNames.PostTemplatePeriod]: new FormControl({ value: null, disabled: (this.packageType === 'addon') }, this.getValidators()),
        // [this.packageNames.MediaPeriod]: new FormControl({value: null, disabled: (this.packageType === 'addon')}, this.getValidators()),
        [this.packageNames.RssFeedPeriod]: new FormControl({ value: null, disabled: (this.packageType === 'addon') }, this.getValidators()),
    });
    newConfig = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PackageEditorDialogData,
        public language: LanguageService,
        public formHelpers: FormHelpersService,
        private dialogRef: MatDialogRef<PackageEditorComponent>,
        private planService: PlansService,
        private addonService: AddonsService,
        private openModal: OpenModalService,
    ) {
        this.formHelpers.formInit(this.packageFormGroup);
    }

    configChange(newConfig: any) {
        this.newConfig = newConfig;
    }

    editPacket(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.packageFormGroup.valid) {
            const packet = {
                ...Utils.lodash.cloneDeep(this.dialogData.packet),
                ...this.newConfig
            } as Plan | Addon;
            const loader = this.openModal.loader(DialogLoaderComponent);

            if (packet.type === 'plan') {
                this.planService.editPlan(packet as Plan)
                    .then(response => {
                        loader.afterClosed().subscribe(() => this.successEdit(response, 'plan'));
                        loader.close();
                    })
                    .catch(error => {
                        loader.afterClosed().subscribe(() => this.failedEdit(error, 'plan'));
                        loader.close();
                    });
            }

            if (packet.type === 'addon') {
                this.addonService.editAddon(packet as Addon)
                    .then(response => {
                        loader.afterClosed().subscribe(() => this.successEdit(response, 'addon'));
                        loader.close();
                    })
                    .catch(error => {
                        loader.afterClosed().subscribe(() => this.failedEdit(error, 'addon'));
                        loader.close();
                    });
            }
        } else {
            this.formHelpers.validateForm(this.packageFormGroup);
        }
    }

    private getValidators(): ValidatorFn[] {

        if (this.packageNames.OrganizationNumber === 'organizationNumber' && this.packageType === 'addon') {
            return (this.packageNames.OrganizationNumber === 'organizationNumber') ? [Validators.max(99999)] : [];
        }

        return (this.packageType === 'plan') ? [Validators.required] : [];
    }

    private successEdit(response: any, type: 'plan' | 'addon') {
        this.dialogRef.afterClosed().subscribe(() => {
            if (!!this.dialogData.successEdit) {
                this.dialogData.successEdit(response);
            }
        });

        this.openModal.successModal(DialogSuccessComponent, {
            message: this.language.getLine(`package.editor.${type}.action.edit.success`)
        }).afterClosed().subscribe(() => {
            this.dialogRef.close();
        });
    }

    private failedEdit(error: any, type: 'plan' | 'addon') {
        this.formHelpers.validateForm(
            this.packageFormGroup,
            Utils.lodash.get(FormValidationService.readError(error), 'formMessages', {}) || {}
        );

        this.openModal.errorModal(DialogErrorComponent, {
            message: FormValidationService.readError(error).message || this.language.getLine(`package.editor.${type}.action.edit.error`)
        });
    }
}
