import { WidgetMetricOptionType } from "~/src/app/modules/analytics/widget/widget.interfaces";
import { MetricConfigs } from "~/src/app/modules/analytics/widget/metric-configs/metric.config";
import { BaseChartConfig } from "~/src/app/modules/analytics/widget/metric-configs/_base-chart-config";
import { SOCIAL_MEDIA_TYPE_LINKEDIN } from "~/src/app/core/constants";

export const WIDGET_METRIC_OPTION_VIEWS_PAGES: WidgetMetricOptionType = {
    id: "views-pages",
    name: "Pages",
    resource: "analytics.analytics.pageViews",
    socialChannel: "facebook",
};

export const WIDGET_METRIC_OPTION_VIEWS_VIDEOS_ORGANIC: WidgetMetricOptionType =
    {
        id: "views-videos-organic",
        name: "Videos organic",
        resource: "analytics.analytics.pageVideoViews",
        socialChannel: "facebook",
    };

export const WIDGET_METRIC_OPTION_VIEWS_VIDEOS_PAID: WidgetMetricOptionType = {
    id: "views-videos-paid",
    name: "Videos paid",
    resource: "analytics.analytics.pageVideoViews",
    socialChannel: "facebook",
};

export const WIDGET_METRIC_OPTION_VIEWS_VIDEOS_TOTAL: WidgetMetricOptionType = {
    id: "views-videos-total",
    name: "Videos total",
    resource: "analytics.analytics.pageVideoViews",
    socialChannel: "facebook",
};

export const WIDGET_METRIC_OPTION_VIEWS_VIDEOS_UNIQUE: WidgetMetricOptionType =
    {
        id: "views-videos-total",
        name: "Video views (Unique)",
        resource: "analytics.analytics.pageVideoViews",
        socialChannel: "linkedIn",
    };

// METRIC CONFIGS

export const MetricViewPagesConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_VIEWS_PAGES.id,
    apiURL: "/page-views",
    socialType: "facebook",
    contentType: "chart",
    dataName: "Page Views",
    chartConfig: {
        ...BaseChartConfig,
    },
};

export const MetricViewVideosOrganicConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_VIEWS_VIDEOS_ORGANIC.id,
    apiURL: "/page-video-views",
    socialType: "facebook",
    contentType: "chart",
    dataName: "Video Views (organic)",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "organic", // TODO utánanézni
        unique: "yes",
    },
};

export const MetricViewVideosPaidConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_VIEWS_VIDEOS_PAID.id,
    apiURL: "/page-video-views",
    socialType: "facebook",
    contentType: "chart",
    dataName: "Video Views (paid)",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        type: "paid", // TODO utánanézni
        unique: "yes",
    },
};

export const MetricViewVideosTotalConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_VIEWS_VIDEOS_TOTAL.id,
    apiURL: "/page-video-views",
    socialType: "facebook",
    contentType: "chart",
    dataName: "Video Views",
    chartConfig: {
        ...BaseChartConfig,
    },
    defaultFilters: {
        unique: "yes",
    },
};
