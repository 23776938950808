import {WidgetAbstract} from '~/src/app/modules/analytics/widget/widget.abstract';
import {Widget} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {TextWidgetEntity} from '~/src/app/modules/analytics/widget/text-widget.entity';
import {ChartWidgetEntity} from '~/src/app/modules/analytics/widget/chart-widget.entity';
import {WIDGET_TYPE_TEXT} from '~/src/app/modules/analytics/widget/widget.configs';

export class BaseWidgetEntity extends WidgetAbstract {
    constructor(widget: Widget) {
        super(widget);

        return widget.type === WIDGET_TYPE_TEXT ? new TextWidgetEntity(widget) : new ChartWidgetEntity(widget);
    }
}
