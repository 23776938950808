import { Injectable } from "@angular/core";
import { LanguageService } from "./language.service";

@Injectable()
export class FormMessagesService {
    private static messages = {
        error: {
            required: { key: "validator.required" },
            minlength: "The minimum length is {min} caracters.",
            maxlength: "Maximum number of characters ({max}) reached.",
            notEqual: "Two fields are not equal.",
            min: "The minimum length is {min} caracters.",
            max: "Maximum number of characters reached.",
            email: "Invalid email address.",
            url: { key: "validator.url" },
            minDate: { key: "validator.mindatereached" },
            maxDate: { key: "validator.maxdatereached" },
            dates: "Start date can not be earlier than todays date",
        },
    };

    constructor() {}

    private static replaceProperties(
        message: string,
        properties: object
    ): string {
        for (const key in properties) {
            const value = properties[key];

            message = message.replace(`{${key}}`, value);
        }

        return message;
    }

    public static getError(type: string, properties: object = {}): string {
        let message;

        if (
            typeof this.messages.error[type] === "object" &&
            this.messages.error[type]["key"]
        ) {
            message = LanguageService.getLine(this.messages.error[type].key);
        } else {
            message = this.messages.error[type];
        }

        return this.replaceProperties(message, properties);
    }
}
