import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {FALLBACK_IMAGE} from '~/src/app/configs/configs';
import {LanguageService} from '~/src/app/services/language.service';
import {SmdFile, SmdFileInterface} from '~/src/app/services/file.class';

let dashboardMediaIndex = 0;

@Component({
    selector: 'smd-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
    @Input() media: SmdFile;
    @Input() showButtons = true;
    @Input() enablePreview = true;
    @Input() isAdminMode = false;
    @Output('onClickEdit') onClickEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output('onClickDelete') onClickDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output('onCreatePost') onCreatePost: EventEmitter<any> = new EventEmitter<any>();
    //@Output('contextmenu') contextmenu: EventEmitter<any> = new EventEmitter<any>();
    @Output('onOpenVideo') onOpenVideo: EventEmitter<any> = new EventEmitter<any>();
    @Output('onClickInfo') onClickInfo: EventEmitter<any> = new EventEmitter<any>();
    @Output() setPartnersClick = new EventEmitter();
    @Output() setStatusClick = new EventEmitter();

    @Output() tagClick: EventEmitter<string> = new EventEmitter<string>();

    dashboardMediaIndex;

    constructor(
        public languageService: LanguageService
    ) {
        this.dashboardMediaIndex = dashboardMediaIndex++;
    }

    ngOnInit() {}

    emitOnOpenVideo(media) {
        this.onOpenVideo.emit(media);
    }

    /* emitContextmenu(event) {
        this.contextmenu.emit(event);
    } */

    emitOnClickInfo(event){
        this.onClickInfo.emit(event);
    }

    emitCreatePost(media) {
        this.onCreatePost.emit(media);
    }

    emitClickDelete(media) {
        this.onClickDelete.emit(media);
    }

    emitClickEdit(media) {
        this.onClickEdit.emit(media);
    }

    emitSetPartnersClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.setPartnersClick.emit();
    }

    emitSetStatusClick(event: MouseEvent, status: 'activate' | 'suspend') {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.setStatusClick.emit(status);
    }

    emitTagClick(tag: string) {
        this.tagClick.emit(tag);
    }

    getTooltipForMediaTags(media) {
        if (media) {
            const tags = media.tags;
            const tooltipTags = [];

            for (const tag of tags.slice(5, tags.length)) {
                tooltipTags.push(tag);
            }

            return tooltipTags.join(", ");
        }

        return "";
    }

    isActiveMedia(media: SmdFileInterface) {
        return media.status === 'active';
    }

    get fileTypes() {
        return FILE_TYPES;
    }

    get i() {
        return this.dashboardMediaIndex;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }
}
