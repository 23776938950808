import { Component, OnInit, Input } from "@angular/core";
import { AbstractFormGroupComponent } from "~/src/app/modules/social-media-post/gmb/form/abstract/abstract.component";
import { each } from "lodash";
import { FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import Utils from "~/src/app/core/utils";
import * as moment from "moment-timezone";
@Component({
    selector: "smd-event",
    templateUrl: "./event-form.component.html",
    styleUrls: ["./event-form.component.scss"],
})
export class EventFormComponent
    extends AbstractFormGroupComponent
    implements OnInit
{
    subscriptions: Subscription[] = [];

    /**
     * Post type
     */
    @Input() postType: string;

    /**
     * Todays date for datepicker
     */
    todaysDate = new Date();

    /**
     * Todays date for datepicker
     */
    minToDate = new Date();

    formats = {
        year: "YYYY",
        month: "MM",
        day: "DD",
        hour: "HH",
        minute: "mm",
        second: "ss",
    };
    date = { year: null, month: null, day: null };
    time = { hours: null, minutes: null, seconds: null, nanos: null };

    schedule = {
        startDate: this.fb.group(this.date),
        startTime: this.fb.group(this.time),
        endDate: this.fb.group(this.date),
        endTime: this.fb.group(this.time),
    };

    formGroup: FormGroup = this.fb.group(
        {
            title: [null, [Validators.required]],
            schedule: this.fb.group(this.schedule),
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
        },
        {
            validator: this.compareDates(
                "startDate",
                "endDate",
                "startTime",
                "endTime"
            ),
        }
    );

    error: any = { isError: false, errorMessage: "" };

    ngOnInit(): void {
        this.formGroup.controls.startDate.valueChanges.subscribe((value) => {
            this.setDate(value, "startDate");
        });
        this.formGroup.controls.endDate.valueChanges.subscribe((value) => {
            this.setDate(value, "endDate");
        });
        this.formGroup.controls.startTime.valueChanges.subscribe((value) => {
            this.setTime(value, "startTime");
        });
        this.formGroup.controls.endTime.valueChanges.subscribe((value) => {
            this.setTime(value, "endTime");
        });
    }

    setDate(date, fieldName) {
        if (fieldName === "startDate") {
            this.minToDate = date;
        }
        this.formGroup.get(["schedule", fieldName]).patchValue({
            year: Number(Utils.moment(date).format(this.formats.year)) || 0,
            month: Number(Utils.moment(date).format(this.formats.month)) || 0,
            day: Number(Utils.moment(date).format(this.formats.day)) || 0,
        });
    }

    setTime(time, fieldName) {
        this.formGroup.get(["schedule", fieldName]).patchValue({
            hours: Number(Utils.moment(time).format(this.formats.hour)) || null,
            minutes:
                Number(Utils.moment(time).format(this.formats.minute)) || null,
            seconds: null,
            nanos: null,
        });
    }

    toggleDisable(toggle) {
        super.toggleDisable(toggle);

        each(["startDate", "endDate"], (field) => {
            const control = this.formGroup.controls[field];
            toggle
                ? control.setValidators([Validators.required])
                : control.clearValidators();
        });
    }

    compareDates(from: string, to: string, fromTime: string, toTime: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const CurrentDate = moment();

            if (
                group.controls[from].value !== null &&
                moment(group.controls[from].value).isBefore(CurrentDate, "day")
            ) {
                return {
                    dates: "Start date can not be earlier than todays date",
                };
            }

            if (
                group.controls[to].value !== null &&
                moment(group.controls[to].value).isBefore(CurrentDate, "day")
            ) {
                return {
                    dates: "End date can not be earlier than todays date",
                };
            }

            if (
                group.controls[to].value !== null &&
                group.controls[from].value !== null &&
                (moment(group.controls[to].value).isBefore(
                    group.controls[from].value,
                    "day"
                ) ||
                    (moment(group.controls[from].value).isSame(
                        group.controls[to].value,
                        "day"
                    ) &&
                        (group.controls[toTime].value === null ||
                            group.controls[fromTime].value === null ||
                            (group.controls[toTime].value !== null &&
                                group.controls[fromTime].value !== null &&
                                moment(group.controls[toTime].value).isBefore(
                                    group.controls[fromTime].value
                                )))))
            ) {
                return {
                    dates: "Start date and time can not be earlier or same as end date",
                };
            }

            return {};
        };
    }
}
