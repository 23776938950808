export const environment = {
    production: true,
    isSharpMode: true,
    isDebugMode: false,
    apiUrl: '',
    requestTimeout: 60000,
    facebookAppID: '2538630596466621',
    linkedIn: {
        linkedInRedirectUri: 'https://app.esemdee.com/linkedin',
        clientId: '77dhj69xlyegcm'
    },
    tinyMcePluginFolder: '/themes/smd/dist/assets/tinymce/plugins',
    enableHelpCenter: true,
    cache: {
        enabled: true,
        maxAge: 10 * 60 * 1000,
    },
};
