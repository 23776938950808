import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SocialManagerComponent} from '../social-manager/social-manager.component';
import {SocialManagerService} from '../social-manager/social-manager.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MyErrorStateMatcher} from '../../../services/helpers';
import {DialogSuccessComponent} from '../../../components/dialog-success/dialog-success.component';
import {DialogLoaderComponent} from '../../../components/dialog-loader/dialog-loader.component';
import {DialogErrorComponent} from '../../../components/dialog-error/dialog-error.component';
import {FormValidationService} from '../../../services/form.validation.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {LanguageService} from '../../../services/language.service';
import {TimezoneService} from '~/src/app/modules/users/social-site-edit-dialog/timezone.service';

interface DialogData {
    id: number;
    name: string;
    timezone: string;
}

interface PageData {
    name: string;
    timezone: string;
}

@Component({
    selector: 'smd-social-site-edit-dialog',
    templateUrl: './social-site-edit-dialog.component.html',
    styleUrls: ['./social-site-edit-dialog.component.scss']
})
export class SocialSiteEditDialogComponent implements OnInit {
    socialManager: SocialManagerComponent;
    editPageForm = new FormGroup({
        name: new FormControl(this.data.name, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(75)
            ]
        ),
        timezone: new FormControl(this.data.timezone, [
            Validators.required,
        ])
    });
    pageData: PageData[] = [];
    editPageFormErrors: object = {};
    matcher = new MyErrorStateMatcher();
    timezones: string[] = [];
    filteredOptions: Observable<string[]>;

    constructor(
        public timezoneService: TimezoneService,
        public languageService: LanguageService,
        public _dialog: MatDialog,
        public _dialogRef: MatDialogRef<SocialSiteEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        socialManagerService: SocialManagerService,
    ) {
        this.socialManager = new SocialManagerComponent(socialManagerService);
    }

    ngOnInit() {
        this.initTimezone();

        this.filteredOptions = this.editPageForm.get('timezone').valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }

    public closeDialog() {
        this._dialogRef.close();
    }

    public submitPage(event) {
        event.preventDefault();

        if (this.validateEditForm()) {
            const _loader = this._dialog.open(DialogLoaderComponent, {
                disableClose: true,
                panelClass: 'dialog-loader-modal',
                minWidth: '200vw',
                minHeight: '200vh',
                hasBackdrop: false
            });

            this.socialManager.editPage(this.data.id, this.editPageForm.getRawValue())
            .then(response => {

                _loader.afterClosed().subscribe(() => {
                    this._dialog.open(DialogSuccessComponent, {
                        data: {
                            message: LanguageService.getLine('social.sites.edit.success')
                        }
                    }).afterClosed().subscribe(() => {
                        this._dialogRef.close(true);
                    });
                });

                _loader.close();

            })
            .catch(error => {
                this.validateEditForm(error);

                _loader.afterClosed().subscribe(() => {
                    this._dialog.open(DialogErrorComponent, {
                        data: {
                            message: FormValidationService.readError(error).message
                        }
                    });
                });

                _loader.close();
            });
        }
    }

    public validateEditForm(error?): boolean {
        this.matcher = new MyErrorStateMatcher();
        this.editPageFormErrors = {};

        if (!this.editPageForm.valid) {
            this.editPageFormErrors = FormValidationService.getMessages(this.editPageForm.controls);
        }

        if (error) {
            this.editPageFormErrors = FormValidationService.setFormControlsIncorrect(error, this.editPageForm, this.editPageFormErrors);
        }

        for (const controlName in this.editPageForm.controls) {
            if (this.editPageFormErrors[controlName]) {
                this.editPageForm.get(controlName).markAsTouched();
            } else {
                this.editPageForm.get(controlName).markAsUntouched();
            }
        }

        return this.editPageForm.valid;
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.timezones.filter(option => option.toLowerCase().includes(filterValue));
    }

    private initTimezone() {
        this.timezoneService.getAll().then(response => this.timezones = response);
    }
}
