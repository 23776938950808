import {Component, Inject, OnInit} from '@angular/core';
import {MyErrorStateMatcher} from '~/src/app/services/helpers';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {LanguageService} from '~/src/app/services/language.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {HashtagCollectionService} from '~/src/app/modules/hashtag/hashtag-collection.service';
import {IHashtagCollectionModalFormDialogData} from '~/src/app/modules/hashtag/components/hashtag-collection-modal-form/hashtag-collection-modal-form.interface';

@Component({
    selector: 'smd-hashtag-collection-modal-form',
    templateUrl: './hashtag-collection-modal-form.component.html',
    styleUrls: ['./hashtag-collection-modal-form.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class HashtagCollectionModalFormComponent implements OnInit {
    // error matcher
    errorMatcher = new MyErrorStateMatcher();

    // is edit mode
    isEditMode = false;

    // hashtag collection control names
    controlNames = {
        Name: 'name',
        Tags: 'tags',
        Organization: 'organizationID'
    };

    // hashtag collection form group
    formGroup: FormGroup = new FormGroup({
        [this.controlNames.Organization]: new FormControl((this.data && this.data.item) ? this.data.item.organizationID : null, [Validators.required]),
        [this.controlNames.Name]: new FormControl((this.data && this.data.item) ? this.data.item.name : null, [Validators.required]),
        [this.controlNames.Tags]: new FormControl((this.data && this.data.item) ? this.data.item.tags : null, [Validators.required])
    });

    constructor(
        public formHelpers: FormHelpersService,
        public componentHelpers: ComponentHelpers,
        public language: LanguageService,
        private dialogRef: MatDialogRef<HashtagCollectionModalFormComponent>,
        private hashtagCollectionService: HashtagCollectionService,
        @Inject(MAT_DIALOG_DATA) public data: IHashtagCollectionModalFormDialogData
    ) {
        this.formHelpers.formInit(this.formGroup);

        if (this.data && this.data.item) {
            this.isEditMode = true;
        }
    }

    ngOnInit() {
    }

    /**
     * Create new hashtag collection
     *
     * @param {MouseEvent} event
     */
    createHashtagCollection(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.formGroup.valid) {
            const data = this.formGroup.value;

            this.componentHelpers.startApiAction(
                () => {
                    return this.hashtagCollectionService.create(data);
                }, {
                    successMessageKey: 'post.hashtagCollection.success.modal.create',
                    failedMessageKey: 'post.hashtagCollection.error.modal.failedToCreate',
                    afterSuccessAction: (response: any) => {
                        if (!!this.data.successAction) {
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.data.successAction(response);
                            });
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: (response: any) => {
                        this.formHelpers.validateForm(this.formGroup, FormValidationService.readError(response).formMessages);
                    }
                },
            );
        } else {
            this.formHelpers.validateForm(this.formGroup);
        }
    }

    /**
     * Modify current hashtag collection
     *
     * @param {MouseEvent} event
     */
    modifyHashtagCollection(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.formGroup.valid) {
            const data = this.formGroup.value;

            this.componentHelpers.startApiAction(
                () => {
                    return this.hashtagCollectionService.update(this.data.item.collectionID, data);
                }, {
                    successMessageKey: 'post.hashtagCollection.success.modal.edit',
                    failedMessageKey: 'post.hashtagCollection.error.modal.failedToEdit',
                    afterSuccessAction: (response: any) => {
                        if (!!this.data.successAction) {
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.data.successAction(response);
                            });
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: (response: any) => {
                        this.formHelpers.validateForm(this.formGroup, FormValidationService.readError(response).formMessages);
                    }
                },
            );
        } else {
            this.formHelpers.validateForm(this.formGroup);
        }
    }
}
