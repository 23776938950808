import { Validators } from "@angular/forms";
import { url } from "@ng-validators/ng-validators";

export const GAPI_KEY = "AIzaSyDZOr-rN0y1_ntFL8UdOUr1Tgske1GQVrQ";
export const GAPI_CLIENT_ID =
    "110797040787-oho4dmk5snhf932794q5q89m8tam2edq.apps.googleusercontent.com";
export const GAPI_DISCOVERY_DOCS = [
    "https://people.googleapis.com/$discovery/rest?version=v1",
];
export const GAPI_SCOPES = "profile";

export const POST_TYPE_OPTIONS = [
    {
        labelKey: "post.gmb.post.type.standard",
        value: "standard",
        hide: ["event", "offer", "alertType"],
    },
    {
        labelKey: "post.gmb.post.type.event",
        value: "event",
        hide: ["offer", "alert", "alertType"],
    },
    {
        labelKey: "post.gmb.post.type.offer",
        value: "offer",
        hide: ["callToAction", "alertType"],
    },
    /*     {
        labelKey: 'post.gmb.post.type.alert',
        value: 'alert',
        hide: ['event', 'offer']
    } */
];

export const ACTION_TYPE_OPTIONS = [
    {
        labelKey: "post.gmb.post.action.type.select",
        value: "",
    },
    {
        labelKey: "post.gmb.post.action.type.book",
        value: "BOOK",
    },
    {
        labelKey: "post.gmb.post.action.type.order",
        value: "ORDER",
    },
    {
        labelKey: "post.gmb.post.action.type.shop",
        value: "SHOP",
    },
    {
        labelKey: "post.gmb.post.action.type.learnMore",
        value: "LEARN_MORE",
    },
    {
        labelKey: "post.gmb.post.action.type.signUp",
        value: "SIGN_UP",
    },
    {
        labelKey: "post.gmb.post.action.type.call",
        value: "CALL",
    },
];

export const ALERT_TYPE_OPTIONS = [
    {
        labelKey: "post.gmb.post.alert.type.covid",
        value: "COVID_19",
    },
];

export const POST_OPTIONS_KEY = "gmbOptions";

// limits
export const LIMITS_TITLE = 58;

export const POST_FORM_CONTROL_NAMES = {
    type: "type",
    event: "event",
    offer: "offer",
    callToAction: "callToAction",
    alertType: "alertType",
};
