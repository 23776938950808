import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as _ from "lodash";
import { EditorModule } from "@tinymce/tinymce-angular";
import { AppComponent } from "./app.component";
import { AuthComponent } from "./modules/auth/auth.component";
import { MainComponent } from "./modules/main/main.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { SmdRouting } from "./routing/app.routing";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DoLogoutDirective } from "./directives/do.logout.directive";
import { PasswordResetComponent } from "./modules/auth/password-reset/password-reset.component";
import { TemplateManagerComponent } from "./modules/posts/template-manager.component";
import { ScheduleComponent } from "./modules/posts/schedule/schedule.component";
import { AccessManagementComponent } from "./modules/users/access-management/access-management.component";
import { OrganizationsComponent } from "./modules/users/organizations/organizations.component";
import { AutofeedPageService } from "./modules/autofeed/autofeed-service.service";
import { DialogSuccessComponent } from "./components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "./components/dialog-error/dialog-error.component";
import { DialogLoaderComponent } from "./components/dialog-loader/dialog-loader.component";
import { DialogConfirmComponent } from "./components/dialog-confirm/dialog-confirm.component";
import { DialogWarnComponent } from "./components/dialog-warn/dialog-warn.component";
import { MomentModule } from "angular2-moment";
import {
    ActiveTabDirective,
    ProfileComponent,
} from "~/src/app/modules/users/profile/profile.component";
import {
    CalendarModule,
    DateAdapter as CalendarDateAdapter,
} from "angular-calendar";
import { SchedulerModule } from "angular-calendar-scheduler";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { DialogPostsListComponent } from "./modules/posts/schedule/dialog-posts-list/dialog-posts-list.component";
import { BottomSheetChooseScheduleSourceComponent } from "./modules/posts/schedule/bottom-sheet-choose-schedule-source/bottom-sheet-choose-schedule-source.component";
import { SlidePanelComponent } from "./components/slide-panel/slide-panel.component";
import { UploadMediaComponent } from "./components/upload-media/upload-media.component";
import { FacebookModule } from "ngx-facebook";
import { FacebookDialogComponent } from "./modules/users/Facebook-dialog.component";
import { CustomWeekViewComponent } from "./modules/posts/schedule/custom-week-view/custom-week-view.component";
import { DialogProgressComponent } from "./components/dialog-progress/dialog-progress.component";
import { CreateCarouselComponent } from "./components/upload-media/create-carousel/create-carousel.component";
import { SocialSiteEditDialogComponent } from "./modules/users/social-site-edit-dialog/social-site-edit-dialog.component";
import { RightSideBarComponent } from "./components/right-side-bar/right-side-bar.component";
import { PostListComponent } from "./modules/posts/post-list/post-list.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { SortablejsModule } from "angular-sortablejs";
import { TwoFactorDialogComponent } from "./modules/users/profile/two-factor-dialog/two-factor-dialog.component";
import { ModifyUserDialogComponent } from "./modules/users/access-management/modify-user-dialog/modify-user-dialog.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CreateOrgModalComponent } from "./modules/users/organizations/create-org-modal/create-org-modal.component";
import { selectOrgModalComponent } from "./modules/users/organizations/select-org-modal/select-org-modal.component";
import { ActivityComponent } from "./modules/activity/activity.component";
import { ModifyRoleDialogComponent } from "./modules/users/access-management/roles/modify-role-dialog/modify-role-dialog.component";
import { AnalyticsModule } from "./modules/analytics/analytics.module";
import { LanguagesModule } from "./modules/languages/languages.module";
import { NgxScrollToFirstInvalidModule } from "@ismaestro/ngx-scroll-to-first-invalid";
import { NgValidatorsModule } from "@ng-validators/ng-validators";
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { DashboardFixModule } from "~/src/app/modules/dashboard-fix/dashboard-fix.module";
import { ScheduleModule } from "~/src/app/modules/posts/schedule/schedule.module";
import { SharedModule } from "~/src/app/shared/shared.module";
import { DocumentsModule } from "~/src/app/modules/documents/documents.module";
import { SocialMediaPostModule } from "~/src/app/modules/social-media-post/social-media-post.module";
import { LinkedInService } from "~/src/app/services/linked-in.service";
import { PostTemplateFiltersModule } from "~/src/app/modules/posts/post-template-filters/post-template-filters.module";
import { PostTemplateModule } from "~/src/app/modules/posts/post-template/post-template.module";
import { LinkedinLoginModule } from "~/src/app/modules/linkedin-login/linkedin-login.module";
import { AdministrationModule } from "~/src/app/modules/administration/administration.module";
import { DateAdapter } from "@angular/material/core";
import { BackendService } from "~/src/app/core/backend.service";
import { CategoriesModule } from "~/src/app/modules/categories/categories.module";
import { EmailNotificationManagerComponent } from "~/src/app/modules/users/profile/email-notification-manager/email-notification-manager.component";
import Utils from "~/src/app/core/utils";
import { BulkUploadModule } from "~/src/app/modules/posts/bulk-upload/bulk-upload.module";
import { OauthComponent } from "~/src/app/modules/oauth/oauth.component";
import { HashtagModule } from "~/src/app/modules/hashtag/hashtag.module";
import { InstagramLocationModule } from "~/src/app/modules/instagram-location/instagram-location.module";
import { MentionModule } from "~/src/app/modules/mention/mention.module";
import { UsernameResetComponent } from "~/src/app/modules/auth/username-reset/username-reset.component";
import { RssModule } from "~/src/app/modules/rss/rss.module";
import { FilterPipe } from "~/src/app/pipes/filter.pipe";
import { AutofeedModule } from "~/src/app/modules/autofeed/autofeed.module";
import { AutofeedComponent } from "~/src/app/modules/autofeed/autofeed.component";
import { ImageEditorComponent } from "./components/image-editor/image-editor.component";
import { ImageBrowserComponent } from "./components/image-browser/image-browser.component";
import { PixieComponent } from "./components/pixie/pixie.component";
import { DialogInfoComponent } from "~/src/app/components/dialog-info/dialog-info.component";
import { OrderByPipe } from "~/src/app/pipes/orderBy.pipe";
import { SocialUserDialogComponent } from "~/src/app/modules/users/social-user-dialog/social-user-dialog.component";
import { GmbLocationsDialogComponent } from "~/src/app/modules/users/gmb/gmb-locations-dialog.component";
import { CacheInterceptor } from "./app.interceptor";
import { NotificationMenuComponent } from '~/src/app/modules/notification-menu/notification-menu.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MentionModule as AngularMentionModule } from '~/node_modules/angular-mentions';
import { DashboardsModule } from "~/src/app/modules/dashboards/dashboards.module";
import { DialogChooserComponent } from "./components/dialog-chooser/dialog-chooser.component";
import { OrganizationsGroupbyPlateformComponent } from './components/organizations-groupby-plateform/organizations-groupby-plateform.component';
import { OrganizationsGroupbyOrganizationComponent } from './components/organizations-groupby-organization/organizations-groupby-organization.component';

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        MainComponent,
        MainMenuComponent,
        DoLogoutDirective,
        PasswordResetComponent,
        TemplateManagerComponent,
        ScheduleComponent,
        AccessManagementComponent,
        OrganizationsComponent,
        AutofeedComponent,
        DialogSuccessComponent,
        DialogErrorComponent,
        DialogLoaderComponent,
        DialogConfirmComponent,
        DialogChooserComponent,
        SocialUserDialogComponent,
        DialogWarnComponent,
        ProfileComponent,
        DialogPostsListComponent,
        BottomSheetChooseScheduleSourceComponent,
        SlidePanelComponent,
        UploadMediaComponent,
        SocialUserDialogComponent,
        GmbLocationsDialogComponent,
        FacebookDialogComponent,
        CustomWeekViewComponent,
        DialogProgressComponent,
        CreateCarouselComponent,
        SocialSiteEditDialogComponent,
        RightSideBarComponent,
        PostListComponent,
        BreadcrumbComponent,
        TwoFactorDialogComponent,
        ModifyUserDialogComponent,
        CreateOrgModalComponent,
        ActivityComponent,
        ModifyRoleDialogComponent,
        ActiveTabDirective,
        EmailNotificationManagerComponent,
        OauthComponent,
        UsernameResetComponent,
        FilterPipe,
        OrderByPipe,
        ImageEditorComponent,
        ImageBrowserComponent,
        PixieComponent,
        DialogInfoComponent,
        NotificationMenuComponent,
        OrganizationsGroupbyPlateformComponent,
        OrganizationsGroupbyOrganizationComponent,
        selectOrgModalComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        SmdRouting,
        HttpClientModule,
        EditorModule,
        LanguagesModule,
        MomentModule,
        CalendarModule.forRoot({
            provide: CalendarDateAdapter,
            useFactory: adapterFactory,
        }),
        SchedulerModule.forRoot({
            locale: "en",
            headerDateFormat: "daysRange",
        }),
        FacebookModule.forRoot(),
        SortablejsModule.forRoot({ animation: 150 }),
        InfiniteScrollModule,
        AnalyticsModule,
        DashboardFixModule,
        ScheduleModule,
        NgxScrollToFirstInvalidModule,
        NgValidatorsModule,
        HttpClientModule,
        NgxUiLoaderModule.forRoot({
            // logoUrl: '/themes/smd/dist/assets/images/logo/esemdee-logo-symbol-01.png',
            fgsColor: "transparent",
            overlayColor: "rgba(255,255,255,1)",
            fgsType: "three-strings",
            pbColor: "#3fcfff",
            pbThickness: 3,
        }),
        NgxUiLoaderRouterModule,
        DocumentsModule,
        SocialMediaPostModule,
        PostTemplateModule,
        PostTemplateFiltersModule,
        LinkedinLoginModule,
        AdministrationModule,
        CategoriesModule,
        HashtagModule,
        InstagramLocationModule,
        RssModule,
        BulkUploadModule,
        MentionModule,
        AutofeedModule,
        MatBadgeModule,
        AngularMentionModule,
        DashboardsModule
    ],
    providers: [
        LinkedInService,
        AutofeedPageService,
        BackendService,
        { useClass: CacheInterceptor, provide: HTTP_INTERCEPTORS, multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
        ScheduleComponent,
        DialogSuccessComponent,
        DialogLoaderComponent,
        DialogErrorComponent,
        DialogConfirmComponent,
        DialogChooserComponent,
        DialogWarnComponent,
        DialogPostsListComponent,
        BottomSheetChooseScheduleSourceComponent,
        UploadMediaComponent,
        SocialUserDialogComponent,
        GmbLocationsDialogComponent,
        FacebookDialogComponent,
        DialogProgressComponent,
        SocialSiteEditDialogComponent,
        RightSideBarComponent,
        TwoFactorDialogComponent,
        ModifyUserDialogComponent,
        CreateOrgModalComponent,
        ModifyRoleDialogComponent,
        EmailNotificationManagerComponent,
        ImageBrowserComponent,
        PixieComponent,
        DialogInfoComponent,
        selectOrgModalComponent,
    ],
})
export class AppModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        dateAdapter.setLocale("en-in"); // DD/MM/YYYY
        window["_"] = _;
        window["queryString"] = Utils.queryString;
        window["htmlEntities"] = Utils.htmlEntities;
    }
}
