import {debounceTime} from 'rxjs/operators';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '~/node_modules/@angular/forms';
import {SubscriptionControlNames} from '~/src/app/modules/administration/partners/partner-subscription/partner-subscription.config';
import {LanguageService} from '~/src/app/services/language.service';
import {PlansService} from '~/src/app/modules/administration/plans.service';
import {Plan} from '~/src/app/modules/administration/plans.interfaces';
import {Addon} from '~/src/app/modules/administration/addons.interfaces';
import {AddonsService} from '~/src/app/modules/administration/addons.service';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {MyErrorStateMatcher} from '~/src/app/services/helpers';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {
    PartnerSubscriptionDialogData,
    SelectedPackages
} from '~/src/app/modules/administration/partners/partner-subscription/partner-subscription.interfaces';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Utils from '~/src/app/core/utils';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {Partner, PartnerSubscription} from '~/src/app/shared/types/partners.model';

@Component({
    selector: 'smd-partner-subscription',
    templateUrl: './partner-subscription.component.html',
    styleUrls: ['./partner-subscription.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class PartnerSubscriptionComponent implements OnInit {

    errorMatcher = new MyErrorStateMatcher();
    subscriptionControlNames = SubscriptionControlNames;
    subscriptionFormGroup = new FormGroup({
        [this.subscriptionControlNames.Name]: new FormControl(null, [Validators.required]),
        [this.subscriptionControlNames.ExpiryDate]: new FormControl(null, [Validators.required]),
        [this.subscriptionControlNames.Plan]: new FormControl(null, [Validators.required]),
        [this.subscriptionControlNames.Addons]: new FormControl([])
    });
    selectedPackageConfigs: SelectedPackages = {
        plan: null,
        addons: null,
    };

    protected partner: Partner;

    public isEditMode = false;
    public createButtonEnabled = false;
    public editButtonEnabled = false;
    public plansPending = false;
    public addonsPending = false;

    public plans: Plan[] = [];
    public addons: Addon[] = [];

    constructor(
        public language: LanguageService,
        public formHelpers: FormHelpersService,
        private componentHelpers: ComponentHelpers,
        private partnersService: PartnersService,
        private plansService: PlansService,
        private addonsService: AddonsService,
        @Inject(MAT_DIALOG_DATA) public data: PartnerSubscriptionDialogData,
        private dialogRef: MatDialogRef<PartnerSubscriptionComponent>
    ) {
    }

    ngOnInit() {
        // set values
        this.partner = this.data.partner;
        this.isEditMode = this.data.isEditMode;

        // initialize subscription form
        this.formHelpers.formInit(this.subscriptionFormGroup);

        // initialize result tab
        this.onChanges();

        if (this.isEditMode) {
            // disable expiry date
            this.subscriptionFormGroup.get(this.subscriptionControlNames.ExpiryDate).disable();
        }

        // wait until plans and addons request done
        Promise.all([this.getPlans(), this.getAddons()]).then(() => {
            // enable buttons
            this.createButtonEnabled = true;
            this.editButtonEnabled = true;

            // on edit mode
            if (this.isEditMode) {
                // patch values
                this.subscriptionFormGroup.patchValue((this.partner.subscription as PartnerSubscription), {emitEvent: true});
            }
        });
    }

    /**
     * Adds subscription
     * @param event
     */
    addSubscription(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.createApiAction(
            () => {
                return this.partnersService.addSubscription(
                    this.subscriptionFormGroup.value, this.partner.partnerID
                );
            },
            'background.subscription.create.message.success',
            'background.subscription.create.message.failed'
        );
    }

    /**
     * Edit subscription
     * @param event
     */
    editSubscription(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.createApiAction(
            () => {
                return this.partnersService.editSubscription(
                    this.subscriptionFormGroup.value,
                    (this.partner.subscription as PartnerSubscription).subscriptionID,
                    this.partner.partnerID
                );
            },
            'background.subscription.edit.message.success',
            'background.subscription.edit.message.failed'
        );
    }

    /**
     * Starts API action
     * @param promise
     * @param successMessageKey
     * @param failedMessageKey
     */
    createApiAction(promise: () => Promise<Object>, successMessageKey?: string, failedMessageKey?: string) {
        if (this.subscriptionFormGroup.valid) {
            this.componentHelpers.startApiAction(
                () => {
                    return promise();
                },
                {
                    successMessageKey: successMessageKey || 'core.message.success',
                    failedMessageKey: failedMessageKey || 'core.message.failed',
                    afterSuccessAction: (response: any) => {
                        if (!!this.data.successSubscriptionAdd) {
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.data.successSubscriptionAdd(response);
                            });
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: (response: any) => {
                        this.formHelpers.validateForm(this.subscriptionFormGroup, FormValidationService.readError(response).formMessages);
                    }
                }
            );
        } else {
            this.formHelpers.validateForm(this.subscriptionFormGroup);
        }
    }

    /**
     * Check has selected plan config
     */
    hasSelectedConfig(type?: keyof SelectedPackages): boolean {

        if (!type) {
            return !!Object.keys(this.selectedPackageConfigs.addons || {}).length ||
                !!Object.keys(this.selectedPackageConfigs.plan || {}).length;
        }

        const entity: any = this.selectedPackageConfigs[type] || null;
        return !!entity && !!(Object.keys(entity).length || 0);
    }

    /**
     * Manage result tab
     */
    private onChanges(): void {
        this.subscriptionFormGroup.valueChanges.pipe(debounceTime(350)).subscribe(() => {
            const planID = this.subscriptionFormGroup.get(this.subscriptionControlNames.Plan).value;
            const addonIDs: number[] = this.subscriptionFormGroup.get(this.subscriptionControlNames.Addons).value;

            let plan: Plan = null;
            let addons: Addon[] = [];
            let addonConfig = {};

            if (!!planID) {
                plan = this.plans.find(item => item.planID === planID);
            }

            if (!!addonIDs && !!addonIDs.length) {
                addons = this.addons.filter(item => addonIDs.indexOf(item.addonID) > -1);
            }

            if (!!addons && !!addons.length) {
                addons.forEach(addon => {
                    addonConfig = {
                        ...addonConfig,
                        ...addon.config || {}
                    };
                });
            }

            this.selectedPackageConfigs = {
                plan: Utils.lodash.get(plan, 'config', {}) || {},
                addons: addonConfig
            };
        });
    }

    /**
     * Return plans
     */
    private getPlans(): Promise<any> {
        this.plansPending = true;

        return this.plansService.getPlans().then(response => {
            this.plans = response.plans;
            this.plansPending = false;

            return response;
        });
    }

    /**
     * Return addons
     */
    private getAddons(): Promise<any> {
        this.addonsPending = true;

        return this.addonsService.getAddons().then(response => {
            this.addons = response.addons;
            this.addonsPending = false;

            return response;
        });
    }
}
