import {Injectable} from '@angular/core';
import {ModelAbstract} from '~/src/app/services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import Utils from '~/src/app/core/utils';
import {Plan, PlansResponse} from '~/src/app/modules/administration/plans.interfaces';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';

@Injectable({
    providedIn: 'root'
})
export class PlansService extends ModelAbstract {
    private _planCollection: BehaviorSubject<Plan[]> = new BehaviorSubject<Plan[]>([]);
    _loaderRef: MatDialogRef<DialogLoaderComponent>;

    constructor(
        public http: HttpClient,
        public dialog: MatDialog,
        public openModal: OpenModalService
    ) {
        super(http, dialog);

        this.apiLink = '/api/plan';
    }

    get plans(): Observable<Plan[]> {
        return this._planCollection.asObservable();
    }

    /**
     * Get plans
     * @param filters
     * @return {Promise<PlansResponse>}
     */
    getPlans(filters?) {
        return this.getAll(filters).then((response: PlansResponse) => {
            this._planCollection.next(
                response.plans.map(plan => {
                    plan.type = 'plan';
                    plan.description = {
                        short: Utils.lodash.truncate(plan.description, {length: 75}),
                        long: plan.description,
                        raw: plan.description
                    };

                    plan.description.long = plan.description.long.replace(/\n/g, '<br />');
                    plan.description.long = plan.description.long.replace(/↵/g, '<br />');
                    plan.config = typeof plan.config == 'string' ? JSON.parse(plan.config) : plan.config;
                    plan.config = Utils.lodash.get(plan, 'config', '{}') || {};

                    for (const configName of PartnersService.getAllConfigName()) {
                        plan.config[configName] = Utils.lodash.get(plan, `config.${configName}`, null);
                    }

                    return plan;
                })
            );

            this.hideLoader();

            return response;
        }).catch(error => {
            this._planCollection.next([]);

            this.hideLoader();

            return error;
        });
    }

    /**
     * Edit plan
     * @param {Plan} plan
     * @return {Promise<any>}
     */
    editPlan(plan: Plan) {
        delete plan.type;
        plan.description = plan.description.raw;

        return this.editWithPost(plan.planID, Utils.obj2fd(plan));
    }

    /**
     * Sync plans from Zoho
     * @return {Promise<Object>}
     */
    syncPlans() {
        return this.http.get(this.apiLink + '/synchronize', Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }

    /**
     * Sync plans from Zoho
     * @return {Promise<Object>}
     */ 
    refreshAuthtoken() {
        return this.http.get(this.apiLink + '/zoho-oauth?code=refresh', Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
