import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface DialogData {
    title: string;
}

@Component({
    selector: 'smd-dialog-progress',
    templateUrl: './dialog-progress.component.html',
    styleUrls: ['./dialog-progress.component.scss']
})
export class DialogProgressComponent implements OnInit {

    progressValue = 5;
    title = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: DialogData,
        public _dialogRef: MatDialogRef<DialogProgressComponent>
    ) {
        this.title = this._data.title;
    }

    ngOnInit() {
        this._dialogRef.updateSize('550px');
    }

}
