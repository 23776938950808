import { Component, OnInit, Input } from "@angular/core";
import Utils from "~/src/app/core/utils";
import { FALLBACK_IMAGE } from "~/src/app/configs/configs";
import { ACTION_TYPE_OPTIONS } from "~/src/app/modules/social-media-post/gmb/gmb.constants";
import { find, get } from "lodash";
import { LanguageService } from "~/src/app/services/language.service";

@Component({
    selector: "smd-gmb-post-preview",
    templateUrl: "./gmb-post-preview.component.html",
    styleUrls: ["./gmb-post-preview.component.scss"],
})
export class GmbPostPreviewComponent implements OnInit {
    @Input() post: any;
    @Input() hasLinkShare: boolean;
    @Input() linkShareImage: string;

    public termsIsVisible: boolean = false;

    constructor(public language: LanguageService) {}

    ngOnInit(): void {
        if (this.post.medias?.length > 1) {
            const mediaList = this.post.medias;
            this.post.medias = [];
            this.post.medias.push(mediaList[0]);
        }
    }

    public getHost(url) {
        const l = (document.createElement("a").host = url);
        return url ? (l.hostname || "").replace("www.", "").trim() : "";
    }

    public getDate() {
        return (
            (this.post && this.post.activeFrom
                ? this.post.activeFrom
                : Utils.get(this.post, "activeFromInput", null)) ||
            "Posted Jul 30, 2020"
        );
    }

    public getButtonLabel() {
        let button;

        if (this.getOption("callToAction.actionType")) {
            button = find(ACTION_TYPE_OPTIONS, {
                value: this.getOption("callToAction.actionType"),
            });
        }

        return button ? this.language.getLine(button.labelKey) : null;
    }

    public getOption(optionPath) {
        return get(this.post, "gmbOptions." + optionPath, null);
    }

    get summary() {
        let summary = [this.post.headline, this.post.mainCopy].join("<br>");

        // remove mark tags (used for highlighting)
        summary = summary.replace(/<mark>/g, "").replace(/<\/mark>/g, "");
        // replace shift+enter(/n) with a single line break character
        summary = summary.replace(/ ?<\/p>\n<p> ?/g, "<br />");

        // replace \r\n with <br> and replace \r with <br>
        //summary = summary.replace(/\r\n/g, "<br>").replace(/\r/g, "<br>");

        // replace brs with a specific br
        //summary = summary.replace(/<br ?\/?>/g, "<br>");

        // remove multiple line breaks
        //summary = summary.replace(/<br>(<br>)+/g, "<br><br>");

        // if summary starts with br, remove it
        summary = summary.replace(/^<br>/, "");

        return summary;
    }

    get schedule() {
        const s = this.getOption("event.schedule");
        let timeString;
        if (s) {
            timeString =
                s.startDate.day +
                "/" +
                s.startDate.month +
                "/" +
                s.startDate.year;
            if ("startTime" in s) {
                timeString +=
                    " " + s.startTime.hours + ":" + s.startTime.minutes;
            }
            timeString +=
                " - " +
                s.endDate.day +
                "/" +
                s.endDate.month +
                "/" +
                s.endDate.year;

            if ("endTime" in s) {
                timeString += " " + s.endTime.hours + ":" + s.endTime.minutes;
            }
        }

        return timeString;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }
}
