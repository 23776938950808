import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {HashtagManagerPageComponent} from './pages/hashtag-manager-page/hashtag-manager-page.component';
import {HashtagManagerComponent} from './components/hashtag-manager/hashtag-manager.component';
import {HashtagCollectionManagerComponent} from './components/hashtag-collection-manager/hashtag-collection-manager.component';
import {HashtagModalFormComponent} from './components/hashtag-modal-form/hashtag-modal-form.component';
import {HashtagCollectionModalFormComponent} from './components/hashtag-collection-modal-form/hashtag-collection-modal-form.component';
import {HashtagSelectComponent} from './components/hashtag-select/hashtag-select.component';
import {HashtagCollectionManagerModalComponent} from './components/hashtag-collection-manager-modal/hashtag-collection-manager-modal.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    declarations: [HashtagManagerPageComponent, HashtagManagerComponent, HashtagCollectionManagerComponent, HashtagModalFormComponent, HashtagCollectionModalFormComponent, HashtagSelectComponent, HashtagCollectionManagerModalComponent],
    entryComponents: [HashtagModalFormComponent, HashtagCollectionModalFormComponent, HashtagCollectionManagerModalComponent]
})
export class HashtagModule {
}
