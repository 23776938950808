import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filesize",
    pure: true,
})
export class FileSizePipe implements PipeTransform {
    constructor () {};
    
    transform(value: number): any {
        let bytes: number = value ? value : 0;
        let exp: number = (Math.log(bytes) / Math.log(1024)) | 0;
        let result: string = (bytes / Math.pow(1024, exp)).toFixed(2);

        result = result.replace(/\.(0)+$/, "");

        return result + " " + (exp == 0 ? "B" : "KMGTPEZY"[exp - 1] + "B");
    }
}
