import { Component, OnInit } from '@angular/core';
import { KeyValue } from "@angular/common";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Router} from '@angular/router';
import { ComponentAbstract } from "~/src/app/services/component.abstract";
import { PLACEHOLDER_IMAGE } from '~/src/app/configs/configs';
import { CollectionListConfig, CollectionListSelectionChange, TableBodyConfig, TableHeadConfig } from '~/src/app/core/components/collection-list/collection-list.interfaces';
import { Report, ReportSharedUser, ReportSocialSites } from '../dashboards.interface';
import { PaginationController, PaginationOptions } from '~/src/app/services/pagination.controller';
import { ReportsService } from '../reports.service';
import { DataTableHelpersService } from '~/src/app/core/services/data-table-helpers.service';
import { Debounce } from '~/src/app/services/helpers';
import { DialogConfirmComponent } from '~/src/app/components/dialog-confirm/dialog-confirm.component';
import { LanguageService } from '~/src/app/services/language.service';
import { DialogLoaderComponent } from '~/src/app/components/dialog-loader/dialog-loader.component';
import { OpenModalService } from '../../social-media-post/open-modal.service';
import { DialogSuccessComponent } from '~/src/app/components/dialog-success/dialog-success.component';
import { DialogErrorComponent } from '~/src/app/components/dialog-error/dialog-error.component';
import { ShareObjectComponent } from '../../analytics/share-reports/share-object.component';

@Component({
  selector: 'smd-my-reports',
  templateUrl: './my-reports.component.html',
  styleUrls: ['./my-reports.component.scss'],
  providers: [DataTableHelpersService],
})
export class MyReportsComponent extends ComponentAbstract implements OnInit {
    myReports: Report[] = [];
    sharedReports: Report[] = [];

    renderHelper = {
        noItemsSelected: true,
        moreThanTenItemsSelected: false,
        tooltips: {
            share: 'report.actions.disabled',
            delete: 'report.actions.disabled',
        }
    }

    loader: MatDialogRef<DialogLoaderComponent>;

    public paginationControllerMy: PaginationController = new PaginationController();
    public paginationControllerShared: PaginationController = new PaginationController();

    // Column data for select
    columnVisibilityMy: any = {
        reportName: {
            name: "myreports.reportName",
            visible: true,
        },
        dateRange: {
            name: "myreports.dateRange",
            visible: true,
        },
        socialPlatforms: {
            name: "myreports.socialPlatforms",
            visible: true,
        },
        sharedWith: {
            name: "myreports.sharedWith",
            visible: true,
        },
        updatedAt: {
            name: "myreports.updatedAt",
            visible: true,
        },
        updatedBy: {
            name: "myreports.updatedBy",
            visible: true,
        },
        createdAt: {
            name: "myreports.createdAt",
            visible: true,
        },
        createdBy: {
            name: "myreports.createdBy",
            visible: true,
        },
    };
    defaultColumnsMy: any[] = Object.keys(this.columnVisibilityMy);

    columnVisibilityShared: any = {
        reportName: {
            name: "myreports.reportName",
            visible: true,
        },
        dateRange: {
            name: "myreports.dateRange",
            visible: true,
        },
        socialPlatforms: {
            name: "myreports.socialPlatforms",
            visible: true,
        },
        sharedWith: {
            name: "myreports.sharedWith",
            visible: true,
        },
        updatedAt: {
            name: "myreports.updatedAt",
            visible: true,
        },
        updatedBy: {
            name: "myreports.updatedBy",
            visible: true,
        },
        createdAt: {
            name: "myreports.createdAt",
            visible: true,
        },
        createdBy: {
            name: "myreports.createdBy",
            visible: true,
        },
    };
    defaultColumnsShared: any[] = Object.keys(this.columnVisibilityShared);

    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
    }

    tableHeadConfigMy: TableHeadConfig[] = [
        {
            nameKey: "myreports.reportName",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["reportName"].visible,
        },
        {
            nameKey: "myreports.dateRange",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["dateRange"].visible,
        },
        {
            nameKey: "myreports.socialPlatforms",
            enable: () => this.columnVisibilityMy["socialPlatforms"].visible,
        },
        {
            nameKey: "myreports.sharedWith",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["sharedWith"].visible,
        },
        {
            nameKey: "myreports.updatedAt",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["updatedAt"].visible,
        },
        {
            nameKey: "myreports.updatedBy",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["updatedBy"].visible,
        },
        {
            nameKey: "myreports.createdAt",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["createdAt"].visible,
        },
        {
            nameKey: "myreports.createdBy",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityMy["createdBy"].visible,
        },
        {
            nameKey: "post.manager.actions",
            onlyDesktop: true,
            onlyMobile: true,
        },
    ];

    tableHeadConfigShared: TableHeadConfig[] = [
        {
            nameKey: "myreports.reportName",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["reportName"].visible,
        },
        {
            nameKey: "myreports.dateRange",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["dateRange"].visible,
        },
        {
            nameKey: "myreports.socialPlatforms",
            enable: () => this.columnVisibilityShared["socialPlatforms"].visible,
        },
        {
            nameKey: "myreports.sharedWith",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["sharedWith"].visible,
        },
        {
            nameKey: "myreports.updatedAt",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["updatedAt"].visible,
        },
        {
            nameKey: "myreports.updatedBy",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["updatedBy"].visible,
        },
        {
            nameKey: "myreports.createdAt",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["createdAt"].visible,
        },
        {
            nameKey: "myreports.createdBy",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibilityShared["createdBy"].visible,
        },
        {
            nameKey: "post.manager.actions",
            onlyDesktop: true,
            onlyMobile: true,
        },
    ];

    tableBodyConfigMy: TableBodyConfig[] = [
        {
            selector: "reportName",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["reportName"].visible,
        },
        {
            selector: "dateRange",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["dateRange"].visible,
        },
        {
            selector: "socialPlatforms",
            enable: () => this.columnVisibilityMy["socialPlatforms"].visible,
        },
        {
            selector: "sharedWith",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["sharedWith"].visible,
        },
        {
            selector: "updatedAt",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["updatedAt"].visible,
        },
        {
            selector: "updatedBy",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["updatedBy"].visible,
        },
        {
            selector: "createdAt",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["createdAt"].visible,
        },
        {
            selector: "createdBy",
            onlyDesktop: true,
            enable: () => this.columnVisibilityMy["createdBy"].visible,
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
        },
    ];
    tableBodyConfigShared: TableBodyConfig[] = [
        {
            selector: "reportName",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["reportName"].visible,
        },
        {
            selector: "dateRange",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["dateRange"].visible,
        },
        {
            selector: "socialPlatforms",
            enable: () => this.columnVisibilityShared["socialPlatforms"].visible,
        },
        {
            selector: "sharedWith",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["sharedWith"].visible,
        },
        {
            selector: "updatedAt",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["updatedAt"].visible,
        },
        {
            selector: "updatedBy",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["updatedBy"].visible,
        },
        {
            selector: "createdAt",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["createdAt"].visible,
        },
        {
            selector: "createdBy",
            onlyDesktop: true,
            enable: () => this.columnVisibilityShared["createdBy"].visible,
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
        },
    ];

    collectionConfigShared: CollectionListConfig = {
        allowSelection: false
    };

    placeholderImg = PLACEHOLDER_IMAGE;

    constructor(
        private reportService: ReportsService,
        public dataTableHelper: DataTableHelpersService,
        public dialog: MatDialog,
        private openModal: OpenModalService,
        private router: Router,
    ) { 
        super();
    }

    ngOnInit(): void {
        this.getMyReports();
        this.getSharedReports();

        this.paginationControllerMy.onSetPagination.subscribe(
            ({ offset, limit }: { offset: number; limit: number }) => {
                this.filters["offset"] = offset;
                this.filters["limit"] = limit;

                this.getMyReports();
            }
        );

        this.paginationControllerShared.onSetPagination.subscribe(
            ({ offset, limit }: { offset: number; limit: number }) => {
                this.filters["offset"] = offset;
                this.filters["limit"] = limit;

                this.getSharedReports();
            }
        );
    }

    getMyReports() {
        const options = {
            status: 'active',
            type: 'my',
            offset: this.filters['offset'],
            limit: this.filters['limit'],
            order: JSON.stringify({createdAt: 'desc'}),
        };

        this.myReports = [];

        this.reportService.getReports(options).then(response => {
            this.myReports = response.reports;
            
            this.myReports.forEach(report => {
                report = this.alterReport(report);
            });

            this.paginationControllerMy.paginationOptions.length = response.countMy;
            this.dataTableHelper.clearSelection();
        });
    }

    getSharedReports() {
        const options = {
            status: 'active',
            type: 'shared',
            offset: this.filters['offset'],
            limit: this.filters['limit'],
            order: JSON.stringify({createdAt: 'desc'}),
        };

        this.sharedReports = [];

        this.reportService.getReports(options).then(response => {
            this.sharedReports = response.reports;
            
            this.sharedReports.forEach(report => {
                report = this.alterReport(report);
            });

            this.paginationControllerShared.paginationOptions.length = response.countShared;
            this.dataTableHelper.clearSelection();
        });
    }

    alterReport(baseReport: Report): Report {
        let report = baseReport;
        if (report.image === null) report.image = this.placeholderImg;

        if (report.sharedWith?.length > 5) {
            report.renderHelper = {
                sharedWithTooltip: this.getTooltipForArrayWithName(report.sharedWith)?.text,
                sharedWithMoreNumber: this.getTooltipForArrayWithName(report.sharedWith)?.number,
            }; 
        }

        if (report.socialSites?.length > 5) {
            report.renderHelper = {
                sharedWithTooltip: this.getTooltipForArrayWithName(report.socialSites)?.text,
                sharedWithMoreNumber: this.getTooltipForArrayWithName(report.socialSites)?.number,
            }; 
        }

        return report;
    }

    @Debounce(100)
    updateRenderData() {
        this.renderHelper.noItemsSelected = this.noItemsSelected();
        this.renderHelper.moreThanTenItemsSelected = this.moreThanTenItemsSelected();

        this.renderHelper.tooltips.share = this.getHoverMessage('share');
        this.renderHelper.tooltips.delete = this.getHoverMessage('delete');
    }

    noItemsSelected(): boolean {
        return this.dataTableHelper.getSelectedItems().length === 0;
    }

    moreThanTenItemsSelected(): boolean {
        return this.dataTableHelper.getSelectedItems().length > 10;
    }

    getHoverMessage(action: string): string {
        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return "report.actions.disabled";
        }
        if (this.dataTableHelper.getSelectedItems().length > 10) {
            return "report.actions.tooManyItems";
        }

        return "report.action." + action + ".tooltip";
    }

    toggleColumnsMy(event): void {
        Object.keys(this.columnVisibilityMy).forEach((column) => {
            this.columnVisibilityMy[column].visible =
                event.value.includes(column);
        });
    }

    toggleColumnsShared(event): void {
        Object.keys(this.columnVisibilityShared).forEach((column) => {
            this.columnVisibilityShared[column].visible =
                event.value.includes(column);
        });
    }

    editReport(report: Report) {
        const url = '/analytics/' + report.organizationType + '-organization/' + report.reportType + '?reportID=' + report.reportID + '&action=edit';
        this.router.navigateByUrl(url);
    }

    shareReport(report: Report) {
        const array = [];
        array.push({
            id: report.reportID,
            name: report.name
        });

        this.share(array);
    }

    deleteReport(report: Report) {
        const reportName = JSON.stringify(report.name)
        
        this.delete({reportIDs: [report.reportID], reportNames: reportName, multiple: false});
    }

    archiveReport(report: Report) {
        console.log('Archiving (does this word even exist?!) report ' + report.reportID);
    }

    cloneReport(report: Report) {
        const url = '/analytics/' + report.organizationType + '-organization/' + report.reportType + '?reportID=' + report.reportID + '&action=clone';
        this.router.navigateByUrl(url);
    }

    viewReport(report: Report) {
        const url = '/analytics/' + report.organizationType + '-organization/' + report.reportType + '?reportID=' + report.reportID + '&action=view';
        this.router.navigateByUrl(url);
    }

    selectAction(event: MouseEvent, value: string): void {
        event.preventDefault();

        if (this.dataTableHelper.getSelectedItems().length > 0) {
            const names = [];
            const ids = [];
            const reports = this.dataTableHelper.getSelectedItems();
            const reportsToShare = [];
            for (const report of reports) {
                names.push(report.name);
                ids.push(report.reportID);

                reportsToShare.push({
                    id: report.reportID,
                    name: report.name
                });
            }
            
            if (value === 'delete') {
                this.delete({reportIDs: ids, reportNames: names.join(', '), multiple: true});
                
            } else if (value === 'share') {
                this.share(reportsToShare);
            }
        }
    }

    delete(object: {reportIDs: Array<number>, reportNames: string, multiple: boolean}) {
        const confirmMessage = object.multiple ? "analytics.report.delete.multiple.confirm.message" : "analytics.report.delete.confirm.message";
        const confirmText = object.multiple ? "analytics.report.delete.multiple.confirm" : "analytics.report.delete.confirm";

        this.dialog
            .open(DialogConfirmComponent, {
                data: {
                    message: LanguageService.getLine(
                        confirmMessage,
                        { reportName: object.reportNames }
                    ),
                    yesButtonText: LanguageService.getLine(
                        confirmText
                    ),
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    //this.loader = this.openModal.loader(DialogLoaderComponent);

                    this.reportService.deleteReports(object.reportIDs).then(response => {
                        this.paginationControllerMy.paginationOptions.pageIndex = 0;
                        this.paginationControllerMy.setPagination(
                            this.paginationControllerMy.paginationOptions
                        );
                        
                        if (response.result === true) {
                            this.openModal.successModal(DialogSuccessComponent, {message: response.message})
                        } else {
                            this.openModal.errorModal(DialogErrorComponent, {message: response.message})
                        }
                    });

                    //this.loader.close();
                }
            });
    }

    share(objects: Array<{reportIDs: number, reportNames:string}>) {
        this.openModal.shareDashboards(ShareObjectComponent, {
            data: {
                objectData: objects,
                objectType: 'report',
                afterSuccessSave: () => {
                    this.getMyReports();
                }
            } as any, // anyscript!!!! (just a fast fix for deployment - fix this later pls)
        });
    }

    getTooltipForArrayWithName(object: ReportSharedUser[] | ReportSocialSites[], ): {text: string, number: number} {
        const tooltipArray = [];
        for (let item of object.slice(5, object.length)) {
            tooltipArray.push(item.name);
        }

        return {
            text: tooltipArray.join(', '),
            number: tooltipArray.length
        };
    }

    selectionChange(event: CollectionListSelectionChange) {
        this.dataTableHelper.setSelectedItems(event.selectedItems, event.itemCollection, 'reportID');
        
        this.updateRenderData();
    }
}
