import { Component, Inject, OnInit } from "@angular/core";
import { GmbApiLocationService } from "~/src/app/modules/social-media-post/gmb/gmb-api-location.service";
import { SocialUserDialogComponent } from "~/src/app/modules/users/social-user-dialog/social-user-dialog.component";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import {
    DialogData,
    ViewList,
} from "~/src/app/modules/users/social-user-dialog/social-user-dialog.interface";
import { map } from "lodash";
import { OpenModalService } from "../../social-media-post/open-modal.service";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogWarnComponent } from "~/src/app/components/dialog-warn/dialog-warn.component";

@Component({
    selector: "smd-gmb-locations-dialog",
    templateUrl: "../social-user-dialog/social-user-dialog.component.html",
    styleUrls: [
        "../Facebook-dialog.component.scss",
        "./gmb-locations-dialog.component.scss",
    ],
})
export class GmbLocationsDialogComponent
    extends SocialUserDialogComponent
    implements OnInit
{
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<GmbLocationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public service: GmbApiLocationService,
        private openModal: OpenModalService
    ) {
        super(dialog, dialogRef, data);
    }

    protected getAction(): Promise<any[]> {
        return this.service.get();
    }

    protected async saveAction() {
        const _loader = this.openModal.loader(DialogLoaderComponent);

        try {
            const saveResponse = await this.service.save(
                this.selectedList,
                this.data.organizationID
            );
            _loader.close();
            this.dialog
                .open(DialogSuccessComponent, {
                    data: {
                        message: "Location successfully created!",
                    },
                })
                .afterClosed()
                .subscribe(() => {
                    this.dialogRef.close(true);
                });
        } catch (e) {
            _loader.close();
            this.dialog.open(DialogWarnComponent, {
                data: {
                    message: "Location create failed!",
                },
            });
        }

        return;
    }

    protected itemsProcessor(data: any) {
        const hasLocations = !!data.length && data.map(item => (item.location)).filter(Boolean).length > 0

        this.list = hasLocations ? data.map(item => item.location) : [];
        this.viewList = hasLocations
            ? map(data, (item) => {
                  return { name: item.location.title, id: item.account + '/' + item.location.name };
              })
            : [];
    }
}
