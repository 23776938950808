import AbstractPreviewAdapter from "~/src/app/modules/posts/post-previews/abstract-preview-adapter";
import { StringSupport } from "~/src/app/core/helper/string-support";

export class FacebookAdapter extends AbstractPreviewAdapter {
    adapt(value, isLinkShare = null): string {
        value = super.adapt(value);

        value = StringSupport.trimLineBreaks(value, false);

        // remove every empty newline
        this.contentLines = this.contentLines.map((str) => str.replace(/ ?\r ?/g, ""));
        this.contentLines = this.contentLines.filter((str) => str !== "<p></p>");
        this.contentLines = this.contentLines.filter((str) => str !== "<p> </p>"); // somehow these exist too, so we remove paragraphs containing a single space

        this.contentLines = this.contentLines.map((string: string, index) => {
            const nextItem = this.contentLines[index + 1] || "";
            const secondNextItem = this.contentLines[index + 2] || "";
            // ha a következő és az azt következő sor tartalmaz br elemet,
            // akkor az aktuális sor p tag-hez adok egy double-line osztályt
            if (nextItem.includes("<br") && secondNextItem.includes("<br")) {
                string = string.replace("<p>", '<p class="double-line">');
            }

            // remove line break elements
            string = string.replace(this.lineBreakRegex, "");

            return string;
        });

        value = this.contentLines.join("");

        return value;
    }
}

export const FacebookPreviewAdapter = new FacebookAdapter();
