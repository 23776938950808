import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UsersController} from '~/src/app/modules/users/users/users.component';
import {NotifyService} from '~/src/app/services/notify.service';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {TeamMembersInterface} from '~/src/app/modules/dashboard-fix/team-members-online-offline/team-members-online-offline.interface';

const AUTO_GETTER_PERIOD = 120000;

@Component({
    selector: 'smd-team-members-online-offline',
    templateUrl: './team-members-online-offline.component.html',
    styleUrls: ['./team-members-online-offline.component.scss']
})
export class TeamMembersOnlineOfflineComponent implements OnInit, OnDestroy {
    @Output() initialized = new EventEmitter();

    teamMembers: TeamMembersInterface[] = [];
    pagination = new PaginationController();
    usersLoading = false;
    autoGetternterval;

    constructor(
        private usersController: UsersController
    ) {
        this.autoGetter();
        this.paginationInit();
    }

    ngOnInit() {
        this.initialized.emit();
    }

    ngOnDestroy(): void {
        clearInterval(this.autoGetternterval);
    }

    private paginationInit() {
        this.pagination.onSetPagination.subscribe((result) => {
            this.usersController.filters['offset'] = result.offset;
            this.usersController.filters['limit'] = result.limit;

            this.initUsers();
        });
    }

    private autoGetter() {
        this.initUsers();

        this.autoGetternterval = setInterval(() => {
            this.initUsers(false);
        }, AUTO_GETTER_PERIOD);
    }

    private initUsers(showLoader: boolean = true) {
        if (showLoader) {
            this.usersLoading = true;
        }

        this.usersController.filters['order'] = this.usersController.getOrderFormat('lastActiveDate-desc');
        this.usersController.getItems(
            (response: any) => {
                this.pagination.paginationOptions.length = response.count;
                this.teamMembers = response.users;

                if (showLoader) {
                    this.usersLoading = false;
                }
            },
            error => {
                NotifyService.error(FormValidationService.readError(error).message, '');

                if (showLoader) {
                    this.usersLoading = false;
                }
            }
        );
    }
}
