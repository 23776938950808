import {Component, Input, OnInit} from '@angular/core';
import {UserRolesService} from '~/src/app/services/user-roles.service';
import {ResourceService} from '~/src/app/directives/resource-checker/resource.service';
import {Category} from '~/src/app/modules/posts/template.interface';
import {CategoriesController} from '~/src/app/modules/posts/categories.component';
import Utils from '~/src/app/core/utils';
import {LanguageService} from '~/src/app/services/language.service';
import {FormGroup} from '~/node_modules/@angular/forms';

@Component({
    selector: 'smd-category-select',
    templateUrl: './category-select.component.html',
    styleUrls: ['./category-select.component.scss'],
    providers: [
        CategoriesController
    ]
})
export class CategorySelectComponent implements OnInit {
    @Input() isAdminMode = false;
    @Input() formGroup: FormGroup;
    @Input() selectControlName: string;
    @Input() errorMessage: string;
    @Input() hintMessage: string;
    @Input() addTag = true;
    @Input() multiple = false;
    @Input() model = undefined;
    @Input() isModal = true;
    @Input() bindLabel: keyof Category = 'name';
    @Input() bindValue: keyof Category = 'name';
    initialValue;
    hasResourceToCreate = false;
    categories: Category[] = [];
    categoryGetPending = false;

    constructor(
        private userService: UserRolesService,
        private categoryService: CategoriesController,
        public language: LanguageService,
        public resourceService: ResourceService
    ) {
        if (this.resourceService.checkResource('post.category.create')) {
            this.hasResourceToCreate = true;
        }
    }

    ngOnInit(): void {

        // set initial value
        if (this.formGroup && this.selectControlName) {
            this.initialValue = this.formGroup.get(this.selectControlName).value;
        }

        this.getCategories();
    }

    getCategories() {
        // fetch generic tags on administration
        if (this.isAdminMode) {
            this.categoryService.setFilters([
                {
                    filterName: 'type',
                    filterValue: 'generic'
                },
            ]);
        }

        this.categoryGetPending = true;
        this.categoryService.getItems(({categories}) => {
            this.categories = Utils.lodash.orderBy(categories, [category => category.name.toLowerCase()]);
            this.categories.forEach(function(element, index, theArray) {
                element.name = element.name.trim()
            });

            // set initial value on select element
            if (this.initialValue && this.formGroup && this.selectControlName) {
                this.formGroup.get(this.selectControlName).setValue(this.initialValue);
            }

            this.categoryGetPending = false;
        }, () => {
            this.categoryGetPending = false;
        });
    }

}
