import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {Injectable} from '@angular/core';
import {
    IRSSSavedPostData,
    IRSSSavedPostListResponse,
    IRSSSavePostResponse
} from '~/src/app/modules/rss/types/rss-saved-post.interface';
import {RSS_SETTINGS_SAVED_POST_API_RESOURCE} from '~/src/app/modules/rss/rss.constants';
import {RssSavedPostModel} from '~/src/app/modules/rss/models/rss-saved-post.model';
import {RssFeedService} from '~/src/app/modules/rss/services/rss-feed.service';
import Utils from '~/src/app/core/utils';

@Injectable({providedIn: 'root'})
export class RssSavedPostService {
    constructor(
        public backend: BackendService,
        private rssFeedService: RssFeedService
    ) {}

    /**
     * Save RSS blog post
     * @param {number} feedID
     * @param {IRSSSavedPostData} content
     * @return {Promise<any>}
     */
    savePost(feedID: number, content: IRSSSavedPostData): Promise<IRSSSavePostResponse<RssSavedPostModel>> {
        const _content = JSON.stringify(content);
        return this.backend.post(RSS_SETTINGS_SAVED_POST_API_RESOURCE, {feedID, content: _content})
            .then((response: IRSSSavePostResponse) => {
                response.savedPost = new RssSavedPostModel(response.savedPost);
                return Promise.resolve(response as IRSSSavePostResponse<RssSavedPostModel>);
            });
    }

    /**
     * List saved RSS blog posts
     * @param {RequestParams} params
     * @return {Promise<IRSSSavedPostListResponse<RssSavedPostModel>>}
     */
    listSavedPosts(params: RequestParams = {}): Promise<IRSSSavedPostListResponse<RssSavedPostModel>> {
        return this.backend.get(RSS_SETTINGS_SAVED_POST_API_RESOURCE, params)
            .then((response: IRSSSavedPostListResponse) => {
                response.savedPosts = (response.savedPosts || []).map(post => new RssSavedPostModel(post));
                const feedIDs: number[] = Utils.lodash.uniq(response.savedPosts.map(post => post.feedID));

                if (feedIDs.length) {
                    return this.rssFeedService.listFeeds({feedID: feedIDs})
                        .then(feedResponse => {
                            const savedPosts: RssSavedPostModel[] = response.savedPosts as RssSavedPostModel[];

                            response.savedPosts = savedPosts.map(post => {
                                post.feedModel = feedResponse.feeds.find(feed => feed.feedID === post.feedID);
                                return post;
                            });

                            return Promise.resolve(response as IRSSSavedPostListResponse<RssSavedPostModel>);
                        });
                }

                return Promise.resolve(response as IRSSSavedPostListResponse<RssSavedPostModel>);
            });
    }

    /**
     * Deleted saved RSS post
     * @param {number} postID
     * @return {Promise<any>}
     */
    deleteSavedPost(postID: number) {
        return this.backend.delete(`${RSS_SETTINGS_SAVED_POST_API_RESOURCE}/${postID}`);
    }
}
