import {debounceTime} from 'rxjs/operators';
import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {AnalyticsService} from '~/src/app/modules/analytics/analytics.service';
import {Dashboard} from '~/src/app/modules/analytics/dashboard.interfaces';
import {OrganisationService} from '~/src/app/modules/users/organizations/organisation.service';
import {ArraySupport} from '~/src/app/core/helper/array-support';
import {UserMinimal, UsersResponse} from '~/src/app/modules/users/users-resource';
import {LanguageService} from '~/src/app/services/language.service';
import {Icons} from '~/src/app/helpers/icons';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {
    DashboardConnectionType,
    DashboardPermissionsList,
    DashboardShareDialogData,
    ShareDashboardEditMode,
    ShareSettingsControlNames
} from '~/src/app/modules/analytics/share-dashboard/share-dashboard';
import Utils from '~/src/app/core/utils';
import {LoggedUser} from '~/src/app/services/logged-user';
import {ShareDashboardSettingsComponent} from '~/src/app/modules/analytics/share-dashboard/share-dashboard-settings/share-dashboard-settings.component';
import {Debounce} from '~/src/app/services/helpers';
import {ResourceService} from '~/src/app/directives/resource-checker/resource.service';
import { UsersService } from '../../users/users/users.service';

interface CreateDashboardShareSettingsInitialValue {
    [DashboardPermissionsList.View]?: (string | number)[];
    [DashboardPermissionsList.Update]?: (string | number)[];
    [DashboardPermissionsList.Delete]?: (string | number)[];
}

@Component({
    selector: 'smd-share-dashboard',
    templateUrl: './share-dashboard.component.html',
    styleUrls: ['./share-dashboard.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class ShareDashboardComponent implements OnInit {

    @ViewChildren(ShareDashboardSettingsComponent) shareSettingsComponents: QueryList<ShareDashboardSettingsComponent>;
    @ViewChildren(MatTab) matTabComponents: QueryList<MatTab>;
    @ViewChild(MatTabGroup, {read: MatTabGroup}) matTabGroupComponent: MatTabGroup;

    userFilterFormControl: FormControl = new FormControl('');
    shareSettingsFormGroup: FormGroup;
    shareSettingsControlNames = ShareSettingsControlNames;

    dashboards: Dashboard[] = [];
    mainDashboard: Dashboard;
    defaultDashboardIDs: number[] = [];

    users: UserMinimal[] = [];

    organizationTree: any[];
    filteredOrganizations: any[];

    // selected organizations for add permission to all child organizations
    selectedOrganizations: {
        dashboardRead: string[];
        dashboardUpdate: string[];
        dashboardDelete: string[];
    } = {
        dashboardRead: [],
        dashboardUpdate: [],
        dashboardDelete: []
    };

    // dashboard share settings for view by dashboardID
    dashboardShareSettings: {
        [p: string]: {
            formGroup: FormGroup,
            dashboardName: string,
            isDefaultDashboard: boolean
        }
    } = {};

    // icon settings
    icons = Icons;

    // get dashboards in progress
    dashboardPending = false;

    // dashboard share settings edit modes
    editModes = ShareDashboardEditMode;

    // dashboard share settings edit mode
    editMode = this.editModes.ApplyToAll;

    // currently selected tab index
    selectedTabIndex = 0;

    myUser = LoggedUser.getUser();

    constructor(
        public language: LanguageService,
        public formHelpers: FormHelpersService,
        private _componentHelpers: ComponentHelpers,
        private _analyticsService: AnalyticsService,
        private _organizationsService: OrganisationService,
        private _usersService: UsersService,
        private _dialogRef: MatDialogRef<ShareDashboardComponent>,
        private _resourceService: ResourceService,
        @Inject(MAT_DIALOG_DATA) private _dialogData: DashboardShareDialogData,
    ) {
        this._initializeDatas();
        this._initShareSettingsForm();
    }

    ngOnInit() {
    }

    /**
     * Dashboard share settings save button click
     *
     * @param {MouseEvent} event
     */
    saveAction(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.shareSettingsFormGroup.invalid) {
            this.formHelpers.validateForm(this.shareSettingsFormGroup);
        } else {
            this._saveShareSettings();
        }
    }

    /**
     * Edit mode radio button click event
     *
     * @param {MouseEvent} event
     * @param {ShareDashboardEditMode} editMode
     */
    editModeRadioClick(event: MouseEvent, editMode: ShareDashboardEditMode) {
        this.editMode = editMode;

        if (this.isApplyToAllEditMode()) {
            this._moveToMainDashboardTab();
        }
    }

    /**
     * Get dashboard by dashboardID
     *
     * @param {number} dashboardID
     * @returns {Dashboard}
     */
    getDashboard(dashboardID: number | string): Dashboard {
        if (!dashboardID) {
            return null;
        }

        const dashboard = this.dashboards.find(dash => dash.dashboardID === Number(dashboardID));

        return dashboard ? dashboard : null;
    }

    /**
     * Return selected more than one dashboard
     *
     * @returns {boolean}
     */
    isMultiDashboardSettings(): boolean {
        return (this.shareSettingsFormGroup.get(this.shareSettingsControlNames.Dashboards).value || []).length > 1;
    }

    /**
     * Return edit mode is apply to all
     *
     * @returns {boolean}
     */
    isApplyToAllEditMode(): boolean {
        return this.editMode === ShareDashboardEditMode.ApplyToAll;
    }

    /**
     * Return required use edit mode setting
     *
     * @returns {boolean}
     */
    useEditModeSetting(): boolean {
        return this.isMultiDashboardSettings();
    }

    /**
     * Init share settings form
     *
     * @private
     */
    private _initShareSettingsForm(): void {

        // create share settings form group
        this.shareSettingsFormGroup = new FormGroup({
            [this.shareSettingsControlNames.Dashboards]: new FormControl([], [
                Validators.required
            ]),
        });

        // subscribe selected dashboard change event
        this.shareSettingsFormGroup.get(this.shareSettingsControlNames.Dashboards)
            .valueChanges.pipe(
            debounceTime(350))
            .subscribe(value => {
                this._selectedDashboardChangeEvent(value);
            });

        // init core form helpers
        this.formHelpers.formInit(this.shareSettingsFormGroup);
    }

    /**
     * Get dashboard share settings form group
     *
     * @param {CreateDashboardShareSettingsInitialValue} initialValues
     * @returns {FormGroup}
     * @private
     */
    private _getDashboardShareSettingsFormGroup(initialValues: CreateDashboardShareSettingsInitialValue = {}) {

        initialValues = {
            [DashboardPermissionsList.View]: [],
            [DashboardPermissionsList.Update]: [],
            [DashboardPermissionsList.Delete]: [],
            ...initialValues
        };

        return new FormGroup({
            [this.shareSettingsControlNames.DashboardRead]: new FormControl(initialValues[DashboardPermissionsList.View]),
            [this.shareSettingsControlNames.DashboardUpdate]: new FormControl(initialValues[DashboardPermissionsList.Update]),
            [this.shareSettingsControlNames.DashboardDelete]: new FormControl(initialValues[DashboardPermissionsList.Delete]),
        });
    }

    /**
     * Get initial dashboardIDs
     *
     * @returns {number[]}
     * @private
     */
    private _getInitialDashboardIDs(): number[] {
        return ArraySupport.itemConvertToArray(
            Utils.get<DashboardShareDialogData>(this._dialogData, ['defaultDashboardIDs'], [])
        );
    }

    /**
     * Initialize datas
     *
     * @returns {Promise<any>}
     * @private
     */
    private _initializeDatas(): Promise<any> {

        // get initial dashboardIDs
        this.defaultDashboardIDs = this._getInitialDashboardIDs();

        // get users, dashboards by type, organizations
        return Promise.all([
            this._getUsers(),
            this._getDashboards(),
            this._getOrganizations()
        ]).then((responses) => {
            const [userData, dashboardData, organizationData] = responses;

            // initialize organization tree
            this.organizationTree = this._initializeOrganizationTree(organizationData.organizationTree);

            // filter organization by search value change
            this.userFilterFormControl
                .valueChanges.pipe(
                debounceTime(350))
                .subscribe(() => {
                    this.filteredOrganizations = this._filterOrganizations();
                });

            this.filteredOrganizations = this._filterOrganizations();

            // set main dashboard
            this.mainDashboard = this.getDashboard(this.defaultDashboardIDs[0]);

            // initialize dashboard share settings
            if (this.mainDashboard) {
                this._initializeDashboardShareSettings(this.mainDashboard);
            }

            return Promise.resolve(responses);
        });
    }

    /**
     * Initialize dashboard share settings
     *
     * @param {Dashboard} dashboard
     * @private
     */
    private _initializeDashboardShareSettings(dashboard: Dashboard): void {

        if (dashboard.permissions && typeof dashboard.permissions !== 'string') {
            const initialValues: CreateDashboardShareSettingsInitialValue = {};

            for (const userID in dashboard.permissions) {
                if (userID in dashboard.permissions) {
                    const permissions = dashboard.permissions[userID];

                    permissions.forEach(permission => {
                        initialValues[permission] = [
                            ...(initialValues[permission] || []),
                            Number(userID)
                        ];
                    });
                }
            }

            this.dashboardShareSettings[dashboard.dashboardID] = {
                formGroup: this._getDashboardShareSettingsFormGroup(initialValues),
                dashboardName: dashboard.name,
                isDefaultDashboard: (this.mainDashboard?.dashboardID === dashboard.dashboardID)
            };
        }
    }

    /**
     * Remove dashboard share setting by dashboardID
     *
     * @param {number} dashboardID
     * @private
     */
    private _removeDashboardShareSettings(dashboardID: number): void {
        const hasShareSettingsDashboardIDs = this._getHasShareSettingsDashboardIDs();

        if (dashboardID && hasShareSettingsDashboardIDs.includes(dashboardID)) {
            delete this.dashboardShareSettings[dashboardID];
        }
    }

    /**
     * Change selected dashboards event
     *
     * @param {number[]} selectedDashboardIDs
     * @private
     */
    @Debounce()
    private _selectedDashboardChangeEvent(selectedDashboardIDs: number[]) {
        const hasShareSettingsDashboardIDs = this._getHasShareSettingsDashboardIDs();

        // init share settings for dashboard which hasn't share settings
        for (const selectedDashboardID of selectedDashboardIDs) {
            if (!hasShareSettingsDashboardIDs.includes(selectedDashboardID)) {
                const selectedDashboard = this.getDashboard(selectedDashboardID);

                this._initializeDashboardShareSettings(selectedDashboard);
            }
        }

        // get unselected dashboardIDs
        const unSelectedDashboardIDs = Utils.lodash.difference(
            this._getHasShareSettingsDashboardIDs(),
            selectedDashboardIDs
        );

        // remove share settings of unselected dashboards
        for (const unSelectedDashboardID of unSelectedDashboardIDs) {
            this._removeDashboardShareSettings(unSelectedDashboardID);
        }

        setTimeout(() => {
            this._moveToMainDashboardTab();
        }, 0);
    }

    /**
     * Navigate tab group to main dashboard tab
     *
     * @private
     */
    private _moveToMainDashboardTab(): void {
        const mainDashboardID = this.mainDashboard?.dashboardID;
        const mainDashboardTabIndex = this.matTabComponents
            .toArray()
            .findIndex((tab) => {
                return Number(tab.ariaLabel) === mainDashboardID;
            });

        if (mainDashboardTabIndex > -1 && this.matTabGroupComponent) {
            this.matTabGroupComponent.selectedIndex = mainDashboardTabIndex;
        }
    }

    /**
     * Save dashboard share settings
     *
     * @private
     */
    private _saveShareSettings(): void {

        const requestData = {};
        const individually = () => {
            this.shareSettingsComponents.forEach(shareSettings => {
                const data = shareSettings.getDatasForRequest();

                requestData[data.dashboardID] = data.permissions;
            });
        };
        const applyToAll = () => {
            const mainDashboardShareSettingsPermissions = this.shareSettingsComponents
                .toArray()
                .find(component => Number(component?.dashboardID) === this.mainDashboard?.dashboardID)
                .getDatasForRequest().permissions;

            this.shareSettingsComponents.forEach(shareSettings => {

                requestData[shareSettings.dashboardID] = mainDashboardShareSettingsPermissions;

            });
        };

        if (this.useEditModeSetting()) {
            if (this.isApplyToAllEditMode()) {
                applyToAll();
            } else {
                individually();
            }
        } else {
            individually();
        }

        this._componentHelpers.startApiAction(
            () => {
                return this._analyticsService.setDashboardShare(requestData);
            },
            {
                successMessageKey: 'analytics.dashboard.shareSettings.save.success',
                failedMessageKey: 'analytics.dashboard.shareSettings.save.error',
                afterSuccessAction: () => {

                    if (this._dialogData && this._dialogData.afterSuccessSave) {
                        this._dialogRef.afterClosed().subscribe(() => {
                            this._dialogData.afterSuccessSave();
                        });
                    }

                    this._dialogRef.close();
                }
            }
        );
    }

    /**
     * Initialize organizations
     *
     * Összeállítom a HTML-ben használt adatstruktúrát
     *
     * @param originOrganizationTree
     * @returns {any[]}
     * @private
     */
    private _initializeOrganizationTree(originOrganizationTree: any): any[] {
        let result = [];

        for (const organizationID in originOrganizationTree) {
            if (organizationID in originOrganizationTree) {
                const organization = originOrganizationTree[organizationID];
                const orgID = organization.organizationID;

                const org: any = {
                    id: parseInt(orgID),
                    name: organization.name,
                    users: this.users.filter(user => {
                        const isNotMyUser = parseInt(user.userID) != this.myUser.userID;
                        const allowViewAnalytics = user.resources.find(resource => resource == 'analytics.dashboard.dashboards');
                        const usersMainOrg = user.organizationID == orgID;
                        return isNotMyUser && allowViewAnalytics && usersMainOrg;
                    }).map(user => {
                        const allowedPermissions = [
                            ShareSettingsControlNames.DashboardRead
                        ];

                        const allowModifyAnalytics = user.resources.find(resource => resource == 'analytics.dashboard.modify');
                        const allowDeleteAnalytics = user.resources.find(resource => resource == 'analytics.dashboard.delete');

                        if (allowModifyAnalytics) {
                            allowedPermissions.push(ShareSettingsControlNames.DashboardUpdate);
                        }

                        if (allowDeleteAnalytics) {
                            allowedPermissions.push(ShareSettingsControlNames.DashboardDelete);
                        }

                        return {
                            id: parseInt(user.userID),
                            name: `${user.name}`,
                            allowedPermissions: allowedPermissions
                        };
                    })
                };

                org['userIDs'] = org.users.map(user => user.id);

                if ('children' in organization) {
                    result = ArraySupport.arrayMerge(
                        result,
                        this._initializeOrganizationTree(organization.children)
                    );
                }

                result.push(org);
            }
        }

        return result;
    }

    /**
     * Get dashboardIDs which has share settings
     *
     * @returns {number[]}
     * @private
     */
    private _getHasShareSettingsDashboardIDs(): number[] {
        return Object.keys(this.dashboardShareSettings).map(id => Number(id));
    }

    /**
     * Get dashboards
     *
     * @returns {Promise<any>}
     * @private
     */
    private _getDashboards(): Promise<any> {
        const onComplete = () => {
            this.dashboardPending = false;
        };

        // csak azokat a dashboard-kat kérjük le, ami az adott felhasználóhoz tartozik
        const filters = {
            type: DashboardConnectionType.Owner
        };

        this.dashboardPending = true;

        return this._analyticsService.getAll(filters).then((response: { dashboards: Dashboard[] }) => {

            const initialDashboardIDs = this._getInitialDashboardIDs();

            this.dashboards = response.dashboards.map(dash => {

                // disable the main dashboard option in select
                if (initialDashboardIDs.length && dash.dashboardID === initialDashboardIDs[0]) {
                    dash['disabled'] = true;
                }

                return dash;
            });

            this.shareSettingsFormGroup.get(this.shareSettingsControlNames.Dashboards).setValue(
                this.defaultDashboardIDs
            );

            onComplete();

            return Promise.resolve(response);
        }).catch((error) => {
            onComplete();

            return Promise.reject(error);
        });
    }

    /**
     * Get organization tree
     *
     * @returns {Promise<any>}
     * @private
     */
    private _getOrganizations(): Promise<any> {
        return this._organizationsService.getOrganization();
    }

    /**
     * Get users
     *
     * @returns {Promise<UsersResponse>}
     * @private
     */
    private _getUsers(): Promise<UsersResponse> {
        return this._usersService.getRelatedUsers({minimal: true}).then(response => {
            this.users = response.users;

            return Promise.resolve(response);
        });
    }

    /**
     * Filter organizations by search value
     *
     * @param {string} searchValue
     * @returns {any[]}
     * @private
     */
    private _filterOrganizations() {
        const searchValue = (this.userFilterFormControl.value || '').toLowerCase().trim();

        const foundedUser = (userName) => {
            return userName.toLowerCase().includes(searchValue);
        };

        const result = Utils.lodash.cloneDeep(
            this.organizationTree.filter(organization => {
                return organization.users.filter((user: any) => {
                    return foundedUser(user.name);
                }).length;
            })
        );

        return result.map(organization => {

            if (searchValue !== '') {
                organization.users = organization.users.map(user => {

                    if (foundedUser(user.name)) {
                        user.name = `<strong>${user.name}</strong>`;
                    }

                    return user;
                });
            }

            return organization;
        });
    }
}
