export enum DashboardConnectionType {
    Owner = 'owner',
    User = 'user'
}

export interface DashboardShareDialogData {
    defaultDashboardIDs: number | number[];
    afterSuccessSave?: () => void;
}

export enum DashboardPermissionsList {
    View = 'view',
    Update = 'edit',
    Delete = 'delete'
}

export enum ShareDashboardEditMode {
    ApplyToAll = 'applyToAll',
    EditIndividually = 'editIndividually'
}

export enum ShareSettingsControlNames {
    Dashboards = 'dashboardIDs',
    DashboardRead = 'dashboardRead',
    DashboardUpdate = 'dashboardUpdate',
    DashboardDelete = 'dashboardDelete',
    Permissions = 'permissions',
}

export interface DashboardPermissionsConfig {
    [p: string]: DashboardPermissionsList[];
}

export interface DashboardShareSettingsPermissions {
    // p: userID
    [p: string]: DashboardPermissionsList[]
}

export interface DashboardShareSettingsSaveRequestData {
    dashboardID: number;
    permissions: DashboardShareSettingsPermissions;
}
