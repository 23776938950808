import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormHelpersService } from "~/src/app/core/services/form-helpers";
import { LanguageService } from "~/src/app/services/language.service";
import { each } from "lodash";
import { Subject, Subscription } from "rxjs";

@Component({
    selector: "smd-abstract-form-group",
    templateUrl: "./abstract.component.html",
    styleUrls: ["./abstract.component.scss"],
})
export class AbstractFormGroupComponent implements OnInit {
    formGroup: FormGroup;
    isDisabled: boolean = false;
    subscriptions: Subscription[] = [];

    @Input() validateTrigger: Subject<any>;

    constructor(
        public formHelpers: FormHelpersService,
        public language: LanguageService,
        public fb: FormBuilder
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        each(this.subscriptions, (subscription) => subscription.unsubscribe());
    }

    getForm(): FormGroup {
        return this.formGroup;
    }

    toggleDisable(toggle) {
        this.isDisabled = toggle;

        each(this.formGroup.controls, (control) => {
            toggle ? control.disable() : control.enable();
        });
    }
}
