export const dashboardGranularities = [
  {
    label: "Hourly",
    value: "hour",
  },
  {
    label: "Daily",
    value: "day",
  },
  {
    label: "Weekly",
    value: "week",
  },
  {
    label: "Monthly",
    value: "month",
  },
];
