import {ComponentAbstract} from '../../services/component.abstract';
import {SocialSiteService} from './social-site.service';
import {Injectable} from '@angular/core';

export class SocialSiteComponent extends ComponentAbstract {

    constructor(
        public service: SocialSiteService
    ) {
        super();

        delete this.filters['limit'];
    }

}

@Injectable({
    providedIn: 'root'
})
export class SocialSiteController extends SocialSiteComponent {

    getSocialSitesInProgress = false;

    constructor(public service: SocialSiteService) {
        super(service);
    }

    setFilters(filters: { filterName: string; filterValue: string | number | boolean }[]): void {
        super.setFilters(filters, false);
    }

    beforeGetItems(): void {
        super.beforeGetItems();

        this.getSocialSitesInProgress = true;
    }

    afterGetItems(): void {
        super.afterGetItems();

        this.getSocialSitesInProgress = false;
    }
}
