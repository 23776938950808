import {Component, ElementRef, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MyErrorStateMatcher} from '~/src/app/services/helpers';
import {LanguageService} from '~/src/app/services/language.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IHashtagModalFormDialogData} from '~/src/app/modules/hashtag/components/hashtag-modal-form/hashtag-modal-form.interface';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {HashtagService} from '~/src/app/modules/hashtag/hashtag.service';
import {MatInput} from '@angular/material/input';
import {IHashtagFormData} from '~/src/app/modules/hashtag/hashtag.interface';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {MessageBoxComponent} from '~/src/app/core/components/message-box/message-box.component';
import {TINYMCE_DELIMITER_HASHTAG} from '~/src/app/configs/configs';

@Component({
    selector: 'smd-hashtag-modal-form',
    templateUrl: './hashtag-modal-form.component.html',
    styleUrls: ['./hashtag-modal-form.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class HashtagModalFormComponent implements OnInit {

    @ViewChildren('tagName', {read: MatInput}) inputs: QueryList<MatInput>;

    // error matcher
    errorMatcher = new MyErrorStateMatcher();

    // is edit mode
    isEditMode = false;

    controlNames = {
        Tag: 'tag',
        Organization: 'organizationID'
    };

    formGroup: FormGroup = new FormGroup({
        [this.controlNames.Tag]: new FormArray([
            new FormControl((this.data && this.data.item) ? this.data.item.tag.replace(TINYMCE_DELIMITER_HASHTAG, '') : null)
        ]),
        [this.controlNames.Organization]: new FormControl((this.data && this.data.item) ? this.data.item.organizationID : null, [Validators.required])
    });

    constructor(
        public formHelpers: FormHelpersService,
        public componentHelpers: ComponentHelpers,
        public language: LanguageService,
        private dialogRef: MatDialogRef<HashtagModalFormComponent>,
        private hashtagService: HashtagService,
        private scrollTo: ScrollToService,
        @Inject(MAT_DIALOG_DATA) public data: IHashtagModalFormDialogData
    ) {
        this.formHelpers.formInit(this.formGroup);

        if (this.data && this.data.item) {
            this.isEditMode = true;
        }
    }

    ngOnInit() {
    }

    createHashtag(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.formGroup.valid) {
            const data: IHashtagFormData = {
                tag: this.formGroup.value[this.controlNames.Tag].filter(tag => !!tag).map(tag => tag.replace(/ /g, '')),
                organizationID: this.formGroup.value[this.controlNames.Organization]
            };

            if (data.tag.length) {
                this.componentHelpers.startApiAction(
                    () => {
                        return this.hashtagService.create(data);
                    }, {
                        successMessageKey: 'post.hashtag.success.modal.create',
                        failedMessageKey: 'post.hashtag.error.modal.failedToCreate',
                        afterSuccessAction: (response: any) => {
                            if (!!this.data.successAction) {
                                this.dialogRef.afterClosed().subscribe(() => {
                                    this.data.successAction(response);
                                });
                            }

                            this.dialogRef.close();
                        },
                        afterFailedAction: (response: any) => {
                            this.formHelpers.validateForm(this.formGroup, FormValidationService.readError(response).formMessages);
                        }
                    },
                );
            }
        } else {
            this.formHelpers.validateForm(this.formGroup);
        }
    }

    modifyHashtag(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.formGroup.valid) {
            const data = {
                ...this.formGroup.value,
                tag: this.formGroup.value[this.controlNames.Tag][0]
            };

            this.componentHelpers.startApiAction(
                () => {
                    return this.hashtagService.update(this.data.item.tagID, data);
                }, {
                    successMessageKey: 'post.hashtag.success.modal.edit',
                    failedMessageKey: 'post.hashtag.error.modal.failedToEdit',
                    afterSuccessAction: (response: any) => {
                        if (!!this.data.successAction) {
                            this.dialogRef.afterClosed().subscribe(() => {
                                this.data.successAction(response);
                            });
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: (response: any) => {
                        this.formHelpers.validateForm(this.formGroup, FormValidationService.readError(response).formMessages);
                    }
                },
            );
        } else {
            this.formHelpers.validateForm(this.formGroup);
        }
    }

    /**
     * @param {MouseEvent} event
     */
    addNewTagControl(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        (this.formGroup.get(this.controlNames.Tag) as FormArray).push(new FormControl(null));
        setTimeout(() => this.inputs.last.focus(), 0);
    }

    /**
     * @param {MouseEvent} event
     * @param {number} index
     */
    removeTagControl(event: MouseEvent, index: number) {
        event.preventDefault();
        event.stopPropagation();

        (this.formGroup.get(this.controlNames.Tag) as FormArray).removeAt(index);
    }

    messageBoxInitialized(elementRef: ElementRef<MessageBoxComponent>): void {
        this.scrollTo.scrollTo({
            target: elementRef
        });
    }
}
