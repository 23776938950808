import { Injectable } from "@angular/core";
import { BackendService } from "~/src/app/core/backend.service";
import { BulkUploadManager } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager";
import { BulkImportPostTemplateItemModel } from "~/src/app/modules/posts/bulk-import-post-template-item.model";
import { BulkImportPostItemModel } from "~/src/app/modules/posts/bulk-import-post-item.model";
import { BulkImportItemModel } from "~/src/app/modules/posts/bulk-import-item.model";
import { POST_STATUS_APPROVED, POST_STATUS_WAITING_FOR_APPROVAL, POST_TEMPLATE_STATUS_ACTIVE, POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL } from "../../../social-media-post/social-media-post.constant";

@Injectable({
    providedIn: "root",
})
export class BulkUploadManagerService {
    /**
     * Import items max number in a request
     *
     * @type {number}
     */
    static BULK_CREATE_REQUEST_LIMIT = 30;

    constructor(private _backendService: BackendService) {
        this._backendService.disableTimeout();
    }

    /**
     * Send post bulk create request
     *
     * @param {BulkUploadManager.PostBulkCreateData} data
     * @returns {Promise<null>}
     */
    postBulkCreate(data: BulkUploadManager.PostBulkCreateData, isApproved = false) {
        data.status = isApproved ? POST_STATUS_APPROVED : POST_STATUS_WAITING_FOR_APPROVAL;

        return this._bulkCreate(data, "post");
    }

    /**
     * Send template bulk create request
     *
     * @param {BulkUploadManager.TemplateBulkCreateData} data
     * @returns {Promise<null>}
     */
    templateBulkCreate(data: BulkUploadManager.TemplateBulkCreateData, isApproved = false) {
        data.status = isApproved ? POST_TEMPLATE_STATUS_ACTIVE : POST_TEMPLATE_STATUS_WAITING_FOR_APPROVAL;

        return this._bulkCreate(data, "template");
    }

    /**
     * Extract templates zip file
     *
     * @param {File} file
     * @param {BulkUploadManager.ExtractFileConfig} config
     * @returns {Promise<BulkUploadManager.ExtractFileResponse>}
     */
    extractTemplatesFile(
        file: File,
        config: BulkUploadManager.ExtractFileConfig
    ) {
        config = {
            ...config,
            type: "template",
        };

        return this._extractFile(file, config).then((response) =>
            this._extractResponsePrepare(response, config).toTemplate()
        );
    }

    /**
     * Extract posts zip file
     *
     * @param {File} file
     * @param {BulkUploadManager.ExtractFileConfig} config
     * @returns {Promise<BulkUploadManager.ExtractFileResponse>}
     */
    extractPostsFile(file: File, config: BulkUploadManager.ExtractFileConfig) {
        config = {
            ...config,
            type: "post",
        };

        return this._extractFile(file, config).then((response) =>
            this._extractResponsePrepare(response, config).toPost()
        );
    }

    /**
     * Download file from API
     *
     * @param {string} apiUrl
     * @returns {Promise<BulkUploadManager.FileResponse>}
     */
    downloadFile(apiUrl: string) {
        if (apiUrl && apiUrl.includes("/api/")) {
            apiUrl = apiUrl.replace("/api", "");
        }

        return this._backendService.get<BulkUploadManager.FileResponse>(apiUrl);
    }

    /**
     * Download sample files
     *
     * @param {"post" | "template"} type
     * @param {"general" | "twitter"} platform
     * @return {Promise<null>}
     */
    downloadSample(
        type: "post" | "template",
        platform: "general" | "instagram" | "twitter" | "gmb" = "general"
    ) {
        const fileNames = {
            post: {
                general: "bulk_post_upload_sample_web_20200206.zip",
                twitter: "bulk_post_upload_twitter_sample_web_20200406.zip",
                instagram: "bulk_post_upload_sample_instagram_20210420.zip",
                gmb: "bulk_post_upload_sample_gmb_20210825.zip",
            },
            template: {
                general: "bulk_template_upload_sample_web_20200206.zip",
                twitter: "bulk_template_upload_twitter_sample_web_20200406.zip",
                instagram: "bulk_template_upload_sample_instagram_20210420.zip",
                gmb: "bulk_template_upload_sample_gmb_20210830.zip",
            },
        };

        return this._backendService.downloadSample(fileNames[type][platform]);
    }

    /**
     * Prepare extract response
     *
     * @param {BulkUploadManager.ExtractFileResponse} response
     * @returns {{toTemplate(): Promise<BulkUploadManager.ExtractFileResponse>; toPost(): Promise<BulkUploadManager.ExtractFileResponse>} | Promise<BulkUploadManager.ExtractFileResponse>}
     * @private
     */
    _extractResponsePrepare(
        response: BulkUploadManager.ExtractFileResponse,
        config: BulkUploadManager.ExtractFileConfig
    ) {
        const initItem = (entity, item) => {
            let result: BulkImportItemModel;

            if (entity === "post") {
                result = new BulkImportPostItemModel(item);

                (<BulkImportPostItemModel>result).setValue(
                    "socialSiteIDs",
                    config.socialSiteIDs
                );
            }

            if (entity === "template") {
                result = new BulkImportPostTemplateItemModel(item);
            }

            result.setValue("socialType", config.socialType);
            result.generateShortProperties();

            (<BulkImportPostTemplateItemModel>result).setValue(
                "organizationIDs",
                config.organizationIDs
            );

            return result;
        };
        const prepare = (entity: "post" | "template") => {
            let isFailed = false;

            response.items = Object.keys(response.items).map((postID) => {
                const values = response.items[postID];

                const result = initItem(entity, values);

                if (result.hasError) {
                    isFailed = true;
                }

                return result;
            });

            response.isFailed = isFailed;

            return Promise.resolve(response);
        };

        return {
            toPost() {
                return prepare("post");
            },
            toTemplate() {
                return prepare("template");
            },
        };
    }

    /**
     * Send file to extract API resource
     *
     * @param {File} file
     * @param {BulkUploadManager.ExtractFileConfig} config
     * @returns {Promise<BulkUploadManager.ExtractFileResponse>}
     */
    _extractFile(file: File, config: BulkUploadManager.ExtractFileConfig) {
        return this._backendService.post<BulkUploadManager.ExtractFileResponse>(
            "/media/bulk-upload-extract",
            {
                file,
                ...config,
            }
        );
    }

    /**
     * Send bulk create API request
     *
     * @param {Object} data
     * @param {"post" | "template"} type
     * @returns {Promise<null>}
     * @private
     */
    _bulkCreate(data: Object, type: "post" | "template") {
        const apiUrls = {
            post: "/post/bulk-create",
            template: "/post/template/bulk-create",
        };

        return this._backendService.post(apiUrls[type], data);
    }
}
