import {Injectable} from '@angular/core';
import {MediaTagsComponent} from '~/src/app/modules/documents/media-tags.component';
import {MediaTagsService} from '~/src/app/modules/documents/media-tags.service';

@Injectable({providedIn: 'root'})
export class MediaTagsController extends MediaTagsComponent {
    constructor(service: MediaTagsService) {
        super(service);
    }
}
