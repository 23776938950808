import {Pipe, PipeTransform} from '@angular/core';
import Utils from '~/src/app/core/utils';

@Pipe({
    name: 'startcase'
})
export class StartCasePipe implements PipeTransform {

    transform(value: string): any {
        return Utils.lodash.startCase(value || '');
    }

}
