import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {PostTemplateModule} from '~/src/app/modules/posts/post-template/post-template.module';
import {PostTemplateFiltersModule} from '~/src/app/modules/posts/post-template-filters/post-template-filters.module';
import {PostTemplateChooserComponent} from '~/src/app/modules/social-media-post/post-template-chooser/post-template-chooser.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule,
        PostTemplateModule,
        PostTemplateFiltersModule
    ],
    declarations: [
        PostTemplateChooserComponent
    ],
    exports: [
        PostTemplateChooserComponent
    ]
})
export class PostTemplateChooserModule {}
