import {BackendService, RequestParams} from '~/src/app/core/backend.service';
import {Injectable} from '@angular/core';
import {RSS_SETTINGS_FEED_API_RESOURCE} from '~/src/app/modules/rss/rss.constants';
import {RssFeedModel} from '~/src/app/modules/rss/models/rss-feed.model';
import {
    IListFeedResponse,
    IRSSChannel,
    IRSSFeed,
    IRssFeedContentResponse,
    IRSSFeedFormData
} from '~/src/app/modules/rss/types/rss-feed.interface';
import {RssBlogPostModel} from '~/src/app/modules/rss/models/rss-blog-post.model';
import {OrganizationService} from '~/src/app/modules/users/organizations/organization.service';
import {OrganizationModel} from '~/src/app/modules/users/organizations/organization.model';

@Injectable({
    providedIn: 'root'
})
export class RssFeedService {

    constructor(
        private backend: BackendService,
        private organizationService: OrganizationService
    ) {}

    /**
     * List RSS feed items
     * @param {RequestParams} params
     * @return {Promise<IListFeedResponse<RssFeedModel>>}
     */
    async listFeeds(params: RequestParams = {}): Promise<IListFeedResponse<RssFeedModel>> {
        const organizationList = await this.organizationService.getList();
        return this.backend.get(RSS_SETTINGS_FEED_API_RESOURCE, params)
            .then((response: IListFeedResponse) => {
                response.feeds = this.prepareFeedItems(response.feeds, organizationList.organizations);
                return Promise.resolve(response) as Promise<IListFeedResponse<RssFeedModel>>;
            });
    }

    /**
     * Load RSS feed content
     * @description Ezen a végponton fontos, hogy a response-ban az rss kulcs alatt ugyanannyi channel érkezzen vissza,
     * ahány url-t küldtem, ugyanabban a sorrendben. Csak így tudom  beállítani a feedID-t a channel objektumhoz.
     * @param {RssFeedModel[]} feeds
     * @return {Promise<IRssFeedContentResponse<RssBlogPostModel>>}
     */
    loadRssChannel(feeds: RssFeedModel[]): Promise<IRssFeedContentResponse<RssBlogPostModel>> {
        const feedUrl = feeds.map(feed => feed.feedUrl);
        return this.backend.post(`${RSS_SETTINGS_FEED_API_RESOURCE}/load`, {feedUrl}, null, {disableTimeOut: true})
            .then((response: IRssFeedContentResponse) => {

                response.rss = (response.rss || []).map((channel, index) => {
                    channel.feedID = feeds[index].feedID;
                    channel.item = (channel.item || []).map(item => new RssBlogPostModel(item, channel as IRSSChannel<RssBlogPostModel>));

                    return channel;
                });

                return Promise.resolve(response) as Promise<IRssFeedContentResponse<RssBlogPostModel>>;
            });
    }

    /**
     * Create multiple RSS feed item
     * @param {IRSSFeedFormData[]} data
     * @return {Promise<any>}
     */
    create(data: IRSSFeedFormData[]): Promise<any> {
        return this.backend.post(`${RSS_SETTINGS_FEED_API_RESOURCE}/bulk-create`, {feedData: data});
    }

    /**
     * Modify RSS feed item
     * @param {number} feedID
     * @param {IRSSFeedFormData} data
     * @return {Promise<any>}
     */
    modify(feedID: number, data: IRSSFeedFormData): Promise<any> {
        return this.backend.put(`${RSS_SETTINGS_FEED_API_RESOURCE}/${feedID}`, data);
    }

    /**
     * Remove multiple RSS feed item
     * @param {number[]} feedIDs
     * @return {Promise<any>}
     */
    remove(feedIDs: number[]): Promise<any> {
        return this.backend.post(`${RSS_SETTINGS_FEED_API_RESOURCE}/bulk-delete`, {feedID: feedIDs});
    }

    /**
     * Instantiates RSS feed models
     * @param {IRSSFeed[]} items
     * @param {OrganizationModel[]} organizations
     * @return {RssFeedModel[]}
     */
    private prepareFeedItems(items: IRSSFeed[] = [], organizations: OrganizationModel[] = []): RssFeedModel[] {
        return items.map(item => {
            const organizationIDs: number[] = (item.organizationID as string[] || []).map(id => Number(id));

            return new RssFeedModel(
                item, null, organizations.filter(organization => organizationIDs.includes(organization.organizationID))
            );
        });
    }
}
