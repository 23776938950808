import {Injectable} from '@angular/core';
import Utils from '~/src/app/core/utils';
import {PartnerPermission} from '~/src/app/shared/services/partner-config/partner-config.interfaces';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import {
    LINK_SHORTEN_TYPE_BITLY,
    LINK_SHORTEN_TYPE_DISABLED
} from '~/src/app/modules/social-media-post/link-shortening.constant';

@Injectable({providedIn: 'root'})
export class PartnerConfigService {
    private _config;

    constructor(
        private partnerService: PartnersService
    ) {
        try {
            this.setConfig();
        } catch (e) {
            console.log({e});
        }
    }

    /**
     * Check the config is enabled for the current partner
     * @param {string} configName
     * @return {boolean}
     */
    hasConfig(configName: PartnerPermission): boolean {
        if (!this.getConfig()) {
            console.warn('Partner config not defined!');
            return true;
        }

        return !!Utils.lodash.get(this._config, configName, true);
    }

    /**
     * Get partner config
     * @return {object}
     */
    getConfig() {
        return this._config;
    }

    setConfig() {
        let defaultConfig: any = this.partnerService.getPartnerConfig('all');

        if (!defaultConfig) {
            defaultConfig = {};

            for (const configName of PartnersService.getAllConfigName()) {
                defaultConfig[configName] = true;
            }
        }

        this._config = defaultConfig;
    }

    /**
     * Check link shorten service allowed
     * @return {boolean}
     */
    allowLinkShorten(): boolean {
        if (!this.hasConfig(PartnerPermissions.LinkShortening)) {
            return false;
        }

        if (!this.partnerService.getMyPartnerData().linkShorten) {
            return false;
        }

        if (this.partnerService.getMyPartnerData().linkShorten === LINK_SHORTEN_TYPE_DISABLED) {
            return false;
        }

        if (this.partnerService.getMyPartnerData().linkShorten === LINK_SHORTEN_TYPE_BITLY) {
            return !!(Utils.lodash.get(this.partnerService.getMyPartnerData(), 'bitlyToken', null) || null);
        }

        return true;
    }
}
