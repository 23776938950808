import {StaffCountRange} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.interfaces';

export const StaffCountRanges: StaffCountRange[] = [
    {
        name: 'Self employed',
        value: 'SIZE_1'
    },
    {
        name: '1-10',
        value: 'SIZE_2_TO_10'
    },
    {
        name: '11-50',
        value: 'SIZE_11_TO_50'
    },
    {
        name: '51-200',
        value: 'SIZE_51_TO_200'
    },
    {
        name: '201-500',
        value: 'SIZE_201_TO_500'
    },
    {
        name: '501-1,000',
        value: 'SIZE_501_TO_1000'
    },
    {
        name: '1,001-5,000',
        value: 'SIZE_1001_TO_5000'
    },
    {
        name: '5,001-10,000',
        value: 'SIZE_5001_TO_10000'
    },
    {
        name: '10,001+',
        value: 'SIZE_10001_OR_MORE'
    },
];

export enum LinkedInTargetingRequestParamNames {
    degrees = 'degrees',
    fieldsOfStudy = 'fieldsOfStudy',
    industries = 'industries',
    interfaceLocales = 'interfaceLocales',
    jobFunctions = 'jobFunctions',
    locations = 'locations',
    schools = 'schools',
    seniorities = 'seniorities',
    staffCountRanges = 'staffCountRanges'
}
