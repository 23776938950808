import {Component, Inject} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IRSSManagerModalData} from '~/src/app/modules/rss/types/rss-feed.interface';

@Component({
    selector: 'smd-rss-manager-page',
    templateUrl: './rss-manager-page.component.html',
    styleUrls: ['./rss-manager-page.component.scss']
})
export class RssManagerPageComponent {
    showFeed: boolean = true;
    showGroup: boolean = false;

    constructor(
        public languageService: LanguageService,
        @Inject(MAT_DIALOG_DATA) private data: IRSSManagerModalData
    ) {}

    onItemsChange() {
        if (this.data && this.data.onItemsChange) {
            this.data.onItemsChange();
        }
    }

    onTabChanged(event) {
        this.showFeed = event.index === 0;
        this.showGroup = event.index === 1;
    }
}
