import { IRelatedUser } from "./../../../users/users/users.interface";
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SmdFileInterface } from "~/src/app/services/file.class";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FALLBACK_IMAGE, SOCIAL_PLATFORMS_DEFAULT_CONFIG } from "~/src/app/configs/configs";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { SocialPlatform } from "~/src/app/shared/types/social-platform-type";
import { PostInterface } from "~/src/app/modules/posts/post-actions";
import { cloneDeep, forEach, has, truncate } from "lodash";
import { PostPreviewDialogData } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.interfaces";
import { AutofeedPageService } from "~/src/app/modules/autofeed/autofeed-service.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import Utils from "~/src/app/core/utils";
import { FacebookPreviewAdapter } from "~/src/app/modules/posts/post-previews/facebook-preview-adapter";
import AbstractPreviewAdapter from "~/src/app/modules/posts/post-previews/abstract-preview-adapter";
import { LinkedInPreviewAdapter } from "~/src/app/modules/posts/post-previews/linkedin-preview-adapter";
import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
} from "~/src/app/core/constants";
import { TwitterPreviewAdapter } from "~/src/app/modules/posts/post-previews/twitter-preview-adapter";
import { InstagramPreviewAdapter } from "~/src/app/modules/posts/post-previews/instagram-preview-adapter";
import {
    PostFormControlNames,
    POST_STATUS_FAILED,
    POST_STATUS_PARTIALLY_FAILED,
    POST_STATUS_POSTED,
} from "~/src/app/modules/social-media-post/social-media-post.constant";
import { IMentionItem, IMentionViewItem } from "~/src/app/modules/social-media-post/post-skeleton/mention-list/mention-list";
import { MENTION_TAG_ELEMENT_CLASS } from "~/src/app/modules/mention/mention.contant";
import { SocialMediaPlatformConfig, socialSiteAppearance } from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { SocialMediaPlatform } from "~/src/app/modules/social-media-post/social-media-post.interface";
import { Gallery, GalleryRef } from "ng-gallery";
import { LanguageService } from "~/src/app/services/language.service";
import { CustomEvents, LoggedUserInterface } from "~/src/app/services/helpers";
import { LoggedUser } from "~/src/app/services/logged-user";
import { Subject } from "~/node_modules/rxjs";
import { MatExpansionPanel } from "@angular/material/expansion";
import { PostTemplateManagerComponent } from "~/src/app/modules/social-media-post/post-template-manager/post-template-manager.component";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PostManagerComponent } from "~/src/app/modules/social-media-post/post-manager/post-manager.component";
import { PostTemplateInterface } from "~/src/app/modules/posts/template.interface";
import { PostTemplateManagerDialogData } from "~/src/app/modules/social-media-post/post-template-manager/post-template-manager.interfaces";
import { UsersService } from "~/src/app/modules/users/users/users.service";
import { MentionConfig } from "~/node_modules/angular-mentions";
import { FormControl } from "@angular/forms";

@Component({
    selector: "smd-post-preview",
    templateUrl: "./post-preview.component.html",
    styleUrls: [
        "./post-preview.component.scss",
        "./styles/facebook-preview.scss",
        "./styles/instagram-preview.scss",
        "./styles/linkedin-preview.scss",
        "./styles/twitter-preview.scss",
    ],
})
export class PostPreviewComponent implements OnInit {
    @Input() posts: PostInterface[];
    @Input() selectedSocialPlatformId?: string;
    @Input() hasButtons = false;
    @Input() isSingle = true;
    @Input() isCommentMode = false;
    @Input() feedView = false;
    @Input() relatedUsers: IRelatedUser[] = [];
    @Input("loadedFrom") loadedFrom = null;
    @ViewChild("commentExpansionPanel", { static: true }) commentExpansionPanel: MatExpansionPanel;
    render: EventEmitter<any> = new EventEmitter<any>();
    galleryID = "postGallery";
    galleryRef: GalleryRef;
    preImages: SmdFileInterface[] = [];
    preVideos: SmdFileInterface[] = [];
    post: PostInterface | PostTemplateInterface;
    socialPlatforms: Array<SocialPlatform> = cloneDeep(
        SOCIAL_PLATFORMS_DEFAULT_CONFIG.filter((config) => socialSiteAppearance?.[config.id]?.schedule !== false)
    );
    objectID;
    objectType;
    status;
    firstComment = "";
    instagramLocationName = "";
    hasAcceptance = true;
    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        infinite: false,
        variableWidth: true,
        swipe: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="mdi mdi-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="mdi mdi-chevron-right"></i></button>',
    };
    hasApprovementWorkflow = false;
    postContentLines = [];
    linkedInContentLines = [];
    twitterContentLines = [];
    facebookPostContentLines = [];
    instagramContentLines = [];
    socialPlatformNames = {
        facebook: SOCIAL_MEDIA_TYPE_FACEBOOK,
        linkedIn: SOCIAL_MEDIA_TYPE_LINKEDIN,
        twitter: SOCIAL_MEDIA_TYPE_TWITTER,
        instagram: SOCIAL_MEDIA_TYPE_INSTAGRAM,
        gmb: SOCIAL_MEDIA_TYPE_GMB,
    };
    selectedMentions: IMentionViewItem[] = null;
    user: LoggedUserInterface = LoggedUser.getUser();
    createCommentInProgress: boolean = false;
    newComment: Subject<string> = new Subject();
    commentFormControl: FormControl = new FormControl("");
    unformattedComment: string = "";
    htmlDoc: HTMLElement;
    mentionConfig: MentionConfig = {
        maxItems: 5,
        dropUp: true,
        labelKey: "name",
        allowSpace: true,
        mentionSelect: (e: any) => {
            return "##" + e.userID + "##";
        },
    };

    constructor(
        public languageService: LanguageService,
        public dialogRef: MatDialogRef<PostPreviewComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: PostPreviewDialogData | PostTemplateManagerDialogData,
        public gallery: Gallery,
        private usersService: UsersService,
        private openModal: OpenModalService,
        private autofeedPageService: AutofeedPageService,
        private partnerConfig: PartnerConfigService
    ) {
        this.hasApprovementWorkflow = this.partnerConfig.hasConfig(PartnerPermissions.ApprovementWorkflow);

        if (!!this.dialogData && "post" in this.dialogData && this.dialogData.post) {
            // this.post = CommonPostHelpers.mergeDataWithDraftData(cloneDeep(this.dialogData.post));
            this.post = cloneDeep(this.dialogData.post);
            this.selectedSocialPlatformId = this.post.socialType;
            this.hasButtons = true;
            this.isCommentMode = this.dialogData.isCommentMode;
        } else if (!!this.dialogData && "template" in this.dialogData && this.dialogData.template) {
            this.post = cloneDeep(this.dialogData.template);
            this.selectedSocialPlatformId = this.post.socialType;
            this.hasButtons = true;
            this.isCommentMode = this.dialogData.isCommentMode;
        }

        this.setMediaRenderer();
    }

    ngOnInit() {
        this.socialPlatforms = this.socialPlatforms.filter((item) => socialSiteAppearance?.[item.id]?.[this.loadedFrom] !== false);

        if ((!this.posts || !this.posts.length) && !this.post) {
            console.error("Post entity required in Post Preview Component!");
            return;
        }

        if (!this.post) {
            this.post = cloneDeep(this.posts[0]);
        }

        this.setLinkShare();

        this.setInstaOptions();

        // current entity is post
        if (has(this.post, "postID")) {
            // disable approval workflow when current post is inactive
            if (this.post.status === "inactive") {
                this.hasApprovementWorkflow = false;
            }
        }

        this.setDefaultActivePlatform();
        this.handlePostChange();

        this.postContentLines = this.setPostContentLines();
        this.facebookPostContentLines = this.setPostContentLines(FacebookPreviewAdapter, SOCIAL_MEDIA_TYPE_FACEBOOK);
        this.linkedInContentLines = this.setPostContentLines(LinkedInPreviewAdapter, SOCIAL_MEDIA_TYPE_LINKEDIN);
        this.twitterContentLines = this.setPostContentLines(TwitterPreviewAdapter, SOCIAL_MEDIA_TYPE_TWITTER);
        this.instagramContentLines = this.setPostContentLines(InstagramPreviewAdapter, SOCIAL_MEDIA_TYPE_INSTAGRAM);
        this.setMentions();
        //this.commentFormControl.setValue("");

        this.unformattedComment = "";

        this.getRelatedUsers();
    }

    getRelatedUsers() {
        if (this.objectID && this.objectType && this.relatedUsers.length === 0 && !this.feedView) {
            this.usersService
                .getObjectRelatedUsers(this.objectType, [this.objectID])
                .then((response) => {
                    this.relatedUsers = response.usersForObject[this.objectID];
                })
                .catch(() => {});
        }
    }

    /**
     * Trigger create new comment in comments component
     */
    createComment(): void {
        let message = document.getElementById("commentText_" + "pp").innerHTML;
        if (message && message !== "") {
            message = message.replace(/<span.*?id="(.*?)".*?>(.*?)<\/span>/g, "##$1##");

            this.createCommentInProgress = true;
            this.newComment.next(message);
        }
    }

    successCreateComment(response): void {
        this.createCommentInProgress = false;
        this.unformattedComment = "";
        this.htmlDoc = document.getElementById("commentText_" + "pp");
        this.htmlDoc.innerHTML = "";
    }

    failedCreateComment(id): void {
        this.createCommentInProgress = false;
        this.unformattedComment = "";
        this.htmlDoc = document.getElementById("commentText_" + "pp");
        this.htmlDoc.innerHTML = "";
    }

    parseContentLine(element: HTMLElement, platform: string) {
        const platformConfig = SocialMediaPlatformConfig.getConfig(platform);

        this.replaceHashtagWithElement(element, platformConfig);
        this.initMentionPreviewBoxes(element, platformConfig);
    }

    getHost(url) {
        const getLocation = function (href) {
            const l = document.createElement("a");
            l.href = href;
            return l;
        };
        if (url) {
            return (getLocation(url).hostname || "").replace("www.", "").trim();
        }
        return "";
    }

    /**
     * Get social site name
     * @return {string}
     */
    getSocialSiteName() {
        let result = "Example Social Preview Page";

        if (!!this.post && "socialSites" in this.post && !!this.post.socialSites && !!this.post.socialSites.length) {
            const name = this.post.socialSites[0].name || this.post.socialSites[0].socialSiteName;

            if (this.post.socialSites.length > 1) {
                result = name + ` (+${this.post.socialSites.length - 1})`;
            } else {
                result = name;
            }
        }

        return result;
    }

    /**
     * Get active from date
     * @return {string | undefined}
     */
    getActiveFrom() {
        let date = this.post && "activeFrom" in this.post && this.post.activeFrom ? this.post.activeFrom : Utils.get(this.post, "activeFromInput", null);

        if (date === null && this.post && "createDate" in this.post) {
            date = this.post.createDate;
        }

        if (date === null) {
            date = "Example date";
        }

        return date;
    }

    hasLinkShare() {
        return this.post && this.post["linkshare"] && this.post["linkshare"]["url"] && this.post["linkshare"]["url"] !== "";
    }

    linkShareImage() {
        if (this.post["medias"] && this.post["medias"][0]) {
            return this.post["medias"][0]["url"];
        }

        if (this.post["linkshare"] && this.post["linkshare"]["image"]) {
            return this.post["linkshare"]["image"];
        }

        return false;
    }

    getInstagramContent() {
        const instaContent = this.setPostContentLines(null, SOCIAL_MEDIA_TYPE_INSTAGRAM);

        let postLines: string | string[] = instaContent.join("<br>");

        // remove mark tags (used for highlighting)
        postLines = postLines.replace(/<mark>/g, "").replace(/<\/mark>/g, "");

        /* The code below makes instagram linebreaks look like they do on the platform.
        Probably needs a refactor or someone to think it through, idk but it won't be */

        // replace \r with "", replace <br /> with <br>
        postLines = postLines.replace(/\r/g, "").replace(/<br ?\/?>/g, "<br>");

        // Split lines by br and add a br to each line to get the same linebreaks as on instagram
        postLines = postLines.split("<br>");
        postLines = postLines.map((line) => {
            return line + "<br>";
        });
        postLines = postLines.join("");

        // remove last br
        postLines = postLines.substring(0, postLines.length - 4);
        /* End of code that makes instagram linebreaks look like they do on the platform */

        // get length of postLines
        const postLinesLength = postLines.length;

        // get length of postLines without html tags
        const postLinesTextLength = postLines.replace(/(<([^>]+)>)/gi, "").length;

        // max post lines length is 120 characters without html tags
        const maxPostLinesLength = 120 + (postLinesLength - postLinesTextLength);

        if (!postLines || postLines === "") {
            return "";
        }
        if (postLines.length >= maxPostLinesLength) {
            return truncate(postLines, { length: maxPostLinesLength }) + '&nbsp;<span class="read-more">more</span>';
        }
        return postLines;
    }

    /******************************************************************************************************************************************
     ********************************************************* START:INSTAGRAM GETTERS ********************************************************
     ******************************************************************************************************************************************/
    /**
     * Get preview post media element is an image
     * @return {boolean}
     */
    getIsImage() {
        if (this.post["medias"] && this.post["medias"][0] && this.post["medias"][0]["fileType"] && this.post["medias"][0]["fileType"] == "image") {
            return true;
        }
        return false;
    }

    /**
     * Get preview post media element is an video
     * @return {boolean}
     */
    getIsVideo() {
        if (
            this.post["medias"] &&
            this.post["medias"][0] &&
            this.post["medias"][0]["fileType"] &&
            this.preVideos &&
            this.preVideos.length > 0 &&
            (this.preVideos[0].fileType == "video/mp4" || this.preVideos[0].fileType == "video" || this.post["medias"][0]["fileType"] == "video")
        ) {
            return true;
        }
        return false;
    }

    /**
     * Get preview post media image is an portrait image or not
     * @return {boolean}
     */
    getIsPortrait() {
        if (
            this.post["medias"] &&
            this.post["medias"][0] &&
            this.post["medias"][0]["instaOrientation"] &&
            this.post["medias"][0]["instaOrientation"] == "portrait"
        ) {
            return true;
        }
        return false;
    }

    /**
     * Get preview post media image is an portrait image or not
     * @return {string}
     */
    getInstaLinkPreviewImage() {
        if (this.post["medias"] && this.post["medias"].length > 0) {
            if (
                this.preVideos &&
                this.preVideos.length > 0 &&
                (this.preVideos[0].fileType == "video/mp4" || this.preVideos[0].fileType == "video" || this.post["medias"][0]["fileType"] == "video") &&
                this.preVideos[0].thumb
            ) {
                return this.preVideos[0].thumb;
            }

            /*
            if( this.post['medias'][0]['fileType'] == "video" && this.post['medias'][0]['galleryThumb'] ) {
                return this.post['medias'][0]['galleryThumb'];
            }
            */

            if (this.post["medias"] && this.post["medias"][0]) {
                let url = this.post["medias"][0]["url"];
                if (url.includes('(') || url.includes(')')) {
                    url = url.replace(/\(/gi, '\\(');
                    url = url.replace(/\)/gi, '\\)');
                }
                return url;
            }

            if (this.post["linkshare"] && this.post["linkshare"]["image"]) {
                return this.post["linkshare"]["image"];
            }
        }

        return false;
    }

    getInstaLinkPreviewImageEncoded() {
        let uri = this.getInstaLinkPreviewImage();
        return uri ? encodeURI(uri) : false;
    }
    /******************************************************************************************************************************************
     ********************************************************* END:INSTAGRAM GETTERS **********************************************************
     ******************************************************************************************************************************************/

    /**
     * @description just one of these can be isActive = true;
     * @param platformId
     */
    setPlatform(platformId: string) {
        this.selectedSocialPlatformId = platformId;
        this.socialPlatforms.map((platform) => {
            platform.isActive = platform.id === platformId;

            if (!!this.posts && !!this.posts.length) {
                platform.isDisabled = !this.posts.filter((post) => post.socialType === platform.id).length;
            }

            return platform;
        });

        if (this.posts && this.posts.length) {
            this.post = cloneDeep(this.posts.find((post) => post.socialType === platformId));
            this.setMentions();
            this.render.emit();
        }

        this.setLinkShare();
    }

    setMentions() {
        const _selectedMentions = [];
        if (this.post && this.post[PostFormControlNames.SelectedMentions]) {
            const _mentions = this.post[PostFormControlNames.SelectedMentions];
            if (_mentions && Object.keys(_mentions).length) {
                for (const _fieldName in _mentions) {
                    _selectedMentions.push(...(_mentions[_fieldName] || []));
                }
            }
        }
        this.selectedMentions = _selectedMentions;
    }

    statusChange(isSuccess: boolean, response: any) {
        if (!!this.dialogRef) {
            this.dialogRef.afterClosed().subscribe(() => {
                if (isSuccess) {
                    this.successApprove(response);
                } else {
                    this.failedApprove(response);
                }
            });
        }
    }

    /**
     * Success approve event
     */
    successApprove(response) {
        if (
            !!this.dialogData &&
            "successApprove" in this.dialogData &&
            this.dialogData.successApprove &&
            typeof this.dialogData.successApprove === "function"
        ) {
            this.dialogData.successApprove(response);
            this.autofeedPageService.reloadAutofeed.next();
        }
    }

    /**
     * Failed approve event
     */
    failedApprove(error) {
        if (!!this.dialogData && "failedApprove" in this.dialogData && this.dialogData.failedApprove && typeof this.dialogData.failedApprove === "function") {
            this.dialogData.failedApprove(error);
        }
    }

    /**
     * Get text content of post
     *
     * @param {AbstractPreviewAdapter} previewAdapter
     * @param socialType
     * @returns {string[]}
     */
    setPostContentLines(previewAdapter?: AbstractPreviewAdapter, socialType?: string, secondIteration?: boolean): string[] {
        const textFields = ["headline", "subHeadline", "mainCopy", "signature"];
        const content: string[] = [];

        let post;

        post = this.posts && this.posts.length ? this.posts.find((postItem) => postItem.socialType === socialType) || this.post : this.post;

        for (const key of textFields) {
            if (has(post, key)) {
                let value: string = post[key];

                if (value) {
                    if (previewAdapter) {
                        if (post.socialType === SOCIAL_MEDIA_TYPE_TWITTER) {
                            value = previewAdapter.adapt(value, post.linkshareIncluded);
                        } else {
                            value = previewAdapter.adapt(value);
                        }
                    }

                    content.push(value);
                }
            }
        }

        return content;
    }

    get hasCarousel() {
        return this.post && this.post["medias"] && this.post["medias"].length > 0 && this.post["medias"][0].type === FILE_TYPES.CAROUSEL;
    }

    get carousel() {
        return this.hasCarousel ? this.post["medias"][0] : false;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }

    private setMediaRenderer() {
        this.render.subscribe(() => {
            this.galleryRef = this.gallery.ref(this.galleryID);
            this.galleryRef.reset();
            this.preImages = [];
            this.preVideos = [];
            // this.post.activeFrom = !!this.post.activeFrom ? this.post.activeFrom : moment().format(BASE_DATETIME_FORMAT);

            if (this.post && this.post.medias) {
                forEach(this.post["medias"], (media, index) => {
                    const file = this.post["medias"][index];

                    switch (file.type) {
                        case FILE_TYPES.IMAGE:
                            this.preImages.push(file);
                            this.galleryRef.addImage({
                                src: file.url,
                                thumb: file.thumb,
                                title: file.name,
                            });
                            break;

                        case FILE_TYPES.VIDEO:
                            this.preVideos.push(file);

                            this.galleryRef.addVideo({
                                src: file.url,
                                thumb: file.thumb,
                                title: file.name,
                            });
                            break;
                    }
                });
            }
        });
    }

    private setLinkShare() {
        if (this.post && !this.post["linkshare"] && !!this.post[PostFormControlNames.LinkShareTitle]) {
            this.post["linkshare"] = {
                title: this.post[PostFormControlNames.LinkShareTitle] || "",
                description: this.post[PostFormControlNames.LinkShareDescription] || "",
                url: this.post[PostFormControlNames.LinkShareURL] || "",
                image: this.post[PostFormControlNames.LinkShareImageURL] || "",
            };
        }
    }

    private setInstaOptions() {
        if (this.posts && this.posts.find((post) => post.socialType == "instagram")) {
            const instaPost = this.posts.find((post) => post.socialType == "instagram");
            this.firstComment = instaPost.firstComment;
            this.instagramLocationName = instaPost.instagramLocationName;
        } else if (this.post && this.post.socialType === "instagram" && "firstComment" in this.post) {
            this.firstComment = this.post.firstComment;
        }
    }

    private handlePostChange() {
        this.objectType = "postID" in this.post && !!this.post.postID ? "post" : !!this.post.templateID ? "postTemplate" : null;
        this.objectID = "postID" in this.post && !!this.post.postID ? this.post.postID : !!this.post.templateID ? this.post.templateID : null;
        this.status = !!this.post.status ? this.post.status : null;

        let forbiddenStatuses = [POST_STATUS_POSTED, POST_STATUS_FAILED, POST_STATUS_PARTIALLY_FAILED];
        if (forbiddenStatuses.includes(this.status)) {
            this.hasAcceptance = false;
        }

        if (this.post && this.post.socialType) {
            this.setPlatform(this.post.socialType);
        } else if (this.selectedSocialPlatformId) {
            this.setPlatform(this.selectedSocialPlatformId);
        }

        if (!!this.post["medias"] && !!this.post["medias"].length) {
            this.render.emit();
        }

        if (("noApproveButtons" in this.dialogData && this.dialogData.noApproveButtons) || this.post.type === "draft") {
            this.hasAcceptance = false;
        }
    }

    /**
     * Set default active platform tab
     */
    private setDefaultActivePlatform() {
        if (!this.selectedSocialPlatformId) {
            this.selectedSocialPlatformId = this.post.socialType;
        }

        this.socialPlatforms.map((platform) => {
            platform.isActive = platform.id === this.selectedSocialPlatformId;
        });
    }

    /**
     * Replace hashtag string with html element
     * @param {HTMLElement} element
     * @param {SocialMediaPlatform} platformConfig
     */
    private replaceHashtagWithElement(element: HTMLElement, platformConfig: SocialMediaPlatform) {
        const urlFormatter = platformConfig ? platformConfig.hashtagSearchPageUrl || null : null;
        element.innerHTML = element.innerHTML.replace(/#([_0-9]*[a-zA-Zá-űÁ-Ű][_0-9a-zA-Zá-űÁ-Ű]*)/g, function (fullMatch, hashtag) {
            if (urlFormatter && hashtag) {
                return `<a class="hashtag" href="${urlFormatter(hashtag)}" target="_blank">${fullMatch}</a>`;
            }

            return fullMatch;
        });
    }

    /**
     * Init mention element preview boxes
     * @param {HTMLElement} element
     * @param {SocialMediaPlatform} platformConfig
     */
    private initMentionPreviewBoxes(element: HTMLElement, platformConfig: SocialMediaPlatform) {
        const mentionTags = element.querySelectorAll(`.${MENTION_TAG_ELEMENT_CLASS}`);
        const platform = platformConfig.platform;

        if (mentionTags && mentionTags.length) {
            for (const mentionTag of Array.from(mentionTags)) {
                if (platformConfig && platformConfig.mentionPreviewFormatter) {
                    mentionTag.innerHTML = mentionTag.innerHTML.replace(mentionTag.textContent, platformConfig.mentionPreviewFormatter(mentionTag.textContent));
                }

                if (!((mentionTag as HTMLElement).dataset["itemUids"] || "").includes(platform)) {
                    element.innerHTML = element.innerHTML.replace(mentionTag.outerHTML, mentionTag.textContent);
                } else {
                    mentionTag.addEventListener("mouseenter", (event) => {
                        const itemUIDs: string[] = ((mentionTag as HTMLElement).dataset["itemUids"] || "").split(",").filter((id) => !!id);
                        const tagUID = (mentionTag as HTMLElement).dataset["uid"] || "";

                        if (itemUIDs && itemUIDs.length) {
                            const itemUID = itemUIDs.find((_uid) => _uid.includes(platform));
                            if (itemUID) {
                                const mentionID = itemUID.split(":")[1];
                                let mention: IMentionItem;
                                this.selectedMentions.forEach((_mention) => {
                                    if (_mention.uid + "" === tagUID) {
                                        const subItem = (_mention.items || []).find((_item) => _item.platform === platform);
                                        if (subItem) {
                                            if (subItem.id + "" === mentionID) {
                                                mention = subItem;
                                            }
                                        }
                                    }
                                });

                                if (mention) {
                                    let html = ``;

                                    if (mention.image) {
                                        html += `
                                        <div class="mention-preview__thumbnail">
                                            <img src="${mention.image}" alt="${mention.name}">
                                        </div>
                                    `;
                                    }

                                    html += `
                                    <div class="mention-preview__content">
                                        <h3>${mention.name}</h3>
                                        <p class="m-0"><i class="${mention.iconClass} m-r-10"></i>${mention.platformName}</p>
                                    </div>
                                `;

                                    const elementPos = mentionTag.getBoundingClientRect();
                                    const tooltipEl = (document as Document).createElement("div");
                                    tooltipEl.classList.add("mention-preview");
                                    tooltipEl.innerHTML = html;
                                    tooltipEl.style.top = elementPos.top + elementPos.height + 10 + "px";
                                    tooltipEl.style.left = elementPos.left + "px";

                                    (document as Document).body.appendChild(tooltipEl);

                                    mentionTag.addEventListener("mouseleave", () => {
                                        tooltipEl.remove();
                                    });
                                }
                            }
                        }
                    });
                }
            }
        }
    }

    openEditModal() {
        if (this.objectType === "post") {
            if ("activeFrom" in this.post && !this.post.activeFromInput) {
                this.post.activeFromInput = this.post.activeFrom; // Fix post-skeleton not setting schedule date. Might cause a problem with post repeat?
            }

            this.dialogRef.close();
            this.openModal
                .editPost(PostManagerComponent, this.post)
                .afterClosed() // Circular dependency TODO
                .subscribe((isChange) => {
                    if (isChange) {
                        CustomEvents.postOrTemplateEdit(this.post);
                    }
                });
        } else if (this.objectType === "postTemplate") {
            this.dialogRef.close();
            this.openModal
                .editTemplate(PostTemplateManagerComponent, this.post)
                .afterClosed() // Circular dependency 2 TODO
                .subscribe((isChange) => {
                    if (isChange) {
                        CustomEvents.postOrTemplateEdit(this.post);
                    }
                });
        }
    }

    relatedUserSelected($event: any) {
        setTimeout(() => {
            this.htmlDoc = document.getElementById("commentText_" + "pp");
            this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(
                "##" + $event.userID + "##",
                '<span id="' + $event.userID + '" class="mention" ' + 'style="color: #0072f0;" contenteditable=false>@' + $event.name + "</span>&nbsp;"
            );
            // set cursor to the end of the text
            this.htmlDoc.focus();
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(this.htmlDoc.childNodes[this.htmlDoc.childNodes.length - 1], 1);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }, 10);
    }
}
