import {Pipe, PipeTransform} from '@angular/core';
import {DateSupport} from '~/src/app/core/helper/date-support';

@Pipe({
    name: 'smdDate'
})
export class SmdDatePipe implements PipeTransform {

    /**
     * Transform date string to another date format with moment.js
     * @param {string} date
     * @param {string} format
     * @return {any}
     */
    transform(date: string, format?: string): any {
        return DateSupport.dateTransform(date, format);
    }
}
