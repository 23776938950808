import { debounceTime } from "rxjs/operators";
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { BaseWidgetEntity } from "~/src/app/modules/analytics/widget/base-widget.entity";
import { LanguageService } from "~/src/app/services/language.service";
import {
    NgxDrpOptions,
    NgxMatDrpComponent,
    PresetItem,
} from "ngx-mat-daterange-picker";
import * as moment from "moment";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { NotifyService } from "~/src/app/services/notify.service";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { FormControl, FormGroup } from "@angular/forms";
import { cloneDeep, forEach, truncate } from "lodash";
import { PostActions } from "~/src/app/modules/posts/post-actions";
import { PostActionsService } from "~/src/app/modules/posts/post-actions.service";
import { MatDialog } from "@angular/material/dialog";
import { UsersController } from "~/src/app/modules/users/users/users.component";
import { CarouselController } from "~/src/app/modules/posts/carousel/carousel.component";
import { TemplateActions } from "~/src/app/modules/posts/template-actions";
import { TemplateActionsService } from "~/src/app/modules/posts/template-actions.service";
import { TagsService } from "~/src/app/modules/posts/tags.service";
import { CategoriesService } from "~/src/app/modules/posts/categories.service";
import { MediasService } from "~/src/app/services/file-browser.service";
import { Subject } from "~/node_modules/rxjs";
import { Debounce, Helpers } from "~/src/app/services/helpers";

import { ResourceService } from "~/src/app/directives/resource-checker/resource.service";
import { PostPreviewComponent } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.component";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_GMB,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
} from "~/src/app/core/constants";
import { GridStackService } from "~/src/app/core/services/grid-stack.service";
import { GridStackItemValueChange } from "~/src/app/core/services/grid-stack.service.d";
import { UserService } from "~/src/app/services/user.service";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import Utils from "~/src/app/core/utils";
import { BASE_DATE_FORMAT } from "~/src/app/configs/configs";
import { WIDGET_TYPE_CHART } from "~/src/app/modules/analytics/widget/widget.configs";
import { WIDGET_METRIC_OPTION_LIKES_GROWTH } from "~/src/app/modules/analytics/widget/metric-configs/facebook/likes";
import {
    WIDGET_METRIC_OPTION_VIEWS_PAGES,
    WIDGET_METRIC_OPTION_VIEWS_VIDEOS_TOTAL,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/views";
import {
    WIDGET_METRIC_OPTION_POSTS_ENGAGEMENT,
    WIDGET_METRIC_OPTION_POSTS_IMPRESSIONS,
    WIDGET_METRIC_OPTION_TOP_POSTS_ENGAGEMENT,
    WIDGET_METRIC_OPTION_TOP_POSTS_IMPRESSIONS,
    WIDGET_METRIC_OPTION_TOP_POSTS_REACH,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/posts";
import {
    WIDGET_METRIC_OPTION_ACTIVITIES_BY_ORGANIZATIONS,
    WIDGET_METRIC_OPTION_ACTIVITIES_BY_TEAM_MEMBERS,
} from "~/src/app/modules/analytics/widget/metric-configs/facebook/activities";
import { WIDGET_METRIC_OPTION_ORGANIZATIONS_PERFORMANCE } from "~/src/app/modules/analytics/widget/metric-configs/facebook/organizations-performance";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { WIDGET_METRIC_OPTION_ENGAGEMENT } from "../analytics/widget/metric-configs/facebook/engagement";

// READDMULTIPLE: Follow instructions to re-add multiple select possibility. Also, add multiple tag to the html.
// Commit history can also help, just revert it to the state before 2022-04-08 if there were no other changes since
@Component({
    selector: "smd-dashboard-fix",
    templateUrl: "./dashboard-fix.component.html",
    styleUrls: ["./dashboard-fix.component.scss"],
    providers: [GridStackService],
})
export class DashboardFixComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("dateRangeInput", { static: true })
    dateRangeInput: NgxMatDrpComponent;
    widgets: object = {};
    staticWidgets: object = {};
    dateRangeOptions: NgxDrpOptions;
    presets: Array<PresetItem> = [];
    socialSites = [];
    filterFormControlNames = {
        SocialSite: "socialSiteID",
        DateRange: "dateRange",
    };
    filterFormGroup = new FormGroup({
        [this.filterFormControlNames.SocialSite]: new FormControl(null),
        [this.filterFormControlNames.DateRange]: new FormControl(null),
    });
    localStorageKey = "dashboardOptions";
    afterViewInited = false;
    subs = [];
    dateRengeDefaultFrom;
    dateRengeDefaultTo;
    rawPosts = [];
    rawTemplates = [];
    recentPosts;
    recentTemplates;
    recentMedias = [];
    postCount = 0;
    templateCount = 0;
    mediaCount = 0;
    postActions;
    templateActions;
    getAnalytics: Subject<any> = new Subject<any>();
    initedWidget = 0;
    recentMediasSettings = {
        id: "recentMedias",
        size: {
            width: 12,
            height: 12,
        },
        position: {
            x: 0,
            y: 41,
        },
    };
    recentCommentsSettings = {
        id: "recentComments",
        size: {
            width: 6,
            height: 10,
        },
        position: {
            x: 0,
            y: 53,
        },
    };
    membersSettings = {
        id: "members",
        size: {
            width: 6,
            height: 10,
        },
        position: {
            x: 6,
            y: 53,
        },
    };
    widgetsProperties: GridStackItemValueChange[] = [];
    saveSuccess = false;
    saveFailed = false;
    savePending = false;
    haveDashboardPersonalization = false;
    readonly allWidgetNumber = 10;

    constructor(
        public languageService: LanguageService,
        public socialSitesController: SocialSiteController,
        public dialog: MatDialog,
        public mediasService: MediasService,
        public resourceService: ResourceService,
        private gridStackService: GridStackService,
        private postService: PostActionsService,
        private usersController: UsersController,
        private carouselController: CarouselController,
        private templateService: TemplateActionsService,
        private tagService: TagsService,
        private categoryService: CategoriesService,
        private openModal: OpenModalService,
        private userService: UserService,
        private partnerConfig: PartnerConfigService
    ) {
        this.haveDashboardPersonalization = this.partnerConfig.hasConfig(
            PartnerPermissions.DashboardPersonalization
        );

        this.postActions = new PostActions(
            this.postService,
            this.dialog,
            this.usersController,
            this.carouselController,
            this.openModal
        );
        this.templateActions = new TemplateActions(
            this.templateService,
            this.tagService,
            this.categoryService,
            this.postService,
            this.dialog,
            this.usersController,
            this.carouselController,
            this.openModal
        );

        const loader = this.openModal.loader(DialogLoaderComponent);

        Promise.all([
            this.socialSitesInit(),
            this.userService
                .getDashboardWidgetProperties()
                .then((dashboardSettings) => {
                    if (!!dashboardSettings) {
                        if (dashboardSettings.widgets) {
                            this.widgetsProperties = dashboardSettings.widgets;
                        }

                        if (dashboardSettings.dateRange) {
                            this.filterFormGroup
                                .get(this.filterFormControlNames.DateRange)
                                .setValue(dashboardSettings.dateRange);
                        }

                        if (dashboardSettings.socialSiteIDs) {
                            this.filterFormGroup
                                .get(this.filterFormControlNames.SocialSite)
                                .setValue(dashboardSettings.socialSiteIDs?.[0]); // READDMULTIPLE
                        }
                    }

                    this.widgetsProperties.forEach((properties) => {
                        const setProperties = (targetSettings) => {
                            targetSettings.size = {
                                width: properties.width,
                                height: properties.height,
                            };

                            targetSettings.position = {
                                x: properties.x,
                                y: properties.y,
                            };
                        };

                        if (!!Number(properties.widgetID)) {
                        } else {
                            switch (properties.widgetID) {
                                case this.recentMediasSettings.id:
                                    this.recentMediasSettings.size = {
                                        width: properties.width,
                                        height: properties.height,
                                    };

                                    this.recentMediasSettings.position = {
                                        x: properties.x,
                                        y: properties.y,
                                    };

                                    break;

                                case this.recentCommentsSettings.id:
                                    this.recentCommentsSettings.size = {
                                        width: properties.width,
                                        height: properties.height,
                                    };

                                    this.recentCommentsSettings.position = {
                                        x: properties.x,
                                        y: properties.y,
                                    };

                                    break;

                                case this.membersSettings.id:
                                    this.membersSettings.size = {
                                        width: properties.width,
                                        height: properties.height,
                                    };

                                    this.membersSettings.position = {
                                        x: properties.x,
                                        y: properties.y,
                                    };

                                    break;
                            }
                        }
                    });

                    return Promise.resolve(dashboardSettings);
                }),
            this.getRecentItems().catch((error) => {
                loader.close();

                return Promise.reject(error);
            }),
        ])
            .then((responses) => {
                this.setFixWidgets();

                this.widgetsProperties.forEach((properties) => {
                    if (!!Number(properties.widgetID)) {
                        forEach(Object.keys(this.widgets), (itemID) => {
                            if (
                                this.widgets[itemID].widget.id ===
                                Number(properties.widgetID)
                            ) {
                                this.widgets[itemID].widget.size = {
                                    width: properties.width,
                                    height: properties.height,
                                };

                                this.widgets[itemID].widget.position = {
                                    x: properties.x,
                                    y: properties.y,
                                };
                            }
                        });

                        forEach(Object.keys(this.staticWidgets), (itemID) => {
                            if (
                                this.staticWidgets[itemID].widget.id ===
                                Number(properties.widgetID)
                            ) {
                                this.staticWidgets[itemID].widget.size = {
                                    width: properties.width,
                                    height: properties.height,
                                };

                                this.staticWidgets[itemID].widget.position = {
                                    x: properties.x,
                                    y: properties.y,
                                };
                            }
                        });
                    }
                });

                this.setDefaultFilterOptions();

                loader.close();
            })
            .catch((err) => {
                loader.close();
            });

        this.gridStackService.valueChange.subscribe((result) => {
            this.widgetsProperties = result.items;

            this._saveDashboardSettings();
        });
    }

    ngOnInit() {
        this.setDateRangeOptions();
        this.filterFromInit();
        setTimeout(() => {
            this.refreshAnalytics();
        }, 50);
    }

    ngAfterViewInit(): void {
        this.afterViewInited = true;
        this.gridStackService.initEventListeners(true);
    }

    ngOnDestroy(): void {
        this.gridStackService.removeEventListeners();
        forEach(this.subs, (sub) => {
            sub.unsubscribe();
        });
    }

    saveSizeAndPositionOfWidgets() {
        this.gridStackService.getAllItemData();
    }

    dateRangeChange(event) {
        if (!this.afterViewInited) {
            return;
        }

        this.filterFormGroup
            .get(this.filterFormControlNames.DateRange)
            .setValue(event);
    }

    @Debounce()
    refreshAnalytics() {
        this.getAnalyticsNext({
            ...this.filterFormGroup.getRawValue(),
            ...{
                clear: true,
            },
        });
    }

    /**
     * Get selected social sites
     * @returns {any[]}
     */
    getSelectedSocialSites() {
        let socialSiteIDs =
            this.filterFormGroup.get(this.filterFormControlNames.SocialSite)
                .value || [];

        if (!Array.isArray(socialSiteIDs)) {
            socialSiteIDs = [socialSiteIDs]; // READDMULTIPLE
        }
        
        return this.socialSites.filter(
            (site) => socialSiteIDs.indexOf(site.siteID) > -1
        );
    }

    goPreview(entity, type: "post" | "postTemplate") {
        const rawCollection =
            type === "post" ? this.rawPosts : this.rawTemplates;
        const IDKey = type === "post" ? "postID" : "templateID";
        const rawItemID = rawCollection.findIndex(
            (item) => item[IDKey] === entity.id
        );
        const post = rawCollection[rawItemID];

        const currentpost = {
            ...post,
        };

        this.openModal.entityPreview(PostPreviewComponent, currentpost);
    }

    /**
     * Save dashboard settings (widget positions/sizes and date range)
     *
     * @private
     */
    private _saveDashboardSettings(): void {
        const resetStatuses = () => {
            setTimeout(() => {
                this.saveSuccess = false;
                this.saveFailed = false;
            }, 3000);
        };

        this.savePending = true;
        this.userService
            .setDashboardWidgetProperties(
                this.widgetsProperties,
                this.filterFormGroup.get(this.filterFormControlNames.DateRange)
                    .value,
                (Array.isArray(this.filterFormGroup.get(this.filterFormControlNames.SocialSite)
                    .value) ? this.filterFormGroup.get(this.filterFormControlNames.SocialSite)
                    .value : [this.filterFormGroup.get(this.filterFormControlNames.SocialSite).value]) // READDMULTIPLE
            )
            .then((response) => {
                this.savePending = false;
                this.saveSuccess = true;

                resetStatuses();
            })
            .catch((error) => {
                this.savePending = false;
                this.saveFailed = true;

                resetStatuses();

                this.openModal.errorModal(DialogErrorComponent, {
                    message: FormValidationService.readError(error).message,
                });
            });
    }

    private getAnalyticsNext(filters) {
        this.getAnalytics.next(filters);
    }

    private filterFromInit() {
        const sub1 = this.filterFormGroup.valueChanges
            .pipe(debounceTime(500))
            .subscribe((values) => {
                const filters = cloneDeep(values);
                filters[this.filterFormControlNames.DateRange] = {
                    fromDate: this.dateRangeInput.rangeStoreService.fromDate,
                    toDate: this.dateRangeInput.rangeStoreService.toDate,
                };
                this.setFilterOptions(filters);
                this.getAnalyticsNext(filters);

                this._saveDashboardSettings();
            });
        this.subs.push(sub1);
    }

    private socialSitesInit() {
        return new Promise((resolve, reject) => {
            this.socialSitesController.getItems(
                (response) => {
                    this.socialSites = Helpers.orderBy(
                        (response.socialSites as SocialSiteInterface[]).filter(
                            (item) =>
                                [SOCIAL_MEDIA_TYPE_INSTAGRAM, SOCIAL_MEDIA_TYPE_FACEBOOK].includes(item.socialType) ||
                                ([SOCIAL_MEDIA_TYPE_LINKEDIN].includes(item.socialType) && item.pageType != "user")
                        ),
                        "name"
                    );
                    this.socialSites.forEach((site) => {
                        if(site.pageType === 'group'){
                        site.isGroup=true;
            
                    }else{
                        site.isGroup=false;
                    }
            
                    });
                    resolve(this.socialSites);
                },
                (error) => {
                    NotifyService.error(
                        FormValidationService.readError(error).message,
                        ""
                    );
                    reject(error);
                }
            );
        });
    }

    private setDefaultFilterOptions() {
        const defaultOptions = this.getFilterOptions();

        if (defaultOptions) {
            defaultOptions[this.filterFormControlNames.SocialSite] =
            (Array.isArray(this.filterFormGroup.get(this.filterFormControlNames.SocialSite)
            .value) ? this.filterFormGroup.get(this.filterFormControlNames.SocialSite)[0]
            .value : this.filterFormGroup.get(this.filterFormControlNames.SocialSite).value) || this.socialSites[0].siteID; // READDMULTIPLE
            const selected_social_site = this.socialSites.find((panel) => panel["siteID"] == defaultOptions[this.filterFormControlNames.SocialSite] );        
            if(selected_social_site.pageType == "group"){
                const selected_social_site_page_id = this.socialSites.find((panel) => panel["pageType"] !== 'group');
               
                defaultOptions[this.filterFormControlNames.SocialSite] = selected_social_site_page_id ? selected_social_site_page_id.siteID : [];
               
                                  
                                }
                              
            defaultOptions[this.filterFormControlNames.DateRange] =
                this.filterFormGroup.get(this.filterFormControlNames.DateRange)
                    .value || {
                    fromDate: this.dateRengeDefaultFrom,
                    toDate: this.dateRengeDefaultTo,
                };

            /*forEach(
                defaultOptions[this.filterFormControlNames.SocialSite],
                (siteID, index) => {
                    if (
                        this.socialSites.map((site) => site.siteID).indexOf(siteID) === -1
                    ) {
                        defaultOptions[
                            this.filterFormControlNames.SocialSite
                        ].splice(index, 1);
                    }
                }
            );*/ // READDMULTIPLE
            if (defaultOptions[this.filterFormControlNames.SocialSite].value) {
                if (this.socialSites.map((site) => site.siteID).indexOf(defaultOptions[this.filterFormControlNames.SocialSite].value) === -1) {
                    defaultOptions[this.filterFormControlNames.SocialSite] = this.socialSites[0].siteID;
                }
            }

            this.filterFormGroup.setValue(defaultOptions);
            this.dateRangeInput.rangeStoreService.updateRange(
                defaultOptions[this.filterFormControlNames.DateRange].fromDate,
                defaultOptions[this.filterFormControlNames.DateRange].toDate
            );
        } else {
            const options = {};

            options[this.filterFormControlNames.DateRange] = {
                fromDate: this.dateRengeDefaultFrom,
                toDate: this.dateRengeDefaultTo,
            };
            // this whole thing is just too messy to do it right in a short time... this'll be ugly, but fixes an issue related to loading
            options[this.filterFormControlNames.SocialSite] = this.filterFormGroup.get(this.filterFormControlNames.SocialSite)
            .value ? this.filterFormGroup.get(this.filterFormControlNames.SocialSite)
            .value : (this.socialSites.length? this.socialSites[0].siteID : null) // READDMULTIPLE []
            this.filterFormGroup.setValue(options);
        }
    }

    private getFilterOptions() {
        const options = localStorage.getItem(this.localStorageKey);

        if (options) {
            return JSON.parse(options);
        } else {
            return null;
        }
    }

    private setFilterOptions(values) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(values));
    }

    private setFixWidgets() {
        const allWidget = {
            "1likeGrowth": {
                col: 4,
                resource: "analytics.analytics.pageLikeGrowth",
                widget: new BaseWidgetEntity({
                    id: 1,
                    name: "Like Growth",
                    type: WIDGET_TYPE_CHART,
                    metrics: WIDGET_METRIC_OPTION_LIKES_GROWTH.id,
                    socialChannel: "facebook",
                    size: {
                        width: 4,
                        height: 7,
                    },
                    position: {
                        x: 0,
                        y: 0,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "2pageViews": {
                col: 4,
                resource: "analytics.analytics.pageViews",
                widget: new BaseWidgetEntity({
                    id: 2,
                    name: "Page Views",
                    type: WIDGET_TYPE_CHART,
                    metrics: WIDGET_METRIC_OPTION_VIEWS_PAGES.id,
                    socialChannel: "facebook",
                    size: {
                        width: 4,
                        height: 7,
                    },
                    position: {
                        x: 4,
                        y: 0,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "3videoViews": {
                col: 4,
                resource: WIDGET_METRIC_OPTION_VIEWS_VIDEOS_TOTAL.resource,
                widget: new BaseWidgetEntity({
                    id: 3,
                    name: "Video Views",
                    type: WIDGET_TYPE_CHART,
                    metrics: WIDGET_METRIC_OPTION_VIEWS_VIDEOS_TOTAL.id,
                    socialChannel:
                        WIDGET_METRIC_OPTION_VIEWS_VIDEOS_TOTAL.socialChannel,
                    size: {
                        width: 4,
                        height: 7,
                    },
                    position: {
                        x: 8,
                        y: 0,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [SOCIAL_MEDIA_TYPE_FACEBOOK],
            },
            "4postEngagement": {
                col: 6,
                resource: "analytics.analytics.pageEngagement",
                widget: new BaseWidgetEntity({
                    id: 4,
                    name: "Post Engagement",
                    type: WIDGET_TYPE_CHART,
                    metrics: WIDGET_METRIC_OPTION_ENGAGEMENT.id,
                    //metrics: WIDGET_METRIC_OPTION_POSTS_ENGAGEMENT.id,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 7,
                    },
                    position: {
                        x: 0,
                        y: 7,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "5postReach": {
                col: 6,
                resource: "analytics.analytics.pageReach",
                widget: new BaseWidgetEntity({
                    id: 5,
                    name: "Post Reach",
                    type: WIDGET_TYPE_CHART,
                    /// Post Reach-nek ez a metrika felel meg
                    metrics: WIDGET_METRIC_OPTION_POSTS_IMPRESSIONS.id,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 7,
                    },
                    position: {
                        x: 6,
                        y: 7,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "6activitiesByTeamMembers": {
                col: 6,
                resource: "analytics.analytics.activitiesByTeamMembers",
                widget: new BaseWidgetEntity({
                    id: 6,
                    name: "Activities by Team Members",
                    type: WIDGET_TYPE_CHART,
                    metrics: WIDGET_METRIC_OPTION_ACTIVITIES_BY_TEAM_MEMBERS.id,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 7,
                    },
                    position: {
                        x: 6,
                        y: 14,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "6activitiesByOrganizations": {
                col: 6,
                resource: "analytics.analytics.activitiesByOrganizations",
                widget: new BaseWidgetEntity({
                    id: 7,
                    name: "Activities by Organizations",
                    type: WIDGET_TYPE_CHART,
                    metrics:
                        WIDGET_METRIC_OPTION_ACTIVITIES_BY_ORGANIZATIONS.id,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 7,
                    },
                    position: {
                        x: 0,
                        y: 14,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "8topPostsEngagement": {
                col: 6,
                resource: "analytics.analytics.topPostsByEngagement",
                widget: new BaseWidgetEntity({
                    id: 9,
                    name: "Top 5 Posts by Engagement",
                    type: WIDGET_TYPE_CHART,
                    metrics: WIDGET_METRIC_OPTION_TOP_POSTS_ENGAGEMENT.id,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 6,
                    },
                    position: {
                        x: 0,
                        y: 28,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [
                    SOCIAL_MEDIA_TYPE_FACEBOOK,
                    SOCIAL_MEDIA_TYPE_LINKEDIN,
                ],
            },
            "9topPostsReach": {
                col: 6,
                resource: "analytics.analytics.topPostsByImpression",
                widget: new BaseWidgetEntity({
                    id: 10,
                    name: "Top 5 Posts by Reach",
                    type: WIDGET_TYPE_CHART,
                    /// Post Reach-nek ez a metrika felel meg
                    //metrics: WIDGET_METRIC_OPTION_TOP_POSTS_IMPRESSIONS.id,
                    // Nem tudom ki vagy, de jól átbasztál :( - ES-100 fix:
                    metrics: WIDGET_METRIC_OPTION_TOP_POSTS_REACH.id,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 6,
                    },
                    position: {
                        x: 6,
                        y: 28,
                    },
                    refreshable: true,
                }),
                availableSocialTypes: [SOCIAL_MEDIA_TYPE_FACEBOOK],
            },
        };
        const allowedWidgets = {};

        for (const widgetKey in allWidget) {
            const widgetConfig = allWidget[widgetKey];
            if (this.resourceService.checkResource(widgetConfig.resource)) {
                allowedWidgets[widgetKey] = widgetConfig;
            }
        }

        this.widgets = allowedWidgets;
    }

    private setFixStaticWidgets() {
        this.staticWidgets = {
            "1mostRecentPosts": {
                col: 6,
                entityType: "post",
                resource: "post.post.posts",
                widget: new BaseWidgetEntity({
                    id: 11,
                    name: "Most recent 5 Posts",
                    type: WIDGET_TYPE_CHART,
                    posts: this.recentPosts,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 7,
                    },
                    position: {
                        x: 0,
                        y: 34,
                    },
                }),
            },
            "2mostRecentTemplates": {
                col: 6,
                resource: "post.template.templates",
                entityType: "postTemplate",
                widget: new BaseWidgetEntity({
                    id: 12,
                    name: "Most recent 5 Templates",
                    type: WIDGET_TYPE_CHART,
                    posts: this.recentTemplates,
                    socialChannel: "facebook",
                    size: {
                        width: 6,
                        height: 7,
                    },
                    position: {
                        x: 6,
                        y: 34,
                    },
                }),
            },
        };
    }

    private getRecentItems() {
        const requests = [];
        const filters = {
            order: this.postActions.getOrderFormat("activeFrom-desc"),
            limit: 5,
        };

        this.postActions.filters = {
            ...this.postActions.filters,
            ...filters,
            ...{ order: this.postActions.getOrderFormat("postID-desc") },
        };
        this.templateActions.filters = {
            ...this.templateActions.filters,
            ...filters,
            ...{
                order: this.postActions.getOrderFormat("templateID-desc"),
            },
        };
        this.mediasService.filters = {
            ...this.mediasService.filters,
            ...filters,
            ...{
                limit: 12,
                order: this.mediasService.getOrderFormat("createDate-desc"),
            },
        };

        requests.push(
            new Promise((resolve, reject) => {
                this.postActions.getItems(
                    ({ posts, count }) => {
                        this.rawPosts = posts;
                        this.postCount = count;
                        resolve(
                            posts.map((post) => {
                                return {
                                    id: post.postID,
                                    createDate: post.activeFrom,
                                    message: truncate(
                                        post.mainCopy ||
                                            post.headline ||
                                            post.subHeadline,
                                        { length: 70 }
                                    ),
                                    socialType: post.socialSites[0].socialType,
                                    gmbType:
                                        post.gmbOptions !== null
                                            ? post.gmbOptions?.type
                                            : false,
                                };
                            })
                        );
                    },
                    (error) => reject(error)
                );
            })
        );

        requests.push(
            new Promise((resolve, reject) => {
                this.templateActions.getItems(
                    (response) => {
                        this.rawTemplates = response.templates;
                        this.templateCount = response.count;
                        resolve(
                            response.templates.map((template) => {
                                return {
                                    id: template.templateID,
                                    createDate: template.createDate,
                                    message: truncate(
                                        template.mainCopy ||
                                            template.headline ||
                                            template.subHeadline,
                                        { length: 70 }
                                    ),
                                    socialType: template.socialType,
                                    gmbType:
                                        "gmbOptions" in template
                                            ? template.gmbOptions?.type
                                            : null,
                                };
                            })
                        );
                    },
                    (error) => reject(error)
                );
            })
        );

        requests.push(this.mediasService.getItemCount());
        // requests.push(this.mediasService.getItems()); -- until we fix media performance, we only get the count

        return Promise.all(requests).then((responses) => {
            this.recentPosts = responses[0];
            this.recentTemplates = responses[1];
            //this.recentMedias = responses[2].medias;

            this.mediaCount = responses[2].count;

            this.setFixStaticWidgets();

            return Promise.resolve(responses);
        });
    }

    private setDateRangeOptions() {
        const year = moment().format('YYYY');
        const month = moment().format('MM');
        const lastDayOfMonth = moment(new Date(Number(year), Number(month), 0)).format('DD');
        const today = new Date();
        const defaultFrom = new Date(`${year}-${month}-01`);
        const defaultTo = new Date(Utils.moment().subtract(1, 'days').format(BASE_DATE_FORMAT));
        /* const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0); */
        // min date is 10 years before
        const minDate = new Date(Utils.moment().subtract(10, 'years').format(BASE_DATE_FORMAT));
        // max date is yesterday
        const maxDate = new Date(Utils.moment().subtract(1, 'days').format(BASE_DATE_FORMAT));

        this.dateRengeDefaultFrom = defaultFrom;
        this.dateRengeDefaultTo = defaultTo;

        this.setupPresets();

        this.dateRangeOptions = {
            presets: this.presets,
            format: "dd MMMM yyyy",
            range: { fromDate: defaultFrom, toDate: defaultTo },
            applyLabel: "Submit",
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: false,
                hasBackdrop: true, // do not change to false, because it is buggy
            },
            // cancelLabel: 'Cancel',
            // excludeWeekends:true,
            fromMinMax: {fromDate: minDate, toDate: maxDate},
            toMinMax: {fromDate: minDate, toDate: maxDate}
        };
    }

    private setupPresets() {
        const backDate = (numOfDays) => {
            const _today = new Date();

            return new Date(_today.setDate(_today.getDate() - numOfDays));
        };

        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const currMonthEnd = new Date(Utils.moment().subtract(1, 'days').format(BASE_DATE_FORMAT));
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.presets = [
            {presetLabel: 'Yesterday', range: {fromDate: yesterday, toDate: yesterday}},
            {presetLabel: 'Last 7 Days', range: {fromDate: minus7, toDate: yesterday}},
            {presetLabel: 'Last 30 Days', range: {fromDate: minus30, toDate: yesterday}},
            {presetLabel: 'This Month', range: {fromDate: currMonthStart, toDate: currMonthEnd}},
            {presetLabel: 'Last Month', range: {fromDate: lastMonthStart, toDate: lastMonthEnd}}
        ];
    }
}
