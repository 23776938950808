import {PostActionsService} from './post-actions.service';
import {Injectable} from '@angular/core';
import {PostActions} from './post-actions';
import {MatDialog} from '@angular/material/dialog';
import {UsersController} from '../users/users/users.component';
import {CarouselController} from './carousel/carousel.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';

@Injectable({providedIn: 'root'})
export class PostController extends PostActions {


    constructor(
        public service: PostActionsService,
        public dialog: MatDialog,
        public users: UsersController,
        carouselController: CarouselController,
        public openModal: OpenModalService
    ) {
        super(service, dialog, users, carouselController, openModal);
    }

    successGetItems(response: any, callback: () => void = () => {}): void {}
}
