import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface DialogData {
    message: string;
}

@Component({
    selector: 'smd-dialog-warn',
    templateUrl: './dialog-warn.component.html',
    styleUrls: ['./dialog-warn.component.scss']
})
export class DialogWarnComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogWarnComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
    }

}
