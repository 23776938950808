import AbstractPreviewAdapter from "~/src/app/modules/posts/post-previews/abstract-preview-adapter";

class Adapter extends AbstractPreviewAdapter {
    adapt(value: string, isLinkShare = null): string {
        value = super.adapt(value);
        
        this.contentLines = this.contentLines.map((str) => str.replace(/ ?\r ?/g, ""));
        this.contentLines = this.contentLines.filter((str) => str !== "<p></p>");
        this.contentLines = this.contentLines.filter((str) => str !== "<p> </p>");

        this.contentLines = this.contentLines.map((str, index) => {
            const prevLine = this.contentLines[index - 1] || "";

            if (prevLine.includes("<p>")) {
                str = str.replace(this.lineBreakRegex, "");
            }

            return str;
        });

        value = this.contentLines.join("");

        return value;
    }
}

export const LinkedInPreviewAdapter = new Adapter();
