import {Component, ComponentRef, HostListener, Inject, Input, OnInit, ViewContainerRef} from '@angular/core';
import {SmdFileInterface} from '../../services/file.class';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';

@Component({
    selector: 'smd-right-side-bar',
    templateUrl: './right-side-bar.component.html',
    styleUrls: ['./right-side-bar.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                transform: 'translateX(0)'
            })),
            state('closed', style({
                transform: 'translateX(100%)'
            })),
            transition('open => closed', [
                animate('.2s')
            ]),
            transition('closed => open', [
                animate('.2s')
            ]),
        ])
    ]
})
export class RightSideBarComponent implements OnInit {
    @Input() media: SmdFileInterface;
    @Input() elementRef: ComponentRef<any>;
    isOpen = false;
    wasInside = false;
    hasMediaTag = false;
    hasMediaCategory = false;

    constructor(
        @Inject(ViewContainerRef) public viewContainerRef: ViewContainerRef,
        private partnerConfig: PartnerConfigService
    ) {
        this.hasMediaTag = this.partnerConfig.hasConfig(PartnerPermissions.MediaTag);
        this.hasMediaCategory = this.partnerConfig.hasConfig(PartnerPermissions.MediaCategory);
    }

    ngOnInit() {
        setTimeout(() => {
            this.isOpen = true;
        }, 100);
    }

    close() {
        this.isOpen = !this.isOpen;
    }

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickOut() {
        if (!this.wasInside) {
            this.isOpen = false;
        }
        this.wasInside = false;
    }

    get fileTypes() {
        return FILE_TYPES;
    }
}
