import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Injectable,
    Input,
    LOCALE_ID,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import {CalendarUtils, CalendarWeekViewComponent, DateAdapter} from 'angular-calendar';
import * as moment from 'moment';
import {
    BASE_DATE_FORMAT,
    BASE_DATETIME_FORMAT,
    BASE_TIME_FORMAT,
    FALLBACK_IMAGE,
    PLACEHOLDER_IMAGE
} from '~/src/app/configs/configs';
import {LanguageService} from '~/src/app/services/language.service';
import {forEach, trim, truncate} from 'lodash';
import {Helpers} from '~/src/app/services/helpers';
import Utils from '~/src/app/core/utils';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';
import {WeekView} from 'calendar-utils';
import {Subject} from 'rxjs';
import {LoggedUser} from '~/src/app/services/logged-user';
import {DateSupport} from '~/src/app/core/helper/date-support';

@Injectable()
export class MyCalendarUtils extends CalendarUtils {
    getWeekView(opts) {
        opts.absolutePositionedEvents = false;
        return super.getWeekView(opts);
    }
}

declare var $;

@Component({
    selector: 'smd-custom-week-view',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './custom-week-view.component.html',
    styleUrls: ['./custom-week-view.component.scss'],
    providers: [{
        provide: CalendarUtils,
        useClass: MyCalendarUtils,
    }]
})
export class CustomWeekViewComponent extends CalendarWeekViewComponent implements OnInit {

    draggedEl = null;

    @Input() postListRefreshed: Subject<any>;

    @Output() dropOn: EventEmitter<any> = new EventEmitter();
    @Output() dragOver: EventEmitter<any> = new EventEmitter();
    @Output('hourContextmenu') hourContextmenu: EventEmitter<any> = new EventEmitter<any>();

    viewHours: any[] = [];
    placeholderImg = PLACEHOLDER_IMAGE;
    todaysDate = moment(
        moment.tz(moment().utc(),LoggedUser.getUser().settings.defaultTimeZone ||
                DateSupport.getClientTimeZoneName()).format("YYYY-MM-DD HH:mm").toString()).toDate();

    constructor(
        _cdr: ChangeDetectorRef,
        _utils: CalendarUtils,
        @Inject(LOCALE_ID) _locale: string,
        _dateAdapter: DateAdapter,
        private _el: ElementRef,
        private _zone: NgZone,
        public languageService: LanguageService
    ) {
        super(_cdr, _utils, _locale, _dateAdapter);
    }

    ngOnInit() {
        this._zone.runOutsideAngular(() => {
            this._el.nativeElement.addEventListener(
                'drop', this.drop_handler.bind(this)
            );
            this._el.nativeElement.addEventListener(
                'dragend', this.dragend_handler.bind(this)
            );
            this._el.nativeElement.addEventListener(
                'dragover', this.dragover_handler.bind(this)
            );
            this._el.nativeElement.addEventListener(
                'dragenter', this.dragenter_handler.bind(this)
            );
            this._el.nativeElement.addEventListener(
                'dragleave', this.dragleave_handler.bind(this)
            );
        });

        if (this.postListRefreshed) {
            this.postListRefreshed.subscribe((data) => this.setPosts(data.events));
        }
    }

    /**
     * @param {any[]} events
     */
    setPosts(events = null) {
        const hours = Utils.get<WeekView>(this.view, 'hourColumns[0].hours', []);
        const viewHours = [];

        hours.forEach(hour => {
            const time = hour.segments[0].date;

            viewHours.push({
                time,
                days: this.days.map(day => {
                    return {
                        events: this.getEventsWithDay(day, time, events).events,
                        day
                    };
                })
            });
        });

        this.viewHours = viewHours;
    }

    /**
     * Get events for that day and hour
     * @param day -  Current week date
     * @param hourDate - current hour segment date
     * @param {any[]} events
     */
    getEventsWithDay(day, hourDate, events = null) {
        const check = moment(day.date, 'YYYY/M/D');
        const check2 = moment(hourDate, 'YYYY/M/D');
        const dateYear = check.format('YYYY');
        const dateMonth = check.format('M');
        const dateDay = check.format('D');
        const dateHour = check2.format('hh');
        const dateZone = check2.format('a');

        let currentEvents = [];

        currentEvents = (events || this.events)
            .filter(function (currentValue) {
                const startDate = moment(currentValue.start, 'YYYY/M/D');
                const startDateYear = startDate.format('YYYY');
                const startDateMonth = startDate.format('M');
                const startDateDay = startDate.format('D');
                const startDateHour = startDate.format('hh');
                const startDateZone = startDate.format('a');

                return (dateYear === startDateYear && dateMonth === startDateMonth && dateDay === startDateDay && dateHour === startDateHour && dateZone === startDateZone);
            })
            .map(event => {

                const post = event.meta.post;

                if (post) {
                    event.meta.post.platform = CommonPostHelpers.getSource(post);
                    event.meta.post.cover = CommonPostHelpers.getCoverMediaSrc(post);
                }

                return event;
            });

        return {
            events: currentEvents
        };
    }

    getCuttedHeadline(post) {
        if (!post.headline || post.headline === '') {
            return '';
        }
        return ' - ' + truncate(post.headline, {length: 20});
    }

    /**
     * Get all category of the post
     * @param post
     * @return {any}
     */
    getCategories(post: any) {
        if (post.categories && post.categories.length > 0) {
            return post.categories;
        } else {
            return false;
        }
    }

    /**
     * Get social type of the post
     * @param post
     * @return {any}
     */
    getPostSocialType(post) {
        if (post && post.socialType && post.socialType !== '') {

            if (Array.isArray(post.socialType) && post.socialType.length) {
                return post.socialType[0];
            }

            return post.socialType;
        }

        return '';
    }

    /**
     * Check post status
     * @param postStatus
     * @param statuses
     * @return {boolean}
     */
    postStatusIs(postStatus, statuses, post) {
        let result = false;
        const repeatIndex = Utils.get(post, 'repeatIndex', null);
        const socialSiteID = Utils.get(post, `socialSites[0].socialSiteID`, null);

        if (!!socialSiteID && !!repeatIndex) {
            postStatus = Utils.get(post, `socialSiteStatuses[${repeatIndex}][${socialSiteID}].postStatus`, postStatus);
        }

        forEach(statuses, (status) => {
            if (status === trim(postStatus)) {
                result = true;
            }
        });

        if (result && post.type === 'draft' && statuses[0] !== 'draft') {
            return false;
        }

        return result;
    }

    strip(string: string) {
        if (!string || typeof string !== 'string') {
            return string;
        }

        return Helpers.strip(string);
    }

    /**
     *  drag and drop methods
     *  */
    private dragstart_handler(event: DragEvent, eventItem) {

        if (this.isAutoFeed($(event.target).data('type')) || !$(event.target).data() || $(event.target).data().length === 0) {

            return;
        }

        this.draggedEl = event.target;

        $(event.target).css('opacity', 0.6);
        $(event.target).addClass('grabbing');

        const targetID = $(event.target).find('id').data('id');
        event.dataTransfer.setData('text', targetID);
    }

    private dragenter_handler(event: DragEvent) {
        this.dragOver.emit(true);

        const target = event.target;

        if (this.isNotPass(target)) {
            $(target).addClass('entered');
        }
    }

    private dragend_handler(event: DragEvent) {
        this.dragOver.emit(false);
        event.preventDefault();

        $(event.target).css('opacity', '');
        this.draggedEl = null;

        return false;
    }

    private dragleave_handler(event: DragEvent) {
        this.dragOver.emit(false);

        const target = event.target;
        $(target).removeClass('entered');
    }

    private dragover_handler(event: DragEvent) {
        event.preventDefault();

        event.dataTransfer.dropEffect = 'move';

        return false;
    }

    private drop_handler(event: DragEvent) {
        event.preventDefault();

        const target = event.target,
            dayDate = $(target).data('daydate'),
            hourDate = $(target).data('hourdate');

        if (target && $(target).hasClass('cal-week-view-container') && this.isNotPass(target)) {
            $(target).removeClass('entered');
            $(this.draggedEl).parent().find(this.draggedEl).remove();
            $(target).append(this.draggedEl);

            const newSlotDate = moment(dayDate).set('hour', Number(moment(hourDate).format('HH'))).set('minute', Number(moment(hourDate).format('mm'))).format(BASE_DATETIME_FORMAT);
            const $activeFromElement = $(this.draggedEl).find('.post-hour').find('strong'),
                oldActiveFrom = $(this.draggedEl).data('activefrom'),
                newActiveFrom = moment(newSlotDate).set('minute', Number(moment(oldActiveFrom).format('mm'))).format(BASE_DATETIME_FORMAT);

            $activeFromElement.text(moment(newActiveFrom).format('HH:mm A'));
            $(this.draggedEl).data('activefrom', newActiveFrom);

            // get post id
            const postID = $(this.draggedEl).find('.id').data('id');

            // api küldés a változásról - küld a postID-t és a newDate-et
            // calendar frissítés

            if (oldActiveFrom !== newActiveFrom) {
                this.dropOn.emit({
                    postID: postID,
                    newActiveFrom: newActiveFrom
                });
            }
        }

        return false;
    }

    showMore(event: MouseEvent) {
        const button = event.target,
            $button = $(button);

        const targetEvents = $button.parent().find('.cal-event').removeAttr('hidden');

        $button.parents('.cal-week-view-row').find('.cal-week-view-col:not(.time)').map((index, col) => {
            const $col = $(col);

            const events = $col.find('.cal-event');

            if (events.length <= targetEvents.length) {
                events.removeAttr('hidden');
                $col.find('.post-load-more').fadeOut();
            }
        });

        $button.fadeOut();
    }

    isNotPass(target) {
        const dayDate = $(target).data('daydate'),
            hourDate = $(target).data('hourdate');

        if (!dayDate || !hourDate) {
            return null;
        }

        const fullDateTime = moment(dayDate).format(BASE_DATE_FORMAT) + ' ' + moment(hourDate).format(BASE_TIME_FORMAT);

        return moment(fullDateTime).isAfter();
    }

    isAutoFeed(type) {
        return type === 'autoFeed';
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }

    isDateBefore(date, time): boolean {
        let actualDate = new Date(date);
        actualDate.setHours(time.getHours());
        return moment(actualDate).isBefore(this.todaysDate, 'hour');
    }

    isDateSameOrAfter(date, time): boolean {
        let actualDate = new Date(date);
        actualDate.setHours(time.getHours());
        return moment(actualDate).isSameOrAfter(this.todaysDate, 'hour');
    }
}
