import {Injectable} from '@angular/core';
import {BackendService} from '~/src/app/core/backend.service';
import {ILinkShortenResponse} from '~/src/app/modules/social-media-post/link-shortening.interface';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';

@Injectable({providedIn: 'root'})
export class LinkShorteningService {

    constructor(
        public partnerService: PartnersService,
        private backendService: BackendService
    ) {}

    /**
     * Get short link
     * @param {string} url
     * @return {Promise<ILinkShortenResponse>}
     */
    getShortLink(url: string): Promise<ILinkShortenResponse> {
        const type = this.partnerService.getMyPartnerData().linkShorten;
        return this.backendService.post(`/link-shorten/${type}/shorten`, {url});
    }
}
