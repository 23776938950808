import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AutofeedService} from '~/src/app/modules/autofeed/autofeed.service';
import {LanguageService} from '~/src/app/services/language.service';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';
import {ClickDecorator} from '~/src/app/core/decorators';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    IScheduleRulePresetFormData,
    IScheduleRulePresetModalFormData
} from '~/src/app/modules/autofeed/interfaces/schedule-rule.interface';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {Category} from '~/src/app/modules/posts/template.interface';
import {CategorySelectComponent} from '~/src/app/core/components/category-select/category-select.component';
import Utils from '~/src/app/core/utils';

@Component({
    selector: 'smd-schedule-rule-preset-modal-form',
    templateUrl: './schedule-rule-preset-modal-form.component.html',
    styleUrls: ['./schedule-rule-preset-modal-form.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class ScheduleRulePresetModalFormComponent implements OnInit {
    controlNames = {
        Name: 'name',
        Period: 'period',
        Hour: 'hour',
        Minute: 'minute',
        Category: 'category',
        Organization: 'organizationID'
    };
    hours = CommonPostHelpers.getHourSelectOptions();
    minutes = CommonPostHelpers.getMinuteSelectOptions();
    formGroup = new FormGroup({
        [this.controlNames.Organization]: new FormControl(
            this.dialogData?.rulePreset?.organizationID || [],
            [Validators.required]
        ),
        [this.controlNames.Name]: new FormControl(
            this.dialogData?.rulePreset?.name || null,
            [Validators.required, Validators.minLength(3)]
        ),
        [this.controlNames.Category]: new FormControl(
            this.dialogData?.rulePreset?.categoryID || null,
            [Validators.required]
        ),
        [this.controlNames.Period]: new FormControl(
            this.dialogData?.rulePreset?.period || null,
            [Validators.required]
        ),
        [this.controlNames.Hour]: new FormControl(
            Utils.get(this.dialogData, 'rulePreset.hour', null),
            [Validators.required]
        ),
        [this.controlNames.Minute]: new FormControl(
            Utils.get(this.dialogData, 'rulePreset.minute', null),
            [Validators.required]
        )
    });
    @ViewChild(CategorySelectComponent, {read: CategorySelectComponent}) categorySelect: CategorySelectComponent;

    constructor(
        public autofeedService: AutofeedService,
        public languageService: LanguageService,
        public formHelpers: FormHelpersService,
        @Inject(MAT_DIALOG_DATA) public dialogData: IScheduleRulePresetModalFormData,
        private componentHelpers: ComponentHelpers,
        private dialogRef: MatDialogRef<ScheduleRulePresetModalFormComponent>
    ) {}

    ngOnInit(): void {
        this.formHelpers.formInit(this.formGroup);
    }

    @ClickDecorator()
    presetCreate(event: MouseEvent): void {
        this.formHelpers.submit(this.formGroup, () => {
            this.componentHelpers.startApiAction(
                () => this.autofeedService.createPreset(this.formDataToRequestData()),
                {
                    successMessageKey: 'post.autoFeedPreset.create.success',
                    failedMessageKey: 'post.autoFeedPreset.create.failed',
                    afterSuccessAction: response => {
                        if (this.dialogData?.afterSuccessAction instanceof Function) {
                            this.dialogData.afterSuccessAction(response);
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: error => {
                        this.formHelpers.validateForm(this.formGroup, FormValidationService.readError(error).formMessages)
                    }
                }
            );
        });
    }

    @ClickDecorator()
    presetSave(event: MouseEvent) {
        this.formHelpers.submit(this.formGroup, () => {
            this.componentHelpers.startApiAction(
                () => this.autofeedService.savePreset(this.dialogData.rulePreset.presetID, this.formDataToRequestData()),
                {
                    successMessageKey: 'post.autoFeedPreset.modify.success',
                    failedMessageKey: 'post.autoFeedPreset.modify.failed',
                    afterSuccessAction: response => {
                        if (this.dialogData?.afterSuccessAction instanceof Function) {
                            this.dialogData.afterSuccessAction(response);
                        }

                        this.dialogRef.close();
                    },
                    afterFailedAction: error => {
                        this.formHelpers.validateForm(this.formGroup, FormValidationService.readError(error).formMessages)
                    }
                }
            );
        });
    }

    /**
     * Convert form data to request data
     * @return {IScheduleRulePresetFormData}
     */
    private formDataToRequestData(): IScheduleRulePresetFormData {
        const data = this.formGroup.value;
        const categoryID: number = data[this.controlNames.Category];
        const category: Category = this.categorySelect.categories.find(_category => _category.categoryID === categoryID);

        return {
            name: data[this.controlNames.Name],
            categoryName: category.name,
            categoryID: category.categoryID,
            period: data[this.controlNames.Period],
            hour: data[this.controlNames.Hour],
            minute: data[this.controlNames.Minute],
            organizationID: data[this.controlNames.Organization]
        };
    }
}
