import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChildren} from '@angular/core';
import {PostTemplateInterface} from '~/src/app/modules/posts/template.interface';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {PostInterface} from '~/src/app/modules/posts/post-actions';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {
    PostTemplateFiltersComponent,
    TemplateFiltersInterface
} from '~/src/app/modules/posts/post-template-filters/post-template-filters.component';
import {Helpers} from '~/src/app/services/helpers';
import {TemplateActionsController} from '~/src/app/modules/posts/template-actions';
import Utils from '~/src/app/core/utils';
import { UsersController } from '../../users/users/users.component';
import { IRelatedUser } from '../../users/users/users.interface';

@Component({
    selector: 'smd-post-template-chooser',
    templateUrl: './post-template-chooser.component.html',
    styleUrls: ['./post-template-chooser.component.scss'],
    providers: [
        TemplateActionsController
    ]
})
export class PostTemplateChooserComponent implements OnInit, AfterViewInit {
    @Output() selectionChange = new EventEmitter();
    @Output() filtersChange = new EventEmitter();
    @ViewChildren(PostTemplateFiltersComponent) filterFormInDialog;
    postTemplates: PostTemplateInterface[] = [];
    templatesPaginationController: PaginationController = new PaginationController();
    postTemplatesPending = false;
    relatedUsers: Array<IRelatedUser> = [];

    constructor(
        private openModal: OpenModalService,
        private templateService: TemplateActionsController,
        public users: UsersController,
    ) {
        this.templatesPaginationController.onSetPagination.subscribe(result => {

            this.templateService.filters['offset'] = result.offset;
            this.templateService.filters['limit'] = result.limit;

            this.postTemplatesInit();
        });
    }

    ngOnInit() {
        this.postTemplatesInit();
        this.getRelatedUsers();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.presetFilters();
        });
    }

    getRelatedUsers() {
        this.users.service.getRelatedUsers()
        .then((response) => {
            this.relatedUsers = response.users;
        })
        .catch((error) => {
            this.templateService.service.showErrorAlert('Unexpected error while getting related users'); //TODO: language
        });
    }

    /**
     * Select source template
     *
     * @param {PostTemplateInterface} template
     */
    selectTemplate(template: PostInterface) {
        this.emitSelectionChange(template);
    }

    /**
     * Change templates filter
     *
     * @param filters
     */
    changeTemplatesFilter(filters: TemplateFiltersInterface) {

        Utils.lodash.forEach(filters, (filter, filterName) => {
            if (filterName === 'order') {
                filter = Helpers.getOrderFormat(filter as string);
            }

            if (filter && filter !== '') {
                this.templateService.filters[filterName] = filter;
            } else if (filterName in this.templateService.filters) {
                delete this.templateService.filters[filterName];
            }
        });

        this.emitFiltersChange(this.templateService.filters);

        this.templatesPaginationController.paginationOptions.pageIndex = 0;
        this.templatesPaginationController.setPagination(this.templatesPaginationController.paginationOptions);
    }

    presetFilters() {
        if (this.filterFormInDialog.first && this.filterFormInDialog.first.hasOwnProperty('form')) {
            this.filterFormInDialog.first.form.controls['socialSiteSelectFormControl'].disable();
            this.filterFormInDialog.first.form.controls['tagsFormControl'].enable();
            this.filterFormInDialog.first.form.controls['dateFromFormControl'].disable();
            this.filterFormInDialog.first.form.controls['dateToFormControl'].disable();
            this.filterFormInDialog.first.form.controls['categoriesFormControl'].enable();
            this.filterFormInDialog.first.form.controls['statusFormControl'].disable();
            this.filterFormInDialog.first.form.controls['orderByFormControl'].disable();
            this.filterFormInDialog.first.form.controls['searchValueFormControl'].enable();
            this.filterFormInDialog.first.form.controls['definedDatesFormControl'].disable();
            this.filterFormInDialog.first.form.controls['organizationID'].enable();
            this.filterFormInDialog.first.form.controls['templateSystemType'].enable();
        }
    }

    /**
     * Get post templates
     */
    private postTemplatesInit() {

        this.templateService.filters['status'] = 'active';
        this.templateService.filters['order'] = Helpers.getOrderFormat('createDate-desc');

        this.emitFiltersChange(this.templateService.filters);

        this.postTemplatesPending = true;

        this.templateService.getItems(
            ({templates, count}: { templates: any[], count: number }) => {
                if (!count) {
                    // A DB nem tartalmaz post template-t
                }
                this.postTemplates = templates;
                this.postTemplatesPending = false;

                this.templatesPaginationController.paginationOptions.length = count;
            },
            error => {
                this.postTemplatesPending = false;
                this.openModal.errorModal(DialogErrorComponent, {
                    message: FormValidationService.readError(error).message
                }).afterClosed().subscribe(() => {
                    // Nem jöttek le a post template-k, a create post modalon ezt le kell kezelni
                });
            }
        );
    }

    /**
     * Trigger selection change event
     * @param data
     */
    private emitSelectionChange(data) {
        this.selectionChange.emit(data);
    }

    /**
     * Trigger filters change
     * @param filters
     */
    private emitFiltersChange(filters) {
        this.filtersChange.emit(filters);
    }
}
