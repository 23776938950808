import {CarouselService} from './carousel.service';
import {ComponentAbstract} from '../../../services/component.abstract';
import {CarouselItemResponseInterface} from './carousel.interface';
import {Token} from '../../../services/token';
import {Injectable} from '@angular/core';
import {environment} from '~/src/environments/environment';
import { cloneDeep } from 'lodash';

export class CarouselComponent extends ComponentAbstract {

    constructor(public service: CarouselService) {
        super();
    }

    /**
     * Get carousel items by carouselID
     *
     * @param {number} carouselID
     * @param {(response: any) => void} success
     * @param {(error: any) => void} failed
     */
    getCarouselItems(carouselID: number, success: (response: any) => void = () => {}, failed: (error: any) => void = () => {}) {
        return this.service.getCarouselItems(carouselID)
            .then(response => {
                success(response);
                return response;
            })
            .catch(error => {
                failed(error);
                return error;
            });
    }

    /**
     * Get carousel items by carousel IDs
     *
     * @param {number[]} carouselIDs
     * @return {Promise<any>}
     */
    getCarouselItemsByIDs(carouselIDs: number[], isAdminMode = false): Promise<any> {
        return this.service.getCarouselItemsByIDs(carouselIDs, isAdminMode);
    }


    /**
     * Delete carousel
     * @param {string} data
     * @returns {Promise<any>}
     */
    deleteCarouselByID(carouselID: any): Promise<any> {
        return this.service.deleteCarouselByID(carouselID);
    }

}

export class CarouselItem implements CarouselItemResponseInterface {
    description: string;
    name: string;
    url: string;

    carouselID: number;
    carouselName: string;
    imageFileID: number;
    imageUrl: string;
    itemID: number;
    position: number;
    status: string;
    raw: CarouselItemResponseInterface;

    constructor(item: CarouselItemResponseInterface) {
        this.carouselID = item.carouselID;
        this.carouselName = item.carouselName;
        this.imageFileID = item.imageFileID;
        this.imageUrl = item.imageUrl;
        this.itemID = item.itemID;
        this.position = item.position;
        this.status = item.status;

        this.description = item.description;
        this.name = item.name;
        this.url = item.url;
        this.raw = cloneDeep(item);
    }

    public static initCarouselItemArray(items: CarouselItemResponseInterface[]) {
        return items.map(item => new CarouselItem(item));
    }
}

@Injectable({providedIn: 'root'})
export class CarouselController extends CarouselComponent {

    constructor(service: CarouselService) {
        super(service);
    }
}
