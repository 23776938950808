import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TemplateFolderInterface } from "../template-folder.interface";

@Component({
  selector: "smd-folder-card",
  templateUrl: "./folder-card.component.html",
  styleUrls: ["./folder-card.component.scss"],
})
export class FolderCardComponent implements OnInit {
  @Input() folder: TemplateFolderInterface;

  @Output("pinFolder") pinFolderEvent: EventEmitter<TemplateFolderInterface> = new EventEmitter<TemplateFolderInterface>();
  @Output("editFolder") editFolderEvent: EventEmitter<TemplateFolderInterface> = new EventEmitter<TemplateFolderInterface>();
  @Output("shareFolder") shareFolderEvent: EventEmitter<TemplateFolderInterface> = new EventEmitter<TemplateFolderInterface>();
  @Output("deleteFolder") deleteFolderEvent: EventEmitter<TemplateFolderInterface> = new EventEmitter<TemplateFolderInterface>();
  @Output("openFolder") openFolderEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    console.log("FolderCardComponent constructor");
  }

  ngOnInit() {
    console.log("FolderCardComponent ngOnInit");
  }

  /* Folder action emitters */
  pinFolder() {
    this.pinFolderEvent.emit(this.folder);
  }

  editFolder() {
    this.editFolderEvent.emit(this.folder);
  }

  shareFolder() {
    this.shareFolderEvent.emit(this.folder);
  }

  deleteFolder() {
    this.deleteFolderEvent.emit(this.folder);
  }

  openFolder() {
    this.openFolderEvent.emit(this.folder.folderID);
  }
}