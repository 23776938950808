import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FILE_TYPES} from "~/src/app/components/file/types/fileTypes";
import EditorControllerService from "~/src/app/components/image-editor/editor-controller.service";

@Component({
  selector: 'smd-image-browser',
  templateUrl: './image-browser.component.html',
  styleUrls: ['./image-browser.component.scss']
})
export class ImageBrowserComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ImageBrowserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    fileSelected(file) {
        EditorControllerService.selectFile(file.file);
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
    }

    get fileTypes() {
        return FILE_TYPES;
    }
}
