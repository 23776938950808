import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '~/node_modules/@angular/forms';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {LanguageService} from '~/src/app/services/language.service';
import {Partner} from '~/src/app/shared/types/partners.model';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {Configs} from '~/src/app/configs/configs';
import {TemplateActionsService} from '~/src/app/modules/posts/template-actions.service';
import {PostTemplateImportDialogData} from '~/src/app/modules/administration/post-template-import/post-template-import.interfaces';
import {FormValidationService} from '~/src/app/services/form.validation.service';

@Component({
    selector: 'smd-post-template-import',
    templateUrl: './post-template-import.component.html',
    styleUrls: ['./post-template-import.component.scss'],
    providers: [
        FormHelpersService,
        ComponentHelpers
    ]
})
export class PostTemplateImportComponent {
    importControlNames = {
        File: 'file',
        PartnerIDs: 'partnerIDs',
        SocialType: 'socialType'
    };
    importFormGroup = new FormGroup({
        [this.importControlNames.File]: new FormControl(null, [Validators.required]),
        [this.importControlNames.PartnerIDs]: new FormControl(null),
        [this.importControlNames.SocialType]: new FormControl(null, [Validators.required]),
    });
    socialTypes = Configs.socials;
    partners: Partner[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: PostTemplateImportDialogData,
        public dialogRef: MatDialogRef<PostTemplateImportComponent>,
        public languageService: LanguageService,
        public formHelpers: FormHelpersService,
        public componentHelpers: ComponentHelpers,
        public partnerService: PartnersService,
        private postTemplateService: TemplateActionsService,
    ) {
        this.formHelpers.formInit(this.importFormGroup);
        this.partnerService.partners.subscribe(partners => this.partners = partners);
        this.partnerService.getPartners();
    }

    /**
     * Import templates from archive file
     * @param event
     */
    importTemplates(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.importFormGroup.valid) {

            this.componentHelpers.startApiAction(
                () => {
                    return this.postTemplateService.importTemplates({
                        ...this.importFormGroup.getRawValue(),
                        [this.importControlNames.File]: this.importFormGroup.get(this.importControlNames.File).value.files[0]
                    });
                },
                {
                    successMessageKey: 'templateList.import.success',
                    failedMessageKey: 'templateList.import.error',
                    afterSuccessAction: (response) => {
                        this.dialogRef.afterClosed().subscribe(() => {
                            if (!!this.dialogData.afterSuccessImport) {
                                this.dialogData.afterSuccessImport(response);
                            }
                        });
                        this.dialogRef.close();
                    },
                    afterFailedAction: (error) => {
                        this.formHelpers.validateForm(this.importFormGroup, FormValidationService.readError(error).formMessages || {})
                        this.dialogRef.afterClosed().subscribe(() => {
                            if (!!this.dialogData.afterFailedImport) {
                                this.dialogData.afterFailedImport(error);
                            }
                        });
                    }
                }
            );

        } else {
            this.formHelpers.validateForm(this.importFormGroup);
        }
    }
}
