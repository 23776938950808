import { BulkImportItemModel } from "~/src/app/modules/posts/bulk-import-item.model";
import Utils from "~/src/app/core/utils";
import { BASE_DATETIME_FORMAT } from "~/src/app/configs/configs";
import { ObjectSupport } from "~/src/app/core/helper/object-support";
import { LoggedUser } from "~/src/app/services/logged-user";
import { DateSupport } from "~/src/app/core/helper/date-support";
import { LanguageService } from "~/src/app/services/language.service";
import { SmdFile } from "~/src/app/services/file.class";
import { MediaPostRequestKeys } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.options";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { SOCIAL_MEDIA_TYPE_GMB } from "../../core/constants";

export class BulkImportPostItemModel extends BulkImportItemModel {
    type: "customFeed" | "autoFeed";
    _activeFrom: string;
    postID: number;
    socialSiteIDs: number[];

    get activeFrom() {
        return this._activeFrom;
    }

    set activeFrom(value) {
        this._activeFrom = value;

        if (!value) {
            if (this.type === "autoFeed") {
                this.removePropertyError("activeFrom");
            }

            return;
        }

        this.checkScheduleDate();
    }

    constructor(props) {
        super(props);

        this.type =
            (props.type && props.type === "autoFeed") ||
            (props.autoFeed && props.autoFeed === "yes")
                ? "autoFeed"
                : "customFeed";
        this.activeFrom = props.activeFrom;
        this.postID = props.postID || null;
        this.socialSiteIDs = props.socialSiteIDs || [];

        if (props.archive) {
            const data = ObjectSupport.parse(props.archive);

            if (data && "autoFeed" in data && data.autoFeed === "yes") {
                this.type = "autoFeed";
            }
        }

        this.isDatabaseEntity = !!this.postID;

        return this;
    }

    getRequestData() {
        return {
            ...super.getRequestData(),
            postID: this.postID,
            draftID: this.postID,
            [PostFormControlNames.ActiveFrom]: Utils.moment(
                this.activeFrom
            ).format(BASE_DATETIME_FORMAT),
            type: this.type,
            id: this.id,
        };
    }

    getDraftRequestData(): any {
        const requestData = super.getDraftRequestData(true);
        let archive = null;

        if (this.socialType === SOCIAL_MEDIA_TYPE_GMB) {
            archive = JSON.parse(this.archive);
        }

        return {
            posts: [
                {
                    socialSiteIDs: this.socialSiteIDs,
                    organizationIDs: this.organizationIDs,
                    categories: [this.category],
                    type: "draft",
                    socialChannels: {
                        default: {
                            type: this.type,
                            ...requestData,
                            [MediaPostRequestKeys.fileIDs]:
                                requestData.fileIDs || [],
                            medias: (this.medias || []).map((media) => {
                                return SmdFile.getRawMedia(media);
                            }),
                            activeFrom: Utils.moment(this.activeFrom).format(
                                BASE_DATETIME_FORMAT
                            ),
                            gmbOptions: archive ? archive.gmbOptions : null,
                        },
                    },
                },
            ],
        };
    }

    /**
     * Validate schedule time
     */
    checkScheduleDate() {
        if (
            Utils.moment(this.activeFrom).isValid() ||
            Utils.moment(this.activeFrom, "DD-MM-YYYY HH:mm").isValid() ||
            Utils.moment(this.activeFrom, "YYYY-MM-DD HH:mm").isValid()
        ) {
            this.removePropertyError("activeFrom");
        } else {
            // TODO: from language
            this.addPropertyError("activeFrom", "The format is invalid!");

            return;
        }

        const userTimezone = LoggedUser.getUserDefaultTimeZoneName();
        const currentTime = DateSupport.moment().tz(userTimezone);
        const scheduleTime = DateSupport.moment(this.activeFrom);

        if (DateSupport.moment(currentTime).isAfter(scheduleTime)) {
            this.addPropertyMessage(
                "activeFrom",
                LanguageService.getLine(
                    "post.post.scheduleTime.early.warning.message"
                ),
                "warning"
            );
        }
    }

    /**
     * Check entity type is autofeed
     *
     * @returns {boolean}
     */
    isAutofeed(): boolean {
        return this.type === "autoFeed";
    }
}
