import {Injectable} from '@angular/core';
import {ModelAbstract} from '~/src/app/services/model.abstract';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class TimezoneService extends ModelAbstract {
    timezones;

    constructor(http: HttpClient, dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/social-sites/timezones';
    }

    getAll(filters?: object): Promise<any> {
        if (this.timezones) {
            return Promise.resolve(this.timezones);
        } else {
            return super.getAll(filters).then(response => {
                this.timezones = response;
                return Promise.resolve(response);
            });
        }
    }
}
