import {FacebookService, InitParams, LoginOptions, LoginResponse} from 'ngx-facebook';
import {MatDialog} from '@angular/material/dialog';
import {FacebookUser} from '../../services/helpers';
import {environment} from '~/src/environments/environment';
import { BackendService } from '../../core/backend.service';


export class Facebook {
    constructor(
        private fb: FacebookService,
        public _dialog: MatDialog,
    ) {


        const initParams: InitParams = {
            appId: environment.facebookAppID,
            cookie: true,
            xfbml: true,
            version: 'v18.0'    //just to change the facebook version 12 to 18
        };


        fb.init(initParams);
        //this.getStatus(); TODO: Tesztelni
    }


    getStatus() {
        this.fb.getLoginStatus().then((res: LoginResponse) => {
            if (res.status === 'connected') {
                //console.log('You are logged in to Facebook.');
            } else if (res.status === 'authorization_expired') {
                //console.log('Authorization has expired.');
                this.loginWithOptions();
            } else if (res.status === 'not_authorized') {
                //console.log('The Application hasn\'t been authorized.');
            } else {
                //console.log('Not logged in to Facebook.');
            }
        }).catch(e => console.log(e));
    }


    loginWithOptions(type: 'page' | 'group' | 'simple' = 'simple'): Promise<any> {
        const permissions = {
            page: [
                'pages_manage_metadata',
                'pages_read_engagement',
                'pages_read_user_content',
                'pages_manage_posts',
                'pages_manage_engagement',
                'pages_show_list,read_insights',
                'instagram_basic',
                'instagram_content_publish',
                'instagram_manage_comments',
                'instagram_manage_insights',
                'ads_management',
                'business_management'
            ].join(','),
            group: ['publish_to_groups'].join(','),
            simple: ''
        };


        return new Promise<any>((resolve, reject) => {
            const loginOptions: LoginOptions = {
                enable_profile_selector: true,
                return_scopes: true,
                scope: permissions[type]
            };


            this.fb.login(loginOptions)
            .then((res: LoginResponse) => {
                resolve(res);
                FacebookUser.saveData({
                    access_token: res.authResponse.accessToken,
                    userID: res.authResponse.userID,
                    domainsData: []
                });
                this.getDomain();
               
            })
            .catch(e => reject(e));
        });
    }


    getDomain(): Promise<any> {
        let userID;


        if (FacebookUser.getData().userID !== null) {
            userID = FacebookUser.getData().userID;
        }
        return new Promise((resolve, reject) => {
            this.fb.api('/' + userID + '/accounts')
            .then((res: any) => {
                resolve(res);
                FacebookUser.saveData({
                    access_token: FacebookUser.getData().access_token,
                    userID: FacebookUser.getData().userID,
                    domainsData: res
                });
            })
            .catch(e => reject(e));
        });
    }


    getPageTargetFeed(pageID): Promise<any> {
        return this.fb.api('/' + pageID + '/feed', 'get', {'fields': 'feed_targeting'});
    }


    getProfile(): Promise<any> {
        return this.fb.api('/me');
    }


    getPages(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getAllEntries('/me/accounts')
                .then((response: any) => resolve(response))
                .catch(error => reject(error));
        });
    }


    getInstagramPages(pageID, access_token): Promise<any> {
        return this.fb.api('/' + pageID + '/instagram_accounts?fields=follow_count,has_profile_picture,id,is_private,followed_by_count,is_published,media_count,profile_pic,username', 'get', {'access_token': access_token });
    }


    getInstagramBusinessID(pageID,access_token): Promise<any> {
        return this.fb.api('/' + pageID + '?fields=instagram_business_account', 'get', {'access_token': access_token });
    }


    getGroups(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getAllEntries('/me/groups')
                .then((response: any) => resolve(response))
                .catch(error => reject(error));
        });
    }


    logout() {
        this.fb.logout().then(() => console.log('Logged out!'));
    }


    getAllEntries(url) {
        const entries = {
            'data': []
        };


        const handleResponse = function (response, resolve, reject) {
            if (response.data) {
                entries.data = entries.data.concat(response.data);
            }
            if (response['paging'] && response['paging']['next']) {
                getFromAPI(resolve, reject, response.paging.next);
            } else {
                resolve(entries);
            }
        };


        const getFromAPI = (resolve, reject, _url) => {
            this.fb.api(_url)
            .then((res: any) => {
                handleResponse(res, resolve, reject);
            })
            .catch(e => reject(e));
        };


        return new Promise((resolve, reject) => {
            getFromAPI(resolve, reject, url);
        });
    }


    getPageAccessToken(pageID: string, backend: BackendService): Promise<any> {
        return backend.post('/social-sites/exchange-access-token', {
            socialType: 'instagram',
            code: FacebookUser.getData().access_token,
            pageID: pageID,
            userToken: true, // get user access token too
        });
    }
}



