import {Chart, Widget} from './widget.interfaces';
import {WIDGET_DEFAULT_HEIGHT, WIDGET_DEFAULT_WIDTH} from '~/src/app/modules/analytics/widget/widget.configs';
import {Range} from '~/node_modules/ngx-mat-daterange-picker';

export abstract class WidgetAbstract implements Widget {
    private _name: string;
    private _position: { x: number; y: number };
    private _size: { width: number; height: number };
    private _type: string;
    private _id: number;
    private _metrics?: string;
    private _socialSiteID?: number;
    private _organizations?: number[];
    private _availableOrganizations?: number[];
    private _metricModifiers?: string[];
    private _content: string;
    private _chart: Chart;
    private _data: any;
    private _posts: any;
    private _dateRange: Range;

    isTextWidget = false;
    isChartWidget = false;

    public defaultSize = {
        width: WIDGET_DEFAULT_WIDTH,
        height: WIDGET_DEFAULT_HEIGHT
    };

    public defaultPosition = {
        x: 0,
        y: 0
    };

    public socialChannel;

    public refreshable = true;

    requestDataOptions = {
        widgetID: 'widgetID',
        name: 'name',
        content: 'content'
    };

    protected constructor(widget: Widget) {
        this.name = widget.name;
        this.type = widget.type;
        this.id = widget.id;
        this.metrics = widget.metrics;
        this.socialSiteID = widget.socialSiteID;
        this.organizations = widget.organizations;
        this.availableOrganizations = widget.availableOrganizations;
        this.metricModifiers = widget.metricModifiers;
        this.content = widget.content;
        this.chart = widget.chart;
        this.data = widget.data;
        this.posts = widget.posts;
        this.socialChannel = widget.socialChannel;
        this.refreshable = widget.refreshable;
        this.dateRange = widget.dateRange;

        if (widget.size) {
            this.size = widget.size;
        } else {
            this.size = this.defaultSize;
        }

        if (widget.position) {
            this.position = widget.position;
        } else {
            this.position = this.defaultPosition;
        }
    }

    getPostData() {
        const postData = new FormData();



        return postData;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get position(): { x: number; y: number } {
        return this._position;
    }

    set position(value: { x: number; y: number }) {
        this._position = value;
    }

    get size(): { width: number; height: number } {
        return this._size;
    }

    set size(value: { width: number; height: number }) {
        this._size = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get metrics(): string {
        return this._metrics;
    }

    set metrics(value: string) {
        this._metrics = value;
    }

    get socialSiteID(): number {
        return this._socialSiteID;
    }

    set socialSiteID(value: number) {
        this._socialSiteID = value;
    }

    set organizations(value: number[]) {
        this._organizations = value;
    }

    get organizations(): number[] {
        return this._organizations;
    }

    set availableOrganizations(value: number[]) {
        this._availableOrganizations = value;
    }

    get availableOrganizations(): number[] {
        return this._availableOrganizations;
    }

    get metricModifiers(): string[] {
        return this._metricModifiers;
    }

    set metricModifiers(value: string[]) {
        this._metricModifiers = value;
    }

    get content(): string {
        return this._content;
    }

    set content(value: string) {
        this._content = value;
    }

    get chart(): Chart {
        return this._chart;
    }

    set chart(value: Chart) {
        this._chart = value;
    }

    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }

    get posts(): any {
        return this._posts;
    }

    set posts(value: any) {
        if (!!value && value instanceof Array && !!value.length) {
            value = value.map(item => {
                if (!!item && !!item.message) {
                    item.message = item.message.replace(/<br \/>/g, '&nbsp;');
                }

                return item;
            });
        }

        this._posts = value;
    }

    get dateRange(): Range {
        return this._dateRange;
    }

    set dateRange(value: Range) {
        this._dateRange = value;
    }
}
