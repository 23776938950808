import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ClickDecorator } from "~/src/app/core/decorators";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { ScheduleRulePresetModalFormComponent } from "~/src/app/modules/autofeed/components/schedule-rule-preset-modal-form/schedule-rule-preset-modal-form.component";
import { AutofeedService } from "~/src/app/modules/autofeed/autofeed.service";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { ScheduleRulePresetModel } from "~/src/app/modules/autofeed/models/schedule-rule-preset.model";
import {
    TableBodyConfig,
    TableHeadConfig,
} from "~/src/app/core/components/collection-list/collection-list.interfaces";
import { LanguageService } from "~/src/app/services/language.service";
import { SocialSiteChooserModalComponent } from "~/src/app/shared/components/social-site-chooser-modal/social-site-chooser-modal.component";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { PaginationController } from "~/src/app/services/pagination.controller";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime, skip } from "rxjs/operators";
import {
    AUTOFEED_PRESET_DEFAULT_ORDER,
    AUTOFEED_PRESET_FILTER_CONTROL_NAMES,
} from "~/src/app/modules/autofeed/autofeed.constants";
import { DialogInfoComponent } from "~/src/app/components/dialog-info/dialog-info.component";
import { ViewChild } from "~/node_modules/@angular/core";
import { CollectionListComponent } from "~/src/app/core/components/collection-list/collection-list.component";

@Component({
    selector: "smd-schedule-rules-preset-list",
    templateUrl: "./schedule-rules-preset-list.component.html",
    styleUrls: ["./schedule-rules-preset-list.component.scss"],
    providers: [ComponentHelpers],
})
export class ScheduleRulesPresetListComponent implements OnInit {
    @Output() ruleApplied = new EventEmitter();
    rulePresets: ScheduleRulePresetModel[] = [];
    tableHeadConfig: TableHeadConfig[] = [
        { nameKey: "core.crud.name" },
        { nameKey: "category.category.name" },
        { nameKey: "period" },
        { nameKey: "core.crud.time" },
        { nameKey: "core.crud.createDate" },
        { nameKey: "core.crud.actions" },
    ];
    tableBodyConfig: TableBodyConfig<ScheduleRulePresetModel>[] = [
        { bindValue: "name" },
        { bindValue: "categoryName" },
        { bindValue: "period" },
        { selector: "scheduleRuleTime" },
        { bindValue: "createDate", dateFormat: true },
        { staticView: "itemActions" },
    ];
    paginatorController = new PaginationController();
    filterControlNames = AUTOFEED_PRESET_FILTER_CONTROL_NAMES;
    filterFormGroup = new FormGroup({
        [this.filterControlNames.Organization]: new FormControl(null),
        [this.filterControlNames.Name]: new FormControl(null),
        [this.filterControlNames.Sort]: new FormControl(
            AUTOFEED_PRESET_DEFAULT_ORDER
        ),
        [this.filterControlNames.Period]: new FormControl(null),
        [this.filterControlNames.Category]: new FormControl(null),
    });

    constructor(
        public language: LanguageService,
        public autofeedService: AutofeedService,
        private openModal: OpenModalService,
        private componentHelpers: ComponentHelpers
    ) {
        this.getRulePresets();

        this.paginatorController.onPaginationChange.subscribe(() => {
            this.getRulePresets();
        });
        this.filterFormGroup.valueChanges
            .pipe(debounceTime(350), skip(1))
            .subscribe(() => {
                this.paginatorController.goToFirstPage();
                this.getRulePresets();
            });
    }

    ngOnInit(): void {}

    @ClickDecorator()
    openScheduleRulePresetManager(event: MouseEvent) {
        this.openModal.scheduleRulePreset(
            ScheduleRulePresetModalFormComponent,
            null,
            {
                data: {
                    afterSuccessAction: () => this.getRulePresets(),
                },
            }
        );
    }

    @ClickDecorator()
    applyOnSocialSites(event: MouseEvent, item: ScheduleRulePresetModel): void {
        this.openModal.socialSiteChooser(SocialSiteChooserModalComponent, {
            multiple: true,
            afterChoose: (socialSites: SocialSiteInterface[]) => {
                if (socialSites && socialSites.length) {
                    const loader = this.openModal.loader(DialogLoaderComponent);
                    this.autofeedService
                        .createScheduleRule(
                            [
                                {
                                    ...item.settings,
                                    presetID: item.presetID,
                                },
                            ],
                            socialSites.map((site) => site.siteID)
                        )
                        .then((res) => {
                            const wrongPresetIDs = Object.keys(
                                res?.autoFeeds?.errors || {}
                            ).map((id) => Number(id));
                            let info = {};
                            if (wrongPresetIDs.length > 0) {
                                info =
                                    res.autoFeeds.errors[wrongPresetIDs[0]]
                                        .info;
                            }

                            this.emitRuleApplied();

                            loader.afterClosed().subscribe(() => {
                                this.openModal.infoModal(DialogInfoComponent, {
                                    message:
                                        wrongPresetIDs.length > 0
                                            ? this.autofeedService.getFailedApplyMessage(
                                                  {
                                                      error: {
                                                          error: { info },
                                                      },
                                                  },
                                                  item.name
                                              )
                                            : this.autofeedService.getSuccessApplyMessage(
                                                  res,
                                                  item.name
                                              ),
                                });
                            });

                            loader.close();
                        })
                        .catch((error) => {
                            loader.afterClosed().subscribe(() => {
                                this.openModal.infoModal(DialogInfoComponent, {
                                    message:
                                        this.autofeedService.getFailedApplyMessage(
                                            error,
                                            item.name
                                        ),
                                });
                            });

                            loader.close();
                        });
                }
            },
        });
    }

    @ClickDecorator()
    applySettingsOnSocialSites(
        event: MouseEvent,
        collectionList: CollectionListComponent
    ): void {
        const items: ScheduleRulePresetModel[] =
            collectionList.dataTableHelpers.getSelectedItems();

        if (!items || !items.length) {
            return;
        }

        this.openModal.socialSiteChooser(SocialSiteChooserModalComponent, {
            multiple: true,
            afterChoose: (socialSites: SocialSiteInterface[]) => {
                if (socialSites && socialSites.length) {
                    const loader = this.openModal.loader(DialogLoaderComponent);
                    this.autofeedService
                        .createScheduleRules(
                            items.map((item) => ({
                                ...item.settings,
                                presetName: item.name,
                                presetID: item.presetID,
                            })),
                            socialSites.map((site) => site.siteID)
                        )
                        .then((result) => {
                            if (result.success) {
                                collectionList.clearSelection();
                                this.emitRuleApplied();
                            }

                            loader.afterClosed().subscribe(() => {
                                this.openModal.infoModal(DialogInfoComponent, {
                                    message: result.message,
                                });
                            });

                            loader.close();
                        });
                }
            },
        });
    }

    @ClickDecorator()
    modifyPreset(event: MouseEvent, item: ScheduleRulePresetModel): void {
        this.openModal.scheduleRulePreset(
            ScheduleRulePresetModalFormComponent,
            item,
            {
                data: {
                    afterSuccessAction: () => this.getRulePresets(),
                },
            }
        );
    }

    @ClickDecorator()
    removePreset(
        event: MouseEvent,
        item: ScheduleRulePresetModel,
        collectionList: CollectionListComponent
    ): void {
        this.componentHelpers.startApiAction(
            () => this.autofeedService.removePreset(item.presetID),
            {
                successMessageKey: "post.autoFeedPreset.remove.success",
                failedMessageKey: "post.autoFeedPreset.remove.failed",
                confirmMessageKey: "post.autoFeedPreset.remove.confirm.message",
                confirmYesButtonTextKey:
                    "post.autoFeedPreset.remove.confirm.button",
                afterSuccessAction: () => {
                    if (collectionList.isSelected(item)) {
                        collectionList.selectItem(item);
                    }
                    this.getRulePresets();
                },
            },
            true
        );
    }

    @ClickDecorator()
    removePresetItems(
        event: MouseEvent,
        collectionList: CollectionListComponent
    ): void {
        const items: ScheduleRulePresetModel[] =
            collectionList.dataTableHelpers.getSelectedItems();

        if (!items?.length) {
            return;
        }

        this.componentHelpers.startApiAction(
            () =>
                this.autofeedService.removePreset(
                    items.map((item) => item.presetID)
                ),
            {
                successMessageKey: "post.autoFeedPreset.remove.success",
                failedMessageKey: "post.autoFeedPreset.remove.failed",
                confirmMessageKey:
                    "post.autoFeedPreset.bulkRemove.confirm.message",
                confirmYesButtonTextKey:
                    "post.autoFeedPreset.remove.confirm.button",
                afterSuccessAction: () => {
                    if (items.length === this.rulePresets.length) {
                        this.paginatorController.goToPreviousPage();
                    }

                    collectionList.clearSelection();

                    this.getRulePresets();
                },
            },
            true
        );
    }

    @ClickDecorator()
    resetFilterForm(event: MouseEvent): void {
        this.filterFormGroup.reset({
            [this.filterControlNames.Sort]: AUTOFEED_PRESET_DEFAULT_ORDER,
        });
    }

    /**
     * Get schedule rule presets
     */
    getRulePresets(): void {
        const loader = this.openModal.loader(DialogLoaderComponent);
        const params = {
            ...this.paginatorController.requestParams,
            ...this.filterFormGroup.value,
        };
        this.autofeedService
            .getPresets(params)
            .then((response) => {
                this.rulePresets = response.presets;
                this.paginatorController.paginationOptions.length =
                    response.count;
            })
            .finally(() => loader.close());
    }

    /**
     * Emit rule applied event
     */
    emitRuleApplied(): void {
        this.ruleApplied.emit();
    }
}
