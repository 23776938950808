import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoriesComponent} from './categories/categories.component';
import {SharedModule} from '~/src/app/shared/shared.module';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {CategoryManagerComponent} from './category-manager/category-manager.component';
import {CategoriesListComponent} from '~/src/app/modules/categories/categories/categories-list/categories-list.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LanguagesModule
    ],
    declarations: [
        CategoriesComponent,
        CategoryManagerComponent,
        CategoriesListComponent,
    ],
    entryComponents: [CategoryManagerComponent]
})
export class CategoriesModule {
}
