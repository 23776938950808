import { SmdGalleryComponent } from './../smd-gallery/smd-gallery.component';
import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FileBrowserService } from "../../services/file-browser.service";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { Debounce } from "../../services/helpers";
import { MatInput } from "@angular/material/input";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { LanguageService } from "../../services/language.service";
import { PaginationController } from "../../services/pagination.controller";
import {
    CarouselController,
    CarouselItem,
} from "../../modules/posts/carousel/carousel.component";
import { CategoriesService } from "~/src/app/modules/posts/categories.service";
import { MediaTagsService } from "~/src/app/modules/documents/media-tags.service";
import { Configs, SocialSite } from "~/src/app/configs/configs";
import {
    RawFile,
    SmdFile,
    SmdFileInterface,
} from "~/src/app/services/file.class";
import { cloneDeep, forEach } from "lodash";
import { ComponentAbstract } from "~/src/app/services/component.abstract";
import { PreloaderComponent } from "~/src/app/components/preloader/preloader.component";
import { CustomEvents, Helpers } from "~/src/app/services/helpers";
import { HttpEventType } from "@angular/common/http";
import { NotifyService } from "~/src/app/services/notify.service";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import Utils from "~/src/app/core/utils";
import {
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
} from "~/src/app/core/constants";
import EditorControllerService from "~/src/app/components/image-editor/editor-controller.service";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogProgressComponent } from "~/src/app/components/dialog-progress/dialog-progress.component";
import { socialSiteAppearance } from "../../modules/social-media-post/social-media-platforms-config";

export enum FileBrowserSelectionChangeTypes {
    SetSelectedFiles = "setSelectedFiles",
    SelectFile = "selectFile",
    UnselectFile = "unselectFile",
}

export interface MediaRules {
    conditions: {
        [key in keyof SmdFileInterface]?: any | any[];
    };
    rules: {
        [key in keyof SmdFileInterface]?: any | any[];
    };
    limits?: {
        selectedImage?: number;
        selectedVideo?: number;
        selectedCarousel?: number;
        selectedMixed?: number; // video + image
    };
}

export interface FileBrowserSelectionChange {
    selectedFiles: SmdFileInterface[];
    file: SmdFileInterface;
    eventType:
        | FileBrowserSelectionChangeTypes.SetSelectedFiles
        | FileBrowserSelectionChangeTypes.SelectFile
        | FileBrowserSelectionChangeTypes.UnselectFile;
}

@Component({
    selector: "smd-file-browser",
    templateUrl: "./file-browser.component.html",
    styleUrls: ["./file-browser.component.scss"],
})
export class FileBrowserComponent extends ComponentAbstract implements OnInit, OnChanges {
    protected _justThisType:
        | FILE_TYPES.IMAGE
        | FILE_TYPES.VIDEO
        | FILE_TYPES.CAROUSEL = null;

    @ViewChild(MatInput, { static: true }) searchValue: MatInput;
    @ViewChild("fileTypeSelect", { static: true }) fileTypeSelect: MatSelect;
    @ViewChild("orderBySelect", { static: true }) orderBySelect: MatSelect;
    @ViewChild(PreloaderComponent, { static: true })
    preLoader: PreloaderComponent;
    @ViewChild(MatPaginator, { static: true }) pagination: MatPaginator;
    @ViewChild("gallery", { static: false }) gallery: SmdGalleryComponent;
    @Input("enableFileUpload") enableFileUpload = true;
    @Input("disableImageEditor") disableImageEditor = false;
    @Input("loadedFrom") loadedFrom = null;
    @Output("selectionChange")
    selectionChange: EventEmitter<FileBrowserSelectionChange> = new EventEmitter<FileBrowserSelectionChange>();

    @Input() set postTemplateID(value) {
        this.filters["templateID"] = value;
        this.getItems();
    }

    _allowedToLoad = false;
    @Input() allowedToLoad;

    ngOnChanges(changes) {
        if (changes.allowedToLoad) {
            this._allowedToLoad = changes.allowedToLoad.currentValue;

            if (this._allowedToLoad) {
                this.getItems(null, null, true);
            }
        }
    }

    @Input() set justThisType(
        value: FILE_TYPES.IMAGE | FILE_TYPES.VIDEO | FILE_TYPES.CAROUSEL
    ) {
        this._justThisType = value;

        this.fileTypeFilterChange({ value: value });
    }

    @Input() isDisabledSocialType = false;
    @Input() defaultSocialTypeIds: string[] = null;
    @Input() set isAdminMode(value: boolean) {
        this._isAdminMode = value;

        if (!value && this.filters["isAdmin"]) {
            delete this.filters["isAdmin"];
        } else if (value) {
            this.filters["isAdmin"] = "yes";
        }
        if (this.gettingCount) {
            this.getItems();
        }
    }
    @Input() isModal = true;
    @Output() initialize = new EventEmitter();

    get isAdminMode(): boolean {
        return this._isAdminMode;
    }

    private _isAdminMode = false;

    get justThisType():
        | FILE_TYPES.IMAGE
        | FILE_TYPES.VIDEO
        | FILE_TYPES.CAROUSEL {
        return this._justThisType;
    }

    /**
     * Upload status
     *
     * @type {number}
     */
    uploadStatus = 0;
    fileUploadDoneData: any;
    fileTypeSelectItems: string[] = [
        FILE_TYPES.VIDEO,
        FILE_TYPES.IMAGE,
        FILE_TYPES.CAROUSEL,
    ];
    image_file_type: string = FILE_TYPES.IMAGE;
    paginationController: PaginationController = new PaginationController();
    socialTypes: SocialSite[] = Configs.socials;
    showLoader = true;
    hadInitCall = false;
    /**
     * All files
     *
     * @type {any[]}
     */
    files: Array<SmdFileInterface> = [];

    mediaError: string[] = [];

    /**
     * Selected files
     *
     * @type {any[]}
     */
    hasMediaTag = false;
    hasMediaCategory = false;

    gettingCount = 0;

    rules: MediaRules;

    filtersControlNames = {
        Categories: "categories",
        Tags: "tags",
    };
    filtersFormGroup = new FormGroup({
        [this.filtersControlNames.Categories]: new FormControl(),
        [this.filtersControlNames.Tags]: new FormControl(),
    });

    private _selectedFiles: Array<SmdFileInterface> = [];
    private _disabled = false;

    private currentPreviewFile = null;

    constructor(
        public service: FileBrowserService,
        public carouselController: CarouselController,
        public categoriesService: CategoriesService,
        public tagsService: MediaTagsService,
        private partnerConfig: PartnerConfigService,
        private openModal: OpenModalService,
        private dialog: MatDialog
    ) {
        super();

        this.hasMediaTag = this.partnerConfig.hasConfig(
            PartnerPermissions.MediaTag
        );
        this.hasMediaCategory = this.partnerConfig.hasConfig(
            PartnerPermissions.MediaCategory
        );

        this.paginationController.onSetPagination.subscribe((result) => {
            this.filters["offset"] = result.offset;
            this.filters["limit"] = result.limit;

            this.getItems();
        });

        this.filtersFormGroup.valueChanges
            .pipe(debounceTime(350))
            .subscribe((filters) => {
                const deleteFilter = (controlName) => {
                    if (this.filters && controlName in this.filters) {
                        delete this.filters[controlName];
                    }
                };
                if (filters && filters instanceof Object) {
                    for (const controlName in filters) {
                        let filterValue = filters[controlName];

                        if (filterValue) {
                            if (filterValue instanceof Array) {
                                if (filterValue.length) {
                                    filterValue = JSON.stringify(filterValue);
                                } else {
                                    filterValue = null;
                                    deleteFilter(controlName);
                                }
                            }

                            if (filterValue) {
                                this.filters[controlName] = filterValue;
                            }
                        } else {
                            deleteFilter(controlName);
                        }
                    }
                } else {
                    Object.values(this.filtersControlNames).forEach(
                        (controlName) => deleteFilter(controlName)
                    );
                }

                this.filter();
            });
    }

    beforeGetItems(): void {
        super.beforeGetItems();
    }

    beforeDataLoad(): void {
        super.beforeDataLoad();

        this.showLoader = true;
    }

    ngOnInit(): void {
        if (this.loadedFrom) {
            this.socialTypes = this.socialTypes.filter(
                (item) =>
                    socialSiteAppearance?.[item.id]?.[this.loadedFrom] !== false
            );
        }

        if (this.isAdminMode) {
            this.filters["isAdmin"] = "yes";
        }

        this.fileTypeSelect.valueChange.subscribe((value) => {
            this.fileTypeFilterChange({ value: value || "" });
        });

        if (!!this.justThisType) {
            this.filters["type"] = this.justThisType;
        }

        if (this.defaultSocialTypeIds && this.defaultSocialTypeIds.length) {
            this.filters["socialChannels"] = this.defaultSocialTypeIds;
        }

        // getItems with init parameters is called on change of allowedToLoad at ngOnChanges if we come from a postSkeleton
        // if allowedToLoad is not set, we'll call it here though
        if (this.allowedToLoad === undefined) {
            this._allowedToLoad = true;
            this.getItems(null, null, true);
        }
    }

    getItems(callback = null, errorCallback = null, isInit = false): void {
        if (!this._allowedToLoad) {
            return;
        }

        if (!isInit && !this.hadInitCall) {
            return;
        }

        super.getItems(
            () => {
                this.showLoader = false;
            },
            () => {
                this.showLoader = false;
            }
        );

        if (!this.hadInitCall) {
            this.hadInitCall = true;
        }
    }

    clearFilters(): void {
        super.clearFilters();

        this.searchValue.value = "";
        this.fileTypeSelect.writeValue(null);
        this.orderBySelect.writeValue(null);

        this.filters["order"] = this.getOrderFormat("none-none");
        this.filters["type"] = "";
        this.filters["searchValue"] = "";
        delete this.filters["socialChannels"];
    }

    /**
     * @param {MediaRules} rules
     * @param {SmdFileInterface[]} files
     * @param {boolean} isSetter
     * @return {SmdFileInterface[]}
     */
    setMediaRules(
        rules: MediaRules,
        files: SmdFileInterface[] = null,
        isSetter = true
    ): SmdFileInterface[] {
        const conditions = rules.conditions;
        let valueChanged = false;

        files = (files || this.files).map((item) => {
            for (const conditionField in conditions) {
                const condition: any[] = Array.isArray(
                    conditions[conditionField]
                )
                    ? conditions[conditionField]
                    : [conditions[conditionField]];

                if (condition.includes(item[conditionField])) {
                    for (const ruleField in rules.rules) {
                        const newValue = rules.rules[ruleField];

                        if (item[ruleField] !== newValue) {
                            valueChanged = true;
                        }

                        item[ruleField] = newValue;
                    }
                }
            }

            return item;
        });

        if (isSetter) {
            this.files = files;
        }

        this.rules = rules;

        if (
            (valueChanged && isSetter) ||
            Utils.get(this.rules, "limits", null)
        ) {
            this.setSelectedFilesInBrowser();
            this.setInactiveFiles();
        }

        return files;
    }

    clearMediaRules(): void {
        this.rules = null;
    }

    /**
     * Before file upload
     */
    beforeFileUpload() {
        this.uploadStatus = 0;
    }

    /**
     * File upload in progress
     *
     * @param event
     */
    fileUploading(event) {
        this.uploadStatus = event.uploadStatus;
    }

    /**
     * File upload done event
     *
     * @param event
     */
    fileUploadDone(event) {
        this.setInactiveFiles();

        this.fileUploadDoneData = event;

        if (!this.goToFirstPage()) {
            this.getItems();
        }
    }

    /**
     * Change fileType filter event
     *
     * @param event
     */
    @Debounce()
    fileTypeFilterChange(event) {
        this.filters["type"] = event.value || "";

        if (!this.goToFirstPage()) {
            this.getItems();
        }
    }

    /**
     * Change orderBy filter event
     *
     * @param event
     */
    orderBySelectChange(event) {
        const value = event.value;

        this.filters["order"] = this.getOrderFormat(value);

        this.getItems();
    }

    socialTypesChange(channelId) {
        const filterKey = "socialChannels";

        if (channelId) {
            this.filters[filterKey] = channelId;
        } else {
            delete this.filters[filterKey];
        }

        this.filter();
    }

    public editImage(media) {
        (media.editorMetaFile
            ? this.service.getEditorMeta(media.editorMetaFile)
            : Promise.resolve(media.editorMeta)
        ).then((response) => {
            media.editorMeta = response;
            EditorControllerService.open({
                media: { ...media },
                callback: this.saveEditedImage.bind(this),
            });
        });
    }

    /**
     * Change searchValue filter event
     *
     * @param {KeyboardEvent} event
     */
    @Debounce(450)
    searchInName(event: KeyboardEvent) {
        const value = event.target["value"];

        this.filters["searchValue"] = value || "";
        this.filters["offset"] = 0;
        this.filters["limit"] =
            this.paginationController.paginationOptions.pageSize;

        this.paginationController.paginationOptions.pageIndex = 0;

        this.getItems();
    }

    private filter() {
        this.paginationController.paginationOptions.pageIndex = 0;
        this.paginationController.paginateChange(
            this.paginationController.paginationOptions
        );
    }

    get languageService() {
        return LanguageService;
    }

    initFiles(response) {
        const medias = response.medias;
        const carouselIDs: number[] = Helpers.arrayUnique(
            medias
                .filter((media) => media.mediaType === FILE_TYPES.CAROUSEL)
                .map((media) => media.mediaID)
        );

        if (carouselIDs.length) {
            return this.carouselController
                .getCarouselItemsByIDs(carouselIDs, this.isAdminMode)
                .then(({ carouselItems }) => {
                    medias.map((media) => {
                        const mediaCarouselItems = [];

                        carouselItems.forEach((carouselItem) => {
                            if (carouselItem.carouselID === media.mediaID) {
                                mediaCarouselItems.push(
                                    new CarouselItem(carouselItem)
                                );
                            }
                        });

                        media.carouselItems = mediaCarouselItems;

                        return media;
                    });

                    response.medias = SmdFile.initFileArray(medias);

                    return response;
                });
        } else {
            response.medias = SmdFile.initFileArray(medias);
            return Promise.resolve(response);
        }
    }

    /**
     * Get items success callback
     *
     * @param response
     */
    successGetItems(response: any, callback: () => void = () => {}): void {
        const previousSelectedItems = this.getSelectedFiles();

        this.initFiles(response).then(
            ({ medias }: { medias: SmdFileInterface[] }) => {
                const ogs = this.files.filter(
                    (file) =>
                        file.type === FILE_TYPES.OG ||
                        file.url.includes("base64")
                );
                let mediaItems = [
                    ...ogs,
                    ...cloneDeep(
                        medias.map((media) => {
                            if (!media.icon) {
                                return new SmdFile(media as RawFile);
                            }
                            return media;
                        })
                    ),
                ];

                if (this.rules) {
                    mediaItems = this.setMediaRules(
                        this.rules,
                        mediaItems,
                        false
                    );
                }

                if (
                    Utils.get(this.filters, "socialChannels", []).includes(
                        SOCIAL_MEDIA_TYPE_TWITTER
                    ) ||
                    Utils.get(this.filters, "socialChannels", []).includes(
                        SOCIAL_MEDIA_TYPE_INSTAGRAM
                    )
                ) {
                    mediaItems = mediaItems.map((item: SmdFileInterface) => {
                        if (item.type === FILE_TYPES.CAROUSEL) {
                            item.notAllow = true;
                        }

                        return item;
                    });
                }

                this.files = mediaItems;

                this.paginationOptions.length = response["count"];

                this.setSelectedFilesInBrowser(() => {
                    this.setInactiveFiles();
                });

                if (this.fileUploadDoneData) {
                    if (
                        this.fileUploadDoneData["response"] &&
                        this.fileUploadDoneData["response"]
                    ) {
                        let selectedFiles = [];
                        selectedFiles = this.files.filter((file) => {
                            return (
                                this.fileUploadDoneData["response"].indexOf(
                                    file.mediaID
                                ) > -1
                            );
                        });

                        // selectedFiles.forEach((selectedFile) => {
                        //     this.emitItemChangeByFile(
                        //         selectedFile,
                        //         FileBrowserSelectionChangeTypes.SelectFile
                        //     );
                        // });
                        let fileindex=0;
                        let fileindexarr=[];
                         selectedFiles.forEach((selectedFile) => { 
                          this.emitItemChangeByFile( 
                              selectedFile, 
                              FileBrowserSelectionChangeTypes.SelectFile 
                              ); 
                              if(selectedFile.type !=mediaItems[0].type){ 
                                    fileindexarr.push(fileindex);
                                   }
                                    fileindex++;
                      }); 
                      if(mediaItems[0].type=='video' && selectedFiles.length>1){  
                          for(var i=0; i<selectedFiles.length; i++){ 
                              let newindexvideo=(selectedFiles.length)-(i);
                                if(newindexvideo != 1){ 
                                  fileindexarr.push(newindexvideo); 
                              } 
                          } 
                      } 
                      fileindexarr.forEach((indx) => { 
                          let newindex= (selectedFiles.length)-(1);
                             selectedFiles.splice(newindex, 1); 
                      });

                        this.setSelectedFiles(
                            previousSelectedItems.concat(selectedFiles)
                        );
                    } else if (
                        this.fileUploadDoneData["carousel"]["carouselID"]
                    ) {
                        const selectedCarouselFile = response["medias"].filter(
                            (item) => {
                                return (
                                    item.mediaID ===
                                    this.fileUploadDoneData["carousel"][
                                        "carouselID"
                                    ]
                                );
                            }
                        );

                        this.setSelectedFiles(selectedCarouselFile);
                    }

                    this.fileUploadDoneData = {};
                }

                if (!this.gettingCount) {
                    this.initialize.emit();
                }

                this.gettingCount++;

                callback();
            }
        );
    }

    deleteFileByPropertyValue(property: keyof SmdFile, value: any) {
        const getIndexOfItemInObject = (object, prop, val) =>
            object.findIndex((item) => item[prop] === val);

        const indexInFiles = getIndexOfItemInObject(
            this.files,
            property,
            value
        );

        if (indexInFiles > -1) {
            if (this.files[indexInFiles].selected) {
                const indexInSelectedFiles = getIndexOfItemInObject(
                    this._selectedFiles,
                    property,
                    value
                );
                if (indexInSelectedFiles > -1) {
                    this._selectedFiles.splice(indexInSelectedFiles, 1);
                }
            }
            this.files.splice(indexInFiles, 1);
        }
    }

    uploadFile(request: object) {
        this.service.uploadFile(request).subscribe(
            (response) => {
                if (response["type"] === HttpEventType.UploadProgress) {
                    this.createInProgress(response);
                } else if (response["type"] === HttpEventType.Response) {
                    this.successCreateWithProgress(response, request);
                }
            },
            (error) => {
                this.failedCreateWithProgress(error, request);
                this.service.showErrorAlert(error);
            }
        );
    }

    saveEditedImage(request) {
        const progress: MatDialogRef<DialogProgressComponent> =
            this.dialog.open(DialogProgressComponent, {
                data: {
                    title: LanguageService.getLine("documents.media.upload"),
                },
                disableClose: true,
            });

        this.service
            .replaceFile({
                file: request.file,
                editorMeta: request.editorMeta,
                mediaID: request.mediaID,
            })
            .subscribe(
                (response) => {
                    if (response["type"] === HttpEventType.UploadProgress) {
                        progress.componentInstance.progressValue++;
                    }

                    if (response["type"] === HttpEventType.Response) {
                        progress.close();
                        this.getItems();
                    }
                },
                (error) => {
                    progress.close();
                    this.service.showErrorAlert(error);
                }
            );
    }

    /**
     * Select file
     *
     * @param {SmdFileInterface} currentFile
     */
    selectFile(currentFile: SmdFileInterface, event?: Event): void | boolean {
        if (event && SmdFile.isBreakFileSelection(event)) {
            return false;
        }

        const indexInSelectedFiles = this._selectedFiles.findIndex((file) => {
            return SmdFile.isFilesEqual(currentFile, file);
        });

        const selectedSocialTypes =
            this.service.selectedSocialTypesInCarouselCreateOrEdit;
        let isAddableToCarousel = true;
        forEach(selectedSocialTypes, (type) => {
            if (
                currentFile.socialChannels.indexOf(type) === -1 &&
                currentFile.socialChannels.length
            ) {
                isAddableToCarousel = false;
            }
        });
        const isNeedAbortToAdd =
            !isAddableToCarousel && selectedSocialTypes.length !== 0;

        if (indexInSelectedFiles === -1) {
            if (!isNeedAbortToAdd) {
                this._selectedFiles.push(currentFile);
                currentFile.selected = true;
                this.emitItemChangeByFile(
                    currentFile,
                    FileBrowserSelectionChangeTypes.SelectFile
                );
            }
        } else {
            this._selectedFiles.splice(indexInSelectedFiles, 1);
            currentFile.selected = false;
            this.emitItemChangeByFile(
                currentFile,
                FileBrowserSelectionChangeTypes.UnselectFile
            );

            return;
        }

        if (isNeedAbortToAdd) {
            NotifyService.warning(
                this.languageService.getLine(
                    "carousel.error.social.channels.file.browse.title"
                ),
                this.languageService.getLine(
                    "carousel.error.social.channels.file.browse.message"
                )
            );

            return;
        }
    }

    /**
     * @param currentFile
     * @param {FileBrowserSelectionChangeTypes.SelectFile | FileBrowserSelectionChangeTypes.UnselectFile} type
     */
    private emitItemChangeByFile(
        currentFile,
        type:
            | FileBrowserSelectionChangeTypes.SelectFile
            | FileBrowserSelectionChangeTypes.UnselectFile
    ) {
        this.selectionChange.emit({
            selectedFiles: this.selectedFiles,
            file: currentFile,
            eventType: type,
        });

        setTimeout(() => {
            this.setSelectedFilesInBrowser();
            this.setInactiveFiles();
        });
    }

    /**
     * Add new file to list
     *
     * @param {SmdFileInterface} file
     * @param isSelected
     */
    addNewFile(file: SmdFileInterface, isSelected?: boolean): void {
        this.files.unshift(file);

        if (isSelected) {
            setTimeout(() => {
                this.selectFile(file);
            });
        }
    }

    /**
     * Set inactive files
     */
    setInactiveFiles(): void {
        const types: string[] = [];
        const imageLimit = Utils.get(this.rules, "limits.selectedImage", null);
        const videoLimit = Utils.get(this.rules, "limits.selectedVideo", null);
        const mixedLimit = Utils.get(this.rules, "limits.selectedMixed", null);
        let inactivateImageItems = false;
        let inactivateVideoItems = false;

        this.selectedFiles.forEach(function (file, index) {
            types.push(file.type);
        });

        if (imageLimit && types.includes(FILE_TYPES.IMAGE)) {
            const imageCount = this.selectedFiles.filter(
                (item) => item.type === FILE_TYPES.IMAGE
            ).length;

            if (imageCount >= imageLimit) {
                inactivateImageItems = true;
            }
        }

        if (videoLimit && types.includes(FILE_TYPES.VIDEO)) {
            const videoCount = this.selectedFiles.filter(
                (item) => item.type === FILE_TYPES.VIDEO
            ).length;

            if (videoCount >= videoLimit) {
                inactivateVideoItems = true;
            }
        }

        if (mixedLimit && types.includes(FILE_TYPES.IMAGE) && types.includes(FILE_TYPES.VIDEO)) {
            const mixedCount = this.selectedFiles.filter(
                (item) => item.type === FILE_TYPES.IMAGE || item.type === FILE_TYPES.VIDEO
            ).length;

            if (mixedCount >= mixedLimit) {
                inactivateVideoItems = true;
                inactivateImageItems = true;
            }
        }

        this.files.map((file) => {
            file.active = true;

            if (
                types.indexOf(FILE_TYPES.CAROUSEL) > -1 ||
                (!mixedLimit && types.indexOf(FILE_TYPES.VIDEO) > -1) ||
                types.indexOf(FILE_TYPES.OG) > -1
            ) {
                if (!file.selected) {
                    file.active = false;
                } else if (file.type === FILE_TYPES.VIDEO && types.includes(FILE_TYPES.IMAGE)) {
                    file.active = false; // Deactivate video if both image and video are selected
                }
            } else if (types.length > 0) {
                if (types.indexOf(file.type) === -1) {
                    file.active = !!mixedLimit;
                }
            }

            if (
                file.type === FILE_TYPES.IMAGE &&
                inactivateImageItems &&
                !file.selected
            ) {
                file.active = false;
            }

            if (
                file.type === FILE_TYPES.VIDEO &&
                inactivateVideoItems &&
                !file.selected
            ) {
                file.active = false;
            }

            if (
                file.type === FILE_TYPES.OG &&
                !types.includes(file.type) &&
                types.length
            ) {
                file.active = false;
            }

            return file;
        });
    }

    /**
     * Set selected files
     */
    setSelectedFilesInBrowser(callback: () => void = () => {}): void {
        const imageLimit = Utils.get(this.rules, "limits.selectedImage", null);
        let selectedFiles = [...this.selectedFiles];
        let selectedFilesChanged = false;

        if (imageLimit) {
            selectedFiles = selectedFiles.filter(
                (item, index) => index < imageLimit
            );
            selectedFilesChanged = true;
        }

        const lengthBefore = selectedFiles.length;
        selectedFiles = selectedFiles.filter((item) => item.status !== 'inactive');
        if (lengthBefore != selectedFiles.length) {
            selectedFilesChanged = true;
        }

        this.files.map((file, index) => {
            const itemIndex = selectedFiles.findIndex((item) =>
                SmdFile.isFilesEqual(file, item)
            );

            if (itemIndex > -1) {
                if (file.notAllow) {
                    selectedFiles.splice(itemIndex, 1);
                    selectedFilesChanged = true;
                    file.selected = false;
                } else {
                    file.selected = true;
                }
            } else {
                file.selected = false;
            }

            return file;
        });

        if (selectedFilesChanged) {
            this._selectedFiles = selectedFiles;
        }

        callback();
    }

    /**
     * Set selectedFiles array
     *
     * @param {SmdFileInterface[]} files
     */
    setSelectedFiles(files: SmdFileInterface[]): void {
        this._selectedFiles = [];

        for (const index in files) {
            const file = files[index];

            file.selected = true;

            this._selectedFiles.push(cloneDeep(file));
        }

        this.setSelectedFilesInBrowser();

        setTimeout(() => {
            this.setInactiveFiles();

            this.selectionChange.emit({
                selectedFiles: this.selectedFiles,
                file: this.selectedFiles[this.selectedFiles.length - 1],
                eventType: FileBrowserSelectionChangeTypes.SetSelectedFiles,
            });
        });
    }

    /**
     * Clear selected files
     */
    clearSelectedFiles(): void {
        this._selectedFiles = [];
    }

    getSelectedFiles() {
        return this._selectedFiles;
    }

    /**
     * All file set active is true, selected is false
     */
    clearBrowser(): void {
        for (const index in this.files) {
            const file = this.files[index];

            file.active = true;
            file.selected = false;
        }
    }

    setDisableState() {
        this.files.forEach((value) => {
            value.active = !this.disabled;
        });
    }

    @HostListener(`document:${CustomEvents.UPDATE_FILE_BROWSER}`, ["$event"])
    refreshBrowser(event) {
        this.getItems();
    }

    get selectedFiles(): Array<SmdFileInterface> {
        if (this.disabled) {
            return [];
        }

        return this._selectedFiles;
    }

    set selectedFiles(value: Array<SmdFileInterface>) {
        this._selectedFiles = cloneDeep(value);

        this.setSelectedFilesInBrowser(() => {
            this.setInactiveFiles();
        });
    }

    @Input()
    set disabled(value) {
        this._disabled = value;

        this.setDisableState();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    onPreviewClick(file: SmdFileInterface) {
        this.gallery.openGalleryWithImage(file);
        return true;
    }
}
