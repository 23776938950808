import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CalendarEvent} from 'angular-calendar';
import {BASE_DATE_FORMAT, BASE_TIME_FORMAT, SOCIAL_PLATFORMS_DEFAULT_CONFIG} from '../../../../configs/configs';
import * as moment from 'moment';
import {PostActions, PostInterface} from '../../post-actions';
import {SocialSiteService} from '../../../../components/social-site-select/social-site.service';
import {SocialSiteInterface} from '../../../../components/social-site-select/social-site-select.component';
import {Debounce, Helpers} from '../../../../services/helpers';
import {PostActionsService} from '../../post-actions.service';
import {Category} from '../../template.interface';
import {CategoriesComponent} from '../../categories.component';
import {CategoriesService} from '../../categories.service';
import {UsersController} from '../../../users/users/users.component';
import {PostPreviewComponent} from '~/src/app/modules/posts/post-previews/post-preview/post-preview.component';
import {CarouselController} from '../../carousel/carousel.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostManagerComponent} from '~/src/app/modules/social-media-post/post-manager/post-manager.component';
import { environment } from '~/src/environments/environment';
import { PostTemplateManagerComponent } from '../../../social-media-post/post-template-manager/post-template-manager.component';
@Component({
    selector: 'smd-dialog-posts-list',
    templateUrl: './dialog-posts-list.component.html',
    styleUrls: ['./dialog-posts-list.component.scss']
})
export class DialogPostsListComponent extends PostActions implements OnInit {

    date = '';
    _categoriesComponent: CategoriesComponent;
    posts: PostInterface[] = [];
    nonFilteredPosts: PostInterface[] = [];

    _listChange = false;

    _socialSites: SocialSiteInterface[] = [];
    public socialPlatforms = SOCIAL_PLATFORMS_DEFAULT_CONFIG;

    activeFilters = {
                        platforms: [],
                        categories: [], // TODO!
                    };

    categories: Category[] = [];

    isEditable = true;

    constructor(
        public _dialogRef: MatDialogRef<DialogPostsListComponent>,
        public _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public dialogData: {
            date: Date,
            events: CalendarEvent[],
            posts: PostInterface[],
            categories: Category[],
            socialSites: SocialSiteInterface[],
            groupByParentID: Boolean,
            defaultFilters: any,
        },
        socialSiteService: SocialSiteService,
        public postActionsService: PostActionsService,
        public _categoriesService: CategoriesService,
        users: UsersController,
        carouselController: CarouselController,
        public openModal: OpenModalService
    ) {
        super(postActionsService, _dialog, users, carouselController, openModal);

        this.date = moment(this.dialogData.date).format(BASE_DATE_FORMAT);

        this._socialSites = this.dialogData.socialSites;

        if (this.dialogData && this.dialogData.categories) {
            this.categories = this.dialogData.categories;
        }

        if (this.dialogData.defaultFilters) {
            this.activeFilters = this.dialogData.defaultFilters;
        }

        if (this.dialogData.posts) {
            this.posts = Helpers.orderBy(this.dialogData.posts, 'activeFrom', 'ASC');
        } else {
            this.renderPosts();
        }
    }

    ngOnInit() {
        this._dialogRef.updateSize('550px');
    }

    afterDeletePost(isSuccess: boolean, response: any) {
        super.afterDeletePost(isSuccess, response);

        if (isSuccess) {
            this._dialogRef.close(true);
        }
    }

    /**
     * Edit post
     *
     * @param {PostInterface} post
     */
    editPostClick(post: PostInterface) {
        this.openModal.editPost(PostManagerComponent, post).afterClosed().subscribe(result => {
            if (result) {
                this._listChange = true;
                this._dialogRef.close(this._listChange);
            }
        });
    }

    commentPostClick(post: PostInterface) {
        this.openModal.entityPreview(PostPreviewComponent, post,
            {
                data: {
                    isCommentMode: true,
                }
            }).afterClosed().subscribe(result => {
            if (result && result.postID) {
                this.replacePost(result);
                this._listChange = true;
            }
        });
    }

    /**
     * Copy post
     *
     * @param {PostInterface} post
     */
    copyPost(post: PostInterface) {
        this.openModal.copyPost(PostManagerComponent, post).afterClosed().subscribe(result => {
            if (result) {
                this._listChange = true;
            }
        });
    }

    /**
     * Replace post in list
     *
     * @param {PostInterface} newPost
     */
    replacePost(newPost: PostInterface) {
        this.posts = this.posts.map(post => {
            newPost['activeFromModified'] = moment(newPost.activeFrom).format(BASE_TIME_FORMAT);

            return (Number(post.postID) === Number(newPost.postID)) ? newPost : post;
        });
    }

    onStatusChange(post: PostInterface) {
        this._listChange = true;
    }

    /**
     * Optimize posts for layout
     */
    renderPosts(): void {
        this.posts = [];
        this.nonFilteredPosts = [];
        this.dialogData.events.forEach(event => {
            let filteredOut = false;
            const post: PostInterface = Object.assign({}, event.meta.post);

            post['activeFromModified'] = moment(post.activeFrom).format(BASE_TIME_FORMAT);

            let socialSiteObject = this._socialSites.find(value => {
                return (value.siteID === post.socialSiteID);
            });

             // if the post has no socialSiteID, it probably has more sites to the post so we go a level deeper
            if (!socialSiteObject && post.socialSites && post.socialSites.length) {
                socialSiteObject = this._socialSites.find(value => {
                    return post.socialSites.some(socialSite => {
                        return value.siteID === socialSite.socialSiteID;
                    });
                });
            }



            if (this.activeFilters.platforms.length > 0) {
                if (this.activeFilters.platforms.indexOf(socialSiteObject?.socialType) === -1) {
                    filteredOut = true;
                }
            }

            post['socialSiteName'] = socialSiteObject ? socialSiteObject.name : '';

            post['enableEdit'] = moment().add(5, 'minute').isBefore(post.activeFrom, 'minute');

            post.socialSites?.forEach(socialSite => {
                socialSite.userHasAccessTo = this._socialSites.some(value => {
                    return value.siteID === socialSite.socialSiteID;
                });
            });

            if (socialSiteObject) {
                if (!filteredOut) {
                    this.posts.push(post);
                };
                this.nonFilteredPosts.push(post);
            } else {
                if (environment.isDebugMode) {
                    console.error('!!! Social site not found !!!', post, this._socialSites);
                }
            }
        });

        this.posts = Helpers.orderBy(this.posts, 'activeFrom', 'ASC');

    }

    categoriesInit() {
        if (!this._categoriesComponent) {
            this._categoriesComponent = new CategoriesComponent(this._categoriesService);
        }

        this._categoriesComponent.getItems(response => {
            this.categories = response.categories;
        });
    }

    goPreview(template) {
        const post = {
            ...template,
            activeFrom: template.createDate,
            linkshare: template.linkshare || {}
        };

        this.openModal.entityPreview(PostPreviewComponent, post, {
            data: {
                successApprove: () => {
                    this.postActionsService.requestRefresh();
                    this._dialogRef.close(true);
                }
            }
        });
    }

    isActiveFilter(filterType: string, value) {
        return this.activeFilters[filterType].indexOf(value) >= 0;
    }

    postCountByPlatform = (platform: string) => {
        return this.nonFilteredPosts.filter(post => {
            return (post?.socialType === platform || (post.socialSites && post.socialSites.length > 0 && post.socialSites.find(site => site?.socialType === platform)));
        }).length;
    };

    isDisabledFilter(filterType: string, value) {
        switch (filterType) {
            case 'platforms':
                return this.postCountByPlatform(value) === 0;
            case 'categories':
                return this.activeFilters[filterType].length === 0;
        }
    }

    setActiveFilter(filterType: string, value) {
        switch (filterType) {
            case 'platforms':
                if (this.isDisabledFilter(filterType, value)) { return };
                if (this.activeFilters.platforms.indexOf(value) === -1 && this.postCountByPlatform(value) > 0) {
                    this.activeFilters.platforms.push(value);
                } else {
                    this.activeFilters.platforms = this.activeFilters.platforms.filter(item => item !== value);
                }
                break;
            case 'categories': // TODO!
                if (this.activeFilters.categories.indexOf(value) === -1) {
                    this.activeFilters.categories.push(value);
                } else {
                    this.activeFilters.categories = this.activeFilters.categories.filter(item => item !== value);
                }
                break;
        }
        this.renderPosts();
    }

    createTemplateFromPost(post: PostInterface) {
        post.activeFromInput = null;
        this.openModal.createTemplateRaw(PostTemplateManagerComponent, {
            data: {
                template: post,
                isTemplateFromPost: true,
            },
        });
    }
}
