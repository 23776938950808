import {Injectable} from '@angular/core';
import {BackendService} from '~/src/app/core/backend.service';
import {MENTION_API_RESOURCE_MENTION} from '~/src/app/modules/mention/mention.contant';
import {IMentionListParams, IMentionListResponse} from '~/src/app/modules/mention/mention.interface';
import {MentionModel} from '~/src/app/modules/mention/mention.model';

@Injectable({
    providedIn: 'root'
})
export class MentionService {

    constructor(
        private backendService: BackendService
    ) {}

    /**
     * @param {IMentionListParams} params
     * @return {Promise<MentionModel[]>}
     */
    getList(params: IMentionListParams): Promise<MentionModel[]> {
        return this.backendService.get(`${MENTION_API_RESOURCE_MENTION}`, params)
            .then((response: IMentionListResponse) => {
                if (!response || !response.mention || !response.mention.data) {
                    return Promise.resolve([]);
                }

                return Promise.resolve(response.mention.data.map(_mention => new MentionModel(_mention)));
            });
    }
}
