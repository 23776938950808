import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IAutocompleteItem} from '~/src/app/modules/posts/autocomplete.interface';
import {
    IMentionViewItem,
    MentionSelectionChangeEvent
} from '~/src/app/modules/social-media-post/post-skeleton/mention-list/mention-list';
import {LanguageService} from '~/src/app/services/language.service';
import {AUTOCOMPLETE_TYPE_MENTION} from '~/src/app/modules/posts/autocomplete.constants';
import {SocialMediaPlatforms} from '~/src/app/modules/social-media-post/social-media-platforms-config';

@Component({
    selector: 'smd-mention-list',
    templateUrl: './mention-list.component.html',
    styleUrls: ['./mention-list.component.scss']
})
export class MentionListComponent implements OnInit, OnDestroy {
    @Input() editor;
    @Input() platform: string;
    @Input() selectedMentions: IMentionViewItem[];
    @Output() selectionChange: EventEmitter<MentionSelectionChangeEvent> = new EventEmitter<MentionSelectionChangeEvent>();
    viewMentions: IMentionViewItem[] = [];

    constructor() {
    }

    ngOnInit() {
        this.editor.on(`autocomplete.${AUTOCOMPLETE_TYPE_MENTION}.selection`, (event) => this.select(event.data.item));
        this.editor.on(`autocomplete.${AUTOCOMPLETE_TYPE_MENTION}.unselect`, (event) => this.unselect(event.data.item));
        this.viewMentions = this.selectedMentions || [];

        if (this.selectedMentions && this.selectedMentions.length) {
            this.editor.fire('autocomplete.initMentions', {data: {items: this.selectedMentions}});
        }
    }

    ngOnDestroy(): void {
        this.editor.off(`autocomplete.${AUTOCOMPLETE_TYPE_MENTION}.selection`);
        this.editor.off(`autocomplete.${AUTOCOMPLETE_TYPE_MENTION}.unselect`);
    }

    /**
     * Select mention item
     * @param {IMentionViewItem} viewItem
     */
    select(viewItem: IMentionViewItem): void {
        viewItem.items = viewItem.items.map(_item => {
            return {
                ..._item,
                iconClass: this._getPlatformConfig(_item.platform).iconClass + ` color-${_item.platform}`,
                platformName: LanguageService.getLine(this._getPlatformConfig(_item.platform).tabNameKey)
            };
        });

        const index = this.viewMentions.findIndex(_item => _item.uid === viewItem.uid);

        if (index > -1) {
            this.viewMentions.splice(index, 1, viewItem);
        } else {
            this.viewMentions.push(viewItem);
        }

        this._emitSelectionChange();
    }

    /**
     * Unselect mention item
     * @param {IMentionViewItem} viewItem
     */
    unselect(viewItem: IMentionViewItem): void {
        const index = this.viewMentions.findIndex(_item => _item.uid === viewItem.uid);

        if (index > -1) {
            this.viewMentions.splice(index, 1);
            this._emitSelectionChange();
        }
    }

    /**
     * Remove mention item
     * @param {MouseEvent} event
     * @param {IAutocompleteItem} item
     */
    removeItem(event: MouseEvent, item: IAutocompleteItem) {
        event.preventDefault();
        this._removeItemFromEditor(item);
    }

    /**
     * Trigger selection change event
     * @private
     */
    _emitSelectionChange(): void {
        this.selectionChange.emit({
            items: this.viewMentions
        });
    }

    /**
     * Get social media platform config
     * @param {string} platform
     * @return {SocialMediaPlatform}
     * @private
     */
    _getPlatformConfig(platform: string) {
        return SocialMediaPlatforms.find(item => item.platform === platform);
    }

    /**
     * @param {IAutocompleteItem} item
     * @private
     */
    _removeItemFromEditor(item: IAutocompleteItem): void {
        this.editor.fire('autocomplete.removeItem', {data: {item}});
    }
}
