
export class CoreConfig {

    /**
     * Get system types
     */
    static getSystemTypes() {
        return {
            Generic: 'generic',
            Branded: 'branded',
            Custom: 'custom'
        };
    }
}
