import {Injectable} from '@angular/core';
import {PostPreviewComponent} from '~/src/app/modules/posts/post-previews/post-preview/post-preview.component';
import {MatDialog} from '@angular/material/dialog';
import {PostActionsService} from '~/src/app/modules/posts/post-actions.service';
import {PostActions} from '~/src/app/modules/posts/post-actions';
import {CarouselController} from '~/src/app/modules/posts/carousel/carousel.component';
import {UsersController} from '~/src/app/modules/users/users/users.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {PostPreviewDialogData} from '~/src/app/modules/posts/post-previews/post-preview/post-preview.interfaces';

@Injectable({providedIn: 'root'})
export class ShowPreviewService {
    private postActions: PostActions;

    constructor(
        private dialog: MatDialog,
        private postActionsService: PostActionsService,
        usersController: UsersController,
        carouselController: CarouselController,
        openModal: OpenModalService
    ) {
        this.postActions = new PostActions(postActionsService, dialog, usersController, carouselController, openModal);
    }

    showPreview(entity, options: PostPreviewDialogData = {}) {
        const entityType = entity.postID ? 'post' : 'postTemplate';
        const entityID = entity.postID ? entity.postID : entity.templateID;
        const post = {
            headline: entity.headline,
            subHeadline: entity.subHeadline,
            mainCopy: entity.mainCopy,
            signature: entity.signature,
            activeFrom: entity.createDate || entity.activeFrom,
            linkshare: entity.linkshare || {},
            medias: entity.medias,
            socialSiteName: 'social-site-name',
            status: entity.status,
            type: entity.type,
            socialType: entity.socialType,
            approveButtonVisible: entity.approveButtonVisible,
            declineButtonVisible: entity.declineButtonVisible
        };

        return new Promise((resolve, reject) => {
            this.dialog.open(PostPreviewComponent, {
                data: {
                    ...options,
                    post: post
                },
                width: '750px'
            }).afterClosed().subscribe(result => {
                if (result && ('status' in result)) {
                    this.postActions.setPostStatus(
                        entityID,
                        this.getStatus(entityType, result.status),
                        entityType
                    ).then((response) => resolve(response)).catch((error) => reject(error));
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getStatus(type, status) {
        return status ? type === 'post' ? 'approved' : 'active' : 'notApproved';
    }
}
