import {ComponentAbstract} from '../../services/component.abstract';
import {CategoriesService} from './categories.service';
import {Injectable} from '@angular/core';

export class CategoriesComponent extends ComponentAbstract {

    constructor(
        public service: CategoriesService
    ) {
        super();

        this.filters = {};
    }

}

@Injectable({providedIn: 'root'})
export class CategoriesController extends CategoriesComponent {
    constructor(service: CategoriesService) {
        super(service);
    }
}
