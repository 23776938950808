import {ComponentAbstract} from '../../services/component.abstract';
import {StatusService} from './status.service';

export class StatusComponent extends ComponentAbstract {

    constructor(
        public service: StatusService
    ) {
        super();

        this.filters = {};
    }
}
