import {Injectable} from '@angular/core';
import {CommentForViewInterface} from './interfaces';
import {ComponentAbstract} from '../../../../services/component.abstract';
import {CommentModel} from './comment-model.service';
import {MatDialog} from '@angular/material/dialog';
import {PostCommentForView} from './post-comment-for-view';
import {UsersController} from '../../../users/users/users.component';

@Injectable({providedIn: 'root'})
export class CommentController extends ComponentAbstract {

    constructor(
        public service: CommentModel,
        public dialog: MatDialog,
        public users: UsersController,
    ) {
        super();
    }

    /**
     * Get comments for post by postID
     *
     * @param {number} postID
     * @return {Promise<any>}
     */
    getComments(postID: number | number[] = null, filterParams: object = {}, callback: (comments: CommentForViewInterface[]) => void = () => {}, failed: (error: any) => void = () => {}) {
        this.service.getComments(postID, filterParams)
            .then(response => {
                const comments = response.comments.map(comment => {
                    comment.profileImage = comment.profileImageUrl;
                    comment.userName = comment.name;
                    delete comment.profileImageUrl;
                    delete comment.name;
                    return new PostCommentForView(comment);
                });

                callback(comments);
            })
            .catch(error => {
                this.service.showErrorAlert(error);

                failed(error);
            });
    }

    /**
     * Create post comment
     *
     * @param {number} postID
     * @param {string} comment
     * @param {(response: any) => void} success
     * @param {(error: any) => void} failed
     */
    createComment(postID: number, comment: string, success: (response: any) => void = () => {}, failed: (error: any) => void = () => {}) {
        this.service.createComment(postID, comment)
            .then(response => {
                success(response);
            })
            .catch(error => {
                failed(error);

                this.service.showErrorAlert(error);
            });
    }

    getRecentComments(callback: (comments: CommentForViewInterface[]) => void = () => {}, failed: (error: any) => void = () => {}) {
        this.service.getRecentComments()
            .then((response) => {
                const comments = response.comments.map(comment => {
                    comment.profileImage = comment.profileImageUrl;
                    comment.userName = comment.name;
                    delete comment.profileImageUrl;
                    delete comment.name;
                    return new PostCommentForView(comment);
                });

                callback(comments);
            })
            .catch(error => {
                this.service.showErrorAlert(error);

                failed(error);
            });
    }
}
