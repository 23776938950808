import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {NotificationModel} from '~/src/app/modules/notification-menu/notification.model';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';

@Injectable({providedIn: 'root'})
export class NotificationService {

    apiLink = '/api/notifications';

    public commentNotificationEvent: EventEmitter<number>;

    constructor(private httpClient: HttpClient,) {
        this.commentNotificationEvent = new EventEmitter<number>();
    }

    // Get notifications
    getNotifications(): Promise<{"notifications": NotificationModel }> {
        return this.httpClient.get(this.apiLink, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then(response => response as {"notifications": NotificationModel })
            .catch(error => {
                console.error(error);
                return error;
            });
    }

    // GET request to get the lastNotification's date
    getLastNotificationDate(): Promise<{lastNotificationDate: string}> {
        return this.httpClient.get(this.apiLink + '/last-notification-date',
            Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then(response => response as string)
            .catch(error => {
                console.error(error);
                return error;
            });
    }

    // POST request to mark notifications as read
    seenNotification(notificationIDs: number[]): Promise<any> {
        const sendFormDataObj = new FormData();
        sendFormDataObj.append('notifications', JSON.stringify(notificationIDs));
        return this.httpClient.post(this.apiLink + '/seen', sendFormDataObj, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then(response => response)
            .catch(error => {
                console.error(error);
                return error;
            });
    }

    public triggerCommentNotificationEvent(objectID: number) {
        this.commentNotificationEvent.emit(objectID);
    }
}
