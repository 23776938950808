import {
    Component,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
    Input,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EventFormComponent } from "~/src/app/modules/social-media-post/gmb/form/event/event-form.component";
import { CallToActionFormComponent } from "~/src/app/modules/social-media-post/gmb/form/call-to-action/call-to-action-form.component";
import { OfferFormComponent } from "~/src/app/modules/social-media-post/gmb/form/offer/offer-form.component";
import { AbstractFormGroupComponent } from "~/src/app/modules/social-media-post/gmb/form/abstract/abstract.component";
import {
    ALERT_TYPE_OPTIONS,
    POST_TYPE_OPTIONS,
} from "~/src/app/modules/social-media-post/gmb/gmb.constants";
import { each, find } from "lodash";

@Component({
    selector: "smd-gmb-form",
    templateUrl: "./gmb-form.component.html",
    styleUrls: ["./gmb-form.component.scss"],
})
export class GmbFormComponent
    extends AbstractFormGroupComponent
    implements OnInit
{
    @ViewChild(OfferFormComponent, { static: true })
    offerForm: OfferFormComponent;
    @ViewChild(EventFormComponent, { static: true })
    eventForm: EventFormComponent;
    @ViewChild(CallToActionFormComponent, { static: true })
    callToActionForm: CallToActionFormComponent;

    @Output() changeMessageTitle = new EventEmitter();

    @Input() disabledFields: boolean = false;

    formGroup: FormGroup = this.fb.group({ type: null, alertType: null });
    postTypes = POST_TYPE_OPTIONS;
    alertTypes = ALERT_TYPE_OPTIONS;
    postType = null;

    ngOnInit(): void {
        this.formGroup.addControl("offer", this.offerForm.getForm());
        this.formGroup.addControl("event", this.eventForm.getForm());
        this.formGroup.addControl(
            "callToAction",
            this.callToActionForm.getForm()
        );
        this.subscriptions.push(
            this.formGroup.controls.type.valueChanges.subscribe(
                this.onTypeChanged.bind(this)
            )
        );
        this.formGroup.patchValue({ type: "standard" });
    }

    onTypeChanged(value) {
        const postType = find(this.postTypes, { value: value }),
            alertType = this.formGroup.get("alertType");

        each(["callToAction", "event", "offer"], (element) => {
            this[element + "Form"].toggleDisable(
                postType.hide.includes(element)
            );
        });

        postType.hide.includes("alertType")
            ? alertType.disable()
            : alertType.enable();

        this.changeMessageTitle.emit(value);
        this.postType = value;
    }

    public setGmbFormByGmbOptions(gmbOptions, gmbForm) {
        for (const fieldName in gmbOptions) {
            const fieldValue = gmbOptions[fieldName];

            if (typeof fieldValue === "object" && fieldValue !== null) {
                const innerForm = gmbForm.get(fieldName);

                for (const innerFieldName in fieldValue) {
                    let innerFieldvalue = fieldValue[innerFieldName];
                    if (
                        innerFieldName === "endTime" ||
                        innerFieldName === "startTime" ||
                        innerFieldName === "startDate" ||
                        innerFieldName === "endDate"
                    ) {
                        innerForm
                            .get(innerFieldName)
                            .setValue(
                                innerFieldvalue
                                    ? new Date(innerFieldvalue)
                                    : null
                            );
                    } else if (innerFieldName === "schedule") {
                        innerFieldvalue = {
                            ...innerFieldvalue,
                            startTime: innerFieldvalue.startTime
                                ? innerFieldvalue.startTime
                                : null,
                            endTime: innerFieldvalue.endTime
                                ? innerFieldvalue.endTime
                                : null,
                        };
                    } else {
                        innerForm.get(innerFieldName).setValue(innerFieldvalue);
                    }
                }
            } else {
                gmbForm.get(fieldName).setValue(fieldValue);
            }
        }
    }
}
