import {Token} from './token';
import {CustomEvents, LoggedUserInterface} from './helpers';
import Utils from '~/src/app/core/utils';
import {DateSupport} from '~/src/app/core/helper/date-support';

/**
 * Logged user manager
 */
export class LoggedUser {
    private static readonly _storageKey: string = 'loggedUser';

    public static hasUser(): boolean {
        return !!localStorage.getItem(this._storageKey);
    }

    public static saveUser(data: LoggedUserInterface) {
        localStorage.setItem(this._storageKey, JSON.stringify(data));
        CustomEvents.userupdate();
    }

    public static getUser(): LoggedUserInterface {
        const user: LoggedUserInterface = JSON.parse(localStorage.getItem(this._storageKey));

        if (user && !!user.profileImageUrl && user.profileImageUrl !== '') {
            user.profileImageUrl = user.profileImageUrl + `&token=${Token.getToken()}`;
        }

        return (user || {}) as LoggedUserInterface;
    }

    /**
     * Get organizations
     * @return {any}
     */
    public static getOrganizations() {
        return LoggedUser.getUser().organizations;
    }

    /**
     * Get main organization
     * @return {any}
     */
    public static getMainOrganization() {
        const organizations = LoggedUser.getUser().organizations;
        let mainOrganization = null;

        for (const organization of Object.values(organizations)) {
            if (organization.main) {
                mainOrganization = organization;
            }
        }

        return mainOrganization;
    }

    /**
     * Get user default timezone name
     *
     * @returns {string}
     */
    public static getUserDefaultTimeZoneName(): string {
        return Utils.get(
            LoggedUser.getUser(),
            'settings.defaultTimeZone',
            DateSupport.getClientTimeZoneName()
        );
    }

    public static deleteUser() {
        localStorage.removeItem(this._storageKey);
    }
}
