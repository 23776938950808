import {ComponentAbstract} from '../../services/component.abstract';
import {MediaTagsService} from './media-tags.service';

export class MediaTagsComponent extends ComponentAbstract {
    constructor(
        public service: MediaTagsService
    ) {
        super();

        this.filters = {};
    }
}
