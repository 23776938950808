import * as moment from 'moment-timezone/builds/moment-timezone-with-data';
import Utils from '~/src/app/core/utils';

export class DateSupport {

    /**
     * Moment with timezone
     */
    public static moment = moment;

    /**
     * Get client timezone name
     *
     * @returns {string}
     */
    public static getClientTimeZoneName(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
     * Format date
     *
     * @param {string} date
     * @param {string} format
     * @return {string}
     */
    static dateTransform(date: string | Date, format?: string) {
        let result = '';

        if (!!date) {

            if (!format || !Utils.lodash.isString(format)) {
                format = 'DD MMMM YYYY, h:mm A';
            }

            result = this.moment(date).isValid() ? Utils.getViewDateFormat(date, format) : date;
        }

        return result;
    }
}
