import {Injectable} from '@angular/core';
import {ModelAbstract} from '~/src/app/services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {Addon, AddonsResponse} from '~/src/app/modules/administration/addons.interfaces';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import Utils from '~/src/app/core/utils';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {Token} from '~/src/app/services/token';
import {Helpers} from '~/src/app/services/helpers';

@Injectable({
    providedIn: 'root'
})
export class AddonsService extends ModelAbstract {
    private _addonCollection: BehaviorSubject<Addon[]> = new BehaviorSubject<Addon[]>([]);
    _loaderRef: MatDialogRef<DialogLoaderComponent>;
    isPending = false;

    constructor(
        public http: HttpClient,
        public dialog: MatDialog,
        public openModal: OpenModalService
    ) {
        super(http, dialog);

        this.apiLink = '/api/addon';
    }

    get addons(): Observable<Addon[]> {
        return this._addonCollection.asObservable();
    }

    /**
     * Get addon collection
     * @param filters
     * @return {Promise<AddonsResponse>}
     */
    getAddons(filters?) {
        return this.getAll(filters).then((response: AddonsResponse) => {
            this._addonCollection.next(
                response.addons.map(addon => {
                    addon.type = 'addon';
                    addon.config = Utils.lodash.get(addon, 'config', '{}') || '{}';
                    addon.description = {
                        short: Utils.lodash.truncate(addon.description, {length: 75}),
                        long: addon.description,
                        raw: addon.description
                    };

                    return addon;
                })
            );

            this.hideLoader();

            return response;
        }).catch(error => {
            this._addonCollection.next([]);

            this.hideLoader();

            return error;
        });
    }

    /**
     * Edit addon
     * @param {Addon} addon
     * @return {Promise<any>}
     */
    editAddon(addon: Addon) {
        delete addon.type;
        addon.description = addon.description.raw;

        return this.editWithPost(addon.addonID, Utils.obj2fd(addon));
    }

    /**
     * Sync addons from Zoho
     * @return {Promise<Object>}
     */
    syncAddons() {
        return this.http.get(this.apiLink + '/synchronize', Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
