export const LINK_SHORTEN_TYPE_BITLY = 'bitly';
export const LINK_SHORTEN_TYPE_ESEMDEE = 'own';
export const LINK_SHORTEN_TYPE_DISABLED = 'disabled';
export const LINK_SHORTEN_TYPES = [
    LINK_SHORTEN_TYPE_BITLY,
    LINK_SHORTEN_TYPE_ESEMDEE,
    LINK_SHORTEN_TYPE_DISABLED
];
export const LINK_SHORTEN_TYPE_OPTIONS = [
    {
        value: LINK_SHORTEN_TYPE_DISABLED,
        labelKey: 'linkShorten.disabled'
    },
    {
        value: LINK_SHORTEN_TYPE_BITLY,
        labelKey: 'linkShorten.name.bitly'
    },
    {
        value: LINK_SHORTEN_TYPE_ESEMDEE,
        labelKey: 'linkShorten.name.esemdee'
    }
];
