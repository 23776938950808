import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  EventEmitter,
  Inject
} from "@angular/core";
import { OrganizationGroupbyService } from '~/src/app/services/organization-groupby.service';
import { IRelatedUser } from "~/src/app/modules/users/users/users.interface";
import { HttpClient } from "@angular/common/http";
import {
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";

import {
  CollectionListSelectionChange,
  TableBodyConfig,
  TableHeadConfig,
} from "~/src/app/core/components/collection-list/collection-list.interfaces";
import { KeyValue } from "@angular/common";
import { DataTableHelpersService } from "~/src/app/core/services/data-table-helpers.service";
import { OrganisationService } from "~/src/app/modules/users/organizations/organisation.service";
import { OrganizationController } from '~/src/app/components/organization-select/organization.component';
import { OrganizationItem } from '~/src/app/components/organization-select/organization.interfaces';
import { LanguageService } from '~/src/app/services/language.service';
import { NotifyService } from '~/src/app/services/notify.service';
import { FormValidationService } from '~/src/app/services/form.validation.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Console, log } from 'console';
import Utils from "~/src/app/core/utils";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SocialSiteEditDialogComponent } from "~/src/app/modules/users/social-site-edit-dialog/social-site-edit-dialog.component";
import { SocialSiteComponent } from "~/src/app/components/social-site-select/social-site.component";
import { SocialSiteService } from "~/src/app/components/social-site-select/social-site.service";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { SocialChannels } from "~/src/app/modules/analytics/widget/widget.interfaces";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { SocialManagerComponent } from "~/src/app/modules/users/social-manager/social-manager.component";
import { SocialManagerService } from "~/src/app/modules/users/social-manager/social-manager.service";
import { Token } from "~/src/app/services/token";
import { Facebook } from "~/src/app/modules/users/Facebook.class";
import { FacebookService } from "ngx-facebook";
import { FacebookDialogComponent } from "~/src/app/modules/users/Facebook-dialog.component";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { TwitterAPI } from "~/src/app/modules/users/twitter.service";
import { BackendService } from "~/src/app/core/backend.service";
import { SocialSiteUtils } from "~/src/app/modules/users/social-site.utils";
import { GmbApiLocationService } from "~/src/app/modules/social-media-post/gmb/gmb-api-location.service";
import { LinkedInService } from "~/src/app/services/linked-in.service";
import { GmbLocationsDialogComponent } from "~/src/app/modules/users/gmb/gmb-locations-dialog.component";
import { selectOrgModalComponent } from "~/src/app/modules/users/organizations/select-org-modal/select-org-modal.component";
import { SocialMediaPlatformChooserComponent } from "~/src/app/modules/social-media-post/social-media-platform-chooser/social-media-platform-chooser.component";
import { EmailNotificationManagerComponent } from '~/src/app/modules/users/profile/email-notification-manager/email-notification-manager.component';
import { CustomEvents, LoggedUserInterface } from '~/src/app/services/helpers';
import { LoggedUser } from '~/src/app/services/logged-user';
import { HostListener } from '~/node_modules/@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Debounce,
  EditOrgErrorStateMatcher,
  FacebookUser,
  Helpers,
} from "~/src/app/services/helpers";
import {
  SOCIAL_MEDIA_TYPE_FACEBOOK,
  SOCIAL_MEDIA_TYPE_LINKEDIN,
  SOCIAL_MEDIA_TYPE_TWITTER,
  SOCIAL_MEDIA_TYPE_INSTAGRAM,
  SOCIAL_MEDIA_TYPE_GMB,
} from "~/src/app/core/constants";
import { platform } from "os";
import { ComponentType } from "@angular/cdk/portal";
import { groupBy } from "lodash";
type ModalType<T = any> = ComponentType<T> | TemplateRef<T>;
interface OrganizationNode {
  name: string;
  id: string,
  children?: OrganizationNode[];
}
const SITE_TYPE = {
  Profile: "profile",
  Page: "page",
  Group: "group",
};
type TYPE_PROFILE = "profile";
type TYPE_PAGE = "page";
type TYPE_GROUP = "group";
type SOCIAL_PLATFORMS =
  | "facebook"
  | "linkedIn"
  | "twitter"
  | "instagram"
  | "gmb";
const SOCIAL_PLATFORMS = {
  Facebook: SOCIAL_MEDIA_TYPE_FACEBOOK,
  LinkedIn: SOCIAL_MEDIA_TYPE_LINKEDIN,
  Twitter: SOCIAL_MEDIA_TYPE_TWITTER,
  Instagram: SOCIAL_MEDIA_TYPE_INSTAGRAM,
  Gmb: SOCIAL_MEDIA_TYPE_GMB,
};
interface DialogData {
  title: string;
  socialsite: boolean;
}
@Component({
  selector: 'smd-organizations-groupby-organization',
  templateUrl: './organizations-groupby-organization.component.html',
  styleUrls: ['./organizations-groupby-organization.component.scss'],
  providers: [DataTableHelpersService, ComponentHelpers, TwitterAPI],
})
export class OrganizationsGroupbyOrganizationComponent implements OnInit {
  listener: EventEmitter<any> = new EventEmitter<any>();
  set submitEvent(emitter: EventEmitter<any>) {
    this.listener.unsubscribe()
    this.listener = emitter
    this.listener.subscribe(this.anotherFunction.bind(this))
  }
  fb: Facebook;
  filterData: any;
  organizations: OrganizationItem[] = [];
  isLoading = false;
  treeControl = new NestedTreeControl<OrganizationNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<OrganizationNode>();
  columnVisibility: any = {
    platform: { name: "platform", visible: true },
    // medias: { name: "post.manager.media", visible: true },
    name: { name: "social.page.name", visible: true },
    timezone: { name: "social.timezone", visible: true },
    socialType: { name: "social.sites.type", visible: true },
    token: { name: "social.sites.token.status", visible: true, type: 'html' },
    connected: { name: "social.sites.connected", visible: true },
    actions: { name: "social.sites.actions", visible: true },

  };
  defaultColumns: any[] = Object.keys(this.columnVisibility);
  tableHeadConfig: TableHeadConfig[] = [
    { nameKey: "platform", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["platform"].visible },
    // { nameKey: "post.manager.media", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["medias"].visible },
    { nameKey: "social.page.name", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["name"].visible },
    { nameKey: "social.timezone", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["timezone"].visible },
    { nameKey: "social.sites.type", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["socialType"].visible },
    { nameKey: "social.sites.token.status", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["token"].visible },
    { nameKey: "social.sites.connected", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["connected"].visible },
    { nameKey: "social.sites.actions", onlyDesktop: true, onlyMobile: true, enable: () => this.columnVisibility["actions"].visible },
    // { nameKey: "social.sites.actions", onlyDesktop: true, onlyMobile: true },
  ];
  tableBodyConfig: TableBodyConfig[] = [
    { bindValue: "sitename", onlyDesktop: true, selector: "sitename", enable: () => this.columnVisibility["platform"].visible },
    // { bindValue: "medias", selector: "mediasView", onlyDesktop: true, enable: () => this.columnVisibility["medias"].visible },
    { bindValue: "sitename", onlyDesktop: true, enable: () => this.columnVisibility["name"].visible },
    { bindValue: "timezone", onlyDesktop: true, enable: () => this.columnVisibility["timezone"].visible },
    { bindValue: "pageType", onlyDesktop: true, enable: () => this.columnVisibility["socialType"].visible },
    { bindValue: "pageAccessTokenExpire", selector: "token", onlyDesktop: true, enable: () => this.columnVisibility["token"].visible },
    { bindValue: "firstName", onlyDesktop: true, selector: "username", enable: () => this.columnVisibility["connected"].visible },
    {
      staticView: "itemActions",
      onlyDesktop: true,
    }

  ];
  public isAdminMode = !!Utils.lodash.get(
    this.route,
    "snapshot.data.isAdmin",
    true
  );
  relatedUsers: Array<IRelatedUser> = [];
  objectRelatedUsers: KeyValue<String, Array<IRelatedUser>>;
  filterToggled = false;
  listView = true;
  posts = [];
  activeDialogLoader: MatDialog;
  socialSites: SocialSiteInterface[] = [];
  socialSitesUser: SocialSiteInterface[] = [];
  socialSitesPage: SocialSiteInterface[] = []; // for Facebook
  linkedInPages: SocialSiteInterface[] = [];
  linkedInProfiles: SocialSiteInterface[] = [];
  twitterProfiles: SocialSiteInterface[] = [];
  instagramPages: SocialSiteInterface[] = [];
  gmbProfiles: SocialSiteInterface[] = [];
  gmbPages: SocialSiteInterface[] = [];
  socialSitesGroup: SocialSiteInterface[] = [];
  organizationTree = []; // organizations objects
  showedOrganizations = [];
  organizationCounterAll = 0; // organizations number (all)
  socialSiteService;
  initializedOrganizations: boolean = false;
  preLoader;
  linkedInLoginWindow;
  isLoggedInWithLinkedIn: boolean;
  initialOrganizationID: string = null;
  initialTabName: string = null;
  initialSocialType: string = null;
  initialSocialSiteID: string = null;
  showComponentA = true;
  organization = {
    organizationID: 0,
    name: "",
    address: "",
    desc: "",
    createdDate: "",
    childNumber: 0,
    childs: [],
    rssArr: [],
  };
  user: LoggedUserInterface | {} = {};
  commonControlNames = {
    Organization: PostFormControlNames.Organization
  };
  commonFormGroup = new FormGroup({

    [this.commonControlNames.Organization]: new FormControl({
      value: null,
      disabled: this.socialSiteController.getSocialSitesInProgress,
    }),

  });
  socialSitesComponent: SocialSiteComponent;
  socialManagerComponent: SocialManagerComponent;
  public isActiveSocialChannelId: SocialChannels = SOCIAL_MEDIA_TYPE_FACEBOOK;
  constructor(
    private OrganizationGroupbyService: OrganizationGroupbyService,
    public dataTableHelper: DataTableHelpersService,
    private organizationService: OrganisationService,
    public languageService: LanguageService,
    private organizationController: OrganizationController,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public socialSitesService: SocialSiteService,
    private openModal: OpenModalService,
    private socialSiteController: SocialSiteController,
    private componentHelpers: ComponentHelpers,
    public socialManagerService: SocialManagerService,
    public twitterAPI: TwitterAPI,
    private backend: BackendService,
    public gmbApiService: GmbApiLocationService,
    private linkedinService: LinkedInService,
    private http: HttpClient,
    public fbService: FacebookService,
    public service: OrganisationService,
    public el: ElementRef<HTMLElement>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.fb = new Facebook(fbService, dialog);
    this.isLoggedInWithLinkedIn = this.linkedinService.isLoggedIn();
    this.socialSitesComponent = new SocialSiteComponent(socialSitesService);
    this.socialManagerComponent = new SocialManagerComponent(
      socialManagerService
    );
    this.setUser();
    this.getGroupByOrganization();

  }

  ngOnInit(): void {

    this.OrganizationGroupbyService.currentFilterData.subscribe(data => {
      this.filterData = data;
      if (data) {
        this.getGroupByOrganization(this.filterData);
      }
    });

  }
  async getInstaBusinesses(fbPages) {
    const promises = [];
    for (const page of fbPages) {
      promises.push(this.getInstaPages(page));
    }

    const responses = await Promise.all(promises);
    const instaPages = responses.filter((response) => response !== null);
    const businessPromises = [];
    for (const response of instaPages) {
      if (response) {
        businessPromises.push(this.getBusinessInstaPages(response));
      }
    }
    return await Promise.all(businessPromises);
  }

  async getInstaPages(page) {
    try {
      const response = await this.fb.getInstagramPages(
        page.id,
        page.access_token
      );
      if (response.data.length) {
        //console.log("Found instagram page connected to: " + page.name);
        return {
          facebook_page_id: page.id,
          access_token: page.access_token,
          name: response.data[0]["username"],
        };
      } else {
        return null;
      }
    } catch (e) {
      //console.log("getInstaPage error:");
      //console.log(e);
      return null;
    }
  }

  async getBusinessInstaPages(page) {
    try {
      const response = await this.fb.getInstagramBusinessID(
        page.facebook_page_id,
        page.access_token
      );
      if (response.instagram_business_account) {
        return { ...page, id: response.instagram_business_account.id };
      } else {
        return null;
      }
    } catch (e) {
      //console.log("getBusinessInstaPages error:");
      //console.log(e);
      return null;
    }
  }
  /**
   
     * @return {Promise<any>}
     */
  getGroupByOrganization(filters: any = {}) {
    // console.log('responseqqqqqqqqqqq', 'this.posts');
    const _loader = this.openModal.loader(DialogLoaderComponent);
    this.organizationService.getGroupByOrganization(filters).then((response) => {

      if (response) {
        this.posts = response['Organization'];

        this.isLoading = false;
        _loader.close();
        return response;

      } else {
        _loader.close();
      }
    });
  }
  /**
     * Edit Social site modal
     *
     * @param id
     * @param name
     * @param timezone
     */
  openEditDialog(id, name, timezone) {
    this.dialog
      .open(SocialSiteEditDialogComponent, {
        data: {
          id,
          name,
          timezone,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.socialSitesUser.length = 0;
          this.socialSitesPage.length = 0;
          this.socialSitesGroup.length = 0;
          this.linkedInPages.length = 0;
          this.socialNetworkGet();
        }
      });
  }
  /**
    *
    * Get all social pages for organization
    *
    * @param event
    */
  @Debounce()
  socialNetworkGet(): Promise<any> {
    const orgID = this.organization.organizationID;


    const _loader = this.openModal.loader(DialogLoaderComponent);

    this.socialSitesPage = [];
    this.socialSitesUser = [];
    this.socialSitesGroup = [];
    this.linkedInPages = [];
    this.twitterProfiles = [];
    this.instagramPages = [];
    this.gmbPages = [];
    // console.log('getAll', 'getAll');

    return new Promise<any>((resolve, reject) => {
      // this.socialSitesService
      //   .getAll({
      //     organizationID: [orgID],
      //     socialType: this.isActiveSocialChannelId,
      //   })
      //   .then((data) => {
      //     this.getSocialSitesByOrgID(data, undefined, orgID);

      //     _loader.afterClosed().subscribe(() => {
      //       resolve(data);
      //     });

      //     _loader.close();
      //   })
      //   .catch((err) => {
      //     this.getSocialSitesByOrgID(undefined, err, orgID);
      //     this.getOrganizationGroupBy();
      //     _loader.afterClosed().subscribe(() => {
      //       reject(err);
      //     });

      //     _loader.close();
      //   });
      this.getGroupByOrganization();
      _loader.close();
    });
  }
  anotherFunction() {
    this.socialNetworkGet()
      .then((data) => {
        console.log('Data:', data);
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  }
  /**
       * Get social sites from API
       */
  getSocialSitesByOrgID(
    socialData: { socialSites: SocialSiteInterface[] } = {
      socialSites: [],
    },
    error,
    orgID
  ) {
    const socialSites = socialData.socialSites
      .filter((site) => {
        return site.organizationID === orgID;
      })
      .map((site) => {
        site.isExpireInFiveDays = false;

        if (site.pageAccessTokenExpire) {
          site.isExpireInFiveDays = Utils.moment(
            site.pageAccessTokenExpire
          ).isBefore(Utils.moment().add(5, "day"));
          site.accessTokenIsExpired = Utils.moment(
            site.pageAccessTokenExpire
          ).isBefore();
        }

        return site;
      });

    this.socialSitesPage = [];
    this.socialSitesUser = [];
    this.socialSitesGroup = [];
    this.linkedInPages = [];
    this.linkedInProfiles = [];
    this.twitterProfiles = [];
    this.instagramPages = [];
    this.gmbPages = [];

    socialSites.map((socialSite) => {
      if (socialSite.pageType === SITE_TYPE.Page) {
        if (socialSite.socialType === "linkedIn") {
          this.linkedInPages.push(socialSite);
        }
        if (socialSite.socialType === SOCIAL_MEDIA_TYPE_FACEBOOK) {
          this.socialSitesPage.push(socialSite);
        }
        if (socialSite.socialType === "instagram") {
          this.instagramPages.push(socialSite);
        }
        if (socialSite.socialType === "gmb") {
          this.gmbPages.push(socialSite);
        }
      }

      if (socialSite.pageType === "user") {
        if (socialSite.socialType === SOCIAL_MEDIA_TYPE_FACEBOOK) {
          this.socialSitesUser.push(socialSite);
        }
        if (socialSite.socialType === SOCIAL_MEDIA_TYPE_LINKEDIN) {
          this.linkedInProfiles.push(socialSite);
        }
        if (socialSite.socialType === SOCIAL_MEDIA_TYPE_TWITTER) {
          this.twitterProfiles.push(socialSite);
        }
        if (socialSite.socialType === SOCIAL_MEDIA_TYPE_GMB) {
          this.gmbProfiles.push(socialSite);
        }
      }

      if (socialSite.pageType === SITE_TYPE.Group) {
        this.socialSitesGroup.push(socialSite);
      }
    });
  }
  addTwitterSite() {
    this.twitterAPI.authenticate((data) => {
      if (data.successAuth) {
        this.componentHelpers.startApiAction(
          () => {
            return this.socialManagerComponent.addProfiles([
              {
                socialType: SOCIAL_MEDIA_TYPE_TWITTER,
                organizationID:
                  this.organization.organizationID.toString(),
                userID: data.twitterToken.user_id,
                userName: data.twitterToken.screen_name,
                accessToken: data.twitterToken.oauth_token,
                accessTokenSecret:
                  data.twitterToken.oauth_token_secret,
              },
            ]);
          },
          {
            failedMessageKey:
              "organization.connect.twitter.site.failed",
            showSuccessModal: false,
            afterSuccessAction: () => {
              this.socialNetworkGet();
            },
          }
        );
      } else {
        this.openModal.errorModal(DialogErrorComponent, {
          message:
            data.message ||
            Utils.get(data, "error.message", null) ||
            LanguageService.getLine(
              "organization.connect.twitter.site.failed"
            ),
        });
      }
    });
  }

  /**
   * Add new google account
   */
  addGmbSite() {
    this.gmbApiService.authorization().then(() => {
      this.dialog
        .open(GmbLocationsDialogComponent, {
          data: {
            dialogTitle: LanguageService.getLine(
              "social.dialog.title.location"
            ),
            headLine: LanguageService.getLine(
              "social.select.locations"
            ),
            organizationID: this.organization.organizationID,
          },
        })
        .afterClosed()
        .subscribe(
          (result) => result ? this.socialNetworkGet() : null,
          (error) => {
            this.openModal.errorModal(DialogErrorComponent, {
              message:
                error.message ||
                Utils.get(error, 'error.message', null) ||
                LanguageService.getLine(
                  'organization.connect.google.site.failed'
                ),
            });
          }
        );
    })
      .catch((e) => {
        this.openModal.errorModal(DialogErrorComponent, {
          message:
            e.message ||
            Utils.get(e, 'error.message', null) ||
            LanguageService.getLine(
              'organization.connect.google.site.failed'
            ),
        });
      });
  }

  /**
   * Check facebook login and get social pages
   *
   */
  facebookLoginPage() {
    const orgID = this.organization.organizationID;
    this.facebookLogin("page")
      .then((res) => {
        if (!!res) {
          this.dialog
            .open(FacebookDialogComponent, {
              data: {
                organizationID: orgID,
                page: "page",
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.socialNetworkGet();
              }
            });
        }
      })
      .catch((e) => {
        return;
      });
  }

  /**
   * Check facebook login and get insta pages
   *
   */
  instagramLoginPage() {
    const orgID = this.organization.organizationID;
    this.facebookLogin("page")
      .then((res) => {
        if (!!res) {
          this.dialog
            .open(FacebookDialogComponent, {
              data: {
                organizationID: orgID,
                page: "instagram",
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.socialNetworkGet();
              }
            });
        }
      })
      .catch((e) => {
        return;
      });
  }

  /**
   * Check facebook login and get social groups
   *
   */
  facebookLoginGroup() {
    const orgID = this.organization.organizationID;
    this.facebookLogin("group").then((res) => {
      if (!!res) {
        this.dialog
          .open(FacebookDialogComponent, {
            data: {
              organizationID: orgID,
              page: "group",
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.socialNetworkGet();
            }
          });
      }
    });
  }

  facebookLogin(type: "page" | "group" | "simple" = "simple") {

    return this.fb.loginWithOptions(type).then((res) => {
      if (res.status === "connected") {
        return res;
      }

      return false;
    });
  }
  /**
     * Refresh facebook site access token
     * @param {SocialSiteInterface} socialSite
     * @param {"page" | "group"} type
     * @private
     */
  /**
      * Refresh instagram site access token
      * @param {SocialSiteInterface} socialSite
      * @param {"page" | "group"} type
      * @private
      */
  private _refreshInstagramSite(
    socialSite: SocialSiteInterface,
    type: TYPE_PAGE | TYPE_GROUP
  ) {
    this.facebookLogin(type).then((res) => {
      if (!!res) {
        const loader = this.openModal.loader(DialogLoaderComponent);
        const methods = {
          page: "getPages",
        };

        this.fb[methods[type]]()
          .then(async (response) => {
            try {
              let fbPages = response.data;
              const businessReturn =
                await this.getInstaBusinesses(fbPages);
              const businessPages = await businessReturn.filter(
                (businessPage) => businessPage !== null
              );

              let page = await businessPages.find(
                (item) => item.id == socialSite.pageID
              );
              if (!!page) {
                this.fb
                  .getPageAccessToken(
                    page.facebook_page_id,
                    this.backend
                  )
                  .then((access) => {
                    this.socialManagerComponent
                      .editPage(socialSite.siteID, {
                        name: socialSite.name,
                        timezone: socialSite.timezone,
                        pageAccessToken:
                          type === "group"
                            ? FacebookUser.getData().access_token
                            : access.accessToken,
                        userAccessToken:
                          type === "group"
                            ? FacebookUser.getData().access_token
                            : access.userAccessToken,
                        pageAccessTokenExpire:
                          SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                        connectedFacebookPageID: page.facebook_page_id,
                      })
                      .then((editResponse) => {
                        loader
                          .afterClosed()
                          .subscribe(() => {
                            this.openModal
                              .successModal(
                                DialogSuccessComponent,
                                {
                                  message:
                                    LanguageService.getLine(
                                      "social.sites.refresh.success"
                                    ),
                                }
                              )
                              .afterClosed()
                              .subscribe(() => {
                                this.socialNetworkGet();
                              });
                          });

                        loader.close();
                      })
                      .catch((editError) => {
                        loader
                          .afterClosed()
                          .subscribe(() => {
                            this.openModal.errorModal(
                              DialogErrorComponent,
                              {
                                message:
                                  FormValidationService.readError(
                                    editError
                                  ).message,
                              }
                            );
                          });

                        loader.close();
                      });
                  });
              } else {
                loader.afterClosed().subscribe(() => {
                  this.openModal.errorModal(
                    DialogErrorComponent,
                    {
                      message: LanguageService.getLine(
                        "organization.socialSite.error.refresh"
                      ),
                    }
                  );
                });

                loader.close();
              }
            } catch (e) {
              loader.afterClosed().subscribe(() => {
                this.openModal.errorModal(
                  DialogErrorComponent,
                  {
                    message: LanguageService.getLine(
                      "organization.socialSite.error.refresh"
                    ),
                  }
                );
              });
              loader.close();
            }
          })
          .catch((error) => {
            loader.afterClosed().subscribe(() => {
              this.openModal.errorModal(DialogErrorComponent, {
                message: LanguageService.getLine(
                  "organization.socialSite.error.refresh"
                ),
              });
            });
            loader.close();
          });
      }
    });
  }

  private _refreshGmbSite(socialSite: SocialSiteInterface, type: TYPE_PAGE) {
    this.gmbApiService.authorization().then(() => {
      const loader = this.openModal.loader(DialogLoaderComponent);
      this.gmbApiService.get().then((response: any) => {
        if (
          response.length > 0 && response.map(item => (item.location)).filter(Boolean).length > 0
        ) {
          const splitID = socialSite.pageID.split("/");
          const pageID = splitID.length === 4 ? splitID[3] : null;
          const location = response.find(
            (item) => item.location.name.split("/")[1] === pageID
          );

          if (location) {
            this.gmbApiService
              .refresh(socialSite.siteID)
              .then(() => {
                loader.afterClosed().subscribe(() => {
                  this.openModal
                    .successModal(DialogSuccessComponent, {
                      message: LanguageService.getLine(
                        "social.sites.refresh.success"
                      ),
                    })
                    .afterClosed()
                    .subscribe(() => {
                      this.socialNetworkGet();
                    });
                });
                loader.close();
              })
              .catch((response) => {
                loader.afterClosed().subscribe(() => {
                  this.openModal.errorModal(
                    DialogErrorComponent,
                    {
                      message: LanguageService.getLine(
                        "organization.socialSite.error.refresh"
                      ),
                    }
                  );
                });
                loader.close();
              });
          } else {
            this.openModal.errorModal(DialogErrorComponent, {
              message: LanguageService.getLine(
                "organization.socialSite.error.refresh"
              ),
            });
            loader.close();
          }
        }
      });
    });
  }

  /**
  * Refresh facebook site access token
  * @param {SocialSiteInterface} socialSite
  * @param {"page" | "group"} type
  * @private
  */
  private _refreshFacebookSite(
    socialSite: SocialSiteInterface,
    type: TYPE_PAGE | TYPE_GROUP
  ) {
    this.facebookLogin(type).then((res) => {
      if (!!res) {
        const loader = this.openModal.loader(DialogLoaderComponent);
        const methods = {
          page: "getPages",
          group: "getGroups",
        };

        this.fb[methods[type]]()
          .then((response) => {
            const page = response.data.find(
              (item) => item.id === socialSite.pageID
            );

            if (!!page) {
              this.socialManagerComponent
                .editPage(socialSite.siteID, {
                  name: socialSite.name,
                  timezone: socialSite.timezone,
                  pageAccessToken:
                    type === "group"
                      ? FacebookUser.getData()
                        .access_token
                      : page.access_token,
                  pageAccessTokenExpire:
                    SocialSiteUtils.getFacebookPageAccessTokenExpire(),
                })
                .then((editResponse) => {
                  loader.afterClosed().subscribe(() => {
                    this.openModal
                      .successModal(
                        DialogSuccessComponent,
                        {
                          message:
                            LanguageService.getLine(
                              "social.sites.refresh.success"
                            ),
                        }
                      )
                      .afterClosed()
                      .subscribe(() => {
                        this.socialNetworkGet();
                      });
                  });

                  loader.close();
                })
                .catch((editError) => {
                  loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(
                      DialogErrorComponent,
                      {
                        message:
                          FormValidationService.readError(
                            editError
                          ).message,
                      }
                    );
                  });

                  loader.close();
                });
            } else {
              loader.afterClosed().subscribe(() => {
                this.openModal.errorModal(
                  DialogErrorComponent,
                  {
                    message: LanguageService.getLine(
                      "organization.socialSite.error.refresh"
                    ),
                  }
                );
              });

              loader.close();
            }
          })
          .catch((error) => {
            loader.close();
          });
      }
    });
  }
  public loginWithLinkedIn(
    isForced = false,
    successCallback: () => void = null
  ) {
    const success = (event: CustomEvent) => {
      // window.removeEventListener(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, success);
      // window.removeEventListener(LINKEDIN_FAILED_LOGIN_EVENT_NAME, failed);

      if (isForced) {
        setTimeout(() => {
          this.linkedInLoginWindow.close();
        }, 5000);
      }

      const loader = this.openModal.loader(DialogLoaderComponent);
      this.linkedinService
        .handleLinkedInTokenByCode(event.detail.code)
        .then(() => {
          this.isLoggedInWithLinkedIn =
            this.linkedinService.isLoggedIn();

          loader.afterClosed().subscribe(() => {
            if (!!successCallback) {
              successCallback();
            } else {
              this.socialNetworkGet();
            }
          });
          loader.close();
        })
        .catch((err) => {
          if (err && (err.massage || err.error_description)) {
            loader.afterClosed().subscribe(() => {
              this.openModal.errorModal(DialogErrorComponent, {
                message: err.message || err.error_description,
              });
            });
          }

          loader.close();
        });
    };
    const failed = (event: CustomEvent) => {
      // window.removeEventListener(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, success);
      // window.removeEventListener(LINKEDIN_FAILED_LOGIN_EVENT_NAME, failed);

      this.openModal.errorModal(DialogErrorComponent, {
        message: event.detail.message,
      });
    };

    window["smdData"] = {
      forceLogin: isForced,
      success: (event: CustomEvent) => {
        success(event);
      },
      failed: (event: CustomEvent) => {
        failed(event);
      },
    };

    const windowFeatures =
      "location=yes,height=570,width=520,scrollbars=no,status=no";
    this.linkedInLoginWindow = window.open(
      this.linkedinService.url,
      null,
      windowFeatures
    );

    // window.addEventListener(LINKEDIN_SUCCESS_LOGIN_EVENT_NAME, success);
    // window.addEventListener(LINKEDIN_FAILED_LOGIN_EVENT_NAME, failed);
  }
  /**
  * Refresh LinkedIn sites access token
  * @param {SocialSiteInterface} socialSite
  * @param {"page" | "profile"} type
  * @private
  */
  private _refreshLinkedInSite(
    socialSite: SocialSiteInterface,
    type: TYPE_PAGE | TYPE_PROFILE
  ) {
    const editPage = (loader, condition: any) => {
      if (!!condition) {
        const data = {
          name: socialSite.name,
          timezone: socialSite.timezone,
          pageAccessToken: this.linkedinService.token,
        };

        if (this.linkedinService.expiresIn) {
          data["pageAccessTokenExpire"] =
            this.linkedinService.expiresIn;
        }

        this.socialManagerComponent
          .editPage(socialSite.siteID, data)
          .then(() => {
            loader.afterClosed().subscribe(() => {
              this.openModal
                .successModal(DialogSuccessComponent, {
                  message: LanguageService.getLine(
                    "social.sites.refresh.success"
                  ),
                })
                .afterClosed()
                .subscribe(() => {
                  this.socialNetworkGet();
                });
            });

            loader.close();
          })
          .catch((editError) => {
            loader.afterClosed().subscribe(() => {
              this.openModal.errorModal(DialogErrorComponent, {
                message:
                  FormValidationService.readError(editError)
                    .message,
              });
            });

            loader.close();
          });
      } else {
        loader.afterClosed().subscribe(() => {
          this.openModal.errorModal(DialogErrorComponent, {
            message: LanguageService.getLine(
              "organization.socialSite.error.refresh"
            ),
          });
        });

        loader.close();
      }
    };

    this.loginWithLinkedIn(false, () => {
      const loader = this.openModal.loader(DialogLoaderComponent);

      switch (type) {
        case SITE_TYPE.Page:
          this.linkedinService
            .getPages()
            .then((response) => {
              const condition = response.elements.find(
                (item: any) =>
                  item.organizationalTarget ===
                  socialSite.pageID
              );

              editPage(loader, condition);
            })
            .catch(() => loader.close());

          break;

        case SITE_TYPE.Profile:
          this.linkedinService
            .getMe()
            .then((response: any) => {
              const condition =
                `urn:li:person:${response.id}` ===
                socialSite.pageID;

              editPage(loader, condition);
            })
            .catch(() => loader.close());

          break;

        default:
          console.warn(`This site type (${type}) is not defined.`);
          loader.close();

          break;
      }
    });
  }

  /**
  * @param {SocialSiteInterface} socialSite
  * @param {TYPE_PROFILE} type
  * @private
  */
  private _refreshTwitterSite(
    socialSite: SocialSiteInterface,
    type: TYPE_PROFILE
  ) {
    this.twitterAPI.authenticate((data) => {
      if (data.successAuth) {
        this.componentHelpers.startApiAction(
          () => {
            if (
              socialSite.pageID.toString() ===
              data.twitterToken.user_id.toString()
            ) {
              return this.socialManagerComponent.editPage(
                socialSite.siteID,
                {
                  name: socialSite.name,
                  timezone: socialSite.timezone,
                  pageAccessToken:
                    data.twitterToken.oauth_token,
                  accessTokenSecret:
                    data.twitterToken.oauth_token_secret,
                }
              );
            } else {
              return Promise.reject({
                error: {
                  error: {
                    message: LanguageService.getLine(
                      "organization.socialSite.error.refresh"
                    ),
                  },
                },
              });
            }
          },
          {
            successMessageKey: "social.sites.refresh.success",
            failedMessageKey:
              "organization.refresh.twitter.site.failed",
            afterSuccessAction: () => {
              this.socialNetworkGet();
            },
          }
        );
      } else {
        this.openModal.errorModal(DialogErrorComponent, {
          message:
            data.message ||
            Utils.get(data, "error.message", null) ||
            LanguageService.getLine(
              "organization.refresh.twitter.site.failed"
            ),
        });
      }
    });
  }
  /**
     * Refresh social sites access token user action method
     * @param {SocialSiteInterface} socialSite
     * @param {"page" | "group" | "profile"} type
     * @param {"facebook" | "linkedIn" | "instagram", "gmb"} socialType
     */
  refreshSocialSite(
    socialSite: SocialSiteInterface,
    type: TYPE_PAGE | TYPE_GROUP | TYPE_PROFILE,
    socialType: SOCIAL_PLATFORMS
  ) {

    if (!socialSite || !socialSite.siteID || !type || !socialType) {


      console.error("Missing parameters at 'refreshSocialSite' method.");
      return;
    }

    switch (socialType) {
      case SOCIAL_MEDIA_TYPE_FACEBOOK:
        this._refreshFacebookSite(
          socialSite,
          type as TYPE_PAGE | TYPE_GROUP
        );

        break;

      case SOCIAL_MEDIA_TYPE_LINKEDIN:
        this._refreshLinkedInSite(
          socialSite,
          type as TYPE_PAGE | TYPE_PROFILE
        );

        break;

      case SOCIAL_MEDIA_TYPE_TWITTER:
        this._refreshTwitterSite(socialSite, type as TYPE_PROFILE);

        break;

      case SOCIAL_MEDIA_TYPE_INSTAGRAM:
        this._refreshInstagramSite(socialSite, type as TYPE_PAGE);

        break;

      case SOCIAL_MEDIA_TYPE_GMB:
        this._refreshGmbSite(socialSite, type as TYPE_PAGE);

        break;

      default:
        console.warn(
          `This social type (${socialType}) is not defined!`
        );

        break;
    }
  }

  /**
     * Delete social site
     *
     * @param {SocialSiteInterface} site
     * @param {SOCIAL_PLATFORMS} socialPlatform
     * @param {TYPE_PROFILE | TYPE_PAGE | TYPE_GROUP} type
     */
  deleteSocialSite(
    site: SocialSiteInterface,
    socialPlatform: SOCIAL_PLATFORMS,
    type: TYPE_PROFILE | TYPE_PAGE | TYPE_GROUP
  ) {

    const confirmMessageKeys = {
      [SOCIAL_PLATFORMS.Facebook]: {
        [SITE_TYPE.Page]: {
          message: "organization.deletefbpageconfirm.message",
          yesBtn: "organization.confirmDeleteFbPage",
        },
        [SITE_TYPE.Group]: {
          message: "organization.deletefbgroupconfirm.message",
          yesBtn: "organization.confirmDeleteFbGroup",
        },
      },
      [SOCIAL_PLATFORMS.Instagram]: {
        [SITE_TYPE.Page]: {
          message: "organization.deletefbpageconfirm.message",
          yesBtn: "organization.confirmDeleteFbPage",
        },
      },
      [SOCIAL_PLATFORMS.LinkedIn]: {
        [SITE_TYPE.Page]: {
          message: "organization.deletefbpageconfirm.message",
          yesBtn: "organization.confirmDeleteFbPage",
        },
        [SITE_TYPE.Profile]: {
          message: "organization.deletefbpageconfirm.message",
          yesBtn: "organization.confirmDeleteFbPage",
        },
      },
      [SOCIAL_PLATFORMS.Twitter]: {
        [SITE_TYPE.Profile]: {
          message: "organization.deletefbpageconfirm.message",
          yesBtn: "organization.confirmDeleteFbPage",
        },
      },
      [SOCIAL_PLATFORMS.Gmb]: {
        [SITE_TYPE.Profile]: {
          message: "organization.deletefbpageconfirm.message",
          yesBtn: "organization.confirmDeleteFbPage",
        },
      },
    };
    const successMessageKeys = {
      [SOCIAL_PLATFORMS.Facebook]: {
        [SITE_TYPE.Page]:
          "organization.success.deletefbpageconfirm.message",
        [SITE_TYPE.Group]:
          "organization.success.deletefbgroupconfirm.message",
      },
      [SOCIAL_PLATFORMS.LinkedIn]: {
        [SITE_TYPE.Page]:
          "organization.success.deletefbpageconfirm.message",
        [SITE_TYPE.Profile]:
          "organization.success.deletefbpageconfirm.message",
      },
      [SOCIAL_PLATFORMS.Twitter]: {
        [SITE_TYPE.Profile]:
          "organization.success.deletefbpageconfirm.message",
      },
      [SOCIAL_PLATFORMS.Instagram]: {
        [SITE_TYPE.Page]:
          "organization.success.deletefbpageconfirm.message",
      },
      [SOCIAL_PLATFORMS.Gmb]: {
        [SITE_TYPE.Profile]:
          "organization.success.deletefbpageconfirm.message",
      },
    };
    const failedMessageKeys = {
      [SOCIAL_PLATFORMS.Facebook]: {
        [SITE_TYPE.Page]:
          "organization.fail.deletefbpageconfirm.message",
        [SITE_TYPE.Group]:
          "organization.fail.deletefbgroupconfirm.message",
      },
      [SOCIAL_PLATFORMS.LinkedIn]: {
        [SITE_TYPE.Page]:
          "organization.fail.deletefbpageconfirm.message",
        [SITE_TYPE.Profile]:
          "organization.fail.deletefbpageconfirm.message",
      },
      [SOCIAL_PLATFORMS.Twitter]: {
        [SITE_TYPE.Profile]:
          "organization.fail.deletefbpageconfirm.message",
      },
      [SOCIAL_PLATFORMS.Instagram]: {
        [SITE_TYPE.Page]:
          "organization.fail.deletefbpageconfirm.message",
      },
      [SOCIAL_PLATFORMS.Gmb]: {
        [SITE_TYPE.Profile]:
          "organization.fail.deletefbpageconfirm.message",
      },
    };
    const successCallbacks = {
      [SOCIAL_PLATFORMS.Facebook]: () => {
        if (type === SITE_TYPE.Page) {
          const index = this.socialSitesPage.findIndex((value) => {
            return (
              parseInt(value.siteID + "") ===
              parseInt(site.siteID + "")
            );
          });

          this.socialSitesPage.splice(index, 1);
          this.getGroupByOrganization();
        }

        if (type === SITE_TYPE.Group) {
          const index = this.socialSitesGroup.findIndex((value) => {
            return (
              parseInt(value.siteID + "") ===
              parseInt(site.siteID + "")
            );
          });

          this.socialSitesGroup.splice(index, 1);
        }
      },
      [SOCIAL_PLATFORMS.LinkedIn]: () => {
        if (type === SITE_TYPE.Page) {
          const index = this.linkedInPages.findIndex((value) => {
            return (
              parseInt(value.siteID + "") ===
              parseInt(site.siteID + "")
            );
          });

          this.linkedInPages.splice(index, 1);
        }

        if (type === SITE_TYPE.Profile) {
          const index = this.linkedInProfiles.findIndex((value) => {
            return (
              parseInt(value.siteID + "") ===
              parseInt(site.siteID + "")
            );
          });

          this.linkedInProfiles.splice(index, 1);
        }
        this.getGroupByOrganization();
      },
      [SOCIAL_PLATFORMS.Twitter]: () => {
        const index = this.twitterProfiles.findIndex((value) => {
          return (
            parseInt(value.siteID + "") ===
            parseInt(site.siteID + "")
          );
        });

        this.twitterProfiles.splice(index, 1);
        this.getGroupByOrganization();
      },
      [SOCIAL_PLATFORMS.Instagram]: () => {
        if (type === SITE_TYPE.Page) {
          const index = this.instagramPages.findIndex((value) => {
            return (
              parseInt(value.siteID + "") ===
              parseInt(site.siteID + "")
            );
          });

          this.instagramPages.splice(index, 1);
        }
        this.getGroupByOrganization();
      },
      [SOCIAL_PLATFORMS.Gmb]: () => {
        const index = this.gmbPages.findIndex((value) => {
          return (
            parseInt(value.siteID + "") ===
            parseInt(site.siteID + "")
          );
        });

        this.gmbPages.splice(index, 1);
        this.getGroupByOrganization();
      },
    };

    this.componentHelpers.startApiAction(
      () => {

        const sitedata = this.socialManagerComponent.deletePage(site.siteID);

        return sitedata;
      },
      {

        confirmMessageKey:
        confirmMessageKeys[socialPlatform]?.[type]?.message ?? "organization.deletefbpageconfirm.message",
      confirmYesButtonTextKey:
          confirmMessageKeys[socialPlatform]?.[type]?.yesBtn ?? "organization.confirmDeleteFbPage",
        successMessageKey: successMessageKeys[socialPlatform][type] ?? "organization.success.deletefbpageconfirm.message",
        failedMessageKey: failedMessageKeys[socialPlatform][type],
        afterSuccessAction: successCallbacks[socialPlatform],
      },

      true
    );

  }
  /**
      * Open Dialog
      */
  createOrgModal() {
    const createModal = this.dialog.open(selectOrgModalComponent, {
      width: "700px",
      data: {
        title: "Create New Organization",
      },
    });

    createModal.componentInstance.submitEvent.subscribe(() => {

      this.updateOrganization();
    });
  }
  /**
       * Refresh DOM when org create/edit/deleted
       */
  @Debounce()
  updateOrganization() {
    this.preLoader = this.openModal.loader(DialogLoaderComponent);
    // return this.getOrganizations().then((response) => {
    //   this.updateShowedOrganization();
    //   
    //   return response;
    // });
    this.getGroupByOrganization();
    this.preLoader.close();
  }
  updateShowedOrganization() {
    const newShowedOrganizations = [
      {
        children: {
          ...this.organizationTree,
        },
      },
    ];
    let keys = `[${Object.keys(this.organizationTree)[0]}]`;

    this.showedOrganizations.forEach((organization, index) => {
      let value;

      if (index > 0) {
        value = this.organizationTree[organization.organizationID];
      }

      if (index > 1) {
        keys += `.children[${organization.organizationID}]`;
        value = Utils.lodash.get(this.organizationTree, keys, null);
      }

      if (!!value) {
        newShowedOrganizations[index] = value;
      }
    });

    this.showedOrganizations = newShowedOrganizations;
  }
  getOrganizations() {
    return this.service
      .getOrganization()
      .then((data) => {
        this.organizationTree = data["organizationTree"];
        //this.sortOrganizationTree();
        this.organizationCounterAll = data["organizationCount"] || 0;

        if (!this.showedOrganizations.length) {
          this.showedOrganizations.push({
            children: {
              ...this.organizationTree,
            },
          });
        }

        this.loadUIByRouteParams();

        this.initializedOrganizations = true;

        this.preLoader.close();

        return data;
      })
      .catch((error) => {
        NotifyService.warning(
          FormValidationService.readError(error).message,
          ""
        );
        this.preLoader.close();

        return error;
      });
  }

  /**
   * Load organization edit panel UI by route params
   */
  loadUIByRouteParams(): void {
    const ORGANIZATION_MODIFY_TAB_EDIT = "edit";
    const ORGANIZATION_MODIFY_TAB_SOCIAL_NETWORKS = "social-networks";
    const ORGANIZATION_MODIFY_TAB_USERS = "users";

    if (this.initialOrganizationID) {
      const initialOrganization = this.findOrganizationByID(
        this.initialOrganizationID,
        this.organizationTree
      );
      const initialTabName = this.initialTabName
        ? this.initialTabName
        : ORGANIZATION_MODIFY_TAB_EDIT;
      const initialSocialType = this.initialSocialType
        ? this.initialSocialType
        : SOCIAL_MEDIA_TYPE_FACEBOOK;
      const organizationTabsMap = {
        [ORGANIZATION_MODIFY_TAB_EDIT]: 0,
        [ORGANIZATION_MODIFY_TAB_SOCIAL_NETWORKS]: 1,
        [ORGANIZATION_MODIFY_TAB_USERS]: 2,
      };
      const socialTypesMap = {
        [SOCIAL_MEDIA_TYPE_FACEBOOK]: 0,
        [SOCIAL_MEDIA_TYPE_LINKEDIN]: 1,
        [SOCIAL_MEDIA_TYPE_TWITTER]: 2,
        [SOCIAL_MEDIA_TYPE_INSTAGRAM]: 3,
        [SOCIAL_MEDIA_TYPE_GMB]: 4,
      };
      const initialTabIndex = organizationTabsMap[initialTabName];
      const initialSocialTypeIndex = socialTypesMap[initialSocialType];

      if (initialOrganization) {
        // go to organization edit panel
        // this.editOrg(initialOrganization, this.initialOrganizationID);

        if (initialTabIndex >= 0) {
          setTimeout(() => {
            // go to specify tab
            this.el.nativeElement
              .querySelectorAll(".organization-tab-nav-item")
            [initialTabIndex].querySelector("a")
              .click();

            // go to specify social network tab
            if (
              initialSocialTypeIndex >= 0 &&
              initialTabName ===
              ORGANIZATION_MODIFY_TAB_SOCIAL_NETWORKS
            ) {
              setTimeout(() => {
                this.el.nativeElement
                  .querySelectorAll(".nav-item--social-type")
                [initialSocialTypeIndex].querySelector("a")
                  .click();
              }, 0);
            }
          }, 0);
        }
      }

      this.initialOrganizationID = null;
      this.initialTabName = null;
      this.initialSocialType = null;
    }
  }
  /**
    * Find organization by organizationID in tree
    * @param {string} organizationID
    * @param {{[p: string]: any}} organizations
    * @return {any}
    */
  findOrganizationByID(
    organizationID: string,
    organizations: { [key: string]: any }
  ): any {
    let _organization = null;

    if (organizations && organizations instanceof Object) {
      if (organizations[organizationID]) {
        _organization = organizations[organizationID];
      } else {
        for (const _organizationID in organizations) {
          const organization = organizations[_organizationID];

          if (
            organization &&
            organization.children &&
            !_organization
          ) {
            _organization = this.findOrganizationByID(
              organizationID,
              organization.children
            );
          }
        }
      }
    }

    return _organization;
  }

  createSocialSitePlatform(orgID: any) {

    this.organization.organizationID = orgID;
    return new Promise<MatDialogRef<ModalType>>((resolve, reject) => {
      this.socialMediaPlatformChooser(SocialMediaPlatformChooserComponent).afterClosed().subscribe(platform => {

        if (platform && platform.additionalInfo) {
          if (platform.platform.name == 'LinkedIn' && platform.additionalInfo == 'profile') {
            this.addLinkedInProfile();
          } else if (platform.platform.name == 'Twitter' && platform.additionalInfo == 'profile') {
            this.addTwitterSite();
          }

        } else {
          if (platform.id == 'facebook') {
            this.facebookLoginPage();
          } else if (platform.id == 'twitter') {
            this.addTwitterSite();
          } else if (platform.id == 'instagram') {
            this.instagramLoginPage();
          } else if (platform.id == 'linkedIn') {
            this.addLinkedIn();
          } else if (platform.id == 'gmb') {
            this.addGmbSite();
          } else if (platform.id == 'LinkedInprofile') {
            this.addLinkedInProfile();
          }

        }
        resolve(platform);
      });
    });
  }
  private socialMediaPlatformChooser(
    modalComponent: ModalType,
    config: MatDialogConfig = {}
  ) {

    return this.dialog.open(modalComponent, {
      autoFocus: false,
      //width: "333px",
      ...config,
      data: {

        title: "Organization",
        socialsite: true
      },
    });
  }
  /**
     * @description most éppen működik egy csúnya assets-ből node module használatával, de... https://github.com/NarHakobyan/ngx-linkedIn
     * ezzel talán szebb és működik is.
     */
  addLinkedIn() {
    this.loginWithLinkedIn(true, () => {
      this.openLinkedInPageSelectionPopup();
    });
  }
  private openLinkedInPageSelectionPopup() {
    this.dialog
      .open(FacebookDialogComponent, {
        data: {
          organizationID: this.organization.organizationID,
          page: "linkedInPage",
          addAnotherPageMethod: () => {
            this.addLinkedIn();
          },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.socialNetworkGet();
        }
      });
  }
  addLinkedInProfile() {
    this.loginWithLinkedIn(true, () => {
      this.openLinkedInProfileModal();
    });
  }

  private openLinkedInProfileModal() {
    this.dialog
      .open(FacebookDialogComponent, {
        data: {
          organizationID: this.organization.organizationID,
          page: "linkedInProfile",
          addAnotherPageMethod: () => {
            this.addLinkedInProfile();
          },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.socialNetworkGet();
        }
      });
  }
  /**
    * Manage E-mail Notifications
    */
  public openEmailNotificationManager(event: MouseEvent, organizationNamevalue:any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (organizationNamevalue) {
      this.user['organizationNamevalue'] =organizationNamevalue;
    }

    this.openModal.manageEmailNotification(EmailNotificationManagerComponent, this.user);
  }
  @HostListener(`document:${CustomEvents.USER_UPDATE}`, ['$event'])
  setUser(): void {
    this.user = LoggedUser.getUser();
  }

}
