import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '~/src/app/modules/auth/auth.service';
import {Token} from '~/src/app/services/token';
import {Helpers, RememberMe, TwoFactorLogin} from '../../services/helpers';
import {HttpClient} from '@angular/common/http';
import {environment} from '~/src/environments/environment';
import {LanguageService} from '~/src/app/services/language.service';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {IPartnerInfo} from '~/src/app/modules/administration/partners/partner.interface';
import {TOKEN_KEY} from '~/src/app/configs/configs';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService,
        private token: Token,
        public http: HttpClient,
        private language: LanguageService,
        private partnersService: PartnersService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const getLanguage = () => {
            const reqs = [
                this.language.setLanguages(),
                this.auth.refreshLoggedUserData()
            ];

            if (localStorage.getItem(TOKEN_KEY)) {
                reqs.push(
                    (this.partnersService.getPartnerInfo() as Promise<IPartnerInfo>)
                        .then(() => Promise.resolve(true))
                        .catch(() => Promise.resolve(true)) as any);
            }

            return Promise.all(reqs as any);
        };

        const goLogin = () => {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login']);
            return getLanguage().then(() => {
                return false;
            });
        };

        if (TwoFactorLogin.has()) {
            return goLogin();
        }

        if (!this.token.isDeadPoint()) {
            // logged in so return true
            return getLanguage().then(() => {
                return true;
            });
        }

        if (RememberMe.has()) {
            const updateToken = () => {
                return this.http.get(
                    environment.apiUrl + '/api/users/update-token',
                    Helpers.getBaseHttpHeaders(Token.getToken())
                ).toPromise();
            };

            return Promise.all([getLanguage(), updateToken()]).then(responses => {
                const [languageRes, tokenRes] = responses;
                this.token.saveToken(tokenRes['data'].token, tokenRes['data'].expires);
                this.token.startTimer();

                return true;
            }).catch(() => {
                this.auth.logout();
                this.router.navigate(['/login']);

                return false;
            });
        }

        this.auth.logout();
        return goLogin();
    }
}
