import {debounceTime} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {HashtagService} from '~/src/app/modules/hashtag/hashtag.service';
import {LanguageService} from '~/src/app/services/language.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {FormHelpersService} from '~/src/app/core/services/form-helpers';
import {DataTableHelpersService} from '~/src/app/core/services/data-table-helpers.service';
import {HashtagModel} from '~/src/app/modules/hashtag/hashtag.model';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {Helpers} from '~/src/app/services/helpers';
import {CollectionListComponent} from '~/src/app/core/components/collection-list/collection-list.component';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {
    CollectionListSelectionChange,
    TableBodyConfig,
    TableHeadConfig
} from '~/src/app/core/components/collection-list/collection-list.interfaces';
import {HashtagModalFormComponent} from '~/src/app/modules/hashtag/components/hashtag-modal-form/hashtag-modal-form.component';
import { KeyValue } from "@angular/common";

@Component({
    selector: 'smd-hashtag-manager',
    templateUrl: './hashtag-manager.component.html',
    styleUrls: ['./hashtag-manager.component.scss'],
    providers: [
        ComponentHelpers,
        FormHelpersService,
        DataTableHelpersService
    ]
})
export class HashtagManagerComponent implements OnInit {
    @ViewChild(CollectionListComponent, {static: true}) collectionList: CollectionListComponent;

    // paginator controller
    paginatorController: PaginationController = new PaginationController();

    readonly defaultOrder = 'tag-asc';

    // filter control names
    filterNames = {
        Keyword: 'tag',
        OrderBy: 'sort',
        Organization: 'organizationID'
    };

    filtersFormGroup: FormGroup = new FormGroup({
        [this.filterNames.Keyword]: new FormControl(null),
        [this.filterNames.OrderBy]: new FormControl(this.defaultOrder),
        [this.filterNames.Organization]: new FormControl(null)
    });

    hashtagItems: HashtagModel[] = [];

    // Column data for select
    columnVisibility: any = {
        name: {
            name: "category.name",
            visible: true,
        },
        createdBy: {
            name: "hashtag.created.by",
            visible: true,
        },
        organizations: {
            name: "hashtag.organizations",
            visible: true,
        },
        createDate: {
            name: "core.crud.createDate",
            visible: true,
        },
    };
    defaultColumns: any[] = Object.keys(this.columnVisibility);
    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
    }

    // table head
    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: 'category.name',
            enable: () => this.columnVisibility["name"].visible,
        },
        {
            nameKey: 'hashtag.created.by',
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            nameKey: 'hashtag.organizations',
            enable: () => this.columnVisibility["organizations"].visible,
        },
        {
            nameKey: 'core.crud.createDate',
            enable: () => this.columnVisibility["createDate"].visible,
        },
        {
            nameKey: 'category.actions',
            onlyDesktop: true,
            alignment: 'right'
        }
    ];

    // table body
    tableBodyConfig: TableBodyConfig<HashtagModel>[] = [
        {
            bindValue: 'tag',
            enable: () => this.columnVisibility["name"].visible,
        },
        {
            selector: 'createdBy',
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            selector: 'organizations',
            enable: () => this.columnVisibility["organizations"].visible,
        },
        {
            bindValue: 'createDate',
            enable: () => this.columnVisibility["createDate"].visible,
        },
        {
            staticView: 'itemActions',
            onlyDesktop: true,
            alignment: 'flex-end'
        },
    ];

    constructor(
        public hashtagService: HashtagService,
        public languageService: LanguageService,
        public componentHelpers: ComponentHelpers,
        public formHelpers: FormHelpersService,
        public dataTableHelperService: DataTableHelpersService,
        private openModal: OpenModalService
    ) {
        this._getHashtagItems();

        this.formHelpers.formInit(this.filtersFormGroup);

        // on filter change value
        this.filtersFormGroup.valueChanges.pipe(
            debounceTime(350))
            .subscribe(() => {
                this.paginatorController.paginationOptions.pageIndex = 0;
                this._getHashtagItems();
            });

        // on pagination
        this.paginatorController.onPaginationChange.subscribe(() => this._getHashtagItems());
    }

    ngOnInit() {
    }

    /**
     * @param {MouseEvent} event
     * @param {HashtagModel} item
     */
    openHashtagManager(event: MouseEvent, item?: HashtagModel) {
        event.preventDefault();
        event.stopPropagation();

        this.openModal.hashtagModalForm(HashtagModalFormComponent, item, {
            data: {
                successAction: (response: any) => {
                    this._getHashtagItems();
                }
            }
        });
    }

    /**
     * @param {MouseEvent} event
     * @param {HashtagModel} hashtag
     */
    deleteHashtag(event: MouseEvent, hashtag: HashtagModel): void {
        event.preventDefault();
        event.stopPropagation();

        this.componentHelpers.startApiAction(
            () => {
                return this.hashtagService.remove(hashtag.tagID);
            },
            {
                successMessageKey: 'post.hashtag.success.modal.delete',
                failedMessageKey: 'post.hashtag.error.modal.failedToDelete',
                confirmMessageKey: 'post.hashtag.confirm.modal.delete',
                confirmYesButtonTextKey: 'modal.confirmDelete.button.delete',
                afterSuccessAction: () => {
                    this._getHashtagItems(this.hashtagItems.length === 1);
                }
            },
            true
        );
    }

    /**
     * @param {MouseEvent} event
     */
    deleteHashtagItems(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const selectedItems = this.dataTableHelperService.getSelectedItems<HashtagModel>();

        if (selectedItems.length) {
            this.componentHelpers.startApiAction(
                () => {
                    return this.hashtagService.remove(selectedItems.map(item => item.tagID));
                },
                {
                    successMessageKey: 'post.hashtag.success.modal.groupDelete',
                    failedMessageKey: 'post.hashtag.error.modal.failedToGroupDelete',
                    confirmMessageKey: 'post.hashtag.confirm.modal.groupDelete',
                    confirmYesButtonTextKey: 'modal.confirmDelete.button.delete',
                    afterSuccessAction: () => {
                        this._getHashtagItems(selectedItems.length === this.hashtagItems.length);
                    }
                },
                true
            );
        }
    }

    /**
     * @param {MouseEvent} event
     */
    resetFilters(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.formHelpers.resetFormGroup(this.filtersFormGroup);
    }

    /**
     * @param {CollectionListSelectionChange<HashtagModel>} event
     */
    selectionChange(event: CollectionListSelectionChange<HashtagModel>): void {
        this.dataTableHelperService.selectedItems = event.selectedItems;
    }

    _getHashtagItems(goToPreviousPage = false) {

        if (goToPreviousPage && this.paginatorController.paginationOptions.pageIndex !== 0) {
            this.paginatorController.paginationOptions.pageIndex -= 1;
        }

        let filters = {
            ...this.paginatorController.requestParams,
            [this.filterNames.OrderBy]: Helpers.getOrderFormat(this.defaultOrder),
            ...this.filtersFormGroup.getRawValue()
        };

        const loader = this.openModal.loader(DialogLoaderComponent);
        this.hashtagService.getList(filters).then(response => {
            this.paginatorController.paginationOptions.length = response.count;

            if (!!this.collectionList) {
                this.collectionList.clearSelection();
            }

            this.hashtagItems = response.tags;
            loader.close();
        }).catch((error) => {

            loader.afterClosed().subscribe(() => {
                this.componentHelpers.failedApiAction(error, {
                    messageKey: 'core.getItems.error'
                });
            });

            loader.close();
        });
    }

    noItemsSelected(): boolean {
        return this.dataTableHelperService.getSelectedItems().length === 0;
    }

    /**
     * Toggle table columns
     * @param event
     * */
    toggleColumns(event): void {
        Object.keys(this.columnVisibility).forEach((column) => {
            this.columnVisibility[column].visible =
                event.value.includes(column);
        });
    }
}
