import {ModelAbstract} from '../../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class SocialManagerService extends ModelAbstract {

    constructor(http: HttpClient, dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/social-sites';
    }
}
