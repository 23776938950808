import { ResourceAbstract } from "~/src/app/core/resource.abstract";
import { BehaviorSubject, Observable } from "~/node_modules/rxjs";
import {
    Media,
    MediaFilters,
    MediaRaw,
    MediaResponse,
    MediaTag,
    MediaTagResponse,
} from "~/src/app/modules/media/media-resource";
import { BackendService } from "~/src/app/core/backend.service";
import { Helpers } from "~/src/app/services/helpers";
import { Core } from "~/src/app/core/core";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { Icons } from "~/src/app/helpers/icons";
import { MAX_MEDIA_NAME_CHARACTER_NUMBER } from "~/src/app/modules/media/media-resource.config";
import Utils from "~/src/app/core/utils";
import { Injectable } from "~/node_modules/@angular/core";
import { filterStackTrace } from "~/node_modules/protractor/built/util";

@Injectable({
    providedIn: "root",
})
export class MediaResourceService extends ResourceAbstract {
    // Main api URL
    protected mainApiUrl = "/media";

    // Sub api URLs
    protected mediaUploadUrl = this.getFullApiUrl("/file-upload");
    protected mediaUpdateUrl = this.getFullApiUrl("/modify");
    protected mediaDeleteUrl = this.getFullApiUrl("/file-delete");
    protected mediaStatusChangeUrl = this.getFullApiUrl("/status");
    protected mediaTagsUrl = this.getFullApiUrl("/tags");
    protected mediaVisibilityChangeUrl =
        this.getFullApiUrl("/media-visibility");

    // Collections
    private mediaCollection: BehaviorSubject<Media[]> = new BehaviorSubject<
        Media[]
    >([]);
    private mediaTagCollection: BehaviorSubject<MediaTag[]> =
        new BehaviorSubject<MediaTag[]>([]);

    constructor(private backendService: BackendService) {
        super();
    }

    /**
     * Hydrate medias
     * @param {MediaRaw[]} medias
     * @param {boolean} isNewMedia
     * @returns {Media[]}
     */
    static mediaHydrator(medias: MediaRaw[], isNewMedia = false): Media[] {
        return medias.map((item) => {
            const media: Media = item as Media;

            media.fileType = item.mime || item.type;
            media.type = Helpers.mimeTypeToObject(media.fileType).type;

            media.previewFileUrl = !!item.previewFileUrl
                ? Core.addDomainAndTokenToUrl(item.previewFileUrl)
                : "";

            if (media.type === FILE_TYPES.OG) {
                // media is OG image
                media.url = item.url || "";
                media.thumb = item.url;
                media.bigThumb = item.url;
            } else {
                // media is not OG image
                if (item.mediaType !== FILE_TYPES.CAROUSEL) {
                    // media is not CAROUSEL
                    media.url = Core.addDomainAndTokenToUrl(item.url);
                } else {
                    // media is CAROUSEL
                    media.url = item.url;
                }

                media.thumb = !!item.url ? item.url + "&thumb=250x250" : "";
                media.bigThumb = !!item.url ? item.url + "&thumb=365x365" : "";
            }

            media.galleryThumb = media.thumb;

            media.resolution = item.resolution || "---";
            media.ratio = item.ratio || "---";
            media.orientation = item.orientation || "---";

            media.icon = Icons.getIcon(media.type);
            media.shortName = this.getMediaShortName(media.name);

            media.tags = media.tags || [];
            media.categories = media.categories || [];
            media.socialChannels = media.socialChannels || [];
            media.partnerIDs = (media.partnerIDs || []).map((id) =>
                parseInt(id + "")
            );
            media.disallowedPartnerIDs = (media.disallowedPartnerIDs || []).map(
                (id) => parseInt(id + "")
            );

            media.selected = false;
            media.active = true;
            media.isNew = isNewMedia;
            media.isEditable = Utils.lodash.get(media, "isEditable", true);
            media.isDeleteable = Utils.lodash.get(media, "isDeleteable", true);
            media.canvaId = Utils.lodash.get(media, "canvaId", null);

            media.systemType = media.systemType || "custom";
            media.systemTypeMessage = media.systemTypeMessage || "";
            media.status = media.status || "";
            media.statusMessage = media.statusMessage || "";

            return media;
        }) as Media[];
    }

    /**
     * Get short media name
     * @param {string} name
     * @returns {string}
     */
    private static getMediaShortName(name: string): string {
        if (name.length > MAX_MEDIA_NAME_CHARACTER_NUMBER) {
            return name.slice(0, MAX_MEDIA_NAME_CHARACTER_NUMBER) + "...";
        }

        return name;
    }

    /**
     * Get medias
     * @returns {Observable<Media[]>}
     */
    get medias(): Observable<Media[]> {
        return this.mediaCollection.asObservable();
    }

    /**
     * Get media tags
     * @returns {Observable<MediaTag[]>}
     */
    get mediaTags(): Observable<MediaTag[]> {
        return this.mediaTagCollection.asObservable();
    }

    /**
     * Get medias from backend
     * @param {MediaFilters} filters
     * @returns {Promise<MediaResponse>}
     */
    async getMedias(filters?: MediaFilters) {
        if (!!filters) {
            /**
             * @todo temporary solution
             * @see MediaFilters
             */
            filters = {
                ...(filters || null),
                tags: JSON.stringify(filters.tags) || null,
                categories: JSON.stringify(filters.categories) || null,
                organizationID: JSON.stringify(filters.organizationID) || null,
                systemType: JSON.stringify(filters.systemType) || null,
            };
        }

        const response = await this.backendService.get<MediaResponse>(
            this.mainApiUrl,
            filters
        );

        const hydratedMedias = MediaResourceService.mediaHydrator(
            response.medias
        );

        this.mediaCollection.next(hydratedMedias);

        response.medias = hydratedMedias;

        return response;
    }

    /**
     * Get media tags from backend
     *
     * @param {{}} filters
     * @param {boolean} orderRequired
     * @returns {Promise<MediaTagResponse>}
     */
    async getMediaTags(filters = {}, orderRequired = true) {
        // Get media tags from backend
        const response = await this.backendService.get<MediaTagResponse>(
            this.mediaTagsUrl,
            filters
        );

        // Hydrate media tags
        let mediaTags = response.tags.map((tag) => {
            tag.tagID = parseInt(tag.tagID as string);

            return tag;
        });

        // Order media tags when required
        if (!!orderRequired) {
            mediaTags = Utils.lodash.orderBy(mediaTags, [
                (tag: MediaTag) => tag.name.toLowerCase(),
            ]);
        }

        // Update media tag collection
        this.mediaTagCollection.next(mediaTags);

        response.tags = mediaTags;

        return response;
    }
}
