import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguagesModule} from '~/src/app/modules/languages/languages.module';
import {NgxMatDrpModule} from 'ngx-mat-daterange-picker';
import {InnerHtmlModule} from '~/src/app/directives/inner-html/inner-html.module';
import {SharedModule} from '~/src/app/shared/shared.module';
import {ChartsModule} from 'ng2-charts';
import {WidgetComponent} from '~/src/app/modules/analytics/widget/widget.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxMatDrpModule,
        InnerHtmlModule,
        LanguagesModule,
        ChartsModule
    ],
    declarations: [
        WidgetComponent,
    ],
    exports: [
        WidgetComponent,
    ]
})
export class WidgetModule {
}
