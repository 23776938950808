import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import {
    NgxDrpOptions,
    NgxMatDrpComponent,
    Range,
} from "~/node_modules/ngx-mat-daterange-picker";
import { Subject } from "~/node_modules/rxjs";
import {
    MetricDataFilters,
    MetricDataService,
} from "~/src/app/modules/analytics/widget/metric-data.service";
import { MetricModel } from "~/src/app/modules/analytics/widget/metric.model";
import { LanguageService } from "~/src/app/services/language.service";
import Utils from "~/src/app/core/utils";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import {
    ChartConfiguration,
    ChartDataSets,
} from "~/node_modules/@types/chart.js";
import { Debounce, Helpers } from "~/src/app/services/helpers";
import {
    WidgetEditEvent,
    WidgetMetricGetterParams,
    WidgetType,
} from "~/src/app/modules/analytics/widget/widget.interfaces";
import { DialogEditWidgetComponent } from "~/src/app/modules/analytics/edit-widget/dialog-edit-widget.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { SocialChannels } from "~/src/app/modules/analytics/widget/metric-configs/metric.config";
import {
    WIDGET_TYPE_CHART,
    WIDGET_TYPE_TEXT,
} from "~/src/app/modules/analytics/widget/widget.configs";
import { ColorSupport } from "~/src/app/core/color-support";

@Component({
    selector: "smd-widget",
    templateUrl: "./widget.component.html",
    styleUrls: ["./widget.component.scss"],
    providers: [MetricDataService],
})
export class WidgetComponent implements OnInit, AfterViewInit, OnChanges {
    // inputs
    @Input() socialSites: SocialSiteInterface[] = [];
    @Input() organizations: number[] = [];
    @Input() availableOrganizations: number[] = [];
    @Input() socialChannel: SocialChannels = null;
    @Input() dateRange: Range;
    @Input() dateRangeOptions: NgxDrpOptions;
    @Input() metricID: string;
    @Input() metricModifiers: string[] = [];

    @Input() enableActions = true;
    @Input() editable = true;
    @Input() deleteable = true;

    @Input() getDatas$: Subject<WidgetMetricGetterParams>;
    @Input() title = "";
    @Input() refreshable = true;
    @Input() posts: any[]; // Used by 'list' type
    @Input() summaryData: any[]; // Used by 'table' types.
    @Input() tableData: {[key: string]: any[]}; // Used by 'table' types.
    @Input() widgetType: WidgetType;
    @Input() widgetID: number;
    @Input() content: string;
    @Input() instantFetch = false;

    @Output() clickOnEntity: EventEmitter<{ entity: any }> = new EventEmitter<{
        entity: any;
    }>();
    @Output() editWidget: EventEmitter<WidgetEditEvent> =
        new EventEmitter<WidgetEditEvent>();
    @Output() deleteWidget: EventEmitter<any> = new EventEmitter<any>();
    @Output() widgetLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() widgetInitialized: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("dateRangeInput") dateRangeInput: NgxMatDrpComponent;

    // boolean variables
    loaderVisible = false;

    // properties
    errorMessage: string;
    infoMessage: string;
    warnMessage: string;
    metricModels: MetricModel[];
    chartConfig: ChartConfiguration;
    htmlContents: string[] = [];
    firstRequestByDateRange = true;

    chartLegendLabels = [];
    datasetsBackup = [];

    chartColorsBackup = [
        "#3366cc",
        "#dc3912",
        "#ff9900",
        "#109618",
        "#990099",
        "#3b3eac",
        "#0099c6",
        "#dd4477",
        "#66aa00",
        "#b82e2e",
        "#316395",
        "#994499",
        "#22aa99",
        "#aaaa11",
        "#6633cc",
        "#e67300",
        "#8b0707",
        "#329262",
        "#5574a6",
        "#3b3eac",
    ];
    chartColors = [...this.chartColorsBackup];

    math = Math; // for the template
    
    // variables for betterrendering
    isHtmlContentV: boolean = false;
    isTextWidgetV: boolean = true;
    isChartContentV: boolean = false;
    hasDatasV: boolean = false;
    isListContentV: boolean = false;
    isSummaryContentV: boolean = false;
    isTableContentV: boolean = false;
    htmlContentsV: string = "";

    constructor(
        public language: LanguageService,
        public dialog: MatDialog,
        private metricDataService: MetricDataService,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        if (!!this.content) {
            this.htmlContents = [this.content];
            this.refreshRenderData();
            this.widgetLoaded.emit();
        }

        this.initGetDatasListener();

        if (this.dateRangeOptions) {
            if (this.dateRange) {
                this.dateRangeOptions.range = this.dateRange;
            }

            if (this.instantFetch) {
                this.getMetricDatas({
                    dateRange: this.dateRangeOptions.range,
                });
            }
        }

        this.widgetInitialized.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.widgetType) {
            this.refreshRenderData();
        }
    }

    ngAfterViewInit(): void {
        if (this.dateRangeInput) {
            this.dateRangeInput.rangeStoreService.rangeUpdate$.subscribe(
                (range: Range) => {
                    this.getMetricDatas({
                        dateRange: range,
                    });

                    this.editWidget.emit({
                        id: this.widgetID + "",
                        socialSiteID: Utils.get(
                            this.socialSites,
                            "[0].siteID",
                            null
                        ),
                        socialChannel: this.socialChannel || Utils.get(
                            this.metricModels,
                            "[0].socialSite.socialType",
                            null
                        ),
                        organizations: this.organizations,
                        metricModifiers: this.metricModifiers,
                        content: this.getHtmlContents(),
                        name: this.title,
                        metrics: this.metricID,
                        from: range.fromDate,
                        to: range.toDate,
                    });
                }
            );
        }
    }

    /**
     * Get chart config of widget
     * @returns {Chart.ChartConfiguration}
     */
    getChartConfig(): ChartConfiguration {
        return this.chartConfig;
    }

    /**
     * Check widget is text type
     * @returns {boolean}
     */
    isTextWidget(): boolean {
        return this.widgetType === WIDGET_TYPE_TEXT;
    }

    /**
     * Content is a list
     * @returns {boolean}
     */
    isListContent(): boolean {
        if (!!this.posts) {
            return true;
        }

        if (!this.hasMetricModel()) {
            return false;
        }

        return (
            this.metricModels?.[0].metricConfig.contentType === "list" ||
            !!this.posts
        );
    }

    getIconForGmbType(type) {
        switch (type) {
            case "event":
                return "event";
            case "offer":
                return "local_offer";
            default:
                return "new_releases";
        }
    }

    /**
     * Content is summary
     * @returns {boolean}
     */
    isSummaryContent(): boolean {
        return this.metricModels?.[0].metricConfig.contentType === "summary";
    }

    /**
     * Content is table
     * @returns {boolean}
     */
    isTableContent(): boolean {
        return this.metricModels?.[0].metricConfig.contentType === "table";
    }

    /**
     * Content is HTML
     * @returns {boolean}
     */
    isHtmlContent(): boolean {
        return !!this.htmlContents.length;
    }

    /**
     * Content is a chart
     * @returns {boolean}
     */
    isChartContent(): boolean {
        if (!this.hasMetricModel()) {
            return false;
        }

        return (
            this.metricModels[0].metricConfig.contentType === "chart" &&
            !!this.chartConfig
        );
    }

    /**
     * Has min. one metric model
     * @returns {boolean}
     */
    hasMetricModel(): boolean {
        return !!this.metricModels && !!this.metricModels.length;
    }

    /**
     * Check has metric datas
     * @returns {boolean}
     */
    hasDatas(): boolean {
        return (
            !!this.metricModels &&
            !!this.metricModels.length &&
            (!!this.chartConfig ||
                !!this.posts ||
                !!this.htmlContents.length ||
                !!this.summaryData ||
                !!this.tableData)
        );
    }

    /**
     * Get html contents
     * @returns {string}
     */
    getHtmlContents() {
        let content = "";

        for (const html of this.htmlContents) {
            content += html;
        }

        return content;
    }

    openEditWidgetDialog(event: MouseEvent): void {
        this.dialog
            .open(DialogEditWidgetComponent, {
                maxHeight: "80vh",
                minWidth: "300px",
                width: "33vw",
                data: {
                    widget: {
                        type: this.widgetType || WIDGET_TYPE_CHART,
                        name: this.title,
                        content: this.getHtmlContents(),
                        metrics: this.metricID,
                        socialSiteID: Utils.get(
                            this.socialSites,
                            "[0].siteID",
                            null
                        ),
                        socialChannel: this.socialChannel || Utils.get(
                            this.metricModels,
                            "[0].socialSite.socialType",
                            null
                        ),
                        organizations: this.organizations,
                        availableOrganizations: this.availableOrganizations,
                        metricModifiers: this.metricModifiers,
                    },
                },
            })
            .afterClosed()
            .subscribe(
                (modifiedWidget: {
                    socialSite: SocialSiteInterface;
                    metrics: string;
                    metricModifiers: string[];
                    socialChannel: SocialChannels;
                    name: string;
                    content: string;
                    organizations: number[];
                }) => {
                    if (modifiedWidget) {
                        const data: WidgetEditEvent = {
                            id: this.widgetID + "",
                            socialSiteID: Utils.lodash.get(
                                modifiedWidget,
                                "socialSite.siteID",
                                null
                            ),
                            metrics: Utils.lodash.get(
                                modifiedWidget,
                                "metrics",
                                null
                            ),
                            name: Utils.lodash.get(
                                modifiedWidget,
                                "name",
                                null
                            ),
                            socialChannel: Utils.lodash.get(
                                modifiedWidget,
                                "socialChannel",
                                null
                            ),
                            organizations: Utils.lodash.get(
                                modifiedWidget,
                                "organizations",
                                null
                            ),
                            metricModifiers: Utils.lodash.get(
                                modifiedWidget,
                                "metricModifiers",
                                null
                            ),
                            from: this.dateRange.fromDate,
                            to: this.dateRange.toDate,
                        };

                        if (modifiedWidget.content) {
                            data.content = modifiedWidget.content;

                            this.htmlContents = [modifiedWidget.content];
                        }

                        if (modifiedWidget.metrics) {
                            this.htmlContents = [];
                        }

                        this.title = data.name;

                        if (
                            this.metricID !== modifiedWidget.metrics ||
                            Utils.get(this.socialSites, "[0].siteID", null) !==
                                Utils.lodash.get(
                                    modifiedWidget,
                                    "socialSite.siteID",
                                    null
                                ) ||
                                (this.organizations && modifiedWidget.organizations && this.organizations != modifiedWidget.organizations)
                        ) {
                            if (modifiedWidget.socialSite) {
                                this.socialSites = [modifiedWidget.socialSite];
                                this.organizations = [];
                            } 
                            if (modifiedWidget.organizations) {
                                this.socialSites = [];
                                this.organizations = modifiedWidget.organizations;
                            }
                            
                            this.metricModifiers = modifiedWidget.metricModifiers;
                            this.metricID = modifiedWidget.metrics;

                            this.getMetricDatas();
                        }

                        this.refreshRenderData();

                        this.editWidget.next(data);
                    }
                }
            );
    }

    deleteWidgetClick(event: MouseEvent): void {
        this.dialog
            .open(DialogConfirmComponent, {
                minWidth: "300px",
                data: {
                    message: LanguageService.getLine(
                        "analytics.widget.delete.confirm"
                    ),
                    yesButtonText: LanguageService.getLine(
                        "analytics.widget.delete.confirm.yes"
                    ),
                },
            })
            .afterClosed()
            .subscribe((doIt) => {
                if (doIt) {
                    this.deleteWidget.next({
                        id: this.widgetID,
                    });
                }
            });
    }

    openCalendar(event: MouseEvent): void {
        this.dateRangeInput.openCalendar({});
    }

    updateWidgetRangeByWidget(range: Range): void {}

    emitClickOnEntity(event: MouseEvent, datas: any): void {
        event.stopPropagation();
        this.clickOnEntity.emit(datas);
    }

    toggleDataset(dataset: { enabled: boolean; index: number; label: string }) {
        dataset.enabled = !dataset.enabled;

        const backupIndex = this.datasetsBackup.findIndex(
            (item) => item.label === dataset.label
        );
        const index = Utils.get(
            this.chartConfig,
            "data.datasets",
            []
        ).findIndex((item) => item.label === dataset.label);

        if (backupIndex > -1 && dataset.enabled) {
            this.chartConfig.data.datasets.push(
                this.datasetsBackup[backupIndex]
            );
        }

        if (index > -1 && !dataset.enabled) {
            this.chartConfig.data.datasets.splice(index, 1);
        }
    }

    /**
     * Get metric datas
     */
    private getMetricDatas(filters?: WidgetMetricGetterParams) {
        if (this.widgetType !== WIDGET_TYPE_CHART) {
            return;
        }

        this.metricModels = null;
        this.errorMessage = null;
        this.infoMessage = null;
        this.warnMessage = null;
        this.chartConfig = null;
        this.posts = null;
        this.summaryData = null;
        this.tableData = null;
        this.htmlContents = [];
        this.chartLegendLabels = [];

        let fromDate: Date | string = Utils.lodash.get(
            filters,
            "dateRange.fromDate",
            null
        );
        let toDate: Date | string = Utils.lodash.get(
            filters,
            "dateRange.toDate",
            null
        );

        fromDate = !!fromDate
            ? fromDate.toString()
            : this.dateRange.fromDate.toString();
        toDate = !!toDate
            ? toDate.toString()
            : this.dateRange.toDate.toString();

        const params: MetricDataFilters = {
            metricID: this.metricID,
            socialSites:
                this.socialSites ||
                Utils.lodash.get(filters, "socialSites", []),
            dateRange: {
                from: fromDate,
                to: toDate,
            },
            organizations: 
                this.organizations || 
                Utils.lodash.get(filters, "organizations", []),
            metricModifiers: this.metricModifiers || Utils.lodash.get(filters, "metricModifiers", []),
        };

        if (
            !params.socialSites ||
            !params.socialSites.length ||
            !params.dateRange
        ) {
            if ((!params.organizations || !params.organizations.length) || !params.dateRange) {
                return
            }
        }

        this.loaderVisible = true;
        this.metricDataService
            .getDatas(params)
            .then((metricModels) => {
                this.loaderVisible = false;
                this.metricModels = metricModels;

                const successModels = metricModels.filter((model) => {
                    return (
                        !model.errorMessage &&
                        !model.warnMessage &&
                        !model.infoMessage
                    );
                });
                const failedModels = metricModels.filter(
                    (model) => !!model.errorMessage
                );
                const infoModels = metricModels.filter(
                    (model) => !!model.infoMessage
                );
                const warnModels = metricModels.filter(
                    (model) => !!model.warnMessage
                );

                this.refreshRenderData();
                this.widgetLoaded.emit();

                if (successModels.length) {
                    this.metricModels = successModels;
                } else {
                    if (!!failedModels.length) {
                        this.errorMessage = failedModels[0].errorMessage;
                        return;
                    }

                    if (!!infoModels.length) {
                        this.infoMessage = infoModels[0].infoMessage;
                        return;
                    }

                    if (!!warnModels.length) {
                        this.warnMessage = warnModels[0].warnMessage;
                        return;
                    }
                }

                this.processMetricModels();
            })
            .catch((error) => {
                this.loaderVisible = false;
                // TODO language service
                this.errorMessage =
                    FormValidationService.readError(error).message ||
                    "Unknown error in component!";

                this.widgetLoaded.emit();
            });
    }

    private processMetricModels() {
        let commonChartConfig: ChartConfiguration;
        let posts;
        let summaryData;
        let tableData;

        for (const metricModel of this.metricModels) {
            const chartConfig: ChartConfiguration =
                metricModel.getChartConfig();

            if (!metricModel.multiSocialSites) {
                if (!!chartConfig) {
                    commonChartConfig = chartConfig;
                } else {
                    posts = metricModel.posts;
                    summaryData = metricModel.summaryData;
                    tableData = metricModel.tableData;
                }

                break;
            }

            if (!!chartConfig) {
                if (!chartConfig.data.datasets.length) {
                    chartConfig.data.datasets[0].data = [];
                }

                if (!commonChartConfig) {
                    commonChartConfig = chartConfig;
                    commonChartConfig.options.title.text = (
                        commonChartConfig.options.title.text as any[]
                    ).map((text) => {
                        if (!!text) {
                            return `${metricModel.socialSite.name} - ${text}`;
                        }

                        return metricModel.socialSite.name;
                    });
                    commonChartConfig.data.datasets =
                        commonChartConfig.data.datasets.map((dataset) => {
                            return {
                                data: dataset.data,
                                label: this.getLabel(dataset, metricModel),
                            };
                        });
                } else {
                    (commonChartConfig.options.title.text as any[]).push(
                        ...(chartConfig.options.title.text as any[]).map(
                            (text) => {
                                if (!!text) {
                                    return `${metricModel.socialSite.name} - ${text}`;
                                }

                                return metricModel.socialSite.name;
                            }
                        )
                    );

                    for (const dataset of chartConfig.data.datasets) {
                        commonChartConfig.data.datasets.push({
                            data: dataset.data,
                            label: this.getLabel(dataset, metricModel),
                        });
                    }
                }
            } else {
                // TODO set value from language service
                const groupedText = " (Grouped)";

                if (!posts) {
                    posts = metricModel.posts;
                    this.title = this.title.replace(groupedText, "");
                } else {
                    (posts as any[]).push(...metricModel.posts);
                    this.title += groupedText;
                }

                if (!summaryData) {
                    summaryData = metricModel.summaryData;
                    this.title = this.title.replace(groupedText, "");
                } else {
                    (summaryData as any[]).push(...metricModel.summaryData);
                    this.title += groupedText;
                }

                if (!tableData) {
                    tableData = metricModel.tableData;
                    this.title = this.title.replace(groupedText, "");
                }

                if (!!metricModel.htmlContents) {
                    this.htmlContents.push(...metricModel.htmlContents);
                }
            }
        }

        if (!!commonChartConfig) {
            //if (commonChartConfig.type === "line")
            commonChartConfig.options.legend.display = false;

            const tempCanvas = document.createElement("canvas");

            commonChartConfig.data.datasets =
                commonChartConfig.data.datasets.map((dataset) => {
                    const gradientFill = tempCanvas
                        .getContext("2d")
                        .createLinearGradient(0, 500, 0, 100);

                    // TODO: Better way to handle this. Also, language... This is just a quick hack for now since there is only a few
                    // new widgets that need specific colors.
                    const specificColors = {
                        "Scheduled": "#57d580",
                        "Published": "#3fcfff",
                        "Failed": "#ff3f3f",
                        "Partially Failed": "#f58d3d",
                        "Approved": "#57d580",
                        "Waiting For Approval": "#fff133",
                        "Declined": "#f96a68",
                        "Missed Review": "#f58d3d",
                        "Esemdee": "#28315e",
                        "Native": "#994921",
                    }

                    let mainColor = "#ffffff";
                    if (dataset.label && !!specificColors[dataset.label]) {
                        mainColor = specificColors[dataset.label];
                    } else {
                        mainColor = this.getChartColor();
                    }

                    gradientFill.addColorStop(
                        1,
                        ColorSupport.convertHexToRgba(mainColor, 0.6)
                    );

                    dataset.backgroundColor = gradientFill;
                    dataset.borderColor = mainColor;
                    dataset.pointBackgroundColor = mainColor;

                    dataset.hoverBackgroundColor = gradientFill;
                    dataset.hoverBorderColor = "#ffffff";
                    //dataset.hoverBorderWidth = 1;
                    dataset.pointHoverBackgroundColor = mainColor;
                    dataset.pointHoverBorderColor = "#ffffff";
                    //dataset.pointHoverBorderWidth = 1;

                    dataset.hidden = false;

                    return dataset;
                });

            tempCanvas.remove();

            this.chartLegendLabels = commonChartConfig.data.datasets.map(
                (dataset, index) => {
                    return {
                        html: `
                            <span style="background:${dataset.borderColor}"></span>
                            ${dataset.label}
                        `,
                        enabled: true,
                        label: dataset.label,
                        index,
                    };
                }
            );

            this.chartLegendLabels = Utils.lodash.uniqBy(this.chartLegendLabels, 'label'); // Remove unnecessary duplicate labels
            //} END OF THE IF

            this.datasetsBackup = [...commonChartConfig.data.datasets];
            this.chartConfig = commonChartConfig;
        }

        if (!!posts) {
            this.posts = Helpers.orderBy(posts, "num", "DESC").slice(0, 5);
        }

        if (!!summaryData) {
            this.summaryData = summaryData;
        }

        if (!!tableData) {
            this.tableData = tableData;
        }

        this.refreshRenderData();
    }

    refreshRenderData() {
        this.hasDatasV = this.hasDatas();
        this.htmlContentsV = this.getHtmlContents();
        this.isHtmlContentV = this.isHtmlContent();
        this.isTextWidgetV = this.isTextWidget();
        this.isChartContentV = this.isChartContent();
        this.isListContentV = this.isListContent();
        this.isSummaryContentV = this.isSummaryContent();
        this.isTableContentV = this.isTableContent();
    }

    private getChartColor(): string {
        const randomIndex = Math.floor(Math.random() * this.chartColors.length);
        const color = this.chartColors[randomIndex];

        this.chartColors.splice(randomIndex, 1);

        if (!this.chartColors.length) {
            this.chartColors = [...this.chartColorsBackup];
        }

        return color;
    }

    /**
     * Init data getter listener
     */
    private initGetDatasListener() {
        if (!!this.getDatas$) {
            this.getDatas$.subscribe((filters?: WidgetMetricGetterParams) => {
                if (this.refreshable || (!this.chartConfig && !this.posts)) {
                    this.getMetricDatas(filters);
                }
            });
        }
    }

    private getLabel(dataset: ChartDataSets, metricModel: MetricModel) {
        let label = `${metricModel.socialSite.name} (${metricModel.metricConfig.dataName})`;

        if (!!dataset.label) {
            label += ` - ${dataset.label}`;
        }

        return label;
    }
}
