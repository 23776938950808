import {Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '~/src/app/services/language.service';
import Utils from '~/src/app/core/utils';

/**
 * !IMPORTANT
 *
 * The style attribute is required in html on button-list element,
 * because the element visible on loaded without inline style!
 */

/**
 * !EVEN MORE IMPORTANT
 * This component caused lag for some reason in the template manager. Probably the clickOutside method.
 */

// Button group vertical positions
type Position = 'above' | 'below';

@Component({
    selector: 'smd-other-buttons',
    templateUrl: './other-buttons.component.html',
    styleUrls: ['./other-buttons.component.scss']
})
export class OtherButtonsComponent {

    // Classes
    @HostBinding('class.active') private _showButtonList = false;
    @HostBinding('class.above') isAbovePosition = false;
    @HostBinding('class.below') isBelowPosition = true;
    @HostBinding('class.exist') existButtonList = false;

    // View elements
    @ViewChild('buttonList', {static: true}) buttonList: ElementRef<HTMLDivElement>;
    @ViewChild('button', {static: true}) button: ElementRef<HTMLButtonElement>;

    // View variables
    tooltipPosition: Position = 'above';
    private _buttonsListTop = 0;
    private _buttonsListLeft = 0;

    get showButtonList(): boolean {
        return this._showButtonList;
    }

    set showButtonList(value: boolean) {
        //const delay = 250;

        if (value) {
            this.existButtonList = true;

            //setTimeout(() => {
                this._showButtonList = value;
            //}, delay);
        } else {
            this._showButtonList = value;

            //setTimeout(() => {
                this.existButtonList = false;
            //}, delay);
        }
    }

    get buttonsListTop(): number {

        if (this.button) {
            const buttonTop = this.button.nativeElement.getBoundingClientRect().top;
            const buttonBottom = this.button.nativeElement.getBoundingClientRect().bottom;
            const buttonHeight = this.button.nativeElement.getBoundingClientRect().height;

            if (this.buttonList) {
                const buttonListHeight = this.buttonList.nativeElement.getBoundingClientRect().height;
                const windowHeight = window.innerHeight;

                if ((windowHeight - 10) < (buttonBottom + buttonListHeight)) {
                    return buttonTop - buttonListHeight;
                }
            }

            return buttonTop + buttonHeight;
        }

        return this._buttonsListTop;
    }

    set buttonsListTop(value: number) {
        this._buttonsListTop = value;
    }

    get buttonsListLeft(): number {

        if (this.button) {
            const buttonLeft = this.button.nativeElement.getBoundingClientRect().left;
            const buttonWidth = this.button.nativeElement.getBoundingClientRect().width;

            return buttonLeft + (buttonWidth / 2);
        }

        return this._buttonsListLeft;
    }

    set buttonsListLeft(value: number) {
        this._buttonsListLeft = value;
    }

    constructor(
        public elementRef: ElementRef,
        public language: LanguageService
    ) {}

    /**
     * Click outside listener
     * @param event
     */
    @HostListener('document:click', ['$event'])
    clickOutside(event: MouseEvent) {
        // click outside
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.closeOtherButtons();
        }
    }

    /**
     * ESC key listener
     * @param event
     */
    @HostListener('document:keydown.escape', ['$event'])
    keyDownEscape(event: KeyboardEvent) {
        this.closeOtherButtons();
    }

    /**
     * Toggle other buttons visibility
     * @param event
     */
    toggleButtonList(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showButtonList = !this.showButtonList;
    }

    /**
     * Close other buttons
     */
    private closeOtherButtons() {
        this.showButtonList = false;
    }
}
