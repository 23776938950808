import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "~/src/app/shared/shared.module";
import { PostManagerComponent } from "./post-manager/post-manager.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { LanguagesModule } from "~/src/app/modules/languages/languages.module";
import { DocumentsModule } from "~/src/app/modules/documents/documents.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PostSkeletonModule } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.module";
import { PostTemplateChooserModule } from "~/src/app/modules/social-media-post/post-template-chooser/post-template-chooser.module";
import { SocialMediaPlatformChooserComponent } from "./social-media-platform-chooser/social-media-platform-chooser.component";
import { PostTemplateManagerComponent } from "./post-template-manager/post-template-manager.component";
import { PostTemplateSuccessCreateModalComponent } from "./post-template-success-create-modal/post-template-success-create-modal.component";
import { DraftComponent } from "./draft/draft.component";
import { CubeParentComponent } from "~/src/app/modules/posts/cube/components/cube-parent/cube-parent.component";
import { GmbModule } from "./gmb/gmb.module";
import { MatTreeModule } from '@angular/material/tree';
import { TemplateFolderManagerComponent } from "./template-folder-manager/template-folder-manager.component";
import { TemplateFolderSelectComponent } from "./template-folder-select/template-folder-select.component";
import { TemplateFolderShareComponent } from "./template-folder-share/template-folder-share.component";
import { PostCreateModalComponent } from "./post-create-modal/post-create-modal.component";
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EditorModule,
        LanguagesModule,
        DocumentsModule,
        InfiniteScrollModule,
        PostSkeletonModule,
        PostTemplateChooserModule,
        GmbModule,
    ],
    declarations: [
        PostManagerComponent,
        SocialMediaPlatformChooserComponent,
        PostTemplateManagerComponent,
        TemplateFolderManagerComponent,
        TemplateFolderSelectComponent,
        TemplateFolderShareComponent,
        PostTemplateSuccessCreateModalComponent,
        DraftComponent,
        CubeParentComponent,
        PostCreateModalComponent
    ],
    exports: [
        PostManagerComponent,
        SocialMediaPlatformChooserComponent,
        PostTemplateManagerComponent,
        TemplateFolderManagerComponent,
        TemplateFolderSelectComponent,
        TemplateFolderShareComponent,
        PostTemplateSuccessCreateModalComponent,
        DraftComponent,
        CubeParentComponent,
        GmbModule,
        MatTreeModule,
        PostCreateModalComponent
    ],
    entryComponents: [
        PostManagerComponent,
        SocialMediaPlatformChooserComponent,
        PostTemplateManagerComponent,
        TemplateFolderManagerComponent,
        TemplateFolderSelectComponent,
        TemplateFolderShareComponent,
        PostTemplateSuccessCreateModalComponent,
        CubeParentComponent,
        PostCreateModalComponent
    ],
})
export class SocialMediaPostModule { }
