import { Component, EventEmitter, Inject } from "@angular/core";
import {
    FormControl,
    FormGroup,
    Validators,
} from "~/node_modules/@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { LanguageService } from "~/src/app/services/language.service";
import { Dashboard } from "~/src/app/modules/analytics/dashboard.interfaces";
import { AnalyticsService } from "../../analytics.service";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { Helpers } from "~/src/app/services/helpers";
import { SocialSiteInterface } from "~/src/app/components/social-site-select/social-site-select.component";
import { socialSiteAppearance } from "../../../social-media-post/social-media-platforms-config";

interface DialogData {
    dashboard: Dashboard;
}

@Component({
    selector: "smd-duplicate-dashboard-modal",
    templateUrl: "./duplicate-dashboard-modal.component.html",
    styleUrls: ["./duplicate-dashboard-modal.component.scss"],
})
export class DuplicateDashboardModalComponent {
    submitEvent = new EventEmitter();
    dashboardForm: FormGroup;
    message: object;
    dashboard: Dashboard;
    socialSites: SocialSiteInterface[] = [];
    socialSitesFetching = false;

    constructor(
        public dialogRef: MatDialogRef<DuplicateDashboardModalComponent>,
        public languageService: LanguageService,
        public socialSiteController: SocialSiteController,
        private analyticsService: AnalyticsService,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) {
        this.dashboardForm = new FormGroup({
            name: new FormControl(
                this.dialogData.dashboard.name || "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(55),
                ])
            ),
            description: new FormControl(
                "",
                Validators.compose([Validators.maxLength(55)])
            ),
            socialSites: new FormControl([]),
        });
        this.dashboard = this.dialogData.dashboard;
    }

    ngOnInit() {
        this.initSocialSites();
    }

    initSocialSites() {
        this.socialSitesFetching = true;
        this.socialSiteController.getItems(
            (response: any) => {
                this.socialSites = 
                    (response.socialSites.filter(item => socialSiteAppearance?.[item.socialType]?.analytics !== false) || []).map((item) => {
                        return { ...item, disabled: false };
                    }),
                this.socialSitesFetching = false;
            },
            () => {
                this.socialSitesFetching = false;
            }
        );
    }

    /**
     * Close modal instance
     */
    closeDialog() {
        this.dialogRef.close(false);
    }

    validateInput(): void {
        if (!this.dashboardForm.valid) {
            this.message = FormValidationService.getMessages(
                this.dashboardForm.controls
            );
        }
    }

    submitDashboard(event) {
        if (this.dashboardForm.valid) {
            this.dashboard.name = this.dashboardForm.get("name").value;
            delete this.dashboard.dashboardID;

            if (this.dashboard.data?.widgets) {
                this.dashboard.data.widgets.forEach((widget) => {
                    widget.id = this.analyticsService.generateUUID();

                    if (widget.socialSiteID) {
                        const widgetSocialSite = this.socialSites.find(
                            (item) => item.siteID === widget.socialSiteID
                        );

                        if (widgetSocialSite) {
                            // check if the selected sites contain a social site of the same socialType
                            // if they do, set those sites as the widgets' social sites
                            const selectedSocialSiteIDs = this.dashboardForm.get("socialSites").value;
                            const selectedSocialSites = this.socialSites.filter(
                                (item) => selectedSocialSiteIDs.includes(item.siteID)
                            );

                            if (selectedSocialSites.some((item) => item.socialType === widgetSocialSite.socialType)) {
                                const selectedSocialSite = selectedSocialSites.find((item) => item.socialType === widgetSocialSite.socialType);
                                widget.socialSiteID = selectedSocialSite.siteID;
                            } else {
                                delete widget.socialSiteID;
                            }
                        } else {
                            delete widget.socialSiteID;
                        }
                    }
                });
            }

            this.submitEvent.emit(this.dashboard);
        }
    }
}
