import { Component, OnInit, Input } from "@angular/core";
import { AbstractFormGroupComponent } from "~/src/app/modules/social-media-post/gmb/form/abstract/abstract.component";
import { url } from "@ng-validators/ng-validators";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "smd-offer",
    templateUrl: "./offer-form.component.html",
    styleUrls: ["./offer-form.component.scss"],
})
export class OfferFormComponent
    extends AbstractFormGroupComponent
    implements OnInit
{
    formGroup: FormGroup = this.fb.group({
        couponCode: null,
        redeemOnlineUrl: [null, [url]],
        termsConditions: null,
    });
}
