import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StoreData, TWITTER_OAUTH_CALLBACK_KEY, TwitterAPI} from '~/src/app/modules/users/twitter.service';
import {SOCIAL_MEDIA_TYPE_TWITTER} from '~/src/app/core/constants';

@Component({
    selector: 'smd-oauth',
    templateUrl: './oauth.component.html',
    styleUrls: ['./oauth.component.scss']
})
export class OauthComponent {
    userMessage = '';
    platform = '';
    twitterCallback = null;

    constructor(
        private route: ActivatedRoute,
        private twitterAPI: TwitterAPI
    ) {
        this.platform = this.route.snapshot.params.platform;
        if (this.platform === SOCIAL_MEDIA_TYPE_TWITTER) {
            this.twitterAuth();
        } else if (this.platform === 'google') { // not gmb! it's google here
            this.gmbAuth();
        }
    }

    gmbAuth() {
        this._onCloseTab({message: 'Authorization cancelled'}, false);
        return;
    }

    twitterAuth() {
        const queryParams: {oauth_token?: string; oauth_verifier?: string, init?: string} = this.route.snapshot.queryParams;

        if (queryParams.init === 'true') {
            this.userMessage = 'Redirecting to authorize twitter app...';

            this.twitterAPI.requestToken()
                .then(() => this.twitterAPI.authorize())
                .catch(error => {
                    this._onCloseTab(error.error || {}, false);
                });

            return;
        }

        const token = this.twitterAPI.getData().oauth_token;
        const data: StoreData = {};

        if (queryParams.oauth_token) {
            data.oauth_token = queryParams.oauth_token;

            if (token !== data.oauth_token) {
                this._onCloseTab({message: 'Stored token and query param token are not equal!'}, false);
                return;
            }
        } else {
            this._onCloseTab({message: 'Query param token not found!'}, false);
            return;
        }

        if (queryParams.oauth_verifier) {
            data.oauth_verifier = queryParams.oauth_verifier;
        } else {
            this._onCloseTab({message: 'Auth verifier not found!'}, false);
            return;
        }

        this.twitterAPI.storeData(data);

        this.userMessage = 'Get access token to account...';
        this.twitterAPI.getAccessToken()
            .then(response => {
                this._onCloseTab({
                    ...response,
                    ...data
                }, true);
            })
            .catch((error: any) => {
                this._onCloseTab(error.error || {}, false);
            });
    }

    _onCloseTab(data = {}, isSuccess = true) {
        data = {
            successAuth: isSuccess,
            ...data
        };

        if (window.opener && window.opener[TWITTER_OAUTH_CALLBACK_KEY]) {
            this.twitterCallback = window.opener[TWITTER_OAUTH_CALLBACK_KEY];
        }

        const _this = this;
        window.onbeforeunload = function (event) {
            if (_this.twitterCallback) {
                _this.twitterCallback(data);
            }
        };

        window.close();
    }

}
