import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LanguageService} from "~/src/app/services/language.service";
import {DialogData, ViewList} from "./social-user-dialog.interface";

@Component({
    selector: 'smd-social-user-dialog',
    templateUrl: './social-user-dialog.component.html',
    styleUrls: ['./social-user-dialog.component.scss']
})
export class SocialUserDialogComponent implements OnInit {
    filter: String = '';
    list: any[] = [];
    selectedList: any[] = [];
    viewList: ViewList[] = [];
    showLoader: boolean = false;
    service = null;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<SocialUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
        this.dialogInit();
        this.getItems();
    }

    protected dialogInit() { this.dialogRef.updateSize('75vw', 'auto') }
    protected itemsProcessor(data) {}
    protected getAction(): Promise<any[]> { return Promise.resolve([]) }
    protected saveAction(): Promise<any> { return Promise.resolve() }

    public changeFilter(event) {
        this.filter = event.target.value;
    }

    public selectItem(item) {
        const index = this.selectedList.indexOf(item);
        index > -1 ? this.selectedList.splice(index, 1) : this.selectedList.push(item);
    }

    public close() {
        this.dialogRef.close();
    }

    public getItems(): void {
        this.showLoader = true;
        this.list = [];
        this.selectedList = [];
        this.viewList = [];

        this.getAction().then((data: any) => {
            this.itemsProcessor(data);
            this.showLoader = false;
        });
    }

    public save() {
        this.saveAction()
            .then(response => console.log('api/social-sites', response))
            .catch(error => console.error('api/social-sites', error));
    }

    get languageService() {
        return LanguageService;
    }
}
