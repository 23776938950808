import {WidgetAbstract} from './widget.abstract';
import {TextWidget} from './widget.interfaces';

export class TextWidgetEntity extends WidgetAbstract {
    constructor(
        widget: TextWidget
    ) {
        super(widget);

        this.isTextWidget = true;
    }
}
