import {ComponentAbstract} from './component.abstract';
import {SmdFile, SmdFileInterface} from './file.class';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {FileBrowserService} from './file-browser.service';
import {PreloaderComponent} from '../components/preloader/preloader.component';
import {EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {CarouselController, CarouselItem} from '../modules/posts/carousel/carousel.component';
import {CustomEvents, Debounce, Helpers} from './helpers';
import {HttpEventType} from '@angular/common/http';
import {NotifyService} from '~/src/app/services/notify.service';
import Utils from '~/src/app/core/utils';
import {MediaRequestObject} from '~/src/app/components/upload-media/upload-media.interface';

export abstract class FileBrowserAbstract extends ComponentAbstract {
    private _disabled = false;

    @ViewChild(PreloaderComponent) preLoader: PreloaderComponent;
    @ViewChild(MatPaginator) pagination: MatPaginator;

    @Input('enableFileUpload') enableFileUpload = true;

    @Output('selectionChange') selectionChange: EventEmitter<{
        selectedFiles: SmdFileInterface[],
        file: SmdFileInterface
    }> = new EventEmitter<{selectedFiles: SmdFileInterface[], file: SmdFile}>();

    showLoader = true;

    /**
     * All files
     *
     * @type {any[]}
     */
    public files: Array<SmdFileInterface> = [];

    /**
     * Selected files
     *
     * @type {any[]}
     */
    private _selectedFiles: Array<SmdFileInterface> = [];

    protected constructor(
        public service: FileBrowserService,
        public carouselController: CarouselController
    ) {
        super();
    }

    initFiles(response, isAdminMode = false) {
        const medias = response.medias.map(media => {
            media['systemTypeMessage'] = Utils.lodash.get(media, 'systemTypeMessage', '');
            return media;
        });
        const carouselIDs: number[] = Helpers.arrayUnique(
            medias.filter(media => media.mediaType === FILE_TYPES.CAROUSEL).map(media => media.mediaID)
        );

        if (carouselIDs.length) {
            return this.carouselController.getCarouselItemsByIDs(carouselIDs, isAdminMode)
            .then(({carouselItems}) => {
                medias.map(media => {
                    const mediaCarouselItems = [];

                    carouselItems.forEach(carouselItem => {
                        if (carouselItem.carouselID === media.mediaID) {
                            mediaCarouselItems.push(new CarouselItem(carouselItem));
                        }
                    });

                    media.carouselItems = mediaCarouselItems;

                    return media;
                });

                response.medias = SmdFile.initFileArray(medias);

                return response;
            });
        } else {
            response.medias = SmdFile.initFileArray(medias);
            return Promise.resolve(response);
        }
    }

    /**
     * Get items success callback
     *
     * @param response
     */
    successGetItems(response: any, callback: () => void = () => {}): void {
        this.initFiles(response)
            .then(({medias}: {medias: SmdFileInterface[]}) => {
                this.files = medias;

                this.paginationOptions.length = response['count'];

                this.setSelectedFilesInBrowser(() => {
                    this.setInactiveFiles();
                });
                this.showLoader = false;
                callback();
            });
    }

    failedGetItems(error: any): void {
        super.failedGetItems(error);

    }

    /**
     * Edit file
     *
     * @param {{name: string; tags: string; mediaID: number}} file
     * @param {(response: any) => void} success
     * @param {(error: any) => void} failed
     */
    editFile(file: {name: string, tags?: string, mediaID: number}, success: (response: any) => void = (response: any) => {}, failed: (error: any) => void = (error: any) => {}) {
        this.service.editFile(file)
            .then(response => {
                success(response);
            })
            .catch(error => {
                failed(error);
            });
    }

    deleteFileByPropertyValue(property, value) {

        const getIndexOfItemInObject = function (object, prop, value) {
            return object.findIndex(function(item) {
                return item[property] === value;
            });
        };

        const indexInFiles = getIndexOfItemInObject(this.files, property, value);

        if (indexInFiles > -1) {
            if (this.files[indexInFiles].selected) {
                const indexInSelectedFiles = getIndexOfItemInObject(this._selectedFiles, property, value);
                if ( indexInSelectedFiles > -1 ) {
                    this._selectedFiles.splice(indexInSelectedFiles, 1);
                }
            }
            this.files.splice(indexInFiles, 1);
        }
    }

    uploadFile(request: MediaRequestObject) {
        this.service.uploadFile(request)
            .subscribe((response) => {
                if (response['type'] === HttpEventType.UploadProgress) {
                    this.createInProgress(response);
                } else if (response['type'] === HttpEventType.Response) {
                    this.successCreateWithProgress(response, request);
                }
            }, error => {
                this.failedCreateWithProgress(error, request);
                this.service.showErrorAlert(error);
            });
    }

    replaceFile(file) {
        this.service.replaceFile(file)
            .subscribe((response) => {
                if (response['type'] === HttpEventType.UploadProgress) {
                    this.createInProgress(response);
                } else if (response['type'] === HttpEventType.Response) {
                    this.successCreateWithProgress(response, file);
                }
            }, error => {
                this.failedCreateWithProgress(error, file);
                this.service.showErrorAlert(error);
            });
    }

    /**
     * Delete files
     *
     * @param {SmdFileInterface[]} files
     */
    deleteFiles(files: SmdFileInterface[], success: (response) => void = () => {}, failed: (error) => void = () => {}): void {
        this.beforeDeleteFiles();

        const fileIDs = [],
            carouselIDs = [],
            data = {};

        for (const index in files) {
            const file = files[index];

            if (file.type === FILE_TYPES.CAROUSEL) {
                carouselIDs.push(file.mediaID);
            } else {
                fileIDs.push(file.mediaID);
            }
        }

        const promises = [];

        if (fileIDs.length > 0) {
            data['fileIDs'] = JSON.stringify(fileIDs);
            promises.push(this.service.deleteFiles(data));
        }

        if (carouselIDs.length > 0) {
            for (const id of carouselIDs) {
                promises.push(this.carouselController.deleteCarouselByID(id));
            }
        }

        Promise.all(promises).then(responses => {
            success(responses);
            this.afterDeleteFiles();
            this.successDeleteFiles(responses);
        }).catch(messages => {
            failed(messages);
            this.afterDeleteFiles();
            this.failedDeleteFiles(messages);
        });

    }

    /**
     * Before delete files
     */
    beforeDeleteFiles(): void {}

    /**
     * After delete files
     */
    afterDeleteFiles(): void {}

    /**
     * Success delete files callback
     *
     * @param response
     */
    successDeleteFiles(response: any): void {}

    /**
     * Failed delete files callback
     *
     * @param error
     */
    failedDeleteFiles(error: any): void {}

    /**
     * Select file
     *
     * @param {SmdFileInterface} currentFile
     */
    @Debounce()
    async selectFile(currentFile: SmdFileInterface, event?: Event) {
        if (event && SmdFile.isBreakFileSelection(event)) {
            return false;
        }

        const indexInSelectedFiles = this._selectedFiles.findIndex((file) => {
            return SmdFile.isFilesEqual(currentFile, file);
        });
        const selectedSocialTypes = this.service.selectedSocialTypesInCarouselCreateOrEdit;
        let isAddableToCarousel = true;

        selectedSocialTypes.forEach(type => {
            if (currentFile.socialChannels.indexOf(type) === -1 && currentFile.socialChannels.length) {
                isAddableToCarousel = false;
            }
        });

        const isNeedAbortToAdd = !isAddableToCarousel && selectedSocialTypes.length !== 0;

        if (indexInSelectedFiles === -1) {
            if (!isNeedAbortToAdd) {
                this._selectedFiles.push(currentFile);
                currentFile.selected = true;
            }
        } else {
            this._selectedFiles.splice(indexInSelectedFiles, 1);
            currentFile.selected = false;

            if (isNeedAbortToAdd) {
                this.emitItemChangeByFile(currentFile);
            }
        }

        if (isNeedAbortToAdd) {
            NotifyService.warning(
                this.service.fileBrowserError.title,
                this.service.fileBrowserError.message
            );

            return;
        } else {
            this.emitItemChangeByFile(currentFile);
        }
    }

    private emitItemChangeByFile(currentFile) {
        this.selectionChange.emit({
            selectedFiles: this.selectedFiles,
            file: currentFile
        });

        setTimeout(() => {
            this.setSelectedFilesInBrowser();
            this.setInactiveFiles();
        });
    }

    /**
     * Add new file to list
     *
     * @param {SmdFileInterface} file
     */
    addNewFile(file: SmdFileInterface, isSelected?: boolean): void {
        this.files.unshift(file);

        if (isSelected) {
            setTimeout(() => {
                this.selectFile(file);
            });
        }
    }

    /**
     * Set inactive files
     */
    setInactiveFiles(): void {
        const types: string[] = [];

        this.selectedFiles.forEach(function (file, index) {
            types.push(file.type);
        });

        this.files.map((file) => {
            file.active = true;

            if (types.indexOf(FILE_TYPES.CAROUSEL) > -1 || types.indexOf(FILE_TYPES.VIDEO) > -1 || types.indexOf(FILE_TYPES.OG) > -1) {
                if (!file.selected) {
                    file.active = false;
                }
            } else if (types.length > 0) {
                if (types.indexOf(file.type) === -1) {
                    file.active = false;
                }
            }

            return file;
        });
    }

    /**
     * Set selected files
     */
    setSelectedFilesInBrowser(callback: () => void = () => {}): void {
        this.files.map(file => {
            if (this.selectedFiles.findIndex(item => SmdFile.isFilesEqual(file, item)) > -1) {
                file.selected = true;
            } else {
                file.selected = false;
            }

            return file;
        });

        callback();
    }

    /**
     * Set selectedFiles array
     *
     * @param {SmdFileInterface[]} files
     */
    setSelectedFiles(files: SmdFileInterface[]): void {
        this._selectedFiles = [];

        for (const index in files) {
            const file = files[index];

            file.selected = true;

            this._selectedFiles.push(file);
        }

        this.setSelectedFilesInBrowser();

        setTimeout(() => {
            this.setInactiveFiles();
        });
    }

    /**
     * Clear selected files
     */
    clearSelectedFiles(): void {
        this._selectedFiles = [];
    }

    getSelectedFiles() {
        return this._selectedFiles;
    }

    /**
     * All file set active is true, selected is false
     */
    clearBrowser(): void {
        for (const index in this.files) {
            const file = this.files[index];

            file.active = true;
            file.selected = false;
        }
    }

    setDisableState() {
        this.files.forEach((value) => {
            value.active = !this.disabled;
        });
    }

    @HostListener(`document:${CustomEvents.UPDATE_FILE_BROWSER}`, ['$event'])
    refreshBrowser(event) {
        this.getItems();
    }

    get selectedFiles(): Array<SmdFileInterface> {
        if (this.disabled) {
            return [];
        }

        return this._selectedFiles;
    }

    set selectedFiles(value: Array<SmdFileInterface>) {
        this._selectedFiles = value;

        this.setSelectedFilesInBrowser(() => {
            this.setInactiveFiles();
        });
    }

    @Input()
    set disabled(value) {
        this._disabled = value;

        this.setDisableState();
    }

    get disabled(): boolean {
        return this._disabled;
    }
}
