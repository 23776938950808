import {TINYMCE_DELIMITER_HASHTAG} from '~/src/app/configs/configs';

const HashtagUtils = ({

    /**
     * Check hashtag contain hashtag sign
     * @param {string} tag
     * @return {boolean}
     */
    hasHashtagSign(tag: string): boolean {
        return (tag || '').substr(0, 1) === TINYMCE_DELIMITER_HASHTAG;
    },

    /**
     * Add hashtag sign to tag string
     * @param {string} tag
     * @return {string}
     */
    addHashtagSign(tag: string): string {
        return TINYMCE_DELIMITER_HASHTAG + tag;
    }
});

export {HashtagUtils};
