import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModelAbstract} from '../../services/model.abstract';
import {MatDialog} from '@angular/material/dialog';

export interface MediaTagInterface {
    tagID: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class MediaTagsService extends ModelAbstract {

    constructor(
        http: HttpClient,
        dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/media/tags';
    }
}
