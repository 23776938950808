import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import Utils from '~/src/app/core/utils';
import {LanguageService} from '~/src/app/services/language.service';
import {FormControl, FormGroup} from '@angular/forms';
import {StaffCountRanges} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.constants';
import {ISOLanguages} from '~/src/app/core/constants';
import {NewsFeedTargetingLinkedinService} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.service';
import {
    FieldsOfStudy,
    Industry,
    JobFunction,
    Seniority
} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.interfaces';

@Component({
    selector: 'smd-news-feed-targeting-linkedin',
    templateUrl: './news-feed-targeting-linkedin.component.html',
    styleUrls: ['./news-feed-targeting-linkedin.component.scss']
})
export class NewsFeedTargetingLinkedinComponent implements OnInit {
    @Input() getData: Subject<any>;

    /**
     * Set feed targeting from base form
     * @param value
     */
    @Input() set targetingOptions(value) {
        if (!Utils.lodash.isEmpty(value) && Utils.lodash.keys(value).length && !this.targetingOptions) {
            this._targetingOptions = value;
            this.setFeedTargeting(value);
        }
    }

    /**
     * Set current entity (post or post template)
     * @param value
     */
    @Input() set initialValue(value: string) {
        if (!!value) {
            this.setFeedTargeting(value);
        }
    }

    /**
     * Must be sent a linkedIn social site ID to the API, because need for get access token
     * @param value
     */
    @Input() set socialSite(value: any) {
        if (!!value && !!value.socialSiteID && !this.wasTargetingInitialize) {
            this.wasTargetingInitialize = true;

            this.service.initialize(Number(value.socialSiteID));
        }
    }

    @Output() valueChange = new EventEmitter();

    //  Settings
    targetingControlNames = {
        Languages: 'interfaceLocales',
        Industries: 'industries',
        JobFunctions: 'jobFunctions',
        CompanySize: 'staffCountRanges',
        Seniorities: 'seniorities',
        FieldsOfStudy: 'fieldsOfStudy',
    };

    //  Form groups and controls
    targetingFormGroup = new FormGroup({
        [this.targetingControlNames.Languages]: new FormControl(null),
        [this.targetingControlNames.Industries]: new FormControl(null),
        [this.targetingControlNames.JobFunctions]: new FormControl(null),
        [this.targetingControlNames.CompanySize]: new FormControl(null),
        [this.targetingControlNames.Seniorities]: new FormControl(null),
    });

    //  Arrays
    staffCountRanges = Utils.lodash.cloneDeep(StaffCountRanges);
    isoLanguages = Utils.lodash.cloneDeep(ISOLanguages);
    isoLanguagesArray: {id: string, name: string}[] = [];
    seniorities: Observable<Seniority[]>;
    jobFunctions: Observable<JobFunction[]>;
    industries: Observable<Industry[]>;
    fieldsOfStudy: Observable<FieldsOfStudy[]>;

    //  Other variables
    private wasTargetingInitialize = false;
    private _targetingOptions;

    constructor(
        public language: LanguageService,
        private service: NewsFeedTargetingLinkedinService
    ) {
        this.initialize();
    }

    ngOnInit() {}

    get targetingOptions() {
        return this._targetingOptions;
    }

    /**
     * Trigger valueChange event
     * @param values
     */
    private emitValueChange(values) {
        const result = {};

        Utils.lodash.forEach(values, (value, name) => {
            switch (name) {
                case this.targetingControlNames.Languages:
                    if (!!value && !!value.length) {
                        result[name] = value.map(data => {
                            return {
                                language: data
                            };
                        });
                    }

                    break;

                default:
                    if (!!value && !!value.length) {
                        result[name] = value;
                    }

                    break;
            }
        });

        this.valueChange.emit(JSON.stringify(result));
    }

    /**
     * Set targeting form controls
     * @param targetingOptions
     */
    private setFeedTargeting(targetingOptions) {
        if (!targetingOptions) {
            return;
        }

        targetingOptions = JSON.parse(targetingOptions);

        Utils.lodash.forEach(targetingOptions, (value, name) => {
            switch (name) {
                case this.targetingControlNames.Languages:
                    if (!!value && !!value.length) {
                        this.targetingFormGroup.get(name).setValue(value.map(option => option.language));
                    }

                    break;

                default:
                    if (!!value && !!value.length && this.targetingFormGroup.get(name)) {
                        this.targetingFormGroup.get(name).setValue(value);
                    }

                    break;
            }
        });
    }

    /**
     * Component initialize
     */
    private initialize() {
        //  Init ISOLanguages array
        Utils.lodash.forEach(this.isoLanguages, (values, code) => {
            this.isoLanguagesArray.push({
                id: code,
                name: values.name
            });
        });

        this.seniorities = this.service.getSeniorities();
        this.jobFunctions = this.service.getJobFunctions();
        this.fieldsOfStudy = this.service.getFieldsOfStudies();
        this.industries = this.service.getIndustries();

        this.targetingFormGroup.valueChanges.subscribe(data => {
            this.emitValueChange(data);
        });
    }
}
