import {Component, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomEvents} from '../../../services/helpers';
import {FormValidationService} from '../../../services/form.validation.service';
import {NotifyService} from '../../../services/notify.service';
import {AuthService} from '../../auth/auth.service';
import {USER_API_URLS, UserService} from '../../../services/user.service';
import {ValidatorEqual} from '../../../directives/validator-equal.directive';
import {DialogConfirmComponent} from '../../../components/dialog-confirm/dialog-confirm.component';
import {Token} from '../../../services/token';
import {FileUploader} from 'ng2-file-upload';
import {MatDialog} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {TwoFactorDialogComponent} from './two-factor-dialog/two-factor-dialog.component';
import {LoggedUser} from '../../../services/logged-user';
import {LanguageService} from '../../../services/language.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import Utils from '~/src/app/core/utils';
import {NO_AVATAR_IMAGE_PATH} from '~/src/app/configs/configs';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import {DialogSuccessComponent} from '~/src/app/components/dialog-success/dialog-success.component';
import {FileInputComponent} from 'ngx-material-file-input';
import {Partner} from '~/src/app/shared/types/partners.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/internal/operators';
import {TimezoneService} from '~/src/app/modules/users/social-site-edit-dialog/timezone.service';
import {EmailNotificationManagerComponent} from '~/src/app/modules/users/profile/email-notification-manager/email-notification-manager.component';
import {IPartnerInfo} from '~/src/app/modules/administration/partners/partner.interface';
import {
    LINK_SHORTEN_TYPE_BITLY,
    LINK_SHORTEN_TYPE_OPTIONS
} from '~/src/app/modules/social-media-post/link-shortening.constant';
import {PartnerControlNames} from '~/src/app/modules/administration/partners/partner-manager/partner-manager.config';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import {MatSelect} from '@angular/material/select';
import {MatInput} from '@angular/material/input';

declare const $;

@Directive({selector: '[tabName]'})
export class ActiveTabDirective implements OnInit {
    @Input('activeTabName') activeTabName: string;
    @Input('tabName') tabName: string;
    @Input('disabled') set disabled(value: boolean) {
        if (this._disabled !== value) {
            this._disabled = value;

            if (value) {
                this.el.nativeElement.classList.add('disabled');
            } else {
                this.el.nativeElement.classList.remove('disabled');
            }
        }
    }
    _disabled = false;

    constructor(
        public el: ElementRef<HTMLElement>,
    ) {
    }

    ngOnInit(): void {
        if (!this._disabled) {
            if (this.tabName === this.activeTabName) {
                this.el.nativeElement.classList.add('active');
            }
        }
    }
}



@Component({
    selector: 'smd-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
    @ViewChild('partnerLogoInput', {static: false}) logoInput: FileInputComponent;
    orgLimit = 5;
    public upload: FileUploader = new FileUploader({
        url: USER_API_URLS.avatar,
        itemAlias: 'file',
        authToken: 'Bearer: ' + Token.getToken()
    });
    profileGeneralForm = new FormGroup({
        username: new FormControl(''),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required])
    });
    profilePasswordForm = new FormGroup({
        currentPassword: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required, ValidatorEqual('confirmPassword', 'password')])
    });
    profileSettingsForm = new FormGroup({
        emailNotification: new FormControl(''),
        twoFactorAuthentication: new FormControl(''),
        defaultTimeZone: new FormControl(null)
    });
    user: any = {};
    formErrorMessages: object = {};
    token = '';
    dialogRef: any;
    activeTabName = 'general';
    subs = [];
    partnerConfig = this.partnerService.getPartnerConfig('all', true, 'total');
    partnerData = {
        logo: NO_AVATAR_IMAGE_PATH,
        name: 'Partner name',
        packageName: 'Base package',
        bitlyToken: '',
        partnerLimitUsage: {},
        linkShorten: ''
    };
    showResultIcon: any = {
        logo: false,
        bitlyToken: false,
        linkShortener: false
    };
    partnerInfo = this.partnerService.getPartnerInfo(false) as IPartnerInfo;
    partnerLogoPending = false;
    bitlyTokenPending = false;
    linkShortenerPending = false;
    enablePartnerTab = false;
    getMePending = false;

    //  TODO temporary
    enablePartnerLogo = true;

    // example bitly token
    exampleBitlyToken = 'g9wsv3ezp3x3fzkx3gjcxts1rvkqsms0tonatbc7';

    // timezones
    timezones: string[] = [];

    // filtered timezones
    filteredTimezones: Observable<string[]> = this.profileSettingsForm.get('defaultTimeZone').valueChanges.pipe(
        startWith(''),
        map(value => this._timeZoneFilter(value))
    );

    linkShortenerOptions = LINK_SHORTEN_TYPE_OPTIONS;
    private _linkShortener = null;

    get linkShortener(): string {
        return this._linkShortener;
    }

    set linkShortener(value: string) {
        this._linkShortener = value;
        this.showBitlyTokenInput = this.linkShortener === LINK_SHORTEN_TYPE_BITLY;
    }

    showBitlyTokenInput = this.linkShortener === LINK_SHORTEN_TYPE_BITLY;
    showLinkShortenBlock = this.partnerConfigService.hasConfig(PartnerPermissions.LinkShortening);

    bitlyTokenFormControl = new FormControl(null, [Validators.required]);
    partnerIsActive: boolean = true;

    constructor(
        private auth: AuthService,
        private userService: UserService,
        public dialog: MatDialog,
        public activatedRoute: ActivatedRoute,
        public language: LanguageService,
        public partnerService: PartnersService,
        public partnerConfigService: PartnerConfigService,
        private router: Router,
        private openModal: OpenModalService,
        private _timezoneService: TimezoneService,
    ) {
        this.token = Token.getToken();

        this.refreshMe().finally(() => {
            this.partnerIsActive = !this.partnerService.getMyPartnerData().partnerDeleteRequested;
        });

        this.subs.push(this.activatedRoute.params.subscribe(data => {
            if (data.tab) {
                this.activeTabName = data.tab;
            }
        }));

        this.setUser();

        this._getTimezones();

        this.profileGeneralForm.controls['username'].setValue(Utils.lodash.get(this.user, 'username', '') || '');
        this.profileGeneralForm.controls['firstName'].setValue(this.user['firstName']);
        this.profileGeneralForm.controls['lastName'].setValue(this.user['lastName']);
        this.profileGeneralForm.controls['email'].setValue(this.user['email']);
        // this.profileSettingsForm.controls['emailApprovementNotification'].setValue(this.user['settings']['emailApprovementNotification']);
        this.profileSettingsForm.controls['emailNotification'].setValue(Utils.get(this.user, 'settings.emailNotification.enabled', null) === 'yes');
        this.profileSettingsForm.controls['twoFactorAuthentication'].setValue(this.user['settings']['twoFactorAuthentication']);
        this.profileSettingsForm.controls['defaultTimeZone'].setValue(
            LoggedUser.getUserDefaultTimeZoneName()
        );

        if (this.usernameIsEditable()) {
            this.profileGeneralForm.get('username').setValidators([Validators.required]);
        }

        this.subs.push(this.profileSettingsForm.controls['twoFactorAuthentication'].valueChanges.subscribe(value => {
            if (value) {
                this.set2FA();
            } else {
                this.onSettingsFormSubmit();
            }
        }));

        this.subs.push(this.profileSettingsForm.controls['emailNotification'].valueChanges.subscribe(value => {

            if (value) {
                this.user.settings = {
                    ...(this.user?.settings || {}),
                    emailNotification: {
                        ...(this.user?.settings?.emailNotification || {}),
                        enabled: 'yes'
                    }
                };
                this.openEmailNotificationManager();
            }

            this.onSettingsFormSubmit();
        }));

    }

    ngOnInit() {
        this.organizationAccord();
        this.upload.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.upload.onCompleteItem = this.onAvatarChanged.bind(this);

        this.subs.push(this.profileGeneralForm.valueChanges.subscribe((value) => {
            this.validateInput();
        }));

    }

    /**
     * Select timezone in autocomplete
     * @param {MatAutocompleteSelectedEvent} event
     */
    selectTimezone(event: MatAutocompleteSelectedEvent) {
        this.onSettingsFormSubmit();
    }

    refreshMe() {
        this.getMePending = true;
        return Promise.all([
            this.auth.refreshLoggedUserData().then(() => {
                this.enablePartnerTab = true;
                
                this.setUser();
                this.partnerConfig = this.partnerService.getPartnerConfig('all', true, 'total');
                this.partnerData = {
                    logo: this.partnerService.getMyPartnerData().partnerLogo || NO_AVATAR_IMAGE_PATH,
                    name: this.partnerService.getMyPartnerData().partnerName,
                    packageName: this.partnerService.getMyPartnerData().subscriptionName,
                    bitlyToken: this.partnerService.getMyPartnerData().bitlyToken,
                    partnerLimitUsage: this.partnerService.getMyPartnerData().partnerLimitUsage,
                    linkShorten: this.user.partnerData?.linkShorten || LINK_SHORTEN_TYPE_BITLY,
                };
                this.bitlyTokenFormControl.setValue(this.partnerData?.bitlyToken || null);
                this.linkShortener = this.partnerData.linkShorten;

                this.getMePending = false;
                return Promise.resolve();
            }),
            this.partnerService.getPartnerInfo()
        ]).catch(() => {
            this.getMePending = false;
        });
    }

    usernameIsEditable(): boolean {
        return this.user.username === this.user.email;
    }

    organizationAccord() {
        $('.profil-organization-list').on('click', 'li', function () {
            $('.profil-organization-list').find('ul').not($(this).find('ul')).hide();

            $(this).find('ul').slideToggle();
        });
    }

    onGeneralFormSubmit() {
        event.preventDefault();
        const profileChangeForm = this.profileGeneralForm;

        this.formErrorMessages = {};

        if (profileChangeForm.valid) {

            this.userService.setGeneralData(profileChangeForm.getRawValue()).subscribe(response => {
                NotifyService.success(
                    LanguageService.getLine('profile.generalform.save.success.title'),
                    LanguageService.getLine('profile.generalform.save.success.message')
                );

                const userData = LoggedUser.getUser();
                userData['username'] = profileChangeForm.controls['username'].value;
                userData['firstName'] = profileChangeForm.controls['firstName'].value;
                userData['lastName'] = profileChangeForm.controls['lastName'].value;
                userData['email'] = profileChangeForm.controls['email'].value;
                userData['fullName'] = userData['firstName'] + ' ' + userData['lastName'];

                this.user = userData;
                LoggedUser.saveUser(userData);

            }, response => {

                NotifyService.error(
                    LanguageService.getLine('profile.generalform.save.fail.title'),
                    FormValidationService.readError(response).message || LanguageService.getLine('profile.generalform.save.error.message')
                );

                this.formErrorMessages = FormValidationService.setFormControlsIncorrect(response, this.profileGeneralForm, this.formErrorMessages);

            });
        } else {
            this.formErrorMessages = FormValidationService.getMessages(profileChangeForm.controls);
        }
    }

    onPasswordFormSubmit(event: any) {
        // /api/
        event.preventDefault();

        this.formErrorMessages = {};

        const passChangeForm = this.profilePasswordForm;

        if (passChangeForm.valid) {
            this.auth.passwordChange(passChangeForm.getRawValue()).subscribe(response => {
                passChangeForm.reset();
                NotifyService.success(LanguageService.getLine('profile.password.save.success.title'), response['data']['message']);
            }, error => {
                NotifyService.error(LanguageService.getLine('profile.password.save.fail.title'), FormValidationService.readError(error).message);
                this.formErrorMessages = FormValidationService.setFormControlsIncorrect(error, this.profilePasswordForm, this.formErrorMessages);
            });
        } else {
            this.formErrorMessages = FormValidationService.getMessages(passChangeForm.controls);
        }
    }

    onSettingsFormSubmit() {
        event.preventDefault();

        this.formErrorMessages = {};
        const profileSettingForm = this.profileSettingsForm;
        if (profileSettingForm.valid) {
            const values = profileSettingForm.getRawValue();
            const emailNotificationSetting = values.emailNotification;
            let userSettings = this.user.settings || {};

            userSettings = {
                ...userSettings,
                emailNotification: {
                    ...(userSettings.emailNotification || {}),
                    enabled: emailNotificationSetting ? 'yes' : 'no',
                }
            };
            values.emailNotification = userSettings.emailNotification;

            this.userService.setEmailNotification({'settings': JSON.stringify(values)}).subscribe(response => {
                NotifyService.success(
                    LanguageService.getLine('profile.settings.save.success.title'),
                    LanguageService.getLine('profile.settings.save.success.message')
                );

                const userData = LoggedUser.getUser();
                userData['settings']['emailNotification'] = {
                    ...(userData['settings']['emailNotification'] || {}),
                    enabled: emailNotificationSetting ? 'yes' : 'no'
                };
                // userData['settings']['emailApprovementNotification'] = values.emailApprovementNotification;

                if (values.twoFactorAuthentication === false) {
                    userData['settings']['twoFactorAuthentication'] = false;
                }

                userData['settings']['defaultTimeZone'] = Utils.lodash.get(values, 'defaultTimeZone', null);

                this.user = userData;
                LoggedUser.saveUser(userData);

            }, error => {
                NotifyService.error(
                    LanguageService.getLine('profile.settings.save.fail.title'),
                    FormValidationService.readError(error).message
                );
            });
        }
    }

    set2FA() {
        const currentToken = Token.getToken();
        const currentTokenExpires = Token.getExpires();
        this.auth.twoFactorAuthSetup().subscribe(response => {
            this.dialogRef = this.dialog.open(TwoFactorDialogComponent, {
                data: response['twoFactorAuthenticate']
            });

            this.dialogRef.afterClosed().subscribe(result => {
                if (!result || result.result === 0) {
                    result = {
                        'result': false
                    };
                }

                this.profileSettingsForm.controls['twoFactorAuthentication'].setValue(result.result, {
                    'emitEvent': false
                });

                const userData = LoggedUser.getUser();
                userData['settings']['twoFactorAuthentication'] = result.result;

                this.user = userData;
                LoggedUser.saveUser(userData);

                if (result.result === 0) {
                    Token.saveToken(currentToken, currentTokenExpires);
                }

                this.onSettingsFormSubmit();
            });

        }, error => {
            NotifyService.error(
                LanguageService.getLine('profile.twofa.save.fail.title'),
                FormValidationService.readError(error).message || LanguageService.getLine('profile.twofa.save.fail.message')
            );
        });
    }

    onProfileRemoveSubmit(event) {
        event.preventDefault();

        this.formErrorMessages = {};
        this.dialog.open(DialogConfirmComponent, {
            data:
                {
                    message: LanguageService.getLine('profile.profileremove.confirm.title'),
                    yesButtonText: LanguageService.getLine('profile.suspension.confirmDelete')
                }
        }).afterClosed().subscribe(doIt => {
            if (doIt) {
                this.userService.removeProfile({'status': 'inactive'}).subscribe(response => {
                    NotifyService.success(LanguageService.getLine('profile.profileremove.logout'), '');
                    this.auth.resetUserData();
                    this.router.navigateByUrl('/login');
                }, error => {
                    NotifyService.error(
                        LanguageService.getLine('profile.profileremove.fail.title'),
                        FormValidationService.readError(error).message
                    );
                });
            }
        });
    }

    onAvatarChange(event) {

        // delete previous file validation error message
        if (Utils.lodash.has(this.formErrorMessages, 'file')) {
            delete this.formErrorMessages['file'];
        }

        // upload new avatar
        this.upload.uploadAll();
    }

    onAvatarChanged(item: any, response: any, status: any, headers: any) {

        // convert response to JSON
        try {
            response = {
                error: JSON.parse(response)
            };
        } catch (e) {}

        // set validation error messages from backend
        this.formErrorMessages = FormValidationService.readError(response).formMessages || {};

        if (status === 200) {
            this.auth.getMe().subscribe(_response => {
                LoggedUser.saveUser(_response['user']);
                NotifyService.success(
                    LanguageService.getLine('profile.avatar.save.success.title'),
                    LanguageService.getLine('profile.avatar.save.success.message')
                );
            });
        } else {
            NotifyService.error(
                LanguageService.getLine('profile.avatar.save.fail.title'),
                FormValidationService.readError(response).message || ''
            );
        }
    }

    @HostListener(`document:${CustomEvents.USER_UPDATE}`, ['$event'])
    setUser() {
        this.user = LoggedUser.getUser();
    }

    limitUp(event) {
        event.preventDefault();

        if (this.orgLimit > 0) {
            this.orgLimit += 5;
        } else {
            this.orgLimit = 5;
        }
    }

    validateInput(formGroup?: FormGroup): void {
        formGroup = formGroup || this.profileGeneralForm;
        this.formErrorMessages = FormValidationService.getMessages(formGroup.controls);
    }

    ngOnDestroy() {
        this.subs.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Delete my partner
     * @param {MouseEvent} event
     */
    partnerDelete(event: MouseEvent) {
        return;
        event.preventDefault();
        event.stopPropagation();

        this.openModal.confirmModal(DialogConfirmComponent, {
            message: this.language.getLine('partner.info.delete.confirmMessage'),
            yesButtonText: this.language.getLine('partner.info.delete.yesButtonText'),
        }).afterClosed().subscribe(doIt => {
            if (!!doIt) {
                const loader = this.openModal.loader(DialogLoaderComponent);

                this.partnerService.deleteRequest('delete').then(() => {
                    this.refreshMe().finally(() => {
                        LoggedUser.saveUser({
                            ...LoggedUser.getUser(),
                            partnerDeleteRequested: true
                        });

                        loader.afterClosed().subscribe(() => {
                            this.openModal.successModal(DialogSuccessComponent, {
                                message: this.language.getLine('partner.info.delete.successMessage')
                            });
                        });

                        this.partnerIsActive = !this.partnerService.getMyPartnerData().partnerDeleteRequested;

                        loader.close();
                    });
                }).catch(errorResponse => {
                    loader.afterClosed().subscribe(() => {
                        this.openModal.errorModal(DialogErrorComponent, {
                            message: FormValidationService.readError(errorResponse).message || this.language.getLine('partner.list.action.delete.error')
                        });
                    });

                    loader.close();
                });
            }
        });
    }

    /**
     * Activate my partner
     * @param {MouseEvent} event
     */
    partnerActivate(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const loader = this.openModal.loader(DialogLoaderComponent);

        this.partnerService.deleteRequest('revert').then(() => {
            this.refreshMe().finally(() => {
                LoggedUser.saveUser({
                    ...LoggedUser.getUser(),
                    partnerDeleteRequested: false
                });

                loader.afterClosed().subscribe(() => {
                    this.openModal.successModal(DialogSuccessComponent, {
                        message: this.language.getLine('partner.info.activate.successMessage')
                    });
                });

                this.partnerIsActive = !this.partnerService.getMyPartnerData().partnerDeleteRequested;

                loader.close();
            });
        }).catch(errorResponse => {
            loader.afterClosed().subscribe(() => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message: FormValidationService.readError(errorResponse).message || this.language.getLine('partner.list.action.activate.error')
                });
            });
        });
    }

    fileUploadClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.logoInput.open();
    }

    partnerDataChange(event: MouseEvent = null, type: 'logo' | 'bitlyToken' | 'linkShortener', value?: any) {
        if (!!event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const hideIcon = () => {
            setTimeout(() => {
                this.showResultIcon = {
                    logo: false,
                    bitlyToken: false,
                    linkShortener: false
                };
            }, 2500);
        };
        const partnerData = {};

        switch (type) {
            case 'logo':
                value = this.logoInput.value;
                partnerData['logo'] = Utils.lodash.get(value, 'files[0]', null);

                break;

            case 'bitlyToken':
                partnerData['bitlyToken'] = value || '';

                break;

            case 'linkShortener':
                partnerData[PartnerControlNames.LinkShortener] = value || '';

                if (value === LINK_SHORTEN_TYPE_BITLY) {
                    if (this.bitlyTokenFormControl.invalid) {
                        return;
                    } else {
                        partnerData['bitlyToken'] = this.bitlyTokenFormControl.value;
                    }
                }

                break;
        }

        if (!!value || value === '') {

            if (type === 'logo') {
                this.partnerLogoPending = true;
            }
            if (type === 'bitlyToken') {
                this.bitlyTokenPending = true;
            }
            if (type === 'linkShortener') {
                this.linkShortenerPending = true;
            }

            this.partnerService.editPartner({
                partnerID: this.partnerService.getMyPartnerData().partnerID,
                name: /* this.partnerService.getMyPartnerData().partnerName, */ this.user.partnerName || '',
                partnerData: partnerData,
                partnerConfig: Utils.lodash.get(LoggedUser.getUser(), 'partnerConfig.manual', null)
            } as Partner).then(() => this.refreshMe()).then(() => {
                if (type === 'logo') {
                    this.showResultIcon.logo = {
                        success: true
                    };

                    this.showResultIcon.bitlyToken = false;
                    this.showResultIcon.linkShortener = false;

                    this.partnerLogoPending = false;
                }

                if (type === 'bitlyToken') {
                    this.showResultIcon.bitlyToken = {
                        success: true
                    };

                    this.showResultIcon.logo = false;
                    this.showResultIcon.linkShortener = false;

                    this.bitlyTokenPending = false;
                }

                if (type === 'linkShortener') {
                    this.showResultIcon.linkShortener = {
                        success: true
                    };

                    this.showResultIcon.logo = false;
                    this.showResultIcon.bitlyToken = false;

                    this.linkShortenerPending = false;
                }

                hideIcon();
            })
            .catch(() => {
                if (type === 'logo') {
                    this.showResultIcon.logo = {
                        failed: true
                    };
                    this.showResultIcon.bitlyToken = false;
                    this.showResultIcon.linkShortener = false;

                    this.partnerLogoPending = false;
                }

                if (type === 'bitlyToken') {
                    this.showResultIcon.bitlyToken = {
                        failed: true
                    };
                    this.showResultIcon.logo = false;
                    this.showResultIcon.linkShortener = false;

                    this.bitlyTokenPending = false;
                }

                if (type === 'linkShortener') {
                    this.showResultIcon.linkShortener = {
                        failed: true
                    };
                    this.showResultIcon.logo = false;
                    this.showResultIcon.bitlyToken = false;

                    this.linkShortenerPending = false;
                }

                hideIcon();
            });
        }
    }

    /**
     * Manage E-mail Notifications
     */
    public openEmailNotificationManager(event?: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.openModal.manageEmailNotification(EmailNotificationManagerComponent, this.user);
    }

    isBoolean(value: any): boolean {
        return Utils.lodash.isBoolean(value);
    }

    isNumber(value: any): boolean {
        return Utils.lodash.isNumber(value);
    }

    get noAvatarSrc() {
        return NO_AVATAR_IMAGE_PATH;
    }

    /**
     * Timezones filter method
     * @param {string} value
     * @returns {string[]}
     * @private
     */
    private _timeZoneFilter(value: string): string[] {
        const filterValue = value.toLowerCase();

        if (!this.timezones || !(this.timezones instanceof Array)) {
            return [];
        }

        return this.timezones.filter(option => option.toLowerCase().includes(filterValue));
    }

    /**
     * Get timezones
     * @private
     */
    private _getTimezones() {
        this._timezoneService.getAll().then(response => {
            this.timezones = response;
        });
    }
}
