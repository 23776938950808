import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';

export const WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_OVERVIEW: WidgetMetricOptionType = {
    id: 'linkedin-impressions-overview',
    name: 'Impressions overview',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_TOTAL: WidgetMetricOptionType = {
    id: 'linkedin-impressions-total',
    name: 'Impressions: Total',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'linkedIn'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_UNIQUE: WidgetMetricOptionType = {
    id: 'linkedin-impressions-unique',
    name: 'Impressions: Unique',
    resource: 'analytics.analytics.pageImpressions',
    socialChannel: 'linkedIn'
};

// METRIC CONFIGS

export const MetricLinkedInImpressionsOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_OVERVIEW.id,
    apiURL: '/page-impressions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Impressions overview',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricLinkedInImpressionsTotalConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_TOTAL.id,
    apiURL: '/page-impressions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Impressions (total)',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        unique: 'no'
    }
};

export const MetricLinkedInImpressionsUniqueConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_IMPRESSIONS_UNIQUE.id,
    apiURL: '/page-impressions',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Impressions (unique)',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        unique: 'yes'
    }
};
