import { NgModule } from "@angular/core";
import { SharedModule } from "~/src/app/shared/shared.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LanguagesModule } from "~/src/app/modules/languages/languages.module";
import { CommonModule } from "@angular/common";
import { EditorModule } from "@tinymce/tinymce-angular";
import { DocumentsModule } from "~/src/app/modules/documents/documents.module";
import { PostSkeletonComponent } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.component";
import { NewsFeedTargetingFacebookComponent } from "~/src/app/modules/social-media-post/news-feed-targeting-facebook/news-feed-targeting-facebook.component";
import { NewsFeedTargetingLinkedinComponent } from "~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.component";
import { MentionListComponent } from "./mention-list/mention-list.component";
import { GmbModule } from "../gmb/gmb.module";
import { GmbPostSkeletonComponent } from "./gmb-post-skeleton.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EditorModule,
        LanguagesModule,
        DocumentsModule,
        InfiniteScrollModule,
        GmbModule,
    ],
    declarations: [
        PostSkeletonComponent,
        GmbPostSkeletonComponent,
        NewsFeedTargetingFacebookComponent,
        NewsFeedTargetingLinkedinComponent,
        MentionListComponent,
    ],
    exports: [
        PostSkeletonComponent,
        GmbPostSkeletonComponent,
        NewsFeedTargetingFacebookComponent,
        NewsFeedTargetingLinkedinComponent,
        MentionListComponent,
    ],
})
export class PostSkeletonModule {}
