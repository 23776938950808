import { IRelatedUser } from './../../users/users/users.interface';
import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, Output, ViewEncapsulation } from "@angular/core";
import { CommentForViewInterface } from "~/src/app/modules/posts/comments/post-comment/interfaces";
import { PostCommentForView } from "~/src/app/modules/posts/comments/post-comment/post-comment-for-view";
import { Helpers, LoggedUserInterface } from "~/src/app/services/helpers";
import { CommentController } from "~/src/app/modules/posts/comments/post-comment/comment-controller.service";
import { Subject } from "rxjs";
import { ConsoleLoggerService } from "~/src/app/shared/services/log/console-logger.service";
import { LoggedUser } from "~/src/app/services/logged-user";
import Utils from '~/src/app/core/utils';
import {DateSupport} from '~/src/app/core/helper/date-support';
import { NotificationService } from "../../notification-menu/notification.service";
import { CacheService } from "~/src/app/services/cache.service";
import { UsersService } from "../../users/users/users.service";

@Component({
    selector: "smd-comments",
    templateUrl: "./comments.component.html",
    styleUrls: ["./comments.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CommentsComponent implements OnInit, OnChanges {
    @Input() objectType;
    @Input() objectID;
    @Input() newComment: Subject<string>;
    @Input() feedView: boolean = false;
    @Input() feedViewComments: CommentForViewInterface[] = null;
    @Input() relatedUsers: Array<IRelatedUser>;
    @Output() successCreateComment = new EventEmitter();
    @Output() failedCreateComment = new EventEmitter();
    getCommentInProgress = false;
    createCommentInProgress = false;
    comments: CommentForViewInterface[] = [];
    user: LoggedUserInterface = LoggedUser.getUser();
    lastRefreshTime: number = 0;

    constructor(
        private commentController: CommentController,
        private consoleLogger: ConsoleLoggerService,
        private notificationService: NotificationService,
        private cacheService: CacheService,
        private usersService: UsersService,
    ) {
        notificationService.commentNotificationEvent.subscribe(objectID => this.onNewComment(objectID));
    }

    ngOnInit() {
        this.initializeOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.feedViewComments && changes.feedViewComments.currentValue && this.feedViewComments) || (changes.relatedUsers && changes.relatedUsers.currentValue && this.feedViewComments)) {
            this.comments = this.feedViewComments.filter((comment) => {
                return comment.objectID == this.objectID;
            }).map((comment) => {
                let textWithMentions = comment.text;

                textWithMentions = textWithMentions.replace(/<[^>]*>/g, '');
                
                if (this.relatedUsers.length) {
                    this.relatedUsers.forEach((user) => {
                        textWithMentions = textWithMentions.replace(
                            new RegExp(`##${user.userID}##`, 'g'),
                            `<span class="mention ${user.userID == this.user.userID ? 'mention-you' : 'mention-other'}">@${user.name}</span>`
                        );
                        // if there is no space before or after the mention, add one
                        textWithMentions = textWithMentions.replace(
                            new RegExp(`@${user.name}([^\\s])`, 'g'),
                            `@${user.name} $1`
                        );
                        textWithMentions = textWithMentions.replace(
                            new RegExp(`([^\\s])@${user.name}`, 'g'),
                            `$1 @${user.name}`
                        );
                    });
                }

                textWithMentions = textWithMentions.replace(/##\d+##/g, (match) => {
                    return `<span class="mention mention-unknown">@Unknown user</span>`;
                });

                comment.renderHelper = {
                    timeAgo: Utils.moment.tz(comment.createDate, 'UTC').fromNow(),
                    timeForUser: Utils.moment.tz(Utils.moment.tz(comment.createDate, 'UTC'), LoggedUser.getUser().settings.defaultTimeZone || DateSupport.getClientTimeZoneName()),
                    textWithMentions: textWithMentions,
                }
                return comment;
            });
        }
    }

    /**
     * Get comments
     * @param {object} filterParams
     */
    getComments(filterParams: object = {}): void {
        if (this.getCommentInProgress) {
            return;
        }

        // fake refresh so user can see the loading interaction on scroll-up every time
        // but we save resources by not allowing it to happen more than once every 10 seconds
        if (this.lastRefreshTime > Date.now() - 10000) {
            this.getCommentInProgress = true;
            setTimeout(() => {
                this.getCommentInProgress = false;
            }, 500); 
            return;
        }

        this.getCommentInProgress = true;

        filterParams = {
            //limit: 10,
            //offset: this.comments.length,
            limit: 1000,
            offset: 0,
            order: Helpers.getOrderFormat("createDate-desc"),
            objectType: this.objectType,
            ...filterParams,
        };

        const objectID = this.objectID;

        this.commentController.getComments(
            objectID,
            filterParams,
            (comments) => {
                this.comments = comments.map((comment) => {
                    let textWithMentions = comment.text;

                    textWithMentions = textWithMentions.replace(/<[^>]*>/g, '');
                    
                    if (this.relatedUsers.length) {
                        this.relatedUsers.forEach((user) => {
                            textWithMentions = textWithMentions.replace(
                                new RegExp(`##${user.userID}##`, 'g'),
                                `<span class="mention ${user.userID == this.user.userID ? 'mention-you' : 'mention-other'}">@${user.name}</span>`
                            );
                            // if there is no space before or after the mention, add one
                            textWithMentions = textWithMentions.replace(
                                new RegExp(`@${user.name}([^\\s])`, 'g'),
                                `@${user.name} $1`
                            );
                            textWithMentions = textWithMentions.replace(
                                new RegExp(`([^\\s])@${user.name}`, 'g'),
                                `$1 @${user.name}`
                            );
                        });
                    }

                    textWithMentions = textWithMentions.replace(/##\d+##/g, (match) => {
                        return `<span class="mention mention-unknown">@Unknown user</span>`;
                    });

                    comment.renderHelper = {
                        timeAgo: Utils.moment.tz(comment.createDate, 'UTC').fromNow(),
                        timeForUser: Utils.moment.tz(Utils.moment.tz(comment.createDate, 'UTC'), LoggedUser.getUser().settings.defaultTimeZone || DateSupport.getClientTimeZoneName()),
                        textWithMentions: textWithMentions,
                    }
                    return comment;
                });

                this.getCommentInProgress = false;
            },
            (error) => {
                this.getCommentInProgress = false;
            }
        );

        this.lastRefreshTime = Date.now();
    }

    /**
     * Create comment
     * @param {string} comment
     */
    public createComment(comment: string): void {
        const id = this.objectID;

        if (comment && comment !== "" && id) {
            this.createCommentInProgress = true;

            this.commentController.service.objectType = this.objectType;
            this.commentController.createComment(
                id,
                comment,
                (response) => {
                    const resultComment = response.comment;
                    this.createCommentInProgress = false;

                    let textWithMentions = resultComment.text;

                    textWithMentions = textWithMentions.replace(/<[^>]*>/g, '');
                    
                    if (this.relatedUsers.length) {
                        this.relatedUsers.forEach((user) => {
                            textWithMentions = textWithMentions.replace(
                                new RegExp(`##${user.userID}##`, 'g'),
                                `<span class="mention ${user.userID == this.user.userID ? 'mention-you' : 'mention-other'}">@${user.name}</span>`
                            );
                            // if there is no space before or after the mention, add one
                            textWithMentions = textWithMentions.replace(
                                new RegExp(`@${user.name}([^\\s])`, 'g'),
                                `@${user.name} $1`
                            );
                            textWithMentions = textWithMentions.replace(
                                new RegExp(`([^\\s])@${user.name}`, 'g'),
                                `$1 @${user.name}`
                            );
                        });
                    }

                    textWithMentions = textWithMentions.replace(/##\d+##/g, (match) => {
                        return `<span class="mention mention-unknown">@Unknown user</span>`;
                    });

                    const viewComment: CommentForViewInterface = {
                        ...resultComment,
                        profileImage: this.user.profileImageUrl,
                        userName: this.user.fullName,
                        renderHelper: {
                            timeAgo: Utils.moment.tz(resultComment.createDate, 'UTC').fromNow(),
                            timeForUser: Utils.moment.tz(Utils.moment.tz(resultComment.createDate, 'UTC'), LoggedUser.getUser().settings.defaultTimeZone || DateSupport.getClientTimeZoneName()),
                            textWithMentions: textWithMentions,
                        }
                    };

                    this.comments.unshift(new PostCommentForView(viewComment));
                    this.emitSuccessCreateComment(response);
                },
                (error) => {
                    console.error(error);
                    this.createCommentInProgress = false;
                    this.emitFailedCreateComment(id);
                }
            );
        }
    }

    /**
     * Comments scrolled event
     */
    commentsScrolled() {
        this.cacheService.invalidateWithPart(`objectID=${this.objectID}`); // invalidate comments cache when forcefully refreshing
        this.getComments();
    }

    /**
     * Initialize on init
     */
    private initializeOnInit() {
        if (this.newComment) {
            this.newComment.subscribe((comment) => {
                if (comment && comment !== "") {
                    this.createComment(comment);
                } else {
                    this.emitFailedCreateComment(
                        "Please, write a comment message!"
                    );
                    this.consoleLogger.warn("Please, write a comment message!");
                }
            });
        }

        if (this.objectType && this.objectID && !this.feedView) {
            this.usersService.getObjectRelatedUsers(this.objectType, [this.objectID])
                .then((response) => {
                    this.relatedUsers = response.usersForObject[this.objectID];
                    this.getComments();
                })
                .catch(() => {
                    // get comments anyways lmao
                    this.getComments();
                });
        }
    }

    /**
     * Success create comment emit
     * @param response
     */
    private emitSuccessCreateComment(response) {
        this.successCreateComment.emit(response);
    }

    /**
     * Failed create comment emit
     * @param id
     */
    private emitFailedCreateComment(id) {
        this.failedCreateComment.emit(id);
    }

    onNewComment(objectID: number) {
        if (objectID == this.objectID) {
            this.getComments();
        }
    }
}
