import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatInput} from '@angular/material/input';
import {AuthService} from '../../../auth/auth.service';

export interface TwoFactorDialogData {
    qrCodeImageUrl: string;
    secret: string;
}

@Component({
    selector: 'smd-two-factor-dialog',
    templateUrl: './two-factor-dialog.component.html',
    styleUrls: ['./two-factor-dialog.component.scss']
})
export class TwoFactorDialogComponent implements OnInit {

    qrCodeImageUrl: string;
    secret: string;

    message: object = {};

    @ViewChild('authCode') authCode: MatInput;

    constructor(
        public dialogRef: MatDialogRef<TwoFactorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TwoFactorDialogData,
        private auth: AuthService
    ) {
    }

    onNoClick(): void {
        // result 0 ha declined
        this.dialogRef.close({'result': 0});
    }

    onYesClick(): void {
        this.auth.twoFactorVerify({'verificationCode': this.authCode.value}).subscribe(response => {
            if (response.result === 'OK') {
                this.dialogRef.close({'result': 1});
            } else {
                this.message['authCode'] = 'Auth code cannot be verified.';
            }
        }, error => {
            this.message['authCode'] = 'Auth code cannot be verified. Server responded with error.';
        });
    }

    ngOnInit() {
    }

}
