import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BulkUploadManager } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager";
import { BulkUploadManagerModel } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.model";
import { ViewChild } from "~/node_modules/@angular/core";
import { FileInputComponent } from "~/node_modules/ngx-material-file-input";
import { BulkUploadManagerService } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { LanguageService } from "~/src/app/services/language.service";
import Utils from "~/src/app/core/utils";
import { PostPreviewComponent } from "~/src/app/modules/posts/post-previews/post-preview/post-preview.component";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import {
    FormControl,
    FormGroup,
    Validators,
} from "~/node_modules/@angular/forms";
import { OrganizationController } from "~/src/app/components/organization-select/organization.service";
import { FormHelpersService } from "~/src/app/core/services/form-helpers";
import { Configs } from "~/src/app/configs/configs";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { ItemEditorComponent } from "~/src/app/modules/posts/bulk-upload/item-editor/item-editor.component";
import { PaginationController } from "~/src/app/services/pagination.controller";
import { BehaviorSubject } from "~/node_modules/rxjs";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { Helpers } from "~/src/app/services/helpers";
import { PostActionsService } from "~/src/app/modules/posts/post-actions.service";
import { BulkImportItemModel } from "~/src/app/modules/posts/bulk-import-item.model";
import { TemplateActionsService } from "~/src/app/modules/posts/template-actions.service";
import { FileBrowserService } from "~/src/app/services/file-browser.service";
import { MatDialogRef } from "@angular/material/dialog";
import { BulkImportPostTemplateItemModel } from "~/src/app/modules/posts/bulk-import-post-template-item.model";
import { BulkImportPostItemModel } from "~/src/app/modules/posts/bulk-import-post-item.model";
import { DraftType } from "~/src/app/modules/posts/post-actions";
import { ArraySupport } from "~/src/app/core/helper/array-support";
import { SmdFile, SmdFileInterface } from "~/src/app/services/file.class";
import { NgSelectComponent } from "@ng-select/ng-select";
import {
    SOCIAL_MEDIA_TYPE_GMB,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_TWITTER,
} from "~/src/app/core/constants";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import * as getUrls from "get-urls";
import {
    OPEN_GRAPH_TYPE_OG,
    OPEN_GRAPH_TYPE_TWITTER,
    UrlOpenGraphService,
} from "~/src/app/services/urlopengraph.service";
import { PostFormControlNames } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { socialSiteAppearance } from "../../../social-media-post/social-media-platforms-config";

@Component({
    selector: "smd-bulk-upload-manager",
    templateUrl: "./bulk-upload-manager.component.html",
    styleUrls: ["./bulk-upload-manager.component.scss"],
    providers: [
        ComponentHelpers,
        FormHelpersService,
        OrganizationController,
        SocialSiteController,
    ],
})
export class BulkUploadManagerComponent implements OnInit {
    /**
     * Import file input
     */
    @ViewChild("fileInput", { static: true }) fileInput: FileInputComponent;

    /**
     * Social site select
     */
    @ViewChild("socialSiteSelect", { static: true })
    socialSiteSelect: NgSelectComponent;

    /**
     * Entity type (bulk upload manager mode)
     */
    entityType: string;

    /**
     * Entity type is post
     */
    isPostEntity: boolean;

    /**
     * Entity type is template
     */
    isTemplateEntity: boolean;

    /**
     * Entity raw items
     *
     * @type {BulkUploadManager.RawItem[]}
     */
    rawItems: BulkImportItemModel[] = [];

    /**
     * Organization items
     *
     * @type {any[]}
     */
    organizations = [];

    /**
     * Social types
     *
     * @type {({name: string; id: string} | {name: string; id: string})[] | {name: string; id: string}}
     */
    platforms = Configs.socials;

    /**
     * Common form group
     */
    commonFormGroup: FormGroup;

    /**
     * Common form control names
     *
     * @type {{Organizations: string; SocialType: string}}
     */
    commonControlNames = {
        Organizations: "organizationIDs",
        SocialType: "socialType",
        SocialSites: "socialSiteIDs",
    };

    /**
     * Pagination controller
     *
     * @type {PaginationController}
     */
    paginatorController: PaginationController = new PaginationController();

    /**
     * View import items
     *
     * @type {BehaviorSubject<BulkUploadManager.ViewImportItem[]>}
     */
    viewItems: BehaviorSubject<BulkImportItemModel[]> = new BehaviorSubject<
        BulkImportItemModel[]
    >([]);

    /**
     * Social site items
     *
     * @type {any[]}
     */
    socialSites = [];

    /**
     * @type {any[]}
     */
    selectedSocialSites = [];

    /**
     * Import button disable state
     *
     * @type {boolean}
     */
    importBtnIsDisabled = false;

    /**
     * Cancel import disable state
     *
     * @type {boolean}
     */
    cancelBtnIsDisabled = false;

    /**
     * Check was extract request
     *
     * @type {boolean}
     */
    wasExtractRequest = false;

    /**
     * Uploaded media items (not in cache)
     *
     * @type {any[]}
     */
    mediaItems: SmdFileInterface[] = [];

    /**
     * @type {{Organization: string; Message: string; LinkShareImageURL: string; ExpiryEndDate: string; SystemType: string; CurrentPostContentHash: string; Medias: string; LinkShareTitle: string; SeparateRepeat: string; Headline: string; AutoFeed: string; SubHeadline: string; IsCopy: string; ExpiryPeriod: string; Tags: string; Comment: string; Categories: string; SocialSite: string; ActiveFrom: string; ActiveFromMinute: string; LinkShareURL: string; ActiveFromHour: string; Partners: string; LinkShareDescription: string; Signature: string; SocialType: string; ActiveFromDate: string; OriginPostContentHash: string; LinkShareIncluded: string; FeedTargetingOptions: string}}
     */
    fieldNames = { ...PostFormControlNames };

    /**
     * @type {string[]}
     */
    excludedFields = [];

    /**
     * @type {string[]}
     */
    twitterExcludedFields = [
        PostFormControlNames.Headline,
        PostFormControlNames.SubHeadline,
        PostFormControlNames.Signature,
    ];

    instagramExcludeFields = [PostFormControlNames.SubHeadline];

    gmbExcludedFields = [
        PostFormControlNames.SubHeadline,
        PostFormControlNames.Signature,
    ];
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dialogData: BulkUploadManager.DialogData,
        public componentHelpers: ComponentHelpers,
        public formHelpers: FormHelpersService,
        public language: LanguageService,
        public organizationController: OrganizationController,
        public socialSiteController: SocialSiteController,
        private _dialogRef: MatDialogRef<BulkUploadManagerComponent>,
        private _bulkUploadManagerService: BulkUploadManagerService,
        private _openModal: OpenModalService,
        private _postService: PostActionsService,
        private _templateService: TemplateActionsService,
        private _fileBrowserService: FileBrowserService,
        private _ogService: UrlOpenGraphService
    ) {
        this.entityType = this.dialogData.entityType;

        this.isPostEntity =
            this.entityType === BulkUploadManagerModel.ENTITY_TYPES.Post;
        this.isTemplateEntity =
            this.entityType === BulkUploadManagerModel.ENTITY_TYPES.Template;

        // get templates on pagination change
        this.paginatorController.onPaginationChange.subscribe(() => {
            this._setViewItems();
        });

        this._initOrganizations();
        this._initCommonFormGroup();
    }

    ngOnInit() {
        this.platforms = this.platforms.filter(
            (item) => socialSiteAppearance?.[item.id]?.postmanager !== false
        );
    }

    /**
     * Import from file button click
     *
     * @param {MouseEvent} event
     */
    importBtnClick(event: MouseEvent) {
        event.preventDefault();

        if (this.commonFormGroup.invalid) {
            this.formHelpers.validateForm(this.commonFormGroup);

            return;
        }

        this.fileInput.open();
    }

    /**
     * Download sample button click
     *
     * @param {MouseEvent} event
     * @param {"general" | "twitter"} platform
     */
    downloadSampleBtnClick(
        event: MouseEvent,
        platform: "general" | "instagram" | "twitter" | "gmb" = "general"
    ) {
        event.preventDefault();

        this.componentHelpers.startApiAction(
            () =>
                this._bulkUploadManagerService.downloadSample(
                    this.isPostEntity ? "post" : "template",
                    platform
                ),
            {
                successMessageKey: this.isPostEntity
                    ? "bulk.upload.download.postSample.success"
                    : "bulk.upload.download.templateSample.success",
                failedMessageKey: this.isPostEntity
                    ? "bulk.upload.download.postSample.error"
                    : "bulk.upload.download.templateSample.error",
            }
        );
    }

    /**
     * Delete all post entity
     *
     * @param {MouseEvent} event
     */
    cancelImportBtnClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this._cancelImport(true);
    }

    /**
     * Save button click event
     *
     * Itt nincs common form validáció, mert ha nincs valid import item a listába,
     * megszakítja a lefutást. Import item viszont csak akkor van, ha sikeresen extract-olt egy fájlt,
     * amihez viszont kötelező kitölteni a common form-ot, utána pedig le van tíltva a módosítási lehetőség.
     *
     * @param {MouseEvent} event
     * @param {boolean} approve should the status be approved immediately
     */
    saveBtnClick(event: MouseEvent, approve: boolean = false) {
        event.preventDefault();
        event.stopPropagation();

        const entities = this.rawItems.filter((item) => !item.hasError);

        if (!entities.length) {
            return;
        }

        this.componentHelpers.startApiAction(
            () => {
                const maxItemLimit =
                    BulkUploadManagerService.BULK_CREATE_REQUEST_LIMIT;
                const itemsData = entities.map((item) => item.getRequestData());
                const requests = [];

                while (itemsData.length) {
                    // const isLastRequest = (itemsData.length <= maxItemLimit);
                    const isLastRequest = false;
                    const data = itemsData.slice(0, maxItemLimit);
                    const afterSuccessResponse = (response) => {
                        // remove created import item from view
                        this.rawItems =
                            ArraySupport.removeItem<BulkImportItemModel>(
                                this.rawItems,
                                data,
                                "id"
                            );

                        this._removeSuccessMediaElement(data);

                        this._setViewItems();

                        return Promise.resolve(response);
                    };
                    const afterFailedResponse = (error) => {
                        // validate common form
                        this.formHelpers.validateForm(
                            this.commonFormGroup,
                            FormValidationService.readError(error).formMessages
                        );

                        // read items info from error response
                        const info =
                            FormValidationService.readError(error).info;
                        const successItems = data.filter(
                            (item) =>
                                !Object.keys(
                                    Utils.get(info, "items", {})
                                ).includes(item.id)
                        );

                        // remove create items from view
                        this.rawItems =
                            ArraySupport.removeItem<BulkImportItemModel>(
                                this.rawItems,
                                successItems,
                                "id",
                                "id"
                            );

                        this._removeSuccessMediaElement(successItems);

                        // add error messages to view items
                        this._validateItems(info || { items: {} });

                        return Promise.reject(error);
                    };

                    if (this.isPostEntity) {
                        const requestData = {
                            posts: data,
                            socialSiteIDs:
                                this.commonFormGroup.get(
                                    this.commonControlNames.SocialSites
                                ).value || [],
                        };

                        if (isLastRequest) {
                            requestData["last"] = "yes";
                        }

                        requests.push(
                            this._bulkUploadManagerService
                                .postBulkCreate(requestData, approve)
                                .then((response) =>
                                    afterSuccessResponse(response)
                                )
                                .catch((error) => afterFailedResponse(error))
                        );
                    }

                    if (this.isTemplateEntity) {
                        const requestData = {
                            templates: data,
                            ...this.commonFormGroup.getRawValue(),
                        };

                        if (isLastRequest) {
                            requestData["last"] = "yes";
                        }

                        requests.push(
                            this._bulkUploadManagerService
                                .templateBulkCreate(requestData, approve)
                                .then((response) =>
                                    afterSuccessResponse(response)
                                )
                                .catch((error) => afterFailedResponse(error))
                        );
                    }

                    itemsData.splice(0, maxItemLimit);
                }

                return Promise.all(requests);
            },
            {
                successMessageKey: "bulk.upload.save.success.message",
                failedMessageKey: "bulk.upload.save.error.message",
                showFailedModal: false,
                afterSuccessAction: (response) => {
                    if (!this.rawItems.length) {
                        this._dialogClose({ isSuccess: true, response });
                    }
                },
            }
        );
    }

    /**
     * Remove origin media element from import item
     *
     * @param {MouseEvent} event
     * @param {BulkImportItemModel} item
     */
    removeOriginMediaBtnClick(event: MouseEvent, item: BulkImportItemModel) {
        event.preventDefault();
        event.stopPropagation();

        item.removePropertyError("media");
        item.getMediaFailed = false;
        item.media = null;

        this.rawItems = ArraySupport.removeItem<BulkImportItemModel>(
            this.rawItems,
            item,
            "id"
        );

        this._setViewItems();
    }

    /**
     * Close button click event
     *
     * @param {MouseEvent} event
     */
    closeBtnClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.rawItems.filter((item) => item.isDatabaseEntity).length) {
            this._openModal
                .confirmModal(DialogConfirmComponent, {
                    title: LanguageService.getLine(
                        "bulk.upload.abortImport.title"
                    ),
                    message: LanguageService.getLine(
                        "bulk.upload.abortImport.deleteQuestion"
                    ),
                    yesButtonText: LanguageService.getLine(
                        "bulk.upload.abortImport.yesAnswer.label"
                    ),
                    noButtonText: LanguageService.getLine(
                        "bulk.upload.abortImport.noAnswer.label"
                    ),
                })
                .afterClosed()
                .subscribe((doIt) => {
                    if (doIt === null) {
                        return;
                    }

                    if (doIt) {
                        this._cancelImport(true);
                    } else {
                        this._dialogClose({ isSuccess: true }, false);
                    }
                });

            return;
        }

        if (this.wasExtractRequest) {
            this._openModal
                .confirmModal(DialogConfirmComponent, {
                    message: LanguageService.getLine(
                        "bulk.upload.abortImport.noDrafts.deleteQuestion"
                    ),
                    yesButtonText: LanguageService.getLine(
                        "bulk.upload.abortImport.yesAnswer.label"
                    ),
                })
                .afterClosed()
                .subscribe((doIt) => {
                    if (doIt) {
                        this._dialogClose({ isSuccess: false });
                    }
                });
        } else {
            this._dialogClose({ isSuccess: false });
        }
    }

    /**
     * Draft save button click event
     *
     * @param {MouseEvent} event
     * @param {BulkUploadManager.BulkImportItem} entity
     */
    draftBtnSave(event: MouseEvent, entity: BulkUploadManager.BulkImportItem) {
        event.preventDefault();
        event.stopPropagation();

        this._draftSave(entity);
    }

    /**
     * Raw item preview button click event
     *
     * @param {MouseEvent} event
     * @param {BulkUploadManager.ViewImportItem} item
     */
    previewBtnClick(event: MouseEvent, item: BulkImportItemModel) {
        event.preventDefault();
        event.stopPropagation();

        this._openModal.entityPreview(
            PostPreviewComponent,
            item.getPreviewData(),
            {
                data: {
                    noApproveButtons: true,
                },
            }
        );
    }

    /**
     * Raw item edit button click event
     *
     * @param {MouseEvent} event
     * @param {BulkImportItemModel} entity
     */
    editBtnClick(event: MouseEvent, entity: BulkImportItemModel) {
        event.preventDefault();
        event.stopPropagation();

        this._openModal.bulkItemEditor(ItemEditorComponent, entity, {
            data: {
                isPostEntity: this.isPostEntity,
                isTemplateEntity: this.isTemplateEntity,
                entity,
                afterSaveAction: (changedEntity: BulkImportItemModel) => {
                    const entityIndex = this.rawItems.findIndex(
                        (item) => item.id === entity.id
                    );

                    if (entityIndex > -1) {
                        // update entity in list
                        this.rawItems.splice(entityIndex, 1, changedEntity);

                        // save draft
                        if (changedEntity.isDatabaseEntity) {
                            this._draftSave(changedEntity);
                        }

                        // refresh view items
                        this._setViewItems();
                    }
                },
            },
        });
    }

    /**
     * Delete raw item button click event
     *
     * @param {MouseEvent} event
     * @param {BulkUploadManager.ViewImportItem} entity
     */
    deleteBtnClick(event: MouseEvent, entity: BulkImportItemModel) {
        event.preventDefault();
        event.stopPropagation();

        this._openModal
            .confirmModal(DialogConfirmComponent, {
                message: LanguageService.getLine("modal.confirmDelete.content"),
                yesButtonText: LanguageService.getLine(
                    "modal.confirmDelete.button.delete"
                ),
            })
            .afterClosed()
            .subscribe((doIt) => {
                if (doIt) {
                    const entityIndex = this.rawItems.findIndex(
                        (item) => item.id === entity.id
                    );

                    if (entityIndex > -1) {
                        this.componentHelpers.startApiAction(
                            () => this._deleteDraftItems([entity]),
                            {
                                showSuccessModal: false,
                                failedMessageKey: "post.manager.deleteFailed",
                                afterSuccessAction: () => {
                                    this.rawItems.splice(entityIndex, 1);

                                    this._setViewItems();
                                },
                            }
                        );
                    }
                }
            });
    }

    /**
     * @param {MouseEvent} event
     */
    selectAllSocialSite(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.commonFormGroup
            .get(this.commonControlNames.SocialSites)
            .setValue(
                CommonPostHelpers.selectAllSocialSite(
                    this.socialSites,
                    this.selectedSocialSites
                )
            );
    }

    /**
     * File input change event
     *
     * @param {Event} event
     */
    fileChange(event: Event) {
        const file = Utils.lodash.get(this.fileInput, "value.files[0]", null);
        let messageKey = null;

        if (!file) {
            messageKey = "organization.tempMedia.error.fileNotFound";
        }

        if (!(file instanceof File)) {
            messageKey = "bulk.upload.importFromFile.error.invalid.message";
        }

        if (!messageKey) {
            this._extractFile(file);
        } else {
            this._openModal.errorModal(DialogErrorComponent, {
                message: LanguageService.getLine(messageKey),
            });
        }
    }

    /**
     * Refresh item media element button click
     *
     * @param {MouseEvent} event
     * @param {BulkImportItemModel} entity
     */
    refreshItemMediaBtnClick(event: MouseEvent, entity: BulkImportItemModel) {
        event.preventDefault();
        event.stopPropagation();

        const entityIndex = this.rawItems.findIndex(
            (item) => item.id === entity.id
        );
        const saveEntity = (item) => {
            this.rawItems.splice(entityIndex, 1, item);

            this._setViewItems();
        };

        if (entityIndex > -1) {
            this._downloadMediaElement(entity)
                .then((item) => {
                    saveEntity(item);
                })
                .catch((item) => {
                    saveEntity(item);
                });
        }
    }

    /**
     * Init common form group
     *
     * @private
     */
    _initCommonFormGroup() {
        const organizationsFormControl = new FormControl(
            [],
            [Validators.required]
        );

        if (this.isTemplateEntity) {
            this.commonFormGroup = new FormGroup({
                [this.commonControlNames.Organizations]:
                    organizationsFormControl,
            });
        }

        if (this.isPostEntity) {
            organizationsFormControl.valueChanges.subscribe((value) => {
                this._initSocialSites();
            });

            this.commonFormGroup = new FormGroup({
                [this.commonControlNames.Organizations]:
                    organizationsFormControl,
                [this.commonControlNames.SocialSites]: new FormControl(
                    [],
                    [Validators.required]
                ),
            });

            this.commonFormGroup
                .get(this.commonControlNames.SocialSites)
                .valueChanges.subscribe((selectedSiteIDs) => {
                    this.selectedSocialSites = this.socialSites.filter((site) =>
                        (selectedSiteIDs || []).includes(site.siteID)
                    );
                    this.socialSites = CommonPostHelpers.filterSocialSiteList(
                        this.socialSites,
                        this.selectedSocialSites
                    );
                });
        }

        this.commonFormGroup.addControl(
            this.commonControlNames.SocialType,
            new FormControl(null, [Validators.required])
        );

        this.commonFormGroup
            .get(this.commonControlNames.SocialType)
            .valueChanges.subscribe((value) => this._socialTypeSelectChange());

        this.formHelpers.formInit(this.commonFormGroup);
    }

    /**
     * Get organizations
     *
     * @private
     */
    _initOrganizations() {
        this.organizationController.filters = {};

        this.organizationController.getItems((response) => {
            this.organizations = response.organizations;
        });
    }

    /**
     * Init social sites
     *
     * @private
     */
    _initSocialSites() {
        const organizationIDs =
            this.commonFormGroup.get(this.commonControlNames.Organizations)
                .value || [];
        const socialType =
            this.commonFormGroup.get(this.commonControlNames.SocialType)
                .value || null;

        const getSocialSites = () => {
            const filters = {};

            if (socialType) {
                filters["socialType"] = socialType;
            }

            this.socialSiteController
                .getItemsByIDs("organizationID", organizationIDs, filters)
                .then((response: any) => {
                    this.socialSites = Helpers.orderBy(
                        response.socialSites,
                        "name"
                    );
                });
        };
        const clearSocialSites = () => {
            this.socialSites = [];
            this.commonFormGroup
                .get(this.commonControlNames.SocialSites)
                .setValue([]);
        };

        clearSocialSites();

        if (organizationIDs.length) {
            if (this.isPostEntity) {
                if (socialType) {
                    getSocialSites();
                }
            } else {
                getSocialSites();
            }
        }
    }

    /**
     * Social type select change
     *
     * @private
     */
    _socialTypeSelectChange() {
        if (this.isPostEntity) {
            this._initSocialSites();
        }
    }

    /**
     * Send file to API extract resource
     *
     * NOTE: Ha autofeed-nek yes adnak meg a csv-ben, valszeg a scheduled time értékét nem fogják kitölteni.
     * Az extract végpont viszont minden esetben validálja a scheduled time értékét, így ilyenkor minden esetben
     * hibát fog visszadobni az activeFrom értékre.
     * Backend szerint: Az extract végpontról az item draft-ként érkezik vissza, így az nem lehet autofeed,
     * ezért nincs megadva a kivétel a validációnál.
     *
     * @param {File} file
     * @private
     */
    _extractFile(file: File) {
        // clear file input value
        this.fileInput.clear();

        // validate common form when entity type is post
        if (this.commonFormGroup.invalid) {
            this.formHelpers.validateForm(this.commonFormGroup);

            return;
        }

        this.wasExtractRequest = true;

        this.componentHelpers.startApiAction(
            () => {
                const extractConfig: BulkUploadManager.ExtractFileConfig = {
                    organizationIDs: this.commonFormGroup.get(
                        this.commonControlNames.Organizations
                    ).value,
                    socialType: this.commonFormGroup.get(
                        this.commonControlNames.SocialType
                    ).value,
                };
                const afterSuccessExtract = (
                    response: BulkUploadManager.ExtractFileResponse
                ) => {
                    this.rawItems = response.items as BulkImportItemModel[];

                    // get uploaded media elements
                    this.mediaItems = this.rawItems
                        .filter((item) => {
                            this._getLinkshareProperties(item);
                            return item.mediaIsUploaded;
                        })
                        .map((item) => item.newMedia);
                    this.importBtnIsDisabled = !!this.rawItems.filter(
                        (item) => !item.hasError
                    ).length;
                    this.commonFormGroup
                        .get(this.commonControlNames.SocialType)
                        .disable({ emitEvent: false });

                    if (this.isPostEntity) {
                        this.commonFormGroup
                            .get(this.commonControlNames.Organizations)
                            .disable({ emitEvent: false });
                        this.commonFormGroup
                            .get(this.commonControlNames.SocialSites)
                            .disable({ emitEvent: false });
                    }

                    if (
                        this.commonFormGroup.get(
                            this.commonControlNames.SocialType
                        ).value === SOCIAL_MEDIA_TYPE_TWITTER
                    ) {
                        this.excludedFields = ArraySupport.arrayMerge(
                            this.excludedFields,
                            [...this.twitterExcludedFields],
                            true
                        );
                    }

                    if (
                        this.commonFormGroup.get(
                            this.commonControlNames.SocialType
                        ).value === SOCIAL_MEDIA_TYPE_INSTAGRAM
                    ) {
                        this.excludedFields = ArraySupport.arrayMerge(
                            this.excludedFields,
                            [...this.instagramExcludeFields],
                            true
                        );
                    }

                    if (
                        this.commonFormGroup.get(
                            this.commonControlNames.SocialType
                        ).value === SOCIAL_MEDIA_TYPE_GMB
                    ) {
                        this.excludedFields = ArraySupport.arrayMerge(
                            this.excludedFields,
                            [...this.gmbExcludedFields],
                            true
                        );

                        this.rawItems.map((rawItem) => {
                            const archive = JSON.parse(rawItem.archive);
                            rawItem.gmbOptions = archive.gmbOptions;
                        });
                    }

                    this._setViewItems();

                    return Promise.resolve(response);
                };

                if (this.isPostEntity) {
                    extractConfig.socialSiteIDs = this.commonFormGroup.get(
                        this.commonControlNames.SocialSites
                    ).value;

                    return this._bulkUploadManagerService
                        .extractPostsFile(file, extractConfig)
                        .then((response) => afterSuccessExtract(response));
                }

                if (this.isTemplateEntity) {
                    return this._bulkUploadManagerService
                        .extractTemplatesFile(file, extractConfig)
                        .then((response) => afterSuccessExtract(response));
                }
            },
            {
                // successMessageKey: 'bulk.upload.importFromFile.success.message',
                showSuccessModal: false,
                failedMessageKey: "bulk.upload.importFromFile.error.message",
            }
        );
    }

    /**
     * Set view item list
     *
     * @private
     */
    _setViewItems() {
        const offset =
            this.paginatorController.paginationOptions.pageIndex *
            this.paginatorController.paginationOptions.pageSize;
        const items = this.rawItems
            .slice(
                offset,
                offset + this.paginatorController.paginationOptions.pageSize
            )
            .map((item) => {
                this._downloadMediaElement(item);

                return item;
            });

        this.paginatorController.paginationOptions.length =
            this.rawItems.length;

        this.viewItems.next(items);
    }

    /**
     * Download media element of import item
     *
     * @param {BulkImportItemModel} item
     * @private
     */
    _downloadMediaElement(item: BulkImportItemModel) {
        if (!!item.media && item.media.cacheUrl && !item.mediaSrc) {
            item.pendingMedia = true;
            return this._bulkUploadManagerService
                .downloadFile(item.media.cacheUrl)
                .then((fileResponse) => {
                    item.removePropertyError("media");

                    item.setValue("pendingMedia", false)
                        .setValue("getMediaFailed", false)
                        .setValue("mediaSrc", fileResponse.content);

                    return Promise.resolve(item);
                })
                .catch((error) => {
                    item.addPropertyError(
                        "media",
                        FormValidationService.readError(error).message
                    );

                    item.setValue("pendingMedia", false)
                        .setValue("getMediaFailed", true)
                        .setValue("mediaSrc", null);

                    return Promise.reject(item);
                });
        }

        return Promise.resolve(item);
    }

    /**
     * @param {BulkImportItemModel} item
     * @return {Promise<BulkImportItemModel>}
     * @private
     */
    _getLinkshareProperties(item: BulkImportItemModel) {
        const content = item.mainCopy || "";
        const urls = getUrls(content);

        if (urls.size && !item.ogMediaItem) {
            const url = CommonPostHelpers.getLastUrlFrom(urls);
            item.setValue("pendingMedia", true);
            return this._ogService
                .getData(
                    url,
                    item.socialType === SOCIAL_MEDIA_TYPE_TWITTER
                        ? OPEN_GRAPH_TYPE_TWITTER
                        : OPEN_GRAPH_TYPE_OG
                )
                .then((res) => {
                    const ogSrc = Utils.get(res, "og.image", null);

                    if (ogSrc) {
                        const ogMediaItem = SmdFile.createMedia(ogSrc);
                        item.setValue("pendingMedia", false)
                            .setValue("getMediaFailed", false)
                            .setValue("medias", [ogMediaItem])
                            .setValue("linkShareURL", url)
                            .setValue("linkShareImageURL", ogSrc)
                            .setValue(
                                "linkShareTitle",
                                Utils.get(res, "og.title", null)
                            )
                            .setValue(
                                "linkShareDescription",
                                Utils.get(res, "og.description", null)
                            )
                            .setValue("ogMediaItem", ogMediaItem);

                        item.setBackupLinkshare();

                        this._draftSave(item);
                    } else {
                        return Promise.reject({
                            message: "OG image not found!",
                        });
                    }

                    return Promise.resolve(item);
                })
                .catch((error) => {
                    item.addPropertyError(
                        "media",
                        FormValidationService.readError(error).message ||
                            error.message
                    );

                    item.setValue("pendingMedia", false)
                        .setValue("getMediaFailed", true)
                        .setValue("mediaSrc", null);

                    return Promise.reject(item);
                });
        }

        return Promise.resolve(item);
    }

    /**
     * Close current dialog
     *
     * @param {{isSuccess: boolean; response?: any}} data
     * @param isCancelImport
     * @private
     */
    _dialogClose(
        data: { isSuccess: boolean; response?: any },
        isCancelImport = true
    ) {
        // if (data.isSuccess && this.dialogData.afterSuccessSave) {
        if (this.wasExtractRequest && this.dialogData.afterSuccessSave) {
            this._dialogRef.afterClosed().subscribe(() => {
                this.dialogData.afterSuccessSave(data.response);
            });
        }

        if (this.mediaItems.length && isCancelImport) {
            this.componentHelpers.startApiAction(
                () => {
                    const fileIDs = this.mediaItems.map(
                        (media) => media.mediaID
                    );

                    return this._fileBrowserService.deleteMedias({ fileIDs });
                },
                {
                    showSuccessModal: false,
                    showFailedModal: false,
                    finallyAction: () => {
                        this._dialogRef.close(data);
                    },
                }
            );
        } else {
            this._dialogRef.close(data);
        }
    }

    /**
     * Cancel import, delete draft entities
     *
     * @param {boolean} [closeModal]
     * @returns {Promise<any>}
     * @private
     */
    _cancelImport(closeModal = false): Promise<any> {
        return this.componentHelpers.startApiAction(
            () => this._deleteDraftItems(this.rawItems),
            {
                successMessageKey: "post.manager.import.cancel.success",
                failedMessageKey: "post.manager.import.cancel.failed",
                afterSuccessAction: () => {
                    this.importBtnIsDisabled = false;
                    this.rawItems = this.rawItems.filter(
                        (item) => !item.isDatabaseEntity
                    );
                    this._setViewItems();
                    this.excludedFields = [];

                    // szeretne még importálni a felhasználó?
                    this._openModal
                        .confirmModal(DialogConfirmComponent, {
                            title: LanguageService.getLine(
                                "bulk.upload.continueImport.title"
                            ),
                            message: LanguageService.getLine(
                                "bulk.upload.continueImport.question"
                            ),
                            yesButtonText: LanguageService.getLine(
                                "bulk.upload.continueImport.yesAnswer.label"
                            ),
                        })
                        .afterClosed()
                        .subscribe((doIt) => {
                            if (!doIt) {
                                if (closeModal) {
                                    this._dialogClose({ isSuccess: false });
                                }
                            } else {
                                this.formHelpers.resetFormGroup(
                                    this.commonFormGroup,
                                    {},
                                    (formControl) => {
                                        formControl.enable();
                                    }
                                );

                                this.importBtnIsDisabled = false;
                                this.rawItems = [];
                                this._setViewItems();
                            }
                        });
                },
            }
        );
    }

    /**
     * Entity draft save
     *
     * @param {BulkUploadManager.BulkImportItem} entity
     * @returns {Promise<any>}
     * @private
     */
    _draftSave(entity: BulkImportItemModel) {
        const afterResponse = () => {
            entity.draftSaveInProgress = false;
        };

        // set entityID
        const entityID = this.isPostEntity
            ? (<BulkImportPostItemModel>entity).postID
            : (<BulkImportPostTemplateItemModel>entity).templateID;

        if (!entityID) {
            return;
        }

        // set draft type
        const draftType: DraftType = this.isPostEntity
            ? "post"
            : "postTemplate";

        // set request data
        const requestData = this.isPostEntity
            ? (<BulkImportPostItemModel>entity).getDraftRequestData()
            : (<BulkImportPostTemplateItemModel>entity).getDraftRequestData();

        entity.draftSaveInProgress = true;
        return this._postService
            .draft(draftType, requestData, entityID)
            .then((response) => {
                afterResponse();

                return Promise.resolve(response);
            })
            .catch((error) => {
                afterResponse();

                this._openModal.errorModal(DialogErrorComponent, {
                    title: LanguageService.getLine(
                        "bulk.upload.label.draftSave"
                    ),
                    message: FormValidationService.readError(error).message,
                });

                return Promise.reject(error);
            });
    }

    /**
     * Delete draft entities from database
     *
     * @param {BulkImportItemModel[]} entities
     * @private
     */
    _deleteDraftItems(entities: BulkImportItemModel[]) {
        let entityDeleteRequest: Promise<any>;

        entities = entities.filter((entity) => entity.isDatabaseEntity);

        if (this.isTemplateEntity) {
            const templateIDs = (<BulkImportPostTemplateItemModel[]>entities)
                .filter((item) => item.isDatabaseEntity)
                .map((item) => item.templateID);

            if (templateIDs.length) {
                entityDeleteRequest =
                    this._templateService.deleteTemplates(templateIDs);
            } else {
                entityDeleteRequest = Promise.resolve();
            }
        }

        if (this.isPostEntity) {
            const postIDs = (<BulkImportPostItemModel[]>entities)
                .filter((item) => item.isDatabaseEntity)
                .map((item) => item.postID);

            if (postIDs.length) {
                entityDeleteRequest = this._postService.deletePosts(postIDs);
            } else {
                entityDeleteRequest = Promise.resolve();
            }
        }

        // get fileIDs of medias from zip file
        const fileIDs = entities
            .filter(
                (item) => !item.hasError && item.media && item.media.mediaID
            )
            .map((item) => item.media.mediaID);

        return entityDeleteRequest.then((response) => {
            // if (fileIDs.length) {
            //     return this._fileBrowserService.deleteMedias({fileIDs})
            //         .then(() => Promise.resolve(response));
            // }

            return Promise.resolve(response);
        });
    }

    /**
     * Validate view items by server error
     *
     * @param {BulkUploadManager.ExtractFileResponse} invalidItems
     * @private
     */
    _validateItems(invalidItems: BulkUploadManager.ExtractFileResponse) {
        // itemID is generated uniq string
        for (const itemID in invalidItems.items) {
            const item: any = invalidItems.items[itemID];
            const viewItemIndex = this.rawItems.findIndex(
                (viewItem) => viewItem.id === itemID
            );

            if (viewItemIndex > -1) {
                const viewItem = this.rawItems[viewItemIndex];
                const errors = item.error;

                if (errors instanceof Object) {
                    for (const propertyName in errors || {}) {
                        const errorMessage = errors[propertyName];

                        viewItem.addPropertyError(
                            <keyof BulkImportItemModel>propertyName,
                            errorMessage
                        );
                    }
                } else {
                    viewItem.addPropertyError("generic", errors);
                }

                this.rawItems.splice(viewItemIndex, 1, viewItem);
            }
        }

        this._setViewItems();
    }

    _removeSuccessMediaElement(data: any[] = []) {
        const successMediaIDs = [];
        const successCacheIDs = [];

        // get success mediaIDs
        data.filter((item) => item.fileIDs && item.fileIDs.length).forEach(
            (item) => successMediaIDs.push(...item.fileIDs)
        );

        // get success cacheIDs
        data.filter((item) => item.media && item.media.cacheID).forEach(
            (item) => successCacheIDs.push(item.media.cacheID)
        );

        this.mediaItems = this.mediaItems.filter(
            (media) =>
                !successMediaIDs.includes(media.mediaID) &&
                !successCacheIDs.includes(media.cacheID)
        );
    }

    getIconForGmbType(type) {
        switch (type) {
            case "event":
                return "event";
            case "offer":
                return "local_offer";
            default:
                return "new_releases";
        }
    }
}
