import {Injectable} from '@angular/core';
import {ModelAbstract} from '~/src/app/services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '~/src/app/services/helpers';
import {Token} from '~/src/app/services/token';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class CarouselService extends ModelAbstract {
    private requestBackup = {};

    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/carousel';
    }

    /**
     * Get carousel items by carouselID
     *
     * @param {number} carouselID
     * @return {Promise<any>}
     */
    getCarouselItems(carouselID: number): Promise<any> {
        return this.http.get(`${this.apiLink}/item?id=${carouselID}`, Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Get carousel items by carousel IDs
     *
     * @param {number[]} carouselIDs
     * @return {Promise<any>}
     */
    getCarouselItemsByIDs(carouselIDs: number[], isAdminMode = false): Promise<any> {
        let url = `${this.apiLink}/item${this.arrayToQuery('carouselID', carouselIDs)}`;

        if (isAdminMode) {
            url += '&isAdmin';
        }

        if (this.requestBackup && url in this.requestBackup) {
            return this.requestBackup[url];
        } else {
            this.requestBackup[url] = this.http.get(url, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then(response => {
                delete this.requestBackup[url];
                return response;
            })
            .catch(error => {
                delete this.requestBackup[url];
                return error;
            });
            return this.requestBackup[url];
        }
    }


    /**
     * Delete carousel
     * @param {string} data
     * @returns {Promise<any>}
     */
    deleteCarouselByID(data: any): Promise<any> {
        return this.http.delete(this.apiLink + '/' + data, Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

}

