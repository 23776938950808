
/*
 *ngFor="let c of oneDimArray | orderBy:'asc'"
 *ngFor="let c of arrayOfObjects | orderBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

  transform(value: any[], order: boolean = true, column: string = ''): any[] {
    //No array or array with one item given 
    if(!value || value.length <= 1) {
        return value;
    }
    if (!column || column === '') { 
        return order ? value.sort() : value.sort().reverse();
    } 
    return orderBy(value, [column], [order]);
  }
}