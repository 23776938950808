import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'smd-dialog-feed-item-delete-confirm',
    templateUrl: './dialog-feed-item-delete-confirm.component.html',
    styleUrls: ['./dialog-feed-item-delete-confirm.component.scss']
})
export class DialogFeedItemDeleteConfirmComponent implements OnInit {

    day = '';

    time = '';

    days = '';

    constructor(
        public _dialogRef: MatDialogRef<DialogFeedItemDeleteConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.day = this.data['day'];
        this.time = this.data['time'];
        this.days = this.data['days'].join(', ');
    }

    ngOnInit() {
    }

}
