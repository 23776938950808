import {Injectable} from '@angular/core';
import {Helpers} from './helpers';

@Injectable({providedIn: 'root'})
export class ValidatorsService {
    private urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})/?';

    constructor() {}

    /**
     * Check validation URL's
     * */
    public checkUrls(urls) {
        const urlPatternObj = new RegExp(this.urlPattern);
        if (urls && urls.length > 0) {
            return urls.every((item) => {
                return item.url.match(urlPatternObj) !== null;
            });
        }
    }
}
