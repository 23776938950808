import AbstractModel from '~/src/app/core/model.abstract';
import {IRSSSavedPost} from '~/src/app/modules/rss/types/rss-saved-post.interface';
import {RssBlogPostModel} from '~/src/app/modules/rss/models/rss-blog-post.model';
import {RssFeedModel} from '~/src/app/modules/rss/models/rss-feed.model';

export class RssSavedPostModel extends AbstractModel<IRSSSavedPost> implements IRSSSavedPost {
    content: string;
    createDate: string;
    feedID: number;
    postID: number;
    userID: number;
    blogPost: RssBlogPostModel;
    feedModel: RssFeedModel;

    constructor(json: IRSSSavedPost, feedModel: RssFeedModel = null) {
        super(json);
        this.parse(json);
        this.feedModel = feedModel;
        this.blogPost = new RssBlogPostModel(JSON.parse(JSON.stringify(this.content)), null, this);
    }
}
