import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SmdFile, SmdFileInterface} from '../../services/file.class';
import {FALLBACK_IMAGE} from '../../configs/configs';
import {Icons} from '../../helpers/icons';

@Component({
    selector: 'smd-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

    @Input() selectedList = false;
    @Output('onPreviewClick') onPreviewClick: EventEmitter<SmdFileInterface> = new EventEmitter<SmdFileInterface>();

    private _file: SmdFileInterface;

    isHovered: boolean = false;

    constructor() {}

    ngOnInit(): void {
        const src = this.file.thumb;

        this._file.thumb = src;
    }

    openGallery($event) {
        $event.stopPropagation();
        this.onPreviewClick.emit(this.file);
    }

    /**
     * Selectable option
     *
     * @type {boolean}
     */
    @Input()
    selectable = false;

    /**
     * Get file
     *
     * @returns {SmdFile}
     */
    get file(): SmdFileInterface {
        return this._file;
    }

    /**
     * Set file from attribute
     *
     * @param {SmdFile} value
     */
    @Input()
    set file(value: SmdFileInterface) {
        this._file = value;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }

    get haveCarousel() {
        return this.file.carouselItems && this.file.carouselItems.length > 0;
    }

    get fileIcons() {
        return Icons;
    }
}
