import {
    Component, EventEmitter, Inject, ElementRef,
    OnInit, Input
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '~/src/app/services/language.service';
import { OrganizationService } from '~/src/app/components/organization-select/organization.service';
import { OrganizationComponent } from '~/src/app/components/organization-select/organization.component';
import { FacebookService } from 'ngx-facebook';
import { OrganisationService } from "~/src/app/modules/users/organizations/organisation.service";
import { OrganizationItem } from '~/src/app/components/organization-select/organization.interfaces';
import { SocialSiteComponent } from "~/src/app/components/social-site-select/social-site.component";
import { SocialSiteService } from "~/src/app/components/social-site-select/social-site.service";
import { SocialChannels } from "~/src/app/modules/analytics/widget/widget.interfaces";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { SocialManagerComponent } from "~/src/app/modules/users/social-manager/social-manager.component";
import { SocialManagerService } from "~/src/app/modules/users/social-manager/social-manager.service";
import { Facebook } from "~/src/app/modules/users/Facebook.class";
import { TwitterAPI } from "~/src/app/modules/users/twitter.service";
import { GmbApiLocationService } from "~/src/app/modules/social-media-post/gmb/gmb-api-location.service";
import { LinkedInService } from "~/src/app/services/linked-in.service";
import { OrganizationGroupbyService } from '~/src/app/services/organization-groupby.service';

import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
} from "~/src/app/core/constants";
import { OrganizationsGroupbyOrganizationComponent } from '~/src/app/components/organizations-groupby-organization/organizations-groupby-organization.component';

interface DialogData {
    title: string;
    socialsite: string;
}

@Component({
    selector: 'smd-select-org-modal',
    templateUrl: './select-org-modal.component.html',
    styleUrls: ['./select-org-modal.component.scss'],
    providers: [
        ComponentHelpers
    ]
})
export class selectOrgModalComponent implements OnInit {
    notify: EventEmitter<any> = new EventEmitter<any>();
    submitEvent = new EventEmitter();

    @Input() organizationsGroupbyOrganizationComponent: OrganizationsGroupbyOrganizationComponent;
    _organizationsComponent = null;
    _organizationComponent: OrganizationComponent;
    newOrganizationForm: FormGroup;

    //organizations = [];
    organization = {
        organizationID: 0,
        name: "",
        address: "",
        desc: "",
        createdDate: "",
        childNumber: 0,
        childs: [],
        rssArr: [],
    };
    fb: Facebook;
    filterData: any;
    organizations: OrganizationItem[] = [];
    isLoading = false;
    inputNumber = 0;
    inputfeeds = [
        { name: '' }
    ];
    inputMetas = [
        {
            id: 1
        }
    ];
    message: object = {};
    form = [{
        url: {
            valid: true,
            validMessage: 'Not valid URL!'
        }
    }];
    public isActiveSocialChannelId: SocialChannels = SOCIAL_MEDIA_TYPE_FACEBOOK;
    socialManagerComponent: SocialManagerComponent;
    urlReg;
    isLoggedInWithLinkedIn: boolean;

    socialSitesComponent: SocialSiteComponent;
    constructor(
        private http: HttpClient,
        fbService: FacebookService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<selectOrgModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _organizationService: OrganizationService,
        private OrganizationGroupbyService: OrganizationGroupbyService,
        public socialSitesService: SocialSiteService,
        public socialManagerService: SocialManagerService,
        public twitterAPI: TwitterAPI,
        public gmbApiService: GmbApiLocationService,
        private linkedinService: LinkedInService,
        public service: OrganisationService,
        public el: ElementRef<HTMLElement>,
    ) {
        this.fb = new Facebook(fbService, dialog);
        this.isLoggedInWithLinkedIn = this.linkedinService.isLoggedIn();
        this.socialSitesComponent = new SocialSiteComponent(socialSitesService);
        this.organizationInit();
    }

    ngOnInit() {
        this.urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w; .-]*/?';

        this.newOrganizationForm = new FormGroup({
            organizationParent: new FormControl('', Validators.required),
        });
        this.OrganizationGroupbyService.currentFilterData.subscribe(data => {
            this.filterData = data;
        });
    }

    organizationInit() {
        if (!this._organizationComponent) {
            this._organizationComponent = new OrganizationComponent(this._organizationService);
        }

        this._organizationComponent.filters = [];
        /* this._organizationComponent.getItems(response => {
            this.organizations = response.organizations;
        }); */
    }

    /**
     * Close modal instance
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     *
     * Create New organization handler
     *
     * @param event organization form event
     */
    async submitNewOrg(event) {
        event.preventDefault();
        const socialsite = this.socialsite;
        const orgName = this.organizationParent;
        this.organization.organizationID = orgName;
        this.dialogRef.close(this.organizationParent);
        // this.closeDialog();
        return orgName;
    }

    /**
     * Getters for create organization form
     * */

    get organizationParent(): any {
        return this.newOrganizationForm.get('organizationParent').value;
    }
    get languageService() {
        return LanguageService;
    }

    get socialsite() {
        return this.data.socialsite;
    }
}
