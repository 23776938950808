import AbstractModel from '~/src/app/core/model.abstract';
import {IOrganization, IOrganizationData} from '~/src/app/modules/users/organizations/organization.interface';
import {ObjectSupport} from '~/src/app/core/helper/object-support';

export class OrganizationModel extends AbstractModel<IOrganization | IOrganizationData> implements IOrganization, IOrganizationData {
    name: string;
    organizationData: IOrganizationData;
    organizationID: number;
    address: string;
    createdDate: string;
    description: string;
    rss: string[];
    main: boolean;

    constructor(entity: IOrganization) {
        super(entity);
        this.parse(entity);

        if (this.organizationData && typeof this.organizationData === 'string') {
            this.organizationData = ObjectSupport.parse(this.organizationData);
            this.parse(this.organizationData);
        }

        this.main = !!this.main;
    }
}
