import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Helpers} from './helpers';

import {map} from 'rxjs/internal/operators';
import {Token} from './token';
import {LoggedUser} from './logged-user';
import {environment} from '~/src/environments/environment';
import {GridStackItemValueChange} from '~/src/app/core/services/grid-stack.service.d';
import obj2fd from 'obj2fd';
import {AuthService} from '~/src/app/modules/auth/auth.service';
import Utils from '~/src/app/core/utils';
import {Range} from '~/node_modules/ngx-mat-daterange-picker';

export const USER_API_URLS = {
    'general': environment.apiUrl + '/api/users/edit',
    'editUser': environment.apiUrl + '/api/users/update/',
    'avatar': environment.apiUrl + '/api/users/edit',
    'removeProfile': environment.apiUrl + '/api/users/edit',
    'emailNotification': environment.apiUrl + '/api/users/edit',
    'create': environment.apiUrl + '/api/users/',
    'deleteUser': environment.apiUrl + '/api/users/',
    'delete': environment.apiUrl + '/api/users/delete',
    'multipleAction': environment.apiUrl + '/api/users/multiple',
    'resendActivationEmail': environment.apiUrl + '/api/users/resend-activation-email',
};

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {

    }

    /**
     * Save properties of dashboard widgets
     *
     * @param {GridStackItemValueChange[]} widgetsProperties
     * @param {Range} dateRange
     * @param {number[]} socialSiteIDs
     * @returns {Promise<Object>}
     */
    setDashboardWidgetProperties(widgetsProperties: GridStackItemValueChange[], dateRange: Range, socialSiteIDs: number[]) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());
        const data = {
            settings: JSON.stringify({
                dashboard: {
                    widgets: widgetsProperties,
                    dateRange: dateRange,
                    socialSiteIDs: socialSiteIDs
                }
            })
        };

        return this.http.post(USER_API_URLS['general'], obj2fd(data), httpOptions).toPromise();
    }

    /**
     * Get properties of dashboard widgets
     * @return {Promise<object>}
     */
    getDashboardWidgetProperties() {
        return this.authService.getMe().toPromise().then(response => {
            return Utils.get(response, 'user.settings.dashboard', null);
        });
    }

    setGeneralData(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(USER_API_URLS['general'], Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    setUserData(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(USER_API_URLS['editUser'] + data.userID, Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    createUser(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(USER_API_URLS['create'], Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    multipleAction(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(USER_API_URLS['multipleAction'], Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    deleteUsers(userIDs) {
        const requests = [];
        for (const userID of userIDs) {
            requests.push(
                this.deleteUser({userID: userID}).toPromise()
            );
        }

        return Promise.all(requests);
    }

    deleteUser(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.delete(USER_API_URLS['deleteUser'] + data.userID, httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

    /**
     * Re-send user activation email to inactive user
     * @param userID
     */
    resendActivationEmail(userID: number) {
        return this.http.get(
            USER_API_URLS['resendActivationEmail'],
            {
                ...Helpers.getBaseHttpHeaders(Token.getToken()),
                params: {
                    userID: userID + ''
                }
            }
        ).toPromise();
    }

    /**
     * Set user status
     * @param userID
     * @param status
     */
    setStatus(userID: number, status: 'active' | 'inactive') {
        return this.http.post(
            USER_API_URLS.editUser + userID,
            Utils.obj2fd({
                status: status
            }),
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }

    setEmailNotification(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(USER_API_URLS['emailNotification'], Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                const user = LoggedUser.getUser();

                user.settings.twoFactorAuthentication = true;

                LoggedUser.saveUser(user);

                return data;
            },
            error => {
                return error;
            }
        ));
    }


    removeProfile(data) {
        const httpOptions = Helpers.getBaseHttpHeaders(Token.getToken());

        return this.http.post(USER_API_URLS['removeProfile'], Helpers.objectToFormData(data), httpOptions)
        .pipe(map(
            data => {
                return data;
            },
            error => {
                return error;
            }
        ));
    }

}
