import {Injectable} from '@angular/core';
import {CalendarDateFormatterInterface, DateAdapter, DateFormatterParams, getWeekViewPeriod} from 'angular-calendar';
import * as moment from 'moment';

/**
 * This will use <a href="http://momentjs.com/" target="_blank">moment</a> to do all date formatting. To use this class:
 *
 * ```typescript
 * import { CalendarDateFormatter, CalendarMomentDateFormatter, MOMENT } from 'angular-calendar';
 * import moment from 'moment';
 *
 * // in your component
 * provide: [{
 *   provide: MOMENT, useValue: moment
 * }, {
 *   provide: CalendarDateFormatter, useClass: CalendarMomentDateFormatter
 * }]
 *
 * ```
 */
@Injectable({
    providedIn: 'root'
})
export class SmdCalendarMomentDateFormatter
    implements CalendarDateFormatterInterface {
    moment = moment;
    /**
     * @hidden
     */
    constructor(
        protected dateAdapter: DateAdapter
    ) {}

    /**
     * The month view header week day labels
     */
    public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('dddd');
    }

    /**
     * The month view cell day number
     */
    public monthViewDayNumber({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('D');
    }

    /**
     * The month view title
     */
    public monthViewTitle({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('MMMM YYYY');
    }

    /**
     * The week view header week day labels
     */
    public weekViewColumnHeader({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('dddd');
    }

    /**
     * The week view sub header day and month labels
     */
    public weekViewColumnSubHeader({
                                       date,
                                       locale
                                   }: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('D MMM');
    }

    /**
     * The week view title
     */
    public weekViewTitle({
                             date,
                             locale,
                             weekStartsOn,
                             excludeDays,
                             daysInWeek
                         }: DateFormatterParams): string {
        const {viewStart, viewEnd} = getWeekViewPeriod(
            this.dateAdapter,
            date,
            weekStartsOn,
            excludeDays,
            daysInWeek
        );
        const format = (dateToFormat: Date, showYear: boolean) =>
            this.moment(dateToFormat)
            .locale(locale)
            .format('D MMM' + (showYear ? ', YYYY' : ''));
        return `${format(
            viewStart,
            viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear()
        )} - ${format(viewEnd, true)}`;
    }

    /**
     * The time formatting down the left hand side of the week view
     */
    public weekViewHour({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('ha');
    }

    /**
     * The time formatting down the left hand side of the day view
     */
    public dayViewHour({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('ha');
    }

    /**
     * The day view title
     */
    public dayViewTitle({date, locale}: DateFormatterParams): string {
        return this.moment(date)
        .locale(locale)
        .format('dddd, D MMMM, YYYY');
    }
}
