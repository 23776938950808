import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { NotificationModel } from "~/src/app/modules/notification-menu/notification.model";
import { Helpers } from "~/src/app/services/helpers";
import { Token } from "~/src/app/services/token";
import { ReportOrganization, ReportShareData } from "../analytics/share-reports/share-object.interface";
import { Report, ReportEditRequest } from "./dashboards.interface";

@Injectable({ providedIn: "root" })
export class ReportsService {
    apiLink = "/api/report";

    constructor(private http: HttpClient) {
        // ...
    }

    /**
     * Gets full data of multiple reports.
     *
     * @param options
     * @returns Promise<{ "reports": Report[]; "countMy": number; "countShared": number }>
     */
    getReports(options?: object): Promise<{ reports: Report[]; countMy: number; countShared: number }> {
        return this.http
            .get(this.apiLink + Helpers.getHttpQuery(options), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as { reports: Report[]; countMy: number; countShared: number })
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    /**
     * Get the data of a single report.
     *
     * @param reportID
     * @return Promise<{ "report": Report }>
     */
    getReport(reportID: number): Promise<{ report: Report }> {
        return this.http
            .get(this.apiLink + "/" + reportID, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as Report)
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    /**
     * Get minimal data of reports. Only the reportID, name and image.
     *
     * @param options
     * @return Promise<{"reports": Report[], "countMy": number, "countShared": number }>
     */
    getMinimalReports(options?: object): Promise<{ reports: Report[] }> {
        return this.http
            .get(this.apiLink + "/minimal" + Helpers.getHttpQuery(options), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as { reports: Report[] })
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    /**
     * Delete one, or multiple reports.
     *
     * @param reportIDs
     * @return Promise<{ "result": boolean; "message": string })>
     * @throws Error
     */
    deleteReports(reportIDs: Array<number>): Promise<{ result: boolean; message: string }> {
        if (!reportIDs) {
            return Promise.reject("At least one report ID required");
        }
        const formData = new FormData();
        formData.append("reportIDs", JSON.stringify(reportIDs));

        return this.http
            .post(this.apiLink + "/delete", formData, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as { result: boolean; message: string })
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    /**
     * Edit an existing report.
     *
     * @param reportID
     * @param reportData
     * @return Promise<{ "result": boolean; "message": string })>
     */
    editReport(reportID: number, data: ReportEditRequest): Promise<{ result: boolean; message: string }> {
        if (!reportID) {
            return Promise.reject("Report ID required");
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(data));

        return this.http
            .post(this.apiLink + "/" + reportID + "/edit", formData, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as { result: boolean; message: string })
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    getShareData(reportIDs: Array<number>): Promise<{ data: { organizations: ReportOrganization[], reports: ReportShareData[] } }> {
        return this.http
            .get(this.apiLink + "/share-data" + Helpers.getHttpQuery({ reportIDs: reportIDs }), Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as { data: { organizations: ReportOrganization[], reports: ReportShareData[] } })
            .catch((error) => {
                console.error(error);
                return error;
            });
    }

    shareReports(shareData: Array<{userID: number, reportID: number, shared: boolean}>): Promise<{ success: boolean, messages: string }> {
        const formData = new FormData();
        formData.append('data', JSON.stringify(shareData));

        return this.http
            .post(this.apiLink + '/share', formData, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then((response) => response as { success: boolean, messages: string })
            .catch((error) => {
                console.error(error);
                return error;
            })
    }
}
