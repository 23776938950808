import {Component, Input, OnInit} from '@angular/core';

declare const $;

export enum AvailableLoaders {
    'PAGE_LOADER' = 'pageLoader',
    'DATA_LOADER_TEMPLATES' = 'dataLoaderTemplates',
    'DATA_LOADER_MEDIAS' = 'dataLoaderMedias',
    'LOGIN_LOADER' = 'loginLoader',
    'PASSWORD_RESET' = 'passwordReset',
}

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

    @Input('selector') selector: string;

    constructor() {
    }

    ngOnInit() {}

    public hide(selector: AvailableLoaders): void {
        $(`#${selector}`).fadeOut();
    }

    public show(selector: AvailableLoaders): void {
        $(`#${selector}`).fadeIn();
    }
}

