import {debounceTime} from 'rxjs/operators';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CollectionListComponent} from '~/src/app/core/components/collection-list/collection-list.component';
import {CATEGORY_TYPE_CUSTOM, CATEGORY_TYPE_GENERIC} from '~/src/app/modules/categories/categories.config';
import {LanguageService} from '~/src/app/services/language.service';
import {DataTableHelpersService} from '~/src/app/core/services/data-table-helpers.service';
import {CategoriesService} from '~/src/app/modules/categories/categories.service';
import {ResourceService} from '~/src/app/directives/resource-checker/resource.service';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {ComponentHelpers} from '~/src/app/core/services/component-helpers';
import {
    CollectionListSelectionChange,
    TableBodyConfig,
    TableHeadConfig
} from '~/src/app/core/components/collection-list/collection-list.interfaces';
import {FormControl, FormGroup} from '~/node_modules/@angular/forms';
import {PaginationController} from '~/src/app/services/pagination.controller';
import {Helpers} from '~/src/app/services/helpers';
import {Categories, Category, CategoryFilters, CategoryTab} from '~/src/app/modules/categories/categories';
import Utils from '~/src/app/core/utils';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {CategoryManagerComponent} from '~/src/app/modules/categories/category-manager/category-manager.component';
import {Subject} from '~/node_modules/rxjs';

@Component({
    selector: 'smd-categories-list',
    templateUrl: './categories-list.component.html',
    styleUrls: ['./categories-list.component.scss'],
    providers: [
        DataTableHelpersService,
        ComponentHelpers,
        CategoriesService
    ]
})
export class CategoriesListComponent implements OnInit {
    @Input() tab: CategoryTab;
    @ViewChild(CollectionListComponent) collectionList: CollectionListComponent;

    // default order and constants
    readonly defaultOrder = 'name-asc';
    readonly categoryTypeCustom = CATEGORY_TYPE_CUSTOM;
    readonly categoryTypeGeneric = CATEGORY_TYPE_GENERIC;

    // paginator controller
    paginatorController: PaginationController = new PaginationController();

    // onInit observable
    onInit: Subject<null> = new Subject();

    // filter names
    filterNames = {
        Keyword: 'searchValue',
        OrderBy: 'order'
    };

    // filters form group
    filtersFormGroup = new FormGroup({
        [this.filterNames.Keyword]: new FormControl(null),
        [this.filterNames.OrderBy]: new FormControl(null)
    });

    // table head
    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: 'category.color',
            onlyDesktop: true,
            onlyMobile: true
        },
        {
            nameKey: 'category.name',
        },
        {
            nameKey: 'category.actions',
            onlyDesktop: true,
            alignment: 'right'
        }
    ];

    // table body
    tableBodyConfig: TableBodyConfig[] = [
        {
            bindValue: 'color',
            selector: 'categoryColor',
            onlyDesktop: true,
        },
        {
            bindValue: 'name'
        },
        {
            staticView: 'itemActions',
            onlyDesktop: true,
            alignment: 'flex-end'
        },
    ];

    constructor(
        public languageService: LanguageService,
        public dataTableHelperService: DataTableHelpersService,
        public categoriesService: CategoriesService,
        public resourceService: ResourceService,
        private openModal: OpenModalService,
        private componentHelpers: ComponentHelpers
    ) {
        this.onInit.pipe(
            debounceTime(350))
            .subscribe(() => {
                // get categories
                this._getCategories(this._getCategoryParams());
            });
    }

    /**
     * On init
     */
    ngOnInit() {
        this.onInit.next();

        // on filter change value
        this.filtersFormGroup.valueChanges.pipe(
            debounceTime(350))
            .subscribe(() => {
                this.paginatorController.paginationOptions.pageIndex = 0;
                this._getCategories(this._getCategoryParams());
            });

        // on pagination
        this.paginatorController.onPaginationChange.subscribe(() => this._getCategories(this._getCategoryParams()));
    }

    /**
     * Reset filters
     * @param {MouseEvent} event
     */
    public resetFilters(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const values = {};
        Utils.lodash.forEach(this.filtersFormGroup.controls, (control, controlName) => {
            values[controlName] = null;
        });

        this.filtersFormGroup.setValue(values, {emitEvent: true});
    }

    /**
     * Selection change
     * @param event
     */
    public selectionChange(event: CollectionListSelectionChange<Category>): void {
        this.dataTableHelperService.selectedItems = event.selectedItems;
    }

    /**
     * Create category
     */
    public openCategoryManager(event: MouseEvent, category?: Category, isEditMode: boolean = false): void {
        event.preventDefault();
        event.stopPropagation();

        this.openModal.manageCategory(CategoryManagerComponent, category, {
            data: {
                successCreate: () => {
                    this._getCategories(this._getCategoryParams());
                },
                successEdit: () => {
                    this._getCategories(this._getCategoryParams());
                },
                isEditMode: isEditMode,
                categoryType: this.tab.type
            },
        });
    }

    /**
     * Delete category
     * @param event
     * @param category
     */
    public deleteCategory(event: MouseEvent, category: Category): void {
        event.preventDefault();
        event.stopPropagation();

        this.componentHelpers.startApiAction(
            () => {
                return this.categoriesService.deleteCategory(category.categoryID);
            },
            {
                successMessageKey: 'post.category.success.modal.delete',
                failedMessageKey: 'post.category.error.modal.failedToDelete',
                confirmMessageKey: 'post.category.confirm.modal.delete',
                confirmYesButtonTextKey: 'modal.confirmDelete.button.delete',
                afterSuccessAction: () => {
                    this._getCategories(this._getCategoryParams());
                }
            },
            true
        );
    }

    /**
     * Delete categories
     * @param event
     */
    public deleteCategories(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (!!this.dataTableHelperService.getSelectedItems().length) {
            this.componentHelpers.startApiAction(
                () => {
                    return this.categoriesService.deleteCategories(this.dataTableHelperService.getSelectedItems().map(item => {
                        return item.categoryID;
                    }));
                },
                {
                    successMessageKey: 'post.category.success.modal.groupDelete',
                    failedMessageKey: 'post.category.error.modal.failedToGroupDelete',
                    confirmMessageKey: 'post.category.confirm.modal.groupDelete',
                    confirmYesButtonTextKey: 'modal.confirmDelete.button.delete',
                    afterSuccessAction: () => {
                        this._getCategories(this._getCategoryParams());
                    }
                },
                true
            );
        }
    }

    /**
     * Get categories
     * @param {CategoryFilters} additionalFilters
     * @private
     */
    protected _getCategories(additionalFilters?: CategoryFilters): void {

        const filters = {
            ...this.paginatorController.requestParams,
            ...additionalFilters,
            [this.filterNames.OrderBy]: Helpers.getOrderFormat(this.defaultOrder)
        };

        Utils.lodash.forEach(this.filtersFormGroup.getRawValue(), (value, filterName) => {
            if (!!value) {

                if (filterName === this.filterNames.OrderBy) {
                    value = Helpers.getOrderFormat(value);
                }

                filters[filterName] = value;
            }
        });

        const loader = this.openModal.loader(DialogLoaderComponent);
        this.categoriesService.getCategories(filters).then((response: Categories) => {
            this.paginatorController.paginationOptions.length = response.count;

            if (!!this.collectionList) {
                this.collectionList.clearSelection();
            }

            loader.close();
        }).catch((error) => {

            loader.afterClosed().subscribe(() => {
                this.componentHelpers.failedApiAction(error, {
                    messageKey: 'core.getItems.error'
                });
            });

            loader.close();
        });
    }

    /**
     * Return category params
     * @returns {CategoryFilters}
     * @private
     */
    protected _getCategoryParams(): CategoryFilters {
        let params;

        switch (this.tab.type) {
            case this.categoryTypeGeneric:
                params = {
                    type: this.categoryTypeGeneric
                };
                break;
            case this.categoryTypeCustom:
                params = {
                    type: this.categoryTypeCustom
                };
                break;
            default:
                params = {};
        }

        return params;
    }
}
