import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';

export const WIDGET_METRIC_OPTION_ENGAGEMENT: WidgetMetricOptionType = {
    id: 'engagement-engagement',
    name: 'Engagement',
    resource: 'analytics.analytics.pageEngagement',
    socialChannel: 'facebook'
};

export const WIDGET_METRIC_OPTION_ENGAGEMENT_RATE: WidgetMetricOptionType = {
    id: 'engagement-engagement-rate',
    name: 'Engagement rate',
    resource: 'analytics.analytics.pageEngagementRate',
    socialChannel: 'facebook'
};

// METRIC CONFIGS

export const MetricEngagementConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_ENGAGEMENT.id,
    apiURL: '/page-engagement',
    contentType: 'chart',
    dataName: 'Total Engagement',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
};

export const MetricEngagementRateConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_ENGAGEMENT_RATE.id,
    apiURL: '/page-engagement-rate',
    contentType: 'chart',
    dataName: 'Engagement rate',
    socialType: 'facebook',
    chartConfig: {
        ...BaseChartConfig
    },
};
