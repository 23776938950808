import {PartnerRule, PeriodUnit} from '~/src/app/shared/types/partners.model';
import {
    PARTNER_BOOLEAN_PERMISSIONS,
    PARTNER_MAX_LIMIT_GENERIC_MEDIA,
    PARTNER_MAX_LIMIT_GENERIC_TEMPLATE,
    PARTNER_MAX_LIMIT_POST_FROM_RSS,
    PARTNER_MAX_LIMIT_RSS_URL,
    PARTNER_MAX_LIMIT_ORGANIZATION,
    PARTNER_MAX_LIMIT_SOCIAL_SITES,
    PARTNER_MAX_LIMIT_USERS,
    PARTNER_PERIOD_MEDIA,
    PARTNER_PERIOD_POST_TEMPLATE,
    PARTNER_PERIOD_RSS_FEED
} from '~/src/app/modules/administration/partners/partner.constant';
import {Helpers} from '~/src/app/services/helpers';

export const PERIOD_UNIT_HOURLY = 'hourly';
export const PERIOD_UNIT_DAILY   = 'daily';
export const PERIOD_UNIT_WEEKLY  = 'weekly';
export const PERIOD_UNIT_MONTHLY = 'monthly';
export const PERIOD_UNIT_YEARLY  = 'yearly';

export const PARTNER_STATUS_ACTIVE = 'active';
export const PARTNER_STATUS_INACTIVE = 'inactive';

export const PARTNER_UNLIMITED_KEY = 'partner.info.unlimited';

export const PartnerControlNames = {
    //  partner data
    Name: 'partnerName',
    Logo: 'logo',
    BitlyToken: 'bitlyToken',
    CustomerID: 'zohoCustomerID',
    LinkShortener: 'linkShorten',

    //  first user data
    FirstName: 'firstName',
    LastName: 'lastName',
    Email: 'email',

    //  rules
    Rules: 'rules',

    //  restrictions
    PostTemplateNumber: PARTNER_MAX_LIMIT_GENERIC_TEMPLATE,
    MediaNumber: PARTNER_MAX_LIMIT_GENERIC_MEDIA,
    RssUrlNumber: PARTNER_MAX_LIMIT_RSS_URL,
    PostFromRssNumber: PARTNER_MAX_LIMIT_POST_FROM_RSS,
    UserNumber: PARTNER_MAX_LIMIT_USERS,
    SocialSiteNumber: PARTNER_MAX_LIMIT_SOCIAL_SITES,
    OrganizationNumber: PARTNER_MAX_LIMIT_ORGANIZATION,
    //  settings
    PostTemplatePeriod: PARTNER_PERIOD_POST_TEMPLATE,
    MediaPeriod: PARTNER_PERIOD_MEDIA,
    RssFeedPeriod: PARTNER_PERIOD_RSS_FEED
};

export const PartnerRules: PartnerRule[] = (() => {
    const result = [];

    for (const configName of PARTNER_BOOLEAN_PERMISSIONS) {
        result.push({
            id: configName,
            nameKey: 'partner.rule.' + configName
        });
    }

    return Helpers.orderBy(result, 'id');
})();

export const PeriodUnits: PeriodUnit[] = [
    // {id: PERIOD_UNIT_HOURLY, nameKey: 'partner.period.unit.hourly'},
    // {id: PERIOD_UNIT_DAILY, nameKey: 'partner.period.unit.daily'},
    // {id: PERIOD_UNIT_WEEKLY, nameKey: 'partner.period.unit.weekly'},
    {id: PERIOD_UNIT_MONTHLY, nameKey: 'partner.period.unit.monthly'},
    {id: PERIOD_UNIT_YEARLY, nameKey: 'partner.period.unit.yearly'},
];
