import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogCreateAnalyticsComponent} from './create-widget/dialog-create-analytics.component';
import {WidgetInterface} from './analytics.interfaces';
import {NgxDrpOptions, PresetItem, Range} from 'ngx-mat-daterange-picker';
import {DialogConfirmComponent} from '../../components/dialog-confirm/dialog-confirm.component';
import {LanguageService} from '../../services/language.service';
import {CreateDashboardModalComponent} from './modals/create-dashboard-modal/create-dashboard-modal.component';
import {CreateDashboard, Dashboard} from './dashboard.interfaces';
import {AnalyticsService} from './analytics.service';
import {NotifyService} from '~/src/app/services/notify.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UpdateDashboardModalComponent} from '~/src/app/modules/analytics/modals/update-dashboard-modal/update-dashboard-modal.component';
import {BaseWidgetEntity} from '~/src/app/modules/analytics/widget/base-widget.entity';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {cloneDeep, map, find} from 'lodash';
import * as moment from 'moment';
import {FormValidationService} from '~/src/app/services/form.validation.service';
import * as md5 from 'md5';
import {Helpers} from '~/src/app/services/helpers';
import {Widget, WidgetEditEvent} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {ExportDashboardModalComponent} from '~/src/app/modules/analytics/modals/export-dashboard-modal/export-dashboard-modal.component';
import {ElementRef, ViewChild} from '~/node_modules/@angular/core';
import {SocialSiteController} from '~/src/app/components/social-site-select/social-site.component';
import {SocialSiteInterface} from '~/src/app/components/social-site-select/social-site-select.component';
import {GridStackService} from '~/src/app/core/services/grid-stack.service';
import Utils from '~/src/app/core/utils';
import {BASE_DATE_FORMAT} from '~/src/app/configs/configs';
import {Subject} from '~/node_modules/rxjs';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {ShareDashboardComponent} from '~/src/app/modules/analytics/share-dashboard/share-dashboard.component';
import {DashboardConnectionType} from '~/src/app/modules/analytics/share-dashboard/share-dashboard';
import {DialogErrorComponent} from '~/src/app/components/dialog-error/dialog-error.component';
import { DuplicateDashboardModalComponent } from './modals/duplicate-dashboard-modal/duplicate-dashboard-modal.component';
import { activeFromDatMinDate } from '../social-media-post/social-media-post.constant';
import { OrganizationController } from '../../components/organization-select/organization.component';

/**
 * Autosave period in ms
 * @type {number}
 */
const AUTOSAVE_PERIOD = 10000;

@Component({
    selector: 'smd-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    providers: [
        AnalyticsService,
        GridStackService
    ]
})
export class AnalyticsComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('screen', {static: true}) screen: ElementRef;

    widgets = []; // (ChartWidgetEntity | TextWidgetEntity)[] = [];
    range: Range = {fromDate: new Date(), toDate: new Date()};
    dateRangeOptions: NgxDrpOptions;
    presets: Array<PresetItem> = [];

    dashboards: Dashboard[] = [];
    dashboardGroupNames: string[] = [];
    groupedDashboard: {[p: string]: Dashboard[]} = {};
    selectedDashboardID: number = null;
    currentDashboard: Dashboard;

    preLoader: MatDialogRef<DialogLoaderComponent>;
    currentDashboardBackup: string;
    saveDashboardInProgress = false;
    widgetCounter = 0;
    analyticsPageLoaded = false;
    isLoaded = false;
    loadingWidgets = false;
    socialSites: SocialSiteInterface[] = [];
    organizations = [];
    getMetrics$ = new Subject();

    waitingSites = [];

    analyticsConnectionWorking = true;

    autoSaveInitialized = false;

    private savedDateRangeKey = 'analyticsDateRange';

    constructor(
        public dialog: MatDialog,
        public language: LanguageService,
        private analyticsService: AnalyticsService,
        public _dialog: MatDialog,
        private socialSiteController: SocialSiteController,
        public organizationController: OrganizationController,
        private gridStackService: GridStackService,
        private _openModal: OpenModalService
    ) {
        this.initSocialSites();
        this.initOrganizations();

        this.healthCheckAnalytics();

        this.gridStackService.valueChange.subscribe(properties => {
            this.currentDashboard.data.widgets = map(this.currentDashboard.data.widgets, (currentWidget) => {
                const widget = find(properties.items, ['widgetID', currentWidget.id]);

                currentWidget.size = {width: widget.width, height: widget.height};
                currentWidget.position = {...widget.position, x: widget.x, y: widget.y};

                return currentWidget;
            });
        });
    }

    ngOnInit() {
        this.setDateRangeOptions();
        /*
            It fixies the following error:
            Expression has changed after it was checked. Previous value: 'id: undefined'. Current value: 'id: mat-dialog-0'.
         */
        setTimeout(() => {
            this.getDashboards();
        });
    }

    ngAfterViewInit(): void {
        this.gridStackService.initEventListeners(true);
    }

    ngOnDestroy(): void {
        this.gridStackService.removeEventListeners();
    }

    /**
     * Return current dashboard is editable
     *
     * @returns {boolean}
     */
    currentDashboardIsEditable(): boolean {
        return this.currentDashboard && this.currentDashboard.editable;
    }

    /**
     * Return current dashboard is deleteable
     *
     * @returns {boolean}
     */
    currentDashboardIsDeleteable(): boolean {
        return this.currentDashboard && this.currentDashboard.deleteable;
    }

    /**
     * Return current dashboard is mine
     *
     * @returns {boolean}
     */
    currentDashboardIsMine(): boolean {
        return this.currentDashboard && this.currentDashboard.userAuthorizationLevel === DashboardConnectionType.Owner;
    }

    /**
     * Dashboard groupBy function
     *
     * @param {Dashboard} item
     * @returns {string}
     */
    dashboardGroupByFn(item: Dashboard): string {
        const dashboardGroupNameLabelsMap = {
            [DashboardConnectionType.Owner]: 'analytics.dashboard.reports.label',
            [DashboardConnectionType.User]: 'analytics.dashboard.reports.sharedWithMe.label',
        };

        return LanguageService.getLine(dashboardGroupNameLabelsMap[item.userAuthorizationLevel]);
    }

    widgetInitialized() {
        this.countWidgets();
    }

    getSocialSitesBySiteID(siteID: number) {
        return this.socialSites.filter(item => String(item.siteID) === String(siteID));
    }

    /**
     * Open share dashboard(s) modal
     * @param {MouseEvent} event
     */
    shareDashboards(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this._openModal.shareDashboards(ShareDashboardComponent, {
            data: {
                defaultDashboardIDs: this.currentDashboard.dashboardID || [],
                afterSuccessSave: () => {
                    this.getDashboards();
                }
            }
        });
    }

    openCreateDashboardDialog() {
        const createDashboardModal = this.dialog.open(CreateDashboardModalComponent, {
            width: '700px',
            data: {
                title: LanguageService.getLine('analytics.create.new.dashboard')
            }
        });

        createDashboardModal.afterClosed().subscribe(() => this.preloaderStop());
        createDashboardModal.componentInstance.submitEvent.subscribe((dashboard: CreateDashboard) => {
            this.preloaderStart();
            this.analyticsService.createDashboard(dashboard).then((dashboardResponse: { dashboard: Dashboard }) => {
                const newDashboard: Dashboard = dashboardResponse.dashboard;

                NotifyService.success(
                    this.language.getLine('analytics.dashboard.create.success'),
                    ''
                );

                this.dashboards = [...this.dashboards, newDashboard];

                this.preLoader.afterClosed().subscribe(() => {
                    this.getDashboards(newDashboard);
                });

                createDashboardModal.close();
            }, (error: HttpErrorResponse) => {
                this.preloaderStop();
                NotifyService.error('', error.error.error.message);
            });
        });
    }

    openEditDashboardDialog() {
        if (this.dashboards.length === 0) {
            NotifyService.info('', this.language.getLine('analytics.dashboard.error.notfound'));

            return;
        }

        const createDashboardModal = this.dialog.open(UpdateDashboardModalComponent, {
            width: '700px',
            data: {
                title: this.language.getLine('analytics.dashboard.edit'),
                dashboard: cloneDeep(this.currentDashboard)
            }
        });

        createDashboardModal.componentInstance.submitEvent.subscribe((dashboard: Dashboard) => {
            if (dashboard) {
                this.currentDashboard.name = dashboard.name;
                const dashboardIndex = this.dashboards.findIndex(item => item.dashboardID === this.currentDashboard.dashboardID);
                if (dashboardIndex > -1) {
                    const _dashboard = this.dashboards[dashboardIndex];
                    _dashboard.name = dashboard.name;
                    this.dashboards = this.dashboards.map(item => item);
                }
                this.updateDashboard()
                    .then(() => createDashboardModal.close())
                    .catch(() => createDashboardModal.close());
            }
        });
    }

    openDuplicateDashboardDialog() {
        if (this.dashboards.length === 0) {
            NotifyService.info('', this.language.getLine('analytics.dashboard.error.notfound'));

            return;
        }

        const duplicateDashboardModal = this.dialog.open(DuplicateDashboardModalComponent, {
            width: '700px',
            data: {
                title: this.language.getLine('analytics.dashboard.edit'),
                dashboard: cloneDeep(this.currentDashboard)
            }
        });

        // duplication basically creates a new dashboard with the given name,
        // then copies all widgets from the current dashboard to the new one with new ids
        duplicateDashboardModal.afterClosed().subscribe(() => this.preloaderStop());
        duplicateDashboardModal.componentInstance.submitEvent.subscribe((duplicatedDashboard: Dashboard) => {
            if (!duplicatedDashboard) {
                return;
            }

            this.preloaderStart();
            this.analyticsService.createDashboard({name: duplicatedDashboard.name}).then((dashboardResponse: { dashboard: Dashboard }) => {
                const newDashboard: Dashboard = {...duplicatedDashboard, dashboardID: dashboardResponse.dashboard.dashboardID, name: dashboardResponse.dashboard.name, data: duplicatedDashboard.data};

                const dataBefore = cloneDeep(duplicatedDashboard.data);

                this.dashboards = [...this.dashboards, newDashboard];
                this.currentDashboard = newDashboard;
                this.selectedDashboardID = newDashboard.dashboardID;

                if (this.currentDashboard.dashboardID === newDashboard.dashboardID) {
                    this.updateDashboard().then(() => {
                        this.changeSelectedDashboard(newDashboard);
                        this.currentDashboard.data = dataBefore;
                        NotifyService.success(this.language.getLine('analytics.dashboard.duplicate.success'), '');
                        this.updateDashboard()
                        duplicateDashboardModal.close();
                    });
                    return;
                }
            }, (error: HttpErrorResponse) => {
                this.preloaderStop();
                NotifyService.error('', error.error.error.message);
            });
        });
    }

    openDeleteDashboardConfirm() {
        if (this.dashboards.length === 0) {
            NotifyService.info('', this.language.getLine('analytics.dashboard.error.notfound'));

            return;
        }
        const confirmRef = this.dialog.open(DialogConfirmComponent, {
            data: {
                message: this.language.getLine('analytics.dashboard.delete.confirm'),
                yesButtonText: this.language.getLine('analytics.dashboard.delete.confirm.yes')
            }
        });

        confirmRef.afterClosed().subscribe(isYes => {
            if (isYes) {
                const relevantDashboard = this.dashboards.find(dashboard => dashboard.dashboardID === this.selectedDashboardID);

                this.preloaderStart();
                this.analyticsService.deleteDashboard(
                    relevantDashboard
                ).then(() => {
                    NotifyService.success(
                        this.language.getLine('analytics.dashboard.delete.success'),
                        ''
                    );
                    
                    this.dashboards = this.dashboards.filter(dashboard => dashboard.dashboardID !== relevantDashboard.dashboardID);

                    const defaultDashboard = this.getDefaultDashboard();

                    if (defaultDashboard) {
                        this.changeSelectedDashboard(defaultDashboard.dashboardID);
                    } else {
                        this.widgets = [];
                        delete this.currentDashboard;
                        delete this.currentDashboardBackup;
                        delete this.selectedDashboardID;
                    }

                    this.preloaderStop();
                }, (error: HttpErrorResponse) => {
                    this.preloaderStop();
                    NotifyService.error('', FormValidationService.readError(error).message);
                });
            }
        });
    }

    changeSelectedDashboard(selectedDashboardID: any) {

        if (selectedDashboardID instanceof Object) {
            selectedDashboardID = selectedDashboardID.dashboardID;
        }

        if (!selectedDashboardID) {
            return;
        }

        const _selectedDashboardID = selectedDashboardID || this.selectedDashboardID || this.dashboards[0].dashboardID;
        const foundDashboard = cloneDeep(this.dashboards.find(item => item.dashboardID === _selectedDashboardID));

        localStorage.setItem('selectedDashboardID', _selectedDashboardID + '');

        if (!foundDashboard) {
            NotifyService.info(this.language.getLine('analytics.dashboard.error.notfound'), '');
        } else {
            this.widgets = [];
            this.currentDashboard = foundDashboard;
            this.selectedDashboardID = _selectedDashboardID;
        
            this.loadingWidgets = true;
            this.analyticsService.getDashboard(foundDashboard).then((response) => {
                this.currentDashboard.data = response.dashboard.data;

                if (!this.currentDashboard.data && response.dashboard.data) {
                    this.currentDashboard.data = response.dashboard.data;
                }

                if (!this.currentDashboard.data) {
                    this.currentDashboard.data = {widgets: []};
                } else if (!this.currentDashboard.data.widgets) {
                    this.currentDashboard.data.widgets = [];
                }
    
                const data = (typeof this.currentDashboard.data === 'string') ?
                    JSON.parse(this.currentDashboard.data) : this.currentDashboard.data;
                
                this.widgets = data.widgets.map(widget => new BaseWidgetEntity(widget)) || [];
                this.saveDashboardBackup();
            }).finally(() => {
                this.loadingWidgets = false;
                return;
            });
        }
    }

    getDashboards(selectedDashboard?: Dashboard) {
        this.preloaderStart();

        return this.analyticsService.getAll().then(({dashboards}: { dashboards: Dashboard[] }) => {
            this.dashboards = dashboards.map(dashboard => {
                const data = dashboard.data ? dashboard.data : {"widgets":[]};

                /* dashboard.data = JSON.parse(data); */
                dashboard.data = data;

                // set dashboard group names
                if (!this.dashboardGroupNames.includes(dashboard.userAuthorizationLevel)) {
                    this.dashboardGroupNames.push(dashboard.userAuthorizationLevel);
                }

                // group dashboards
                this.groupedDashboard[dashboard.userAuthorizationLevel] = this.groupedDashboard[dashboard.userAuthorizationLevel] || [];
                this.groupedDashboard[dashboard.userAuthorizationLevel].push(dashboard);

                return dashboard;
            });
            const defaultDashboard: Dashboard = selectedDashboard || this.getDefaultDashboard();

            if (defaultDashboard) {
                this.changeSelectedDashboard(defaultDashboard.dashboardID);
            }

            this.preloaderStop();

            if (!this.autoSaveInitialized) {
                this.autosave();
            }

        }).catch(error => {
            this.preLoader.afterClosed().subscribe(() => {
                this._openModal.errorModal(DialogErrorComponent, {
                    message: FormValidationService.readError(error).message || LanguageService.getLine('item.chooser.noItems')
                });
            });

            this.preloaderStop();
        });
    }

    /**
     * @param widget
     */
    createWidget(widget) {
        widget.position = {
            x: 0,
            y: this.getLastWidgetBottom()
        };

        widget.size = {
            width: 4,
            height: 4
        };

        this.currentDashboard.data.widgets.push(widget);
        this.preloaderStart();
        this.analyticsService.updateDashboard(this.currentDashboardRaw).then(() => {
            this.addCurrentDashboardToDashboards();
            this.widgets.push(new BaseWidgetEntity(widget));
            this.preloaderStop();
        }).catch(errorResponse => {
            const widgetIndex = this.currentDashboard.data.widgets.findIndex(item => item.id === widget.id);

            if (widgetIndex > -1) {
                this.currentDashboard.data.widgets.splice(widgetIndex, 1);
            }

            this.preloaderStop();
            NotifyService.error('', FormValidationService.readError(errorResponse).message);
        });
    }

    onWidgetEdit(event: WidgetEditEvent) {
        const widgetIndex = this.currentDashboard.data.widgets.findIndex(item => item.id === event.id);
        const widget: Widget = this.currentDashboard.data.widgets[widgetIndex];

        const widgetIndexInList = this.widgets.findIndex(item => item.id === event.id);

        widget.name = event.name;
        widget.socialSiteID = event.socialSiteID;
        widget.metrics = event.metrics;
        widget.socialChannel = event.socialChannel;
        widget.organizations = event.organizations;
        widget.metricModifiers = event.metricModifiers;

        if (event.content) {
            widget.content = event.content;
        }

        widget.dateRange = {
            fromDate: Utils.get<Range>(widget.dateRange, 'fromDate', null),
            toDate: Utils.get<Range>(widget.dateRange, 'toDate', null)
        };

        if (event.from) {
            widget.dateRange.fromDate = event.from as Date;
        }

        if (event.to) {
            widget.dateRange.toDate = event.to as Date;
        }

        this.currentDashboard.data.widgets.splice(widgetIndex, 1, widget);

        const widgetEntity = this.widgets[widgetIndexInList];

        if (widgetEntity instanceof Object) {
            Object.assign(widgetEntity, widget);
        }
    }

    updateDashboard(showLoader: boolean = true) {
        if (!this.currentDashboardIsEditable()) {
            return;
        }

        if (showLoader) {
            this.preloaderStart();
        }

        this.saveDashboardInProgress = true;
        return this.analyticsService.updateDashboard(this.currentDashboardRaw).then(() => {
            this.saveDashboardBackup();
            this.addCurrentDashboardToDashboards();

            if (showLoader) {
                this.preloaderStop();
            }
            this.saveDashboardInProgress = false;
        }).catch(errorResponse => {
            NotifyService.error('', FormValidationService.readError(errorResponse).message);

            if (showLoader) {
                this.preloaderStop();
            }
            this.saveDashboardInProgress = false;
        });
    }

    deleteWidget(widget: WidgetInterface) {
        const index = this.widgets.findIndex(item => item.id === widget.id);
        this.widgets.splice(index, 1);
        this.currentDashboard.data.widgets.splice(index, 1);

        this.updateDashboard();
    }

    updateRange(range) {
        localStorage.setItem(this.savedDateRangeKey, JSON.stringify(range));
        this.range = range;
        this.getMetrics$.next({
            dateRange: range
        });
    }

    openNewWidgetDialog() {
        if (this.dashboards.length === 0) {
            NotifyService.info('', this.language.getLine('analytics.dashboard.error.notfound'));

            return;
        }
        this.dialog.open(DialogCreateAnalyticsComponent).afterClosed().subscribe(widget => {
            if (widget) {
                this.createWidget(widget);
            }
        });
    }

    setupPresets() {
        const backDate = (numOfDays) => {
            const _today = new Date();

            return new Date(_today.setDate(_today.getDate() - numOfDays));
        };

        const today = new Date();
        const yesterday = backDate(1);
        const minus7 = backDate(7);
        const minus30 = backDate(30);
        const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const currMonthEnd = new Date(Utils.moment().subtract(1, 'days').format(BASE_DATE_FORMAT));
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        this.presets = [
            {presetLabel: 'Yesterday', range: {fromDate: yesterday, toDate: yesterday}},
            {presetLabel: 'Last 7 Days', range: {fromDate: minus7, toDate: yesterday}},
            {presetLabel: 'Last 30 Days', range: {fromDate: minus30, toDate: yesterday}},
            {presetLabel: 'This Month', range: {fromDate: currMonthStart, toDate: currMonthEnd}},
            {presetLabel: 'Last Month', range: {fromDate: lastMonthStart, toDate: lastMonthEnd}}
        ];
    }

    exportDashboard() {
        this._dialog.open(ExportDashboardModalComponent, <any> {
            disableClose: true,
            hasBackdrop: true,
            width: '500px',
            margin: '0 auto',
            data: {
                screen: this.screen,
                dashboardname: this.currentDashboard.name,
                interval: this.range
            }
        });
    }

    countWidgets() {
        this.widgetCounter += 1;
        if (this.widgetCounter === this.widgets.length) {
            this.analyticsPageLoaded = true;
        }
    }

    private initSocialSites() {
        this.socialSiteController.getItems(
            response => {
                this.socialSites = Helpers.orderBy(response.socialSites, 'name');

                this.getMetrics$.next();
            }
        );

        this.analyticsService.getUnfinishedSocialSites().then(
            response => {
                if (response && response.waitingSites && response.waitingSites.length) {
                    this.waitingSites = response.waitingSites;
                }
            }
        );
    }

    /**
     * Get organizations
     */
    private initOrganizations() {
        this.organizationController.getItems((response) => {
            const orgIDs = response.organizations.map(org => org.organizationID);
            this.organizations = orgIDs;
        });
    }

    /**
     * @description get the last used dashboard or the first of the list.
     */
    private getDefaultDashboard(): Dashboard {
        if (this.dashboards.length === 0) {
            return null;
        }

        const selectedDashboardID = localStorage.getItem('selectedDashboardID') ?
            parseInt(localStorage.getItem('selectedDashboardID'), 10) : this.dashboards[0].dashboardID;
        const defaultDashboard = this.dashboards.find(dashboard => dashboard.dashboardID === selectedDashboardID) ||
            this.dashboards[0];

        return defaultDashboard;
    }

    private preloaderStart() {
        if (!this.preLoader) {
            this.preLoader = this._dialog.open(DialogLoaderComponent, {
                disableClose: true,
                panelClass: 'dialog-loader-modal',
                minWidth: '200vw',
                minHeight: '200vh',
                hasBackdrop: false
            });
        }
    }

    private preloaderStop() {
        if (this.preLoader) {
            this.preLoader.close();
            this.preLoader = null;
        }
    }

    private setDateRangeOptions() {
        const savedDateRange = localStorage.getItem(this.savedDateRangeKey);

        const year = moment().format('YYYY');
        const month = moment().format('MM');
        const lastDayOfMonth = moment(new Date(Number(year), Number(month), 0)).format('DD');
        const defaultFrom = new Date(`${year}-${month}-01`);
        const defaultTo = new Date(Utils.moment().subtract(1, 'days').format(BASE_DATE_FORMAT));
        const defaultRange = !!savedDateRange ? JSON.parse(savedDateRange) : {fromDate: defaultFrom, toDate: defaultTo};

        // min date is 10 years before
        const minDate = new Date(Utils.moment().subtract(10, 'years').format(BASE_DATE_FORMAT));
        // max date is yesterday
        const maxDate = new Date(Utils.moment().subtract(1, 'days').format(BASE_DATE_FORMAT));

        this.setupPresets();

        this.dateRangeOptions = {
            presets: this.presets,
            format: 'dd MMMM yyyy',
            range: defaultRange,
            applyLabel: 'Submit',
            calendarOverlayConfig: {
                shouldCloseOnBackdropClick: false,
                hasBackdrop: true // do not change to false, because it is buggy
            },
            fromMinMax: {fromDate: minDate, toDate: maxDate},
            toMinMax: {fromDate: minDate, toDate: maxDate}
        };
    }

    private saveDashboardBackup() {
        this.currentDashboardBackup = md5(JSON.stringify(this.currentDashboardRaw));
    }

    private getLastWidgetBottom() {
        let height = 0;
        let maxTop = 0;
        const items: NodeList = document.querySelector(
            this.gridStackService.gridStackSelector
        ).querySelectorAll(this.gridStackService.gridStackItemsSelector);

        for (let i = 0; i < items.length; ++i) {
            const data = items.item(i)['dataset'];

            if (maxTop < Number(data.gsY)) {
                maxTop = Number(data.gsY);
                height = Number(data.gsHeight);
            } else if (height < Number(data.gsHeight)) {
                height = Number(data.gsHeight);
            }
        }

        return maxTop + height;
    }

    private addCurrentDashboardToDashboards() {
        const index = this.dashboards.findIndex(item => item.dashboardID === this.currentDashboard.dashboardID);

        if (index > -1) {
            //this.dashboards.splice(index, 1, this.currentDashboard);
            this.dashboards[index] = this.currentDashboard;
        }
    }

    private autosave() {
        if (this.isSaveable && !this.loadingWidgets && this.widgets?.length > 0) {
            this.updateDashboard(false);
        }

        setTimeout(() => {
            this.autosave();
        }, AUTOSAVE_PERIOD);

        this.autoSaveInitialized = true;
    }

    get currentDashboardRaw() {
        const dashboard = cloneDeep(this.currentDashboard);

        dashboard.data.widgets.map(widget => {
            if (widget.chart && widget.content) {
                delete widget.content;
            }

            if (widget && widget.chart) {
                delete widget.chart;
            }

            return widget;
        });
        dashboard.data = JSON.stringify(dashboard.data);

        return dashboard;
    }

    get isSaveable() {
        if (this.currentDashboardBackup && this.currentDashboardRaw) {
            return this.currentDashboardBackup !== md5(JSON.stringify(this.currentDashboardRaw));
        } else {
            return false;
        }
    }

    private healthCheckAnalytics() {
        this.analyticsService.healthCheckAnalytics().then(
            response => {
                this.analyticsConnectionWorking = response;
            }
        );
    }
}
