import {Injectable} from '~/node_modules/@angular/core';
import {SocialPlatform} from '~/src/app/shared/types';
import {SOCIAL_PLATFORMS_DEFAULT_CONFIG} from '~/src/app/configs/configs';

@Injectable()
export class SocialPlatformService {
    public storageKey = 'socialPlatformsSwitcher';
    public platformsDefault: Array<SocialPlatform> = SOCIAL_PLATFORMS_DEFAULT_CONFIG;
    public platforms;
    
    private socialPlatformsSwitcherConfig =localStorage.getItem(this.storageKey);
    
    constructor() {
        this.platforms = this.socialPlatformsSwitcherConfig ? JSON.parse(this.socialPlatformsSwitcherConfig) : this.platformsDefault;
    }
}
