import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EmojiEvent} from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Injectable({providedIn: 'root'})
export class EmojiPickerService {
    emojiPickerVisibility: BehaviorSubject<boolean>;
    style = {};
    private currentEditor;

    constructor() {
        this.emojiPickerVisibility = new BehaviorSubject<boolean>(false);
    }

    show(editor, button: HTMLElement) {
        this.currentEditor = editor;
        this.emojiPickerVisibility.next(true);

        const buttonPos = button.getBoundingClientRect();
        const bodyBottom = window.innerHeight;
        const top = buttonPos.bottom;
        const left = (buttonPos.left + (buttonPos.width / 2)) + 'px';
        const isOuterWindow = (top + 427) > bodyBottom;
        const commonStyle = {
            position: 'fixed',
            transform: 'translateX(-50%)',
            left
        };

        // on mobile view
        if (window.innerWidth <= 568) {
            this.style = {
                ...commonStyle,
                left: '50%',
                top: 0,
                width: 'calc(100vw - 10px)',
                overflow: 'auto'
            };
            return;
        }

        if (isOuterWindow) {
            this.style = {
                ...commonStyle,
                bottom: 0,
            };
        } else {
            this.style = {
                ...commonStyle,
                top: top + 'px'
            };
        }
    }

    hide() {
        this.currentEditor = null;
        this.emojiPickerVisibility.next(false);
    }

    emojiSelection(event: EmojiEvent) {
        if (this.currentEditor) {
            this.currentEditor.insertContent(event.emoji.native);
        }
    }
}
