import {HttpClient, HttpParams} from '@angular/common/http';
import {Helpers} from './helpers';
import {Token} from './token';
import {Observable} from 'rxjs/index';
import {DialogErrorComponent} from '../components/dialog-error/dialog-error.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormValidationService} from './form.validation.service';
import {environment} from '~/src/environments/environment';
import {has, includes, forEach} from 'lodash';
import {DialogLoaderComponent} from '~/src/app/components/dialog-loader/dialog-loader.component';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';

export abstract class ModelAbstract {

    /**
     * API url
     */
    private _apiLink: string;
    private promiseBackup = {};
    private _loader = false;
    _loaderRef: MatDialogRef<DialogLoaderComponent>;
    isPending = false;
    isEditPending = false;

    protected constructor(
        private _http: HttpClient,
        private _dialog: MatDialog
    ) {
    }

    /**
     * Get items
     *
     * @param {object} filters
     * @return {Promise<any>}
     */
    getAll(filters?: object | string, queryConvert = true): Promise<any> {
        const minimalData = (filters && has(filters, 'minimalData')); // if its set, we will get simplified data

        if (minimalData) {
            delete filters['minimalData'];
        }

        const query = queryConvert ? (filters) ? Helpers.getHttpQuery(filters as object) : '' : '?' + filters;

        const url = this.apiLink + (minimalData ? '/minimal' : '') + query;
        if (has(this.promiseBackup, url)) {
            return this.promiseBackup[url];
        } else {
            this.promiseBackup[url] = this._http.get(url, Helpers.getBaseHttpHeaders(Token.getToken()))
            .toPromise()
            .then(response => {
                delete this.promiseBackup[url];
                return response;
            })
            .catch(error => {
                delete this.promiseBackup[url];
                return error;
            });
            return this.promiseBackup[url];
        }
    }

    /**
     * Get items by IDs
     *
     * @param {string} name
     * @param {number[]} IDs
     * @return {Promise<any>}
     */
    getByIDs(name: string, IDs: any[], filters = null): Promise<any> {
        const query = (filters) ? Helpers.getHttpQuery(filters).replace('?', '&') : '';
        return this._http.get(this.apiLink + this.arrayToQuery(name, IDs) + query, Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Create new item
     *
     * @param {object} item
     * @return {Promise<any>}
     */
    create(item: object | FormData): Promise<any> {
        return this._http.post(this.apiLink, Helpers.objectToFormData(item), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Create new item with progress
     *
     * @param {object} item
     * @return {Observable<any>}
     */
    createWithProgress(item: object): Observable<any> {
        const options = Helpers.getBaseHttpHeaders(
            Token.getToken(),
            {
              reportProgress: true,
              observe: 'events'
            }
        );

        return this._http.post(this.apiLink, Helpers.objectToFormData(item), options);
    }

    /**
     * Edit item
     *
     * @param {number} id
     * @param {object} item
     * @return {Promise<any>}
     */
    edit(id: number, item: object): Promise<any> {
        this.isEditPending = true;

        return this._http.put(this.apiLink + `/${id}`, Helpers.objectToFormData(item), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise().then(response => {
            this.isEditPending = false;
            return response;
        }).catch(error => {
            this.isEditPending = false;
            return error;
        });
    }

    /**
     * Edit item with post request
     *
     * @param {number} id
     * @param {object} item
     * @return {Promise<any>}
     */
    editWithPost(id: number | string, item: object | FormData): Promise<any> {
        return this._http.post(this.apiLink + `/${id}`, Helpers.objectToFormData(item), Helpers.getBaseHttpHeaders(Token.getToken()))
        .toPromise();
    }

    /**
     * Delete item
     *
     * @param {number} id
     * @return {Promise<any>}
     */
    deleteItem(id: number): Promise<any> {
        return this._http.delete(this.apiLink + `/${id}`, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }

    /**
     * Get api link
     *
     * @return {string}
     */
    protected get apiLink(): string {
        return this._apiLink;
    }

    /**
     * Set api link
     *
     * @param {string} value
     */
    protected set apiLink(value: string) {
        if (includes(value, environment.apiUrl)) {
            this._apiLink = value;
        } else {
            this._apiLink = environment.apiUrl + value;
        }
    }

    arrayToQuery(name: string, array: any[]): string {
        let query = '?';
        let index = 0;

        for (const item of array) {
            index++;

            query += `${name}[]=${item}`;

            if (index < array.length) {
                query += '&';
            }
        }

        return !!query.length ? query : '';
    }


    showErrorAlert(error) {
        const errorMessages = FormValidationService.readError(error);
        let errorMessage = errorMessages.message ? errorMessages.message : "Unknown error";

        if (errorMessages && errorMessages.formMessages) {
            if (typeof errorMessages.formMessages === "string") {
                errorMessage += " " + errorMessages.formMessages;
            } else if (typeof errorMessages.formMessages === "object") {
                if (Object.keys(errorMessages.formMessages).length) {
                    forEach(Object.keys(errorMessages.formMessages), (key) => {
                        errorMessage += " " + errorMessages.formMessages[key];
                    });
                }
            }
        }

        return this._dialog.open(DialogErrorComponent, {
            data: {
                message: errorMessage
            }
        });
    }

    getParams(query) {
        let params: HttpParams = new HttpParams();
        for (const key of Object.keys(query)) {
            if (query[key]) {
                // jesus christ mega fuck
                try {
                    query[key] = JSON.parse(query[key]);
                } catch (e) {
                    query[key] = query[key];
                }
                if (query[key] instanceof Array) {
                    query[key].forEach((item, index) => {
                        params = params.append(key.toString() + '[' + index + ']', item);
                    });
                } else {
                    params = params.append(key.toString(), query[key]);
                }
            }
        }
        return params;
    }

    showLoader(openModal: OpenModalService) {
        // if (this._loader) {
        //     this._loaderRef = openModal.loader(DialogLoaderComponent);
        // } else {
        //     this.isPending = true;
        // }

        this.isPending = true;
    }

    hideLoader() {
        this.isPending = false;

        // if (!!this._loaderRef) {
        //     this._loaderRef.close();
        // }
    }
}
