import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import {
    Category,
    PostTemplate,
    PostTemplateInterface,
    Tag,
} from "./template.interface";
import { PreloaderComponent } from "../../components/preloader/preloader.component";
import { TemplateActions } from "./template-actions";
import { PostActionsService } from "./post-actions.service";
import { CategoriesService } from "./categories.service";
import { TagsService } from "./tags.service";
import { TemplateActionsService } from "./template-actions.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { PostTemplateFiltersComponent } from "./post-template-filters/post-template-filters.component";
import { UsersController } from "../users/users/users.component";
import { LanguageService } from "../../services/language.service";
import { DialogConfirmComponent } from "../../components/dialog-confirm/dialog-confirm.component";
import { DialogLoaderComponent } from "../../components/dialog-loader/dialog-loader.component";
import { DialogErrorComponent } from "../../components/dialog-error/dialog-error.component";
import { DialogWarnComponent } from "../../components/dialog-warn/dialog-warn.component";
import { ActivatedRoute } from "@angular/router";
import { CustomEvents, Debounce, Helpers, LoggedUserInterface, UserOrganization } from "../../services/helpers";
import { PaginationController } from "../../services/pagination.controller";
import { PostInterface } from "./post-actions";
import { CarouselController, CarouselItem } from "./carousel/carousel.component";
import { BASE_DATETIME_FORMAT, FALLBACK_IMAGE, PLACEHOLDER_IMAGE } from "~/src/app/configs/configs";
import { ShowPreviewService } from "~/src/app/directives/show-preview/show-preview.service";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PostTemplateManagerComponent } from "~/src/app/modules/social-media-post/post-template-manager/post-template-manager.component";
import Utils from "~/src/app/core/utils";
import {
    CollectionListConfig,
    CollectionListSelectionChange,
    TableBodyConfig,
    TableHeadConfig,
} from "~/src/app/core/components/collection-list/collection-list.interfaces";
import { CollectionListComponent } from "~/src/app/core/components/collection-list/collection-list.component";
import { DataTableHelpersService } from "~/src/app/core/services/data-table-helpers.service";
import { DialogSuccessComponent } from "~/src/app/components/dialog-success/dialog-success.component";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { PostTemplateImportComponent } from "~/src/app/modules/administration/post-template-import/post-template-import.component";
import { PartnerChooserComponent } from "~/src/app/modules/administration/partners/partner-chooser/partner-chooser.component";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { BulkUploadManagerComponent } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.component";
import { BulkUploadManagerModel } from "~/src/app/modules/posts/bulk-upload/bulk-upload-manager/bulk-upload-manager.model";
import { CoreConfig } from "~/src/app/core/core.config";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import {
    POST_TEMPLATE_STATUS_ACTIVE,
    POST_TEMPLATE_STATUS_NOT_APPROVED,
    POST_TYPE_DRAFT,
} from "~/src/app/modules/social-media-post/social-media-post.constant";
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import { TemplateOrganizationChooserComponent } from "./post-template/template-organization-chooser/template-organization-chooser.component";
import { OrganizationService } from "../users/organizations/organization.service";
import { OrganizationModel } from "../users/organizations/organization.model";

import { Observable, Subject } from "rxjs";
import { IRelatedUser } from "../users/users/users.interface";
import { PostPreviewComponent } from '~/src/app/modules/posts/post-previews/post-preview/post-preview.component';
import { KeyValue } from "@angular/common";
import { PostManagerComponent } from '~/src/app/modules/social-media-post/post-manager/post-manager.component';
import * as moment from "moment";
import { SmdFile } from "../../services/file.class";
import { FILE_TYPES } from "../../components/file/types/fileTypes";
import { CarouselItemResponseInterface } from "./carousel/carousel.interface";
import { PostStatusConfigs } from "./post-card/post-card";
import { LoggedUser } from "../../services/logged-user";
import { FormControl, FormGroup } from '@angular/forms';
import { C } from "@angular/cdk/keycodes";
import { CommentsComponent } from "../social-media-post/comments/comments.component";
import { CommentController } from "./comments/post-comment/comment-controller.service";
import { CommentForViewInterface } from "~/src/app/modules/posts/comments/post-comment/interfaces";
import { MentionConfig } from "angular-mentions";
import { TemplateFolderViewComponent } from "./template-folder-view/template-folder-view.component";
import { TemplateFolderManagerComponent } from "../social-media-post/template-folder-manager/template-folder-manager.component";
import { TemplateFolderSelectComponent } from "../social-media-post/template-folder-select/template-folder-select.component";
import { TemplateFolderMinimalInterface } from "./template-folder.interface";
import { map, startWith } from "rxjs/operators";
import { TemplateFolderActionsService } from "./template-folder-actions.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

@Component({
    selector: "smd-template-manager",
    templateUrl: "./template-manager.component.html",
    styleUrls: ["./template-manager.component.scss"],
    providers: [
        DataTableHelpersService,
        ComponentHelpers,
    ],
})
export class TemplateManagerComponent
    extends TemplateActions
    implements OnInit, AfterViewInit {
    /**
     * Filters Child
     */
    @ViewChildren(PostTemplateFiltersComponent) filterFormInDialog;
    @ViewChildren('feedViewComment') feedViewComment: QueryList<CommentsComponent>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(PreloaderComponent, { static: true })
    preLoader: PreloaderComponent;
    @ViewChild(CollectionListComponent) collectionList: CollectionListComponent;
    @ViewChildren(TemplateFolderViewComponent) templateFolderView: TemplateFolderViewComponent;

    loader: MatDialogRef<DialogLoaderComponent>;
    // Components
    tags: Array<Tag>;
    categories: Array<Category>;
    paginationController: PaginationController = new PaginationController();

    tagClickEventsSubject: Subject<string> = new Subject<string>();
    filterClickEventsSubject: Subject<any> = new Subject();
    folderCreated: Subject<any> = new Subject();
    templateAssigned: Subject<any> = new Subject();

    user: LoggedUserInterface = LoggedUser.getUser();

    unformattedComment: string = '';
    htmlDoc: HTMLElement;
    mentionConfig: MentionConfig = {
        maxItems: 5,
        dropUp: true,
        labelKey: "name",
        allowSpace: true,
        mentionSelect: (e: any) => {
            return '##' + e.userID + '##';
        },
    };
    createCommentInProgress: boolean = false;
    objectType: string = "postTemplate";

    commentsForFeedView: CommentForViewInterface[];


    /**
     * Templates variable
     */
    templates: Array<PostTemplate> = [];
    isAdminMode = !!Utils.lodash.get(
        this.route,
        "snapshot.data.isAdmin",
        false
    );

    collectionConfig: CollectionListConfig<PostTemplate> = {
        allowSelection: (item) => {
            return (
                item.systemType !== CoreConfig.getSystemTypes().Generic ||
                this.isAdminMode
            );
        },
    };

    // Column data for select
    columnVisibility: any = {
        templateID: {
            name: "post.manager.id",
            visible: true,
        },
        medias: {
            name: "post.manager.media",
            visible: true,
        },
        mainCopy: {
            name: "post.manager.mainCopy",
            visible: true,
        },
        sharedWithOrg: {
            name: "post.manager.shared.with.organizations",
            visible: true,
        },
        socialType: {
            name: "post.manager.social.network",
            visible: true,
        },
        createdDate: {
            name: "post.manager.created.date",
            visible: true,
        },
        createdBy: {
            name: "post.manager.created.by",
            visible: true,
        },
        statusMessage: {
            name: "post.manager.status",
            visible: true,
        },
    };
    defaultColumns: any[] = Object.keys(this.columnVisibility);

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    tableHeadConfig: TableHeadConfig[] = [
        {
            nameKey: "post.manager.id",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["templateID"].visible,
        },
        {
            nameKey: "post.manager.media",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["medias"].visible,
        },
        {
            nameKey: "post.manager.mainCopy",
            enable: () => this.columnVisibility["mainCopy"].visible,
        },
        {
            nameKey: "post.manager.shared.with.organizations",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["sharedWithOrg"].visible,
        },
        {
            nameKey: "post.manager.social.network",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["socialType"].visible,
        },
        {
            nameKey: "post.manager.created.date",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["createdDate"].visible,
        },
        {
            nameKey: "post.manager.created.by",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            nameKey: "post.manager.status",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => this.columnVisibility["statusMessage"].visible,
        },
        {
            nameKey: "post.manager.systemType",
            onlyDesktop: true,
            onlyMobile: true,
            enable: () => {
                return this.isAdminMode;
            },
        },
        {
            nameKey: "post.manager.actions",
            onlyDesktop: true
        },
    ];
    tableBodyConfig: TableBodyConfig[] = [
        {
            bindValue: "templateID",
            onlyDesktop: true,
            enable: () => this.columnVisibility["templateID"].visible,
        },
        {
            selector: "imageView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["medias"].visible,
        },
        {
            bindValue: "shortenedPostContent",
            enable: () => this.columnVisibility["mainCopy"].visible,
        },
        {
            selector: "sharedOrganizationView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["sharedWithOrg"].visible,
        },
        {
            bindValue: "socialTypeMessage",
            selector: "socialTypeView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["socialType"].visible,
        },
        {
            bindValue: "createDate",
            dateFormat: true,
            onlyDesktop: true,
            enable: () => this.columnVisibility["createdDate"].visible,
        },
        {
            selector: "createdBy",
            onlyDesktop: true,
            enable: () => this.columnVisibility["createdBy"].visible,
        },
        {
            bindValue: "statusMessage",
            selector: "statusView",
            onlyDesktop: true,
            enable: () => this.columnVisibility["statusMessage"].visible,
        },
        {
            bindValue: "systemTypeMessage",
            onlyDesktop: true,
            enable: () => {
                return this.isAdminMode;
            },
        },
        {
            staticView: "itemActions",
            onlyDesktop: true,
        },
    ];
    placeholderImg = PLACEHOLDER_IMAGE;

    organizations: Array<OrganizationModel> = [];

    relatedUsers: Array<IRelatedUser> = [];
    objectRelatedUsers: KeyValue<String, Array<IRelatedUser>>;
    filterToggled = false;

    listView = false;
    cardView = true;
    feedView = false;

    renderHelper = { // properties to avoid function calls in template
        noneSelected: true,
        mixedSelected: false,
        nonCustomSelected: false,
        nonEditableSelected: false,
        tooltips: {
            approve: 'schedule.share.template.selectAtLeastOne',
            share: 'schedule.share.template.selectAtLeastOne',
            folder: 'schedule.share.template.selectAtLeastOne',
            decline: 'schedule.share.template.selectAtLeastOne',
            delete: 'schedule.share.template.selectAtLeastOne',
        }
    }

    templateManagerPage = 'all';
    selectedTabindex = 0;
    folderName = '';

    foldersOrdered: TemplateFolderMinimalInterface[] = [];
    foldersFiltered: TemplateFolderMinimalInterface[] = [];

    folderControlNames = {
        JumpToFolder: "jumpToFolder",
    };

    folderFormGroup = new FormGroup({
        [this.folderControlNames.JumpToFolder]: new FormControl(""),
    });

    constructor(
        service: TemplateActionsService,
        tagsService: TagsService,
        categoriesService: CategoriesService,
        public postActionsService: PostActionsService,
        public dialog: MatDialog,
        public users: UsersController,
        public activatedRoute: ActivatedRoute,
        carouselController: CarouselController,
        public previewService: ShowPreviewService,
        private openModal: OpenModalService,
        private route: ActivatedRoute,
        public dataTableHelper: DataTableHelpersService,
        private componentHelpers: ComponentHelpers,
        private partnerConfigService: PartnerConfigService,
        private socialMediaPostService: SocialMediaPostService,
        private organizationService: OrganizationService,
        private commentController: CommentController,
        public folderService: TemplateFolderActionsService,

    ) {
        super(
            service,
            tagsService,
            categoriesService,
            postActionsService,
            dialog,
            users,
            carouselController,
            openModal
        );

        this.filters["order"] = Helpers.getOrderFormat("createDate-desc");

        if (this.isAdminMode) {
            this.filters["isAdmin"] = null;
        }
    }

    initialize() {
        this.paginationController.onSetPagination.subscribe((result) => {
            this.filters["limit"] = result.limit;
            this.filters["offset"] = result.offset;

            this.getTemplates();
        });

        this.urlListener();
        this.getRelatedUsers();
    }

    ngOnInit() {
        this.initialize();
        this.getOrganizations();
        this.getAllFoldersForSelect();
    }

    async getOrganizations() {
        this.organizations = (await this.organizationService.getList()).organizations;
    }

    /**
     * Get related users (to the current user)
     */
    getRelatedUsers(templateIDs?: number[]) {
        if (!templateIDs) {
            this.users.service.getRelatedUsers()
                .then((response) => {
                    response.users.forEach((user) => {
                        this.relatedUsers[user.userID] = {
                            userID: user.userID,
                            name: user.name,
                            organizationID: user.organizationID,
                            organizationName: user.organizationName,
                            organizationIDs: user.organizationIDs,
                            roleName: user.roleName,
                        };
                    });
                })
                .catch((error) => {
                    this.service.showErrorAlert('Unexpected error while getting related users'); //TODO: language
                });
        } else {
            this.users.service.getObjectRelatedUsers("postTemplate", templateIDs)
                .then((response) => {
                    this.objectRelatedUsers = response.usersForObject;
                })
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.presetFilters();
        });
    }

    allowActivate(item: PostTemplate): boolean {
        return (
            this.isAdminMode &&
            item.status !== POST_TYPE_DRAFT &&
            item.status !== POST_TEMPLATE_STATUS_ACTIVE
        );
    }

    allowSuspend(item: PostTemplate): boolean {
        return (
            this.isAdminMode &&
            item.status !== POST_TYPE_DRAFT &&
            item.status === POST_TEMPLATE_STATUS_ACTIVE
        );
    }

    allowApprove(template: PostTemplate): boolean {
        return CommonPostHelpers.allowApprove({
            hasPartnerConfig: this.partnerConfigService.hasConfig(
                PartnerPermissions.ApprovementWorkflow
            ),
            isAdminMode: this.isAdminMode,
            status: template.status,
            type: template.type,
            systemType: template.systemType,
            templateOrganizationID: template.organizationID,
            approveButtonVisible: template.approveButtonVisible,
        });
    }

    allowDecline(template: PostTemplate): boolean {
        return CommonPostHelpers.allowDecline({
            hasPartnerConfig: this.partnerConfigService.hasConfig(
                PartnerPermissions.ApprovementWorkflow
            ),
            isAdminMode: this.isAdminMode,
            status: template.status,
            type: template.type,
            systemType: template.systemType,
            templateOrganizationID: template.organizationID,
            declineButtonVisible: template.declineButtonVisible,
        });
    }

    allowComment(item: PostTemplate): boolean {
        return CommonPostHelpers.allowCommentOnTemplate({
            systemType: item.systemType,
            isAdminMode: this.isAdminMode,
        });
    }

    allowShare(item: PostTemplate): boolean {
        return item.systemType == "custom" && !!item.isEditable;
    }

    approvement(items: PostTemplate | PostTemplate[], approve: boolean): void {
        items = Array.isArray(items) ? items : [items];
        const loader = this.openModal.loader(DialogLoaderComponent);
        const status = approve
            ? POST_TEMPLATE_STATUS_ACTIVE
            : POST_TEMPLATE_STATUS_NOT_APPROVED;
        const successMsgKey = approve
            ? "post.template.status.message.approved.success"
            : "post.template.status.message.notApproved.success";
        const errorMsgKey = approve
            ? "post.template.status.message.approved.error"
            : "post.template.status.message.notApproved.error";

        items = items.filter(
            (item) => this.allowApprove(item) || this.allowDecline(item)
        );

        this.socialMediaPostService
            .setPostTemplateStatus(
                items.map((item) => item.templateID),
                status
            )
            .then((response) => {
                this.openModal
                    .successModal(DialogSuccessComponent, {
                        message: this.languageService.getLine(successMsgKey),
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.getTemplates();
                    });
            })
            .catch((error) => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        FormValidationService.readError(error).message ||
                        this.languageService.getLine(errorMsgKey),
                });
            })
            .finally(() => loader.close());
    }

    // Unused? this.socialMediaPostService.setPostTemplateOrganizations is used instead
    // If there are no issues, delete this.
    /*share(templates: PostTemplate | PostTemplate[], organizations: Organization | Organization[]): void {
        const loader = this.openModal.loader(DialogLoaderComponent);
        const successMsgKey = "post.template.share.message.success";
        const errorMsgKey = "post.template.share.message.error";

        templates = Array.isArray(templates) ? templates : [templates];
        organizations = Array.isArray(organizations) ? organizations : [organizations];

        //templates = templates.filter((item) => (item.isEditable)); // only send editable templates -> not needed, backend should throw error
        
        this.socialMediaPostService
            .setPostTemplateOrganizations(
                templates.map((item) => item.templateID),
                organizations.map((item) => item.organizationID)
            )
            .then((response) => {
                this.openModal
                    .successModal(DialogSuccessComponent, {
                        message: this.languageService.getLine(successMsgKey),
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.getTemplates();
                    });
            })
            .catch((error) => {
                this.openModal.errorModal(DialogErrorComponent, {
                    message:
                        FormValidationService.readError(error).message ||
                        this.languageService.getLine(errorMsgKey),
                });
            })
            .finally(() => loader.close());
    }*/

    /**
     * Open bulk upload manager in template mode
     *
     * @param {MouseEvent} event
     */
    importTemplatesBtnClick(event: MouseEvent) {
        event.preventDefault();

        this.openModal.bulkUploadManager(BulkUploadManagerComponent, {
            data: {
                entityType: BulkUploadManagerModel.ENTITY_TYPES.Template,
                afterSuccessSave: () => {
                    this.getTemplates();
                },
            },
        });
    }

    /**
     * Get first image or OG image of template
     * @param {PostTemplateInterface} template
     * @returns {null}
     */
    getFirstMedia(template: PostTemplateInterface) {
        return (
            Utils.lodash.get(template, "medias[0].thumb", null) ||
            Utils.lodash.get(template, "linkshare.image", null) ||
            null
        );
    }

    @Debounce()
    getTemplates() {
        const loader = this.openModal.loader(DialogLoaderComponent);
        /*this.getItems(
            () => loader.close(),
            () => loader.close()
        );*/
        this.getItems(
            () => {
                if (!!this.collectionList) {
                    this.collectionList.clearSelection();
                }

                loader.close();
            },
            () => loader.close()
        );
    }

    /**
     * Preset filters shown in modal
     */
    presetFilters() {
        if (
            this.filterFormInDialog.first &&
            this.filterFormInDialog.first.hasOwnProperty("form")
        ) {
            if (!this.isAdminMode) {
                this.filterFormInDialog.first.form.controls[
                    "organizationID"
                ].enable();
            }

            const systemType = this.filters["systemType"] || null;
            const status = this.filters["status"] || null;
            const searchValue = this.filters["searchValue"] || null;

            this.filterFormInDialog.first.form.controls[
                "socialSiteSelectFormControl"
            ].disable();
            this.filterFormInDialog.first.form.controls[
                "dateFromFormControl"
            ].disable();
            this.filterFormInDialog.first.form.controls[
                "dateToFormControl"
            ].disable();
            this.filterFormInDialog.first.form.controls[
                "statusFormControl"
            ].enable();
            this.filterFormInDialog.first.form.controls[
                "statusFormControl"
            ].setValue(status ? [status] : status);
            this.filterFormInDialog.first.form.controls[
                "orderByFormControl"
            ].enable();
            this.filterFormInDialog.first.form.controls[
                "orderByFormControl"
            ].setValue("createDate-desc");
            this.filterFormInDialog.first.form.controls[
                "searchValueFormControl"
            ].enable();
            this.filterFormInDialog.first.form.controls[
                "searchValueFormControl"
            ].setValue(searchValue);
            this.filterFormInDialog.first.form.controls[
                "definedDatesFormControl"
            ].disable();
            this.filterFormInDialog.first.form.controls[
                "templateSystemType"
            ].enable();
            this.filterFormInDialog.first.form.controls[
                "templateSystemType"
            ].setValue(systemType ? [systemType] : systemType);
        }
    }

    successGetItems(response: any): void {
        super.successGetItems(response);

        this.templates = response.templates;

        this.paginationOptions.length = response.count;
        // note: if there is an error in here, successGetItems parent catches it so it wont output to console
        this.templates.forEach((template) => {
            let status = template.status;
            template.statusConfigs = PostStatusConfigs[status];

            let name = Utils.get(template, "categories[0].name", null);
            let color = Utils.get(
                template,
                "categories[0].color",
                "#d4459e"
            );
            template.categoryConfigs = {
                categoryName: name,
                categoryColor: color,
            }

            if (template.organizationIDs !== null) {
                template.renderHelper = {
                    nonMainOrganizations: this.getNonMainOrganizations(template).map((id) => {
                        return {
                            id: id,
                            name: this.getOrganizationNameFromID(id),
                        };
                    }),
                    organizationTooltipFor10: this.getTooltipForOrganizationSharing(template.templateID, 9),
                    organizationTooltipFor5: this.getTooltipForOrganizationSharing(template.templateID, 4),
                }
            }

            let userMainOrgArray = this.user.organizations.filter((organization: UserOrganization) => {
                if (organization.main === true) {
                    return organization;
                }
            });
            let userMainOrg = userMainOrgArray[0].organizationID;

            let userTemplateMainOrgArray = this.user.organizations.filter((organization: UserOrganization) => {
                if (organization.organizationID == template.organizationID) {
                    return organization;
                }
            });
            let userAdminInTemplateMainOrg = userTemplateMainOrgArray.length > 0 ? userTemplateMainOrgArray[0].admin : null;

            if (userMainOrg !== template.organizationID && userAdminInTemplateMainOrg !== "yes") {
                //template.renderHelper.organizationPermissionText = "You have limited access because the template's main organizations is different from yours.";
                template.renderHelper.organizationPermissionText = this.languageService.getLine("post.template.organizationPermissionText");
            }

            let nonMainOrganizations = this.getNonMainOrganizations(template);
            if (nonMainOrganizations.length < template.organizationIDs.length - 1 && userMainOrg === template.organizationID) {
                //template.renderHelper.organizationPermissionText = "You have limited access because the template is shared with organizations that you do not have access to.";
                // Client requested this message for both.
                template.renderHelper.organizationPermissionText = this.languageService.getLine("post.template.organizationPermissionText");
            }

            if (template.statusChangedOn) {
                template.statusChangedOn = moment.utc(template.statusChangedOn).tz(this.user.settings.defaultTimeZone);
            }

            template.allowedActions = {
                suspend: this.allowSuspend(template),
                share: this.allowShare(template),
                activate: this.allowActivate(template),
                approve: this.allowApprove(template),
                decline: this.allowDecline(template),
            }
        });
        let templateIDs = this.templates.map(function (template) {
            return template.templateID;
        });

        let filterParams = {
            limit: 1000,
            offset: 0,
            order: Helpers.getOrderFormat("createDate-desc"),
            objectType: this.objectType,
        };

        this.commentController.getComments(
            templateIDs,
            filterParams,
            (comments) => {
                this.commentsForFeedView = comments;
            },
        );

        this.getRelatedUsers(templateIDs);
    }

    successDelete(response: any): void {
        super.successDelete(response);

        this.getTemplates();
    }

    urlListener() {
        this.activatedRoute.data.subscribe((routeData: any) => {
            if (
                routeData &&
                routeData instanceof Object &&
                "systemType" in routeData
            ) {
                this.filters["systemType"] = routeData.systemType;
                this.templateManagerPage = routeData.systemType;
            } else {
                delete this.filters["systemType"];
                this.templateManagerPage = 'all';
            }

            this.getTemplates();
        });

        this.activatedRoute.queryParams.subscribe((params) => {
            const templateID = params.templateID || null;
            const status = params.status || null;
            const searchValue = params.searchValue || null;

            if (status) {
                delete this.filters["status"];
                this.filters["status"] = status;
                this.filters["order"] = "createDate-desc";
                this.filtersChange(this.filters);
            } else {
                delete this.filters["status"];
                this.filters["order"] = "createDate-desc";
                this.filtersChange(this.filters);
            }
            if (searchValue) {
                delete this.filters["searchValue"];
                this.filters["searchValue"] = searchValue;
                this.filters["order"] = "createDate-desc";
                this.filtersChange(this.filters);
            } else {
                delete this.filters["searchValue"];
                this.filters["order"] = "createDate-desc";
                this.filtersChange(this.filters);
            }

            if (templateID) {
                this.getItemByID({ templateID: templateID })
                    .then(
                        ({
                            templates,
                            count,
                        }: {
                            templates: any[];
                            count: number;
                        }) => {
                            if (templates.length > 0) {
                                const template = Utils.initLineBreaksOnPostEntity(templates[0]); // so linekbreaks in texts work when editing

                                if (template.medias && template.medias[0] && template.medias[0].type === FILE_TYPES.CAROUSEL) { // so carousels work
                                    const mediaID = template.medias[0].mediaID

                                    if (mediaID) {
                                        return this.carouselController
                                            .getCarouselItemsByIDs([mediaID])
                                            .then(({ carouselItems }: { carouselItems: CarouselItemResponseInterface[] }) => {
                                                template.medias = template.medias.map(media => {
                                                    const mediaCarouselItems = [];

                                                    carouselItems.map(carouselItem => {
                                                        if (Number(media.mediaID) === Number(carouselItem.carouselID)) {
                                                            mediaCarouselItems.push(new CarouselItem(carouselItem));
                                                        }
                                                    });

                                                    media.carouselItems = mediaCarouselItems;

                                                    return new SmdFile(media);
                                                });

                                                template.coverMediaSrc = CommonPostHelpers.getCoverMediaSrc(template);
                                                return this.openModal.commentTemplate(PostPreviewComponent, template)
                                                    .afterClosed()
                                                    .subscribe(() => {
                                                        this.getTemplates();
                                                    });
                                            });
                                    }
                                } else {
                                    template.medias = SmdFile.initFileArray(template.medias);
                                    return this.openModal.commentTemplate(PostPreviewComponent, template)
                                        .afterClosed()
                                        .subscribe(() => {
                                            this.getTemplates();
                                        });
                                }
                            }
                        }
                    )
                    .catch((error) => {
                        this.service.showErrorAlert(error);
                    });
            }
        });
    }

    importTemplates(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.openModal.importTemplates(PostTemplateImportComponent, {
            data: {
                afterSuccessImport: () => {
                    this.getTemplates();
                },
            },
        });
    }

    /**
     * @param {MouseEvent} event
     * @param {"general" | "twitter" | "instagram" | "gmb"} platform
     */
    downloadSample(
        event: MouseEvent,
        platform: "general" | "twitter" | "instagram" | "gmb" = "general"
    ) {
        event.preventDefault();
        event.stopPropagation();

        this.componentHelpers.startApiAction(
            () => {
                return this.service.downloadSample(platform);
            },
            {
                successMessageKey:
                    "post.template.success.sampleDownloadStarted",
                failedMessageKey: "post.template.error.sampleDownloadFailed",
            }
        );
    }

    createTemplate(isNew: boolean = true, copyTemplate?) {
        if (isNew) {
            this.openModal.createTemplate(PostTemplateManagerComponent, {
                data: {
                    afterCreateCallback: () => {
                        this.getTemplates();
                    },
                    isAdminMode: this.isAdminMode,
                },
            });
        }
    }

    createFolder() {
        this.openModal.createTemplateFolder(TemplateFolderManagerComponent, {
            data: {
                afterSuccessCreate: () => {
                    this.folderCreated.next();
                },
                isAdminMode: this.isAdminMode,
            }
        });
    }

    foldersDeleted() {
        this.getTemplates();
    }

    copyPost(post: PostInterface) {
        this.openModal.copyTemplate(PostTemplateManagerComponent, post, {
            data: {
                afterCreateCallback: () => {
                    this.getTemplates();
                },
                isAdminMode: this.isAdminMode,
            },
        });
    }

    writeComment(postTemplate: PostInterface) {
        this.openModal.commentTemplate(
            PostPreviewComponent,
            postTemplate,
            {
                data: {
                    isCommentMode: true,
                    isAdminMode: this.isAdminMode,
                },
            }
        );
    }

    filtersChange(filters) {
        for (const filterName in filters) {
            let filter = filters[filterName];

            if (!!filter && filter !== "") {
                if (filterName === "order" && filter) {
                    filter = this.getOrderFormat(filter);
                }

                this.filters[filterName] = filter;
            } else {
                delete this.filters[filterName];
            }
        }

        this.refreshPagination(0);

        this.getTemplates();
    }

    @Debounce(250)
    updateRenderData() {
        this.renderHelper.noneSelected = this.noItemsSelected();
        this.renderHelper.mixedSelected = this.isMixedSelectionBySystemType();
        this.renderHelper.nonCustomSelected = this.isNonCustomSelected();
        this.renderHelper.nonEditableSelected = this.isNonEditableSelected();

        this.renderHelper.tooltips.approve = this.getApproveHoverMessage();
        this.renderHelper.tooltips.decline = this.getDeclineHoverMessage();
        this.renderHelper.tooltips.share = this.getShareTemplateHoverMessage();
        this.renderHelper.tooltips.delete = this.getDeleteHoverMessage();
        this.renderHelper.tooltips.folder = this.getAddToFolderHoverMessage();
    }

    selectionChange(event: CollectionListSelectionChange) {
        this.dataTableHelper.selectedItems = event.selectedItems;

        // rerender necessary components
        this.updateRenderData();
    }

    activateTemplateEvent(item) {
        this.setTemplatesStatus("active", [item]);
    }

    suspendTemplateEvent(item) {
        this.setTemplatesStatus("inactive", [item]);
    }

    setPartnersEvent(item) {
        this.setPartners([item]);
    }

    setTemplateOrganizationsEvent(item) {
        this.setTemplateOrganizations([item]);
    }

    editTemplateEvent(item) {
        this.showEdit(item);
    }

    deleteTemplateEvent(item) {
        this.onDelete(item);
    }

    setPartners(templates: PostTemplateInterface[]) {
        this.openModal.setPartners(PartnerChooserComponent, {
            data: {
                templates: templates,
                afterSuccessSave: () => {
                    this.getTemplates();
                },
            },
        });
    }


    setTemplateOrganizations(templates: PostTemplateInterface[]) {
        this.openModal.setOrganizationsForTemplates(TemplateOrganizationChooserComponent, {
            data: {
                templates: templates,
                afterSuccessSave: () => {
                    this.getTemplates();
                    this.dataTableHelper.clearSelection();
                    // if we don't clear the selection, and try to share orgs more times, we will always see the first state
                    // probably due to cloning the template instead of passing it for some reason... no time for this now though, this will be fine for now
                },
            },
        });
    }

    isMixedSelectionBySystemType(): boolean {
        const types: string[] = [];

        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return false;
        }

        for (const template of this.dataTableHelper.getSelectedItems()) {
            if (
                types.findIndex((type) => type === template["systemType"]) ===
                -1
            ) {
                types.push(template["systemType"]);
            }
        }

        return types.length > 1;
    }

    isNonCustomSelected(): boolean {
        const types: string[] = [];

        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return false;
        }

        for (const template of this.dataTableHelper.getSelectedItems()) {
            if (
                types.findIndex((type) => type === template["systemType"]) ===
                -1
            ) {
                types.push(template["systemType"]);
            }
        }

        return !(types.length === 1 && types[0] === "custom");
    }

    isNonEditableSelected(): boolean {
        return this.dataTableHelper.getSelectedItems().some(
            (template) => template.isEditable === false
        );
    }


    noItemsSelected(): boolean {
        return this.dataTableHelper.getSelectedItems().length === 0;
    }

    getApproveHoverMessage(): string {
        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return "schedule.share.template.selectAtLeastOne";
        }

        return "schedule.share.template.selected.approve";
    }

    getDeclineHoverMessage(): string {
        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return "schedule.share.template.selectAtLeastOne";
        }

        return "schedule.share.template.selected.decline";
    }

    getDeleteHoverMessage(): string {
        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return "schedule.share.template.selectAtLeastOne";
        }

        return "schedule.share.template.selected.delete";
    }

    getShareTemplateHoverMessage(): string {
        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return "schedule.share.template.selectAtLeastOne";
        }

        if (this.isNonCustomSelected()) {
            return 'schedule.share.template.onlyForCustom';
        }

        if (this.isNonEditableSelected()) {
            return 'schedule.share.template.onlyForEditable';
        }

        return 'schedule.share.template.selected';
    }

    getAddToFolderHoverMessage(): string {
        if (this.dataTableHelper.getSelectedItems().length === 0) {
            return "schedule.share.template.selectAtLeastOne";
        }

        return "schedule.folder.template.selected";
    }

    /**
     * Show edit template modal
     *
     * @param {PostTemplate} template
     */
    showEdit(template: PostTemplateInterface): void {
        this.openModal
            .editTemplate(PostTemplateManagerComponent, template, {
                data: {
                    isAdminMode: this.isAdminMode,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.getTemplates();
                }
            });
    }

    /**
     * Show template delete confirm modal and set click delete button callback (delete template item)
     *
     * @param {PostTemplate} template
     */
    onDelete(template: PostTemplateInterface): void {
        /**
         * Show confirm modal
         */
        const itemID = JSON.stringify(template.templateID);
        this.dialog
            .open(DialogConfirmComponent, {
                data: {
                    message: LanguageService.getLine(
                        "post.template.delete.confirm.message",
                        { itemID: itemID }
                    ),
                    yesButtonText: LanguageService.getLine(
                        "post.template.delete.confirm"
                    ),
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.loader = this.openModal.loader(DialogLoaderComponent);
                    this.deleteTemplates({
                        templateIDs: JSON.stringify([template.templateID]),
                    });
                }
            });
    }

    afterDeleteTemplates(): void {
        super.afterDeleteTemplates();

        this.loader.close();
    }

    successDeleteTemplates(response: any): void {
        super.successDeleteTemplates(response);
        this.getTemplates();
        this.dataTableHelper.clearSelection();
    }

    failedDeleteTemplates(error: any): void {
        super.failedDeleteTemplates(error);

        this.dialog.open(DialogErrorComponent, {
            data: {
                message: error.error.data.message,
            },
        });
    }

    refreshPagination(pageIndex) {
        this.paginationController.paginationOptions.pageIndex = pageIndex;
        this.filters["offset"] =
            this.paginationOptions.pageIndex * this.paginationOptions.pageSize;
    }

    /**
     * Select action
     *
     * @param event
     */
    selectAction(event: MouseEvent, value: string): void {
        event.preventDefault();
        event.stopPropagation();

        if (
            value !== undefined &&
            value !== "undefined" &&
            this.dataTableHelper.selectedItems.length > 0
        ) {
            switch (value) {
                case "setPartners":
                    this.setPartners(this.dataTableHelper.getSelectedItems());
                    break;

                case "setTemplateOrganizations":
                    this.setTemplateOrganizations(
                        this.dataTableHelper.getSelectedItems()
                    );
                    break;

                case "addToFolders":
                    this.addTemplatesToFolder(
                        this.dataTableHelper.getSelectedItems()
                    );
                    break;

                case "activate":
                    this.setTemplatesStatus("active");
                    break;

                case "suspend":
                    this.setTemplatesStatus("inactive");
                    break;

                case "delete":
                    if (this.dataTableHelper.selectedItems.length > 0) {
                        const confirmRef = this.dialog.open(
                            DialogConfirmComponent,
                            {
                                data: {
                                    message: LanguageService.getLine(
                                        "post.template.delete.multiple.confirm.message",
                                        {
                                            itemCount:
                                                this.dataTableHelper
                                                    .selectedItems.length,
                                        }
                                    ),
                                    yesButtonText: LanguageService.getLine(
                                        "post.template.delete.multiple.confirm"
                                    ),
                                },
                            }
                        );

                        confirmRef.afterClosed().subscribe((result) => {
                            if (result) {
                                this.loader = this.dialog.open(
                                    DialogLoaderComponent,
                                    {
                                        disableClose: true,
                                        panelClass: "dialog-loader-modal",
                                        minWidth: "200vw",
                                        minHeight: "200vh",
                                        hasBackdrop: false,
                                    }
                                );
                                const ids = [];
                                for (const template of this.dataTableHelper
                                    .selectedItems) {
                                    ids.push(template["templateID"]);
                                }
                                this.deleteTemplates({
                                    templateIDs: JSON.stringify(ids),
                                });
                            }
                        });
                    } else {
                        this.dialog.open(DialogWarnComponent, {
                            data: {
                                message: LanguageService.getLine(
                                    "post.template.delete.error.multipleSelect"
                                ),
                            },
                        });
                    }

                    break;
            }
        }
    }

    onStatusChange() {
        this.getTemplates();
    }

    get languageService() {
        return LanguageService;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }

    private setTemplatesStatus(status: "active" | "inactive", templates = []) {
        const successMessages = {
            active: this.languageService.getLine(
                "post.template.status.activate.success"
            ),
            inactive: this.languageService.getLine(
                "post.template.status.suspend.success"
            ),
        };
        const errorMessages = {
            active: this.languageService.getLine(
                "post.template.status.activate.error"
            ),
            inactive: this.languageService.getLine(
                "post.template.status.suspend.error"
            ),
        };
        const loader = this.openModal.loader(DialogLoaderComponent);

        this.postActionsService
            .setPostStatus(
                {
                    postTemplateIDs: JSON.stringify(
                        !!templates.length
                            ? templates.map((item) => item.templateID)
                            : this.dataTableHelper.selectedItems.map(
                                (item) => item.templateID
                            )
                    ),
                    status: status,
                },
                "postTemplate"
            )
            .then((response) => {
                loader.afterClosed().subscribe(() => {
                    if (!response.errors) {
                        this.openModal
                            .successModal(DialogSuccessComponent, {
                                message: successMessages[status],
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.getTemplates();
                            });
                    } else {
                        let errorMessage = "";
                        for (const id of Object.keys(response.errors)) {
                            const message = response.errors[id];

                            errorMessage += `${id} - ${message}\n`;
                        }

                        this.openModal
                            .successModal(DialogErrorComponent, {
                                message: errorMessage,
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.getTemplates();
                            });
                    }
                });

                loader.close();
            })
            .catch((error) => {
                loader.afterClosed().subscribe(() => {
                    this.openModal.errorModal(DialogErrorComponent, {
                        message:
                            FormValidationService.readError(error).message ||
                            errorMessages[status],
                    });
                });

                loader.close();
            });
    }

    gmbIcons = {
        event: "event",
        offer: "local_offer",
        default: "new_releases",
    };

    getOrganizationNameFromID(organizationID) {
        return this.organizations?.find(
            (organization) => organization.organizationID == organizationID
        )?.name || organizationID;
    };

    getTooltipForOrganizationSharing(templateID, number: number) {
        const template = this.templates.find(
            (template) => template.templateID == templateID
        );

        if (template) {
            const organizationIDs = this.getNonMainOrganizations(template);
            const organizationNames = [];

            for (const organizationID of organizationIDs.slice(number, organizationIDs.length)) {
                organizationNames.push(
                    this.getOrganizationNameFromID(organizationID)
                );
            }

            return organizationNames.join(", ");
        }

        return "";
    }

    getNonMainOrganizations(template) {
        return template.organizationIDs !== null ? template.organizationIDs.filter(orgId => orgId != template.organizationID && this.organizations.some(org => org.organizationID == orgId)) : [];
    }

    tagClick(tag: string) {
        this.tagClickEventsSubject.next(tag);
    }

    /**
     * Toggle table columns
     * @param event
     * */
    toggleColumns(event): void {
        Object.keys(this.columnVisibility).forEach((column) => {
            this.columnVisibility[column].visible =
                event.value.includes(column);
        });
    }

    /**
     * Emit show preview output event
     *
     * @param data
     */
    showPreview(data: any) {
        this.previewService.showPreview(data);
    }

    createPostFromTemplate(template: PostTemplateInterface) {
        this.openModal.createPost(PostManagerComponent, {
            data: {
                post: template,
                createdFromTemplateManager: true,
            },
        });
    }

    filterToggleClicked() {
        if (!this.filterToggled) {
            this.filterClickEventsSubject.next();
        }
        this.filterToggled = true;
    }

    toggleViews(currentView: string) {
        if (currentView === "list") {
            this.listView = true;
            this.cardView = false;
            this.feedView = false;
        } else if (currentView === "card") {
            this.listView = false;
            this.cardView = true;
            this.feedView = false;
        } else if (currentView === "feed") {
            this.listView = false;
            this.cardView = false;
            this.feedView = true;
        }
    }

    @HostListener(`document:${CustomEvents.POST_OR_TEMPLATE_EDIT}`, ['$event'])
    getPostsOnPostEdit(): void {
        this.getTemplates();
    }

    /**
    * Trigger create new comment in comments component
    */
    createComment(templateID: number): void {
        let message = document.getElementById('commentText_' + templateID).innerHTML;
        if (message && message !== "") {
            message = message.replace(/<span.*?id="(.*?)".*?>(.*?)<\/span>/g, '##$1##');

            this.createCommentInProgress = true;
            this.feedViewComment.find((commentComponent) => { return commentComponent.objectID == templateID }).createComment(message);
        }
    }

    successCreateComment(response): void {
        this.createCommentInProgress = false;
        this.unformattedComment = "";
        this.htmlDoc = document.getElementById('commentText_' + response.comment.objectID);
        this.htmlDoc.innerHTML = "";
    }

    failedCreateComment(id): void {
        this.createCommentInProgress = false;
        this.unformattedComment = "";
        this.htmlDoc = document.getElementById('commentText_' + id);
        this.htmlDoc.innerHTML = "";
    }

    relatedUserSelected($event: any, templateID: number): void {
        setTimeout(() => {
            this.htmlDoc = document.getElementById('commentText_' + templateID);
            this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(
                '##' + $event.userID + '##',
                '<span id="' + $event.userID + '" class="mention" ' +
                'style="color: #0072f0;" contenteditable=false>@' + $event.name + '</span>&nbsp;'
            );
            // set cursor to the end of the text
            this.htmlDoc.focus();
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(this.htmlDoc.childNodes[this.htmlDoc.childNodes.length - 1], 1);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);

        }, 10);
    }

    addTemplatesToFolder(templates: Array<PostTemplateInterface>) {
        this.openModal.addTemplateToFolder(TemplateFolderSelectComponent, {
            data: {
                templates: templates,
                isAdminMode: this.isAdminMode,
                afterSuccessSave: () => {
                    this.templateAssigned.next();
                    this.getTemplates();
                },
            },
        });
    }

    changeTab(object: { tabIndex: number, folderID: number, folderName: string }) {
        this.selectedTabindex = object.tabIndex;
        this.filters["folderID"] = object.folderID;
        this.filters["order"] = "createDate-desc";
        this.filtersChange(this.filters);
        this.folderName = object.folderName;
        console.log('folderID: ', object.folderID);
    }

    tabSelectedIndexChange(index: number) {
        if (index === 0) {
            this.filters["order"] = "createDate-desc";
            delete this.filters["folderID"];
            this.filtersChange(this.filters);
            this.folderName = '';
        }
        this.selectedTabindex = index;
    }

    getAllFoldersForSelect() {
        const filters = {
            myFolders: this.templateManagerPage === 'custom' ? true : false,
        };

        this.folderService.getAllForSelect(filters)
            .then((response: { folders: TemplateFolderMinimalInterface[] }) => {
                this.foldersOrdered = response.folders;
                this.foldersFiltered = this.foldersOrdered.slice();
            }).catch((error) => {
                console.error(error);
            });
    }

    openFolder(folderID: number) {
        this.changeTab({ tabIndex: 1, folderID: folderID, folderName: this.getFolderName(folderID) });
        this.folderFormGroup.get(this.folderControlNames.JumpToFolder).setValue("");
    }

    closeFolder(folderID: number) {
        this.changeTab({ tabIndex: 1, folderID: null, folderName: null });
        this.folderFormGroup.get(this.folderControlNames.JumpToFolder).setValue("");
    }

    getFolderName(folderID) {
        const folder = this.foldersOrdered.find((folder) => folder.folderID == folderID);

        return folder ? folder.name : "";
    }

    filterFolders(value: string) {
        return this.foldersOrdered.filter(folder =>
            folder.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    filter(eventType?: string) {
        let filterValue = this.folderFormGroup.get(this.folderControlNames.JumpToFolder).value;
        if (eventType && eventType === 'focus') {
            filterValue = '';
        }
        this.foldersFiltered = this.filterFolders(filterValue);
    }
}