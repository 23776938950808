import {TemplateActionsService} from './template-actions.service';
import {Configs, SocialSite} from '../../configs/configs';
import {CategoriesComponent} from './categories.component';
import {Category, PostTemplateInterface, Tag} from './template.interface';
import {TagsComponent} from './tags.component';
import {CategoriesService} from './categories.service';
import {TagsService} from './tags.service';
import {PostActions} from './post-actions';
import {PostActionsService} from './post-actions.service';
import {SmdFile} from '../../services/file.class';
import {ComponentAbstract} from '../../services/component.abstract';
import {MatDialog} from '@angular/material/dialog';
import {UsersController} from '../users/users/users.component';
import {CarouselController, CarouselItem} from './carousel/carousel.component';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import Utils from '~/src/app/core/utils';
import {Injectable} from '@angular/core';
import CommonPostHelpers from '~/src/app/modules/posts/common-post-helpers';

export class TemplateActions extends ComponentAbstract {

    /**
     * Social sites
     *
     * @type {{id: string; name: string}[]}
     */
    socials: SocialSite[] = Configs.socials;

    /**
     * Tags for templates
     *
     * @type {any[]}
     */
    tags: Tag[] = [];

    /**
     * Selected tags
     *
     * @type {any[]}
     */
    selectedTags: string[] = [];

    /**
     * Tags class
     */
    tagsComponent: TagsComponent;

    /**
     * Categories for templates
     *
     * @type {any[]}
     */
    categories: Category[] = [];

    /**
     * Selected categories
     *
     * @type {any[]}
     */
    selectedCategories: string[] = [];

    /**
     * Categories class
     */
    categoriesComponent: CategoriesComponent;

    /**
     * Current template ID
     *
     * @type {null}
     */
    templateID: number = null;

    /**
     * Social type of current template
     */
    protected _socialType: string;

    /**
     * Post actions class
     */
    postActions: PostActions;

    constructor(
        public service: TemplateActionsService,
        tagsService: TagsService,
        categoriesService: CategoriesService,
        postActionsService: PostActionsService,
        _dialog: MatDialog,
        users: UsersController,
        public carouselController: CarouselController,
        openModal: OpenModalService
    ) {
        super();

        /**
         * New tags class
         *
         * @type {TagsComponent}
         */
        this.tagsComponent = new TagsComponent(tagsService);

        /**
         * New categories class
         *
         * @type {CategoriesComponent}
         */
        this.categoriesComponent = new CategoriesComponent(categoriesService);

        /**
         * New post actions class
         *
         * @type {PostActions}
         */
        this.postActions = new PostActions(postActionsService, _dialog, users, carouselController, openModal);
    }

    /**
     * Instant posting action
     */
    instantPosting(post?) {
        this.beforeDataLoad();

        this.postActions.instantPosting(post || this.getTemplate(), response => {
            this.afterDataLoad();
            this.successInstantPosting(response);
        }, error => {
            this.afterDataLoad();
            this.failedInstantPosting(error);
        });
    }

    postSchedule(post) {
        this.beforeDataLoad();

        this.postActions.postSchedule(post, response => {
            this.afterDataLoad();
            this.successPostSchedule(response);
        }, error => {
            this.afterDataLoad();
            this.failedPostSchedule(error);
        });
    }

    getItems(
        callback: (response: { templates: PostTemplateInterface[], count: number }) => void = () => {},
        failed: (error: any) => void = () => {},
        filters: object = {}
    ): void {
        this.beforeGetItems();
        this.beforeDataLoad();

        let options: any = Object.assign(this.filters, filters);

        options = Utils.queryString.stringify(options, {arrayFormat: 'bracket'});

        this.service.getAll(options, false)
            .then(response => {
                const IDs = [];

                response.templates?.map(template => {
                    if (template.medias && template.medias.length > 0 && template.medias[0].type === FILE_TYPES.CAROUSEL) {
                        IDs.push(template.medias[0].mediaID);
                    }

                    template['partnerIDs'] = Utils.lodash.get(template, 'partnerIDs', []).map(id => parseInt(id, null));
                    template['disallowedPartnerIDs'] = Utils.lodash.get(template, 'disallowedPartnerIDs', []).map(id => parseInt(id, null));

                    template['shortMessage'] = CommonPostHelpers.getShortMessage(template);

                    template['tags'] = (template['tags'] || []).filter(tag => tag !== '');

                    template = Utils.initLineBreaksOnPostEntity(template);
                    return template;
                });

                if (!!IDs.length) {
                    this.carouselController.getCarouselItemsByIDs(Utils.lodash.uniq(IDs))
                        .then(({carouselItems}) => {

                            response.templates = response.templates.map(template => {
                                template.medias = template.medias.map(media => {
                                    const mediaCarouselItems = [];

                                    carouselItems.map(carouselItem => {
                                        if (Number(media.mediaID) === Number(carouselItem.carouselID)) {
                                            mediaCarouselItems.push(new CarouselItem(carouselItem));
                                        }
                                    });

                                    media.carouselItems = mediaCarouselItems;

                                    return new SmdFile(media);
                                });

                                template.coverMediaSrc = CommonPostHelpers.getCoverMediaSrc(template);

                                return template;
                            });

                            callback(response);
                            this.afterGetItems();
                            this.afterDataLoad();
                            this.successGetItems(response);
                        })
                        .catch(error => {
                            failed(error);
                            this.afterGetItems();
                            this.afterDataLoad();
                            this.failedGetItems(error);
                        });
                } else {
                    response.templates = !!response.templates ? response.templates.map(template => {
                        template.medias = SmdFile.initFileArray(template.medias || []);
                        template.coverMediaSrc = CommonPostHelpers.getCoverMediaSrc(template);
                        return template;
                    }) : [];
                    callback(response);
                    this.afterGetItems();
                    this.afterDataLoad();
                    this.successGetItems(response);
                }
            })
            .catch(error => {
                failed(error);
                this.afterGetItems();
                this.afterDataLoad();
                this.failedGetItems(error);
            });
    }

    /**
     * Delete templates by IDs
     *
     * @param {object} IDs
     */
    deleteTemplates(IDs: object) {
        this.beforeDeleteTemplates();

        this.service.deleteItems(IDs)
            .then(response => {
                this.afterDeleteTemplates();
                this.successDeleteTemplates(response);
            })
            .catch(error => {
                this.afterDeleteTemplates();
                this.failedDeleteTemplates(error);
            });
    }

    successPostSchedule(response: any): void {
    }

    failedPostSchedule(error: any): void {
    }

    /**
     * Before delete templates event
     */
    beforeDeleteTemplates(): void {
    }

    /**
     * After delete templates event
     */
    afterDeleteTemplates(): void {
    }

    /**
     * Success delete items callback
     *
     * @param response
     */
    successDeleteTemplates(response: any): void {
    }

    /**
     * Failed delete items callback
     *
     * @param error
     */
    failedDeleteTemplates(error: any): void {
    }

    /**
     * Success instant posting callback
     *
     * @param response
     */
    successInstantPosting(response: any): void {
    }

    /**
     * Failed instant posting callback
     *
     * @param error
     */
    failedInstantPosting(error: any): void {
    }

    /**
     * Get current template data
     *
     * @return {any}
     */
    getTemplate(): any {
    }

    /**
     * Set current template
     *
     * @param {PostTemplateInterface} template
     */
    setTemplate(template: PostTemplateInterface): void {
    }

    /**
     * Set socialType event
     *
     * @param {string} value
     */
    setSocialTypeEvent(value: string): void {
    }

    /**
     * Get socialType
     *
     * @return {string}
     */
    public get socialType(): string {
        return this._socialType;
    }

    /**
     * Set socialType
     *
     * @param {string} value
     */
    public set socialType(value: string) {
        this._socialType = value;
        this.setSocialTypeEvent(value);
    }

}


@Injectable()
export class TemplateActionsController extends TemplateActions {
    constructor(
        service: TemplateActionsService,
        tagsService: TagsService,
        categoriesService: CategoriesService,
        postActionsService: PostActionsService,
        dialog: MatDialog,
        users: UsersController,
        carouselController: CarouselController,
        openModal: OpenModalService
    ) {
        super(service, tagsService, categoriesService, postActionsService, dialog, users, carouselController, openModal);
    }
}
