import AbstractModel from '~/src/app/core/model.abstract';
import {IHashtagCollection} from '~/src/app/modules/hashtag/hashtag-collection.interface';
import Utils from '~/src/app/core/utils';
import {VIEW_MOMENT_BASE_DATETIME_FORMAT} from '~/src/app/configs/configs';
import {HashtagUtils} from '~/src/app/modules/hashtag/hashtag.utils';

export class HashtagCollectionModel extends AbstractModel<IHashtagCollection> implements IHashtagCollection {
    collectionID: number;
    name: string;
    tags: string[];
    createDate: string;
    organizationID: number[];
    organizationNames?: string[];
    tagsString: string;
    userID?: number;
    userName?: string;
    orgOfUser?: number;
    nameOfUserOrg?: string;

    constructor(json: IHashtagCollection) {
        super(json);

        this.parse(json);

        if (typeof this.tags === 'string') {
            this.tags = (this.tags as string).split(', ').map(tag => {
                if (HashtagUtils.hasHashtagSign(tag)) {
                    return tag;
                }

                return HashtagUtils.addHashtagSign(tag);
            });
        }

        this.tagsString = this.tags.map(tag => {
            if (HashtagUtils.hasHashtagSign(tag)) {
                return tag;
            }

            return HashtagUtils.addHashtagSign(tag);
        }).join(', ');

        if (this.createDate) {
            this.createDate = Utils.moment(this.createDate).format(VIEW_MOMENT_BASE_DATETIME_FORMAT);
        }

        if (this.organizationID && this.organizationID instanceof Array) {
            this.organizationID = this.organizationID.map(id => Number(id));
        }
    }
}
